import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/products/Overview.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";
import PlusBigIcon from "../../../assets/images/plus-big-20x20.svg";
import autobind from "autobind-decorator";
import CloseSmallIcon from "../../../assets/images/close-small-15x15.svg";
import {setNotification} from "../../../redux/actions/general/notification";
import {
    deleteProductImage,
    listProducts,
    readProduct,
    reorderProductImages,
    uploadProductImages
} from "../../../redux/actions/table/products";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../../core/utils";
import {hideAlertbox, showAlertbox} from "../../../redux/actions/general/alertbox";
import {showLightbox} from "../../../redux/actions/general/lightbox";
import {ReactSortable} from "react-sortablejs";
import Image from "../../../components/Image";
import DefaultImage from "../../../assets/images/default.png";

@mapStateToProps(state => ({
    store: state.table.superadmin_products
}))
class Overview extends Component {
    render() {
        const {collapsed, store} = this.props;
        const {flyout, language_id} = store;

        const thumbnail_images = _.sortBy(_.filter(flyout.images, ['category', 'thumbnail']), 'order');

        const lightbox_images = _.map(thumbnail_images, image => ({
            id: image.id,
            stream_url: image.stream_url,
            title: [image.name, image.ext].join('.')
        }));

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                <div className={styles.flex}>
                    <div className={classNames(styles.f1, styles.image)} onClick={() => !_.isEmpty(thumbnail_images) ? showLightbox(lightbox_images, _.first(thumbnail_images).id) : undefined}>
                        <Image default={DefaultImage} src={flyout.thumb_url} />
                    </div>
                    <div className={classNames(styles.f1, styles.info)}>
                        <div className={styles.logo}>
                            <Image default={DefaultImage} src={flyout.brand_logo_url} />
                        </div>
                        <div className={styles.details}>
                            {_.size(flyout.model) > 0 && <div className={styles.row}>
                                <strong>{__('products.column.model')}</strong>
                                <span>{flyout.model}</span>
                            </div>}
                            {_.size(flyout.brand) > 0 && <div className={styles.row}>
                                <strong>{__('products.column.manufacturer')}</strong>
                                <span>{flyout.brand}</span>
                            </div>}
                            {_.size(getLocalized(flyout.description, language_id)) > 0 && <div className={styles.row}>
                                <strong>{__('products.column.description')}</strong>
                                <span>{getLocalized(flyout.description, language_id)}</span>
                            </div>}
                            {_.size(flyout.family) > 0 && <div className={styles.row}>
                                <strong>{__('products.column.family')}</strong>
                                <span>{flyout.family}</span>
                            </div>}
                            {_.size(flyout.collection) > 0 && <div className={styles.row}>
                                <strong>{__('products.column.collection')}</strong>
                                <span>{flyout.collection}</span>
                            </div>}
                            {_.size(flyout.designer) > 0 && <div className={styles.row}>
                                <strong>{__('products.column.designer')}</strong>
                                <span>{flyout.designer}</span>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Overview;
