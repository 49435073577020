const initialState = {};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "table.my_company_members/SET_TABLE_PARAMS":
			return {
				...state,
				...action.params
			};
		case "table.my_company_members/LIST_MY_COMPANY_MEMBERS_SUCCESS":
			return {
				...state,
				data: action.response.data,
                filters: action.response.filters,
				...action.response.pagination
			};
		default:
			return state;
	}
}
