import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/superadmin/boq_template_calculations/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm } from '../../../core/utils';
import { setNotification } from '../../../redux/actions/general/notification';
import {
  listBOQTemplateCalculations,
  updateBOQTemplateCalculation,
} from '../../../redux/actions/table/superadmin/boq_template_calculations';
import EditBOQTemplateCalculationForm from '../../../forms/superadmin/boq_template_calculations/EditBOQTemplateCalculationForm';

@withRouter
class Properties extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateBOQTemplateCalculation(formData.id, _.omit(formData, ['id'])).then(
        () => {
          listBOQTemplateCalculations();

          this.props.readAction(formData.id);

          setNotification(
            __('properties.notifications.properties-have-been-saved')
          );
        }
      )
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.scroller}>
        <EditBOQTemplateCalculationForm
          onSubmit={this._handleSubmit}
          initialValues={{
            ..._.pick(data, [
              'id',
              'name',
              'boq_template_subgroup_id',
              'unit_id',
              'price',
              'description',
              'budget_type',
              'installation_type',
              'boq_supply_type_group_id',
            ]),
          }}
        />
      </div>
    );
  }
}

export default Properties;
