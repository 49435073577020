import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddSpecificationProductAttributeGroupAsTemplateWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import ProductAttributeGroupSaveIcon from "../assets/images/product-attribute-group-save-60x60.svg";
import * as _ from "lodash";
import {
	listSpecificationProductAttributeGroupFields,
	saveSpecificationProductAttributeGroupAsTemplate
} from "../redux/actions/table/working_set_specifications";
import AddSpecificationProductAttributeGroupAsTemplateForm
	from "../forms/add_specification_product_attribute_group_as_template_wizard/AddSpecificationProductAttributeGroupAsTemplateForm";
import Localization from "../helpers/Localization";
import {subscribe} from "react-contextual";

@subscribe(Localization, "localization")

@mapStateToProps(state => ({
	group_id: _.get(state, 'table.working_set_specifications.save_specification_product_attribute_group_as_template_wizard')
}))
class AddSpecificationProductAttributeGroupAsTemplateWizard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: []
		}
	}

	componentDidMount() {
		listSpecificationProductAttributeGroupFields(this.props.group_id).then(({response}) => {
			this.setState({fields: response.data});
		});
	}

	@autobind
	_handleSubmit(formData) {
		const data = {
			name: formData.name,
			fields: _.filter(_.keys(formData.fields), field_id => formData.fields[field_id] == true)
		}

		return validateForm(
			saveSpecificationProductAttributeGroupAsTemplate(this.props.group_id, data).then(() => {
				setNotification("A specification attribute group has been saved as a template.");

				this.props.onClose();
			})
		);
	}

	render() {
		const fields = _.filter(this.state.fields, field => !field.product_attribute_template_field_id || !this.props.localization.product_attribute_fields[field.product_attribute_template_field_id].discontinued);

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('specifications.working-set.flyout.save-template.save-attribute-group-as-template')}</span>
						<span>{__('specifications.working-set.flyout.save-template.save-attribute-group-as-template-to-your-company-storage')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeGroupSaveIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddSpecificationProductAttributeGroupAsTemplateForm onSubmit={this._handleSubmit} fields={fields} initialValues={{
						fields: _.transform(fields, (fields, field) => {
							_.set(fields, field.id, 1);
						}, {})
					}} />
				</div>
			</>
		);
	}
}

export default AddSpecificationProductAttributeGroupAsTemplateWizard;
