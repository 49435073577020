import React, { Component } from "react";
import styles from "../styles/components/TexturePicker.scss";
import classNames from "classnames";
import ClickOutside from "react-click-outside";
import SearchIcon from "../assets/images/search-15x15.svg";
import * as _ from "lodash";
import autobind from "autobind-decorator";
import { __, transformToListPairs, getLocalized } from "../core/utils";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../helpers/Localization";
import MultiSelect from "./MultiSelect";
import idToKey from "id-to-key";
import ArrowDownMiddleIcon from "../assets/images/arrow-down-middle-15x15.svg";
import Dropzone from "./Dropzone";
import { setNotification } from "../redux/actions/general/notification";
import {listLocalization, listTextures} from "../redux/actions/localization";
import FieldComponent from "./FieldComponent";
import OutsideClickWrapper from "./OutsideClickWrapper";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import { isMobileOnly } from "react-device-detect";
import Image from "./Image";
import Input from "./Input";
import Tooltip from "./Tooltip";
import InfoIcon from "../assets/images/info-positive-16x16.svg";
import {createTexture} from "../redux/actions/general";

@FieldComponent
@subscribe(Localization, "localization")
class TexturePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            raw_color_dropdown_active: false,
            filter_colors: [],
            texture_name: {},
            texture_code: '',
            texture_thumb: undefined,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState != this.state || prevProps.meta != this.props.meta) {
            this.props.recalculateOffset();
        }
    }

    @autobind
    _handleClick() {
        this._handleOpen();
    }

    @autobind
    _handleOpen() {
        if (!this.props.disabled) {
            this.setState({
                raw_color_dropdown_active: false,
            });

            this.props.input.onFocus();

            // if (this.props.input.value) {
            //     if (_.isInteger(this.props.input.value)) {
            //         if (_.find(this.props.localization.textures, ['id', this.props.input.value])) {
            //             this._changeTab('system');
            //         } else {
            //             this._changeTab('my_company');
            //         }
            //     }
            //
            //     if (_.isObject(this.props.input.value)) {
            //         this._changeTab('new');
            //     }
            // }
        }
    }

    @autobind
    _handleClose() {
        this.props.input.onBlur();
    }

    @autobind
    _createTexture() {
        if (_.isEmpty(_.filter(this.state.texture_name, _.identity))) {
            this.setState({ texture_name_error: true });
        } else {
            this.setState({ texture_name_error: false });
        }

        if (_.isEmpty(this.state.texture_code)) {
            this.setState({ code_error: true });
        } else {
            this.setState({ code_error: false });
        }

        if (_.isEmpty(this.state.filter_colors)) {
            this.setState({ raw_color_error: true });
        } else {
            this.setState({ raw_color_error: false });
        }

        if (!_.has(this.refs.file.files, '0')) {
            setNotification({
                text: __('specifications.working-set.flyout.atributes.please-upload-texture-thumb'),
                type: "warning"
            });
        }

        if (_.has(this.refs.file.files, '0') && !_.isEmpty(_.filter(this.state.texture_name, _.identity)) && !_.isEmpty(this.state.texture_code) && !_.isEmpty(this.state.filter_colors)) {
            createTexture({
                name: this.state.texture_name,
                code: this.state.texture_code,
                raw_colors: this.state.filter_colors,
                thumb: this.refs.file.files[0]
            }).then(({ response }) => {
                listTextures();

                this.props.input.onChange(response.data.id);
                this.props.input.onBlur();
            });
        }
    }

    render() {
        const { raw_color_dropdown_active, filter_colors, code_error, raw_color_error } = this.state;
        const { name_active, texture_name_error, code_active } = this.state;
        const { active } = this.props.meta;
        const system_textures = this.props.localization.textures;
        const raw_colors = this.props.localization.raw_colors;
        const { value } = this.props.input;
        const { language, leftPosition, topPosition, elementRef, dropdownRef } = this.props;

        const active_texture = {
            name: getLocalized(_.get(_.find(system_textures, ['id', _.toInteger(value)]), 'name'), language),
            thumbnail_url: _.get(_.find(system_textures, ['id', _.toInteger(value)]), 'thumbnail_url'),
            preview_url: _.get(_.find(system_textures, ['id', _.toInteger(value)]), 'preview_url'),
        };

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        return (
            <div className={classNames(
                styles.wrapper,
                active && styles.focus,
                this.props.disabled && styles.disabled,
                _.get(this.props, "meta.error") && _.get(this.props, "meta.touched") && styles.error,
            )}>
                {this.props.label && <div className={styles.label}>
                    <label onClick={this._handleClick}>
                        {this.props.labelTooltip ?
                            <Tooltip text={this.props.labelTooltip}>
                                {this.props.label}
                            </Tooltip> :
                            this.props.label
                        }
                    </label>
                </div>}
                <div className={styles.inputGroup} ref={elementRef} onClick={this._handleClick}>
                    <div className={styles.input}>
                        {!!value &&
                            <Tooltip placement="left" text={<Image className={styles.thumbPreview} src={active_texture.preview_url} />} html={true} dark={true}>
                                <Image className={styles.textureThumb} src={active_texture.thumbnail_url} />
                            </Tooltip>
                        }
                        {value ? active_texture.name : __('specifications.working-set.flyout.atributes.select-texture')}
                        <ArrowDownMiddleIcon />
                    </div>
                </div>
                {active && (
                    <OutsideClickWrapper closable={false} onClickOutside={e => {
                        e.stopPropagation();
                    }}>
                        <div className={styles.dropdown} ref={dropdownRef} style={{ top: topPosition + 'px', left: leftPosition + 'px', marginTop: (this.props.top || 27) + 'px', marginLeft: (this.props.left || 0) + 'px' }}>
                            {isMobileOnly && <div className={styles.mobileHeader}>{this.props.label}</div>}
                            <div className={styles.content}>
                                <div className={classNames(styles.flex, styles.inputs)}>
                                    <div className={classNames(styles.f1, styles.file)}>
                                        <span>{__('specifications.working-set.flyout.atributes.add-texture.texture-thumbnail')}</span>
                                        <input ref="file" accept="image/x-png,image/jpeg" type="file" />
                                    </div>
                                </div>
                                <div className={classNames(styles.flex, styles.inputs)}>
                                    <Input
                                        name="texture_name"
                                        label={__('specifications.working-set.flyout.atributes.add-texture.texture-name')}
                                        localized={application_languages}
                                        wrapperClassName={styles.textureNameInput}
                                        meta={{
                                            form: 'texture_form',
                                            active: name_active,
                                            error: texture_name_error,
                                            touched: texture_name_error,
                                        }}
                                        input={{
                                            value: this.state.texture_name,
                                            onBlur: () => this.setState({ name_active: false }),
                                            onFocus: () => this.setState({ name_active: true }),
                                            onChange: texture_name => this.setState({ texture_name })
                                        }}
                                    />
                                </div>
                                <div className={classNames(styles.flex, styles.inputs)}>
                                    <Input
                                        name="texture_code"
                                        label={__('specifications.working-set.flyout.atributes.add-texture.texture-code')}
                                        wrapperClassName={styles.textureCodeInput}
                                        meta={{
                                            form: 'texture_form',
                                            error: code_error,
                                            touched: code_error,
                                            active: code_active
                                        }}
                                        input={{
                                            value: this.state.texture_code,
                                            onBlur: () => this.setState({ code_active: false }),
                                            onFocus: () => this.setState({ code_active: true }),
                                            onChange: texture_code => this.setState({ texture_code })
                                        }}
                                    />
                                </div>
                                <div className={styles.raw}>
                                    <span className={classNames(raw_color_dropdown_active && styles.active)}>{__('specifications.working-set.flyout.atributes.add-texture.filter-texture')}</span>
                                    <div className={styles.multiSelectComponent}>
                                        <MultiSelect
                                            searchable
                                            input={{
                                                value: filter_colors,
                                                onFocus: () => this.setState({ raw_color_dropdown_active: true, raw_color_error: false }),
                                                onBlur: () => this.setState({ raw_color_dropdown_active: false }),
                                                onChange: (filter_colors) => this.setState({ filter_colors }),
                                            }}
                                            meta={{
                                                active: raw_color_dropdown_active,
                                                error: raw_color_error,
                                                touched: raw_color_error
                                            }}
                                            noSort // options are already sorted.
                                            options={_.sortBy(_.map(raw_colors, raw_color => ({
                                                label: _.get(raw_color.name, language, _.get(raw_color.name, 1)),
                                                priority: raw_color.priority,
                                                value: raw_color.id
                                            })), 'priority')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.footer}>
                                <ButtonGroup right>
                                    <Button lightGray medium middleText={__('button.cancel')} onClick={this._handleClose} />
                                    <Button lightBlue medium middleText={__('button.add-and-select')} onClick={this._createTexture} />
                                </ButtonGroup>
                            </div>
                        </div>
                    </OutsideClickWrapper>
                )}
                <div className={styles.bar} />
                <div className={classNames(styles.assist, ((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.hint) && styles.hint)}>
                    {((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.hint) && <span>{_.get(this.props, "meta.error") || this.props.hint}</span>}
                </div>
            </div>
        );
    }
}

export default TexturePicker;
