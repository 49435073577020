import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {connect} from "react-redux";
import Textarea from "../../components/Textarea";
import {enableAutosave} from "../../redux/actions/general/autosave";
import {__} from "../../core/utils";
import DateTimePicker from "../../components/DateTimePicker";

const mapStateToProps = state => {
    return {
        values: getFormValues("stages.details")(state),
        auth: state.auth
    };
};

@reduxForm({
    form: "stages.details",
    enableReinitialize: true,
})
@connect(mapStateToProps)
class EditStageForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const stage_statuses = [
            {
                value: 1,
                label: __('project-settings.stages.flyout.status.not-started')
            },
            {
                value: 2,
                label: __('project-settings.stages.flyout.status.started')
            },
            {
                value: 3,
                label: __('project-settings.stages.flyout.status.finished')
            }
        ];

        return (
            <Form onSubmit={this.props.handleSubmit}>
                <Field disabled={!this.props.isProjectAdministrator} name="date" component={DateTimePicker} label={__('project-settings.stages.flyout.date-range')} footer range />
                <Field disabled={!this.props.isProjectAdministrator} localized={this.props.projectLanguages} name="name" component={Input} label={__('project-settings.stages.flyout.title')} maxLength={50} />
                <Field disabled={!this.props.isProjectAdministrator} localized={this.props.projectLanguages} name="description" component={Textarea} label={__('project-settings.stages.flyout.description')} maxLength={100} rows={1} maxRows={10} />
                <Field disabled={!this.props.isProjectAdministrator} name="status" component={Select} label={__('project-settings.stages.flyout.status')} options={stage_statuses} />
            </Form>
        );
    }
}

export default EditStageForm;
