import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddRegionWizard.scss";
import autobind from "autobind-decorator";
import AddRegionIcon from "../../assets/images/add-region-64x64.svg";
import {mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import {createRegion, listRegions} from "../../redux/actions/table/superadmin/regions";
import AddRegionForm from "../../forms/superadmin/add_region_wizard/AddRegionForm";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_regions"],
}))
class AddRegionWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const { edit } = formData;

        return validateForm(
            createRegion(formData).then(({ response }) => {
                listRegions().then(() => {
                    if (edit) {
                        setTableParams('superadmin_regions', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_regions', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                this.props.onClose();

                setNotification("You have successfully added a new region.");
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>Add region</span>
                        <span>Add region to the application</span>
                    </div>
                    <div className={styles.right}>
                        <AddRegionIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddRegionForm onSubmit={this._handleSubmit} />
                </div>
            </>
        );
    }
}

export default AddRegionWizard;