import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { getLocalized, mapStateToProps, transformToListPairs } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import { withRouter } from "react-router";
import MultiSelect from "../../../components/MultiSelect";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../../../helpers/Localization";

@reduxForm({
    form: "superadmin_colors.edit_color",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, 'localization')
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    auth: state.auth,
}))
class EditColorForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin, localization, auth } = this.props;

        const application_languages = _.transform(_.filter(localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        const localized_raw_colors = _.sortBy(_.map(localization.raw_colors, item => ({
            value: item.id,
            priority: item.priority,
            label: getLocalized(item.name, this.props.language),
        })), 'priority');

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_color} name="name" localized={application_languages} component={Input} label="Name" />
                <Field disabled={!superadmin.edit_color} name="raw_colors" component={MultiSelect} searchable noSort options={localized_raw_colors} label="Filter colors" />
                <Field disabled={!superadmin.edit_color} name="hex" component={Input} maxLength={6} label="Hex" />
            </Form>
        );
    }
}

export default EditColorForm;