import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/superadmin/product_attribute_template_groups/Properties.scss";
import * as _ from "lodash";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../../core/utils";
import { setNotification } from "../../../redux/actions/general/notification";
import { listNotes } from "../../../redux/actions/table/superadmin/notes";
import {
    listProductAttributeTemplateGroups,
    updateProductAttributeTemplateGroup
} from "../../../redux/actions/table/superadmin/product_attribute_template_groups";
import EditProductAttributeTemplateGroupForm
    from "../../../forms/superadmin/product_attribute_template_groups/EditProductAttributeTemplateGroupForm";
import { listLocalization } from "../../../redux/actions/localization";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        const fields = formData.fields?.map((field, priority) => ({
            ...field,
            priority
        }));

        const data = {
            ..._.omit(formData, ['id', 'fields']),
            fields: _.transform(fields, (acc, field) => {
                _.set(acc, field.id, {
                    ...field,
                    priority: field.priority,
                });
            }, {})
        }

        return validateForm(
            updateProductAttributeTemplateGroup(formData.id, data).then(() => {
                listProductAttributeTemplateGroups();

                listLocalization();

                this.props.readAction(formData.id);

                setNotification(__('properties.notifications.properties-have-been-saved'));
            })
        );
    }

    render() {
        const { data } = this.props;

        return (
            <div className={styles.scroller}>
                <EditProductAttributeTemplateGroupForm
                    onSubmit={this._handleSubmit}
                    fields={data.fields}
                    initialValues={{
                        ..._.pick(data, ['id', 'priority', 'name', 'fields']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;