import * as React from "react";
import {Component} from "react";
import {Route} from "react-router";
import SnackBar from "../components/SnackBar";
import Wizards from "../containers/Wizards";
import Localization from "./Localization";
import {subscribe} from "react-contextual";
import _ from 'lodash';
import {clearNotifications} from "../redux/actions/general/notification";
import {removePlusActions} from "../redux/actions/general/plus";
import Downloader from "../components/Downloader";
import {clearActiveModules} from "../redux/actions/general/active_modules";
import {mapStateToProps, screenIs, setPageTitle} from "../core/utils";
import {hideDropdown} from "../redux/actions/general/dropdown";
import AlertBox from "../components/AlertBox";
import {hideSidebar} from "../redux/actions/general/sidebar";
import Lightbox from "../containers/Lightbox";
import ReactTooltip from "./tooltip";
import Overlay from "../components/Overlay";

@subscribe(Localization, "localization")
@mapStateToProps(state => ({
	auth: state.auth
}))
class DynamicRoute extends Component {
	UNSAFE_componentWillReceiveProps(nextProps) {
		const path = this.props.remountable ? this.props.computedMatch.url : this.props.computedMatch.path;
		const nextPath = nextProps.remountable ? nextProps.computedMatch.url : nextProps.computedMatch.path;

		if(path !== nextPath) {
            clearNotifications();
            removePlusActions();
            clearActiveModules();
            hideDropdown();
            setPageTitle(null);

            window.previousLocation = _.pick(this.props.location, ['search', 'pathname']);

			screenIs('<=', 1024) && hideSidebar();
		}
	}

	render() {
		const Component = this.props.component;
		const Layout = this.props.layout;

		return (
			<Route
				{..._.omit(this.props, ["component", "layout"])}
				render={props => (
					<>
						<Layout>
							<Component key={(this.props.remountable ? this.props.computedMatch.url : this.props.computedMatch.path) + (_.get(this.props, 'auth.loggedIn') ? this.props.auth.language_id : '')} {...props} localization={this.props.localization} />
						</Layout>
						<SnackBar layout={Layout} />
						<Wizards />
						<Lightbox />
						<ReactTooltip effect="solid" />
						<AlertBox />
						<Downloader />
						<Overlay />
					</>
				)}
			/>
		);
	}
}

export default DynamicRoute;
