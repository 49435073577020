import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/products/Parts.scss";
import * as _ from "lodash";
import classNames from "classnames";
import {__, getLocalized, redirect, mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import Tooltip from "../../components/Tooltip";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";
import autobind from "autobind-decorator";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import {setNotification} from "../../redux/actions/general/notification";
import {
    deleteProductPart, listProducts,
    readProduct, updateProductCategories
} from "../../redux/actions/table/products";
import TableNA from "../../components/TableNA";
import CategoriesForm from "../../forms/products/CategoriesForm";
@mapStateToProps(state => ({
    store: state.table.products,
    application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
class Categories extends Component {
    @autobind
    _handleCategoriesSubmit(formData) {
        return validateForm(
            updateProductCategories(this.props.store.flyout.id, formData).then(() => {
                setNotification(__('products.flyout.product-categories.updated'));

                readProduct(this.props.store.flyout.id).then(({ response }) => {
                    setTableParams('products', {
                        flyout: response.data
                    })
                });

                listProducts();
            })
        );
    }

    render() {
        const {collapsed, store} = this.props;
        const {flyout} = store;

        const {product_groups, product_subgroups} = this.props.localization;

        return (
            <CategoriesForm collapsed={collapsed} onSubmit={this._handleCategoriesSubmit} initialValues={{
                rows: _.map(flyout.subgroups, subgroup_id => ({
                    subgroup: subgroup_id,
                    group: _.get(_.find(product_subgroups, ['id', subgroup_id]) || {}, 'product_group_id'),
                    category: _.get(_.find(product_groups, ['id', _.get(_.find(product_subgroups, ['id', subgroup_id]) || {}, 'product_group_id')]) || {}, 'product_category_id'),
                }))
            }} />
        );
    }
}

export default Categories;