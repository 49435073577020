import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/working_set_specifications/Attributes.scss";
import * as _ from "lodash";
import classNames from "classnames";
import autobind from "autobind-decorator";
import { __, mapStateToProps, setTableParams, getLocalized } from "../../../core/utils";
import AttributesForm from "../../../forms/working_set_specifications/read_only/AttributesForm";
import { setNotification } from "../../../redux/actions/general/notification";
import { ReactSortable } from "react-sortablejs";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import Tooltip from "../../../components/Tooltip";
import GrabberIcon from "../../../assets/images/grabber-16x16.svg";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";

@mapStateToProps((state) => ({
	store: state.table.working_set_specifications,
	application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
class Attributes extends Component {
	last_request_id = null;

	constructor(props) {
		super(props);

		this.state = {
			collapsed_groups: []
		};
	}

	@autobind
	_toggleCollapseGroup(group) {
		this.setState({
			collapsed_groups: _.xor(this.state.collapsed_groups, [group])
		});
	}

	@autobind
	_changeOrder(attribute_groups) {
		setTableParams("working_set_specifications", {
			flyout: {
				...this.props.store.flyout,
				attribute_groups: _.map(attribute_groups, (attribute_group, i) => ({
					...attribute_group,
					priority: i
				}))
			}
		});
	}

	render() {
		const { collapsed, store, application_language_id } = this.props;
		const { flyout } = store;
		const { collapsed_groups } = this.state;

		const attribute_groups = _.sortBy(flyout.attribute_groups, "priority");

		return (
			<div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
				<ReactSortable
					className={styles.attributes}
					list={attribute_groups}
					setList={this._changeOrder}
					animation={200}
					delayOnTouchStart={true}
					onEnd={() => {
						reorderSpecificationProductAttributeGroups(flyout.id, _.map(attribute_groups, "id")).then(() => {
							setNotification(__("working-set-specifications.read-only-attributes.specification-attribute-groups-order-saved"));
						});
					}}
					handle={"." + styles.grabber}
				>
					{_.map(attribute_groups, (group) => (
						<div className={styles.group} key={group.id}>
							<div className={styles.title} onClick={() => this._toggleCollapseGroup(group.id)}>
								<Tooltip text={__("working-set-specifications.read-only-attributes.reorder-attributes-group")}>
									<div className={styles.grabber}>
										<GrabberIcon />
									</div>
								</Tooltip>
								<ArrowDownMiddleIcon className={classNames(styles.collapse, !_.includes(collapsed_groups, group.id) && styles.collapsed)} />
								{getLocalized(group.name, application_language_id)}
							</div>
							<AttributesForm
								expanded={!_.includes(collapsed_groups, group.id)}
								onSubmit={(formData) => this._handleSubmit(group.id, formData)}
								form={"specifications.attributes.group" + group.id}
								groupId={group.id}
								initialValues={_.transform(
									group.attributes,
									(attributes, attribute) => {
										const type = attribute.template_field_id ? _.get(this.props.localization.product_attribute_fields, [attribute.template_field_id, "type"]) : attribute.type;

										// for some reason, when redux receives a id for initial value key
										// it registers as many fields as big key is, i.e. if initial value
										// key was 2704, there would be 2704 registered fields for that form
										// with 1 - 2703 being just empty value.
										const fieldName = `attr-${attribute.id}`;
										if (type == "string") {
											attributes[fieldName] = _.size(attribute.value) > 0 ? attribute.value : {};
										} else {
											attributes[fieldName] = attribute.value;
										}

										return attributes;
									},
									{}
								)}
							/>
						</div>
					))}
				</ReactSortable>
			</div>
		);
	}
}

export default Attributes;
