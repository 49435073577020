import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/upload_plans_wizard/UploadPlansForm.scss";
import {__} from "../../core/utils";
import * as _ from "lodash";
import Select from "../../components/Select";
import {listPossibleTeams} from "../../redux/actions/table/plan_uploads";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        team_id: _.get(getFormValues("upload_plans_wizard.upload_plans")(state), 'team_id')
    };
};

@reduxForm({
    form: "upload_plans_wizard.upload_plans",
    destroyOnUnmount: false,
})
@withRouter
@subscribe(Localization, "localization")
@connect(mapStateToProps, null)
class UploadPlansForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_teams: [],
        }
    }

    componentDidMount() {
        listPossibleTeams(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_teams: response.data});

            if(!_.includes(_.map(this.state.possible_teams, 'id'), this.props.team_id)) {
                this.props.reset();
            }

            if(_.size(response.data) == 1) {
                this.props.change("team_id", _.get(response.data, [0, 'id'], null));
            }
        });
    }

    render() {
        const teams = _.map(this.state.possible_teams, team => {
            return {
                value: team.id,
                label: team.company.name + ' [' + ([team.stage_discipline.code, team.stage_discipline.discipline_id == null ? (team.stage_discipline.description + '*') : _.get(this.props.localization, ['disciplines', team.stage_discipline.discipline_id, 'name'])].join(': ')) + ']'
            };
        });

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <Field name="team_id" component={Select} searchable label={__('plans.plan-upload.plan-upload-list.upload-plans.discipline-team')} options={teams} />
            </Form>
        );
    }
}

export default UploadPlansForm;
