import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddTaskWizard.scss';
import autobind from 'autobind-decorator';
import AddTaskIcon from '../assets/images/add-task-60x60.svg';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import AddTaskForm from '../forms/add_task_wizard/AddTaskForm';
import {
  createTask,
  listAllTasks,
  listPossibleTaskAssignee,
  listPossibleTaskFollowers,
} from '../redux/actions/table/all_tasks';
import { withRouter } from 'react-router';
import { getStagesLanguages, readStage } from '../redux/actions/table/stages';
import * as _ from 'lodash';
import {
  listAllTeams,
  listPossibleStageDisciplines,
} from '../redux/actions/table/teams';
import { listPossibleTags } from '../redux/actions/general';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';

@withRouter
@mapStateToProps((state) => ({
  table: state.table['all_tasks'],
}))
@subscribe(Localization, 'localization')
class AddTaskWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_task_assignee: [],
      list_all_teams: [],
      possible_task_followers: [],
      stage_tags: [],
      possible_stage_disciplines: [],
    };
  }

  componentDidMount() {
    const { disciplines } = this.props.localization;

    listPossibleTaskAssignee(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({
          possible_task_assignee: _.map(response.data, (data) => {
            return data.type === 'discipline'
              ? {
                  ...data,
                  name: `${[
                    data.company_name,
                    [
                      data.stage_discipline.code,
                      data.stage_discipline.discipline_id == null
                        ? data.stage_discipline.description + '*'
                        : _.get(disciplines, [
                            data.stage_discipline.discipline_id,
                            'name',
                          ]),
                    ].join(': '),
                  ].join(' | ')}`,
                }
              : data;
          }),
        });
      }
    );

    listAllTeams(this.props.match.params.stage).then(({ response }) => {
      this.setState({
        list_all_teams: response.data,
      });
    });

    listPossibleTaskFollowers(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({
          possible_task_followers: _.map(response.data, (data) => {
            return data.type === 'discipline'
              ? {
                  ...data,
                  name: `${[
                    data.company_name,
                    [
                      data.stage_discipline.code,
                      data.stage_discipline.discipline_id == null
                        ? data.stage_discipline.description + '*'
                        : _.get(disciplines, [
                            data.stage_discipline.discipline_id,
                            'name',
                          ]),
                    ].join(': '),
                  ].join(' | ')}`,
                }
              : data;
          }),
        });
      }
    );

    readStage(this.props.match.params.stage).then(({ response }) => {
      listPossibleTags('project', response.data.project_id).then(
        ({ response }) => {
          this.setState({ stage_tags: response.data });
        }
      );
    });

    getStagesLanguages(this.props.match.params.stage).then(({ response }) => {
      this.setState({ project_languages: response.data });
    });

    listPossibleStageDisciplines(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({ possible_stage_disciplines: response.data });
      }
    );
  }

  @autobind
  _handleSubmit(formData) {
    const { edit } = formData;

    formData = {
      ..._.omit(formData, ['edit']),
      assignee: _.map(formData.assignee, 'id'),
      followers: _.map(formData.followers, 'id'),
    };

    return validateForm(
      createTask(this.props.match.params.stage, formData).then(
        ({ response }) => {
          listAllTasks(this.props.match.params.stage).then(() => {
            if (edit) {
              setTableParams('all_tasks', {
                clicked_row: response.data.id,
              });
            }
          });

          setNotification(__('wizard.add-task.confirm'));

          this.props.onClose();
        }
      )
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('wizard.add-task.title.create-new')}</span>
            <span>{__('wizard.add-task.subtitle.create-new')}</span>
          </div>
          <div className={styles.right}>
            <AddTaskIcon />
          </div>
        </div>
        <div className={styles.body}>
          {this.state.project_languages && (
            <AddTaskForm
              possibleTaskAssignee={this.state.possible_task_assignee}
              possibleTaskFollowers={this.state.possible_task_followers}
              possibleStageDisciplines={this.state.possible_stage_disciplines}
              stageTags={this.state.stage_tags}
              projectLanguages={this.state.project_languages}
              onSubmit={this._handleSubmit}
              initialValues={{
                status: 'not-started',
                priority: 'normal',
                visibility: '1', //TODO - fetch from project preferences !!!
                type: 'todo',
                description: {},
              }}
            />
          )}
        </div>
      </>
    );
  }
}

export default AddTaskWizard;
