import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/edit_specification_code_wizard/EditSpecificationCodeForm.scss";
import Button from "../../components/Button";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../core/utils";
import Textarea from "../../components/Textarea";
import * as _ from "lodash";
import Input from "../../components/Input";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import Select from "../../components/Select";

@subscribe(Localization, "localization")
@reduxForm({form: "edit_specification_code_wizard.edit_specification_code"})
@mapStateToProps(state => ({
    store: _.get(state.table, 'working_set_specifications'),
    application_language_id: state.auth.language_id,
    values: getFormValues("edit_specification_code_wizard.edit_specification_code")(state),
}))
class EditSpecificationCodeForm extends Component {
    render() {
        const {application_language_id, values} = this.props;
        const {flyout} = this.props.store;
        const {project_language} = flyout;
        const {specification_groups, specification_subgroups} = this.props.localization;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    {_.size(flyout.options) > 1 && <div className={styles.warning}>
                        {__('specifications.working-set.edit-specification.changing-specification-code-update-all-specification-same code')}
                    </div>}
                    <div className={styles.flex}>
                        <div className={styles.f3}>
                            <Field name="specification_subgroup_id" searchable={true} component={Select} label={__('specifications.working-set.add-new.category')} options={_.map(specification_subgroups, specification_subgroup => {
                                const specification_group = _.get(specification_groups, _.get(specification_subgroup, 'specification_group_id'), {});
                                const label = getLocalized(specification_group.code, project_language) + [_.padEnd(specification_subgroup.code, 3, "0"), getLocalized(specification_subgroup.name, application_language_id)].join(': ');
                                const group = [getLocalized(specification_group.code, project_language), getLocalized(specification_group.name, application_language_id)].join(': ');

                                return {
                                    value: specification_subgroup.id,
                                    label,
                                    group
                                };
                            })} />
                        </div>
                        {values.specification_subgroup_id && <div className={styles.code}>
                            {getLocalized(_.get(specification_groups, [_.get(specification_subgroups, [values.specification_subgroup_id, 'specification_group_id']), 'code']), project_language) + _.get(specification_subgroups, [values.specification_subgroup_id, 'code'])}
                        </div>}
                        <div className={styles.f1}>
                            <Field name="code" component={Input} label={__('specifications.working-set.add-new.code')} numeric maxLength={2} />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('working_set_specifications', {
                        edit_specification_code_wizard: undefined
                    })} />
                    <Button lightBlue medium right submit middleText={__('button.edit')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default EditSpecificationCodeForm;
