import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_storey_wizard/AddStoreyForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams, transformToListPairs} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";
import Select from "../../components/Select";
import {listPossibleBuildings} from "../../redux/actions/table/storeys";

@withRouter
@reduxForm({form: "add_storey_wizard.add_storey"})
class AddStoreyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_buildings: [],
        }
    }

    componentDidMount() {
        listPossibleBuildings(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_buildings: response.data});
        });
    }

    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const {possible_buildings} = this.state;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="building_id" component={Select} label={__('storeys.field.building')} searchable options={transformToListPairs(possible_buildings, 'title')} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="number" component={Input} label={__('storeys.field.number')} maxLength={3} />
                        </div>
                        <div className={styles.f3}>
                            <Field name="name" component={Input} localized={this.props.projectLanguages} label={__('storeys.field.name')} maxLength={50} />
                        </div>
                    </div>
                    <Field name="description" component={Textarea} localized={this.props.projectLanguages} label={__('storeys.field.description')} maxLength={2000} rows={1} maxRows={10} />
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="relative_altitude" component={Input} label={__('storeys.field.relative-altitude')} numeric decimal maxLength={8} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="height" component={Input} label={__('storeys.field.height')} numeric decimal maxLength={8} />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('storeys', {
                        add_storey_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddStoreyForm;
