import store from "../../../core/store";

export const listDeliverySpecifications = (specification_delivery_id) => {
    return store.dispatch({
        action: "table.delivery_specifications/LIST_DELIVERY_SPECIFICATIONS",
        method: "GET",
        endpoint: "/specification_deliveries/" + specification_delivery_id + "/specifications",
        main: true
    });
};

export const deleteDeliverySpecifications = (specification_delivery_id, specification_ids) => {
    return store.dispatch({
        action: "table.delivery_specifications/DELETE_DELIVERY_SPECIFICATIONS",
        method: "DELETE",
        endpoint: "/specification_deliveries/" + specification_delivery_id + "/specifications",
        data: {
            specifications: specification_ids
        }
    });
};