import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles
    from "../../styles/forms/change_product_variant_attributes_wizard/ChangeProductVariantAttributesForm.scss";
import Button from "../../components/Button";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";
import * as _ from "lodash";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({form: "change_product_variant_attributes_wizard.change_product_variant_attributes"})
@mapStateToProps(state => ({
    application_language_id: state.auth.language_id,
    store: state.table["products"],
}))
class ChangeProductVariantAttributesForm extends Component {
    render() {
        const {flyout} = this.props.store;
        const {application_language_id} = this.props;
        const { product_attribute_fields, units } = this.props.localization;

        const possible_product_attribute_fields = _.sortBy(_.transform(flyout.attribute_groups, (attribute_fields, group) => {
            _.each(group.attributes, attribute => {
                const currentAttribute = attribute.template_field_id ? _.get(product_attribute_fields, attribute.template_field_id, {}) : attribute;
                const type = currentAttribute.type;
                let label = getLocalized(currentAttribute.label, application_language_id);
                label += ((attribute.unit_id || currentAttribute.unit_id) && _.includes(['numeric', 'range', 'range_selectable'], type)) ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')' : '';
                label += !attribute.template_field_id ? '*' : '';

                attribute_fields.push({
                    value: attribute.id,
                    label,
                    groupPriority: group.priority,
                    group: getLocalized(group.name, application_language_id)
                })
            });
        }, []), 'groupPriority');

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="attribute_field_ids" noSort component={MultiSelect} searchable label="Attributes" options={possible_product_attribute_fields} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('products', {
                        change_product_variant_attributes_wizard: undefined
                    })} />
                    <Button lightBlue medium right submit middleText={__('button.save')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default ChangeProductVariantAttributesForm;
