import React, { useState, useEffect } from 'react';
import styles from '../styles/wizards/CopySpecificationsWizard.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import CopySpecificationsForm from '../forms/copy_specifications_wizard/CopySpecificationsForm';
import CopyLinkedSpecificationsForm from '../forms/copy_specifications_wizard/CopyLinkedSpecificationsForm';
import { readProjectGeneral } from '../redux/actions/projects';
import {
  copySpecifications,
  validateCopySpecificationDetails,
} from '../redux/actions/table/working_set_specifications';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../helpers/Localization';
import classNames from 'classnames';
import { getFormValues } from 'redux-form';
import _ from 'lodash';

const CopySpecificationsWizard = (props) => {
  const { specification_details } = props;

  const steps = [
    {
      id: '01',
      title: __(
        'specifications.working-set.copy-wizard.copy-specifications-details'
      ),
    },
    {
      id: '02',
      title: __(
        'specifications.working-set.copy-wizard.copy-linked-specifications-details'
      ),
    },
  ];

  const [active, set_active_step] = useState(_.first(steps));

  const [project_language, set_project_language] = useState(null);

  useEffect(() => {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      set_project_language(response.data.main_language);
    });
  }, []);

  const _setActiveStep = (id) => {
    set_active_step(_.find(steps, (item) => item.id === id));
  };

  const _handleCopySpecificationsSubmit = (formData) => {
    return validateForm(
      validateCopySpecificationDetails(formData).then(() => {
        _setActiveStep('02');
      })
    );
  };

  const _handleLinkedSpecificationsSubmit = (formData) => {
    formData = {
      specification_ids: props.store.copy_specifications_wizard_active,
      project_id: props.specification_details.project_id,
      stage_id: props.specification_details.stage_id,
      copy_specifications_option:
        props.specification_details.copy_specifications_option,
      team_id: props.specification_details.team_id,
      copy_linked_specifications_option:
        formData.copy_linked_specifications_option,
    };

    return validateForm(
      copySpecifications(formData).then(() => {
        props.onClose();

        setNotification(
          __(
            'specifications.copy-specification.successfully-copied-specifications'
          )
        );
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.titleBar}>
          <div className={styles.left}>
            <span>
              {__('specifications.working-set.copy-wizard.copy-specifications')}
            </span>
            <span>{active.title}</span>
          </div>
          <div className={styles.right}>
            <span>{active.id}</span>
            <span>{_.last(steps).id}</span>
          </div>
        </div>
        <div className={styles.steps}>
          {_.map(steps, (step, i) => (
            <div
              key={i}
              className={classNames(
                styles.step,
                active.id == step.id && styles.active,
                step.id < active.id && styles.completed
              )}
            >
              <span>{step.id}</span>
              <span>{step.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.body}>
        {active.id === '01' && (
          <CopySpecificationsForm
            onSubmit={_handleCopySpecificationsSubmit}
            projectLanguage={project_language}
            initialValues={{
              project_id: getActiveProject(),
              copy_specifications_option: 1,
            }}
          />
        )}
        {active.id === '02' && (
          <CopyLinkedSpecificationsForm
            onBack={() => _setActiveStep('01')}
            onSubmit={_handleLinkedSpecificationsSubmit}
            initialValues={{
              copy_linked_specifications_option: 0,
              selected_same_stage:
                getActiveStage() == specification_details?.stage_id,
            }}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    store: state.table.working_set_specifications,
    specification_details: getFormValues(
      'copy_specifications_wizard.copy_specification_details'
    )(state),
  };
}

export default subscribe(
  Localization,
  'localization'
)(connect(mapStateToProps)(CopySpecificationsWizard));
