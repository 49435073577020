import * as React from 'react';
import {
  __,
  getActiveStage,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import {
  addBOQCalculation,
  getBOQCalculation,
  readWorkingSetBOQ,
  updateBOQCalculationFromTemplate,
} from '../redux/actions/table/working_set_boq';
import BOQIcon from '../assets/images/boq-24x24.svg';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import AddBOQCalculationFromTemplateForm from '../forms/add_boq_calculation_from_template/AddBOQCalculationFromTemplateForm';
import styles from '../styles/wizards/AddBOQCalculationFromTemplateWizard.scss';

const AddBOQCalculationFromTemplateWizard = ({ flyout, onClose, state }) => {
  const _handleSubmit = (formData) => {
    let action;

    if (_.isBoolean(state.add_boq_calculation_from_template_wizard)) {
      action = addBOQCalculation({
        boq_item_id: state.boq_item_id,
        stage_id: getActiveStage(),
        template_id: formData.boq_template_calculation_id,
      });
    } else {
      const { id } = flyout;

      action = updateBOQCalculationFromTemplate(
        id,
        formData.boq_template_calculation_id
      ).then(() =>
        getBOQCalculation(id).then(({ response }) => {
          setTableParams('working_set_boq', {
            flyout: response.data,
          });
        })
      );
    }

    return validateForm(
      action.then(() => {
        readWorkingSetBOQ(getActiveStage());

        setNotification('The boq calculation has been loaded.');

        onClose();
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__(
              'boq-calculation.flyout.add-boq-calculation-from-template.title'
            )}
          </span>
          <span>
            {__(
              'boq-calculation.flyout.add-boq-calculation-from-template.subtitle'
            )}
          </span>
        </div>
        <div className={styles.right}>
          <BOQIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddBOQCalculationFromTemplateForm
          onSubmit={_handleSubmit}
          onClose={onClose}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    flyout: state.table.working_set_boq.flyout,
    state: state.table.working_set_boq,
  };
};

export default connect(
  mapStateToProps,
  null
)(subscribe(Localization, 'localization')(AddBOQCalculationFromTemplateWizard));
