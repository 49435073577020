import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_specification_to_calculation_wizard/AddSpecificationToCalculationForm.scss';
import Button from '../../components/Button';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import MultiSelect from '../../components/MultiSelect';
import { listPossibleProductParts } from '../../redux/actions/table/products';
import * as _ from 'lodash';
import Input from '../../components/Input';
import RadioGroup from '../../components/RadioGroup';
import Select from '../../components/Select';
import { listPossibleSpecificationCodes } from '../../redux/actions/table/working_set_boq';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'add_specification_to_calculation_wizard.add_specification_to_calculation',
})
@mapStateToProps((state) => ({
  application_language_id: state.auth.language_id,
  store: state.table['working_set_boq'],
}))
class AddSpecificationToCalculationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_specification_codes: [],
    };
  }

  componentDidMount() {
    listPossibleSpecificationCodes(getActiveStage()).then(({ response }) => {
      this.setState({ possible_specification_codes: response.data });
    });
  }

  render() {
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const possible_specification_codes = _.map(
      this.state.possible_specification_codes,
      (specification) => {
        const specification_subgroup =
          _.get(specification_subgroups, [
            specification.specification_subgroup_id,
          ]) || {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const project_language_id = _.findKey(
          this.props.store.project_languages,
          (primary) => primary == true
        );

        const code =
          getLocalized(specification_group.code, project_language_id) +
          specification_subgroup.code +
          _.padStart(specification.code, 2, '0');
        const title = _.filter(
          [
            getLocalized(
              specification.description,
              this.props.application_language_id
            ),
            specification.model,
            specification.brand,
          ],
          _.identity
        ).join(' / ');

        return {
          value: specification.id,
          label: _.filter([code, title], _.identity).join(': '),
        };
      }
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='specification_code_id'
            component={Select}
            searchable
            label='Specification'
            options={possible_specification_codes}
            placeholder={__(
              'working-set-boq.calculation.add-specification.select_existing_specification'
            )}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_boq', {
                add_specification_to_calculation_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.add')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default AddSpecificationToCalculationForm;
