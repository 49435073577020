import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import Textarea from '../../components/Textarea';
import { __, mapStateToProps } from '../../core/utils';
import styles from '../../styles/forms/plans/EditCurrentSetPlanForm.scss';
import Input from '../../components/Input';
import Image from '../../components/Image';
import Select from '../../components/Select';
import Tags from '../../components/Tags';

@reduxForm({
  form: 'plans.edit_current_set_plan',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  auth: state.auth,
}))
class EditCurrentSetPlanForm extends Component {
  render() {
    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className={styles.imageSection}>
          <div className={styles.image}>
            <Image src={this.props.previewUrl} />
          </div>
          <div className={styles.data}>
            <Field
              name='status'
              disabled={true}
              component={Select}
              noSort
              label={__('Status')}
              options={[
                {
                  label: __('Canceled'),
                  value: '0',
                },
                {
                  label: __('Active'),
                  value: '1',
                },
              ]}
            />
            <Field
              name='scale'
              component={Input}
              label={__('Scale')}
              disabled={true}
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='code'
                disabled={true}
                component={Input}
                label={__('Code')}
              />
            </div>
            <div className={styles.f2}>
              <Field
                name='title'
                disabled={true}
                component={Input}
                label={__('Title')}
              />
            </div>
          </div>
          <Field
            name='description'
            disabled={true}
            component={Textarea}
            label={__('Description')}
            maxLength={70}
            rows={1}
            maxRows={10}
          />
        </div>
        <div className={styles.tagsSection}>
          <Field
            disabled={true}
            name='tags'
            component={Tags}
            label={__('Tags')}
          />
          <Field
            disabled={true}
            name='space_allocation'
            component={Tags}
            label={__('general.label.space-allocation')}
          />
        </div>
      </Form>
    );
  }
}

export default EditCurrentSetPlanForm;
