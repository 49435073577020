import idToKey from "id-to-key";

let initialState = {};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "localization/LIST_LOCALIZATION_SUCCESS":
			return {
				...state,
				..._.transform(action.response.data, (localization, list, key) => {
					return localization[key] = key == 'translations' ? list : idToKey(list);
				}, {}),
			};
		case "localization/LIST_TEXTURES_SUCCESS":
			return {
				...state,
				textures: idToKey(action.response.data),
			};
		default:
			return state;
	}
}
