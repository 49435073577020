import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/profile/NotificationsSettingsForm.scss';
import connect from 'react-redux/es/connect/connect';
import autobind from 'autobind-decorator';
import UserMaleIcon from '../../assets/images/user-male-15x15.svg';
import classNames from 'classnames';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import ProjectIcon from '../../assets/images/project-15x15.svg';
import PlanSetIcon from '../../assets/images/plan-set-15x15.svg';
import MeetingIcon from '../../assets/images/meeting-16x16.svg';
import TasksIcon from '../../assets/images/tasks-16x16.svg';
import TendersIcon from '../../assets/images/tender-24x24.svg';
import { enableAutosave } from '../../redux/actions/general/autosave';
import CompanyIcon from '../../assets/images/company-16x16.svg';
import SpecificationsIcon from '../../assets/images/specifications-16x16.svg';
import {
  generateSelectArrayOfTimes,
  sortValuesByArray,
  __,
} from '../../core/utils';
import Checkbox from '../../components/Checkbox';
import Select from '../../components/Select';
import { generateDigestHours } from '../../lists/digest_hours';

@reduxForm({
  form: 'profile.notifications_settings',
  enableReinitialize: true,
})
@connect((state) => ({
  values: getFormValues('profile.notifications_settings')(state),
  state: state,
}))
class NotificationsSettingsForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _toggleAlertNotificationAction(name, select) {
    const { actions } = this.props;

    if (!_.get(_.find(actions, ['name', name]), 'alert.disabled')) {
      this.props.touch('alert_notifications.' + name);
      this.props.change('alert_notifications.' + name, select);
      this.props.blur('alert_notifications.' + name);
    }
  }

  @autobind
  _toggleEmailNotificationAction(name, select) {
    const { actions } = this.props;

    if (!_.get(_.find(actions, ['name', name]), 'email.disabled')) {
      this.props.touch('email_notifications.' + name);
      this.props.change('email_notifications.' + name, select);
      this.props.blur('email_notifications.' + name);
    }
  }

  @autobind
  _toggleDigestNotificationAction(name, select) {
    const { actions } = this.props;

    if (!_.get(_.find(actions, ['name', name]), 'digest.disabled')) {
      this.props.touch('digest_notifications.' + name);
      this.props.change('digest_notifications.' + name, select);
      this.props.blur('digest_notifications.' + name);
    }
  }

  @autobind
  _selectAllAlertGroupActions(name, select) {
    const { actions } = this.props;

    this.props.touch('alert_notifications');
    _.each(
      _.filter(
        actions,
        (action) => action.group == name && action.alert.disabled == false
      ),
      (action) => {
        this.props.change('alert_notifications.' + action.name, select);
      }
    );
    this.props.blur('alert_notifications');
  }

  @autobind
  _selectAllEmailGroupActions(name, select) {
    const { actions } = this.props;

    this.props.touch('email_notifications');
    _.each(
      _.filter(
        actions,
        (action) => action.group == name && action.email.disabled == false
      ),
      (action) => {
        this.props.change('email_notifications.' + action.name, select);
      }
    );
    this.props.blur('email_notifications');
  }

  @autobind
  _selectAllAlertActions(select) {
    const { actions } = this.props;

    this.props.touch('alert_notifications');
    _.each(
      _.filter(actions, (action) => action.alert.disabled == false),
      (action) => {
        this.props.change('alert_notifications.' + action.name, select);
      }
    );
    this.props.blur('alert_notifications');
  }

  @autobind
  _selectAllEmailActions(select) {
    const { actions } = this.props;

    this.props.touch('email_notifications');
    _.each(
      _.filter(actions, (action) => action.email.disabled == false),
      (action) => {
        this.props.change('email_notifications.' + action.name, select);
      }
    );
    this.props.blur('email_notifications');
  }

  render() {
    const { alert_notifications, email_notifications, digest_notifications } =
      this.props.values;
    const { actions } = this.props;

    const groups = [
      {
        name: 'profile',
        icon: <UserMaleIcon className={styles.profileIcon} />,
      },
      {
        name: 'company',
        icon: <CompanyIcon className={styles.companyIcon} />,
      },
      {
        name: 'projects',
        icon: <ProjectIcon className={styles.projectIcon} />,
      },
      {
        name: 'meetings',
        icon: <MeetingIcon className={styles.meetingIcon} />,
      },
      {
        name: 'tasks',
        icon: <TasksIcon className={styles.tasksIcon} />,
      },
      {
        name: 'tenders',
        icon: <TendersIcon className={styles.tendersIcon} />,
      },
      {
        name: 'plans',
        icon: <PlanSetIcon className={styles.plansIcon} />,
      },
      {
        name: 'specifications',
        icon: <SpecificationsIcon className={styles.specificationsIcon} />,
      },
    ];

    const available_groups = _.reject(groups, (group) =>
      _.isUndefined(_.find(actions, (action) => action.group == group.name))
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.heading}>
          <span className={styles.type}>
            {__('notifications.columns.type')}
          </span>
          <span>{__('notifications.columns.alert')}</span>
          <span>{__('notifications.columns.email')}</span>
          <span>{__('notifications.columns.digest')}</span>
        </div>
        <div className={styles.content}>
          {_.map(available_groups, (group, i) => {
            return (
              <div className={styles.group} key={i}>
                <div className={styles.groupHeading}>
                  <div className={styles.name}>
                    <span className={styles.icon}>{group.icon}</span>
                    {__('notifications.settings.groups.' + group.name)}
                  </div>
                </div>
                {_.map(
                  _.filter(actions, ['group', group.name]),
                  (action, i) => (
                    <div className={styles.action} key={i}>
                      <div className={styles.name}>
                        <span>
                          {__('notifications.settings.actions.' + action.name)}
                        </span>
                        <span className={styles.description}>
                          {__(
                            'notifications.settings.actions.description-' +
                              action.name
                          )}
                        </span>
                      </div>
                      <span>
                        {_.get(alert_notifications, action.name) ? (
                          <CheckedIcon
                            className={classNames(
                              styles.checked,
                              action.alert.disabled && styles.checkedDisabled
                            )}
                            onClick={() =>
                              this._toggleAlertNotificationAction(
                                action.name,
                                false
                              )
                            }
                          />
                        ) : (
                          <UncheckedIcon
                            className={classNames(
                              styles.unchecked,
                              action.alert.disabled && styles.disabled
                            )}
                            onClick={() =>
                              this._toggleAlertNotificationAction(
                                action.name,
                                true
                              )
                            }
                          />
                        )}
                      </span>
                      <span>
                        {_.get(email_notifications, action.name) ? (
                          <CheckedIcon
                            className={classNames(
                              styles.checked,
                              action.email.disabled && styles.checkedDisabled
                            )}
                            onClick={() =>
                              this._toggleEmailNotificationAction(
                                action.name,
                                false
                              )
                            }
                          />
                        ) : (
                          <UncheckedIcon
                            className={classNames(
                              styles.unchecked,
                              action.email.disabled && styles.disabled
                            )}
                            onClick={() =>
                              this._toggleEmailNotificationAction(
                                action.name,
                                true
                              )
                            }
                          />
                        )}
                      </span>
                      <span>
                        {_.get(digest_notifications, action.name) ? (
                          <CheckedIcon
                            className={classNames(
                              styles.checked,
                              action.digest.disabled && styles.checkedDisabled
                            )}
                            onClick={() =>
                              this._toggleDigestNotificationAction(
                                action.name,
                                false
                              )
                            }
                          />
                        ) : (
                          <UncheckedIcon
                            className={classNames(
                              styles.unchecked,
                              action.digest.disabled && styles.disabled
                            )}
                            onClick={() =>
                              this._toggleDigestNotificationAction(
                                action.name,
                                true
                              )
                            }
                          />
                        )}
                      </span>
                    </div>
                  )
                )}
              </div>
            );
          })}
        </div>

        <div className={classNames(styles.f1, styles.flex, styles.digestTime)}>
          <div className={styles.f1}>
            <Field
              name='digest_time'
              component={Select}
              noSort
              label={__('notifications.settings.digest_time')}
              options={generateDigestHours()}
            />
          </div>

          <div className={styles.f6} />
        </div>
      </Form>
    );
  }
}

export default NotificationsSettingsForm;
