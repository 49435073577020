import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_building_wizard/AddBuildingForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams, transformToListPairs} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";
import {listPossibleSites} from "../../redux/actions/table/buildings";
import Select from "../../components/Select";

@withRouter
@reduxForm({form: "add_building_wizard.add_building"})
class AddBuildingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_sites: [],
        }
    }

    componentDidMount() {
        listPossibleSites(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_sites: response.data});
        });
    }

    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const {possible_sites} = this.state;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="site_id" component={Select} label={__('buildings.field.site')} searchable options={transformToListPairs(possible_sites, 'title')} />
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="code" component={Input} label={__('buildings.field.code')} maxLength={6} />
                        </div>
                        <div className={styles.f4}>
                            <Field name="title" component={Input} localized={this.props.projectLanguages} label={__('buildings.field.title')} maxLength={50} />
                        </div>
                    </div>
                    <Field name="description" component={Textarea} localized={this.props.projectLanguages} label={__('buildings.field.description')} maxLength={2000} rows={1} maxRows={10} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('buildings', {
                        add_building_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddBuildingForm;
