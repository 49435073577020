import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/zones/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import { listZones, updateZone } from '../../redux/actions/table/zones';
import EditZoneForm from '../../forms/zones/EditZoneForm';

@withRouter
class Properties extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateZone(formData.id, _.omit(formData, ['id'])).then(() => {
        listZones(this.props.match.params.stage);

        this.props.readAction(formData.id);

        setNotification(__('zones.flyout.notification.saved'));
      })
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.scroller}>
        <EditZoneForm
          onSubmit={this._handleSubmit}
          data={data}
          initialValues={{
            ..._.pick(data, [
              'id',
              'functional_spaces',
              'title',
              'description',
              'volume',
              'area',
              'requirement',
            ]),
          }}
        />
      </div>
    );
  }
}

export default Properties;
