import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/EditSpecificationProductAttributeGroupWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeGroupEditIcon from "../assets/images/product-attribute-group-edit-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {
	readWorkingSetSpecification,
	updateSpecificationProductAttributesGroup
} from "../redux/actions/table/working_set_specifications";
import EditSpecificationProductAttributeGroupForm
	from "../forms/edit_specification_product_attribute_group_form/EditSpecificationProductAttributeGroupForm";

@mapStateToProps(state => ({
	flyout: _.get(state, 'table.working_set_specifications.flyout'),
	group_id: state.table.working_set_specifications.edit_specification_product_attribute_group_wizard,
}))
class EditSpecificationProductAttributeGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
			updateSpecificationProductAttributesGroup(this.props.group_id, formData).then(() => readWorkingSetSpecification(this.props.flyout.id).then(({response}) => {
				setNotification(__('notification.specification-attribute-group-updated'));

				setTableParams('working_set_specifications', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('specifications.working-set.flyout.edit-group.edit-specification-attribute-group')}</span>
						<span>{__('specifications.working-set.flyout.edit-group.edit-specification-attribute-group')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeGroupEditIcon />
					</div>
				</div>
				<div className={styles.body}>
					<EditSpecificationProductAttributeGroupForm onSubmit={this._handleSubmit} initialValues={{
						name: _.get(_.find(this.props.flyout.attribute_groups, ['id', this.props.group_id]), 'name')
					}} />
				</div>
			</>
		);
	}
}

export default EditSpecificationProductAttributeGroupWizard;
