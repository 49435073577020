import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddRelatedTaskWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import AddRelatedTaskIcon from "../assets/images/add-related-task-64x64.svg";
import {withRouter} from "react-router";
import AddRelatedTaskForm from "../forms/add_related_task_form/AddRelatedTaskForm";
import {
    addRelatedTasks,
    readTask
} from "../redux/actions/table/all_tasks";
import {setNotification} from "../redux/actions/general/notification";

@mapStateToProps(state => ({
    store: _.get(state.table, 'all_tasks')
}))
@withRouter
class AddRelatedTaskWizard extends Component {
    @autobind
    _handleSubmit(formData) {
       
        return validateForm(
            addRelatedTasks(this.props.store.add_related_task_wizard, formData).then(() => readTask(this.props.store.add_related_task_wizard).then(() => {
                if(this.props.match.path == "/meetings/:meeting") {
                    setTableParams('all_tasks', {
                        clicked_row: this.props.store.add_related_task_wizard,
                    });
                }

                setNotification(__("wizard.tasks.all-tasks.flyout.related-tasks.subtitle.related-task-added"));

                this.props.onClose();
            }))
        )
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.tasks.all-tasks.flyout.related-tasks.title.add-related-task')}</span>
                        <span>{__('wizard.tasks.all-tasks.flyout.related-tasks.subtitle.add-related-task')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddRelatedTaskIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddRelatedTaskForm onSubmit={this._handleSubmit} initialValues={{
                        task_ids: [],
                    }} />
                </div>
            </>
        );
    }
}

export default AddRelatedTaskWizard;
