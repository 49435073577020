import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/CompanyPartnersWizard.scss";
import autobind from "autobind-decorator";
import AddCompanyIcon from "../assets/images/add-company-60x60.svg";
import AddCompanyPartnersForm from "../forms/company_partners_wizard/AddCompanyPartnersForm";
import {__, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {addCompaniesToPartners} from "../redux/actions/table/all_companies";
import {listPartnerCompanies} from "../redux/actions/table/partner_companies";

class CompanyPartnersWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
			addCompaniesToPartners(_.get(formData, "companies")).then(() => {
				listPartnerCompanies();

				setNotification(__("wizard.company-partners.notification.success"));

				this.props.onClose();
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.company-partners.title.add-partner-companies')}</span>
						<span>{__('wizard.company-partners.subtitle.add-partner-companies')}</span>
					</div>
					<div className={styles.right}>
						<AddCompanyIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddCompanyPartnersForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default CompanyPartnersWizard;
