import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/unregistered_meeting_member_wizard/UnregisteredMeetingMemberForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, mapStateToProps, setTableParams} from "../../core/utils";
import ButtonGroup from "../../components/ButtonGroup";
import {
    deleteUnregisteredMeetingFollower,
    deleteUnregisteredMeetingParticipant
} from "../../redux/actions/table/all_meetings";
import {setNotification} from "../../redux/actions/general/notification";
import autobind from "autobind-decorator";

@reduxForm({
    form: "unregistered_meeting_member_wizard.unregistered_meeting_member",
})
@mapStateToProps(state => ({
    edit_data: _.get(state.table, 'all_meetings.edit_data'),
    type: _.get(state.table, 'all_meetings.type'),
    disabled: _.get(state.table, 'all_meetings.disabled')
}))
class UnregisteredMeetingMemberForm extends Component {
    @autobind
    _deleteMember() {
        const {edit_data, type} = this.props;

        if(type == 'participant') {
            return deleteUnregisteredMeetingParticipant(edit_data.id).then(() => {
                setNotification(__('meetings.all-meetings.flyout.notification.participant-deleted-from-meeting'));

                this.props.onClose();
            });
        }

        if(type == 'follower') {
            return deleteUnregisteredMeetingFollower(edit_data.id).then(() => {
                setNotification(__('meetings.all-meetings.flyout.notification.follower-deleted-from-meeting'));

                this.props.onClose();
            });
        }
    }

    render() {
        const {edit_data, disabled} = this.props;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <Field name="fullname" component={Input} label={__('meetings.all-meetings.flyout.unregistered-member.name')} />
                <Field name="profession" component={Input} label={__('meetings.all-meetings.flyout.unregistered-member.profession')} />
                <Field name="company" component={Input} label={__('meetings.all-meetings.flyout.unregistered-member.company')} />
                <Field name="email" component={Input} label={__('meetings.all-meetings.flyout.unregistered-member.email')} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_meetings', {
                        wizard_unregistered_meeting_member: undefined
                    })} />
                    {edit_data ? (
                        !disabled ? <ButtonGroup>
                            <Button gray medium middleText={__('button.delete')} onClick={this._deleteMember} spinning={this.props.submitting} />
                            <Button lightBlue medium submit middleText={__('button.save')} spinning={this.props.submitting} />
                        </ButtonGroup> : ''
                    ) : (
                        <Button lightBlue medium submit right middleText={__('button.add')} spinning={this.props.submitting} />
                    )}
                </div>
            </Form>
        );
    }
}

export default UnregisteredMeetingMemberForm;
