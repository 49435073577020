import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import Textarea from '../../components/Textarea';
import { __ } from '../../core/utils';
import { enableAutosave } from '../../redux/actions/general/autosave';
import Input from '../../components/Input';
import { withRouter } from 'react-router';
import styles from '../../styles/forms/room_types/EditRoomTypeForm.scss';

@reduxForm({
  form: 'room_types.edit_room_type',
  enableReinitialize: true,
})
@withRouter
class EditRoomTypeForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  render() {
    const { handleSubmit, data } = this.props;
    const { project_languages } = data;

    return (
      <Form onSubmit={handleSubmit}>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='code'
              component={Input}
              label={__('room-types.field.code')}
              maxLength={6}
            />
          </div>
          <div className={styles.f2}>
            <Field
              name='title'
              component={Input}
              localized={project_languages}
              label={__('room-types.field.title')}
              maxLength={50}
            />
          </div>
        </div>
        <Field
          name='description'
          component={Textarea}
          localized={project_languages}
          label={__('room-types.field.description')}
          maxLength={2000}
          rows={1}
          maxRows={10}
        />
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='requirement'
              component={Textarea}
              label={__('room-types.field.requirement')}
              maxLength={2000}
              rows={3}
              maxRows={10}
              localized={project_languages}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default EditRoomTypeForm;
