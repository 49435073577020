import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddRoomWizard.scss";
import autobind from "autobind-decorator";
import DuplicateRoomIcon from "../assets/images/duplicate-room-64x64.svg";
import {__, mapStateToProps, validateForm} from "../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../redux/actions/general/notification";
import {duplicateRoom, listRooms} from "../redux/actions/table/rooms";
import DuplicateRoomForm from "../forms/duplicate_room_wizard/DuplicateRoomForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["rooms"],
}))
class DuplicateRoomWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const room_id = this.props.table.duplicate;

		return validateForm(
			duplicateRoom(room_id, formData.copies).then(() => {
				listRooms(this.props.match.params.stage);

				this.props.onClose();

				setNotification(__("wizard.duplicate-room.message.success"));
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.duplicate-room.title.duplicate-room')}</span>
						<span>{__('wizard.duplicate-room.subtitle.duplicate-room')}</span>
					</div>
					<div className={styles.right}>
						<DuplicateRoomIcon />
					</div>
				</div>
				<div className={styles.body}>
					<DuplicateRoomForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default DuplicateRoomWizard;