import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import styles from "../../styles/forms/current_set_specifications/TagsForm.scss";
import { mapStateToProps, resetForm, __ } from "../../core/utils";
import classNames from "classnames";
import Tags from "../../components/Tags";
import _ from "lodash";

@reduxForm({
	form: "current_set_specifications.tags",
	enableReinitialize: true
})
@mapStateToProps((state) => ({
	store: state.table.current_set_specifications
}))
class TagsForm extends Component {
	componentDidUpdate(prevProps) {
		this.props.change("tags", this.props.store.flyout.tags);
	}

	render() {
		const { handleSubmit, collapsed, store } = this.props;

		return (
			<Form onSubmit={handleSubmit} className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
				<Field key={store.flyout.id} name="tags" component={Tags} label={__("current-set-specifications.tags-form.tags")} disabled />
			</Form>
		);
	}
}

export default TagsForm;
