import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/plan_uploads/Files.scss';
import { withRouter } from 'react-router';
import { __, dateTimeFrom, scrollToBottom } from '../../core/utils';
import DocumentExtIcon from '../../assets/images/document-ext-30x30.svg';
import DownloadIcon from '../../assets/images/download-15x15.svg';
import * as _ from 'lodash';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames';
import UploadIcon from '../../assets/images/upload-40x40.svg';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import { uploadPlanFile } from '../../redux/actions/table/plan_uploads';
import { showLightbox } from '../../redux/actions/general/lightbox';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { deletePlanFile } from '../../redux/actions/table/working_set_plans';
import FilePreview from '../../components/FilePreview';

@withRouter
class Files extends Component {
  componentDidMount() {
    scrollToBottom(this.refs.scroll);
  }

  @autobind
  _handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({ drag: false });

    const { data } = this.props;

    if (!_.isEmpty(acceptedFiles)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('plan-uploads.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('plan-uploads.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (_.first(acceptedFiles).size >= 104857600) {
        setNotification({
          text: __('plan-uploads.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        setNotification(__('plan-uploads.files.upload.uploading'));

        uploadPlanFile(data.id, acceptedFiles).then(() => {
          setNotification(__('plan-uploads.files.upload.uploaded'));

          this.props.readAction(data.id).then(() => {
            scrollToBottom(this.refs.scroll);
          });
        });
      }
    }
  }

  @autobind
  _downloadFile(row, download = false) {
    if (row.ext == 'pdf' && download == false) {
      window.open(row.stream_url, '_blank');
    } else {
      setNotification(__('plan-uploads.files.download.continue'));

      window.open(row.download_url, '_blank');
    }
  }

  render() {
    const { data } = this.props;

    const lightbox_images = _.map(
      _.filter(data.files, (file) =>
        _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
      ),
      (file) => ({
        id: file.id,
        stream_url: file.stream_url,
        title: [file.name, file.ext].join('.'),
      })
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.scroller} ref='scroll'>
          {_.map(data.files, (row) => (
            <div className={styles.row} key={row.id}>
              <div className={styles.left}>
                <FilePreview
                  lightbox_images={lightbox_images}
                  row={row}
                  onDownloadFile={this._downloadFile}
                />
              </div>
              <div className={styles.right}>
                {row.created_by} · {dateTimeFrom(row.created_at)}{' '}
                <DownloadIcon onClick={() => this._downloadFile(row, true)} />
                {_.get(data, 'is_editor') && (
                  <DeleteIcon
                    onClick={() => {
                      showAlertbox({
                        color: 'red',
                        title: __('general.alert.are-you-sure'),
                        description: __(
                          'files.general.alert.want-delete-file-attachment-plan.process-cannot-undone'
                        ),
                        buttons: [
                          {
                            color: 'lightGray',
                            text: __('general.alert.no-close'),
                            onClick: 'close',
                          },
                          {
                            color: 'gray',
                            text: __('general.alert.yes-delete'),
                            onClick: () => {
                              deletePlanFile(data.id, row.id).then(() => {
                                hideAlertbox();

                                setNotification(
                                  __(
                                    'files.notification.selected-file-attachment-deleted-plan'
                                  )
                                );

                                this.props.readAction(data.id).then(() => {
                                  scrollToBottom(this.refs.scroll);
                                });
                              });
                            },
                          },
                        ],
                      });
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        {_.get(data, 'is_editor') && (
          <ReactDropzone
            className={classNames(
              styles.dropzone,
              _.get(this.state, 'drag') && styles.drag
            )}
            onDrop={this._handleDrop}
            onDragOver={() => this.setState({ drag: true })}
            onDragLeave={() => this.setState({ drag: false })}
            multiple={true}
          >
            <UploadIcon />
            <span className={styles.dropTitle}>
              {__('dropzone.title.drop-your-file-here')}
            </span>
            <span className={styles.dropSubtitle}>
              {__('dropzone.title.or-select')}
            </span>
          </ReactDropzone>
        )}
      </div>
    );
  }
}

export default Files;
