import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import {__, getURLParam, mapStateToProps, setTableParams} from "../../core/utils";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import {setPlusActions} from "../../redux/actions/general/plus";
import TableNA from "../../components/TableNA";
import classNames from "classnames";
import styles from "../../styles/views/companies_and_users/AddressBookContacts.scss";
import {listAddressBookContacts, readAddressBookContact} from "../../redux/actions/table/address_book_contacts";
import AddressBookContactsFlyout from "../../flyouts/address_book_contacts/AddressBookContactsFlyout";

@mapStateToProps(state => ({
	table: state.table["address_book_contacts"],
	auth: state.auth
}))
class AddressBookContacts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();

		createSidebar({
			title: __('companies-and-users.address-book-contacts.companies-users'),
			items: [
				{
					title: __('companies-and-users.address-book-contacts.companies'),
					link: () => "/companies",
					items: [
						{
							title: __('companies-and-users.address-book-contacts.all-companies'),
							link: () => "/companies",
						},
						{
							title: __('companies-and-users.address-book-contacts.partner-companies'),
							link: () => "/companies/partner",
						},
					]
				}, {
					title: __('companies-and-users.address-book-contacts.users'),
					link: () => "/companies/my/members",
					items: [
						{
							title: __('companies-and-users.address-book-contacts.partner-members'),
							link: () => "/companies/partner_members"
						},
						{
							title: __('companies-and-users.address-book-contacts.my-company-members'),
							link: () => "/companies/my/members",
						}
					]
				}, {
					title: __('modules.address-book'),
					link: () => "/address_book/companies",
					active: true,
					items: [
						{
							title: __('modules.address-book.companies'),
							link: () => "/address_book/companies",
						},
						{
							title: __('modules.address-book.contacts'),
							link: () => "/address_book/contacts",
							active: true,
						},
					]
				}
			]
		});

		setPlusActions([{
			title: __('button.add-new-contact'),
			onClick: () => setTableParams('address_book_contacts', {
				wizard_active: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readAddressBookContact(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const columns = [
			{
				key: "title",
				name: __('companies-and-users.address-book.contacts.title'),
				width: 80,
			}, {
				key: "firstname",
				name: __('companies-and-users.address-book.contacts.first-name'),
				width: 200,
				required: true,
				value: firstname => <div className={styles.ellipsis}><Tooltip text={firstname}>{firstname || <TableNA />}</Tooltip></div>,
			}, {
				key: "lastname",
				name: __('companies-and-users.address-book.contacts.last-name'),
				width: 200,
				required: true,
				value: lastname => <div className={styles.ellipsis}><Tooltip text={lastname}>{lastname || <TableNA />}</Tooltip></div>,
			}, {
				key: "company",
				name: __('companies-and-users.address-book.contacts.company'),
				width: 200,
				value: company => <div className={styles.ellipsis}><Tooltip text={company}>{company || <TableNA />}</Tooltip></div>,
			}, {
				key: "emails",
				name: __('companies-and-users.address-book.contacts.emails'),
				sortable: false,
				value: emails => _.isEmpty(emails) ? <TableNA /> : <div className={styles.ellipsis}><Tooltip text={emails.join("\n")}>{emails.join(', ')}</Tooltip></div>,
				width: 180,
			}, {
				key: "phones",
				name: __('companies-and-users.address-book.contacts.phones'),
				sortable: false,
				value: phones => _.isEmpty(phones) ? <TableNA /> : <div className={styles.ellipsis}><Tooltip text={phones.join("\n")}>{phones.join(', ')}</Tooltip></div>,
				width: 100,
			}, {
				key: "note",
				name: __('companies-and-users.address-book.contacts.note'),
				value: note => <div className={styles.ellipsis}><Tooltip text={note}>{note || <TableNA />}</Tooltip></div>,
			}, {
				key: "is_designer",
				name: __('companies-and-users.address-book.contacts.designer'),
				value: is_designer => <div className={classNames(styles.chip, is_designer && styles.yes)}>{is_designer ? __('companies-and-users.address-book.contacts.designer.yes') : __('companies-and-users.address-book.contacts.designer.no')}</div>,
				width: 50,
			}, {
				key: "is_client",
				name: __('companies-and-users.address-book.contacts.client'),
				value: is_client => <div className={classNames(styles.chip, is_client && styles.yes)}>{is_client ? __('companies-and-users.address-book.contacts.client.yes') : __('companies-and-users.address-book.contacts.client.no')}</div>,
				width: 50,
			}
		];

		const preview = (
			<AddressBookContactsFlyout
				data={this.state.preview}
				readAction={address_book_contact_id => {
					return new Promise(resolve => {
						readAddressBookContact(getURLParam('id') || address_book_contact_id).then(({response}) => {
							this.setState({preview: response.data});

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listAddressBookContacts}
				name="address_book_contacts"
				title={__('modules.address-book.contacts')}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default AddressBookContacts;
