import store from "../../../../core/store";

export const listNotes = () => {
	return store.dispatch({
		action: "table.superadmin_notes/LIST_NOTES",
		method: "GET",
		endpoint: "/superadmin/notes",
		main: true
	});
};

export const createNote = (formData) => {
	return store.dispatch({
		action: "table.superadmin_notes/CREATE_NOTE",
		method: "POST",
		endpoint: "/superadmin/notes",
		data: formData
	});
};

export const deleteNotes = (note_ids) => {
	return store.dispatch({
		action: "table.superadmin_notes/DELETE_NOTES",
		method: "DELETE",
		endpoint: "/superadmin/notes",
		data: {
			notes: note_ids
		}
	});
};

export const readNote = (note_id) => {
	return store.dispatch({
		action: "table.superadmin_notes/READ_NOTE",
		method: "GET",
		endpoint: "/superadmin/notes/" + note_id,
		has404: true
	});
};

export const updateNote = (note_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_notes/UPDATE_NOTE",
		method: "PUT",
		endpoint: "/superadmin/notes/" + note_id,
		data: formData
	});
};