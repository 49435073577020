import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/superadmin/product_subgroups/Properties.scss";
import * as _ from "lodash";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../../core/utils";
import { setNotification } from "../../../redux/actions/general/notification";
import { listProductSubgroups, updateProductSubgroup } from "../../../redux/actions/table/superadmin/product_subgroups";
import EditProductSubgroupForm from "../../../forms/superadmin/product_subgroups/EditProductSubgroupForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateProductSubgroup(formData.id, _.omit(formData, ['id'])).then(() => {
                listProductSubgroups();

                this.props.readAction(formData.id);

                setNotification(__('properties.notifications.properties-have-been-saved'));
            })
        );
    }

    render() {
        const { data } = this.props;

        return (
            <div className={styles.scroller}>
                <EditProductSubgroupForm
                    onSubmit={this._handleSubmit}
                    initialValues={{
                        ..._.pick(data, ['id', 'name', 'priority', 'product_group_id', 'specification_subgroups', 'product_attribute_template_field_ids']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
