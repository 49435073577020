import store from "../../../core/store";
import {change} from "redux-form";

export const registerMultieditField = (form, field) => {
    return store.dispatch({
        type: "general.multiedit/REGISTER_MULTIEDIT_FIELD",
        form,
        field
    });
};

export const unregisterMultieditField = (form, field) => {
    store.dispatch(change(form, field, ''));

    return store.dispatch({
        type: "general.multiedit/UNREGISTER_MULTIEDIT_FIELD",
        form,
        field
    });
};

export const toggleMultieditClearAll = (form, field) => {
    return store.dispatch({
        type: "general.multiedit/TOGGLE_MULTIEDIT_CLEAR_ALL",
        form,
        field
    });
};

export const enableMultiedit = (form) => {
    return store.dispatch({
        type: "general.multiedit/ENABLE_MULTIEDIT",
        form,
    });
};

export const disableMultiedit = (form) => {
    return store.dispatch({
        type: "general.multiedit/DISABLE_MULTIEDIT",
        form,
    });
};