import store from "../../../core/store";

export const listAllCompanies = () => {
	return store.dispatch({
		action: "table.all_companies/LIST_ALL_COMPANIES",
		method: "GET",
		endpoint: "/companies/all",
		main: true
	});
};

export const addCompaniesToPartners = (postData) => {
    return store.dispatch({
		action: "table.all_companies/ADD_COMPANIES_TO_PARTNERS",
		method: "POST",
		endpoint: "/companies/partner",
		data: {
			companies: postData
		}
	});
};

export const inviteNewUser = (postData) => {
    return store.dispatch({
        action: "table.all_companies/INVITE_NEW_USER",
        method: "POST",
        endpoint: "/companies/invite",
        data: postData
    });
};