import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/views/projects/ProjectGeneral.scss';
import Image from '../../components/Image';
import {
  __,
  getActiveProject,
  getActiveStage,
  redirect,
  validateForm,
} from '../../core/utils';
import MainLayout from '../../layouts/MainLayout';
import PhotoIcon from '../../assets/images/photo-15x15.svg';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import { createSidebar } from '../../redux/actions/general/sidebar';
import ProjectGeneralForm from '../../forms/projects/ProjectGeneralForm';
import {
  getProjectPermissions,
  readProjectGeneral,
  updateProjectGeneral,
  uploadProjectThumbnail,
} from '../../redux/actions/projects';
import {
  enableProjectsDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import classNames from 'classnames';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import DefaultImage from '../../assets/images/default.png';

class ProjectGeneral extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {},
      is_project_administrator: false,
    };
  }

  componentDidMount() {
    getProjectPermissions(this.props.match.params.project).then(
      ({ response }) => {
        this.setState({
          is_project_administrator: _.get(response.data, 'administrator'),
        });

        enableProjectsDropdown(({ project_id }) =>
          redirect('/projects/' + project_id + '/general')
        );

        createSidebar({
          title: __('submenu.title.project'),
          items: [
            {
              title: __('submenu.title.stages'),
              link: () => '/projects/' + getActiveProject() + '/stages',
            },
            {
              title: __('submenu.title.teams'),
              link: () => '/stages/' + getActiveStage() + '/teams',
            },
            {
              title: __('submenu.title.approvers'),
              link: () => '/stages/' + getActiveStage() + '/approvers',
            },
            {
              title: __('submenu.title.project-properties'),
              active: true,
              items: [
                {
                  title: __('submenu.title.project-general'),
                  link: () => '/projects/' + getActiveProject() + '/general',
                  active: true,
                },
              ],
            },
            {
              title: __('submenu.title.import_spreadsheet'),
              link: () => '/import/' + getActiveStage(),
            },
          ],
        });

        readProjectGeneral(this.props.match.params.project).then(
          ({ response }) => {
            const project_languages = {};

            project_languages[response.data.main_language] = 1;
            _.each(response.data.additional_languages, (language_id) => {
              project_languages[language_id] = 0;
            });

            this.setState({
              project: response.data,
              project_languages,
            });

            listActiveModules();
          }
        );
      }
    );
  }

  @autobind
  _handleSubmit(formData) {
    formData = {
      ...formData,
      administrators: _.map(formData.administrators, 'id'),
    };

    return validateForm(
      updateProjectGeneral(this.props.match.params.project, formData).then(
        () => {
          readProjectGeneral(this.props.match.params.project).then(
            ({ response }) => {
              const project_languages = {};

              project_languages[response.data.main_language] = 1;
              _.each(response.data.additional_languages, (language_id) => {
                project_languages[language_id] = 0;
              });

              this.setState({
                project: response.data,
                project_languages,
              });
            }
          );

          listActiveModules();

          setNotification(__('project.general-settings.saved'));
        }
      )
    );
  }

  @autobind
  _handleUploadThumbnail(e) {
    if (e.target.files && e.target.files.length) {
      setNotification(__('project.image-uploaded.wait'));

      uploadProjectThumbnail(
        this.props.match.params.project,
        e.target.files[0]
      ).then(() => {
        setNotification(__('project.image-uploaded.success'));

        readProjectGeneral(this.props.match.params.project).then(
          ({ response }) => {
            this.setState({ project: response.data });
          }
        );
      });
    }
  }

  render() {
    const { project } = this.state;
    let client_id;

    if (project.client_company_id) {
      client_id = '0-' + project.client_company_id;
    }

    if (project.client_user_id) {
      client_id = '1-' + project.client_user_id;
    }

    if (project.client_address_book_company_id) {
      client_id = '2-' + project.client_address_book_company_id;
    }

    if (project.client_address_book_contact_id) {
      client_id = '3-' + project.client_address_book_contact_id;
    }

    return (
      <>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Image
              className={classNames(
                this.state.is_project_administrator && styles.hasAction
              )}
              src={project.image_url}
              default={DefaultImage}
            />
            {this.state.is_project_administrator && (
              <div className={styles.actions}>
                <Tooltip text={__('content.tooltip.project.logo')}>
                  <PhotoIcon onClick={() => this.refs.logo.click()} />
                </Tooltip>
                <input
                  type='file'
                  ref='logo'
                  onChange={this._handleUploadThumbnail}
                  accept='image/x-png,image/jpeg'
                />
              </div>
            )}
          </div>
          <div className={styles.user}>
            <div className={styles.name}>
              <strong>{project.code}:</strong> {project.name}
            </div>
            <div className={styles.info}>
              <span>{_.get(project.description, project.main_language)}</span>
            </div>
          </div>
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__('Current set plans')}</div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__('Unresolved tasks')}</div>*/}
          {/*</div>*/}
          {/*<div className={styles.item}>*/}
          {/*<div className={styles.count}>0</div>*/}
          {/*<div className={styles.description}>{__('Active participants')}</div>*/}
          {/*</div>*/}
        </div>
        <div className={styles.content}>
          {!_.isEmpty(project) && (
            <ProjectGeneralForm
              isProjectAdministrator={this.state.is_project_administrator}
              onSubmit={this._handleSubmit}
              project={project}
              projectLanguages={this.state.project_languages}
              initialValues={{
                ..._.pick(project, [
                  'code',
                  'name',
                  'description',
                  'administrators',
                  'main_language',
                  'additional_languages',
                  'currency_id',
                  'designer_id',
                  'country_id',
                  'address',
                  'building_type_ids',
                  'area',
                ]),
                client_id,
              }}
            />
          )}
        </div>
      </>
    );
  }
}

export default ProjectGeneral;
