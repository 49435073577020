import * as React from 'react';
import { Component } from 'react';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import classNames from 'classnames';
import Table from '../../components/Table';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import styles from '../../styles/views/space_allocation/Rooms.scss';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import DuplicateIcon from '../../assets/images/duplicate-16x16.svg';
import {
  deleteRooms,
  listRooms,
  readRoom,
} from '../../redux/actions/table/rooms';
import RoomsFlyout from '../../flyouts/rooms/RoomsFlyout';
import EditIcon from '../../assets/images/edit-16x16.svg';
import RoomsMultiEditFlyout from '../../flyouts/rooms_multi_edit/RoomsMultiEditFlyout';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';

@mapStateToProps((state) => ({
  table: state.table['rooms'],
}))
class Rooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('space-allocation.rooms.table.button.create'),
            onClick: () =>
              setTableParams('rooms', {
                add_room_wizard: true,
              }),
          },
        ]);
    });

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/rooms')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/rooms')
    );

    createSidebar({
      title: __('submenu.title.space-allocation'),
      items: [
        {
          title: __('submenu.title.space-allocation.rooms'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/rooms',
          active: true,
        },
        {
          title: __('submenu.title.space-allocation.room-types'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_types',
        },
        {
          title: __('submenu.title.space-allocation.room-groups'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_groups',
        },
        {
          title: __('submenu.title.space-allocation.zones'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/zones',
        },
        {
          title: __('submenu.title.space-allocation.entrances'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/entrances',
        },
        {
          title: __('submenu.title.space-allocation.expansion-joints'),
          link: () =>
            '/stages/' +
            getActiveStage() +
            '/space_allocation/expansion_joints',
        },
        {
          title: __('submenu.title.space-allocation.storeys'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/storeys',
        },
        {
          title: __('submenu.title.space-allocation.buildings'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/buildings',
        },
        {
          title: __('submenu.title.space-allocation.sites'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/sites',
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row &&
      this.props.table.clicked_row != 'multi'
    ) {
      setTableParams('rooms', {
        multi_edit: [],
      });

      if (!prevProps.table.clicked_row) {
        setTableParams('rooms', {
          flyout: undefined,
        });
      }

      readRoom(this.props.table.clicked_row).then(({ response }) => {
        setTableParams('rooms', {
          flyout: response.data,
        });
      });
    }
  }

  render() {
    const { multi_edit } = this.props.table;

    const columns = [
      {
        key: 'code',
        name: __('rooms.field.code'),
        width: 70,
        required: true,
      },
      {
        key: 'title',
        name: __('rooms.field.title'),
        required: true,
        sortable: false,
      },
      {
        key: 'site',
        name: __('rooms.field.site'),
        width: 80,
        value: (site) =>
          _.isObject(site) ? (
            <Tooltip text={_.join([site.code, site.title], ': ')}>
              {site.code}
            </Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
        active: false,
      },
      {
        key: 'building',
        name: __('rooms.field.building'),
        width: 80,
        value: (building) =>
          _.isObject(building) ? (
            <Tooltip text={_.join([building.code, building.title], ': ')}>
              {building.code}
            </Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'storey',
        name: __('rooms.field.storey'),
        width: 50,
        value: (storey) =>
          _.isObject(storey) ? (
            <Tooltip text={storey.name + ' (' + storey.number + ')'}>
              {storey.number}
            </Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'expansion_joints',
        name: __('rooms.field.expansion-joint'),
        width: 80,
        value: (expansion_joints) =>
          !_.isEmpty(expansion_joints) ? (
            <Tooltip text={_.join(expansion_joints, ', ')}>
              {_.join(expansion_joints, ', ')}
            </Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
        active: false,
      },
      {
        key: 'entrances',
        name: __('rooms.field.entrance'),
        width: 80,
        value: (entrances) =>
          !_.isEmpty(entrances) ? (
            <Tooltip text={_.join(entrances, ', ')}>
              {_.join(entrances, ', ')}
            </Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
        active: false,
      },
      {
        key: 'zones',
        name: __('rooms.field.zone'),
        width: 80,
        value: (zones) =>
          !_.isEmpty(zones) ? (
            <Tooltip text={_.join(zones, ', ')}>{_.join(zones, ', ')}</Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'functional_spaces',
        name: __('rooms.field.functional-space'),
        width: 100,
        value: (functional_spaces) =>
          !_.isEmpty(functional_spaces) ? (
            <Tooltip text={_.join(functional_spaces, ', ')}>
              {_.join(functional_spaces, ', ')}
            </Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'foh',
        name: __('rooms.field.foh-boh'),
        width: 50,
        value: (foh) =>
          !_.isNull(foh) ? (
            foh ? (
              __('rooms.value.foh')
            ) : (
              __('rooms.value.boh')
            )
          ) : (
            <TableNA />
          ),
        active: false,
      },
      {
        key: 'room_group',
        name: __('rooms.field.room-group'),
        width: 100,
        value: (room_group) =>
          room_group ? (
            <Tooltip text={room_group}>{room_group}</Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'room_type',
        name: __('rooms.field.room-type'),
        width: 100,
        value: (room_type) =>
          room_type ? (
            <Tooltip text={room_type}>{room_type}</Tooltip>
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'outdoor',
        name: __('rooms.field.indoor-outdoor'),
        width: 70,
        value: (outdoor) =>
          outdoor ? __('rooms.value.outdoor') : __('rooms.value.indoor'),
        active: false,
      },
      {
        key: 'created_at',
        name: __('rooms.field.created-at'),
        value: (created_at) => (
          <Tooltip text={dateTimeFrom(created_at)}>
            {dateFrom(created_at)}
          </Tooltip>
        ),
        width: 80,
        active: false,
      },
      {
        key: 'created_by',
        name: __('rooms.field.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
        active: false,
      },
      {
        key: 'indoor_area',
        name: __('rooms.field.indoor-area'),
        width: 70,
        value: (x, row) => (
          <div className={styles.right}>
            {row.area ? (
              (row.outdoor ? 0 : _.toNumber(row.area)).toFixed(2)
            ) : (
              <TableNA />
            )}
          </div>
        ),
        fullWidth: true,
      },
      {
        key: 'outdoor_area',
        name: __('rooms.field.outdoor-area'),
        width: 70,
        value: (x, row) => (
          <div className={styles.right}>
            {row.area ? (
              (row.outdoor ? _.toNumber(row.area) : 0).toFixed(2)
            ) : (
              <TableNA />
            )}
          </div>
        ),
        fullWidth: true,
      },
      {
        key: 'volume',
        name: __('rooms.field.volume'),
        width: 70,
        value: (volume) => (
          <div className={styles.right}>
            {volume ? _.toNumber(volume).toFixed(2) : <TableNA />}
          </div>
        ),
        fullWidth: true,
      },
      {
        key: 'occupancy',
        name: __('rooms.field.occupancy'),
        width: 50,
        value: (occupancy) => (
          <div className={styles.right}>
            {occupancy ? _.toNumber(occupancy) : <TableNA />}
          </div>
        ),
        fullWidth: true,
      },
    ];

    const singleActions = (room_id) => {
      return [
        <Tooltip text={__('rooms.tooltip.room-properties')} key={_.uniqueId()}>
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('rooms', {
                clicked_row: room_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>,
        <Tooltip text={__('rooms.tooltip.duplicate-room')} key={_.uniqueId()}>
          <DuplicateIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('rooms', {
                duplicate: room_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>,
        <Tooltip text={__('rooms.tooltip.delete-room')} key={_.uniqueId()}>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description: __('rooms.alert.delete-confirmation'),
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteRooms([room_id]).then(() => {
                        hideAlertbox();

                        setNotification(__('rooms.alert.deleted'));
                        listRooms(this.props.match.params.stage);

                        setTableParams('rooms', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (rooms_ids) => [
      <Tooltip text={__('rooms.tooltip.multi-edit-rooms')} key={_.uniqueId()}>
        <EditIcon
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('rooms', {
              multi_edit: rooms_ids,
              clicked_row: 'multi',
            });
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__('rooms.tooltip.delete-selected-rooms')}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __('rooms.alert.multiple-delete-confirmation'),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteRooms(rooms_ids).then(() => {
                      hideAlertbox();

                      setNotification(__('rooms.alert.multiple-deleted'));
                      listRooms(this.props.match.params.stage);

                      setTableParams('rooms', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <RoomsFlyout
        readAction={(room_id) => {
          return new Promise((resolve) => {
            readRoom(getURLParam('id') || room_id).then(({ response }) => {
              setTableParams('rooms', {
                flyout: response.data,
              });

              resolve(response);
            });
          });
        }}
      />
    );

    const multi_edit_flyout = <RoomsMultiEditFlyout />;

    const grand_total = _.get(this.props.table.additional_params, [
      'grand_total',
    ]);
    const total_filtered = _.get(this.props.table.additional_params, [
      'total_filtered',
    ]);

    const footer = (
      <>
        <span className={styles.total}>Total indoor:</span>
        <span className={styles.number}>
          {`${total_filtered?.area} (${grand_total?.area})`}
        </span>
        <span className={styles.total}>Total outdoor:</span>
        <span className={styles.number}>
          {`${total_filtered?.outdoor} (${grand_total?.outdoor})`}
        </span>
        <span className={styles.total}>Total volume:</span>
        <span className={styles.number}>
          {`${total_filtered?.volume} (${grand_total?.volume})`}
        </span>
      </>
    );

    return (
      <Table
        listAction={() => listRooms(this.props.match.params.stage)}
        name='rooms'
        footer={footer}
        title={__('submenu.title.space-allocation.rooms')}
        singleActions={singleActions}
        groupActions={groupActions}
        columns={columns}
        preview={
          !_.isEmpty(multi_edit)
            ? multi_edit_flyout
            : this.props.table.flyout
            ? preview
            : []
        }
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default Rooms;
