import * as React from "react";
import { Component } from "react";
import { Field, FieldArray, Form, getFormValues, reduxForm } from "redux-form";
import { __, mapStateToProps, transformToListPairs, getLocalized } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import Select from "../../../components/Select";
import classNames from "classnames";
import styles
    from "../../../styles/forms/superadmin/product_attribute_template_fields/EditProductAttributeTemplateFieldForm.scss";
import * as _ from "lodash";
import {
    addProductAttributeTemplateFieldOption,
    listPossibleProductAttributeTemplateGroups, listProductAttributeTemplateFields, readProductAttributeTemplateField
} from "../../../redux/actions/table/superadmin/product_attribute_template_fields";
import Checkbox from "../../../components/Checkbox";
import { setNotification } from "../../../redux/actions/general/notification";
import MultiSelect from "../../../components/MultiSelect";
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from "../../../assets/images/grabber-16x16.svg";
import Textarea from "../../../components/Textarea";

@reduxForm({
    form: "superadmin_product_attribute_template_fields.edit_product_attribute_template_field",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    values: getFormValues("superadmin_product_attribute_template_fields.edit_product_attribute_template_field")(state),
}))
@subscribe(Localization, "localization")
class EditProductAttributeTemplateFieldForm extends Component {
    state = {
        possible_groups: []
    }

    componentDidMount() {
        listPossibleProductAttributeTemplateGroups().then(({ response }) => {
            this.setState({ possible_groups: response.data });
        });
    }

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render_options = ({ fields }) => {
        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        const { type, id } = this.props.values;

        return (
            <>
                <ReactSortable
                    className={styles.option}
                    list={fields.getAll()}
                    setList={() => { }}
                    onEnd={({ oldIndex, newIndex }) => {
                        fields.move(oldIndex, newIndex);
                        this.props.submit();
                    }}
                    animation={200}
                    delayOnTouchStart={true}
                    handle={'.' + styles.grabber}
                >
                    {fields.map((field, i) => (
                        <div key={field} className={styles.optionGroup}>
                            <div className={styles.grabber}>
                                <GrabberIcon />
                            </div>
                            <div className={classNames(styles.flex, styles.row, _.get(this.props.values, field + '.discontinued') && styles.disabled)}>
                                {(type == 'dropdown_string' || type == 'dropdown_string_multiple') && <Field name={field + '.value'} component={Input} label={"Option #" + (i + 1)} localized={languages} />}
                                {(type == 'dropdown_numeric' || type == 'dropdown_numeric_multiple') && <Field numeric decimal name={field + '.value'} component={Input} label={"Option #" + (i + 1)} localized={languages} />}
                                <Field name={field + '.discontinued'} component={Checkbox} label={__('products.flyout.edit-product-attribute.discontinued')} className={styles.discontinuedCheckbox} />
                                <Field name={field + '.priority'} component="input" type="hidden" />
                            </div>
                        </div>
                    ))}
                </ReactSortable>
                <div className={styles.addRow}>
                    <button type="button" onClick={() => {
                        addProductAttributeTemplateFieldOption(id).then(() => {
                            this.props.reload();
                        });

                        setNotification("Product attribute template field option has been added.");
                    }}>Add option</button>
                </div>
            </>
        )
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        const data_types = [
            {
                label: 'Boolean',
                value: 'boolean'
            },
            {
                label: 'String',
                value: 'string'
            },
            {
                label: 'Predefined String Dropdown',
                value: 'dropdown_string'
            },
            {
                label: 'Predefined String Dropdown (multiple)',
                value: 'dropdown_string_multiple'
            },
            {
                label: 'Numeric',
                value: 'numeric'
            },
            {
                label: 'Predefined Numeric Dropdown',
                value: 'dropdown_numeric'
            },
            {
                label: 'Predefined Numeric Dropdown (multiple)',
                value: 'dropdown_numeric_multiple'
            },
            {
                label: 'Color',
                value: 'color'
            },
            {
                label: 'Material',
                value: 'material'
            },
            {
                label: 'Texture',
                value: 'texture'
            },
            {
                label: 'Range',
                value: 'range'
            },
            {
                label: 'Selectable Range',
                value: 'range_selectable'
            }
        ];

        const units = _.sortBy(_.map(this.props.localization.units, unit => ({
            label: unit.symbol,
            value: unit.id,
            tooltip: unit.description,
            group: _.get(this.props.localization.unit_groups, [unit.unit_group_id, 'name', 1]),
            groupPriority: _.get(this.props.localization.unit_groups, [unit.unit_group_id, 'priority'])
        })), 'groupPriority');

        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        const { type } = this.props.values;
        const { possible_groups } = this.state;

        const product_subgroups = _.map(this.props.localization.product_subgroups, product_subgroup => {
            const product_group = _.get(this.props.localization.product_groups, product_subgroup.product_group_id, {});

            return {
                label: product_subgroup.name,
                value: product_subgroup.id,
                group: [_.get(this.props.localization.product_categories, [product_group.product_category_id, 'name']), product_group.name].join(' / '),
            };
        });

        const filters = _.map(this.props.localization.filters, filter => {
            return {
                value: filter.id,
                label: getLocalized(filter.name, this.props.application_language_id)
            };
        });

        return (
            <Form onSubmit={handleSubmit}>
                <Field name="group_id" disabled={!superadmin.edit_product_attribute_template_field} component={Select} searchable noSort label="Group" options={transformToListPairs(possible_groups)} />
                <Field name="label" maxLength={50} disabled={!superadmin.edit_product_attribute_template_field} component={Input} label="Label" localized={languages} />
                <Field component={Textarea} maxLength={500} rows={1} maxRows={10} name="description" disabled={!superadmin.edit_product_attribute_template_field} label="Description" localized={languages} />
                <Field name="norm" disabled={!superadmin.edit_product_attribute_template_field} component={Input} label="Norm" />
                <Field name="discontinued" component={Checkbox} label={__('products.flyout.edit-product-attribute.discontinued')} className={styles.discontinuedCheckbox} />

                <div className={classNames(styles.flex, styles.type)}>
                    <div className={styles.f3}>
                        <Field disabled={true} name="type" component={Select} noSort label="Type" options={data_types} />
                    </div>
                    {_.includes(['numeric', 'dropdown_numeric', 'dropdown_numeric_multiple', 'range', 'range_selectable'], type) && <div className={styles.f1}>
                        <Field disabled={!superadmin.edit_product_attribute_template_field} name="unit_id" component={Select} searchable noSort label="Unit" options={units} />
                    </div>}
                </div>
                <Field name="product_subgroup_ids" disabled={!superadmin.edit_product_attribute_template_field} searchable component={MultiSelect} label="Assigned Product Subgroups" options={product_subgroups} />
                <Field name="filter_ids" disabled={!superadmin.edit_filter} searchable component={MultiSelect} label="Assigned Filters" options={filters} />
                {_.includes(['dropdown_string', 'dropdown_string_multiple', 'dropdown_numeric', 'dropdown_numeric_multiple'], type) && <FieldArray name="options" component={this.render_options} />}
            </Form>
        );
    }
}

export default EditProductAttributeTemplateFieldForm;