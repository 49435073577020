import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/ConfirmAccount.scss";
import {__, clearURLFragment, redirect} from "../../core/utils";
import classNames from "classnames";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import AuthLayout from "../../layouts/AuthLayout";
import queryString from "query-string";

class TeamMemberConfirmation extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		if(this.props.location.hash && _.includes(this.props.location.hash, '#project_id')) {
			const hash = _.trimStart(this.props.location.hash, '#');

			this.setState({project_id: _.get(queryString.parse(hash), 'project_id')});

			clearURLFragment();
		} else {
			redirect('/', true);
		}
	}

	render() {
		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
					<div className={styles.title}>
                        {__('Thank You!')}
					</div>
				</div>
				<div className={styles.details}>
					<div className={styles.content}>
                       <p className={classNames(styles.strong, styles.blue)}>{__("project.team.confirmation.success-join")}</p>
					</div>
                    <div className={styles.footer}>
						<Button onClick={() => redirect("/projects" + (this.state.project_id ? ("/" + this.state.project_id + "/general") : ""))} center leftText={this.state.project_id ? __('project.team.confirmation.success-join-goto-project') : __('project.team.confirmation.success-join-goto-projects')} rightIcon={ArrowRightMiddleIcon} />
                    </div>
				</div>
			</>
		);
	}
}

export default TeamMemberConfirmation;