import store from "../../../core/store";

export const showAlertbox = (options) => {
    return store.dispatch({
        type: "general.alertbox/SHOW_ALERTBOX",
        ...options
    });
};

export const hideAlertbox = () => {
    return store.dispatch({
        type: "general.alertbox/HIDE_ALERTBOX",
    });
};