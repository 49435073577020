import * as React from 'react';
import { Component, createRef } from 'react';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import { getStagesLanguages } from '../../redux/actions/table/stages';
import { withRouter } from 'react-router';
import {
  getBOQCurrency,
  getBOQArea,
} from '../../redux/actions/table/working_set_boq';
import styles from '../../styles/views/tenders/TenderBOQ.scss';
import Tooltip from '../../components/Tooltip';
import autobind from 'autobind-decorator';
import { getPreferences, updatePreferences } from '../../redux/actions/profile';
import {
  readTender,
  readTenderBOQ,
  readTenderBOQOwner,
  readTenderSpecification,
  updateBidderStatus,
} from '../../redux/actions/table/tenders';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import ArrowLeftMiddleIcon from '../../assets/images/arrow-left-middle-15x15.svg';
import TendersFlyout from '../../flyouts/tenders/TendersFlyout';
import Table from './helpers/TableOwner';
import TableBidder from './helpers/TableBidder';
import TableOwner from './helpers/TableOwner';
import WorkingSetSpecificationsFlyout from '../../flyouts/working_set_specifications/WorkingSetSpecificationsFlyout';
import { readWorkingSetSpecification } from '../../redux/actions/table/working_set_specifications';

@mapStateToProps((state) => ({
  table: state.table['tenders'],
  auth: state.auth,
  specification_id: _.get(state.table, [
    'working_set_specifications',
    'clicked_row',
  ]),
}))
@withRouter
class TenderBOQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused_id: null,
      space_allocation: {},
      expanded_rows: [],
      isDataLoaded: false,
    };

    this.tableRef = createRef();
    this.nameRefs = [];

    this.savePreferencesTimeout = null;
  }

  @autobind
  _refreshTenderBOQ(list_active_modules = false) {
    return new Promise((resolve, reject) => {
      const { is_bidder } = this.state.preview;

      const action = is_bidder
        ? readTenderBOQ(this.props.match.params.tender)
        : readTenderBOQOwner(this.props.match.params.tender);

      action.then(() => {
        this.setState({ isDataLoaded: true });
        resolve();

        list_active_modules && listActiveModules();
      });
    });
  }

  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/tenders')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/tenders')
    );

    getStagesLanguages(this.props.match.params.stage).then(({ response }) => {
      setTableParams('tenders', {
        project_languages: response.data,
        language_id: _.findKey(response.data, (primary) => primary == true),
      });
    });

    readTender(this.props.match.params.tender)
      .then(({ response }) => {
        const { is_bidder, bidders } = response.data;

        if (is_bidder && _.first(bidders).status == 'received') {
          updateBidderStatus(
            getURLParam('id') || this.props.match.params.tender,
            _.first(bidders).id,
            { status: 'in-progress' }
          ).then(() => readTender(this.props.match.params.tender));
        }

        this.setState({ preview: response.data });
      })
      .then(() => {
        this._refreshTenderBOQ(true);
      })
      .then(() => {
        getPreferences(`tenders.${this.props.match.params.tender}`).then(
          ({ response: { data } }) => {
            const expanded_rows = _.get(data, 'expanded_rows', []);

            this.tableRef.setExpandedRowKeys(expanded_rows);

            this.setState({ expanded_rows });
          }
        );
      });

    // Have to be changed
    getBOQArea(this.props.match.params.stage).then(({ response }) => {
      this.setState({ space_allocation: response.data });
    });

    // Have to be changed
    getBOQCurrency(this.props.match.params.stage).then(({ response }) => {
      setTableParams('tenders', {
        project_currency: _.get(this.props.localization.currencies, [
          response.data,
          'code',
        ]),
      });
    });

    createSidebar({
      title: __('submenu.title.tenders'),
      items: [
        {
          title: __('submenu.title.all-tenders'),
          link: () => '/stages/' + getActiveStage() + '/tenders',
          active: true,
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {}

  @autobind
  _saveTablePreferences() {
    if (this.savePreferencesTimeout) clearTimeout(this.savePreferencesTimeout);

    this.savePreferencesTimeout = setTimeout(() => {
      updatePreferences(`tenders.${this.props.match.params.tender}`, {
        expanded_rows: this.state.expanded_rows,
      });
    }, 1000);
  }

  render() {
    const { space_allocation, expanded_rows, focused_id, isDataLoaded } =
      this.state;

    const {
      language_id,
      project_languages,
      project_currency,
      tender_boq_data,
      flyout,
    } = this.props.table;
    const { localization, specification_id } = this.props;

    const project_language_id = _.findKey(
      project_languages,
      (primary) => primary == true
    );

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div className={styles.tender}>
              <span>
                <strong>
                  {'B-' + _.padStart(flyout?.identifier, 4, '0')}:
                </strong>
                {_.get(
                  flyout?.title,
                  _.findKey(
                    _.get(flyout, 'project_languages'),
                    (primary) => primary == 1
                  )
                )}
              </span>
            </div>
            <div className={styles.details}>{`${dateTimeFrom(
              flyout?.start_date
            )} - ${dateTimeFrom(flyout?.end_date)}`}</div>
          </div>
          <div className={styles.toolbar}>
            <div
              className={styles.left}
              onClick={() => {
                redirect(
                  '/stages/' + this.props.match.params.stage + '/tenders'
                );
              }}
            >
              <ArrowLeftMiddleIcon /> {__('tenders.tools.back-to-tender-list')}
            </div>
            <div className={styles.right}>
              <Tooltip text={__('tenders.tooltip.icon.tender-properties')}>
                <InfoPositiveIcon
                  className={styles.info}
                  onClick={() =>
                    setTableParams('tenders', {
                      clicked_row: flyout.id,
                      stage_id: this.props.match.params.stage,
                    })
                  }
                />
              </Tooltip>
            </div>
          </div>
        </div>

        {!_.isEmpty(this.state.preview) && isDataLoaded && (
          <div className={styles.table}>
            {this.state.preview.is_bidder ? (
              <TableBidder
                localization={localization}
                languageId={language_id}
                projectLanguageId={project_language_id}
                projectCurrency={project_currency}
                data={tender_boq_data}
                spaceAllocationData={space_allocation}
                refreshData={this._refreshTenderBOQ}
                setRef={(ref) => (this.tableRef = ref)}
                tableRef={this.tableRef}
                setNameRef={(id, ref) => (this.nameRefs[id] = ref)}
                expandedRows={expanded_rows}
                setFocusedId={(focused_id) => this.setState({ focused_id })}
                focusedId={focused_id}
                setExpandedRows={(expanded_rows) => {
                  this.setState({ expanded_rows });

                  this._saveTablePreferences();
                }}
                rowClassName={styles.row}
              />
            ) : (
              <TableOwner
                is_bidder={this.state.preview?.is_bidder}
                bidders={this.state.preview?.bidders}
                localization={localization}
                languageId={language_id}
                projectLanguageId={project_language_id}
                projectCurrency={project_currency}
                data={tender_boq_data}
                spaceAllocationData={space_allocation}
                refreshData={this._refreshTenderBOQ}
                setRef={(ref) => (this.tableRef = ref)}
                tableRef={this.tableRef}
                setNameRef={(id, ref) => (this.nameRefs[id] = ref)}
                expandedRows={expanded_rows}
                setFocusedId={(focused_id) => this.setState({ focused_id })}
                focusedId={focused_id}
                setExpandedRows={(expanded_rows) => {
                  this.setState({ expanded_rows });

                  this._saveTablePreferences();
                }}
                rowClassName={styles.row}
              />
            )}
          </div>
        )}

        {this.props.table.clicked_row && this.state.preview && (
          <div className={styles.flyout}>
            <TendersFlyout
              data={this.state.preview}
              readAction={(tender_id) => {
                return new Promise((resolve) => {
                  readTender(tender_id).then(({ response }) => {
                    this.setState({ preview: response.data });
                    // this.setState({ meeting: response.data });

                    resolve(response);
                  });
                });
              }}
            />
          </div>
        )}

        {specification_id && (
          <div className={styles.flyout}>
            <WorkingSetSpecificationsFlyout
              readOnly
              readAction={(specification_id) => {
                return new Promise((resolve) => {
                  readTenderSpecification(
                    this.props.match.params.tender,
                    specification_id
                  ).then(({ response }) => {
                    setTableParams('working_set_specifications', {
                      flyout: response.data,
                      clicked_row: response.data.id,
                    });
                    resolve(response);
                  });
                });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TenderBOQ;
