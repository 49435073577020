import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/superadmin/product_categories/Properties.scss";
import * as _ from "lodash";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../../core/utils";
import { setNotification } from "../../../redux/actions/general/notification";
import { listProductCategories, updateProductCategory } from "../../../redux/actions/table/superadmin/product_categories";
import EditProductCategoryForm from "../../../forms/superadmin/product_categories/EditProductCategoryForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateProductCategory(formData.id, _.omit(formData, ['id'])).then(() => {
                listProductCategories();

                this.props.readAction(formData.id);

                setNotification(__('properties.notifications.properties-have-been-saved'));
            })
        );
    }

    render() {
        const { data } = this.props;

        return (
            <div className={styles.scroller}>
                <EditProductCategoryForm
                    onSubmit={this._handleSubmit}
                    initialValues={{
                        ..._.pick(data, ['id', 'name', 'priority']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
