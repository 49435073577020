import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../../styles/forms/superadmin/add_color_wizard/AddColorForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { __, mapStateToProps, setTableParams, transformToListPairs, getLocalized } from "../../../core/utils";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import MultiSelect from "../../../components/MultiSelect";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../../../helpers/Localization";

@withRouter
@reduxForm({ form: "superadmin.add_color_wizard.add_color" })
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_color_wizard.add_color")(state),
    auth: state.auth,
}))
class AddColorForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == this.props.auth.language_id;

            return languages;
        }, {});

        const localized_raw_colors = _.sortBy(_.map(this.props.localization.raw_colors, item => ({
            value: item.id,
            priority: item.priority,
            label: getLocalized(item.name, this.props.language),
        })), 'priority');

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" localized={application_languages} component={Input} label="Name" />
                    <Field name="raw_colors" component={MultiSelect} searchable noSort options={localized_raw_colors} label="Filter colors" />
                    <Field name="hex" component={Input} maxLength={6} label="Hex" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_colors', {
                        add_color_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddColorForm;
