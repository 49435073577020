import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddUnitWizard.scss";
import autobind from "autobind-decorator";
import AddUnitIcon from "../../assets/images/add-unit-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createUnit, listUnits } from "../../redux/actions/table/superadmin/units";
import AddUnitForm from "../../forms/superadmin/add_unit_wizard/AddUnitForm";
import * as _ from "lodash";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_units"],
}))
class AddUnitWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const { edit } = formData;

        return validateForm(
            createUnit(formData).then(({ response }) => {
                listUnits().then(() => {
                    if (edit) {
                        setTableParams('superadmin_units', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_units', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                this.props.onClose();

                setNotification("You have successfully added a new unit.");
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>Add unit</span>
                        <span>Add unit to the application</span>
                    </div>
                    <div className={styles.right}>
                        <AddUnitIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddUnitForm onSubmit={this._handleSubmit} initialValues={{
                        name: {},
                        symbol: {},
                        description: {},
                    }} />
                </div>
            </>
        );
    }
}

export default AddUnitWizard;