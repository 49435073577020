import React, {Component} from "react";
import styles from "../styles/components/Tags.scss";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../assets/images/arrow-down-middle-15x15.svg";
import ClickOutside from "react-click-outside";
import autobind from "autobind-decorator";
import SearchIcon from "../assets/images/search-15x15.svg";
import {__, mapStateToProps} from "../core/utils";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import {
    registerMultieditField,
    toggleMultieditClearAll,
    unregisterMultieditField
} from "../redux/actions/general/multiedit";
import * as _ from "lodash";
import UncheckedIcon from "../assets/images/unchecked-15x15.svg";
import CheckedIcon from "../assets/images/checked-15x15.svg";
import PlusMiddleIcon from "../assets/images/plus-middle-15x15.svg";
import Tooltip from "./Tooltip";
import FieldComponent from "./FieldComponent";
import OutsideClickWrapper from "./OutsideClickWrapper";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import KeyboardEventHandler from "react-keyboard-event-handler";
import {isMobileOnly} from "react-device-detect";

@FieldComponent
@mapStateToProps(state => ({
	multiedit: state.general.multiedit
}))
class Tags extends Component {
	constructor(props) {
		super(props);

		this.state = {
			query: ''
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.meta.active != this.props.meta.active) {
			this.setState({
				query: '',
				selected: this.props.input.value || [],
			}, () => {
				this.props.recalculateOffset();
			});
		}

		if(prevState.selected != this.state.selected || prevState.query != this.state.query) {
			this.props.recalculateOffset();
		}
	}

	@autobind
	_handleOpen() {
		if(!this.props.disabled) {
			this.props.input.onFocus();
		}
	}

	@autobind
	_handleClose() {
		this.props.input.onBlur();
	}

	@autobind
	_handleSave() {
		this.props.input.onChange(this.state.selected);

		this._handleClose();
	}

	@autobind
	_addOption(value) {
		const multiedit_enabled = _.isObject(this.props.multiedit[this.props.meta.form]);
		multiedit_enabled && registerMultieditField(this.props.meta.form, this.props.input.name);

		this.setState({
			selected: _.xor(this.state.selected, [value])
		}, () => {
			this.refs.search && this.refs.search.select();
		});
	}

    @autobind
    _handleSearch(e) {
        this.setState({ query: e.target.value });
    }

    @autobind
    _handleEnter() {
		const results = _.difference(_.filter(this.props.options, tag => _.isEmpty(this.state.query) || _.includes(tag.toLowerCase(), this.state.query.toLowerCase())), this.state.selected);

		if(!_.isEmpty(this.state.query) && !_.includes(_.map(this.state.selected, _.toLower), _.toLower(this.state.query))) {
			this._addOption(_.isEmpty(results) ? _.toLower(this.state.query) : _.first(results));
		}
    }

    @autobind
    _removeOption(e, value) {
		e.stopPropagation();

		if(!this.props.disabled) {
			const multiedit_enabled = _.isObject(this.props.multiedit[this.props.meta.form]);
			multiedit_enabled && registerMultieditField(this.props.meta.form, this.props.input.name);

			this.setState({
				selected: _.xor(this.state.selected, [value])
			});
		}
	}

	@autobind
	_handleClearSearch() {
		this.setState({
			query: ''
		});

		this.refs.search.focus();
	}

	render() {
		const multiedit_enabled = _.isObject(this.props.multiedit[this.props.meta.form]);
		const multiedited = multiedit_enabled && _.includes(_.get(this.props.multiedit, [this.props.meta.form, 'fields']), this.props.input.name);
		const multiedit_clear_existing = multiedit_enabled && _.includes(_.get(this.props.multiedit, [this.props.meta.form, 'reset']), this.props.input.name);

		const {query, selected} = this.state;
		const results = _.difference(_.filter(this.props.options, tag => _.isEmpty(this.state.query) || _.includes(tag.toLowerCase(), this.state.query.toLowerCase())), selected);

		const {disabled, leftPosition, topPosition, elementRef, dropdownRef} = this.props;

		return (
			<div
				className={classNames(
					styles.wrapper,
                    _.isEmpty(this.props.input.value) && styles.placeholder,
					this.props.meta.active && styles.focus,
					this.props.center && styles.center,
					_.get(this.props, "meta.error") && _.get(this.props, "meta.touched") && styles.error,
					this.props.disabled && styles.disabled,
					multiedit_enabled && !multiedited && !multiedit_clear_existing && styles.noChange
				)}
			>
				<div className={styles.label}>
					<label onClick={this._handleOpen}>{this.props.label}</label>
					{multiedit_enabled && (multiedited || multiedit_clear_existing) && <span onClick={() => unregisterMultieditField(this.props.meta.form, this.props.input.name)}>{__('button.revert-changes')}</span>}
				</div>
				<div className={styles.tagsGroup} ref={elementRef} onClick={this._handleOpen}>
					<div className={styles.tags}>
						{_.isEmpty(this.props.input.value) ? (multiedit_enabled ? ((multiedited || multiedit_clear_existing) ? '' : '-- no change --') : '') : _.map(_.sortBy(this.props.input.value), (tag, i) => <span key={i}>{tag}</span>)}
					</div>
					{(!this.props.center && !this.props.disabled) && <ArrowDownMiddleIcon />}
				</div>
				{this.props.meta.active && (
					<OutsideClickWrapper closable={false}>
						<KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this._handleClose} />
						<div className={styles.dropdown} ref={dropdownRef} style={{top: topPosition + 'px', left: leftPosition + 'px', marginTop: (this.props.top || 27) + 'px', marginLeft: (this.props.left || 0) + 'px'}}>
							{isMobileOnly && <div className={styles.mobileHeader}>{this.props.label}</div>}
							{!_.isEmpty(selected) && <div className={styles.selected}>
								<div className={styles.title}>{__('company-settings.submenu.brands.flyout.collection.currently-selected')}</div>
								<div className={styles.list}>
									{_.map(selected, (tag, i) => (
										<div className={styles.row} key={i} onClick={(e) => this._removeOption(e, tag)}>
											<span className={styles.tag}>{tag}</span>
											{!disabled && <CloseSmallIcon className={styles.close} />}
										</div>
									))}
								</div>
							</div>}
							<div className={styles.available}>
								<div className={styles.title}>{this.props.availableText ? this.props.availableText : __('specifications.working-set.flyout.tags.available-tags')}</div>
								<div className={styles.search}>
									<SearchIcon className={styles.searchIcon} />
									<input maxLength={25} type="text" onKeyDown={e => {
										if(e.key == "Enter") {
											e.stopPropagation();
											e.preventDefault();

											this._handleEnter();
										}

										if(e.keyCode == 27) {
											e.stopPropagation();
											e.preventDefault();

											this._handleClose();
										}
									}} value={query} ref="search" autoFocus={true} placeholder={__('table.filters.search')} onChange={this._handleSearch} />
									{!_.isEmpty(query) && <CloseSmallIcon className={styles.close} onClick={this._handleClearSearch} />}
								</div>
								<div className={styles.list}>
									{!_.isEmpty(results) && _.map(_.sortBy(results, tag => tag.toLowerCase()), (tag, i) => (
										<div className={styles.row} key={i} onClick={() => this._addOption(tag)}>
											<span>{tag}</span>
											<PlusMiddleIcon className={styles.plus} />
										</div>
									))}
								</div>
								{(!_.isEmpty(query) && _.isEmpty(_.filter(_.get(this.state, "selected", []), item => item.toLowerCase() == query.toLowerCase())) && _.isEmpty(_.filter(results, tag => tag.toLowerCase() == query.toLowerCase()))) && <Tooltip placement="left" text={__('tags.title.create-new-tag') + ' "' + query + '"'}>
									<button type="button" onClick={() => this._addOption(_.toLower(this.state.query))} className={styles.createNew}>
										{__('tags.title.create-new-tag')} <strong>"{query}"</strong>
									</button>
								</Tooltip>}
							</div>
							<div className={styles.footer}>
								<ButtonGroup right>
									<Button lightGray medium middleText={__('button.cancel')} onClick={this._handleClose} />
									<Button lightBlue medium middleText={__('button.done')} onClick={this._handleSave} />
								</ButtonGroup>
							</div>
						</div>
					</OutsideClickWrapper>
				)}
				<div className={styles.bar} />
				{multiedit_enabled && (
					<div className={styles.clearExisting} onClick={() => toggleMultieditClearAll(this.props.meta.form, this.props.input.name)}>
						{!multiedit_clear_existing && <UncheckedIcon className={styles.unchecked} />}
						{multiedit_clear_existing && <CheckedIcon className={styles.checked} />}
						{__('component.tags.clear-all-existing-tags')}
					</div>
				)}
				{this.props.center && (
					<div className={styles.arrow}>
						<ArrowDownMiddleIcon />
					</div>
				)}
				{!this.props.center && (
					<div className={classNames(styles.assist, ((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.hint) && styles.hint)}>
						{(_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || (this.props.hint && <span>{_.get(this.props, "meta.error") || this.props.hint}</span>)}
					</div>
				)}
			</div>
		);
	}
}

export default Tags;
