import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_related_task_wizard/AddRelatedTaskForm.scss";
import Button from "../../components/Button";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import MeetingTasksChooser from "../../components/MeetingTasksChooser";
import {listPossibleRelatedTasks} from "../../redux/actions/table/all_tasks";
import * as _ from "lodash";

@withRouter
@reduxForm({form: "add_related_task_wizard.add_related_task"})
@mapStateToProps(state => ({
    store: state.table.all_tasks,
}))

class AddRelatedTaskForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_related_tasks: [],
        }
    }

    componentDidMount() {
        listPossibleRelatedTasks(this.props.store.add_related_task_wizard).then(({response}) => {
            this.setState({possible_related_tasks: response.data});
        });
    }

    render() {
        const {possible_related_tasks} = this.state;


        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="task_ids" component={MeetingTasksChooser} label={__('table.columns.related-tasks')} tasks={possible_related_tasks} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_tasks', {
                        add_related_task_wizard: undefined
                    })} />
                    <Button lightBlue medium right submit middleText={__('button.add')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default AddRelatedTaskForm;
