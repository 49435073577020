import store from "../../../core/store";

export const listPlanApprovals = (stage_id, status) => {
    return store.dispatch({
        action: "table.plan_approvals/LIST_PLAN_APPROVALS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/approvals" + (status ? '?status=' + status : ''),
        main: true
    });
};

export const approvePlanVersions = (formData) => {
    return store.dispatch({
        action: "table.plan_approvals/APPROVE_PLAN_VERSIONS",
        method: "POST",
        endpoint: "/plan_versions/approve",
        data: formData
    });
};

export const rejectPlanVersions = (formData) => {
    return store.dispatch({
        action: "table.plan_approvals/REJECT_PLAN_VERSIONS",
        method: "POST",
        endpoint: "/plan_versions/reject",
        data: formData
    });
};