import * as React from "react";
import {Component} from "react";
import {Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/create_meeting_followup_meeting_wizard/AgendaItemsForm.scss";
import Button from "../../components/Button";
import {__, mapStateToProps, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ButtonGroup from "../../components/ButtonGroup";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import classNames from 'classnames';
import * as _ from "lodash";
import Tooltip from "../../components/Tooltip";
import TaskRfiIcon from "../../assets/images/task-rfi-15x15.svg";
import TaskFyiIcon from "../../assets/images/task-fyi-15x15.svg";
import TaskDecisionIcon from "../../assets/images/task-decision-15x15.svg";
import TaskTodoIcon from "../../assets/images/task-todo-15x15.svg";
import TaskMilestoneIcon from "../../assets/images/task-milestone-15x15.svg";
import TaskIssueIcon from "../../assets/images/task-issue-15x15.svg";
import autobind from "autobind-decorator";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";

@subscribe(Localization, "localization")
@withRouter
@reduxForm({
    form: "create_followup_meeting_wizard.agenda_items",
    destroyOnUnmount: false
})
@mapStateToProps(state => ({
    values: getFormValues("create_followup_meeting_wizard.agenda_items")(state),
}))
class AgendaItemsForm extends Component {
    @autobind
    _deleteItem(item_id) {
        this.props.change('items', _.reject(this.props.values.items, item => item == item_id));
    }

    render() {
        const {items, values} = this.props;
        const selected_items = _.sortBy(_.filter(items, item => _.includes(values.items, item.id)), 'order');

        let group_counter = 1;

        const statuses = {
            'not-started': 'NS',
            'started': 'ST',
            'finished': 'FI',
            'closed': 'CL',
            'reopened': 'RE',
            'canceled': 'CA',
        };

        const priorities = {
            'low': 'LO',
            'normal': 'NO',
            'high': 'HI',
            'urgent': 'UR',
        };

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        Title
                    </div>
                    <div className={styles.task}>
                        Task
                    </div>
                </div>
                <div className={styles.table}>
                    {_.map(selected_items, item => {
                        if(item.type == 'group') {
                            return (
                                <div className={classNames(styles.item, styles.group)}>
                                    <div className={styles.number}>{_.padStart(_.toString(group_counter++), 2, '0')}</div>
                                    <div className={styles.title}>{item.title}</div>
                                    <div className={styles.type} />
                                    <div className={styles.delete}>
                                        <Tooltip text={__('Delete Item')}>
                                            <CloseMiddleIcon onClick={() => this._deleteItem(item.id)} />
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        }

                        if(item.type == 'task') {
                            return (
                                <div className={classNames(styles.item, styles.task)}>
                                    <div className={styles.dash}>&mdash;</div>
                                    <div className={styles.title}>{item.task_title}</div>
                                    <div className={styles.type}>
                                        {item.task_type == 'rfi' && <TaskRfiIcon className={styles.rfiIcon} />}
                                        {item.task_type == 'fyi' && <TaskFyiIcon className={styles.fyiIcon} />}
                                        {item.task_type == 'decision' && <TaskDecisionIcon className={styles.decisionIcon} />}
                                        {item.task_type == 'todo' && <TaskTodoIcon className={styles.todoIcon} />}
                                        {item.task_type == 'milestone' && <TaskMilestoneIcon className={styles.milestoneIcon} />}
                                        {item.task_type == 'issue' && <TaskIssueIcon className={styles.issueIcon} />}
                                        <span className={styles.id}>{'T-' + _.padStart(item.task_identifier, 4, "0")}</span>
                                        <div className={classNames(
                                            styles.bubble,
                                            item.task_priority == 'low' && styles.low,
                                            item.task_priority == 'normal' && styles.normal,
                                            item.task_priority == 'high' && styles.high,
                                            item.task_priority == 'urgent' && styles.urgent,
                                        )}>
                                            {priorities[item.task_priority]}
                                        </div>
                                        <div className={classNames(
                                            styles.bubble,
                                            item.task_status == 'not-started' && styles.notStarted,
                                            item.task_status == 'started' && styles.started,
                                            item.task_status == 'finished' && styles.finished,
                                            item.task_status == 'closed' && styles.closed,
                                            item.task_status == 'reopened' && styles.reopened,
                                            item.task_status == 'canceled' && styles.canceled,
                                        )}>
                                            {statuses[item.task_status]}
                                        </div>
                                    </div>
                                    <div className={styles.delete}>
                                        <Tooltip text={__('Delete Item')}>
                                            <CloseMiddleIcon onClick={() => this._deleteItem(item.id)} />
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        }

                        if(item.type == 'topic') {
                            return (
                                <div className={classNames(styles.item, styles.topic)}>
                                    <div className={styles.dash}>&mdash;</div>
                                    <div className={styles.title}>{item.title}</div>
                                    <div className={styles.type}/>
                                    <div className={styles.delete}>
                                        <Tooltip text={__('Delete Item')}>
                                            <CloseMiddleIcon onClick={() => this._deleteItem(item.id)} />
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_meetings', {
                        create_followup_meeting_wizard: undefined
                    })} />
                    <ButtonGroup right>
                        <Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
                        <Button submit medium lightBlue leftText={__('button.done')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AgendaItemsForm;
