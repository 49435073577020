import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Drawings.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  dateFrom,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import TextareaAutosize from 'react-autosize-textarea';
import TableNA from '../../components/TableNA';
import {
  deleteSpecificationDrawing,
  deleteSpecificationFile,
  readWorkingSetSpecification,
  updateSpecificationDrawingLetter,
  updateSpecificationNote,
  updateSpecificationRevisionDescription,
  uploadSpecificationDrawing,
  uploadSpecificationFiles,
} from '../../redux/actions/table/working_set_specifications';
import { setNotification } from '../../redux/actions/general/notification';
import Tooltip from '../../components/Tooltip';
import WarningIcon from '../../assets/images/warning-30x30.svg';
import PDFIcon from '../../assets/images/pdf-16x16.svg';
import CloseMiddleIcon from '../../assets/images/close-middle-15x15.svg';
import Select from '../../components/Select';
import GlobeIcon from '../../assets/images/globe-16x16.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

@mapStateToProps((state) => ({
  flyout: state.table.working_set_specifications.flyout,
}))
class Drawings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: null,
      letter: ' ',
    };
  }

  componentDidMount() {
    this._resetLetter();
  }

  @autobind
  _resetLetter() {
    const used_letters = _.map(this.props.flyout.drawings, 'letter');

    this.setState({
      letter: '-',
    });
  }

  @autobind
  _updateSpecificationDrawingLetter(specification_drawing_id, letter) {
    const specification_id = this.props.flyout.id;

    if (letter == '-') {
      letter = null;
    }

    updateSpecificationDrawingLetter(specification_drawing_id, letter).then(
      () => {
        readWorkingSetSpecification(specification_id).then(({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
          });

          this._resetLetter();
        });

        setNotification(
          __(
            'working-set-specifications.drawings.selected-specification-drawing-letter-updated'
          )
        );
      }
    );
  }

  @autobind
  _uploadDrawing(files) {
    const specification_id = this.props.flyout.id;
    const letter = this.state.letter;

    setNotification(
      __('working-set-specifications.drawings.please-wait-file-uploaded')
    );

    uploadSpecificationDrawing(specification_id, letter, files[0])
      .then(() => {
        readWorkingSetSpecification(specification_id).then(({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
          });

          this._resetLetter();
        });
      })
      .catch(() => {
        setNotification({
          text: __(
            'working-set-specifications.drawings.file-not-uploaded.double-check-file-format-size'
          ),
          type: 'warning',
        });
      });
  }

  @autobind
  _removeDrawing(specification_drawing_id) {
    const specification_id = this.props.flyout.id;

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'working-set-specifications.drawings.are-you-sure-remove-this-drawing'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () =>
            deleteSpecificationDrawing(specification_drawing_id).then(() => {
              hideAlertbox();

              readWorkingSetSpecification(specification_id).then(
                ({ response }) => {
                  setTableParams('working_set_specifications', {
                    flyout: response.data,
                  });

                  this._resetLetter();
                }
              );

              setNotification(
                __(
                  'working-set-specifications.drawings.selected-specification-drawing-deleted'
                )
              );
            }),
        },
      ],
    });
  }

  render() {
    const { collapsed, flyout, readOnly } = this.props;
    const { active, letter } = this.state;

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.header}>
          <span className={styles.letter}>
            {__('specifications.working-set.flyout.drawings.letter')}
          </span>
          <span className={styles.file}>
            {__('specifications.working-set.flyout.drawings.file')}
          </span>
        </div>
        {_.map(flyout.drawings, (file, i) => (
          <div className={styles.row} key={i}>
            <div className={styles.letter}>
              <Select
                disabled={readOnly}
                input={{
                  value: file.letter || '-',
                  onChange: (letter) =>
                    this._updateSpecificationDrawingLetter(file.id, letter),
                  onFocus: () => this.setState({ active: i }),
                  onBlur: () => this.setState({ active: null }),
                }}
                meta={{
                  active: active == i,
                }}
                label={__(
                  'working-set-specifications.drawings.product-language'
                )}
                options={_.map(
                  _.split('-abcdefghijklmnopqrstuvwxyz', ''),
                  (letter) => ({
                    label: letter == '-' ? '--' : _.toUpper(letter),
                    value: letter,
                  })
                )}
                raw
                top={28}
                noSort
                searchable
              />
            </div>
            <div
              className={styles.file}
              onClick={() => window.open(file.stream_url, '_blank')}
            >
              <PDFIcon /> {[file.name, file.ext].join('.')}
            </div>
            <div className={styles.remove}>
              <CloseMiddleIcon onClick={() => this._removeDrawing(file.id)} />
            </div>
          </div>
        ))}
        <div className={styles.row}>
          <div className={styles.letter}>
            <Select
              disabled={readOnly}
              input={{
                value: letter,
                onChange: (letter) => this.setState({ letter }),
                onFocus: () => this.setState({ active: 'new' }),
                onBlur: () => this.setState({ active: null }),
              }}
              meta={{
                active: active == 'new',
              }}
              label={__('working-set-specifications.drawings.product-language')}
              options={_.map(
                _.split('-abcdefghijklmnopqrstuvwxyz', ''),
                (letter) => ({
                  label: letter == '-' ? '--' : _.toUpper(letter),
                  value: letter,
                })
              )}
              raw
              top={28}
              noSort
              searchable
            />
          </div>
          {!readOnly && (
            <div className={styles.file}>
              <button onClick={() => this.refs.file.click()}>
                {__('button.add-file')}
              </button>
              <input
                type='file'
                accept='application/pdf'
                ref='file'
                onChange={(e) => this._uploadDrawing(e.target.files)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Drawings;
