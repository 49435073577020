import * as React from "react";
import {Component} from "react";
import ReactTooltip from "../helpers/tooltip";
import styles from "../styles/components/Tooltip.scss";
import ReactDOMServer from "react-dom/server";

class Tooltip extends Component {
    _refresh() {
        if(window.tooltip_refresher) clearTimeout(window.tooltip_refresher);

        window.tooltip_refresher = setTimeout(() => ReactTooltip.rebuild && ReactTooltip.rebuild(), 500);
    }

    componentDidMount() {
        this._refresh();
    }

    componentDidUpdate() {
        this._refresh();
    }

    render() {
        return (
            <>
                {_.isString(this.props.children) ?
                    <span className={styles.wrapper} ref={ref => this.reference = ref} onMouseUp={() => ReactTooltip.hide(this.reference)} data-tip={this.props.text} data-place={this.props.placement}>
                    {this.props.children}
                </span> : React.Children.map(this.props.children, child =>
                        React.cloneElement(child, {
                            onMouseUp: () => ReactTooltip.hide(this.reference),
                            ref: ref => this.reference = ref,
                            'data-tip': this.props.html ? ReactDOMServer.renderToStaticMarkup(this.props.text) : this.props.text,
                            'data-html': this.props.html,
                            'data-place': this.props.placement
                        })
                    )}
            </>);
    }
}

export default Tooltip;
