import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {__, mapStateToProps} from "../../../core/utils";
import {enableAutosave} from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import Textarea from "../../../components/Textarea";

@reduxForm({
    form: "superadmin_translations.edit_translation",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
@subscribe(Localization, "localization")
class EditTranslationForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        const {handleSubmit, superadmin} = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_translation} name="keyword" component={Input} label={__('superadmin.translations.edit-translation.keyword')} />
                <Field disabled={!superadmin.edit_translation} name="tag" component={Input} label={__('superadmin.translations.edit-translation.tag')} />
                <Field disabled={!superadmin.edit_translation} localized={languages}  name="translations" component={Textarea} label={__('superadmin.translations.edit-translation.translations')} />
            </Form>
        );
    }
}

export default EditTranslationForm;