const initialState = {
};

export function setPlusAction(onClick) {
	return {
		type: "general/SET_PLUS_ACTION",
		onClick
	};
}

export function removePlusAction() {
	return {
		type: "general/REMOVE_PLUS_ACTION"
	};
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "general/SET_PLUS_ACTION":
			return {
				...state,
				plusAction: action.onClick
			};
		case "general/REMOVE_PLUS_ACTION":
			return {
				...state,
				plusAction: undefined
			};
		default:
			return state;
	}
}
