import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { get } from 'lodash';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import moment from 'moment';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  getStagesPermissions,
  listProjectStages,
  readStage,
  updateStage,
  updateStageOrder,
  updateStageStatus,
} from '../../redux/actions/table/stages';
import classNames from 'classnames';
import Dropdown from '../../components/Dropdown';
import styles from '../../styles/views/stages/Stages.scss';
import {
  __,
  dateFrom,
  getActiveProject,
  getActiveStage,
  getLocalizedListValue,
  getURLParam,
  redirect,
  validateForm,
} from '../../core/utils';
import autobind from 'autobind-decorator';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import MoveRowUpIcon from '../../assets/images/move-row-up-15x15.svg';
import MoveRowDownIcon from '../../assets/images/move-row-down-15x15.svg';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import StatusIcon from '../../assets/images/status-15x15.svg';
import EditStageForm from '../../forms/stages/EditStageForm';
import {
  enableProjectsDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import PlanSetIcon from '../../assets/images/plan-set-15x15.svg';
import TasksIcon from '../../assets/images/tasks-16x16.svg';

const mapStateToProps = (state) => {
  return {
    table: state.table['stages'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.stages/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};
@connect(mapStateToProps, mapDispatchToProps)
class Stages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_project_administrator: false,
    };
  }

  componentDidMount() {
    enableProjectsDropdown(({ project_id }) =>
      redirect('/projects/' + project_id + '/stages')
    );

    getStagesPermissions(this.props.match.params.project).then(
      ({ response }) => {
        this.setState({
          is_project_administrator: _.get(response.data, 'administrator'),
        });

        createSidebar({
          title: __('submenu.title.project'),
          items: [
            {
              title: __('submenu.title.stages'),
              link: () => '/projects/' + getActiveProject() + '/stages',
              active: true,
            },
            {
              title: __('submenu.title.teams'),
              link: () => '/stages/' + getActiveStage() + '/teams',
            },
            {
              title: __('submenu.title.approvers'),
              link: () => '/stages/' + getActiveStage() + '/approvers',
            },
            {
              title: __('submenu.title.project-properties'),
              link: () => '/projects/' + getActiveProject() + '/general',
            },
            {
              title: __('submenu.title.import_spreadsheet'),
              link: () => '/import/' + getActiveStage(),
            },
          ],
        });

        this.state.is_project_administrator &&
          setPlusActions([
            {
              title: __('wizard.add-stage.title.create-new-stage'),
              onClick: () =>
                this.props.actions.setTableParams({
                  wizard_active: true,
                }),
            },
          ]);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readStage(this.props.table.clicked_row).then(({ response }) => {
        const data = _.transform(
          response.data,
          (data, value, key) => {
            data[key] = _.has(value, 'date')
              ? moment(value).format('YYYY-MM-DD')
              : value;
          },
          {}
        );

        this.setState({ preview: data });
      });
    }
  }

  @autobind
  _setStageStatus(e, stage_id, status) {
    e.stopPropagation();

    updateStageStatus(stage_id, status).then(() => {
      listProjectStages(this.props.match.params.project);

      setNotification(__('project.stages.notification.success-update'));

      hideDropdown();
    });
  }

  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateStage(formData.id, _.omit(formData, ['id'])).then(() => {
        listProjectStages(this.props.match.params.project);

        readStage(getURLParam('id')).then(({ response }) => {
          const data = _.transform(
            response.data,
            (data, value, key) => {
              data[key] = _.has(value, 'date')
                ? moment(value).format('YYYY-MM-DD')
                : value;
            },
            {}
          );

          this.setState({ preview: data });
        });

        listActiveModules();

        setNotification(__('project.stages.notification.saved'));
      })
    );
  }

  render() {
    const singleActions = (stage) => [
      // <Tooltip text={__('projects.tooltips.stages.make-current')} key={_.uniqueId()}><MakeCurrentStageIcon
      //     onClick={e => {
      //         e.stopPropagation();
      //
      //         updateStageCurrent(stage).then(() => {
      //             listProjectStages(this.props.match.params.project);
      //
      //             setNotification(__("project.stages.notification.current-successfully-set"));
      //
      //             this.props.actions.setTableParams({
      //                 actioned_row: undefined
      //             });
      //         });
      //     }}
      // /></Tooltip>,
      <Tooltip text={__('projects.tooltips.stages.move-up')} key={_.uniqueId()}>
        <MoveRowUpIcon
          onClick={(e) => {
            e.stopPropagation();

            updateStageOrder(stage, 'up').then(() => {
              listProjectStages(this.props.match.params.project);

              setNotification(
                __('project.stages.notification.order-successfully-set')
              );

              this.props.actions.setTableParams({
                actioned_row: undefined,
              });

              listActiveModules();
            });
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__('projects.tooltips.stages.move-down')}
        key={_.uniqueId()}
      >
        <MoveRowDownIcon
          onClick={(e) => {
            e.stopPropagation();

            updateStageOrder(stage, 'down').then(() => {
              listProjectStages(this.props.match.params.project);

              setNotification(
                __('project.stages.notification.order-successfully-set')
              );

              this.props.actions.setTableParams({
                actioned_row: undefined,
              });

              listActiveModules();
            });
          }}
        />
      </Tooltip>,
    ];

    const stage_statuses = [
      {
        value: 1,
        label: __('project-settings.stages.flyout.status.not-started'),
      },
      {
        value: 2,
        label: __('project-settings.stages.flyout.status.started'),
      },
      {
        value: 3,
        label: __('project-settings.stages.flyout.status.finished'),
      },
    ];

    const columns = [
      {
        key: 'order',
        name: '#',
        required: true,
        default: true,
        width: 50,
      },
      {
        key: 'name',
        name: __('project.stages.column.title'),
        required: true,
        default: true,
      },
      {
        key: 'plans',
        sortable: false,
        name: __('Plans'),
        value: (value, row) => (
          <PlanSetIcon
            className={styles.plans}
            onClick={() => redirect('/stages/' + row.id + '/plans/current')}
          />
        ),
        width: 50,
      },
      {
        key: 'tasks',
        sortable: false,
        name: __('Tasks'),
        value: (value, row) => (
          <TasksIcon
            className={styles.tasks}
            onClick={() => redirect('/stages/' + row.id + '/tasks')}
          />
        ),
        width: 50,
      },
      {
        key: 'description',
        name: __('project.stages.column.description'),
        sortable: false,
      },
      {
        key: 'start_date',
        name: __('project.stages.column.start'),
        value: (value) => dateFrom(value),
        width: 80,
      },
      {
        key: 'end_date',
        name: __('project.stages.column.end'),
        value: (value) => dateFrom(value),
        width: 80,
        // }, {
        //     key: "current",
        //     name: __("project.stages.column.current"),
        //     value: current => current ? (
        //         <span className={classNames(styles.current, styles.active)}>{__('Yes')}</span>
        //     ) : (
        //         <span className={styles.current}>{__('No')}</span>
        //     ),
        //     width: 80,
      },
      {
        key: 'status',
        name: __('project.stages.column.status'),
        overflow: true,
        value: (status, row) =>
          this.state.is_project_administrator ? (
            <Dropdown
              top={23}
              right={10}
              name={'status.' + row.id}
              header={
                <div className={styles.statusDropdownHeader}>
                  <StatusIcon />
                  {__('project.stages.column.stage-status')}
                </div>
              }
              content={
                <div className={styles.statusDropdownContent}>
                  {_.map(stage_statuses, (stage_status, i) => (
                    <div
                      key={i}
                      className={classNames(
                        styles.item,
                        status === stage_status.value && styles.active
                      )}
                      onClick={(e) =>
                        this._setStageStatus(e, row.id, stage_status.value)
                      }
                    >
                      <span>{stage_status.label}</span>
                      {status === stage_status.value && (
                        <span className={styles.dot} />
                      )}
                    </div>
                  ))}
                </div>
              }
            >
              <div
                className={classNames(styles.status, styles['color' + status])}
              >
                {getLocalizedListValue(
                  stage_statuses,
                  status,
                  'label',
                  'value'
                )}{' '}
                <ArrowDownSmallIcon />
              </div>
            </Dropdown>
          ) : (
            <div
              className={classNames(styles.status, styles['color' + status])}
            >
              {getLocalizedListValue(stage_statuses, status, 'label', 'value')}
            </div>
          ),
        width: 130,
      },
    ];

    const preview = (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              <strong>{get(this.state, 'preview.order')}:</strong>{' '}
              {get(this.state, [
                'preview',
                'name',
                _.findKey(
                  get(this.state, ['preview', 'languages']),
                  (item) => item == 1
                ),
              ])}
            </span>
            <CloseBigIcon
              onClick={() =>
                this.props.actions.setTableParams({
                  clicked_row: undefined,
                })
              }
            />
          </div>
          <div className={styles.actions}>
            {/*<div className={styles.actionWrapper}>*/}
            {/*    <Tooltip text={__('projects.tooltips.stages.make-current')}><MakeCurrentStageIcon*/}
            {/*        onClick={() => {*/}
            {/*            updateStageCurrent(get(this.state, "preview.id")).then(() => {*/}
            {/*                listProjectStages(this.props.match.params.project);*/}

            {/*                setNotification(__("project.stages.notification.current-successfully-set"));*/}
            {/*            });*/}
            {/*        }}*/}
            {/*    /></Tooltip>*/}
            {/*</div>*/}
            <div className={styles.actionWrapper}>
              <Tooltip text={__('projects.tooltips.stages.move-up')}>
                <MoveRowUpIcon
                  onClick={() => {
                    updateStageOrder(get(this.state, 'preview.id'), 'up').then(
                      () => {
                        listProjectStages(this.props.match.params.project);

                        readStage(get(this.state, 'preview.id')).then(
                          ({ response }) => {
                            const data = _.transform(
                              response.data,
                              (data, value, key) => {
                                data[key] = _.has(value, 'date')
                                  ? moment(value).format('YYYY-MM-DD')
                                  : value;
                              },
                              {}
                            );

                            this.setState({ preview: data });
                          }
                        );

                        setNotification(
                          __(
                            'project.stages.notification.order-successfully-set'
                          )
                        );

                        listActiveModules();
                      }
                    );
                  }}
                />
              </Tooltip>
            </div>
            <div className={styles.actionWrapper}>
              <Tooltip text={__('projects.tooltips.stages.move-down')}>
                <MoveRowDownIcon
                  onClick={() => {
                    updateStageOrder(
                      get(this.state, 'preview.id'),
                      'down'
                    ).then(() => {
                      listProjectStages(this.props.match.params.project);

                      readStage(get(this.state, 'preview.id')).then(
                        ({ response }) => {
                          const data = _.transform(
                            response.data,
                            (data, value, key) => {
                              data[key] = _.has(value, 'date')
                                ? moment(value).format('YYYY-MM-DD')
                                : value;
                            },
                            {}
                          );

                          this.setState({ preview: data });
                        }
                      );

                      setNotification(
                        __('project.stages.notification.order-successfully-set')
                      );

                      listActiveModules();
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.scroller}>
          <div className={styles.form}>
            <EditStageForm
              isProjectAdministrator={this.state.is_project_administrator}
              onSubmit={this._handleSubmit}
              initialValues={{
                ..._.pick(get(this.state, 'preview'), [
                  'id',
                  'name',
                  'description',
                  'status',
                ]),
                date: {
                  start: get(this.state, 'preview.start_date'),
                  end: get(this.state, 'preview.end_date'),
                },
              }}
              projectLanguages={_.get(this.state, 'preview.languages')}
            />
          </div>
        </div>
      </div>
    );

    return (
      <Table
        listAction={() => listProjectStages(this.props.match.params.project)}
        name='stages'
        title={__('submenu.title.stages')}
        singleActions={this.state.is_project_administrator && singleActions}
        columns={columns}
        preview={get(this.state, 'preview') ? preview : []}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default Stages;
