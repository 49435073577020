import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_meeting_wizard/CreateMeetingForm.scss';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import { __, getActiveStage } from '../../core/utils';
import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker';
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';
import { withRouter } from 'react-router';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import {
  listPossibleMeetingAdministrators,
  listPossibleMeetingParticipants,
  listPossibleMeetingTasks,
} from '../../redux/actions/table/all_meetings';
import PeopleChooser from '../../components/PeopleChooser';
import MeetingTasksChooser from '../../components/MeetingTasksChooser';
import autobind from 'autobind-decorator';
import MultiSelect from '../../components/MultiSelect';
import WarningIcon from '../../assets/images/warning-30x30.svg';

const mapStateToProps = (state) => {
  return {
    values: getFormValues('create_meeting_form.create_meeting')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideCreateMeetingWizard: () =>
        dispatch({
          type: 'table.all_meetings/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
          },
        }),
    },
  };
};

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'create_meeting_form.create_meeting' })
@connect(mapStateToProps, mapDispatchToProps)
class CreateMeetingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_meeting_participants: [],
      possible_meeting_tasks: [],
      possible_meeting_administrators: [],
    };
  }

  componentDidMount() {
    listPossibleMeetingParticipants(
      this.props.match.params.stage || getActiveStage()
    ).then(({ response }) => {
      this.setState({ possible_meeting_participants: response.data });
    });

    listPossibleMeetingTasks(
      this.props.match.params.stage || getActiveStage()
    ).then(({ response }) => {
      this.setState({ possible_meeting_tasks: response.data });
    });

    listPossibleMeetingAdministrators(
      this.props.match.params.stage || getActiveStage()
    ).then(({ response }) => {
      this.setState({ possible_meeting_administrators: response.data });
    });
  }

  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  render() {
    const statuses = [
      {
        value: 'agenda',
        label: __('table.columns.meeting-status-agenda'),
        color: '#9C27B0',
      },
      {
        value: 'minutes',
        label: __('table.columns.meeting-status-minutes'),
        color: '#5777FC',
      },
    ];

    const visibilities = [
      {
        value: '0',
        label: __('meeting.visibility.private'),
        color: '#CCCCCA',
      },
      {
        value: '1',
        label: __('meeting.visibility.public'),
        color: '#029DFC',
      },
    ];

    const { possible_meeting_tasks } = this.state;
    const tasks = _.get(this.props.values, 'tasks', []);
    const visibility = _.get(this.props.values, 'visibility', []);

    let warnings = [];

    const selected_tasks = _.filter(possible_meeting_tasks, (task) =>
      _.includes(tasks, task.id)
    );

    if (visibility == '1') {
      if (!_.isEmpty(_.filter(selected_tasks, ['visibility', 0]))) {
        warnings.push(
          'Adding private task to a public meeting will result in converting a task visibility to public.'
        );
      }
    } else {
      if (!_.isEmpty(_.filter(selected_tasks, ['visibility', 1]))) {
        warnings.push(
          'Adding public task to a private meeting will result in updates done at task being publicly visible.'
        );
      }

      if (!_.isEmpty(_.filter(selected_tasks, ['visibility', 0]))) {
        warnings.push(
          'Adding private task to a private meeting will result in adding all meeting members as task followers.'
        );
      }
    }

    const languages = _.map(
      this.props.projectLanguages,
      (primary, language_id) => ({
        label: _.get(this.props.localization.languages, [language_id, 'name']),
        value: language_id,
      })
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.top}>
          <Field
            name='status'
            component={Select}
            label={__('table.columns.meeting-status')}
            options={statuses}
            noSort
            dropdown
            icon={<MeetingStatusIcon className={styles.icon} />}
            wrapper={(handleOpen, activeItem) => (
              <Tooltip text={__('meeting.tooltip.icon.status')}>
                <div
                  className={styles.dropdown}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <MeetingStatusIcon className={styles.icon} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
          <Field
            name='visibility'
            component={Select}
            label={__('table.columns.meeting-visibility')}
            options={visibilities}
            noSort
            dropdown
            icon={<TaskVisibilityIcon className={styles.icon} />}
            wrapper={(handleOpen, activeItem) => (
              <Tooltip text={__('meeting.tooltip.icon.visibility')}>
                <div
                  className={styles.dropdown}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <TaskVisibilityIcon className={styles.icon} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
        </div>
        <div className={styles.form}>
          {_.map(warnings, (warning, i) => (
            <div key={i} className={styles.warning}>
              <WarningIcon />
              <span>{warning}</span>
            </div>
          ))}
          <div className={styles.flex}>
            <div className={styles.f5} />
            <div className={styles.f3}>
              <Field
                name='languages'
                component={MultiSelect}
                searchable
                label={__('Meeting Languages')}
                noSort
                options={languages}
              />
            </div>
          </div>
          <Field
            name='tasks'
            component={MeetingTasksChooser}
            label={__('table.columns.meeting-tasks')}
            tasks={possible_meeting_tasks}
          />
          <div className={styles.flex}>
            <div className={styles.f5}>
              <Field
                name='date'
                component={DateTimePicker}
                label={__('table.columns.meeting-date-time')}
                time
                footer
              />
            </div>
            <div className={styles.f3}>
              <Field
                name='duration'
                component={Input}
                label={__('Duration Estimate')}
                numeric
                maxLength={4}
                hint={__('meetings.hint.in-minutes')}
              />
            </div>
          </div>
          <Field
            name='title'
            component={Input}
            localized={this.props.projectLanguages}
            label={__('table.columns.meeting-title')}
            maxLength={50}
          />
          <Field
            name='description'
            component={Textarea}
            localized={this.props.projectLanguages}
            label={__('table.columns.meeting-description')}
            maxLength={1000}
            rows={1}
            maxRows={10}
          />
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='administrators'
                component={PeopleChooser}
                label={__('table.columns.meeting-administrators')}
                searchable
                options={this.state.possible_meeting_administrators}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='participants'
                component={PeopleChooser}
                label={__('table.columns.meeting-participants')}
                searchable
                options={this.state.possible_meeting_participants}
              />
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='location_name'
                component={Input}
                label={__('table.columns.meeting-location-name')}
                maxLength={50}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='location_address'
                component={Input}
                label={__('table.columns.meeting-location-address')}
                maxLength={50}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={this.props.actions.hideCreateMeetingWizard}
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('Create')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(false)}
            />
            <Button
              lightBlue
              medium
              middleText={__('Create & Edit')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(true)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default CreateMeetingForm;
