import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getLocalized, getURLParam, mapStateToProps, setTableParams } from "../../core/utils";
import { createSidebar, createSuperadminSidebar } from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import { listLocalization } from "../../redux/actions/localization";
import {
	deleteProductAttributeTemplateFields,
	listProductAttributeTemplateFields,
	readProductAttributeTemplateField
} from "../../redux/actions/table/superadmin/product_attribute_template_fields";
import ProductAttributeTemplateFieldsFlyout
	from "../../flyouts/superadmin/product_attribute_template_fields/ProductAttributeTemplateFieldsFlyout";
import styles from "../../styles/views/superadmin/ProductAttributeTemplateFields.scss";
import TableNA from "../../components/TableNA";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_product_attribute_template_fields"],
	auth: state.auth,
	application_language_id: state.auth.language_id,
}))
class ProductAttributeTemplateFields extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		listLocalization(); //reload filters

		const { superadmin } = this.props;

		superadmin.add_product_attribute_template_field && setPlusActions([{
			title: __('products.attribute-template-field.add-product-attribute'),
			onClick: () => setTableParams('superadmin_product_attribute_template_fields', {
				add_product_attribute_template_field_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readProductAttributeTemplateField(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, application_language_id } = this.props;

		let columns = [
			{
				key: "label",
				name: __('products.attribute-template-field.label'),
				required: true,
				value: label => (
					<Tooltip text={getLocalized(label, application_language_id)}>{getLocalized(label, application_language_id)}</Tooltip>
				)
			},
			{
				key: "filter_ids",
				name: __('products.attribute-template-field.filters'),
				required: true,
				value: filters => {
					filters = _.map(filters, filter_id => getLocalized(_.get(this.props.localization.filters, [filter_id, 'name'], {}), application_language_id));

					return _.isEmpty(filters) ? <TableNA /> : <Tooltip text={_.join(filters, "\n")}>{_.join(filters, ", ")}</Tooltip>;
				}
			},
			{
				key: "description",
				name: __('products.attribute-template-field.description'),
				required: true,
				value: description => (
					<Tooltip text={getLocalized(description, application_language_id)}>{getLocalized(description, application_language_id)}</Tooltip>
				)
			},
			{
				key: "norm",
				name: __('products.attribute-template-field.norm'),
				required: true,
				value: norm => (
					<Tooltip text={norm}>{norm}</Tooltip>
				)
			},
			{
				key: "type",
				name: __('products.attribute-template-field.type'),
				width: 200,
				sortable: false,
				value: type => {
					switch (type) {
						case "boolean":
							type = __('products.attribute-template-field.type.boolean');
							break;
						case "string":
							type = __('products.attribute-template-field.type.string');
							break;
						case "dropdown_string":
							type = __('products.attribute-template-field.type.predefined-string-dropdown');
							break;
						case "dropdown_string_multiple":
							type = __('products.attribute-template-field.type.predefined-string-dropdown-multiple');
							break;
						case "numeric":
							type = __('products.attribute-template-field.type.numeric');
							break;
						case "dropdown_numeric":
							type = __('products.attribute-template-field.type.predefined-numeric-dropdown');
							break;
						case "dropdown_numeric_multiple":
							type = __('products.attribute-template-field.type.predefined-numeric-dropdown-multiple');
							break;
						case "color":
							type = __('products.attribute-template-field.type.color');
							break;
						case "material":
							type = __('products.attribute-template-field.type.material');
							break;
						case "texture":
							type = __('products.attribute-template-field.type.texture');
							break;
						case "range":
							type = __('products.attribute-template-field.type.range');
							break;
						case "range_selectable":
							type = __('products.attribute-template-field.type.selectable-range');
							break;
					}

					return <Tooltip text={type}>{type}</Tooltip>;
				}
			}, {
				key: "group_id",
				name: __('products.attribute-template-field.group'),
				width: 150,
				value: group_id => {
					const group = _.get(this.props.localization.product_attribute_groups, [group_id, 'name'], {});

					return (
						<Tooltip text={getLocalized(group, application_language_id)}>{getLocalized(group, application_language_id)}</Tooltip>
					)
				}
			}, {
				key: "options",
				name: __('products.attribute-template-field.options'),
				width: 70,
				value: (options, row) => {
					options = _.map(options, option => getLocalized(option, application_language_id));

					return _.includes(['dropdown_string', 'dropdown_string_multiple', 'dropdown_numeric', 'dropdown_numeric_multiple'], row.type) ? <Tooltip text={_.join(options, "\n")}><span className={styles.chip}>{_.size(options)}</span></Tooltip> : <TableNA />
				}
			}, {
				key: "unit_id",
				name: __('products.attribute-template-field.unit'),
				width: 70,
				value: (unit_id, row) => {
					const unit = _.get(this.props.localization.units, [unit_id, 'name']);

					return (_.includes(['numeric', 'dropdown_numeric', 'dropdown_numeric_multiple', 'range', 'range_selectable'], row.type) && unit) ? <Tooltip text={unit}>{unit}</Tooltip> : <TableNA />;
				}
			}, {
				key: "product_subgroups",
				name: __('products.attribute-template-field.assigned-product-subgroups'),
				sortable: false,
				width: 150,
				value: product_subgroups => {
					product_subgroups = _.map(product_subgroups, product_subgroup_id => _.get(this.props.localization.product_subgroups, [product_subgroup_id, 'name'], {}));

					return !_.isEmpty(product_subgroups) ? (
						<Tooltip text={_.join(product_subgroups, "\n")}>{_.join(product_subgroups, ", ")}</Tooltip>
					) : <TableNA />;
				}
			}
		];

		const singleActions = (product_attribute_template_field_id) => {
			return [
				<Tooltip text={__('products.attribute-template-field.delete-selected-product-attribute-template-field')} key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: __('products.attribute-template-field.want-delete-selected-product-attribute-template-field.process-cannot-undone'),
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteProductAttributeTemplateFields([product_attribute_template_field_id]).then(() => {
												hideAlertbox();

												setNotification(__('products.attribute-template-field.notification.selected-product-attribute-template-field-deleted'));
												listProductAttributeTemplateFields();

												listLocalization();

												setTableParams('superadmin_product_attribute_template_fields', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (product_attribute_template_field_ids) => [
			<Tooltip text={__('products.attribute-template-field.tooltip.delete-selected-product-attribute-template-fields')} key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: __('products.attribute-template-field.want-delete-product-attribute-template-fields.process-cannot-undone'),
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteProductAttributeTemplateFields(product_attribute_template_field_ids).then(() => {
											hideAlertbox();

											setNotification(__('products.attribute-template-field.notification.selected-product-attribute-template-fields-deleted'));
											listProductAttributeTemplateFields();

											listLocalization();

											setTableParams('superadmin_product_attribute_template_fields', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<ProductAttributeTemplateFieldsFlyout
				data={this.state.preview}
				readAction={product_attribute_template_field_id => {
					return new Promise(resolve => {
						readProductAttributeTemplateField(getURLParam('id') || product_attribute_template_field_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listProductAttributeTemplateFields}
				name="superadmin_product_attribute_template_fields"
				groupActions={superadmin.delete_product_attribute_template_field && groupActions}
				singleActions={superadmin.delete_product_attribute_template_field && singleActions}
				title={__('modules.submodules.title.product-attribute-template-fields')}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default ProductAttributeTemplateFields;
