import store from "../../../../core/store";

export const listTranslations = () => {
	return store.dispatch({
		action: "table.superadmin_translations/LIST_TRANSLATIONS",
		method: "GET",
		endpoint: "/superadmin/translations",
		main: true
	});
};

export const createTranslation = (formData) => {
	return store.dispatch({
		action: "table.superadmin_translations/CREATE_TRANSLATION",
		method: "POST",
		endpoint: "/superadmin/translations",
		data: formData
	});
};

export const deleteTranslations = (translation_ids) => {
	return store.dispatch({
		action: "table.superadmin_translations/DELETE_TRANSLATIONS",
		method: "DELETE",
		endpoint: "/superadmin/translations",
		data: {
			translations: translation_ids
		}
	});
};

export const readTranslation = (translation_id) => {
	return store.dispatch({
		action: "table.superadmin_translations/READ_TRANSLATION",
		method: "GET",
		endpoint: "/superadmin/translations/" + translation_id,
		has404: true
	});
};

export const updateTranslation = (translation_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_translations/UPDATE_TRANSLATION",
		method: "PUT",
		endpoint: "/superadmin/translations/" + translation_id,
		data: formData
	});
};