const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.multiedit/REGISTER_MULTIEDIT_FIELD":
            return {
                ...state,
                [action.form]: {
                    ..._.get(state, action.form, {}),
                    fields: [
                        ..._.get(state, [action.form, 'fields'], []),
                        action.field
                    ]
                }
            };
        case "general.multiedit/UNREGISTER_MULTIEDIT_FIELD":
            return {
                ...state,
                [action.form]: {
                    ..._.get(state, action.form, {}),
                    fields: _.reject(_.get(state, [action.form, 'fields'], []), field => field == action.field),
                    reset: _.reject(_.get(state, [action.form, 'reset'], []), field => field == action.field),
                }
            };
        case "general.multiedit/TOGGLE_MULTIEDIT_CLEAR_ALL":
            return {
                ...state,
                [action.form]: {
                    ..._.get(state, action.form, {}),
                    reset: _.xor(_.get(state, [action.form, 'reset'], []), [action.field])
                }
            };
        case "general.multiedit/ENABLE_MULTIEDIT":
            return {
                ...state,
                [action.form]: {}
            };
        case "general.multiedit/DISABLE_MULTIEDIT":
            return {
                ...state,
                [action.form]: undefined
            };
        case "@@redux-form/INITIALIZE":
            return {
                ...state,
                [action.meta.form]: undefined
            };
        case "@@redux-form/RESET":
            return {
                ...state,
                [action.meta.form]: _.isObject(state[action.meta.form]) ? {} : undefined
            };
        default:
            return state;
    }
}