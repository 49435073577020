import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_meeting_followup_meeting_wizard/BasicAttributesForm.scss';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import { __, setTableParams } from '../../core/utils';
import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker';
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';
import { withRouter } from 'react-router';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import {
  listPossibleMeetingAdministrators,
  listPossibleMeetingParticipants,
} from '../../redux/actions/table/all_meetings';
import PeopleChooser from '../../components/PeopleChooser';
import MultiSelect from '../../components/MultiSelect';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';

@subscribe(Localization, 'localization')
@withRouter
@reduxForm({
  destroyOnUnmount: false,
  form: 'create_followup_meeting_wizard.basic_attributes',
})
class BasicAttributesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_meeting_participants: [],
      possible_meeting_administrators: [],
    };
  }

  componentDidMount() {
    listPossibleMeetingParticipants(this.props.stageId).then(({ response }) => {
      this.setState({ possible_meeting_participants: response.data });
    });

    listPossibleMeetingAdministrators(this.props.stageId).then(
      ({ response }) => {
        this.setState({ possible_meeting_administrators: response.data });
      }
    );
  }

  render() {
    const statuses = [
      {
        value: 'agenda',
        label: __('table.columns.meeting-status-agenda'),
        color: '#9C27B0',
      },
      {
        value: 'minutes',
        label: __('table.columns.meeting-status-minutes'),
        color: '#5777FC',
      },
    ];

    const visibilities = [
      {
        value: '0',
        label: __('meeting.visibility.private'),
        color: '#CCCCCA',
      },
      {
        value: '1',
        label: __('meeting.visibility.public'),
        color: '#029DFC',
      },
    ];

    const languages = _.map(
      this.props.projectLanguages,
      (primary, language_id) => ({
        label: _.get(this.props.localization.languages, [language_id, 'name']),
        value: language_id,
      })
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.top}>
          <Field
            name='status'
            component={Select}
            label={__('table.columns.meeting-status')}
            options={statuses}
            noSort
            dropdown
            icon={<MeetingStatusIcon className={styles.icon} />}
            wrapper={(handleOpen, activeItem) => (
              <Tooltip text={__('meeting.tooltip.icon.status')}>
                <div
                  className={styles.dropdown}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <MeetingStatusIcon className={styles.icon} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
          <Field
            name='visibility'
            component={Select}
            label={__('table.columns.meeting-visibility')}
            options={visibilities}
            noSort
            dropdown
            icon={<TaskVisibilityIcon className={styles.icon} />}
            wrapper={(handleOpen, activeItem) => (
              <Tooltip text={__('meeting.tooltip.icon.visibility')}>
                <div
                  className={styles.dropdown}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <TaskVisibilityIcon className={styles.icon} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
        </div>
        <div className={styles.form}>
          <div className={styles.flex}>
            <div className={styles.f5} />
            <div className={styles.f3}>
              <Field
                name='languages'
                component={MultiSelect}
                searchable
                label={__('Meeting Languages')}
                noSort
                options={languages}
              />
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f5}>
              <Field
                name='date'
                component={DateTimePicker}
                label={__('table.columns.meeting-date-time')}
                time
                footer
              />
            </div>
            <div className={styles.f3}>
              <Field
                name='duration'
                component={Input}
                label={__('Duration Estimate')}
                numeric
                maxLength={4}
                hint={__('meetings.hint.in-minutes')}
              />
            </div>
          </div>
          <Field
            name='title'
            component={Input}
            localized={this.props.projectLanguages}
            label={__('table.columns.meeting-title')}
            maxLength={50}
          />
          <Field
            name='description'
            component={Textarea}
            localized={this.props.projectLanguages}
            label={__('table.columns.meeting-description')}
            maxLength={1000}
            rows={1}
            maxRows={10}
          />
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='administrators'
                component={PeopleChooser}
                label={__('table.columns.meeting-administrators')}
                searchable
                options={this.state.possible_meeting_administrators}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='participants'
                component={PeopleChooser}
                label={__('table.columns.meeting-participants')}
                searchable
                options={this.state.possible_meeting_participants}
              />
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='location_name'
                component={Input}
                label={__('table.columns.meeting-location-name')}
                maxLength={50}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='location_address'
                component={Input}
                label={__('table.columns.meeting-location-address')}
                maxLength={50}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('all_meetings', {
                create_followup_meeting_wizard: undefined,
              })
            }
          />
          <Button
            submit
            medium
            lightBlue
            leftText={__('button.next')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default BasicAttributesForm;
