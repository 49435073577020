import store from '../../../core/store';
import { downloadFile } from '../general/downloader';
import { backendURL } from '../../../core/utils';

export const uploadPlan = (team_id, file) => {
  const id = _.uniqueId('file');

  store.dispatch({
    type: 'table.plan_uploads/UPLOAD_PLAN',
    file: {
      ..._.pick(file, ['name', 'size']),
      id,
      progress: 0,
    },
  });

  return store.dispatch({
    action: 'table.plan_uploads/UPLOAD_PLAN',
    method: 'POST',
    preloaded: false,
    endpoint: '/teams/' + team_id + '/plans',
    progress: true,
    id,
    data: {
      file,
    },
  });
};

export const listPlanUploads = (stage_id) => {
  return store.dispatch({
    action: 'table.plan_uploads/LIST_PLAN_UPLOADS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/plans/uploads',
    main: true,
  });
};

export const changePlanStatus = (plan, processed) => {
  store.dispatch({
    type: 'table.plan_uploads/CHANGE_PLAN_STATUS',
    plan,
    processed,
  });
};

export const minimizePlanUploads = () => {
  store.dispatch({
    type: 'table.plan_uploads/MINIMIZE_PLAN_UPLOADS',
  });
};

export const hidePlanUploadFile = (plan) => {
  store.dispatch({
    type: 'table.plan_uploads/HIDE_PLAN_UPLOAD_FILE',
    plan,
  });
};

export const listPossibleTeams = (stage_id) => {
  return store.dispatch({
    action: 'table.plan_uploads/LIST_POSSIBLE_TEAMS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_teams',
  });
};

export const readPlanUpload = (plan_id) => {
  return store.dispatch({
    action: 'table.plan_uploads/READ_PLAN_UPLOAD',
    method: 'GET',
    endpoint: '/plans/uploads/' + plan_id,
  });
};

export const updatePlanUpload = (plan_id, formData) => {
  return store.dispatch({
    action: 'table.plan_uploads/UPDATE_PLAN_UPLOAD',
    method: 'PUT',
    endpoint: '/plans/uploads/' + plan_id,
    data: formData,
  });
};

export const publishPlanUploads = (plan_ids) => {
  return store.dispatch({
    action: 'table.plan_uploads/PUBLISH_PLAN_UPLOADS',
    method: 'POST',
    endpoint: '/plans/uploads/publish',
    data: {
      plans: plan_ids,
    },
  });
};

export const rotatePlanUpload = (plan_id, rotation) => {
  return store.dispatch({
    action: 'table.plan_uploads/ROTATE_PLAN_UPLOAD',
    method: 'PUT',
    endpoint: '/plans/uploads/' + plan_id + '/rotate',
    data: {
      rotation: rotation,
    },
  });
};

export const deletePlanUploads = (plan_ids) => {
  return store.dispatch({
    action: 'table.plan_uploads/DELETE_PLAN_UPLOADS',
    method: 'DELETE',
    endpoint: '/plans/uploads',
    data: {
      plans: plan_ids,
    },
  });
};

export const uploadPlanFile = (plan_id, files) => {
  return store.dispatch({
    action: 'table.plan_uploads/UPLOAD_PLAN_FILE',
    method: 'POST',
    preloaded: false,
    endpoint: '/plans/' + plan_id + '/files',
    data: {
      files,
    },
  });
};

export const downloadPlanFile = (plan_id, attachment_id) => {
  return downloadFile(
    backendURL + '/plans/' + plan_id + '/files/' + attachment_id,
    [],
    'POST'
  );
};

export const multipleEditPlanScale = (plan_ids, scale) => {
  return store.dispatch({
    action: 'table.plan_uploads/MULTIPLE_EDIT_PLAN_SCALE',
    method: 'PUT',
    endpoint: '/plans/scale',
    data: {
      scale,
      plans: plan_ids,
    },
  });
};

export const multipleEditPlanTags = (plan_ids, tags) => {
  return store.dispatch({
    action: 'table.plan_uploads/MULTIPLE_EDIT_PLAN_TAGS',
    method: 'PUT',
    endpoint: '/plans/tags',
    data: {
      tags,
      plans: plan_ids,
    },
  });
};

export const startPlansOCR = (ocr_template_id, plan_ids) => {
  return store.dispatch({
    action: 'table.plan_uploads/START_PLANS_OCR',
    method: 'POST',
    endpoint: '/plans/ocr',
    data: {
      ocr_template_id,
      plans: plan_ids,
    },
  });
};

export const changePlanOCRProcessingStatus = (plan, status) => {
  store.dispatch({
    type: 'table.plan_uploads/CHANGE_PLAN_OCR_PROCESSING_STATUS',
    plan,
    status,
  });
};

export const updatePlanSpaceAllocation = (plan_id, formData) => {
  return store.dispatch({
    action: 'table.plan_uploads/UPDATE_TASK_SPACE_ALLOCATION',
    method: 'PUT',
    endpoint: '/plans/' + plan_id + '/space_allocation',
    data: formData,
  });
};
