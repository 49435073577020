import * as _ from 'lodash';
import styles from '../../../styles/views/tenders/TenderBOQ.scss';
import {
  unformatMoney,
  formatMoney,
  getLocalized,
  __,
  setTableParams,
} from '../../../core/utils';
import ArrowDoubleRightSmallIcon from '../../../assets/images/arrow-double-right-small-15x15.svg';
import Dropdown from '../../../components/Dropdown';
import classNames from 'classnames';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import BaseTable from 'react-base-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ExpandIcon from './ExpandIcon';
import Row from './Row';
import NumericInput from './NumericInput';
import RoomChooser from '../../../components/RoomChooser';
import Tooltip from '../../../components/Tooltip';
import CommentIconSmallIcon from '../../../assets/images/comments-16x16.svg';
import { readWorkingSetSpecification } from '../../../redux/actions/table/working_set_specifications';
import {
  readTenderSpecification,
  updateTenderBOQCalculationPrice,
  updateTenderBOQCalculationStatus,
  updateTenderBOQCalculationComment,
} from '../../../redux/actions/table/tenders';
import Select from '../../../components/Select';
import { hideDropdown } from '../../../redux/actions/general/dropdown';
import { withRouter } from 'react-router';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';
import TextareaAutosize from 'react-autosize-textarea';

const TableBidder = ({
  focusedId,
  setFocusedId,
  data,
  refreshData,
  setRef,
  languageId,
  setNameRef,
  projectLanguageId,
  projectCurrency,
  localization,
  tableRef,
  spaceAllocationData,
  setExpandedRows,
  expandedRows,
  match,
}) => {
  const boq_calculation_statuses = [
    {
      value: 'empty',
      label: __('tender-boq.boq_calculation_status.empty'),
      color: '#CCCDD1',
      disabled: ['available', 'substitute'],
    },
    {
      value: 'available',
      label: __('tender-boq.boq_calculation_status.available'),
      color: '#5777FC',
      disabled: ['empty'],
    },
    {
      value: 'substitute',
      label: __('tender-boq.boq_calculation_status.substitute'),
      color: '#9C27B0',
      disabled: ['empty'],
    },
    {
      value: 'not_available',
      label: __('tender-boq.boq_calculation_status.not_available'),
      color: '#E62A10',
      disabled: ['empty'],
    },
  ];

  const {
    specification_groups,
    specification_subgroups,
    units,
    boq_supply_types,
  } = localization;

  const final_data = useMemo(() => {
    const rows = data;

    const process = (rows) =>
      _.map(rows, (row) => {
        return {
          ...row,
          children: _.isEmpty(row.children) ? undefined : process(row.children),
        };
      });

    return process(rows);
  }, [data]);

  const _handlePriceChange = useCallback((id, price, status) => {
    const calculation_id = _.get(_.split(id, '-', 5), 3);

    updateTenderBOQCalculationPrice(calculation_id, unformatMoney(price)).then(
      () => {
        if (price != 0 && status == 'empty') {
          return _handleStatusChange(id, 'available');
        }

        if (price == 0 && status != 'not_available') {
          return _handleStatusChange(id, 'empty');
        }

        return refreshData();
      }
    );
  }, []);

  const _handleStatusChange = useCallback((id, status, comment) => {
    const calculation_id = _.get(_.split(id, '-', 5), 3);

    updateTenderBOQCalculationStatus(calculation_id, status).then(() => {
      if (status === 'not_available') {
        return _handlePriceChange(id, 0, status);
      }

      if (status === 'available' && !_.isEmpty(comment)) {
        return _handleCommentChange(id, '');
      }

      return refreshData();
    });

    hideDropdown();
  }, []);

  const _handleCommentChange = useCallback((id, comment) => {
    const calculation_id = _.get(_.split(id, '-', 5), 3);

    updateTenderBOQCalculationComment(calculation_id, comment).then(() =>
      refreshData()
    );

    hideDropdown();
  }, []);

  const _handleMasterExpand = (expanded_rows, unallocated_collapsed) => {
    setExpandedRows(expanded_rows); //set expanded rows in local state
    tableRef.setExpandedRowKeys(expanded_rows); //set expanded rows in dependency state
  };

  const total = _.sumBy(data, (group) => group.subtotal);

  const space_allocation = useMemo(() => {
    const space_allocation = [];

    _.each(spaceAllocationData.sites, (site) =>
      space_allocation.push({
        id: site.id,
        type: 'site',
        display_name: getLocalized(site.title, languageId),
        parent_type: null,
        parent_id: null,
      })
    );

    _.each(spaceAllocationData.buildings, (building) =>
      space_allocation.push({
        id: building.id,
        type: 'building',
        display_name: getLocalized(building.title, languageId),
        parent_type: building.site_id ? 'site' : null,
        parent_id: building.site_id ? building.site_id : null,
      })
    );

    _.each(spaceAllocationData.storeys, (storey) =>
      space_allocation.push({
        id: storey.id,
        type: 'storey',
        display_name: getLocalized(storey.name, languageId),
        parent_type: storey.building_id ? 'building' : null,
        parent_id: storey.building_id ? storey.building_id : null,
      })
    );

    _.each(spaceAllocationData.rooms, (room) =>
      space_allocation.push({
        id: room.id,
        type: 'room',
        display_name: getLocalized(room.name, languageId),
        parent_type: room.storey_id ? 'storey' : null,
        parent_id: room.storey_id ? room.storey_id : null,
      })
    );

    return space_allocation;
  }, [languageId, spaceAllocationData]);

  let columns = [
    {
      key: 'name',
      title: 'Title',
      width: 600,
      minWidth: 500,
      resizable: true,
      sortable: false,
      frozen: 'left',
      cellRenderer: ({ rowData }) => (
        <>
          {rowData.level < 3 ? (
            <span className={styles.code}>{rowData.code}</span>
          ) : (
            <span className={styles.codePlaceholder} />
          )}
          <div
            className={classNames(
              styles.nameContainer,
              !_.isEmpty(rowData.description)
            )}
          >
            <div className={styles.descriptionRow}>
              <div className={styles.shortDescriptionRow}>
                {rowData.level === 4 && <span>&#x2212;&nbsp;&nbsp;</span>}
                <div
                  className={classNames(
                    styles.input,
                    (rowData.level === 2 || rowData.level === 3) &&
                      styles.longDescription,
                    rowData.level != 4 && styles.bold
                  )}
                >
                  {_.get(rowData.name, languageId)}
                </div>
              </div>
              {(rowData.level === 2 || rowData.level === 3) &&
                !_.isEmpty(rowData.description) && (
                  <div className={styles.longDescriptionRow}>
                    {_.get(rowData.description, languageId)}
                  </div>
                )}
            </div>
          </div>
        </>
      ),
    },
    {
      key: 'spec',
      title: 'Spec',
      width: 100,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3) {
          if (rowData.specification_code_id) {
            const specification_subgroup =
              _.get(specification_subgroups, [
                rowData.specification.subgroup_id,
              ]) || {};
            const specification_group =
              _.get(
                specification_groups,
                _.get(specification_subgroup, 'specification_group_id')
              ) || {};

            const specification_code =
              getLocalized(specification_group.code, projectLanguageId, 1) +
              specification_subgroup.code +
              _.padStart(rowData.specification.code, 2, '0');

            return (
              <Tooltip text='Go to specification'>
                <div
                  className={styles.spec}
                  onClick={() => {
                    readTenderSpecification(
                      match.params.tender,
                      rowData.specification.id
                    ).then(({ response }) => {
                      setTableParams('working_set_specifications', {
                        flyout: response.data,
                        clicked_row: response.data.id,
                      });
                    });
                  }}
                >
                  {specification_code}
                  <ArrowDoubleRightSmallIcon />
                </div>
              </Tooltip>
            );
          } else {
            return (
              <div className={styles.noSpec}>
                (No spec.)
                <ArrowDoubleRightSmallIcon />
              </div>
            );
          }
        }
      },
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataKey: 'quantity',
      width: 120,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 || rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.quantity}
              readOnly={true}
              tooltip={
                rowData.level === 3 && !_.isEmpty(rowData.children)
                  ? 'This field is auto-calculated'
                  : ''
              }
              defaultValue={rowData.quantity}
            />
          );
        }
      },
    },
    {
      key: 'unit',
      title: 'Unit',
      width: 70,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        rowData.level === 3 ? (
          <Dropdown
            readOnly
            top={25}
            right={-15}
            wrapperClassName={classNames(
              styles.dropdownWrapper,
              styles.disabled
            )}
            name={'unit.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Unit</div>}
          >
            <div className={classNames(styles.dropdown, styles.disabled)}>
              {rowData.unit_id && (
                <span>{_.get(units, [rowData.unit_id, 'symbol'])}</span>
              )}
              {!rowData.unit_id && <span className={styles.none}>--</span>}
              <ArrowDownSmallIcon />
            </div>
          </Dropdown>
        ) : (
          ''
        ),
    },
    {
      key: 'price',
      title: `Price (${projectCurrency})`,
      width: 100,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 || rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.price}
              onBlur={(value) => {
                _handlePriceChange(rowData.id, value, rowData.status);
              }}
              readOnly={rowData.level === 4}
              tooltip={
                rowData.level === 4 ? 'This field is auto-calculated' : ''
              }
              defaultValue={rowData.price}
            />
          );
        }
      },
    },
    {
      key: 'subtotal',
      title: 'Total',
      width: 110,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        return formatMoney(rowData.subtotal);
      },
    },

    {
      key: 'comment',
      title: <CommentIconSmallIcon />,
      width: 50,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3) {
          const commentValue = rowData.comment;
          const disabled = _.includes(['available'], rowData.status);

          return (
            <Dropdown
              top={1}
              left={-10}
              readOnly={disabled}
              leftStyled
              wrapperClassName={classNames(
                styles.dropdownWrapper,
                styles.commentWrapper
              )}
              name={'comment.' + rowData.id}
              content={
                <div
                  className={classNames(
                    styles.dropdownContent,
                    styles.commentContent
                  )}
                >
                  <TextareaAutosize
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length
                      )
                    }
                    autoFocus
                    rows={3}
                    maxRows={15}
                    defaultValue={commentValue}
                    key={rowData.id + '_textarea'}
                    maxLength={3000}
                    onBlur={(e) => {
                      e.preventDefault();

                      _handleCommentChange(rowData.id, e.currentTarget.value);
                    }}
                  />

                  <div className={styles.saveFooter}>
                    <ButtonGroup right>
                      <Button
                        lightBlue
                        medium
                        middleText={__('button.done')}
                        onClick={() => {}}
                      />
                    </ButtonGroup>
                  </div>
                </div>
              }
            >
              <div
                className={classNames(
                  styles.comment,
                  commentValue && styles.active
                )}
              >
                <CommentIconSmallIcon />
              </div>
            </Dropdown>
          );
        }
      },
    },

    {
      key: 'availability',
      title: 'Availability',
      width: 150,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3) {
          const rowStatus = rowData.status;

          const selectedStatus = _.find(
            boq_calculation_statuses,
            (item) => item.value === rowStatus
          );

          const statuses = _.filter(boq_calculation_statuses, (status) => {
            const disabled = !_.includes(
              [...selectedStatus.disabled, selectedStatus.value],
              status.value
            );

            return selectedStatus.value == 'empty'
              ? disabled
              : disabled && !_.toNumber(rowData.price) == 0;
          });

          return (
            <Dropdown
              top={30}
              left={-10}
              //readOnly={rowStatus === 'empty'}
              leftStyled
              wrapperClassName={styles.dropdownWrapper}
              name={'area.' + rowData.id}
              header={
                <div className={styles.dropdownHeader}>
                  {__('tender-boq.tooltip.boq_calculation_status')}
                </div>
              }
              content={
                <div className={styles.dropdownContent}>
                  {_.map(statuses, (status, i) => (
                    <div
                      key={i}
                      className={classNames(styles.option)}
                      // style={{ color: status.color }}
                      onClick={(e) => {
                        e.stopPropagation();

                        _handleStatusChange(
                          rowData.id,
                          status.value,
                          rowData.comment
                        );
                      }}
                    >
                      <span>{status.value}</span>

                      <span className={styles.dot} />
                    </div>
                  ))}
                </div>
              }
            >
              <div
                className={classNames(
                  styles.dropdownStatus
                  // rowStatus == 'empty' && styles.disabled
                )}
                style={{ background: selectedStatus.color }}
              >
                <div className={styles.button}>
                  <span>{rowStatus}</span>

                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Dropdown>
          );
        }
      },
    },

    {
      key: 'placeholder',
      title: '',
      width: 0,
      resizable: false,
      sortable: false,
      frozen: 'right',
    },

    {
      key: 'percentage',
      title: '%',
      width: 70,
      align: 'right',
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        return formatMoney(_.round((rowData.subtotal / total) * 100, 2));
      },
    },
    // {
    //   key: 'boq_supply_type_id',
    //   title: 'Type',
    //   width: 130,
    //   resizable: false,
    //   sortable: false,
    //   cellRenderer: ({ rowData }) =>
    //     rowData.level === 3 ? (
    //       <Dropdown
    //         readOnly
    //         top={25}
    //         right={-20}
    //         wrapperClassName={styles.dropdownWrapper}
    //         name={'supply_type.' + rowData.id}
    //         header={<div className={styles.dropdownHeader}>Type</div>}
    //       >
    //         <div className={styles.dropdown}>
    //           {rowData.boq_supply_type_id && (
    //             <Tooltip
    //               text={getLocalized(
    //                 _.get(boq_supply_types, [
    //                   rowData.boq_supply_type_id,
    //                   'name',
    //                 ]),
    //                 languageId
    //               )}
    //             >
    //               <span>
    //                 {getLocalized(
    //                   _.get(boq_supply_types, [
    //                     rowData.boq_supply_type_id,
    //                     'name',
    //                   ]),
    //                   languageId
    //                 )}
    //               </span>
    //             </Tooltip>
    //           )}
    //           {!rowData.boq_supply_type_id && (
    //             <span className={styles.none}>--</span>
    //           )}
    //           <ArrowDownSmallIcon />
    //         </div>
    //       </Dropdown>
    //     ) : (
    //       ''
    //     ),
    // },
    // {
    //   key: 'area',
    //   title: 'Area',
    //   width: 150,
    //   resizable: false,
    //   sortable: false,
    //   cellRenderer: ({ rowData }) => {
    //     if (rowData.level === 4) {
    //       let area;

    //       if (rowData.area_id) {
    //         area =
    //           _.find(space_allocation, {
    //             type: rowData.area_type,
    //             id: rowData.area_id,
    //           }) || {};
    //       }

    //       return (
    //         <Dropdown
    //           readOnly
    //           top={25}
    //           right={-20}
    //           wrapperClassName={styles.dropdownWrapper}
    //           name={'area.' + rowData.id}
    //           header={
    //             <div className={styles.dropdownHeader}>
    //               {__('table.area-tab.area')}
    //             </div>
    //           }
    //           content={
    //             <div
    //               className={classNames(styles.dropdownContent, styles.space)}
    //             >
    //               <RoomChooser
    //                 onCancel={hideDropdown}
    //                 options={space_allocation}
    //                 initialValues={[]}
    //               />
    //             </div>
    //           }
    //         >
    //           <div className={classNames(styles.dropdown, styles.areaDropdown)}>
    //             {area ? (
    //               <Tooltip text={area.display_name}>
    //                 <span>{area.display_name}</span>
    //               </Tooltip>
    //             ) : (
    //               <span className={styles.none}>--</span>
    //             )}{' '}
    //             <ArrowDownSmallIcon />
    //           </div>
    //         </Dropdown>
    //       );
    //     }
    //   },
    // },
    // {
    //   key: 'installation_type',
    //   title: 'F & I',
    //   width: 68,
    //   resizable: false,
    //   sortable: false,
    //   cellRenderer: ({ rowData }) =>
    //     rowData.level === 3 ? (
    //       <Dropdown
    //         readOnly
    //         top={25}
    //         right={-20}
    //         wrapperClassName={styles.dropdownWrapper}
    //         name={'installation_type.' + rowData.id}
    //         header={
    //           <div className={styles.dropdownHeader}>Furnish & Install</div>
    //         }
    //       >
    //         <div className={styles.dropdown}>
    //           {rowData.installation_type && (
    //             <span>{rowData.installation_type}</span>
    //           )}
    //           {!rowData.installation_type && (
    //             <span className={styles.none}>--</span>
    //           )}
    //           <ArrowDownSmallIcon />
    //         </div>
    //       </Dropdown>
    //     ) : (
    //       ''
    //     ),
    // },
    // {
    //   key: 'budget_type',
    //   title: 'Budget',
    //   width: 70,
    //   resizable: false,
    //   sortable: false,
    //   cellRenderer: ({ rowData }) =>
    //     rowData.level === 3 ? (
    //       <Dropdown
    //         top={25}
    //         readOnly
    //         right={0}
    //         wrapperClassName={styles.dropdownWrapper}
    //         name={'budget_type.' + rowData.id}
    //         header={<div className={styles.dropdownHeader}>Budget</div>}
    //       >
    //         <div className={styles.dropdown}>
    //           {rowData.budget_type && <span>{rowData.budget_type}</span>}
    //           {!rowData.budget_type && <span className={styles.none}>--</span>}
    //           <ArrowDownSmallIcon />
    //         </div>
    //       </Dropdown>
    //     ) : (
    //       ''
    //     ),
    // },

    // {
    //   key: 'placeholder',
    //   title: '',
    //   width: 0,
    //   resizable: false,
    //   sortable: false,
    //   frozen: 'right',
    // },
  ];

  const headerRenderer = ({ headerIndex, cells, columns }) => {
    if (headerIndex === 0) {
      return _.map(columns, (column, i) => {
        if (column.key === 'name') {
          const levels = {
            2: [],
            3: [],
            4: [],
          };

          _.each(data, (group) => {
            !_.isEmpty(group.children) && levels[2].push(group.id);

            _.each(group.children, (subgroup) => {
              !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);

              _.each(subgroup.children, (item) => {
                !_.isEmpty(item.children) && levels[4].push(item.id);
              });
            });
          });

          let active = {};

          for (let i = 2; i <= 4; i++) {
            active[i] = _.every(levels[i], (id) =>
              _.includes(expandedRows, id)
            );
          }

          return React.cloneElement(
            cells[i],
            null,
            <>
              <div className={styles.placeholder} />
              <div className={styles.masterActions}>
                <Tooltip text='Show group-level'>
                  <span
                    className={classNames(styles.active)}
                    onClick={() => _handleMasterExpand([], true)}
                  >
                    1
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[2]) ? '' : 'Show type-level'}>
                  <span
                    className={classNames(
                      active[2] && styles.active,
                      _.isEmpty(levels[2]) && styles.disabled
                    )}
                    onClick={() => _handleMasterExpand(levels[2], true)}
                  >
                    2
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[3]) ? '' : 'Show item-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && styles.active,
                      _.isEmpty(levels[3]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand([...levels[2], ...levels[3]], true)
                    }
                  >
                    3
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[4]) ? '' : 'Show cost-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && active[4] && styles.active,
                      _.isEmpty(levels[4]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand(
                        [...levels[2], ...levels[3], ...levels[4]],
                        false
                      )
                    }
                  >
                    4
                  </span>
                </Tooltip>
              </div>
              <div className='BaseTable__header-cell-text'>Title</div>
            </>
          );
        } else {
          return React.cloneElement(cells[i], null);
        }
      });
    } else {
      return _.map(columns, (column, i) => {
        let children = _.filter(cells[i].props.children, _.identity);

        let value = '';

        if (column.key === 'subtotal') {
          value = (
            <div className='BaseTable__header-cell-text'>
              {formatMoney(total)}
            </div>
          );
        }

        // if (column.key === 'comment') {
        //   value = <CommentIconSmallIcon />;
        // }

        children[column.frozen ? 1 : 0] = value;

        return React.cloneElement(cells[i], null, children);
      });
    }
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <BaseTable
            ref={(ref) => {
              setRef(ref);

              tableRef = ref;
            }}
            rowClassName={({ rowData }) =>
              classNames(
                rowData.level === 0 && styles.darkGray,
                rowData.level === 1 && styles.lightGray,
                styles.row,
                rowData.id === focusedId && styles.focused,
                rowData.id === 'UN' && styles.unallocatedGroup
              )
            }
            fixed
            rowProps={({ rowData, rowIndex }) => ({
              //tagName: Row,
              index: rowIndex,
              id: rowData.id,
              onClick: () => setFocusedId(rowData.id),
              level: rowData.level,
              parentId: rowData.parentId,
            })}
            components={{
              ExpandIcon,
            }}
            expandIconProps={({ rowData }) => ({
              depth: rowData.level,
              id: rowData.id,
            })}
            expandColumnKey='name'
            columns={columns}
            data={final_data}
            width={width}
            height={height}
            onExpandedRowsChange={setExpandedRows}
            ignoreFunctionInColumnCompare={false}
            headerHeight={[32, 32]}
            headerRenderer={headerRenderer}
            headerClassName={({ headerIndex }) =>
              classNames(headerIndex === 1 && styles.calculations)
            }
            estimatedRowHeight={32}
          />
        );
      }}
    </AutoSizer>
  );
};

export default withRouter(TableBidder);
