import React, { useState, useRef } from 'react';
import styles from '../../styles/flyouts/tenders/BidderFiles.scss';
import { withRouter } from 'react-router';
import { __, bytesToSize, dateFrom, timeFrom } from '../../core/utils';
import DownloadIcon from '../../assets/images/download-15x15.svg';
import * as _ from 'lodash';
import classNames from 'classnames';
import { setNotification } from '../../redux/actions/general/notification';
import FilePreview from '../../components/FilePreview';
import Avatar from '../../components/Avatar';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { connect } from 'react-redux';

const BidderFiles = (props) => {
  const [expanded, setExpanded] = useState([]);
  const scroll = useRef();

  const _downloadFile = (row, download = false) => {
    if (row.ext == 'pdf' && download == false) {
      window.open(row.stream_url, '_blank');
    } else {
      setNotification(__('tenders.files.download.continue'));

      window.open(row.download_url, '_blank');
    }
  };

  const _toggleCollapseCategory = (category) => {
    setExpanded(_.xor(expanded, [category]));
  };

  const { data, collapsed } = props;

  const files_group = _.groupBy(
    _.sortBy(
      _.filter(data.files, (file) => !file.is_admin),
      ['created_at']
    ),
    'creator.company'
  );

  return (
    <div
      className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      ref={scroll}
    >
      <div className={styles.scroller}>
        {_.isEmpty(files_group) ? (
          <div className={styles.empty}>
            {__('tenders.flyout.files.there-are-no-files')}
          </div>
        ) : (
          ''
        )}

        {_.map(files_group, (files, key) => {
          const lightbox_images = _.map(
            _.filter(files, (file) =>
              _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
            ),
            (file) => ({
              id: file.id,
              stream_url: file.stream_url,
              title: [file.name, file.ext].join('.'),
            })
          );

          return (
            <div className={styles.category} key={key}>
              <div
                className={styles.title}
                onClick={() => _toggleCollapseCategory(key)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    !_.includes(expanded, key) && styles.collapsed
                  )}
                />
                {key}
              </div>
              {!_.includes(expanded, key) && (
                <div className={styles.files}>
                  {_.map(files, (row, i) => {
                    return (
                      <div className={styles.row} key={i}>
                        <div className={styles.left}>
                          <FilePreview
                            onDownloadFile={_downloadFile}
                            row={row}
                            lightbox_images={lightbox_images}
                          />
                        </div>
                        <div className={styles.right}>
                          {bytesToSize(row.size)}

                          <div className={styles.avatar}>
                            <Avatar
                              size={24}
                              {...row.creator}
                              avatar_url={row.creator.avatar_url}
                              className={styles.avatar}
                            />
                          </div>

                          <div className={styles.date_time}>
                            {dateFrom(row.created_at)}
                            <br />
                            {timeFrom(row.created_at)}
                          </div>
                          <div className={styles.icons}>
                            <DownloadIcon
                              onClick={() => _downloadFile(row, true)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.id,
    clicked_row: _.get(state.table, ['tenders', 'clicked_row']),
    wizard_active: _.get(state.table, ['tenders', 'wizard_active']),
  };
};

export default withRouter(connect(mapStateToProps, null)(BidderFiles));
