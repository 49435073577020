import {
  __,
  backendURL,
  getToken,
  hidePreloader,
  redirect,
  screenIs,
  setTableParams,
  setToken,
  showPreloader,
} from './utils';
import * as axios from 'axios';
import * as _ from 'lodash';
import { startsWith } from 'lodash';
import { objectToForm } from 'object-to-form';
import appendQuery from 'append-query';
import queryString from 'query-string';
import {
  addPreloader,
  removePreloader,
} from '../redux/actions/general/preloaders';
import { submit } from 'redux-form';
import { logout } from '../redux/actions/auth';
import { setNotification } from '../redux/actions/general/notification';
import { enableMaintenanceMode } from '../redux/actions/general/mainenance_mode';

/**
 * Handling backend requests
 *
 * @param store
 * @returns {function(*=): Function}
 */
export const ajaxMiddleware = (store) => (next) => (action) => {
  if ('endpoint' in action) {
    return new Promise((resolve, reject) => {
      const cancel_token = axios.CancelToken.source();

      const request = {
        method: action.method || 'GET',
        url: backendURL + action.endpoint,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
          Token: getToken(),
        },
        responseType: 'json',
        id: action.id || _.uniqueId(),
        cancelToken: cancel_token.token,
        cancel: () => cancel_token.cancel(),
      };

      if (action.data) {
        request.data =
          request.method === 'POST' ? objectToForm(action.data) : action.data;
      }

      const request_time = new Date().getTime();

      if (action.progress) {
        request.onUploadProgress = (progress) => {
          next({
            ...request,
            type: action.action + '_PROGRESS',
            progress,
          });
        };
      }

      if (startsWith(action.action, 'table.') && action.main) {
        const table_name = _.first(_.split(action.action, '/'));
        const table_store = _.get(store.getState(), table_name);

        //only send these values to the backend
        const additional_params = _.pickBy(table_store, (value, key) => {
          if (_.includes(['page', 'limit'], key) && !table_store.pagination) {
            return false;
          }

          if (screenIs('<=', 992) && key == 'disabled_columns') {
            return false;
          }

          return (
            _.includes(
              [
                'page',
                'limit',
                'sort_by',
                'order',
                'query',
                'disabled_columns',
                'view',
              ],
              key
            ) || startsWith(key, 'filter.')
          );
        });

        setTableParams(_.replace(table_name, 'table.', ''), { loading: true });

        request['url'] = appendQuery(
          request.url,
          queryString.stringify({
            ...additional_params,
            pagination: table_store.pagination,
            order: _.includes(['asc', 'desc'], additional_params.order)
              ? additional_params.order
              : 'desc',
          })
        );
      }

      if (action.preloaded !== false) {
        showPreloader();
        addPreloader(request.id);
      }

      next({
        ...request,
        type: action.action + '_REQUEST',
      });

      //filters
      if (action.main && action.method == 'GET' && !action.filter_request) {
        axios
          .request({
            ...request,
            url: appendQuery(request.url, 'segment=filters'),
          })
          .then((response) => {
            next({
              type: action.action + '_FILTERS_SUCCESS',
              response: _.get(response, 'data'),
              status: _.get(response, 'status'),
              request,
            });
          });
      }

      axios
        .request({
          ...request,
        })
        .then((response) => {
          if (_.get(response, 'headers.token')) {
            setToken(_.get(response, 'headers.token'));
          }

          if (startsWith(action.action, 'table.') && action.main) {
            const table_name = _.first(_.split(action.action, '/'));

            setTimeout(
              () =>
                setTableParams(_.replace(table_name, 'table.', ''), {
                  loading: false,
                }),
              400
            );
          }

          if (action.preloaded !== false) {
            removePreloader(request.id);

            setTimeout(() => {
              if (_.isEmpty(store.getState().general.preloaders)) {
                hidePreloader();
              }
            }, 100);
          }

          const last_request_time = _.get(store.getState(), [
            'general',
            'requests',
            action.action + '_SUCCESS',
          ]);

          if (!last_request_time || request_time > last_request_time) {
            return resolve(
              next({
                type: action.action + '_SUCCESS',
                response: _.get(response, 'data'),
                status: _.get(response, 'status'),
                request_at: request_time,
                request,
              })
            );
          }
        })
        .catch((response) => {
          if (
            _.get(response, 'response.status') === 503 &&
            _.get(response, 'response.data.maintenance') == true
          ) {
            enableMaintenanceMode();
          }

          if (
            store.getState().auth.loggedIn &&
            _.get(response, 'response.status') === 401
          ) {
            logout();
          }

          if (
            _.get(response, 'response.status') === 404 &&
            ((startsWith(action.action, 'table.') && action.main) ||
              action.has404)
          ) {
            redirect('/');
          }

          if (_.get(response, 'response.status') === 400) {
            setNotification({
              text: __('general.no-permission-to-access'),
              type: 'warning',
            });
          }

          if (action.preloaded !== false) {
            removePreloader(request.id);

            setTimeout(() => {
              if (_.isEmpty(store.getState().general.preloaders)) {
                hidePreloader();
              }
            }, 100);
          }

          return reject(
            next({
              type: action.action + '_FAILURE',
              response: _.get(response, 'response.data'),
              status: _.get(response, 'response.status'),
              request,
            })
          );
        });
    });
  } else {
    return next(action);
  }
};

/**
 * Handling Redux Form events (for autosave)
 *
 * @param store
 * @returns {function(*): Function}
 */
export const reduxFormMiddleware = (store) => (next) => (action) => {
  if (action.type == '@@redux-form/FOCUS') {
    const value = _.get(
      store.getState(),
      'form.' + action.meta.form + '.values.' + action.meta.field
    );

    next({
      type: 'general.autosave/SET_VALUE',
      form: action.meta.form,
      field: action.meta.field,
      value,
    });

    setTimeout(() => next(action), 100); //autosave fix
  } else if (action.type == '@@redux-form/BLUR') {
    const old_value = _.get(store.getState(), [
      'general',
      'autosave',
      action.meta.form,
      'values',
      action.meta.field,
    ]);
    const new_value = _.get(
      store.getState(),
      'form.' + action.meta.form + '.values.' + action.meta.field
    );
    const autosave = _.get(store.getState(), [
      'general',
      'autosave',
      action.meta.form,
      'autosave',
    ]);

    if (old_value != new_value && autosave) {
      setTimeout(() => {
        const active = _.get(
          store.getState(),
          'form.' + action.meta.form + '.active'
        );

        if (!active) {
          next(submit(action.meta.form));
        }
      }, 100);
    }

    next({
      type: 'general.autosave/HANDLE_BLUR',
      form: action.meta.form,
    });

    return next(action);
  } else if (action.type == '@@redux-form/INITIALIZE') {
    const active = _.get(
      store.getState(),
      'form.' + action.meta.form + '.active'
    );

    next(action);

    next({
      type: '@@redux-form/FOCUS',
      meta: {
        form: action.meta.form,
        field: active,
      },
    });
  } else {
    return next(action);
  }
};
