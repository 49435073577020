import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/products/read_only/About.scss";
import * as _ from "lodash";
import classNames from "classnames";
import {__, getLocalized, mapStateToProps, setTableParams, toBoolean, validateForm} from "../../../core/utils";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import ArrowRightSmallIcon from "../../../assets/images/arrow-right-small-15x15.svg";

@mapStateToProps(state => ({
    store: state.table.products,
    application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
class About extends Component {
    render() {
        const {collapsed, store} = this.props;
        const {flyout, language_id} = store;
        const {product_categories, product_groups, product_subgroups, languages} = this.props.localization;

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                {_.size(getLocalized(flyout.description_long, language_id)) > 0 && <div className={styles.row}>
                    <strong>{__('products.flyout.details.long-description')}</strong>
                    <span>{getLocalized(flyout.description_long, language_id)}</span>
                </div>}
                <div className={styles.row}>
                    <strong>{__('products.flyout.categories')}</strong>
                    <span className={styles.categories}>
                        {_.map(flyout.subgroups, (subgroup_id, i) => {
                            const subgroup = _.get(product_subgroups, [subgroup_id]);
                            const group = _.get(product_groups, [subgroup.product_group_id]);
                            const category = _.get(product_categories, [group.product_category_id]);

                            return (
                                <div className={styles.category} key={i}>
                                    {category.name}
                                    <ArrowRightSmallIcon />
                                    {group.name}
                                    <ArrowRightSmallIcon />
                                    {subgroup.name}
                                </div>
                            );
                        })}
                    </span>
                </div>
            </div>
        );
    }
}

export default About;
