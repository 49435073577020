import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/edit_product_attribute_group_wizard/EditProductAttributeGroupForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {__, mapStateToProps, setTableParams} from "../../core/utils";
import Select from "../../components/Select";
import * as _ from "lodash";
import {withRouter} from "react-router";
import Input from "../../components/Input";

@reduxForm({
    form: "edit_product_attribute_group_wizard.edit_product_attribute_group",
})
@subscribe(Localization, "localization")
@withRouter
class EditProductAttributeGroupForm extends Component {
    render() {
        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <Field name="name" component={Input} localized={languages} label={__('products.product-attribute-flyout.add-product-group.group-name')} maxLength={50} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('products', {
                        edit_product_attribute_group_wizard: undefined,
                    })} />
                    <Button lightBlue medium submit right leftText={__('button.edit')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default EditProductAttributeGroupForm;
