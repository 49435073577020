import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_room_type_wizard/AddRoomTypeForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";

@withRouter
@reduxForm({form: "add_room_type_wizard.add_room_type"})
class AddRoomTypeForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="code" component={Input} label={__('room-types.field.code')} maxLength={6} />
                        </div>
                        <div className={styles.f4}>
                            <Field name="title" component={Input} localized={this.props.projectLanguages} label={__('room-types.field.title')} maxLength={50} />
                        </div>
                    </div>
                    <Field name="description" component={Textarea} localized={this.props.projectLanguages} label={__('room-types.field.description')} maxLength={2000} rows={1} maxRows={10} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('room_types', {
                        add_room_type_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddRoomTypeForm;
