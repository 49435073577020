import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MergeBOQItemWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getLocalized,
  mapStateToProps,
  setFormErrors,
  setTableParams,
  validateForm,
} from '../core/utils';
import LinkIcon from '../assets/images/link-16x16.svg';
import { withRouter } from 'react-router';
import {
  getBOQCalculation,
  mergeUnallocatedBOQPositionsToSpecification,
  readWorkingSetBOQ,
  reorderBOQPositions,
} from '../redux/actions/table/working_set_boq';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import MergeBOQItemForm from '../forms/merge_boq_item_wizard/MergeBOQItemForm';
import { readWorkingSetSpecification } from '../redux/actions/table/working_set_specifications';

@mapStateToProps((state) => ({
  store: state.table.working_set_boq,
  specification:
    state.table.working_set_boq.merge_boq_item_wizard.specification,
  id: state.table.working_set_boq.merge_boq_item_wizard.id,
}))
@subscribe(Localization, 'localization')
@withRouter
class MergeBOQItemWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: null,
    };
  }

  @autobind
  _handleSubmit(formData) {
    const { match } = this.props;

    const id = _.last(this.props.id.split('-'));

    if (_.isNil(formData.boq_item_id)) {
      return setFormErrors('merge_boq_item_wizard.merge_boq_item', {
        boq_item_id: ['Field required'],
      });
    }

    getBOQCalculation(id).then(({ response }) => {
      const sourcePositions = _.cloneDeep(
        _.sortBy(response.data.positions, 'order')
      );

      let boq_calculation_id;
      let boq_position_id = null;

      if (_.includes(formData.boq_item_id, '-')) {
        boq_calculation_id = _.first(formData.boq_item_id.split('-'));
        boq_position_id = _.last(formData.boq_item_id.split('-'));
      } else {
        boq_calculation_id = formData.boq_item_id;
      }

      getBOQCalculation(boq_calculation_id).then(({ response }) => {
        const destination_boq_calculation_id = response.data.id;
        const destination_boq_positions = _.cloneDeep(
          _.sortBy(response.data.positions, 'order')
        );

        const indexToInsert = boq_position_id
          ? _.findIndex(
              destination_boq_positions,
              (position) => position.id == boq_position_id
            )
          : 0;

        destination_boq_positions.splice(
          indexToInsert + (formData.move_action === 'before' ? 0 : 1),
          0,
          ...sourcePositions
        );

        const hasEmptyPositions = _.isEmpty(destination_boq_positions);
        const actions = () => {
          return mergeUnallocatedBOQPositionsToSpecification(id, {
            boq_calculation_id: destination_boq_calculation_id,
          }).then(() => {
            if (!hasEmptyPositions) {
              return reorderBOQPositions(
                destination_boq_calculation_id,
                _.map(destination_boq_positions, (row) => row.id)
              );
            }
          });
        };

        return validateForm(
          actions().then(() => {
            readWorkingSetBOQ(match.params.stage);

            setTableParams('working_set_boq', {
              merge_boq_item_wizard: undefined,
            });
          })
        );
      });
    });
  }

  componentDidMount() {
    const specification_id = this.props.specification.id;

    readWorkingSetSpecification(specification_id).then(({ response }) => {
      const boq_calculations = response.data.quantity.boq_calculations;
      const language_id = response.data.language_id;
      const options = [];

      _.each(
        _.sortBy(boq_calculations, (calculation) =>
          calculation?.item_id ? calculation?.id : Infinity
        ),
        (calculation) => {
          calculation.item_id &&
            options.push({
              value: calculation.id,
              label: `${calculation?.code} ${getLocalized(
                calculation?.name,
                language_id
              )}`,
              children: _.map(
                _.sortBy(calculation.positions, 'order'),
                (position) => {
                  return {
                    value: calculation.id + '-' + position.id,
                    label: getLocalized(position?.name, language_id),
                  };
                }
              ),
            });
        }
      );

      this.setState({ options });
    });
  }

  render() {
    const { store } = this.props;

    const options = this.state.options;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('working-set-boq.cost.merge')}</span>
            <span>{__('working-set-boq.cost.description')}</span>
          </div>
          <div className={styles.right}>
            <LinkIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MergeBOQItemForm
            onSubmit={this._handleSubmit}
            initialValues={{ move_action: 'before' }}
            options={options}
          />
        </div>
      </>
    );
  }
}

export default MergeBOQItemWizard;
