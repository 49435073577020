import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CreateMeetingWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveStage,
  mapStateToProps,
  redirect,
  setTableParams,
  validateForm,
} from '../core/utils';
import AddMeetingIcon from '../assets/images/add-meeting-60x60.svg';
import { withRouter } from 'react-router';
import CreateMeetingForm from '../forms/create_meeting_wizard/CreateMeetingForm';
import { getStagesLanguages } from '../redux/actions/table/stages';
import { setNotification } from '../redux/actions/general/notification';
import {
  createMeeting,
  listAllMeetings,
} from '../redux/actions/table/all_meetings';

@withRouter
@mapStateToProps((state) => ({
  tasks: _.get(state.table, 'all_meetings.tasks'),
  user: _.get(state, 'auth'),
}))
class CreateMeetingWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    getStagesLanguages(this.props.match.params.stage || getActiveStage()).then(
      ({ response }) => {
        this.setState({ project_languages: response.data });
      }
    );
  }

  @autobind
  _handleSubmit(formData) {
    const { edit } = formData;

    formData = {
      ..._.omit(formData, ['edit']),
      participants: _.map(formData.participants, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    return validateForm(
      createMeeting(
        this.props.match.params.stage || getActiveStage(),
        formData
      ).then(({ response }) => {
        listAllMeetings(this.props.match.params.stage || getActiveStage()).then(
          () => {
            if (edit) {
              redirect('/meetings/' + response.data.id);
            }
          }
        );

        setTableParams('all_tasks', {
          actioned_row: undefined,
          selected_rows: [],
        });

        this.props.onClose();

        setNotification(__('wizard.add-meeting.message.success'));
      })
    );
  }

  render() {
    const { id, avatar_url, email, firstname, lastname } = this.props.user;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('wizard.meeting.title.create-meeting')}</span>
            <span>{__('wizard.meeting.subtitle.create-meeting')}</span>
          </div>
          <div className={styles.right}>
            <AddMeetingIcon />
          </div>
        </div>
        <div className={styles.body}>
          {this.state.project_languages && (
            <CreateMeetingForm
              projectLanguages={this.state.project_languages}
              initialValues={{
                status: 'agenda',
                visibility: '1',
                duration: '60',
                tasks: this.props.tasks,
                title: {},
                description: {},
                languages: [
                  _.findKey(
                    this.state.project_languages,
                    (item) => item == true
                  ), //primarni jezik projekta
                ],
                administrators: [
                  { id, avatar_url, email, name: `${firstname} ${lastname}` },
                ],
              }}
              onSubmit={this._handleSubmit}
            />
          )}
        </div>
      </>
    );
  }
}

export default CreateMeetingWizard;
