import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddProjectWizard.scss";
import classNames from "classnames";
import {__, mapStateToProps, redirect, resetForm, validateForm} from "../core/utils";
import {getFormValues} from "redux-form";
import Localization from "../helpers/Localization";
import {subscribe} from "react-contextual";
import {setNotification} from "../redux/actions/general/notification";
import autobind from "autobind-decorator";
import ProjectPropertiesForm from "../forms/add_project_wizard/ProjectPropertiesForm";
import ProjectImageForm from "../forms/add_project_wizard/ProjectImageForm";
import InitialStageForm from "../forms/add_project_wizard/InitialStageForm";
import InitialTeamForm from "../forms/add_project_wizard/InitialTeamForm";
import {createProject, validateProjectDetails, validateProjectStage} from "../redux/actions/table/all_projects";
import {listActiveModules} from "../redux/actions/general/active_modules";

@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    details: getFormValues("add_project_wizard.project_properties")(state),
    image: getFormValues("add_project_wizard.project_image")(state),
    stage: getFormValues("add_project_wizard.initial_stage")(state),
}))
class AddProjectWizard extends Component {
    steps = [
        {
            id: "01",
            title: __("wizard.add-project.title.project-properties")
        },
        {
            id: "02",
            title: __("wizard.add-project.title.project-image")
        },
        {
            id: "03",
            title: __("wizard.add-project.title.initial-stage")
        },
        {
            id: "04",
            title: __("wizard.add-project.title.initial-team")
        }
    ];

    @autobind
    _setActiveStep(id) {
        this.setState({ active: _.find(this.steps, item => item.id === id) });
    }

    componentWillUnmount() {
        resetForm('add_project_wizard');
    }

    @autobind
    _handlePropertiesSubmit(formData) {
        return validateForm(validateProjectDetails(formData).then(() => {
            this._setActiveStep("02");
        }));
    }

    @autobind
    _handleImageSubmit() {
        this._setActiveStep("03");
    }

    @autobind
    _handleStageSubmit(formData) {
        return validateForm(validateProjectStage(formData).then(() => {
            this._setActiveStep("04");
        }));
    }

    @autobind
    _handleTeamSubmit(formData) {
        formData = {
            project_name: this.props.details.name,
            project_code: this.props.details.code,
            project_description: this.props.details.description,
            currency_id: this.props.details.currency_id,
            main_language: this.props.details.main_language,
            additional_languages: this.props.details.additional_languages,
            logo: _.get(this.props.image, 'logo'),
            stage_name: this.props.stage.name,
            stage_description: this.props.stage.description,
            stage_date: this.props.stage.date,
            custom_discipline: _.get(formData, 'custom_discipline', false) ? 1 : 0,
            discipline: formData.discipline,
            code: formData.code,
            discipline_description: formData.discipline_description,
            company_id: formData.company_id,
            company_name: formData.company_name,
            owner: formData.owner,
            new_company: _.get(formData, 'new_company', false) ? 1 : 0,
        };

        return validateForm(createProject(formData).then(({response}) => {
            this.props.onClose();

            listActiveModules();

            setNotification(__("wizard.add-project.message.success"));

            setTimeout(() => {
                redirect('/stages/' + response.data.stage_id + '/teams');
            }, 25);
        }));
    }

    render() {
        const active = _.get(this.state, "active", _.first(this.steps));

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.titleBar}>
                        <div className={styles.left}>
                            <span>{__('wizard.add-project.title.create-project')}</span>
                            <span>{active.title}</span>
                        </div>
                        <div className={styles.right}>
                            <span>{active.id}</span>
                            <span>{_.last(this.steps).id}</span>
                        </div>
                    </div>
                    <div className={styles.steps}>
                        {_.map(this.steps, (step, i) => (
                            <div key={i} className={classNames(styles.step, active.id == step.id && styles.active, step.id < active.id && styles.completed)}>
                                <span>{step.id}</span>
                                <span>{step.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.body}>
                    {active.id === "01" && (
                        <ProjectPropertiesForm
                            onSubmit={this._handlePropertiesSubmit}
                            initialValues={{
                                currency_id: 30
                            }}
                        />
                    )}
                    {active.id === "02" && (
                        <ProjectImageForm
                            onBack={() => this._setActiveStep("01")}
                            onSubmit={this._handleImageSubmit}
                        />
                    )}
                    {active.id === "03" && (
                        <InitialStageForm
                            onBack={() => this._setActiveStep("02")}
                            onSubmit={this._handleStageSubmit}
                        />
                    )}
                    {active.id === "04" && (
                        <InitialTeamForm
                            onBack={() => this._setActiveStep("03")}
                            onSubmit={this._handleTeamSubmit}
                            initialValues={{
                                custom_discipline: false,
                                new_company: false,
                                owner: [
                                    {
                                        team_role: 'editor'
                                    }
                                ]
                            }}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default AddProjectWizard;
