import * as React from 'react';
import { Component } from 'react';
import * as _ from 'lodash';
import TableListView from './TableListView';
import TableGridView from './TableGridView';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getPreferences } from '../redux/actions/profile';
import queryString from 'query-string';
import autobind from 'autobind-decorator';
import { getURLParam, hasURLParam, redirect, screenIs } from '../core/utils';

const mapStateToProps = (state, props) => {
  return {
    ...state.table[props.name],
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.' + props.name + '/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Table extends Component {
  componentWillMount() {
    this.props.actions.setTableParams({
      data: undefined,
      selected_rows: [],
      actioned_row: undefined,
      clicked_row: hasURLParam('id') ? getURLParam('id') : undefined,
      showFilters: false,
      filters: undefined,
      search_active: false,
      search_focus: false,
    });
  }

  componentWillUnmount() {
    this.props.actions.setTableParams({
      clicked_row: undefined,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.onLoad &&
      _.isUndefined(prevProps.data) &&
      !_.isUndefined(this.props.data)
    ) {
      this.props.onLoad();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clicked_row != nextProps.clicked_row) {
      this._setURLParams({
        id: nextProps.clicked_row,
      });
    }
  }

  componentDidMount() {
    let default_values = {
      sort_by:
        _.get(_.find(this.props.columns, { default: true }), 'key') ||
        _.get(_.first(this.props.columns), 'key'),
      order: _.find(this.props.columns, { desc: true }) ? 'desc' : 'asc',
      disabled_columns: _.map(
        _.filter(
          this.props.columns,
          (column) => column.active == false && !column.required
        ),
        'key'
      ),
      pagination: _.get(this.props, 'pagination', true),
      footer: this.props.footer,
      page: 1,
      limit: 50,
      view: this.props.defaultView || 'list',
    };

    getPreferences('table.' + this.props.name).then(({ response }) => {
      const user_preference_params = _.transform(
        response.data,
        (params, value, key) => {
          if (_.startsWith(key, 'filter_')) {
            params[key.replace('filter_', 'filter.')] = _.toString(value);
          } else {
            params[key] = value;
          }
        },
        {}
      );

      default_values = {
        ...default_values,
        ...user_preference_params,
      };

      if (screenIs('<=', 992)) {
        const filtered_mobile_columns = _.map(
          _.filter(this.props.columns, (item) => !item.mobileVisible),
          'key'
        );

        if (_.size(filtered_mobile_columns) != _.size(this.props.columns)) {
          default_values['disabled_columns'] = filtered_mobile_columns;
        }
      }

      this.props.actions.setTableParams(
        _.pick(default_values, [
          'disabled_columns',
          'view',
          'pagination',
          'pinned_columns',
        ])
      );

      const url_parameters = queryString.parse(
        this.props.history.location.search
      );

      if (
        _.isEmpty(url_parameters) ||
        (_.size(url_parameters) == 1 &&
          (_.has(url_parameters, 'id') ||
            !_.isUndefined(
              _.find(url_parameters, (value, key) => _.includes(key, 'filter.'))
            )))
      ) {
        const new_params = _.pickBy(default_values, (value, key) => {
          return (
            _.includes(['sort_by', 'order', 'page', 'limit', 'query'], key) ||
            _.startsWith(key, 'filter.')
          );
        });

        this._setURLParams({
          ...new_params,
          ...url_parameters,
        });
      } else {
        this._mapUrlParamsToProps();
      }

      if (this.props.query) {
        this.props.actions.setTableParams({
          search_active: true,
        });
      }
    });
  }

  @autobind
  _setURLParams(params) {
    const search = queryString.stringify({
      ...queryString.parse(this.props.history.location.search),
      ...params,
    });
    redirect({ search }, true);
  }

  _mapUrlParamsToProps() {
    const params = queryString.parse(this.props.location.search);

    this.props.actions.setTableParams(
      _.pick(params, ['page', 'limit', 'sort_by', 'order', 'query'])
    );

    if (_.has(params, 'id')) {
      this.props.actions.setTableParams({
        clicked_row: params.id,
      });
    }

    _.each(params, (value, key) => {
      //TODO - try to merge this with the upper keys using pickBy()
      if (_.startsWith(key, 'filter.')) {
        const params = {};
        params[key] = value;

        this.props.actions.setTableParams(params);
      }
    });

    if (params.query && params.query != this.props.query) {
      this.props.actions.setTableParams({
        page: 1,
        selected_rows: [],
        actioned_row: undefined,
        clicked_row: undefined,
      });
    }

    this.props.listAction();
  }

  render() {
    return (
      <>
        {this.props.view == 'list' && (
          <TableListView setURLParams={this._setURLParams} {...this.props} />
        )}
        {this.props.view == 'grid' && (
          <TableGridView setURLParams={this._setURLParams} {...this.props} />
        )}
      </>
    );
  }
}

export default Table;
