import React from 'react';
import { showLightbox } from '../redux/actions/general/lightbox';
import styles from '../styles/components/FilePreview.scss';
import DocumentExtIcon from '../assets/images/document-ext-30x30.svg';
import classNames from 'classnames';
import Tooltip from './Tooltip';
import Image from '../components/Image';

function FilePreview({
  row,
  lightbox_images,
  onDownloadFile,
  fileName = true,
  customStyles,
}) {
  return (
    <>
      {_.includes(['jpg', 'jpeg', 'png'], _.toLower(row.ext)) ? (
        <Tooltip
          placement='left'
          text={<Image className={styles.thumbPreview} src={row.stream_url} />}
          html={true}
          dark={true}
        >
          <div
            className={classNames(styles.thumbnail, customStyles)}
            onClick={() => showLightbox(lightbox_images, row.id)}
            style={{
              backgroundImage: "url('" + row.stream_url + "')",
            }}
          />
        </Tooltip>
      ) : (
        <div
          className={classNames(styles.icon, customStyles)}
          onClick={() => onDownloadFile(row)}
        >
          <>
            <DocumentExtIcon
              className={
                styles[
                  'color' +
                    (_.sumBy(_.toLower(row.ext).split(''), (item) =>
                      item.charCodeAt(0)
                    ) %
                      5)
                ]
              }
            />

            <span>{row.ext.substring(0, 4)}</span>
          </>
        </div>
      )}
      {fileName && (
        <>
          <strong onClick={() => onDownloadFile(row)}>{row.name}</strong>·{' '}
          {row.ext.toUpperCase()}
        </>
      )}
    </>
  );
}

export default FilePreview;
