import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams, getLocalized } from "../../core/utils";
import { createSidebar, createSuperadminSidebar } from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import RawColorsFlyout from "../../flyouts/superadmin/raw_colors/RawColorsFlyout";
import {
	deleteRawColors,
	listRawColors,
	readRawColor,
} from "../../redux/actions/table/superadmin/raw_colors";
import { listLocalization } from "../../redux/actions/localization";
import TableNA from "../../components/TableNA";
import ColorPreview from "../../components/ColorPreview";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_raw_colors"],
	auth: state.auth,
	application_language_id: state.auth.language_id,
}))
class RawColors extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_raw_color && setPlusActions([{
			title: "Add filter color",
			onClick: () => setTableParams('superadmin_raw_colors', {
				add_raw_color_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readRawColor(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, application_language_id } = this.props;

		let columns = [
			{
				key: "hex",
				name: "Hex",
				width: 100,
				value: hex => (
					<Tooltip text={hex}>
						{hex ? <ColorPreview hex={hex} /> : <TableNA />}
					</Tooltip>
				)
			},
			{
				key: "name",
				name: "Name",
				required: true,
				value: name => {
					const localized_name = getLocalized(name, application_language_id);
					return <Tooltip text={localized_name}>{localized_name}</Tooltip>
				}
			},
			{
				key: "priority",
				name: "Priority",
				width: 60,
			},
			{
				key: "colors",
				name: "Attached colors",
				value: colors => {
					const joined_colors = _.join(colors, ', ');

					return _.isEmpty(colors) ? <TableNA /> : <Tooltip text={joined_colors}>{joined_colors}</Tooltip>;
				},
				sortable: false
			},
		];

		const singleActions = (raw_color_id) => {
			return [
				<Tooltip text="Delete selected filter color" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected filter color?\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteRawColors([raw_color_id]).then(() => {
												hideAlertbox();

												setNotification("The selected filter color has been deleted.");
												listRawColors();

												listLocalization();

												setTableParams('superadmin_raw_colors', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (raw_colors_ids) => [
			<Tooltip text="Delete selected filter colors" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these filter colors?\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteRawColors(raw_colors_ids).then(() => {
											hideAlertbox();

											setNotification("The selected filter colors have been deleted.");
											listRawColors();

											listLocalization();

											setTableParams('superadmin_raw_colors', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<RawColorsFlyout
				data={this.state.preview}
				readAction={raw_color_id => {
					return new Promise(resolve => {
						readRawColor(getURLParam('id') || raw_color_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listRawColors}
				name="superadmin_raw_colors"
				groupActions={superadmin.delete_raw_color && groupActions}
				singleActions={superadmin.delete_raw_color && singleActions}
				title={__("modules.submodules.title.raw-colors")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default RawColors;
