import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_specification_from_product_wizard/CreateSpecificationFromProductForm.scss';
import Button from '../../components/Button';
import {
  __,
  getActiveProject,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import {
  checkSpecificationCode,
  getNextSpecificationCode,
  listPossibleSpecificationProjects,
  listPossibleSpecificationStages,
  listPossibleSpecificationTeams,
} from '../../redux/actions/table/working_set_specifications';
import * as _ from 'lodash';
import Input from '../../components/Input';
import ButtonGroup from '../../components/ButtonGroup';
import autobind from 'autobind-decorator';
import RadioGroup from '../../components/RadioGroup';
import { getStagesLanguages } from '../../redux/actions/table/stages';

@reduxForm({
  form: 'create_specification_from_product_wizard.create_specification_from_product',
})
@mapStateToProps((state) => ({
  values:
    getFormValues(
      'create_specification_from_product_wizard.create_specification_from_product'
    )(state) || {},
  application_language_id: state.auth.language_id,
  create_action: state.table.working_set_specifications.create_action,
}))
@subscribe(Localization, 'localization')
class CreateSpecificationFromProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_projects: [],
      possible_stages: [],
      possible_teams: [],
      project_language: null,
    };
  }

  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  componentDidMount() {
    listPossibleSpecificationProjects().then(({ response }) => {
      this.setState({ possible_projects: response.data });
    });

    const active_project = getActiveProject();
    const active_stage = getActiveStage();

    if (active_project) {
      this.props.change('project_id', active_project);

      listPossibleSpecificationStages(active_project).then(({ response }) => {
        this.setState({ possible_stages: response.data }, () => {
          if (active_stage) {
            this.props.change('stage_id', active_stage);

            listPossibleSpecificationTeams(active_stage).then(
              ({ response }) => {
                this.setState({ possible_teams: response.data });

                if (_.size(response.data) == 1) {
                  this.props.change(
                    'team_id',
                    _.get(response.data, [0, 'id'], null)
                  );
                }
              }
            );
          }
        });

        if (_.size(response.data) == 1) {
          this.props.change('stage_id', _.get(response.data, [0, 'id'], null));
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'values.project_id') &&
      _.get(prevProps, 'values.project_id') !=
        _.get(this.props, 'values.project_id') &&
      _.get(this.props, 'values.project_id')
    ) {
      listPossibleSpecificationStages(
        _.get(this.props, 'values.project_id')
      ).then(({ response }) => {
        this.setState({ possible_stages: response.data });

        if (_.size(response.data) == 1) {
          this.props.change('stage_id', _.get(response.data, [0, 'id'], null));
        }
      });

      this.props.change('stage_id', null);
      this.props.change('team_id', null);
    }

    if (
      _.get(prevProps, 'values.stage_id') &&
      _.get(prevProps, 'values.stage_id') !=
        _.get(this.props, 'values.stage_id') &&
      _.get(this.props, 'values.stage_id')
    ) {
      this.props.change('team_id', null);

      listPossibleSpecificationTeams(_.get(this.props, 'values.stage_id')).then(
        ({ response }) => {
          this.setState({ possible_teams: response.data });

          if (_.size(response.data) == 1) {
            this.props.change('team_id', _.get(response.data, [0, 'id'], null));
          }
        }
      );

      getStagesLanguages(this.props.values.stage_id).then(({ response }) => {
        this.setState({
          project_language: _.findKey(
            response.data,
            (primary) => primary == true
          ),
        });
      });
    }

    if (
      _.get(prevProps, 'values.specification_subgroup_id') !=
        _.get(this.props, 'values.specification_subgroup_id') &&
      _.get(this.props, 'values.specification_subgroup_id')
    ) {
      getNextSpecificationCode(this.props.values.stage_id, {
        specification_subgroup_id: _.get(
          this.props,
          'values.specification_subgroup_id'
        ),
      }).then(({ response }) => {
        this.props.change('code', response.data.code);
      });
    }

    if (
      prevProps.values.specification_subgroup_id !=
        this.props.values.specification_subgroup_id ||
      prevProps.values.code != this.props.values.code ||
      prevProps.values.team_id != this.props.values.team_id
    ) {
      setTableParams('working_set_specifications', {
        create_action: false,
      });

      if (
        this.props.values.specification_subgroup_id &&
        this.props.values.code &&
        this.props.values.team_id &&
        _.size(this.props.values.code) == 2
      ) {
        checkSpecificationCode(this.props.values.team_id, {
          code: this.props.values.code,
          specification_subgroup_id:
            this.props.values.specification_subgroup_id,
        })
          .then(() => {
            setTableParams('working_set_specifications', {
              create_action: false,
            });
          })
          .catch(({ response }) => {
            if (_.has(response, 'code') && response.code === 1) {
              setTableParams('working_set_specifications', {
                create_action: true,
              });
            } else {
              setTableParams('working_set_specifications', {
                create_action: false,
              });
            }
          });
      }
    }
  }

  render() {
    const { values, application_language_id, create_action } = this.props;
    const {
      possible_projects,
      possible_stages,
      possible_teams,
      project_language,
    } = this.state;
    const { disciplines, specification_groups, specification_subgroups } =
      this.props.localization;

    const units = _.sortBy(
      _.map(this.props.localization.units, (unit) => ({
        label: unit.symbol,
        value: unit.id,
        tooltip: unit.description,
        group: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'name',
          1,
        ]),
        groupPriority: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'priority',
        ]),
      })),
      'groupPriority'
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <span className={styles.desc}>
          {__(
            'specifications.create-specification.enter-specifications-basic-details'
          )}{' '}
          {__('create-specification-from-product-form.edit-attributes-later')}
        </span>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='project_id'
              searchable={true}
              component={Select}
              label={__('create-specification-from-product-form.project')}
              options={_.map(possible_projects, (project) => ({
                value: project.id,
                label: [project.code, project.name].join(': '),
              }))}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='stage_id'
              disabled={!values.project_id}
              searchable={true}
              component={Select}
              label={__('create-specification-from-product-form.stage')}
              options={_.map(possible_stages, (stage) => ({
                value: stage.id,
                label: [_.padStart(stage.order, 2, '0'), stage.name].join(': '),
              }))}
            />
          </div>
        </div>
        <Field
          name='team_id'
          disabled={!values.stage_id}
          searchable={true}
          component={Select}
          label={__('create-specification-from-product-form.discipline-team')}
          options={_.map(possible_teams, (team) => ({
            value: team.id,
            label:
              team.company.name +
              ' [' +
              _.get(disciplines, [
                team.stage_discipline.discipline_id,
                'name',
              ]) +
              (team.stage_discipline.discipline_id == '1'
                ? ' (' + team.stage_discipline.description + ')'
                : '') +
              ']',
          }))}
        />
        <div className={styles.flex}>
          <div className={styles.f2}>
            <Field
              name='specification_subgroup_id'
              disabled={!values.stage_id}
              searchable={true}
              component={Select}
              label={__('create-specification-from-product-form.category')}
              options={_.map(
                specification_subgroups,
                (specification_subgroup) => {
                  const specification_group = _.get(
                    specification_groups,
                    _.get(specification_subgroup, 'specification_group_id'),
                    {}
                  );
                  const label =
                    getLocalized(specification_group.code, project_language) +
                    [
                      _.padEnd(specification_subgroup.code, 3, '0'),
                      getLocalized(
                        specification_subgroup.name,
                        application_language_id
                      ),
                    ].join(': ');
                  const group = [
                    getLocalized(specification_group.code, project_language),
                    getLocalized(
                      specification_group.name,
                      application_language_id
                    ),
                  ].join(': ');

                  return {
                    value: specification_subgroup.id,
                    label,
                    group,
                  };
                }
              )}
            />
          </div>
          {values.specification_subgroup_id && (
            <div className={styles.code}>
              <div className={styles.code}>
                {getLocalized(
                  _.get(specification_groups, [
                    _.get(specification_subgroups, [
                      values.specification_subgroup_id,
                      'specification_group_id',
                    ]),
                    'code',
                  ]),
                  project_language
                ) +
                  _.get(specification_subgroups, [
                    values.specification_subgroup_id,
                    'code',
                  ])}
              </div>
            </div>
          )}
          <div className={styles.right}>
            <Field
              name='code'
              component={Input}
              label={__('create-specification-from-product-form.code')}
              numeric
              maxLength={2}
            />
          </div>
        </div>
        {create_action &&
          values.specification_subgroup_id &&
          _.size(values.code) == 2 && (
            <>
              <div className={styles.exists}>
                {__(
                  'specifications.working-set.flyout.create-new-specification.code'
                )}{' '}
                <strong>
                  {getLocalized(
                    _.get(specification_groups, [
                      _.get(specification_subgroups, [
                        values.specification_subgroup_id,
                        'specification_group_id',
                      ]),
                      'code',
                    ]),
                    project_language
                  ) +
                    _.get(specification_subgroups, [
                      values.specification_subgroup_id,
                      'code',
                    ]) +
                    _.padStart(values.code, 2, '0')}
                </strong>{' '}
                already exists. Please choose an action:
              </div>
              <Field
                name='action'
                component={RadioGroup}
                options={[
                  {
                    label: __(
                      'create-specification-from-product-form.add-as-new-option-main'
                    ),
                    value: 'option_main',
                  },
                  {
                    label: __(
                      'create-specification-from-product-form.add-as-new-option-alternative'
                    ),
                    value: 'option_alternative',
                  },
                  {
                    label: __(
                      'create-specification-from-product-form.replace-specification'
                    ),
                    value: 'replace',
                  },
                ]}
              />
            </>
          )}
        <Field
          name='unit_id'
          component={Select}
          label={__('create-specification-from-product-form.unit')}
          searchable
          options={units}
          noSort
        />
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('products', {
                create_specification_from_product_wizard: undefined,
              })
            }
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.create')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(false)}
            />
            <Button
              lightBlue
              medium
              middleText={__('button.create-edit')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(true)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default CreateSpecificationFromProductForm;
