const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "table.all_tasks/SET_TABLE_PARAMS":
            return {
                ...state,
                ...action.params
            };
        case "table.all_tasks/LIST_ALL_TASKS_SUCCESS":
            return {
                ...state,
                data: action.response.data,
                ...action.response.pagination,
            };
        case "table.all_tasks/LIST_ALL_TASKS_FILTERS_SUCCESS":
            return {
                ...state,
                filters: action.response.filters,
            };
        case "table.all_tasks/READ_TASK_SUCCESS":
            return {
                ...state,
                flyout: action.response.data,
            };
        case "table.all_tasks/READ_TASK_HISTORY_SUCCESS":
            return {
                ...state,
                flyout: {
                    ...state.flyout,
                    history: action.response.data
                },
            };
        default:
            return state;
    }
}
