import store from "../../../core/store";

export const listAllProjects = () => {
    return store.dispatch({
        action: "table.all_projects/LIST_ALL_PROJECTS",
        method: "GET",
        endpoint: "/projects",
        main: true
    });
};

export const validateProjectDetails = (formData) => {
    return store.dispatch({
        action: "table.all_projects/VALIDATE_PROJECT_DETAILS",
        method: "POST",
        endpoint: "/projects/project_details",
        data: formData
    });
};

export const validateProjectStage = (formData) => {
    return store.dispatch({
        action: "table.all_projects/VALIDATE_PROJECT_STAGE",
        method: "POST",
        endpoint: "/projects/project_stage",
        data: formData
    });
};

export const validateProjectDiscipline = (formData) => {
    return store.dispatch({
        action: "table.all_projects/VALIDATE_PROJECT_DISCIPLINE",
        method: "POST",
        endpoint: "/projects/project_discipline",
        data: formData
    });
};

export const createProject = (formData) => {
    return store.dispatch({
        action: "table.all_projects/CREATE_PROJECT",
        method: "POST",
        endpoint: "/projects",
        data: formData
    });
};

export const deactivateProjects = (project_ids) => {
    return store.dispatch({
        action: "table.all_projects/DEACTIVATE_PROJECTS",
        method: "DELETE",
        endpoint: "/projects/deactivate",
        data: {
            projects: project_ids
        }
    });
};

export const activateProjects = (project_ids) => {
    return store.dispatch({
        action: "table.all_projects/ACTIVATE_PROJECTS",
        method: "POST",
        endpoint: "/projects/activate",
        data: {
            projects: project_ids
        }
    });
};