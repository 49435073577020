import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_brand_wizard/AddBrandForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import Dropzone from "../../components/Dropzone";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({form: "add_brand_wizard.add_brand"})
class AddBrandForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" component={Input} label={__('company-settings.brands.create-new-brand.name')} />
                    <Field name="phone" component={Input} label={__('company-settings.brands.create-new-brand.phone')} />
                    <Field name="web" component={Input} label={__('company-settings.brands.create-new-brand.web')} />
                    <Field name="address" component={Input} label={__('company-settings.brands.create-new-brand.address')} />
                    <Field name="file" component={Dropzone} item={__('company-settings.brands.create-new-brand.logo')} accept="image/png,image/jpeg" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('brands', {
                        wizard_active: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddBrandForm;
