import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import { __, mapStateToProps } from '../../core/utils';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import styles from '../../styles/forms/current_set_specifications/ProjectNoteForm.scss';
import Textarea from '../../components/Textarea';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';

@reduxForm({
  form: 'current_set_specifications.project_note',
  enableReinitialize: true,
})
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
}))
class ProjectNoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _toggleCollapse() {
    this.setState((prevState) => {
      return {
        expanded: !prevState.expanded,
      };
    });
  }

  render() {
    const { handleSubmit } = this.props;

    const { language_id } = this.props.store;

    const { expanded } = this.state;

    return (
      <Form className={styles.wrapper} onSubmit={handleSubmit}>
        <div className={styles.title} onClick={() => this._toggleCollapse()}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapse,
              !expanded && styles.collapsed
            )}
          />
          {__('working-set-specifications.project-note-form.project-note')}
        </div>
        {expanded && (
          <div className={styles.field}>
            <Field
              name={'project_note.' + language_id}
              disabled={true}
              component={Textarea}
              maxLength={2000}
              rows={1}
              maxRows={20}
            />
          </div>
        )}
      </Form>
    );
  }
}

export default ProjectNoteForm;
