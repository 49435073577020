import _ from 'lodash';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.notifications/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };
    case 'table.notifications/LIST_ALL_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        ...action.response.pagination,
      };
    case 'table.notifications/LIST_ALL_NOTIFICATIONS_FILTERS_SUCCESS':
      return {
        ...state,
        filters: action.response.filters,
      };
    case 'table.notifications/READ_NOTIFICATION_STATE':
      const notification_id = action.notification_id;
      const isNotificationIdArray = Array.isArray(notification_id);

      return {
        ...state,
        data: _.map(state.data, (notification) => {
          const isNotificationIdIncluded = _.includes(
            isNotificationIdArray ? notification_id : [notification_id],
            notification.id
          );

          return {
            ...notification,
            read: isNotificationIdIncluded
              ? action.read
                ? 1
                : 0
              : notification.read,
          };
        }),
      };
    default:
      return state;
  }
}
