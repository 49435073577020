import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddStageWizard.scss";
import classNames from "classnames";
import {__, mapStateToProps, resetForm, validateForm} from "../core/utils";
import {getFormValues} from "redux-form";
import Localization from "../helpers/Localization";
import {subscribe} from "react-contextual";
import {setNotification} from "../redux/actions/general/notification";
import autobind from "autobind-decorator";
import {listActiveModules} from "../redux/actions/general/active_modules";
import StageDetailsForm from "../forms/add_stage_wizard/StageDetailsForm";
import {createStage, listProjectStages, validateStageDetails} from "../redux/actions/table/stages";
import InitialTeamForm from "../forms/add_stage_wizard/InitialTeamForm";
import {withRouter} from "react-router";

@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    stage: getFormValues("add_stage_wizard.stage_details")(state),
    discipline: getFormValues("add_stage_wizard.initial_discipline")(state),
}))
@withRouter
class AddProjectWizard extends Component {
    steps = [
        {
            id: "01",
            title: __("wizard.add-project.title.stage-details")
        },
        {
            id: "02",
            title: __("wizard.add-project.title.initial-team")
        }
    ];

    @autobind
    _setActiveStep(id) {
        this.setState({ active: _.find(this.steps, item => item.id === id) });
    }

    componentWillUnmount() {
        resetForm('add_stage_wizard');
    }

    @autobind
    _handleStageSubmit(formData) {
        return validateForm(validateStageDetails(formData).then(() => {
            this._setActiveStep("02");
        }));
    }

    @autobind
    _handleTeamSubmit(formData) {
        formData = {
            stage_name: this.props.stage.name,
            stage_description: this.props.stage.description,
            stage_date: this.props.stage.date,
            custom_discipline: _.get(formData, 'custom_discipline', false) ? 1 : 0,
            discipline: formData.discipline,
            code: formData.code,
            discipline_description: formData.discipline_description,
            company_id: formData.company_id,
            company_name: formData.company_name,
            owner: formData.owner,
            new_company: _.get(formData, 'new_company', false) ? 1 : 0,
        };

        return validateForm(createStage(this.props.match.params.project, formData).then(() => {
            listActiveModules();

            listProjectStages(this.props.match.params.project);

            this.props.onClose();

            setNotification(__('wizard.add-stage.notification.success'));
        }));
    }

    render() {
        const active = _.get(this.state, "active", _.first(this.steps));

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.titleBar}>
                        <div className={styles.left}>
                            <span>{__('project-settings.stages.create-new-stage.create-stage')}</span>
                            <span>{active.title}</span>
                        </div>
                        <div className={styles.right}>
                            <span>{active.id}</span>
                            <span>{_.last(this.steps).id}</span>
                        </div>
                    </div>
                    <div className={styles.steps}>
                        {_.map(this.steps, (step, i) => (
                            <div key={i} className={classNames(styles.step, active.id == step.id && styles.active, step.id < active.id && styles.completed)}>
                                <span>{step.id}</span>
                                <span>{step.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.body}>
                    {active.id === "01" && (
                        <StageDetailsForm
                            onSubmit={this._handleStageSubmit}
                        />
                    )}
                    {active.id === "02" && (
                        <InitialTeamForm
                            onBack={() => this._setActiveStep("02")}
                            onSubmit={this._handleTeamSubmit}
                            initialValues={{
                                custom_discipline: false,
                                new_company: false,
                                owner: [
                                    {
                                        team_role: 'editor'
                                    }
                                ]
                            }}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default AddProjectWizard;
