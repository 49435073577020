import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/OCRPlansWizard.scss";
import autobind from "autobind-decorator";
import OCRIcon from "../assets/images/ocr-64x64.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {withRouter} from "react-router";
import OCRPlansForm from "../forms/ocr_plans_wizard/OCRPlansForm";
import {setNotification} from "../redux/actions/general/notification";
import {startPlansOCR} from "../redux/actions/table/plan_uploads";

@withRouter
@mapStateToProps(state => ({
	table: state.table["plan_uploads"],
}))
class OCRPlansWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const {ocr_template_id} = formData;
		const {ocr_plans_wizard} = this.props.table;

		return validateForm(startPlansOCR(ocr_template_id, ocr_plans_wizard).then(() => {
			setNotification(__("wizard.ocr-plans.message.ocr-processing-started"));

			this.props.onClose();

			setTableParams('plan_uploads', {
				actioned_row: undefined,
				selected_rows: []
			});
		}));
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.ocr-plans.title.ocr-plans')}</span>
						<span>{__('wizard.ocr-plans.subtitle.ocr-plans')}</span>
					</div>
					<div className={styles.right}>
						<OCRIcon />
					</div>
				</div>
				<div className={styles.body}>
					<OCRPlansForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default OCRPlansWizard;