import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/UploadPlansWizard.scss";
import {withRouter} from "react-router";
import AddPlanIcon from "../assets/images/add-plan-60x60.svg";
import classNames from "classnames";
import * as _ from "lodash";
import ReactDropzone from "react-dropzone";
import {setNotification} from "../redux/actions/general/notification";
import Button from "../components/Button";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import {
    hidePlanUploadFile,
    listPlanUploads,
    minimizePlanUploads,
    uploadPlan
} from "../redux/actions/table/plan_uploads";
import {connect} from "react-redux";
import {__, bytesToSize, getActiveProject, getActiveStage} from "../core/utils";
import autobind from "autobind-decorator";
import UploadPlansForm from "../forms/upload_plans_wizard/UploadPlansForm";
import {getFormValues} from "redux-form";

const mapStateToProps = state => {
    return {
        table: state.table["plan_uploads"],
        active_modules: state.general.active_modules,
        team_id: _.get(getFormValues("upload_plans_wizard.upload_plans")(state), 'team_id')
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            hideUploadPlansWizard: () => dispatch({
                type: "table.plan_uploads/SET_TABLE_PARAMS",
                params: {
                    wizard_active: undefined,
                }
            }),
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
class UploadPlansWizard extends Component {
    @autobind
    _handleDrop(acceptedFiles, rejectedFiles) {
        this.setState({ drag: false });

        _.forEach(_.filter(acceptedFiles, file => file.size < 104857600), file => {
            uploadPlan(this.props.team_id, file).then(() => listPlanUploads(this.props.match.params.stage));
        });

        if(!_.isEmpty(_.filter(acceptedFiles, file => file.size >= 104857600))) {
            setNotification({
                text: __('uploadplan.warning.size'),
                type: "warning"
            });
        }

        if(!_.isEmpty(rejectedFiles)) {
            setNotification({
                text: __('uploadplan.warning.type'),
                type: "warning"
            });
        }
    }

    @autobind
    _minimize() {
        minimizePlanUploads();
        setNotification(__('uploadplan.info.background'));
    }

    @autobind
    _cancelUpload(file) {
        file.cancel();
    }

    @autobind
    _cancelUploads() {
        _.forEach(_.filter(this.props.table.files, file => _.has(file, 'cancel')), file => file.cancel());
    }

    @autobind
    _close() {
        this.props.actions.hideUploadPlansWizard();

        _.forEach(_.filter(this.props.table.files, file => _.has(file, 'success')), file => hidePlanUploadFile(file.id));
    }

    render() {
        const uploading = !_.isEmpty(this.props.table.files) && !_.isEmpty(_.filter(this.props.table.files, file => !_.has(file, 'success')));
        const project = _.find(this.props.active_modules.items, ['id', getActiveProject()]);
        const stage = project ? _.find(project.stages, ['id', getActiveStage()]) : null;

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('uploadplan.title.upload-plans')}</span>
                        <span>{__('uploadplan.title.upload-plans-to-workspace')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddPlanIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.location}>
                        <span><strong>{project.name}</strong>, {stage.name}</span>
                    </div>
                    <UploadPlansForm />
                    {!_.isEmpty(this.props.table.files) && <div className={styles.filelist}>
                        {_.map(this.props.table.files, (file, i) => (
                            <div key={i}>
                                <div className={classNames(styles.bar, file.success && styles.success, file.success === false && styles.error)} style={{width: file.progress + "%"}}/>
                                <div className={styles.info}>
                                    <span>{file.name}</span>
                                    <span>{bytesToSize(file.size)}</span>
                                    {_.isUndefined(file.success) && <CloseSmallIcon onClick={() => this._cancelUpload(file)} />}
                                </div>
                            </div>
                        ))}
                    </div>}
                    {this.props.team_id && <ReactDropzone
                        accept="application/pdf"
                        className={classNames(styles.dropzone, _.get(this.state, "drag") && styles.drag)}
                        onDrop={this._handleDrop}
                        onDragOver={() => this.setState({ drag: true })}
                        onDragLeave={() => this.setState({ drag: false })}
                    >
                        <span>{__('dropzone.title.drop-your')} {__('dropzone.title.files')} {__('dropzone.title.here')}</span>
                        <span>{__('dropzone.title.or-select')}</span>
                    </ReactDropzone>}
                    <div className={styles.footer}>
                        {uploading && <Button lightGray medium left middleText={__('uploadplan.button.cancel')} onClick={this._cancelUploads} />}
                        {uploading && <Button gray medium right middleText={__('uploadplan.button.minimize')} onClick={this._minimize} />}
                        {!uploading && <Button gray medium right middleText={__('uploadplan.button.close')} onClick={this._close} />}
                    </div>
                </div>
            </>
        );
    }
}

export default UploadPlansWizard;
