import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getLocalized, getURLParam, mapStateToProps, setTableParams } from "../../core/utils";
import { createSuperadminSidebar } from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import {
	deleteProductGroups,
	listProductGroups,
	readProductGroup
} from "../../redux/actions/table/superadmin/product_groups";
import ProductGroupsFlyout from "../../flyouts/superadmin/product_groups/ProductGroupsFlyout";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_product_groups"],
	application_language_id: state.auth.language_id,
}))
class ProductGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_product_group && setPlusActions([{
			title: "Add product group",
			onClick: () => setTableParams('superadmin_product_groups', {
				add_product_group_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readProductGroup(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, application_language_id } = this.props;

		const columns = [
			{
				key: "id",
				name: "ID",
				width: 60,
				sortable: false
			},
			{
				key: "name",
				name: "Name",
				value: name => getLocalized(name, application_language_id)
			},
			{
				key: "priority",
				name: "Priority",
				width: 60,
			},
			{
				key: "product_category",
				name: "Category",
				width: 200,
				value: product_category => {
					const translation = getLocalized(product_category, application_language_id);

					return <Tooltip text={translation}>{translation}</Tooltip>;
				}
			},
		];

		const singleActions = (product_group_id) => {
			return [
				<Tooltip text="Delete selected product group" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected product group?\n\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteProductGroups([product_group_id]).then(() => {
												hideAlertbox();

												setNotification("The selected product group has been deleted.");
												listProductGroups();

												setTableParams('superadmin_product_groups', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (product_groups_ids) => [
			<Tooltip text="Delete selected product groups" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these product groups?\n\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteProductGroups(product_groups_ids).then(() => {
											hideAlertbox();

											setNotification("The selected product groups have been deleted.");
											listProductGroups();

											setTableParams('superadmin_product_groups', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<ProductGroupsFlyout
				data={this.state.preview}
				readAction={product_group_id => {
					return new Promise(resolve => {
						readProductGroup(getURLParam('id') || product_group_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listProductGroups}
				name="superadmin_product_groups"
				groupActions={superadmin.delete_product_group && groupActions}
				singleActions={superadmin.delete_product_group && singleActions}
				title={__("modules.submodules.title.product-groups")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default ProductGroups;
