import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddAddressBookContactWizard.scss";
import autobind from "autobind-decorator";
import {__, setTableParams, validateForm} from "../core/utils";
import AddAddressBookContactIcon from "../assets/images/add-address-book-contact-64x64.svg";
import {setNotification} from "../redux/actions/general/notification";
import AddAddressBookContactForm from "../forms/add_address_book_contact_wizard/AddAddressBookContactForm";
import {createAddressBookContact, listAddressBookContacts} from "../redux/actions/table/address_book_contacts";

class AddAddressBookContactWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;

        formData = _.omit(formData, ['edit']);

        return validateForm(
            createAddressBookContact(formData).then(({response}) => {
                listAddressBookContacts().then(() => {
                    if(edit) {
                        setTableParams('address_book_contacts', {
                            clicked_row: response.data.id
                        });
                    }
                });

                this.props.onClose();

                setNotification(__('wizard.add-address-book-contact.message.success'));
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.address-book-contacts.title.add-address-book-contact')}</span>
                        <span>{__('wizard.address-book-contacts.subtitle.add-address-book-contact')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddAddressBookContactIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddAddressBookContactForm onSubmit={this._handleSubmit} initialValues={{
                        emails: [''],
                        phones: [''],
                    }} />
                </div>
            </>
        );
    }
}

export default AddAddressBookContactWizard;
