import store from "../../../../core/store";

export const listBuildingTypes = () => {
	return store.dispatch({
		action: "table.superadmin_building_types/LIST_BUILDING_TYPES",
		method: "GET",
		endpoint: "/superadmin/building_types",
		main: true
	});
};

export const createBuildingType = (formData) => {
	return store.dispatch({
		action: "table.superadmin_building_types/CREATE_BUILDING_TYPE",
		method: "POST",
		endpoint: "/superadmin/building_types",
		data: formData
	});
};

export const deleteBuildingTypes = (building_type_ids) => {
	return store.dispatch({
		action: "table.superadmin_building_types/DELETE_BUILDING_TYPES",
		method: "DELETE",
		endpoint: "/superadmin/building_types",
		data: {
			building_types: building_type_ids
		}
	});
};

export const readBuildingType = (building_type_id) => {
	return store.dispatch({
		action: "table.superadmin_building_types/READ_BUILDING_TYPE",
		method: "GET",
		endpoint: "/superadmin/building_types/" + building_type_id,
		has404: true
	});
};

export const updateBuildingType = (building_type_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_building_types/UPDATE_BUILDING_TYPE",
		method: "PUT",
		endpoint: "/superadmin/building_types/" + building_type_id,
		data: formData
	});
};