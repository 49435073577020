import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/EditTeamWizard.scss";
import autobind from "autobind-decorator";
import AddTeamIcon from "../assets/images/add-team-60x60.svg";
import {__, mapStateToProps, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import {listAllTeams, updateTeam} from "../redux/actions/table/teams";
import EditTeamForm from "../forms/edit_team_wizard/EditTeamForm";

@mapStateToProps(state => ({
	table: _.get(state, 'table.teams')
}))
@withRouter
class EditTeamWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		formData = {
			...formData,
			custom_discipline: _.get(formData, 'custom_discipline', false) ? 1 : 0,
		};

		const validation = validateForm(
            updateTeam(this.props.table.edit_team, formData).then(() => {
                listAllTeams(this.props.match.params.stage);

				setNotification(__("wizard.edit-team.notification.success"));

				this.props.onClose();
			})
		);

		validation.catch(({ errors }) => {
			if (_.has(errors, "code")) {
				if (errors.code === 1) {
					setNotification({
						type: "warning",
						text: __("The team you have selected already exists in the specified stage discipline.")
					});
				}
			}
		});

		return validation;
	}

	render() {
		const {company_id, stage_discipline_id} = this.props.table;

		const stage_discipline = _.get(_.find(this.props.table.data, ['id', this.props.table.edit_team]), 'stage_discipline', {});

		const custom = !stage_discipline.discipline_id;

		let initialValues = {
			custom_discipline: custom,
			discipline: `STAGEDISCIPLINE-${stage_discipline_id}`,
			company_id: company_id,
			code: custom ? stage_discipline.code : undefined,
			description: custom ? stage_discipline.description : undefined,
		};

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard-edit-team.title.edit-team')}</span>
						<span>{__('wizard.edit-team.subtitle.edit-team')}</span>
					</div>
					<div className={styles.right}>
						<AddTeamIcon />
					</div>
				</div>
				<div className={styles.body}>
					<EditTeamForm onSubmit={this._handleSubmit} initialValues={initialValues} />
				</div>
			</>
		);
	}
}

export default EditTeamWizard;
