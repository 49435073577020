import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {__} from "../../core/utils";
import {enableAutosave} from "../../redux/actions/general/autosave";
import Input from "../../components/Input";

@reduxForm({
    form: "plans.edit_my_delivery",
    enableReinitialize: true,
})
class EditMyDeliveryForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit}>
                <Field name="title" component={Input} label={__('Title')} />
            </Form>
        );
    }
}

export default EditMyDeliveryForm;