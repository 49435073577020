import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/move_meeting_item_wizard/MoveMeetingItemForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';
import Textarea from '../../components/Textarea';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'move_meeting_item_wizard.move_meeting_item' })
class MoveBOQMeetingForm extends Component {
  render() {
    const { options } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='name'
            component={Textarea}
            disabled
            label={__('move-meeting-item.name')}
          />

          <Field
            name='move_action'
            component={RadioGroup}
            options={[
              {
                label: __('move-meeting-item.move-before'),
                value: 'before',
              },
              {
                label: __('move-meeting-item.move-after'),
                value: 'after',
              },
            ]}
          />

          <Field
            name='selected_item_id'
            component={Select}
            searchable
            noSort
            label={__('move-meeting-item.selected-item')}
            options={options}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('all_meetings', {
                move_meeting_item_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.move')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default MoveBOQMeetingForm;

// else if (level == 2) {
//   _.each(filtered_groups, (group) => {
//     _.each(group.children, (subgroup) => {
//       options.push({
//         value: _.last(subgroup.id.split('-')),
//         label: getLocalized(subgroup.name, language_id),
//         group: getLocalized(group.name, language_id),
//       });
//     });
//   });
// } else if (level == 3) {
//   _.each(filtered_groups, (group) => {
//     _.each(group.children, (subgroup) => {
//       _.each(subgroup.children, (item) => {
//         options.push({
//           value: _.last(item.id.split('-')),
//           label: getLocalized(item.name, language_id),
//           group: [
//             getLocalized(group.name, language_id),
//             getLocalized(subgroup.name, language_id),
//           ].join(' / '),
//         });
//       });
//     });
//   });
// } else if (level == 4) {
//   _.each(filtered_groups, (group) => {
//     _.each(group.children, (subgroup) => {
//       _.each(subgroup.children, (item) => {
//         _.each(item.children, (calculation) => {
//           options.push({
//             value: _.last(calculation.id.split('-')),
//             label: getLocalized(calculation.name, language_id),
//             group: [
//               getLocalized(group.name, language_id),
//               getLocalized(subgroup.name, language_id),
//               getLocalized(item.name, language_id),
//             ].join(' / '),
//           });
//         });
//       });
//     });
//   });
// }

{
  /* {level == 2 && (
            <Field
              name='boq_subgroup_id'
              component={Select}
              searchable
              noSort
              label='Subgroup'
              options={options}
            />
          )}
          {level == 3 && (
            <Field
              name='boq_item_id'
              component={Select}
              searchable
              noSort
              label='Item'
              options={options}
            />
          )}
          {level == 4 && (
            <Field
              name='boq_calculation_id'
              component={Select}
              searchable
              noSort
              label='Cost'
              options={options}
            />
          )} */
}
