import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/address_book_companies/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import {listAddressBookCompanies, updateAddressBookCompany} from "../../redux/actions/table/address_book_companies";
import EditAddressBookCompanyForm from "../../forms/address_book_companies/EditAddressBookCompanyForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateAddressBookCompany(formData.id, _.omit(formData, ['id'])).then(() => {
                listAddressBookCompanies();

                this.props.readAction(formData.id);

                setNotification(__('address-book-companies.flyout.notification.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditAddressBookCompanyForm
                    onSubmit={this._handleSubmit}
                    data={data}
                    initialValues={{
                        ..._.pick(data, ['id', 'name', 'vat', 'web', 'is_manufacturer', 'is_supplier', 'is_designer', 'is_client', 'disciplines', 'address', 'city', 'zip', 'state', 'country_id', 'tags', 'note']),
                        emails: _.isEmpty(data.emails) ? [''] : data.emails,
                        phones: _.isEmpty(data.phones) ? [''] : data.phones,
                    }}
                />
            </div>
        );
    }
}

export default Properties;
