import store from "../../../core/store";

export const listOCRTemplates = (stage_id) => {
    return store.dispatch({
        action: "table.ocr_templates/LIST_OCR_TEMPLATES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/ocr_templates",
        main: true
    });
};

export const createOCRTemplate = (stage_id, formData) => {
    return store.dispatch({
        action: "table.ocr_templates/CREATE_OCR_TEMPLATE",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/ocr_templates",
        data: formData
    });
};

export const deleteOCRTemplates = (ocr_template_ids) => {
    return store.dispatch({
        action: "table.ocr_templates/DELETE_OCR_TEMPLATES",
        method: "DELETE",
        endpoint: "/ocr_templates",
        data: {
            ocr_templates: ocr_template_ids
        }
    });
};

export const readOCRTemplate = (ocr_template_id) => {
    return store.dispatch({
        action: "table.ocr_templates/READ_OCR_TEMPLATE",
        method: "GET",
        endpoint: "/ocr_templates/" + ocr_template_id,
        has404: true
    });
};

export const updateOCRTemplate = (ocr_template_id, formData) => {
    return store.dispatch({
        action: "table.ocr_templates/UPDATE_OCR_TEMPLATE",
        method: "PUT",
        endpoint: "/ocr_templates/" + ocr_template_id,
        data: formData
    });
};

export const listPossibleOCRTemplates = (stage_id) => {
    return store.dispatch({
        action: "table.ocr_templates/LIST_POSSIBLE_OCR_TEMPLATES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_ocr_templates",
    });
};