import store from "../../../../core/store";

export const listUnitGroups = () => {
	return store.dispatch({
		action: "table.superadmin_unit_groups/LIST_UNIT_GROUPS",
		method: "GET",
		endpoint: "/superadmin/unit_groups",
		main: true
	});
};

export const createUnitGroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_unit_groups/CREATE_UNIT_GROUP",
		method: "POST",
		endpoint: "/superadmin/unit_groups",
		data: formData
	});
};

export const deleteUnitGroups = (unit_group_ids) => {
	return store.dispatch({
		action: "table.superadmin_unit_groups/DELETE_UNIT_GROUPS",
		method: "DELETE",
		endpoint: "/superadmin/unit_groups",
		data: {
			unit_groups: unit_group_ids
		}
	});
};

export const readUnitGroup = (unit_group_id) => {
	return store.dispatch({
		action: "table.superadmin_unit_groups/READ_UNIT_GROUP",
		method: "GET",
		endpoint: "/superadmin/unit_groups/" + unit_group_id,
		has404: true
	});
};

export const updateUnitGroup = (unit_group_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_unit_groups/UPDATE_UNIT_GROUP",
		method: "PUT",
		endpoint: "/superadmin/unit_groups/" + unit_group_id,
		data: formData
	});
};