import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddBrandWizard.scss";
import autobind from "autobind-decorator";
import {__, setTableParams, validateForm} from "../core/utils";
import AddAddressBookCompanyIcon from "../assets/images/add-address-book-company-64x64.svg";
import {setNotification} from "../redux/actions/general/notification";
import AddBrandform from "../forms/add_brand_wizard/AddBrandForm";
import {createBrand, listBrands} from "../redux/actions/table/brands";

class AddBrandWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;

        formData = _.omit(formData, ['edit']);

        return validateForm(
            createBrand(formData).then(({response}) => {
                listBrands().then(() => {
                    if(edit) {
                        setTableParams('brands', {
                            clicked_row: response.data.id
                        });
                    }
                });

                this.props.onClose();

                setNotification(__("wizard.add-brand.message.success"));
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.brands.title.add-brand')}</span>
                        <span>{__('wizard.brands.subtitle.add-brand')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddAddressBookCompanyIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddBrandform onSubmit={this._handleSubmit} />
                </div>
            </>
        );
    }
}

export default AddBrandWizard;
