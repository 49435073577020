import store from "../../../core/store";

export const listRoomTypes = (stage_id) => {
    return store.dispatch({
        action: "table.room_types/LIST_ROOM_TYPES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/room_types",
        main: true,
    });
};

export const createRoomType = (stage_id, formData) => {
    return store.dispatch({
        action: "table.room_types/CREATE_ROOM_TYPE",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/room_types",
        data: formData
    });
};

export const readRoomType = (room_type_id) => {
    return store.dispatch({
        action: "table.room_types/READ_ROOM_TYPE",
        method: "GET",
        endpoint: "/room_types/" + room_type_id,
        has404: true
    });
};

export const updateRoomType = (room_type_id, formData) => {
    return store.dispatch({
        action: "table.room_types/UPDATE_ROOM_TYPE",
        method: "PUT",
        endpoint: "/room_types/" + room_type_id,
        data: formData
    });
};

export const deleteRoomTypes = (room_type_ids) => {
    return store.dispatch({
        action: "table.room_types/DELETE_ROOM_TYPES",
        method: "DELETE",
        endpoint: "/room_types",
        data: {
            room_types: room_type_ids
        }
    });
};