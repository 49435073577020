import store from "../../../../core/store";

export const listLanguages = () => {
	return store.dispatch({
		action: "table.superadmin_languages/LIST_LANGUAGES",
		method: "GET",
		endpoint: "/superadmin/languages",
		main: true
	});
};

export const createLanguage = (formData) => {
	return store.dispatch({
		action: "table.superadmin_languages/CREATE_LANGUAGE",
		method: "POST",
		endpoint: "/superadmin/languages",
		data: formData
	});
};

export const deleteLanguages = (language_ids) => {
	return store.dispatch({
		action: "table.superadmin_languages/DELETE_LANGUAGES",
		method: "DELETE",
		endpoint: "/superadmin/languages",
		data: {
			languages: language_ids
		}
	});
};

export const readLanguage = (language_id) => {
	return store.dispatch({
		action: "table.superadmin_languages/READ_LANGUAGE",
		method: "GET",
		endpoint: "/superadmin/languages/" + language_id,
		has404: true
	});
};

export const updateLanguage = (language_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_languages/UPDATE_LANGUAGE",
		method: "PUT",
		endpoint: "/superadmin/languages/" + language_id,
		data: formData
	});
};