import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddLinkedSpecificationWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import AddLinkedSpecificationIcon from "../assets/images/add-linked-specification-64x64.svg";
import {withRouter} from "react-router";
import AddLinkedSpecificationForm from "../forms/add_linked_specification_wizard/AddLinkedSpecificationForm";
import {
    addLinkedSpecificationCode,
    readWorkingSetSpecification
} from "../redux/actions/table/working_set_specifications";
import {listOCRTemplates} from "../redux/actions/table/ocr_templates";
import {setNotification} from "../redux/actions/general/notification";

@mapStateToProps(state => ({
    store: _.get(state.table, 'working_set_specifications')
}))
@withRouter
class AddLinkedSpecificationWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            addLinkedSpecificationCode(this.props.store.flyout.specification_code_id, formData).then(() => readWorkingSetSpecification(this.props.store.flyout.id).then(({response}) => {
                setNotification("A linked specification has been added.");

                setTableParams('working_set_specifications', {
                    flyout: response.data
                });
                this.props.onClose();
            }))
        )
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.working-set-specifications.title.add-linked-specification')}</span>
                        <span>{__('wizard.working-set-specifications.subtitle.add-linked-specification')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddLinkedSpecificationIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddLinkedSpecificationForm onSubmit={this._handleSubmit} initialValues={{
                        included_in_price: '0',
                        quantity: 1
                    }} />
                </div>
            </>
        );
    }
}

export default AddLinkedSpecificationWizard;
