const initialState = {
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.overlay/SHOW_OVERLAY":
            return {
                show: true
            };
        case "general.overlay/HIDE_OVERLAY":
            return {
                show: false
            };
        default:
            return state;
    }
}
