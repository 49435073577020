import store from '../../../../core/store';

export const listNoteCategories = () => {
  return store.dispatch({
    action: 'table.superadmin_note_categories/LIST_NOTE_CATEGORIES',
    method: 'GET',
    endpoint: '/superadmin/note_categories',
    main: true,
  });
};

export const createNoteCategory = (formData) => {
  return store.dispatch({
    action: 'table.superadmin_note_categories/CREATE_NOTE_CATEGORY',
    method: 'POST',
    endpoint: '/superadmin/note_categories',
    data: formData,
  });
};

export const deleteNoteCategories = (note_categories_ids) => {
  return store.dispatch({
    action: 'table.superadmin_note_categories/DELETE_NOTE_CATEGORIES',
    method: 'DELETE',
    endpoint: '/superadmin/note_categories',
    data: {
      note_categories: note_categories_ids,
    },
  });
};

export const readNoteCategory = (note_category_id) => {
  return store.dispatch({
    action: 'table.superadmin_note_categories/READ_NOTE_CATEGORY',
    method: 'GET',
    endpoint: '/superadmin/note_categories/' + note_category_id,
    has404: true,
  });
};

export const updateNoteCategory = (note_category_id, formData) => {
  return store.dispatch({
    action: 'table.superadmin_note_categories/UPDATE_NOTE_CATEGORY',
    method: 'PUT',
    endpoint: '/superadmin/note_categories/' + note_category_id,
    data: formData,
  });
};
