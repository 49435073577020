import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/sites/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import {listSites, updateSite} from "../../redux/actions/table/sites";
import EditSiteForm from "../../forms/sites/EditSiteForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateSite(formData.id, _.omit(formData, ['id'])).then(() => {
                listSites(this.props.match.params.stage);

                this.props.readAction(formData.id);

                setNotification(__('sites.flyout.notification.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditSiteForm
                    onSubmit={this._handleSubmit}
                    data={data}
                    initialValues={{
                        ..._.pick(data, ['id', 'code', 'title', 'description', 'address', 'latitude', 'longitude', 'altitude', 'timezone_id', 'lot_number', 'area', 'wind_zone', 'earthquake_zone', 'salt_exposure', 'snow_loading_zone']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
