import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {mapStateToProps} from "../../../core/utils";
import {enableAutosave} from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import {withRouter} from "react-router";
import Checkbox from "../../../components/Checkbox";

@reduxForm({
    form: "superadmin_languages.edit_language",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
class EditLanguageForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {handleSubmit, superadmin} = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_language} name="code" component={Input} label="Code" />
                <Field disabled={!superadmin.edit_language} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_language} name="tesseract_name" component={Input} label="Tesseract (OCR) code" />
                <Field disabled={!superadmin.edit_language} name="application_language" component={Checkbox} label="Application language" />
            </Form>
        );
    }
}

export default EditLanguageForm;