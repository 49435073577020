import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { getLocalized, mapStateToProps } from '../../../core/utils';
import { enableAutosave } from '../../../redux/actions/general/autosave';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import { listLocalization } from '../../../redux/actions/localization';
import Textarea from '../../../components/Textarea';

@reduxForm({
  form: 'superadmin_boq_template_subgroups.edit_boq_template_subgroup',
  enableReinitialize: true,
})
@withRouter
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  application_language_id: state.auth.language_id,
}))
class EditBOQTemplateCalculationForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  componentDidMount() {
    listLocalization();
  }

  render() {
    const { handleSubmit, superadmin } = this.props;

    const language_id = this.props.application_language_id;

    const installation_types = [
      { value: 'OFCI', label: 'OFCI' },
      { value: 'OFOI', label: 'OFOI' },
      { value: 'CFCI', label: 'CFCI' },
      { value: 'CFOI', label: 'CFOI' },
    ];

    const budget_types = [
      { value: 'FF&E', label: 'FF&E' },
      { value: 'OS&E', label: 'OS&E' },
      { value: 'CAPEX', label: 'CAPEX' },
    ];

    const boq_supply_type_groups = _.sortBy(
      _.map(this.props.localization.boq_supply_type_groups, (unit) => ({
        label: getLocalized(unit.name, language_id),
        value: unit.id,
        tooltip: getLocalized(unit.name, language_id),
        priority: _.get(this.props.localization.boq_supply_type_groups, [
          unit.boq_supply_type_group_id,
          'priority',
        ]),
      })),
      'priority'
    );

    const application_languages = _.transform(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (languages, language) => {
        languages[language.id] = language.id == 1;

        return languages;
      },
      {}
    );

    const boq_template_subgroups = _.map(
      this.props.localization.boq_template_subgroups,
      (subgroup) => {
        return {
          value: subgroup.id,
          label: getLocalized(subgroup.name, language_id),
          group: getLocalized(
            _.get(this.props.localization.boq_template_groups, [
              subgroup.boq_template_group_id,
              'name',
            ]),
            language_id
          ),
        };
      }
    );

    const units = _.sortBy(
      _.map(this.props.localization.units, (unit) => ({
        label: unit.symbol,
        value: unit.id,
        tooltip: unit.description,
        group: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'name',
          1,
        ]),
        groupPriority: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'priority',
        ]),
      })),
      'groupPriority'
    );

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          disabled={!superadmin.edit_boq_template_subgroup}
          localized={application_languages}
          name='name'
          component={Input}
          label='Name'
        />
        <Field
          disabled={!superadmin.edit_boq_template_subgroup}
          localized={application_languages}
          name='description'
          component={Textarea}
          label='Description'
        />
        <Field
          disabled={!superadmin.edit_boq_template_subgroup}
          name='boq_template_subgroup_id'
          component={Select}
          searchable
          options={boq_template_subgroups}
          label='BOQ Template Subgroup'
        />
        <Field
          disabled={!superadmin.edit_boq_template_subgroup}
          name='unit_id'
          component={Select}
          label='Unit'
          searchable
          options={units}
        />
        <Field
          disabled={!superadmin.edit_boq_template_subgroup}
          name='price'
          component={Input}
          label='Price'
        />
        <Field
          name='budget_type'
          component={Select}
          label={'Budget type'}
          searchable
          options={budget_types}
          noSort
        />
        <Field
          name='installation_type'
          component={Select}
          label={'Installation type'}
          searchable
          options={installation_types}
          noSort
        />
        <Field
          name='boq_supply_type_group_id'
          component={Select}
          label={'Supply type group'}
          searchable
          options={boq_supply_type_groups}
          noSort
        />
      </Form>
    );
  }
}

export default EditBOQTemplateCalculationForm;
