import * as React from "react";
import {Component} from "react";
import styles from "../styles/layouts/MainLayout.scss";
import LeftBar from "../containers/LeftBar";
import SideBar from "../containers/SideBar";
import TopBar from "../containers/TopBar";
import {connect} from "react-redux";
import classNames from "classnames";
import FloatingProgress from "../containers/FloatingProgress";
import {screenIs} from "../core/utils";
import {hideSidebar} from "../redux/actions/general/sidebar";
import {withRouter} from "react-router";

@withRouter
class MainLayout extends Component {
	updateDimensions = () => {
		screenIs('<=', 1024) && hideSidebar();
	};

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	render() {
		const {sidebar, children} = this.props;

		return (
			<div className={styles.wrapper}>
				<LeftBar />
				<SideBar />
				<div className={classNames(styles.main, (this.props.match.path == '/' || sidebar.hidden) && styles.sidebarCollapsed)}>
					<TopBar />
					{children}
				</div>
				<FloatingProgress />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		sidebar: state.general.sidebar,
	};
}

export default connect(
	mapStateToProps,
	null
)(MainLayout);
