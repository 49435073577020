import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CreateFollowUpMeetingWizard.scss';
import classNames from 'classnames';
import {
  __,
  mapStateToProps,
  redirect,
  resetForm,
  validateForm,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import autobind from 'autobind-decorator';
import BasicAttributesForm from '../forms/create_followup_meeting_wizard/BasicAttributesForm';
import {
  createFollowUpMeeting,
  readFollowUpMeetingDetails,
  validateFollowUpMeetingBasicAttributes,
} from '../redux/actions/table/all_meetings';
import { withRouter } from 'react-router';
import AgendaItemsForm from '../forms/create_followup_meeting_wizard/AgendaItemsForm';
import { getFormValues } from 'redux-form';
import { setNotification } from '../redux/actions/general/notification';

@withRouter
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  meeting_id: _.get(state.table, 'all_meetings.create_followup_meeting_wizard'),
  basic_attributes: getFormValues(
    'create_followup_meeting_wizard.basic_attributes'
  )(state),
}))
class CreateFollowUpMeetingWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meeting: {},
    };
  }

  steps = [
    {
      id: '01',
      title: __('wizard.create-followup-meeting.title.basic-attributes'),
    },
    {
      id: '02',
      title: __('wizard.create-followup-meeting.title.agenda-items'),
    },
  ];

  componentWillUnmount() {
    resetForm('create_followup_meeting_wizard');
  }

  @autobind
  _setActiveStep(id) {
    this.setState({ active: _.find(this.steps, (item) => item.id === id) });
  }

  componentDidMount() {
    readFollowUpMeetingDetails(this.props.meeting_id).then(({ response }) => {
      this.setState({ meeting: response.data });
    });
  }

  @autobind
  _handleBasicAttributesSubmit(formData) {
    const { meeting_id } = this.props;

    formData = {
      ...formData,
      participants: _.map(formData.participants, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    return validateForm(
      validateFollowUpMeetingBasicAttributes(meeting_id, formData).then(() => {
        this._setActiveStep('02');
      })
    );
  }

  @autobind
  _handleAgendaItemsSubmit(formData) {
    const { meeting_id, basic_attributes } = this.props;
    const { footnote } = this.state.meeting;

    formData = {
      ...formData,
      ...basic_attributes,
      participants: _.map(basic_attributes.participants, 'id'),
      administrators: _.map(basic_attributes.administrators, 'id'),
      footnote,
    };

    return validateForm(
      createFollowUpMeeting(meeting_id, formData).then(({ response }) => {
        this.props.onClose();

        setNotification(__('wizard.create-followup-meeting.message.success'));

        setTimeout(() => {
          redirect('/meetings/' + response.data.id);
        }, 25);
      })
    );
  }

  render() {
    const active = _.get(this.state, 'active', _.first(this.steps));
    const { meeting } = this.state;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.titleBar}>
            <div className={styles.left}>
              <span>
                {__(
                  'wizard.create-followup-meeting.title.create-followup-meeting'
                )}
              </span>
              <span>{active.title}</span>
            </div>
            <div className={styles.right}>
              <span>{active.id}</span>
              <span>{_.last(this.steps).id}</span>
            </div>
          </div>
          <div className={styles.steps}>
            {_.map(this.steps, (step, i) => (
              <div
                key={i}
                className={classNames(
                  styles.step,
                  active.id == step.id && styles.active,
                  step.id < active.id && styles.completed
                )}
              >
                <span>{step.id}</span>
                <span>{step.title}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.body}>
          {!_.isEmpty(meeting) && active.id === '01' && (
            <BasicAttributesForm
              projectLanguages={meeting.project_languages}
              stageId={meeting.stage_id}
              initialValues={{
                status: 'agenda',
                visibility: meeting.visibility,
                duration: meeting.duration,
                title: _.mapValues(
                  meeting.title,
                  (title) => title.substring(0, 43) + ' (copy)'
                ),
                description: meeting.description,
                languages: _.map(meeting.languages, _.toString),
                participants: meeting.participants,
                administrators: meeting.administrators,
                location_name: meeting.location_name,
                location_address: meeting.location_address,
              }}
              onSubmit={this._handleBasicAttributesSubmit}
            />
          )}
          {!_.isEmpty(meeting) && active.id === '02' && (
            <AgendaItemsForm
              onBack={() => this._setActiveStep('01')}
              onSubmit={this._handleAgendaItemsSubmit}
              items={meeting.items}
              initialValues={{
                items: _.map(meeting.items, 'id'),
              }}
            />
          )}
        </div>
      </>
    );
  }
}

export default CreateFollowUpMeetingWizard;
