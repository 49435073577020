import {endsWith, startsWith} from "lodash";

const initialState = {};

export default function reducer(state = initialState, action) {
    if(startsWith(action.type, "table.") && endsWith(action.type, "_SUCCESS")) {
        return {
            ...state,
            [action.type]: action.request_at
        }
    } else {
        return state;
    }
}