import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {__} from "../../core/utils";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import styles from "../../styles/forms/company_info_wizard/DetailsForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {hideCreateCompanyWizard} from "../../redux/actions/profile";

@reduxForm({
	form: "company_info_wizard.details",
	destroyOnUnmount: false
})
class DetailsForm extends Component {
	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.form}>
					<div className={styles.flex}>
						<div className={styles.f2}>
							<Field name="name" component={Input} label={__('Company title')} />
						</div>
						<div className={styles.f1}>
							<Field name="vat" component={Input} label={__('VAT Number')} />
						</div>
					</div>
					<Field name="description" component={Input} label={__('Short Description')} maxLength={70} />
					<Field name="description_long" component={Textarea} label={__('Long Description')} maxLength={2000} rows={1} maxRows={10} />
				</div>
				<div className={styles.footer}>
					<Button lightGray medium left middleText={__('button.cancel')} onClick={hideCreateCompanyWizard} />
					<Button submit medium right lightBlue leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default DetailsForm;