import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/general/Invalid.scss";
import AuthLayout from "../../layouts/AuthLayout";
import {redirect} from "../../core/utils";

class Invalid extends Component {
    render() {
        return (
            <>
                <div className={styles.heading}>
                    <LogoIcon onClick={() => redirect('/')} />
                    <div className={styles.title}>
                        Oops ..
                    </div>
                </div>
                <div className={styles.details}>
                    <div className={styles.content}>
                        <strong>The link you have visited is not valid because:</strong>
                        <ul>
                            <li>The URL is not valid anymore</li>
                            <li>You do not have permission to access this resource</li>
                            <li>Mispelled URL</li>
                            <li>Unknown error</li>
                        </ul>
                        <div className={styles.check}>Please double-check the link you have clicked and try again.</div>
                        <div className={styles.support}>If you need any further assistant, please contact our support at <a href="mailto:support@volum3.com">support@volum3.com</a>.</div>
                    </div>
                </div>
            </>
        );
    }
}

export default Invalid;
