import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/products/Files.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import {
  __,
  bytesToSize,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import {
  deleteProductFile,
  readProduct,
  uploadProductFiles,
} from '../../redux/actions/table/products';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import ReactDropzone from 'react-dropzone';
import UploadIcon from '../../assets/images/upload-40x40.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import { Form, getFormValues, reduxForm } from 'redux-form';
import Select from '../../components/Select';
import Images from './Images';
import EditIcon from '../../assets/images/edit-16x16.svg';
import FilePreview from '../../components/FilePreview';

@reduxForm({
  form: 'products.files',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  flyout: state.table.products.flyout,
}))
class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
      files: [],
      active_category: null,
      category_errors: null,
    };
  }

  componentDidMount() {
    const { files, images } = this.props.flyout;

    this.setState({
      expanded: _.map([...files, ...images], (file) => file.category),
    });

    window.addEventListener('paste', this._handlePaste);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this._handlePaste);
  }

  @autobind
  _handlePaste(event) {
    const files = event.clipboardData.files;

    this.setState({
      files: [
        ...this.state.files,
        ..._.map(files, (file) => {
          return {
            file: Object.assign(file, {
              stream_url: URL.createObjectURL(file),
              ext: file.name.split('.').pop(),
            }),
            category: '',
          };
        }),
      ],
    });

    this.props.change('files', files);
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  @autobind
  _uploadFiles(category, files) {
    const product_id = this.props.flyout.id;

    setNotification(
      __('products.flyout.files-being-uploaded.please-wait.notification')
    );

    uploadProductFiles(product_id, this.state.files)
      .then(() => {
        readProduct(product_id).then(({ response }) => {
          setTableParams('products', {
            flyout: response.data,
          });
        });

        this.setState({
          files: [],
          category_errors: null,
          active_category: null,
        });
      })
      .catch((error) => {
        this.setState({
          category_errors: _.map(Object.keys(error.response.errors), (key) =>
            _.toNumber(_.first(key.match(/\d+/)))
          ),
        });

        setNotification({
          text: __(
            'products.flyout.files.cant-upload-file.doublecheck-file-size-format.warning'
          ),
          type: 'warning',
        });
      });
  }

  @autobind
  _downloadFile({ download_url }) {
    setNotification(
      __('products.flyout.files.products.files.download.continue')
    );

    window.open(download_url, '_blank');
  }

  @autobind
  _removeFile(category, attachment_id) {
    const product_id = this.props.flyout.id;

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'products.flyout.files.are-you-sure.remove-file.products.alert'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () =>
            deleteProductFile(product_id, category, attachment_id).then(() => {
              hideAlertbox();

              readProduct(product_id).then(({ response }) => {
                setTableParams('products', {
                  flyout: response.data,
                });
              });

              setNotification(
                __(
                  'products.flyout.files.file-has-been-deleted.products.notification'
                )
              );
            }),
        },
      ],
    });
  }

  @autobind
  _handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({ drag: false });

    if (!_.isEmpty(acceptedFiles)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('specifications.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('specifications.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (
        _.find(acceptedFiles, (file) => {
          return file.size >= 312857600;
        })
      ) {
        setNotification({
          text: __('specifications.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        this.setState({
          files: [
            ...this.state.files,
            ..._.map(acceptedFiles, (file) => {
              return {
                file: Object.assign(file, {
                  stream_url: URL.createObjectURL(file),
                  ext: file.name.split('.').pop(),
                }),
                category: '',
              };
            }),
          ],
        });

        this.props.change('files', acceptedFiles);
      }
    }
  }

  render() {
    const { collapsed, flyout } = this.props;
    const { expanded } = this.state;

    const categories = [
      {
        label: __('products.flyout.files.thumbnail'),
        value: 'thumbnail',
      },
      {
        label: __('products.flyout.files.product'),
        value: 'product',
      },
      {
        label: __('products.flyout.files.drawing'),
        value: 'drawing',
      },
      {
        label: __('products.flyout.files.ambient'),
        value: 'ambient',
      },
      {
        label: __('products.flyout.files.technical'),
        value: 'technical',
      },
      {
        label: __('products.flyout.files.tileable-texture-for-3d'),
        value: 'tileable',
      },
      {
        label: __('products.flyout.files.manuals'),
        value: 'manuals',
      },
      {
        label: __('products.flyout.files.guarantee'),
        value: 'guarantee',
      },
      {
        label: __('products.flyout.files.certificates'),
        value: 'certificates',
      },
      {
        label: __('products.flyout.files.catalogs'),
        value: 'catalogs',
      },
      {
        label: __('products.flyout.files.installation'),
        value: 'installation',
      },
      {
        label: __('products.flyout.files.bim'),
        value: 'bim',
      },
      {
        label: __('products.flyout.files.3d'),
        value: '3d',
      },
      {
        label: __('products.flyout.files.cad'),
        value: 'cad',
      },
      {
        label: __('products.flyout.files.2d'),
        value: '2d',
      },
      {
        label: __('products.flyout.files.photometrical-distribution'),
        value: 'light_data',
      },
      {
        label: __('products.flyout.files.video'),
        value: 'video',
      },
      {
        label: __('products.flyout.files.audio'),
        value: 'audio',
      },
      {
        label: __('products.flyout.files.other'),
        value: 'other',
      },
    ];

    const { files, category_errors, active_category } = this.state;

    const lightbox_files = _.map(
      _.filter(files, (row) =>
        _.includes(['jpg', 'jpeg', 'png'], _.toLower(row.file.ext))
      ),
      (row) => ({
        id: row.file.id,
        stream_url: row.file.stream_url,
        title: [row.file.name, row.file.ext].join('.'),
      })
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {/* Files list */}
        {_.map(categories, (category) => {
          const category_files = _.filter(
            [...flyout.files, ...flyout.images],
            ['category', category.value]
          );

          const lightbox_images = _.map(
            _.filter(category_files, (file) =>
              _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
            ),
            (file) => ({
              id: file.id,
              stream_url: file.stream_url,
              title: [file.name, file.ext].join('.'),
            })
          );

          return (
            <>
              {!_.isEmpty(category_files) && (
                <div className={styles.category} key={category.value}>
                  <div
                    className={styles.title}
                    onClick={() => this._toggleCollapseCategory(category.value)}
                  >
                    <ArrowDownMiddleIcon
                      className={classNames(
                        styles.collapse,
                        !_.includes(expanded, category.value) &&
                          styles.collapsed
                      )}
                    />
                    {category.label}{' '}
                    {!_.isEmpty(category_files) &&
                      '(' + _.size(category_files) + ')'}
                  </div>
                  {_.includes(expanded, category.value) &&
                    (_.includes(
                      [
                        'thumbnail',
                        'product',
                        'drawing',
                        'ambient',
                        'technical',
                        'tileable',
                      ],
                      category.value
                    ) ? (
                      // Change this component
                      <Images
                        data={category_files}
                        category={category.value}
                        handleDeleteProductFile={(attachment_id) =>
                          this._removeFile(category.value, attachment_id)
                        }
                      />
                    ) : (
                      !_.isEmpty(category_files) && (
                        <div className={styles.files}>
                          {_.map(category_files, (file, i) => (
                            <div className={styles.file} key={i}>
                              <FilePreview
                                onDownloadFile={this._downloadFile}
                                row={file}
                                lightbox_images={lightbox_images}
                              />

                              <div className={styles.icons}>
                                <div className={styles.edit}>
                                  <EditIcon
                                    onClick={() =>
                                      setTableParams('products', {
                                        edit_product_file_category:
                                          category.value,
                                        attachment_id: file.id,
                                        file_ext: file.ext,
                                      })
                                    }
                                  />
                                </div>
                                <div className={styles.remove}>
                                  <CloseMediumIcon
                                    onClick={() =>
                                      this._removeFile(category.value, file.id)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    ))}
                </div>
              )}
            </>
          );
        })}

        {/* Dropzone - upload files */}
        <ReactDropzone
          className={classNames(
            styles.dropzone,
            _.get(this.state, 'drag') && styles.drag
          )}
          onDrop={this._handleDrop}
          onDragOver={() => this.setState({ drag: true })}
          onDragLeave={() => this.setState({ drag: false })}
          multiple={true}
        >
          <UploadIcon />
          <span className={styles.dropTitle}>
            {__('dropzone.title.drop-your-file-here')}
          </span>
          <span className={styles.dropSubtitle}>
            {__('dropzone.title.or-select')}
          </span>
        </ReactDropzone>

        {/* Clickboard files */}
        {!_.isEmpty(files) && (
          <Form className={styles.clickboardFiles} onSubmit={this.handleSubmit}>
            {_.map(files, (row, i) => {
              const key = _.toNumber(i);
              return (
                <div className={styles.fileRow} key={key}>
                  <div className={styles.left}>
                    <FilePreview
                      onDownloadFile={this._downloadFile}
                      row={row.file}
                      lightbox_images={lightbox_files}
                    />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.select}>
                      <Select
                        placeholder={__('products.flyout.select-file-category')}
                        // label={__('products.flyout.file-category')}
                        input={{
                          value:
                            this.state.files && this.state.files[i]?.category
                              ? this.state.files[i].category
                              : '',
                          onChange: (value) => {
                            this.setState({
                              files: {
                                ...this.state.files,
                                [key]: {
                                  ...this.state.files[key],
                                  category: value,
                                },
                              },

                              category_errors: _.filter(
                                category_errors,
                                (error) => error != key
                              ),
                            });
                          },
                          onBlur: () =>
                            this.setState({
                              active_category: { [key]: false },
                            }),
                          onFocus: () =>
                            this.setState({ active_category: { [key]: true } }),
                        }}
                        meta={{
                          active: active_category && active_category[key],
                          error: _.includes(category_errors, key),
                          touched: _.includes(category_errors, key),
                        }}
                        options={
                          !_.includes(
                            ['jpg', 'jpeg', 'png'],
                            _.toLower(row.file.ext)
                          )
                            ? _.filter(
                                categories,
                                (category) =>
                                  !_.includes(
                                    [
                                      'thumbnail',
                                      'product',
                                      'drawing',
                                      'ambient',
                                      'technical',
                                      'tileable',
                                    ],
                                    category.value
                                  )
                              )
                            : categories
                        }
                        noSort
                        top={28}
                        searchable
                      />
                    </div>
                    <div className={styles.icons}>
                      <DeleteIcon
                        onClick={() => {
                          this.setState(
                            {
                              files: _.filter(files, (file) => file != row),
                            },
                            () => {
                              this.props.change('files', this.state.files);
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className={styles.addRow}>
              <button onClick={this._uploadFiles} type='button'>
                {__('products.flyout.add-files')}
              </button>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default Files;
