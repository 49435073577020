import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import * as _ from "lodash";
import {connect} from "react-redux";
import {createSidebar, updateSidebar} from "../../redux/actions/general/sidebar";
import {
    enableProjectsDropdown,
    enableStagesDropdown,
    listActiveModules
} from "../../redux/actions/general/active_modules";
import classNames from "classnames";
import {
    __,
    backendURL,
    bytesToSize,
    dateFrom,
    dateTimeFrom,
    getActiveStage,
    getURLParam,
    hasURLParam,
    redirect,
    setURLParam
} from "../../core/utils";
import Table from "../../components/Table";
import Tooltip from "../../components/Tooltip";
import {listDocuments} from "../../redux/actions/table/documents";
import DownloadIcon from "../../assets/images/download-15x15.svg";
import {setNotification} from "../../redux/actions/general/notification";
import {downloadFile} from "../../redux/actions/general/downloader";
import {setPlusActions} from "../../redux/actions/general/plus";
import {showLightbox} from "../../redux/actions/general/lightbox";
import styles from "../../styles/views/documents/Documents.scss";
import PlanIcon from "../../assets/images/plan-15x15.svg";
import TasksIcon from "../../assets/images/tasks-16x16.svg";
import TableNA from "../../components/TableNA";
import MeetingIcon from "../../assets/images/meeting-16x16.svg";

const mapStateToProps = state => {
    return {
        table: state.table["documents"],
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.documents/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class Documents extends Component {
    componentDidMount() {
        enableProjectsDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/documents'));
        enableStagesDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/documents'));

        createSidebar({
            title: __('submenu.title.documents'),
            items: [
                {
                    title: __("submenu.title.document-types"),
                    link: () => "/stages/" + getActiveStage() + "/documents?filter.extension=",
                    active: true,
                }
            ]
        });

        if(!hasURLParam('filter.extension')) {
            setURLParam('filter.extension', '');
        }
    }

    componentDidUpdate(prevProps) {
        //if there are new filter options available, update sidebar
        if(!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
            const items = _.get(this.props.table.filters, 'extension.items');

            updateSidebar('items.0.items', [
                {
                    title: __('submenu.title.all-document-types'),
                    onClick: () => setURLParam('filter.extension', ''),
                    active: () => {
                        const active_extensions = _.split(getURLParam('filter.extension'), '|');

                        const intersected = _.intersection(active_extensions, _.keys(items));

                        return _.isEmpty(getURLParam('filter.extension')) || _.size(intersected) == _.size(items);
                    },
                    checkbox: {
                        status: () => {
                            const active_extensions = _.split(getURLParam('filter.extension'), '|');

                            const intersected = _.intersection(active_extensions, _.keys(items));

                            if(_.isEmpty(getURLParam('filter.extension'))) {
                                return 'checked';
                            }

                            if(_.isEmpty(intersected)) {
                                return 'unchecked';
                            } else if(_.size(intersected) == _.size(items)) {
                                return 'checked';
                            } else {
                                return 'neutral';
                            }
                        },
                        onClick: () => {
                            const active_extensions = _.split(getURLParam('filter.extension'), '|');

                            const intersected = _.intersection(active_extensions, _.keys(items));

                            if(_.size(intersected) == _.size(items) || _.isEmpty(getURLParam('filter.extension'))) {
                                setURLParam('filter.extension', 'n/a');
                            } else {
                                setURLParam('filter.extension', '');
                            }
                        }
                    }
                },
                ..._.map(items, (extension, extension_id) => ({
                    title: extension,
                    onClick: () => setURLParam('filter.extension', extension_id),
                    active: () => {
                        const active_extensions = _.split(getURLParam('filter.extension'), '|');

                        return _.isEmpty(getURLParam('filter.extension')) || _.includes(active_extensions, extension_id);
                    },
                    checkbox: {
                        status: () => {
                            const active_extensions = _.split(getURLParam('filter.extension'), '|');

                            if(_.isEmpty(getURLParam('filter.extension'))) {
                                return 'checked';
                            }

                            return _.includes(active_extensions, extension_id) ? 'checked' : 'unchecked';
                        },
                        onClick: () => {
                            const setExtensions = (extensions) => {
                                extensions = _.trim(_.join(_.reject(extensions, extension => extension == 'n/a'), '|'), '|');

                                setURLParam('filter.extension', _.isEmpty(extensions) ? 'n/a' : extensions);
                            }

                            const active_extensions = getURLParam('filter.extension') == "" ? _.keys(items) : _.split(getURLParam('filter.extension'), '|');

                            if(_.includes(active_extensions, extension_id)) {
                                setExtensions(_.reject(active_extensions, row => row == extension_id))
                            } else {
                                active_extensions.push(extension_id);

                                setExtensions(active_extensions)
                            }
                        }
                    }
                }))
            ]);
        }
    }

    render() {
        const columns = [
            {
                key: "title",
                name: __("documents.table.title.title"),
                required: true,
                value: (value, row) => _.join([row.data.name, row.data.ext], '.')
            }, {
                key: "module_type",
                name: __("Go To"),
                width: 100,
                value: (type, row) => {
                    if(type == 'tasks') {
                        return (
                            <Tooltip text={_.filter([row.code, row.title]).join(': ')}>
                                <div className={styles.icon} onClick={(e) => {
                                    e.stopPropagation();
                                    redirect('/stages/' + this.props.match.params.stage + '/tasks?id=' + row.module_id);
                                }}>
                                    <TasksIcon className={styles.tasks} />
                                    <span>{row.code}</span>
                                </div>
                            </Tooltip>
                        )
                    } else if(type == 'meeting') {
                        return (
                            <Tooltip text={_.filter([row.code, row.title]).join(': ')}>
                                <div className={styles.icon} onClick={(e) => {
                                    e.stopPropagation();

                                    redirect('/meetings/' + row.module_id);
                                }}>
                                    <MeetingIcon className={styles.meetings}/>
                                    <span>{row.code}</span>
                                </div>
                            </Tooltip>
                        )
                    } else if(type == 'plan_uploads') {
                        return (
                            <Tooltip text={_.filter([row.code, row.title]).join(': ')}>
                                <div className={styles.icon} onClick={(e) => {
                                    e.stopPropagation();

                                    redirect('/stages/' + this.props.match.params.stage + '/plans/uploads?id=' + row.module_id);
                                }}>
                                    <PlanIcon className={styles.plans} />
                                    <span>{row.code}</span>
                                </div>
                            </Tooltip>
                        )
                    } else if(type == 'working_set_plans') {
                        return (
                            <Tooltip text={_.filter([row.code, row.title]).join(': ')}>
                                <div className={styles.icon} onClick={(e) => {
                                    e.stopPropagation();
                                    redirect('/stages/' + this.props.match.params.stage + '/plans/working?id=' + row.module_id);
                                }}>
                                    <PlanIcon className={styles.plans} />
                                    <span>{row.code}</span>
                                </div>
                            </Tooltip>
                        )
                    } else if(type == 'current_set_plans') {
                        return (
                            <Tooltip text={_.filter([row.code, row.title]).join(': ')}>
                                <div className={styles.icon} onClick={(e) => {
                                    e.stopPropagation();
                                    redirect('/stages/' + this.props.match.params.stage + '/plans/current?id=' + row.module_id);
                                }}>
                                    <PlanIcon className={styles.plans} />
                                    <span>{row.code}</span>
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return <TableNA />;
                    }
                }
            }, {
                key: "size",
                name: __("documents.table.title.filesize"),
                width: 80,
                sortable: false,
                value: value => bytesToSize(value)
            }, {
                key: "updated_at",
                name: __("documents.table.title.added"),
                default: true,
                desc: true,
                value: value => <Tooltip text={dateTimeFrom(value)}>{dateFrom(value)}</Tooltip>,
                width: 80
            }, {
                key: "updated_by",
                name: __("documents.table.title.added-by"),
                value: (value, row) => <Tooltip text={row.updated_by}>{row.updated_by}</Tooltip>,
                width: 130
            }
        ];

        let singleActions = (attachment_id) => {
            const attachment = _.find(this.props.table.data, {id: attachment_id});

            const actions = [
                <Tooltip text={__('plans.tooltip.icon.documents.download')} key={_.uniqueId()}><DownloadIcon
                    onClick={e => {
                        e.stopPropagation();

                        setNotification(__('plan-uploads.files.download.continue'));

                        window.open(_.get(_.find(this.props.table.data, {id: attachment_id}), 'download_url'), '_blank');
                    }}
                /></Tooltip>,
            ];

            switch(attachment.module_type) {
                case 'tasks':
                    actions.push(
                        <Tooltip text={_.filter([attachment.code, attachment.title]).join(': ')} key={_.uniqueId()}>
                            <TasksIcon className={styles.tasks} onClick={(e) => {
                                e.stopPropagation();
                                redirect('/stages/' + this.props.match.params.stage + '/tasks?id=' + attachment.module_id);
                            }} />
                        </Tooltip>
                    );
                    break;
                case 'meeting':
                    actions.push(
                        <Tooltip text={_.filter([attachment.code, attachment.title]).join(': ')} key={_.uniqueId()}>
                            <MeetingIcon className={styles.meetings} onClick={(e) => {
                                e.stopPropagation();

                                redirect('/stages/' + this.props.match.params.stage + '/meetings?id=' + attachment.module_id);
                            }} />
                        </Tooltip>
                    )
                    break;
                case 'plan_uploads':
                    actions.push(
                        <Tooltip text={_.filter([attachment.code, attachment.title]).join(': ')} key={_.uniqueId()}>
                            <PlanIcon className={styles.plans} onClick={(e) => {
                                e.stopPropagation();

                                redirect('/stages/' + this.props.match.params.stage + '/plans/uploads?id=' + attachment.module_id);
                            }} />
                        </Tooltip>
                    )
                    break;
                case 'working_set_plans':
                    actions.push(
                        <Tooltip text={_.filter([attachment.code, attachment.title]).join(': ')} key={_.uniqueId()}>
                            <PlanIcon className={styles.plans} onClick={(e) => {
                                e.stopPropagation();
                                redirect('/stages/' + this.props.match.params.stage + '/plans/working?id=' + attachment.module_id);
                            }} />
                        </Tooltip>
                    )
                    break;
                case 'current_set_plans':
                    actions.push(
                        <Tooltip text={_.filter([attachment.code, attachment.title]).join(': ')} key={_.uniqueId()}>
                            <PlanIcon className={styles.plans} onClick={(e) => {
                                e.stopPropagation();
                                redirect('/stages/' + this.props.match.params.stage + '/plans/current?id=' + attachment.module_id);
                            }} />
                        </Tooltip>
                    )
                    break;
            }

            return actions;
        }

        const groupActions = (attachment_ids) => [
            <Tooltip text={__('plans.tooltip.icon.documents.download')} key={_.uniqueId()}>
                <DownloadIcon
                    onClick={e => {
                        e.stopPropagation();

                        setNotification(__('plan-uploads.files.download.continue'));

                        downloadFile(backendURL + "/documents/download", {
                            attachments: attachment_ids
                        }, "POST");
                    }}
                />
            </Tooltip>
        ];

        const gridOptions = {
            section: __('submenu.title.documents'),
            image: (id, row) => row.thumb_url,
            title: (row) => [row.data.name, row.data.ext].join('.'),
            rightTitle: (row) => '',
            subTitle: (row) => bytesToSize(row.size),
            content: (row) => [
                dateTimeFrom(row.updated_at),
                'by ' + row.updated_by
            ]
        };

        const lightbox_images = _.map(_.filter(this.props.table.data, 'stream_url'), file => ({
            id: file.id,
            stream_url: file.stream_url,
            title: [file.data.name, file.data.ext].join('.')
        }));

        return (
            <Table
                listAction={() => listDocuments(this.props.match.params.stage)}
                name="documents"
                singleActions={singleActions}
                groupActions={groupActions}
                gridOptions={gridOptions}
                title={__('submenu.title.documents')}
                columns={columns}
                onLoad={() => {
                    listActiveModules().then(() => {
                        setPlusActions([{
                            title: __('button.upload-new-document'),
                            onClick: () => this.props.actions.setTableParams({
                                wizard_active: true
                            })
                        }]);
                    })
                }}
                onRowClick={(id) => {
                    const stream_url = _.get(_.find(this.props.table.data, {id}), 'stream_url');

                    if(stream_url) {
                        showLightbox(lightbox_images, id);
                    } else {
                        window.open(_.get(_.find(this.props.table.data, {id: id}), 'download_url'), '_blank');
                    }
                }}
            />
        );
    }
}

export default Documents;