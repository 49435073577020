import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/room_groups/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import {
  listRoomGroups,
  updateRoomGroup,
} from '../../redux/actions/table/room_groups';
import EditRoomGroupForm from '../../forms/room_groups/EditRoomGroupForm';

@withRouter
class Properties extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateRoomGroup(formData.id, _.omit(formData, ['id'])).then(() => {
        listRoomGroups(this.props.match.params.stage);

        this.props.readAction(formData.id);

        setNotification(__('room-groups.flyout.notification.saved'));
      })
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.scroller}>
        <EditRoomGroupForm
          onSubmit={this._handleSubmit}
          data={data}
          initialValues={{
            ..._.pick(data, [
              'id',
              'code',
              'title',
              'description',
              'requirement',
            ]),
          }}
        />
      </div>
    );
  }
}

export default Properties;
