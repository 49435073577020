import store from "../../../core/store";

export const listMyDeliveries = (stage_id) => {
    return store.dispatch({
        action: "table.my_deliveries/LIST_MY_DELIVERIES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/deliveries/my",
        main: true
    });
};

export const sendDeliveries = (delivery_ids) => {
    return store.dispatch({
        action: "table.my_deliveries/SEND_DELIVERIES",
        method: "POST",
        endpoint: "/deliveries/my/send",
        data: {
            deliveries: delivery_ids
        }
    });
};

export const readMyDelivery = (delivery_id) => {
    return store.dispatch({
        action: "table.my_deliveries/READ_MY_DELIVERY",
        method: "GET",
        endpoint: "/deliveries/my/" + delivery_id
    });
};

export const updateMyDelivery = (delivery_id, formData) => {
    return store.dispatch({
        action: "table.my_deliveries/UPDATE_MY_DELIVERY",
        method: "PUT",
        endpoint: "/deliveries/my/" + delivery_id,
        data: formData
    });
};

export const deleteMyDeliveries = (delivery_ids) => {
    return store.dispatch({
        action: "table.my_deliveries/DELETE_MY_DELIVERIES",
        method: "DELETE",
        endpoint: "/deliveries/my/",
        data: {
            deliveries: delivery_ids
        }
    });
};