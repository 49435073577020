import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_boq_items_as_template_wizard/AddBOQItemsAsTemlateForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __ } from '../../core/utils';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Input from '../../components/Input';

const AddBOQItemsAsTemplateForm = ({ handleSubmit, onClose, submitting }) => {
  return (
    <Form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.f1}>
          <Field
            name='name'
            component={Input}
            label={__('working_set_boq.add_boq_items_as_template.field.name')}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={onClose}
        />

        <Button
          lightBlue
          medium
          middleText={__('button.add')}
          spinning={submitting}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(
  reduxForm({
    form: 'add_boq_items_as_template_wizard.add_boq_items_as_template',
    enableReinitialize: true,
  })(
    connect(
      mapStateToProps,
      null
    )(subscribe(Localization, 'localization')(AddBOQItemsAsTemplateForm))
  )
);
