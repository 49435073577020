import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/MultiplePlanTagsEditWizard.scss";
import autobind from "autobind-decorator";
import TagIcon from "../assets/images/tag-64x64.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import {listPlanUploads, multipleEditPlanTags} from "../redux/actions/table/plan_uploads";
import {listWorkingSetPlans} from "../redux/actions/table/working_set_plans";
import MultiplePlanTagsEditForm from "../forms/wizard_multiple_plan_tags_edit/MultiplePlanTagsEditForm";

@mapStateToProps((state) => ({
	plan_ids: _.get(state.table, "plan_uploads.wizard_multiple_plan_tags_edit"),
}))
@withRouter
class MultiplePlanTagsEditWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const {plan_ids} = this.props;
		
		return validateForm(
			multipleEditPlanTags(plan_ids, _.get(formData, 'tags')).then(() => {
				if(this.props.match.path == "/stages/:stage/plans/uploads") {
					listPlanUploads(this.props.match.params.stage);

					setTableParams('plan_uploads', {
						selected_rows: []
					});
				} else if(this.props.match.path == "/stages/:stage/plans/working") {
					listWorkingSetPlans(this.props.match.params.stage);

					setTableParams('working_set_plans', {
						selected_rows: []
					});
				}

				setNotification(__("Multiple plan tags have been saved."));

				this.props.onClose();
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('Multiple Edit Plan Tags')}</span>
						<span>{__('Choose the tags for selected plans')}</span>
					</div>
					<div className={styles.right}>
						<TagIcon />
					</div>
				</div>
				<div className={styles.body}>
					<MultiplePlanTagsEditForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default MultiplePlanTagsEditWizard;
