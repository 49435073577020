import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditProductNoteWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import AddNoteIcon from '../assets/images/add-note-60x60.svg';
import {
  addProductNote,
  readProduct,
  updateProductNote,
} from '../redux/actions/table/products';
import EditProductNoteForm from '../forms/edit_product_note_wizard/EditProductNoteForm';

@mapStateToProps((state) => ({
  flyout: state.table.products.flyout,
  data: _.get(state.table, 'products.edit_product_note_data'),
}))
class EditProductNoteWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { id } = this.props.flyout;

    const { edit } = formData;

    formData = _.omit(formData, ['edit']);

    return validateForm(
      (edit
        ? updateProductNote(id, formData)
        : addProductNote(id, { ...formData, add_new: 1 })
      ).then(() =>
        readProduct(this.props.flyout.id).then(({ response }) => {
          setNotification('The product note has been updated.');

          setTableParams('products', {
            flyout: response.data,
          });

          this.props.onClose();
        })
      )
    );
  }

  render() {
    const { text, category_id, priority, id } = this.props.data;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('products.flyout.edit-instruction.title')}</span>
            <span>{__('products.flyout.edit-instruction.subtitle')}</span>
          </div>
          <div className={styles.right}>
            <AddNoteIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditProductNoteForm
            onSubmit={this._handleSubmit}
            initialValues={{
              note_category_id: category_id,
              note: text,
              id: id,
              priority: priority,
            }}
          />
        </div>
      </>
    );
  }
}

export default EditProductNoteWizard;
