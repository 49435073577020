import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import {__, backendURL, dateTimeFrom, mapStateToProps, redirect, setTableParams} from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import { listCompanies } from "../../redux/actions/table/superadmin/companies";
import styles from "../../styles/views/superadmin/Companies.scss";
import * as _ from "lodash";
import Avatar from "../../components/Avatar";
import Tooltip from "../../components/Tooltip";
import TableNA from "../../components/TableNA";
import classNames from "classnames";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import ExportIcon from "../../assets/images/export-15x15.svg";
import {setNotification} from "../../redux/actions/general/notification";
import {downloadFile} from "../../redux/actions/general/downloader";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin
}))
class Companies extends Component {
	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;
	}

	render() {
		const columns = [
			{
				key: "id",
				name: __('superadmin.companies.column.id'),
				width: 60
			},
			{
				key: "name",
				name: __('superadmin.companies.column.name'),
				required: true,
				value: name => <Tooltip text={name}>{name}</Tooltip>
			},
			{
				key: "public",
				name: __('superadmin.companies.column.public'),
				value: is_public => (
					<div className={classNames(styles.public, is_public && styles.yes)}>{is_public ? __('general.yes') : __('general.no')}</div>
				),
				width: 50,
				fullWidth: true,
			},
			{
				key: "vat",
				name: __('superadmin.companies.column.vat'),
				width: 130,
				value: vat => vat || <TableNA />
			},
			{
				key: "size",
				name: __('superadmin.companies.column.size'),
				width: 130,
				value: size => size.name + ' (' + size.size + ')'
			}, {
				key: "total_members",
				name: __('superadmin.companies.column.total-members'),
				value: (total_members, row) => (
					<div className={classNames(styles.members)} onClick={() => {
						setTableParams('superadmin_users', _.transform(['filter_email_confirmed', 'filter_two_factor', 'filter_company_id', 'filter_role', 'filter_language_id', 'filter_timezone', 'filter_created_at', 'filter_last_login_at', 'query'], (filters, filter) => {
							filters[filter] = '';

							return filters;
						}, {}));

						redirect('/superadmin/users?filter.company_id=' + row.id)
					}}>
						{total_members}
						<ArrowDoubleRightSmallIcon />
					</div>
				),
				width: 60,
			}, {
				key: "projects",
				name: __('superadmin.companies.column.total-projects'),
				value: (projects) => (
					<Tooltip text={projects.join("\n")}><span className={styles.chip}>{_.size(projects)}</span></Tooltip>
				),
				width: 60,
			}, {
				key: "owners",
				name: __('superadmin.companies.column.owners'),
				value: users => (
					<div className={styles.users}>
						{_.isEmpty(users) && <TableNA />}
						{_.size(users) == 1 && _.map(_.take(users, 1), (user, i) => (
							<Avatar key={i} avatar_url={user.avatar_url} name={user.fullname} active={user.active} />
						))}
						{_.size(users) > 1 && (
							<Tooltip text={_.map(users, 'fullname').join('\n')}>
								<div className={styles.more}>
									<span>{_.size(users)}</span>
								</div>
							</Tooltip>
						)}
					</div>
				),
				sortable: false,
				width: 50,
			},
			{
				key: "administrators",
				name: __('superadmin.companies.column.administrators'),
				value: users => (
					<div className={styles.users}>
						{_.isEmpty(users) && <TableNA />}
						{_.size(users) == 1 && _.map(_.take(users, 1), (user, i) => (
							<Avatar key={i} avatar_url={user.avatar_url} name={user.fullname} active={user.active} />
						))}
						{_.size(users) > 1 && (
							<Tooltip text={_.map(users, 'fullname').join('\n')}>
								<div className={styles.more}>
									<span>{_.size(users)}</span>
								</div>
							</Tooltip>
						)}
					</div>
				),
				sortable: false,
				width: 50,
			},
			{
				key: "users",
				name: __('superadmin.companies.column.users'),
				value: users => (
					<div className={styles.users}>
						{_.isEmpty(users) && <TableNA />}
						{_.size(users) == 1 && _.map(_.take(users, 1), (user, i) => (
							<Avatar key={i} avatar_url={user.avatar_url} name={user.fullname} active={user.active} />
						))}
						{_.size(users) > 1 && (
							<Tooltip text={_.map(users, 'fullname').join('\n')}>
								<div className={styles.more}>
									<span>{_.size(users)}</span>
								</div>
							</Tooltip>
						)}
					</div>
				),
				sortable: false,
				width: 50,
			}, {
				key: "created_at",
				name: __('superadmin.companies.column.created-at'),
				value: value => value ? dateTimeFrom(value) : <TableNA />,
				width: 120,
				required: true,
				default: true,
				desc: true,
			}
		];

		const groupActions = (company_ids) => [
			<Tooltip text={__('companies.tooltip.icon.export-companies')} key={_.uniqueId()}>
				<ExportIcon
					onClick={e => {
						e.stopPropagation();

						setNotification(__('companies.notification.companies-export'));

						downloadFile(backendURL + "/superadmin/companies/export", {
							companies: company_ids
						});
					}}
				/>
			</Tooltip>,
		];

		return (
			<Table
				listAction={listCompanies}
				name="superadmin_companies"
				title={__('modules.submodules.title.companies')}
				groupActions={groupActions}
				columns={columns}
			/>
		);
	}
}

export default Companies;
