import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddUnitGroupWizard.scss";
import autobind from "autobind-decorator";
import AddUnitGroupIcon from "../../assets/images/add-unit-group-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createUnitGroup, listUnitGroups } from "../../redux/actions/table/superadmin/unit_groups";
import AddUnitGroupForm from "../../forms/superadmin/add_unit_groups_wizard/AddUnitGroupForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_unit_groups"],
}))
class AddUnitGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createUnitGroup(formData).then(({ response }) => {
				listUnitGroups().then(() => {
					if (edit) {
						setTableParams('superadmin_unit_groups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_unit_groups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new unit group.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add unit group</span>
						<span>Add unit group to system unit groups</span>
					</div>
					<div className={styles.right}>
						<AddUnitGroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddUnitGroupForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddUnitGroupWizard;