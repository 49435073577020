import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddProductSubgroupWizard.scss";
import autobind from "autobind-decorator";
import AddProductSubgroupIcon from "../../assets/images/add-functional-space-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createProductSubgroup, listProductSubgroups } from "../../redux/actions/table/superadmin/product_subgroups";
import AddProductSubgroupForm from "../../forms/superadmin/add_product_subgroups_wizard/AddProductSubgroupForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_product_subgroups"],
}))
class AddProductSubgroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createProductSubgroup(formData).then(({ response }) => {
				listProductSubgroups().then(() => {
					if (edit) {
						setTableParams('superadmin_product_subgroups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_product_subgroups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new product subgroup.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add product subgroup</span>
						<span>Add product subgroup to system product subgroups</span>
					</div>
					<div className={styles.right}>
						<AddProductSubgroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddProductSubgroupForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
						product_group_id: null
					}} />
				</div>
			</>
		);
	}
}

export default AddProductSubgroupWizard;