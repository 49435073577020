import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { enableAutosave } from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/products/BasicDataForm.scss";
import Input from "../../components/Input";
import { __, mapStateToProps, setTableParams, transformToListPairs } from "../../core/utils";
import classNames from "classnames";
import Image from "../../components/Image";
import DefaultImage from "../../assets/images/default.png";
import MultiSelect from "../../components/MultiSelect";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import Tooltip from "../../components/Tooltip";
import PhotoIcon from "../../assets/images/photo-15x15.svg";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { deleteProductThumbnail, listPossibleProductBrands, listProducts, readProduct, uploadProductThumbnails } from "../../redux/actions/table/products";
import { setNotification } from "../../redux/actions/general/notification";
import autobind from "autobind-decorator";
import * as _ from "lodash";
import { showLightbox } from "../../redux/actions/general/lightbox";
import Select from "../../components/Select";
import { listMyCompanyBrands } from "../../redux/actions/companies";

@reduxForm({
	form: "products.basic_data",
	enableReinitialize: true
})
@subscribe(Localization, "localization")
@mapStateToProps((state) => ({
	store: state.table.products
}))
class BasicDataForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_brands: []
		};
	}

	componentDidMount() {
		const {
			flyout: { type }
		} = this.props.store;

		listPossibleProductBrands({ type }).then(({ response }) => {
			this.setState({
				possible_brands: _.map(response.data, (brand) => {
					return {
						value: brand.id,
						label: _.filter([brand.name], _.identity).join(" · "),
						group: _.get({ 0: "My Brands", 1: "Address Book Companies" }, brand.type)
					};
				})
			});
		});
	}

	UNSAFE_componentWillReceiveProps() {
		enableAutosave(this.props.form);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {
			flyout: { type, brand_address_book_company_id }
		} = this.props.store;

		if (prevProps.store.flyout.type != type) {
			listPossibleProductBrands({ type }).then(({ response }) => {
				this.setState({
					possible_brands: _.map(response.data, (brand) => {
						return {
							value: brand.id,
							label: _.filter([brand.name], _.identity).join(" · "),
							group: _.get({ 0: "My Brands", 1: "Address Book Companies" }, brand.type)
						};
					})
				});

				if (type === "product" && !_.isNull(brand_address_book_company_id)) {
					this.props.change("brand_id", _.get(response.data, [0, "id"], null));
				}
			});
		}
	}

	@autobind
	_handleUploadThumbnail(e) {
		const product_id = this.props.store.flyout.id;

		if (e.target.files) {
			setNotification(__("products.basic-data.thumbnail-image-being-uploaded-wait"));

			setNotification(__("products.basic-data.thumbnail-image-being-uploaded.please-wait"));

			uploadProductThumbnails(product_id, e.target.files)
				.then(() => {
					readProduct(product_id).then(({ response }) => {
						setTableParams("products", {
							flyout: response.data
						});
					});

					listProducts();
				})
				.catch(() => {
					setNotification({
						text: __("products.basic-data.cant-upload-image.doublecheck-file-size-format"),
						type: "warning"
					});
				});
		}
	}

	render() {
		const { handleSubmit, collapsed } = this.props;

		const { language_id, flyout } = this.props.store;

		const languages = _.map(this.props.localization.languages, (language) => {
			let data = {
				value: language.id,
				label: language.name
			};

			if (language.id == 1) {
				data["disabled"] = true;
			}

			return data;
		});

		const { possible_brands } = this.state;

		const thumbnail_images = _.sortBy(_.filter(flyout.images, ["category", "thumbnail"]), "order");

		const lightbox_images = _.map(thumbnail_images, (image) => ({
			id: image.id,
			stream_url: image.stream_url,
			title: [image.name, image.ext].join(".")
		}));

		return (
			<Form onSubmit={handleSubmit} className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
				<div className={styles.fields}>
					<div className={styles.columns}>
						<Field name="model" component={Input} label={__("products.column.model")} maxLength={250} /> {/* Ako se bude mijenjao ovaj broj 250, promijeniti i u ProductsController.php@copyProduct:1450 */}
						<Field name="brand_id" searchable component={Select} options={possible_brands} label={__("products.column.manufacturer")} />
					</div>
					<div className={styles.languages}>
						<Field name="languages" component={MultiSelect} searchable label={__("products.column.languages")} options={languages} />
					</div>
					<div className={styles.description}>
						<Field name={"description." + language_id} component={Input} label={__("products.column.description")} maxLength={70} />
					</div>
				</div>
				<div className={styles.image}>
					<Image default={DefaultImage} src={flyout.thumb_url} />
					<div className={classNames(styles.actions, !_.isEmpty(thumbnail_images) && styles.clickable)} onClick={() => (!_.isEmpty(thumbnail_images) ? showLightbox(lightbox_images, _.first(thumbnail_images).id) : undefined)}>
						<Tooltip text={__("products.basic-data-form.upload-thumbnail-image.tooltip")}>
							<PhotoIcon
								onClick={(e) => {
									this.refs.thumbnail.click();

									e.stopPropagation();
								}}
							/>
						</Tooltip>
						<input type="file" multiple={true} onClick={(e) => e.stopPropagation()} ref="thumbnail" onChange={this._handleUploadThumbnail} accept="image/x-png,image/jpeg" />
						{flyout.thumb_url && (
							<>
								<div className={styles.separator} />
								<Tooltip text={__("products.basic-data-form.delete-thumbnail-image.tooltip")}>
									<DeleteIcon
										onClick={(e) => {
											e.stopPropagation();

											showAlertbox({
												color: "red",
												title: __("general.alert.are-you-sure"),
												description: __("are-you-sure.remove-product-thumbnail.alert"),
												buttons: [
													{
														color: "lightGray",
														text: __("general.alert.no-close"),
														onClick: "close"
													},
													{
														color: "gray",
														text: __("general.alert.yes-delete"),
														onClick: () => {
															deleteProductThumbnail(flyout.id).then(() =>
																readProduct(flyout.id).then(({ response }) => {
																	hideAlertbox();

																	setNotification(__("product-thumbnail-has-been-deleted.notification"));

																	setTableParams("products", {
																		flyout: response.data
																	});
																})
															);
														}
													}
												]
											});
										}}
									/>
								</Tooltip>
							</>
						)}
					</div>
				</div>
			</Form>
		);
	}
}

export default BasicDataForm;
