import store from "../../../../core/store";

export const listBoqSupplyTypes = () => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_types/LIST_BOQ_SUPPLY_TYPES",
		method: "GET",
		endpoint: "/superadmin/boq_supply_types",
		main: true
	});
};

export const createBoqSupplyType = (formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_types/CREATE_BOQ_SUPPLY_TYPE",
		method: "POST",
		endpoint: "/superadmin/boq_supply_types",
		data: formData
	});
};

export const deleteBoqSupplyTypes = (boq_supply_types_ids) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_types/DELETE_BOQ_SUPPLY_TYPES",
		method: "DELETE",
		endpoint: "/superadmin/boq_supply_types",
		data: {
			boq_supply_types: boq_supply_types_ids
		}
	});
};

export const readBoqSupplyType = (boq_supply_types_id) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_types/READ_BOQ_SUPPLY_TYPES",
		method: "GET",
		endpoint: "/superadmin/boq_supply_types/" + boq_supply_types_id,
		has404: true
	});
};

export const updateBoqSupplyType = (boq_supply_types_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_types/UPDATE_BOQ_SUPPLY_TYPES",
		method: "PUT",
		endpoint: "/superadmin/boq_supply_types/" + boq_supply_types_id,
		data: formData
	});
};