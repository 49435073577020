const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.maintenance_mode/ENABLE":
            return true;
        case "general.maintenance_mode/GENERAL_CHECK_SUCCESS":
            if(state == true) {
                location.reload();

                return 'refreshing';
            }

            return false;
        default:
            return state;
    }
}