import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_project_wizard/ProjectPropertiesForm.scss";
import Button from "../../components/Button";
import {__, mapStateToProps, setTableParams, transformToListPairs} from "../../core/utils";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import Select from "../../components/Select";
import MultiSelect from "../../components/MultiSelect";
import * as _ from "lodash";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";

@reduxForm({
    form: "add_project_wizard.project_properties",
    destroyOnUnmount: false
})
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    values: getFormValues("add_project_wizard.project_properties")(state),
}))
class ProjectPropertiesForm extends Component {
	render() {
		return (
            <Form onSubmit={this.props.handleSubmit}>
                <div className={styles.flex}>
                    <div className={styles.f2}>
                        <Field name="code" component={Input} label={__('table.columns.project-number')} maxLength={7} />
                    </div>
                    <div className={styles.f6}>
                        <Field name="name" component={Input} label={__('table.columns.project-title')} maxLength={50} />
                    </div>
                </div>
                <Field name="description" component={Textarea} label={__('table.columns.project-scope')} maxLength={500} rows={1} maxRows={10} />
                <div className={styles.flex}>
                    <div className={styles.f2}>
                        <Field name="main_language" component={Select} searchable label={__('project-settings.properties.main-language')} noSort options={transformToListPairs(this.props.localization.languages, "name")} />
                    </div>
                    <div className={styles.f5}>
                        <Field name="additional_languages" component={MultiSelect} searchable label={__('project-settings.properties.additional-languages')} noSort options={_.reject(transformToListPairs(this.props.localization.languages, "name"), ['value', _.get(this.props.values, 'main_language')])} />
                    </div>
                </div>
                <Field name="currency_id" component={Select} searchable label={__('project-settings.properties.currency')} options={_.map(this.props.localization.currencies, currency => ({
                    label: [currency.code, currency.name].join(' - '),
                    value: currency.id
                }))} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_projects', {
                        wizard_active: undefined
                    })} />
                    <Button submit medium gray leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
		);
	}
}

export default ProjectPropertiesForm;