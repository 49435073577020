import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_specification_product_note_wizard/AddSpecificationProductNoteForm.scss';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  getActiveStage,
} from '../../core/utils';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Textarea from '../../components/Textarea';
import MultiSelect from '../../components/MultiSelect';
import RadioGroup from '../../components/RadioGroup';
import {
  listNoteSpecificationCodes,
  listPossibleSpecificationNotes,
} from '../../redux/actions/table/working_set_specifications';
import NoteChooser from '../../components/NoteChooser';
import Select from '../../components/Select';
import Checkbox from '../../components/Checkbox';

@reduxForm({
  form: 'add_specification_product_note_wizard.add_specification_product_note',
})
@mapStateToProps((state, props) => ({
  store: state.table.working_set_specifications,
  values: getFormValues(
    'add_specification_product_note_wizard.add_specification_product_note'
  )(state),
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
@withRouter
class AddSpecificationProductNoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company_specification_notes: [],
      note_specifications: [],
    };
  }

  componentDidMount() {
    listPossibleSpecificationNotes(this.props.store.flyout.id)
      .then(({ response }) =>
        this.setState({ company_specification_notes: response.data })
      )
      .then(() => {
        listNoteSpecificationCodes(this.props.store.flyout.stage_id).then(
          ({ response }) => {
            this.setState({ note_specification_codes: response.data });
          }
        );
      });
  }

  render() {
    const { flyout, language_id, project_language } = this.props.store;
    const { company_specification_notes, note_specification_codes } =
      this.state;
    const { add_new } = this.props.values;
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const { application_language_id } = this.props;

    let notes = [];

    _.each(company_specification_notes, (note) =>
      notes.push({
        value: note.id,
        priority: note.priority,
        label: getLocalized(note.text, language_id),
        group: getLocalized(note.note_category, language_id),
        type: note.type,
        category_id: note.category_id,
        specification_subgroup_ids: note.specification_subgroup_ids,
        is_company_note: note.type == 'company',
      })
    );

    let note_categories = [];

    _.each(this.props.localization.note_categories, (note) => {
      note_categories.push({
        value: note.id,
        label: getLocalized(note.name, language_id),
      });
    });

    notes = _.sortBy(notes, ['group', 'priority']);

    const filters = [
      {
        name: 'type',
        options: _.map(_.groupBy(notes, 'type'), (options, group, i) => {
          return {
            key: _.uniqueId(),
            value: group,
            label: group,
          };
        }),
      },
      {
        name: 'category_id',
        options: _.map(note_categories, (category, i) => {
          return {
            key: i,
            value: category.value,
            label: category.label,
          };
        }),
      },
      {
        name: 'specification_subgroup_ids',
        options: _.sortBy(
          _.sortBy(
            _.map(note_specification_codes, (specification) => {
              const specification_subgroup =
                _.get(specification_subgroups, [
                  specification.specification_subgroup_id,
                ]) || {};

              const specification_group = _.get(
                specification_groups,
                _.get(specification_subgroup, 'specification_group_id'),
                {}
              );

              const label =
                getLocalized(specification_group.code, project_language) +
                [
                  _.padEnd(specification_subgroup.code, 3, '0'),
                  getLocalized(
                    specification_subgroup.name,
                    application_language_id
                  ),
                ].join(': ');
              const group = [
                getLocalized(specification_group.code, project_language),
                getLocalized(specification_group.name, application_language_id),
              ].join(': ');

              return {
                value: specification.specification_subgroup_id,
                label: label,
                key: _.uniqueId(),
                group: group,
              };
            }),
            'label'
          ),
          'group'
        ),
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.row}>
          <Field
            name='add_new'
            component={RadioGroup}
            options={[
              {
                label: __(
                  'specifications.working-set.flyout.add-specification.predefined-instruction'
                ),
                value: '0',
              },
              {
                label: __(
                  'specifications.working-set.flyout.add-specification.add-new-instruction'
                ),
                value: '1',
              },
            ]}
          />
          <div className={styles.type}>
            {add_new === '0' && (
              <Field
                name='note_ids'
                searchable
                noSort
                multiedit={true}
                component={NoteChooser}
                handleClickOnEdit={(id) => {
                  setTableParams('working_set_specifications', {
                    add_specification_product_note_wizard: undefined,
                    edit_specification_product_note_wizard: true,
                    edit_specification_product_note_data: _.find(
                      company_specification_notes,
                      ['id', id]
                    ),
                  });
                }}
                label={__(
                  'specifications.add-specification-product-instruction-form.instructions'
                )}
                options={notes}
                filters={filters}
                initValues={{
                  specification_subgroup_id:
                    this.props.store.flyout.specification_subgroup_id,
                }}
              />
            )}
          </div>
        </div>
        {add_new === '1' && (
          <>
            <div className={styles.flex}>
              <div className={styles.f1}>
                <Field
                  name='note_category_id'
                  component={Select}
                  label={__(
                    'specifications.add-specification-product-instruction-form.note_category'
                  )}
                  options={note_categories}
                />
              </div>
              <div className={styles.f1} />
            </div>

            <Field
              name='note'
              component={Textarea}
              rows={4}
              maxRows={10}
              localized={flyout.languages}
              label={__(
                'specifications.add-specification-product-instruction-form.text'
              )}
              maxLength={1000}
            />

            <div className={styles.f1}>
              <div className={styles.companyNote}>
                <Field
                  name='is_my_company_note'
                  component={Checkbox}
                  label={__(
                    'specifications.add-specification-product-instruction-form.is-my-company-instruction'
                  )}
                />
              </div>
            </div>
          </>
        )}
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                add_specification_product_note_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            submit
            right
            leftText={__('button.done')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default AddSpecificationProductNoteForm;
