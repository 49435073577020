import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_product_note_wizard/AddProductNoteForm.scss';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Textarea from '../../components/Textarea';
import MultiSelect from '../../components/MultiSelect';
import { listPossibleProductNotes } from '../../redux/actions/table/products';
import RadioGroup from '../../components/RadioGroup';
import Select from '../../components/Select';
import NoteChooser from '../../components/NoteChooser';

@reduxForm({
  form: 'add_product_note_wizard.add_product_note',
})
@mapStateToProps((state, props) => ({
  store: state.table.products,
  values: getFormValues('add_product_note_wizard.add_product_note')(state),
}))
@subscribe(Localization, 'localization')
@withRouter
class AddProductNoteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company_product_notes: [],
    };
  }

  componentDidMount() {
    listPossibleProductNotes(this.props.store.flyout.id).then(({ response }) =>
      this.setState({ company_product_notes: response.data })
    );
  }

  render() {
    const { flyout, language_id } = this.props.store;
    const { company_product_notes } = this.state;
    const { add_new } = this.props.values;

    let notes = [];

    _.each(company_product_notes, (note) =>
      notes.push({
        value: note.id,
        label: getLocalized(note.text, language_id),
        group: getLocalized(note.note_category, language_id),
        category_id: note.category_id,
      })
    );

    // _.each(
    //   _.reject(this.props.localization.notes, (note) =>
    //     _.includes(_.map(flyout.notes, 'id'), note.id)
    //   ),
    //   (note) =>
    //     notes.push({
    //       value: note.id,
    //       label: getLocalized(note.text, language_id),
    //       priority: note.priority,
    //       group: __(
    //         'products.add-product-instructions-form.system-instructions'
    //       ),
    //     })
    // );

    notes = _.sortBy(notes, ['group', 'priority']);

    let note_categories = [];

    _.each(this.props.localization.note_categories, (note) => {
      note_categories.push({
        value: note.id,
        label: getLocalized(note.name, language_id),
      });
    });

    const languages = _.transform(
      flyout.languages,
      (languages, language_id) => {
        languages[language_id] = language_id == 1 ? 1 : 0;

        return languages;
      },
      {}
    );

    const filters = [
      {
        name: 'category_id',
        options: _.map(note_categories, (category, i) => {
          return {
            key: i,
            value: category.value,
            label: category.label,
          };
        }),
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.row}>
          <Field
            name='add_new'
            component={RadioGroup}
            options={[
              {
                label: __(
                  'products.product-instruction-flyout.add-instruction.predefined-instruction'
                ),
                value: '0',
              },
              {
                label: __(
                  'products.product-instruction-flyout.add-instruction.add-new-instruction'
                ),
                value: '1',
              },
            ]}
          />
          <div className={styles.type}>
            {add_new === '0' && (
              <Field
                name='note_ids'
                searchable
                noSort
                component={NoteChooser}
                filters={filters}
                label={__(
                  'products.product-instruction-flyout.add-instruction.instructions'
                )}
                options={notes}
              />
            )}
          </div>
        </div>
        {add_new === '1' && (
          <>
            <div className={styles.flex}>
              <div className={styles.f1}>
                <Field
                  name='note_category_id'
                  component={Select}
                  label={__(
                    'products.product-instruction-flyout.add-instruction.add-new-instruction.note_category'
                  )}
                  options={note_categories}
                />
              </div>
              <div className={styles.f1} />
            </div>

            <Field
              name='note'
              component={Textarea}
              localized={languages}
              label={__(
                'products.product-instruction-flyout.add-instruction.add-new-instruction.text'
              )}
              maxLength={1000}
            />
          </>
        )}

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('products', {
                add_product_note_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            submit
            right
            leftText={__('button.add')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default AddProductNoteForm;
