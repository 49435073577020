import * as React from 'react';
import styles from '../styles/wizards/AddBOQCalculationAsTemplateWizard.scss';
import { __, validateForm } from '../core/utils';
import BOQIcon from '../assets/images/boq-24x24.svg';
import { createBOQTemplateCalculations } from '../redux/actions/table/working_set_boq';
import { setNotification } from '../redux/actions/general/notification';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import AddBOQCalculationAsTemplateForm from '../forms/add_boq_calculation_as_template/AddBOQCalculationAsTemplateForm';

const AddBOQCalculationAsTemplateWizard = ({ state, onClose }) => {
  const _handleSubmit = (formData) => {
    const id = state.add_boq_template_calculation_wizard;

    return validateForm(
      createBOQTemplateCalculations(id, formData).then(() => {
        setNotification(__('working-set-boq.boq-calculation-saved'));

        onClose();
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__('working-set-boq.add-boq-calculation-as-template.title')}
          </span>
          <span>
            {__('working-set-boq.add-boq-calculation-as-template.subtitle')}
          </span>
        </div>
        <div className={styles.right}>
          <BOQIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddBOQCalculationAsTemplateForm
          onSubmit={_handleSubmit}
          onClose={onClose}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.table.working_set_boq,
  };
};

export default connect(
  mapStateToProps,
  null
)(subscribe(Localization, 'localization')(AddBOQCalculationAsTemplateWizard));
