import store from "../../../../core/store";

export const listProductAttributeTemplateGroups = () => {
	return store.dispatch({
		action: "table.superadmin_product_attribute_template_groups/LIST_PRODUCT_ATTRIBUTE_TEMPLATE_GROUPS",
		method: "GET",
		endpoint: "/superadmin/product_attribute_template_groups",
		main: true
	});
};

export const createProductAttributeTemplateGroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_product_attribute_template_groups/CREATE_PRODUCT_ATTRIBUTE_TEMPLATE_GROUP",
		method: "POST",
		endpoint: "/superadmin/product_attribute_template_groups",
		data: formData
	});
};

export const deleteProductAttributeTemplateGroups = (product_attribute_template_group_ids) => {
	return store.dispatch({
		action: "table.superadmin_product_attribute_template_groups/DELETE_PRODUCT_ATTRIBUTE_TEMPLATE_GROUPS",
		method: "DELETE",
		endpoint: "/superadmin/product_attribute_template_groups",
		data: {
			product_attribute_template_groups: product_attribute_template_group_ids
		}
	});
};

export const readProductAttributeTemplateGroup = (product_attribute_template_group_id) => {
	return store.dispatch({
		action: "table.superadmin_product_attribute_template_groups/READ_PRODUCT_ATTRIBUTE_TEMPLATE_GROUP",
		method: "GET",
		endpoint: "/superadmin/product_attribute_template_groups/" + product_attribute_template_group_id,
		has404: true
	});
};

export const updateProductAttributeTemplateGroup = (product_attribute_template_group_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_product_attribute_template_groups/UPDATE_PRODUCT_ATTRIBUTE_TEMPLATE_GROUP",
		method: "PUT",
		endpoint: "/superadmin/product_attribute_template_groups/" + product_attribute_template_group_id,
		data: formData
	});
};