import * as React from "react";
import autobind from "autobind-decorator";

const FieldComponent = (WrappedFieldComponent) => {
    class HOC extends React.Component {
        constructor(props) {
            super(props);

            this.state = {};

            this.dropdown = React.createRef();
            this.element = React.createRef();
        }

        componentDidMount() {
            window.addEventListener("resize", this.recalculateOffset);
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.recalculateOffset);
        }

        @autobind
        recalculateOffset() {
            if(this.props.meta.active && this.dropdown && this.dropdown.current && this.element && this.element.current) {
                const element = this.element.current;
                const dropdown = this.dropdown.current;

                const dropdown_start_x = element.getBoundingClientRect().left - element.offsetParent.getBoundingClientRect().left;
                const dropdown_start_y = element.getBoundingClientRect().top - element.offsetParent.getBoundingClientRect().top;

                const dropdown_width = dropdown.offsetWidth + parseInt(dropdown.style.marginLeft) + 30 + element.offsetParent.getBoundingClientRect().left;
                const dropdown_height = dropdown.offsetHeight + parseInt(dropdown.style.marginTop) + 30 + element.offsetParent.getBoundingClientRect().top;

                const dropdown_end_x = dropdown_start_x + dropdown_width;
                const dropdown_end_y = dropdown_start_y + dropdown_height;

                const window_width = window.innerWidth;
                const window_height = window.innerHeight;

                this.setState({
                    dropdown_offset_x: dropdown_start_x - ((dropdown_end_x > window_width) ? (dropdown_end_x - window_width) : 0),
                    dropdown_offset_y: dropdown_start_y - ((dropdown_end_y > window_height) ? (dropdown_end_y - window_height) : 0)
                });
            }
        }

        render() {
            const {dropdown_offset_y, dropdown_offset_x} = this.state;

            return <WrappedFieldComponent
                {...this.props}
                topPosition={dropdown_offset_y}
                leftPosition={dropdown_offset_x}
                recalculateOffset={this.recalculateOffset}
                dropdownRef={this.dropdown}
                elementRef={this.element}
            />;
        }
    }

    return HOC;
};

export default FieldComponent;