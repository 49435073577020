import store from "../../../../core/store";

export const listProductCategories = () => {
	return store.dispatch({
		action: "table.superadmin_product_categories/LIST_PRODUCT_CATEGORIES",
		method: "GET",
		endpoint: "/superadmin/product_categories",
		main: true
	});
};

export const createProductCategory = (formData) => {
	return store.dispatch({
		action: "table.superadmin_product_categories/CREATE_PRODUCT_CATEGORY",
		method: "POST",
		endpoint: "/superadmin/product_categories",
		data: formData
	});
};

export const deleteProductCategories = (product_category_ids) => {
	return store.dispatch({
		action: "table.superadmin_product_categories/DELETE_PRODUCT_CATEGORIES",
		method: "DELETE",
		endpoint: "/superadmin/product_categories",
		data: {
			product_categories: product_category_ids
		}
	});
};

export const readProductCategory = (product_category_id) => {
	return store.dispatch({
		action: "table.superadmin_product_categories/READ_PRODUCT_CATEGORY",
		method: "GET",
		endpoint: "/superadmin/product_categories/" + product_category_id,
		has404: true
	});
};

export const updateProductCategory = (product_category_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_product_categories/UPDATE_PRODUCT_CATEGORY",
		method: "PUT",
		endpoint: "/superadmin/product_categories/" + product_category_id,
		data: formData
	});
};