import {toBoolean} from "../../../core/utils";

const initialState = {
    hidden: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.sidebar/TOGGLE_SIDEBAR":
            return {
                ...state,
                hidden: !state.hidden
            };
        case "general.sidebar/HIDE_SIDEBAR":
            return {
                ...state,
                hidden: true
            };
        case "general.sidebar/CREATE_SIDEBAR":
            return {
                hidden: state.hidden,
                ...action.options,
            };
        case "general.sidebar/UPDATE_SIDEBAR":
            const sidebar = _.cloneDeep(state);

            _.set(sidebar, action.key, action.value);

            return sidebar;
        case "general.sidebar/TOGGLE_SIDEBAR_COLLAPSE":
            const sidebar_clone = _.cloneDeep(state);

            const expanded_key = action.key + '.expanded';

            _.set(sidebar_clone, expanded_key, !toBoolean(_.get(sidebar_clone, expanded_key)));

            return sidebar_clone;
        default:
            return state;
    }
}
