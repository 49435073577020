import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/duplicate_room_wizard/DuplicateRoomForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams} from "../../core/utils";

@reduxForm({form: "duplicate_room_wizard.duplicate_room"})
class DuplicateRoomForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="copies" component={Input} label={__('rooms.duplicate.field.copies')} numeric maxLength={2} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('rooms', {
                        duplicate: undefined
                    })} />
                    <Button lightBlue medium right submit middleText={__('button.duplicate')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default DuplicateRoomForm;
