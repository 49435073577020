import store from "../../../core/store";

export const listStageDisciplines = (stage_id) => {
    return store.dispatch({
        action: "table.stage_disciplines/LIST_STAGE_DISCIPLINES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/disciplines",
        main: true
    });
};

export const listPossibleStageDisciplineApprovers = (stage_id) => {
    return store.dispatch({
        action: "table.stage_disciplines/LIST_POSSIBLE_STAGE_DISCIPLINE_APPROVERS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_approvers",
    });
};

export const createStageDiscipline = (stage_id, formData) => {
    return store.dispatch({
        action: "table.stage_disciplines/CREATE_STAGE_DISCIPLINE",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/disciplines",
        data: formData
    });
};

export const readStageDiscipline = (stage_discipline_id) => {
    return store.dispatch({
        action: "table.stage_disciplines/READ_STAGE_DISCIPLINE",
        method: "GET",
        endpoint: "/stage_disciplines/" + stage_discipline_id,
    });
};

export const updateStageDiscipline = (stage_discipline_id, formData) => {
    return store.dispatch({
        action: "table.stage_disciplines/UPDATE_STAGE_DISCIPLINE",
        method: "PUT",
        endpoint: "/stage_disciplines/" + stage_discipline_id,
        data: formData
    });
};

export const getStageDisciplinesPermissions = (stage_id) => {
    return store.dispatch({
        action: "table.stage_disciplines/GET_STAGE_DISCIPLINES_PERMISSIONS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/permissions"
    });
};