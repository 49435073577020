import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/EditProductAttributeGroupWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeGroupEditIcon from "../assets/images/product-attribute-group-edit-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {readProduct, updateProductAttributesGroup} from "../redux/actions/table/products";
import EditProductAttributeGroupForm from "../forms/edit_product_attribute_group_form/EditProductAttributeGroupForm";

@mapStateToProps(state => ({
	flyout: _.get(state, 'table.products.flyout'),
	group_id: state.table.products.edit_product_attribute_group_wizard,
}))
class EditProductAttributeGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
			updateProductAttributesGroup(this.props.group_id, formData).then(() => readProduct(this.props.flyout.id).then(({response}) => {
				setNotification(__('products.all-products.flyout.edit-product-attribute-group.product-attribute-group-has-been-updated'));

				setTableParams('products', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('products.all-products.flyout.edit-group-name.edit-product-attribute-group')}</span>
						<span>{__('products.all-products.flyout.edit-group-name.subtitle.edit-product-attribute-group')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeGroupEditIcon />
					</div>
				</div>
				<div className={styles.body}>
					<EditProductAttributeGroupForm onSubmit={this._handleSubmit} initialValues={{
						name: _.get(_.find(this.props.flyout.attribute_groups, ['id', this.props.group_id]), 'name')
					}} />
				</div>
			</>
		);
	}
}

export default EditProductAttributeGroupWizard;
