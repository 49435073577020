import * as React from "react";
import {Component} from "react";
import styles from "../styles/containers/MaintenanceMode.scss";

class MaintenanceMode extends Component {
    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.modal}>
                    <div className={styles.header}>
                        <div className={styles.left}>
                            <span className={styles.title}>Maintenance Mode is On!</span>
                            <span className={styles.description}>
                                VOLUM3 is undergoing scheduled maintenance.<br />Sorry for the inconvenience.
                            </span>
                        </div>
                        <div className={styles.right}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                <path d="M48.86,42.05a8,8,0,0,1,3.48,1.23l2.6-2a1,1,0,0,1,1.28,0,12.06,12.06,0,0,1,1.69,2,1,1,0,0,1-.16,1.27l-2.43,2.25h0A8,8,0,0,1,56,50.4l3.08,1.23a1,1,0,0,1,.61,1.13,12.06,12.06,0,0,1-.87,2.45,1,1,0,0,1-1.18.49l-3.17-1h0a8,8,0,0,1-2.8,2.4l.47,3.28a1,1,0,0,1-.67,1.09A12.06,12.06,0,0,1,48.9,62a1,1,0,0,1-1-.78L47.14,58h0a8,8,0,0,1-3.48-1.22l-2.6,2a1,1,0,0,1-1.28,0,12.06,12.06,0,0,1-1.69-2,1,1,0,0,1,.16-1.27l2.43-2.25h0A8,8,0,0,1,40,49.61l-3.08-1.23a1,1,0,0,1-.61-1.13,12.06,12.06,0,0,1,.87-2.45,1,1,0,0,1,1.18-.49l3.17,1h0a8,8,0,0,1,2.8-2.4l-.47-3.28a1,1,0,0,1,.67-1.09A12.06,12.06,0,0,1,47.1,38a1,1,0,0,1,1,.78Z" />
                                <path d="M48.14,5a10.42,10.42,0,0,1,3.57.49l1.75-2.72a1,1,0,0,1,1.25-.38A14.55,14.55,0,0,1,56.9,3.7,1,1,0,0,1,57.18,5L55.66,7.83h0a10.42,10.42,0,0,1,2.18,2.87L61,10a1,1,0,0,1,1.15.62,14.55,14.55,0,0,1,.64,2.46,1,1,0,0,1-.7,1.1L59,15.14h0a10.42,10.42,0,0,1-.49,3.57l2.72,1.75a1,1,0,0,1,.38,1.25A14.55,14.55,0,0,1,60.3,23.9a1,1,0,0,1-1.28.28l-2.85-1.52h0a10.42,10.42,0,0,1-2.87,2.18L54,28a1,1,0,0,1-.62,1.15,14.55,14.55,0,0,1-2.46.64,1,1,0,0,1-1.1-.7L48.86,26h0a10.42,10.42,0,0,1-3.57-.49l-1.75,2.72a1,1,0,0,1-1.25.38A14.55,14.55,0,0,1,40.1,27.3,1,1,0,0,1,39.82,26l1.52-2.85h0a10.42,10.42,0,0,1-2.18-2.87L36,21a1,1,0,0,1-1.15-.62,14.55,14.55,0,0,1-.64-2.46,1,1,0,0,1,.7-1.1L38,15.86h0a10.42,10.42,0,0,1,.49-3.57l-2.72-1.75a1,1,0,0,1-.38-1.25A14.55,14.55,0,0,1,36.7,7.1,1,1,0,0,1,38,6.82l2.85,1.52h0A10.42,10.42,0,0,1,43.7,6.17L43,3a1,1,0,0,1,.62-1.15,14.55,14.55,0,0,1,2.46-.64,1,1,0,0,1,1.1.7Z" />
                                <path d="M36,42.94l3,1a1,1,0,0,0,1.22-.52,20.59,20.59,0,0,0,.88-2.31,1,1,0,0,0-.57-1.2l-2.91-1.24A16.64,16.64,0,0,0,38,35.31l3.12-.51a1,1,0,0,0,.84-1,20.61,20.61,0,0,0-.3-2.46,1,1,0,0,0-1.06-.8l-3.16.25a16.65,16.65,0,0,0-1.2-3.15l2.54-1.91a1,1,0,0,0,.27-1.3,20.6,20.6,0,0,0-1.4-2,1,1,0,0,0-1.31-.21l-2.69,1.7a16.57,16.57,0,0,0-2.52-2.24l1.36-2.87a1,1,0,0,0-.37-1.28,20.6,20.6,0,0,0-2.19-1.15,1,1,0,0,0-1.26.42l-1.59,2.75a16.36,16.36,0,0,0-3.28-.81l-.13-3.17a1,1,0,0,0-.92-1,20.62,20.62,0,0,0-2.47,0,1,1,0,0,0-.92,1l-.13,3.17a16.37,16.37,0,0,0-3.27.8l-1.59-2.75a1,1,0,0,0-1.26-.42,20.61,20.61,0,0,0-2.19,1.15,1,1,0,0,0-.37,1.28l1.36,2.87a16.57,16.57,0,0,0-2.52,2.24l-2.69-1.7a1,1,0,0,0-1.31.21A20.6,20.6,0,0,0,4,24.4a1,1,0,0,0,.27,1.3l2.53,1.91a16.37,16.37,0,0,0-1.19,3.15l-3.16-.25a1,1,0,0,0-1.06.8A20.61,20.61,0,0,0,1,33.76a1,1,0,0,0,.84,1L5,35.3a16.52,16.52,0,0,0,.4,3.35L2.5,39.89a1,1,0,0,0-.57,1.2,20.6,20.6,0,0,0,.88,2.31A1,1,0,0,0,4,43.93l3-1A16.52,16.52,0,0,0,9,45.7L7,48.16A1,1,0,0,0,7,49.49a20.61,20.61,0,0,0,1.85,1.64A1,1,0,0,0,10.18,51l2.19-2.28a16.44,16.44,0,0,0,3,1.57l-.63,3.1a1,1,0,0,0,.66,1.15,20.61,20.61,0,0,0,2.4.59,1,1,0,0,0,1.12-.71l.88-3a16.7,16.7,0,0,0,3.38,0l.88,3a1,1,0,0,0,1.12.71,20.6,20.6,0,0,0,2.4-.59,1,1,0,0,0,.67-1.15l-.63-3.1a16.45,16.45,0,0,0,3-1.56L32.81,51a1,1,0,0,0,1.33.11A20.61,20.61,0,0,0,36,49.5,1,1,0,0,0,36,48.17l-2-2.46A16.52,16.52,0,0,0,36,42.94Z" />
                                <circle cx="48" cy="50" r="4" />
                                <circle cx="48.5" cy="15.5" r="5" />
                                <circle cx="21.5" cy="35" r="9" />
                            </svg>
                        </div>
                    </div>
                    <div className={styles.content}>
                        We are currently deploying a new version and we will be back soon with awesome new features. The page will automatically refresh as soon as we finish.<br /><br />Thank you for your patience.
                    </div>
                </div>
            </div>
        );
    }
}

export default MaintenanceMode;
