import * as React from "react";
import { Component } from "react";
import { Field, FieldArray, Form, reduxForm } from "redux-form";
import Textarea from "../../components/Textarea";
import { __, transformToListPairs, redirect } from "../../core/utils";
import { enableAutosave } from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/address_book_contacts/EditAddressBookContactForm.scss";
import Input from "../../components/Input";
import { withRouter } from "react-router";
import Select from "../../components/Select";
import { listPossibleSites } from "../../redux/actions/table/buildings";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";
import classNames from "classnames";
import Checkbox from "../../components/Checkbox";
import { listPossibleAddressBookCompanies } from "../../redux/actions/table/address_book_contacts";
import * as _ from "lodash";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import MinusMiddleIcon from "../../assets/images/minus-middle-15x15.svg";
import people_addressing from "../../lists/people_addressing";
import Tags from "../../components/Tags";
import { listPossibleTags } from "../../redux/actions/general";
import autobind from "autobind-decorator";
import { createAddressBookCompany } from "../../redux/actions/table/address_book_companies";

@reduxForm({
	form: "address_book_contacts.edit_address_book_contact",
	enableReinitialize: true
})
@withRouter
@subscribe(Localization, "localization")
class EditAddressBookContactForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_address_book_companies: [],
			possible_tags: []
		};
	}

	componentDidMount() {
		listPossibleAddressBookCompanies().then(({ response }) => {
			this.setState({ possible_address_book_companies: response.data });
		});

		listPossibleTags("address_book").then(({ response }) => {
			this.setState({ possible_tags: response.data });
		});
	}

	UNSAFE_componentWillReceiveProps() {
		enableAutosave(this.props.form);
	}

	render_emails = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("companies-and-users.address-book-contacts.flyout.email") + " " + (index + 1) : __("companies-and-users.address-book-contacts.flyout.email")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	render_phones = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("companies-and-users.address-book-contacts.flyout.phone") + " " + (index + 1) : __("companies-and-users.address-book-contacts.flyout.phone")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	@autobind
	_createNewCompany(value) {
		createAddressBookCompany({ name: value }).then(({ response }) => {
			listPossibleAddressBookCompanies()
				.then(({ response }) => {
					this.setState({ possible_address_book_companies: response.data });
				})
				.then(() => {
					this.props.change("address_book_company_id", _.get(response.data, "id"));
				});
		});
	}

	render() {
		const { handleSubmit } = this.props;
		const { possible_address_book_companies } = this.state;

		const available_disciplines = _.sortBy(this.props.localization.disciplines, "priority");

		return (
			<Form onSubmit={handleSubmit}>
				<div className={styles.flex}>
					<div className={styles.f1}>
						<Field name="title" component={Select} label={__("companies-and-users.address-book-contacts.flyout.title")} options={people_addressing} />
					</div>
					<div className={styles.f4}>
						<Field name="firstname" component={Input} label={__("companies-and-users.address-book-contacts.flyout.first-name")} />
					</div>
					<div className={styles.f4}>
						<Field name="lastname" component={Input} label={__("companies-and-users.address-book-contacts.flyout.last-name")} />
					</div>
				</div>
				<div className={styles.flex}>
					<div className={styles.f1}>
						<Field
							name="address_book_company_id"
							component={Select}
							searchable
							label={__("companies-and-users.address-book-contacts.flyout.company")}
							options={transformToListPairs(possible_address_book_companies)}
							redirectTo={(id) => {
								redirect(`/address_book/companies?id=${id}`);
							}}
							callbackSetNewValue={(value) => {
								this._createNewCompany(value);
							}}
						/>
					</div>
					<div className={styles.f1}>
						<Field name="disciplines" noSort component={MultiSelect} searchable label={__("companies-and-users.address-book-contacts.flyout.profession")} options={transformToListPairs(available_disciplines, "profession")} />
					</div>
				</div>
				<div className={styles.flex}>
					<div className={styles.f1}>
						<FieldArray name="emails" component={this.render_emails} />
					</div>
					<div className={styles.f1}>
						<FieldArray name="phones" component={this.render_phones} />
					</div>
					<div className={styles.f1}>
						<div className={styles.roles}>
							<Field name="is_designer" component={Checkbox} label={__("companies-and-users.address-book-contacts.flyout.designer")} />
							<Field name="is_client" component={Checkbox} label={__("companies-and-users.address-book-contacts.flyout.client")} />
						</div>
					</div>
				</div>
				<Field name="tags" component={Tags} label={__("companies-and-users.address-book-contacts.flyout.tags")} options={this.state.possible_tags} />
				<Field name="note" component={Textarea} label={__("companies-and-users.address-book-contacts.flyout.instruction")} maxLength={2000} rows={1} maxRows={10} />
			</Form>
		);
	}
}

export default EditAddressBookContactForm;
