import store from "../../../core/store";

export const listPublicBrands = () => {
    return store.dispatch({
        action: "table.public_brands/LIST_PUBLIC_BRANDS",
        method: "GET",
        endpoint: "/brands/public",
        main: true,
    });
};
