const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.superadmin_boq_template_calculations/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };
    case 'table.superadmin_boq_template_calculations/LIST_BOQ_TEMPLATE_CALCULATIONS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        filters: action.response.filters,
        ...action.response.pagination,
      };
    default:
      return state;
  }
}
