import store from "../../../core/store";

export const enableMaintenanceMode = () => {
    return store.dispatch({
        type: "general.maintenance_mode/ENABLE",
    });
};

export const generalCheck = () => {
    return store.dispatch({
        action: "general.maintenance_mode/GENERAL_CHECK",
        method: "GET",
        endpoint: "/check",
        preloaded: false,
    });
}