import React, { useState, useEffect } from 'react';
import {
  Field,
  Form,
  getFormError,
  getFormSubmitErrors,
  getFormValues,
  reduxForm,
} from 'redux-form';
import styles from '../../styles/forms/import_spreadsheet_wizard/ImportSpreadsheetForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __, setFormValue } from '../../core/utils';
import FileUploadIcon from '../../assets/images/file-upload-32x32.svg';
import classNames from 'classnames';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import FolderOpenedIcon from '../../assets/images/folder-opened-16x16.svg';
import ReactDropzone from 'react-dropzone';
import { setNotification } from '../../redux/actions/general/notification';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import DocumentExtIcon from '../../assets/images/document-ext-30x30.svg';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import Select from '../../components/Select';

const ImportSpreadsheetBasicAttributesForm = (props) => {
  const [drag, setDrag] = useState(false);
  const [clipboardFile, setClipboardFile] = useState({});

  useEffect(() => {
    window.addEventListener('paste', _handlePaste);

    return () => {
      window.removeEventListener('paste', _handlePaste);
    };
  }, []);

  const _handleDrop = (acceptedFile, rejectedFiles) => {
    setDrag(false);

    if (!_.isEmpty(acceptedFile)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('import-spreadsheet.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('import-spreadsheet.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (_.first(acceptedFile).size >= 104857600) {
        setNotification({
          text: __('import-spreadsheet.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        const file = _.first(acceptedFile);

        setClipboardFile(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        props.change('file', file);
      }
    }
  };

  const _handlePaste = (event) => {
    const file = _.first(event.clipboardData.files);

    setClipboardFile(
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    props.change('file', file);
  };

  useEffect(() => {
    if (props?.values?.file) {
      setClipboardFile(
        Object.assign(props?.values?.file, {
          preview: URL.createObjectURL(props?.values?.file),
        })
      );
    }
  }, []);

  let { errors, modules, data_structures, values } = props;

  data_structures = _.filter(
    data_structures,
    (data_structure) =>
      data_structure.value !=
      _.find(modules, (module) => module.value == values?.module)
        ?.hidden_data_structure
  );

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='module'
              component={Select}
              noSort
              label={__('import-spreadsheet.field.module')}
              options={modules}
              placeholder={__('import-spreadsheet.placeholder.module')}
              onChange={() => {
                setFormValue(
                  'import_spreadsheet_wizard.import_spreadsheet',
                  'data_structure',
                  null
                );
              }}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='data_structure'
              component={Select}
              noSort
              label={__('import-spreadsheet.field.data_structure')}
              options={data_structures}
              placeholder={__('import-spreadsheet.placeholder.data_structure')}
            />
          </div>
        </div>

        {/* <div className={styles.flex}>
          <div className={styles.f5}>
            <Field
              name='modules'
              component={Select}
              // label={__('table.columns.task-date-time')}
              footer
              range
              time
              presets
            />
          </div>
        </div> */}

        {!_.isEmpty(clipboardFile) && (
          <div className={styles.clipboardFiles}>
            <Tooltip text={clipboardFile.name}>
              <div className={styles.icon} onClick={() => {}}>
                <DocumentExtIcon
                  className={
                    styles[
                      'color' +
                        (_.sumBy(
                          _.toLower(clipboardFile.type).split('/'),
                          (item) => item.charCodeAt(0)
                        ) %
                          5)
                    ]
                  }
                />
                <span>{clipboardFile.name.split('.').pop()}</span>

                <div className={styles.remove}>
                  <CloseSmallIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      setClipboardFile({});

                      props.change('file', {});
                    }}
                  />
                </div>
              </div>
            </Tooltip>
          </div>
        )}

        <ReactDropzone
          accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          className={classNames(
            styles.dropzone,
            drag && styles.drag,
            errors?.file && styles.errorDropzone
          )}
          onDrop={_handleDrop}
          onDragOver={() => setDrag(true)}
          onDragLeave={() => setDrag(false)}
          multiple={false}
        >
          <FileUploadIcon />
          <span>{__('tasks.wizard.drop-your-image-here')}</span>
          <div className={styles.actions}>
            <div>
              <FolderOpenedIcon />
            </div>
          </div>
        </ReactDropzone>

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() => {
              props.actions.hideImportSpreadsheet();

              props.reset();
            }}
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={
                values?.module != 'bill_of_quantities'
                  ? __('button.import')
                  : __('button.next')
              }
              spinning={props.submitting}
              onClick={props.handleSubmit}
            />
          </ButtonGroup>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    values: getFormValues(
      'import_spreadsheet_wizard.import_spreadsheet_basic_attributes'
    )(state),
    table:
      state.table[
        'import_spreadsheet_wizard.import_spreadsheet_basic_attributes'
      ],
    active_modules: state.general.active_modules,
    state: state,
    errors: getFormSubmitErrors(
      'import_spreadsheet_wizard.import_spreadsheet_basic_attributes'
    )(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideImportSpreadsheet: () =>
        dispatch({
          type: 'table.import_spreadsheet/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
          },
        }),
    },
  };
};

export default reduxForm({
  form: 'import_spreadsheet_wizard.import_spreadsheet_basic_attributes',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    subscribe(
      Localization,
      'localization'
    )(ImportSpreadsheetBasicAttributesForm)
  )
);
