import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/CompanyInfoWizard.scss";
import classNames from "classnames";
import {__, getFirstKey, mapStateToProps, redirect, setFormValue, validateForm} from "../core/utils";
import OfficesForm from "../forms/company_info_wizard/OfficesForm";
import {getFormValues, SubmissionError} from "redux-form";
import Localization from "../helpers/Localization";
import {subscribe} from "react-contextual";
import autobind from "autobind-decorator";
import DetailsForm from "../forms/company_info_wizard/DetailsForm";
import {createCompany, validateCompanyDetails, validateCompanyOffices} from "../redux/actions/companies";
import LogoForm from "../forms/company_info_wizard/LogoForm";
import DisciplinesForm from "../forms/company_info_wizard/DisciplinesForm";
import {setNotification} from "../redux/actions/general/notification";
import SizeForm from "../forms/company_info_wizard/SizeForm";
import MarketForm from "../forms/company_info_wizard/MarketForm";
import {getUser} from "../redux/actions/auth";
import {hideCreateCompanyWizard} from "../redux/actions/profile";

@subscribe(Localization, "localization")
@mapStateToProps(state => ({
	auth: state.auth,
	predefined_company_name: _.get(state.profile, 'predefined_company_name'),
	details: getFormValues("company_info_wizard.details")(state),
	offices: getFormValues("company_info_wizard.offices")(state),
	logo: getFormValues("company_info_wizard.logo")(state),
	disciplines: getFormValues("company_info_wizard.disciplines")(state),
	size: getFormValues("company_info_wizard.size")(state),
	market: getFormValues("company_info_wizard.market")(state),
	subscription: getFormValues("company_info_wizard.subscription")(state)
}))
class CompanyInfoWizard extends Component {
	steps = _.map([
		__("wizard.company.details"),
		__("wizard.company.offices"),
		__("wizard.company.logo"),
		__("wizard.company.disciplines"),
		__("wizard.company.size"),
		__("wizard.company.market"),
	], (title, id) => ({title, id: _.padStart(id+1, 2, '0')}));

	constructor(props) {
		super(props);

		this.state = {
			active: _.first(this.steps)
		};

		setFormValue('company_info_wizard.details', 'name', props.predefined_company_name);
	}

	@autobind
	_setActiveStep(id) {
		this.setState({ active: _.find(this.steps, item => item.id === id) });
	}

	@autobind
	_handleDetailsSubmit(formData) {
		return validateForm(validateCompanyDetails(formData).then(() => {
			this._setActiveStep("02");
		}));
	}

	@autobind
	_handleOfficesSubmit(formData) {
		return validateForm(validateCompanyOffices(formData).then(() => {
			this._setActiveStep("03");
		}));
	}

	@autobind
	_handleLogoSubmit() {
		this._setActiveStep("04");
	}

	@autobind
	_handleDisciplinesSubmit({ disciplines }) {
		if (!_.isEmpty(disciplines)) {
			this._setActiveStep("05");
		} else {
			throw new SubmissionError({ disciplines: true });
		}
	}

	@autobind
	_handleSizeSubmit() {
		this._setActiveStep("06");
	}

	@autobind
	_handleMarketSubmit() {
		const formData = {
			details: this.props.details,
			offices: this.props.offices,
			logo: _.get(this.props, "logo.logo"),
			disciplines: _.get(this.props, "disciplines.disciplines"),
			size_id: _.get(this.props, "size.size"),
			market: _.get(this.props, "market.countries"),
		};

		return createCompany(formData).then(() => {
			getUser().then(() => {
				hideCreateCompanyWizard();

				redirect('/projects')

				setNotification(__("wizard.company.notification.saved"));
			})
		});
	}

	render() {
		const {active} = this.state;

		return (
			<>
				<div className={styles.header}>
					<div className={styles.titleBar}>
						<div className={styles.left}>
							<span>{__('wizard.company.title.company-profile')}</span>
							<span>{active.title}</span>
						</div>
						<div className={styles.right}>
							<span>{active.id}</span>
							<span>{_.last(this.steps).id}</span>
						</div>
					</div>
					<div className={styles.steps}>
						{_.map(this.steps, (step, i) => (
							<div key={i} className={classNames(styles.step, active.id == step.id && styles.active, step.id < active.id && styles.completed)}>
								<span>{step.id}</span>
								<span>{step.title}</span>
							</div>
						))}
					</div>
				</div>
				<div className={styles.body}>
					{active.id === "01" && (
						<DetailsForm onSubmit={this._handleDetailsSubmit} />
					)}
					{active.id === "02" && (
						<OfficesForm
							initialValues={{
								..._.pick(_.get(this.state, "company"), ["address", "country_id", "state", "zip", "city"]),
								emails: [""],
								phones: [""]
							}}
							onSubmit={this._handleOfficesSubmit}
							onBack={() => this._setActiveStep("01")}
						/>
					)}
					{active.id === "03" && <LogoForm onSubmit={this._handleLogoSubmit} onBack={() => this._setActiveStep("02")} />}
					{active.id === "04" && <DisciplinesForm onSubmit={this._handleDisciplinesSubmit} onBack={() => this._setActiveStep("03")} />}
					{active.id === "05" && (
						<SizeForm initialValues={{ size: getFirstKey(this.props.localization.company_sizes) }} onSubmit={this._handleSizeSubmit} onBack={() => this._setActiveStep("04")} />
					)}
					{active.id === "06" && (
						<MarketForm initialValues={{ countries: [_.get(this.props, 'offices.country_id')] }} onSubmit={this._handleMarketSubmit} onBack={() => this._setActiveStep("05")} />
					)}
				</div>
			</>
		);
	}
}

export default CompanyInfoWizard;