const initialState = {
    uploads: {}
};

export default function reducer(state = initialState, action) {
    let data;

    switch (action.type) {
        case "table.working_set_plans/SET_TABLE_PARAMS":
            return {
                ...state,
                ...action.params,
            };
        case "table.working_set_plans/LIST_WORKING_SET_PLANS_SUCCESS":
            return {
                ...state,
                data: action.response.data,
                filters: action.response.filters,
                ...action.response.pagination
            };
        case "table.working_set_plans/UPLOAD_NEW_PLAN_VERSION_PROGRESS":
            data = {
                ...state
            };

            _.set(data, 'uploads.' + action.id, parseFloat(action.progress.loaded / action.progress.total * 100).toFixed(1));

            return data;
        case "table.working_set_plans/REMOVE_NEW_PLAN_VERSION_UPLOAD":
            data = {
                ...state
            };

            _.unset(data, 'uploads.' + action.plan_id);

            return data;
        default:
            return state;
    }
}
