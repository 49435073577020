import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_task_wizard/AddTaskForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import { __, getActiveProject, getActiveStage } from '../../core/utils';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker';
import PeopleChooser from '../../components/PeopleChooser';
import TaskDecisionIcon from '../../assets/images/task-decision-15x15.svg';
import TaskTodoIcon from '../../assets/images/task-todo-15x15.svg';
import TaskRfiIcon from '../../assets/images/task-rfi-15x15.svg';
import TaskFyiIcon from '../../assets/images/task-fyi-15x15.svg';
import TaskIssueIcon from '../../assets/images/task-issue-15x15.svg';
import TaskMilestoneIcon from '../../assets/images/task-milestone-15x15.svg';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import TaskPriorityIcon from '../../assets/images/task-priority-15x15.svg';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';
import FileUploadIcon from '../../assets/images/file-upload-32x32.svg';
import classNames from 'classnames';
import { withRouter } from 'react-router';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import PhotoIcon from '../../assets/images/photo-16x16.svg';
import FolderOpenedIcon from '../../assets/images/folder-opened-16x16.svg';
import ReactDropzone from 'react-dropzone';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import ButtonGroup from '../../components/ButtonGroup';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Tags from '../../components/Tags';
import MultiSelect from '../../components/MultiSelect';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import DocumentExtIcon from '../../assets/images/document-ext-30x30.svg';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import { showLightbox } from '../../redux/actions/general/lightbox';

const mapStateToProps = (state) => {
  return {
    values: getFormValues('add_task_wizard.add_task')(state),
    active_modules: state.general.active_modules,
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideAddTaskWizard: () =>
        dispatch({
          type: 'table.all_tasks/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
            attach_task_wizard: undefined,
          },
        }),
    },
  };
};

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'add_task_wizard.add_task' })
@connect(mapStateToProps, mapDispatchToProps)
class AddTaskForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_more: false,
      clipboardFiles: [],
    };
  }

  @autobind
  _handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({ drag: false });

    if (!_.isEmpty(acceptedFiles)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('tasks.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('tasks.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (_.first(acceptedFiles).size >= 104857600) {
        setNotification({
          text: __('tasks.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        this.setState(
          {
            clipboardFiles: [
              ...this.state.clipboardFiles,
              ..._.map(acceptedFiles, (file) => {
                return Object.assign(file, {
                  preview: URL.createObjectURL(file),
                });
              }),
            ],
          },
          () => {
            this.props.change('files', this.state.clipboardFiles);
          }
        );

        // let preview = new FileReader();
        // preview.onloadend = () => {
        //   this.setState({
        //     file_preview: preview.result,
        //   });
        // };
        // preview.readAsDataURL(_.first(acceptedFiles));
      }
    }
  }

  @autobind
  _handlePaste(event) {
    const files = event.clipboardData.files;

    this.setState(
      {
        clipboardFiles: [
          ...this.state.clipboardFiles,
          ..._.map(files, (file) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
          }),
        ],
      },
      () => {
        this.props.change('files', this.state.clipboardFiles);
      }
    );
  }

  componentDidMount() {
    window.addEventListener('paste', this._handlePaste);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this._handlePaste);
  }

  // _handlePaste(files) {
  //   console.log(files, 'files123');
  //   if (!_.isEmpty(files)) {
  //     this.setState({ files: files });

  //     const request = new XMLHttpRequest();

  //     const change = this.props.change;
  //     const pointer = this;

  //     request.open('GET', _.last(files), true);
  //     request.responseType = 'blob';
  //     request.onload = function () {
  //       const reader = new FileReader();

  //       reader.readAsDataURL(request.response);
  //       reader.onloadend = function (e) {
  //         let arr = e.target.result.split(','),
  //           mime = arr[0].match(/:(.*?);/)[1],
  //           bstr = atob(arr[1]),
  //           n = bstr.length,
  //           u8arr = new Uint8Array(n);

  //         while (n--) {
  //           u8arr[n] = bstr.charCodeAt(n);
  //         }

  //         const file = new File(
  //           [u8arr],
  //           'IMG_' + moment.utc().local().format('YYMMDDHHmmss') + '.jpg',
  //           {
  //             type: mime,
  //           }
  //         );

  //         change('file', file);

  //         pointer.setState({ file_preview: _.last(files) });

  //         pointer.setState({ files: files });
  //       };
  //     };

  //     request.send();
  //   }
  // }

  // @autobind
  // _handleRemove() {
  //   this.props.change('file', null);

  //   this.setState({ file_preview: undefined });
  // }

  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  render() {
    const project = _.find(this.props.active_modules.items, [
      'id',
      getActiveProject(),
    ]);
    const stage = project
      ? _.find(project.stages, ['id', getActiveStage()])
      : null;

    const { show_more } = this.state;
    const { disciplines } = this.props.localization;

    const types = [
      {
        value: 'rfi',
        label: __('tasks.tooltip.icon.rfi'),
        icon: (hover, active, selected) => (
          <TaskRfiIcon
            className={classNames(
              styles.rfiIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'fyi',
        label: __('tasks.tooltip.icon.fyi'),
        icon: (hover, active, selected) => (
          <TaskFyiIcon
            className={classNames(
              styles.fyiIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'decision',
        label: __('tasks.tooltip.icon.decision'),
        icon: (hover, active, selected) => (
          <TaskDecisionIcon
            className={classNames(
              styles.decisionIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'todo',
        label: __('tasks.tooltip.icon.todo'),
        icon: (hover, active, selected) => (
          <TaskTodoIcon
            className={classNames(
              styles.todoIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'milestone',
        label: __('tasks.tooltip.icon.milestone'),
        icon: (hover, active, selected) => (
          <TaskMilestoneIcon
            className={classNames(
              styles.milestoneIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'issue',
        label: __('tasks.tooltip.icon.issue'),
        icon: (hover, active, selected) => (
          <TaskIssueIcon
            className={classNames(
              styles.issueIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
    ];

    const statuses = [
      {
        value: 'not-started',
        label: __('table.columns.task-status-notstarted'),
        color: '#9C27B0',
      },
      {
        value: 'started',
        label: __('table.columns.task-status-started'),
        color: '#5777FC',
      },
      {
        value: 'finished',
        label: __('table.columns.task-status-finished'),
        color: '#ADD42B',
      },
    ];

    const priorities = [
      {
        value: 'low',
        label: __('table.columns.task-priority-low'),
        color: '#FFC108',
      },
      {
        value: 'normal',
        label: __('table.columns.task-priority-normal'),
        color: '#FE9800',
      },
      {
        value: 'high',
        label: __('table.columns.task-priority-high'),
        color: '#FF5721',
      },
      {
        value: 'urgent',
        label: __('table.columns.task-priority-urgent'),
        color: '#E62A11',
      },
    ];

    const visibilities = [
      {
        value: '0',
        label: __('task.visibility.private'),
        color: '#7f7f7d',
      },
      {
        value: '1',
        label: __('task.visibility.public'),
        color: '#029DFC',
      },
    ];

    const { clipboardFiles } = this.state;

    const clipboard_lightbox_images = _.filter(
      _.map(
        clipboardFiles,
        (file, i) =>
          _.includes(
            ['image/jpg', 'image/jpeg', 'image/png'],
            _.toLower(file.type)
          ) && {
            id: ++i,
            stream_url: URL.createObjectURL(file),
            title: file.name,
          }
      )
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.top}>
          <Field
            name='status'
            component={Select}
            label={__('table.columns.task-status')}
            options={statuses}
            noSort
            dropdown
            icon={<TaskStatusIcon className={styles.taskStatus} />}
            wrapper={(handleOpen, activeItem, elementRef) => (
              <Tooltip text={__('tasks.tooltip.icon.status')}>
                <div
                  className={styles.dropdown}
                  ref={elementRef}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <TaskStatusIcon className={styles.taskStatus} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
          <Field
            name='priority'
            component={Select}
            label={__('table.columns.task-priority')}
            options={priorities}
            noSort
            dropdown
            icon={<TaskPriorityIcon className={styles.taskPriority} />}
            wrapper={(handleOpen, activeItem, elementRef) => (
              <Tooltip text={__('tasks.tooltip.icon.priority')}>
                <div
                  className={styles.dropdown}
                  ref={elementRef}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <TaskPriorityIcon className={styles.taskPriority} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
          <Field
            name='visibility'
            component={Select}
            label={__('tasks.tooltip.icon.visibility')}
            options={visibilities}
            noSort
            dropdown
            icon={<TaskVisibilityIcon className={styles.taskVisibility} />}
            wrapper={(handleOpen, activeItem, elementRef) => (
              <Tooltip text={__('tasks.tooltip.icon.visibility')}>
                <div
                  className={styles.dropdown}
                  ref={elementRef}
                  style={{ background: activeItem.color }}
                  onClick={handleOpen}
                >
                  <TaskVisibilityIcon className={styles.taskVisibility} />
                  <div className={styles.button}>
                    {activeItem.label}
                    <ArrowDownSmallIcon />
                  </div>
                </div>
              </Tooltip>
            )}
          />
        </div>
        <div className={styles.form}>
          {stage && (
            <div className={styles.location}>
              <span>
                <strong>{project.name}</strong>, {stage.name}
              </span>
            </div>
          )}
          <div className={styles.flex}>
            <div className={styles.f4}>
              <Field
                name='type'
                component={Select}
                noSort
                label={__('table.columns.task-type')}
                options={types}
              />
            </div>
            <div className={styles.f5}>
              <Field
                name='date'
                component={DateTimePicker}
                label={__('table.columns.task-date-time')}
                footer
                range
                time
                presets
              />
            </div>
          </div>
          <Field
            name='title'
            component={Input}
            label={__('table.columns.task-title')}
            maxLength={60}
          />
          <Field
            name='description'
            component={Textarea}
            localized={this.props.projectLanguages}
            label={__('table.columns.task-description')}
            maxLength={2000}
            rows={1}
            maxRows={10}
          />
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='assignee'
                component={PeopleChooser}
                label={__('table.columns.assignee')}
                searchable
                options={this.props.possibleTaskAssignee}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='followers'
                component={PeopleChooser}
                label={__('tasks.flyout.followers')}
                searchable
                options={this.props.possibleTaskFollowers}
              />
            </div>
          </div>
          <div className={styles.more}>
            {show_more && (
              <div className={styles.flex}>
                <div className={styles.f1}>
                  <Field
                    name='stage_disciplines'
                    searchable={true}
                    component={MultiSelect}
                    label={__('tasks.flyout.disciplines')}
                    options={_.map(
                      this.props.possibleStageDisciplines,
                      (stage_discipline) => ({
                        value: stage_discipline.id,
                        label: [
                          stage_discipline.code,
                          stage_discipline.discipline_id == null
                            ? stage_discipline.description + '*'
                            : _.get(disciplines, [
                                stage_discipline.discipline_id,
                                'name',
                              ]),
                        ].join(': '),
                      })
                    )}
                  />
                </div>
                <div className={styles.f1}>
                  <Field
                    name='tags'
                    component={Tags}
                    label={__('tasks.flyout.tags')}
                    options={this.props.stageTags}
                  />
                </div>
              </div>
            )}
            <div
              className={styles.showMore}
              onClick={() => this.setState({ show_more: !show_more })}
            >
              {show_more
                ? __('tasks.add-task.show-less')
                : __('tasks.add-task.show-more')}{' '}
              <ArrowDownMiddleIcon className={show_more && styles.active} />
            </div>
          </div>

          {clipboardFiles && (
            <div className={styles.clipboardFiles}>
              {_.map(clipboardFiles, (clipboardFile, i) => {
                return !_.includes(
                  ['image/jpg', 'image/jpeg', 'image/png'],
                  clipboardFile.type
                ) ? (
                  <Tooltip text={clipboardFile.name} key={i}>
                    <div className={styles.icon} onClick={() => {}}>
                      <DocumentExtIcon
                        className={
                          styles[
                            'color' +
                              (_.sumBy(
                                _.toLower(clipboardFile.type).split('/'),
                                (item) => item.charCodeAt(0)
                              ) %
                                5)
                          ]
                        }
                      />
                      <span>{clipboardFile.name.split('.').pop()}</span>

                      <div className={styles.remove}>
                        <CloseSmallIcon
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            const files = _.filter(
                              Object.values(clipboardFiles),
                              (file) => {
                                return file != clipboardFile;
                              }
                            );

                            this.setState({
                              clipboardFiles: files,
                            });

                            this.props.change('files', files);
                          }}
                        />
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    key={i}
                    className={styles.thumbnail}
                    onClick={() => showLightbox(clipboard_lightbox_images, ++i)}
                    style={{
                      backgroundImage: "url('" + clipboardFile.preview + "')",
                    }}
                  >
                    <CloseSmallIcon
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const files = _.filter(
                          Object.values(clipboardFiles),
                          (file) => {
                            return file != clipboardFile;
                          }
                        );

                        this.setState({
                          clipboardFiles: files,
                        });

                        this.props.change('files', files);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {/* {this.props.values.file && this.state.file_preview && (
            <div className={styles.preview}>
              <img
                src={this.state.file_preview}
                key={this.state.file_preview}
              />
              <span className={styles.delete} onClick={this._handleRemove}>
                <CloseBigIcon />
              </span>
            </div>
          )} */}

          <ReactDropzone
            accept='image/png,image/jpeg'
            className={classNames(
              styles.dropzone,
              _.get(this.state, 'drag') && styles.drag
            )}
            onDrop={this._handleDrop}
            onDragOver={() => this.setState({ drag: true })}
            onDragLeave={() => this.setState({ drag: false })}
            multiple={true}
          >
            <FileUploadIcon />
            <span>{__('tasks.wizard.drop-your-image-here')}</span>
            <div className={styles.actions}>
              <div>
                <FolderOpenedIcon />
              </div>
              <div>
                <PhotoIcon />
              </div>
            </div>
          </ReactDropzone>

          {/* {!this.props.values.file && (
            <Gluejar onPaste={this._handlePaste}>{() => <></>}</Gluejar>
          )} */}

          <div className={styles.footer}>
            <Button
              lightGray
              medium
              left
              middleText={__('button.cancel')}
              onClick={this.props.actions.hideAddTaskWizard}
            />
            <ButtonGroup>
              <Button
                lightBlue
                medium
                middleText={__('button.create')}
                spinning={this.props.submitting}
                onClick={() => this._handleSubmit(false)}
              />
              {this.props.match.path == '/stages/:stage/tasks' && (
                <Button
                  lightBlue
                  medium
                  middleText={__('button.create-edit')}
                  spinning={this.props.submitting}
                  onClick={() => this._handleSubmit(true)}
                />
              )}
            </ButtonGroup>
          </div>
        </div>
      </Form>
    );
  }
}

export default AddTaskForm;
