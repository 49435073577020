import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/meetings/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import EditMeetingForm from "../../forms/meetings/EditMeetingForm";
import {listAllMeetings, updateMeeting} from "../../redux/actions/table/all_meetings";
import {__, mapStateToProps, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";

@withRouter
@mapStateToProps(state => ({
    table: state.table["all_meetings"],
}))
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        formData = {
            ...formData,
            participants: _.map(formData.participants, 'id'),
            followers: _.map(formData.followers, 'id'),
            administrators: _.map(formData.administrators, 'id'),
        }

        return validateForm(
            updateMeeting(formData.id, _.omit(formData, ['id'])).then(() => {
                listAllMeetings(this.props.match.params.stage || this.props.table.stage_id, this.props.match.params.status);

                this.props.readAction(formData.id);

                setNotification(__('meetings.properties.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditMeetingForm
                    onSubmit={this._handleSubmit}
                    data={data}
                    initialValues={{
                        ..._.pick(data, ['id', 'title', 'period', 'duration', 'description', 'administrators', 'participants', 'followers', 'tags', 'footnote', 'location_name', 'location_address']),
                        languages: _.map(data.languages, _.toString),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
