import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditLinkedSpecificationWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { withRouter } from 'react-router';
import {
  readWorkingSetSpecification,
  updateSpecificationFileCategory,
} from '../redux/actions/table/working_set_specifications';
import { setNotification } from '../redux/actions/general/notification';
import * as _ from 'lodash';
import AddDocumentIcon from '../assets/images/add-document-60x60.svg';
import EditSpecificationFileCategoryForm from '../forms/edit_specification_file_category_wizard/EditSpecificationFileCategoryForm';

@mapStateToProps((state) => ({
  store: _.get(state.table, 'working_set_specifications'),
}))
@withRouter
class EditSpecificationFileCategoryWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const specification_id = this.props.store.flyout.id;
    const attachment_id = this.props.store.attachment_id;

    return validateForm(
      updateSpecificationFileCategory(specification_id, {
        category: formData.category,
        attachment_id: attachment_id,
      }).then(() =>
        readWorkingSetSpecification(this.props.store.flyout.id).then(
          ({ response }) => {
            setNotification('A specification file category has been updated.');

            setTableParams('working_set_specifications', {
              flyout: response.data,
            });

            this.props.onClose();
          }
        )
      )
    );
  }

  render() {
    const { edit_specification_file_category } = this.props.store;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.working-set-specifications.title.edit-specification-file-category'
              )}
            </span>
            <span>
              {__(
                'wizard.working-set-specifications.subtitle.edit-specification-file-category'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddDocumentIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditSpecificationFileCategoryForm
            onSubmit={this._handleSubmit}
            initialValues={{ category: edit_specification_file_category }}
          />
        </div>
      </>
    );
  }
}

export default EditSpecificationFileCategoryWizard;
