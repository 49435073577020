import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/products/read_only/Overview.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";
import PlusBigIcon from "../../../assets/images/plus-big-20x20.svg";
import autobind from "autobind-decorator";
import CloseSmallIcon from "../../../assets/images/close-small-15x15.svg";
import { setNotification } from "../../../redux/actions/general/notification";
import { deleteProductImage, listProducts, readProduct, reorderProductImages, uploadProductImages } from "../../../redux/actions/table/products";
import { __, getLocalized, mapStateToProps, setTableParams, redirect } from "../../../core/utils";
import { hideAlertbox, showAlertbox } from "../../../redux/actions/general/alertbox";
import { showLightbox } from "../../../redux/actions/general/lightbox";
import { ReactSortable } from "react-sortablejs";
import Image from "../../../components/Image";
import DefaultImage from "../../../assets/images/default.png";

@mapStateToProps((state) => ({
	store: state.table.products
}))
class Overview extends Component {
	render() {
		const { collapsed, store } = this.props;
		const { flyout, language_id } = store;

		const thumbnail_images = _.sortBy(_.filter(flyout.images, ["category", "thumbnail"]), "order");

		const lightbox_images = _.map(thumbnail_images, (image) => ({
			id: image.id,
			stream_url: image.stream_url,
			title: [image.name, image.ext].join(".")
		}));

		return (
			<div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
				<div className={styles.flex}>
					<div className={classNames(styles.f1, styles.image)} onClick={() => (!_.isEmpty(thumbnail_images) ? showLightbox(lightbox_images, _.first(thumbnail_images).id) : undefined)}>
						<Image default={DefaultImage} src={flyout.thumb_url} />
					</div>
					<div className={classNames(styles.f1, styles.info)}>
						<div className={styles.logo}>
							<Image
								onClick={() => {
									redirect("/products?filter.brand=" + flyout.brand_id);
									setTableParams("products", { clicked_row: undefined });
								}}
								default={DefaultImage}
								src={flyout.brand_logo_url}
							/>
						</div>
						<div className={styles.details}>
							{_.size(flyout.model) > 0 && (
								<div className={styles.row}>
									<strong>{__("products.column.model")}</strong>
									<span>{flyout.model}</span>
								</div>
							)}
							{_.size(flyout.brand) > 0 && (
								<div className={styles.row}>
									<strong>{__("products.column.manufacturer")}</strong>
									{_.isNull(flyout.brand_address_book_company_id) ? (
										<span
											className={styles.linkTo}
											onClick={() => {
												redirect("/products?filter.brand=" + flyout.brand_id);
												setTableParams("products", { clicked_row: undefined });
											}}
										>
											{flyout.brand}
										</span>
									) : (
										<span>{flyout.brand}</span>
									)}
								</div>
							)}
							{_.size(getLocalized(flyout.description, language_id)) > 0 && (
								<div className={styles.row}>
									<strong>{__("products.column.description")}</strong>
									<span>{getLocalized(flyout.description, language_id)}</span>
								</div>
							)}
							{_.size(flyout.family) > 0 && (
								<div className={styles.row}>
									<strong>{__("products.column.family")}</strong>
									<span>
										{_.map(flyout.family, (family, i) => (
											<>
												{i !== 0 && ", "}
												<a
													className={styles.linkTo}
													onClick={() => {
														redirect("/products?filter.family=" + family);
														setTableParams("products", { clicked_row: undefined });
													}}
												>
													{family}
												</a>
											</>
										))}
									</span>
								</div>
							)}
							{_.size(flyout.collection) > 0 && (
								<div className={styles.row}>
									<strong>{__("products.column.collection")}</strong>
									<span>
										{_.map(flyout.collection, (collection, i) => (
											<>
												{i !== 0 && ", "}
												<a
													className={styles.linkTo}
													onClick={() => {
														redirect("/products?filter.collection=" + collection);
														setTableParams("products", { clicked_row: undefined });
													}}
												>
													{collection}
												</a>
											</>
										))}
									</span>
								</div>
							)}
							{_.size(flyout.designer) > 0 && (
								<div className={styles.row}>
									<strong>{__("products.column.designer")}</strong>
									<span>
										{_.map(flyout.designer, (designer, i) => (
											<>
												{i !== 0 && ", "}
												<a
													className={styles.linkTo}
													onClick={() => {
														redirect("/products?filter.designer=" + designer);
														setTableParams("products", { clicked_row: undefined });
													}}
												>
													{designer}
												</a>
											</>
										))}
									</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Overview;
