import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/translations/TranslationsFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../../assets/images/close-big-15x15.svg";
import {__, mapStateToProps, setTableParams} from "../../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../../helpers/Localization";
import {setNotification} from "../../../redux/actions/general/notification";
import withRouter from "react-router/es/withRouter";
import Tooltip from "../../../components/Tooltip";
import DeleteIcon from "../../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../../redux/actions/general/alertbox";
import classNames from "classnames";
import Properties from "./Properties";
import {deleteTranslations, listTranslations} from "../../../redux/actions/table/superadmin/translations";

@subscribe(Localization, "localization")
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    auth: state.auth,
}))
class TranslationsFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    render() {
        const {data, readAction, superadmin, auth} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>
                            {_.get(data, 'keyword')}
                        </span>
                        <CloseBigIcon onClick={() => setTableParams('superadmin_translations', {
                            clicked_row: undefined
                        })}/>
                    </div>
                    <div className={styles.actions}>
                        {superadmin.delete_translation && <div className={styles.actionWrapper}>
                            <Tooltip text={__('superadmin.translations.flyout.delete-translation')} key={_.uniqueId()}>
                                <DeleteIcon
                                    onClick={e => {
                                        e.stopPropagation();

                                        showAlertbox({
                                            color: "red",
                                            title: __('general.alert.are-you-sure'),
                                            description: __('superadmin.translations.flyout.delete-translation.want-delete-selected-translation.process-cannot-be-undone'),
                                            buttons: [
                                                {
                                                    color: 'lightGray',
                                                    text: __('general.alert.no-close'),
                                                    onClick: 'close'
                                                },
                                                {
                                                    color: 'gray',
                                                    text: __('general.alert.yes-delete'),
                                                    onClick: () => {
                                                        deleteTranslations([data.id]).then(() => {
                                                            hideAlertbox();

                                                            setNotification(__('superadmin.add-translation.notification.selected-translation-has-been-deleted'));
                                                            listTranslations();

                                                            setTableParams('superadmin_translations', {
                                                                clicked_row: undefined
                                                            });
                                                        });
                                                    },
                                                }
                                            ]
                                        })
                                    }}
                                />
                            </Tooltip>
                        </div>}
                    </div>
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>Properties</span>
                </div>
                {!_.isEmpty(data) && this.state.active == 'properties' && <Properties data={data} readAction={readAction} />}
            </div>
        );
    }
}

export default TranslationsFlyout;
