import * as React from "react";
import {Component} from "react";
import styles from "../styles/components/Overlay.scss";
import {mapStateToProps} from "../core/utils";
import classNames from "classnames";

@mapStateToProps(state => ({
	...state.general.overlay
}))
class Overlay extends Component {
	render() {
		const {show} = this.props;

		return <div className={classNames(styles.wrapper, show && styles.overlay)} />
	}
}

export default Overlay;
