import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/profile/ChangePasswordForm.scss";
import PasswordInput from "../../components/PasswordInput";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import {__} from "../../core/utils";

const mapStateToProps = state => {
	return {
		values: getFormValues("profile.change_password")(state)
	};
};
@subscribe(Localization, "localization")
@reduxForm({
	form: "profile.change_password",
})
@connect(mapStateToProps)
class ChangePasswordForm extends Component {
	render() {
       return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				<div className={styles.flex}>
					<div className={styles.f1}>
						<Field
							name="password"
							autoComplete={false}
							component={PasswordInput}
							success={this.props.values && this.props.values.password_confirmation && this.props.values.password == this.props.values.password_confirmation}
							label={__('profile-settings.security.update-password')}
						/>
					</div>
					<div className={styles.f1}>
						<Field
							name="password_confirmation"
							component={PasswordInput}
							autoComplete={false}
							error={this.props.values && this.props.values.password_confirmation && this.props.values.password != this.props.values.password_confirmation}
							success={this.props.values && this.props.values.password_confirmation && this.props.values.password == this.props.values.password_confirmation}
							label={__('profile-settings.security.confirm-password')}
						/>
					</div>
				</div>
				<Button left submit leftText={__('Save')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
			</Form>
		);
	}
}

export default ChangePasswordForm;
