import * as React from 'react';
import styles from '../../styles/flyouts/boq/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import { __, getURLParam, validateForm } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import classNames from 'classnames';
import EditBOQForm from '../../forms/boq/EditBOQForm';
import {
  updateBOQCalculationBasicData,
  updateBOQItem,
} from '../../redux/actions/table/working_set_boq';

const Properties = (props) => {
  const _handleSubmit = (formData) => {
    const id = props.table.clicked_row;

    const action = getURLParam('boq_item_id')
      ? updateBOQItem(id, formData)
      : updateBOQCalculationBasicData(id, formData);

    return validateForm(
      action.then(() => {
        props.readAction(formData.id);

        setNotification(__('boq.properties.saved'));
      })
    );
  };

  const {
    data,
    collapsed,
    table: { project_languages },
  } = props;

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      <EditBOQForm
        onSubmit={_handleSubmit}
        data={data}
        project_languages={project_languages}
        initialValues={{
          ..._.pick(data, [
            'id',
            'name',
            'description',
            'team_id',
            'responsible_user_id',
          ]),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['working_set_boq'],
    language_id: state.auth.language_id,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(subscribe(Localization, 'localization')(Properties))
);
