import * as _ from 'lodash';
import styles from '../../../styles/views/tenders/TenderBOQ.scss';
import {
  formatMoney,
  getLocalized,
  __,
  setTableParams,
} from '../../../core/utils';
import ArrowDoubleRightSmallIcon from '../../../assets/images/arrow-double-right-small-15x15.svg';
import Dropdown from '../../../components/Dropdown';
import classNames from 'classnames';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import BaseTable from 'react-base-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, { useCallback, useMemo, useState } from 'react';
import ExpandIcon from './ExpandIcon';
import Row from './Row';
import NumericInput from './NumericInput';
import Tooltip from '../../../components/Tooltip';
import CommentIconSmallIcon from '../../../assets/images/comments-16x16.svg';
import { readTenderSpecification } from '../../../redux/actions/table/tenders';
import { withRouter } from 'react-router';
import TextareaAutosize from 'react-autosize-textarea';
import WarningIcon from '../../../assets/images/tender-icon-warning-16x16.svg';
import SubstituteIcon from '../../../assets/images/tender-icon-substitute-16x16.svg';
import NotAvailableIcon from '../../../assets/images/tender-icon-not-available-16x16.svg';
import AddLongDescriptionIcon from '../../../assets/images/add-long-description-from-boq-16x16.svg';

const TableOwner = ({
  bidders,
  focusedId,
  setFocusedId,
  data,
  setRef,
  languageId,
  setNameRef,
  projectLanguageId,
  projectCurrency,
  localization,
  tableRef,
  setExpandedRows,
  expandedRows,
  match,
}) => {
  const { specification_groups, specification_subgroups, units } = localization;

  const icon_statuses = {
    not_available: {
      color: '#E62A10',
      icon: <NotAvailableIcon />,
    },

    warning: {
      color: '#FBC02D',
      icon: <WarningIcon />,
    },

    substitute: {
      color: '#9C27B0',
      icon: <SubstituteIcon />,
    },
  };

  const final_data = useMemo(() => {
    const rows = data;

    const process = (rows) =>
      _.map(rows, (row) => {
        return {
          ...row,
          children: _.isEmpty(row.children) ? undefined : process(row.children),
        };
      });

    return process(rows);
  }, [data]);

  const _handleMasterExpand = (expanded_rows, unallocated_collapsed) => {
    setExpandedRows(expanded_rows); //set expanded rows in local state
    tableRef.setExpandedRowKeys(expanded_rows); //set expanded rows in dependency state
  };

  const calculateMedian = (arr) => {
    const sortedArr = [...arr].sort((a, b) => a - b);

    const half = Math.floor(sortedArr.length / 2);

    return sortedArr.length % 2
      ? sortedArr[half]
      : (sortedArr[half - 1] + sortedArr[half]) / 2;
  };

  let columns = [
    {
      key: 'name',
      title: 'Title',
      width: 600,
      minWidth: 500,
      resizable: true,
      sortable: false,
      frozen: 'left',
      cellRenderer: ({ rowData }) => (
        <>
          {rowData.level < 3 ? (
            <span className={styles.code}>{rowData.code}</span>
          ) : (
            <span className={styles.codePlaceholder} />
          )}

          <div
            className={classNames(
              styles.nameContainer,
              !_.isEmpty(rowData.description)
            )}
          >
            <div className={styles.descriptionRow}>
              <div className={styles.shortDescriptionRow}>
                {rowData.level === 4 && <span>&#x2212;&nbsp;&nbsp;</span>}
                <div
                  className={classNames(
                    styles.input,
                    (rowData.level === 2 || rowData.level === 3) &&
                      styles.longDescription,
                    rowData.level != 4 && styles.bold
                  )}
                >
                  {_.get(rowData.name, languageId)}
                </div>
              </div>
              {(rowData.level === 2 || rowData.level === 3) &&
                !_.isEmpty(rowData.description) && (
                  <div className={styles.longDescriptionRow}>
                    {_.get(rowData.description, languageId)}
                  </div>
                )}
            </div>
          </div>
        </>
      ),
    },
    {
      key: 'spec',
      title: 'Spec',
      align: 'right',
      width: 100,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3) {
          if (rowData.specification_code_id) {
            const specification_subgroup =
              _.get(specification_subgroups, [
                rowData.specification.subgroup_id,
              ]) || {};
            const specification_group =
              _.get(
                specification_groups,
                _.get(specification_subgroup, 'specification_group_id')
              ) || {};

            const specification_code =
              getLocalized(specification_group.code, projectLanguageId, 1) +
              specification_subgroup.code +
              _.padStart(rowData.specification.code, 2, '0');

            return (
              <Tooltip text='Go to specification'>
                <div
                  className={styles.spec}
                  onClick={() => {
                    readTenderSpecification(
                      match.params.tender,
                      rowData.specification.id
                    ).then(({ response }) => {
                      setTableParams('working_set_specifications', {
                        flyout: response.data,
                        clicked_row: response.data.id,
                      });
                    });
                  }}
                >
                  {specification_code}
                  <ArrowDoubleRightSmallIcon />
                </div>
              </Tooltip>
            );
          } else {
            return <div className={styles.noSpec}>(No spec.)</div>;
          }
        }
      },
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataKey: 'quantity',
      width: 120,
      resizable: true,
      sortable: false,
      align: 'right',
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 || rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.quantity}
              readOnly={true}
              tooltip={
                rowData.level === 3 && !_.isEmpty(rowData.children)
                  ? 'This field is auto-calculated'
                  : ''
              }
              defaultValue={rowData.quantity}
            />
          );
        }
      },
    },
    {
      key: 'unit',
      title: 'Unit',
      width: 70,
      resizable: false,
      sortable: false,
      align: 'right',
      cellRenderer: ({ rowData }) =>
        rowData.level === 3 ? (
          <Dropdown
            readOnly
            top={25}
            right={-15}
            wrapperClassName={classNames(
              styles.dropdownWrapper,
              styles.disabled
            )}
            name={'unit.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Unit</div>}
          >
            <div className={classNames(styles.dropdown, styles.disabled)}>
              {rowData.unit_id && (
                <span>{_.get(units, [rowData.unit_id, 'symbol'])}</span>
              )}
              {!rowData.unit_id && <span className={styles.none}>--</span>}
              <ArrowDownSmallIcon />
            </div>
          </Dropdown>
        ) : (
          ''
        ),
    },
  ];

  columns = [
    ...columns,

    ..._.flatMap(bidders, (item, key) => {
      return [
        {
          key: `bidder_price-${item.id}`,
          title: `Price (${projectCurrency})`,
          width: 100,

          cellRenderer: ({ rowData }) => {
            if (rowData.level === 3 || rowData.level === 4) {
              const bidder_prices = _.toArray(rowData.prices);

              const price = _.get(rowData.prices, item.id);

              return (
                <div
                  className={classNames(
                    styles.alignRight,
                    styles.priceWrapper,
                    _.size(bidder_prices) > 1 && [
                      _.min(bidder_prices) == price && styles.minValue,
                      _.max(bidder_prices) == price && styles.maxValue,
                    ]
                  )}
                >
                  {formatMoney(price)}
                </div>
              );
            }
          },
        },
        {
          key: `bidder_subtotal-${item.id}`,
          title: `Total`,
          width: 140,
          align: 'right',
          resizable: true,
          cellRenderer: ({ rowData }) => {
            const bidder_subtotals = _.toArray(rowData.subtotal);
            const subtotal = _.get(rowData.subtotal, item.id);

            const status = _.get(rowData.statuses, item.id);
            const warning_status = _.get(rowData.warning_statuses, item.id);

            let selected_icon;

            if (rowData.level < 3 && warning_status) {
              selected_icon = icon_statuses['warning'];
            }

            if (_.includes(['not_available', 'substitute'], status)) {
              selected_icon = icon_statuses[status];
            }

            return (
              <div
                className={classNames(
                  styles.subtotalWrapper,
                  selected_icon && styles.subtotal,
                  _.size(bidder_subtotals) > 1 && [
                    _.min(bidder_subtotals) == subtotal && styles.minValue,
                    _.max(bidder_subtotals) == subtotal && styles.maxValue,
                  ]
                )}
              >
                {selected_icon && (
                  <div
                    className={styles.icon}
                    style={{ backgroundColor: selected_icon['color'] }}
                  >
                    {selected_icon['icon']}
                  </div>
                )}

                <span>{formatMoney(_.toNumber(subtotal))}</span>
              </div>
            );
          },
        },

        {
          key: `bidder_percentage-${item.id}`,
          title: `%`,
          width: 50,
          align: 'right',
          resizable: true,
          cellRenderer: ({ rowData }) => {
            const subtotal = _.get(rowData.subtotal, item.id);

            const total = _.sumBy(data, (group) => group.subtotal[item.id]);

            return formatMoney(_.round((subtotal / total) * 100, 2));
          },
        },

        {
          key: `bidder_comments-${item.id}`,
          title: <CommentIconSmallIcon />,
          width: 50,
          resizable: false,
          sortable: false,
          cellRenderer: ({ rowData }) => {
            if (rowData.level === 3) {
              const commentValue = _.get(rowData.comments, item.id);
              const disabled = _.isEmpty(commentValue);

              if (_.isEmpty(commentValue)) {
                return;
              }
              return (
                <Dropdown
                  top={25}
                  left={-15}
                  header={<div className={styles.dropdownHeader}>Comment</div>}
                  readOnly={disabled}
                  leftStyled
                  wrapperClassName={classNames(
                    styles.dropdownWrapper,
                    styles.commentWrapper
                  )}
                  name={rowData.id + '.' + item.id}
                  content={
                    <div
                      className={classNames(
                        styles.dropdownContent,
                        styles.commentContent
                      )}
                    >
                      <TextareaAutosize
                        onFocus={(e) =>
                          e.currentTarget.setSelectionRange(
                            e.currentTarget.value.length,
                            e.currentTarget.value.length
                          )
                        }
                        readOnly
                        autoFocus
                        rows={3}
                        maxRows={15}
                        defaultValue={commentValue}
                        key={rowData.id + '.' + item.id}
                        maxLength={3000}
                      />
                    </div>
                  }
                >
                  <div
                    className={classNames(
                      styles.comment,
                      commentValue && styles.active
                    )}
                  >
                    <CommentIconSmallIcon />
                  </div>
                </Dropdown>
              );
            }
          },
        },
      ];
    }),

    {
      key: 'min',
      title: 'Min',
      width: 108,
      resizable: false,
      sortable: false,
      frozen: 'right',
      cellRenderer: ({ rowData }) => {
        const subtotal = _.toArray(rowData.subtotal);

        return formatMoney(_.min(subtotal));
      },
    },

    {
      key: 'average',
      title: 'Average',
      width: 108,
      resizable: false,
      sortable: false,
      frozen: 'right',
      cellRenderer: ({ rowData }) => {
        const subtotal = _.toArray(rowData.subtotal);

        return formatMoney(_.mean(subtotal));
      },
    },

    {
      key: 'median',
      title: 'Median',
      width: 108,
      resizable: false,
      sortable: false,
      frozen: 'right',
      cellRenderer: ({ rowData }) => {
        const subtotal = _.toArray(rowData.subtotal);

        return formatMoney(calculateMedian(subtotal));
      },
    },
  ];

  const headerRenderer = ({ headerIndex, cells, columns }) => {
    if (headerIndex === 0) {
      return _.map(columns, (column, i) => {
        let children = _.filter(cells[i].props.children, _.identity);

        let value = '';

        if (_.includes(column.key, 'bidder_price')) {
          const bidder_id = _.last(_.split(column.key, '-'));

          value = (
            <div>
              {_.find(bidders, (bidder) => bidder.id == bidder_id)?.company}
            </div>
          );
        }

        if (_.includes(['average', 'median', 'min'], column.key)) {
          children[0] = value;

          if (column.key === 'min') {
            value = 'Analytics';
          }
        }

        children[column.frozen ? 1 : 0] = value;

        return React.cloneElement(cells[i], null, children);
      });
    } else if (headerIndex === 1) {
      return _.map(columns, (column, i) => {
        if (column.key === 'name') {
          const levels = {
            2: [],
            3: [],
            4: [],
          };

          _.each(data, (group) => {
            !_.isEmpty(group.children) && levels[2].push(group.id);

            _.each(group.children, (subgroup) => {
              !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);

              _.each(subgroup.children, (item) => {
                !_.isEmpty(item.children) && levels[4].push(item.id);
              });
            });
          });

          let active = {};

          for (let i = 2; i <= 4; i++) {
            active[i] = _.every(levels[i], (id) =>
              _.includes(expandedRows, id)
            );
          }

          return React.cloneElement(
            cells[i],
            null,
            <>
              <div className={styles.placeholder} />
              <div className={styles.masterActions}>
                <Tooltip text='Show group-level'>
                  <span
                    className={classNames(styles.active)}
                    onClick={() => _handleMasterExpand([], true)}
                  >
                    1
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[2]) ? '' : 'Show type-level'}>
                  <span
                    className={classNames(
                      active[2] && styles.active,
                      _.isEmpty(levels[2]) && styles.disabled
                    )}
                    onClick={() => _handleMasterExpand(levels[2], true)}
                  >
                    2
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[3]) ? '' : 'Show item-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && styles.active,
                      _.isEmpty(levels[3]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand([...levels[2], ...levels[3]], true)
                    }
                  >
                    3
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[4]) ? '' : 'Show cost-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && active[4] && styles.active,
                      _.isEmpty(levels[4]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand(
                        [...levels[2], ...levels[3], ...levels[4]],
                        false
                      )
                    }
                  >
                    4
                  </span>
                </Tooltip>
              </div>
              <div className='BaseTable__header-cell-text'>Title</div>
            </>
          );
        } else {
          return React.cloneElement(cells[i], null);
        }
      });
    } else {
      return _.map(columns, (column, i) => {
        let children = _.filter(cells[i].props.children, _.identity);

        let value = '';

        if (_.includes(column.key, 'bidder_subtotal')) {
          const bidder_id = _.last(_.split(column.key, '-'));

          const subtotal = _.sumBy(data, (group) => group.subtotal[bidder_id]);

          const warning_status = _.some(data, (group) =>
            _.get(group.warning_statuses, bidder_id)
          );

          const bidder_subtotals = _.filter(
            _.map(bidders, ({ id }) => {
              return _.sumBy(data, (group) => group.subtotal[id]);
            })
          );

          value = (
            <div
              className={classNames(
                styles.subtotal,
                _.size(bidder_subtotals) > 1 &&
                  classNames(
                    _.min(bidder_subtotals) == subtotal && styles.minValue,
                    _.max(bidder_subtotals) == subtotal && styles.maxValue
                  )
              )}
            >
              {/* {warning_status && (
                <div
                  className={styles.icon}
                  style={{ backgroundColor: icon_statuses['warning']['color'] }}
                >
                  {icon_statuses['warning']['icon']}
                </div>
              )} */}
              <div>{formatMoney(subtotal)}</div>
            </div>
          );
        }

        if (_.includes(['average', 'median', 'min'], column.key)) {
          const subtotals = _.toArray(_.first(data)?.subtotal);

          children[0] = value;

          if (column.key == 'average') {
            value = formatMoney(_.mean(subtotals));
          }

          if (column.key == 'min') {
            value = formatMoney(_.min(subtotals));
          }

          if (column.key == 'median') {
            value = formatMoney(calculateMedian(subtotals));
          }
        }

        children[column.frozen ? 1 : 0] = value;

        return React.cloneElement(cells[i], null, children);
      });
    }
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <BaseTable
            ref={(ref) => {
              setRef(ref);

              tableRef = ref;
            }}
            rowClassName={({ rowData }) =>
              classNames(
                rowData.level === 0 && styles.darkGray,
                rowData.level === 1 && styles.lightGray,
                styles.row,
                rowData.id === focusedId && styles.focused,
                rowData.id === 'UN' && styles.unallocatedGroup
              )
            }
            fixed
            rowProps={({ rowData, rowIndex }) => ({
              //tagName: Row,
              index: rowIndex,
              id: rowData.id,
              onClick: () => setFocusedId(rowData.id),
              level: rowData.level,
              parentId: rowData.parentId,
            })}
            components={{
              ExpandIcon,
            }}
            expandIconProps={({ rowData }) => ({
              depth: rowData.level,
              id: rowData.id,
            })}
            expandColumnKey='name'
            columns={columns}
            data={final_data}
            width={width}
            height={height}
            onExpandedRowsChange={setExpandedRows}
            ignoreFunctionInColumnCompare={false}
            headerHeight={[32, 32, 32]}
            headerRenderer={headerRenderer}
            headerClassName={({ headerIndex }) =>
              classNames(
                headerIndex === 2 && styles.calculations,
                headerIndex === 0 && styles.biddersInfo
              )
            }
            estimatedRowHeight={32}
          />
        );
      }}
    </AutoSizer>
  );
};

export default withRouter(TableOwner);
