import store from "../../../core/store";
import { backendURL } from "../../../core/utils";
import { downloadFile } from "../general/downloader";

export const listDeliveryPlans = (delivery_id) => {
	return store.dispatch({
		action: "table.delivery_plans/LIST_DELIVERY_PLANS",
		method: "GET",
		endpoint: "/deliveries/" + delivery_id + "/plans",
		main: true
	});
};

export const deleteDeliveryPlans = (delivery_id, plan_version_ids) => {
	return store.dispatch({
		action: "table.delivery_plans/DELETE_DELIVERY_PLANS",
		method: "DELETE",
		endpoint: "/deliveries/" + delivery_id + "/plans",
		data: {
			plan_versions: plan_version_ids
		}
	});
};

export const downloadDeliveryPublicPlans = (plan_delivery_id) => {
	return downloadFile(backendURL + `/deliveries/${plan_delivery_id}/download`, [], "POST");
};
