import * as React from "react";
import { Component } from "react";
import { Field, FieldArray, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../../styles/forms/superadmin/add_product_attribute_template_field_wizard/AddProductAttributeTemplateFieldForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { __, mapStateToProps, setTableParams, transformToListPairs } from "../../../core/utils";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import Select from "../../../components/Select";
import classNames from "classnames";
import * as _ from "lodash";
import CloseMediumIcon from "../../../assets/images/close-middle-15x15.svg";
import { listPossibleProductAttributeTemplateGroups } from "../../../redux/actions/table/superadmin/product_attribute_template_fields";
import MultiSelect from "../../../components/MultiSelect";
import Textarea from "../../../components/Textarea";

const data_types = [
    {
        label: __('products.add-product-attribute-template-field.boolean'),
        value: 'boolean'
    },
    {
        label: __('products.add-product-attribute-template-field.string'),
        value: 'string'
    },
    {
        label: __('products.add-product-attribute-template-field.predefined-string-dropdown'),
        value: 'dropdown_string'
    },
    {
        label: __('products.add-product-attribute-template-field.predefined-string-dropdown-multiple'),
        value: 'dropdown_string_multiple'
    },
    {
        label: __('products.add-product-attribute-template-field.numeric'),
        value: 'numeric'
    },
    {
        label: __('products.add-product-attribute-template-field.predefined-numeric-dropdown'),
        value: 'dropdown_numeric'
    },
    {
        label: __('products.add-product-attribute-template-field.predefine-numeric-dropdown-multiple'),
        value: 'dropdown_numeric_multiple'
    },
    {
        label: __('products.add-product-attribute-template-field.color'),
        value: 'color'
    },
    {
        label: __('products.add-product-attribute-template-field.material'),
        value: 'material'
    },
    {
        label: __('products.add-product-attribute-template-field.texture'),
        value: 'texture'
    },
    {
        label: __('products.add-product-attribute-template-field.range'),
        value: 'range'
    },
    {
        label: __('products.add-product-attribute-template-field.selectable-range'),
        value: 'range_selectable'
    }
];

@withRouter
@reduxForm({ form: "superadmin.add_product_attribute_template_field_wizard.add_product_attribute_template_field" })
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_product_attribute_template_field_wizard.add_product_attribute_template_field")(state),
    auth: state.auth,
}))
@subscribe(Localization, "localization")
class AddProductAttributeTemplateFieldForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_groups: []
        }
    }

    componentDidMount() {
        listPossibleProductAttributeTemplateGroups().then(({ response }) => {
            this.setState({ possible_groups: response.data });
        });
    }

    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.values.type != this.props.values.type) {
            this.props.change('unit_id', null);

            if (_.includes(['dropdown_string', 'dropdown_string_multiple', 'dropdown_numeric', 'dropdown_numeric_multiple'], this.props.values.type)) {
                this.props.change('options', [{}]);
            } else {
                this.props.change('options', null);
            }
        }
    }

    render_options = ({ fields }) => {
        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        const { type } = this.props.values;

        return (
            <>
                {fields.map((field, i) => (
                    <div className={classNames(styles.flex, styles.row)} key={i}>
                        {(type == 'dropdown_string' || type == 'dropdown_string_multiple') && <Field name={field} component={Input} label={"Option #" + (i + 1)} localized={languages} />}
                        {(type == 'dropdown_numeric' || type == 'dropdown_numeric_multiple') && <Field numeric decimal name={field} component={Input} label={"Option #" + (i + 1)} localized={languages} />}
                        <div className={styles.remove}>
                            <CloseMediumIcon onClick={() => fields.remove(i)} />
                        </div>
                    </div>
                ))}
                <div className={styles.addRow}>
                    <button type="button" onClick={() => fields.push({})}>{__('button.add-option')}</button>
                </div>
            </>
        )
    }

    render() {
        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        const units = _.sortBy(_.map(this.props.localization.units, unit => ({
            label: unit.symbol,
            value: unit.id,
            tooltip: unit.description,
            group: _.get(this.props.localization.unit_groups, [unit.unit_group_id, 'name', 1]),
            groupPriority: _.get(this.props.localization.unit_groups, [unit.unit_group_id, 'priority']),
        })), 'groupPriority');

        const { type } = this.props.values;
        const { possible_groups } = this.state;

        const product_subgroups = _.map(this.props.localization.product_subgroups, product_subgroup => {
            const product_group = _.get(this.props.localization.product_groups, product_subgroup.product_group_id, {});

            return {
                label: product_subgroup.name,
                value: product_subgroup.id,
                group: [_.get(this.props.localization.product_categories, [product_group.product_category_id, 'name']), product_group.name].join(' / '),
            };
        });

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="group_id" component={Select} searchable noSort label={__('products.add-product-attribute-template-field.group')} options={transformToListPairs(possible_groups)} />
                    <Field name="label" maxLength={50} component={Input} label={__('products.add-product-attribute-template-field.label')} localized={languages} />
                    <Field name="description" component={Textarea} maxLength={500} rows={1} maxRows={10} label={__('products.add-product-attribute-template-field.description')} localized={languages} />
                    <Field name="norm" component={Input} label={__('products.add-product-attribute-template-field.norm')} />
                    <div className={classNames(styles.flex, styles.type)}>
                        <div className={styles.f2}>
                            <Field name="type" component={Select} noSort label={__('products.add-product-attribute-template-field.type')} options={data_types} />
                        </div>
                        {_.includes(['numeric', 'dropdown_numeric', 'dropdown_numeric_multiple', 'range', 'range_selectable'], type) && <div className={styles.f1}>
                            <Field name="unit_id" component={Select} searchable noSort label={__('products.add-product-attribute-template-field.unit')} options={units} />
                        </div>}
                    </div>
                    <Field name="product_subgroup_ids" searchable component={MultiSelect} label={__('products.add-product-attribute-template-field.assigned-product-subgroups')} options={product_subgroups} />
                    {_.includes(['dropdown_string', 'dropdown_string_multiple', 'dropdown_numeric', 'dropdown_numeric_multiple'], type) && <FieldArray name="options" component={this.render_options} />}
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_product_attribute_template_fields', {
                        add_product_attribute_template_field_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddProductAttributeTemplateFieldForm;
