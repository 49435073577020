import React, {Component, PureComponent} from 'react'
import color from 'react-color/lib/helpers/color'

export const ColorWrap = (Picker) => {
    class ColorPicker extends (PureComponent || Component) {
        constructor(props) {
            super(props);

            this.state = {
                ...color.toState(props.color, 0),
            }
        }

        handleChange = (data, event) => {
            const isValidColor = color.simpleCheckForValidColor(data)
            if (isValidColor) {
                const colors = color.toState(data, data.h || this.state.oldHue)
                this.setState(colors)
                this.props.onChangeComplete && this.props.onChangeComplete(colors, event)
                this.props.onChange && this.props.onChange(colors, event)
            }
        }

        handleSwatchHover = (data, event) => {
            const isValidColor = color.simpleCheckForValidColor(data)
            if (isValidColor) {
                const colors = color.toState(data, data.h || this.state.oldHue)
                this.setState(colors)
                this.props.onSwatchHover && this.props.onSwatchHover(colors, event)
            }
        }

        render() {
            const optionalEvents = {}
            if (this.props.onSwatchHover) {
                optionalEvents.onSwatchHover = this.handleSwatchHover
            }

            return (
                <Picker
                    { ...this.props }
                    { ...this.state }
                    onChange={ this.handleChange }
                    { ...optionalEvents }
                />
            )
        }
    }

    ColorPicker.propTypes = {
        ...Picker.propTypes,
    };

    ColorPicker.defaultProps = {
        ...Picker.defaultProps,
        color: {
            h: 204,
            s: 0.70,
            l: 0.5,
            a: 1,
        },
    };

    return ColorPicker
}

export default ColorWrap;