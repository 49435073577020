import _ from 'lodash';

export const createTreeStructure = (arr = []) => {
  const tree = [];
  const lookup = {};

  // First map the nodes of the array to an object -> create a hash table.
  arr.forEach((element) => {
    const key = `${element.type}:${element.id}`;
    lookup[key] = element;
    lookup[key]['items'] = [];
  });

  Object.keys(lookup).forEach((key) => {
    if (lookup.hasOwnProperty(key)) {
      const element = lookup[key];
      // If the element is not at the root level, add it to its parent array of children.
      if (element.parent_id) {
        lookup[`${element.parent_type}:${element.parent_id}`]['items'].push(
          element
        );
      } else {
        // If the element is at the root level, add it to first level elements array.
        tree.push(element);
      }
    }
  });

  return tree;
};

const getFilteredTree = (nodes, query, labelKey, itemsKey) => {
  return _.reduce(
    nodes,
    (result, node) => {
      const newNode = { ...node };

      const sizeOfItems = _.size(_.get(newNode, itemsKey)) > 0;
      if (sizeOfItems > 0) {
        newNode[itemsKey] = getFilteredTree(
          _.get(newNode, itemsKey),
          query,
          labelKey,
          itemsKey
        );
      }

      const sizeOfFilteredItems = _.size(_.get(newNode, itemsKey)) > 0;
      const isMatchingQuery = _.includes(
        _.isObject(_.get(newNode, labelKey))
          ? _.get(newNode, labelKey).props.children?.toLowerCase()
          : _.get(newNode, labelKey)?.toLowerCase(),
        query?.toLowerCase()
      );
      if (isMatchingQuery || sizeOfFilteredItems) {
        result.push(newNode);
      }

      return result;
    },
    []
  );
};

export const getFilteredData = (
  data,
  query,
  labelKey = 'display_name',
  itemsKey = 'items'
) => {
  if (!query) {
    return data;
  }

  return _.transform(
    _.keys(data),
    (acc, key) => {
      acc[key] = getFilteredTree(data[key], query, labelKey, itemsKey);
      return acc;
    },
    {}
  );
};
