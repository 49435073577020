import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/TasksFlyout.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import TaskDecisionIcon from '../../assets/images/task-decision-20x20.svg';
import TaskTodoIcon from '../../assets/images/task-todo-20x20.svg';
import TaskRfiIcon from '../../assets/images/task-rfi-20x20.svg';
import TaskFyiIcon from '../../assets/images/task-fyi-20x20.svg';
import TaskIssueIcon from '../../assets/images/task-issue-20x20.svg';
import TaskMilestoneIcon from '../../assets/images/task-milestone-20x20.svg';
import classNames from 'classnames';
import Properties from './Properties';
import History from './History';
import Comments from './Comments';
import Checklist from './Checklist';
import RelatedTasks from './RelatedTasks';
import * as _ from 'lodash';
import EditTaskToolbarForm from '../../forms/tasks/EditTaskToolbarForm';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import { __, setTableParams } from '../../core/utils';
import {
  listAllTasks,
  readTaskHistory,
  updateTaskToolbar,
} from '../../redux/actions/table/all_tasks';
import Files from './Files';
import Meetings from './Meetings';
import Tooltip from '../../components/Tooltip';
import AddToMeetingIcon from '../../assets/images/add-to-meeting-16x16.svg';
import PrintIcon from '../../assets/images/print-16x16.svg';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';

const mapStateToProps = (state) => {
  return {
    tab_opened: _.get(state.table, ['all_tasks', 'tab']),
    clicked_row: _.get(state.table, ['all_tasks', 'clicked_row']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_tasks/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
class TasksFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed_tabs: [], //fix for scrollSpy not working on init
      highlighted_tab: null,
      history_expanded: false,
    };

    this.highlight_timeout = null;

    this.tabs = [
      'properties',
      'comments',
      'files',
      'checklist',
      'meetings',
      'history',
      'related-tasks',
    ];

    this.tab_refs = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //handle change flyout task ID
    if (
      _.get(prevProps, 'data.id') &&
      _.get(this.props, 'data.id') &&
      _.get(prevProps, 'data.id') != _.get(this.props, 'data.id')
    ) {
      this.setState({
        history_expanded: false,
        collapsed_tabs: _.merge(this.state.collapsed_tabs, ['history']),
      });
    }
  }

  componentDidMount() {
    scrollSpy.update();

    this.setState({
      collapsed_tabs: ['history'],
    });

    if (this.props.tab_opened) {
      setTimeout(() => {
        this.tab_refs[this.props.tab_opened] &&
          this.tab_refs[this.props.tab_opened].click();

        setTableParams('all_tasks', {
          tab: undefined,
        });
      }, 500);
    }
  }

  @autobind
  _lazyLoadTaskHistory() {
    if (!this.state.history_expanded) {
      readTaskHistory(this.props.data.id);

      this.setState({
        history_expanded: true,
      });
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    if (tab == 'history') {
      this._lazyLoadTaskHistory();
    }

    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _handleSubmit(formData) {
    updateTaskToolbar(formData.id, _.omit(formData, ['id'])).then(() => {
      this.props.match.params.stage &&
        listAllTasks(this.props.match.params.stage);

      this.props.readAction(formData.id);

      setNotification(__('tasks.properties.saved'));
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  render() {
    const { data, readAction } = this.props;

    const { collapsed_tabs, highlighted_tab } = this.state;

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          props.target == 'history' && this._lazyLoadTaskHistory();

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    const files_size =
      _.size(data.files) +
      _.size(
        _.transform(
          data.comments,
          (files, comment) => {
            _.each(comment.files, (file) => {
              files.push(file);
            });
          },
          []
        )
      );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              {data.type == 'rfi' && <TaskRfiIcon />}
              {data.type == 'fyi' && <TaskFyiIcon />}
              {data.type == 'decision' && <TaskDecisionIcon />}
              {data.type == 'todo' && (
                <TaskTodoIcon className={styles.todoIcon} />
              )}
              {data.type == 'milestone' && <TaskMilestoneIcon />}
              {data.type == 'issue' && <TaskIssueIcon />}
              <span>
                <strong>{'T-' + data.identifier}: </strong>
                {data.title}
              </span>
            </div>
            <CloseBigIcon
              onClick={() =>
                setTimeout(
                  () =>
                    this.props.actions.setTableParams({
                      clicked_row: undefined,
                    }),
                  100
                )
              }
            />
          </div>
          <div className={styles.actions}>
            <div className={styles.actionWrapper}>
              <Tooltip text={__('tasks.tooltip.icon.add-task-to-meeting')}>
                <AddToMeetingIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('all_meetings', {
                      meeting_from_tasks: [data.id],
                      stage_id: data.stage.id,
                    });
                  }}
                />
              </Tooltip>
            </div>
            <div className={styles.actionWrapper}>
              <Tooltip text={__('tasks.tooltip.icon.export-tasks-pdf')}>
                <PrintIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('all_tasks', {
                      print_tasks_report_wizard: [data.id],
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.toolbar}>
          <EditTaskToolbarForm
            data={data}
            onSubmit={this._handleSubmit}
            initialValues={{
              ..._.pick(data, ['id', 'status', 'priority']),
              visibility: _.toString(data.visibility),
              date: {
                start: data.start_date,
                end: data.end_date,
                allDay: data.all_day,
              },
            }}
          />
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>{__('task.flyout.properties')}</Tab>
            <Tab target='related-tasks'>
              {__('task.flyout.related-tasks')}{' '}
              {!_.isEmpty(data.related_tasks) &&
                ' (' + _.size(data.related_tasks) + ')'}
            </Tab>
            <Tab target='comments'>
              {__('task.flyout.comments')}{' '}
              {!_.isEmpty(data.comments) && ' (' + _.size(data.comments) + ')'}
            </Tab>
            <Tab target='files'>
              {__('task.flyout.files')}
              {!!files_size && ' (' + files_size + ')'}
            </Tab>
            <Tab target='checklist'>
              {__('task.flyout.checklist')}{' '}
              {!_.isEmpty(data.checklist) &&
                ' (' + _.size(data.checklist) + ')'}
            </Tab>
            <Tab target='meetings'>
              {__('task.flyout.meetings')}{' '}
              {!_.isEmpty(data.meetings) && ' (' + _.size(data.meetings) + ')'}
            </Tab>
            <Tab target='history'>{__('task.flyout.history')}</Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('task.flyout.properties')}
            </div>
            <Properties
              collapsed={_.includes(collapsed_tabs, 'properties')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='related-tasks'
            className={classNames(
              styles.section,
              highlighted_tab == 'related-tasks' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('related-tasks')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'related-tasks') &&
                    styles.collapsed
                )}
              />
              {__('task.flyout.related-tasks')}
              {!_.isEmpty(data.related_tasks) &&
                ' (' + _.size(data.related_tasks) + ')'}
            </div>
            <RelatedTasks
              collapsed={_.includes(collapsed_tabs, 'related-tasks')}
              data={data}
            />
          </Element>
          <Element
            name='comments'
            className={classNames(
              styles.section,
              highlighted_tab == 'comments' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('comments')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'comments') && styles.collapsed
                )}
              />
              {__('task.flyout.comments')}
              {!_.isEmpty(data.comments) && ' (' + _.size(data.comments) + ')'}
            </div>
            <Comments
              collapsed={_.includes(collapsed_tabs, 'comments')}
              data={data}
              readAction={readAction}
              key={data.id}
            />
          </Element>
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {__('task.flyout.files')}
              {!!files_size && ' (' + files_size + ')'}
            </div>
            <Files
              collapsed={_.includes(collapsed_tabs, 'files')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='checklist'
            className={classNames(
              styles.section,
              highlighted_tab == 'checklist' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('checklist')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'checklist') && styles.collapsed
                )}
              />
              {__('task.flyout.checklist')}
              {!_.isEmpty(data.checklist) &&
                ' (' + _.size(data.checklist) + ')'}
            </div>
            <Checklist
              collapsed={_.includes(collapsed_tabs, 'checklist')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='meetings'
            className={classNames(
              styles.section,
              highlighted_tab == 'meetings' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('meetings')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'meetings') && styles.collapsed
                )}
              />
              {__('task.flyout.meetings')}
              {!_.isEmpty(data.meetings) && ' (' + _.size(data.meetings) + ')'}
            </div>
            <Meetings
              collapsed={_.includes(collapsed_tabs, 'meetings')}
              data={data}
            />
          </Element>
          <Element
            name='history'
            className={classNames(
              styles.section,
              highlighted_tab == 'history' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('history')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'history') && styles.collapsed
                )}
              />
              {__('task.flyout.history')}
            </div>
            <History
              collapsed={_.includes(collapsed_tabs, 'history')}
              data={data}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default TasksFlyout;
