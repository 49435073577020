import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm, getFormValues } from 'redux-form';
import styles from '../../styles/forms/edit_product_attribute_group_field_parts_form/EditProductAttributeGroupFieldPartsForm.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  resetForm,
  setTableParams,
} from '../../core/utils';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import PlusMiddleIcon from '../../assets/images/plus-middle-15x15.svg';
import Select from '../../components/Select';
import MultiSelect from '../../components/MultiSelect';
import { listPossibleSites } from '../../redux/actions/table/buildings';
import {
  addProductAttributesGroupFieldParts,
  deleteProductAttributesGroupFieldPart,
  listPossibleProductAttributeGroupFieldParts,
  readProduct,
  toggleProductAttributesGroupFieldPartValue,
} from '../../redux/actions/table/products';
import { setNotification } from '../../redux/actions/general/notification';
import {
  listMyCompanyColors,
  listMyCompanyMaterials,
} from '../../redux/actions/companies';
import Tooltip from '../../components/Tooltip';
import Image from '../../components/Image';

@reduxForm({
  form: 'edit_product_attribute_group_field_parts_form.edit_product_attribute_group_field_parts',
  initialValues: {},
})
@mapStateToProps((state, props) => ({
  store: _.get(state, 'table.products'),
  attribute_field_id:
    state.table.products.edit_product_attribute_group_field_values_wizard,
  application_language_id: state.auth.language_id,
  values: getFormValues(props.form)(state),
}))
@subscribe(Localization, 'localization')
@withRouter
class EditProductAttributeGroupFieldPartsForm extends Component {
  state = {
    possible_parts: [],
    collapsed_attributes: [],
    my_company_colors: [],
    my_company_materials: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { attribute_field_id } = this.props;

    if (!prevProps.submitSucceeded && this.props.submitSucceeded) {
      listPossibleProductAttributeGroupFieldParts(attribute_field_id).then(
        ({ response }) => {
          this.setState({
            possible_parts: _.filter(
              response.data,
              (item) => item.category == 'variable'
            ),
          });
        }
      );
    }
  }

  componentDidMount() {
    const { attribute_field_id } = this.props;

    listPossibleProductAttributeGroupFieldParts(attribute_field_id).then(
      ({ response }) => {
        this.setState({
          possible_parts: _.filter(
            response.data,
            (item) => item.category == 'variable'
          ),
        });
      }
    );

    listMyCompanyColors().then(({ response }) => {
      this.setState({ my_company_colors: response.data });
    });

    listMyCompanyMaterials().then(({ response }) => {
      this.setState({ my_company_materials: response.data });
    });
  }

  @autobind
  _handleDeletePart(id) {
    const { attribute_field_id } = this.props;
    const { flyout } = this.props.store;

    deleteProductAttributesGroupFieldPart(attribute_field_id, id).then(() => {
      setNotification(
        'Product attribute variations have been successfully deleted.'
      );

      listPossibleProductAttributeGroupFieldParts(attribute_field_id).then(
        ({ response }) => {
          this.setState({
            possible_parts: _.filter(
              response.data,
              (item) => item.category == 'variable'
            ),
          });
        }
      );

      readProduct(flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });
      });
    });
  }

  @autobind
  _toggleCollapseAttribute(id) {
    const { collapsed_attributes } = this.state;

    this.setState({
      collapsed_attributes: _.xor(collapsed_attributes, [id]),
    });
  }

  _getColorNameAndHex = (id) => {
    const color =
      _.get(this.props.localization.colors, [id]) ||
      _.find(this.state.my_company_colors, (color) => color.id == id);

    return {
      label:
        (color?.name &&
          getLocalized(color?.name, this.props.store.language_id)) +
        (color?.code ? ` · ${color.code}` : ''),
      hex: color?.hex,
    };
  };

  _getMaterialNameAndThumbnailUrl = (id) => {
    const material =
      _.get(this.props.localization.materials, [id]) ||
      _.find(this.state.my_company_materials, (material) => material.id == id);

    return {
      label:
        material?.name &&
        getLocalized(material?.name, this.props.store.language_id),
      thumbnail_url: material?.thumbnail_url,
      preview_url: material?.preview_url,
    };
  };

  _getTextureNameAndThumbnailUrl = (id) => {
    const texture = _.get(this.props.localization.textures, id) || {};

    return {
      label: [
        texture.code,
        getLocalized(texture.name, this.props.store.language_id),
      ].join(' '),
      thumbnail_url: texture?.thumbnail_url,
      preview_url: texture?.preview_url,
    };
  };

  @autobind
  _togglePartValueInUse(id) {
    const { attribute_field_id } = this.props;
    const { flyout } = this.props.store;

    toggleProductAttributesGroupFieldPartValue(attribute_field_id, id).then(
      () => {
        setNotification('Product attribute variations have been saved.');

        readProduct(flyout.id).then(({ response }) => {
          setTableParams('products', {
            flyout: response.data,
          });
        });
      }
    );
  }

  render() {
    const { store, attribute, application_language_id, values, type } =
      this.props;
    const { possible_parts, collapsed_attributes } = this.state;
    const { units, product_attribute_fields } = this.props.localization;
    const { language_id, flyout } = store;

    const parts = _.map(possible_parts, (part) => ({
      value: part.id,
      label: _.filter(
        [part.model, getLocalized(part.description, application_language_id)],
        _.identity
      ).join(' / '),
    }));

    const selected_part = values.part_id
      ? _.find(possible_parts, ['id', values.part_id])
      : {};

    const possible_attributes = _.transform(
      selected_part.attribute_groups,
      (attributes, group) => {
        _.each(group.fields, (field) => {
          const attribute = field.template_field_id
            ? _.get(product_attribute_fields, field.template_field_id, {})
            : field;

          let label = getLocalized(attribute.label, application_language_id);
          label += !field.template_field_id ? '*' : '';

          attributes.push({
            group: getLocalized(group.name, application_language_id),
            value: field.id,
            label,
          });
        });
      },
      []
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.note1}>
          <span>
            {__(
              'products.attributes-form.edit-variations.note.add-part-on-part-tab-if-want-variate-by-part'
            )}
          </span>
        </div>
        {!_.isEmpty(attribute.parts) && (
          <div className={styles.list}>
            {_.map(attribute.parts, (part_attribute) => {
              const fetched_attribute = part_attribute.template_field_id
                ? _.get(
                    product_attribute_fields,
                    part_attribute.template_field_id,
                    {}
                  )
                : part_attribute;

              let label = getLocalized(
                fetched_attribute.label,
                application_language_id
              );
              label += !part_attribute.template_field_id ? '*' : '';

              let unit = '';

              if (
                _.includes(
                  [
                    'numeric',
                    'dropdown_numeric',
                    'dropdown_numeric_multiple',
                    'range',
                    'range_selectable',
                  ],
                  part_attribute.type
                ) &&
                part_attribute.unit_id
              ) {
                unit = ' ' + _.get(units, [part_attribute.unit_id, 'symbol']);
              }

              const values = _.reject(
                part_attribute.values,
                (value) => _.size(value.value) === 0
              );

              return (
                <>
                  <div className={styles.part}>
                    <ArrowDownMiddleIcon
                      className={classNames(
                        _.includes(collapsed_attributes, part_attribute.id) &&
                          styles.collapsed
                      )}
                      onClick={() =>
                        this._toggleCollapseAttribute(part_attribute.id)
                      }
                    />
                    <span>
                      {part_attribute.product.model} &middot; {label} &middot;{' '}
                      {getLocalized(
                        part_attribute.group,
                        application_language_id
                      )}
                    </span>
                    <div className={styles.delete}>
                      <CloseMediumIcon
                        onClick={() =>
                          this._handleDeletePart(part_attribute.id)
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={classNames(
                      styles.attribute,
                      _.includes(collapsed_attributes, part_attribute.id) &&
                        styles.collapsed
                    )}
                  >
                    {_.map(values, ({ id, in_use, inherit_in_use, value }) => {
                      let transformed_value = '';
                      let icon;

                      switch (type) {
                        case 'boolean':
                          transformed_value = _.toInteger(value)
                            ? __('products.attributes-form.boolean.yes')
                            : __('products.attributes-form.boolean.no');
                          break;
                        case 'dropdown_string_multiple':
                          transformed_value = _.map(
                            JSON.parse(value),
                            (one_value) => {
                              return getLocalized(
                                _.get(
                                  _.find(
                                    _.find(product_attribute_fields, [
                                      'id',
                                      part_attribute.template_field_id,
                                    ]).options,
                                    ['id', _.parseInt(one_value)]
                                  ),
                                  'value'
                                ),
                                language_id
                              );
                            }
                          ).join(', ');
                          break;
                        case 'dropdown_numeric_multiple':
                          transformed_value = _.map(
                            JSON.parse(value),
                            (one_value) => {
                              return (
                                getLocalized(
                                  _.get(
                                    _.find(
                                      _.find(product_attribute_fields, [
                                        'id',
                                        part_attribute.template_field_id,
                                      ]).options,
                                      ['id', _.parseInt(one_value)]
                                    ),
                                    'value'
                                  ),
                                  language_id
                                ) + unit
                              );
                            }
                          ).join(', ');
                          break;
                        case 'dropdown_string':
                          transformed_value = getLocalized(
                            _.get(
                              _.find(
                                _.find(product_attribute_fields, [
                                  'id',
                                  part_attribute.template_field_id,
                                ]).options,
                                ['id', _.parseInt(value)]
                              ),
                              'value'
                            ),
                            language_id
                          );
                          break;
                        case 'dropdown_numeric':
                          transformed_value =
                            getLocalized(
                              _.get(
                                _.find(
                                  _.find(product_attribute_fields, [
                                    'id',
                                    part_attribute.template_field_id,
                                  ]).options,
                                  ['id', _.parseInt(value)]
                                ),
                                'value'
                              ),
                              language_id
                            ) + unit;
                          break;
                        case 'string':
                          transformed_value = getLocalized(value, language_id);
                          break;
                        case 'numeric':
                          transformed_value = value + unit;
                          break;
                        case 'range':
                          transformed_value = _.join(value, ' ~ ') + unit;
                          break;
                        case 'range_selectable':
                          transformed_value = _.join(value, ' ~ ') + unit;
                          break;
                        case 'color':
                          transformed_value =
                            this._getColorNameAndHex(value).label;
                          icon = (
                            <Tooltip
                              placement='left'
                              text={
                                <span
                                  className={styles.thumbPreview}
                                  style={{
                                    background:
                                      '#' + this._getColorNameAndHex(value).hex,
                                  }}
                                />
                              }
                              html={true}
                              dark={true}
                            >
                              <div
                                className={styles.colorThumb}
                                style={{
                                  background:
                                    '#' + this._getColorNameAndHex(value).hex,
                                }}
                              />
                            </Tooltip>
                          );
                          break;
                        case 'material':
                          transformed_value =
                            this._getMaterialNameAndThumbnailUrl(value).label;

                          icon = (
                            <Tooltip
                              placement='left'
                              text={
                                <Image
                                  className={styles.thumbPreview}
                                  src={
                                    this._getMaterialNameAndThumbnailUrl(value)
                                      .preview_url
                                  }
                                />
                              }
                              html={true}
                              dark={true}
                            >
                              <Image
                                className={styles.materialThumb}
                                src={
                                  this._getMaterialNameAndThumbnailUrl(value)
                                    .thumbnail_url
                                }
                              />
                            </Tooltip>
                          );

                          break;
                        case 'texture':
                          transformed_value =
                            this._getTextureNameAndThumbnailUrl(value).label;

                          icon = (
                            <Tooltip
                              placement='left'
                              text={
                                <Image
                                  className={styles.thumbPreview}
                                  src={
                                    this._getTextureNameAndThumbnailUrl(value)
                                      .preview_url
                                  }
                                />
                              }
                              html={true}
                              dark={true}
                            >
                              <Image
                                className={styles.textureThumb}
                                src={
                                  this._getTextureNameAndThumbnailUrl(value)
                                    .thumbnail_url
                                }
                              />
                            </Tooltip>
                          );
                          break;
                      }

                      return (
                        <div className={styles.row}>
                          {icon}

                          {inherit_in_use ? (
                            in_use ? (
                              <Tooltip text="Mark as 'not in use'">
                                <CheckedIcon
                                  className={styles.checked}
                                  onClick={() => this._togglePartValueInUse(id)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip text="Mark as 'in use'">
                                <UncheckedIcon
                                  className={styles.unchecked}
                                  onClick={() => this._togglePartValueInUse(id)}
                                />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip text="Product attribute variation value has been marked as 'not in use' by the manufacturer">
                              <UncheckedIcon
                                className={classNames(
                                  styles.unchecked,
                                  styles.disabled
                                )}
                              />
                            </Tooltip>
                          )}
                          <span>{transformed_value}</span>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        )}
        <div
          className={classNames(
            styles.add,
            !values.part_id && styles.noPart,
            _.isEmpty(values.attributes) && styles.noAttributes
          )}
        >
          <Field
            name='part_id'
            component={Select}
            searchable
            label='Part'
            options={parts}
          />
          <Field
            name='attributes'
            component={MultiSelect}
            disabled={!values.part_id}
            searchable
            label='Attributes'
            options={possible_attributes}
          />
          <Button
            submit
            lightBlue
            disabled={!values.part_id || _.isEmpty(values.attributes)}
            rightIcon={PlusMiddleIcon}
            leftText='Add'
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightBlue
            medium
            right
            middleText={__('button.done')}
            onClick={() =>
              setTableParams('products', {
                edit_product_attribute_group_field_values_wizard: undefined,
              })
            }
          />
        </div>
      </Form>
    );
  }
}

export default EditProductAttributeGroupFieldPartsForm;
