import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/products/Accessories.scss";
import * as _ from "lodash";
import classNames from "classnames";
import { __, getLocalized, redirect, mapStateToProps, setTableParams } from "../../core/utils";
import Tooltip from "../../components/Tooltip";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";
import autobind from "autobind-decorator";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import { deleteProductAccessory, readProduct, reorderProductAccessoryItems } from "../../redux/actions/table/products";
import TableNA from "../../components/TableNA";
import { ReactSortable } from "react-sortablejs";
import GrabberIcon from "../../assets/images/grabber-16x16.svg";
import Image from "../../components/Image";
import DefaultImage from "../../assets/images/default.png";

@mapStateToProps((state) => ({
	application_language_id: state.auth.language_id,
	flyout: state.table.products.flyout
}))
@subscribe(Localization, "localization")
class Accessory extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: []
		};
	}

	@autobind
	_deleteAccessory(e, accessory_id) {
		e.stopPropagation();

		showAlertbox({
			color: "red",
			title: __("general.alert.are-you-sure"),
			description: __("products.accessory.are-you-sure-delete-part"),
			buttons: [
				{
					color: "lightGray",
					text: __("general.alert.no-close"),
					onClick: "close"
				},
				{
					color: "gray",
					text: __("general.alert.yes-delete"),
					onClick: () =>
						deleteProductAccessory(this.props.flyout.id, {
							accessory_id
						}).then(() =>
							readProduct(this.props.flyout.id).then(({ response }) => {
								hideAlertbox();

								setNotification(__("products.parts.part-deleted"));

								setTableParams("products", {
									flyout: response.data
								});
							})
						)
				}
			]
		});
	}

	@autobind
	_changeOrder(accessories) {
		setTableParams("products", {
			flyout: {
				...this.props.flyout,
				accessories: _.map(accessories, (item, i) => ({
					...item,
					order: i
				}))
			}
		});
	}

	render() {
		const { collapsed, flyout } = this.props;
		const sorted_list = _.sortBy(flyout.accessories, "order");

		return (
			<div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
				{_.isEmpty(sorted_list) ? (
					<div className={styles.empty}>{__("products.accessory.no-accessories")}</div>
				) : (
					<>
						<div className={styles.header}>
							<span className={styles.description}>{__("products.accessory.description")}</span>
							<span className={styles.actions} />
						</div>
						<ReactSortable
							list={sorted_list}
							setList={this._changeOrder}
							onEnd={() =>
								reorderProductAccessoryItems(flyout.id, _.map(sorted_list, "id")).then(() =>
									readProduct(flyout.id).then(({ response }) => {
										setTableParams("products", {
											flyout: response.data
										});
									})
								)
							}
							animation={200}
							delayOnTouchStart={true}
							handle={"." + styles.grabber}
						>
							{_.map(sorted_list, (accessory, i) => {
								return (
									<div className={styles.row} key={i}>
										<div className={styles.grabber}>
											<GrabberIcon />
										</div>
										<Image default={DefaultImage} src={accessory.thumb_url} className={styles.icon} />

										<Tooltip placement="left" text={getLocalized(accessory.description, this.props.application_language_id)}>
											<div
												className={styles.description}
												onClick={() =>
													readProduct(accessory.id).then(({ response }) => {
														setTableParams("products", {
															flyout: response.data
														});
													})
												}
											>
												{_.filter([accessory.model, getLocalized(accessory.description, this.props.application_language_id)], _.identity).join(" / ") || <TableNA />}
											</div>
										</Tooltip>
										<div className={styles.actions}>
											<Tooltip text={__("products.parts.delete-accessory")}>
												<CloseMiddleIcon onClick={(e) => this._deleteAccessory(e, accessory.id)} />
											</Tooltip>
										</div>
									</div>
								);
							})}
						</ReactSortable>
					</>
				)}
				<div className={styles.addRow}>
					<button
						onClick={() =>
							setTableParams("products", {
								add_accessory_to_product_wizard: true
							})
						}
					>
						{__("button.add-accessory")}
					</button>
				</div>
			</div>
		);
	}
}

export default Accessory;
