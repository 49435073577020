import React, { Component } from 'react';
import styles from '../styles/components/Select.scss';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import SearchIcon from '../assets/images/search-15x15.svg';
import { __, mapStateToProps } from '../core/utils';
import ReactHoverObserver from 'react-hover-observer';
import * as _ from 'lodash';
import {
  registerMultieditField,
  unregisterMultieditField,
} from '../redux/actions/general/multiedit';
import Tooltip from './Tooltip';
import FieldComponent from './FieldComponent';
import { isMobileOnly } from 'react-device-detect';
import Button from './Button';
import OutsideClickWrapper from './OutsideClickWrapper';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import EditIcon from '../assets/images/edit-16x16.svg';

/**
 * Component Props:
 * - options
 * - searchable
 * - dropdown
 * - wrapper
 * - icon
 * - noSort
 * - top
 */
@FieldComponent
@mapStateToProps((state) => ({
  multiedit: state.general.multiedit,
}))
class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
    };
  }

  @autobind
  _handleClick() {
    if (this.props.meta.active) {
      this._handleClose();
    } else {
      this._handleOpen();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.props.input.onFocus();
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _chooseOption(value) {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );

    multiedit_enabled &&
      registerMultieditField(this.props.meta.form, this.props.input.name);

    this.props.input.onChange(value);
    this._handleClose();
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _handleKeyDown(e) {
    const { query } = this.state;

    const available_options = _.filter(
      this.props.options,
      ({ value }) => !_.includes(this.props.input.value, value)
    );
    const results = _.filter(
      available_options,
      (item) =>
        _.isEmpty(this.state, 'query') ||
        _.includes(_.toLower(item.label), _.toLower(this.state.query))
    );

    if (e.keyCode == 13) {
      e.stopPropagation();
      e.preventDefault();

      !_.isEmpty(query) &&
        !_.isEmpty(results) &&
        !_.first(results).disabled &&
        this._chooseOption(_.first(results).value);
    }
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    this.refs.search.focus();
  }

  @autobind
  _addOption() {
    this.props.callbackSetNewValue(this.state.query);

    this._handleClose();
  }

  @autobind
  _renderNestedList(sorted_results, depth = 0) {
    return _.map(
      sorted_results,
      ({
        label,
        tooltip,
        value,
        icon,
        color,
        markedValue,
        children,
        parentId,
      }) => {
        return (
          <>
            {!_.isEmpty(children) ? (
              <>
                <div
                  className={styles.group}
                  key={value}
                  style={{ paddingLeft: `${depth * 10}px` }}
                >
                  <div className={styles.heading}>{label}</div>
                </div>

                {this._renderNestedList(children, depth + 1)}
              </>
            ) : (
              <ReactHoverObserver key={value}>
                {({ isHovering }) => (
                  <Tooltip placement='right' text={tooltip || label}>
                    <div
                      onClick={() => this._chooseOption(value)}
                      className={classNames(
                        styles.option,
                        markedValue &&
                          this.props.input.value != value &&
                          styles.markedValue,
                        this.props.input.value == value && styles.active
                      )}
                      style={{ paddingLeft: `${depth * 10}px` }} // Add padding based on depth
                    >
                      {icon}
                      <span className={styles.title}>{label || ''}</span>
                      <span
                        className={styles.dot}
                        style={
                          color && {
                            background: color,
                            boxShadow:
                              'inset 0 0 0 2px #FFF' +
                              (this.props.input.value == value
                                ? ', 0 0 0 1px ' + color
                                : ''),
                          }
                        }
                      />
                    </div>
                  </Tooltip>
                )}
              </ReactHoverObserver>
            )}
          </>
        );
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.meta.active != this.props.meta.active) {
      this.setState(
        {
          query: '',
        },
        () => {
          this.props.recalculateOffset();
        }
      );
    }

    if (prevState.query != this.state.query) {
      this.props.recalculateOffset();
    }
  }

  render() {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );
    const multiedited =
      multiedit_enabled &&
      _.includes(
        _.get(this.props.multiedit, [this.props.meta.form, 'fields']),
        this.props.input.name
      );

    const {
      options,
      wrapper,
      dropdown,
      searchable,
      icon,
      noSort,
      raw,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
      labelTooltip,
      label,
      redirectTo,
      callbackSetNewValue,
      noneValue,
      nestedList,
    } = this.props;

    const { query } = this.state;

    const filterNestedOptions = (options, query) => {
      const filterRecursively = (items) => {
        const filteredItems = [];

        _.each(items, (item) => {
          const newItem = _.cloneDeep(item);
          const children = _.get(newItem, 'children', []);

          if (
            _.includes(_.toLower(_.get(newItem, 'label', '')), _.toLower(query))
          ) {
            // If the item itself matches the query, push it to filteredItems
            filteredItems.push(newItem);
          } else {
            // Recursively filter children
            const filteredChildren = filterRecursively(children);
            if (filteredChildren.length > 0) {
              // If any of its children match the query, attach them to the newItem
              newItem.children = filteredChildren;
              // Push newItem to filteredItems
              filteredItems.push(newItem);
            }
          }
        });

        return filteredItems;
      };

      return filterRecursively(options);
    };

    const findNestedOption = (options, targetValue) => {
      let foundOption = null;

      _.each(options, (option) => {
        if (
          !foundOption &&
          option.value?.toString() === targetValue?.toString()
        ) {
          foundOption = option;
        }
        if (!foundOption && option.children) {
          foundOption = findNestedOption(option.children, targetValue);
        }
      });

      return foundOption;
    };

    let results = _.filter(
      options,
      (item) =>
        _.isEmpty(this.state, 'query') ||
        _.includes(_.toLower(item.label), _.toLower(this.state.query)) ||
        (item.group &&
          _.includes(_.toLower(item.group), _.toLower(this.state.query)))
    );

    results = nestedList
      ? filterNestedOptions(options, this.state.query)
      : results;

    let sorted_results = noSort
      ? results
      : _.sortBy(results, (item) => _.toLower(item.label));
    sorted_results = _.reject(sorted_results, ['disabled', true]);

    let active_item =
      this.props.input.value &&
      _.find(
        options,
        (option) =>
          option.value?.toString() == this.props.input.value?.toString()
      );

    active_item = nestedList
      ? findNestedOption(options, this.props.input.value)
      : active_item;

    const active_icon = _.get(active_item, 'icon');

    let has_placeholder_prop = false;
    let placeholder;

    if (this.props.input.value) {
      placeholder = _.get(active_item, 'label');
    } else if (multiedit_enabled) {
      if (multiedited) {
        placeholder = '';
      } else {
        placeholder = '-- no change --';
      }
    } else if (this.props.placeholder) {
      placeholder = this.props.placeholder;
      has_placeholder_prop = true;
    }

    if (noneValue && _.size(sorted_results) > 0) {
      const noneValueObject = {
        label: __('select.none-value'),
        value: null,
      };

      sorted_results.unshift(noneValueObject);
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          wrapper && styles.hasWrapper,
          dropdown && styles.dropdownStyle,
          this.props.input.value?.toString().length == 0 && styles.placeholder,
          this.props.meta.active && styles.focus,
          this.props.center && styles.center,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          this.props.disabled && styles.disabled,
          multiedit_enabled && !multiedited && styles.noChange
        )}
      >
        {!wrapper && !raw && label && (
          <div className={styles.label}>
            <label onClick={this._handleClick}>
              <Tooltip
                text={active_item?.tooltip || labelTooltip || this.props.label}
              >
                {this.props.label}
              </Tooltip>
            </label>
            {multiedit_enabled && multiedited && (
              <span
                className={styles.labelRevertChanges}
                onClick={() =>
                  unregisterMultieditField(
                    this.props.meta.form,
                    this.props.input.name
                  )
                }
              >
                {__('button.revert-changes')}
              </span>
            )}
          </div>
        )}
        {!wrapper && (
          <div
            className={styles.selectGroup}
            ref={elementRef}
            onClick={this._handleClick}
          >
            <Tooltip text={has_placeholder_prop ? '' : placeholder}>
              <div
                className={classNames(
                  styles.select,
                  !!active_icon && styles.selectWithIcon
                )}
              >
                {active_icon ? (
                  <>
                    <span className={styles.icon}>
                      {active_icon(false, false, true)}
                    </span>
                    <span className={styles.placeholder}>{placeholder}</span>
                  </>
                ) : (
                  placeholder
                )}
              </div>
            </Tooltip>

            <>
              {redirectTo && (
                <span
                  className={styles.labelEdit}
                  onClick={() => redirectTo(this.props.input.value)}
                >
                  <strong>
                    <EditIcon />
                  </strong>
                </span>
              )}
              {!this.props.center && !this.props.disabled && (
                <ArrowDownMiddleIcon />
              )}
            </>
          </div>
        )}
        {wrapper && (
          <div ref={elementRef}>{wrapper(this._handleClick, active_item)}</div>
        )}
        {this.props.meta.active && (
          <OutsideClickWrapper
            onClickOutside={(e) => {
              e.stopPropagation();

              this._handleClose();
            }}
          >
            <KeyboardEventHandler
              handleKeys={['esc']}
              onKeyEvent={this._handleClose}
            />
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              {dropdown && (
                <div className={styles.header}>
                  {icon} {this.props.label}
                </div>
              )}
              {searchable && (
                <div className={styles.search}>
                  <SearchIcon className={styles.searchIcon} />
                  <input
                    ref='search'
                    type='text'
                    value={query}
                    autoFocus={true}
                    placeholder={__('select.title.search')}
                    onChange={this._handleSearch}
                    onKeyDown={this._handleKeyDown}
                  />
                  {!_.isEmpty(query) && (
                    <CloseSmallIcon
                      className={styles.close}
                      onClick={this._handleClearSearch}
                    />
                  )}
                </div>
              )}
              <div className={styles.options}>
                {!_.isEmpty(results) &&
                  (nestedList ? (
                    this._renderNestedList(sorted_results)
                  ) : (
                    <>
                      {_.map(
                        _.reject(sorted_results, 'group'),
                        ({
                          label,
                          tooltip,
                          value,
                          icon,
                          color,
                          markedValue,
                          blackMarkedValue,
                        }) => (
                          <ReactHoverObserver key={value}>
                            {({ isHovering }) => (
                              <Tooltip
                                placement='right'
                                text={tooltip || label}
                              >
                                <div
                                  onClick={() => this._chooseOption(value)}
                                  className={classNames(
                                    styles.option,
                                    markedValue &&
                                      this.props.input.value != value &&
                                      styles.markedValue,
                                    blackMarkedValue &&
                                      this.props.input.value != value &&
                                      styles.blackMarkedValue,
                                    this.props.input.value == value &&
                                      styles.active
                                  )}
                                >
                                  {icon}
                                  <span className={styles.title}>{label}</span>
                                  <span
                                    className={styles.dot}
                                    style={
                                      color && {
                                        background: color,
                                        boxShadow:
                                          'inset 0 0 0 2px #FFF' +
                                          (this.props.input.value == value
                                            ? ', 0 0 0 1px ' + color
                                            : ''),
                                      }
                                    }
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </ReactHoverObserver>
                        )
                      )}
                      {_.map(
                        _.groupBy(_.filter(sorted_results, 'group'), 'group'),
                        (items, group) => (
                          <div className={styles.group} key={group}>
                            <div className={styles.heading}>{group}</div>
                            {_.map(
                              items,
                              ({
                                label,
                                tooltip,
                                value,
                                icon,
                                color,
                                markedValue,
                              }) => (
                                <ReactHoverObserver key={value}>
                                  {({ isHovering }) => (
                                    <Tooltip
                                      placement='right'
                                      text={tooltip || label}
                                    >
                                      <div
                                        onClick={() =>
                                          this._chooseOption(value)
                                        }
                                        className={classNames(
                                          styles.option,
                                          markedValue &&
                                            this.props.input.value != value &&
                                            styles.markedValue,

                                          this.props.input.value == value &&
                                            styles.active
                                        )}
                                      >
                                        {icon}
                                        {/* {icon &&
                                        icon(
                                          isHovering,
                                          this.props.input.value == value
                                        )} */}
                                        <span className={styles.title}>
                                          {label}
                                        </span>
                                        <span
                                          className={styles.dot}
                                          style={
                                            color && {
                                              background: color,
                                              boxShadow:
                                                'inset 0 0 0 2px #FFF' +
                                                (this.props.input.value == value
                                                  ? ', 0 0 0 1px ' + color
                                                  : ''),
                                            }
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  )}
                                </ReactHoverObserver>
                              )
                            )}
                          </div>
                        )
                      )}
                    </>
                  ))}

                {_.isEmpty(results) && !callbackSetNewValue ? (
                  <div className={styles.noResults}>
                    {__('select.info.no-results')}
                  </div>
                ) : (
                  _.isEmpty(results) &&
                  callbackSetNewValue && (
                    <Tooltip
                      placement='left'
                      text={__('select.create-new-value')}
                    >
                      <button
                        type='button'
                        onClick={() => this._addOption()}
                        className={styles.createNew}
                      >
                        {__('select.create-new-value')}
                      </button>
                    </Tooltip>
                  )
                )}
              </div>
              {isMobileOnly && (
                <div className={styles.footer}>
                  <Button
                    lightGray
                    medium
                    middleText={__('button.cancel')}
                    onClick={this._handleClose}
                  />
                </div>
              )}
            </div>
          </OutsideClickWrapper>
        )}
        {!wrapper && !raw && (
          <>
            <div className={styles.bar} />
            {!this.props.center && (
              <div
                className={classNames(
                  styles.assist,
                  ((_.get(this.props, 'meta.error') &&
                    _.get(this.props, 'meta.touched')) ||
                    this.props.hint) &&
                    styles.hint
                )}
              >
                {((_.get(this.props, 'meta.error') &&
                  _.get(this.props, 'meta.touched')) ||
                  this.props.hint) && (
                  <span>
                    {_.get(this.props, 'meta.error.0') || this.props.hint}
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default Select;
