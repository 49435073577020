const initialState = {
    actions: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.plus/SET_PLUS_ACTIONS":
            return {
                actions: action.actions
            };
        case "general.plus/REMOVE_PLUS_ACTIONS":
            return {
                actions: []
            };
        default:
            return state;
    }
}
