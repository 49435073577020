import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddRawMaterialWizard.scss";
import autobind from "autobind-decorator";
import AddMaterialIcon from "../../assets/images/add-material-60x60.svg";
import {mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import {createRawMaterial, listRawMaterials} from "../../redux/actions/table/superadmin/raw_materials";
import AddRawMaterialForm from "../../forms/superadmin/add_raw_materials_wizard/AddRawMaterialForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_raw_materials"],
}))
class AddRawMaterialWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const {edit} = formData;

		return validateForm(
			createRawMaterial(formData).then(({response}) => {
				listRawMaterials().then(() => {
					if(edit) {
						setTableParams('superadmin_raw_materials', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_raw_materials', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new filter material.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add filter material</span>
						<span>Add filter material to system raw materials</span>
					</div>
					<div className={styles.right}>
						<AddMaterialIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddRawMaterialForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddRawMaterialWizard;