import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_address_book_company_wizard/AddAddressBookCompanyForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams, transformToListPairs} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/Select";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import MinusMiddleIcon from "../../assets/images/minus-middle-15x15.svg";
import * as _ from "lodash";
import MultiSelect from "../../components/MultiSelect";
import Tags from "../../components/Tags";
import {listPossibleTags} from "../../redux/actions/general";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({form: "add_address_book_company_wizard.add_address_book_company"})
class AddAddressBookCompanyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_tags: [],
        }
    }

    componentDidMount() {
        listPossibleTags('address_book').then(({response}) => {
            this.setState({possible_tags: response.data});
        });
    }

    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render_emails = ({ fields }) =>
        fields.map((field, index) => (
            <Field
                key={index}
                name={field}
                component={Input}
                label={fields.length > 1 ? __('companies-and-users.address-book-companies.add-new-company.email') + " " + (index + 1) : __('companies-and-users.address-book-companies.add-new-company.email')}
                rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
                onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
            />
        ));

    render_phones = ({ fields }) =>
        fields.map((field, index) => (
            <Field
                key={index}
                name={field}
                component={Input}
                label={fields.length > 1 ? __('companies-and-users.address-book-companies.add-new-company.phone') + " " + (index + 1) : __('companies-and-users.address-book-companies.add-new-company.phone')}
                rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
                onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
            />
        ));

    render() {
        const available_disciplines = _.sortBy(this.props.localization.disciplines, 'priority');

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.name')} />
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <FieldArray name="emails" component={this.render_emails} />
                        </div>
                        <div className={styles.f1}>
                            <FieldArray name="phones" component={this.render_phones} />
                        </div>
                        <div className={styles.f1}>
                            <div className={styles.roles}>
                                <Field name="is_manufacturer" component={Checkbox} label={__('companies-and-users.address-book-companies.add-new-company.manufacturer')} />
                                <Field name="is_supplier" component={Checkbox} label={__('companies-and-users.address-book-companies.add-new-company.representative')} />
                                <Field name="is_designer" component={Checkbox} label={__('companies-and-users.address-book-companies.add-new-company.designer')} />
                                <Field name="is_client" component={Checkbox} label={__('companies-and-users.address-book-companies.add-new-company.client')} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="disciplines" noSort component={MultiSelect} searchable label={__('companies-and-users.address-book-companies.add-new-company.disciplines')} options={transformToListPairs(available_disciplines, "name")} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="web" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.web')} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="vat" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.vat')} />
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f3}>
                            <Field name="address" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.address')} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="city" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.city')} />
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="zip" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.zip-code')} />
                        </div>
                        <div className={styles.f2}>
                            <Field name="state" component={Input} label={__('companies-and-users.address-book-companies.add-new-company.state')} />
                        </div>
                        <div className={styles.f2}>
                            <Field name="country_id" searchable component={Select} label={__('companies-and-users.address-book-companies.add-new-company.country')} options={transformToListPairs(this.props.localization.countries)} />
                        </div>
                    </div>
                    <Field name="tags" component={Tags} label={__('companies-and-users.address-book-companies.add-new-company.tags')} options={this.state.possible_tags} />
                    <Field name="note" component={Textarea} label={__('companies-and-users.address-book-companies.add-new-company.instruction')} maxLength={2000} rows={1} maxRows={10} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('address_book_companies', {
                        wizard_active: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddAddressBookCompanyForm;
