import moment from 'moment';

export const generateHours = () => {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    const hour = moment(i, 'h').format('HH');
    hours.push({ value: hour, label: hour });
  }

  return hours;
};

export const generateMinutes = () => {
  const minutes = [];

  for (let i = 0; i < 60; i = i + 15) {
    const minute = moment(i, 'mm').format('mm');
    minutes.push({ value: minute, label: minute });
  }

  return minutes;
};
