import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MoveBOQPositionWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import {
  moveBOQPosition,
  readWorkingSetBOQ,
  reorderBOQPositions,
} from '../redux/actions/table/working_set_boq';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import MoveBOQPositionForm from '../forms/move_boq_position_wizard/MoveBOQPositionForm';
import { readWorkingSetSpecification } from '../redux/actions/table/working_set_specifications';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
@subscribe(Localization, 'localization')
@withRouter
class MoveBOQPositionWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const {
      store: { flyout, calculation_id },
      match,
    } = this.props;

    const positionId = this.props.store.move_boq_position_wizard;

    const sourcePositions = _.cloneDeep(
      _.sortBy(
        _.find(
          flyout.quantity.boq_calculations,
          (calculation) => calculation.id === calculation_id
        ).positions,
        'order'
      )
    );

    const destinationCalculation = _.find(
      flyout.quantity.boq_calculations,
      (calculation) =>
        _.find(
          calculation.positions,
          (position) => position.id === formData.boq_position_id
        )
    );

    const destinationPositions = _.cloneDeep(
      _.sortBy(destinationCalculation.positions, 'order')
    );
    const destinationCalculationId = destinationCalculation.id;

    const isMoved = destinationCalculationId !== calculation_id;

    const indexToInsert = _.findIndex(
      destinationPositions,
      (position) => position.id === formData.boq_position_id
    );

    const sourceIndex = _.findIndex(
      sourcePositions,
      (position) => position.id === positionId
    );

    if (!isMoved) {
      destinationPositions.splice(sourceIndex, 1);
    }

    destinationPositions.splice(
      indexToInsert + (formData.move_action === 'before' ? 0 : 1),
      0,
      ...sourcePositions.splice(sourceIndex, 1)
    );

    const hasEmptyPositions = _.isEmpty(destinationPositions);

    const actions = () => {
      if (isMoved) {
        return moveBOQPosition(positionId, {
          boq_calculation_id: destinationCalculationId,
        }).then(() => {
          if (!hasEmptyPositions) {
            return reorderBOQPositions(
              destinationCalculationId,
              _.map(destinationPositions, (row) => row.id)
            );
          }
        });
      } else {
        return reorderBOQPositions(
          destinationCalculationId,
          _.map(destinationPositions, (row) => row.id)
        );
      }
    };

    return validateForm(
      actions().then(() => {
        readWorkingSetBOQ(match.params.stage);

        readWorkingSetSpecification(flyout.id).then(({ response }) => {
          setTableParams('working_set_specifications', {
            flyout: response.data,
            move_boq_position_wizard: undefined,
          });
        });
      })
    );
  }

  render() {
    const { flyout, name, language_id } = this.props.store;

    let options = [];

    _.each(
      _.sortBy(flyout.quantity.boq_calculations, (calculation) =>
        calculation?.item_id ? calculation?.id : Infinity
      ),
      (calculation) => {
        _.each(_.sortBy(calculation.positions, 'order'), (position) => {
          options.push({
            value: position.id,
            label: getLocalized(position?.name, language_id),
            group: `${calculation?.code} ${getLocalized(
              calculation?.name,
              language_id
            )}`,
          });
        });
      }
    );

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('working-set-boq.element.move')}</span>
            <span>{__('working-set-boq.element.move.description')}</span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MoveBOQPositionForm
            onSubmit={this._handleSubmit}
            initialValues={{ move_action: 'after', name }}
            options={options}
          />
        </div>
      </>
    );
  }
}

export default MoveBOQPositionWizard;
