import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddFilterWizard.scss";
import autobind from "autobind-decorator";
import AddBOQTemplateGroupIcon from "../../assets/images/add-boq-template-group-60x60.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createFilter, listFilters } from "../../redux/actions/table/superadmin/filters";
import AddFilterForm from "../../forms/superadmin/add_filter_wizard/AddFilterForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_filters"],
}))
class AddFilterWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createFilter(formData).then(({ response }) => {
				listFilters().then(() => {
					if (edit) {
						setTableParams('superadmin_filters', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_filters', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new filter.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add Filter</span>
						<span>Add Filter</span>
					</div>
					<div className={styles.right}>
						<AddBOQTemplateGroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddFilterForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
						product_attribute_template_field_ids: []
					}} />
				</div>
			</>
		);
	}
}

export default AddFilterWizard;