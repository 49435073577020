import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddSpecificationToCalculationWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveStage,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import AddSpecificationCalculationIcon from '../assets/images/add-specification-calculation-64x64.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import AddSpecificationToCalculationForm from '../forms/add_specification_to_calculation_wizard/AddSpecificationToCalculationForm';
import {
  addSpecificationCodeToCalculation,
  readWorkingSetBOQ,
} from '../redux/actions/table/working_set_boq';
import Button from '../components/Button';

@mapStateToProps((state) => ({
  calculation_id: _.get(state.table, 'working_set_boq.calculation_id'),
}))
@withRouter
class AddSpecificationToCalculationWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      addSpecificationCodeToCalculation(
        this.props.calculation_id,
        formData
      ).then(() =>
        readWorkingSetBOQ(getActiveStage()).then(() => {
          setNotification(
            'A specification has been successfully added to the selected calculation.'
          );

          this.props.onClose();
        })
      )
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('working-set-boq.calculation.add-specification')}</span>
            <span>
              {__('working-set-boq.calculation.add-specification.description')}
            </span>
          </div>
          <div className={styles.right}>
            <AddSpecificationCalculationIcon />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.wrapper}>
            <button
              className={styles.button}
              onClick={() => {
                setTableParams('working_set_boq', {
                  add_specification_to_calculation_wizard: undefined,

                  create_specification_from_ws_boq_wizard: true,
                });
              }}
            >
              {__(
                'working-set-boq.calculation.add-specification.create-new-specification'
              )}
            </button>
          </div>

          <AddSpecificationToCalculationForm onSubmit={this._handleSubmit} />
        </div>
      </>
    );
  }
}

export default AddSpecificationToCalculationWizard;
