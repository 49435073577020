import store from '../../../core/store';
import { downloadFile } from '../general/downloader';

export const listPossibleTenderAdministrators = (stage_id) => {
  return store.dispatch({
    action: 'table.tenders/LIST_POSSIBLE_TENDER_ADMINISTRATORS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_tender_administrators',
  });
};

export const listPossibleTenderFollowers = (stage_id) => {
  return store.dispatch({
    action: 'table.tenders/LIST_POSSIBLE_TENDER_FOLLOWERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_tender_followers',
  });
};

export const listPossibleTenderBidders = (stage_id) => {
  return store.dispatch({
    action: 'table.tenders/LIST_POSSIBLE_TENDER_BIDDERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_tender_bidders',
  });
};

export const createTender = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/CREATE_TENDER',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/tenders',
    data: formData,
  });
};

export const listAllTenders = (stage_id) => {
  return store.dispatch({
    action: 'table.tenders/LIST_ALL_TENDERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/tenders',
    main: true,
  });
};

export const listPossibleTenders = (stage_id) => {
  return store.dispatch({
    action: 'table.tenders/LIST_POSSIBLE_TENDERS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_tenders',
  });
};

export const readTender = (tender_id) => {
  return store.dispatch({
    action: 'table.tenders/READ_TENDER',
    method: 'GET',
    endpoint: '/tenders/' + tender_id,
    has404: true,
  });
};

export const updateTenderToolbar = (tender_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_TENDER_TOOLBAR',
    method: 'PUT',
    endpoint: '/tenders/' + tender_id + '/toolbar',
    data: formData,
  });
};

export const updateBidderStatus = (tender_id, bidder_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_BIDDER_STATUS',
    method: 'PUT',
    endpoint: '/tenders/' + tender_id + '/bidder/' + bidder_id + '/status',
    data: formData,
  });
};

export const updateBidderDecisionStatus = (tender_id, bidder_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_BIDDER_DECISION_STATUS',
    method: 'PUT',
    endpoint:
      '/tenders/' + tender_id + '/bidder/' + bidder_id + '/decision_status',
    data: formData,
  });
};

export const updateTender = (tender_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_TENDER',
    method: 'PUT',
    endpoint: '/tenders/' + tender_id,
    data: formData,
  });
};

export const downloadTenderFiles = (tender_id) => {
  return downloadFile(
    backendURL + `/tenders/${tender_id}/download`,
    [],
    'POST'
  );
};

export const uploadTenderFiles = (tender_id, files) => {
  return store.dispatch({
    action: 'table.tenders/UPLOAD_TENDER_FILE',
    method: 'POST',
    preloaded: false,
    endpoint: '/tenders/' + tender_id + '/files',
    data: {
      files,
    },
  });
};

export const deleteTenderFile = (tender_id, attachment_id) => {
  return store.dispatch({
    action: 'table.tenders/DELETE_TENDER_FILE',
    method: 'DELETE',
    endpoint: '/tenders/' + tender_id + '/files',
    data: {
      attachment_id,
    },
  });
};

export const createBidder = (tender_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/CREATE_BIDDER',
    method: 'POST',
    endpoint: '/tenders/' + tender_id + '/bidders',
    data: formData,
  });
};

export const addBOQToTenderBOQ = (tender_id, formData) => {
  return store.dispatch({
    action: 'table.tenders/ADD_BOQ_TO_TENDER_BOQ',
    method: 'POST',
    endpoint: '/tenders/' + tender_id + '/boq',
    data: formData,
  });
};

export const readTenderBOQ = (tender_id) => {
  return store.dispatch({
    action: 'table.tenders/READ_TENDER_BOQ',
    method: 'GET',
    endpoint: '/tenders/' + tender_id + '/boq',
  });
};

export const readTenderBOQOwner = (tender_id) => {
  return store.dispatch({
    action: 'table.tenders/READ_TENDER_BOQ',
    method: 'GET',
    endpoint: '/tenders/' + tender_id + '/boq/owner',
  });
};

export const updateTenderBOQCalculationPrice = (
  tender_boq_calculation_id,
  price
) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_TENDER_BOQ_CALCULATION_PRICE',
    method: 'PUT',
    endpoint:
      '/tenders/tender_boq_calculations/' +
      tender_boq_calculation_id +
      '/price',
    data: {
      price,
    },
  });
};

export const updateTenderBOQCalculationStatus = (
  tender_boq_calculation_id,
  status
) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_TENDER_BOQ_CALCULATION_STATUS',
    method: 'PUT',
    endpoint:
      '/tenders/tender_boq_calculations/' +
      tender_boq_calculation_id +
      '/status',
    data: {
      status,
    },
  });
};

export const readTenderSpecification = (tender_id, specification_id) => {
  return store.dispatch({
    action: 'table.tenders/READ_TENDER_SPECIFICATIONS',
    method: 'GET',
    endpoint: '/tenders/' + tender_id + '/specifications/' + specification_id,
    has404: true,
  });
};

export const updateTenderBOQCalculationComment = (
  tender_boq_calculation_id,
  comment
) => {
  return store.dispatch({
    action: 'table.tenders/UPDATE_TENDER_BOQ_CALCULATION_COMMENT',
    method: 'PUT',
    endpoint:
      '/tenders/tender_boq_calculations/' +
      tender_boq_calculation_id +
      '/comment',
    data: {
      comment,
    },
  });
};
