import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/plan_approval_wizard/PlanApprovalForm.scss";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {__} from "../../core/utils";
import Textarea from "../../components/Textarea";

const mapStateToProps = state => {
    return {
        table: state.table["plan_approvals"],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideCreateDeliveryWizard: () =>
                dispatch({
                    type: "table.plan_approvals/SET_TABLE_PARAMS",
                    params: {
                        wizard_active: undefined,
                        approve: undefined
                    }
                })
        }
    };
};

@reduxForm({form: "plan_approval_wizard.plan_approval"})
@connect(mapStateToProps, mapDispatchToProps)
class PlanApprovalForm extends Component {
    render() {
        const {approve} = this.props.table;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <Field name="message" component={Textarea} label={approve ? __('Approval Message') : __('Rejection Message')} hint="This message is addressed to delivery creator." rows={1} maxRows={10} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideCreateDeliveryWizard} />
                    <Button gray medium right submit middleText={approve ? __('Approve') : __('Reject')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default PlanApprovalForm;
