import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditLinkedSpecificationWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import EditLinkedSpecificationIcon from '../assets/images/edit-linked-specification-64x64.svg';
import { withRouter } from 'react-router';
import AddLinkedSpecificationForm from '../forms/add_linked_specification_wizard/AddLinkedSpecificationForm';
import {
  addLinkedSpecificationCode,
  readWorkingSetSpecification,
  updateLinkedSpecificationCode,
} from '../redux/actions/table/working_set_specifications';
import { listOCRTemplates } from '../redux/actions/table/ocr_templates';
import { setNotification } from '../redux/actions/general/notification';
import * as _ from 'lodash';
import EditLinkedSpecificationForm from '../forms/edit_linked_specification_wizard/EditLinkedSpecificationForm';

@mapStateToProps((state) => ({
  store: _.get(state.table, 'working_set_specifications'),
}))
@withRouter
class EditLinkedSpecificationWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateLinkedSpecificationCode(
        this.props.store.flyout.specification_code_id,
        formData
      ).then(() =>
        readWorkingSetSpecification(this.props.store.flyout.id).then(
          ({ response }) => {
            setNotification('A linked specification has been updated.');

            setTableParams('working_set_specifications', {
              flyout: response.data,
            });

            this.props.onClose();
          }
        )
      )
    );
  }

  render() {
    const edited_specification_code = _.find(this.props.store.flyout.linked, [
      'specification_code_id',
      this.props.store.edit_linked_specification_wizard,
    ]);

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'wizard.working-set-specifications.title.edit-linked-specification'
              )}
            </span>
            <span>
              {__(
                'wizard.working-set-specifications.subtitle.edit-linked-specification'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <EditLinkedSpecificationIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditLinkedSpecificationForm
            onSubmit={this._handleSubmit}
            initialValues={{
              specification_code_id:
                this.props.store.edit_linked_specification_wizard,
              new_specification_code_id:
                this.props.store.edit_linked_specification_wizard,
              description: edited_specification_code?.linked_description,
              included_in_price: this.props.store?.included_in_price,
            }}
          />
        </div>
      </>
    );
  }
}

export default EditLinkedSpecificationWizard;
