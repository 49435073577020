import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/send_meeting_report_wizard/SendMeetingReportForm.scss";
import Input from "../../components/Input";
import {__, resetForm, setTableParams} from "../../core/utils";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";

@reduxForm({
    form: "send_meeting_report_wizard.send_meeting_report",
    destroyOnUnmount: false
})
class SendMeetingReportForm extends Component {
    render() {
        const {meeting} = this.props;

        return (
            <div className={styles.wrapper}>
                <Form onSubmit={this.props.handleSubmit} className={styles.form}>
                    <Field name="subject" component={Input} label={__('meetings.all-meetings.send-meeting.subject')} />
                    <Field name="message" component={Textarea} label={__('meetings.all-meetings.send-meeting.message')} maxLength={5000} rows={1} />
                </Form>
                <button onClick={() => {
                    setTableParams('all_meetings', {
                        send_meeting_report_wizard: undefined,
                        send: true,
                        meeting_status: meeting.status,
                    });

                    setTimeout(() => setTableParams('all_meetings', {
                        print_meeting_report_wizard: true,
                    }), 10);
                }}>{__('meetings.all-meetings.send-meeting.pdf-settings')}</button>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => {
                        setTableParams('all_meetings', {
                            send_meeting_report_wizard: undefined
                        });
                        this.props.reset();
                        resetForm('print_meeting_report_wizard');
                    }} />
                    <Button lightBlue onClick={() => this.props.submit()} spinning={this.props.submitting} disabled={this.props.submitting} medium right middleText={__('button.send')} />
                </div>
            </div>
        );
    }
}

export default SendMeetingReportForm;
