import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/superadmin/raw_colors/Properties.scss";
import * as _ from "lodash";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../../core/utils";
import { setNotification } from "../../../redux/actions/general/notification";
import { listRawColors, updateRawColor } from "../../../redux/actions/table/superadmin/raw_colors";
import EditRawColorForm from "../../../forms/superadmin/raw_colors/EditRawColorForm";
import { listLocalization } from "../../../redux/actions/localization";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateRawColor(formData.id, _.omit(formData, ['id'])).then(() => {
                listRawColors();

                this.props.readAction(formData.id);

                listLocalization();

                setNotification(__('properties.notifications.properties-have-been-saved'));
            })
        );
    }

    render() {
        const { data } = this.props;

        return (
            <div className={styles.scroller}>
                <EditRawColorForm
                    onSubmit={this._handleSubmit}
                    initialValues={{
                        ..._.pick(data, ['id', 'priority', 'name', 'hex']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
