import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  getActiveProject,
  getActiveStage,
  redirect,
} from '../../core/utils';
import { setPlusActions } from '../../redux/actions/general/plus';
import Localization from '../../helpers/Localization';
import { subscribe } from 'react-contextual';
import ImportSpreadsheetTable from '../../components/ImportSpreadsheetTable';
import _ from 'lodash';
import { setNotification } from '../../redux/actions/general/notification';
import { importSpreadsheet } from '../../redux/actions/table/import_spreadsheets';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import { withRouter } from 'react-router';
import { getStagesLanguages } from '../../redux/actions/table/stages';

const ImportBOQSpreadsheet = ({
  actions,
  table: {
    data,
    columns_count,
    module,
    data_structure,
    rows,
    import_type,
    language_id,
  },
  match,
}) => {
  let options = {
    bill_of_quantities: [
      {
        value: 'name',
        label: __('import_spreadsheet.bill_of_quantities.field.name'),
        required: true,
      },
      {
        value: 'quantity',
        label: __('import_spreadsheet.bill_of_quantities.field.quantity'),
        required: true,
      },
      {
        value: 'unit',
        label: __('import_spreadsheet.bill_of_quantities.field.unit'),
        required: true,
      },
      {
        value: 'price',
        label: __('import_spreadsheet.bill_of_quantities.field.price'),
        required: true,
      },
    ],
  };

  useEffect(() => {
    listActiveModules();

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/boq/import')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/boq/import')
    );

    setPlusActions([
      {
        title: __('import_spreadsheet.table.button.create'),
        onClick: () =>
          actions.setTableParams({
            wizard_active: true,
            is_boq_page: true,
          }),
      },
    ]);

    getStagesLanguages(match.params.stage).then(({ response }) => {
      actions.setTableParams({
        project_languages: response.data,
        language_id: _.findKey(response.data, (primary) => primary == true),
      });
    });

    createSidebar({
      title: __('submenu.title.bill-of-quantities'),
      items: [
        // {
        //   title: __('submenu.title.current-set-bill-of-quantities'),
        //   link: () => '/stages/' + getActiveStage() + '/boq/current',
        // },
        {
          title: __('submenu.title.working-set-bill-of-quantities'),
          link: () =>
            '/stages/' + getActiveStage() + '/boq/working?filter.subgroup=',
        },

        {
          title: __('submenu.title.import_spreadsheet'),
          link: () => '/stages/' + getActiveStage() + '/boq/import',
          active: true,
        },
        // {
        //   title: __('submenu.title.deliveries'),
        //   link: () => '/stages/' + getActiveStage() + '/boq_deliveries',
        // },
        // {
        //   title: __('submenu.title.approvals'),
        //   link: () => '/stages/' + getActiveStage() + '/boq_approvals',
        // },
      ],
    });

    actions.setTableParams({
      module: undefined,
      data_structure: undefined,
      rows: [],
    });
  }, []);

  const isHierarchyStructure = data_structure === 'hierarchy';

  const _handleSubmit = (data) => {
    const headerRow = _.first(data)?.headerRow;

    let rowsData = [];

    !isHierarchyStructure
      ? _.each(
          _.filter(_.slice(data, 1), (row) => row.checked),
          (row) => {
            let rowObject = {};

            _.each(headerRow, (header, i) => {
              rowObject = { ...rowObject, [header.value]: row[i - 1] || '' };
            });

            rowsData.push(rowObject);
          }
        )
      : _.each(_.slice(data, 1), (row) => {
          let rowObject = {};

          _.each(headerRow, (header, i) => {
            rowObject = { ...rowObject, [header.value]: row[i] || '' };
          });

          rowsData.push(rowObject);
        });

    showAlertbox({
      color: 'blue',
      title: __('import-spreadsheet.alert.title'),
      description: __('import-spreadsheet.alert.import-confirmation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-import'),
          onClick: () => {
            setNotification(__('import_spreadsheet.importing'));

            const formData = {
              data: rowsData,
              module,
              data_structure,
              import_type,
              language_id,
            };

            importSpreadsheet(getActiveStage(), formData).then(() => {
              actions.setTableParams({
                module: undefined,
                data_structure: undefined,
                import_type: '',
                rows: [],
              });
            });

            hideAlertbox();
          },
        },
      ],
    });
  };

  options = _.filter(
    options[module],
    (option) =>
      _.isUndefined(option.isVisibleInHierarchyStructure) ||
      (isHierarchyStructure
        ? option.isVisibleInHierarchyStructure
        : !option.isVisibleInHierarchyStructure)
  );

  return (
    <ImportSpreadsheetTable
      name='import_spreadsheet'
      data={rows}
      isHierarchyStructure={isHierarchyStructure}
      title={__('import_spreadsheet')}
      columns_count={columns_count}
      options={options}
      _handleSubmit={(data) => _handleSubmit(data)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['import_spreadsheet'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.import_spreadsheet/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(ImportBOQSpreadsheet))
);
