import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Table from '../../components/Table';
import {
  __,
  getLocalized,
  getURLParam,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { createSuperadminSidebar } from '../../redux/actions/general/sidebar';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import { setPlusActions } from '../../redux/actions/general/plus';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import {
  deleteBOQTemplateSubgroups,
  listBOQTemplateSubgroups,
  readBOQTemplateSubgroup,
} from '../../redux/actions/table/superadmin/boq_template_subgroups';
import BOQTemplateSubgroupsFlyout from '../../flyouts/superadmin/boq_template_subgroups/BOQTemplateSubgroupsFlyout';
import TableNA from '../../components/TableNA';

@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  table: state.table['superadmin_boq_template_subgroups'],
  application_language_id: state.auth.language_id,
}))
class BOQTemplateSubgroups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    listActiveModules();
    createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

    const { superadmin } = this.props;

    superadmin.add_boq_template_subgroup &&
      setPlusActions([
        {
          title: 'Add BOQ template subgroup',
          onClick: () =>
            setTableParams('superadmin_boq_template_subgroups', {
              add_boq_template_subgroup_wizard: true,
            }),
        },
      ]);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readBOQTemplateSubgroup(this.props.table.clicked_row).then(
        ({ response }) => {
          this.setState({ preview: response.data });
        }
      );
    }
  }

  render() {
    const { superadmin, application_language_id } = this.props;

    const columns = [
      {
        key: 'id',
        name: 'ID',
        width: 60,
        sortable: false,
      },
      {
        key: 'name',
        name: 'Name',
        value: (name) => getLocalized(name, application_language_id),
      },
      {
        key: 'boq_template_group',
        name: 'BOQ Template Group',
        value: (boq_template_group) => {
          const translation = getLocalized(
            boq_template_group,
            application_language_id
          );

          return <Tooltip text={translation}>{translation}</Tooltip>;
        },
      },
      {
        key: 'specification_subgroups',
        name: 'Linked Specification Subgroups',
        width: 200,
        sortable: false,
        value: (specification_subgroups) => {
          return _.isEmpty(specification_subgroups) ? (
            <TableNA />
          ) : (
            <Tooltip text={_.join(specification_subgroups, '\n')}>
              {_.join(specification_subgroups, ', ')}
            </Tooltip>
          );
        },
      },
    ];

    const singleActions = (boq_template_subgroup_id) => {
      return [
        <Tooltip
          text='Delete selected BOQ template subgroup'
          key={_.uniqueId()}
        >
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description:
                  'Do you really want to delete the selected BOQ template subgroup?\n\nThis process cannot be undone.',
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteBOQTemplateSubgroups([
                        boq_template_subgroup_id,
                      ]).then(() => {
                        hideAlertbox();

                        setNotification(
                          'The selected BOQ template subgroup has been deleted.'
                        );
                        listBOQTemplateSubgroups();

                        setTableParams('superadmin_boq_template_subgroups', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (boq_template_grousubps_ids) => [
      <Tooltip text='Delete selected BOQ template subgroups' key={_.uniqueId()}>
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description:
                'Do you really want to delete these BOQ template subgroups?\n\nThis process cannot be undone.',
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteBOQTemplateSubgroups(boq_template_grousubps_ids).then(
                      () => {
                        hideAlertbox();

                        setNotification(
                          'The selected BOQ template subgroups have been deleted.'
                        );
                        listBOQTemplateSubgroups();

                        setTableParams('superadmin_boq_template_subgroups', {
                          selected_rows: [],
                        });
                      }
                    );
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <BOQTemplateSubgroupsFlyout
        data={this.state.preview}
        readAction={(boq_template_subgroup_id) => {
          return new Promise((resolve) => {
            readBOQTemplateSubgroup(
              getURLParam('id') || boq_template_subgroup_id
            ).then(({ response }) => {
              this.setState({ preview: response.data });

              resolve(response);
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={listBOQTemplateSubgroups}
        name='superadmin_boq_template_subgroups'
        groupActions={superadmin.delete_boq_template_subgroup && groupActions}
        singleActions={superadmin.delete_boq_template_subgroup && singleActions}
        title={__('modules.submodules.title.boq-template-subgroups')}
        preview={this.state.preview ? preview : []}
        columns={columns}
      />
    );
  }
}

export default BOQTemplateSubgroups;
