import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/products/Files.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";
import autobind from "autobind-decorator";
import DocumentExtIcon from "../../../assets/images/document-ext-30x30.svg";
import {__, bytesToSize, mapStateToProps, setTableParams} from "../../../core/utils";
import CloseMediumIcon from "../../../assets/images/close-middle-15x15.svg";
import {setNotification} from "../../../redux/actions/general/notification";
import {deleteProductFile, readProduct, uploadProductFiles} from "../../../redux/actions/table/products";
import {hideAlertbox, showAlertbox} from "../../../redux/actions/general/alertbox";

@mapStateToProps(state => ({
    flyout: state.table.superadmin_products.flyout
}))
class Files extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: []
        };
    }

    @autobind
    _toggleCollapseCategory(category) {
        this.setState({
            expanded: _.xor(this.state.expanded, [category])
        })
    }

    @autobind
    _downloadFile(download_url) {
        setNotification(__('flyouts.products.files.products.files.download.continue'));

        window.open(download_url, '_blank');
    }
    
    render() {
        const {collapsed, flyout} = this.props;
        const {expanded} = this.state;

        const categories = [
            {
                label: __('products.flyout.files.manuals'),
                value: "manuals",
            }, {
                label: __('products.flyout.files.guarantee'),
                value: "guarantee",
            }, {
                label: __('products.flyout.files.certificates'),
                value: "certificates",
            }, {
                label: __('products.flyout.files.catalogs'),
                value: "catalogs",
            }, {
                label: __('products.flyout.files.installation'),
                value: "installation",
            }, {
                label: __('products.flyout.files.bim'),
                value: "bim",
            }, {
                label: __('products.flyout.files.3d'),
                value: "3d",
            }, {
                label: __('products.flyout.files.cad'),
                value: "cad",
            }, {
                label: __('products.flyout.files.2d'),
                value: "2d",
            }, {
                label: __('products.flyout.files.photometrical-distribution'),
                value: "light_data",
            }, {
                label: __('products.flyout.files.video'),
                value: "video",
            }, {
                label: __('products.flyout.files.audio'),
                value: "audio",
            }, {
                label: __('products.flyout.files.other'),
                value: "other",
            }
        ];

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                {_.map(categories, category => {
                    const category_files = _.filter(flyout.files, ['category', category.value]);

                    return _.isEmpty(category_files) ? '' : (
                        <div className={styles.category} key={category.value}>
                            <div className={styles.title} onClick={() => this._toggleCollapseCategory(category.value)}>
                                <ArrowDownMiddleIcon className={classNames(styles.collapse, !_.includes(expanded, category.value) && styles.collapsed)} />
                                {category.label} {!_.isEmpty(category_files) && '(' + _.size(category_files) + ')'}
                            </div>
                            {_.includes(expanded, category.value) && <>
                                {!_.isEmpty(category_files) && <div className={styles.files}>
                                    {_.map(category_files, (file, i) => (
                                        <div className={styles.file} key={i}>
                                            <div className={styles.icon} onClick={() => this._downloadFile(file.download_url)}>
                                                <DocumentExtIcon className={styles['color' + _.sumBy(_.toLower(file.ext).split(''), item => item.charCodeAt(0)) % 5]}/>
                                                <span>{file.ext.substring(0, 4)}</span>
                                            </div>
                                            <strong onClick={() => this._downloadFile(file.download_url)}>{file.name}</strong> · {_.toUpper(file.ext)}
                                        </div>
                                    ))}
                                </div>}
                            </>}
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default Files;
