import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {__} from "../../core/utils";
import styles from "../../styles/forms/plans/OCRTemplateEditorForm.scss";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
import classNames from "classnames";
import {enableAutosave} from "../../redux/actions/general/autosave";
import Input from "../../components/Input";
import * as _ from "lodash";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {getStagesLanguages} from "../../redux/actions/table/stages";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({
    form: "plans.ocr_template_editor",
    enableReinitialize: true,
})
class OCRTemplateEditorForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            project_languages: []
        }
    }

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    componentDidMount() {
        getStagesLanguages(this.props.data.stage_id).then(({response}) => {
            this.setState({project_languages: response.data});
        });
    }

    render() {
        const {project_languages} = this.state;

        const languages = _.map(project_languages, (primary, language_id) => ({
            label: _.get(this.props.localization.languages, [language_id, 'name']),
            value: language_id,
        }));

        const alignment_options = [
            {
                label: __('plans.plan-upload.ocr-template.plan-alignment.top-left'),
                value: 'tl',
            }, {
                label: __('plans.plan-upload.ocr-template.plan-alignment.top-right'),
                value: 'tr',
            }, {
                label: __('plans.plan-upload.ocr-template.plan-alignment.bottom-left'),
                value: 'bl',
            }, {
                label: __('plans.plan-upload.ocr-template.plan-alignment.bottom-right'),
                value: 'br',
            }
        ];

        return (
            <Form onSubmit={this.props.handleSubmit}>
                <Field name="name" component={Input} label={__('ocr-templates.field.name')} maxLength={50} />
                <Field name="language_id" component={Select} noSort label={__('ocr-templates.field.language')} searchable options={languages} />
                <Field name="alignment" component={Select} label={__('ocr-templates.field.plan-alignment')} options={alignment_options} />
                <div className={classNames(styles.checkbox, styles.code)}>
                    <Field name="code" component={Checkbox} label={__('ocr-templates.field.enable-code')} />
                </div>
                <div className={classNames(styles.checkbox, styles.title)}>
                    <Field name="title" component={Checkbox} label={__('ocr-templates.field.enable-title')} />
                </div>
                <div className={classNames(styles.checkbox, styles.scale)}>
                    <Field name="scale" component={Checkbox} label={__('ocr-templates.field.enable-scale')} />
                </div>
            </Form>
        );
    }
}

export default OCRTemplateEditorForm;