import * as React from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/copy_specifications_wizard/CopySpecificationsForm.scss';
import Button from '../../components/Button';
import { __, getActiveProject, getActiveStage } from '../../core/utils';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import { connect } from 'react-redux';
import ButtonGroup from '../../components/ButtonGroup';

const CopyLinkedSpecificationsForm = (props) => {
  const {
    values: { selected_same_stage },
  } = props;

  const project = _.find(props.active_modules.items, [
    'id',
    getActiveProject(),
  ]);

  const stage = project
    ? _.find(project.stages, ['id', getActiveStage()])
    : null;

  const options = _.filter(
    [
      {
        label: __(
          'specifications.working-set.copy-specification.copy_linked_specifications'
        ),
        value: 0,
      },
      {
        label: __(
          'specifications.working-set.copy-specification.keep_links_to_existing_specifications'
        ),
        value: 1,
      },
      {
        label: __(
          'specifications.working-set.copy-specification.ignore_linked_specifications'
        ),
        value: 2,
      },
    ],
    (option) => {
      return (
        selected_same_stage || (!selected_same_stage && option.value !== 1)
      );
    }
  );

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      {stage && (
        <div className={styles.location}>
          <span>
            <strong>
              {project.name} / {stage.name}
            </strong>
          </span>
        </div>
      )}

      <Field
        name='copy_linked_specifications_option'
        component={RadioGroup}
        options={options}
      />

      <div className={styles.copyOptionInfo}>
        * {__('specifications.working-set.copy-linked-specifications.info')}
      </div>

      <div className={styles.footer}>
        <ButtonGroup right>
          <Button
            lightGray
            medium
            middleText={__('button.back')}
            onClick={() => props.onBack()}
          />
          <Button
            lightBlue
            medium
            submit
            middleText={__('button.next')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={props.submitting}
          />
        </ButtonGroup>
      </div>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    values: getFormValues(
      'copy_specifications_wizard.copy_linked_specifications'
    )(state),
    application_language_id: state.auth.language_id,
    active_modules: state.general.active_modules,
  };
}

export default reduxForm({
  form: 'copy_specifications_wizard.copy_linked_specifications',
})(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(CopyLinkedSpecificationsForm)
  )
);

// class CopySpecificationForm extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       possible_teams: [],
//       //possible_stages: []
//     };
//   }

//   componentDidMount() {
//     if (this.props.values.stage_id) {
//       this.props.change('team_id', null);

//       listPossibleSpecificationStages(_.get(this.props, 'values.project_id'))
//         .then(({ response }) => {
//           this.setState({ possible_stages: response.data });

//           if (_.size(response.data) == 1) {
//             this.props.change(
//               'stage_id',
//               _.get(response.data, [0, 'id'], null)
//             );
//           }
//         })
//         .then(() =>
//           listPossibleSpecificationTeams(this.props.values.stage_id).then(
//             ({ response }) => {
//               this.setState({ possible_teams: response.data });

//               if (_.size(response.data) == 1) {
//                 this.props.change(
//                   'team_id',
//                   _.get(response.data, [0, 'id'], null)
//                 );
//               }
//             }
//           )
//         );
//     }
//   }

//   componentDidUpdate(prevProps) {
//     if (
//       _.get(prevProps, 'values.project_id') &&
//       _.get(prevProps, 'values.project_id') !=
//         _.get(this.props, 'values.project_id') &&
//       _.get(this.props, 'values.project_id')
//     ) {
//       listPossibleSpecificationStages(
//         _.get(this.props, 'values.project_id')
//       ).then(({ response }) => {
//         this.setState({ possible_stages: response.data });

//         if (_.size(response.data) !== 0) {
//           this.props.change('stage_id', _.get(response.data, [0, 'id'], null));
//         }
//       });

//       this.props.change('stage_id', null);
//       this.props.change('team_id', null);
//     }

//     if (
//       prevProps.values.stage_id != this.props.values.stage_id &&
//       this.props.values.stage_id
//     ) {
//       this.props.change('team_id', null);

//       listPossibleSpecificationTeams(this.props.values.stage_id).then(
//         ({ response }) => {
//           this.setState({ possible_teams: response.data });

//           if (_.size(response.data) == 1) {
//             this.props.change('team_id', _.get(response.data, [0, 'id'], null));
//           }
//         }
//       );
//     }
//   }

//   @autobind
//   _handleSubmit() {
//     this.props.submit();
//   }

//   render() {
//     const { values } = this.props;

//     const { possible_teams, possible_stages } = this.state;

//     const possible_projects = this.props.active_modules.items;

//     const project = _.find(this.props.active_modules.items, [
//       'id',
//       getActiveProject(),
//     ]);
//     const stage = project
//       ? _.find(project.stages, ['id', getActiveStage()])
//       : null;

//     return (
//       <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
//         {stage && (
//           <div className={styles.location}>
//             <span>
//               <strong>
//                 {project.name} / {stage.name}
//               </strong>
//             </span>
//           </div>
//         )}

//         <Field
//           name='project_id'
//           searchable={true}
//           component={Select}
//           label={__('specifications.working-set.copy-specification.project')}
//           options={_.map(possible_projects, (project) => ({
//             value: project.id,
//             label: project.name,
//           }))}
//         />
//         <Field
//           name='stage_id'
//           searchable={true}
//           disabled={!values.project_id}
//           component={Select}
//           label={__('specifications.working-set.copy-specification.stage')}
//           options={_.map(possible_stages, (stage) => ({
//             value: stage.id,
//             label: stage.name,
//           }))}
//         />
//         <Field
//           name='team_id'
//           searchable={true}
//           disabled={!values.stage_id}
//           component={Select}
//           label={__(
//             'specifications.working-set.copy-specification.discipline-team'
//           )}
//           options={_.map(possible_teams, (team) => ({
//             value: team.id,
//             label:
//               team.company.name +
//               ' [' +
//               [
//                 team.stage_discipline.code,
//                 team.stage_discipline.discipline_id == null
//                   ? team.stage_discipline.description + '*'
//                   : _.get(this.props.localization, [
//                       'disciplines',
//                       team.stage_discipline.discipline_id,
//                       'name',
//                     ]),
//               ].join(': ') +
//               ']',
//           }))}
//         />

//         <Field
//           name='copy_specifications_option'
//           component={RadioGroup}
//           options={[
//             {
//               label: __(
//                 'specifications.working-set.copy-specification.skip-option'
//               ),
//               value: '0',
//             },
//             {
//               label: __(
//                 'specifications.working-set.copy-specification.replace-option'
//               ),
//               value: '1',
//             },
//           ]}
//         />

//         <div className={styles.copyOptionInfo}>
//           * {__('specifications.working-set.copy-specification.info')}
//         </div>

//         <div className={styles.footer}>
//           <Button
//             lightGray
//             medium
//             left
//             middleText={__('button.cancel')}
//             onClick={() =>
//               setTableParams('working_set_specifications', {
//                 copy_specifications_wizard_active: undefined,
//               })
//             }
//           />
//           <Button
//             lightBlue
//             medium
//             submit
//             middleText={__('button.next')}
//             rightIcon={ArrowRightMiddleIcon}
//             spinning={this.props.submitting}
//             onClick={() => this._handleSubmit(false)}
//           />
//         </div>
//       </Form>
//     );
//   }
// }

// export default CopySpecificationForm;
