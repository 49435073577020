import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/tasks/History.scss";
import {withRouter} from "react-router";
import {dateTimeFrom} from "../../core/utils";
import classNames from "classnames";

@withRouter
class History extends Component {
    render() {
        const {data, collapsed} = this.props;

        return (
            <div className={classNames(styles.scroller, collapsed && styles.collapsed)}>
                {_.map(data.history, (row, i) => (
                    <div className={styles.row} key={i}>
                        <div className={styles.heading}>
                            <span>{row.title}</span>
                            <span>{row.user} &middot; {dateTimeFrom(row.created_at)}</span>
                        </div>
                        {_.size(row.content) > 0 && <div className={styles.content}>{row.content}</div>}
                    </div>
                ))}
            </div>
        );
    }
}

export default History;
