import {applyMiddleware, combineReducers, createStore} from "redux";
import {ajaxMiddleware, reduxFormMiddleware} from "./middlewares";
import {routerMiddleware} from "react-router-redux";
import {createLogger} from "redux-logger";
import reducers from "./reducers";
import history from "./history";

/*==============================================
=            Initialize middlewares            =
==============================================*/

    const middlewares = [
        routerMiddleware(history),
        ajaxMiddleware,
        reduxFormMiddleware
    ];

    //Use redux logger, if in development mode
    if (process.env.NODE_ENV === "development" && !localStorage.getItem('DISABLE_REDUX_LOGGER')) {
        const logger = createLogger({
            predicate: (getState, action) => !_.includes([
                'general.preloaders/ADD_PRELOADER',
                'general.preloaders/REMOVE_PRELOADER',
                'general.maintenance_mode/GENERAL_CHECK_REQUEST',
                'general.maintenance_mode/GENERAL_CHECK_SUCCESS',
                'general.autosave/ENABLE_AUTOSAVE',
                '@@redux-form/REGISTER_FIELD',
                '@@redux-form/UNREGISTER_FIELD',
            ], action.type)
        });
        middlewares.push(logger);
    }

/*=====  Initialize middlewares  ======*/


/*====================================
=            Create store            =
====================================*/

    const store = createStore(
        combineReducers({
            ...reducers
        }),
        applyMiddleware(...middlewares)
    );

/*=====  Create store  ======*/

export default store;