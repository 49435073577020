import store from "../../../core/store";

export const listCurrentSetSpecifications = (stage_id) => {
	return store.dispatch({
		action: "table.current_set_specifications/LIST_CURRENT_SET_SPECIFICATIONS",
		method: "GET",
		endpoint: "/stages/" + stage_id + "/specifications/current",
		main: true
	});
};

export const readCurrentSetSpecification = (specification_id) => {
	return store.dispatch({
		action: "table.current_set_specifications/READ_CURRENT_SET_SPECIFICATIONS",
		method: "GET",
		endpoint: "/specifications/current/" + specification_id,
		has404: true
	});
};

export const printCurrentSetSpecifications = (stage_id, specification_ids, formData) => {
	return store.dispatch({
		action: "table.current_set_specifications/PRINT_CURRENT_SET_SPECIFICATIONS",
		method: "POST",
		endpoint: "/stages/" + stage_id + "/specifications/current/print",
		data: {
			...formData,
			specifications: specification_ids
		}
	});
};

export const getCountOfCurrentSetSpecifications = (stage_id, data) => {
	return store.dispatch({
		action: "table.working_set_specifications/UPDATE_SPECIFICATION_READY_FOR_DELIVERY",
		method: "PUT",
		endpoint: "/specifications/" + stage_id + "/count",
		data: data
	});
};
