import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, reduxForm} from "redux-form";
import Textarea from "../../components/Textarea";
import {__, transformToListPairs} from "../../core/utils";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/address_book_companies/EditAddressBookCompanyForm.scss";
import Input from "../../components/Input";
import {withRouter} from "react-router";
import Select from "../../components/Select";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";
import Checkbox from "../../components/Checkbox";
import * as _ from "lodash";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import MinusMiddleIcon from "../../assets/images/minus-middle-15x15.svg";
import Tags from "../../components/Tags";
import {listPossibleTags} from "../../redux/actions/general";

@reduxForm({
    form: "address_book_companies.edit_address_book_company",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
class EditAddressBookCompanyForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    constructor(props) {
        super(props);

        this.state = {
            possible_tags: [],
        }
    }

    componentDidMount() {
        listPossibleTags('address_book').then(({response}) => {
            this.setState({possible_tags: response.data});
        });
    }

    render_emails = ({ fields }) =>
        fields.map((field, index) => (
            <Field
                key={index}
                name={field}
                component={Input}
                label={fields.length > 1 ? __('companies-and-users.address-book-companies.flyout.email') + " " + (index + 1) : __('companies-and-users.address-book-companies.flyout.email')}
                rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
                onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
            />
        ));

    render_phones = ({ fields }) =>
        fields.map((field, index) => (
            <Field
                key={index}
                name={field}
                component={Input}
                label={fields.length > 1 ? __('companies-and-users.address-book-companies.flyout.phone') + " " + (index + 1) : __('companies-and-users.address-book-companies.flyout.phone')}
                rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
                onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
            />
        ));

    render() {
        const {handleSubmit, localization} = this.props;

        const available_disciplines = _.sortBy(this.props.localization.disciplines, 'priority');

        return (
            <Form onSubmit={handleSubmit}>
                <Field name="name" component={Input} label={__('companies-and-users.address-book-companies.flyout.name')} />
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <FieldArray name="emails" component={this.render_emails} />
                    </div>
                    <div className={styles.f1}>
                        <FieldArray name="phones" component={this.render_phones} />
                    </div>
                    <div className={styles.f1}>
                        <div className={styles.roles}>
                            <Field name="is_manufacturer" component={Checkbox} label={__('companies-and-users.address-book-companies.flyout.manufacturer')} />
                            <Field name="is_supplier" component={Checkbox} label={__('companies-and-users.address-book-companies.flyout.representative')} />
                            <Field name="is_designer" component={Checkbox} label={__('companies-and-users.address-book-companies.flyout.designer')}  />
                            <Field name="is_client" component={Checkbox} label={__('companies-and-users.address-book-companies.flyout.client')} />
                        </div>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="disciplines" noSort component={MultiSelect} searchable label={__('companies-and-users.address-book-companies.flyout.disciplines')} options={transformToListPairs(available_disciplines, "name")} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="web" component={Input} label={__('companies-and-users.address-book-companies.flyout.web')} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="vat" component={Input} label={__('companies-and-users.address-book-companies.flyout.vat')} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f3}>
                        <Field name="address" component={Input} label={__('companies-and-users.address-book-companies.flyout.address')} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="city" component={Input} label={__('companies-and-users.address-book-companies.flyout.city')} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="zip" component={Input} label={__('companies-and-users.address-book-companies.flyout.zip-code')} />
                    </div>
                    <div className={styles.f2}>
                        <Field name="state" component={Input} label={__('companies-and-users.address-book-companies.flyout.state')} />
                    </div>
                    <div className={styles.f2}>
                        <Field name="country_id" searchable component={Select} label={__('companies-and-users.address-book-companies.flyout.country')} options={transformToListPairs(this.props.localization.countries)} />
                    </div>
                </div>
                <Field name="tags" component={Tags} label={__('companies-and-users.address-book-companies.flyout.tags')} options={this.state.possible_tags} />
                <Field name="note" component={Textarea} label={__('companies-and-users.address-book-companies.flyout.instruction')} maxLength={2000} rows={1} maxRows={10} />
            </Form>
        );
    }
}

export default EditAddressBookCompanyForm;
