import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/move_boq_position_wizard/MoveBOQPositionForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';
import Textarea from '../../components/Textarea';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'move_boq_position_wizard.move_boq_position' })
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  language_id: state.auth.language_id,
}))
class MoveBOQPositionForm extends Component {
  render() {
    const {
      options,
      store: { flyout },
      language_id,
    } = this.props;

    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const specification_subgroup =
      _.get(specification_subgroups, [flyout.specification_subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};

    const specification_code =
      getLocalized(specification_group.code, flyout.project_language, 1) +
      specification_subgroup.code +
      _.padStart(flyout.code, 2, '0');

    const specification_title = _.filter(
      [_.get(flyout.description, language_id), flyout.model, flyout.brand],
      _.identity
    ).join(' / ');

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <span className={styles.name}>
            <strong>{specification_code}</strong>
            {!_.isEmpty(specification_title) ? ' : ' : ''}
            {specification_title}
          </span>

          <Field
            name='move_action'
            component={RadioGroup}
            options={[
              {
                label: __('move-boq-item.move-before'),
                value: 'before',
              },
              {
                label: __('move-boq-item.move-after'),
                value: 'after',
              },
            ]}
          />

          <Field
            name={'boq_position_id'}
            component={Select}
            searchable
            noSort
            label={__('move-boq-position.selected-item')}
            options={options}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                move_boq_position_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.move')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default MoveBOQPositionForm;
