import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/ForgotPassword.scss";
import AuthLayout from "../../layouts/AuthLayout";
import ForgotPasswordForm from "../../forms/auth/ForgotPasswordForm";
import {__, redirect, validateForm} from "../../core/utils";
import autobind from "autobind-decorator";
import {forgotPassword} from "../../redux/actions/auth";
import classNames from "classnames";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

    @autobind
	_handleSubmit(formData) {
		return validateForm(
			forgotPassword(formData.email).then(() => {
				this.setState({email: formData.email})
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
					<div className={styles.title}>{this.state.email ? __("auth.check-email.title") : __('auth.forgot-password.title')}?</div>
				</div>
				{this.state.email ? (
					<div className={styles.details}>
						<p>{__("auth.check-email.message1")}</p>
						<p className={classNames(styles.blue, styles.strong)}>{this.state.email}</p>
						<p>{__("auth.check-email.message2")}</p>
						<p>{__("auth.check-email.message3")}</p>
						<div className={styles.info}>
							{__("auth.check-email.message4")} <a onClick={() => redirect("/login")}>{__("Sign in")}</a>
						</div>
					</div>
				) : (
					<div className={styles.form}>
						<ForgotPasswordForm onSubmit={this._handleSubmit} />
					</div>
				)}
			</>
		);
	}
}

export default ForgotPassword;