import store from "../../../core/store";

export const addPreloader = (id) => {
    return store.dispatch({
        type: "general.preloaders/ADD_PRELOADER",
        id
    });
};

export const removePreloader = (id) => {
    return store.dispatch({
        type: "general.preloaders/REMOVE_PRELOADER",
        id
    });
};