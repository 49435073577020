import store from "../../../core/store";

export const listProjectStages = (project_id) => {
    return store.dispatch({
        action: "table.stages/LIST_PROJECT_STAGES",
        method: "GET",
        endpoint: "/projects/" + project_id + "/stages",
        main: true
    });
};

export const updateStageStatus = (stage_id, status) => {
    return store.dispatch({
        action: "table.stages/UPDATE_STAGE_STATUS",
        method: "PUT",
        endpoint: "/stages/" + stage_id + "/status",
        data: {
            status
        }
    });
};

export const updateStageCurrent = (stage_id) => {
    return store.dispatch({
        action: "table.stages/UPDATE_STAGE_CURRENT",
        method: "PUT",
        endpoint: "/stages/" + stage_id + "/current",
    });
};

export const updateStageOrder = (stage_id, direction) => {
    return store.dispatch({
        action: "table.stages/UPDATE_STAGE_ORDER",
        method: "PUT",
        endpoint: "/stages/" + stage_id + "/order",
        data: {
            direction
        }
    });
};

export const readStage = (stage_id) => {
    return store.dispatch({
        action: "table.stages/READ_STAGE",
        method: "GET",
        endpoint: "/stages/" + stage_id,
    });
};

export const updateStage = (stage_id, formData) => {
    return store.dispatch({
        action: "table.stages/UPDATE_STAGE",
        method: "PUT",
        endpoint: "/stages/" + stage_id,
        data: formData
    });
};

export const getStagesPermissions = (project_id) => {
    return store.dispatch({
        action: "table.stages/GET_STAGE_PERMISSIONS",
        method: "GET",
        endpoint: "/projects/" + project_id + "/permissions"
    });
};

export const validateStageDetails = (formData) => {
    return store.dispatch({
        action: "table.stages/VALIDATE_STAGE_DETAILS",
        method: "POST",
        endpoint: "/stages/stage_details",
        data: formData
    });
};

export const validateStageDiscipline = (formData) => {
    return store.dispatch({
        action: "table.stages/VALIDATE_STAGE_DISCIPLINE",
        method: "POST",
        endpoint: "/stages/stage_discipline",
        data: formData
    });
};

export const createStage = (project_id, formData) => {
    return store.dispatch({
        action: "table.stages/CREATE_STAGE",
        method: "POST",
        endpoint: "/projects/" + project_id + "/stages",
        data: formData
    });
};

export const getStagesLanguages = (stage_id) => {
    return store.dispatch({
        action: "table.stages/GET_STAGE_LANGUAGES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/languages"
    });
};