import * as React from "react";
import {Component} from "react";
import {Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/company/CompanyMarketForm.scss";
import UncheckedIcon from "../../assets/images/unchecked-15x15.svg";
import CheckedIcon from "../../assets/images/checked-15x15.svg";
import SearchIcon from "../../assets/images/search-15x15.svg";
import NeutralIcon from "../../assets/images/neutral-15x15.svg";
import {connect} from "react-redux";
import classNames from "classnames";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import autobind from "autobind-decorator";
import {bindActionCreators} from "redux";
import {setNotification} from "../../redux/actions/general/notification";
import {enableAutosave} from "../../redux/actions/general/autosave";
import {__} from "../../core/utils";

const mapStateToProps = state => {
	return {
		values: getFormValues("company.market")(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				setNotification
			},
			dispatch
		)
	};
};
@subscribe(Localization, "localization")
@reduxForm({
	form: "company.market",
    enableReinitialize: true,
})
@autobind
@connect(
	mapStateToProps,
	mapDispatchToProps
)
class CompanyMarketForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected_region: _.get(_.find(this.props.localization.countries, { id: _.get(props, "initialValues.market[0]") }), "region_id")
		};
	}

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    @autobind
	_addCountry(id) {
		const countries = [..._.get(this.props, "values.market", []), id];

		this.props.touch("market");
		this.props.change("market", _.uniqBy(countries));
        this.props.blur("market");
	}

    @autobind
	_removeCountry(id) {
		const countries = _.filter(_.get(this.props, "values.market", []), item => item != id);

		if (countries.length > 0) {
            this.props.touch("market");
			this.props.change("market", _.uniqBy(countries));
            this.props.blur("market");
		} else {
			setNotification({
				text: __('company-setting.market.at-least-one-country-is-required'),
				type: "warning"
			});
		}
	}

    @autobind
	_addAllCountries(id) {
		const countries = [..._.get(this.props, "values.market", []), ..._.map(_.filter(this.props.localization.countries, { region_id: id }), "id")];

        this.props.touch("market");
		this.props.change("market", _.uniqBy(countries));
        this.props.blur("market");
	}

    @autobind
	_removeAllCountries(id) {
		const exclude = _.map(_.filter(this.props.localization.countries, { region_id: id }), "id");

		const countries = _.filter(_.get(this.props, "values.market", []), item => !_.includes(exclude, item));

		if (countries.length > 0) {
            this.props.touch("market");
			this.props.change("market", _.uniqBy(countries));
            this.props.blur("market");
		} else {
			setNotification({
				text: __('company-settings.market.at-least-one-region-is-required'),
				type: "warning"
			});
		}
	}

    @autobind
	_handleSearch(e) {
		this.setState({ query: e.target.value });
	}

	render() {
		let filtered_countries = _.filter(this.props.localization.countries, country => !this.state.selected_region || country.region_id == this.state.selected_region);

		if (_.get(this.state, "query")) {
			filtered_countries = _.filter(filtered_countries, item => _.includes(item.name.toLowerCase(), this.state.query.toLowerCase()));
		}

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.wrapper}>
					<div className={styles.titles}>
						<span>{__('company-settings.market.my-regions')}</span>
						<span>{_.get(_.find(this.props.localization.regions, { id: this.state.selected_region }), "name")}</span>
					</div>
					<div className={styles.selectors}>
						<div className={styles.regions}>
							{_.map(this.props.localization.regions, region => {
								const selected_countries = _.filter(
									this.props.localization.countries,
									country => country.region_id == region.id && _.includes(_.get(this.props, "values.market", []), country.id)
								).length;
								const total_countries = _.filter(this.props.localization.countries, {
									region_id: region.id
								}).length;

								return (
									<div
										key={region.id}
										className={classNames(styles.region, selected_countries > 0 && styles.hasSelectedCountries, region.id == this.state.selected_region && styles.selected)}
										onClick={() => this.setState({ selected_region: region.id })}
									>
										<div className={styles.details}>
											{region.name}
											<div className={styles.counter}>
												<span>
													{selected_countries} | {total_countries}
												</span>
												<span>{__('company-settings.market.countries')}</span>
											</div>
										</div>
										{selected_countries == 0 && <UncheckedIcon className={styles.unchecked} onClick={() => this._addAllCountries(region.id)} />}
										{selected_countries == total_countries && <CheckedIcon className={styles.checked} onClick={() => this._removeAllCountries(region.id)} />}
										{selected_countries != 0 &&
											selected_countries < total_countries && <NeutralIcon className={styles.neutral} onClick={() => this._addAllCountries(region.id)} />}
									</div>
								);
							})}
						</div>
						<div className={styles.countries}>
							<div className={styles.search}>
								<SearchIcon />
								<input type="text" placeholder={__('company-settings.market.search')} onChange={this._handleSearch} />
							</div>
							<div className={styles.list}>
								{_.map(filtered_countries, item => (
									<div
										key={item.id}
										className={classNames(styles.country, _.includes(_.get(this.props, "values.market", []), item.id) && styles.checked)}
										onClick={() => (_.includes(_.get(this.props, "values.market", []), item.id) ? this._removeCountry(item.id) : this._addCountry(item.id))}
									>
										{_.includes(_.get(this.props, "values.market", []), item.id) && <CheckedIcon />}
										{!_.includes(_.get(this.props, "values.market", []), item.id) && <UncheckedIcon />}
										{item.name}
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default CompanyMarketForm;
