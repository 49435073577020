import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import styles from "../../styles/views/plans/OCRTemplateEditor.scss";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {
    enableProjectsDropdown,
    enableStagesDropdown,
    listActiveModules
} from "../../redux/actions/general/active_modules";
import * as _ from "lodash";
import {__, dateFrom, mapStateToProps, redirect} from "../../core/utils";
import {withRouter} from "react-router";
import {readOCRTemplate, updateOCRTemplate} from "../../redux/actions/table/ocr_templates";
import OCRTemplateEditorForm from "../../forms/plans/OCRTemplateEditorForm";
import OpenSeaDragon from "openseadragon";
import {fabric} from "../../helpers/fabricjs";
import autobind from "autobind-decorator";
import {getFormValues} from "redux-form";
import {setNotification} from "../../redux/actions/general/notification";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";

@withRouter
@mapStateToProps(state => ({
    ...getFormValues("plans.ocr_template_editor")(state) || {}
}))
class OCRTemplateEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ocr_template: {},
        };

        this.OSD = null;
        this.FabricJS = null;

        this._containerWidth = 0;
        this._containerHeight = 0;

        this._planWidth = 0;
        this._planHeight = 0;

        this.objects = {};

        this.delay = null;
    }

    resize() {
        if(this.refs.fabricjs_wrapper && this.refs.fabricjs) {
            if (this._containerWidth !== this.OSD.container.clientWidth) {
                this._containerWidth = this.OSD.container.clientWidth;
                this.refs.fabricjs_wrapper.setAttribute('width', this._containerWidth);
                this.refs.fabricjs.setAttribute('width', this._containerWidth);
            }

            if (this._containerHeight !== this.OSD.container.clientHeight) {
                this._containerHeight = this.OSD.container.clientHeight;
                this.refs.fabricjs_wrapper.setAttribute('height', this._containerHeight);
                this.refs.fabricjs.setAttribute('height', this._containerHeight);
            }
        }
    };

    resizeCanvas() {
        const origin = new OpenSeaDragon.Point(0, 0);
        const viewportZoom = this.OSD.viewport.getZoom(true);
        const image1 = this.OSD.world.getItemAt(0);
        const zoom = image1.viewportToImageZoom(viewportZoom);

        this.FabricJS.setWidth(this._containerWidth);
        this.FabricJS.setHeight(this._containerHeight);
        this.FabricJS.setZoom(zoom);

        const image1WindowPoint = image1.imageToWindowCoordinates(origin);
        const x = Math.round(image1WindowPoint.x);
        const y = Math.round(image1WindowPoint.y);

        if(this.refs.fabricjs_wrapper) {
            const canvasOffset = this.refs.fabricjs_wrapper.getBoundingClientRect();

            this.FabricJS.absolutePan(new fabric.Point(canvasOffset.left - x, canvasOffset.top - y));
        }
    }

    componentWillUnmount() {
        this._save(true);
    }

    componentDidUpdate(prevProps) {
        if(!_.isEmpty(this.objects)) {
            if(prevProps.code != this.props.code) {
                this.objects.code.setVisible(this.props.code);
                this.FabricJS.deactivateAll().renderAll();
            }

            if(prevProps.title != this.props.title) {
                this.objects.title.setVisible(this.props.title);
                this.FabricJS.deactivateAll().renderAll();
            }

            if(prevProps.scale != this.props.scale) {
                this.objects.scale.setVisible(this.props.scale);
                this.FabricJS.deactivateAll().renderAll();
            }
        }

        return false;
    }

    @autobind
    _bindObjectListeners(object) {
        object.on("moving", () => {
            if(object.left < 0) {
                object.left = 0;
            } else if(object.left > (this._planWidth - object.width)) {
                object.left = this._planWidth - object.width;
            }

            if(object.top < 0) {
                object.top = 0;
            } else if(object.top > (this._planHeight - object.height)) {
                object.top = this._planHeight - object.height;
            }
        });

        object.on('scaling', () => {
            if ((object.top < 0) || object.top + object.height * object.scaleY > this._planHeight) {
                object.top = object.lastGoodTop;
                object.scaleY = object.lastGoodScaleY;
            }

            if ((object.left < 0) || (object.left + object.width * object.scaleX > this._planWidth)) {
                object.left = object.lastGoodLeft;
                object.scaleX = object.lastGoodScaleX;
            }

            object.lastGoodScaleX = object.scaleX;
            object.lastGoodScaleY = object.scaleY;
            object.lastGoodLeft = object.left;
            object.lastGoodTop = object.top;
        });

        object.on("modified", () => {
            object.width = object.width * object.scaleX;
            object.scaleX = 1;

            object.height = object.height * object.scaleY;
            object.scaleY = 1;

            this._save();
        });
    }

    @autobind
    _initEditor() {
        const {ocr_template} = this.state;

        this.OSD = OpenSeaDragon({
            element: this.refs.openseadragon,
            showNavigationControl: false,
            showNavigator: true,
            navigatorPosition: "BOTTOM_LEFT",
            defaultZoomLevel: 0,
            maxZoomLevel: 20,
            minZoomLevel: 0,
            constrainDuringPan: true,
            visibilityRatio: 1,
            tileSources: {
                height: ocr_template.height,
                width: ocr_template.width,
                tileSize: 256,
                getTileUrl: function( level, x, y ){
                    return ocr_template.tiles[[level,x,y].join('-').toString()];
                },
                overlays: [{
                    element: this.refs.fabricjs_wrapper
                }],
            },
            gestureSettingsMouse: {
                clickToZoom: false,
            },
        });

        this.OSD.addHandler('update-viewport', () => {
            this.resize();
            this.resizeCanvas();

            this._planWidth = this.OSD.source.width;
            this._planHeight = this.OSD.source.height;
        });

        this.OSD.addHandler('open', () => {
            this.resize();
            this.resizeCanvas();

            this._planWidth = this.OSD.source.width;
            this._planHeight = this.OSD.source.height;
        });

        this.resize();

        this.FabricJS = new fabric.Canvas(this.refs.fabricjs);

        this.FabricJS.selection = false;

        this.FabricJS.on('mouse:down', function (options) {
            if (options.target) {
                options.e.preventDefaultAction = true;
                options.e.preventDefault();
                options.e.stopPropagation();
            }
        });

        const code = new fabric.Rect({
            top: _.isNull(ocr_template.code_top) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.code_top),
            left: _.isNull(ocr_template.code_left) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.code_left),
            hasRotatingPoint: false,
            visible: ocr_template.code,
            width: _.isNull(ocr_template.code_width) ? ocr_template.width * 0.1 : _.toNumber(ocr_template.code_width),
            height: _.isNull(ocr_template.code_height) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.code_height),
            fill: 'rgb(173, 212, 42, 0.2)',
            stroke: 'rgb(173, 212, 42)',
            strokeWidth: 3,
        });

        this.FabricJS.add(code);
        this._bindObjectListeners(code);

        const title = new fabric.Rect({
            top: _.isNull(ocr_template.title_top) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.title_top),
            left: _.isNull(ocr_template.title_left) ? ocr_template.width * 0.1 + ocr_template.width * 0.07 : _.toNumber(ocr_template.title_left),
            hasRotatingPoint: false,
            visible: ocr_template.title,
            width: _.isNull(ocr_template.title_width) ? ocr_template.width * 0.1 : _.toNumber(ocr_template.title_width),
            height: _.isNull(ocr_template.title_height) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.title_height),
            fill: 'rgb(231, 76, 60, 0.2)',
            stroke: 'rgb(231, 76, 60)',
            strokeWidth: 3,
        });

        this.FabricJS.add(title);
        this._bindObjectListeners(title);

        const scale = new fabric.Rect({
            top: _.isNull(ocr_template.scale_top) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.scale_top),
            left: _.isNull(ocr_template.scale_left) ? ocr_template.width * 0.2 + ocr_template.width * 0.09 : _.toNumber(ocr_template.scale_left),
            hasRotatingPoint: false,
            visible: ocr_template.scale,
            width: _.isNull(ocr_template.scale_width) ? ocr_template.width * 0.1 : _.toNumber(ocr_template.scale_width),
            height: _.isNull(ocr_template.scale_height) ? ocr_template.width * 0.05 : _.toNumber(ocr_template.scale_height),
            fill: 'rgb(2, 157, 252, 0.2)',
            stroke: 'rgb(2, 157, 252)',
            strokeWidth: 3,
        });

        this.FabricJS.add(scale);
        this._bindObjectListeners(scale);

        this.objects = {
            code,
            title,
            scale
        }
    }

    @autobind
    _save(instant = false) {
        const callback = () => {
            const {code, title, scale} = this.objects;

            let formData = {
                name: this.props.name,
                alignment: this.props.alignment,
                language_id: this.props.language_id,
                code: this.props.code,
                title: this.props.title,
                scale: this.props.scale,
            };

            if(code) {
                formData.code_width = code.width;
                formData.code_height = code.height;
                formData.code_left = code.left;
                formData.code_top = code.top;
            }

            if(title) {
                formData.title_width = title.width;
                formData.title_height = title.height;
                formData.title_left = title.left;
                formData.title_top = title.top;
            }

            if(scale) {
                formData.scale_width = scale.width;
                formData.scale_height = scale.height;
                formData.scale_left = scale.left;
                formData.scale_top = scale.top;
            }

            updateOCRTemplate(this.props.match.params.ocr_template, formData).then(({}) => {
                readOCRTemplate(this.props.match.params.ocr_template).then(({response}) => {
                    this.setState({ocr_template: response.data});
                });

                setNotification(__("ocr-templates.notification.details-saved"));
            });
        };

        if(instant) {
            callback();
        } else {
            this.delay && clearTimeout(this.delay);

            this.delay = setTimeout(callback, 2000);
        }
    }
    
    componentDidMount() {
        readOCRTemplate(this.props.match.params.ocr_template).then(({response}) => {
            this.setState({ocr_template: response.data});
            
            const {ocr_template} = this.state;

            listActiveModules();

            enableProjectsDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/ocr_templates'));
            enableStagesDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/ocr_templates'));

            createSidebar({
                title: __('submenu.title.plans'),
                items: [
                    {
                        title: __('submenu.title.current-set'),
                        link: () => "/stages/" +  + "/plans/current",
                    },
                    {
                        title: __('submenu.title.working-set'),
                        link: () => "/stages/" + ocr_template.stage_id + "/plans/working",
                    },
                    {
                        title: __('submenu.title.uploads'),
                        active: true,
                        items: [
                            {
                                title: __('submenu.title.uploads-list'),
                                link: () => "/stages/" + ocr_template.stage_id + "/plans/uploads",
                            }, {
                                title: __('submenu.title.ocr-templates'),
                                link: () => "/stages/" + ocr_template.stage_id + "/ocr_templates",
                                active: true
                            }
                        ]
                    },
                    {
                        title: __('submenu.title.deliveries'),
                        link: () => "/stages/" + ocr_template.stage_id + "/deliveries",
                    },
                    {
                        title: __('submenu.title.approvals'),
                        link: () => "/stages/" + ocr_template.stage_id + "/approvals",
                    }
                ]
            });

            this._initEditor();
        });
    }

    render() {
        const {ocr_template} = this.state;

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <div className={styles.name}>
                            {ocr_template.name}
                        </div>
                        <div className={styles.details}>
                            {dateFrom(ocr_template.created_at)}
                        </div>
                    </div>
                    <div className={styles.back} onClick={() => redirect('/stages/' + ocr_template.stage_id + "/ocr_templates")}>
                        <ArrowLeftMiddleIcon /> {__('ocr-templates.tools.back-to-ocr-templates-list')}
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div className={styles.title}>{__('ocr-templates.ocr-template-settings')}</div>
                        {!_.isEmpty(ocr_template) && <OCRTemplateEditorForm
                            onSubmit={this._save}
                            initialValues={{
                                ..._.pick(ocr_template, ['name', 'language_id', 'code', 'title', 'scale']),
                                alignment: _.isNull(ocr_template.alignment) ? 'br' : ocr_template.alignment,
                            }}
                            data={ocr_template}
                        />}
                    </div>
                    <div className={styles.right}>
                        <div ref="openseadragon" className={styles.openseadragon} onClick={() => this.OSD.setFullScreen(true)} />
                        <div ref="fabricjs_wrapper" className={styles.fabricjsWrapper}>
                            <canvas ref="fabricjs" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default OCRTemplateEditor;