import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddBOQTemplateSubgroupWizard.scss";
import autobind from "autobind-decorator";
import AddBOQTemplateSubgroupIcon from "../../assets/images/add-boq-template-subgroup-60x60.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createBOQTemplateSubgroup, listBOQTemplateSubgroups } from "../../redux/actions/table/superadmin/boq_template_subgroups";
import AddBOQTemplateSubgroupForm from "../../forms/superadmin/add_boq_template_subgroups_wizard/AddBOQTemplateSubgroupForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_boq_template_subgroups"],
}))
class AddBOQTemplateSubgroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createBOQTemplateSubgroup(formData).then(({ response }) => {
				listBOQTemplateSubgroups().then(() => {
					if (edit) {
						setTableParams('superadmin_boq_template_subgroups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_boq_template_subgroups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new BOQ template subgroup.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add BOQ template subgroup</span>
						<span>Add BOQ template subgroup to system BOQ template subgroups</span>
					</div>
					<div className={styles.right}>
						<AddBOQTemplateSubgroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddBOQTemplateSubgroupForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
						boq_template_group_id: null
					}} />
				</div>
			</>
		);
	}
}

export default AddBOQTemplateSubgroupWizard;