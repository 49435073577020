import styles from "./NumericInput.scss";
import Cleave from "cleave.js/dist/cleave-react-node";
import React, {useState} from "react";
import LockClosedSmall from "../../../assets/images/lock-closed-small-16x16.svg";
import {unformatMoney, formatMoney} from "../../../core/utils";
import classNames from "classnames";
import Tooltip from "../../../components/Tooltip";

const NumericInput = ({defaultValue = '', onBlur, readOnly, tooltip, ...rest}) => {
    const [value, setValue] = useState(formatMoney(defaultValue));

    return (
        <Tooltip text={tooltip}>
            <div className={classNames(styles.wrapper, readOnly && styles.readOnly)}>
                {readOnly && <LockClosedSmall />}
                <Cleave
                    {...rest}
                    readOnly={readOnly}
                    className={styles.input}
                    options={{
                        numericOnly: true,
                        numeral: true,
                        numeralDecimalScale: 2,
                        numeralDecimalMark: ',',
                        delimiter: '.'
                    }}
                    type="text"
                    value={value}
                    onChange={(e) => setValue(e.currentTarget.value)}
                    onKeyDown={e => e.key === "Enter" && e.currentTarget.blur()}
                    onBlur={() => onBlur(unformatMoney(value))}
                />
            </div>
        </Tooltip>
    )
}

export default NumericInput;