import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Table from '../../components/Table';
import {
  __,
  getLocalized,
  getURLParam,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { createSuperadminSidebar } from '../../redux/actions/general/sidebar';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import { setPlusActions } from '../../redux/actions/general/plus';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import {} from '../../redux/actions/table/superadmin/boq_template_calculations';
import BOQTemplateSubgroupsFlyout from '../../flyouts/superadmin/boq_template_calculations/BOQTemplateCalculationsFlyout';
import TableNA from '../../components/TableNA';
import { readBOQTemplateCalculation } from '../../redux/actions/table/superadmin/boq_template_calculations';
import { deleteBOQTemplateCalculations } from '../../redux/actions/table/superadmin/boq_template_calculations';
import { listBOQTemplateCalculations } from '../../redux/actions/table/superadmin/boq_template_calculations';

@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  table: state.table['superadmin_boq_template_calculations'],
  application_language_id: state.auth.language_id,
}))
class BOQTemplateCalculations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    listActiveModules();
    createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

    const { superadmin } = this.props;

    superadmin.add_boq_template_calculation &&
      setPlusActions([
        {
          title: 'Add BOQ template calculation',
          onClick: () =>
            setTableParams('superadmin_boq_template_calculations', {
              add_boq_template_calculation_wizard: true,
            }),
        },
      ]);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readBOQTemplateCalculation(this.props.table.clicked_row).then(
        ({ response }) => {
          this.setState({ preview: response.data });
        }
      );
    }
  }

  render() {
    const { superadmin, application_language_id } = this.props;

    const columns = [
      {
        key: 'id',
        name: 'ID',
        width: 60,
        sortable: false,
      },
      {
        key: 'name',
        name: 'Name',
        value: (name) => getLocalized(name, application_language_id),
      },
      {
        key: 'boq_template_subgroup',
        name: 'BOQ Template Subgroup',
        value: (boq_template_subgroup) => {
          const translation = getLocalized(
            boq_template_subgroup,
            application_language_id
          );

          return <Tooltip text={translation}>{translation}</Tooltip>;
        },
      },
      {
        key: 'unit_id',
        name: 'Unit',
      },
      {
        key: 'price',
        name: 'Price',
      },
    ];

    const singleActions = (boq_template_calculation_id) => {
      return [
        <Tooltip
          text='Delete selected BOQ template calculation'
          key={_.uniqueId()}
        >
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description:
                  'Do you really want to delete the selected BOQ template calculation?\n\nThis process cannot be undone.',
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteBOQTemplateCalculations([
                        boq_template_calculation_id,
                      ]).then(() => {
                        hideAlertbox();

                        setNotification(
                          'The selected BOQ template calculation has been deleted.'
                        );
                        listBOQTemplateCalculations();

                        setTableParams('superadmin_boq_template_calculations', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (boq_template_grousubps_ids) => [
      <Tooltip
        text='Delete selected BOQ template calculations'
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description:
                'Do you really want to delete these BOQ template calculations?\n\nThis process cannot be undone.',
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteBOQTemplateCalculations(
                      boq_template_grousubps_ids
                    ).then(() => {
                      hideAlertbox();

                      setNotification(
                        'The selected BOQ template calculations have been deleted.'
                      );
                      listBOQTemplateCalculations();

                      setTableParams('superadmin_boq_template_calculations', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <BOQTemplateSubgroupsFlyout
        data={this.state.preview}
        readAction={(boq_template_calculation_id) => {
          return new Promise((resolve) => {
            readBOQTemplateCalculation(
              getURLParam('id') || boq_template_calculation_id
            ).then(({ response }) => {
              this.setState({ preview: response.data });

              resolve(response);
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={listBOQTemplateCalculations}
        name='superadmin_boq_template_calculations'
        groupActions={
          superadmin.delete_boq_template_calculation && groupActions
        }
        singleActions={
          superadmin.delete_boq_template_calculation && singleActions
        }
        title={__('modules.submodules.title.boq-template-calculations')}
        preview={this.state.preview ? preview : []}
        columns={columns}
      />
    );
  }
}

export default BOQTemplateCalculations;
