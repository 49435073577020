import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateTimeFrom,
  getActiveStage,
  goBack,
  guessPaperSize,
  ptToMm,
  redirect,
} from '../../core/utils';
import Table from '../../components/Table';
import { readMyDelivery } from '../../redux/actions/table/my_deliveries';
import { connect } from 'react-redux';
import Tooltip from '../../components/Tooltip';
import { readPublicDelivery } from '../../redux/actions/table/public_deliveries';
import styles from '../../styles/views/plans/DeliveryPlans.scss';
import {
  deleteDeliveryPlans,
  listDeliveryPlans,
} from '../../redux/actions/table/delivery_plans';
import ArrowRightSmallIcon from '../../assets/images/arrow-right-small-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import Image from '../../components/Image';

const mapStateToProps = (state) => {
  return {
    table: state.table['delivery_plans'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.delivery_plans/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class DeliveryPlans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery_title: null,
    };
  }

  componentDidMount() {
    if (this.props.match.params.type == 'my') {
      readMyDelivery(this.props.match.params.delivery).then(({ response }) => {
        this.setState({
          delivery_title: response.data.title,
          unsent: response.data.delivered_at == null,
        });
      });
    } else if (this.props.match.params.type == 'public') {
      readPublicDelivery(this.props.match.params.delivery).then(
        ({ response }) => {
          this.setState({ delivery_title: response.data.title });
        }
      );
    }

    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/deliveries' +
          (this.props.match.params.type == 'my' ? '/my' : '')
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/deliveries' +
          (this.props.match.params.type == 'my' ? '/my' : '')
      )
    );

    createSidebar({
      title: __('submenu.title.plans'),
      items: [
        {
          title: __('submenu.title.current-set'),
          link: () => '/stages/' + getActiveStage() + '/plans/current',
        },
        {
          title: __('submenu.title.working-set'),
          link: () => '/stages/' + getActiveStage() + '/plans/working',
        },
        {
          title: __('submenu.title.uploads'),
          link: () => '/stages/' + getActiveStage() + '/plans/uploads',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () => '/stages/' + getActiveStage() + '/deliveries',
          active: true,
          items: [
            {
              title: __('submenu.title.deliveries.public'),
              link: () => '/stages/' + getActiveStage() + '/deliveries',
              active: this.props.match.params.type == 'public',
            },
            {
              title: __('submenu.title.deliveries.my-team'),
              link: () => '/stages/' + getActiveStage() + '/deliveries/my',
              active: this.props.match.params.type == 'my',
            },
          ],
        },
        {
          title: __('submenu.title.approvals'),
          link: () => '/stages/' + getActiveStage() + '/approvals',
        },
      ],
    });
  }

  _handleOpacity(value, row) {
    return row.status == '1' ? (
      value
    ) : (
      <div className={styles.hidden}>{value}</div>
    );
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('plans.table.title.code'),
        width: 80,
      },
      {
        key: 'thumb',
        name: __('plans.table.thumbnail'),
        value: (value, row) =>
          this._handleOpacity(
            row.thumbnail_url ? (
              <Tooltip
                placement='right'
                text={
                  <Image
                    className={styles.thumbPreview}
                    src={row.thumbnail_url}
                  />
                }
                html={true}
                dark={true}
              >
                <Image className={styles.thumb} src={row.thumbnail_url} />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'title',
        name: __('plans.table.title.title'),
        required: true,
      },
      {
        key: 'discipline',
        name: __('plans.deliveries.columns.disciplines'),
        value: (discipline, row) => (
          <Tooltip
            text={[
              row.discipline.code,
              row.discipline.id == null
                ? row.discipline.description + '*'
                : _.get(this.props.localization.disciplines, [
                    row.discipline.id,
                    'name',
                  ]),
            ].join(': ')}
          >
            {row.discipline.code}
          </Tooltip>
        ),
      },
      {
        key: 'team',
        name: __('plans.deliveries.columns.team'),
        value: (team) => team.company,
      },
      {
        key: 'size',
        name: __('plans.table.title.size'),
        value: (size, row) =>
          row.width == 0 && row.height == 0 ? (
            <TableNA />
          ) : (
            <Tooltip
              text={
                guessPaperSize(ptToMm(row.width), ptToMm(row.height))
                  .description
              }
            >
              {guessPaperSize(ptToMm(row.width), ptToMm(row.height)).size}
            </Tooltip>
          ),
        width: 50,
      },
      {
        key: 'scale',
        name: __('plans.table.title.scale'),
        width: 80,
        fullWidth: true,
        value: (scale, row) =>
          _.join([_.toNumber(row.scale1), _.toNumber(row.scale2)], ':'),
      },
      {
        key: 'version',
        name: __('plans.table.title.version'),
        value: (value) => 'V' + value,
        width: 60,
      },
      {
        key: 'revision',
        name: __('plans.table.title.revision'),
        value: (value) => (!_.isNull(value) ? 'R' + value : <TableNA />),
        width: 60,
      },
      {
        key: 'created_at',
        name: __('plans.table.title.added'),
        default: true,
        desc: true,
        value: (value) => dateTimeFrom(value),
        width: 120,
      },
      {
        key: 'created_by',
        name: __('plans.table.title.added-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
      {
        key: 'status',
        name: __('Status'),
        value: (status) => (
          <>
            {status === 0 && (
              <div className={styles.pending}>
                {__('submenu.title.approvals.pending')}
              </div>
            )}
            {status === 1 && (
              <div className={styles.approved}>
                {__('submenu.title.approvals.approved')}
              </div>
            )}
            {status === 2 && (
              <div className={styles.rejected}>
                {__('submenu.title.approvals.rejected')}
              </div>
            )}
          </>
        ),
        width: 120,
      },
    ];

    const singleActions = (plan_version_id) => {
      return !this.state.unsent
        ? []
        : [
            <Tooltip text={__('Delete plan from delivery')} key={_.uniqueId()}>
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();

                  showAlertbox({
                    color: 'red',
                    title: __('general.alert.are-you-sure'),
                    description:
                      'Do you really want to delete the selected plan from this delivery?\nThis process cannot be undone.',
                    buttons: [
                      {
                        color: 'lightGray',
                        text: __('general.alert.no-close'),
                        onClick: 'close',
                      },
                      {
                        color: 'gray',
                        text: __('general.alert.yes-delete'),
                        onClick: () => {
                          deleteDeliveryPlans(
                            this.props.match.params.delivery,
                            [plan_version_id]
                          ).then(() => {
                            hideAlertbox();

                            setNotification(
                              __(
                                'The selected plan has been deleted from the delivery.'
                              )
                            );
                            listDeliveryPlans(this.props.match.params.delivery);

                            this.props.actions.setTableParams({
                              actioned_row: undefined,
                            });
                          });
                        },
                      },
                    ],
                  });
                }}
              />
            </Tooltip>,
          ];
    };

    const groupActions = (plan_version_ids) =>
      !this.state.unsent
        ? []
        : [
            <Tooltip text={__('Delete plans from delivery')} key={_.uniqueId()}>
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();

                  showAlertbox({
                    color: 'red',
                    title: __('general.alert.are-you-sure'),
                    description:
                      'Do you really want to delete the selected plans from this delivery?\nThis process cannot be undone.',
                    buttons: [
                      {
                        color: 'lightGray',
                        text: __('general.alert.no-close'),
                        onClick: 'close',
                      },
                      {
                        color: 'gray',
                        text: __('general.alert.yes-delete'),
                        onClick: () => {
                          deleteDeliveryPlans(
                            this.props.match.params.delivery,
                            plan_version_ids
                          ).then(() => {
                            hideAlertbox();

                            setNotification(
                              __(
                                'The selected plans have been deleted from the delivery.'
                              )
                            );
                            listDeliveryPlans(this.props.match.params.delivery);

                            this.props.actions.setTableParams({
                              selected_rows: undefined,
                            });
                          });
                        },
                      },
                    ],
                  });
                }}
              />
            </Tooltip>,
          ];

    const gridOptions = {
      section: 'Plans',
      image: (id, row) => row.thumbnail_url,
      title: (row) => row.code,
      rightTitle: (row) => (
        <>
          {row.status === 0 && <div className={styles.circleP}>P</div>}
          {row.status === 1 && <div className={styles.circleA}>A</div>}
          {row.status === 2 && <div className={styles.circleR}>R</div>}
        </>
      ),
      subTitle: (row) => row.title,
      content: (row) => [
        <>
          {dateTimeFrom(row.created_at)} by {row.created_by.fullname}
        </>,
        (row.width == 0 && row.height == 0
          ? ''
          : guessPaperSize(ptToMm(row.width), ptToMm(row.height)).size +
            ' (' +
            guessPaperSize(ptToMm(row.width), ptToMm(row.height)).description +
            ') | ') +
          _.join([_.toNumber(row.scale1), _.toNumber(row.scale2)], ':'),
      ],
    };

    return (
      <Table
        listAction={() => listDeliveryPlans(this.props.match.params.delivery)}
        name='delivery_plans'
        title={
          <div className={styles.breadcrumbs}>
            {this.state.delivery_title && (
              <>
                {this.props.match.params.type == 'my' && (
                  <span
                    onClick={() =>
                      window.previousLocation
                        ? goBack()
                        : redirect(
                            '/stages/' +
                              this.props.match.params.stage +
                              '/deliveries/my'
                          )
                    }
                  >
                    {__('breadcrumbs.title.my-team-deliveries')}
                  </span>
                )}
                {this.props.match.params.type == 'public' && (
                  <span
                    onClick={() =>
                      window.previousLocation
                        ? goBack()
                        : redirect(
                            '/stages/' +
                              this.props.match.params.stage +
                              '/deliveries/'
                          )
                    }
                  >
                    {__('breadcrumbs.title.public-deliveries')}
                  </span>
                )}
                <ArrowRightSmallIcon />
                {this.state.delivery_title}
              </>
            )}
          </div>
        }
        gridOptions={gridOptions}
        columns={columns}
        singleActions={singleActions}
        groupActions={groupActions}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default DeliveryPlans;
