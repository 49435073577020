import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/profile/SecurityForm.scss";
import Input from "../../components/Input";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import {__, mapStateToProps} from "../../core/utils";

@subscribe(Localization, "localization")
@reduxForm({
	form: "profile.security",
})
@mapStateToProps(state => ({
	values: getFormValues("profile.security")(state)
}))
class SecurityForm extends Component {
	render() {
       return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				<Field name="code" component={Input} label="Code" hint="6-digit code" numeric maxLength={6} />
				<Button middle left submit leftText={__('Verify')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
			</Form>
		);
	}
}

export default SecurityForm;
