import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/InviteNewUserWizard.scss";
import autobind from "autobind-decorator";
import UserNewIcon from "../assets/images/user-new-60x60.svg";
import {__, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import {inviteNewUser, listAllCompanies} from "../redux/actions/table/all_companies";
import InviteNewUserForm from "../forms/invite_new_user_wizard/InviteNewUserForm";
import {listPartnerMembers} from "../redux/actions/table/partner_members";

@withRouter
class InviteNewUserWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
            inviteNewUser(formData).then(({response}) => {
            	if(this.props.match.path == "/companies") {
					listAllCompanies();
				} else if(this.props.match.path == "/companies/partner_members") {
					listPartnerMembers();
				}

				if(_.get(response, 'data.invite') === false) {
					setNotification("A user with the entered email already exists in the application.\n\nInstead, a user has been added to Partner Members.");
				} else {
					setNotification("You have successfully sent an invitation.");
				}

				this.props.onClose();
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.invite-new-user.title')}</span>
						<span>{__('wizard.invite-new-user.subtitle')}</span>
					</div>
					<div className={styles.right}>
						<UserNewIcon />
					</div>
				</div>
				<div className={styles.body}>
					<InviteNewUserForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default InviteNewUserWizard;
