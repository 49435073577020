import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Table from '../../components/Table';
import {
  __,
  getURLParam,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import {
  createSidebar,
  createSuperadminSidebar,
} from '../../redux/actions/general/sidebar';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import styles from '../../styles/views/superadmin/Notes.scss';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import { setPlusActions } from '../../redux/actions/general/plus';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import classNames from 'classnames';
import NotesFlyout from '../../flyouts/superadmin/notes/NotesFlyout';
import {
  deleteNotes,
  listNotes,
  readNote,
} from '../../redux/actions/table/superadmin/notes';
import TableNA from '../../components/TableNA';

@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  table: state.table['superadmin_notes'],
  auth: state.auth,
}))
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    listActiveModules();
    createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

    const { superadmin } = this.props;

    superadmin.add_note &&
      setPlusActions([
        {
          title: 'Add Instruction',
          onClick: () =>
            setTableParams('superadmin_notes', {
              add_note_wizard: true,
            }),
        },
      ]);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readNote(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  render() {
    const { superadmin } = this.props;

    const columns = [];

    _.each(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (language) =>
        columns.push({
          key: 'note' + language.id,
          name: ['Instruction', language.name].join(' '),
          value: (x, row) => {
            const translation = _.get(row.note, language.id);

            return (
              <Tooltip text={translation}>{translation || <TableNA />}</Tooltip>
            );
          },
        })
    );

    columns.push({
      key: 'priority',
      name: 'Priority',
      width: 80,
      value: (priority) => priority,
    });

    columns.push({
      key: 'note_category',
      name: 'Instruction category',
      width: 80,
      value: (note_category) => note_category,
    });

    const singleActions = (note_id) => {
      return [
        <Tooltip text='Delete selected instruction' key={_.uniqueId()}>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description:
                  'Do you really want to delete the selected instruction?\nThis process cannot be undone.',
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteNotes([note_id]).then(() => {
                        hideAlertbox();

                        setNotification(
                          'The selected instruction has been deleted.'
                        );
                        listNotes();

                        setTableParams('superadmin_notes', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (notes_ids) => [
      <Tooltip text='Delete selected instructions' key={_.uniqueId()}>
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description:
                'Do you really want to delete these instructions?\nThis process cannot be undone.',
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteNotes(notes_ids).then(() => {
                      hideAlertbox();

                      setNotification(
                        'The selected instructions have been deleted.'
                      );
                      listNotes();

                      setTableParams('superadmin_notes', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <NotesFlyout
        data={this.state.preview}
        readAction={(note_id) => {
          return new Promise((resolve) => {
            readNote(getURLParam('id') || note_id).then(({ response }) => {
              this.setState({ preview: response.data });

              resolve(response);
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={listNotes}
        name='superadmin_notes'
        groupActions={superadmin.delete_note && groupActions}
        singleActions={superadmin.delete_note && singleActions}
        title={__('modules.submodules.title.instructions')}
        preview={this.state.preview ? preview : []}
        columns={columns}
      />
    );
  }
}

export default Notes;

// columns.push({
//   key: 'product_categories',
//   name: 'Categories',
//   width: 80,
//   value: (product_categories) => {
//     return (
//       <Tooltip text={_.join(_.map(product_categories, 'name'), '\n')}>
//         <span className={styles.chip}>{_.size(product_categories)}</span>
//       </Tooltip>
//     );
//   },
// });

// columns.push({
//   key: 'product_groups',
//   name: 'Groups',
//   width: 80,
//   value: (product_groups) => {
//     return (
//       <Tooltip text={_.join(_.map(product_groups, 'name'), '\n')}>
//         <span className={styles.chip}>{_.size(product_groups)}</span>
//       </Tooltip>
//     );
//   },
// });

// columns.push({
//   key: 'product_subgroups',
//   name: 'Subgroups',
//   width: 80,
//   value: (product_subgroups) => {
//     return (
//       <Tooltip text={_.join(_.map(product_subgroups, 'name'), '\n')}>
//         <span className={styles.chip}>{_.size(product_subgroups)}</span>
//       </Tooltip>
//     );
//   },
// });
