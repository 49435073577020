import * as React from "react";
import {Component} from "react";
import {Form, reduxForm, Field} from "redux-form";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/products/VariantRowForm.scss";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import * as _ from "lodash";
import classNames from "classnames";
import {__, mapStateToProps, setTableParams} from "../../core/utils";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import {deleteProductVariant, readProduct} from "../../redux/actions/table/products";
import {setNotification} from "../../redux/actions/general/notification";
import ArrowDownSmallIcon from "../../assets/images/arrow-down-small-15x15.svg";
import autobind from "autobind-decorator";
import Tooltip from "../../components/Tooltip";
import Select from "../../components/Select";

@reduxForm({
    enableReinitialize: true,
})
@mapStateToProps((state) => ({
    store: state.table.products,
    application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
class VariantRowForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    @autobind
    _deleteVariant(e, product_variant_id) {
        e.stopPropagation();

        showAlertbox({
            color: "red",
            title: __('general.alert.are-you-sure'),
            description: __("products.alert.are-you-sure.delete-product-variant"),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => deleteProductVariant(product_variant_id).then(() => readProduct(this.props.store.flyout.id).then(({response}) => {
                        hideAlertbox();

                        setNotification(__('products.variants.variant-deleted'));

                        setTableParams('products', {
                            flyout: response.data
                        })
                    }))
                }
            ]
        })
    }

    render() {
        const {attributes, handleSubmit, variant} = this.props;

        return (
            <Form onSubmit={handleSubmit} className={styles.row}>
                {_.map(attributes, (attribute, i) => (
                    <div key={i} className={styles.attribute}>
                        <Field name={`attribute-${attribute.id}`} component={Select} noSort placeholder="--" top={28} options={attribute.options} />
                    </div>
                ))}
                <div className={styles.actions}>
                    <Tooltip text={__('products.variants.delete-product-variant')}>
                        <CloseMiddleIcon onClick={(e) => this._deleteVariant(e, variant.id)} />
                    </Tooltip>
                </div>
            </Form>
        );
    }
}

export default VariantRowForm;