import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/products/Images.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";
import PlusBigIcon from "../../../assets/images/plus-big-20x20.svg";
import autobind from "autobind-decorator";
import CloseSmallIcon from "../../../assets/images/close-small-15x15.svg";
import {setNotification} from "../../../redux/actions/general/notification";
import {
    deleteProductImage,
    listProducts,
    readProduct,
    reorderProductImages,
    uploadProductImages
} from "../../../redux/actions/table/products";
import {__, mapStateToProps, setTableParams} from "../../../core/utils";
import {hideAlertbox, showAlertbox} from "../../../redux/actions/general/alertbox";
import {showLightbox} from "../../../redux/actions/general/lightbox";
import {ReactSortable} from "react-sortablejs";

@mapStateToProps(state => ({
    flyout: state.table.superadmin_products.flyout
}))
class Images extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: []
        };
    }

    @autobind
    _toggleCollapseCategory(category) {
        this.setState({
            expanded: _.xor(this.state.expanded, [category])
        })
    }

    render() {
        const {collapsed, flyout} = this.props;
        const {expanded} = this.state;

        const categories = [
            {
                label: __('products.flyout.images.thumbnail'),
                value: "thumbnail",
            }, {
                label: __('products.flyout.images.product'),
                value: "product",
            }, {
                label: __('products.flyout.images.drawing'),
                value: "drawing",
            }, {
                label: __('products.flyout.images.ambient'),
                value: "ambient",
            }, {
                label: __('products.flyout.images.technical'),
                value: "technical",
            }, {
                label: __('products.flyout.images.tileable-3d'),
                value: "tileable",
            }
        ];

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                {_.map(categories, category => {
                    const category_images = _.sortBy(_.filter(flyout.images, ['category', category.value]), 'order');

                    const lightbox_images = _.map(category_images, image => ({
                        id: image.id,
                        stream_url: image.stream_url,
                        title: [image.name, image.ext].join('.')
                    }));

                    return _.isEmpty(category_images) ? '' : (
                        <div className={styles.category} key={category.value}>
                            <div className={styles.title} onClick={() => this._toggleCollapseCategory(category.value)}>
                                <ArrowDownMiddleIcon className={classNames(styles.collapse, !_.includes(expanded, category.value) && styles.collapsed)} />
                                {category.label} {!_.isEmpty(category_images) && '(' + _.size(category_images) + ')'}
                            </div>
                            {_.includes(expanded, category.value) && <div className={styles.content}>
                                {_.map(category_images, (image, i) => (
                                    <div key={i} className={styles.thumbnail} onClick={() => showLightbox(lightbox_images, image.id)} style={{backgroundImage: "url('" + image.thumb_url + "')"}}/>
                                ))}
                            </div>}
                        </div>
                    )
                })}
            </div>
        );
    }
}

export default Images;
