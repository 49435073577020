import store from '../../core/store';

export const setNotificationCount = (count) => {
  return store.dispatch({
    type: 'auth/SET_NOTIFICATION_COUNT',
    count,
  });
};

export const login = (formData) => {
  return store.dispatch({
    action: 'auth/LOGIN',
    method: 'POST',
    endpoint: '/auth/login',
    data: formData,
  });
};

export const getUser = () => {
  return store.dispatch({
    action: 'auth/GET_USER',
    method: 'POST',
    endpoint: '/auth/get_user',
  });
};

export const resendConfirmationEmail = (email) => {
  return store.dispatch({
    action: 'auth/RESEND_CONFIRMATION_EMAIL',
    method: 'POST',
    endpoint: '/auth/resend_confirmation',
    data: {
      email,
    },
  });
};

export const forgotPassword = (email) => {
  return store.dispatch({
    action: 'auth/FORGOT_PASSWORD',
    method: 'POST',
    endpoint: '/auth/forgot_password',
    data: {
      email,
    },
  });
};

export const resetPassword = (token, formData) => {
  return store.dispatch({
    action: 'auth/RESET_PASSWORD',
    method: 'PUT',
    endpoint: '/reset_password/' + token,
    data: formData,
  });
};

export const logout = () => {
  return store.dispatch({
    action: 'auth/LOGOUT',
    method: 'POST',
    endpoint: '/auth/logout',
  });
};

export const register = (formData) => {
  return store.dispatch({
    action: 'auth/REGISTER',
    method: 'POST',
    endpoint: '/auth/register',
    data: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...formData,
    },
  });
};

export const getRegistrationInvitation = (invitation_type, token) => {
  return store.dispatch({
    action: 'auth/GET_REGISTRATION_INVITATION',
    method: 'POST',
    endpoint: '/auth/register/invitation',
    data: {
      invitation_type,
      token,
    },
  });
};
