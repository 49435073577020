import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import Textarea from '../../components/Textarea';
import { __, mapStateToProps } from '../../core/utils';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/plans/EditWorkingSetPlanForm.scss';
import Input from '../../components/Input';
import Image from '../../components/Image';
import Tags from '../../components/Tags';
import Select from '../../components/Select';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import autobind from 'autobind-decorator';
import { readStage } from '../../redux/actions/table/stages';
import { listPossibleTags } from '../../redux/actions/general';
import { updatePlanSpaceAllocation } from '../../redux/actions/table/plan_uploads';
import { listWorkingSetPlans } from '../../redux/actions/table/working_set_plans';
import Dropdown from '../../components/Dropdown';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import classNames from 'classnames';
import RoomChooser from '../../components/RoomChooser';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../../redux/actions/general/notification';

@reduxForm({
  form: 'plans.edit_working_set_plan',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  auth: state.auth,
  values: getFormValues('plans.edit_working_set_plan')(state),
}))
@withRouter
class EditWorkingSetPlanForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stage_tags: [],
      code: '',
      title: '',
      scale: '',
    };
  }

  componentDidMount() {
    readStage(this.props.stageId).then(({ response }) => {
      listPossibleTags('project', response.data.project_id).then(
        ({ response }) => {
          this.setState({ stage_tags: response.data });
        }
      );
    });
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _handleTempCode() {
    this.setState({
      code: this.props.values.code,
    });
  }

  @autobind
  _handleChangePlanCode(e, code) {
    e.preventDefault();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'plans.edit-working-set-plan.alert.code-change-confirmation'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('code');
            this.props.change('code', this.state.code);
            this.props.blur('code');
          },
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('code');
            this.props.change('code', this.props.values.code);
            this.props.blur('code');
          },
        },
      ],
    });
  }

  @autobind
  _handleTempTitle() {
    this.setState({
      title: this.props.values.title,
    });
  }

  @autobind
  _handleChangePlanTitle(e, title) {
    e.preventDefault();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'plans.edit-working-set-plan.alert.title-change-confirmation'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('title');
            this.props.change('title', this.state.title);
            this.props.blur('title');
          },
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('title');
            this.props.change('title', this.props.values.title);
            this.props.blur('title');
          },
        },
      ],
    });
  }

  @autobind
  _handleTempScale() {
    this.setState({
      scale: this.props.values.scale,
    });
  }

  @autobind
  _handleChangePlanScale(e, scale) {
    e.preventDefault();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'plans.edit-working-set-plan.alert.scale-change-confirmation'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('scale');
            this.props.change('scale', this.state.scale);
            this.props.blur('scale');
          },
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('scale');
            this.props.change('scale', this.props.values.scale);
            this.props.blur('scale');
          },
        },
      ],
    });
  }

  @autobind
  _handleChangeStatus(e, status) {
    if (status == '0') {
      e.preventDefault();

      showAlertbox({
        color: 'red',
        title: __('general.alert.are-you-sure'),
        description: __(
          'plans.edit-working-set-plan.alert.status-cancel-confirmation'
        ),
        buttons: [
          {
            color: 'lightGray',
            text: __('general.alert.no'),
            onClick: 'close',
          },
          {
            color: 'gray',
            text: __('general.alert.yes'),
            onClick: () => {
              hideAlertbox();

              this.props.touch('status');
              this.props.change('status', '0');
              this.props.blur('status');
            },
          },
        ],
      });
    }
  }

  @autobind
  _handleSpaceAllocation(values) {
    const { id } = this.props.data;

    this.props.change('space_allocation', values);

    updatePlanSpaceAllocation(id, {
      space_allocation: _.reduce(
        _.keys(values),
        (space_allocation, value) => {
          const [space_allocation_type, space_allocation_id] = _.split(
            value,
            ':'
          );

          if (!_.isArray(space_allocation[space_allocation_type])) {
            space_allocation[space_allocation_type] = [];
          }

          space_allocation[space_allocation_type].push(space_allocation_id);

          return space_allocation;
        },
        {}
      ),
    })
      .then(() => {
        this.props.match.params.stage &&
          listWorkingSetPlans(this.props.match.params.stage);
      })
      .then(() => {
        setNotification(__('plans.properties.saved'));
      });

    hideDropdown();
  }

  render() {
    const { data, possibleSpaceAllocation } = this.props;

    const initialSpaceAllocation = _.get(
      this.props.values,
      'space_allocation',
      []
    );

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className={styles.imageSection}>
          <div className={styles.image}>
            <Image src={this.props.previewUrl} />
          </div>
          <div className={styles.data}>
            <Field
              name='status'
              disabled={
                !this.props.isEditor ||
                _.get(this.props.values, 'status') == '0'
              }
              onChange={this._handleChangeStatus}
              component={Select}
              noSort
              label={__('Status')}
              options={[
                {
                  label: __('Canceled'),
                  value: '0',
                },
                {
                  label: __('Active'),
                  value: '1',
                },
              ]}
            />
            <Field
              name='scale'
              onFocus={
                this.props.hasRevisions ? this._handleTempScale : undefined
              }
              onBlur={
                this.props.hasRevisions
                  ? this._handleChangePlanScale
                  : undefined
              }
              component={Input}
              label={__('Scale')}
              disabled={
                !this.props.isEditor ||
                _.get(this.props.values, 'status') == '0'
              }
            />
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='code'
                onFocus={
                  this.props.hasRevisions ? this._handleTempCode : undefined
                }
                onBlur={
                  this.props.hasRevisions
                    ? this._handleChangePlanCode
                    : undefined
                }
                disabled={
                  !this.props.isEditor ||
                  _.get(this.props.values, 'status') == '0'
                }
                component={Input}
                label={__('Code')}
              />
            </div>
            <div className={styles.f2}>
              <Field
                name='title'
                onFocus={
                  this.props.hasRevisions ? this._handleTempTitle : undefined
                }
                onBlur={
                  this.props.hasRevisions
                    ? this._handleChangePlanTitle
                    : undefined
                }
                disabled={
                  !this.props.isEditor ||
                  _.get(this.props.values, 'status') == '0'
                }
                maxLength={70}
                component={Input}
                label={__('Title')}
              />
            </div>
          </div>
          <Field
            name='description'
            disabled={
              !this.props.isEditor || _.get(this.props.values, 'status') == '0'
            }
            component={Textarea}
            localized={this.props.projectLanguages}
            label={__('Description')}
            maxLength={70}
            rows={1}
            maxRows={10}
          />
        </div>
        <div className={styles.section}>
          <Dropdown
            top={25}
            right={-20}
            wrapperClassName={styles.dropdownWrapper}
            name={'plans_flyout_area.' + data.id}
            header={
              <div className={styles.dropdownHeader}>
                {__('table.area-tab.area')}
              </div>
            }
            content={
              <div className={classNames(styles.dropdownContent, styles.space)}>
                <RoomChooser
                  multiselect={true}
                  onCancel={hideDropdown}
                  options={possibleSpaceAllocation}
                  onDone={this._handleSpaceAllocation}
                  initialValues={initialSpaceAllocation}
                />
              </div>
            }
          >
            <label className={styles.spaceAllocationLabel}>
              {__('general.label.space-allocation')}
            </label>
            <div className={styles.tagsGroup} onClick={this._handleOpen}>
              <div className={styles.tags}>
                {_.map(_.sortBy(initialSpaceAllocation), (tag, i) => (
                  <span key={i}>{tag.display_name}</span>
                ))}
              </div>
              {!this.props.center && !this.props.disabled && (
                <ArrowDownMiddleIcon />
              )}
            </div>
          </Dropdown>
          <div className={styles.tagsSection}>
            <Field
              disabled={
                !this.props.isEditor ||
                _.get(this.props.values, 'status') == '0'
              }
              name='tags'
              component={Tags}
              label={__('Tags')}
              options={this.state.stage_tags}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default EditWorkingSetPlanForm;
