import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/specification_approval_wizard/SpecificationApprovalForm.scss";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {__, setTableParams, mapStateToProps} from "../../core/utils";
import Textarea from "../../components/Textarea";

@mapStateToProps(state => ({
    table: state.table["specification_approvals"],
}))
@reduxForm({form: "specification_approval_wizard.specification_approval"})
class SpecificationApprovalForm extends Component {
    render() {
        const {approve} = this.props.table;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <Field name="message" component={Textarea} label={approve ? __('specification-approval-form.approval-message') : __('specification-approval-form.rejection-message')} hint={__('specification-approval-form.message-addressed-to-delivery-creator')} rows={1} maxRows={6} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('specification_approvals', {
                        wizard_active: undefined,
                        approve: undefined
                    })} />
                    <Button gray medium right submit middleText={approve ? __('specification-approval-form.approve') : __('specification-approval-form.reject')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default SpecificationApprovalForm;
