import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/Meetings.scss';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import {
  __,
  dateFrom,
  redirect,
  timeFrom,
  setTableParams,
} from '../../core/utils';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import * as _ from 'lodash';

@withRouter
class Meetings extends Component {
  render() {
    const { data, collapsed } = this.props;
    return (
      <div
        className={classNames(styles.scroller, collapsed && styles.collapsed)}
      >
        {/* {_.isEmpty(data.meetings) ? <div className={styles.empty}>{__('tasks.all-tasks.flyout.meetings.there-are-no-attached-meetings')}</div> : ''} */}
        {_.map(data.meetings, (row, i) => (
          <div className={styles.row} key={i}>
            <div
              className={styles.left}
              onClick={() => redirect('/meetings/' + row.id)}
            >
              <span>
                {'M-' + _.padStart(row.identifier, 4, '0') + ': ' + row.title}
              </span>{' '}
              <ArrowDoubleRightSmallIcon />
            </div>
            <div className={styles.right}>
              {timeFrom(row.date)}
              <div
                className={classNames(
                  styles.status,
                  row.status == 'minutes' && styles.minutes,
                  row.status == 'agenda' && styles.agenda,
                  row.status == 'review' && styles.review,
                  row.status == 'closed' && styles.closed,
                  row.status == 'canceled' && styles.canceled
                )}
              >
                {row.status == 'minutes' ? 'MI' : ''}
                {row.status == 'agenda' ? 'AG' : ''}
                {row.status == 'review' ? 'RE' : ''}
                {row.status == 'closed' ? 'CL' : ''}
                {row.status == 'canceled' ? 'CA' : ''}
              </div>
            </div>
          </div>
        ))}
        <div className={styles.addRow}>
          <button
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('all_meetings', {
                meeting_from_tasks: [data.id],
                stage_id: data.stage.id,
              });
            }}
          >
            {__('tasks.button.add-task-to-meeting')}
          </button>
        </div>
      </div>
    );
  }
}

export default Meetings;
