import React, { useMemo, useState, useRef, useEffect } from 'react';
import * as _ from 'lodash';
import styles from '../styles/components/ImportSpreadsheetTable.scss';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import CheckedIcon from '../assets/images/checked-15x15.svg';
import UncheckedIcon from '../assets/images/unchecked-15x15.svg';
import BaseTable from 'react-base-table';
import Dropdown from './Dropdown';
import { __, redirect } from '../core/utils';
import connect from 'react-redux/es/connect/connect';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import { hideDropdown } from '../redux/actions/general/dropdown';
import { setNotification } from '../redux/actions/general/notification';
import Button from './Button';
import Tooltip from './Tooltip';

const ImportSpreadsheetTable = ({
  data,
  title,
  options,
  columns_count,
  _handleSubmit,
  isHierarchyStructure,
  sidebar,
}) => {
  const [checked, setChecked] = useState([]);
  const [headerRow, setHeaderRow] = useState({});
  const myRef = useRef();

  useEffect(() => {
    if (isHierarchyStructure) {
      setHeaderRow({
        0: {
          value: 'sectionDepth',
          label: __('import_spreadsheet.field.section_depth*'),
          default: true,
        },
      });
    } else {
      setHeaderRow({});
      setChecked(_.map(data, (row, i) => i + 1));
    }
  }, [data, isHierarchyStructure]);

  const columns = [
    {
      key: 0,
      title: '',
      width: 50,
      sortable: false,
      cellRenderer: ({ rowIndex, rowData }) => {
        const isChecked = rowData.checked;

        return rowIndex == 0 ? null : isChecked ? (
          <CheckedIcon
            className={classNames(styles.checked)}
            onClick={() => _handleCheckbox(rowIndex)}
          />
        ) : (
          <UncheckedIcon
            className={classNames(styles.unchecked)}
            onClick={() => _handleCheckbox(rowIndex)}
          />
        );
      },
    },
    ..._.map(Array(columns_count).fill(0), (column, key) => {
      return {
        key: key + 1,
        title: __('project.discipline.table.column.' + key),
        width: 150,
        resizable: false,
        sortable: false,
        cellRenderer: ({ rowData, rowIndex, columnIndex, cellData }) => {
          if (rowIndex === 0) {
            const readOnly =
              isHierarchyStructure &&
              _.get(rowData.headerRow, `${columnIndex}.default`);

            return (
              <Dropdown
                top={30}
                left={-10}
                readOnly={readOnly}
                leftStyled
                wrapperClassName={classNames(
                  styles.dropdownWrapper,
                  readOnly && styles.readOnly
                )}
                name={'header.' + columnIndex}
                header={
                  <div className={styles.dropdownHeader}>
                    {__('import_spreadsheet.table.header')}

                    {_.get(rowData.headerRow, `${columnIndex}`) && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();

                          setHeaderRow((prevState) => {
                            delete prevState[columnIndex];

                            return { ...prevState };
                          });

                          hideDropdown();
                        }}
                      >
                        {__('import_spreadsheet.table.clear_header_column')}
                      </span>
                    )}
                  </div>
                }
                content={
                  <div className={styles.dropdownContent}>
                    {_.map(options, (header_row, i) => (
                      <div
                        key={i}
                        className={classNames(
                          styles.item,
                          _.some(
                            rowData.headerRow,
                            (row) => row.value == header_row.value
                          ) && styles.selectedItem,
                          _.isEqual(
                            _.get(rowData.headerRow, `${columnIndex}.value`),
                            header_row.value
                          ) && styles.active
                        )}
                        onClick={(e) => {
                          e.stopPropagation();

                          if (_.isNull(header_row.value)) {
                            setHeaderRow((prevState) => {
                              delete prevState[columnIndex];
                              return { ...prevState };
                            });
                          } else {
                            setHeaderRow((prevState) => {
                              _.each(Object.keys(rowData.headerRow), (key) => {
                                if (
                                  _.isEqual(
                                    rowData.headerRow[key],
                                    header_row
                                  ) &&
                                  !_.isEqual(parseInt(key), columnIndex)
                                ) {
                                  delete prevState[key];

                                  setNotification({
                                    text: __(
                                      'import_spreadsheet.table.header.another_column_unselected'
                                    ),
                                    type: 'warning',
                                  });
                                }
                              });

                              return {
                                ...prevState,
                                [columnIndex]: header_row,
                              };
                            });
                          }

                          hideDropdown();
                        }}
                      >
                        <span>{header_row.label}</span>

                        {_.isEqual(
                          _.get(rowData.headerRow, `${columnIndex}.value`),
                          header_row.value
                        ) && <span className={styles.dot} />}
                      </div>
                    ))}
                  </div>
                }
              >
                <Tooltip
                  text={
                    isHierarchyStructure && columnIndex < 3
                      ? __('import_spreadsheet.table.header.required-column')
                      : __('import_spreadsheet.table.header.select-column')
                  }
                >
                  <div
                    className={classNames(
                      styles.dropdownValue,
                      !_.get(rowData.headerRow, `${columnIndex}.label`) &&
                        styles.unset
                    )}
                  >
                    <span>
                      {_.get(rowData.headerRow, `${columnIndex}.label`) ||
                        __(
                          'import_spreadsheet.table.placeholder.select-column'
                        )}
                    </span>

                    <ArrowDownSmallIcon />
                  </div>
                </Tooltip>
              </Dropdown>
            );
          }

          return (
            <Tooltip
              placement='top'
              text={rowData[key]}
              html={true}
              dark={true}
            >
              <span className={styles.label}>{rowData[key]}</span>
            </Tooltip>
          );
        },
      };
    }),
  ];

  const final_data = useMemo(() => {
    return _.isEmpty(data)
      ? []
      : _.map(_.concat([data[0]], data), (row, i) => {
          if (i === 0)
            return {
              ...row,
              headerRow,
            };
          return {
            ...row,
            checked: _.includes(checked, i),
          };
        });
  }, [checked, headerRow, data]);

  const _handleCheckbox = (id) => {
    setChecked((prevChecked) => {
      return _.xor(prevChecked, [id]);
    });
  };

  const _disableImportButton = _.isEmpty(options)
    ? true
    : !_.isEqual(
        _.size(_.filter(options, 'required')),
        _.size(
          _.filter(Object.keys(headerRow), (key) =>
            _.get(headerRow, `${key}.required`)
          )
        )
      );

  return (
    <div className={styles.wrapper}>
      <div className={styles.toolbar}>
        <div className={styles.titleWrapper}>
          <Dropdown
            top={25}
            name={title}
            readOnly={!sidebar.hidden}
            wrapperClassName={styles.titleMenuWrapper}
            header={
              <div className={styles.titleMenuHeader}>
                {__('titlemenu.header')}
              </div>
            }
            content={
              <div className={styles.titleMenuContent}>
                {_.map(sidebar.items, (item, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      item.active && styles.activeItem
                    )}
                    key={i}
                    onClick={() => {
                      redirect(item.link());

                      hideDropdown();
                    }}
                  >
                    <Tooltip text={item.title}>
                      <span>{item.title}</span>
                    </Tooltip>

                    <div className={styles.dot} />
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.title}>
              {title}

              {sidebar.hidden && <ArrowDownSmallIcon />}
            </div>
          </Dropdown>
        </div>

        <Button
          medium
          disabled={_disableImportButton}
          middleText={
            <Tooltip
              text={
                _disableImportButton
                  ? __('import_spreadsheet.button.disabled')
                  : __('import_spreadsheet.button.upload')
              }
            >
              {__('import_spreadsheet.button.upload')}
            </Tooltip>
          }
          onClick={() => _handleSubmit(final_data)}
        />
      </div>

      {!_.isEmpty(data) && (
        <div className={classNames(styles.content)}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <BaseTable
                  ref={myRef}
                  className={styles.ImportSpreadsheetTable}
                  fixed
                  ignoreFunctionInColumnCompare={false}
                  columns={isHierarchyStructure ? _.slice(columns, 1) : columns}
                  data={final_data}
                  width={width}
                  height={height}
                  rowHeight={32}
                  rowClassName={({ rowData, rowIndex }) =>
                    classNames(
                      _.includes(checked, rowIndex) && styles.isChecked,
                      styles.row
                    )
                  }
                  rowCount={_.size(data)}
                  headerHeight={0}
                />
              );
            }}
          </AutoSizer>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    dropdownName: state.general.dropdown.shown,
    sidebar: state.general.sidebar,
  };
};

// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     actions: {
//       setTableParams: (params) =>
//         dispatch({
//           type: 'table.import_spreadsheet/SET_TABLE_PARAMS',
//           params,
//         }),
//     },
//   };
// };

export default connect(mapStateToProps, null)(ImportSpreadsheetTable);
