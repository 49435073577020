import store from '../../../core/store';

export const listMyCompanyMembers = () => {
  return store.dispatch({
    action: 'table.my_company_members/LIST_MY_COMPANY_MEMBERS',
    method: 'GET',
    endpoint: '/companies/my/members',
    main: true,
  });
};

export const refreshMyCompanyMembers = () => {
  return store.dispatch({
    action: 'table.my_company_members/LIST_MY_COMPANY_MEMBERS',
    method: 'GET',
    endpoint: '/companies/my/members',
    main: true,
    preloaded: false,
  });
};

export const updateCompanyMemberRole = (user_id, role) => {
  return store.dispatch({
    action: 'table.my_company_members/UPDATE_COMPANY_MEMBER_ROLE',
    method: 'PUT',
    endpoint: '/companies/my/members/' + user_id + '/role',
    data: {
      role,
    },
  });
};

export const inviteNewCompanyMember = (postData) => {
  return store.dispatch({
    action: 'table.my_company_members/INVITE_NEW_COMPANY_MEMBER',
    method: 'POST',
    endpoint: '/companies/my/members/invite',
    data: postData,
  });
};

export const resendCompanyJoinInvitation = (id) => {
  return store.dispatch({
    action: 'table.my_company_members/RESEND_COMPANY_JOIN_INVITATION',
    method: 'POST',
    endpoint: '/companies/my/join_invitations/' + id + '/resend',
  });
};

export const updateCompanyInvitationRole = (id, role) => {
  return store.dispatch({
    action: 'table.my_company_members/UPDATE_COMPANY_INVITATION_ROLE',
    method: 'PUT',
    endpoint: '/companies/my/join_invitations/' + id + '/role',
    data: {
      role,
    },
  });
};

export const approveCompanyJoinRequest = (id) => {
  return store.dispatch({
    action: 'table.my_company_members/APPROVE_COMPANY_JOIN_REQUEST',
    method: 'PUT',
    endpoint: '/companies/my/join_requests/' + id + '/approve',
  });
};

export const declineCompanyJoinRequest = (id) => {
  return store.dispatch({
    action: 'table.my_company_members/DECLINE_COMPANY_JOIN_REQUEST',
    method: 'DELETE',
    endpoint: '/companies/my/join_requests/' + id + '/decline',
  });
};

export const cancelCompanyJoinRequest = (id) => {
  return store.dispatch({
    action: 'table.my_company_members/CANCEL_COMPANY_JOIN_REQUEST',
    method: 'DELETE',
    endpoint: '/companies/my/join_invitations/' + id + '/cancel',
  });
};

export const deactivateCompanyMember = (user_id) => {
  return store.dispatch({
    action: 'table.my_company_members/DEACTIVATE_COMPANY_MEMBER',
    method: 'PUT',
    endpoint: '/company/members/' + user_id + '/deactivate',
  });
};

export const reactivateCompanyMember = (user_id) => {
  return store.dispatch({
    action: 'table.my_company_members/REACTIVATE_COMPANY_MEMBER',
    method: 'PUT',
    endpoint: '/company/members/' + user_id + '/reactivate',
  });
};
