import React, { useState, useEffect } from 'react';
import { Field, focus, Form, getFormValues, reduxForm } from 'redux-form';
import { __, dateFrom } from '../../core/utils';
import styles from '../../styles/forms/tenders/EditTenderToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import classNames from 'classnames';
import DateTimePicker from '../../components/DateTimePicker';
import DateIcon from '../../assets/images/date-15x15.svg';
import connect from 'react-redux/es/connect/connect';
import * as _ from 'lodash';
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import moment from 'moment';
import { showAlertbox } from '../../redux/actions/general/alertbox';

const EditTenderToolbarForm = (props) => {
  const prevProp = React.useRef();
  const [status_switcher, set_status_switcher] = useState(false);

  useEffect(() => {
    if (
      props.anyTouched &&
      prevProp.current &&
      !_.isEqual(prevProp.current, props.values)
    ) {
      props.submit();

      prevProp.current = null;
    } else prevProp.current = props.values; // Update the ref to the current prop for the next render
  }, [props.values]); // Only re-run the effect if 'someProp' changes

  const {
    data,
    user_id,
    values,
    store: { flyout },
  } = props;
  const { status } = values;
  const is_administrator = _.isObject(
    _.find(data.administrators, ['id', user_id])
  );

  const statuses = [
    {
      value: 'draft',
      label: __('tenders.status.draft'),
      color: '#9C27B0',
      disabled: true,
    },
    {
      value: 'started',
      label: __('tenders.status.started'),
      color: '#5777FC',
      disabled: !_.includes(['draft'], status),
    },
    {
      value: 'finished',
      label: __('tenders.status.finished'),
      color: '#ADD42B',
      disabled: !_.includes(['started'], status),
    },
    {
      value: 'closed',
      label: __('tenders.status.closed'),
      color: '#009688',
      disabled: !_.includes(['finished'], status),
    },
    {
      value: 'canceled',
      label: __('tenders.status.canceled'),
      color: '#CCCDD1',
      disabled: !_.includes(['draft', 'started', 'finished'], status),
    },
  ];

  const disabled =
    !is_administrator || _.includes(['closed', 'canceled'], status);

  const bidder_status = flyout?.is_bidder && _.first(flyout.bidders).status;

  const bidder_statuses = [
    {
      value: 'received',
      label: __('tenders.bidder-status.received'),
      color: '#9C27B0',
    },
    {
      value: 'in-progress',
      label: __('tenders.bidder-status.in-progress'),
      color: '#5777FC',
    },
    {
      value: 'submitted',
      label: __('tenders.bidder-status.submitted'),
      color: '#ADD42B',
    },
    {
      value: 'withdrawn',
      label: __('tenders.bidder-status.withdrawn'),
      color: '#CCCDD1',
    },
    {
      value: 'expired',
      label: __('tenders.bidder-status.expired'),
      color: '#009688',
      disabled: true,
    },
  ];

  const bidder_disabled = _.includes(
    ['submitted', 'withdrawn', 'expired'],
    bidder_status
  );

  const decision_statuses = [
    {
      value: 'not_selected',
      label: __('tenders.bidder.decision_status.not_selected'),
      color: '#CCCDD1',
    },
    {
      value: 'awarded',
      label: __('tenders.bidder.decision_status.awarded'),
      color: '#5777FC',
    },
    {
      value: 'shortlisted',
      label: __('tenders.bidder.decision_status.shortlisted'),
      color: '#75FCFD',
    },
    {
      value: 'disqualified',
      label: __('tenders.bidder.decision_status.disqualified'),
      color: '#E62A10',
    },
    {
      value: 'withdrawn',
      label: __('tenders.bidder.decision_status.withdrawn'),
      color: '#F19E38',
    },
    {
      value: 'expired',
      label: __('tenders.bidder.decision_status.expired'),
      color: '#FEFE54',
    },
  ];

  const bidder_decision_status =
    flyout?.is_bidder && _.first(flyout.bidders).decision_status;

  return (
    <div className={styles.wrapper}>
      <Form onSubmit={props.handleSubmit} className={styles.formWrapper}>
        <Field
          disabled={true}
          name='date'
          component={DateTimePicker}
          label={__('Date & Time')}
          footer
          dropdown
          range
          time
          top={32}
          presets
          clearDate
          wrapper={(handleOpen, value) => (
            <Tooltip text={__('tenders.tooltips.datetime.change-date')}>
              <div
                className={classNames(styles.date, disabled && styles.disabled)}
                onClick={handleOpen}
              >
                <DateIcon />{' '}
                {value.start && value.end
                  ? moment
                      .utc(value.start)
                      .local()
                      .startOf('day')
                      .format('X') ==
                    moment.utc(value.end).local().startOf('day').format('X')
                    ? dateFrom(value.start)
                    : dateFrom(value.start) + ' - ' + dateFrom(value.end)
                  : __('tenders.flyout.select-date')}
                <ArrowDownSmallIcon />
              </div>
            </Tooltip>
          )}
        />

        {flyout?.is_bidder && _.includes(['finished'], status) ? (
          <Field
            disabled={true}
            name='decision_status'
            input={{
              value: bidder_decision_status,
            }}
            component={Select}
            label={__('table.columns.tender-decision-status')}
            options={decision_statuses}
            top={40}
            icon={<MeetingStatusIcon className={styles.icon} />}
            wrapper={(handleOpen, activeItem) => (
              <Tooltip text={__('tenders.tooltip.icon.decision-status')}>
                <div
                  className={classNames(
                    styles.dropdown,
                    disabled && styles.disabled
                  )}
                  style={
                    activeItem ? { background: activeItem.color } : undefined
                  }
                  onClick={handleOpen}
                >
                  <MeetingStatusIcon className={styles.icon} />
                  <div className={styles.button}>{activeItem.label}</div>
                </div>
              </Tooltip>
            )}
          />
        ) : (
          <Field
            disabled={disabled}
            name='status'
            component={Select}
            label={__('table.columns.tender-status')}
            options={statuses}
            noSort
            dropdown
            top={40}
            icon={<MeetingStatusIcon className={styles.icon} />}
            wrapper={(handleOpen, activeItem) => (
              <Tooltip text={__('tenders.tooltip.icon.status')}>
                <div
                  className={classNames(
                    styles.dropdown,
                    disabled && styles.disabled
                  )}
                  style={
                    activeItem ? { background: activeItem.color } : undefined
                  }
                  onClick={handleOpen}
                >
                  <MeetingStatusIcon className={styles.icon} />
                  <div className={styles.button}>
                    {activeItem.label}
                    {!disabled && <ArrowDownSmallIcon />}
                  </div>
                </div>
              </Tooltip>
            )}
          />
        )}
      </Form>

      {flyout.is_bidder && (
        <Select
          disabled={bidder_disabled}
          input={{
            value: bidder_status,

            onChange: (status) => {
              if (bidder_status === status) return;

              if (_.includes(['submitted', 'withdrawn'], status))
                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: `${__(
                    `tenders.bidder-status.alert-${bidder_status}.first-warning`
                  )}\n\n${__(
                    `tenders.bidder-status.alert-${bidder_status}.second-warning`
                  )}`,
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-change-status'),
                      onClick: () => props.changeBidderStatus(status),
                    },
                  ],
                });
              else {
                // this.props.changeStatus(status);
              }
            },
            onBlur: () => set_status_switcher(false),

            onFocus: () => set_status_switcher(true),
          }}
          meta={{
            active: status_switcher,
          }}
          label={__('Status')}
          options={bidder_statuses}
          noSort
          dropdown
          top={34}
          left={50}
          icon={<TaskStatusIcon className={styles.bidderStatusIcon} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('tenders.tooltip.icon.bidder-status')}>
              <div
                className={classNames(
                  styles.dropdown,
                  bidder_disabled && styles.disabled
                )}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskStatusIcon className={styles.bidderStatusIcon} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.id,
    values: getFormValues('tenders.edit_tender_toolbar')(state),
    dropdown: state.general.dropdown.shown,
    store: state.table.tenders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    focus: (field) => {
      dispatch(focus('tenders.edit_tender_toolbar', field));
    },
  };
};

export default reduxForm({
  form: 'tenders.edit_tender_toolbar',
  enableReinitialize: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(EditTenderToolbarForm))
);

// import * as React from 'react';
// import { Component } from 'react';
// import { Field, focus, Form, getFormValues, reduxForm } from 'redux-form';
// import { __, dateFrom, timeFrom } from '../../core/utils';
// import styles from '../../styles/forms/meetings/EditMeetingToolbarForm.scss';
// import Select from '../../components/Select';
// import Tooltip from '../../components/Tooltip';
// import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';
// import classNames from 'classnames';
// import DateTimePicker from '../../components/DateTimePicker';
// import DateIcon from '../../assets/images/date-15x15.svg';
// import connect from 'react-redux/es/connect/connect';
// import { enableAutosave } from '../../redux/actions/general/autosave';
// import * as _ from 'lodash';
// import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
// import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
// import { subscribe } from 'react-contextual/dist/react-contextual.es';
// import Localization from '../../helpers/Localization';
// import moment from 'moment';

// @subscribe(Localization, 'localization')
// @reduxForm({
//   form: 'tenders.edit_tender_toolbar',
//   enableReinitialize: true,
// })
// @connect(
//   (state) => ({
//     user_id: state.auth.id,
//     values: getFormValues('tenders.edit_tender_toolbar')(state),
//     dropdown: state.general.dropdown.shown,
//   }),
//   (dispatch) => ({
//     focus: (field) => {
//       dispatch(focus('tenders.edit_tender_toolbar', field));
//     },
//   })
// )
// class EditTenderToolbarForm extends Component {
//   UNSAFE_componentWillReceiveProps() {
//     enableAutosave(this.props.form);
//   }

//   render() {
//     const { data, user_id, values } = this.props;
//     const { status } = values;
//     const is_administrator = _.isObject(
//       _.find(data.administrators, ['id', user_id])
//     );

//     const statuses = [
//       {
//         value: 'draft',
//         label: __('tenders.status.draft'),
//         color: '#9C27B0',
//         disabled: !_.includes(['draft'], status),
//       },
//       {
//         value: 'active',
//         label: __('tenders.status.active'),
//         color: '#5777FC',
//         disabled: !_.includes(['finished'], status),
//       },
//       {
//         value: 'finished',
//         label: __('tenders.status.finished'),
//         color: '#ADD42B',
//         disabled: !_.includes(['draft', 'active'], status),
//       },
//       {
//         value: 'closed',
//         label: __('tenders.status.closed'),
//         color: '#009688',
//         disabled: !_.includes(['draft', 'active', 'finished'], status),
//       },
//       {
//         value: 'canceled',
//         label: __('tenders.status.canceled'),
//         color: '#CCCDD1',
//         disabled: !_.includes(['draft', 'active', 'finished'], status),
//       },
//     ];
//     const disabled =
//       !is_administrator || _.includes(['closed', 'canceled'], status);

//     return (
//       <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
//         <Field
//           disabled={disabled}
//           name='date'
//           component={DateTimePicker}
//           label={__('Date & Time')}
//           footer
//           dropdown
//           range
//           time
//           top={32}
//           presets
//           clearDate
//           wrapper={(handleOpen, value) => (
//             <Tooltip text={__('tenders.tooltips.datetime.change-date')}>
//               <div
//                 className={classNames(styles.date, disabled && styles.disabled)}
//                 onClick={handleOpen}
//               >
//                 <DateIcon />{' '}
//                 {value.start && value.end
//                   ? moment
//                       .utc(value.start)
//                       .local()
//                       .startOf('day')
//                       .format('X') ==
//                     moment.utc(value.end).local().startOf('day').format('X')
//                     ? dateFrom(value.start)
//                     : dateFrom(value.start) + ' - ' + dateFrom(value.end)
//                   : __('tenders.flyout.select-date')}
//                 <ArrowDownSmallIcon />
//               </div>
//             </Tooltip>
//           )}
//         />
//         <Field
//           disabled={disabled}
//           name='status'
//           component={Select}
//           label={__('table.columns.tender-status')}
//           options={statuses}
//           noSort
//           dropdown
//           top={40}
//           icon={<MeetingStatusIcon className={styles.icon} />}
//           wrapper={(handleOpen, activeItem) => (
//             <Tooltip text={__('tenders.tooltip.icon.status')}>
//               <div
//                 className={classNames(
//                   styles.dropdown,
//                   disabled && styles.disabled
//                 )}
//                 style={
//                   activeItem ? { background: activeItem.color } : undefined
//                 }
//                 onClick={handleOpen}
//               >
//                 <MeetingStatusIcon className={styles.icon} />
//                 <div className={styles.button}>
//                   {activeItem.label}
//                   <ArrowDownSmallIcon />
//                 </div>
//               </div>
//             </Tooltip>
//           )}
//         />
//       </Form>
//     );
//   }
// }

// export default EditTenderToolbarForm;
