import * as React from 'react';
import styles from '../styles/wizards/CreateTenderBOQFromBOQWizard.scss';
import { __, getActiveStage, redirect, setFormErrors } from '../core/utils';
import AddToTenderIcon from '../assets/images/add-to-tender-60x60.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import { connect } from 'react-redux';
import Localization from '../helpers/Localization';
import CreateTenderBOQFromBOQForm from '../forms/create_tender_boq_from_boq_wizard/CreateTenderBOQFromBOQForm';
import { subscribe } from 'react-contextual';
import { addBOQToTenderBOQ } from '../redux/actions/table/tenders';

const CreateTenderBOQFromBOQWizard = (props) => {
  const _handleSubmit = (formData) => {
    const { edit, tender_id } = formData;
    const { selected_boq_rows } = props;

    if (!tender_id) {
      return setFormErrors('create_tender_boq_form.create_tender_boq', {
        tender_id: ['Field is empty'],
      });
    }

    addBOQToTenderBOQ(tender_id, selected_boq_rows).then(() => {
      props.onClose();

      if (edit) {
        redirect(`/stages/${getActiveStage()}/tenders?id=${tender_id}`);
      }

      setTimeout(
        () =>
          setNotification(
            __(
              'boq.notification.selected-rows-have-been-added-to-the-tender-boq'
            )
          ),
        25
      );
    });
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.boq.title.create-tender-boq-from-boq')}</span>
          <span>{__('wizard.boq.subtitle.create-tender-boq-from-boq')}</span>
        </div>
        <div className={styles.right}>
          <AddToTenderIcon />
        </div>
      </div>
      <div className={styles.body}>
        <CreateTenderBOQFromBOQForm
          initialValues={{
            new_tender_boq: false,
          }}
          onSubmit={_handleSubmit}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected_boq_rows: _.get(state.table, 'working_set_boq.selected_boq_rows'),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(CreateTenderBOQFromBOQWizard)
  )
);
