const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.alertbox/SHOW_ALERTBOX":
            return {
                color: action.color,
                title: action.title,
                description: action.description,
                buttons: action.buttons,
            };
        case "general.alertbox/HIDE_ALERTBOX":
            return {};
        default:
            return state;
    }
}