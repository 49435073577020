import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams, getLocalized } from "../../core/utils";
import { createSidebar, createSuperadminSidebar } from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import BOQSupplyTypesFlyout from "../../flyouts/superadmin/boq_supply_types/BOQSupplyTypesFlyout";
import {
	deleteBoqSupplyTypes,
	listBoqSupplyTypes,
	readBoqSupplyType,
} from "../../redux/actions/table/superadmin/boq_supply_types";
import { listLocalization } from "../../redux/actions/localization";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_boq_supply_types"],
	auth: state.auth,
	application_language_id: state.auth.language_id,
}))
class BOQSupplyTypes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}
	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_boq_supply_type && setPlusActions([{
			title: "Add Supply Type",
			onClick: () => setTableParams('superadmin_boq_supply_types', {
				add_boq_supply_types_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readBoqSupplyType(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, application_language_id } = this.props;
		let columns = [
			{
				key: "name",
				name: "Name",
				value: name => getLocalized(name, application_language_id)
			},
			{
				key: "boq_supply_type_group",
				name: "Boq supply type group",
				required: true,
				width: 150,
				value: boq_supply_type_group => {
					const localizedName = getLocalized(boq_supply_type_group, application_language_id);
					return <Tooltip text={localizedName}>{localizedName}</Tooltip>
				}
				
			},
		];

		const singleActions = (boq_supply_type_id) => {
			return [
				<Tooltip text="Delete selected bill of quantities supply type" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected bill of quantities supply type?\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteBoqSupplyTypes([boq_supply_type_id]).then(() => {
												hideAlertbox();

												setNotification("The selected bill of quantities supply type has been deleted.");
												listBoqSupplyTypes();

												listLocalization();

												setTableParams('superadmin_boq_supply_types', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (boq_supply_type_ids) => [
			<Tooltip text="Delete selected bill of quantities supply types" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these bill of quantities supply types?\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteBoqSupplyTypes(boq_supply_type_ids).then(() => {
											hideAlertbox();

											setNotification("The selected bill of quantities supply types have been deleted.");
											listBoqSupplyTypes();

											listLocalization();

											setTableParams('superadmin_boq_supply_types', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<BOQSupplyTypesFlyout
				data={this.state.preview}
				readAction={boq_supply_type_id => {
					return new Promise(resolve => {
						readBoqSupplyType(getURLParam('id') || boq_supply_type_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listBoqSupplyTypes}
				name="superadmin_boq_supply_types"
				groupActions={superadmin.delete_boq_supply_type && groupActions}
				singleActions={superadmin.delete_boq_supply_type && singleActions}
				title={__("modules.submodules.title.boq-supply-types")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default BOQSupplyTypes;
