import React, { Component, useCallback } from 'react';
import styles from '../styles/components/SearchableDropdown.scss';
import Dropdown from './Dropdown';
import SearchIcon from '../assets/images/search-15x15.svg';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import { __ } from '../core/utils';
import _ from 'lodash';
import UncheckedIcon from '../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../assets/images/checked-15x15.svg';
import Tooltip from '../components/Tooltip';
import Button from '../components/Button';
import { hideDropdown } from '../redux/actions/general/dropdown';
import classNames from 'classnames';
import LogoIcon from '../assets/images/logo-50x50.svg';

class SearchableDropdown extends Component {
  state = { query: '' };

  _handleQueryChange = (e) => this.setState({ query: e.target.value });

  _handleQueryClear = () => this.setState({ query: '' });

  componentDidMount() {
    this.props.initialQuery &&
      this.setState({ query: this.props.defaultValue });
  }

  render() {
    const {
      name,
      header,
      options,
      onSelect,
      onRemove,
      valueKey = 'value',
      labelKey = 'label',
      children,
      content,
      leftStyled,
      dropdownWrapperClassName,
      multiselect = false,
      selectedValues = [],
      footer,
      onButtonClick,
      dropdownContentClassName,
      closable = true,
      groupReadOnly,
      handleAddNewOptionButton,
      showIcon,
    } = this.props;

    const { query } = this.state;

    const { availableOptions, selectedOptions } = _.reduce(
      options,
      (result, option) => {
        const isSelected = !!_.find(
          selectedValues,
          (value) => value == _.get(option, valueKey)
        );
        const isMatched = _.includes(
          _.get(option, labelKey)?.toLowerCase(),
          query?.toLowerCase()
        );

        if (isSelected) {
          result.selectedOptions.push(option);
        } else if (isMatched) {
          result.availableOptions.push(option);
        }

        return result;
      },
      { availableOptions: [], selectedOptions: [] }
    );

    return (
      <Dropdown
        top={25}
        right={leftStyled ? 'unset' : 0}
        name={name}
        wrapperClassName={dropdownWrapperClassName}
        leftStyled={leftStyled}
        closable={closable}
        header={
          typeof header === 'string' ? (
            <div className={styles.dropdownHeader}>{header}</div>
          ) : (
            header
          )
        }
        content={
          content || (
            <div
              className={classNames(
                styles.dropdownContent,
                dropdownContentClassName
              )}
            >
              {multiselect && _.size(selectedOptions) > 0 && (
                <>
                  <div className={styles.selectedOptionsHeader}>
                    {__('tasks.priority.dropdown.selected-options')}{' '}
                    {_.size(selectedOptions) > 0 &&
                      `(${_.size(selectedOptions)})`}
                  </div>
                  <div className={styles.options}>
                    {_.map(
                      _.reject(selectedOptions, 'group'),
                      (option, index) => (
                        <Option
                          key={option[valueKey]}
                          label={option[labelKey]}
                          item={option}
                          index={index}
                          onClick={onRemove}
                          multiselect={multiselect}
                          selected
                        />
                      )
                    )}

                    {_.map(
                      _.groupBy(_.filter(selectedOptions, 'group'), 'group'),
                      (items, group) => (
                        <div className={styles.group} key={group}>
                          {_.every(
                            _.groupBy(
                              _.filter(availableOptions, 'group'),
                              'group'
                            )[group],
                            (value) => _.includes(selectedValues, value)
                          ) ? (
                            <Option
                              key={_.uniqueId()}
                              label={group}
                              item={{
                                value: items.map((item) => item.value),
                                groupHeading: true,
                              }}
                              index={group}
                              onClick={onRemove}
                              multiselect={multiselect}
                              selected
                              group
                            />
                          ) : (
                            <>
                              <div className={styles.groupHeading}>{group}</div>

                              {_.map(items, (groupItem, index) => (
                                <Option
                                  key={groupItem[valueKey]}
                                  label={groupItem[labelKey]}
                                  item={groupItem}
                                  index={index}
                                  onClick={
                                    _.includes(
                                      selectedValues,
                                      groupItem[valueKey]
                                    )
                                      ? onRemove
                                      : onSelect
                                  }
                                  multiselect={multiselect}
                                  selected={_.includes(
                                    selectedValues,
                                    groupItem[valueKey]
                                  )}
                                />
                              ))}
                            </>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
              <div className={styles.selectedOptionsHeader}>
                {__('tasks.priority.dropdown.available-options')}
              </div>

              {_.size(options) > 6 && (
                <div className={styles.search}>
                  <SearchIcon className={styles.searchIcon} />
                  <input
                    ref='search'
                    type='text'
                    value={query}
                    autoFocus={true}
                    placeholder={__('select.title.search')}
                    onChange={this._handleQueryChange}
                  />
                  {!_.isEmpty(query) && (
                    <CloseSmallIcon
                      className={styles.close}
                      onClick={this._handleQueryClear}
                    />
                  )}
                </div>
              )}
              <div className={styles.options}>
                {_.map(_.reject(availableOptions, 'group'), (option, index) => (
                  <Option
                    key={option[valueKey]}
                    label={option[labelKey]}
                    item={option}
                    index={index}
                    onClick={
                      _.includes(selectedValues, option[valueKey])
                        ? onRemove
                        : onSelect
                    }
                    multiselect={multiselect}
                    selected={_.includes(selectedValues, option[valueKey])}
                    showIcon={showIcon}
                  />
                ))}

                {_.map(
                  _.groupBy(_.filter(availableOptions, 'group'), 'group'),
                  (items, group) => (
                    <div className={styles.group} key={group}>
                      <Option
                        groupReadOnly={groupReadOnly}
                        key={_.uniqueId()}
                        label={group}
                        item={{
                          value: items.map((item) => item.value),
                          groupHeading: true,
                        }}
                        index={group}
                        onClick={onSelect}
                        multiselect={multiselect}
                        selected={false}
                        group
                      />

                      {/* <div className={styles.groupHeading}>
                        {group}
                        </div> */}
                      {_.map(items, (groupItem, index) => (
                        <Option
                          key={groupItem[valueKey]}
                          label={groupItem[labelKey]}
                          textTransformInitial={groupReadOnly}
                          item={groupItem}
                          index={index}
                          onClick={
                            _.includes(selectedValues, groupItem[valueKey])
                              ? onRemove
                              : onSelect
                          }
                          multiselect={multiselect}
                          selected={_.includes(
                            selectedValues,
                            groupItem[valueKey]
                          )}
                        />
                      ))}
                    </div>
                  )
                )}
              </div>
              {_.size(availableOptions) === 0 && (
                <div className={styles.emptyOptions}>
                  {handleAddNewOptionButton ? (
                    <button
                      type='button'
                      onClick={() => handleAddNewOptionButton(query)}
                    >
                      {__('working-set-boq.' + _.first(_.split(name, '.')))}
                    </button>
                  ) : (
                    'No available options'
                  )}
                </div>
              )}

              <div className={styles.footer}>
                {footer}
                <Button
                  className={styles.buttonDone}
                  lightBlue
                  small
                  right
                  middleText={__('button.done')}
                  onClick={() => {
                    onButtonClick && onButtonClick(query);

                    hideDropdown();
                  }}
                />
              </div>
            </div>
          )
        }
      >
        {children}
      </Dropdown>
    );
  }
}

const Option = ({
  onClick,
  item,
  index,
  multiselect,
  label,
  selected,
  group,
  groupReadOnly = false,
  textTransformInitial = false,
  showIcon,
}) => {
  const handleClick = useCallback(() => {
    onClick(item, index);
  }, [onClick]);

  if (groupReadOnly) {
    return (
      <div
        className={classNames(
          styles.option,
          styles.groupOption,
          styles.groupReadOnly
        )}
      >
        <Tooltip text={label}>
          <span className={styles.title}>{label}</span>
        </Tooltip>
      </div>
    );
  }
  return (
    <div
      className={classNames(styles.option, group && styles.groupOption)}
      onClick={handleClick}
    >
      {multiselect ? (
        selected ? (
          <CheckedIcon className={styles.checkedOption} />
        ) : (
          <UncheckedIcon className={styles.uncheckedOption} />
        )
      ) : null}

      {showIcon && (
        <div
          className={classNames(
            styles.icon,

            item.is_company ? styles.company : styles.system
          )}
        >
          {item.is_company ? (
            'MY'
          ) : (
            <Tooltip text={'System'}>
              <LogoIcon />
            </Tooltip>
          )}
        </div>
      )}

      <Tooltip text={label}>
        <span
          className={classNames(
            styles.title,
            textTransformInitial && styles.textTransformInitial
          )}
        >
          {label}
        </span>
      </Tooltip>

      {!multiselect && <span className={styles.dot} />}
    </div>
  );
};

export default SearchableDropdown;
