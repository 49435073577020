import * as React from "react";
import { Component } from "react";
import styles from "../../../styles/flyouts/superadmin/product_attribute_template_fields/Properties.scss";
import * as _ from "lodash";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import { __, validateForm } from "../../../core/utils";
import { setNotification } from "../../../redux/actions/general/notification";
import {
    listProductAttributeTemplateFields,
    updateProductAttributeTemplateField
} from "../../../redux/actions/table/superadmin/product_attribute_template_fields";
import EditProductAttributeTemplateFieldForm
    from "../../../forms/superadmin/product_attribute_template_fields/EditProductAttributeTemplateFieldForm";
import { listLocalization } from "../../../redux/actions/localization";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        const options = formData.options?.map((option, priority) => ({
            ...option,
            priority
        }));

        const data = {
            ..._.omit(formData, ['id', 'type', 'options']),
            options: _.transform(options, (acc, option) => {
                _.set(acc, option.id, {
                    value: option.value,
                    discontinued: option.discontinued,
                    priority: option.priority,
                });
            }, {})
        }

        return validateForm(
            updateProductAttributeTemplateField(formData.id, data).then(() => {
                listProductAttributeTemplateFields();

                listLocalization();

                this.props.readAction(formData.id);

                setNotification(__('products.attribute-template.properties.properties-have-been-saved'));
            })
        );
    }

    render() {
        const { data } = this.props;

        return (
            <div className={styles.scroller}>
                <EditProductAttributeTemplateFieldForm
                    onSubmit={this._handleSubmit}
                    reload={() => this.props.readAction(data.id)}
                    initialValues={{
                        ..._.pick(data, ['id', 'group_id', 'type', 'label', 'description', 'norm', 'unit_id', 'options', 'product_subgroup_ids', 'filter_ids', 'discontinued']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;