import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_product_attribute_group_field_values_form/EditProductAttributeGroupFieldValuesForm.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  toBoolean,
} from '../../core/utils';
import Select from '../../components/Select';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Input from '../../components/Input';
import MultiSelect from '../../components/MultiSelect';
import ColorPicker from '../../components/ColorPicker';
import MaterialPicker from '../../components/MaterialPicker';
import Tooltip from '../../components/Tooltip';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import PlusMiddleIcon from '../../assets/images/plus-middle-15x15.svg';
import {
  addProductAttributeGroupFieldValue,
  deleteProductAttributeGroupFieldValue,
  readProduct,
  updateProductAttributeGroupFieldValueInUse,
  useAsThumbnailProductAttributeGroupField,
  useAsThumbnailProductAttributeGroupFieldValue,
} from '../../redux/actions/table/products';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import { enableAutosave } from '../../redux/actions/general/autosave';
import TexturePicker from '../../components/TexturePicker';
import Checkbox from '../../components/Checkbox';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
@reduxForm({
  form: 'edit_product_attribute_group_field_values_form.edit_product_attribute_group_field_values',
  //enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: _.get(state, 'table.products'),
  attribute_field_id:
    state.table.products.edit_product_attribute_group_field_values_wizard,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
@withRouter
class EditProductAttributeGroupFieldValuesForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      use_as_thumbnail: this.props.initialValues.use_as_thumbnail,
    };
  }

  // UNSAFE_componentWillReceiveProps() {
  // 	enableAutosave(this.props.form);
  // }

  @autobind
  _handleAddValue() {
    const { attribute, store } = this.props;

    addProductAttributeGroupFieldValue(attribute.id).then(() =>
      readProduct(store.flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });
      })
    );
  }

  @autobind
  _handleUpdateInUse(product_attribute_group_field_value_id, in_use) {
    const { store } = this.props;

    updateProductAttributeGroupFieldValueInUse(
      product_attribute_group_field_value_id,
      in_use
    ).then(() =>
      readProduct(store.flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });

        setNotification(
          'The product attribute variation properties have been saved.'
        );
      })
    );
  }

  @autobind
  _handleDeleteValue(product_attribute_group_field_value_id) {
    const { store } = this.props;

    deleteProductAttributeGroupFieldValue(
      product_attribute_group_field_value_id
    ).then(() =>
      readProduct(store.flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });

        setNotification(
          'The product attribute variation has been successfully deleted.'
        );
      })
    );
  }

  @autobind
  _handleUseAsThumbnail() {
    const { attribute, store } = this.props;
    const use_as_thumbnail = this.state.use_as_thumbnail ? 0 : 1;

    this.setState({ use_as_thumbnail });

    useAsThumbnailProductAttributeGroupField(attribute.id, {
      use_as_thumbnail,
    }).then(() =>
      readProduct(store.flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });
      })
    );
  }

  render() {
    const { store, attribute, type } = this.props;
    const { units } = this.props.localization;
    const { language_id } = store;

    const values = _.get(attribute, 'values', []);

    const unit = _.get(units, [attribute.unit_id, 'symbol']);

    const boolean_options = [
      {
        label: __('products.attributes-form.boolean.yes'),
        value: '1',
      },
      {
        label: __('products.attributes-form.boolean.no'),
        value: '0',
      },
    ];

    const languages = _.transform(
      _.filter(this.props.localization.languages, (language) =>
        _.includes(store.flyout.languages, language.id)
      ),
      (languages, language) => {
        languages[language.id] = language.id == 1;
      },
      {}
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.heading}>
          <span className={styles.inUse}>In use</span>
          <span className={styles.value}>
            Value {unit ? '(' + unit + ')' : ''}
          </span>
          {_.includes(['color', 'material', 'texture'], type) ? (
            <>
              <span className={styles.delete}>
                <Field
                  name='use_as_thumbnail'
                  component={Checkbox}
                  label={__(
                    'edit_product_attribute_group_field_values_form.use_as_thumbnail'
                  )}
                />
              </span>

              {/* <span className={styles.delete}>
						{use_as_thumbnail ? (
							<CheckedIcon
								className={styles.checked}
								onClick={(e) => {
									e.stopPropagation();
									this._handleUseAsThumbnail();
								}}
							/>
						) : (
							<UncheckedIcon
								className={styles.unchecked}
								onClick={(e) => {
									e.stopPropagation();
									this._handleUseAsThumbnail();
								}}
							/>
						)}
				</span>
			<span className={styles.delete}>{__("edit_product_attribute_group_field_values_form.use_as_thumbnail")}</span> */}
            </>
          ) : (
            <span className={styles.delete} />
          )}
        </div>
        {_.map(values, ({ id, in_use }) => {
          const fieldName = `value-${id}`;

          return (
            <div className={styles.row} key={id}>
              <div className={styles.inUse}>
                {in_use ? (
                  <Tooltip text="Mark as 'not in use'">
                    <CheckedIcon
                      className={styles.checked}
                      onClick={() => this._handleUpdateInUse(id, false)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip text="Mark as 'in use'">
                    <UncheckedIcon
                      className={styles.unchecked}
                      onClick={() => this._handleUpdateInUse(id, true)}
                    />
                  </Tooltip>
                )}
              </div>
              <div className={styles.value}>
                {type == 'boolean' && (
                  <Field
                    name={fieldName}
                    component={Select}
                    options={boolean_options}
                  />
                )}
                {type == 'dropdown_string' && (
                  <Field
                    searchable
                    name={fieldName}
                    noSort
                    component={Select}
                    options={_.map(
                      _.find(this.props.localization.product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ]).options,
                      (option) => ({
                        value: option.id,
                        label: getLocalized(option.value, language_id),
                        disabled: toBoolean(option.discontinued),
                      })
                    )}
                  />
                )}
                {type == 'dropdown_string_multiple' && (
                  <Field
                    searchable
                    name={fieldName}
                    noSort
                    component={MultiSelect}
                    options={_.map(
                      _.find(this.props.localization.product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ]).options,
                      (option) => ({
                        value: option.id,
                        label: getLocalized(option.value, language_id),
                        disabled: toBoolean(option.discontinued),
                      })
                    )}
                  />
                )}
                {type == 'dropdown_numeric' && (
                  <Field
                    searchable
                    name={fieldName}
                    noSort
                    component={Select}
                    options={_.map(
                      _.find(this.props.localization.product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ]).options,
                      (option) => {
                        let unit = _.get(units, [attribute.unit_id, 'name']);

                        return {
                          value: option.id,
                          label:
                            _.toString(
                              getLocalized(option.value, language_id)
                            ) + (unit ? ' (' + unit + ')' : ''),
                          disabled: toBoolean(option.discontinued),
                        };
                      }
                    )}
                  />
                )}
                {type == 'dropdown_numeric_multiple' && (
                  <Field
                    searchable
                    name={fieldName}
                    noSort
                    component={MultiSelect}
                    options={_.map(
                      _.find(this.props.localization.product_attribute_fields, [
                        'id',
                        attribute.template_field_id,
                      ]).options,
                      (option) => {
                        let unit = _.get(units, [attribute.unit_id, 'name']);

                        return {
                          value: option.id,
                          label:
                            _.toString(
                              getLocalized(option.value, language_id)
                            ) + (unit ? ' (' + unit + ')' : ''),
                          disabled: toBoolean(option.discontinued),
                        };
                      }
                    )}
                  />
                )}
                {type == 'string' && (
                  <Field
                    name={fieldName}
                    localized={languages}
                    component={Input}
                  />
                )}
                {type == 'numeric' && (
                  <Field numeric decimal name={fieldName} component={Input} />
                )}
                {type == 'color' && (
                  <Field
                    name={fieldName}
                    language={store.language_id}
                    component={ColorPicker}
                    myCompanyColors
                  />
                )}
                {type == 'material' && (
                  <Field
                    name={fieldName}
                    component={MaterialPicker}
                    language={language_id}
                    myCompanyMaterials
                  />
                )}
                {type == 'texture' && (
                  <Field
                    name={fieldName}
                    component={TexturePicker}
                    language={language_id}
                  />
                )}
                {type == 'range' && (
                  <div className={styles.range}>
                    <div className={styles.flex}>
                      <div className={styles.f1}>
                        <Field
                          numeric
                          decimal
                          name={fieldName + '.0'}
                          component={Input}
                        />
                      </div>
                      <span className={styles.minus}>-</span>
                      <div className={styles.f1}>
                        <Field
                          numeric
                          decimal
                          name={fieldName + '.1'}
                          component={Input}
                          align='right'
                        />
                      </div>
                    </div>
                  </div>
                )}
                {type == 'range_selectable' && (
                  <div className={styles.range}>
                    <div className={styles.flex}>
                      <div className={styles.f1}>
                        <Field
                          numeric
                          decimal
                          name={fieldName + '.0'}
                          component={Input}
                        />
                      </div>
                      <span className={styles.minus}>-</span>
                      <div className={styles.f1}>
                        <Field
                          numeric
                          decimal
                          name={fieldName + '.1'}
                          component={Input}
                          align='right'
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.delete}>
                {_.size(values) > 1 ? (
                  <Tooltip text='Delete attribute variation'>
                    <CloseMediumIcon
                      onClick={() => this._handleDeleteValue(id)}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip text='You may not delete the last custom variation of the attribute'>
                    <CloseMediumIcon className={styles.disabled} />
                  </Tooltip>
                )}
              </div>
            </div>
          );
        })}
        <Button
          lightBlue
          rightIcon={PlusMiddleIcon}
          leftText='Add variation'
          onClick={this._handleAddValue}
        />

        <div className={styles.footer}>
          <Button
            lightBlue
            medium
            spinning={this.props.submitting}
            right
            middleText={__('button.done')}
            onClick={() => {
              this.props.handleSubmit();
            }}
          />
        </div>
      </Form>
    );
  }
}

export default EditProductAttributeGroupFieldValuesForm;
