import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddProductAttributeGroupFieldWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeAddIcon from "../assets/images/product-attribute-add-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import AddProductAttributeGroupFieldForm
	from "../forms/add_product_attribute_group_field_wizard/AddProductAttributeGroupFieldForm";
import {setNotification} from "../redux/actions/general/notification";
import {addProductAttributeGroupField, readProduct} from "../redux/actions/table/products";

@mapStateToProps(state => ({
	group_id: _.get(state, 'table.products.add_product_attribute_group_field_wizard'),
	flyout: state.table.products.flyout
}))
class AddProductAttributeGroupFieldWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		formData = {
			...formData,
			custom_attribute: _.get(formData, 'custom_attribute', false) ? 1 : 0,
		};

		return validateForm(
			addProductAttributeGroupField(this.props.group_id, formData).then(() => readProduct(this.props.flyout.id).then(({response}) => {
				setNotification(__('products.all-products.flyout.add-attributes-group.product-attributes-have-been-added'));

				setTableParams('products', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('products.all-products.flyout.add-attributes-group.add-product-attributes')}</span>
						<span>{__('products.all-products.flyout.add-attributes-group.add-attributes-your-product-attribute-group')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeAddIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddProductAttributeGroupFieldForm onSubmit={this._handleSubmit} initialValues={{
						custom_attribute: false,
						label: {}
					}} />
				</div>
			</>
		);
	}
}

export default AddProductAttributeGroupFieldWizard;
