import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import styles from "../../styles/views/general/StartPage.scss";
import Select from "../../components/Select";
import classNames from "classnames";
import {
    acceptCompanyJoinSuggestion,
    getInvitation,
    joinCompany,
    sendJoinRequest,
    showCreateCompanyWizard
} from "../../redux/actions/profile";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import {__, dateTimeFrom, transformToListPairs} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import autobind from "autobind-decorator";
import {listPublicCompanies} from "../../redux/actions/companies";
import {getUser} from "../../redux/actions/auth";

class StartPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invitations: {},
            companies: [],
        };
    }

    _refresh() {
        getInvitation().then(({response}) => {
            this.setState({
                invitations: response.data
            });
        });

        listPublicCompanies().then(({response}) => {
            this.setState({
                companies: response.data
            });
        })
    }

    componentDidMount() {
        this._refresh();
    }

    @autobind
    _removeCompanyJoinSuggestion(e) {
        e.stopPropagation();

        showAlertbox({
            color: "red",
            title: __('general.alert.are-you-sure'),
            description: __('start-page.alert.are-you-sure.suggestion-remove'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.button.yes-remove'),
                    onClick: () => {
                        alert('ovdje brisemo sugestiju');
                    },
                }
            ]
        })
    }

    @autobind
    _removeCompanyJoinInvitation(e) {
        e.stopPropagation();

        showAlertbox({
            color: "red",
            title: __('general.alert.are-you-sure'),
            description: __('start-page.alert.are-you-sure.decline-invitation'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.button.yes-decline'),
                    onClick: () => {
                        alert('ovdje brisemo invitation');
                    },
                }
            ]
        })
    }

    @autobind
    _removeCompanyJoinRequest(e) {
        e.stopPropagation();

        showAlertbox({
            color: "red",
            title: __('general.alert.are-you-sure'),
            description: __('start-page.alert.are-you-sure.cancel-request'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.button.yes-cancel'),
                    onClick: () => {
                        alert('ovdje brisemo request');
                    },
                }
            ]
        })
    }

    @autobind
    _joinCompany(company_join_invitation_id) {
        showAlertbox({
            color: "blue",
            title: __('general.alert.are-you-sure'),
            description: __('start-page.alert.are-you-sure.join-company'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.button.yes-join-company'),
                    onClick: () => {
                        joinCompany(company_join_invitation_id).then(({response}) => {
                            getUser().then(() => {
                                hideAlertbox();
                                setTimeout(() => setNotification(__('start-page.join-request.successfully-joined', {COMPANY: response.data.company})),25);
                            });
                        });
                    },
                }
            ]
        })
    }

    @autobind
    _sendJoinRequest(company_id) {
        showAlertbox({
            color: "blue",
            title: __('general.alert.are-you-sure'),
            description: __('start-page.are-you-sure.join-request'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.yes'),
                    onClick: () => {
                        sendJoinRequest(company_id).then(({response}) => {
                            hideAlertbox();

                            this.setState({
                                company_id: undefined,
                            });

                            setNotification(__('start-page.join-request.successfully-sent', {COMPANY: response.data.company}));

                            this._refresh();
                        });
                    },
                }
            ]
        })
    }

    @autobind
    _acceptCompanyJoinSuggestion(company_join_suggestion_id) {
        showAlertbox({
            color: "blue",
            title: __('general.alert.are-you-sure'),
            description: __('start-page.are-you-sure.join-request'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: 'Yes',
                    onClick: () => {
                        acceptCompanyJoinSuggestion(company_join_suggestion_id).then(({response}) => {
                            hideAlertbox();
                            setNotification(__('start-page.join-request.successfully-sent', {COMPANY: response.data.company}))

                            this._refresh();
                        });
                    },
                }
            ]
        })
    }

    render() {
        const {search_dropdown, invitations, companies} = this.state;
        const company_id = this.state.company_id || '';
        const {virtual_company_invitations, company_join_invitations, company_join_suggestions, company_join_requests} = invitations;

        return (
            <div className={styles.wrapper}>
                <div className={styles.scroller}>
                    <div className={styles.welcome}>{__('start-page.welcome-to-volume')}</div>
                    <div className={styles.info}>
                        In order to continue using VOLUM3 application, you have to be a member of a registered company.<br/>
                        You can join already registered company or create your own.<br/><br/>
                        <strong>Please explore the available options and then continue by choosing one.</strong>
                    </div>
                    {!_.isEmpty(invitations) && <div className={styles.boxes}>
                        <div className={classNames(styles.box, styles.f2)}>
                            <div className={styles.header}>
                                <strong>Create Company</strong>
                                <span>{_.isEmpty(invitations) ? <>In order to use VOLUM3, you have to be a company member.<br />You may create your own company by click the button below.</> : <>Registered member(s) suggested you to create company.<br />You can accept the suggestion by choosing one of the options below.</>}</span>
                            </div>
                            <div className={styles.content}>
                                {_.map(virtual_company_invitations, (invitation, i) => (
                                    <div className={styles.row} key={i}>
                                        <div className={styles.details} onClick={() => showCreateCompanyWizard(invitation.company, invitation.id)}>
                                            <div className={styles.left}>
                                                <div className={styles.title}>
                                                    Create company '{invitation.company}'
                                                </div>
                                                <div className={styles.description}>
                                                    Suggestion from {invitation.inviter.name} ({invitation.inviter.company}), at {dateTimeFrom(invitation.invited_at)}
                                                </div>
                                            </div>
                                            <button>Create company</button>
                                        </div>
                                        {/*<CloseMiddleIcon onClick={(e) => this._removeCompanyJoinSuggestion(e)} />*/}
                                    </div>
                                ))}
                                <div className={styles.footer}>
                                    {!_.isEmpty(virtual_company_invitations) && <span className={styles.or}>Or</span>}
                                    <div className={styles.createCompany}>
                                        <button onClick={() => showCreateCompanyWizard()}>Create Your Own Company</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(!_.isEmpty(company_join_invitations) || !_.isEmpty(company_join_suggestions)) && <div className={classNames(styles.box, styles.f3)}>
                            <div className={styles.header}>
                                <strong>Invitations</strong>
                                <span>Registered member(s) suggested you to join a company.<br />You can accept the suggestion and join the company by clicking one of the options below.</span>
                            </div>
                            <div className={styles.content}>
                                {_.map(company_join_invitations, (invitation, i) => (
                                    <div className={styles.row} key={i}>
                                        <div className={styles.details} onClick={() => this._joinCompany(invitation.id)}>
                                            <div className={styles.left}>
                                                <div className={styles.title}>
                                                    Join company '{invitation.company}'
                                                </div>
                                                <div className={styles.description}>
                                                    Invitation from {invitation.inviter}, at {dateTimeFrom(invitation.invited_at)}
                                                </div>
                                            </div>
                                            <button>Join Company</button>
                                        </div>
                                        {/*<CloseMiddleIcon onClick={(e) => this._removeCompanyJoinInvitation(e)}/>*/}
                                    </div>
                                ))}
                                {_.map(company_join_suggestions, (invitation, i) => (
                                    <div className={styles.row} key={i}>
                                        <div className={styles.details} onClick={() => this._acceptCompanyJoinSuggestion(invitation.id)}>
                                            <div className={styles.left}>
                                                <div className={styles.title}>
                                                    Request to join company '{invitation.company}'
                                                </div>
                                                <div className={styles.description}>
                                                    Suggestion from {invitation.inviter.name} ({invitation.inviter.company}), at {dateTimeFrom(invitation.invited_at)}
                                                </div>
                                            </div>
                                            <button>Send Join Request</button>
                                        </div>
                                        {/*<CloseMiddleIcon onClick={(e) => this._removeCompanyJoinSuggestion(e)}/>*/}
                                    </div>
                                ))}
                            </div>
                        </div>}
                        <div className={classNames(styles.box, styles.f2)}>
                            <div className={styles.header}>
                                <strong>{_.isEmpty(company_join_requests) ? 'Join Existing Company' : 'Requests'}</strong>
                                <span>{_.isEmpty(company_join_requests) ? <>Select the company you wish to join from the list below.<br />You will need to be approved by the company administrator.</> : <>You have sent a request to join company(s).<br />Also, you can send a new request by choosing the company below.</>}</span>
                            </div>
                            <div className={styles.content}>
                                {_.map(company_join_requests, (invitation, i) => (
                                    <div className={styles.row} key={i}>
                                        <div className={styles.details}>
                                            <div className={classNames(styles.left, styles.readOnly)}>
                                                <div className={styles.title}>
                                                    Join company '{invitation.company}'
                                                </div>
                                                <div className={styles.description}>
                                                    Request sent at {dateTimeFrom(invitation.sent_at)}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<CloseMiddleIcon onClick={(e) => this._removeCompanyJoinRequest(e)}/>*/}
                                    </div>
                                ))}
                                <div className={styles.footer}>
                                    {!_.isEmpty(company_join_requests) && <span className={styles.or}>Or</span>}
                                    <div className={styles.joinCompany}>
                                        <Select searchable input={{
                                            value: company_id,
                                            onChange: company_id => {
                                                this.setState({
                                                    company_id,
                                                    search_dropdown: false
                                                });
                                            },
                                            onBlur: () => {
                                                this.setState({search_dropdown: false});
                                            },
                                            onFocus: () => this.setState({search_dropdown: true})
                                        }} meta={{
                                            active: search_dropdown
                                        }} label={_.isEmpty(company_join_requests) ? "Choose Company" : "Choose Another Company"} options={transformToListPairs(companies)} noSort />
                                        <button className={classNames(!company_id && styles.disabled)} onClick={() => company_id && this._sendJoinRequest(company_id)}>Send request</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default StartPage;