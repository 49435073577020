import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/brands/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import EditBrandForm from "../../forms/brands/EditBrandForm";
import {listBrands, updateBrand} from "../../redux/actions/table/brands";
import {listPossibleTags} from "../../redux/actions/general";
import EditRoomForm from "../../forms/rooms/EditRoomForm";

@withRouter
class Properties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collections: [],
            families: [],
        };
    }

    @autobind
    _loadTags() {
        listPossibleTags('brand_collection').then(({response}) => {
            this.setState({collections: response.data});
        });

        listPossibleTags('brand_family').then(({response}) => {
            this.setState({families: response.data});
        });
    }

    componentDidMount() {
        this._loadTags();
    }

    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateBrand(formData.id, _.omit(formData, ['id'])).then(() => {
                this._loadTags();

                listBrands();

                this.props.readAction(formData.id);

                setNotification(__('brands.flyout.notification.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;
        const {collections, families} = this.state;

        return (
            <div className={styles.scroller}>
                <EditBrandForm
                    onSubmit={this._handleSubmit}
                    data={data}
                    collectionTags={collections}
                    familyTags={families}
                    initialValues={{
                        ..._.pick(data, ['id', 'name', 'collection', 'family', 'address', 'phone', 'web', 'logo']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
