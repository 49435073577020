import store from "../../../../core/store";

export const listBOQTemplateGroups = () => {
	return store.dispatch({
		action: "table.superadmin_boq_template_groups/LIST_BOQ_TEMPLATE_GROUPS",
		method: "GET",
		endpoint: "/superadmin/boq_template_groups",
		main: true
	});
};

export const createBOQTemplateGroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_groups/CREATE_BOQ_TEMPLATE_GROUP",
		method: "POST",
		endpoint: "/superadmin/boq_template_groups",
		data: formData
	});
};

export const deleteBOQTemplateGroups = (boq_template_group_ids) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_groups/DELETE_BOQ_TEMPLATE_GROUPS",
		method: "DELETE",
		endpoint: "/superadmin/boq_template_groups",
		data: {
			boq_template_groups: boq_template_group_ids
		}
	});
};

export const readBOQTemplateGroup = (boq_template_group_id) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_groups/READ_BOQ_TEMPLATE_GROUP",
		method: "GET",
		endpoint: "/superadmin/boq_template_groups/" + boq_template_group_id,
		has404: true
	});
};

export const updateBOQTemplateGroup = (boq_template_group_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_groups/UPDATE_BOQ_TEMPLATE_GROUP",
		method: "PUT",
		endpoint: "/superadmin/boq_template_groups/" + boq_template_group_id,
		data: formData
	});
};