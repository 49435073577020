import * as React from 'react';
import { Component } from 'react';
import { Field, focus, Form, getFormValues, reduxForm } from 'redux-form';
import { __, dateFrom } from '../../core/utils';
import styles from '../../styles/forms/tasks/EditTaskToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import TaskPriorityIcon from '../../assets/images/task-priority-15x15.svg';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';
import classNames from 'classnames';
import moment from 'moment';
import DateTimePicker from '../../components/DateTimePicker';
import DateIcon from '../../assets/images/date-15x15.svg';
import connect from 'react-redux/es/connect/connect';
import { enableAutosave } from '../../redux/actions/general/autosave';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';

@reduxForm({
  form: 'tasks.edit_task_toolbar',
  enableReinitialize: true,
})
@connect(
  (state) => ({
    dropdown: state.general.dropdown.shown,
    user_id: state.auth.id,
    values: getFormValues('tasks.edit_task_toolbar')(state),
  }),
  (dispatch) => ({
    focus: (field) => {
      dispatch(focus('tasks.edit_task_toolbar', field));
    },
  })
)
class EditTaskToolbarForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  render() {
    const { data, user_id, values } = this.props;
    const { is_editor, is_reviewer } = data;
    const is_assignee = _.includes(_.map(data.assignee, 'id'), user_id);
    const status = _.get(values, 'status');
    const disabled =
      !is_editor || _.includes(['closed', 'canceled'], data.status);

    const statuses = [
      {
        value: 'not-started',
        label: __('tasks.status.not-started'),
        color: '#9C27B0',
        disabled: !(
          (is_editor || (is_reviewer && is_assignee)) &&
          _.includes(['started'], status)
        ),
      },
      {
        value: 'started',
        label: __('tasks.status.started'),
        color: '#5777FC',
        disabled: !(
          (is_editor || (is_reviewer && is_assignee)) &&
          _.includes(['not-started', 'reopened'], status)
        ),
      },
      {
        value: 'finished',
        label: __('tasks.status.finished'),
        color: '#ADD42B',
        disabled: !(
          (is_editor || (is_reviewer && is_assignee)) &&
          _.includes(['not-started', 'started'], status)
        ),
      },
      {
        value: 'closed',
        label: __('tasks.status.closed'),
        color: '#009688',
        disabled: !(
          is_editor &&
          _.includes(['not-started', 'started', 'finished'], status)
        ),
      },
      {
        value: 'reopened',
        label: __('tasks.status.reopened'),
        color: '#303F9F',
        disabled: !(
          is_editor && _.includes(['finished', 'closed', 'canceled'], status)
        ),
      },
      {
        value: 'canceled',
        label: __('tasks.status.canceled'),
        color: '#CCCDD1',
        disabled: !(
          is_editor &&
          _.includes(['not-started', 'started', 'finished', 'reopened'], status)
        ),
      },
    ];

    const priorities = [
      {
        value: 'low',
        label: __('tasks.priority.low'),
        color: '#FFC108',
      },
      {
        value: 'normal',
        label: __('tasks.priority.normal'),
        color: '#FE9800',
      },
      {
        value: 'high',
        label: __('tasks.priority.high'),
        color: '#FF5721',
      },
      {
        value: 'urgent',
        label: __('tasks.priority.urgent'),
        color: '#E62A11',
      },
    ];

    const visibilities = [
      {
        value: '0',
        label: __('tasks.visibility.private'),
        color: '#7f7f7d',
      },
      {
        value: '1',
        label: __('tasks.visibility.public'),
        color: '#029DFC',
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <Field
          disabled={disabled}
          name='date'
          component={DateTimePicker}
          label={__('Date & Time')}
          footer
          dropdown
          range
          time
          top={32}
          presets
          clearDate
          wrapper={(handleOpen, value) => (
            <Tooltip text={__('tasks.tooltips.datetime.change-date')}>
              <div
                className={classNames(styles.date, disabled && styles.disabled)}
                onClick={handleOpen}
              >
                <DateIcon />{' '}
                {value.start && value.end
                  ? moment
                      .utc(value.start)
                      .local()
                      .startOf('day')
                      .format('X') ==
                    moment.utc(value.end).local().startOf('day').format('X')
                    ? dateFrom(value.start)
                    : dateFrom(value.start) + ' - ' + dateFrom(value.end)
                  : __('tasks.flyout.select-date')}
                <ArrowDownSmallIcon />
              </div>
            </Tooltip>
          )}
        />
        <Field
          name='status'
          disabled={
            !(
              is_editor ||
              (is_reviewer &&
                is_assignee &&
                _.includes(['not-started', 'started', 'reopened'], status))
            )
          }
          component={Select}
          label={__('Status')}
          options={statuses}
          noSort
          dropdown
          top={34}
          left={-40}
          icon={<TaskStatusIcon className={styles.taskStatus} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('tasks.tooltip.icon.status')}>
              <div
                className={classNames(
                  styles.dropdown,
                  !(
                    is_editor ||
                    (is_reviewer &&
                      is_assignee &&
                      _.includes(
                        ['not-started', 'started', 'reopened'],
                        status
                      ))
                  ) && styles.disabled
                )}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskStatusIcon className={styles.taskStatus} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
        <Field
          name='priority'
          disabled={disabled}
          component={Select}
          label={__('Priority')}
          options={priorities}
          noSort
          dropdown
          top={34}
          left={-40}
          icon={<TaskPriorityIcon className={styles.taskPriority} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('tasks.tooltip.icon.priority')}>
              <div
                className={classNames(
                  styles.dropdown,
                  disabled && styles.disabled
                )}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskPriorityIcon className={styles.taskPriority} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
        <Field
          name='visibility'
          disabled={disabled}
          component={Select}
          label={__('Visibility')}
          options={visibilities}
          noSort
          dropdown
          top={34}
          left={-40}
          icon={<TaskVisibilityIcon className={styles.taskVisibility} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('tasks.tooltip.icon.visibility')}>
              <div
                className={classNames(
                  styles.dropdown,
                  disabled && styles.disabled
                )}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskVisibilityIcon className={styles.taskVisibility} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </Form>
    );
  }
}

export default EditTaskToolbarForm;
