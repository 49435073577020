const initialState = {};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "table.address_book_companies/SET_TABLE_PARAMS":
			return {
				...state,
				...action.params
			};
		case "table.address_book_companies/LIST_ADDRESS_BOOK_COMPANIES_SUCCESS":
			return {
				...state,
				data: action.response.data,
                filters: action.response.filters,
				...action.response.pagination
			};
		default:
			return state;
	}
}
