import store from "../../../core/store";

export const listPossibleStageDisciplines = (stage_id) => {
    return store.dispatch({
        action: "table.teams/LIST_STAGE_DISCIPLINES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_disciplines",
    });
};

export const listPossibleTeamCompanies = () => {
    return store.dispatch({
        action: "table.teams/LIST_POSSIBLE_TEAM_COMPANIES",
        method: "GET",
        endpoint: "/teams/possible_companies",
    });
};

export const createTeam = (stage_id, formData) => {
    return store.dispatch({
        action: "table.teams/CREATE_TEAM",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/teams",
        data: formData
    });
};

export const listAllTeams = (stage_id) => {
    return store.dispatch({
        action: "table.teams/LIST_ALL_TEAMS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/teams",
        main: true
    });
};

export const refreshTeams = (stage_id) => {
    return store.dispatch({
        action: "table.teams/LIST_ALL_TEAMS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/teams",
        main: true,
        preloaded: false
    });
};

export const listPossibleTeamMembers = (team_id) => {
    return store.dispatch({
        action: "table.teams/LIST_POSSIBLE_TEAM_MEMBERS",
        method: "GET",
        endpoint: "/teams/" + team_id + "/possible_team_members",
    });
};

export const addTeamMembers = (formData) => {
    return store.dispatch({
        action: "table.teams/ADD_TEAM_MEMBERS",
        method: "POST",
        endpoint: "/teams/members",
        data: formData
    });
};

export const getTeamsPermissions = (stage_id) => {
    return store.dispatch({
        action: "table.teams/GET_TEAMS_PERMISSIONS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/permissions"
    });
};

export const deactivateTeamMember = (team_id, user_id) => {
    return store.dispatch({
        action: "table.teams/DEACTIVATE_TEAM_MEMBER",
        method: "PUT",
        endpoint: "/teams/" + team_id + "/members/deactivate",
        data: {
            user_id
        }
    });
};

export const reactivateTeamMember = (team_id, user_id) => {
    return store.dispatch({
        action: "table.teams/REACTIVATE_TEAM_MEMBER",
        method: "PUT",
        endpoint: "/teams/" + team_id + "/members/reactivate",
        data: {
            user_id
        }
    });
};

export const deleteTeam = (team_id) => {
    return store.dispatch({
        action: "table.teams/DELETE_TEAM",
        method: "DELETE",
        endpoint: "/teams/" + team_id,
    });
};

export const deleteTeamMemberInvitation = (team_member_invitation_id) => {
    return store.dispatch({
        action: "table.teams/DELETE_TEAM_MEMBER_INVITATION",
        method: "DELETE",
        endpoint: "/teams/team_member_invitations/" + team_member_invitation_id,
    });
};

export const deleteCompanyJoinInvitation = (company_join_invitation_id) => {
    return store.dispatch({
        action: "table.teams/DELETE_COMPANY_JOIN_INVITATION",
        method: "DELETE",
        endpoint: "/teams/company_join_invitations/" + company_join_invitation_id,
    });
};

export const deleteCompanyJoinSuggestion = (company_join_suggestion_id) => {
    return store.dispatch({
        action: "table.teams/DELETE_COMPANY_JOIN_SUGGESTION",
        method: "DELETE",
        endpoint: "/teams/company_join_suggestions/" + company_join_suggestion_id,
    });
};

export const deleteCompanyJoinRequest = (company_join_request_id) => {
    return store.dispatch({
        action: "table.teams/DELETE_COMPANY_JOIN_REQUEST",
        method: "DELETE",
        endpoint: "/teams/company_join_requests/" + company_join_request_id
    });
};

export const deleteVirtualCompanyInvitation = (virtual_company_invitation_id) => {
    return store.dispatch({
        action: "table.teams/DELETE_VIRTUAL_COMPANY_INVITATION",
        method: "DELETE",
        endpoint: "/teams/virtual_company_invitations/" + virtual_company_invitation_id
    });
};

export const resendTeamMemberInvitation = (team_member_invitation_id) => {
    return store.dispatch({
        action: "table.teams/RESEND_TEAM_MEMBER_INVITATION",
        method: "POST",
        endpoint: "/teams/team_member_invitations/" + team_member_invitation_id + "/resend",
    });
};

export const resendCompanyJoinInvitation = (company_join_invitation_id) => {
    return store.dispatch({
        action: "table.teams/RESEND_COMPANY_JOIN_INVITATION",
        method: "POST",
        endpoint: "/teams/company_join_invitations/" + company_join_invitation_id + "/resend",
    });
};

export const resendCompanyJoinSuggestion = (company_join_suggestion_id) => {
    return store.dispatch({
        action: "table.teams/RESEND_COMPANY_JOIN_SUGGESTION",
        method: "POST",
        endpoint: "/teams/company_join_suggestions/" + company_join_suggestion_id + "/resend",
    });
};

export const resendCompanyJoinRequest = (company_join_request_id) => {
    return store.dispatch({
        action: "table.teams/RESEND_COMPANY_JOIN_REQUEST",
        method: "POST",
        endpoint: "/teams/company_join_requests/" + company_join_request_id + "/resend",
    });
};

export const resendVirtualCompanyInvitation = (virtual_company_invitation_id) => {
    return store.dispatch({
        action: "table.teams/RESEND_VIRTUAL_COMPANY_INVITATION",
        method: "POST",
        endpoint: "/teams/virtual_company_invitations/" + virtual_company_invitation_id + "/resend",
    });
};

export const updateTeamMemberRole = (team_id, user_id, role) => {
    return store.dispatch({
        action: "table.teams/UPDATE_TEAM_MEMBER_ROLE",
        method: "PUT",
        endpoint: "/teams/" + team_id + "/members/" + user_id + "/role",
        data: {
            role
        }
    });
};

export const updateTeamMemberInvitationRole = (team_member_invitation_id, role) => {
    return store.dispatch({
        action: "table.teams/UPDATE_TEAM_MEMBER_INVITATION_ROLE",
        method: "PUT",
        endpoint: "/teams/team_member_invitations/" + team_member_invitation_id + "/role",
        data: {
            role
        }
    });
};

export const updateCompanyJoinInvitationRole = (company_join_invitation_id, role) => {
    return store.dispatch({
        action: "table.teams/UPDATE_COMPANY_JOIN_INVITATION_ROLE",
        method: "PUT",
        endpoint: "/teams/company_join_invitations/" + company_join_invitation_id + "/role",
        data: {
            role
        }
    });
};

export const updateCompanyJoinSuggestionRole = (company_join_suggestion_id, role) => {
    return store.dispatch({
        action: "table.teams/UPDATE_COMPANY_JOIN_SUGGESTION_ROLE",
        method: "PUT",
        endpoint: "/teams/company_join_suggestions/" + company_join_suggestion_id + "/role",
        data: {
            role
        }
    });
};

export const updateCompanyJoinRequestRole = (company_join_request_id, role) => {
    return store.dispatch({
        action: "table.teams/UPDATE_COMPANY_JOIN_REQUEST_ROLE",
        method: "PUT",
        endpoint: "/teams/company_join_requests/" + company_join_request_id + "/role",
        data: {
            role
        }
    });
};

export const updateVirtualCompanyInvitationRole = (virtual_company_invitation_id, role) => {
    return store.dispatch({
        action: "table.teams/UPDATE_VIRTUAL_COMPANY_INVITATION_ROLE",
        method: "PUT",
        endpoint: "/teams/virtual_company_invitations/" + virtual_company_invitation_id + "/role",
        data: {
            role
        }
    });
};

export const updateTeam = (team_id, formData) => {
    return store.dispatch({
        action: "table.teams/UPDATE_TEAM",
        method: "PUT",
        endpoint: "/teams/" + team_id,
        data: formData
    });
};