import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/boq/BOQFlyout.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { __, getURLParam } from '../../core/utils';
import Properties from './Properties';
import Quantities from './Quantities';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.working_set_boq/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(
  (state) => ({
    user_id: state.auth.id,
    flyout: state.table.working_set_boq.flyout,
  }),
  mapDispatchToProps
)
@withRouter
class BOQItemFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed_tabs: [],
      highlighted_tab: null,
    };

    this.highlight_timeout = null;

    this.tabs = ['properties'];

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  render() {
    const { collapsed_tabs, highlighted_tab } = this.state;

    const { flyout, readAction, project_languages } = this.props;

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              {_.get(
                flyout?.name,
                _.findKey(project_languages, (primary) => primary == 1)
              )}
            </span>
            <CloseBigIcon
              onClick={() =>
                setTimeout(() => {
                  this.props.actions.setTableParams({
                    clicked_row: undefined,
                  });
                }, 100)
              }
            />
          </div>
        </div>

        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>{__('boq.flyout.properties')}</Tab>
          </div>
        </div>

        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('boq.flyout.properties')}
            </div>
            <Properties
              data={flyout}
              readAction={readAction}
              collapsed={_.includes(collapsed_tabs, 'properties')}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default BOQItemFlyout;
