import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { mapStateToProps, getLocalized } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { withRouter } from "react-router";
import Localization from "../../../helpers/Localization";
import { subscribe } from "react-contextual";

@subscribe(Localization, "localization")
@reduxForm({
	form: "superadmin_units.edit_unit",
	enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	application_language_id: state.auth.language_id
}))
class EditUnitForm extends Component {
	UNSAFE_componentWillReceiveProps() {
		enableAutosave(this.props.form);
	}

	render() {
		const { handleSubmit, superadmin, localization, application_language_id } = this.props;

		const application_languages = _.transform(_.filter(localization.languages, language => language.application_language == true), (languages, language) => {
			languages[language.id] = language.id == 1;

			return languages;
		}, {});

		const unit_groups = _.sortBy(_.map(localization.unit_groups, item => ({
			value: item.id,
			priority: item.priority,
			label: getLocalized(item.name, application_language_id),
		})), 'priority');

		return (
			<Form onSubmit={handleSubmit}>
				<Field disabled={!superadmin.edit_unit} localized={application_languages} name="name" component={Input} label="Name" />
				<Field disabled={!superadmin.edit_unit} localized={application_languages} name="symbol" component={Input} label="Symbol" />
				<Field disabled={!superadmin.edit_unit} localized={application_languages} name="description" component={Input} label="Description" />
				<Field disabled={!superadmin.edit_unit} name="coefficient" numeric decimal component={Input} decimals={9} label="Coefficient" />
				<Field disabled={!superadmin.edit_unit} name="unit_group_id" component={Select} searchable noSort options={unit_groups} label="Unit Group" />
			</Form>
		);
	}
}

export default EditUnitForm;