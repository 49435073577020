import store from "../../../core/store";

export const listActiveModules = () => {
    return store.dispatch({
        action: "general.active_modules/LIST_ACTIVE_MODULES",
        method: "GET",
        endpoint: "/active_modules",
    });
};

export const clearActiveModules = () => {
    return store.dispatch({
        type: "general.active_modules/CLEAR_ACTIVE_MODULES",
    });
};

export const enableProjectsDropdown = (onClick) => {
    return store.dispatch({
        type: "general.active_modules/ENABLE_PROJECTS_DROPDOWN",
        onClick
    });
};

export const enableStagesDropdown = (onClick) => {
    return store.dispatch({
        type: "general.active_modules/ENABLE_STAGES_DROPDOWN",
        onClick
    });
};