import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../../styles/forms/superadmin/add_filter_wizard/AddFilterForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import MultiSelect from "../../../components/MultiSelect";
import * as _ from "lodash";

@subscribe(Localization, "localization")
@withRouter
@reduxForm({ form: "superadmin.add_filter_wizard.add_filter" })
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_filter_wizard.add_filter")(state),
    auth: state.auth,
    application_language_id: state.auth.language_id,
    superadmin: state.auth.superadmin
}))
class AddFilterForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const { localization, application_language_id } = this.props;

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == this.props.auth.language_id;

            return languages;
        }, {});

        const product_attribute_fields = _.sortBy(_.map(localization.product_attribute_fields, field => ({
            label: getLocalized(field.label, application_language_id),
            value: field.id,
            tooltip: getLocalized(field.description, application_language_id),
            groupPriority: _.get(localization.product_attribute_groups, [field.product_attribute_template_group_id, 'priority']),
            group: getLocalized(_.get(localization.product_attribute_groups, [field.product_attribute_template_group_id, 'name']), application_language_id)
        })), 'groupPriority');

          return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" localized={application_languages} component={Input} label="Name" />
				    <Field searchable name="product_attribute_template_field_ids"  component={MultiSelect} label="Product Attributes" noSort options={product_attribute_fields} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_filters', {
                        add_filter_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddFilterForm;
