import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {__, getURLParam, linkifyText, mapStateToProps, setTableParams} from "../../core/utils";
import Table from "../../components/Table";
import {setPlusActions} from "../../redux/actions/general/plus";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import {listBrands, readBrand} from "../../redux/actions/table/brands";
import BrandsFlyout from "../../flyouts/brands/BrandsFlyout";
import TableNA from "../../components/TableNA";

@mapStateToProps(state => ({
    table: state.table["brands"],
    auth: state.auth
}))
class Brands extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preview: null,
        };
    }

    componentDidMount() {
        setPlusActions([{
            title: __('button.create-new-brand'),
            onClick: () => setTableParams('brands', {
                wizard_active: true
            })
        }]);

        createSidebar({
            title: __('topbar.account.company-settings'),
            items: [
                {
                    title: __('submenu.title.company-details'),
                    link: () => "/company/details",
                },
                {
                    title: __('submenu.title.offices'),
                    link: () => "/company/offices"
                },
                {
                    title: __('company-settings.submenu.title.brands'),
                    link: () => "/company/brands",
                    active: true
                },
                {
                    title: __('company-settings.submenu.title.disciplines'),
                    link: () => "/company/disciplines",
                },
                {
                    title: __('submenu.title.market'),
                    link: () => "/company/market",
                },
                // {
                //     title: __("submenu.title.subscription-and-billing"),
                //     link: () => "/company/subscription"
                // }
            ]
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
            if (!prevProps.table.clicked_row) {
                this.setState({ preview: undefined });
            }

            readBrand(this.props.table.clicked_row).then(({ response }) => {
                this.setState({ preview: response.data });
            });
        }
    }

    render() {
        const columns = [
            {
                key: "name",
                name: __('company-settings.submenu.brands.title.name'),
                required: true,
            }, {
                key: "collections",
                name: __('company-settings.submenu.brands.title.collection'),
                width: 200,
                value: values => _.isEmpty(values) ? <TableNA/> : values.join(', '),
            }, {
                key: "families",
                name: __('company-settings.submenu.brands.title.family'),
                width: 200,
                value: values => _.isEmpty(values) ? <TableNA /> : values.join(', ')
            }, {
                key: "address",
                name: __('company-settings.submenu.brands.title.address'),
                width: 200,
            }, {
                key: "phone",
                name: __('company-settings.submenu.brands.title.phone'),
                width: 150,
            }, {
                key: "web",
                name: __('company-settings.submenu.brands.title.web'),
                width: 150,
                value: (web) => web ? linkifyText(web) : <TableNA />
            }
        ];
        
        const preview = (
            <BrandsFlyout
                data={this.state.preview}
                readAction={brand_id => {
                    return new Promise(resolve => {
                        readBrand(getURLParam('id') || brand_id).then(({response}) => {
                            this.setState({preview: response.data});

                            resolve(response);
                        });
                    });
                }}
            />
        );

        const gridOptions = {
          section: __('products.submenu.title.brands'),
          image: (id, row) => row.logo,
          title: (row) => row.name,
          subTitle: (row) => [], //todo
          content: (row) => [], //TODO
        };

        return (
          <Table
            listAction={() => listBrands()}
            name="brands"
            title={__('products.submenu.title.brands')}
            columns={columns}
            gridOptions={gridOptions}
            preview={this.state.preview ? preview : []}
            onLoad={() => listActiveModules()}
          />
        );
    }
}

export default Brands;