import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Table from '../../components/Table';
import { __, getLocalizedListValue, setTableParams } from '../../core/utils';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import { connect } from 'react-redux';
import styles from '../../styles/views/companies_and_users/MyCompanyMembers.scss';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Dropdown from '../../components/Dropdown';
import UserMaleIcon from '../../assets/images/user-male-15x15.svg';
import TickIcon from '../../assets/images/tick-15x15.svg';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  approveCompanyJoinRequest,
  cancelCompanyJoinRequest,
  deactivateCompanyMember,
  declineCompanyJoinRequest,
  listMyCompanyMembers,
  reactivateCompanyMember,
  resendCompanyJoinInvitation,
  updateCompanyInvitationRole,
  updateCompanyMemberRole,
} from '../../redux/actions/table/my_company_members';
import { setPlusActions } from '../../redux/actions/general/plus';
import ResendIcon from '../../assets/images/resend-15x15.svg';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import TableNA from '../../components/TableNA';
import DeactivateIcon from '../../assets/images/deactivate-15x15.svg';
import ActivateIcon from '../../assets/images/activate-15x15.svg';
import * as _ from 'lodash';

const mapStateToProps = (state) => {
  return {
    table: state.table['my_company_members'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.my_company_members/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};
@connect(mapStateToProps, mapDispatchToProps)
class MyCompanyMembers extends Component {
  componentDidMount() {
    listActiveModules();

    createSidebar({
      title: __('companies-and-users.title.companies-&-users'),
      items: [
        {
          title: __('companies-and-users.sidebar.companies'),
          link: () => '/companies',
          items: [
            {
              title: __('companies-and-users.sidebar.all-companies'),
              link: () => '/companies',
            },
            {
              title: __('companies-and-users.sidebar.partner-companies'),
              link: () => '/companies/partner',
            },
          ],
        },
        {
          title: __('companies-and-users.sidebar.users'),
          active: true,
          items: [
            {
              title: __('companies-and-users.sidebar.partner-members'),
              link: () => '/companies/partner_members',
            },
            {
              title: __('companies-and-users.submenu.my-company-members'),
              link: () => '/companies/my/members',
              active: true,
            },
          ],
        },
        {
          title: __('modules.address-book'),
          link: () => '/address_book/companies',
          items: [
            {
              title: __('modules.address-book.companies'),
              link: () => '/address_book/companies',
            },
            {
              title: __('modules.address-book.contacts'),
              link: () => '/address_book/contacts',
            },
          ],
        },
      ],
    });

    _.includes(['administrator', 'owner'], this.props.auth.role) &&
      setPlusActions([
        {
          title: __('companies-and-users.my-company-members.invite-new-member'),
          onClick: () =>
            this.props.actions.setTableParams({
              wizard_active: true,
            }),
        },
      ]);
  }

  @autobind
  _setMemberRole(e, id, type, role) {
    e.stopPropagation();

    if (type == 'user') {
      updateCompanyMemberRole(id, role).then(() => {
        listMyCompanyMembers();

        setNotification(
          __(
            'companies-users.users.my-company-members.company-member-role-updated'
          )
        );

        hideDropdown();
      });
    } else if (type == 'company_join_invitation') {
      updateCompanyInvitationRole(id, role).then(() => {
        listMyCompanyMembers();

        setNotification(
          __(
            'companies-users.users.my-company-members.company-member-role-updated'
          )
        );

        hideDropdown();
      });
    }
  }

  render() {
    const columns = [
      {
        key: 'fullname',
        name: __('companies-and-users.my-company-members.column-title.name'),
        required: true,
        value: (fullname, row) =>
          fullname ? (
            row.type == 'user' && row.active == false ? (
              <span className={styles.faded}>{fullname}</span>
            ) : (
              fullname
            )
          ) : (
            <TableNA />
          ),
        width: 160,
      },
      {
        key: 'email',
        fullWidth: true,
        name: __('companies-and-users.my-company-members.column-title.email'),
        value: (email, row) =>
          row.type == 'user' && row.active == false ? (
            <span className={styles.faded}>{row.email}</span>
          ) : (
            row.email
          ),
      },
      {
        key: 'phone_number',
        name: __(
          'companies-and-users.my-company-members.column-title.phone_number'
        ),
        width: 150,
        value: (phone_number, row) =>
          row.type == 'phone_number' && row.active == false ? (
            <span className={styles.faded}>{row.phone_number}</span>
          ) : (
            row.phone_number
          ),
      },
      {
        key: 'professions',
        name: __(
          'companies-and-users.my-company-members.column-title.profession'
        ),
        value: (professions, row) =>
          _.isEmpty(professions) ? (
            <TableNA />
          ) : (
            <Tooltip
              text={_.map(professions, (profession) =>
                getLocalizedListValue(
                  this.props.localization.disciplines,
                  profession,
                  'profession'
                )
              ).join(', ')}
            >
              {row.type == 'user' && row.active == false ? (
                <span className={styles.faded}>
                  {_.map(professions, (profession) =>
                    getLocalizedListValue(
                      this.props.localization.disciplines,
                      profession,
                      'profession'
                    )
                  ).join(', ')}
                </span>
              ) : (
                _.map(professions, (profession) =>
                  getLocalizedListValue(
                    this.props.localization.disciplines,
                    profession,
                    'profession'
                  )
                ).join(', ')
              )}
            </Tooltip>
          ),
        sortable: false,
        width: 200,
      },
      {
        key: 'status',
        name: __('companies-and-users.my-company-members.column-title.status'),
        value: (status, row) => (
          <div
            className={classNames(
              styles.status,
              row.type == 'company_join_invitation' &&
                styles.companyJoinInvitation,
              row.type == 'company_join_request' && styles.companyJoinRequest,
              row.type == 'user' && !row.active && styles.deactivated
            )}
          >
            {row.type == 'user' &&
              row.active == true &&
              __(
                'companies-and-users.my-company-members.column-tiitle.status.active'
              )}
            {row.type == 'user' &&
              row.active == false &&
              __(
                'companies-and-users.my-company-members.column-tiitle.status.deactivated'
              )}
            {row.type == 'company_join_invitation' &&
              __(
                'companies-and-users.my-company-members.column-tiitle.status.pending'
              )}
            {row.type == 'company_join_request' &&
              __(
                'companies-and-users.my-company-members.column-tiitle.status.waiting-for-acceptance'
              )}
          </div>
        ),
        default: true,
        desc: true,
        fullWidth: true,
      },
      {
        key: 'role',
        name: __('companies-and-users.my-company-members.column-title.role'),
        overflow: true,
        fullWidth: true,
        value: (role, row) =>
          _.includes(['administrator', 'owner'], this.props.auth.role) ? (
            'u' + this.props.auth.id == row.id ||
            row.type == 'company_join_request' ? (
              <div className={classNames(styles.role, styles.disabled)}>
                {role}
              </div>
            ) : (
              <Dropdown
                top={23}
                right={0}
                name={'role.' + row.id}
                header={
                  <div className={styles.roleDropdownHeader}>
                    {__('companies-and-users.role.member-role')}
                    <UserMaleIcon />
                  </div>
                }
                content={
                  <div className={styles.roleDropdownContent}>
                    {this.props.auth.role === 'owner' && (
                      <div
                        className={classNames(
                          styles.item,
                          role === 'owner' && styles.active
                        )}
                        onClick={(e) =>
                          this._setMemberRole(
                            e,
                            row.id.substr(1),
                            row.type,
                            'owner'
                          )
                        }
                      >
                        {role === 'owner' && <TickIcon />}
                        <span>
                          {__('companies-and-users.role.member-role.owner')}
                        </span>
                      </div>
                    )}
                    <div
                      className={classNames(
                        styles.item,
                        role === 'administrator' && styles.active
                      )}
                      onClick={(e) =>
                        this._setMemberRole(
                          e,
                          row.id.substr(1),
                          row.type,
                          'administrator'
                        )
                      }
                    >
                      {role === 'administrator' && <TickIcon />}
                      <span>
                        {__(
                          'companies-and-users.role.member-role.administrator'
                        )}
                      </span>
                    </div>
                    <div
                      className={classNames(
                        styles.item,
                        role === 'user' && styles.active
                      )}
                      onClick={(e) =>
                        this._setMemberRole(
                          e,
                          row.id.substr(1),
                          row.type,
                          'user'
                        )
                      }
                    >
                      {role === 'user' && <TickIcon />}
                      <span>
                        {__('companies-and-users.role.member-role.user')}
                      </span>
                    </div>
                  </div>
                }
              >
                <div className={styles.role}>
                  {role} <ArrowDownSmallIcon />
                </div>
              </Dropdown>
            )
          ) : (
            _.upperFirst(role)
          ),
      },
    ];

    const singleActions = (id) => {
      const row = _.find(this.props.table.data, { id }) || {};

      let actions = [];

      if (row.type == 'company_join_invitation') {
        actions.push(
          <Tooltip
            text={__('companies-and-users.invite-new-member.resend-invitation')}
            key={_.uniqueId()}
          >
            <ResendIcon
              onClick={(e) => {
                e.stopPropagation();

                resendCompanyJoinInvitation(id.substr(1)).then(() => {
                  setTableParams('my_company_members', {
                    actioned_row: undefined,
                  });

                  setNotification(
                    __(
                      'companies-and-users.invite-new-member.notification.company-member-invitation-resent'
                    )
                  );
                });
              }}
            />
          </Tooltip>
        );

        actions.push(
          <Tooltip
            text={__(
              'companies-and-users.invite-new-member.tooltip.cancel-invitation-request'
            )}
            key={_.uniqueId()}
          >
            <DeactivateIcon
              onClick={(e) => {
                e.stopPropagation();

                cancelCompanyJoinRequest(id.substr(1)).then(() => {
                  setTableParams('my_company_members', {
                    actioned_row: undefined,
                  });

                  listMyCompanyMembers();

                  setNotification(
                    __(
                      'companies-and-users.invite-new-member.notification.successfully-canceled-request'
                    )
                  );
                });
              }}
            />
          </Tooltip>
        );
      } else if (row.type == 'company_join_request') {
        actions.push(
          <Tooltip
            text={__(
              'companies-and-users.invite-new-member.tooltip.approve-request'
            )}
            key={_.uniqueId()}
          >
            <ActivateIcon
              onClick={(e) => {
                e.stopPropagation();

                approveCompanyJoinRequest(id.substr(1)).then(() => {
                  setTableParams('my_company_members', {
                    actioned_row: undefined,
                  });

                  listMyCompanyMembers();

                  setNotification(
                    __(
                      'companies-and-users.invite-new-member.notification.successfully-approved-request.user-page-active'
                    )
                  );
                });
              }}
            />
          </Tooltip>
        );

        actions.push(
          <Tooltip
            text={__(
              'companies-and-users.invite-new-member.tooltip.decline-request'
            )}
            key={_.uniqueId()}
          >
            <DeactivateIcon
              onClick={(e) => {
                e.stopPropagation();

                declineCompanyJoinRequest(id.substr(1)).then(() => {
                  setTableParams('my_company_members', {
                    actioned_row: undefined,
                  });

                  listMyCompanyMembers();

                  setNotification(
                    __(
                      'companies-and-users.invite-new-member.notification.successfully-declined-request'
                    )
                  );
                });
              }}
            />
          </Tooltip>
        );
      } else if (row.type == 'user') {
        row.active == false &&
          (_.includes(['user', 'administrator'], row.role) ||
            this.props.auth.role == 'owner') &&
          actions.push(
            <Tooltip
              text={__(
                'companies-and-users.invite-new-member.tooltip.reactivate-company-member'
              )}
              key={_.uniqueId()}
            >
              <ActivateIcon
                onClick={(e) => {
                  e.stopPropagation();

                  reactivateCompanyMember(id.substr(1))
                    .then(() => {
                      setTableParams('my_company_members', {
                        actioned_row: undefined,
                      });

                      listMyCompanyMembers();

                      setNotification(
                        __(
                          'companies-and-users.invite-new-member.notification.successfully-reactivated-company-member.user-page-active'
                        )
                      );
                    })
                    .catch(({ response }) => {
                      if (_.has(response, 'code')) {
                        if (response.code === 1) {
                          setNotification({
                            text: __(
                              'companies-and-users.invite-new-member.warning.not-reactivate-user-member-another-company'
                            ),
                            type: 'warning',
                          });

                          setTableParams('my_company_members', {
                            actioned_row: undefined,
                          });
                        }
                      }
                    });
                }}
              />
            </Tooltip>
          );

        row.active == true &&
          this.props.auth.id != id.substr(1) &&
          (_.includes(['user', 'administrator'], row.role) ||
            this.props.auth.role == 'owner') &&
          actions.push(
            <Tooltip
              text={__(
                'companies-and-users.item-actions.tooltip.deactivate-company-member'
              )}
              key={_.uniqueId()}
            >
              <DeactivateIcon
                onClick={(e) => {
                  e.stopPropagation();

                  deactivateCompanyMember(id.substr(1)).then(() => {
                    setTableParams('my_company_members', {
                      actioned_row: undefined,
                    });

                    listMyCompanyMembers();

                    setNotification(
                      __(
                        'companies-and-users.item-actions.notification.successfully-deactivated-company-member'
                      )
                    );
                  });
                }}
              />
            </Tooltip>
          );
      }

      return actions;
    };

    const gridOptions = {
      section: __('companies-and-users.status.users'),
      image: (id, row) => row.avatar_url,
      title: (row) => row.fullname,
      rightTitle: (row) => _.upperFirst(row.role),
      subTitle: (row) => {
        let status;

        if (row.type == 'user') {
          if (row.active == true) {
            status = __('companies-and-users.status.active');
          } else {
            status = __('companies-and-users.status.deactivated');
          }
        } else if (row.type == 'company_join_invitation') {
          status = __('companies-and-users.status.pending');
        } else if (row.type == 'company_join_request') {
          status = __('companies-and-users.status.waiting-for-acceptance');
        }

        return _.filter([row.email, status]).join(' | ');
      },
      content: (row) => [], //TODO
    };

    return (
      <Table
        listAction={listMyCompanyMembers}
        singleActions={
          _.includes(['administrator', 'owner'], this.props.auth.role) &&
          singleActions
        }
        name='my_company_members'
        title={__('companies-and-users.submenu.my-company-members')}
        columns={columns}
        gridOptions={gridOptions}
      />
    );
  }
}

export default MyCompanyMembers;
