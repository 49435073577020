import store from "../../../core/store";

export const listZones = (stage_id) => {
    return store.dispatch({
        action: "table.zones/LIST_ZONES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/zones",
        main: true,
    });
};

export const createZone = (stage_id, formData) => {
    return store.dispatch({
        action: "table.zones/CREATE_ZONE",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/zones",
        data: formData
    });
};

export const readZone = (zone_id) => {
    return store.dispatch({
        action: "table.zones/READ_ZONE",
        method: "GET",
        endpoint: "/zones/" + zone_id,
        has404: true
    });
};

export const updateZone = (zone_id, formData) => {
    return store.dispatch({
        action: "table.zones/UPDATE_ZONE",
        method: "PUT",
        endpoint: "/zones/" + zone_id,
        data: formData
    });
};

export const deleteZones = (zone_ids) => {
    return store.dispatch({
        action: "table.zones/DELETE_ZONES",
        method: "DELETE",
        endpoint: "/zones",
        data: {
            zones: zone_ids
        }
    });
};