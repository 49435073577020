import {hideDocumentUploadFile} from "../../actions/table/documents";

const initialState = {
    files: []
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "table.documents/LIST_DOCUMENTS_SUCCESS":
            return {
                ...state,
                data: action.response.data,
                ...action.response.pagination
            };
        case "table.documents/LIST_DOCUMENTS_FILTERS_SUCCESS":
            return {
                ...state,
                filters: action.response.filters,
            };
        case "table.documents/SET_TABLE_PARAMS":
            return {
                ...state,
                ...action.params
            };
        case "table.documents/UPLOAD_DOCUMENT":
            return {
                ...state,
                files: [
                    ...state.files,
                    action.file
                ]
            };
        case "table.documents/UPLOAD_DOCUMENT_PROGRESS":
            return {
                ...state,
                files: _.map(state.files, file => {
                    if(file.id == action.id) {
                        file.progress = parseFloat(action.progress.loaded / action.progress.total * 100).toFixed(1);
                    }

                    return file;
                })
            };
        case "table.documents/UPLOAD_DOCUMENT_SUCCESS":
            return {
                ...state,
                files: _.map(state.files, file => {
                    if(file.id == action.request.id) {
                        file.success = true;

                        setTimeout(() => {
                            hideDocumentUploadFile(action.request.id);
                        }, 10000);
                    }

                    return file;
                })
            };
        case "table.documents/UPLOAD_DOCUMENT_REQUEST":
            return {
                ...state,
                files: _.map(state.files, file => {
                    if(file.id == action.id) {
                        file.cancel = action.cancel;
                    }

                    return file;
                })
            };
        case "table.documents/UPLOAD_DOCUMENT_FAILURE":
            return {
                ...state,
                files: _.map(state.files, file => {
                    if(file.id == action.request.id) {
                        file.success = false;

                        setTimeout(() => {
                            hideDocumentUploadFile(action.request.id);
                        }, 10000);
                    }

                    return file;
                })
            };
        case "table.documents/MINIMIZE_DOCUMENT_UPLOADS":
            return {
                ...state,
                minimize: true,
                wizard_active: undefined,
            };
        case "table.documents/CHANGE_DOCUMENT_STATUS":
            return {
                ...state,
                data: _.map(state.data, row => {
                    if(row.id == action.document) {
                        row.status = action.processed;
                    }

                    return row;
                })
            };
        case "table.documents/HIDE_DOCUMENT_UPLOAD_FILE":
            return {
                ...state,
                files: _.reject(state.files, file => file.id == action.document)
            };
        default:
            return state;
    }
}
