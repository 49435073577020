import * as React from 'react';
import styles from '../../styles/flyouts/meetings/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import { updateMeeting } from '../../redux/actions/table/all_meetings';
import { __, validateForm } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import {
  listAllTenders,
  updateTender,
} from '../../redux/actions/table/tenders';
import EditTenderForm from '../../forms/tenders/EditTenderForm';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import classNames from 'classnames';

const Properties = (props) => {
  const _handleSubmit = (formData) => {
    formData = {
      ...formData,
      followers: _.map(formData.followers, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };

    return validateForm(
      updateTender(formData.id, _.omit(formData, ['id'])).then(() => {
        listAllTenders(props.match.params.stage || props.table.stage_id);

        props.readAction(formData.id);

        setNotification(__('tenders.properties.saved'));
      })
    );
  };

  const { data, collapsed } = props;

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      <EditTenderForm
        onSubmit={_handleSubmit}
        data={data}
        initialValues={{
          ..._.pick(data, [
            'id',
            'title',
            'duration',
            'remaining_days',
            'description',
            'administrators',
            'followers',
            'question_date',
            'result_date',
            'start_date',
            'end_date',
            'all_day',
          ]),
          languages: _.map(data.languages, _.toString),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['tenders'],
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(subscribe(Localization, 'localization')(Properties))
);
