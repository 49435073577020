import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles
    from "../../styles/forms/edit_product_attribute_group_field_wizard/EditProductAttributeGroupFieldForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {__, getLocalized, mapStateToProps, setTableParams, toBoolean, transformToListPairs} from "../../core/utils";
import Select from "../../components/Select";
import classNames from "classnames";
import * as _ from "lodash";
import {withRouter} from "react-router";
import Input from "../../components/Input";

@reduxForm({
    form: "edit_product_attribute_group_field_wizard.edit_product_attribute_group_field",
})
@mapStateToProps((state, props) => ({
    store: state.table.products,
    values: getFormValues('edit_product_attribute_group_field_wizard.edit_product_attribute_group_field')(state),
    application_language_id: state.auth.language_id,
}))
@subscribe(Localization, "localization")
@withRouter
class EditProductAttributeGroupFieldForm extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.values.custom_attribute != this.props.values.custom_attribute) {
            this.props.change('template_field_id', null);
            this.props.change('unit_id', null);
            this.props.change('type', null);
            this.props.change('label', {});
        }

        if(prevProps.values.template_field_id != this.props.values.template_field_id && !this.props.values.custom_attribute) {
            const {type, unit_id} = _.find(this.props.localization.product_attribute_fields, ['id', this.props.values.template_field_id]);

            if(_.includes(['numeric', 'dropdown_numeric', 'dropdown_numeric_multiple', 'range', 'range_selectable'], type)) {
                this.props.change('unit_id', unit_id);
            } else {
                this.props.change('unit_id', null);
            }
        }
    }

    render() {
        const {flyout} = this.props.store;
        const {application_language_id} = this.props;
        const {custom_attribute, unit_id, type, template_field_id} = this.props.values;
        const units = custom_attribute ? this.props.localization.units : _.filter(this.props.localization.units, ['unit_group_id', _.get(this.props.localization.units, [unit_id, 'unit_group_id'])]);

        let group_id = null;
        const field_id = _.parseInt(this.props.store.edit_product_attribute_group_field_wizard);

        _.each(flyout.attribute_groups, group => {
            const attribute = _.find(group.attributes, ['id', field_id]);

            if(attribute) {
                group_id = group.id;
            }
        });

        const product_attribute_fields = _.sortBy(_.map(_.reject(this.props.localization.product_attribute_fields, field => {return (_.includes(_.map(_.get(_.find(flyout.attribute_groups, ['id', group_id]), 'attributes', []), 'template_field_id'), field.id) && field.id != this.props.store.template_field_id), field.discontinued}), field => ({
            label: getLocalized(field.label, application_language_id),
            value: field.id,
            groupPriority: _.get(this.props.localization.product_attribute_groups, [field.product_attribute_template_group_id, 'priority']),
            group: getLocalized(_.get(this.props.localization.product_attribute_groups, [field.product_attribute_template_group_id, 'name']), application_language_id)
        })), 'groupPriority');

        const application_languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        const data_types = [
            {
                label: __('products.add-product-attributes.custom-attribute.type.boolean'),
                value: 'boolean'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.string'),
                value: 'string'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.numeric'),
                value: 'numeric'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.color'),
                value: 'color'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.material'),
                value: 'material'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.texture'),
                value: 'texture'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.range'),
                value: 'range'
            },
            {
                label: __('products.add-product-attributes.custom-attribute.type.selectable-range'),
                value: 'range_selectable'
            }
        ];

        const unitSelectable = !custom_attribute && toBoolean(_.get(this.props.localization.product_attribute_fields, [template_field_id, 'unit_id']));

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.chooser}>
                    <div className={classNames(styles.option, !custom_attribute && styles.selected)} onClick={() => this.props.change('custom_attribute', false)}>
                        <span/> {__('products.flyout.edit-product-attribute.predefined-attribute')}
                    </div>
                    <div className={classNames(styles.option, custom_attribute && styles.selected)} onClick={() => this.props.change('custom_attribute', true)}>
                        <span/> {__('products.flyout.edit-product-attribute.custom-attribute')}
                    </div>
                </div>
                {!custom_attribute && <>
                    <Field name="template_field_id" searchable={true} component={Select} label={__('products.product-attribute-flyout.attribute')} noSort options={product_attribute_fields}/>
                    {unitSelectable && <Field name="unit_id" component={Select} searchable noSort label={__('products.product-attribute-flyout.unit')} options={transformToListPairs(units, 'symbol')} />}
                </>}
                {custom_attribute && <>
                    <Field name="type" component={Select} label={__('products.add-product-attributes.custom-attribute.type')} options={data_types}/>
                    <div className={styles.flex}>
                        <div className={styles.f3}>
                            <Field name="label" maxLength={50} component={Input} label={__('products.add-product-attributes.custom-attribute.label')} localized={application_languages}/>
                        </div>
                        {_.includes(['numeric', 'dropdown_numeric', 'dropdown_numeric_multiple', 'range', 'range_selectable'], type) && <div className={styles.f1}>
                            <Field name="unit_id" component={Select} searchable noSort label={__('products.product-attribute-flyout.unit')} options={transformToListPairs(units, 'symbol')}/>
                        </div>}
                    </div>
                </>}
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('products', {
                        edit_product_attribute_group_field_wizard: undefined,
                        template_field_id: undefined
                    })}/>
                    <Button lightBlue medium submit right leftText={__('button.edit')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default EditProductAttributeGroupFieldForm;
