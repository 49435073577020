import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddSpecificationWizard.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  mapStateToProps,
  redirect,
  setActiveProject,
  setActiveStage,
  setTableParams,
  validateForm,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import autobind from 'autobind-decorator';
import { setNotification } from '../redux/actions/general/notification';
import CopySpecificationForm from '../forms/copy_specification_wizard/CopySpecificationForm';
import { readProjectGeneral } from '../redux/actions/projects';
import {
  copySpecification,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
} from '../redux/actions/table/working_set_specifications';
import CopySpecificationIcon from '../assets/images/specification-copy-64x64.svg';
import { listActiveModules } from '../redux/actions/general/active_modules';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
class CopySpecificationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_language: null,
    };
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      this.setState({ project_language: response.data.main_language });
    });
  }

  @autobind
  _handleCopySpecification(formData) {
    const { edit } = formData;

    if (this.props.store.create_action) {
      formData = _.omit(formData, ['edit']);
    } else {
      formData = _.omit(formData, ['action', 'edit']);
    }

    if (this.props.store.linked_specification_code_id) {
      formData.linked_specification_code_id =
        this.props.store.linked_specification_code_id;
    }

    return validateForm(
      copySpecification(this.props.store.copy_wizard_active, formData).then(
        ({ response }) => {
          this.props.onClose();

          setNotification(
            __(
              'specifications.copy-specification.successfully-copied-specification'
            )
          );

          if (edit) {
            if (response.data.stage_id != getActiveStage()) {
              setActiveProject(response.data.project_id);
              setActiveStage(response.data.stage_id);

              redirect(
                '/stages/' +
                  response.data.stage_id +
                  '/specifications/working?id=' +
                  response.data.id
              );
            } else {
              setTableParams('working_set_specifications', {
                clicked_row: response.data.id,
              });
            }
          } else if (this.props.store.linked_specification_code_id) {
            readWorkingSetSpecification(this.props.store.flyout.id).then(
              ({ response }) => {
                setTableParams('working_set_specifications', {
                  flyout: response.data,
                });
              }
            );
          }

          listWorkingSetSpecifications(getActiveStage());
        }
      )
    );
  }

  render() {
    const { project_language } = this.state;

    const { specification_subgroup_id } = this.props.store.flyout;
    const { specification_code } = this.props.store;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('specifications.working-set.copy-wizard.copy-specification')}
            </span>
            <span>
              {__(
                'specifications.working-set.copy-wizard.copy-specification-in-your-workspace'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <CopySpecificationIcon />
          </div>
        </div>
        <div className={styles.body}>
          <CopySpecificationForm
            onSubmit={this._handleCopySpecification}
            projectLanguage={project_language}
            initialValues={{
              action: 'option_main',
              project_id: getActiveProject(),
              stage_id: getActiveStage(),
              specification_subgroup_id,
              specification_code,
            }}
          />
        </div>
      </>
    );
  }
}

export default CopySpecificationWizard;
