import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/notifications/NotificationsFlyout.scss';
import * as _ from 'lodash';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import { connect } from 'react-redux';
import {
  __,
  dateTimeFrom,
  linkifyText,
  redirect,
  setTableParams,
  timeFrom,
  getLocalized,
  dateFrom,
} from '../../core/utils';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import { setNotification } from '../../redux/actions/general/notification';
import withRouter from 'react-router/es/withRouter';
import Tooltip from '../../components/Tooltip';
import MessageMarkUnreadIcon from '../../assets/images/message-mark-unread-16x16.svg';
import {
  markNotificationsAsRead,
  markNotificationsAsUnread,
  readNotificationState,
} from '../../redux/actions/table/notifications';
import ProjectIcon from '../../assets/images/project-15x15.svg';
import StageIcon from '../../assets/images/stage-15x15.svg';
import ModuleIcon from '../../assets/images/module-16x16.svg.svg';
import classNames from 'classnames';
import Avatar from '../../components/Avatar';
import LogoIcon from '../../assets/images/logo-50x50.svg';
import moment from 'moment';
import NotificationsFlyoutSkeleton from './NotificationsFlyoutSkeleton';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.notifications/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

const mapStateToProps = (state) => {
  return {
    application_language_id: state.auth.language_id,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
@subscribe(Localization, 'localization')
@withRouter
class NotificationsFlyout extends Component {
  _actionToTitle(action, data) {
    switch (action) {
      case 'task_comment_added':
        return __('notification.notification-title.task-comment-added', {
          TASK: data.task,
        });
      case 'tasks_report_generated':
        return __('notification.notification-title.tasks-report-generated');
      case 'task_status_changed':
        return __('notification.notification-title.task-status-changed', {
          USER: data.user,
          TASK: data.task,
          STATUS: data.status,
        });
      case 'task_date_changed':
        return __('notification.notification-title.task-date-changed', {
          USER: data.user,
          TASK: data.task,
          DATE: data.date,
        });
      case 'task_visibility_changed':
        return __('notification.notification-title.task-visibility-changed', {
          USER: data.user,
          TASK: data.task,
          VISIBILITY: data.visibility,
        });
      case 'added_as_task_assignee':
        return __('notification.notification-title.added-as-task-assignee', {
          TASK: data.task,
        });
      case 'added_as_task_follower':
        return __('notification.notification-title.added-as-task-follower', {
          TASK: data.task,
        });
      case 'added_as_tender_follower':
        return __('notification.notification-title.added-as-tender-follower', {
          TENDER: data.tender,
        });
      case 'added_as_tender_administrator':
        return __(
          'notification.notification-title.added-as-tender-administrator',
          {
            TENDER: data.tender,
          }
        );
      case 'tender_status_changed':
        return __('notification.notification-title.tender-status-changed', {
          USER: data.user,
          TENDER: data.tender,
          STATUS: data.status,
        });
      case 'bidder_status_changed':
        return __('notification.notification-title.bidder-status-changed', {
          USER: data.user,
          TENDER: data.tender,
          STATUS: data.status,
        });

      case 'tender_date_changed':
        return __('notification.notification-title.tender-date-changed', {
          USER: data.user,
          TENDER: data.tender,
          DATE: data.date,
        });
      case 'added_as_stage_discipline_approver':
        return __(
          'notification.notification-title.added-as-stage-discipline-approver',
          {
            DISCIPLINE: data.discipline,
            STAGE: data.stage,
            PROJECT: data.project,
          }
        );
      case 'added_as_partner_company':
        return __('notification.notification-title.added-as-partner-company', {
          PARTNER_COMPANY: data.partner_company,
        });
      case 'added_as_partner_member':
        return __('notification.notification-title.added-as-partner-member', {
          USER: data.user,
          COMPANY: data.company,
        });
      case 'partner_member_request':
        return __('notification.notification-title.partner-member-request', {
          USER: data.user,
          COMPANY: data.company,
        });
      case 'user_joined_company':
        return __('notification.notification-title.user-joined-company', {
          USER: data.user,
        });
      case 'team_member_invitation':
        return __('notification.notification-title.team-member-invitation', {
          TEAM: data.team,
          STAGE: data.stage,
          PROJECT: data.project,
        });
      case 'user_joined_team':
        return __('notification.notification-title.user-joined-team', {
          TEAM: data.team,
        });
      case 'checklist_item_status_changed':
        return __(
          'notification.notification-title.checklist-item-status-changed',
          {
            TASK: data.task,
            STATUS: data.status,
          }
        );
      case 'plan_versions_approved':
        return __('notification.notification-title.plan-versions-approved', {
          DELIVERY: data.delivery,
          PROJECT: data.project,
          STAGE: data.stage,
        });
      case 'plan_versions_rejected':
        return __('notification.notification-title.plan-versions-rejected', {
          DELIVERY: data.delivery,
          PROJECT: data.project,
          STAGE: data.stage,
        });
      case 'current_set_plans_updated':
        return __('notification.notification-title.current-set-plans-updated', {
          DISCIPLINE: data.discipline,
          STAGE: data.stage,
          PROJECT: data.project,
        });
      case 'current_set_specifications_updated':
        return __(
          'notification.notification-title.current-set-specifications-updated',
          {
            DISCIPLINE: data.discipline,
            STAGE: data.stage,
          }
        );
      case 'team_membership_reactivated':
        return __(
          'notification.notification-title.team-membership-reactivated',
          {
            TEAM: data.team,
            STAGE: data.stage,
          }
        );
      case 'team_membership_deactivated':
        return __(
          'notification.notification-title.team-membership-deactivated',
          {
            TEAM: data.team,
            STAGE: data.stage,
          }
        );
      case 'meeting_report_generated':
        return __('notification.notification-title.meeting-report-generated', {
          MEETING: data.meeting,
        });
      case 'meeting_report':
        return __('notification.notification-title.meeting-report', {
          MEETING: data.meeting,
        });
      case 'meeting_minutes':
        return __('notification.notification-title.meeting-minutes', {
          MEETING: data.meeting,
        });
      case 'meeting_review':
        return __('notification.notification-title.meeting-review', {
          MEETING: data.meeting,
        });
      case 'meeting_closed':
        return __('notification.notification-title.meeting-closed', {
          MEETING: data.meeting,
        });
      case 'meeting_canceled':
        return __('notification.notification-title.meeting-canceled', {
          MEETING: data.meeting,
        });
      case 'meeting_review_period_reminder':
        return __('notification.notification-title.meeting-review-reminder', {
          MEETING: data.meeting,
        });
      case 'plan_processing_finished':
        return __('notification.notification-title.plan-processing-finished', {
          FILE: data.filename,
          PROJECT: data.project,
          STAGE: data.stage,
        });
      case 'added_as_delivery_approver':
        return __(
          'notification.notification-title.added-as-delivery-approver',
          {
            PROJECT: data.project,
            STAGE: data.stage,
          }
        );
      case 'task_file_added':
        return __('notification.notification-title.task-file-added', {
          PROJECT: data.project,
          TASK: data.task,
        });
      case 'plan_file_added':
        return __('notification.notification-title.plan-file-added', {
          PROJECT: data.project,
          STAGE: data.stage,
          PLAN: data.plan,
        });
      case 'current_set_specifications_book_generated':
        return __(
          'notification.notification-title.current-set-specifications-book-generated'
        );
      case 'working_set_specifications_book_generated':
        return __(
          'notification.notification-title.working-set-specifications-book-generated'
        );
      case 'working_set_specifications_copied':
        return __(
          'notification.notification-title.working-set-specifications-copied',
          {
            DISCIPLINE: data.discipline,
            STAGE: data.stage,
          }
        );
      case 'current_set_specifications_approved':
        return __(
          'notification.notification-title.current_set_specifications_approved',
          {
            DELIVERY: data.delivery,
          }
        );

      case 'current_set_specifications_rejected':
        return __(
          'notification.notification-title.current_set_specifications_rejected',
          {
            DELIVERY: data.delivery,
          }
        );

      case 'specification_drawing_added':
        return __(
          'notification.notification-title.specification-drawing-added',
          {
            PROJECT: data.project,
            SPECIFICATION: this._renderSpecificationLabel(data.specification),
          }
        );

      case 'specification_status_changed':
        return __(
          'notification.notification-title.specification-status-changed',
          {
            PROJECT: data.project,
            SPECIFICATION: this._renderSpecificationLabel(data.specification),
          }
        );

      case 'task_checklist_item_status_changed':
        return __(
          'notification.notification-title.task_checklist_item_status_changed',
          {
            USER: data.user,
            TASK: data.task,
          }
        );

      case 'user_join_invitation_your_company':
        return __(
          'notification.notification-title.user_join_invitation_your_company',
          {
            COMPANY: data.company,
            USER: data.user,
          }
        );

      case 'added_as_project_administrator':
        return __(
          'notification.notification-title.added_as_project_administrator',
          {
            PROJECT: data.project,
          }
        );

      case 'import_spreadsheet_finished':
        return __(
          'notification.notification-title.import_spreadsheet_finished'
        );

      case 'import_spreadsheet_failed':
        return __('notification.notification-title.import_spreadsheet_failed');
    }
  }

  _actionToContent({ action, data }) {
    if (action == 'task_comment_added') {
      return <>{linkifyText(data.comment)}</>;
    }

    if (action == 'tasks_report_generated') {
      return (
        <>
          <a onClick={() => redirect(data.url)}>
            <strong>
              {__('notification.tasks-report-generated.download-pdf')}
            </strong>
          </a>

          {__('notification.tasks-report-generated.download-valid')}
        </>
      );
    }

    if (action == 'task_status_changed') {
      return data.status;
    }

    if (action == 'tender_status_changed') {
      return data.status;
    }

    if (action == 'bidder_status_changed') {
      return data.status;
    }

    if (action == 'task_date_changed') {
      const renderDate = (date) => {
        const allDay = data.all_day;

        return allDay ? dateFrom(date) : dateTimeFrom(date);
      };

      const dateFromLabel = data.start_date;
      const dateToLabel = data.end_date;

      return renderDate(dateFromLabel) + ' - ' + renderDate(dateToLabel);
    }

    if (action == 'task_visibility_changed') {
      return data.visibility;
    }

    if (action == 'added_as_task_assignee') {
      return data.user;
    }

    if (action == 'added_as_task_follower') {
      return data.user;
    }

    if (action == 'added_as_tender_follower') {
      return data.user;
    }

    if (action == 'added_as_tender_administrator') {
      return data.user;
    }

    if (action == 'tender_date_changed') {
      const renderDate = (date) => {
        const allDay = data.all_day;

        return allDay ? dateFrom(date) : dateTimeFrom(date);
      };

      const dateFromLabel = data.start_date;
      const dateToLabel = data.end_date;

      return renderDate(dateFromLabel) + ' - ' + renderDate(dateToLabel);
    }

    if (action == 'added_as_stage_discipline_approver') {
      return data.user;
    }

    if (action == 'added_as_partner_company') {
      return (
        <>
          {data.company}
          <br />
          {data.user}
        </>
      );
    }

    if (action == 'added_as_partner_member') {
      return __('notification.notification-content.added-as-partner-member', {
        USER: data.user,
        COMPANY: data.company,
      });
    }

    if (action == 'partner_member_request') {
      return __('notification.notification-content.partner-member-request', {
        USER: data.user,
        COMPANY: data.company,
      });
    }

    if (action == 'user_joined_company') {
      return data.user;
    }

    if (action == 'team_member_invitation') {
      return __(
        'notification-flyout.notification-content.team-member-invitation'
      );
    }

    if (action == 'user_joined_team') {
      return data.team;
    }

    if (action == 'checklist_item_status_changed') {
      return data.status;
    }

    if (action == 'plan_versions_approved') {
      return (
        <>
          {linkifyText(data.message)}
          {_.map(data.plan_versions, (version, i) => (
            <span key={i}>
              {version.code ? version.code + ' -' : ''} {version.title} (
              {version.number})<br />
            </span>
          ))}
        </>
      );
    }

    if (action == 'plan_versions_rejected') {
      return (
        <>
          {linkifyText(data.message)}
          {_.map(data.plan_versions, (version, i) => (
            <span key={i}>
              {version.code ? version.code + ' -' : ''} {version.title} (
              {version.number})<br />
            </span>
          ))}
        </>
      );
    }

    if (action == 'current_set_plans_updated') {
      return _.map(data.plan_revisions, (revision, i) => (
        <span key={i}>
          {revision.code ? revision.code + ' -' : ''} {revision.title} (
          {revision.number})
        </span>
      ));
    }

    if (action == 'current_set_specifications_updated') {
      return _.map(data.currentSetSpecifications, (specification, i) => (
        <span key={i}>
          {specification.specification_group}
          {specification.specification_subgroup}
          {specification.specification_code} - {specification.description} - (R
          {specification.number}
          {specification.status === 'canceled' && '(canceled)'})
        </span>
      ));
    }

    if (action == 'team_membership_reactivated') {
      return __(
        'notification-flyout.notification-content.team_membership_reactivated'
      );
    }

    if (action == 'team_membership_deactivated') {
      return __(
        'notification-flyout.notification-content.team_membership_deactivated'
      );
    }

    if (action == 'meeting_report_generated') {
      return (
        <>
          {_.map(data.reports, (report, i) => (
            <span key={i}>
              <a onClick={() => redirect(report.download_url)}>
                <strong>{report.filename}</strong>
              </a>
            </span>
          ))}

          {__('notification.meeting-report-generated.valid-links')}
        </>
      );
    }

    if (action == 'meeting_report') {
      return (
        <>
          {_.map(data.reports, (report, i) => (
            <span key={i}>
              <a onClick={() => redirect(report.download_url)}>
                <strong>{report.filename}</strong>
              </a>
            </span>
          ))}

          {__('notification.meeting-report.valid-links')}
        </>
      );
    }

    if (action == 'meeting_review') {
      return (
        <>
          {__('notification.meeting-review.meeting-status-reviewed')}
          {_.map(data.reports, (report, i) => (
            <span key={i}>
              <a onClick={() => redirect(report.download_url)}>
                <strong>{report.filename}</strong>
              </a>
            </span>
          ))}
          <span>
            {__('notification.meeting-review.reviewed-period', {
              REVIEW_PERIOD: <strong>{data.review_period} days</strong>,
            })}
            {__('notification.meeting-review.valid-links')}
          </span>
        </>
      );
    }

    if (action == 'meeting_closed') {
      return (
        <>
          {__('notification.notification-content.meeting-closed')}
          {_.map(data.reports, (report, i) => (
            <span key={i}>
              <a onClick={() => redirect(report.download_url)}>
                <strong>{report.filename}</strong>
              </a>
            </span>
          ))}
        </>
      );
    }

    if (action == 'meeting_canceled') {
      return __('notification.notification-content.meeting-canceled');
    }

    if (action == 'meeting_review_period_reminder') {
      return (
        <span>
          {__(
            'notification.meeting-review-reminder.meeting-review-period-has-ended',
            {
              REVIEW_PERIOD: <strong>{data.review_period} days</strong>,
            }
          )}
        </span>
      );
    }

    if (action == 'plan_processing_finished') {
      return data.filename;
    }

    if (action == 'added_as_delivery_approver') {
      return data.delivery;
    }

    if (action == 'task_file_added') {
      return data.file;
    }

    if (action == 'plan_file_added') {
      return data.file;
    }

    if (action == 'current_set_specifications_book_generated') {
      return __(
        'notification.notification-content.current-set-specifications-book-generated',
        {
          FILE: data.file,
        }
      );
    }

    if (action == 'working_set_specifications_book_generated') {
      return __(
        'notification.notification-content.working-set-specifications-book-generated',
        {
          FILE: data.file,
        }
      );
    }

    if (action == 'current_set_specifications_approved') {
      return (
        <>
          {__(
            'notification.notification-content.current_set_specifications_approved'
          )}
          {data.message && (
            <span className={styles.box}>{linkifyText(data.message)}</span>
          )}
          {_.map(data.currentSetSpecifications, (specification, i) => (
            <span key={i}>
              {specification.specification_group}
              {specification.specification_subgroup}
              {specification.specification_code} - {specification.description} -
              (R{specification.number}
              {specification.status === 'canceled' && '(canceled)'})
            </span>
          ))}
        </>
      );
    }

    if (action == 'current_set_specifications_rejected') {
      return (
        <>
          {__(
            'notification.notification-content.current_set_specifications_rejected'
          )}
          {data.message && (
            <span className={styles.box}>{linkifyText(data.message)}</span>
          )}
          {_.map(data.currentSetSpecifications, (specification, i) => (
            <span key={i}>
              ({specification.specification_group}
              {specification.specification_subgroup}
              {specification.specification_code}) - {specification.description}{' '}
              - (R{specification.number}
              {specification.status === 'canceled' && '(canceled)'})
            </span>
          ))}
        </>
      );
    }

    if (action == 'specification_drawing_added') {
      return data.file;
    }

    if (action == 'specification_status_changed') {
      return data.status;
    }

    if (action == 'working_set_specifications_copied') {
      return (
        <>
          {__(
            'notification.notification-content.working_set_specifications_copied'
          )}
          {_.size(data.uncopied_specifications) > 0 ? (
            <>
              <br />
              {__(
                'notification.notification-content.working_set_specifications_uncopied'
              )}
            </>
          ) : null}
          {_.map(data.uncopied_specifications, (specification, i) => (
            <span key={i}>
              {specification.specification_group}
              {specification.specification_subgroup}
              {specification.specification_code} - {specification.description}
              {specification.status === 'canceled' && '(canceled)'}
            </span>
          ))}
        </>
      );
    }

    if (action == 'task_checklist_item_status_changed') {
      return (
        <span>
          {data.status} {data.item && '(' + data.item + ')'}
        </span>
      );
    }

    if (action == 'meeting_minutes') {
      return 'Meeting minutes';
    }

    if (action === 'user_join_invitation_your_company')
      return __(
        'notification-flyout.notification-content.user_join_invitation_your_company',
        {
          COMPANY: data.company,
          USER: data.user,
          EMAIL: data.email,
        }
      );

    if (action == 'added_as_project_administrator') {
      return __(
        'notification.notification-content.added_as_project_administrator',
        {
          PROJECT: data.project,
          USER: data.user,
        }
      );
    }

    if (action == 'import_spreadsheet_failed') {
      return (
        <>
          {__('notification.import_spreadsheet_failed')}

          <a onClick={() => redirect(data.url)}>
            <strong>
              {__('notification.import_spreadsheet_failed.download-pdf', {
                FILE: data.file,
              })}
            </strong>
          </a>

          {__('notification.import_spreadsheet_failed.download-valid')}
        </>
      );
    }

    if (action == 'import_spreadsheet_finished') {
      return __('notification.import_spreadsheet_finished');
    }
  }

  _groupModuleToTitle(module, { data }) {
    switch (module) {
      case 'tasks':
        return __('notification.group-notification-title.tasks', {
          TASK: data.task,
          PROJECT: data.project,
        });

      case 'plans':
        return __('notification.group-notification-title.plans', {
          PLAN: data.plan,
          PROJECT: data.project,
        });

      case 'specifications':
        return __('notification.group-notification-title.specifications', {
          SPECIFICATION: this._renderSpecificationLabel(data.specification),
          PROJECT: data.project,
        });

      case 'meetings':
        return __('notification.group-notification-title.meetings', {
          MEETING: data.meeting,
          PROJECT: data.project,
        });
    }
  }

  _renderSpecificationLabel(data) {
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const { subgroup_id, description, subgroup_code, code } = data;

    let specification;

    if (subgroup_id) {
      const specification_subgroup =
        _.get(specification_subgroups, [subgroup_id]) || {};
      const specification_group =
        _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id')
        ) || {};

      specification =
        getLocalized(
          specification_group.code,
          this.props.application_language_id,
          1
        ) +
        subgroup_code +
        code +
        ' - ' +
        description;
    } else {
      specification = data.specification;
    }

    return specification;
  }

  _renderNotificationBody = (notification_data, key = 1) => {
    return (
      <div
        key={key}
        className={classNames(
          styles.row,
          notification_data.read && styles.read
        )}
      >
        <div className={styles.read}>
          {!notification_data.read && <span className={styles.dot} />}
        </div>

        <div className={classNames(styles.activityAndContent, styles.column)}>
          {__('notification-flyout.activity-name.' + notification_data.action)}
          <br />
          {this._actionToContent(notification_data)}
        </div>

        <div className={styles.triggered_by}>
          {notification_data?.triggered_by ? (
            <Avatar
              avatar_url={notification_data.triggered_by.avatar_url}
              name={
                notification_data.triggered_by.fullname +
                '\n' +
                notification_data.triggered_by.company_name
              }
              active={notification_data.triggered_by.active}
              tooltip={true}
            />
          ) : (
            <Tooltip text={'System'}>
              <LogoIcon className={styles.logo} />
            </Tooltip>
          )}
        </div>
        <div className={styles.date_time}>
          {dateFrom(notification_data.created_at)}
          <br />
          {timeFrom(notification_data.created_at)}
        </div>
      </div>
    );
  };

  render() {
    if (!_.first(this.props.data)) {
      return <NotificationsFlyoutSkeleton />;
    }

    const { action, id, module, project, stage, data } = _.first(
      this.props.data
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              {_.size(this.props.data) > 1
                ? this._groupModuleToTitle(module, {
                    ..._.first(this.props.data),
                    project: project,
                  })
                : this._actionToTitle(action, {
                    ...data,
                    project: project,
                    stage: stage,
                  })}
            </span>

            <CloseBigIcon
              onClick={() => {
                markNotificationsAsRead([id]).then(() =>
                  readNotificationState(id)
                );
                setTimeout(() => {
                  this.props.actions.setTableParams({
                    clicked_row: undefined,
                  });
                }, 100);
              }}
            />
          </div>
          <div className={styles.actions}>
            <div className={styles.actionWrapper}>
              <Tooltip
                text={__('notifications.flyout.mark-notification-unread')}
                key={_.uniqueId()}
              >
                <MessageMarkUnreadIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    markNotificationsAsUnread([id]).then(() => {
                      setNotification(
                        __('notifications.flyout.notification-marked-unread')
                      );

                      readNotificationState(id, false);

                      setTableParams('notifications', {
                        clicked_row: undefined,
                      });
                    });
                  }}
                />
              </Tooltip>

              {!_.includes(
                [
                  'added_as_stage_discipline_approver',
                  'added_as_partner_company',
                  'added_as_partner_member',
                  'user_joined_company',
                  'plan_versions_approved',
                  'plan_versions_rejected',
                  'team_membership_deactivated',
                  'added_as_delivery_approver',
                  'current_set_specifications_approved',
                  'current_set_specifications_rejected',
                  'import_spreadsheet_finished',
                ],
                action
              ) && (
                <>
                  <span className={styles.separator} />

                  <Tooltip text={__('notification-flyout.go-to.' + action)}>
                    <div
                      className={styles.goToButton}
                      onClick={() => {
                        redirect(_.first(this.props.data).data.url);

                        markNotificationsAsRead([id]).then(() =>
                          readNotificationState(id)
                        );
                      }}
                    >
                      <span>{__('notification-flyout.go-to.' + action)}</span>
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.scroller}>
          <div className={styles.details}>
            <div className={classNames(styles.column, styles.f2)}>
              <div>
                <ProjectIcon />
                <span>{__('notifications.column-name.project')}</span>{' '}
                {project || 'n/a'}
              </div>
              <div>
                <StageIcon />
                <span>{__('notifications.column-name.stage')}</span>{' '}
                {stage || 'n/a'}
              </div>
              <div>
                <ModuleIcon />
                <span>{__('notifications.column-name.module')}</span>{' '}
                {__('notifications.table.module-' + module)}
              </div>
            </div>
          </div>
          <div className={styles.content}>
            {_.map(this.props.data, (notification, key) =>
              this._renderNotificationBody(notification, key)
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationsFlyout;
