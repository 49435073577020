import * as React from "react";
import {Component} from "react";
import styles from "../styles/components/Downloader.scss";
import {getToken, mapStateToProps} from "../core/utils";
import {resetDownload} from "../redux/actions/general/downloader";

@mapStateToProps(state => ({
    ...state.general.downloader
}))
class Downloader extends Component {
    componentDidUpdate(prevProps) {
        if(this.props.url && this.props.url != prevProps.url) {
            this.refs.form.submit();

            setTimeout(resetDownload, 50);
        }
    }

    render() {
        const {url, fields, method} = this.props;

        return !url ? '' : (
            <form className={styles.wrapper} method={method} ref="form" action={url} target="_blank">
                {_.map(fields, (value, key) => {
                    if(_.isArray(value)) {
                        return _.map(value, subValue => (
                            <input key={_.uniqueId()} type="hidden" name={key + "[]"} value={subValue} />
                        ));
                    } else if(_.isObject(value)) {
                        return _.map(value, (subValue, subKey) => (
                            <input key={_.uniqueId()} type="hidden" name={key + "[" + subKey + "]"} value={subValue} />
                        ));
                    } else {
                        return (
                            <input key={_.uniqueId()} type="hidden" name={key} value={value} />
                        );
                    }
                })}
                <input type="hidden" name="token" value={getToken()} />
            </form>
        )
    }
}

export default Downloader;