import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/views/meetings/MeetingItems.scss';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  linkifyText,
  mapStateToProps,
  redirect,
  screenIs,
  setPageTitle,
  setTableParams,
  timeFrom,
  setURLParam,
  hasURLParam,
} from '../../core/utils';
import MainLayout from '../../layouts/MainLayout';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { setPlusActions } from '../../redux/actions/general/plus';
import ArrowLeftMiddleIcon from '../../assets/images/arrow-left-middle-15x15.svg';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import GlobeIcon from '../../assets/images/globe-16x16.svg';
import MeetingIcon from '../../assets/images/meeting-16x16.svg';
import SendIcon from '../../assets/images/send-16x16.svg';
import PrintIcon from '../../assets/images/print-16x16.svg';
import { withRouter } from 'react-router';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  convertMeetingItemToTask,
  createMeetingGroupItem,
  createMeetingItemComment,
  createMeetingTaskItem,
  createMeetingTopicItem,
  deleteMeetingItem,
  readMeeting,
  readMeetingItems,
  reorderMeetingItems,
  updateMeetingDuration,
  updateMeetingItemDescription,
  updateMeetingItemDuration,
  updateMeetingItemTitle,
  updateMeetingTitle,
  updateMeetingUserPreferences,
  uploadMeetingItemFiles,
} from '../../redux/actions/table/all_meetings';
import * as _ from 'lodash';
import classNames from 'classnames';
import { ReactSortable } from 'react-sortablejs';
import * as moment from 'moment';
import TaskRfiIcon from '../../assets/images/task-rfi-15x15.svg';
import TaskFyiIcon from '../../assets/images/task-fyi-15x15.svg';
import TaskDecisionIcon from '../../assets/images/task-decision-15x15.svg';
import TaskTodoIcon from '../../assets/images/task-todo-15x15.svg';
import TaskMilestoneIcon from '../../assets/images/task-milestone-15x15.svg';
import TaskIssueIcon from '../../assets/images/task-issue-15x15.svg';
import CloseMiddleIcon from '../../assets/images/close-middle-15x15.svg';
import Dropdown from '../../components/Dropdown';
import autobind from 'autobind-decorator';
import {
  readTask,
  updateTaskPriority,
  updateTaskStatus,
  updateTaskType,
} from '../../redux/actions/table/all_tasks';
import { setNotification } from '../../redux/actions/general/notification';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import Cleave from 'cleave.js/dist/cleave-react-node';
import CommentsIcon from '../../assets/images/comments-16x16.svg';
import AttachmentsIcon from '../../assets/images/attachments-16x16.svg';
import DocumentExtIcon from '../../assets/images/document-ext-30x30.svg';
import Tooltip from '../../components/Tooltip';
import TextareaAutosize from 'react-autosize-textarea';
import { showLightbox } from '../../redux/actions/general/lightbox';
import ColumnSelectorIcon from '../../assets/images/column-selector-16x16.svg';
import ConvertToTaskIcon from '../../assets/images/convert-to-task-16x16.svg';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Button';
import MeetingsFlyout from '../../flyouts/meetings/MeetingsFlyout';
import { getPreferences } from '../../redux/actions/profile';
import TasksFlyout from '../../flyouts/tasks/TasksFlyout';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Select from '../../components/Select';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import MeetingFollowUpIcon from '../../assets/images/meeting-follow-up-16x16.svg';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import FilePreview from '../../components/FilePreview';
import MoveIcon from '../../assets/images/move-16x16.svg';

@mapStateToProps((state) => ({
  forceUpdate: _.get(state.table['all_meetings'], 'forceUpdate'),
  auth: state.auth,
  table: state.table['all_meetings'],
  clicked_task_id: _.get(state.table, ['all_tasks', 'clicked_row']),
  task_flyout_data: _.get(state.table, ['all_tasks', 'flyout']),
}))
@withRouter
class MeetingItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meeting: {},
      items: [],
      expanded: [],
      temp_disabled_columns: [],
      disabled_columns: [],
      language_switcher: false,
      comments_shown: [],
      attachments_shown: [],
      prevent_reload: false,
      force_reload: false,
      task_title: '',
    };
  }

  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/meetings')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/meetings')
    );

    createSidebar({
      title: __('submenu.title.meetings'),
      items: [
        {
          title: __('submenu.title.all-meetings'),
          link: () => '/stages/' + getActiveStage() + '/meetings',
          active: true,
        },
      ],
    });

    //fix flyout keeping opened after switching meetings throught tasks flyout -> meetings tab
    setTableParams('all_tasks', {
      clicked_row: undefined,
      flyout: undefined,
    });

    readMeeting(this.props.match.params.meeting).then(({ response }) => {
      this.setState({ meeting: response.data });

      setPageTitle([response.data.prefixed_title]);

      const is_administrator = _.isObject(
        _.find(this.state.meeting.administrators, ['id', this.props.auth.id])
      );
      const disabled =
        !is_administrator ||
        _.includes(['closed', 'canceled'], this.state.meeting.status);

      !disabled &&
        setPlusActions([
          {
            name: __('meeting.items.add-topic'),
            onClick: () => this._createTopic(),
          },
          {
            name: __('meeting.items.add-existing-task'),
            onClick: () =>
              setTableParams('all_meetings', {
                wizard_add_tasks_to_meeting: true,
                visibility: this.state.meeting.visibility,
                order: _.get(
                  _.find(
                    this.state.items,
                    (item) => item.id == this.state.focused
                  ),
                  'order'
                ),
              }),
          },
          {
            name: __('meeting.items.add-new-task'),
            onClick: () => this._createTask(),
          },
          {
            name: __('meeting.items.add-group'),
            onClick: () => this._createGroup(),
          },
        ]);

      getPreferences('meeting.' + this.props.match.params.meeting).then(
        ({ response }) => {
          const mobile_disabled_columns = [
            'status',
            'priority',
            'target',
            'due',
            'assignee',
            'startend',
            'duration',
          ];

          this.setState({
            temp_disabled_columns: screenIs('<=', 992)
              ? mobile_disabled_columns
              : _.get(response.data, 'disabled_columns', []),
            disabled_columns: screenIs('<=', 992)
              ? mobile_disabled_columns
              : _.get(response.data, 'disabled_columns', []),
            language: _.get(
              response.data,
              'language',
              _.first(this.state.meeting.languages)
            ),
          });
        }
      );
    });

    readMeetingItems(this.props.match.params.meeting)
      .then(({ response }) => {
        this.setState({ items: response.data });

        listActiveModules();
      })
      .then(() => {
        if (getURLParam('task_id')) {
          this._handleTaskFlyout(getURLParam('task_id'));
        }
      });

    window.addEventListener('paste', this._handlePaste);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (!this.props.forceUpdate && nextProps.forceUpdate) {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({
          items: response.data,
        });

        const last_order = _.maxBy(this.state.items, 'id').order;

        setTableParams('all_meetings', {
          forceUpdate: undefined,
        });

        const sorted_items = _.sortBy(this.state.items, 'order');
        const highlighted_item_id = _.isUndefined(this.state.focused)
          ? _.get(sorted_items, [sorted_items.length - 1, 'id'])
          : _.find(sorted_items, ['order', last_order + 1]).id;
        this.setState({ focused: highlighted_item_id });

        setNotification(
          __(
            'meetings.all-meetings.add-new.notification.tasks-have-been-added-to-the-meeting'
          )
        );
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readMeeting(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }

    if (
      this.props.clicked_task_id &&
      this.props.clicked_task_id !== prevProps.clicked_task_id
    ) {
      readTask(this.props.clicked_task_id);
      setURLParam('task_id', this.props.clicked_task_id);
    }

    if (
      !this.props.clicked_task_id &&
      this.props.clicked_task_id !== prevProps.clicked_task_id
    ) {
      setURLParam('task_id', undefined);
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextState.items != this.state.items) {
      _.each(nextState.items, (item) => {
        _.set(this, 'comment' + item.id, React.createRef());
      });
    }

    if (this.state.focused != nextState.focused && nextProps.clicked_task_id) {
      const item = _.find(nextState.items, ['id', nextState.focused]);

      if (item.type == 'task') {
        setTableParams('all_tasks', {
          clicked_row: item.task_id,
        });
      } else {
        setTableParams('all_tasks', {
          clicked_row: undefined,
        });
      }
    }
  }

  @autobind
  _changeTaskType(task_id, type) {
    hideDropdown();

    updateTaskType(task_id, type).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });
      });

      setNotification(
        __('meetings.all-meetings.flyout.notification.task-type-updated')
      );
    });
  }

  @autobind
  _changeTaskPriority(task_id, priority) {
    hideDropdown();

    updateTaskPriority(task_id, priority).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });
      });

      setNotification(
        __('meetings.all-meetings.flyout.notification.task-priority-updated')
      );
    });
  }

  @autobind
  _changeTaskStatus(task_id, status) {
    hideDropdown();

    updateTaskStatus(task_id, status).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });
      });

      setNotification(
        __('meetings.all-meetings.flyout.notification.task-status-updated')
      );
    });
  }

  @autobind
  _toggleColumn(column) {
    this.setState({
      temp_disabled_columns: _.xor(this.state.temp_disabled_columns, [column]),
    });
  }

  @autobind
  _resetColumnsToDefault() {
    this.setState({
      temp_disabled_columns: [],
    });
  }

  @autobind
  _toggleAttachments(item_id) {
    if (
      !_.includes(this.state.attachments_shown, item_id) &&
      !_.includes(this.state.expanded, item_id)
    ) {
      this._toggleExpandItem(item_id);
    }

    this.setState({
      attachments_shown: _.xor(this.state.attachments_shown, [item_id]),
    });
  }

  @autobind
  _toggleComments(item_id) {
    if (
      !_.includes(this.state.comments_shown, item_id) &&
      !_.includes(this.state.expanded, item_id)
    ) {
      this._toggleExpandItem(item_id);
    }

    this.setState({
      comments_shown: _.xor(this.state.comments_shown, [item_id]),
    });
  }

  @autobind
  _toggleAllAttachments() {
    if (
      _.isEmpty(
        _.difference(
          _.map(
            _.filter(this.state.items, (item) =>
              _.includes(['task', 'topic'], item.type)
            ),
            'id'
          ),
          this.state.attachments_shown
        )
      )
    ) {
      this.setState({ attachments_shown: [] });
    } else {
      this.setState({
        attachments_shown: _.map(
          _.filter(this.state.items, (item) =>
            _.includes(['task', 'topic'], item.type)
          ),
          'id'
        ),
      });

      const expandable_items = _.filter(this.state.items, (item) =>
        _.includes(['task', 'topic'], item.type)
      );
      const expanded_items = this.state.expanded;

      if (expandable_items.length != expanded_items.length) {
        this.setState({ expanded: _.map(expandable_items, 'id') });
      }
    }
  }

  @autobind
  _toggleAllComments() {
    if (
      _.isEmpty(
        _.difference(
          _.map(
            _.filter(this.state.items, (item) =>
              _.includes(['task', 'topic'], item.type)
            ),
            'id'
          ),
          this.state.comments_shown
        )
      )
    ) {
      this.setState({ comments_shown: [] });
    } else {
      this.setState({
        comments_shown: _.map(
          _.filter(this.state.items, (item) =>
            _.includes(['task', 'topic'], item.type)
          ),
          'id'
        ),
      });

      const expandable_items = _.filter(this.state.items, (item) =>
        _.includes(['task', 'topic'], item.type)
      );
      const expanded_items = this.state.expanded;

      if (expandable_items.length != expanded_items.length) {
        this.setState({ expanded: _.map(expandable_items, 'id') });
      }
    }
  }

  @autobind
  _deleteItem(item_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'meetings.all-meetings.flyout.alert.remove-item-from-meeting'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            hideAlertbox();

            deleteMeetingItem(item_id).then(() => {
              readMeetingItems(this.props.match.params.meeting).then(
                ({ response }) => {
                  this.setState({ items: response.data });
                }
              );

              this.setState({ actioned_row: undefined });
            });

            setNotification(
              __(
                'meetings.all-meetings.flyout.notification.meeting-item-deleted'
              )
            );
          },
        },
      ],
    });
  }

  @autobind
  _convertToTask(item_id) {
    showAlertbox({
      color: 'blue',
      title: __('general.alert.are-you-sure'),
      description: __(
        'meetings.all-meetings.flyout.alert.convert-this-topic-task'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            hideAlertbox();

            convertMeetingItemToTask(item_id).then(() => {
              readMeetingItems(this.props.match.params.meeting).then(
                ({ response }) => {
                  this.setState({ items: response.data });
                }
              );

              this.setState({ actioned_row: undefined });
            });

            setNotification(
              __(
                'meetings.all-meetings.flyout.notification.meeting-topic-converted-task'
              )
            );
          },
        },
      ],
    });
  }

  @autobind
  _changeOrder(items) {
    this.setState({
      items: _.map(items, (item, i) => ({
        ...item,
        order: i,
      })),
    });
  }

  @autobind
  _handleKeyDown(e) {
    if (e.keyCode == 13) {
      e.persist();
      e.target.blur();
    }
  }

  @autobind
  _updateItemDuration(item_id, duration, e) {
    if (duration == '') {
      e.target.value = '0';
      duration = 0;
    } else {
      duration = _.toInteger(duration);
    }

    const items = _.cloneDeep(this.state.items);

    const item_index = _.findIndex(items, ['id', item_id]);

    updateMeetingItemDuration(item_id, duration);
    _.set(items, [item_index, 'duration'], duration);

    this.setState({ items });
  }

  @autobind
  _updateMeetingDuration(duration, e) {
    if (duration == '') {
      e.target.value = '0';
      duration = 0;
    } else {
      duration = _.toInteger(duration);
    }

    updateMeetingDuration(this.props.match.params.meeting, duration);

    this.setState({
      meeting: {
        ...this.state.meeting,
        duration,
      },
    });
  }

  @autobind
  _createGroup() {
    let order = _.get(
      _.find(this.state.items, (item) => item.id == this.state.focused),
      'order'
    );
    const meeting_id = this.state.meeting.id;

    createMeetingGroupItem(meeting_id, order).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });

        setNotification(
          __(
            'meetings.all-meetings.add-new.notification.meeting-item-group-has-been-created'
          )
        );

        const sorted_items = _.sortBy(this.state.items, 'order');
        const highlighted_item_id = _.isUndefined(order)
          ? _.get(sorted_items, [sorted_items.length - 1, 'id'])
          : _.maxBy(this.state.items, 'id').id;
        this.refs['title' + highlighted_item_id].select();
        this.setState({ focused: highlighted_item_id });
      });
    });
  }

  @autobind
  _createTopic() {
    let order = _.get(
      _.find(this.state.items, (item) => item.id == this.state.focused),
      'order'
    );
    const meeting_id = this.state.meeting.id;

    createMeetingTopicItem(meeting_id, order).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });

        setNotification(
          __(
            'meetings.all-meetings.add-new.notification.meeting-topic-has-been-created'
          )
        );

        const sorted_items = _.sortBy(this.state.items, 'order');
        const highlighted_item_id = _.isUndefined(order)
          ? _.get(sorted_items, [sorted_items.length - 1, 'id'])
          : _.maxBy(this.state.items, 'id').id;
        this.refs['title' + highlighted_item_id].select();
        this.setState({ focused: highlighted_item_id });
      });
    });
  }

  @autobind
  _createTask() {
    let order = _.get(
      _.find(this.state.items, (item) => item.id == this.state.focused),
      'order'
    );
    const meeting_id = this.state.meeting.id;

    createMeetingTaskItem(meeting_id, order).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });

        setNotification(
          __(
            'meetings.all-meetings.add-new.notification.meeting-task-has-been-created'
          )
        );

        const sorted_items = _.sortBy(this.state.items, 'order');
        const highlighted_item_id = _.isUndefined(order)
          ? _.get(sorted_items, [sorted_items.length - 1, 'id'])
          : _.maxBy(this.state.items, 'id').id;
        this.refs['title' + highlighted_item_id].select();
        this.setState({ focused: highlighted_item_id });
      });
    });
  }

  @autobind
  _updateItemTitle(item_id, title) {
    const items = _.cloneDeep(this.state.items);
    const item = _.find(items, ['id', item_id]);
    const item_index = _.findIndex(items, ['id', item_id]);
    const title_attribute = item.type == 'task' ? 'task_title' : 'title';

    if (title) {
      updateMeetingItemTitle(item_id, title).then(() => {
        if (item.task_id && item.task_id === this.props.clicked_task_id) {
          readTask(this.props.clicked_task_id);
        }
      });
      _.set(items, [item_index, title_attribute], title);
    }

    _.set(items, [0, 'hash'], _.uniqueId());

    this.setState({ items });

    if (this.props.force_reload) {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });
      });

      readMeeting(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ meeting: response.data });
        this.setState({ preview: response.data });
      });

      this.setState({ force_reload: false });
    }
  }

  @autobind
  _toggleExpandItem(item_id) {
    this.setState({ expanded: _.xor(this.state.expanded, [item_id]) });
  }

  @autobind
  _toggleExpandAllItems() {
    const expandable_items = _.filter(this.state.items, (item) =>
      _.includes(['task', 'topic'], item.type)
    );
    const expanded_items = this.state.expanded;

    if (expandable_items.length == expanded_items.length) {
      this.setState({ expanded: [] });
    } else {
      this.setState({ expanded: _.map(expandable_items, 'id') });
    }
  }

  @autobind
  _updateItemDescription(item_id, description) {
    const { language } = this.state;

    const items = _.cloneDeep(this.state.items);
    const item = _.find(items, ['id', item_id]);
    const item_index = _.findIndex(items, ['id', item_id]);
    const description_attribute =
      item.type == 'task' ? 'task_description' : 'description';

    updateMeetingItemDescription(item_id, description, language).then(() => {
      if (item.task_id && item.task_id === this.props.clicked_task_id) {
        readTask(this.props.clicked_task_id);
      }
    });

    _.set(items, [item_index, description_attribute, language], description);

    this.setState({ items });

    if (this.props.force_reload) {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ items: response.data });
      });

      readMeeting(this.props.match.params.meeting).then(({ response }) => {
        this.setState({ meeting: response.data });
        this.setState({ preview: response.data });
      });

      this.setState({ force_reload: false });
    }
  }

  @autobind
  _createComment(item_id) {
    const comment = _.get(this, ['comment' + item_id, 'current', 'value']);
    const items = _.cloneDeep(this.state.items);
    const item = _.find(items, ['id', item_id]);

    createMeetingItemComment(item_id, comment).then(() => {
      readMeetingItems(this.props.match.params.meeting).then(({ response }) => {
        if (item.task_id && item.task_id === this.props.clicked_task_id) {
          readTask(this.props.clicked_task_id);
        }

        this.setState({ items: response.data });

        setNotification(
          __('meetings.all-meetings.flyout.notification.comment-added')
        );
      });
    });
  }

  @autobind
  _uploadFiles(item_id, files) {
    const items = _.cloneDeep(this.state.items);
    const item = _.find(items, ['id', item_id]);

    setNotification(
      __('meetings.all-meetings.flyout.notification.please-wait-files-uploaded')
    );

    uploadMeetingItemFiles(item_id, files)
      .then(() => {
        readMeetingItems(this.props.match.params.meeting)
          .then(({ response }) => {
            this.setState({ items: response.data });

            setNotification(
              __(
                'meetings.all-meetings.flyout.notification.attachments-uploaded'
              )
            );
          })
          .then(() => {
            if (item.task_id && item.task_id === this.props.clicked_task_id) {
              readTask(this.props.clicked_task_id);
            }
          });
      })
      .catch(() => {
        setNotification({
          text: __(
            'meetings.all-meetings.flyout.notification.files-couldnot-uploaded.check-formats-sizes'
          ),
          type: 'warning',
        });
      });
  }

  @autobind
  _downloadFile({ download_url }) {
    setNotification(
      __(
        'meetings.all-meetings.flyout.notification.download-continue-background'
      )
    );

    window.open(download_url, '_blank');
  }

  @autobind
  _showRowActions(id) {
    this.setState({
      actioned_row: this.state.actioned_row != id ? id : undefined,
    });
  }

  @autobind
  _selectLanguage(language) {
    const meeting_id = this.props.match.params.meeting;

    this.setState({ language });

    updateMeetingUserPreferences(meeting_id, {
      disabled_columns: this.state.disabled_columns,
      language,
    });
  }

  @autobind
  _setItemFocus(item_id) {
    this.setState({ focused: item_id });
  }

  @autobind
  _handleTaskFlyout(task_id) {
    setTableParams('all_tasks', {
      clicked_row: task_id,
    });
  }

  @autobind
  _handleShortcut(key, e) {
    const { meeting } = this.state;
    const { auth } = this.props;
    const is_administrator = _.isObject(
      _.find(meeting.administrators, ['id', auth.id])
    );
    const disabled =
      !is_administrator || _.includes(['closed', 'canceled'], meeting.status);

    if (!disabled) {
      if (key == 'alt+enter') {
        e.preventDefault();

        this._createTopic();
      }

      if (key == 'alt+t') {
        e.preventDefault();

        this._createTask();
      }

      if (key == 'alt+g') {
        e.preventDefault();

        this._createGroup();
      }

      if (key == 'alt+shift+t') {
        e.preventDefault();

        setTableParams('all_meetings', {
          wizard_add_tasks_to_meeting: true,
          visibility: this.state.meeting.visibility,
          order: _.get(
            _.find(this.state.items, (item) => item.id == this.state.focused),
            'order'
          ),
        });
      }
    }
  }

  @autobind
  _updateMeetingTitle(value, e) {
    const { meeting } = this.state;

    const target_key = _.findKey(
      meeting.project_languages,
      (primary) => primary == 1
    );

    if (_.size(value) > 0) {
      const title = {
        ...this.state.meeting.title,
        [target_key]: value,
      };

      if (_.get(meeting.title, target_key) != value) {
        updateMeetingTitle(this.props.match.params.meeting, title).then(() => {
          setNotification('Meeting title has been successfully updated.');
        });

        this.setState({
          meeting: {
            ...meeting,
            title,
          },
        });
      }
    } else {
      e.target.value = _.get(meeting.title, target_key);
    }
  }

  @autobind
  _goBack() {
    window.previousLocation
      ? window.history.back()
      : redirect('/stages/' + getActiveStage() + '/meetings');
  }

  @autobind
  _handlePaste(event) {
    const files = event.clipboardData.files;

    const { clicked_task_id } = this.props;

    if (!clicked_task_id && this.state.focused && !_.isEmpty(files)) {
      this._uploadFiles(this.state.focused, files);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this._handlePaste);
  }

  render() {
    const {
      meeting,
      items,
      expanded,
      language_switcher,
      language,
      focused,
      attachments_shown,
      comments_shown,
    } = this.state;
    const { auth } = this.props;
    const sorted_items = _.sortBy(items, 'order');

    const is_administrator = !_.isEmpty(
      _.find(meeting.administrators, ['id', auth.id])
    );
    const is_participant = !_.isEmpty(
      _.find(meeting.participants, ['id', auth.id])
    );
    const is_follower = !_.isEmpty(_.find(meeting.followers, ['id', auth.id]));
    const is_editor = meeting.is_editor;

    let group_counter = 1;

    let start_time = moment.utc(meeting.date).local();
    let end_time = moment
      .utc(meeting.date)
      .local()
      .add(meeting.duration, 'minutes');
    let calculated_time_difference = moment
      .duration(
        start_time
          .clone()
          .add(_.sumBy(items, 'duration'), 'minutes')
          .diff(end_time)
      )
      .asMinutes();

    const statuses = {
      'not-started': 'NS',
      started: 'ST',
      finished: 'FI',
      closed: 'CL',
      reopened: 'RE',
      canceled: 'CA',
    };

    const priorities = {
      low: 'LO',
      normal: 'NO',
      high: 'HI',
      urgent: 'UR',
    };

    const available_columns = {
      target: __('meetings.items.columns.type'),
      priority: __('meetings.items.columns.priority'),
      status: __('meetings.items.columns.status'),
      due: __('meetings.items.columns.due'),
      assignee: __('meetings.items.columns.assignee'),
      startend: __('meetings.items.columns.start-end'),
      duration: __('meetings.items.columns.duration'),
    };

    const languages = _.map(meeting.languages, (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      code: _.get(this.props.localization.languages, [language_id, 'code']),
      value: language_id,
    }));

    const disabled =
      !is_administrator || _.includes(['closed', 'canceled'], meeting.status);

    return !_.isEmpty(meeting) ? (
      <>
        <div className={styles.header}>
          <KeyboardEventHandler
            handleKeys={['alt+enter', 'alt+t', 'alt+g', 'alt+shift+t']}
            onKeyEvent={this._handleShortcut}
          />
          <div className={styles.title}>
            <div className={styles.meeting}>
              <strong>{'M-' + _.padStart(meeting.identifier, 4, '0')}:</strong>{' '}
              <input
                disabled={disabled}
                onKeyDown={this._handleKeyDown}
                onBlur={(e) => this._updateMeetingTitle(e.target.value, e)}
                type='text'
                defaultValue={_.get(
                  meeting.title,
                  _.findKey(
                    meeting.project_languages,
                    (primary) => primary == 1
                  )
                )}
              />
            </div>
            <div className={styles.details}>
              {dateTimeFrom(meeting.date)}{' '}
              {meeting.location_name ? '@ ' + meeting.location_name : ''}
            </div>
          </div>
          <div className={styles.toolbar}>
            <div
              className={styles.left}
              onClick={() => {
                redirect('/stages/' + meeting.stage_id + '/meetings');
              }}
            >
              <ArrowLeftMiddleIcon />{' '}
              {__('meetings.tools.back-to-meetings-list')}
            </div>
            <div className={styles.right}>
              {is_editor && (
                <Tooltip text={__('meetings.tooltip.icon.create-follow-up')}>
                  <MeetingFollowUpIcon
                    className={styles.followup}
                    onClick={() => {
                      setTableParams('all_meetings', {
                        create_followup_meeting_wizard: meeting.id,
                      });
                    }}
                  />
                </Tooltip>
              )}
              {is_administrator && (
                <Tooltip
                  text={
                    'Send meeting ' +
                    (meeting.status == 'agenda' ? 'agenda' : 'minutes')
                  }
                >
                  <SendIcon
                    className={styles.send}
                    onClick={() => {
                      setTableParams('all_meetings', {
                        send_meeting_report_wizard: true,
                        meeting_id: meeting.id,
                        send: true,
                      });
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip text={__('meetings.tooltip.icon.print-meeting-report')}>
                <PrintIcon
                  className={styles.print}
                  onClick={() => {
                    setTableParams('all_meetings', {
                      print_meeting_report_wizard: true,
                      meeting_id: meeting.id,
                      meeting_status: meeting.status,
                      send: false,
                    });
                  }}
                />
              </Tooltip>
              <Tooltip text={__('meetings.tooltip.icon.meeting-properties')}>
                <InfoPositiveIcon
                  className={styles.info}
                  onClick={() =>
                    setTableParams('all_meetings', {
                      clicked_row: meeting.id,
                      stage_id: meeting.stage_id,
                    })
                  }
                />
              </Tooltip>
              {language && (
                <>
                  <span className={styles.separator} />
                  <Select
                    input={{
                      value: language,
                      onChange: (language) => this._selectLanguage(language),
                      onBlur: () => this.setState({ language_switcher: false }),
                    }}
                    meta={{
                      active: language_switcher,
                    }}
                    label={'Meeting Language'}
                    options={languages}
                    left={-75}
                    top={40}
                    noSort
                    dropdown
                    icon={<GlobeIcon className={styles.globeIcon} />}
                    wrapper={(handleOpen, activeItem, elementRef) => (
                      <Tooltip
                        text={__('meeting.items.change-meeting-language')}
                      >
                        <div
                          className={styles.languageSwitcher}
                          ref={elementRef}
                          onClick={() =>
                            this.setState({ language_switcher: true })
                          }
                        >
                          <GlobeIcon className={styles.icon} />
                          {_.get(activeItem, 'code')}
                          <ArrowDownSmallIcon className={styles.arrow} />
                        </div>
                      </Tooltip>
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.heading}>
          <div className={styles.title}>
            <span>{__('meetings.items.meeting-' + meeting.status)}</span>
            {(is_administrator && (
              <Tooltip
                placement='right'
                text={__(
                  'meetings.all-meetings.tooltip.administrator-this-meeting'
                )}
              >
                <div className={styles.role}>Administrator</div>
              </Tooltip>
            )) ||
              (is_participant && (
                <Tooltip
                  placement='right'
                  text={__(
                    'meetings.all-meetings.flyout.tooltip.you-are-participant-meeting'
                  )}
                >
                  <div className={styles.role}>Participant</div>
                </Tooltip>
              )) ||
              (is_follower && (
                <Tooltip
                  placement='right'
                  text={__(
                    'meetings.all-meetings.flyout.tooltip.you-are-follower-this-meeting'
                  )}
                >
                  <div className={styles.role}>Follower</div>
                </Tooltip>
              ))}
          </div>
          <div className={styles.details}>
            {!_.includes(this.state.disabled_columns, 'target') && (
              <div className={styles.target}>
                <MeetingIcon className={styles.icon} />
                <Tooltip text={__('meeting.items.meeting-properties')}>
                  <span
                    onClick={() =>
                      setTableParams('all_meetings', {
                        clicked_row: meeting.id,
                        stage_id: meeting.stage_id,
                      })
                    }
                  >
                    {'M-' + _.padStart(meeting.identifier, 4, '0')}
                    <ArrowDoubleRightSmallIcon />
                  </span>
                </Tooltip>
              </div>
            )}
            {!_.includes(this.state.disabled_columns, 'priority') && (
              <div className={styles.priority} />
            )}
            {!_.includes(this.state.disabled_columns, 'status') && (
              <div className={styles.status} />
            )}
            {!_.includes(this.state.disabled_columns, 'due') && (
              <div className={styles.due} />
            )}
            {!_.includes(this.state.disabled_columns, 'assignee') && (
              <div className={styles.assignee} />
            )}
            {!_.includes(this.state.disabled_columns, 'startend') && (
              <div className={styles.startend}>
                {timeFrom(meeting.date)}-{timeFrom(end_time)}
              </div>
            )}
            {!_.includes(this.state.disabled_columns, 'duration') && (
              <div className={styles.duration}>
                <Cleave
                  options={{ numericOnly: true }}
                  maxLength={3}
                  type='text'
                  onFocus={(e) => e.currentTarget.select()}
                  value={moment
                    .duration(end_time.diff(moment.utc(meeting.date).local()))
                    .asMinutes()}
                  onKeyDown={this._handleKeyDown}
                  onBlur={(e) => this._updateMeetingDuration(e.target.value, e)}
                />
                <div className={styles.calculatedTime}>
                  <span
                    className={classNames(
                      calculated_time_difference > 0 && styles.red
                    )}
                  >
                    ({calculated_time_difference > 0 ? '+' : ''}
                    {calculated_time_difference})
                  </span>{' '}
                  min
                </div>
              </div>
            )}
            <div className={styles.columnSelector}>
              <Dropdown
                closable={false}
                top={29}
                right={-19}
                name='column_selector'
                header={
                  <div className={styles.columnSelectorHeader}>
                    {__('table.columns.select-columns')}
                  </div>
                }
                content={
                  <div className={styles.columnSelectorContent}>
                    {_.map(available_columns, (label, value) => (
                      <div
                        onClick={() => this._toggleColumn(value)}
                        key={value}
                        className={styles.column}
                      >
                        {label}
                        {_.includes(
                          this.state.temp_disabled_columns,
                          value
                        ) && <UncheckedIcon className={styles.unchecked} />}
                        {!_.includes(
                          this.state.temp_disabled_columns,
                          value
                        ) && <CheckedIcon className={styles.checked} />}
                      </div>
                    ))}
                    <div
                      onClick={this._resetColumnsToDefault}
                      className={styles.column}
                    >
                      {__('table.columns.reset-to-default')}
                    </div>
                    <div className={styles.saveFooter}>
                      <ButtonGroup right>
                        <Button
                          lightGray
                          medium
                          middleText={__('button.cancel')}
                          onClick={() => {
                            hideDropdown();
                          }}
                        />
                        <Button
                          lightBlue
                          medium
                          middleText={__('button.done')}
                          onClick={() => {
                            updateMeetingUserPreferences(meeting.id, {
                              disabled_columns:
                                this.state.temp_disabled_columns,
                              language,
                            });

                            this.setState({
                              disabled_columns:
                                this.state.temp_disabled_columns,
                            });

                            hideDropdown();
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                }
              >
                <Tooltip text={__('content.tooltip.table.columns')}>
                  <ColumnSelectorIcon />
                </Tooltip>
              </Dropdown>
            </div>
          </div>
        </div>
        <div
          className={classNames(styles.tableTitle, disabled && styles.disabled)}
        >
          <div
            className={styles.collapse}
            onClick={() => this._toggleExpandAllItems()}
          >
            <Tooltip text={__('meeting.items.expand-collapse-items')}>
              <ArrowDownMiddleIcon
                className={classNames(
                  _.filter(this.state.items, (item) =>
                    _.includes(['task', 'topic'], item.type)
                  ).length == this.state.expanded.length && styles.active
                )}
                onClick={this._toggleExpandAllItems}
              />
            </Tooltip>
          </div>
          <div className={styles.title}>
            {__('meetings.items.columns.title')}
          </div>
          <div className={styles.toggles}>
            <Tooltip text={__('meeting.items.toggle-all-attachments')}>
              <span
                onClick={this._toggleAllAttachments}
                className={classNames(
                  _.isEmpty(
                    _.difference(
                      _.map(
                        _.filter(this.state.items, (item) =>
                          _.includes(['task', 'topic'], item.type)
                        ),
                        'id'
                      ),
                      attachments_shown
                    )
                  ) &&
                    attachments_shown.length > 0 &&
                    styles.active
                )}
              >
                <AttachmentsIcon />
              </span>
            </Tooltip>
            <Tooltip text={__('meeting.items.toggle-all-comments')}>
              <span
                onClick={this._toggleAllComments}
                className={classNames(
                  _.isEmpty(
                    _.difference(
                      _.map(
                        _.filter(this.state.items, (item) =>
                          _.includes(['task', 'topic'], item.type)
                        ),
                        'id'
                      ),
                      comments_shown
                    )
                  ) &&
                    comments_shown.length > 0 &&
                    styles.active
                )}
              >
                <CommentsIcon />
              </span>
            </Tooltip>
          </div>
          {!_.includes(this.state.disabled_columns, 'target') && (
            <div className={styles.target}>
              {__('meetings.items.columns.type')}
            </div>
          )}
          {!_.includes(this.state.disabled_columns, 'priority') && (
            <div className={styles.priority}>
              {__('meetings.items.columns.priority')}
            </div>
          )}
          {!_.includes(this.state.disabled_columns, 'status') && (
            <div className={styles.status}>
              {__('meetings.items.columns.status')}
            </div>
          )}
          {!_.includes(this.state.disabled_columns, 'due') && (
            <div className={styles.due}>{__('meetings.items.columns.due')}</div>
          )}
          {!_.includes(this.state.disabled_columns, 'assignee') && (
            <div className={styles.assignee}>
              {__('meetings.items.columns.assignee')}
            </div>
          )}
          {!_.includes(this.state.disabled_columns, 'startend') && (
            <div className={styles.startend}>
              {__('meetings.items.columns.start-end')}
            </div>
          )}
          {!_.includes(this.state.disabled_columns, 'duration') && (
            <div className={styles.duration}>
              {__('meetings.items.columns.duration')}
            </div>
          )}
          <div className={styles.move} />
          <div className={styles.delete} />
        </div>
        <div
          className={classNames(styles.table, disabled && styles.disabled)}
          ref='table'
        >
          {!_.isEmpty(sorted_items) && (
            <ReactSortable
              list={sorted_items}
              onEnd={() =>
                reorderMeetingItems(meeting.id, _.map(sorted_items, 'id'))
              }
              setList={this._changeOrder}
              animation={200}
              onChoose={(sorting_item) => {
                this.setState({
                  expanded: _.reject(
                    this.state.expanded,
                    (item_id) =>
                      item_id ==
                      _.get(sorted_items, [
                        sorting_item.oldDraggableIndex,
                        'id',
                      ])
                  ),
                });
              }}
              delayOnTouchStart={true}
              handle={'.' + styles.grabber}
            >
              {_.map(sorted_items, (item, i) => {
                if (item.type == 'task') {
                  const comment_files = _.transform(
                    item.comments,
                    (files, comment) => {
                      _.each(comment.files, (file) => {
                        files.push(file);
                      });
                    },
                    []
                  );

                  const files = _.sortBy(
                    [...item.attachments, ...comment_files],
                    ['created_at']
                  );

                  const lightbox_images = _.map(
                    _.filter(files, 'stream_url'),
                    (file) => ({
                      id: file.id,
                      stream_url: file.stream_url,
                      title: [file.name, file.ext].join('.'),
                    })
                  );

                  return (
                    <div
                      className={classNames(
                        styles.item,
                        _.includes(expanded, item.id) && styles.expanded,
                        this.state.focused == item.id && styles.focused
                      )}
                      key={item.id}
                      onClick={() => this._setItemFocus(item.id)}
                    >
                      <div
                        className={classNames(
                          styles.task,
                          _.includes(
                            ['closed', 'canceled'],
                            item.task_status
                          ) && styles.disabled
                        )}
                      >
                        {disabled ? (
                          <div className={styles.grabberPlaceholder} />
                        ) : (
                          <div className={styles.grabber}>
                            <Tooltip text={__('meeting.items.drag-item')}>
                              <GrabberIcon />
                            </Tooltip>
                          </div>
                        )}
                        <div
                          className={styles.collapse}
                          onClick={() => this._toggleExpandItem(item.id)}
                        >
                          <Tooltip
                            text={__('meeting.items.expand-collapse-item')}
                          >
                            <ArrowDownMiddleIcon
                              className={classNames(
                                _.includes(expanded, item.id) && styles.active
                              )}
                            />
                          </Tooltip>
                        </div>
                        <div className={styles.dash}>&mdash;</div>
                        <div className={styles.title}>
                          <input
                            spellCheck='false'
                            ref={'title' + _.uniqueId()}
                            maxLength={60}
                            disabled={
                              disabled ||
                              _.includes(
                                ['closed', 'canceled'],
                                item.task_status
                              )
                            }
                            onFocus={(e) => {
                              this.setState({ prevent_reload: true });
                              if (
                                e.target.value ==
                                __('meetings.all-meetings.flyout.untitled-task')
                              ) {
                                this.refs['title' + item.id].focus();
                                this.refs['title' + item.id].value = '';
                              }
                            }}
                            onKeyDown={this._handleKeyDown}
                            onBlur={(e) => {
                              this.setState({ prevent_reload: false });
                              this._updateItemTitle(item.id, e.target.value);
                            }}
                            key={item.task_title}
                            defaultValue={item.task_title}
                          />
                        </div>
                        <div className={styles.toggles}>
                          <Tooltip
                            text={__('meeting.items.toggle-attachments')}
                          >
                            <span
                              onClick={() => this._toggleAttachments(item.id)}
                              className={classNames(
                                _.includes(attachments_shown, item.id) &&
                                  styles.active,
                                _.size(files) > 0 && styles.visible
                              )}
                            >
                              <AttachmentsIcon />
                              {_.size(files)}
                            </span>
                          </Tooltip>
                          <Tooltip text={__('meeting.items.toggle-comments')}>
                            <span
                              onClick={() => this._toggleComments(item.id)}
                              className={classNames(
                                _.includes(comments_shown, item.id) &&
                                  styles.active,
                                _.size(item.comments) > 0 && styles.visible
                              )}
                            >
                              <CommentsIcon />
                              {_.size(item.comments)}
                            </span>
                          </Tooltip>
                        </div>
                        {!_.includes(this.state.disabled_columns, 'target') && (
                          <div className={styles.target}>
                            <Dropdown
                              top={30}
                              right={-17}
                              name={'task_type.' + item.id}
                              header={
                                <div className={styles.taskTypeDropdownHeader}>
                                  Task Type
                                </div>
                              }
                              content={
                                <div className={styles.taskTypeDropdownContent}>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_type == 'rfi' && styles.active
                                    )}
                                    onClick={() =>
                                      this._changeTaskType(item.task_id, 'rfi')
                                    }
                                  >
                                    <TaskRfiIcon className={styles.rfiIcon} />
                                    <span>
                                      {__(
                                        'meetings.all-meetings.flyout.request-for-information'
                                      )}
                                    </span>
                                    <span className={styles.dot} />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_type == 'fyi' && styles.active
                                    )}
                                    onClick={() =>
                                      this._changeTaskType(item.task_id, 'fyi')
                                    }
                                  >
                                    <TaskFyiIcon className={styles.fyiIcon} />
                                    <span>
                                      {__(
                                        'meetings.all-meetings.flyout.for-your-information'
                                      )}
                                    </span>
                                    <span className={styles.dot} />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_type == 'decision' &&
                                        styles.active
                                    )}
                                    onClick={() =>
                                      this._changeTaskType(
                                        item.task_id,
                                        'decision'
                                      )
                                    }
                                  >
                                    <TaskDecisionIcon
                                      className={styles.decisionIcon}
                                    />
                                    <span>
                                      {__(
                                        'meetings.all-meetings.flyout.decision'
                                      )}
                                    </span>
                                    <span className={styles.dot} />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_type == 'todo' && styles.active
                                    )}
                                    onClick={() =>
                                      this._changeTaskType(item.task_id, 'todo')
                                    }
                                  >
                                    <TaskTodoIcon className={styles.todoIcon} />
                                    <span>
                                      {__('meetings.all-meetings.flyout.to-do')}
                                    </span>
                                    <span className={styles.dot} />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_type == 'milestone' &&
                                        styles.active
                                    )}
                                    onClick={() =>
                                      this._changeTaskType(
                                        item.task_id,
                                        'milestone'
                                      )
                                    }
                                  >
                                    <TaskMilestoneIcon
                                      className={styles.milestoneIcon}
                                    />
                                    <span>
                                      {__(
                                        'meetings.all-meetings.flyout.milestone'
                                      )}
                                    </span>
                                    <span className={styles.dot} />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_type == 'issue' && styles.active
                                    )}
                                    onClick={() =>
                                      this._changeTaskType(
                                        item.task_id,
                                        'issue'
                                      )
                                    }
                                  >
                                    <TaskIssueIcon
                                      className={styles.issueIcon}
                                    />
                                    <span>
                                      {__('meetings.all-meetings.flyout.issue')}
                                    </span>
                                    <span className={styles.dot} />
                                  </div>
                                </div>
                              }
                            >
                              {item.task_type == 'rfi' && (
                                <Tooltip
                                  text={__('meeting.items.change-task-type')}
                                >
                                  <TaskRfiIcon className={styles.rfiIcon} />
                                </Tooltip>
                              )}
                              {item.task_type == 'fyi' && (
                                <Tooltip
                                  text={__('meeting.items.change-task-type')}
                                >
                                  <TaskFyiIcon className={styles.fyiIcon} />
                                </Tooltip>
                              )}
                              {item.task_type == 'decision' && (
                                <Tooltip
                                  text={__('meeting.items.change-task-type')}
                                >
                                  <TaskDecisionIcon
                                    className={styles.decisionIcon}
                                  />
                                </Tooltip>
                              )}
                              {item.task_type == 'todo' && (
                                <Tooltip
                                  text={__('meeting.items.change-task-type')}
                                >
                                  <TaskTodoIcon className={styles.todoIcon} />
                                </Tooltip>
                              )}
                              {item.task_type == 'milestone' && (
                                <Tooltip
                                  text={__('meeting.items.change-task-type')}
                                >
                                  <TaskMilestoneIcon
                                    className={styles.milestoneIcon}
                                  />
                                </Tooltip>
                              )}
                              {item.task_type == 'issue' && (
                                <Tooltip
                                  text={__('meeting.items.change-task-type')}
                                >
                                  <TaskIssueIcon className={styles.issueIcon} />
                                </Tooltip>
                              )}
                            </Dropdown>
                            <Tooltip text={__('meeting.items.task-properties')}>
                              <span
                                onClick={() =>
                                  this._handleTaskFlyout(item.task_id)
                                }
                              >
                                {'T-' +
                                  _.padStart(item.task_identifier, 4, '0')}
                                <ArrowDoubleRightSmallIcon />
                              </span>
                            </Tooltip>
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'priority'
                        ) && (
                          <div className={styles.priority}>
                            <Dropdown
                              top={30}
                              right={-17}
                              name={'task_priority.' + item.id}
                              header={
                                <div
                                  className={styles.taskPriorityDropdownHeader}
                                >
                                  Task Priority
                                </div>
                              }
                              content={
                                <div
                                  className={styles.taskPriorityDropdownContent}
                                >
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_priority == 'low' &&
                                        styles.active
                                    )}
                                    onClick={() =>
                                      !disabled &&
                                      this._changeTaskPriority(
                                        item.task_id,
                                        'low'
                                      )
                                    }
                                  >
                                    <span className={styles.title}>Low</span>
                                    <span
                                      className={styles.dot}
                                      style={{
                                        background: '#FFC108',
                                        boxShadow:
                                          'inset 0 0 0 2px #FFF' +
                                          (item.task_priority == 'low'
                                            ? ', 0 0 0 1px #FFC108'
                                            : ''),
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_priority == 'normal' &&
                                        styles.active
                                    )}
                                    onClick={() =>
                                      !disabled &&
                                      this._changeTaskPriority(
                                        item.task_id,
                                        'normal'
                                      )
                                    }
                                  >
                                    <span className={styles.title}>Normal</span>
                                    <span
                                      className={styles.dot}
                                      style={{
                                        background: '#FE9800',
                                        boxShadow:
                                          'inset 0 0 0 2px #FFF' +
                                          (item.task_priority == 'normal'
                                            ? ', 0 0 0 1px #FE9800'
                                            : ''),
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_priority == 'high' &&
                                        styles.active
                                    )}
                                    onClick={() =>
                                      !disabled &&
                                      this._changeTaskPriority(
                                        item.task_id,
                                        'high'
                                      )
                                    }
                                  >
                                    <span className={styles.title}>High</span>
                                    <span
                                      className={styles.dot}
                                      style={{
                                        background: '#FF5721',
                                        boxShadow:
                                          'inset 0 0 0 2px #FFF' +
                                          (item.task_priority == 'high'
                                            ? ', 0 0 0 1px #FF5721'
                                            : ''),
                                      }}
                                    />
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.option,
                                      item.task_priority == 'urgent' &&
                                        styles.active
                                    )}
                                    onClick={() =>
                                      !disabled &&
                                      this._changeTaskPriority(
                                        item.task_id,
                                        'urgent'
                                      )
                                    }
                                  >
                                    <span className={styles.title}>Urgent</span>
                                    <span
                                      className={styles.dot}
                                      style={{
                                        background: '#E62A11',
                                        boxShadow:
                                          'inset 0 0 0 2px #FFF' +
                                          (item.task_priority == 'urgent'
                                            ? ', 0 0 0 1px #E62A11'
                                            : ''),
                                      }}
                                    />
                                  </div>
                                </div>
                              }
                            >
                              <Tooltip
                                text={__('meeting.items.change-task-priority')}
                              >
                                <div
                                  className={classNames(
                                    styles.bubble,
                                    item.task_priority == 'low' && styles.low,
                                    item.task_priority == 'normal' &&
                                      styles.normal,
                                    item.task_priority == 'high' && styles.high,
                                    item.task_priority == 'urgent' &&
                                      styles.urgent
                                  )}
                                >
                                  {priorities[item.task_priority]}
                                </div>
                              </Tooltip>
                            </Dropdown>
                          </div>
                        )}
                        {!_.includes(this.state.disabled_columns, 'status') && (
                          <div
                            className={classNames(
                              styles.status,
                              !is_administrator && styles.disabled
                            )}
                          >
                            <Dropdown
                              top={30}
                              right={-17}
                              name={'task_status.' + item.id}
                              header={
                                <div
                                  className={styles.taskStatusDropdownHeader}
                                >
                                  Task Status
                                </div>
                              }
                              content={
                                <div
                                  className={styles.taskStatusDropdownContent}
                                >
                                  {_.includes(
                                    ['started'],
                                    item.task_status
                                  ) && (
                                    <div
                                      className={styles.option}
                                      onClick={() =>
                                        is_administrator &&
                                        this._changeTaskStatus(
                                          item.task_id,
                                          'not-started'
                                        )
                                      }
                                    >
                                      <span className={styles.title}>
                                        Not Started
                                      </span>
                                      <span
                                        className={styles.dot}
                                        style={{
                                          background: '#9C27B0',
                                          boxShadow: 'inset 0 0 0 2px #FFF',
                                        }}
                                      />
                                    </div>
                                  )}
                                  {_.includes(
                                    ['not-started', 'reopened'],
                                    item.task_status
                                  ) && (
                                    <div
                                      className={styles.option}
                                      onClick={() =>
                                        is_administrator &&
                                        this._changeTaskStatus(
                                          item.task_id,
                                          'started'
                                        )
                                      }
                                    >
                                      <span className={styles.title}>
                                        Started
                                      </span>
                                      <span
                                        className={styles.dot}
                                        style={{
                                          background: '#5777FC',
                                          boxShadow: 'inset 0 0 0 2px #FFF',
                                        }}
                                      />
                                    </div>
                                  )}
                                  {_.includes(
                                    ['not-started', 'started'],
                                    item.task_status
                                  ) && (
                                    <div
                                      className={styles.option}
                                      onClick={() =>
                                        is_administrator &&
                                        this._changeTaskStatus(
                                          item.task_id,
                                          'finished'
                                        )
                                      }
                                    >
                                      <span className={styles.title}>
                                        Finished
                                      </span>
                                      <span
                                        className={styles.dot}
                                        style={{
                                          background: '#ADD42A',
                                          boxShadow: 'inset 0 0 0 2px #FFF',
                                        }}
                                      />
                                    </div>
                                  )}
                                  {_.includes(
                                    ['not-started', 'started', 'finished'],
                                    item.task_status
                                  ) && (
                                    <div
                                      className={styles.option}
                                      onClick={() =>
                                        is_administrator &&
                                        this._changeTaskStatus(
                                          item.task_id,
                                          'closed'
                                        )
                                      }
                                    >
                                      <span className={styles.title}>
                                        Closed
                                      </span>
                                      <span
                                        className={styles.dot}
                                        style={{
                                          background: '#009688',
                                          boxShadow: 'inset 0 0 0 2px #FFF',
                                        }}
                                      />
                                    </div>
                                  )}
                                  {_.includes(
                                    ['finished', 'closed', 'canceled'],
                                    item.task_status
                                  ) && (
                                    <div
                                      className={styles.option}
                                      onClick={() =>
                                        is_administrator &&
                                        this._changeTaskStatus(
                                          item.task_id,
                                          'reopened'
                                        )
                                      }
                                    >
                                      <span className={styles.title}>
                                        Reopened
                                      </span>
                                      <span
                                        className={styles.dot}
                                        style={{
                                          background: '#303F9F',
                                          boxShadow: 'inset 0 0 0 2px #FFF',
                                        }}
                                      />
                                    </div>
                                  )}
                                  {_.includes(
                                    [
                                      'not-started',
                                      'started',
                                      'finished',
                                      'reopened',
                                    ],
                                    item.task_status
                                  ) && (
                                    <div
                                      className={styles.option}
                                      onClick={() =>
                                        is_administrator &&
                                        this._changeTaskStatus(
                                          item.task_id,
                                          'canceled'
                                        )
                                      }
                                    >
                                      <span className={styles.title}>
                                        Canceled
                                      </span>
                                      <span
                                        className={styles.dot}
                                        style={{
                                          background: '#CCCDD1',
                                          boxShadow: 'inset 0 0 0 2px #FFF',
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              }
                            >
                              <Tooltip
                                text={__('meeting.items.change-task-status')}
                              >
                                <div
                                  className={classNames(
                                    styles.bubble,
                                    item.task_status == 'not-started' &&
                                      styles.notStarted,
                                    item.task_status == 'started' &&
                                      styles.started,
                                    item.task_status == 'finished' &&
                                      styles.finished,
                                    item.task_status == 'closed' &&
                                      styles.closed,
                                    item.task_status == 'reopened' &&
                                      styles.reopened,
                                    item.task_status == 'canceled' &&
                                      styles.canceled
                                  )}
                                >
                                  {statuses[item.task_status]}
                                </div>
                              </Tooltip>
                            </Dropdown>
                          </div>
                        )}
                        {!_.includes(this.state.disabled_columns, 'due') && (
                          <div className={styles.due}>
                            {item.task_end_date ? (
                              dateFrom(item.task_end_date)
                            ) : (
                              <TableNA />
                            )}
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'assignee'
                        ) && (
                          <div className={styles.assignee}>
                            {_.isEmpty(item.task_assignee) && <TableNA />}
                            {_.size(item.task_assignee) == 1 &&
                              _.map(
                                _.take(_.values(item.task_assignee), 1),
                                (user, i) => (
                                  <Avatar
                                    key={i}
                                    avatar_url={user.avatar_url}
                                    name={user.fullname}
                                    active={user.active}
                                  />
                                )
                              )}
                            {_.size(_.values(item.task_assignee)) > 1 && (
                              <Tooltip
                                text={_.map(
                                  _.values(item.task_assignee),
                                  'fullname'
                                ).join('\n')}
                              >
                                <div className={styles.more}>
                                  <span>
                                    {_.size(_.values(item.task_assignee))}
                                  </span>
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'startend'
                        ) && (
                          <div className={styles.startend}>
                            {start_time.format('HH:mm')}-
                            {start_time
                              .add(item.duration, 'minutes')
                              .format('HH:mm')}
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'duration'
                        ) && (
                          <div className={styles.duration}>
                            <Cleave
                              options={{ numericOnly: true }}
                              maxLength={3}
                              onFocus={(e) => e.currentTarget.select()}
                              type='text'
                              disabled={disabled}
                              value={item.duration}
                              onKeyDown={this._handleKeyDown}
                              onBlur={(e) =>
                                this._updateItemDuration(
                                  item.id,
                                  e.target.value,
                                  e
                                )
                              }
                            />
                          </div>
                        )}

                        <div className={styles.move}>
                          {!disabled && (
                            <Tooltip text={__('meeting.items.move-item')}>
                              <MoveIcon
                                onClick={() =>
                                  setTableParams('all_meetings', {
                                    move_meeting_item_wizard: {
                                      id: item.id,
                                      name: item.task_title,
                                      sorted_items,
                                    },
                                  })
                                }
                              />
                            </Tooltip>
                          )}
                        </div>

                        <div className={styles.delete}>
                          {!disabled && (
                            <Tooltip text={__('meeting.items.delete-item')}>
                              <CloseMiddleIcon
                                onClick={() => this._deleteItem(item.id)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div className={styles.subcontent}>
                        <div className={styles.description}>
                          <TextareaAutosize
                            maxLength={2000}
                            rows={1}
                            disabled={
                              disabled ||
                              _.includes(
                                ['closed', 'canceled'],
                                item.task_status
                              )
                            }
                            placeholder={__('meeting-items.enter-description')}
                            key={_.get(item.task_description, language)}
                            defaultValue={_.get(
                              item.task_description,
                              language
                            )}
                            onBlur={(e) => {
                              this.setState({ prevent_reload: false });

                              this._updateItemDescription(
                                item.id,
                                e.target.value
                              );
                            }}
                            onFocus={() =>
                              this.setState({ prevent_reload: true })
                            }
                          />
                        </div>
                        {_.includes(attachments_shown, item.id) && (
                          <div className={styles.attachmentsTitle}>
                            <AttachmentsIcon />
                            {__(
                              'meetings.all-meetings.flyout.toggle-attachments.attachments'
                            )}{' '}
                            {_.isEmpty(item.attachments) ? (
                              <span>
                                &middot;{' '}
                                {__(
                                  'meetings.all-meetings.flyout.toggle-attachments.no-attachments'
                                )}
                              </span>
                            ) : (
                              '(' + _.size(files) + ')'
                            )}
                            {(is_administrator ||
                              is_follower ||
                              is_participant) &&
                              !_.includes(
                                ['closed', 'canceled'],
                                meeting.status
                              ) &&
                              !_.includes(
                                ['closed', 'canceled'],
                                item.task_status
                              ) && (
                                <div
                                  className={styles.button}
                                  onClick={() =>
                                    this.refs['file_input' + item.id].click()
                                  }
                                >
                                  {__(
                                    'meetings.all-meetings.flyout.add-attachment'
                                  )}
                                </div>
                              )}
                            <input
                              key={item.id}
                              type='file'
                              ref={'file_input' + item.id}
                              multiple={true}
                              onChange={(e) =>
                                this._uploadFiles(item.id, e.target.files)
                              }
                            />
                          </div>
                        )}
                        {_.includes(attachments_shown, item.id) &&
                          !_.isEmpty(files) && (
                            <div className={styles.attachments}>
                              {_.map(files, (attachment, i) => (
                                <Tooltip
                                  text={[attachment.name, attachment.ext].join(
                                    '.'
                                  )}
                                  key={i}
                                >
                                  <FilePreview
                                    lightbox_images={lightbox_images}
                                    onDownloadFile={this._downloadFile}
                                    row={attachment}
                                    fileName={false}
                                    customStyles={
                                      _.includes(
                                        ['jpg', 'jpeg', 'png'],
                                        _.toLower(attachment.ext)
                                      ) && styles.thumbnail
                                    }
                                  />
                                </Tooltip>
                              ))}
                            </div>
                          )}
                        {_.includes(comments_shown, item.id) && (
                          <div className={styles.commentsTitle}>
                            <CommentsIcon />
                            {__(
                              'meetings.all-meetings.flyout.toggle-comments.comments'
                            )}{' '}
                            {_.isEmpty(item.comments) ? (
                              <span>
                                &middot;{' '}
                                {__(
                                  'meetings.all-meetings.flyout.toggle-comments.no-comments'
                                )}
                              </span>
                            ) : (
                              '(' + _.size(item.comments) + ')'
                            )}
                          </div>
                        )}
                        {_.includes(comments_shown, item.id) && (
                          <div className={styles.comments}>
                            {_.map(item.comments, (comment, i) => (
                              <div className={styles.comment} key={i}>
                                <div className={styles.author}>
                                  <Avatar
                                    avatar_url={comment.user.avatar_url}
                                    name={comment.user.fullname}
                                    active={comment.user.active}
                                  />
                                  <strong>
                                    {comment.user.fullname} &middot;
                                  </strong>
                                  {dateTimeFrom(comment.created_at)}
                                </div>
                                <div className={styles.messageContent}>
                                  {linkifyText(comment.message)}
                                </div>
                              </div>
                            ))}
                            {(is_administrator ||
                              is_follower ||
                              is_participant) &&
                              !_.includes(
                                ['closed', 'canceled'],
                                meeting.status
                              ) &&
                              !_.includes(
                                ['closed', 'canceled'],
                                item.task_status
                              ) && (
                                <div className={styles.comment}>
                                  <div className={styles.author}>
                                    <Avatar avatar_url={auth.avatar_url} />
                                    <strong>
                                      {auth.firstname} {auth.lastname}
                                    </strong>
                                  </div>
                                  <div
                                    className={styles.message}
                                    key={item.id + '_' + _.size(item.comments)}
                                  >
                                    <TextareaAutosize
                                      rows={1}
                                      ref={_.get(this, 'comment' + item.id)}
                                      placeholder={__(
                                        'meetings.all-meetings.flyout.enter-comment'
                                      )}
                                      maxLength={5000}
                                    />
                                    <div
                                      className={styles.button}
                                      onClick={() =>
                                        this._createComment(item.id)
                                      }
                                    >
                                      <SendIcon />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (item.type == 'group') {
                  const group_end_index = _.findIndex(
                    sorted_items,
                    (searching_item) =>
                      searching_item.order > item.order &&
                      searching_item.type == 'group'
                  );

                  const group_items = _.slice(
                    sorted_items,
                    i + 1,
                    group_end_index < 0
                      ? sorted_items.length + 1
                      : group_end_index
                  );

                  const group_end_time = start_time
                    .clone()
                    .add(_.sumBy(group_items, 'duration'), 'minutes');

                  return (
                    <div
                      className={styles.item}
                      key={item.id}
                      onClick={() => this._setItemFocus(item.id)}
                    >
                      <div className={styles.group}>
                        {disabled ? (
                          <div className={styles.grabberPlaceholder} />
                        ) : (
                          <div className={styles.grabber}>
                            <Tooltip text={__('meeting.items.drag-item')}>
                              <GrabberIcon />
                            </Tooltip>
                          </div>
                        )}
                        <div className={styles.collapse} />
                        <div className={styles.number}>
                          {_.padStart(_.toString(group_counter++), 2, '0')}
                        </div>

                        <div className={styles.title}>
                          <input
                            spellCheck='false'
                            ref={'title' + item.id}
                            maxLength={60}
                            disabled={disabled}
                            onFocus={(e) => {
                              this.setState({ prevent_reload: true });

                              if (
                                e.target.value ==
                                __(
                                  'meetings.all-meetings.flyout.untitled-group'
                                )
                              ) {
                                this.refs['title' + item.id].focus();
                                this.refs['title' + item.id].value = '';
                              }
                            }}
                            onKeyDown={this._handleKeyDown}
                            onBlur={(e) => {
                              this.setState({ prevent_reload: false });

                              this._updateItemTitle(item.id, e.target.value);
                            }}
                            key={meeting.updated_at}
                            defaultValue={item.title}
                          />
                        </div>
                        {!_.includes(this.state.disabled_columns, 'target') && (
                          <div className={styles.target} />
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'priority'
                        ) && <div className={styles.priority} />}
                        {!_.includes(this.state.disabled_columns, 'status') && (
                          <div className={styles.status} />
                        )}
                        {!_.includes(this.state.disabled_columns, 'due') && (
                          <div className={styles.due} />
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'assignee'
                        ) && <div className={styles.assignee} />}
                        {!_.includes(
                          this.state.disabled_columns,
                          'startend'
                        ) && (
                          <div className={styles.startend}>
                            {start_time.format('HH:mm')}-
                            {group_end_time.format('HH:mm')}
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'duration'
                        ) && (
                          <div className={styles.duration}>
                            {moment
                              .duration(group_end_time.diff(start_time))
                              .asMinutes()}{' '}
                            min
                          </div>
                        )}
                        <div className={styles.move}>
                          {!disabled && (
                            <Tooltip text={__('meeting.items.move-item')}>
                              <MoveIcon
                                onClick={() =>
                                  setTableParams('all_meetings', {
                                    move_meeting_item_wizard: {
                                      id: item.id,
                                      name: item.title,
                                      sorted_items,
                                    },
                                  })
                                }
                              />
                            </Tooltip>
                          )}
                        </div>

                        <div className={styles.delete}>
                          {!disabled && (
                            <Tooltip text={__('meeting.items.delete-item')}>
                              <CloseMiddleIcon
                                onClick={() => this._deleteItem(item.id)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }

                if (item.type == 'topic') {
                  const lightbox_images = _.map(
                    _.filter(item.attachments, 'stream_url'),
                    (file) => ({
                      id: file.id,
                      stream_url: file.stream_url,
                      title: [file.name, file.ext].join('.'),
                    })
                  );

                  return (
                    <div
                      className={classNames(
                        styles.item,
                        _.includes(expanded, item.id) && styles.expanded,
                        this.state.focused == item.id && styles.focused
                      )}
                      key={item.id}
                      onClick={() => this._setItemFocus(item.id)}
                    >
                      <div className={styles.emptyTopic}>
                        {disabled ? (
                          <div className={styles.grabberPlaceholder} />
                        ) : (
                          <div className={styles.grabber}>
                            <Tooltip text={__('meeting.items.drag-item')}>
                              <GrabberIcon />
                            </Tooltip>
                          </div>
                        )}
                        <div
                          className={styles.collapse}
                          onClick={() => this._toggleExpandItem(item.id)}
                        >
                          <Tooltip
                            text={__('meeting.items.expand-collapse-item')}
                          >
                            <ArrowDownMiddleIcon
                              className={classNames(
                                _.includes(expanded, item.id) && styles.active
                              )}
                            />
                          </Tooltip>
                        </div>
                        <div className={styles.dash}>&mdash;</div>
                        <div className={styles.title}>
                          <input
                            spellCheck='false'
                            ref={'title' + item.id}
                            maxLength={60}
                            disabled={disabled}
                            onFocus={(e) => {
                              if (
                                e.target.value ==
                                __('meetings.all-meetings.flyout.topic')
                              ) {
                                this.refs['title' + item.id].focus();
                                this.refs['title' + item.id].value = '';
                              }

                              this.setState({ prevent_reload: true });
                            }}
                            onKeyDown={this._handleKeyDown}
                            onBlur={(e) => {
                              this.setState({ prevent_reload: false });

                              this._updateItemTitle(item.id, e.target.value);
                            }}
                            key={meeting.updated_at}
                            defaultValue={item.title}
                          />
                        </div>
                        <div className={styles.toggles}>
                          <Tooltip
                            text={__('meeting.items.toggle-attachments')}
                          >
                            <span
                              onClick={() => this._toggleAttachments(item.id)}
                              className={classNames(
                                _.includes(attachments_shown, item.id) &&
                                  styles.active,
                                _.size(item.attachments) > 0 && styles.visible
                              )}
                            >
                              <AttachmentsIcon />
                              {_.size(item.attachments)}
                            </span>
                          </Tooltip>
                          <Tooltip text={__('meeting.items.toggle-comments')}>
                            <span
                              onClick={() => this._toggleComments(item.id)}
                              className={classNames(
                                _.includes(comments_shown, item.id) &&
                                  styles.active,
                                _.size(item.comments) > 0 && styles.visible
                              )}
                            >
                              <CommentsIcon />
                              {_.size(item.comments)}
                            </span>
                          </Tooltip>
                        </div>
                        {!_.includes(this.state.disabled_columns, 'target') && (
                          <div className={styles.target}>
                            {!disabled && (
                              <Tooltip
                                text={__(
                                  'meetings.all-meetings.tooltip.convert-to-task'
                                )}
                              >
                                <ConvertToTaskIcon
                                  onClick={() => this._convertToTask(item.id)}
                                />
                              </Tooltip>
                            )}
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'priority'
                        ) && <div className={styles.priority} />}
                        {!_.includes(this.state.disabled_columns, 'status') && (
                          <div className={styles.status} />
                        )}
                        {!_.includes(this.state.disabled_columns, 'due') && (
                          <div className={styles.due} />
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'assignee'
                        ) && <div className={styles.assignee} />}
                        {!_.includes(
                          this.state.disabled_columns,
                          'startend'
                        ) && (
                          <div className={styles.startend}>
                            {start_time.format('HH:mm')}-
                            {start_time
                              .add(item.duration, 'minutes')
                              .format('HH:mm')}
                          </div>
                        )}
                        {!_.includes(
                          this.state.disabled_columns,
                          'duration'
                        ) && (
                          <div className={styles.duration}>
                            <Cleave
                              options={{ numericOnly: true }}
                              maxLength={3}
                              onFocus={(e) => e.currentTarget.select()}
                              type='text'
                              disabled={disabled}
                              value={item.duration}
                              onKeyDown={this._handleKeyDown}
                              onBlur={(e) =>
                                this._updateItemDuration(
                                  item.id,
                                  e.target.value,
                                  e
                                )
                              }
                            />
                          </div>
                        )}
                        <div className={styles.move}>
                          {!disabled && (
                            <Tooltip text={__('meeting.items.move-item')}>
                              <MoveIcon
                                onClick={() =>
                                  setTableParams('all_meetings', {
                                    move_meeting_item_wizard: {
                                      id: item.id,
                                      name: item.title,
                                      sorted_items,
                                    },
                                  })
                                }
                              />
                            </Tooltip>
                          )}
                        </div>
                        <div className={styles.delete}>
                          {!disabled && (
                            <Tooltip text={__('meeting.items.delete-item')}>
                              <CloseMiddleIcon
                                onClick={() => this._deleteItem(item.id)}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div className={styles.subcontent}>
                        <div className={styles.description}>
                          <TextareaAutosize
                            maxLength={2000}
                            rows={1}
                            disabled={disabled}
                            placeholder={__(
                              'meetings.all-meetings.flyout.enter-description'
                            )}
                            key={meeting.updated_at}
                            defaultValue={_.get(item.description, language)}
                            onBlur={(e) => {
                              this.setState({ prevent_reload: false });

                              this._updateItemDescription(
                                item.id,
                                e.target.value
                              );
                            }}
                            onFocus={() =>
                              this.setState({ prevent_reload: true })
                            }
                          />
                        </div>
                        {_.includes(attachments_shown, item.id) && (
                          <div className={styles.attachmentsTitle}>
                            <AttachmentsIcon />
                            {__(
                              'meetings.all-meetings.flyout.toggle-attachments.attachments'
                            )}{' '}
                            {_.isEmpty(item.attachments) ? (
                              <span>
                                &middot;{' '}
                                {__(
                                  'meetings.all-meetings.flyout.toggle-attachments.no-attachments'
                                )}
                              </span>
                            ) : (
                              '(' + _.size(item.attachments) + ')'
                            )}
                            {(is_administrator ||
                              is_follower ||
                              is_participant) &&
                              !_.includes(
                                ['closed', 'canceled'],
                                meeting.status
                              ) && (
                                <div
                                  className={styles.button}
                                  onClick={() =>
                                    this.refs['file_input' + item.id].click()
                                  }
                                >
                                  {__(
                                    'meetings.all-meetings.flyout.add-attachment'
                                  )}
                                </div>
                              )}
                            <input
                              key={item.id}
                              type='file'
                              ref={'file_input' + item.id}
                              multiple={true}
                              onChange={(e) =>
                                this._uploadFiles(item.id, e.target.files)
                              }
                            />
                          </div>
                        )}
                        {_.includes(attachments_shown, item.id) &&
                          !_.isEmpty(item.attachments) && (
                            <div className={styles.attachments}>
                              {_.map(item.attachments, (attachment, i) => (
                                <Tooltip
                                  text={[attachment.name, attachment.ext].join(
                                    '.'
                                  )}
                                  key={i}
                                >
                                  <FilePreview
                                    lightbox_images={lightbox_images}
                                    onDownloadFile={this._downloadFile}
                                    row={attachment}
                                    fileName={false}
                                    customStyles={
                                      _.includes(
                                        ['jpg', 'jpeg', 'png'],
                                        _.toLower(attachment.ext)
                                      ) && styles.thumbnail
                                    }
                                  />
                                </Tooltip>
                              ))}
                            </div>
                          )}
                        {_.includes(comments_shown, item.id) && (
                          <div className={styles.commentsTitle}>
                            <CommentsIcon />
                            {__(
                              'meetings.all-meetings.flyout.toggle-comments.comments'
                            )}{' '}
                            {_.isEmpty(item.comments) ? (
                              <span>
                                &middot;{' '}
                                {__(
                                  'meetings.all-meetings.flyout.toggle-comments.no-comments'
                                )}
                              </span>
                            ) : (
                              '(' + _.size(item.comments) + ')'
                            )}
                          </div>
                        )}
                        {_.includes(comments_shown, item.id) && (
                          <div className={styles.comments}>
                            {_.map(item.comments, (comment, i) => (
                              <div className={styles.comment} key={i}>
                                <div className={styles.author}>
                                  <Avatar
                                    avatar_url={comment.user.avatar_url}
                                    name={comment.user.fullname}
                                    active={comment.user.active}
                                  />
                                  <strong>
                                    {comment.user.fullname} &middot;
                                  </strong>
                                  {dateTimeFrom(comment.created_at)}
                                </div>
                                <div className={styles.messageContent}>
                                  {linkifyText(comment.message)}
                                </div>
                              </div>
                            ))}
                            {(is_administrator ||
                              is_follower ||
                              is_participant) &&
                              !_.includes(
                                ['closed', 'canceled'],
                                meeting.status
                              ) && (
                                <div className={styles.comment}>
                                  <div className={styles.author}>
                                    <Avatar avatar_url={auth.avatar_url} />
                                    <strong>
                                      {auth.firstname} {auth.lastname}
                                    </strong>
                                  </div>
                                  <div
                                    className={styles.message}
                                    key={item.id + '_' + _.size(item.comments)}
                                  >
                                    <TextareaAutosize
                                      rows={1}
                                      ref={_.get(this, 'comment' + item.id)}
                                      placeholder={__(
                                        'meetings.all-meetings.flyout.enter-comment'
                                      )}
                                      maxLength={5000}
                                    />
                                    <div
                                      className={styles.button}
                                      onClick={() =>
                                        this._createComment(item.id)
                                      }
                                    >
                                      <SendIcon />
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </ReactSortable>
          )}
        </div>
        {!disabled && (
          <div className={styles.footer}>
            <div className={styles.button} onClick={this._createTopic}>
              {__('meeting.items.add-topic')}
            </div>
            <div
              className={styles.button}
              onClick={() =>
                setTableParams('all_meetings', {
                  wizard_add_tasks_to_meeting: true,
                  visibility: meeting.visibility,
                  order: _.get(
                    _.find(items, (item) => item.id == focused),
                    'order'
                  ),
                })
              }
            >
              {__('meeting.items.add-existing-task')}
            </div>
            <div className={styles.button} onClick={this._createTask}>
              {__('meeting.items.add-new-task')}
            </div>
            <div className={styles.button} onClick={this._createGroup}>
              {__('meeting.items.add-group')}
            </div>
          </div>
        )}
        <div
          className={classNames(
            styles.flyout,
            (this.props.table.clicked_row || this.props.clicked_task_id) &&
              styles.active
          )}
        >
          {this.props.table.clicked_row && this.state.preview && (
            <MeetingsFlyout
              data={this.state.preview}
              readAction={(meeting_id) => {
                return new Promise((resolve) => {
                  readMeeting(meeting_id).then(({ response }) => {
                    this.setState({ preview: response.data });
                    this.setState({ meeting: response.data });

                    resolve(response);
                  });
                });
              }}
            />
          )}
          {this.props.clicked_task_id && this.props.task_flyout_data && (
            <TasksFlyout
              data={this.props.task_flyout_data}
              readAction={(task_id) => {
                return new Promise((resolve) => {
                  readTask(task_id).then(({ response }) => {
                    readMeetingItems(this.props.match.params.meeting).then(
                      ({ response }) => {
                        this.setState({ items: response.data });
                      }
                    );

                    resolve(response);
                  });
                });
              }}
            />
          )}
        </div>
      </>
    ) : (
      ''
    );
  }
}

export default MeetingItems;
