import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/public_deliveries/PublicDeliveriesFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import { connect } from "react-redux";
import { __, dateTimeFrom, redirect } from "../../core/utils";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import withRouter from "react-router/es/withRouter";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import PlanSetIcon from "../../assets/images/plan-set-15x15.svg";
import DisciplineIcon from "../../assets/images/discipline-15x15.svg";
import TeamIcon from "../../assets/images/team-15x15.svg";
import DatetimeIcon from "../../assets/images/datetime-15x15.svg";
import UserMaleIcon from "../../assets/images/user-male-15x15.svg";
import Tooltip from "../../components/Tooltip";
import { downloadDeliveryPublicPlans } from "../../redux/actions/table/delivery_plans";
import DownloadIcon from "../../assets/images/download-15x15.svg";

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			setTableParams: (params) =>
				dispatch({
					type: "table.public_deliveries/SET_TABLE_PARAMS",
					params
				})
		}
	};
};

@connect(null, mapDispatchToProps)
@subscribe(Localization, "localization")
@withRouter
class PublicDeliveriesFlyout extends Component {
	render() {
		const { data } = this.props;

		return (
			<div className={styles.preview}>
				<div className={styles.header}>
					<div className={styles.title}>
						<span>{data.title}</span>
						<CloseBigIcon
							onClick={() =>
								setTimeout(() => {
									this.props.actions.setTableParams({
										clicked_row: undefined
									});
								}, 100)
							}
						/>
					</div>

					<div className={styles.actions}>
						<div className={styles.actionWrapper}>
							<Tooltip text={__("plans.tooltip.icon.deliveries.plans-download")}>
								<DownloadIcon
									onClick={(e) => {
										e.stopPropagation();

										downloadDeliveryPublicPlans(data.id);
									}}
								/>
							</Tooltip>
						</div>
					</div>
				</div>

				<div className={styles.scroller}>
					<div className={styles.details}>
						<div>
							<span>
								<PlanSetIcon /> {__("plans.deliveries.columns.plans")}
							</span>
							<span className={styles.plans} onClick={() => redirect("/stages/" + this.props.match.params.stage + "/deliveries/public/" + data.id)}>
								{data.plans}{" "}
								<Tooltip text={__("plans.tooltip.icon.deliveries.goto-plans")}>
									<ArrowDoubleRightSmallIcon />
								</Tooltip>
							</span>
						</div>
						<div>
							<span>
								<DisciplineIcon /> {__("plans.deliveries.columns.disciplines")}
							</span>
							<span>{data.discipline.id == null ? data.discipline.description + "*" : _.get(this.props.localization.disciplines, [data.discipline.id, "name"])}</span>
						</div>
						<div>
							<span>
								<TeamIcon /> {__("plans.deliveries.columns.team")}
							</span>
							<span>{data.team.company}</span>
						</div>
						<div>
							<span>
								<DatetimeIcon /> {__("plans.deliveries.columns.created-at")}
							</span>
							<span>{dateTimeFrom(data.created_at)}</span>
						</div>
						<div>
							<span>
								<UserMaleIcon /> {__("plans.deliveries.columns.created-by")}
							</span>
							<span>{data.created_by}</span>
						</div>
						<div>
							<span>
								<DatetimeIcon /> {__("plans.deliveries.columns.delivered-at")}
							</span>
							<span>{dateTimeFrom(data.delivered_at)}</span>
						</div>
						<div>
							<span>
								<UserMaleIcon /> {__("plans.deliveries.columns.delivered-to")}
							</span>
							<span>{_.join(data.delivered_to, ", ")}</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default PublicDeliveriesFlyout;
