import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/rooms/RoomsFlyout.scss';
import * as _ from 'lodash';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import { __, setTableParams, mapStateToProps } from '../../core/utils';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import { setNotification } from '../../redux/actions/general/notification';
import withRouter from 'react-router/es/withRouter';
import { deleteRooms, listRooms } from '../../redux/actions/table/rooms';
import Tooltip from '../../components/Tooltip';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import classNames from 'classnames';
import Properties from './Properties';
import DuplicateIcon from '../../assets/images/duplicate-16x16.svg';

@subscribe(Localization, 'localization')
@withRouter
@mapStateToProps((state) => ({
  store: state.table.rooms,
}))
class RoomsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'properties',
    };
  }

  render() {
    const { readAction, store } = this.props;

    const { flyout } = store;

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              {flyout.code && <strong>{flyout.code}:</strong>}
              {_.get(
                flyout.title,
                _.findKey(
                  _.get(flyout, 'project_languages'),
                  (primary) => primary == 1
                )
              )}
            </span>
            <CloseBigIcon
              onClick={() =>
                setTimeout(() => {
                  setTableParams('rooms', {
                    clicked_row: undefined,
                  });
                }, 100)
              }
            />
          </div>
          <div className={styles.actions}>
            <div className={styles.actionWrapper}>
              <Tooltip
                text={__('rooms.tooltip.duplicate-room')}
                key={_.uniqueId()}
              >
                <DuplicateIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('rooms', {
                      duplicate: flyout.id,
                    });
                  }}
                />
              </Tooltip>
            </div>
            <div className={styles.actionWrapper}>
              <Tooltip
                text={__('rooms.tooltip.delete-room')}
                key={_.uniqueId()}
              >
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    showAlertbox({
                      color: 'red',
                      title: __('general.alert.are-you-sure'),
                      description: __('rooms.alert.delete-confirmation'),
                      buttons: [
                        {
                          color: 'lightGray',
                          text: __('general.alert.no-close'),
                          onClick: 'close',
                        },
                        {
                          color: 'gray',
                          text: __('general.alert.yes-delete'),
                          onClick: () => {
                            deleteRooms([flyout.id]).then(() => {
                              hideAlertbox();

                              setNotification(__('rooms.alert.deleted'));
                              listRooms(this.props.match.params.stage);

                              setTableParams('rooms', {
                                clicked_row: undefined,
                              });
                            });
                          },
                        },
                      ],
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.tabs}>
          <span
            className={classNames(
              this.state.active == 'properties' && styles.active
            )}
            onClick={() => this.setState({ active: 'properties' })}
          >
            {__('rooms.flyout.properties')}
          </span>
        </div>
        {!_.isEmpty(flyout) && this.state.active == 'properties' && (
          <Properties readAction={readAction} />
        )}
      </div>
    );
  }
}

export default RoomsFlyout;
