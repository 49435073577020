import React, {Component} from "react";
import autobind from "autobind-decorator";
import * as _ from "lodash";
import classNames from "classnames";
import styles from "../styles/components/Avatar.scss";
import AvatarImage from "../assets/images/avatar.png";
import ForbiddenIcon from "../assets/images/forbidden.svg";
import Tooltip from "./Tooltip";
import {__} from "../core/utils";

class Avatar extends Component {
	@autobind
	_handleError(e) {
		e.target.src = AvatarImage;
	}

	render() {
		const {avatar_url, name} = this.props;
		const active = _.get(this.props, 'active', true);
		const size = this.props.size || 24;
		const tooltip = _.isUndefined(this.props.tooltip) ? true : this.props.tooltip;

		const Avatar = <div className={classNames(styles.wrapper, active == false && styles.deactivated, this.props.className)} style={{
			width: size + 'px',
			height: size + 'px',
		}}>
			<img src={avatar_url || AvatarImage} onError={this._handleError} loading="lazy"/>
			{active == false && <ForbiddenIcon/>}
		</div>;

		return tooltip ? (
			<Tooltip text={active == false ? (name + ' (' + __('avatar.deactivated') + ')') : name}>
				{Avatar}
			</Tooltip>
		) : Avatar;
	}
}

export default Avatar;