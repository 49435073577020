import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import styles from "../../styles/forms/add_part_to_product_wizard/AddPartToProductForm.scss";
import Button from "../../components/Button";
import { __, getLocalized, mapStateToProps, setTableParams } from "../../core/utils";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";
import { listPossibleProductAccessories } from "../../redux/actions/table/products";
import * as _ from "lodash";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({ form: "add_part_to_product_wizard.add_part_to_product" })
@mapStateToProps((state) => ({
	store: _.get(state.table, "products"),
	application_language_id: state.auth.language_id
}))
class AddAccessoryToProductForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_accessories: []
		};
	}

	componentDidMount() {
		listPossibleProductAccessories(this.props.store.flyout.id).then(({ response }) => {
			this.setState({ possible_accessories: response.data });
		});
	}

	render() {
		const possible_accessories = _.map(this.state.possible_accessories, (accessory) => {
			return {
				value: accessory.id,
				label: _.filter([accessory.model, getLocalized(accessory.description, this.props.application_language_id)], _.identity).join(" / ")
			};
		});

		return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				<div className={styles.form}>
					<Field name="product_ids" component={MultiSelect} searchable label={__("products.product-accessories-flyout.add-product-accessory.accessories")} options={possible_accessories} />
				</div>
				<div className={styles.footer}>
					<Button
						lightGray
						medium
						left
						middleText={__("button.cancel")}
						onClick={() =>
							setTableParams("products", {
								add_accessory_to_product_wizard: undefined
							})
						}
					/>
					<Button lightBlue medium right submit middleText={__("button.add")} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default AddAccessoryToProductForm;
