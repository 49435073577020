import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../../styles/forms/superadmin/add_language_wizard/AddLanguageForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import {__, mapStateToProps, setTableParams, transformToListPairs} from "../../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import MultiSelect from "../../../components/MultiSelect";
import Dropzone from "../../../components/Dropzone";
import Checkbox from "../../../components/Checkbox";

@withRouter
@reduxForm({form: "superadmin.add_language_wizard.add_language"})
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_language_wizard.add_language")(state),
    auth: state.auth,
}))
class AddLanguageForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="code" component={Input} label="Code" />
                    <Field name="name" component={Input} label="Name" />
                    <Field name="tesseract_name" component={Input} label="Tesseract (OCR) code" />
                    <Field name="application_language" component={Checkbox} label="Application language" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_languages', {
                        add_language_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddLanguageForm;
