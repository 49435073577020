import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/UnregisteredMeetingMemberWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, validateForm} from "../core/utils";
import AddUserIcon from "../assets/images/add-user-60x60.svg";
import UnregisteredMeetingMemberForm from "../forms/unregistered_meeting_member_wizard/UnregisteredMeetingMemberForm";
import {setNotification} from "../redux/actions/general/notification";
import {
    addUnregisteredMeetingFollower,
    addUnregisteredMeetingParticipant,
    updateUnregisteredMeetingFollower,
    updateUnregisteredMeetingParticipant
} from "../redux/actions/table/all_meetings";

@mapStateToProps(state => ({
    type: _.get(state.table, 'all_meetings.type'),
    edit_data: _.get(state.table, 'all_meetings.edit_data'),
    meeting_id: _.get(state.table, 'all_meetings.meeting_id')
}))
class UnregisteredMeetingMemberWizard extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    @autobind
    _handleSubmit(formData) {
        const {type, edit_data, meeting_id} = this.props;

        if(edit_data) {
            if(type == 'participant') {
                return validateForm(updateUnregisteredMeetingParticipant(edit_data.id, formData).then(() => {
                    setNotification(__('meetings.all-meetings.flyout.notification.participant-details-updated'));

                    this.props.onClose();
                }));
            }

            if(type == 'follower') {
                return validateForm(updateUnregisteredMeetingFollower(edit_data.id, formData).then(() => {
                    setNotification(__('meetings.all-meetings.flyout.notification.follower-details-updated'));

                    this.props.onClose();
                }));
            }
        } else {
            if(type == 'participant') {
                return validateForm(addUnregisteredMeetingParticipant(meeting_id, formData).then(() => {
                    setNotification(__('meetings.all-meetings.flyout.notification.participant-added-to-meeting'));

                    this.props.onClose();
                }));
            }

            if(type == 'follower') {
                return validateForm(addUnregisteredMeetingFollower(meeting_id, formData).then(() => {
                    setNotification(__('meetings.all-meetings.flyout.notification.follower-added-to-meeting'));

                    this.props.onClose();
                }));
            }
        }
    }

    render() {
        const {type, edit_data} = this.props;

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>
                            {type == 'participant' && __('meetings.all-meetings.flyout.unregistered-participant')}
                            {type == 'follower' && __('meetings.all-meetings.flyout.unregistered-follower')}
                        </span>
                        <span>
                            {type == 'participant' && __('meetings.all-meetings.flyout.unregistered-participants-distribution-lists-printed-reports')}
                            {type == 'follower' && __('meetings.all-meetings.flyout.unregistered-followers-distribution-lists-printed-reports')}
                        </span>
                    </div>
                    <div className={styles.right}>
                        <AddUserIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <UnregisteredMeetingMemberForm
                        onClose={this.props.onClose}
                        onSubmit={this._handleSubmit}
                        initialValues={edit_data ? _.pick(edit_data, ['fullname', 'profession', 'company', 'email']) : {}}
                    />
                </div>
            </>
        );
    }
}

export default UnregisteredMeetingMemberWizard;
