import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm, stopSubmit} from "redux-form";
import {withRouter} from "react-router";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import styles from "../../styles/forms/auth/LoginForm.scss";
import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import Checkbox from "../../components/Checkbox";
import {__, redirect} from "../../core/utils";

@reduxForm({
	form: "auth.login",
	onSubmitFail: ({ code }, dispatch) => {
		if (code === 1) {
			dispatch(stopSubmit("auth.login", { email: true, password: true }));
		}
	}
})
@withRouter
class LoginForm extends Component {
	render() {
		const {twoFactor} = this.props;

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.form}>
					{twoFactor ? (
						<>
							{__('two-factor-code.google-authenticator-app.on-your-device')}
							<div className={styles.code}>
								<Field name="code" center component={Input} label={__('profile-settings-security-two-factor-authentication.code')} hint={__('profile-settings-security-two-factor-authentication.6-digit-code')} numeric maxLength={6} />
							</div>
							{__('lost-device.cannot-access-to-account.contact-volum3-support-team')}
						</>
					) : (
						<>
							<Field name="email" component={Input} autoComplete={null} label={__('welcome-to-volum3.enter-your-email')} center />
							<Field name="password" component={PasswordInput} label={__('welcome-to-volum3.enter-your-password')} center />
							<div className={styles.options}>
								<Field name="remember" component={Checkbox} label={__('welcome-to-volum3.remember-me')} />
								<i className={styles.separator} />
								<div>
									<span>{__('welcome-to-volum3.forgot-password')}?</span>
									<a onClick={() => redirect("/forgot_password")}>{__('welcome-to-volum3.recover')}</a>
								</div>
								<i className={styles.separator} />
								<a onClick={() => redirect('/register')}>
									<strong>{__('welcome-to-volum3.register')}</strong>
								</a>
							</div>
						</>
					)}
				</div>
				<div className={styles.footer}>
					<Button submit center leftText={__('button.login')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default LoginForm;