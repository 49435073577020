import * as React from "react";
import { Component } from "react";
import * as _ from "lodash";
import { get } from "lodash";
import { createSidebar } from "../../redux/actions/general/sidebar";
import { enableProjectsDropdown, enableStagesDropdown, listActiveModules } from "../../redux/actions/general/active_modules";
import { __, dateTimeFrom, getActiveStage, redirect } from "../../core/utils";
import Table from "../../components/Table";
import { connect } from "react-redux";
import { listPublicDeliveries, readPublicDelivery } from "../../redux/actions/table/public_deliveries";
import PublicDeliveriesFlyout from "../../flyouts/public_deliveries/PublicDeliveriesFlyout";
import styles from "../../styles/views/plans/PublicDeliveries.scss";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import Tooltip from "../../components/Tooltip";
import { downloadDeliveryPublicPlans } from "../../redux/actions/table/delivery_plans";
import DownloadIcon from "../../assets/images/download-15x15.svg";

const mapStateToProps = (state) => {
	return {
		table: state.table["public_deliveries"]
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			setTableParams: (params) =>
				dispatch({
					type: "table.public_deliveries/SET_TABLE_PARAMS",
					params
				})
		}
	};
};

@connect(mapStateToProps, mapDispatchToProps)
class PublicDeliveries extends Component {
	componentDidMount() {
		enableProjectsDropdown(({ stage_id }) => redirect("/stages/" + stage_id + "/deliveries"));
		enableStagesDropdown(({ stage_id }) => redirect("/stages/" + stage_id + "/deliveries"));

		createSidebar({
			title: __("submenu.title.plans"),
			items: [
				{
					title: __("submenu.title.current-set"),
					link: () => "/stages/" + getActiveStage() + "/plans/current"
				},
				{
					title: __("submenu.title.working-set"),
					link: () => "/stages/" + getActiveStage() + "/plans/working"
				},
				{
					title: __("submenu.title.uploads"),
					link: () => "/stages/" + getActiveStage() + "/plans/uploads"
				},
				{
					title: __("submenu.title.deliveries"),
					link: () => "/stages/" + getActiveStage() + "/deliveries",
					active: true,
					items: [
						{
							title: __("submenu.title.deliveries.public"),
							link: () => "/stages/" + getActiveStage() + "/deliveries",
							active: true
						},
						{
							title: __("submenu.title.deliveries.my-team"),
							link: () => "/stages/" + getActiveStage() + "/deliveries/my"
						}
					]
				},
				{
					title: __("submenu.title.approvals"),
					link: () => "/stages/" + getActiveStage() + "/approvals"
				}
			]
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readPublicDelivery(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const columns = [
			{
				key: "title",
				name: __("plans.table.title.title"),
				required: true
			},
			{
				key: "plans",
				name: __("plans.deliveries.public.plans"),
				sortable: false,
				value: (plans, row) => (
					<div className={styles.plans} onClick={() => redirect("/stages/" + this.props.match.params.stage + "/deliveries/public/" + row.id)}>
						{plans}{" "}
						<Tooltip text={__("plans.tooltip.icon.deliveries.goto-plans")}>
							<ArrowDoubleRightSmallIcon />
						</Tooltip>
					</div>
				),
				width: 60
			},
			{
				key: "discipline",
				name: __("plans.deliveries.columns.disciplines"),
				value: (discipline, row) => {
					const prefixed_discipline = [row.discipline.code, row.discipline.id == null ? row.discipline.description + "*" : _.get(this.props.localization.disciplines, [row.discipline.id, "name"])].join(": ");

					return <Tooltip text={prefixed_discipline}>{prefixed_discipline}</Tooltip>;
				}
			},
			{
				key: "team",
				name: __("plans.deliveries.columns.team"),
				value: (team) => team.company
			},
			{
				key: "created_at",
				name: __("plans.deliveries.public.created-at"),
				default: true,
				desc: true,
				value: (value) => dateTimeFrom(value),
				width: 120
			},
			{
				key: "created_by",
				name: __("plans.table.title.added"),
				width: 110
			},
			{
				key: "delivered_at",
				name: __("plans.deliveries.columns.delivered-at"),
				value: (value) => dateTimeFrom(value),
				width: 105
			},
			{
				key: "delivered_to",
				name: __("plans.deliveries.columns.delivered-to"),
				value: (approvers) => _.join(approvers, ", "),
				sortable: false
			}
		];

		const preview = <PublicDeliveriesFlyout data={_.get(this.state, "preview", {})} />;

		const singleActions = (plan_delivery_id) => {
			return [
				<Tooltip text={__("plans.tooltip.icon.deliveries.plans-download")} key={_.uniqueId()}>
					<DownloadIcon
						onClick={(e) => {
							e.stopPropagation();

							downloadDeliveryPublicPlans(plan_delivery_id);
						}}
					/>
				</Tooltip>
			];
		};

		return <Table listAction={() => listPublicDeliveries(this.props.match.params.stage)} name="public_deliveries" title={__("submenu.title.deliveries.public")} columns={columns} preview={get(this.state, "preview") ? preview : []} onLoad={() => listActiveModules()} singleActions={singleActions} />;
	}
}

export default PublicDeliveries;
