import store from "../../../core/store";

export const setNotification = (content) => {
    return store.dispatch({
        type: "general.notifications/SET_NOTIFICATION",
        notification: typeof content === "string" ? {
            text: content
        } : {
            ...content
        }
    });
};

export const clearNotifications = () => {
    return store.dispatch({
        type: "general.notifications/CLEAR_NOTIFICATIONS"
    });
};