import store from "../../../core/store";

export const listBuildings = (stage_id) => {
    return store.dispatch({
        action: "table.buildings/LIST_BUILDINGS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/buildings",
        main: true,
    });
};

export const createBuilding = (stage_id, formData) => {
    return store.dispatch({
        action: "table.buildings/CREATE_BUILDING",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/buildings",
        data: formData
    });
};

export const readBuilding = (building_id) => {
    return store.dispatch({
        action: "table.buildings/READ_BUILDING",
        method: "GET",
        endpoint: "/buildings/" + building_id,
        has404: true
    });
};

export const updateBuilding = (building_id, formData) => {
    return store.dispatch({
        action: "table.buildings/UPDATE_BUILDING",
        method: "PUT",
        endpoint: "/buildings/" + building_id,
        data: formData
    });
};

export const deleteBuildings = (building_ids) => {
    return store.dispatch({
        action: "table.buildings/DELETE_BUILDINGS",
        method: "DELETE",
        endpoint: "/buildings",
        data: {
            buildings: building_ids
        }
    });
};

export const listPossibleSites = (stage_id) => {
    return store.dispatch({
        action: "table.buildings/LIST_POSSIBLE_SITES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_sites",
    });
};