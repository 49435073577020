import * as React from "react";
import { Component } from "react";
import styles from "../styles/wizards/AddProductAttributeGroupAsTemplateWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeGroupAddIcon from "../assets/images/product-attribute-group-add-60x60.svg";
import { __, mapStateToProps, setTableParams, validateForm } from "../core/utils";
import { setNotification } from "../redux/actions/general/notification";
import AddProductAttributeGroupForm from "../forms/add_product_attribute_group_form/AddProductAttributeGroupForm";
import { addProductAttributeGroup, listProductAttributeGroupFields, readProduct, saveProductAttributeGroupAsTemplate } from "../redux/actions/table/products";
import ProductAttributeGroupSaveIcon from "../assets/images/product-attribute-group-save-60x60.svg";
import AddProductAttributeGroupAsTemplateForm from "../forms/add_product_attribute_group_as_template_wizard/AddProductAttributeGroupAsTemplateForm";
import * as _ from "lodash";
import Localization from "../helpers/Localization";
import { subscribe } from "react-contextual";

@subscribe(Localization, "localization")
@mapStateToProps((state) => ({
	group_id: _.get(state, "table.products.save_product_attribute_group_as_template_wizard")
}))
class AddProductAttributeGroupAsTemplateWizard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: []
		};
	}

	componentDidMount() {
		listProductAttributeGroupFields(this.props.group_id).then(({ response }) => {
			this.setState({ fields: response.data });
		});
	}

	@autobind
	_handleSubmit(formData) {
		const data = {
			name: formData.name,
			fields: _.filter(_.keys(formData.fields), (field_id) => formData.fields[field_id] == true)
		};

		return validateForm(
			saveProductAttributeGroupAsTemplate(this.props.group_id, data).then(() => {
				setNotification("A product attribute group has been saved as a template.");

				this.props.onClose();
			})
		);
	}

	render() {
		const fields = _.filter(this.state.fields, (field) => !field.product_attribute_template_field_id || !this.props.localization.product_attribute_fields[field.product_attribute_template_field_id].discontinued);

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__("products.flyout.save-template.save-attribute-group-as-template")}</span>
						<span>{__("products.flyout.save-template.save-attribute-group-as-template-to-your-company-storage")}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeGroupSaveIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddProductAttributeGroupAsTemplateForm
						onSubmit={this._handleSubmit}
						fields={fields}
						initialValues={{
							fields: _.transform(
								fields,
								(fields, field) => {
									_.set(fields, field.id, 1);
								},
								{}
							)
						}}
					/>
				</div>
			</>
		);
	}
}

export default AddProductAttributeGroupAsTemplateWizard;
