import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import {mapStateToProps, getLocalized, __} from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import * as _ from "lodash";
import MultiSelect from "../../../components/MultiSelect";

@reduxForm({
    form: "superadmin_specification_subgroups.edit_specification_subgroup",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    application_language_id: state.auth.language_id,
}))
class EditSpecificationSubgroupForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        const specification_groups = _.map(this.props.localization.specification_groups, item => ({
			value: item.id,
			label: getLocalized(item.name, this.props.application_language_id),
        }));
        
        const boq_template_subgroups = _.map(this.props.localization.boq_template_subgroups, item => ({
			value: item.id,
			label: getLocalized(item.name, this.props.application_language_id),
		}));

        const product_subgroups = _.map(this.props.localization.product_subgroups, product_subgroup => {
            const product_group = _.get(this.props.localization.product_groups, product_subgroup.product_group_id, {});

            return {
                label: product_subgroup.name,
                value: product_subgroup.id,
                group: [_.get(this.props.localization.product_categories, [product_group.product_category_id, 'name']), product_group.name].join(' / '),
            };
        });

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_specification_subgroup} localized={application_languages} name="name" component={Input} label={__('specifications.edit-subgroup-form.name')} />
                <Field disabled={!superadmin.edit_specification_subgroup} numeric name="code" component={Input} label={__('specifications.edit-subgroup-form.code')} maxLength={1} />
                <Field disabled={!superadmin.edit_specification_subgroup} name="specification_group_id" component={Select} options={specification_groups} label={__('specifications.edit-subgroup-form.specification-group')} />
                <Field disabled={!superadmin.edit_specification_subgroup} name="boq_template_subgroup_id" component={Select} options={boq_template_subgroups} label={__('specifications.edit-subgroup-form.boq-template-subgroup')} />
                <Field disabled={!superadmin.edit_specification_subgroup} name="product_subgroups" component={MultiSelect} searchable options={product_subgroups} label={__('specifications.edit-subgroup-form.linked-product-subgroups')} />
            </Form>
        );
    }
}

export default EditSpecificationSubgroupForm;