import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import Textarea from "../../components/Textarea";
import {__, transformToListPairs} from "../../core/utils";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/sites/EditSiteForm.scss";
import Input from "../../components/Input";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import Select from "../../components/Select";

@reduxForm({
    form: "sites.edit_site",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
class EditSiteForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {data, handleSubmit, localization} = this.props;
        const {project_languages} = data;
        const {timezones} = localization;

        return (
            <Form onSubmit={handleSubmit}>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="code" component={Input} label={__('sites.field.code')} maxLength={6} />
                    </div>
                    <div className={styles.f2}>
                        <Field name="title" component={Input} localized={project_languages} label={__('sites.field.title')} maxLength={50} />
                    </div>
                </div>
                <Field name="description" component={Textarea} localized={project_languages} label={__('sites.field.description')} maxLength={2000} rows={1} maxRows={10} />
                <Field name="address" component={Textarea} label={__('sites.field.address')} maxLength={2000} rows={1} maxRows={10} />
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="latitude" component={Input} label={__('sites.field.latitude')} numeric decimal maxLength={11} decimals={8} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="longitude" component={Input} label={__('sites.field.longitude')} numeric decimal maxLength={12} decimals={9} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="altitude" component={Input} label={__('sites.field.altitude')} numeric decimal maxLength={8} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="timezone_id" component={Select} searchable label={__('sites.field.timezone')} options={transformToListPairs(timezones)} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="lot_number" component={Input} label={__('sites.field.lot-number')} maxLength={255} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="area" component={Input} label={__('sites.field.area')} numeric decimal maxLength={10} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="wind_zone" component={Input} localized={project_languages} label={__('sites.field.wind-zone')} maxLength={100} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="earthquake_zone" component={Input} localized={project_languages} label={__('sites.field.earthquake-zone')} maxLength={100} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="salt_exposure" component={Input} localized={project_languages} label={__('sites.field.salt-exposure')} maxLength={100} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="snow_loading_zone" component={Input} localized={project_languages} label={__('sites.field.snow-loading-zone')} maxLength={100} />
                    </div>
                </div>
            </Form>
        );
    }
}

export default EditSiteForm;
