import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/copy_boq_item_wizard/CopyBOQItemForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import Textarea from '../../components/Textarea';
import RadioGroup from '../../components/RadioGroup';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'copy_boq_item_wizard.copy_boq_item' })
@mapStateToProps((state) => ({
  store: state.table['working_set_boq'],
}))
class CopyBOQItemForm extends Component {
  render() {
    const { level } = this.props.store.copy_boq_item_wizard;
    const { selectFieldName, options } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='name'
            component={Textarea}
            disabled
            label={__('copy-boq-item.name')}
          />

          <Field
            name='move_action'
            component={RadioGroup}
            options={[
              {
                label: __('copy-boq-item.move-before'),
                value: 'before',
              },
              {
                label: __('copy-boq-item.move-after'),
                value: 'after',
              },
            ]}
          />

          <Field
            name={selectFieldName}
            component={Select}
            searchable
            noSort
            label={__('copy-boq-item.selected-item')}
            options={options}
            nestedList
            level={level}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_boq', {
                copy_boq_item_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.copy')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default CopyBOQItemForm;
