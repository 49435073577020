import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/SendMeetingReportWizard.scss";
import autobind from "autobind-decorator";
import MessageSendIcon from "../assets/images/message-send-60x60.svg";
import {__, dateFrom, durationToString, mapStateToProps, resetForm, timeFrom, validateForm} from "../core/utils";
import {readMeetingPrintDetails, sendMeetingReport} from "../redux/actions/table/all_meetings";
import * as _ from "lodash";
import moment from "moment";
import SendMeetingReportForm from "../forms/send_meeting_report_wizard/SendMeetingReportForm";
import {setNotification} from "../redux/actions/general/notification";

@mapStateToProps(state => ({
	meeting_id: _.get(state.table, 'all_meetings.meeting_id'),
	print_config: _.get(state.table, 'all_meetings.print_config'),
}))
class SendMeetingReportWizard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			meeting: {}
		};
	}

	@autobind
	_handleSubmit(formData) {
		const {meeting_id, print_config} = this.props;
		const {meeting} = this.state;

		return validateForm(sendMeetingReport(meeting_id, {
			...formData,
			header: 1,
			footer: 1,
			legend: 1,
			details: 1,
			description: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			attachments: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			qr_code: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			comments: _.toInteger(_.includes(['review'], meeting.status)),
			footnote: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_type: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_id: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_priority: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_status: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_due_date: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_assignee: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting.status)),
			column_startend: _.toInteger(_.includes(['agenda'], meeting.status)),
			column_duration: _.toInteger(_.includes(['agenda'], meeting.status)),
			...print_config
		}).then(() => {
			setNotification('A meeting report will be sent shortly.');

			this.props.onClose();
			resetForm('send_meeting_report_wizard');
			resetForm('print_meeting_report_wizard');
		}));
	}

	componentDidMount() {
		const {meeting_id} = this.props;

		readMeetingPrintDetails(meeting_id).then(({response}) => {
			this.setState({meeting: response.data});
		});
	}

	render() {
		const {meeting} = this.state;
		const start_time = moment.utc(meeting.date).local();
		const end_time = moment.utc(meeting.date).local().add(meeting.duration, 'minutes');
		const calculated_time = durationToString(moment.duration(end_time.clone().diff(start_time)), true, '0h');

		let subject = 'M-' + _.padStart(meeting.identifier, 4, "0") + ': ' + _.get(meeting.title, _.findKey(meeting.project_languages, primary => primary == 1)) + ' - Meeting ';
		let message = _.get(meeting.description, _.findKey(meeting.project_languages, primary => primary == 1));

		switch(meeting.status) {
			case 'minutes':
				subject = subject + 'Minutes';
				break;
			case 'agenda':
				subject = subject + 'Agenda';
				break;
			case 'review':
				subject = subject + 'Review';
				break;
			case 'closed':
				subject = subject + 'Closed';
				break;
			case 'canceled':
				subject = subject + 'Canceled';
				break;
		}

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('meetings.all-meetings.send-meeting')} {meeting.status == 'agenda' ? __('meetings.all-meetings.send-meeting.agenda') : __('meetings.all-meetings.send-meeting.minutes')}</span>
						<span>{__('meetings.send-meeting.notify-participants-changes-in-meeting')}</span>
					</div>
					<div className={styles.right}>
						<MessageSendIcon />
					</div>
				</div>
				<div className={styles.body}>
					<div className={styles.title}>
						<strong>{'M-' + _.padStart(meeting.identifier, 4, "0")}: </strong>
						{_.get(meeting.title, _.findKey(meeting.project_languages, primary => primary == 1))}
					</div>
					<div className={styles.event}>
						<span>{dateFrom(meeting.date)} &middot; {timeFrom(start_time)} - {timeFrom(end_time)} &middot; {calculated_time}</span>
						<span>{_.filter([meeting.location_name, meeting.location_address], _.identity).join(' · ')}</span>
					</div>
					{!_.isEmpty(meeting.recipients) && <div className={styles.recipients}>
						<strong>{__('meetings.all-meetings.seng-meeting.recipients')}</strong>
						<div className={styles.emails}>
							{_.join(meeting.recipients, ', ')}
						</div>
					</div>}
					{!_.isEmpty(meeting) && <SendMeetingReportForm meeting={meeting} onSubmit={this._handleSubmit} initialValues={{
						subject,
						message
					}} />}
				</div>

			</>
		);
	}
}

export default SendMeetingReportWizard;
