import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import {get, groupBy, map} from "lodash";
import Table from "../../components/Table";
import {__, dateTimeFrom, getLocalizedListValue} from "../../core/utils";
import AddCompanyIcon from "../../assets/images/add-company-15x15.svg";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import styles from "../../styles/views/companies_and_users/AllCompanies.scss";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import CompanySizeIcon from "../../assets/images/company-size-15x15.svg";
import PhoneIcon from "../../assets/images/phone-15x15.svg";
import LocationPinIcon from "../../assets/images/location-pin-15x15.svg";
import MessageBigIcon from "../../assets/images/message-big-15x15.svg";
import InfoPositiveIcon from "../../assets/images/info-positive-16x16.svg";
import UserMaleIcon from "../../assets/images/user-male-15x15.svg";
import {readPublicCompany} from "../../redux/reducers/companies";
import Image from "../../components/Image";
import {setNotification} from "../../redux/actions/general/notification";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {addCompaniesToPartners, listAllCompanies} from "../../redux/actions/table/all_companies";
import classNames from "classnames";
import DefaultImage from "../../assets/images/default.png";
import Tooltip from "../../components/Tooltip";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import TableNA from "../../components/TableNA";

const mapStateToProps = state => {
	return {
		table: state.table["all_companies"],
		auth: state.auth
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: {
			...bindActionCreators(
				{
					readPublicCompany,
				},
				dispatch
			),
			setTableParams: params =>
				dispatch({
					type: "table.all_companies/SET_TABLE_PARAMS",
					params
				})
		}
	};
};
@connect(
	mapStateToProps,
	mapDispatchToProps
)
class AllCompanies extends Component {
	componentDidMount() {
		listActiveModules();
		
        createSidebar({
            title: __('companies-and-users.all-companies.submenu.companies-users'),
			items: [
				{
					title: __('companies-and-users.all-companies.submenu.companies'),
					active: true,
					items: [
						{
							title: __('companies-and-users.all-companies.submenu.all-companies'),
							link: () => "/companies",
							active: true,
						},
						{
							title: __('companies-and-users.all-companies.submenu.partner-companies'),
							link: () => "/companies/partner",
						},
					]
				}, {
					title: __('companies-and-users.all-companies.submenu.users'),
					link: () => "/companies/my/members",
					items: [
						{
							title: __('companies-and-users.all-companies.submenu.partner-members'),
							link: () => "/companies/partner_members"
						},
						{
							title: __('companies-and-users.all-companies.submenu.my.company-members'),
							link: () => "/companies/my/members",
						}
					]
				}, {
					title: __('modules.address-book'),
					link: () => "/address_book/companies",
					items: [
						{
							title: __('modules.address-book.companies'),
							link: () => "/address_book/companies",
						},
						{
							title: __('modules.address-book.contacts'),
							link: () => "/address_book/contacts",
						},
					]
				}
			]
        });

        // _.includes(["administrator", "owner"], this.props.auth.role) && setPlusActions([{
        //     title: 'Invite New User',
        //     onClick: () => this.props.actions.setTableParams({
        //         wizard_active: true
        //     })
        // }]);
		//TODO:URGENT - implement this
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			this.props.actions.readPublicCompany(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const columns = [
			{
				key: "name",
				name: __('all-companies.column.name'),
				required: true,
				width: 250
			},
			{
				key: "disciplines",
				name: __('all-companies.column.disciplines'),
				value: disciplines => _.map(disciplines, discipline => getLocalizedListValue(this.props.localization.disciplines, discipline)).join(", "),
				sortable: false
			},
			{
                key: "is_partner",
                name: __('all-companies.column.partner'),
				sortable: false,
				value: (is_partner) => (
                    <div className={classNames(styles.partner, is_partner && styles.yes)}>{is_partner ? __('general.alert.yes') : __('general.alert.no')}</div>
                ),
                width: 100
			}, {
				key: "created_at",
				name: __('all-companies.column.created-at'),
				value: value => value ? dateTimeFrom(value) : <TableNA />,
				width: 120,
				required: true,
				default: true,
				desc: true,
			}
		];

		const singleActions = (company) => [
			<Tooltip text={__('companies.tooltips.add-company')} key={_.uniqueId()}><AddCompanyIcon
				onClick={e => {
					e.stopPropagation();

					addCompaniesToPartners([company]).then(() => {
						setNotification(__('companies-and-users.all-companies.notifications.company-successfully-added-partner-list'));
                        listAllCompanies();

						this.props.actions.setTableParams({
							actioned_row: undefined
						});
					});
				}}
			/></Tooltip>
		];

		const groupActions = (companies) => [
			<Tooltip text={__('companies.tooltips.add-company')} key={_.uniqueId()}><AddCompanyIcon
				onClick={e => {
					e.stopPropagation();

					addCompaniesToPartners(companies).then(() => {
						setNotification(__('companies-and-users.all-companies.notifications.selected-companies-successfully-added-partner-list'));
                        listAllCompanies();

						this.props.actions.setTableParams({
							selected_rows: []
						});
					});
				}}
			/></Tooltip>
		];

		const preview = (
			<div className={styles.preview}>
				<div className={styles.header}>
					<div className={styles.title}>
						{get(this.state, "preview.name")}
						<CloseBigIcon
							onClick={() =>
								this.props.actions.setTableParams({
									clicked_row: undefined
								})
							}
						/>
					</div>
					<div className={styles.actions}>
						<div className={styles.actionWrapper}>
							<Tooltip text={__('companies.tooltips.add-company')}><AddCompanyIcon
								onClick={() => {
									addCompaniesToPartners([get(this.state, "preview.id")]).then(() => {
										setNotification(__('companies-and-users.all-companies.notifications.company-successfully-added-partner-list'));

										listAllCompanies();
									});
								}}
							/></Tooltip>
						</div>
					</div>
				</div>
				<div className={styles.scroller}>
					<div className={styles.details}>
						<div className={styles.info}>
							<div>
								<span>
									<CompanySizeIcon /> {__('companies-and-users.all-companies.size')}
								</span>
								{get(this.state, "preview.size_id") && <span>
									{_.upperFirst(getLocalizedListValue(this.props.localization.company_sizes, get(this.state, "preview.size_id")))}:{" "}
									{getLocalizedListValue(this.props.localization.company_sizes, get(this.state, "preview.size_id"), "size")} {__("employees")}
								</span>}
							</div>
							<div>
								<span>
									<LocationPinIcon /> {__('companies-and-users.all-companies.location')}
								</span>
								<span>
									{get(this.state, "preview.office.address")}
									<br />
									{get(this.state, "preview.office.state") && get(this.state, "preview.office.state") + ", "}
									{get(this.state, "preview.office.zip")}, {get(this.state, "preview.office.city")}
									<br />
									{getLocalizedListValue(this.props.localization.countries, get(this.state, "preview.office.country_id"))}
								</span>
							</div>
							<div>
								<span>
									<PhoneIcon /> {__('companies-and-users.all-companies.phone')}
								</span>
								<span>{get(this.state, "preview.office.phones.0")}</span>
							</div>
							<div>
								<span>
									<MessageBigIcon /> {__('companies-and-users.all-companies.email')}
								</span>
								<span>{get(this.state, "preview.office.emails.0")}</span>
							</div>
							<div>
								<span>
									<InfoPositiveIcon className='' /> {__('companies-and-users.all-companies.vat-nr')}
								</span>
								<span>{get(this.state, "preview.vat")}</span>
							</div>
							<div>
								<span>
									<UserMaleIcon /> {__('companies.account.owner')}
								</span>
								<span>{get(this.state, "preview.owner")}</span>
							</div>
						</div>
						<div className={styles.logo}>
							<Image src={get(this.state, "preview.logo_url")} default={DefaultImage} />
						</div>
					</div>
					{(get(this.state, "preview.description_long") || get(this.state, "preview.description")) && (
						<div className={styles.content}>
							<div className={styles.title}>{__('companies-and-users.partner-companies.description')}</div>
							<p>{get(this.state, "preview.description_long") || get(this.state, "preview.description")}</p>
						</div>
					)}
					{!_.isEmpty(get(this.state, "preview.disciplines")) && <div className={styles.content}>
						<div className={styles.title}>{__('companies-and-users.partner-companies.disciplines')}</div>
						<p>{map(get(this.state, "preview.disciplines"), discipline => getLocalizedListValue(this.props.localization.disciplines, discipline)).join(", ")}</p>
					</div>}
                    {!_.isEmpty(get(this.state, "preview.market")) && <div className={styles.content}>
						<div className={styles.title}>{__('companies-and-users.partner-companies.market')}</div>
						{map(groupBy(get(this.state, "preview.market"), country_id => getLocalizedListValue(this.props.localization.countries, country_id, "region_id")), (countries, region_id) => (
							<p key={region_id}>
								<strong>{getLocalizedListValue(this.props.localization.regions, region_id)}:</strong>{" "}
								{map(countries, country_id => getLocalizedListValue(this.props.localization.countries, country_id)).join(", ")}
							</p>
						))}
					</div>}
				</div>
			</div>
		);

        const gridOptions = {
            section: __('companies-and-users.all-companies.companies'),
            image: (id, row) => row.logo_url,
            title: (row) => row.name,
			rightTitle: (row) => row.is_partner ? <span className={styles.isPartner}>Partner</span> : undefined,
            subTitle: (row) => getLocalizedListValue(this.props.localization.countries, row.country_id),
			content: (row) => [] //TODO
        };

		return (
			<Table
				listAction={listAllCompanies}
				singleActions={_.includes(["administrator", "owner"], this.props.auth.role) && singleActions}
				groupActions={_.includes(["administrator", "owner"], this.props.auth.role) && groupActions}
				name="all_companies"
				title={__('companies-and-users.all-companies.submenu.all-companies')}
				columns={columns}
				gridOptions={gridOptions}
				preview={get(this.state, "preview") ? preview : []}
                defaultView="grid"
			/>
		);
	}
}

export default AllCompanies;
