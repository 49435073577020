import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/entrances/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import {listEntrances, updateEntrance} from "../../redux/actions/table/entrances";
import EditEntranceForm from "../../forms/entrances/EditEntranceForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateEntrance(formData.id, _.omit(formData, ['id'])).then(() => {
                listEntrances(this.props.match.params.stage);

                this.props.readAction(formData.id);

                setNotification(__('entrances.flyout.notification.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditEntranceForm
                    onSubmit={this._handleSubmit}
                    data={data}
                    initialValues={{
                        ..._.pick(data, ['id', 'title', 'description']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
