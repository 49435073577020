import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import Table from '../../components/Table';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import {
  deleteRoomTypes,
  listRoomTypes,
  readRoomType,
} from '../../redux/actions/table/room_types';
import RoomTypesFlyout from '../../flyouts/room_types/RoomTypesFlyout';
import TableNA from '../../components/TableNA';
import classNames from 'classnames';
import styles from '../../styles/views/space_allocation/Entrances.scss';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import Avatar from '../../components/Avatar';

@mapStateToProps((state) => ({
  table: state.table['room_types'],
}))
class RoomTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('space-allocation.room-types.table.button.create'),
            onClick: () =>
              setTableParams('room_types', {
                add_room_type_wizard: true,
              }),
          },
        ]);
    });

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/room_types')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/room_types')
    );

    createSidebar({
      title: __('submenu.title.space-allocation'),
      items: [
        {
          title: __('submenu.title.space-allocation.rooms'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/rooms',
        },
        {
          title: __('submenu.title.space-allocation.room-types'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_types',
          active: true,
        },
        {
          title: __('submenu.title.space-allocation.room-groups'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_groups',
        },
        {
          title: __('submenu.title.space-allocation.zones'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/zones',
        },
        {
          title: __('submenu.title.space-allocation.entrances'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/entrances',
        },
        {
          title: __('submenu.title.space-allocation.expansion-joints'),
          link: () =>
            '/stages/' +
            getActiveStage() +
            '/space_allocation/expansion_joints',
        },
        {
          title: __('submenu.title.space-allocation.storeys'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/storeys',
        },
        {
          title: __('submenu.title.space-allocation.buildings'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/buildings',
        },
        {
          title: __('submenu.title.space-allocation.sites'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/sites',
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readRoomType(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('room-types.field.code'),
        default: true,
        value: (code) => code || <TableNA />,
        width: 100,
      },
      {
        key: 'title',
        name: __('room-types.field.title'),
        required: true,
        sortable: false,
      },
      {
        key: 'rooms',
        name: __('room-types.field.rooms'),
        value: (rooms, row) => (
          <div
            className={classNames(styles.rooms)}
            onClick={() => {
              setTableParams(
                'rooms',
                _.transform(
                  [
                    'filter.site',
                    'filter.building',
                    'filter.storey',
                    'filter.expansion_joint',
                    'filter.entrance',
                    'filter.zone',
                    'filter.functional_space',
                    'filter.foh',
                    'filter.outdoor',
                    'filter.room_group',
                    'filter.room_type',
                    'filter.security',
                    'filter.date',
                    'filter.creator',
                    'query',
                  ],
                  (filters, filter) => {
                    filters[filter] = '';

                    return filters;
                  },
                  {}
                )
              );

              redirect(
                '/stages/' +
                  this.props.match.params.stage +
                  '/space_allocation/rooms?filter.room_type=' +
                  row.id
              );
            }}
          >
            {rooms}
            <ArrowDoubleRightSmallIcon />
          </div>
        ),
        width: 60,
        sortable: false,
      },
      {
        key: 'created_at',
        name: __('room-types.field.created-at'),
        value: (created_at) => (
          <Tooltip text={dateTimeFrom(created_at)}>
            {dateFrom(created_at)}
          </Tooltip>
        ),
        width: 80,
      },
      {
        key: 'created_by',
        name: __('room-types.field.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
    ];

    const singleActions = (room_type_id) => {
      return [
        <Tooltip
          text={__('room-types.tooltip.room-type-properties')}
          key={_.uniqueId()}
        >
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('room_types', {
                clicked_row: room_type_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>,
        <Tooltip
          text={__('room-types.tooltip.delete-room-type')}
          key={_.uniqueId()}
        >
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description: __('room-types.alert.delete-confirmation'),
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteRoomTypes([room_type_id]).then(() => {
                        hideAlertbox();

                        setNotification(__('room-types.alert.deleted'));
                        listRoomTypes(this.props.match.params.stage);

                        setTableParams('room_types', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (room_types_ids) => [
      <Tooltip
        text={__('room-types.tooltip.delete-selected-room-types')}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __('room-types.alert.multiple-delete-confirmation'),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteRoomTypes(room_types_ids).then(() => {
                      hideAlertbox();

                      setNotification(__('room-types.alert.multiple-deleted'));
                      listRoomTypes(this.props.match.params.stage);

                      setTableParams('room_types', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <RoomTypesFlyout
        data={this.state.preview}
        readAction={(room_type_id) => {
          return new Promise((resolve) => {
            readRoomType(getURLParam('id') || room_type_id).then(
              ({ response }) => {
                this.setState({ preview: response.data });

                resolve(response);
              }
            );
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listRoomTypes(this.props.match.params.stage)}
        name='room_types'
        title={__('submenu.title.space-allocation.room-types')}
        singleActions={singleActions}
        groupActions={groupActions}
        columns={columns}
        preview={this.state.preview ? preview : []}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default RoomTypes;
