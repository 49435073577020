import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm, getFormValues } from 'redux-form';
import styles from '../../../styles/forms/products/read_only/AttributesForm.scss';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import * as _ from 'lodash';
import Select from '../../../components/Select';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  formatUrlToOpenExternalLink,
} from '../../../core/utils';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import Tooltip from '../../../components/Tooltip';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';
import {
  listMyCompanyColors,
  listMyCompanyMaterials,
} from '../../../redux/actions/companies';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import Image from '../../../components/Image';
import {
  changeAttributeThumbnail,
  readProduct,
} from '../../../redux/actions/table/products';
import GlobeIcon from '../../../assets/images/globe-16x16.svg';

@reduxForm({
  form: 'products.read_only.attributes',
  enableReinitialize: true,
})
@mapStateToProps((state, props) => ({
  store: state.table.products,
  application_language_id: state.auth.language_id,
  values: getFormValues(props.form)(state),
}))
@subscribe(Localization, 'localization')
class AttributesForm extends Component {
  state = {
    collapsed_groups: [],
    my_company_colors: [],
    my_company_materials: [],
  };

  componentDidMount() {
    listMyCompanyColors().then(({ response }) => {
      this.setState({ my_company_colors: response.data });
    });

    listMyCompanyMaterials().then(({ response }) => {
      this.setState({ my_company_materials: response.data });
    });
  }

  @autobind
  _toggleCollapseGroup(group) {
    this.setState({
      collapsed_groups: _.xor(this.state.collapsed_groups, [group]),
    });
  }

  _getColorNameAndHex = (id) => {
    const color =
      _.get(this.props.localization.colors, [id]) ||
      _.find(this.state.my_company_colors, (color) => color.id == id);

    return {
      label:
        (color?.name &&
          getLocalized(color?.name, this.props.store.language_id)) +
        (color?.code ? ` · ${color.code}` : ''),
      hex: color?.hex,
    };
  };

  _getMaterialNameAndThumbnailUrl = (id) => {
    const material =
      _.get(this.props.localization.materials, [id]) ||
      _.find(this.state.my_company_materials, (material) => material.id == id);

    return {
      label:
        material?.name &&
        getLocalized(material?.name, this.props.store.language_id),
      thumbnail_url: material?.thumbnail_url,
      preview_url: material?.preview_url,
    };
  };

  _getTextureNameAndThumbnailUrl = (id) => {
    const texture = _.get(this.props.localization.textures, id) || {};

    return {
      label: [
        texture.code,
        getLocalized(texture.name, this.props.store.language_id),
      ].join(' '),
      thumbnail_url: texture?.thumbnail_url,
      preview_url: texture?.preview_url,
    };
  };

  @autobind
  _handleFieldChange(field, value, use_as_thumbnail) {
    const { flyout } = this.props.store;
    const { values } = this.props;

    if (
      !_.isEmpty(
        _.reject(flyout.variants, (variant) => _.isEmpty(variant.values))
      )
    ) {
      //get possible variants based on the selected option
      const possible_variants = _.filter(
        flyout.variants,
        (variant) => _.get(variant.values, field) == value
      );

      //find the matched variant based on the most similar selected values
      //also, if the current selected value is not in the variant attributes, show all attributes
      const matched_variant = _.sortBy(
        _.isEmpty(possible_variants) ? flyout.variants : possible_variants,
        (variant) => {
          const matched_values = _.reduce(
            variant.values,
            (sum, value, key) => {
              _.get(values, `field-${key}`) == value && sum++;

              return sum;
            },
            0
          );

          return -matched_values;
        }
      );

      //set all attributes to the matched variant
      _.each(matched_variant[0].values, (value, key) =>
        this.props.change(`field-${key}`, value)
      );
    }

    if (use_as_thumbnail) {
      changeAttributeThumbnail(field, { id: value }).then(() =>
        readProduct(flyout.id).then(({ response }) => {
          setTableParams('products', {
            flyout: response.data,
          });
        })
      );
    }
  }

  render() {
    const {
      collapsed,
      store,
      application_language_id,
      handleSubmit,
      attribute_groups,
    } = this.props;
    const { flyout, language_id } = store;
    const { collapsed_groups } = this.state;

    const { units, product_attribute_fields } = this.props.localization;

    const attribute_variants = _.transform(
      flyout.variants,
      (attribute_variants, variant) => {
        _.each(variant.values, (value_id, attribute_id) => {
          if (_.has(attribute_variants, attribute_id)) {
            !_.includes(attribute_variants[attribute_id], value_id) &&
              attribute_variants[attribute_id].push(value_id);
          } else {
            attribute_variants[attribute_id] = [value_id];
          }
        });

        return attribute_variants;
      },
      {}
    );

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.map(attribute_groups, (group) => {
          const attributes = _.sortBy(
            _.reject(group.attributes, (attribute) => {
              let custom_variations = _.map(
                _.reject(
                  attribute.values,
                  (value) => _.size(value.value) === 0 || !value.in_use
                ),
                (value) => true
              );

              const part_variations = _.transform(
                attribute.parts,
                (variations, part) =>
                  _.each(part.values, (value) => {
                    if (
                      _.size(value.value) > 0 &&
                      value.in_use &&
                      value.inherit_in_use
                    ) {
                      variations.push({
                        id: value.id,
                        value: value.value,
                        unit_id: part.unit_id,
                      });
                    }
                  }),
                []
              );

              const values = [...custom_variations, ...part_variations];

              return _.isEmpty(values);
            }),
            'priority'
          );

          return _.isEmpty(attributes) ? (
            ''
          ) : (
            <div className={styles.group} key={group.id}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseGroup(group.id)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_groups, group.id) && styles.collapsed
                  )}
                />
                {getLocalized(group.name, application_language_id)}
              </div>
              <div
                className={classNames(
                  styles.attributes,
                  !_.includes(collapsed_groups, group.id) && styles.expanded
                )}
              >
                {_.map(attributes, (attribute, i) => {
                  let label = '',
                    type,
                    labelTooltip;

                  if (attribute.template_field_id) {
                    const original_attribute = _.get(
                      product_attribute_fields,
                      attribute.template_field_id,
                      {}
                    );

                    type = original_attribute.type;

                    if (original_attribute.description) {
                      labelTooltip = getLocalized(
                        original_attribute.description,
                        application_language_id
                      );
                    }

                    label += getLocalized(
                      original_attribute.label,
                      application_language_id
                    );
                  } else {
                    label += getLocalized(
                      attribute.label,
                      application_language_id
                    );

                    type = attribute.type;

                    if (attribute.description) {
                      labelTooltip = getLocalized(
                        attribute.description,
                        application_language_id
                      );
                    }
                  }

                  let custom_variations = _.map(
                    _.reject(
                      attribute.values,
                      (value) => _.size(value.value) === 0 || !value.in_use
                    ),
                    (value) => ({
                      id: value.id,
                      value: value.value,
                      unit_id: attribute.unit_id,
                    })
                  );

                  const part_variations = _.transform(
                    attribute.parts,
                    (variations, part) => {
                      _.each(part.values, (value) => {
                        if (
                          _.size(value.value) > 0 &&
                          value.in_use &&
                          value.inherit_in_use
                        ) {
                          let label = '';

                          if (part.template_field_id) {
                            const original_attribute = _.get(
                              product_attribute_fields,
                              part.template_field_id,
                              {}
                            );

                            label += getLocalized(
                              original_attribute.label,
                              application_language_id
                            );
                          } else {
                            label += getLocalized(
                              part.label,
                              application_language_id
                            );
                          }

                          let group_name = `${part.product.model} by ${part.product.brand}`; //${'\u00b7'} ${label}

                          variations.push({
                            id: value.id,
                            value: value.value,
                            unit_id: part.unit_id,
                            model: part.product.model,
                            group_name: group_name,
                          });
                        }
                      });
                    },
                    []
                  );

                  const values = [...custom_variations, ...part_variations];

                  const possible_variant_values =
                    !_.isEmpty(
                      _.reject(flyout.variants, (variant) =>
                        _.isEmpty(variant.values)
                      )
                    ) && _.includes(flyout.variant_attributes, attribute.id)
                      ? _.filter(values, (value) =>
                          _.includes(
                            _.get(attribute_variants, attribute.id, []),
                            value.id
                          )
                        )
                      : values;

                  const options = _.map(
                    possible_variant_values,
                    ({ id, value, unit_id, group_name }) => {
                      const option = {
                        value: id,
                      };

                      let unit = '';

                      if (
                        _.includes(
                          [
                            'numeric',
                            'dropdown_numeric',
                            'dropdown_numeric_multiple',
                            'range',
                            'range_selectable',
                          ],
                          type
                        ) &&
                        unit_id
                      ) {
                        unit = ' ' + _.get(units, [unit_id, 'symbol']);
                      }

                      switch (type) {
                        case 'boolean':
                          option.label = _.toInteger(value)
                            ? __('products.attributes-form.boolean.yes')
                            : __('products.attributes-form.boolean.no');
                          break;
                        case 'dropdown_string_multiple':
                          option.label = _.map(value, (one_value) => {
                            return getLocalized(
                              _.get(
                                _.find(
                                  _.flatten(
                                    _.map(
                                      this.props.localization
                                        .product_attribute_fields,
                                      'options'
                                    )
                                  ),
                                  ['id', _.parseInt(one_value)]
                                ),
                                'value'
                              ),
                              language_id
                            );
                          }).join(', ');
                          break;
                        case 'dropdown_numeric_multiple':
                          option.label = _.map(
                            JSON.parse(value),
                            (one_value) => {
                              return (
                                getLocalized(
                                  _.get(
                                    _.find(
                                      _.flatten(
                                        _.map(
                                          this.props.localization
                                            .product_attribute_fields,
                                          'options'
                                        )
                                      ),
                                      ['id', _.parseInt(one_value)]
                                    ),
                                    'value'
                                  ),
                                  language_id
                                ) + unit
                              );
                            }
                          ).join(', ');
                          break;
                        case 'dropdown_string':
                          option.label = getLocalized(
                            _.get(
                              _.find(
                                _.flatten(
                                  _.map(
                                    this.props.localization
                                      .product_attribute_fields,
                                    'options'
                                  )
                                ),
                                ['id', _.parseInt(value)]
                              ),
                              'value'
                            ),
                            language_id
                          );
                          break;
                        case 'dropdown_numeric':
                          option.label =
                            getLocalized(
                              _.get(
                                _.find(
                                  _.flatten(
                                    _.map(
                                      this.props.localization
                                        .product_attribute_fields,
                                      'options'
                                    )
                                  ),
                                  ['id', _.parseInt(value)]
                                ),
                                'value'
                              ),
                              language_id
                            ) + unit;
                          break;
                        case 'string':
                          option.label = getLocalized(value, language_id);
                          break;
                        case 'numeric':
                          option.label = value + unit;
                          break;
                        case 'range':
                          option.label = _.join(value, ' ~ ') + unit;
                          break;
                        case 'range_selectable':
                          option.label = _.join(value, ' ~ ') + unit;
                          break;
                        case 'color':
                          const color = this._getColorNameAndHex(value) || {};

                          option.label = color.label;
                          option.hex = color.hex;

                          option.icon = (
                            <Tooltip
                              placement='left'
                              text={
                                <span
                                  className={styles.thumbPreview}
                                  style={{
                                    background: '#' + color.hex,
                                  }}
                                />
                              }
                              html={true}
                              dark={true}
                            >
                              <span
                                className={styles.colorThumb}
                                style={{
                                  background: '#' + color.hex,
                                }}
                              />
                            </Tooltip>
                          );

                          break;
                        case 'material':
                          const material =
                            this._getMaterialNameAndThumbnailUrl(value) || {};

                          option.label = material.label;
                          option.thumbnail_url = material.thumbnail_url;
                          option.preview_url = material.preview_url;

                          option.icon = (
                            <Tooltip
                              placement='left'
                              text={
                                <Image
                                  className={styles.thumbPreview}
                                  src={material?.preview_url}
                                />
                              }
                              html={true}
                              dark={true}
                            >
                              <Image
                                className={styles.materialThumb}
                                src={material?.thumbnail_url}
                              />
                            </Tooltip>
                          );
                          break;
                        case 'texture':
                          const texture =
                            this._getTextureNameAndThumbnailUrl(value) || {};

                          option.label = texture.label;
                          option.thumbnail_url = texture.thumbnail_url;
                          option.preview_url = texture.preview_url;

                          option.icon = (
                            <Tooltip
                              placement='left'
                              text={
                                <Image
                                  className={styles.thumbPreview}
                                  src={texture?.preview_url}
                                />
                              }
                              html={true}
                              dark={true}
                            >
                              <Image
                                className={styles.textureThumb}
                                src={texture?.thumbnail_url}
                              />
                            </Tooltip>
                          );

                          break;
                      }

                      if (group_name) {
                        option.group = group_name;
                      }

                      if (
                        _.includes(
                          _.get(attribute_variants, attribute.id, []),
                          id
                        ) &&
                        _.includes(
                          _.map(flyout.variants, (item) => {
                            return _.isEqual(
                              item.values,
                              _.transform(
                                flyout.variants,
                                (attribute_variants, variant) => {
                                  _.each(
                                    variant.values,
                                    (value_id, attribute_id) => {
                                      if (attribute_id == attribute.id) {
                                        attribute_variants[attribute_id] = id;
                                      } else {
                                        attribute_variants[attribute_id] =
                                          this.props.values[
                                            'field-' + attribute_id
                                          ];
                                      }
                                    }
                                  );

                                  return attribute_variants;
                                },
                                {}
                              )
                            );
                          }),
                          true
                        )
                      ) {
                        option.markedValue = true;
                      }

                      return option;
                    }
                  );

                  return (
                    <div className={styles.attributeWrapper} key={i}>
                      <div className={styles.attribute}>
                        <Tooltip text={labelTooltip || label}>
                          <strong>{label}</strong>
                        </Tooltip>
                        <Field
                          name={`field-${attribute.id}`}
                          top={31}
                          wrapper={(handleOpen, activeItem) => (
                            <div
                              className={classNames(
                                styles.dropdown,
                                _.size(values) <= 1 && styles.disabled
                              )}
                              onClick={handleOpen}
                            >
                              {type == 'color' && activeItem?.hex && (
                                <Tooltip
                                  placement='left'
                                  text={
                                    <span
                                      className={styles.thumbPreview}
                                      style={{
                                        background: '#' + activeItem?.hex,
                                        width: '250px',
                                        height: '250px',
                                        borderRadius: '3px',
                                        display: 'flex',
                                      }}
                                    />
                                  }
                                  html={true}
                                  dark={true}
                                >
                                  <span
                                    className={styles.colorThumb}
                                    style={{
                                      background: '#' + activeItem?.hex,
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {type == 'material' &&
                                activeItem?.preview_url &&
                                activeItem?.thumbnail_url && (
                                  <Tooltip
                                    placement='left'
                                    text={
                                      <Image
                                        className={styles.thumbPreview}
                                        src={activeItem?.preview_url}
                                      />
                                    }
                                    html={true}
                                    dark={true}
                                  >
                                    <Image
                                      className={styles.materialThumb}
                                      src={activeItem?.thumbnail_url}
                                    />
                                  </Tooltip>
                                )}
                              {type == 'texture' &&
                                activeItem?.preview_url &&
                                activeItem?.thumbnail_url && (
                                  <Tooltip
                                    placement='left'
                                    text={
                                      <Image
                                        className={styles.thumbPreview}
                                        src={activeItem?.preview_url}
                                      />
                                    }
                                    html={true}
                                    dark={true}
                                  >
                                    <Image
                                      className={styles.textureThumb}
                                      src={activeItem?.thumbnail_url}
                                    />
                                  </Tooltip>
                                )}
                              <Tooltip text={activeItem?.label}>
                                {type == 'string' &&
                                getLocalized(
                                  _.get(
                                    product_attribute_fields,
                                    attribute.template_field_id,
                                    {}
                                  ).label,
                                  1
                                ) === 'Product URL' &&
                                !_.isEmpty(activeItem.label) ? (
                                  // TO DO
                                  <Tooltip
                                    text={__(
                                      'read_only_products.flyout.tabs.attributes.product_url'
                                    )}
                                  >
                                    <a
                                      className={styles.productUrlContainer}
                                      href={formatUrlToOpenExternalLink(
                                        activeItem.label
                                      )}
                                      target='_blank'
                                    >
                                      <div className={styles.button}>
                                        <GlobeIcon
                                          className={styles.globeIcon}
                                        />
                                        {__(
                                          'read_only_products.flyout.tabs.attributes.product_url'
                                        )}
                                      </div>
                                    </a>
                                  </Tooltip>
                                ) : (
                                  <span>{activeItem?.label}</span>
                                )}
                              </Tooltip>
                              {_.size(values) > 1 && <ArrowDownSmallIcon />}
                            </div>
                          )}
                          component={Select}
                          onChange={(e, value) =>
                            this._handleFieldChange(
                              attribute.id,
                              value,
                              attribute.use_as_thumbnail
                            )
                          }
                          searchable
                          disabled={_.size(values) <= 1}
                          noSort
                          options={options}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Form>
    );
  }
}

export default AttributesForm;
