import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { mapStateToProps } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import { withRouter } from "react-router";

@reduxForm({
    form: "superadmin_regions.edit_region",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
class EditRegionForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_region} name="name" component={Input} label="Name" />
            </Form>
        );
    }
}

export default EditRegionForm;