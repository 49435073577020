import * as _ from "lodash";

const initialState = null;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.notifications/SET_NOTIFICATION":
            return {
                ...action.notification,
                id: _.uniqueId(),
            };
        case "general.notifications/CLEAR_NOTIFICATIONS":
            return null;
        default:
            return state;
    }
}