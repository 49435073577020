import * as React from "react";
import { Component } from "react";
import styles from "../styles/wizards/AddProductAttributeGroupWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeGroupAddIcon from "../assets/images/product-attribute-group-add-60x60.svg";
import { __, mapStateToProps, setTableParams, validateForm } from "../core/utils";
import { setNotification } from "../redux/actions/general/notification";
import AddProductAttributeGroupForm from "../forms/add_product_attribute_group_form/AddProductAttributeGroupForm";
import { addProductAttributeGroup, readProduct } from "../redux/actions/table/products";
import * as _ from "lodash";

@mapStateToProps((state) => ({
	product_id: _.get(state, "table.products.add_product_attribute_group_wizard"),
	is_configurator: _.get(state, "table.products.is_configurator")
}))
class AddProductAttributeGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		let data = {
			name: formData.name,
			type: formData.type
		};

		if (formData.type == "template" && formData.template) {
			if (_.startsWith(formData.template, "system")) {
				data.source = "system";
				data.template = formData.template.substring(7);
			}

			if (_.startsWith(formData.template, "company")) {
				data.source = "company";
				data.template = formData.template.substring(8);
			}

			data.fields = _.filter(_.keys(formData.fields), (field_id) => formData.fields[field_id] == true);
		}

		data.is_configurator = this.props.is_configurator;

		return validateForm(
			addProductAttributeGroup(this.props.product_id, data).then(() =>
				readProduct(this.props.product_id).then(({ response }) => {
					setNotification(__("products.flyout.add-group.product-attribute-group-added"));

					setTableParams("products", {
						flyout: response.data
					});

					this.props.onClose();
				})
			)
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__("products.flyout.add-group.add-product-attribute-group")}</span>
						<span>{__("products.flyout.add-group.add-attribute-group-your-product")}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeGroupAddIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddProductAttributeGroupForm
						onSubmit={this._handleSubmit}
						initialValues={{
							type: "template",
							name: {}
						}}
					/>
				</div>
			</>
		);
	}
}

export default AddProductAttributeGroupWizard;
