import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Quantity.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  formatMoney,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setTableParams,
  unformatMoney,
  validateForm,
  submitForm,
} from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import Tooltip from '../../components/Tooltip';
import QuantityForm from '../../forms/working_set_specifications/QuantityForm';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';

import RoomChooser from '../../components/RoomChooser';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import Dropdown from '../../components/Dropdown';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import NumericInput from '../../views/boq/helpers/NumericInput';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

import {
  addBOQPosition,
  addMultipleBOQPositions,
  deleteBOQPosition,
  deleteMultipleBOQPositions,
  getBOQArea,
  reorderBOQPositions,
  updateBOQCalculationQuantity,
  updateBOQPositionArea,
  updateBOQPositionName,
  updateBOQPositionQuantity,
} from '../../redux/actions/table/working_set_boq';
import {
  listWorkingSetSpecifications,
  readWorkingSetSpecification,

  /* --- QUANTITY --- */
  updateSpecificationQuantity,
} from '../../redux/actions/table/working_set_specifications';
import PlusMiddleIcon from '../../assets/images/plus-middle-15x15.svg';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import MoveIcon from '../../assets/images/move-16x16.svg';

@subscribe(Localization, 'localization')
@mapStateToProps((state, props) => ({
  store: state.table.working_set_specifications,
}))
class Quantities extends Component {
  static defaultProps = {
    editable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      space_allocation: {},
      collapsed_items: false,
      calculation_positions: [],
      quantity_area_expanded_all: false,
    };
  }

  componentDidMount() {
    const {
      flyout: { stage_id },
    } = this.props.store;

    getBOQArea(stage_id).then(({ response }) => {
      this.setState({ space_allocation: response.data });
    });

    this.setState({ calculation_positions: this.props.data });
  }

  @autobind
  _reloadSpecification() {
    readWorkingSetSpecification(this.props.store.flyout.id)
      .then(({ response }) => {
        setTableParams('working_set_specifications', {
          flyout: response.data,
        });
      })
      .then(() => {
        this.props.readWorkingSetBOQ();
      });
  }

  // TOOGLE //
  @autobind
  _toggleMasterCollapse() {
    const collapsable_boq_calculation = _.filter(
      this.props.store.flyout?.quantity?.boq_calculations,
      (calculation) => !_.isEmpty(calculation.positions)
    );

    if (
      _.size(collapsable_boq_calculation) == _.size(this.state.collapsed_items)
    ) {
      this.setState({
        collapsed_items: [],
      });
    } else {
      this.setState({
        collapsed_items: _.map(collapsable_boq_calculation, 'id'),
      });
    }
  }

  @autobind
  _toggleCollapse(calculation_id) {
    this.setState({
      collapsed_items: _.xor(this.state.collapsed_items, [calculation_id]),
    });
  }

  // BOQ FORM SUBMIT //
  @autobind
  _handleBOQSubmit(formData) {
    return validateForm(
      updateSpecificationQuantity(this.props.store.flyout.id, {
        ...formData,
        price: _.toNumber(formData.price),
      }).then(() => {
        setNotification(
          __('working-set-specifications-flyout.specification-details-updated')
        );

        this._reloadSpecification();

        listWorkingSetSpecifications(getActiveStage());
      })
    );
  }

  // ACTIONS //
  @autobind
  _handlePositionQuantityChange(id, quantity, key) {
    const { flyout } = this.props.store;

    updateBOQPositionQuantity(id, unformatMoney(quantity), key, flyout.id).then(
      () => {
        setNotification(
          __('working-set-specifications-flyout.specification-details-updated')
        );

        this._reloadSpecification();

        listWorkingSetSpecifications(getActiveStage());
      }
    );

    hideDropdown();
  }

  @autobind
  _handlePositionNameChange(id, value, position) {
    const { language_id, flyout } = this.props.store;

    updateBOQPositionName(id, value, language_id, position, flyout.id).then(
      () => {
        setNotification(
          __('working-set-specifications-flyout.specification-details-updated')
        );

        this._reloadSpecification();

        // listWorkingSetSpecifications(getActiveStage());
      }
    );

    hideDropdown();
  }

  @autobind
  _handleAreaChange(id, values, position) {
    const { type: area_type, id: area_id } = values || {};
    const { flyout } = this.props.store;

    const area_count = area_id
      ? _.size(
          _.filter(
            this.state.space_allocation.rooms,
            (room) => room.room_type_id == area_id
          )
        ) || 1
      : 1;

    updateBOQPositionArea(id, area_type, area_id, area_count).then(() => {
      setNotification(
        __('working-set-specifications-flyout.specification-details-updated')
      );

      this._reloadSpecification();

      listWorkingSetSpecifications(getActiveStage());
    });

    hideDropdown();
  }

  @autobind
  _handlePositionAreaCountChange({ id, area_type, area_id }, area_count, key) {
    const { flyout } = this.props.store;

    updateBOQPositionArea(
      id,
      area_type,
      area_id,
      unformatMoney(area_count)
    ).then(() => {
      setNotification(
        __('working-set-specifications-flyout.specification-details-updated')
      );

      this._reloadSpecification();

      listWorkingSetSpecifications(getActiveStage());
    });

    hideDropdown();
  }

  @autobind
  _handleBOQCalculationQuantityChange(id, value) {
    updateBOQCalculationQuantity(id, value).then(() => {
      this._reloadSpecification();

      listWorkingSetSpecifications(getActiveStage());
    });
  }

  @autobind
  _handleAddPosition(boq_calculation_id) {
    if (!boq_calculation_id) {
      return submitForm('working_set_specifications.boq');
    }

    return addBOQPosition(boq_calculation_id).then(() => {
      this._reloadSpecification();
      listWorkingSetSpecifications(getActiveStage());
    });
  }

  @autobind
  _handleAddMultiplePositions(boq_calculation, areas) {
    const formData = _.map(
      _.filter(areas, (area) => {
        return !_.find(
          boq_calculation.positions,
          (position) =>
            area.id == position.area_id && area.type == position.area_type
        );
      }),
      (position) => {
        return {
          area_id: position.id,
          area_type: position.type,
        };
      }
    );

    const unit_id = boq_calculation.unit_id;

    this.setState({ collapsed_items: true });

    if (!unit_id) {
      submitForm('working_set_specifications.boq');
    } else {
      const deletedSpaceAllocationOrderIds = _.map(
        _.filter(
          _.filter(boq_calculation.positions, (position) => position.area_id),
          (position) => {
            return !_.find(
              areas,
              (area) =>
                area.id == position.area_id && area.type == position.area_type
            );
          }
        ),
        (position) => position.order
      );

      if (_.size(deletedSpaceAllocationOrderIds) > 0) {
        showAlertbox({
          color: 'red',
          title: __('general.alert.are-you-sure'),
          description: __('quantity.alert.delete-confirmation'),
          buttons: [
            {
              color: 'lightGray',
              text: __('general.alert.no-close'),
              onClick: 'close',
            },
            {
              color: 'gray',
              text: __('general.alert.yes-delete'),
              onClick: () => {
                deleteMultipleBOQPositions(
                  boq_calculation.id,
                  deletedSpaceAllocationOrderIds
                ).then(() =>
                  addMultipleBOQPositions(boq_calculation.id, {
                    data: formData,
                  }).then(() => {
                    this._reloadSpecification();

                    listWorkingSetSpecifications(getActiveStage());

                    hideDropdown();
                  })
                );

                hideAlertbox();
              },
            },
          ],
        });
      } else {
        addMultipleBOQPositions(boq_calculation.id, {
          data: formData,
        }).then(() => {
          this._reloadSpecification();

          listWorkingSetSpecifications(getActiveStage());

          hideDropdown();
        });

        hideAlertbox();
      }
    }
  }

  @autobind
  _handleDelete(id, position, item) {
    let handleDelete;
    const { flyout } = this.props.store;

    handleDelete = () => deleteBOQPosition(id, position, flyout.id);

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('quantity.alert.delete-confirmation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () => {
            handleDelete().then(() => {
              this._reloadSpecification();

              // listWorkingSetSpecifications(getActiveStage());

              setNotification(__('quantity.alert.deleted'));
            });

            hideAlertbox();
          },
        },
      ],
    });
  }

  @autobind
  _changeOrder(id, positions) {
    const { flyout } = this.props.store;

    setTableParams('working_set_specifications', {
      flyout: {
        ...flyout,
        quantity: {
          ...flyout.quantity,
          boq_calculations: _.map(
            flyout.quantity?.boq_calculations,
            (calculation, i) => {
              if (calculation.id == id) {
                return {
                  ...calculation,
                  positions: _.map(positions, (position) => {
                    return {
                      ...position,
                      order: _.findIndex(positions, ['id', position.id]),
                    };
                  }),
                };
              }

              return calculation;
            }
          ),
        },
      },
    });
  }

  render() {
    const { collapsed, store, localization, readOnly } = this.props;
    const { flyout, language_id } = store;
    const { units, currencies, specification_groups, specification_subgroups } =
      localization;

    const editable = flyout.main;

    const { collapsed_items, quantity_area_expanded_all } = this.state;

    let space_allocation = [];

    const parent_linked_specs = _.sortBy(
      _.map(flyout.parent_linked, (spec) => {
        const specification_subgroup =
          _.get(specification_subgroups, [spec.specification_subgroup_id]) ||
          {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const code =
          getLocalized(specification_group.code, flyout.project_language) +
          specification_subgroup.code +
          _.padStart(spec.code, 2, '0');

        return {
          ...spec,
          code,
        };
      }),
      'code'
    );

    _.each(this.state.space_allocation.sites, (site) =>
      space_allocation.push({
        id: site.id,
        type: 'site',
        display_name: site.code + ': ' + getLocalized(site.title, language_id),
        parent_type: null,
        parent_id: null,
      })
    );

    _.each(this.state.space_allocation.buildings, (building) =>
      space_allocation.push({
        id: building.id,
        type: 'building',
        display_name:
          building.code + ': ' + getLocalized(building.title, language_id),
        parent_type: building.site_id ? 'site' : null,
        parent_id: building.site_id ? building.site_id : null,
        children: _.filter(
          this.state.space_allocation.storeys,
          (storey) => storey.building_id === building.id
        ),
      })
    );

    _.each(this.state.space_allocation.storeys, (storey) => {
      const disabled =
        _.includes(
          _.map(
            _.filter(
              _.get(flyout?.quantity?.boq_calculations, 'positions'),
              (position) => position.area_type == 'storey'
            ),
            (position) => position.area_id
          ),
          storey.id
        ) ||
        _.includes(
          _.map(
            _.filter(
              _.get(flyout?.quantity?.boq_calculations, 'positions'),
              (position) => position.area_type == 'building'
            ),
            (position) => position.area_id
          ),
          storey.building_id
        ) ||
        _.some(
          space_allocation,
          (row) =>
            row.id == storey.building_id &&
            row.type == 'building' &&
            row.disabled
        );

      space_allocation.push({
        id: storey.id,
        type: 'storey',
        display_name:
          storey.number + ': ' + getLocalized(storey.name, language_id),
        parent_type: storey.building_id ? 'building' : null,
        parent_id: storey.building_id ? storey.building_id : null,
      });
    });

    _.each(this.state.space_allocation.rooms, (room) => {
      const find_room_type = _.find(this.state.space_allocation.room_types, [
        'id',
        room.room_type_id,
      ]);

      space_allocation.push({
        id: room.id,
        type: 'room',
        display_name: (
          <Tooltip
            text={
              find_room_type
                ? `${getLocalized(
                    find_room_type?.title,
                    language_id
                  )} (${_.size(
                    _.filter(this.state.space_allocation.rooms, [
                      'room_type_id',
                      find_room_type?.id,
                    ])
                  )})`
                : room.code + ': ' + getLocalized(room.name, language_id)
            }
          >
            {room.code + ': ' + getLocalized(room.name, language_id)}
          </Tooltip>
        ),

        parent_type: room.storey_id ? 'storey' : null,
        parent_id: room.storey_id ? room.storey_id : null,
        room_type_id: room.room_type_id,
      });
    });

    _.each(this.state.space_allocation.room_types, (room_type) => {
      space_allocation.push({
        id: room_type.id,
        type: 'room_type',
        display_name: `${getLocalized(room_type.title, language_id)} (${_.size(
          _.filter(this.state.space_allocation.rooms, [
            'room_type_id',
            room_type?.id,
          ])
        )})`,
      });
    });

    const unit = _.get(units, [_.get(flyout.quantity, 'unit_id'), 'symbol']);

    const collapsable_boq_calculation = _.filter(
      flyout?.quantity?.boq_calculations,
      (calculation) => !_.isEmpty(calculation.positions)
    );

    const sorted_calculations = _.sortBy(
      flyout?.quantity?.boq_calculations,
      (calculation) => (calculation?.item_id ? calculation?.id : Infinity)
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <QuantityForm
          readOnly={readOnly}
          editable={editable}
          onSubmit={this._handleBOQSubmit}
          language_id={language_id}
          initialValues={{
            ..._.pick(flyout.quantity, [
              'price',
              'unit_id',
              'budget_type',
              'installation_type',
              'boq_supply_type_group_id',
            ]),
          }}
        />
        {/* --- HEADER --- */}
        <div className={classNames(styles.row, styles.header)}>
          <span className={styles.expand} />
          <span className={styles.nameAndArea}>
            {__('working-set-specifications-flyout.quantity.name&area')}
          </span>

          <span className={styles.areaCount}>
            {__('working-set-specifications-flyout.quantity.area-count')}
          </span>
          <span className={styles.quantity}>
            {__('working-set-specifications-flyout.quantity.quantity')}
          </span>
          <span className={styles.subQuantity}>
            {__('working-set-specifications-flyout.quantity.sub_quantity')}
          </span>
          <span className={styles.unit}>
            {__('working-set-specifications-flyout.quantity.unit')}
          </span>
          <span className={styles.price}>
            {__('working-set-specifications-flyout.quantity.price')}
            {' (' +
              _.get(currencies, [flyout?.project_currency_id, 'code']) +
              ')'}
          </span>
          <span className={styles.total}>
            {__('working-set-specifications-flyout.quantity.total')}
          </span>
          <span className={styles.move} />
          <span className={styles.delete} />
        </div>

        {/* --- FIRST BODY ROW - TOTAL --- */}
        <div className={classNames(styles.row, styles.calculation)}>
          <span className={styles.expand}>
            <ArrowDownMiddleIcon
              className={classNames(
                _.size(collapsable_boq_calculation) !=
                  _.size(collapsed_items) && styles.active
              )}
              onClick={this._toggleMasterCollapse}
            />
          </span>
          <span className={styles.nameAndArea}>
            {__('working-set-specifications-flyout.quantity.total')}
          </span>

          <span className={styles.subQuantity}>
            {formatMoney(
              _.toNumber(
                _.sumBy(
                  flyout?.quantity?.boq_calculations,
                  (calculation) => calculation.quantity
                )
              ) +
                _.toNumber(
                  _.sumBy(
                    _.filter(parent_linked_specs, (spec) => spec.quantity),
                    (specification) =>
                      specification.included_in_price == 0 &&
                      specification.quantity * specification.count
                  )
                )
            )}
          </span>
          <span className={styles.unit}>{unit}</span>

          <span className={styles.price}>
            {formatMoney(_.get(flyout.quantity, 'price'))}
          </span>

          <span className={styles.total}>
            {formatMoney(
              _.toNumber(
                _.sumBy(
                  flyout.quantity?.boq_calculations,
                  (calculation) =>
                    calculation.quantity * _.get(flyout.quantity, 'price')
                )
              ) +
                _.sumBy(
                  _.filter(parent_linked_specs, (spec) => spec.quantity),
                  (specification) =>
                    specification.included_in_price == 0 &&
                    specification.quantity *
                      specification.count *
                      _.get(flyout.quantity, 'price')
                )
            )}
          </span>
          <span className={styles.move} />
          <span className={styles.delete} />
        </div>

        {/* --- QUANTITY CALCULATIONS --- */}
        {_.map(sorted_calculations, (calculation, i) => {
          const selected_room_type_ids = _.map(
            _.filter(
              _.get(calculation, 'positions'),
              (position) => position.area_type == 'room_type'
            ),
            (position) => position.area_id
          );

          const excluded_room_type_ids = _.map(
            _.filter(this.state.space_allocation.rooms, (room) =>
              _.some(
                _.get(calculation, 'positions'),
                (position) =>
                  position.area_type == 'room' && position.area_id == room.id
              )
            ),
            (data) => data.room_type_id
          );

          space_allocation = _.map(space_allocation, (area) => {
            return _.find(
              calculation.positions,
              (position) =>
                area.type == position.area_type && area.id == position.area_id
            ) ||
              (area.type == 'room_type' &&
                _.includes(excluded_room_type_ids, area.id)) ||
              (area.type == 'room' &&
                _.includes(selected_room_type_ids, area.room_type_id))
              ? { ...area, disabled: true }
              : { ...area, disabled: false };
          });

          const sorted_positions = _.sortBy(calculation.positions, 'order');

          return (
            <>
              <div className={styles.row} key={i}>
                <span className={styles.expand}>
                  <ArrowDownMiddleIcon
                    className={classNames(
                      !_.includes(collapsed_items, calculation.id) &&
                        styles.active,
                      _.isEmpty(calculation.positions) && styles.faded
                    )}
                    onClick={() =>
                      !_.isEmpty(calculation.positions) &&
                      this._toggleCollapse(calculation.id)
                    }
                  />
                </span>
                <span
                  className={classNames(
                    styles.nameAndArea,
                    _.isEmpty(calculation.positions) && styles.faded,
                    _.isNil(calculation.item_id) &&
                      styles.unallocatedCalculation
                  )}
                >
                  {`${calculation?.code || ''} `}
                  {getLocalized(calculation?.name, language_id)}
                </span>

                {_.isEmpty(calculation.positions) && (
                  <span className={styles.quantity}>
                    <NumericInput
                      key={_.uniqueId()}
                      onBlur={(value) =>
                        this._handleBOQCalculationQuantityChange(
                          calculation.id,
                          value
                        )
                      }
                      defaultValue={calculation.quantity}
                    />
                  </span>
                )}

                <span className={styles.subQuantity}>
                  <NumericInput
                    readOnly={true}
                    key={_.uniqueId()}
                    defaultValue={calculation.quantity}
                  />
                </span>
                <span className={styles.unit}>
                  <Dropdown
                    top={25}
                    right={-15}
                    readOnly
                    wrapperClassName={styles.dropdownWrapper}
                    name={'unit.' + calculation.id}
                    header={<div className={styles.dropdownHeader}>Unit</div>}
                    content={
                      <div className={styles.dropdownContent}>
                        <div
                          className={classNames(
                            styles.option,
                            !calculation.unit_id && styles.active
                          )}
                          // onClick={(e) =>
                          //   this._handleUnitChange(e, calculation.id, null)
                          // }
                        >
                          <span className={styles.title}>--</span>
                          {!calculation.unit_id && (
                            <span className={styles.dot} />
                          )}
                        </div>
                        {_.map(units, (unit, i) => (
                          <div
                            key={i}
                            className={classNames(
                              styles.option,
                              (calculation.unit_id || null) == unit.id &&
                                styles.active
                            )}
                            // onClick={(e) =>
                            //   this._handleUnitChange(e, calculation.id, unit.id)
                            // }
                          >
                            <span className={styles.title}>{unit.symbol}</span>
                            {calculation.unit_id == unit.id && (
                              <span className={styles.dot} />
                            )}
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <div className={styles.dropdown}>
                      {calculation.unit_id && (
                        <span>
                          {_.get(units, [calculation.unit_id, 'symbol'])}
                        </span>
                      )}
                      {!calculation.unit_id && (
                        <span className={styles.none}>--</span>
                      )}
                      {/* <ArrowDownSmallIcon /> */}
                    </div>
                  </Dropdown>
                </span>
                <span className={styles.price}>
                  <NumericInput
                    key={calculation.id + calculation.price}
                    readOnly={true}
                    defaultValue={calculation.price}
                  />
                </span>
                <span className={styles.total}>
                  {formatMoney(
                    calculation.quantity * _.get(flyout.quantity, 'price')
                  )}
                </span>
                <span className={styles.move} />
                <span
                  className={styles.plus}
                  onClick={() => this._handleAddPosition(calculation.id)}
                >
                  <Tooltip
                    text={__(
                      __(
                        'working-set-specifications-flyout.quantity.add-element'
                      )
                    )}
                  >
                    <PlusMiddleIcon />
                  </Tooltip>
                </span>
              </div>
              <ReactSortable
                list={sorted_positions}
                setList={(list) => this._changeOrder(calculation.id, list)}
                animation={200}
                delayOnTouchStart={true}
                onEnd={(items) => {
                  reorderBOQPositions(
                    calculation.id,
                    _.map(sorted_positions, 'id')
                  );
                }}
                handle={'.' + styles.grabber}
              >
                {!_.includes(collapsed_items, calculation.id) &&
                  _.map(_.sortBy(sorted_positions, 'order'), (position, p) => {
                    let area;

                    if (position.area_id) {
                      area =
                        _.find(space_allocation, {
                          type: position.area_type,
                          id: position.area_id,
                        }) || {};
                    }

                    return (
                      <div
                        className={classNames(styles.row, styles.position)}
                        key={p}
                      >
                        <div className={styles.grabber}>
                          <GrabberIcon />
                        </div>

                        <span className={styles.expand}>{p + 1}</span>
                        <span className={styles.nameAndArea}>
                          <span className={styles.area}>
                            <Dropdown
                              readOnly={!editable || readOnly}
                              top={30}
                              left={-20}
                              leftStyled
                              wrapperClassName={styles.dropdownWrapper}
                              name={'area.' + position.id}
                              header={
                                <div className={styles.dropdownHeader}>
                                  {__('table.area-tab.area')}

                                  <div className={styles.dropdownHeaderActions}>
                                    <span
                                      className={styles.dropdownHeaderExpandAll}
                                      onClick={() => {
                                        this.setState({
                                          quantity_area_expanded_all:
                                            !this.state
                                              .quantity_area_expanded_all,
                                        });
                                      }}
                                    >
                                      {__(
                                        'working-set-specifications-flyout.quantity.area.expand-all'
                                      )}
                                    </span>
                                    <span
                                      className={styles.dropdownHeaderClear}
                                      onClick={() =>
                                        this._handleAreaChange(
                                          position.id,
                                          {
                                            type: '',
                                            id: null,
                                          },
                                          p + 1
                                        )
                                      }
                                    >
                                      {__(
                                        'working-set-specifications-flyout.quantity.area.clear'
                                      )}
                                    </span>
                                  </div>
                                </div>
                              }
                              content={
                                <div
                                  className={classNames(
                                    styles.dropdownContent,
                                    styles.space
                                  )}
                                >
                                  <RoomChooser
                                    onCancel={hideDropdown}
                                    options={space_allocation}
                                    onDone={(values) =>
                                      this._handleAreaChange(
                                        position.id,
                                        values,
                                        p + 1
                                      )
                                    }
                                    initialValues={
                                      _.isEmpty(area) ? {} : [area]
                                    }
                                    expandedAllNodes={
                                      quantity_area_expanded_all
                                    }
                                  />
                                </div>
                              }
                            >
                              <div
                                className={classNames(
                                  styles.dropdown,
                                  styles.areaDropdown
                                )}
                              >
                                {area ? (
                                  <Tooltip text={area.display_name}>
                                    <span>{area.display_name}</span>
                                  </Tooltip>
                                ) : (
                                  <span className={styles.none}>
                                    {__(
                                      'working-set-specifications-flyout.quantity.select-area'
                                    )}
                                  </span>
                                )}{' '}
                                <ArrowDownSmallIcon />
                              </div>
                            </Dropdown>
                          </span>

                          <span className={styles.name}>
                            <input
                              disabled={!editable}
                              className={styles.input}
                              type='text'
                              placeholder={__(
                                'working-set-specifications-flyout.quantity.add-name'
                              )}
                              onKeyDown={(e) =>
                                e.key === 'Enter' && e.currentTarget.blur()
                              }
                              onBlur={({ currentTarget }) =>
                                this._handlePositionNameChange(
                                  position.id,
                                  currentTarget.value,
                                  p + 1
                                )
                              }
                              key={
                                position.id +
                                getLocalized(position.name, language_id)
                              }
                              defaultValue={getLocalized(
                                position.name,
                                language_id
                              )}
                            />
                          </span>
                        </span>

                        <span className={styles.areaCount}>
                          <NumericInput
                            readOnly={!editable || position.area_id || readOnly}
                            key={position.id + position.area_id}
                            onBlur={(value) =>
                              this._handlePositionAreaCountChange(
                                position,
                                value,
                                p + 1
                              )
                            }
                            defaultValue={position.area_count}
                          />
                        </span>

                        <span className={styles.quantity}>
                          <NumericInput
                            readOnly={!editable || readOnly}
                            key={position.id + position.quantity}
                            onBlur={(value) =>
                              this._handlePositionQuantityChange(
                                position.id,
                                value,
                                p + 1
                              )
                            }
                            defaultValue={position.quantity}
                          />
                        </span>

                        <span className={styles.subQuantity}>
                          {formatMoney(position.sub_quantity)}
                        </span>

                        <span className={styles.unit}>
                          <Dropdown
                            leftStyled
                            top={25}
                            right={-15}
                            readOnly
                            wrapperClassName={styles.dropdownWrapper}
                            name={'unit.' + position.id}
                            header={
                              <div className={styles.dropdownHeader}>
                                {__(
                                  'working-set-specifications-flyout.quantity.unit'
                                )}
                              </div>
                            }
                          >
                            <div className={styles.dropdown}>
                              {unit && <span>{unit}</span>}
                              {!unit && <span className={styles.none}>--</span>}
                            </div>
                          </Dropdown>
                        </span>
                        <span className={styles.price}>
                          <NumericInput
                            key={position.id + flyout.quantity.price}
                            readOnly={true}
                            tooltip='This field is auto-calculated'
                            defaultValue={flyout?.quantity.price}
                          />
                        </span>
                        <span className={styles.total}>
                          {formatMoney(position.total)}
                        </span>

                        {editable ? (
                          <>
                            <span
                              className={styles.move}
                              onClick={() => {
                                setTableParams('working_set_specifications', {
                                  move_boq_position_wizard: position.id,
                                  calculation_id: calculation.id,
                                  name: getLocalized(
                                    position?.name,
                                    language_id
                                  ),
                                });
                              }}
                            >
                              <Tooltip
                                text={__(
                                  __(
                                    'working-set-specifications-flyout.quantity.move-element'
                                  )
                                )}
                              >
                                <MoveIcon />
                              </Tooltip>
                            </span>
                            <span
                              className={styles.delete}
                              onClick={() =>
                                this._handleDelete(position.id, p + 1)
                              }
                            >
                              <Tooltip
                                text={__(
                                  __(
                                    'working-set-specifications-flyout.quantity.delete-element'
                                  )
                                )}
                              >
                                <DeleteIcon />
                              </Tooltip>
                            </span>
                          </>
                        ) : (
                          <>
                            <span className={styles.move} />
                            <span className={styles.delete} />
                          </>
                        )}
                      </div>
                    );
                  })}
              </ReactSortable>
            </>
          );
        })}

        {/* --- LINKED SPECIFICATIONS ROW --- */}
        {!_.isEmpty(parent_linked_specs) && (
          <div className={classNames(styles.row)}>
            <span className={styles.expand} />

            <span className={styles.name}>
              {__(
                'working-set-specifications-flyout.quantity.total-linked-specifications'
              )}
            </span>

            <span className={styles.areaCount} />
            <span className={styles.quantity}>
              {formatMoney(
                _.sumBy(
                  _.filter(parent_linked_specs, (spec) => spec.quantity),
                  (specification) =>
                    specification.included_in_price == 0 &&
                    specification.quantity * specification.count
                )
              )}
            </span>

            <span className={styles.unit}>
              {_.get(units, [flyout.quantity?.unit_id, 'symbol'])}
            </span>

            <span className={styles.price}>
              {formatMoney(_.get(flyout.quantity, 'price'))}
            </span>

            <span className={styles.total}>
              {formatMoney(
                _.sumBy(
                  _.filter(parent_linked_specs, (spec) => spec.quantity),
                  (specification) =>
                    specification.included_in_price == 0 &&
                    specification.quantity *
                      specification.count *
                      _.get(flyout.quantity, 'price')
                )
              )}
            </span>
            <span className={styles.move} />
            <span className={styles.delete} />
          </div>
        )}

        {!readOnly && (
          <div className={styles.addElementRow}>
            <button
              className={styles.button}
              onClick={() =>
                this._handleAddPosition(_.last(sorted_calculations)?.id)
              }
            >
              {__('working-set-specifications-flyout.quantity.add-element')}
            </button>

            <Dropdown
              top={25}
              leftStyled
              wrapperClassName={styles.dropdownWrapper}
              name={
                'working-set-specifications-flyout.quantity.add-multiple-rooms'
              }
              header={
                <div className={styles.dropdownHeader}>
                  {__('table.area-tab.area')}

                  <div className={styles.dropdownHeaderActions}>
                    <span
                      className={styles.dropdownHeaderExpandAll}
                      onClick={() => {
                        this.setState({
                          quantity_area_expanded_all:
                            !this.state.quantity_area_expanded_all,
                        });
                      }}
                    >
                      expand-all
                      {/* {__(
                        'working-set-specifications-flyout.quantity.area.expand-all'
                      )} */}
                    </span>
                  </div>
                </div>
              }
              content={
                <div
                  className={classNames(styles.dropdownContent, styles.space)}
                >
                  <RoomChooser
                    multiselect={true}
                    onCancel={hideDropdown}
                    options={_.map(space_allocation, (area) => {
                      return { ...area, disabled: false };
                    })}
                    //options={space_allocation}
                    onDone={(values) => {
                      this._handleAddMultiplePositions(
                        _.last(sorted_calculations),
                        values
                      );
                    }}
                    selectAllRooms
                    initialValues={_.map(
                      _.filter(
                        _.find(
                          flyout?.quantity?.boq_calculations,
                          (calculation) => !calculation.item_id
                        )?.positions,
                        (position) => position?.area_id
                      ),
                      (position) => {
                        return _.find(space_allocation, {
                          type: position.area_type,
                          id: position.area_id,
                        });
                      }
                    )}
                    expandedAllNodes={quantity_area_expanded_all}
                  />
                </div>
              }
            >
              <button className={styles.button}>
                {__(
                  'working-set-specifications-flyout.quantity.add-multiple-rooms'
                )}
              </button>
            </Dropdown>
          </div>
        )}
      </div>
    );
  }
}

export default Quantities;

// @autobind
// _handleItemNameChange(id, value) {
//   const { language_id } = this.props.store;

//   updateBOQItemName(id, value, language_id).then(() => {
//     setNotification(
//       __('working-set-specifications-flyout.specification-details-updated')
//     );

//     this._reloadSpecification();
//   });

//   hideDropdown();
// }

// @autobind
// _handleUnitChange(e, id, unit_id) {
//   e.stopPropagation();

//   updateBOQCalculationUnit(id, unit_id).then(() => {
//     setNotification(
//       __('working-set-specifications-flyout.specification-details-updated')
//     );

//     this._reloadSpecification();
//   });

//   hideDropdown();
// }

// disabled:
//   _.includes(room_types_quantity, room.room_type_id) ||
//   _.includes(
//     _.map(
//       _.filter(
//         _.get(flyout.quantity.boq_calculations, 'positions'),
//         (position) => position.area_type == 'room'
//       ),
//       (position) => position.area_id
//     ),
//     room.id
//   ) ||
//   _.includes(
//     _.map(
//       _.filter(
//         _.get(flyout.quantity.boq_calculations, 'positions'),
//         (position) => position.area_type == 'storey'
//       ),
//       (position) => position.area_id
//     ),
//     room.storey_id
//   ) ||
//   _.some(
//     space_allocation,
//     (row) =>
//       row.id == room.storey_id && row.type == 'storey' && row.disabled
//   ),
