import * as React from 'react';
import styles from '../styles/wizards/AddBOQItemsFromTemplateWizard.scss';
import { __, getActiveStage, redirect, validateForm } from '../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import BOQIcon from '../assets/images/boq-24x24.svg';
import AddBOQItemsFromTemplateForm from '../forms/add_boq_items_from_template_wizard/AddBOQItemsFromTemplateForm';
import {
  addBOQFromTemplate,
  readWorkingSetBOQ,
} from '../redux/actions/table/working_set_boq';

const AddBOQItemsFromTemplateWizard = ({ onClose }) => {
  const _handleSubmit = (formData) => {
    return validateForm(
      addBOQFromTemplate({ stage_id: getActiveStage(), ...formData }).then(
        () => {
          onClose();

          readWorkingSetBOQ(getActiveStage());

          setTimeout(
            () =>
              setNotification(
                __('boq.notification.selected-template-has-been-added')
              ),
            25
          );
        }
      )
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.add-boq-items-from-template.title')}</span>
          <span>{__('wizard.add-boq-items-from-template.subtitle')}</span>
        </div>
        <div className={styles.right}>
          <BOQIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddBOQItemsFromTemplateForm
          onSubmit={_handleSubmit}
          onClose={onClose}
        />
      </div>
    </>
  );
};

export default AddBOQItemsFromTemplateWizard;
