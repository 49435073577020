import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/rooms_multi_edit/RoomsMultiEditFlyout.scss";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import {__, setTableParams} from "../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import withRouter from "react-router/es/withRouter";
import classNames from "classnames";
import Properties from "./Properties";

@subscribe(Localization, "localization")
@withRouter
class RoomsMultiEditFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    render() {
        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>
                            {__('rooms.flyout.multi-edit.multiple-rooms-edit')}
                        </span>
                        <CloseBigIcon onClick={() => setTimeout(() => {
                            setTableParams('rooms', {
                            clicked_row: undefined,
                            multi_edit: undefined
                        })}, 100)}/>
                    </div>
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>{__('rooms.flyout.properties')}</span>
                </div>
                {this.state.active == 'properties' && <Properties />}
            </div>
        );
    }
}

export default RoomsMultiEditFlyout;
