import React, { useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_boq_items_from_template_wizard/AddBOQItemsFromTemplateForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __ } from '../../core/utils';
import Select from '../../components/Select';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import { listPossibleBOQTemplates } from '../../redux/actions/table/working_set_boq';

const AddBOQItemsFromTemplateForm = ({ handleSubmit, onClose, submitting }) => {
  const [list_possible_templates, set_list_possible_templates] = useState([]);

  useEffect(() => {
    listPossibleBOQTemplates().then(({ response }) => {
      set_list_possible_templates(response.data);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.f1}>
          <Field
            name='boq_template_id'
            component={Select}
            noSort
            searchable
            label={__(
              'working_set_boq.add_boq_items_as_template.field.boq_template_id'
            )}
            options={list_possible_templates}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={onClose}
        />
        <Button
          lightBlue
          medium
          middleText={__('button.add')}
          spinning={submitting}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

export default withRouter(
  reduxForm({
    form: 'add_boq_items_from_template_wizard.add_boq_items_from_template',
  })(subscribe(Localization, 'localization')(AddBOQItemsFromTemplateForm))
);
