import store from "../../../core/store";

export const listPublicDeliveries = (stage_id) => {
    return store.dispatch({
        action: "table.public_deliveries/LIST_PUBLIC_DELIVERIES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/deliveries",
        main: true
    });
};

export const readPublicDelivery = (delivery_id) => {
    return store.dispatch({
        action: "table.public_deliveries/READ_PUBLIC_DELIVERY",
        method: "GET",
        endpoint: "/deliveries/" + delivery_id
    });
};