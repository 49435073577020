import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import styles from '../../styles/views/plans/OCRTemplates.scss';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  redirect,
  setTableParams,
} from '../../core/utils';
import { leaveChannel } from '../../core/sockets';
import Table from '../../components/Table';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import Tooltip from '../../components/Tooltip';
import { withRouter } from 'react-router';
import {
  deleteOCRTemplates,
  listOCRTemplates,
} from '../../redux/actions/table/ocr_templates';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import classNames from 'classnames';
import Avatar from '../../components/Avatar';

const mapStateToProps = (state) => {
  return {
    table: state.table['ocr_templates'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.ocr_templates/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
class OCRTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_roles: [],
    };
  }

  componentWillUnmount() {
    leaveChannel('plan_uploads.' + this.props.match.params.stage);
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      window.Echo.private(
        'plan_uploads.' + this.props.match.params.stage
      ).listen('OCRTemplateStatusChanged', () => {
        listOCRTemplates(this.props.match.params.stage);
      });

      enableProjectsDropdown(({ stage_id }) =>
        redirect('/stages/' + stage_id + '/ocr_templates')
      );
      enableStagesDropdown(({ stage_id }) =>
        redirect('/stages/' + stage_id + '/ocr_templates')
      );

      createSidebar({
        title: __('submenu.title.plans'),
        items: [
          {
            title: __('submenu.title.current-set'),
            link: () => '/stages/' + getActiveStage() + '/plans/current',
          },
          {
            title: __('submenu.title.working-set'),
            link: () => '/stages/' + getActiveStage() + '/plans/working',
          },
          {
            title: __('submenu.title.uploads'),
            active: true,
            items: [
              {
                title: __('submenu.title.uploads-list'),
                link: () => '/stages/' + getActiveStage() + '/plans/uploads',
              },
              {
                title: __('submenu.title.ocr-templates'),
                link: () => '/stages/' + getActiveStage() + '/ocr_templates',
                active: true,
              },
            ],
          },
          {
            title: __('submenu.title.deliveries'),
            link: () => '/stages/' + getActiveStage() + '/deliveries',
          },
          {
            title: __('submenu.title.approvals'),
            link: () => '/stages/' + getActiveStage() + '/approvals',
          },
        ],
      });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('button.create-new-template'),
            onClick: () =>
              this.props.actions.setTableParams({
                add_ocr_template_wizard: true,
              }),
          },
        ]);
    });
  }

  render() {
    const columns = [
      {
        key: 'name',
        name: __('ocr-templates.field.name'),
        required: true,
        sortable: false,
      },
      {
        key: 'status',
        name: __('ocr-templates.field.status'),
        value: (status) => {
          switch (status) {
            case 0:
              return (
                <span className={classNames(styles.balloon, styles.processing)}>
                  {__('ocr-templates.value.status-processing')}
                </span>
              );
            case 1:
              return (
                <span className={classNames(styles.balloon, styles.editing)}>
                  {__('ocr-templates.value.status-waiting-for-edit')}
                </span>
              );
            case 2:
              return (
                <span className={classNames(styles.balloon, styles.ready)}>
                  {__('ocr-templates.value.status-ready')}
                </span>
              );
          }
        },
        width: 130,
        sortable: false,
      },
      {
        key: 'language',
        name: __('ocr-templates.field.language'),
        width: 100,
        sortable: false,
      },
      {
        key: 'created_at',
        name: __('ocr-templates.field.created-at'),
        value: (created_at) => (
          <Tooltip text={dateTimeFrom(created_at)}>
            {dateFrom(created_at)}
          </Tooltip>
        ),
        width: 80,
        sortable: false,
      },
      {
        key: 'created_by',
        name: __('ocr-templates.field.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
        sortable: false,
      },
    ];

    const singleActions = (ocr_template_id) => {
      return [
        <Tooltip
          text={__('ocr-templates.tooltip.delete-ocr-template')}
          key={_.uniqueId()}
        >
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description: __('ocr-templates.alert.delete-confirmation'),
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteOCRTemplates([ocr_template_id]).then(() => {
                        hideAlertbox();

                        setNotification(__('ocr-templates.alert.deleted'));
                        listOCRTemplates(this.props.match.params.stage);

                        setTableParams('ocr_templates', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (ocr_template_ids) => [
      <Tooltip
        text={__('ocr-templates.tooltip.delete-selected-ocr-templates')}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __(
                'ocr-templates.alert.multiple-delete-confirmation'
              ),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteOCRTemplates(ocr_template_ids).then(() => {
                      hideAlertbox();

                      setNotification(
                        __('ocr-templates.alert.multiple-deleted')
                      );
                      listOCRTemplates(this.props.match.params.stage);

                      setTableParams('ocr_templates', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    return (
      <Table
        listAction={() => listOCRTemplates(this.props.match.params.stage)}
        name='ocr_templates'
        title={__('submenu.title.ocr-templates')}
        columns={columns}
        onRowClick={(id) => {
          const status = _.get(_.find(this.props.table.data, { id }), 'status');

          if (status) {
            redirect('/ocr_templates/' + id);
          } else {
            setNotification({
              text: __('ocr-templates.alert.processing-templates-edit'),
              type: 'warning',
            });
          }
        }}
        singleActions={singleActions}
        groupActions={groupActions}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default OCRTemplates;
