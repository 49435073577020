import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/MultipleSpecificationTagsEditWizard.scss";
import autobind from "autobind-decorator";
import TagIcon from "../assets/images/tag-64x64.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import {
	listWorkingSetSpecifications,
	multipleEditSpecificationTags
} from "../redux/actions/table/working_set_specifications";
import MultipleSpecificationTagsEditForm from "../forms/wizard_multiple_specification_tags_edit/MultipleSpecificationTagsEditForm";

@mapStateToProps((state) => ({
	specification_ids: _.get(state.table, "working_set_specifications.multiple_specification_tags_edit_wizard"),
}))
@withRouter
class MultipleSpecificationTagsEditWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const {specification_ids} = this.props;
		
		return validateForm(
			multipleEditSpecificationTags(specification_ids, _.get(formData, 'tags')).then(() => {
				listWorkingSetSpecifications(this.props.match.params.stage);

				setTableParams('working_set_specifications', {
					selected_rows: []
				});

				setNotification(__("Multiple specifications tags have been saved."));

				this.props.onClose();
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('Multiple Edit Specifications Tags')}</span>
						<span>{__('Choose the tags for selected specifications')}</span>
					</div>
					<div className={styles.right}>
						<TagIcon />
					</div>
				</div>
				<div className={styles.body}>
					<MultipleSpecificationTagsEditForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default MultipleSpecificationTagsEditWizard;
