import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddTasksToMeetingWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, validateForm} from "../core/utils";
import AddTaskIcon from "../assets/images/add-task-60x60.svg";
import {withRouter} from "react-router";
import {addTasksToMeeting} from "../redux/actions/table/all_meetings";
import AddTasksToMeetingForm from "../forms/add_task_to_meeting_wizard/AddTasksToMeetingForm";

@mapStateToProps(state => ({
    order: _.get(state.table, 'all_meetings.order')
}))
@withRouter
class AddTasksToMeetingWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {order} = this.props;

        formData = {
            ...formData,
            order
        };

        return validateForm(
            addTasksToMeeting(this.props.match.params.meeting, formData).then(() => {
                this.props.onClose();
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.meeting.title.add-existing-tasks-to-meeting')}</span>
                        <span>{__('wizard.meeting.subtitle.add-existing-tasks-to-meeting')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddTaskIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddTasksToMeetingForm
                        onSubmit={this._handleSubmit}
                    />
                </div>
            </>
        );
    }
}

export default AddTasksToMeetingWizard;
