import React, { createRef } from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Revisions.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  dateFrom,
  getActiveStage,
  mapStateToProps,
  setTableParams,
  toBoolean,
} from '../../core/utils';
import TextareaAutosize from 'react-autosize-textarea';
import TableNA from '../../components/TableNA';
import {
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  updateSpecificationReadyForDelivery,
  updateSpecificationRevisionDescription,
} from '../../redux/actions/table/working_set_specifications';
import { setNotification } from '../../redux/actions/general/notification';
import Tooltip from '../../components/Tooltip';
import WarningIcon from '../../assets/images/warning-16x16.svg';
import autobind from 'autobind-decorator';
import ToggleButton from '../../components/ToggleButton';

@mapStateToProps((state) => ({
  flyout: state.table.working_set_specifications.flyout,
}))
class Revisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      textAreaError: false,
      isToggled: false,
    };

    this.textAreaRef = createRef();
  }

  componentDidMount() {
    this.setState({ isToggled: this.props.flyout.ready_for_delivery });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.flyout.revisions != this.props.flyout.revisions) {
      this.setState({ isToggled: this.props.flyout.ready_for_delivery });
    }
  }

  @autobind
  _handleReadyForDelivery(ready_for_delivery) {
    const {
      flyout: { id, revisions },
    } = this.props;

    this.setState({ isToggled: ready_for_delivery });

    updateSpecificationReadyForDelivery(id, {
      ready_for_delivery,
      revision_description: _.isEmpty(revisions)
        ? ''
        : this.textAreaRef.current.value,
    })
      .then(() =>
        readWorkingSetSpecification(id).then(({ response }) => {
          listWorkingSetSpecifications(getActiveStage());

          setNotification(
            __('working-set-specifications.revision.ready-for-delivery-updated')
          );

          setTableParams('working_set_specifications', {
            flyout: response.data,
          });
        })
      )
      .catch(() => {
        this.setState({ isToggled: false, textAreaError: true });
      });
  }

  render() {
    const { collapsed, flyout, readOnly } = this.props;
    const { active, textAreaError, isToggled } = this.state;

    const textarea = (
      <>
        <div
          className={classNames(
            styles.textarea,
            active && styles.active,
            (_.isEmpty(flyout.revisions) ||
              flyout.specification_delivery_id ||
              readOnly) &&
              styles.disabled,
            textAreaError && styles.error
          )}
        >
          <TextareaAutosize
            onFocus={() =>
              this.setState({ active: true, textAreaError: false })
            }
            onBlur={(e) => {
              this.setState({ active: false });
              const text = e.currentTarget.value;

              updateSpecificationRevisionDescription(flyout.id, text)
                .then(() => {
                  setNotification(
                    __(
                      'working-set-specifications.revisions.specification-revision-description-saved'
                    )
                  );

                  readWorkingSetSpecification(flyout.id).then(
                    ({ response }) => {
                      setTableParams('working_set_specifications', {
                        flyout: response.data,
                      });
                    }
                  );
                })
                .catch(() => {
                  this._handleReadyForDelivery(false);

                  this.setState({ textAreaError: true, isToggled: false });
                });
            }}
            rows={1}
            placeholder={__(
              'working-set-specifications.revisions.enter-description'
            )}
            maxRows={10}
            key={'revisions-' + _.size(flyout.revisions)}
            maxLength={500}
            disabled={flyout.specification_delivery_id}
            defaultValue={flyout.revision_description}
            ref={this.textAreaRef}
          />

          <div className={styles.bar} />
        </div>

        {textAreaError && (
          <div className={styles.textAreaErrorRow}>Description is required</div>
        )}
      </>
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.header}>
          <span className={styles.no}>
            {__('specifications.working-set.flyout.revisions.code')}
          </span>
          <span className={styles.date}>
            {__('specifications.working-set.flyout.revisions.date')}
          </span>
          <span className={styles.description}>
            {__('specifications.working-set.flyout.revisions.description')}
          </span>

          <span className={styles.readyForDelivery}>
            {__(
              'specifications.working-set.flyout.revisions.ready-for-delivery'
            )}
          </span>
        </div>
        <div className={styles.row}>
          <div className={styles.no}>
            <TableNA />
          </div>
          <div className={classNames(styles.date, styles.waiting)}>
            {flyout.specification_delivery_id
              ? __('specifications.working-set.revisions.waiting-approval')
              : __('specifications.working-set.revisions.waiting-delivery')}
          </div>
          <div className={styles.description} key={flyout.id}>
            {flyout.specification_delivery_id ? (
              <Tooltip
                text={__(
                  'working-set-specifications.revisions.revision-description-cannot-edited-approval-process-finished'
                )}
              >
                {textarea}
              </Tooltip>
            ) : (
              !_.isEmpty(flyout.revisions) && textarea
            )}
          </div>
          <Tooltip
            text={__(
              !flyout.main
                ? 'working-set-specifications.revisions.cannot-deliver-optional-specification'
                : ''
            )}
          >
            <div
              className={styles.readyForDelivery}
              key={_.size(flyout.revisions)}
            >
              <ToggleButton
                disabled={!flyout.main || readOnly}
                isToggled={isToggled}
                onToggle={() =>
                  this._handleReadyForDelivery(
                    !this.props.flyout.ready_for_delivery
                  )
                }
                value={isToggled}
              />
            </div>
          </Tooltip>
        </div>
        {_.map(
          _.orderBy(flyout.revisions, ['number'], ['desc']),
          (revision, i) => {
            const row = (
              <div
                className={classNames(
                  styles.row,
                  revision.specification_id != flyout.id && styles.faded
                )}
                key={i}
              >
                <div className={styles.no}>
                  R{revision.number}
                  {revision.status == 'canceled' && ' (canceled)'}
                  {revision.specification_id != flyout.id && <WarningIcon />}
                </div>
                <div className={styles.date}>
                  {dateFrom(revision.created_at)}
                </div>
                <div className={styles.description}>
                  {revision.description || (
                    <span className={styles.faded}>n/a</span>
                  )}
                </div>
                {toBoolean(flyout.main) && (
                  <div className={styles.readyForDelivery} />
                )}
              </div>
            );
            return revision.specification_id == flyout.id ? (
              row
            ) : (
              <Tooltip
                placement='left'
                text={__(
                  'working-set-specifications.revisions.revision-created-another-variant-same-code-specification'
                )}
              >
                {row}
              </Tooltip>
            );
          }
        )}
      </div>
    );
  }
}

export default Revisions;
