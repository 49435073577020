import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/merge_boq_item_wizard/MergeBOQItemForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({ form: 'merge_boq_item_wizard.merge_boq_item' })
@mapStateToProps((state) => ({
  specification:
    state.table.working_set_boq.merge_boq_item_wizard.specification,
  language_id: state.auth.language_id,
}))
class MergeBOQItemForm extends Component {
  render() {
    const { options, specification, language_id } = this.props;

    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const specification_subgroup =
      _.get(specification_subgroups, [specification.subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};

    const specification_code =
      getLocalized(specification_group.code, language_id, 1) +
      specification_subgroup.code +
      _.padStart(specification.code, 2, '0');

    const specification_title = _.filter(
      [
        _.get(specification.description, language_id),
        specification.model,
        specification.brand,
      ],
      _.identity
    ).join(' / ');

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <span className={styles.name}>
            <strong>{specification_code}</strong>
            {!_.isEmpty(specification_title) ? ' : ' : ''}
            {specification_title}
          </span>

          <Field
            name='move_action'
            component={RadioGroup}
            options={[
              {
                label: __('merge-boq-item.move-before'),
                value: 'before',
              },
              {
                label: __('merge-boq-item.move-after'),
                value: 'after',
              },
            ]}
          />

          <Field
            name={'boq_item_id'}
            component={Select}
            searchable
            noSort
            label={__('merge-boq-position.selected-item')}
            options={options}
            nestedList
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_boq', {
                merge_boq_item_wizard: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.move')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default MergeBOQItemForm;
