import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/translations/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../../core/utils";
import {setNotification} from "../../../redux/actions/general/notification";
import {listTranslations, updateTranslation} from "../../../redux/actions/table/superadmin/translations";
import EditTranslationForm from "../../../forms/superadmin/translations/EditTranslationForm";
import {listLocalization} from "../../../redux/actions/localization";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateTranslation(formData.id, _.omit(formData, ['id'])).then(() => {
                listTranslations();

                this.props.readAction(formData.id);

                listLocalization();

                setNotification(__('properties.notifications.properties-have-been-saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditTranslationForm
                    onSubmit={this._handleSubmit}
                    initialValues={{
                        ..._.pick(data, ['id', 'keyword', 'tag', 'translations']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
