import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/profile/PersonalDetailsForm.scss';
import Input from '../../components/Input';
import classNames from 'classnames';
import Select from '../../components/Select';
import people_addressing from '../../lists/people_addressing';
import MessageBigIcon from '../../assets/images/message-big-15x15.svg';
import LockedIcon from '../../assets/images/locked-15x15.svg';
import { connect } from 'react-redux';
import Localization from '../../helpers/Localization';
import { subscribe } from 'react-contextual';
import { __, transformToListPairs } from '../../core/utils';
import MultiSelect from '../../components/MultiSelect';
import * as _ from 'lodash';
import { enableAutosave } from '../../redux/actions/general/autosave';

const mapStateToProps = (state) => {
  return {
    values: getFormValues('profile.details')(state),
  };
};
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'profile.details',
  enableReinitialize: true,
})
@connect(mapStateToProps)
class PersonalDetailsForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  render() {
    const { values } = this.props;

    const available_disciplines = _.sortBy(
      this.props.localization.disciplines,
      'priority'
    );

    const { timezones } = this.props.localization;

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className={styles.flex}>
          <div className={classNames(styles.f1, styles.flex)}>
            <div className={styles.f1}>
              <Field
                name='title'
                component={Select}
                label={__('profile-settings.personal-details.title')}
                options={people_addressing}
              />
            </div>
            <div className={styles.f5}>
              <Field
                name='firstname'
                component={Input}
                label={__('profile-settings.personal-details.name')}
              />
            </div>
          </div>
          <div className={styles.f1}>
            <Field
              name='lastname'
              component={Input}
              label={__('profile-settings.personal-details.surname')}
            />
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='language_id'
              component={Select}
              searchable
              label={__('profile-settings.personal-details.app-language')}
              noSort
              options={transformToListPairs(
                _.filter(
                  this.props.localization.languages,
                  (language) => language.application_language == true
                ),
                'name'
              )}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='disciplines'
              noSort
              component={MultiSelect}
              searchable
              label={__('profile-settings.personal-details.profession')}
              options={transformToListPairs(
                available_disciplines,
                'profession'
              )}
            />
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='email'
              component={Input}
              label={__('profile-settings.personal-details.email')}
              hint={__(
                'profile-settings.personal-details.notification.email-change'
              )}
              disabled
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='phone_number'
              component={Input}
              label={__('personal-details.phone-number')}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='company'
              component={Input}
              label={__('profile-settings.personal-details.company')}
              hint={
                _.get(values, 'company')
                  ? __(
                      'profile-settings.personal-details.notification.company-change'
                    )
                  : ''
              }
              disabled
            />
          </div>
        </div>

        <div className={styles.flex}>
          <div className={styles.f2}>
            <Field
              name='timezone_id'
              component={Select}
              searchable
              label={__('profile-settings.personal-details.timezone')}
              options={transformToListPairs(timezones)}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default PersonalDetailsForm;
