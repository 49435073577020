import React, {Component} from "react";
import styles from "../styles/components/PasswordInput.scss";
import classNames from "classnames";
import VisibilityIcon from "../assets/images/visibility-15x15.svg";
import VisibilityDisabledIcon from "../assets/images/visibility-disabled-15x15.svg";
import autobind from "autobind-decorator";
import {__} from "../core/utils";
import Tooltip from "../components/Tooltip";

class PasswordInput extends Component {
    @autobind
	_toggleShow() {
		this.setState({ show: !_.get(this.state, "show", 1) });
	}

	render() {
		return (
			<div
				className={classNames(
					styles.wrapper,
					this.props.meta.active && styles.focus,
					this.props.center && styles.center,
					((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.error) && styles.error,
					!(_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) && this.props.success && styles.success,
					this.props.disabled && styles.disabled
				)}
			>
				{this.props.label && (
					<div className={styles.label}>
						<label htmlFor={this.props.input.name}>
							<Tooltip text={this.props.label}>
								{this.props.label}
							</Tooltip>
						</label>
					</div>
				)}
				<div className={styles.inputGroup}>
					<input
						{...this.props.input}
						id={this.props.input.name}
						disabled={this.props.disabled}
						type={_.get(this.state, "show", 1) ? "password" : "text"}
						maxLength={this.props.maxLength}
						autoComplete={this.props.autoComplete === false ? 'new-password' : undefined}
					/>
					{!this.props.center && !_.get(this.state, "show", 1) && <Tooltip text={__('password.tooltip.hide-password')}><VisibilityIcon onClick={this._toggleShow} /></Tooltip>}
					{!this.props.center && _.get(this.state, "show", 1) && <Tooltip text={__('password.tooltip.show-password')}><VisibilityDisabledIcon onClick={this._toggleShow} /></Tooltip>}
				</div>
				<div className={styles.bar} />
				<div
					className={classNames(
						styles.assist,
						((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.hint) && styles.hint,
						this.props.maxLength && styles.maxLength
					)}
				>
					{((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.hint) && <span>{_.get(this.props, "meta.error.0") || this.props.hint}</span>}
					{this.props.maxLength && (
						<span>
							{this.props.input.value.length} / {this.props.maxLength}
						</span>
					)}
				</div>
			</div>
		);
	}
}

export default PasswordInput;
