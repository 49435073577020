import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CreateSpecificationDeliveryWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setFormErrors,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import AddDeliveryIcon from '../assets/images/add-delivery-60x60.svg';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import {
  addToExistingSpecificationDelivery,
  createSpecificationDelivery,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
} from '../redux/actions/table/working_set_specifications';
import CreateSpecificationDeliveryForm from '../forms/create_specification_delivery_wizard/CreateSpecificationDeliveryForm';
import { SubmissionError } from 'redux-form';
import { resendConfirmationEmail } from '../redux/actions/auth';

@mapStateToProps((state) => ({
  table: state.table['working_set_specifications'],
}))
@withRouter
class CreateSpecificationDeliveryWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    if (!formData.new_delivery && !formData.delivery_id) {
      throw new SubmissionError({ delivery_id: 'Field required' });
    } else {
      const validation = validateForm(
        formData.new_delivery
          ? createSpecificationDelivery(formData.team_id, {
              specifications: _.map(
                _.filter(
                  this.props.table.selected_specifications,
                  (specification) => specification.team.id === formData.team_id
                ),
                (specification) => specification.id
              ),
              title: _.get(formData, 'title'),
              sent: _.get(formData, 'sent') ? 1 : 0,
            }).then(() => {
              listWorkingSetSpecifications(this.props.match.params.stage);

              if (this.props.table.clicked_row) {
                readWorkingSetSpecification(this.props.table.clicked_row).then(
                  ({ response }) => {
                    setTableParams('working_set_specifications', {
                      flyout: response.data,
                    });
                  }
                );
              }

              if (_.get(formData, 'sent')) {
                setNotification(
                  __(
                    'specifications.working-set.deliver-specification.notifications.create-delivery.approvers-will-be-notified'
                  )
                );
              } else {
                setNotification(
                  __(
                    'specifications.working-set.deliver-specification.notifications.create-delivery.successfully-created'
                  )
                );
              }

              this.props.onClose();
            })
          : addToExistingSpecificationDelivery(_.get(formData, 'delivery_id'), {
              specifications: _.map(
                this.props.table.selected_specifications,
                (specification) => specification.id
              ),
              sent: _.get(formData, 'sent') ? 1 : 0,
            }).then(() => {
              listWorkingSetSpecifications(this.props.match.params.stage);

              if (this.props.table.clicked_row) {
                readWorkingSetSpecification(this.props.table.clicked_row).then(
                  ({ response }) => {
                    setTableParams('working_set_specifications', {
                      flyout: response.data,
                    });
                  }
                );
              }

              if (_.get(formData, 'sent')) {
                setNotification(
                  __(
                    'specifications.working-set.deliver-specification.notifications.create-delivery.approvers-will-be-notified'
                  )
                );
              } else {
                setNotification(
                  __(
                    'specifications.working-set.deliver-specification.notifications.create-delivery.successfully-created'
                  )
                );
              }

              this.props.onClose();
            })
      );

      validation.catch(({ errors }) => {
        if (_.has(errors, 'code')) {
          if (errors.code === 1) {
            setNotification({
              type: 'warning',
              text: __(
                'specifications.working-set.deliver-specification.notifications.create-delivery.already-contains-specifications'
              ),
            });
          }
        }
      });

      return validation;
    }
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'specifications.working-set.deliver-specification.create-delivery'
              )}
            </span>
            <span>
              {__(
                'specifications.working-set.deliver-specification.create-delivery-selected-specifications-not sent-edit-later'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddDeliveryIcon />
          </div>
        </div>
        <div className={styles.body}>
          <CreateSpecificationDeliveryForm
            onSubmit={this._handleSubmit}
            initialValues={{
              new_delivery: true,
            }}
          />
        </div>
      </>
    );
  }
}

export default CreateSpecificationDeliveryWizard;
