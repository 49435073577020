import store from "../../../../core/store";

export const listProductAttributeTemplateFields = () => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/LIST_PRODUCT_ATTRIBUTE_TEMPLATE_FIELDS",
        method: "GET",
        endpoint: "/superadmin/product_attribute_template_fields",
        main: true
    });
};

export const createProductAttributeTemplateField = (formData) => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/CREATE_PRODUCT_ATTRIBUTE_TEMPLATE_FIELD",
        method: "POST",
        endpoint: "/superadmin/product_attribute_template_fields",
        data: formData
    });
};

export const deleteProductAttributeTemplateFields = (product_attribute_template_field_ids) => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/DELETE_PRODUCT_ATTRIBUTE_TEMPLATE_FIELDS",
        method: "DELETE",
        endpoint: "/superadmin/product_attribute_template_fields",
        data: {
            product_attribute_template_fields: product_attribute_template_field_ids
        }
    });
};

export const readProductAttributeTemplateField = (product_attribute_template_field_id) => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/READ_PRODUCT_ATTRIBUTE_TEMPLATE_FIELD",
        method: "GET",
        endpoint: "/superadmin/product_attribute_template_fields/" + product_attribute_template_field_id,
        has404: true
    });
};

export const updateProductAttributeTemplateField = (product_attribute_template_field_id, formData) => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/UPDATE_PRODUCT_ATTRIBUTE_TEMPLATE_FIELD",
        method: "PUT",
        endpoint: "/superadmin/product_attribute_template_fields/" + product_attribute_template_field_id,
        data: formData
    });
};

export const listPossibleProductAttributeTemplateGroups = () => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/LIST_POSSIBLE_PRODUCT_ATTRIBUTE_TEMPLATE_GROUPS",
        method: "GET",
        endpoint: "/superadmin/possible_product_attribute_template_groups",
    });
};

export const addProductAttributeTemplateFieldOption = (product_attribute_template_field_id) => {
    return store.dispatch({
        action: "table.superadmin_product_attribute_template_fields/ADD_PRODUCT_ATTRIBUTE_TEMPLATE_FIELD_OPTION",
        method: "POST",
        endpoint: "/superadmin/product_attribute_template_fields/" + product_attribute_template_field_id + "/options",
    });
};
