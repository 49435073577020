import React, { Component } from 'react';
import styles from '../styles/components/TeamPeopleChooser.scss';
import classNames from 'classnames';
import ClickOutside from 'react-click-outside';
import SearchIcon from '../assets/images/search-15x15.svg';
import * as _ from 'lodash';
import PlusMiddleIcon from '../assets/images/plus-middle-15x15.svg';
import autobind from 'autobind-decorator';
import AvatarImage from '../assets/images/avatar.png';
import Image from './Image';
import Tooltip from '../components/Tooltip';
import { __, scrollToBottom } from '../core/utils';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import { isEmail } from 'validator';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import FieldComponent from './FieldComponent';
import OutsideClickWrapper from './OutsideClickWrapper';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import { isMobileOnly } from 'react-device-detect';
import slugify from 'slugify';

@FieldComponent
class TeamPeopleChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.meta.active != this.props.meta.active) {
      this.setState(
        {
          query: '',
          selected: this.props.input.value || [],
        },
        () => {
          this.props.recalculateOffset();
        }
      );
    }

    if (
      prevState.selected != this.state.selected ||
      prevState.query != this.state.query
    ) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.props.input.onFocus();
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _handleSave() {
    this.props.input.onChange(this.state.selected);

    this._handleClose();
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _addMember(member) {
    this.setState(
      {
        selected: [
          ...this.state.selected,
          {
            id: member.value,
            name: member.label,
            role: 'editor',
            avatar_url: member.avatar_url,
          },
        ],
      },
      () => {
        scrollToBottom(this.refs.invites);
      }
    );
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    this.refs.search.focus();
  }

  @autobind
  _inviteEmail() {
    this.setState(
      {
        selected: [
          ...this.state.selected,
          {
            name: this.state.query.toLowerCase(),
            role: 'editor',
            invite: true,
          },
        ],
        query: '',
      },
      () => {
        scrollToBottom(this.refs.invites);
      }
    );
  }

  @autobind
  _handleRoleDropdown(e, item_id) {
    e.stopPropagation();

    this.setState({ role_dropdown: item_id });
  }

  @autobind
  _changeRole(item_id, role) {
    const selected = _.cloneDeep(this.state.selected);

    selected[item_id]['role'] = role;

    this.setState({
      selected,
      role_dropdown: undefined,
    });
  }

  @autobind
  _removeMember(item_id) {
    const selected = _.cloneDeep(this.state.selected);

    _.unset(selected, item_id);

    this.setState({
      selected: _.reject(selected, _.isEmpty),
    });
  }

  _roleValueToLabel(role) {
    switch (role) {
      case 'viewer':
        return 'Viewer';
      case 'reviewer':
        return 'Reviewer';
      case 'editor':
        return 'Editor';
    }
  }

  render() {
    const {
      options,
      disabled,
      label,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
    } = this.props;
    const { query, selected, role_dropdown } = this.state;
    const { active } = this.props.meta;
    const { value, name } = this.props.input;
    const avatars_limit = 16;

    let available_values;

    if (this.props.meta.active) {
      available_values = _.sortBy(
        _.filter(
          options,
          (item) =>
            !_.find(selected, { id: item.value }) &&
            (_.isEmpty(query) ||
              _.includes(
                slugify(item.label.toLowerCase()),
                slugify(query.toLowerCase())
              ) ||
              _.includes(item.email.toLowerCase(), query.toLowerCase()))
        ),
        'label'
      );
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          _.isEmpty(value) && styles.placeholder,
          active && styles.focus,
          disabled && styles.disabled,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error
        )}
      >
        <div className={styles.label}>
          <label onClick={this._handleOpen} htmlFor={name}>
            {label}
            {!_.isEmpty(value) && ' (' + _.size(value) + ')'}
          </label>
        </div>
        <div
          className={styles.inputGroup}
          ref={elementRef}
          onClick={this._handleOpen}
        >
          <div className={styles.input} ref='input'>
            {!_.isEmpty(value) && (
              <>
                {_.map(_.take(value, avatars_limit), (member, i) => (
                  <Tooltip key={i} text={member.name}>
                    <Image
                      src={member.id && member.avatar_url}
                      default={AvatarImage}
                    />
                  </Tooltip>
                ))}
                {_.size(value) > avatars_limit && (
                  <Tooltip
                    text={_.map(
                      _.slice(value, avatars_limit),
                      (member) => member.name
                    ).join('\n')}
                  >
                    <div className={styles.more}>
                      <span>+ {_.size(value) - avatars_limit}</span>
                    </div>
                  </Tooltip>
                )}
              </>
            )}
          </div>
          <PlusMiddleIcon />
        </div>
        {active && (
          <OutsideClickWrapper closable={false}>
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              {!_.isEmpty(selected) && (
                <div className={styles.selected}>
                  <div className={styles.title}>
                    {__('peoplechooser.title.currently-selected')}
                  </div>
                  {!_.isUndefined(role_dropdown) && (
                    <ClickOutside
                      className={styles.roleDropdown}
                      onClickOutside={() => {
                        this.setState({ role_dropdown: undefined });
                      }}
                    >
                      <div
                        className={classNames(
                          styles.role,
                          _.get(selected, [role_dropdown, 'role']) ==
                            'viewer' && styles.active
                        )}
                        onClick={() =>
                          this._changeRole(role_dropdown, 'viewer')
                        }
                      >
                        Viewer
                        <span />
                      </div>
                      <div
                        className={classNames(
                          styles.role,
                          _.get(selected, [role_dropdown, 'role']) ==
                            'reviewer' && styles.active
                        )}
                        onClick={() =>
                          this._changeRole(role_dropdown, 'reviewer')
                        }
                      >
                        Reviewer <span />
                      </div>
                      <div
                        className={classNames(
                          styles.role,
                          _.get(selected, [role_dropdown, 'role']) ==
                            'editor' && styles.active
                        )}
                        onClick={() =>
                          this._changeRole(role_dropdown, 'editor')
                        }
                      >
                        Editor <span />
                      </div>
                    </ClickOutside>
                  )}
                  <div className={styles.list} ref='invites'>
                    {_.map(selected, (item, i) => (
                      <div
                        className={styles.row}
                        key={i}
                        onClick={() => this._removeMember(i)}
                      >
                        <Image
                          src={item.id && item.avatar_url}
                          default={AvatarImage}
                        />
                        <Tooltip text={item.name}>
                          <span>{item.name}</span>
                        </Tooltip>
                        <div
                          className={styles.role}
                          onClick={(e) => this._handleRoleDropdown(e, i)}
                        >
                          {this._roleValueToLabel(item.role)}{' '}
                          <ArrowDownSmallIcon />
                        </div>
                        <CloseSmallIcon className={styles.close} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.available}>
                <div className={styles.title}>
                  {__('peoplechooser.title.available-members')}
                </div>
                <div className={styles.desc}>
                  {__('peoplechooser.description.invite-new-member')}
                </div>
                <div className={styles.search}>
                  <SearchIcon className={styles.searchIcon} />
                  <input
                    type='text'
                    value={query}
                    autoFocus={true}
                    ref='search'
                    placeholder={__('peoplechooser.title.search')}
                    onChange={this._handleSearch}
                  />
                  {!_.isEmpty(query) && (
                    <CloseSmallIcon
                      className={styles.close}
                      onClick={this._handleClearSearch}
                    />
                  )}
                </div>
                <div className={styles.list}>
                  {_.map(available_values, (item, i) => (
                    <div
                      className={styles.row}
                      key={i}
                      onClick={() => this._addMember(item)}
                    >
                      <Image src={item.avatar_url} default={AvatarImage} />
                      <Tooltip text={item.label}>
                        <span>{item.label}</span>
                      </Tooltip>
                      <PlusMiddleIcon className={styles.plus} />
                    </div>
                  ))}
                  {_.isEmpty(available_values) &&
                    (isEmail(query) ? (
                      <div className={styles.invite}>
                        {_.find(
                          selected,
                          (item) =>
                            !_.isUndefined(item.invite) &&
                            query.toLowerCase() == item.name
                        ) ? (
                          __('peoplechooser.invite.email-already-added')
                        ) : (
                          <>
                            {__('peoplechooser.invite.email-valid')}
                            <div className={styles.buttons}>
                              <button type='button' onClick={this._inviteEmail}>
                                {__('peoplechooser.invite.invite-to-volum3')}
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={styles.noResults}>
                        {!_.isEmpty(
                          _.filter(
                            options,
                            (item) => !_.find(selected, { id: item.value })
                          )
                        ) && (
                          <>
                            {__('peoplechooser.search.no-registered-members')}
                            <br />
                          </>
                        )}
                        {__('peoplechooser.search.unregistered-member-invite')},
                        <br />
                        {__('peoplechooser.search.enter-valid-email')}
                        <br />
                      </div>
                    ))}
                </div>
              </div>
              <div className={styles.footer}>
                <ButtonGroup right>
                  <Button
                    lightGray
                    medium
                    middleText={__('button.cancel')}
                    onClick={this._handleClose}
                  />
                  <Button
                    lightBlue
                    medium
                    middleText={__('button.done')}
                    onClick={this._handleSave}
                  />
                </ButtonGroup>
              </div>
            </div>
          </OutsideClickWrapper>
        )}
        <div className={styles.bar} />
        <div
          className={classNames(
            styles.assist,
            ((_.get(this.props, 'meta.error') &&
              _.get(this.props, 'meta.touched')) ||
              this.props.hint) &&
              styles.hint
          )}
        >
          {((_.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched')) ||
            this.props.hint) && (
            <span>{_.get(this.props, 'meta.error') || this.props.hint}</span>
          )}
        </div>
      </div>
    );
  }
}

export default TeamPeopleChooser;
