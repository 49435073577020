import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../../styles/forms/superadmin/add_boq_template_calculation_wizard/AddBOQTemplateCalculationForm.scss';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import Input from '../../../components/Input';
import {
  __,
  mapStateToProps,
  setTableParams,
  getLocalized,
} from '../../../core/utils';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import ButtonGroup from '../../../components/ButtonGroup';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import { listLocalization } from '../../../redux/actions/localization';

@withRouter
@reduxForm({
  form: 'superadmin.add_boq_template_calculation_wizard.add_boq_template_calculation',
})
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  values: getFormValues(
    'superadmin.add_boq_template_calculation_wizard.add_boq_template_calculation'
  )(state),
  application_language_id: state.auth.language_id,
}))
class AddBOQTemplateCalculationForm extends Component {
  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  componentDidMount() {
    listLocalization();
  }

  render() {
    const language_id = this.props.application_language_id;

    const application_languages = _.transform(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (languages, language) => {
        languages[language.id] = language.id == language_id;

        return languages;
      },
      {}
    );

    const boq_template_subgroups = _.map(
      this.props.localization.boq_template_subgroups,
      (subgroup) => {
        return {
          value: subgroup.id,
          label: getLocalized(subgroup.name, language_id),
          group: getLocalized(
            _.get(this.props.localization.boq_template_groups, [
              subgroup.boq_template_group_id,
              'name',
            ]),
            language_id
          ),
        };
      }
    );

    const units = _.sortBy(
      _.map(this.props.localization.units, (unit) => ({
        label: unit.symbol,
        value: unit.id,
        tooltip: unit.description,
        group: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'name',
          1,
        ]),
        groupPriority: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'priority',
        ]),
      })),
      'groupPriority'
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='name'
            localized={application_languages}
            component={Input}
            label='Name'
          />
          <Field
            name='boq_template_subgroup_id'
            component={Select}
            searchable
            options={boq_template_subgroups}
            label='BOQ Template Subgroup'
          />

          <Field
            name='price'
            component={Input}
            label='BOQ Template Price'
            maxLength={50}
            numeric
            decimal
          />
          <Field
            name='unit_id'
            component={Select}
            label='BOQ Template Unit'
            searchable
            options={units}
            noSort
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('superadmin_boq_template_calculations', {
                add_boq_template_calculation_wizard: undefined,
              })
            }
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.create')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(false)}
            />
            <Button
              lightBlue
              medium
              middleText={__('button.create-edit')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(true)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default AddBOQTemplateCalculationForm;
