import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/products/ProductsFlyout.scss';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../../core/utils';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import GlobeIcon from '../../assets/images/globe-16x16.svg';
import * as _ from 'lodash';
import VisibilityIcon from '../../assets/images/visibility-16x16.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import ProductPartIcon from '../../assets/images/product-part-24x24.svg';
import ProductIcon from '../../assets/images/product-24x24.svg';
import Select from '../../components/Select';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import DetailsForm from '../../forms/products/DetailsForm';
import autobind from 'autobind-decorator';
import BasicDataForm from '../../forms/products/BasicDataForm';
import PriceForm from '../../forms/products/PriceForm';
import Images from './Images';
import Files from './Files';
import Attributes from './Attributes';
import Notes from './Notes';
import Parts from './Parts';
import { setNotification } from '../../redux/actions/general/notification';
import {
  copyProduct,
  listProducts,
  readProduct,
  updateProductBasicData,
  updateProductDetails,
  updateProductPrice,
  updateProductType,
} from '../../redux/actions/table/products';
import Categories from './Categories';
import DuplicateIcon from '../../assets/images/duplicate-16x16.svg';
import Variants from './Variants';
import Accessory from './Accessory';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.products,
}))
class ProductsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language_switcher: false,
      collapsed_tabs: ['properties'], //fix for scrollSpy not working on init
      highlighted_tab: null,
      type_switcher: false,
    };

    setTableParams('products', {
      language_id: _.first(props.store.flyout.languages),
    });

    this.highlight_timeout = null;
  }

  componentDidMount() {
    scrollSpy.update();

    this.setState({
      collapsed_tabs: [],
    });
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    const all_tabs = [
      'properties',
      'details',
      'categories',
      'attributes',
      'price',
      // 'images',
      'files',
      'clauses',
      'parts',
      'accessory',
      // 'variants'
    ];

    if (_.size(this.state.collapsed_tabs) == _.size(all_tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: all_tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  @autobind
  _handleBasicDataSubmit(formData) {
    formData = {
      ...formData,
      description: Object.assign({}, formData.description),
    };

    return validateForm(
      updateProductBasicData(this.props.store.flyout.id, formData).then(() => {
        readProduct(this.props.store.flyout.id).then(({ response }) => {
          setTableParams('products', {
            flyout: response.data,
          });
        });

        setNotification(__('products.flyout.product-details.updated'));
      })
    );
  }

  @autobind
  _handleDetailsSubmit(formData) {
    formData = {
      ...formData,
      description_long: Object.assign({}, formData.description_long),
    };

    return validateForm(
      updateProductDetails(this.props.store.flyout.id, formData).then(() => {
        setNotification(
          __('product-details.updated.products.flyout.notification')
        );
      })
    );
  }

  @autobind
  _handlePriceSubmit(formData) {
    formData = {
      ...formData,
      price_note: Object.assign({}, formData.price_note),
    };

    return validateForm(
      updateProductPrice(this.props.store.flyout.id, formData).then(() => {
        readProduct(this.props.store.flyout.id).then(({ response }) => {
          setTableParams('products', {
            flyout: response.data,
          });
        });

        setNotification(
          __('products.flyout.product-details-have-been-updated')
        );
      })
    );
  }

  render() {
    const { language_id, flyout } = this.props.store;

    const {
      language_switcher,
      collapsed_tabs,
      highlighted_tab,
      type_switcher,
    } = this.state;

    const languages = _.map(flyout.languages, (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      code: _.get(this.props.localization.languages, [language_id, 'code']),
      value: language_id,
    }));

    const types = [
      {
        label: 'Product',
        value: 'product',
      },
      {
        label: 'Part',
        value: 'part',
      },
    ];

    const TabLink = ScrollLink((props) => (
      <span
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    let total_tabs = 9;

    if (flyout.type == 'product') {
      total_tabs++;
    }

    let brand_id;

    if (!flyout.brand_address_book_company_id) {
      brand_id = '0-' + flyout.brand_id;
    }

    if (flyout.brand_address_book_company_id) {
      brand_id = '1-' + flyout.brand_address_book_company_id;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              {flyout.type == 'product' && (
                <Tooltip text={__('products.table-titles.type.product')}>
                  <ProductIcon />
                </Tooltip>
              )}
              {flyout.type == 'part' && (
                <Tooltip text={__('products.table-titles.type.part')}>
                  <ProductPartIcon />
                </Tooltip>
              )}
              <strong>{'P-' + _.padStart(flyout.id, 4, '0')}:</strong>
              <span>
                {flyout.model} by {flyout.brand}
              </span>
            </div>
            <Tooltip text={__('products.flyout.close-product-flyout')}>
              <CloseBigIcon
                className={styles.close}
                onClick={() =>
                  setTimeout(() => {
                    setTableParams('products', {
                      clicked_row: undefined,
                    });
                  }, 100)
                }
              />
            </Tooltip>
          </div>
          <div className={styles.actions}>
            <Tooltip text={__('products.flyout.enter-view-mode')}>
              <VisibilityIcon
                onClick={() =>
                  setTimeout(() => {
                    setTableParams('products', {
                      edit_mode: false,
                    });
                  }, 50)
                }
              />
            </Tooltip>
            <Tooltip text={__('products.flyout.tooltip.duplicate')}>
              <DuplicateIcon
                onClick={(e) => {
                  e.stopPropagation();

                  copyProduct(flyout.id).then(({ response }) => {
                    setNotification(
                      __('products.flyout.notification.successfully-copied')
                    );

                    listProducts();

                    setTableParams('products', {
                      clicked_row: response.data.id,
                      actioned_row: undefined,
                      selected_rows: [],
                    });
                  });
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.toolbar}>
          <div className={styles.type}>
            <Select
              input={{
                value: flyout.type,
                onChange: (type) =>
                  updateProductType(flyout.id, {
                    type,
                  }).then(() => {
                    setNotification(
                      __(
                        'products-flyout.notification-successfully-updated-type'
                      )
                    );

                    readProduct(flyout.id).then(({ response }) => {
                      setTableParams('products', {
                        flyout: response.data,
                      });
                    });
                  }),
                onBlur: () => this.setState({ type_switcher: false }),
              }}
              meta={{
                active: type_switcher,
              }}
              label={__('products-flyout.product-type')}
              options={types}
              left={-75}
              top={40}
              noSort
              dropdown
              wrapper={(handleOpen, activeItem) => (
                <Tooltip text={__('products-flyout.change-product-type')}>
                  <div
                    className={styles.typeSwitcher}
                    onClick={() => this.setState({ type_switcher: true })}
                  >
                    {_.get(activeItem, 'label')}
                    <ArrowDownSmallIcon className={styles.arrow} />
                  </div>
                </Tooltip>
              )}
            />
          </div>
          {!flyout.public ? (
            <Tooltip text='Draft'>
              <div className={styles.draft}>Draft</div>
            </Tooltip>
          ) : (
            <Tooltip text='Public'>
              <div className={styles.public}>Public</div>
            </Tooltip>
          )}
          {flyout.discontinued ? (
            <Tooltip text={__('products.flyout.tooltip.product-discontinued')}>
              <div className={styles.discontinued}>
                {__('products.flyout.discontinued')}
              </div>
            </Tooltip>
          ) : (
            ''
          )}
          {language_id && (
            <div className={styles.language}>
              <Select
                input={{
                  value: language_id,
                  onChange: (language_id) =>
                    setTableParams('products', {
                      language_id,
                    }),
                  onBlur: () => this.setState({ language_switcher: false }),
                }}
                meta={{
                  active: language_switcher,
                }}
                label={__('products.flyout.product-language')}
                options={languages}
                left={-75}
                top={40}
                noSort
                dropdown
                icon={<GlobeIcon className={styles.globeIcon} />}
                wrapper={(handleOpen, activeItem) => (
                  <Tooltip text={__('products.flyout.change.product.language')}>
                    <div
                      className={styles.languageSwitcher}
                      onClick={() => this.setState({ language_switcher: true })}
                    >
                      <GlobeIcon className={styles.icon} />
                      {_.get(activeItem, 'code')}
                      <ArrowDownSmallIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                )}
              />
            </div>
          )}
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == total_tabs && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>
              {__('products.flyout.tabs.properties')}
            </Tab>
            <Tab target='details'>{__('products.flyout.tabs.details')}</Tab>
            <Tab target='categories'>
              {__('products.flyout.tabs.categories')}
            </Tab>
            <Tab target='configurator'>
              {__('products.flyout.tabs.configurator')}
            </Tab>
            <Tab target='variants'>{__('products.flyout.tabs.variants')}</Tab>
            <Tab target='attributes'>
              {__('products.flyout.tabs.attributes')}
            </Tab>
            {flyout.type == 'product' && (
              <Tab target='parts'>{__('products.flyout.tabs.parts')}</Tab>
            )}
            <Tab target='clauses'>
              {__('products.flyout.tabs.instructions')}
            </Tab>
            <Tab target='price'>{__('products.flyout.tabs.price')}</Tab>
            {/* <Tab target='images'>{__('products.flyout.tabs.images')}</Tab> */}
            <Tab target='files'>{__('products.flyout.tabs.files')}</Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.properties')}
            </div>
            <BasicDataForm
              collapsed={_.includes(collapsed_tabs, 'properties')}
              onSubmit={this._handleBasicDataSubmit}
              initialValues={{
                ..._.pick(flyout, [
                  'model',
                  'brand_id',
                  'description',
                  'languages',
                ]),
                brand_id,
              }}
            />
          </Element>
          <Element
            name='details'
            className={classNames(
              styles.section,
              highlighted_tab == 'details' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('details')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'details') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.details')}
            </div>
            <DetailsForm
              collapsed={_.includes(collapsed_tabs, 'details')}
              onSubmit={this._handleDetailsSubmit}
              initialValues={_.pick(flyout, [
                'description_long',
                'family',
                'collection',
                'designer',
              ])}
            />
          </Element>
          <Element
            name='categories'
            className={classNames(
              styles.section,
              highlighted_tab == 'categories' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('categories')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'categories') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.categories')}
            </div>
            <Categories collapsed={_.includes(collapsed_tabs, 'categories')} />
          </Element>
          <Element
            name='configurator'
            className={classNames(
              styles.section,
              highlighted_tab == 'configurator' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('configurator')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'configurator') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.configurator')}
            </div>
            <Attributes
              collapsed={_.includes(collapsed_tabs, 'configurator')}
              attribute_groups={_.sortBy(
                _.filter(
                  flyout.attribute_groups,
                  (item) => item.is_configurator
                ),
                'priority'
              )}
              is_configurator
            />
          </Element>
          <Element
            name='variants'
            className={classNames(
              styles.section,
              highlighted_tab == 'variants' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('variants')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'variants') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.variants')}
            </div>
            <Variants collapsed={_.includes(collapsed_tabs, 'variants')} />
          </Element>
          <Element
            name='attributes'
            className={classNames(
              styles.section,
              highlighted_tab == 'attributes' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('attributes')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'attributes') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.attributes')}
            </div>
            <Attributes
              collapsed={_.includes(collapsed_tabs, 'attributes')}
              attribute_groups={_.sortBy(
                _.filter(
                  flyout.attribute_groups,
                  (item) => !item.is_configurator
                ),
                'priority'
              )}
            />
          </Element>
          {flyout.type == 'product' && (
            <Element
              name='parts'
              className={classNames(
                styles.section,
                highlighted_tab == 'parts' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('parts')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'parts') && styles.collapsed
                  )}
                />
                {__('products.flyout.tabs.parts')}
              </div>
              <Parts collapsed={_.includes(collapsed_tabs, 'parts')} />
            </Element>
          )}
          <Element
            name='clauses'
            className={classNames(
              styles.section,
              highlighted_tab == 'clauses' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('clauses')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'clauses') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.instructions')}
            </div>
            <Notes collapsed={_.includes(collapsed_tabs, 'clauses')} />
          </Element>
          <Element
            name='price'
            className={classNames(
              styles.section,
              highlighted_tab == 'price' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('price')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'price') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.price')}
            </div>
            <PriceForm
              collapsed={_.includes(collapsed_tabs, 'price')}
              onSubmit={this._handlePriceSubmit}
              initialValues={_.pick(flyout, [
                'price_from',
                'price_to',
                'currency_id',
                'price_date',
                'units',
                'price_note',
                'discontinued',
              ])}
            />
          </Element>
          {/* <Element
            name='images'
            className={classNames(
              styles.section,
              highlighted_tab == 'images' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('images')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'images') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.images')}
            </div>
            <Images collapsed={_.includes(collapsed_tabs, 'images')} />
          </Element> */}
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.files')}
            </div>
            <Files collapsed={_.includes(collapsed_tabs, 'files')} />
          </Element>

          {flyout.type == 'product' && (
            <Element
              name='accessory'
              className={classNames(
                styles.section,
                highlighted_tab == 'accessory' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('accessory')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'accessory') && styles.collapsed
                  )}
                />
                {__('products.flyout.tabs.accessories')}
              </div>
              <Accessory collapsed={_.includes(collapsed_tabs, 'accessory')} />
            </Element>
          )}
        </div>
      </div>
    );
  }
}

export default ProductsFlyout;
