import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/company_info_wizard/OfficesForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import MinusMiddleIcon from "../../assets/images/minus-middle-15x15.svg";
import Button from "../../components/Button";
import {withRouter} from "react-router";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {__, transformToListPairs} from "../../core/utils";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import ButtonGroup from "../../components/ButtonGroup";
import {hideCreateCompanyWizard} from "../../redux/actions/profile";

@subscribe(Localization, "localization")

class OfficesForm extends Component {
	render_emails = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("Email") + " " + (index + 1) : __("Email")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	render_phones = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("Phone") + " " + (index + 1) : __("Phone")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.content}>
					<div className={styles.offices}>
						<span className={styles.active}>{__('Headquarters')}</span>
					</div>
					<div className={styles.form}>
						<div className={styles.title}>{__('Headquarters')}</div>
						<div className={styles.flex}>
							<div className={styles.f1}>
								<Field name="country_id" component={Select} searchable label={__('Country')} options={transformToListPairs(this.props.localization.countries)} />
								<Field name="address" component={Input} label={__('Street address')} />
								<Field name="city" component={Input} label={__('City')} />
								<Field name="zip" component={Input} label={__('Zip zode')} />
								<Field name="state" component={Input} label={__('Province / State')} />
							</div>
							<div className={styles.f1}>
								<FieldArray name="emails" component={this.render_emails} />
								<FieldArray name="phones" component={this.render_phones} />
							</div>
						</div>
					</div>
				</div>
				<div className={styles.footer}>
					<Button lightGray medium left middleText={__('button.cancel')} onClick={hideCreateCompanyWizard} />
					<ButtonGroup right>
						<Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
						<Button submit medium lightBlue leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

export default withRouter(
	reduxForm({
		form: "company_info_wizard.offices",
		destroyOnUnmount: false
	})(OfficesForm)
);
