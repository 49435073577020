import React, { Component } from "react";
import styles from "../styles/components/Input.scss";
import classNames from "classnames";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../helpers/Localization";
import autobind from "autobind-decorator";
import Cleave from "cleave.js/dist/cleave-react-node";
import { __, mapStateToProps } from "../core/utils";
import { registerMultieditField, unregisterMultieditField } from "../redux/actions/general/multiedit";
import Tooltip from "./Tooltip";
import InfoIcon from "../assets/images/info-positive-16x16.svg";

@mapStateToProps(state => ({
	multiedit: state.general.multiedit
}))
@subscribe(Localization, "localization")
class Input extends Component {
	constructor(props) {
		super(props);

		this.state = {
			active_language: _.findKey(this.props.localized, item => item == true),
		};
	}

	@autobind
	_handleOnChange(e) {
		if (this.props.localized) {
			const value = this.props.input.value == "" ? {} : _.cloneDeep(this.props.input.value);

			_.set(value, this.state.active_language, e.currentTarget.value);

			this.props.input.onChange(_.pickBy(value, translation => !_.isEmpty(translation)));
		} else {
			this.props.input.onChange(e.currentTarget.value);
		}
	}

	@autobind
	_handleOnFocus() {
		this.props.input.onFocus();

		const multiedit_enabled = _.isObject(this.props.multiedit[this.props.meta.form]);

		multiedit_enabled && registerMultieditField(this.props.meta.form, this.props.input.name);
	}

	render() {
		const multiedit_enabled = _.isObject(this.props.multiedit[this.props.meta.form]);
		const multiedited = multiedit_enabled && _.includes(_.get(this.props.multiedit, [this.props.meta.form, 'fields']), this.props.input.name);
		const LeftIcon = this.props.leftIcon;
		const RightIcon = this.props.rightIcon;
		const { leftIconTooltip, rightIconTooltip } = this.props;

		const inputType = _.get(this.props, 'type', 'text');

		const value = this.props.localized ? _.get(this.props.input.value, this.state.active_language, '') : this.props.input.value;

		const languages = _.map(_.orderBy(_.map(this.props.localized, (primary, language_id) => ({
			primary,
			language_id
		})), ['primary'], ['desc']), 'language_id');

		let options = {};

		if (this.props.decimal) {
			options = {
				numeral: true,
				numeralThousandsGroupStyle: 'none',
				numeralDecimalScale: _.get(this.props, 'decimals', _.get(this.props, 'decimals', 2))
			};
		}

		let placeholder = '';

		if (multiedit_enabled) {
			placeholder = multiedited ? '' : '-- no change --';
		}

		return (
			<div
				className={classNames(
					styles.wrapper,
					this.props.meta.active && styles.focus,
					this.props.center && styles.center,
					_.get(this.props, "meta.error") && _.get(this.props, "meta.touched") && styles.error,
					this.props.disabled && styles.disabled,
					multiedit_enabled && !multiedited && styles.noChange,
					this.props.hidden && styles.hidden,
					this.props.wrapperClassName,
				)}
			>
				{this.props.label && (
					<div className={styles.label}>
						<label htmlFor={this.props.input.name}>
							<Tooltip text={this.props.labelTooltip || this.props.label}>
								{this.props.label}
							</Tooltip>
						</label>
						{multiedit_enabled && multiedited && <span className={styles.labelRevertChanges} onClick={() => unregisterMultieditField(this.props.meta.form, this.props.input.name)}>{__('button.revert-changes')}</span>}
					</div>
				)}
				<div className={styles.inputGroup}>
					{this.props.leftIcon && !this.props.center && <>
						{leftIconTooltip ? <Tooltip text={leftIconTooltip}>
							<LeftIcon onClick={this.props.onLeftIconClick} className={this.props.onLeftIconClick && styles.hasAction} />
						</Tooltip> : <LeftIcon onClick={this.props.onLeftIconClick} className={this.props.onLeftIconClick && styles.hasAction} />}
					</>}
					<Tooltip text={this.props.disabled ? value : ''}>
						{this.props.numeric ? <Cleave
							options={{ numericOnly: true, ...options, ...this.props.options }}
							onBlur={() => this.props.onBlur ? this.props.onBlur() : this.props.input.onBlur()}
							onFocus={() => this.props.onFocus ? this.props.onFocus() : this._handleOnFocus()}
							value={value}
							onChange={this.props.onChange || this._handleOnChange}
							id={this.props.input.name}
							disabled={this.props.disabled}
							type={inputType}
							autoComplete={this.props.autoComplete || 'off'}
							placeholder={placeholder}
							maxLength={this.props.maxLength}
							style={{
								textAlign: this.props.align
							}}
						/> : <input
							onBlur={() => this.props.onBlur ? this.props.onBlur() : this.props.input.onBlur()}
							onFocus={() => this.props.onFocus ? this.props.onFocus() : this._handleOnFocus()}
							value={value || ""}
							onChange={this.props.onChange || this._handleOnChange}
							id={this.props.input.name}
							disabled={this.props.disabled}
							type={inputType}
							autoComplete={this.props.autoComplete || 'off'}
							placeholder={placeholder}
							maxLength={this.props.maxLength}
							style={{
								textAlign: this.props.align
							}}
						/>}
					</Tooltip>
					{this.props.rightIcon && !this.props.center && <>
						{rightIconTooltip ? <Tooltip text={rightIconTooltip}>
							<RightIcon onClick={this.props.onRightIconClick} className={classNames(this.props.onRightIconClick && styles.hasAction, this.props.rightIconDisabled && styles.iconDisabled)} />
						</Tooltip> : <RightIcon onClick={this.props.onRightIconClick} className={classNames(this.props.onRightIconClick && styles.hasAction, this.props.rightIconDisabled && styles.iconDisabled)} />}
					</>}
				</div>
				<div className={styles.bar} />
				<div
					className={classNames(
						styles.assist,
						((_.get(this.props, "meta.error") && !this.props.localized && _.get(this.props, "meta.touched")) || this.props.hint) && styles.hint,
						this.props.maxLength && styles.maxLength
					)}
				>
					{this.props.localized ? (
						<div className={styles.localization}>
							{_.map(languages, (language) => (
								<Tooltip key={language} text={_.get(this.props.localization, ['languages', language, 'name']) + ": " + _.size(_.trim(_.get(this.props.input.value, language))) + " characters"}>
									<div onClick={() => this.setState({ active_language: language })} className={classNames(styles.language, _.size(_.trim(_.get(this.props.input.value, language))) == 0 && styles.empty, language == this.state.active_language && styles.active)}>
										<span className={styles.code}>{_.get(this.props.localization, ['languages', language, 'code'])}</span>
										<span className={styles.count}>({_.size(_.trim(_.get(this.props.input.value, language)))})</span>
									</div>
								</Tooltip>
							))}
						</div>
					) : (
						<>
							{((_.get(this.props, "meta.error") && _.get(this.props, "meta.touched")) || this.props.hint) && <span>{_.get(this.props, "meta.error.0") || this.props.hint}</span>}
						</>
					)}
					{this.props.maxLength && (
						<span>
							{_.size(value)} / {this.props.maxLength}
						</span>
					)}
				</div>
			</div>
		);
	}
}

export default Input;
