import styles from '../../styles/components/Table/Range.scss';
import Input from '../Input';
import { __, setURLParam } from '../../core/utils';
import * as _ from 'lodash';
import CheckedIcon from '../../assets/images/checked-15x15.svg';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Cleave from 'cleave.js/dist/cleave-react-node';
import classNames from 'classnames';

let timeout = null;

const Range = ({ nullable, value, onChange }) => {
  let from, to;

  //split value prop '22|56' into from=22, to=56
  const splitted_value = value.split('|');

  if (splitted_value.length == 2) {
    [from, to] = splitted_value;
  }

  //state for focus
  const [fromActive, setFromActive] = useState(false);
  const [toActive, setToActive] = useState(false);

  //state for value
  const [fromValue, setFromValue] = useState(from);
  const [toValue, setToValue] = useState(to);

  //trigger onChange prop after 600ms timeout
  useEffect(() => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (fromValue && toValue) {
        onChange([fromValue, toValue].join('|'));
      }
    }, 600);
  }, [fromValue, toValue]);

  useEffect(() => {
    if (value == '') {
      setFromValue('');
      setToValue('');
    }
  }, [value]);

  return (
    <div className={styles.wrapper}>
      {(value != '0' || !nullable) && (
        <div className={styles.ranges}>
          <div className={classNames(styles.range, fromActive && styles.focus)}>
            <Cleave
              options={{ numericOnly: true }}
              value={fromValue}
              onFocus={() => {
                setFromActive(true);
                setToActive(false);
              }}
              onChange={(e) => setFromValue(e.currentTarget.value)}
              onBlur={() => setFromActive(false)}
              className={styles.input}
              placeholder='From'
            />
            <div className={styles.bar} />
          </div>
          <div className={classNames(styles.range, toActive && styles.focus)}>
            <Cleave
              options={{ numericOnly: true }}
              value={toValue}
              onFocus={() => {
                setFromActive(false);
                setToActive(true);
              }}
              onChange={(e) => setToValue(e.currentTarget.value)}
              onBlur={() => setToActive(false)}
              className={styles.input}
              placeholder='To'
            />
            <div className={styles.bar} />
          </div>
        </div>
      )}
      {nullable && (
        <div className={styles.nullable}>
          {value == '0' ? (
            <CheckedIcon
              className={styles.checked}
              onClick={() => onChange([fromValue, toValue].join('|'))}
            />
          ) : (
            <UncheckedIcon
              className={styles.unchecked}
              onClick={() => onChange('0')}
            />
          )}
          <span
            onClick={() =>
              onChange(value == '0' ? [fromValue, toValue].join('|') : '0')
            }
          >
            {nullable}
          </span>
        </div>
      )}
    </div>
  );
};

export default Range;
