import store from "../../../../core/store";

export const listRegions = () => {
	return store.dispatch({
		action: "table.superadmin_regions/LIST_REGIONS",
		method: "GET",
		endpoint: "/superadmin/regions",
		main: true
	});
};

export const createRegion = (formData) => {
	return store.dispatch({
		action: "table.superadmin_regions/CREATE_REGION",
		method: "POST",
		endpoint: "/superadmin/regions",
		data: formData
	});
};

export const deleteRegions = (region_ids) => {
	return store.dispatch({
		action: "table.superadmin_regions/DELETE_REGIONS",
		method: "DELETE",
		endpoint: "/superadmin/regions",
		data: {
			regions: region_ids
		}
	});
};

export const readRegion = (region_id) => {
	return store.dispatch({
		action: "table.superadmin_regions/READ_REGION",
		method: "GET",
		endpoint: "/superadmin/regions/" + region_id,
		has404: true
	});
};

export const updateRegion = (region_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_regions/UPDATE_REGION",
		method: "PUT",
		endpoint: "/superadmin/regions/" + region_id,
		data: formData
	});
};