import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/PrintTasksReportWizard.scss";
import autobind from "autobind-decorator";
import PrintIcon from "../assets/images/print-60x60.svg";
import {__, mapStateToProps, setTableParams} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import PrintTasksReportForm from "../forms/print_tasks_report_wizard/PrintTasksReportForm";
import {generateTasksReport} from "../redux/actions/table/all_tasks";

@mapStateToProps((state) => ({
	task_ids: _.get(state.table, "all_tasks.print_tasks_report_wizard"),
}))
@withRouter
class PrintTasksReportWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		setTableParams('all_tasks', {
			print_tasks_report_wizard: undefined
		});

		generateTasksReport({
			tasks: this.props.task_ids,
			...formData
		});

        setNotification(__('tasks.tasks-list.flyout.generate-report.tasks-report-pdf-generation-start.receive-link-email'));
	}

	render() {
		const legend = _.size(this.props.task_ids) == 1 ? 0 : 1;

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('tasks.tasks-list.flyout.generate-report.print-selected-tasks')}</span>
						<span>{__('tasks.tasks-list.flyout.generate-report.choose-options-print-selected-tasks-pdf')}</span>
					</div>
					<div className={styles.right}>
						<PrintIcon />
					</div>
				</div>
				<div className={styles.body}>
					<PrintTasksReportForm onSubmit={this._handleSubmit} initialValues={{
						header: 1,
						footer: 1,
						legend,
						index: 0,
						details: 1,
						description: 1,
						qr_code: 1,
						images: 0,
						comments: 0,
						history: 0,
						column_type: 1,
						column_id: 1,
						column_title: 1,
						column_assignee: 1,
						column_created_by: 1,
						column_status: 1,
						column_priority: 1,
						column_start: 1,
						column_end: 1,
					}} />
				</div>
			</>
		);
	}
}

export default PrintTasksReportWizard;
