import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/current_set_plans/Revisions.scss";
import * as _ from "lodash";
import DownloadIcon from "../../assets/images/download-15x15.svg";
import { __, dateTimeFrom } from "../../core/utils";
import { connect } from "react-redux";
import { downloadPlanRevision } from "../../redux/actions/table/current_set_plans";
import Tooltip from "../../components/Tooltip";
import classNames from "classnames";

const mapStateToProps = (state, props) => {
  return {
    upload: _.get(state.table["current_set_plans"], ['uploads', props.data.id]),
    auth: state.auth
  };
};

@connect(mapStateToProps)
class Revisions extends Component {
  render() {
    const { data, collapsed } = this.props;

    return (
      <div className={classNames(styles.table, collapsed && styles.collapsed)}>
        <div className={styles.header}>
          <div className={styles.revision}>Rev</div>
          <div>{__('plans.current-set.tab.revisions.uploaded-by')}</div>
          <div>{__('plans.current-set.tab.revisions.uploaded-at')}</div>
          <div className={styles.download} />
        </div>
        <div className={styles.rows}>
          {_.map(data.revisions, (revision, i) => (
            <div className={styles.row} key={i}>
              <div className={styles.revision}>
                {'R' + revision.number}
              </div>
              <div>{revision.created_by}</div>
              <div>{dateTimeFrom(revision.created_at)}</div>
              <Tooltip text={__('plans.tooltip.icon.current-set.download-revision')}><div className={styles.download} onClick={() => downloadPlanRevision(revision.id)}><DownloadIcon /></div></Tooltip>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Revisions;
