import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/PrintMeetingReportWizard.scss";
import autobind from "autobind-decorator";
import PrintIcon from "../assets/images/print-60x60.svg";
import {__, mapStateToProps, resetForm, setTableParams} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import PrintMeetingReportForm from "../forms/print_meeting_report_wizard/PrintMeetingReportForm";
import * as _ from "lodash";
import {generateMeetingReport} from "../redux/actions/table/all_meetings";

@mapStateToProps(state => ({
	meeting_id: _.get(state.table, 'all_meetings.meeting_id'),
	meeting_status: _.get(state.table, 'all_meetings.meeting_status'),
	send: _.get(state.table, 'all_meetings.send')
}))
class PrintMeetingReportWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const {meeting_id, send} = this.props;

		if(send) {
			setTableParams('all_meetings', {
				print_config: {
					...formData
				},
				print_meeting_report_wizard: undefined,
			});

			setTimeout(() => setTableParams('all_meetings', {
				send_meeting_report_wizard: true
			}), 10);
		} else {
			setTableParams('all_meetings', {
				print_meeting_report_wizard: undefined
			});

			generateMeetingReport(meeting_id, {
				...formData
			});

			resetForm('print_meeting_report_wizard');

			setNotification("Meeting PDF generation will now start. You will receive a notification with a download link.");
		}
	}

	render() {
		const {meeting_status} = this.props;

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('meetings.all-meetings.flyout.send-meeting.pdf-settings')}</span>
						<span>{__('meetings.all-meetings.flyout.send-meeting.pdf-settings.choose-your-options-for-selected-meeting-printing-pdf')}</span>
					</div>
					<div className={styles.right}>
						<PrintIcon />
					</div>
				</div>
				<div className={styles.body}>
					<PrintMeetingReportForm onSubmit={this._handleSubmit} initialValues={{
						header: 1,
						footer: 1,
						legend: 1,
						details: 1,
						description: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						attachments: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						qr_code: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						comments: _.toInteger(_.includes(['review'], meeting_status)),
						footnote: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_type: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_id: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_priority: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_status: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_due_date: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_assignee: _.toInteger(_.includes(['minutes', 'review', 'closed', 'canceled'], meeting_status)),
						column_startend: _.toInteger(_.includes(['agenda'], meeting_status)),
						column_duration: _.toInteger(_.includes(['agenda'], meeting_status)),
					}} />
				</div>
			</>
		);
	}
}

export default PrintMeetingReportWizard;
