import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/wizards/superadmin/AddProductCategoryWizard.scss';
import autobind from 'autobind-decorator';
import AddNoteIcon from '../../assets/images/add-note-60x60.svg';
import {
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../../redux/actions/general/notification';
import {
  createNoteCategory,
  listNoteCategories,
} from '../../redux/actions/table/superadmin/note_categories';
import AddNoteCategoryForm from '../../forms/superadmin/add_note_categories_wizard/AddNoteCategoryForm';
import { listLocalization } from '../../redux/actions/localization';

@withRouter
@mapStateToProps((state) => ({
  table: state.table['superadmin_note_categories'],
}))
class AddNoteCategoryWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { edit } = formData;

    return validateForm(
      createNoteCategory(formData).then(({ response }) => {
        listNoteCategories()
          .then(() => {
            if (edit) {
              setTableParams('superadmin_note_categories', {
                clicked_row: response.data.id,
              });
            }
          })
          .then(() => {
            listLocalization();
          });

        setTableParams('superadmin_note_categories', {
          actioned_row: undefined,
          selected_rows: [],
        });

        this.props.onClose();

        setNotification(
          'You have successfully added a new instruction category.'
        );
      })
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>Add instruction category</span>
            <span>
              Add instruction category to system instruction categories
            </span>
          </div>
          <div className={styles.right}>
            <AddNoteIcon />
          </div>
        </div>
        <div className={styles.body}>
          <AddNoteCategoryForm
            onSubmit={this._handleSubmit}
            initialValues={{
              name: {},
            }}
          />
        </div>
      </>
    );
  }
}

export default AddNoteCategoryWizard;
