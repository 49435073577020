import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/company/CompanyOfficesForm.scss";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import MinusMiddleIcon from "../../assets/images/minus-middle-15x15.svg";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {__, transformToListPairs} from "../../core/utils";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import {connect} from "react-redux";
import autobind from "autobind-decorator";
import {enableAutosave} from "../../redux/actions/general/autosave";

const mapStateToProps = state => {
	return {
		values: getFormValues("company.offices")(state),
	};
};
@subscribe(Localization, "localization")
@reduxForm({
	form: "company.offices",
    enableReinitialize: true,
})
@autobind
@connect(mapStateToProps)
class CompanyOfficesForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

	render_emails = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("Email") + " " + (index + 1) : __("Email")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));
	render_phones = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("Phone") + " " + (index + 1) : __("Phone")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.wrapper}>
					<div className={styles.offices}>
						<span className={styles.active}>{__('Headquarters')}</span>
						{/*<span>Office 1</span>*/}
						{/*<span>Office 2</span>*/}
						{/*<span>Office 3</span>*/}
						{/*<span>Office 4</span>*/}
						{/*<span>Office 5</span>*/}
						{/*<span>Office N</span>*/}
						{/*<div className={styles.add}>*/}
						{/*<PlusBigIcon/> Add office*/}
						{/*</div>*/}
					</div>
					<div className={styles.form}>
						<div className={styles.title}>{__('Headquarters')}</div>
						<div className={styles.flex}>
							<div className={styles.f1}>
								<Field name="country_id" component={Select} searchable label={__('Country')} options={transformToListPairs(this.props.localization.countries)} />
								<Field name="address" component={Input} label={__('Street address')} />
								<Field name="city" component={Input} label={__('City')} />
								<Field name="zip" component={Input} label={__('Zip zode')} />
								<Field name="state" component={Input} label={__('Province / State')} />
							</div>
							<div className={styles.f1}>
								<FieldArray name="emails" component={this.render_emails} />
								<FieldArray name="phones" component={this.render_phones} />
							</div>
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default CompanyOfficesForm;
