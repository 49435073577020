import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../../styles/forms/superadmin/add_paper_size_wizard/AddPaperSizeForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import {__, mapStateToProps, setTableParams} from "../../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";

@withRouter
@reduxForm({form: "superadmin.add_paper_size_wizard.add_paper_size"})
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_paper_size_wizard.add_paper_size")(state),
    auth: state.auth,
}))
class AddPaperSizeForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" component={Input} label="Name" />
                    <Field name="width" component={Input} numeric decimal label="Width" />
                    <Field name="height" component={Input} numeric decimal label="Height" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_paper_sizes', {
                        add_paper_size_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddPaperSizeForm;
