import React, {Component} from "react";
import TransparentImage from "../assets/images/transparent.png";
import autobind from "autobind-decorator";

class Image extends Component {
	@autobind
	_handleError(e) {
		e.target.src = this.props.default || TransparentImage;
	}

	render() {
		const {src, className} = this.props;

		return (
			<img {..._.omit(this.props, ['default', 'src'])} className={className} src={src || (this.props.default || TransparentImage)} onError={this._handleError} loading="lazy"/>
		);
	}
}

export default Image;