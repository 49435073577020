import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_specification_wizard/AddSpecificationForm.scss';
import Button from '../../components/Button';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setTableParams,
  transformToListPairs,
} from '../../core/utils';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Input from '../../components/Input';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import {
  checkSpecificationCode,
  getNextSpecificationCode,
  listPossibleSpecificationTeams,
} from '../../redux/actions/table/working_set_specifications';
import ButtonGroup from '../../components/ButtonGroup';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';
import { listPossibleAddressBookCompanies } from '../../redux/actions/table/address_book_contacts';
import { listPossibleAddressBookManufacturers } from '../../redux/actions/table/address_book_companies';

@reduxForm({
  form: 'add_specification_wizard.add_specification',
})
@mapStateToProps((state) => ({
  values: getFormValues('add_specification_wizard.add_specification')(state),
  application_language_id: state.auth.language_id,
  create_action: state.table.working_set_specifications.create_action,
}))
@subscribe(Localization, 'localization')
class AddSpecificationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_teams: [],
      possible_address_book_manufacturers: [],
    };
  }

  componentDidMount() {
    listPossibleSpecificationTeams(getActiveStage()).then(({ response }) => {
      this.setState({ possible_teams: response.data });

      if (_.size(response.data) == 1) {
        this.props.change('team_id', _.get(response.data, [0, 'id'], null));
      }
    });

    listPossibleAddressBookManufacturers().then(({ response }) => {
      this.setState({ possible_address_book_manufacturers: response.data });
    });
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'values.specification_subgroup_id') !=
        _.get(this.props, 'values.specification_subgroup_id') &&
      _.get(this.props, 'values.specification_subgroup_id')
    ) {
      getNextSpecificationCode(getActiveStage(), {
        specification_subgroup_id: _.get(
          this.props,
          'values.specification_subgroup_id'
        ),
      }).then(({ response }) => {
        this.props.change('code', response.data.code);
      });
    }

    if (
      prevProps.values.specification_subgroup_id !=
        this.props.values.specification_subgroup_id ||
      prevProps.values.code != this.props.values.code ||
      prevProps.values.team_id != this.props.values.team_id
    ) {
      setTableParams('working_set_specifications', {
        create_action: false,
      });

      if (
        this.props.values.specification_subgroup_id &&
        this.props.values.code &&
        this.props.values.team_id &&
        _.size(this.props.values.code) == 2
      ) {
        checkSpecificationCode(this.props.values.team_id, {
          code: this.props.values.code,
          specification_subgroup_id:
            this.props.values.specification_subgroup_id,
        })
          .then(() => {
            setTableParams('working_set_specifications', {
              create_action: false,
            });
          })
          .catch(({ response }) => {
            if (_.has(response, 'code') && response.code === 1) {
              setTableParams('working_set_specifications', {
                create_action: true,
              });
            } else {
              setTableParams('working_set_specifications', {
                create_action: false,
              });
            }
          });
      }
    }
  }

  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  render() {
    const { values, projectLanguage, application_language_id, create_action } =
      this.props;
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const { possible_teams, possible_address_book_manufacturers } = this.state;

    const units = _.sortBy(
      _.map(this.props.localization.units, (unit) => ({
        label: unit.symbol,
        value: unit.id,
        tooltip: unit.description,
        group: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'name',
          1,
        ]),
        groupPriority: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'priority',
        ]),
      })),
      'groupPriority'
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <Field
          name='team_id'
          searchable={true}
          component={Select}
          label={__('specifications.working-set.add-new.discipline-team')}
          options={_.map(possible_teams, (team) => ({
            value: team.id,
            label:
              team.company.name +
              ' [' +
              [
                team.stage_discipline.code,
                team.stage_discipline.discipline_id == null
                  ? team.stage_discipline.description + '*'
                  : _.get(this.props.localization, [
                      'disciplines',
                      team.stage_discipline.discipline_id,
                      'name',
                    ]),
              ].join(': ') +
              ']',
          }))}
        />
        <div className={styles.flex}>
          <div className={styles.f2}>
            <Field
              name='specification_subgroup_id'
              searchable={true}
              component={Select}
              label={__(
                'specifications.working-set.create-new-specification.category'
              )}
              options={_.map(
                specification_subgroups,
                (specification_subgroup) => {
                  const specification_group = _.get(
                    specification_groups,
                    _.get(specification_subgroup, 'specification_group_id'),
                    {}
                  );
                  const label =
                    getLocalized(specification_group.code, projectLanguage) +
                    [
                      _.padEnd(specification_subgroup.code, 3, '0'),
                      getLocalized(
                        specification_subgroup.name,
                        application_language_id
                      ),
                    ].join(': ');
                  const group = [
                    getLocalized(specification_group.code, projectLanguage),
                    getLocalized(
                      specification_group.name,
                      application_language_id
                    ),
                  ].join(': ');

                  return {
                    value: specification_subgroup.id,
                    label,
                    group,
                  };
                }
              )}
            />
          </div>
          {values.specification_subgroup_id && (
            <div className={styles.code}>
              <div className={styles.code}>
                {getLocalized(
                  _.get(specification_groups, [
                    _.get(specification_subgroups, [
                      values.specification_subgroup_id,
                      'specification_group_id',
                    ]),
                    'code',
                  ]),
                  projectLanguage
                ) +
                  _.get(specification_subgroups, [
                    values.specification_subgroup_id,
                    'code',
                  ])}
              </div>
            </div>
          )}
          <div className={styles.right}>
            <Field
              name='code'
              component={Input}
              label={__(
                'specifications.working-set.create-new-specification.code'
              )}
              numeric
              maxLength={2}
            />
          </div>
        </div>
        <Field
          name={'description.' + projectLanguage}
          component={Input}
          label={__('working-set-specifications.basic-data-form.description')}
          maxLength={70}
        />
        <Field
          name='model'
          component={Input}
          label={__(
            'specifications.working-set.create-new-specification.model'
          )}
        />
        <Field
          name='manufacturer_company_id'
          component={Select}
          searchable
          label={__(
            'specifications.working-set.create-new-specification.manufacturer'
          )}
          options={transformToListPairs(possible_address_book_manufacturers)}
        />
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='unit_id'
              component={Select}
              label={__(
                'specifications.working-set.create-new-specification.unit'
              )}
              searchable
              options={units}
              noSort
            />
          </div>

          <div className={styles.f1}>
            <Field
              name='quantity'
              component={Input}
              label={__(
                'specifications.working-set.create-new-specification.quantity'
              )}
              maxLength={50}
              numeric
              decimal
            />
          </div>
        </div>
        {create_action &&
          values.specification_subgroup_id &&
          _.size(values.code) == 2 && (
            <>
              <div className={styles.exists}>
                {__('specifications.working-set.flyout.add-new.code')}{' '}
                <strong>
                  {getLocalized(
                    _.get(specification_groups, [
                      _.get(specification_subgroups, [
                        values.specification_subgroup_id,
                        'specification_group_id',
                      ]),
                      'code',
                    ]),
                    projectLanguage
                  ) +
                    _.get(specification_subgroups, [
                      values.specification_subgroup_id,
                      'code',
                    ]) +
                    _.padStart(values.code, 2, '0')}
                </strong>{' '}
                already exists. Please choose an action:
              </div>
              <Field
                name='action'
                component={RadioGroup}
                options={[
                  {
                    label: __('add-specification-form.add-as-new-option.main'),
                    value: 'option_main',
                  },
                  {
                    label: __(
                      'add-specification-form.add-as-new-option.alternative'
                    ),
                    value: 'option_alternative',
                  },
                ]}
              />
            </>
          )}
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                wizard_active: undefined,
                linked_specification_code_id: undefined,
              })
            }
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.create')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(false)}
            />
            <Button
              lightBlue
              medium
              middleText={__('button.create-edit')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(true)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default AddSpecificationForm;
