import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { connect } from 'react-redux';
import {
  createSidebar,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  dateTimeFrom,
  destroyForm,
  getActiveStage,
  getURLParam,
  redirect,
  setTableParams,
  setURLParam,
  sortValuesByArray,
  hasURLParam,
} from '../../core/utils';
import Table from '../../components/Table';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  listAllMeetings,
  readMeeting,
} from '../../redux/actions/table/all_meetings';
import styles from '../../styles/views/meetings/AllMeetings.scss';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import PrintIcon from '../../assets/images/print-16x16.svg';
import MeetingsFlyout from '../../flyouts/meetings/MeetingsFlyout';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import SendIcon from '../../assets/images/send-16x16.svg';
import MeetingFollowUpIcon from '../../assets/images/meeting-follow-up-16x16.svg';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import classNames from 'classnames';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';

const mapStateToProps = (state) => {
  return {
    table: state.table['all_meetings'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.all_meetings/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AllMeetings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('meetings.table.button.create'),
            onClick: () =>
              this.props.actions.setTableParams({
                wizard_active: true,
              }),
          },
        ]);
    });

    //fix bug when keeping the "send meeting report" values, due to `destroyOnUnmount=true`
    destroyForm('send_meeting_report_wizard.send_meeting_report');

    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/meetings' +
          (this.props.match.params.status
            ? '/' + this.props.match.params.status
            : '')
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/meetings' +
          (this.props.match.params.status
            ? '/' + this.props.match.params.status
            : '')
      )
    );

    createSidebar({
      title: __('submenu.title.meetings'),
      items: [
        {
          title: __('submenu.title.meetings-list'),
          link: () => '/stages/' + getActiveStage() + '/meetings',
          active: true,
        },
      ],
    });

    if (!hasURLParam('filter.status')) {
      setURLParam('filter.status', '');
    }
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const items = _.sortBy(
        _.keys(_.get(this.props.table.filters, 'status.items')),
        (status) => {
          return sortValuesByArray(
            ['agenda', 'minutes', 'review', 'closed', 'canceled'],
            status
          );
        }
      );

      const all_ids = items;

      updateSidebar('items.0.items', [
        {
          title: __('submenu.title.all-meetings'),
          onClick: () => setURLParam('filter.status', ''),
          active: () => {
            const active_statuses = _.split(getURLParam('filter.status'), '|');

            const intersected = _.intersection(active_statuses, items);

            return (
              _.isEmpty(getURLParam('filter.status')) ||
              _.size(intersected) == _.size(items)
            );
          },
          checkbox: {
            status: () => {
              const active_statuses = _.split(
                getURLParam('filter.status'),
                '|'
              );

              const intersected = _.intersection(active_statuses, items);

              if (_.isEmpty(getURLParam('filter.status'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(items)) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_statuses = _.split(
                getURLParam('filter.status'),
                '|'
              );

              const intersected = _.intersection(active_statuses, items);

              if (
                _.size(intersected) == _.size(items) ||
                _.isEmpty(getURLParam('filter.status'))
              ) {
                setURLParam('filter.status', 'n/a');
              } else {
                setURLParam('filter.status', '');
              }
            },
          },
        },
        ..._.map(items, (status) => ({
          title: __('table.columns.meeting-status-' + status),
          onClick: () => setURLParam('filter.status', status),
          active: () => {
            const active_statuses = _.split(getURLParam('filter.status'), '|');

            return (
              _.isEmpty(getURLParam('filter.status')) ||
              _.includes(active_statuses, status)
            );
          },
          checkbox: {
            status: () => {
              const active_statuses = _.split(
                getURLParam('filter.status'),
                '|'
              );

              if (_.isEmpty(getURLParam('filter.status'))) {
                return 'checked';
              }

              return _.includes(active_statuses, status)
                ? 'checked'
                : 'unchecked';
            },
            onClick: () => {
              const setStatuses = (statuses) => {
                statuses = _.trim(
                  _.join(
                    _.reject(statuses, (status) => status == 'n/a'),
                    '|'
                  ),
                  '|'
                );

                setURLParam(
                  'filter.status',
                  _.isEmpty(statuses) ? 'n/a' : statuses
                );
              };

              const active_statuses =
                getURLParam('filter.status') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.status'), '|');

              if (_.includes(active_statuses, status)) {
                setStatuses(_.reject(active_statuses, (row) => row == status));
              } else {
                active_statuses.push(status);

                setStatuses(active_statuses);
              }
            },
          },
        })),
      ]);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readMeeting(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  render() {
    const columns = [
      {
        key: 'identifier',
        name: __('meetings.columns.id'),
        value: (value) => 'M-' + value,
        width: 75,
        default: true,
        desc: true,
      },
      {
        key: 'title',
        name: __('meetings.columns.title'),
        required: true,
      },
      {
        key: 'location_name',
        name: __('meetings.columns.location'),
        width: 120,
        value: (location) => location || <TableNA />,
      },
      {
        key: 'date',
        name: __('meetings.columns.date-time'),
        value: (value) => (
          <Tooltip text={dateTimeFrom(value)}>{dateFrom(value)}</Tooltip>
        ),
        width: 80,
      },
      {
        key: 'participants',
        name: __('meetings.columns.participants'),
        value: (participants) =>
          !_.isEmpty(participants) ? (
            <div className={styles.participants}>
              {_.size(participants) == 1 &&
                _.map(_.take(participants, 1), (user, i) => (
                  <Avatar
                    key={i}
                    avatar_url={user.avatar_url}
                    name={user.fullname}
                    active={user.active}
                  />
                ))}
              {_.size(participants) > 1 && (
                <Tooltip text={_.map(participants, 'fullname').join('\n')}>
                  <div className={styles.more}>
                    <span>{_.size(participants)}</span>
                  </div>
                </Tooltip>
              )}
            </div>
          ) : (
            <TableNA />
          ),
        sortable: false,
        width: 60,
      },
      {
        key: 'administrators',
        name: __('meetings.columns.administrators'),
        value: (administrator) => (
          <div className={styles.administrators}>
            {_.size(administrator) == 1 &&
              _.map(_.take(administrator, 1), (user, i) => (
                <Avatar
                  key={i}
                  avatar_url={user.avatar_url}
                  name={user.fullname}
                  active={user.active}
                />
              ))}
            {_.size(administrator) > 1 && (
              <Tooltip text={_.map(administrator, 'fullname').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(administrator)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 60,
      },
      {
        key: 'status',
        name: __('meetings.columns.status'),
        value: (status) => {
          switch (status) {
            case 'agenda':
              return (
                <Tooltip text={__('table.columns.meeting-status-agenda')}>
                  <span className={classNames(styles.balloon, styles.agenda)}>
                    {__('table.columns.meeting-status-agenda-short')}
                  </span>
                </Tooltip>
              );
            case 'minutes':
              return (
                <Tooltip text={__('table.columns.meeting-status-minutes')}>
                  <span className={classNames(styles.balloon, styles.minutes)}>
                    {__('table.columns.meeting-status-minutes-short')}
                  </span>
                </Tooltip>
              );
            case 'review':
              return (
                <Tooltip text={__('table.columns.meeting-status-review')}>
                  <span className={classNames(styles.balloon, styles.review)}>
                    {__('table.columns.meeting-status-review-short')}
                  </span>
                </Tooltip>
              );
            case 'closed':
              return (
                <Tooltip text={__('table.columns.meeting-status-closed')}>
                  <span className={classNames(styles.balloon, styles.closed)}>
                    {__('table.columns.meeting-status-closed-short')}
                  </span>
                </Tooltip>
              );
            case 'canceled':
              return (
                <Tooltip text={__('table.columns.meeting-status-canceled')}>
                  <span className={classNames(styles.balloon, styles.canceled)}>
                    {__('table.columns.meeting-status-canceled-short')}
                  </span>
                </Tooltip>
              );
          }
        },
        width: 50,
      },
      {
        key: 'visibility',
        name: __('meetings.columns.visibility'),
        value: (visibility) =>
          visibility == '1' ? (
            <Tooltip text={__('table.columns.meeting-visibility-public')}>
              <span className={classNames(styles.balloon, styles.public)}>
                {__('table.columns.meeting-visibility-public-short')}
              </span>
            </Tooltip>
          ) : (
            <Tooltip text={__('table.columns.meeting-visibility-private')}>
              <span className={classNames(styles.balloon, styles.private)}>
                {__('table.columns.meeting-visibility-private-short')}
              </span>
            </Tooltip>
          ),
        width: 50,
      },
      {
        key: 'created_by',
        name: __('plans.deliveries.columns.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
    ];

    const singleActions = (meeting_id) => {
      const meeting_status = _.get(
        _.find(this.props.table.data, { id: meeting_id }),
        'status'
      );
      const is_admin = _.get(
        _.find(this.props.table.data, { id: meeting_id }),
        'is_admin'
      );

      let actions = [];

      if (this.state.team_roles && !_.isEmpty(this.state.team_roles.editor)) {
        actions.push(
          <Tooltip
            text={__('meetings.tooltip.icon.create-follow-up')}
            key={_.uniqueId()}
          >
            <MeetingFollowUpIcon
              className=''
              onClick={(e) => {
                e.stopPropagation();

                setTableParams('all_meetings', {
                  create_followup_meeting_wizard: meeting_id,
                });
              }}
            />
          </Tooltip>
        );
      }

      if (is_admin) {
        actions.push(
          <Tooltip
            text={__('meetings.tooltip.icon.send-meeting-report')}
            key={_.uniqueId()}
          >
            <SendIcon
              onClick={(e) => {
                e.stopPropagation();

                setTableParams('all_meetings', {
                  send_meeting_report_wizard: true,
                  meeting_id,
                  send: true,
                });
              }}
            />
          </Tooltip>
        );
      }

      actions.push(
        <Tooltip
          text={__('meetings.tooltip.icon.print-meeting-report')}
          key={_.uniqueId()}
        >
          <PrintIcon
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('all_meetings', {
                print_meeting_report_wizard: true,
                meeting_id,
                meeting_status,
                send: false,
              });
            }}
          />
        </Tooltip>
      );

      actions.push(
        <Tooltip
          text={__('meetings.tooltip.icon.meeting-properties')}
          key={_.uniqueId()}
        >
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              this.props.actions.setTableParams({
                clicked_row: meeting_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>
      );

      return actions;
    };

    const preview = (
      <MeetingsFlyout
        data={this.state.preview}
        readAction={(meeting_id) => {
          return new Promise((resolve) => {
            readMeeting(getURLParam('id') || meeting_id).then(
              ({ response }) => {
                this.setState({ preview: response.data });

                resolve(response);
              }
            );
          });
        }}
      />
    );

    const filtersSort = (filter, value, label) => {
      switch (filter) {
        case 'tag':
        case 'followers':
        case 'administrators':
        case 'participants':
        case 'created_by':
          return value;
      }
    };

    return (
      <Table
        filtersSort={filtersSort}
        listAction={() =>
          listAllMeetings(
            this.props.match.params.stage,
            this.props.match.params.status
          )
        }
        name='all_meetings'
        title={__('submenu.title.meetings')}
        columns={columns}
        singleActions={singleActions}
        preview={this.state.preview ? preview : []}
        onRowClick={(id) =>
          redirect(
            '/meetings/' +
              id +
              (this.props.match.params.status
                ? '?status=' + this.props.match.params.status
                : '')
          )
        }
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default AllMeetings;
