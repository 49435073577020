import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/Notes.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import { getLocalized, mapStateToProps, __ } from '../../core/utils';
import { ReactSortable } from 'react-sortablejs';
import Tooltip from '../../components/Tooltip';
import ProjectNoteForm from '../../forms/current_set_specifications/ProjectNoteForm';
import autobind from 'autobind-decorator';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import LogoIcon from '../../assets/images/logo-50x50.svg';

@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
}))
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  componentDidMount() {
    const { notes } = this.props.store.flyout;
    const { language_id } = this.props.store;

    this.setState({
      expanded: _.map(
        _.groupBy(notes, (note_group) =>
          getLocalized(note_group.category, language_id)
        ),
        (notes, group) => {
          return group;
        }
      ),
    });
  }

  render() {
    const { collapsed, store } = this.props;
    const { flyout, language_id } = store;
    const { expanded } = this.state;

    const notes = _.sortBy(flyout.notes, 'order');

    let note_categories = _.sortBy(
      _.map(
        _.groupBy(notes, (note_group) =>
          getLocalized(note_group.category, language_id)
        ),
        (notes, group) => {
          return {
            label: group,
            value: group,
          };
        }
      ),
      'order'
    );

    const Row = ({ notes, value }) => {
      return (
        <ReactSortable
          disabled={true}
          list={notes}
          setList={() => {}}
          animation={200}
          delayOnTouchStart={true}
        >
          {_.map(notes, (note) => {
            return (
              <div
                className={classNames(styles.flex, styles.row)}
                key={_.uniqueId()}
              >
                <div className={styles.note}>
                  <span>&mdash;</span>

                  <span>{getLocalized(note.text, language_id)}</span>
                </div>
              </div>
            );
          })}
        </ReactSortable>
      );
    };

    return (
      <div
        className={classNames(
          styles.wrapper,
          collapsed && styles.collapsed,
          _.isEmpty(_.first(note_categories)?.value) && styles.hasPadding
        )}
      >
        {!_.isEmpty(flyout.project_note) && (
          <ProjectNoteForm
            initialValues={{
              project_note: flyout.project_note,
            }}
          />
        )}
        {!_.isEmpty(notes) && (
          <div className={styles.content}>
            {_.isEmpty(_.first(note_categories)?.value) ? (
              <Row notes={notes} />
            ) : (
              _.map(note_categories, (category) => {
                const filtered_notes = _.sortBy(
                  _.filter(
                    notes,
                    (note) =>
                      getLocalized(note.category, language_id) == category.value
                  ),
                  'order'
                );

                return (
                  <div className={styles.category} key={_.uniqueId()}>
                    <div
                      className={styles.title}
                      onClick={() =>
                        this._toggleCollapseCategory(category.value)
                      }
                    >
                      <ArrowDownMiddleIcon
                        className={classNames(
                          styles.collapse,
                          !_.includes(expanded, category.value) &&
                            styles.collapsed
                        )}
                      />
                      {category.label}
                    </div>
                    {_.includes(expanded, category.value) && (
                      <Row notes={filtered_notes} value={category.value} />
                    )}
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Notes;
