import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/languages/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../../core/utils";
import {setNotification} from "../../../redux/actions/general/notification";
import {listLanguages, updateLanguage} from "../../../redux/actions/table/superadmin/languages";
import EditLanguageForm from "../../../forms/superadmin/languages/EditLanguageForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateLanguage(formData.id, _.omit(formData, ['id'])).then(() => {
                listLanguages();

                this.props.readAction(formData.id);

                setNotification(__('superadmin.languages.flyout.properties.properties-been-saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditLanguageForm
                    onSubmit={this._handleSubmit}
                    initialValues={{
                        ..._.pick(data, ['id', 'code', 'name', 'tesseract_name', 'application_language']),
                    }}
                />
            </div>
        );
    }
}

export default Properties;
