import store from "../../../core/store";

export const listSpecificationApprovals = (stage_id, status) => {
    return store.dispatch({
        action: "table.specification_approvals/LIST_SPECIFICATION_APPROVALS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/specification_approvals" + (status ? '?status=' + status : ''),
        main: true
    });
};

export const approveSpecifications = (formData) => {
    return store.dispatch({
        action: "table.specification_approvals/APPROVE_SPECIFICATIONS",
        method: "POST",
        endpoint: "/specifications/approve",
        data: formData
    });
};

export const rejectSpecifications = (formData) => {
    return store.dispatch({
        action: "table.specification_approvals/REJECT_SPECIFICATIONS",
        method: "POST",
        endpoint: "/specifications/reject",
        data: formData
    });
};