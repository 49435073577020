import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/address_book_companies/AddressBookCompaniesFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import {__, setTableParams} from "../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import withRouter from "react-router/es/withRouter";
import classNames from "classnames";
import Properties from "./Properties";

@subscribe(Localization, "localization")
@withRouter
class AddressBookCompaniesFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    render() {
        const {data, readAction} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>
                            {data.name}
                        </span>
                        <CloseBigIcon onClick={() => setTimeout(() => {
                            setTableParams('address_book_companies', {
                            clicked_row: undefined
                        })}, 100)}/>
                    </div>
                    <div className={styles.actions}/>
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>{__('address-book-companies.flyout.properties')}</span>
                </div>
                {!_.isEmpty(data) && this.state.active == 'properties' && <Properties data={data} readAction={readAction} />}
            </div>
        );
    }
}

export default AddressBookCompaniesFlyout;
