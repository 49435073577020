import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { mapStateToProps, transformToListPairs, getLocalized } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import MultiSelect from "../../../components/MultiSelect";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import * as _ from "lodash";

@reduxForm({
    form: "superadmin_product_subgroups.edit_product_subgroup",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    application_language_id: state.auth.language_id
}))
class EditProductSubgroupForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        const specification_subgroups = _.map(this.props.localization.specification_subgroups, item => ({
            value: item.id,
            label: getLocalized(item.name, this.props.application_language_id),
        }));

        const product_attribute_template_fields = _.sortBy(_.map(this.props.localization.product_attribute_fields, product_subgroup => {
            const product_attribute_template_group = _.get(this.props.localization.product_attribute_groups, product_subgroup.product_attribute_template_group_id, {});

            return {
                label: getLocalized(product_subgroup.label),
                value: product_subgroup.id,
                groupPriority: product_attribute_template_group.priority,
                group: getLocalized(product_attribute_template_group.name, this.props.application_language_id),
            };
        }), 'groupPriority');

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_product_subgroup} localized={application_languages} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_product_subgroup} name="product_group_id" component={Select} searchable options={transformToListPairs(this.props.localization.product_groups)} label="Product Group" />
                <Field disabled={!superadmin.edit_product_subgroup} name="priority" numeric component={Input} label="Priority" />
                <Field disabled={!superadmin.edit_product_subgroup} name="specification_subgroups" component={MultiSelect} searchable options={specification_subgroups} label="Linked Specification Subgroups" />
                <Field disabled={!superadmin.edit_product_subgroup} name="product_attribute_template_field_ids" component={MultiSelect} searchable noSort options={product_attribute_template_fields} label="Assigned Product Attribute Fields" />
            </Form>
        );
    }
}

export default EditProductSubgroupForm;