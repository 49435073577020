import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../../styles/forms/superadmin/add_specification_subgroup_wizard/AddSpecificationSubgroupForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { __, mapStateToProps, setTableParams, getLocalized } from "../../../core/utils";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import MultiSelect from "../../../components/MultiSelect";
import * as _ from "lodash";

@withRouter
@reduxForm({ form: "superadmin.add_specification_subgroup_wizard.add_specification_subgroup" })
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_specification_subgroup_wizard.add_specification_subgroup")(state),
    auth: state.auth,
    application_language_id: state.auth.language_id,
}))
class AddSpecificationSubgroupForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == this.props.auth.language_id;

            return languages;
        }, {});

        const specification_groups = _.map(this.props.localization.specification_groups, item => ({
			value: item.id,
			label: getLocalized(item.name, this.props.application_language_id),
        }));
        
        const boq_template_subgroups = _.map(this.props.localization.boq_template_subgroups, item => ({
			value: item.id,
			label: getLocalized(item.name, this.props.application_language_id),
		}));

        const product_subgroups = _.map(this.props.localization.product_subgroups, product_subgroup => {
            const product_group = _.get(this.props.localization.product_groups, product_subgroup.product_group_id, {});

            return {
                label: product_subgroup.name,
                value: product_subgroup.id,
                group: [_.get(this.props.localization.product_categories, [product_group.product_category_id, 'name']), product_group.name].join(' / '),
            };
        });

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" localized={application_languages} component={Input} label="Name" />
                    <Field name="code" component={Input} numeric label="Code" maxLength={1} />
                    <Field name="specification_group_id" component={Select} options={specification_groups} label="Specification Group" />
                    <Field name="boq_template_subgroup_id" component={Select} options={boq_template_subgroups} label="BOQ Template Subgroup" />
                    <Field name="product_subgroups" component={MultiSelect} searchable options={product_subgroups} label="Linked Product Subgroups" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_specification_subgroups', {
                        add_specification_subgroup_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddSpecificationSubgroupForm;
