import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles
    from "../../styles/forms/add_specification_product_attribute_group_wizard/AddSpecificationProductAttributeGroupForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import { __, getLocalized, mapStateToProps, setTableParams } from "../../core/utils";
import Select from "../../components/Select";
import * as _ from "lodash";
import { withRouter } from "react-router";
import RadioGroup from "../../components/RadioGroup";
import Input from "../../components/Input";
import Checkbox from "../../components/Checkbox";
import { listMyCompanySpecificationAttributeTemplateGroups } from "../../redux/actions/table/working_set_specifications";

@reduxForm({
    form: "add_specification_product_attribute_group_wizard.add_specification_product_attribute_group",
})
@mapStateToProps(state => ({
    store: state.table.working_set_specifications,
    values: getFormValues("add_specification_product_attribute_group_wizard.add_specification_product_attribute_group")(state) || {},
    application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
@withRouter
class AddSpecificationProductAttributeGroupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company_templates: []
        };
    }

    componentDidMount() {
        listMyCompanySpecificationAttributeTemplateGroups().then(({ response }) => this.setState({ company_templates: response.data }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.values.template != this.props.values.template || (prevProps.values.type == 'custom' && this.props.values.type == 'template' && this.props.values.template)) {
            if (_.startsWith(this.props.values.template, 'company')) {
                this.props.change('name', { [this.props.store.flyout.project_language]: _.get(_.find(this.state.company_templates, ['id', _.parseInt(this.props.values.template.substring(8))]), 'name') });
                _.each(_.get(_.find(this.state.company_templates, ['id', _.parseInt(this.props.values.template.substring(8))]), 'fields', []), field => this.props.change('fields.' + field.id, 0));
            } else if (_.startsWith(this.props.values.template, 'system')) {
                const name = _.get(this.props.localization.product_attribute_groups, [this.props.values.template.substring(7), 'name']);

                this.props.change('name', _.pick(name, _.keys(this.props.store.flyout.project_language)));

                this.props.change('name.' + this.props.store.flyout.project_language, getLocalized(name, this.props.store.flyout.project_language));
                _.each(_.filter(this.props.localization.product_attribute_fields, ['product_attribute_template_group_id', _.parseInt(this.props.values.template.substring(7))]), field => this.props.change('fields.' + field.id, 0));
            }
        } else if (prevProps.values.type == 'template' && this.props.values.type == 'custom') {
            this.props.change('name', {});
        }
    }

    selectAllHandler(status) {
        if (_.startsWith(this.props.values.template, 'company')) {
            _.each(_.get(_.find(this.state.company_templates, ['id', _.parseInt(this.props.values.template.substring(8))]), 'fields', []), field => this.props.change('fields.' + field.id, status));
        } else if (_.startsWith(this.props.values.template, 'system')) {
            _.each(_.filter(this.props.localization.product_attribute_fields, ['product_attribute_template_group_id', _.parseInt(this.props.values.template.substring(7))]), field => this.props.change('fields.' + field.id, status))
        }
    }

    render() {
        const { values, application_language_id } = this.props;
        const { language_id } = this.props.store;

        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        let product_attribute_groups = [];

        _.each(this.state.company_templates, group => product_attribute_groups.push({
            label: group.name,
            value: ['company', group.id].join('-'),
            group: __('add-specifications-attribute-group-form.dropdown.my-company-templates')
        }));

        _.each(this.props.localization.product_attribute_groups, group => product_attribute_groups.push({
            label: _.get(group.name, language_id, _.get(group.name, '1')),
            priority: group.priority,
            value: ['system', group.id].join('-'),
            group: __('specifications.working-set.flyout.add-group.template.system-templates')
        }));

        product_attribute_groups = _.sortBy(product_attribute_groups, ['group', 'priority']);

        let attributes = [];

        if (values.type == 'template') {
            if (_.startsWith(values.template, 'system')) {
                attributes = _.map(_.filter(this.props.localization.product_attribute_fields, ['product_attribute_template_group_id', _.parseInt(values.template.substring(7))]), attribute => ({
                    ...attribute,
                    label: getLocalized(attribute.label, application_language_id)
                }));
            } else if (_.startsWith(values.template, 'company')) {
                attributes = _.get(_.find(this.state.company_templates, ['id', _.parseInt(values.template.substring(8))]), 'fields', []);
            }
        }

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.row}>
                    <Field name="type" component={RadioGroup} options={[
                        {
                            label: __('specifications.working-set.flyout.add-specification-attribute-group.from-template'),
                            value: 'template',
                        }, {
                            label: __('specifications.working-set.flyout.add-specification-attribute-group.custom'),
                            value: 'custom',
                        }
                    ]} />
                    <div className={styles.type}>
                        {values.type == 'template' && <Field name="template" searchable={true} noSort component={Select} label={__('specifications.working-set.flyout.add-specification-attribute-group.template')} options={product_attribute_groups} />}
                    </div>
                </div>
                <Field name="name" component={Input} localized={languages} label={__('specifications.working-set.flyout.add-specification-attribute-group.group-name')} maxLength={50} />
                {(attributes && !_.isEmpty(attributes)) && <>
                    <div className={styles.label}>{__('specifications.working-set.flyout.add-specification.attributes-list')}</div>
                    <div className={styles.selector}>
                        <button type="button" onClick={() => this.selectAllHandler(0)}>{__('products.flyout.attribute-group.unselect-all')}</button>
                        <button type="button" onClick={() => this.selectAllHandler(1)}>{__('products.flyout.attribute-group.select-all')}</button>
                    </div>
                    {_.map(attributes, (field, i) => (
                        <Field name={"fields." + field.id} key={i} className={styles.attribute} component={Checkbox} label={field.label} />
                    ))}
                </>}
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('working_set_specifications', {
                        add_specification_product_attribute_group_wizard: undefined
                    })} />
                    <Button lightBlue medium submit right leftText={__('button.add')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default AddSpecificationProductAttributeGroupForm;