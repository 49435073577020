import * as React from 'react';
import { Component } from 'react';
import { mapStateToProps, __ } from '../../core/utils';
import styles from '../../styles/forms/working_set_specifications/EditSpecificationToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import { showAlertbox } from '../../redux/actions/general/alertbox';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
class EditWorkingSetSpecificationToolbarForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status_switcher: false,
    };
  }

  render() {
    const statuses = [
      {
        value: 'active',
        label: __('working_set_specifications.status.active'),
        color: '#5777FC',
      },

      {
        value: 'canceled',
        label: __('working_set_specifications.status.canceled'),
        color: '#CCCDD1',
      },
    ];

    const { flyout } = this.props.store;
    const { readOnly } = this.props;

    return (
      <div className={styles.wrapper}>
        <Select
          disabled={readOnly}
          input={{
            value: flyout.status,

            onChange: (status) => {
              if (flyout.status === status) return;

              if (status === 'canceled')
                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: `${__(
                    'specifications.working-set.status.alert.first-warning'
                  )}\n\n${__(
                    'specifications.working-set.status.alert.second-warning'
                  )}`,
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-change-status'),
                      onClick: () => this.props.changeStatus(status),
                    },
                  ],
                });
              else {
                this.props.changeStatus(status);
              }
            },
            onBlur: () => this.setState({ status_switcher: false }),
            onFocus: () => this.setState({ status_switcher: true }),
          }}
          meta={{
            active: this.state.status_switcher,
          }}
          label={__('Status')}
          options={statuses}
          noSort
          dropdown
          top={34}
          left={-40}
          icon={<TaskStatusIcon className={styles.taskStatus} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip
              text={__('working_set_specifications.tooltip.icon.status')}
            >
              <div
                className={classNames(styles.dropdown)}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskStatusIcon className={styles.taskStatus} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </div>
    );
  }
}

export default EditWorkingSetSpecificationToolbarForm;
