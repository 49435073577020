import * as React from "react";
import {Component} from "react";
import styles from "../styles/containers/SideBar.scss";
import LogoNewWideIcon from "../assets/images/logo-new-wide-66x11.svg";
import classNames from "classnames";
import UncheckedIcon from "../assets/images/unchecked-15x15.svg";
import CheckedIcon from "../assets/images/checked-15x15.svg";
import NeutralIcon from "../assets/images/neutral-15x15.svg";
import {mapStateToProps, redirect} from "../core/utils";
import {withRouter} from "react-router";
import ArrowDownMiddleIcon from "../assets/images/arrow-down-middle-15x15.svg";
import Tooltip from "../components/Tooltip";

@withRouter
@mapStateToProps(state => ({
	router: state.router,
	sidebar: state.general.sidebar,
}))
class SideBar extends Component {
	render() {
		const MenuItem = item => {
			const onClick = () => {
				if (item.onClick) {
					item.onClick();
				} else if (item.link) {
					redirect(item.link());
				}
			}

			const active = () => {
				if(item.active) {
					if(_.isFunction(item.active)) {
						return item.active();
					} else {
						return item.active;
					}
				} else {
					return false;
				}
			}

			const checkbox_status = item.checkbox && item.checkbox.status();

			return (
				<>
					<div className={classNames(styles.item, active() && styles.active)} onClick={onClick}>
						{item.onArrowClick && <ArrowDownMiddleIcon className={classNames(styles.arrow, item.expanded && styles.expanded)} onClick={e => {
							e.stopPropagation();

							item.onArrowClick();
						}} />}
						<Tooltip text={item.title} placement="right"><span className={styles.name}>{item.title}</span></Tooltip>
						{item.count && <span className={styles.count}>&middot; {item.count}</span>}
						{checkbox_status && (
							<>
								{checkbox_status == 'checked' && <CheckedIcon className={styles.checked} onClick={e => {
									e.stopPropagation();

									item.checkbox.onClick();
								}} />}
								{checkbox_status == 'unchecked' && <UncheckedIcon className={styles.unchecked} onClick={e => {
									e.stopPropagation();

									item.checkbox.onClick();
								}} />}
								{checkbox_status == 'neutral' && <NeutralIcon className={styles.neutral} onClick={e => {
									e.stopPropagation();

									item.checkbox.onClick();
								}} />}
							</>
						)}
					</div>
					{(!_.isEmpty(item.items) && (!item.onArrowClick || item.expanded)) && <div className={styles.subitems}>
						{_.map(item.items, (item, i) => (
							<MenuItem {...item} key={i} />
						))}
					</div>}
				</>
			)
		}

		return (
			<div className={classNames(styles.wrapper, (this.props.match.path == '/' || this.props.sidebar.hidden) && styles.sidebarCollapsed)}>
				<div className={styles.logo}>
					<LogoNewWideIcon />
				</div>
				<div className={styles.module}>{this.props.sidebar.title}</div>
				<div className={styles.items}>
					{_.map(this.props.sidebar.items, (item, i) => (
						<MenuItem {...item} key={i} />
					))}
				</div>
			</div>
		);
	}
}

export default SideBar;