const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.all_meetings/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };
    case 'table.all_meetings/LIST_ALL_MEETINGS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        filters: action.response.filters,
        ...action.response.pagination,
      };
    case 'table.all_meetings/ADD_TASKS_TO_MEETING_SUCCESS':
      return {
        ...state,
        forceUpdate: true,
      };

    case 'table.all_meetings/REORDER_MEETING_ITEMS_SUCCESS':
      return {
        ...state,
        forceUpdate: !_.isEmpty(state.move_meeting_item_wizard),
      };

    case 'table.all_meetings/ADD_UNREGISTERED_MEETING_PARTICIPANT_SUCCESS':
      return {
        ...state,
        forceMeetingUpdate: true,
      };
    case 'table.all_meetings/ADD_UNREGISTERED_MEETING_FOLLOWER_SUCCESS':
      return {
        ...state,
        forceMeetingUpdate: true,
      };
    case 'table.all_meetings/UPDATE_UNREGISTERED_MEETING_PARTICIPANT_SUCCESS':
      return {
        ...state,
        forceMeetingUpdate: true,
      };
    case 'table.all_meetings/UPDATE_UNREGISTERED_MEETING_FOLLOWER_SUCCESS':
      return {
        ...state,
        forceMeetingUpdate: true,
      };
    case 'table.all_meetings/DELETE_UNREGISTERED_MEETING_PARTICIPANT_SUCCESS':
      return {
        ...state,
        forceMeetingUpdate: true,
      };
    case 'table.all_meetings/DELETE_UNREGISTERED_MEETING_FOLLOWER_SUCCESS':
      return {
        ...state,
        forceMeetingUpdate: true,
      };
    default:
      return state;
  }
}
