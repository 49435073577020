import React from 'react';
import { useEffect, useState } from 'react';
import styles from '../styles/wizards/CreateTenderWizard.scss';
import AddTaskIcon from '../assets/images/add-task-60x60.svg';
import {
  __,
  getActiveStage,
  setTableParams,
  validateForm,
} from '../core/utils';
import { getStagesLanguages } from '../redux/actions/table/stages';
import * as _ from 'lodash';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import CreateTenderForm from '../forms/create_tender_wizard/CreateTenderForm';
import { createTender, listAllTenders } from '../redux/actions/table/tenders';
import { connect } from 'react-redux';
import { setNotification } from '../redux/actions/general/notification';
import { withRouter } from 'react-router';

const CreateTenderWizard = (props) => {
  const [project_languages, set_project_languages] = useState(null);

  useEffect(() => {
    getStagesLanguages(getActiveStage()).then(({ response }) => {
      set_project_languages(response.data);
    });
  }, []);

  const _handleSubmit = (formData) => {
    const { edit } = formData;
    formData = {
      ..._.omit(formData, ['edit']),
      followers: _.map(formData.followers, 'id'),
      administrators: _.map(formData.administrators, 'id'),
    };
    return validateForm(
      createTender(getActiveStage(), formData).then(({ response }) => {
        listAllTenders(getActiveStage()).then(() => {
          if (edit) {
            setTableParams('tenders', {
              clicked_row: response.data.id,
            });
          }
        });

        setNotification(__('wizard.add-tender.confirm'));

        props.onClose();
      })
    );
  };

  const { id, avatar_url, email, firstname, lastname } = props.user;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.add-tender.title.create-new')}</span>
          <span>{__('wizard.add-tender.subtitle.create-new')}</span>
        </div>
        <div className={styles.right}>
          <AddTaskIcon />
        </div>
      </div>
      <div className={styles.body}>
        {project_languages && (
          <CreateTenderForm
            projectLanguages={project_languages}
            onSubmit={_handleSubmit}
            initialValues={{
              description: {},
              title: {},
              languages: [
                _.findKey(project_languages, (item) => item == true), //primarni jezik projekta
              ],
              status: 'draft',
              administrators: [
                { id, avatar_url, email, name: `${firstname} ${lastname}` },
              ],
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['tenders'],
    user: _.get(state, 'auth'),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(CreateTenderWizard)
  )
);
