import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/UploadDocumentsWizard.scss";
import {withRouter} from "react-router";
import AddDocumentIcon from "../assets/images/add-document-60x60.svg";
import classNames from "classnames";
import * as _ from "lodash";
import ReactDropzone from "react-dropzone";
import {setNotification} from "../redux/actions/general/notification";
import Button from "../components/Button";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import {connect} from "react-redux";
import {__, bytesToSize, getActiveProject, getActiveStage} from "../core/utils";
import autobind from "autobind-decorator";
import {
    hideDocumentUploadFile,
    listDocuments,
    minimizeDocumentUploads,
    uploadDocument
} from "../redux/actions/table/documents";

const mapStateToProps = state => {
    return {
        table: state.table["documents"],
        active_modules: state.general.active_modules,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            hideUploadDocumentsWizard: () => dispatch({
                type: "table.documents/SET_TABLE_PARAMS",
                params: {
                    wizard_active: undefined,
                }
            }),
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
class UploadDocumentsWizard extends Component {
    @autobind
    _handleDrop(acceptedFiles, rejectedFiles) {
        this.setState({ drag: false });

        _.forEach(_.filter(acceptedFiles, file => file.size < 104857600), file => {
            uploadDocument(this.props.match.params.stage, file).then(() => listDocuments(this.props.match.params.stage));
        });

        if(!_.isEmpty(_.filter(acceptedFiles, file => file.size >= 104857600))) {
            setNotification({
                text: __('uploadplan.warning.size'),
                type: "warning"
            });
        }
    }

    @autobind
    _minimize() {
        minimizeDocumentUploads();

        setNotification(__('uploadplan.info.background'));
    }

    @autobind
    _cancelUpload(file) {
        file.cancel();
    }

    @autobind
    _cancelUploads() {
        _.forEach(_.filter(this.props.table.files, file => _.has(file, 'cancel')), file => file.cancel());
    }

    @autobind
    _close() {
        this.props.actions.hideUploadDocumentsWizard();

        _.forEach(_.filter(this.props.table.files, file => _.has(file, 'success')), file => hideDocumentUploadFile(file.id));
    }

    render() {
        const uploading = !_.isEmpty(this.props.table.files) && !_.isEmpty(_.filter(this.props.table.files, file => !_.has(file, 'success')));
        const project = _.find(this.props.active_modules.items, ['id', getActiveProject()]);
        const stage = _.find(project.stages, ['id', getActiveStage()]);

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('uploaddocument.title.upload-documents')}</span>
                        <span>{__('uploaddocument.title.upload-documents-to-workspace')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddDocumentIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.location}>
                        <span><strong>{__('uploadplan.title.project')}:</strong> {project.name}</span>
                        <span><strong>{__('uploadplan.title.stage')}:</strong> {stage.name}</span>
                    </div>
                    {!_.isEmpty(this.props.table.files) && <div className={styles.filelist}>
                        {_.map(this.props.table.files, (file, i) => (
                            <div key={i}>
                                <div className={classNames(styles.bar, file.success && styles.success, file.success === false && styles.error)} style={{width: file.progress + "%"}}/>
                                <div className={styles.info}>
                                    <span>{file.name}</span>
                                    <span>{bytesToSize(file.size)}</span>
                                    {_.isUndefined(file.success) && <CloseSmallIcon onClick={() => this._cancelUpload(file)} />}
                                </div>
                            </div>
                        ))}
                    </div>}
                    <ReactDropzone
                        className={classNames(styles.dropzone, _.get(this.state, "drag") && styles.drag)}
                        onDrop={this._handleDrop}
                        onDragOver={() => this.setState({ drag: true })}
                        onDragLeave={() => this.setState({ drag: false })}
                    >
                        <span>{__('dropzone.title.drop-your')} {__('dropzone.title.files')} {__('dropzone.title.here')}</span>
                        <span>{__('dropzone.title.or-select')}</span>
                    </ReactDropzone>
                    <div className={styles.footer}>
                        {uploading && <Button lightGray medium left middleText={__('uploadplan.button.cancel')} onClick={this._cancelUploads} />}
                        {uploading && <Button gray medium right middleText={__('uploadplan.button.minimize')} onClick={this._minimize} />}
                        {!uploading && <Button gray medium right middleText={__('uploadplan.button.close')} onClick={this._close} />}
                    </div>
                </div>
            </>
        );
    }
}

export default UploadDocumentsWizard;
