import React, { Component } from 'react';
import styles from '../styles/components/NoteChooser.scss';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import SearchIcon from '../assets/images/search-15x15.svg';
import { __, mapStateToProps } from '../core/utils';
import * as _ from 'lodash';
import { registerMultieditField } from '../redux/actions/general/multiedit';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import PlusMiddleIcon from '../assets/images/plus-middle-15x15.svg';
import OutsideClickWrapper from './OutsideClickWrapper';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import FieldComponent from './FieldComponent';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { isMobileOnly } from 'react-device-detect';
import Tooltip from './Tooltip';
import LogoIcon from '../assets/images/logo-50x50.svg';
import SearchableDropdown from './SearchableDropdown';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import { isArray } from 'lodash';

@FieldComponent
@mapStateToProps((state) => ({
  multiedit: state.general.multiedit,
}))
class NoteChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      selectedFilterValues: {
        specification_subgroup_ids: [
          this.props?.initValues?.specification_subgroup_id,
        ],
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.meta.active != this.props.meta.active) {
      this.setState(
        {
          query: '',
          selected: this.props.input.value || [],
        },
        () => {
          this.props.recalculateOffset();
        }
      );
    }

    if (
      prevState.selected != this.state.selected ||
      prevState.query != this.state.query
    ) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.props.input.onFocus();
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _handleSave() {
    this.props.input.onChange(this.state.selected);

    this._handleClose();
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _handleKeyDown(e) {
    if (e.keyCode == 27) {
      e.stopPropagation();
      e.preventDefault();

      this._handleClose();
    }

    const { query, selected } = this.state;

    const available_options = _.filter(
      this.props.options,
      ({ value }) => !_.includes(selected, value)
    );

    const results = _.filter(
      available_options,
      (item) =>
        _.isEmpty(this.state.query) ||
        _.includes(item.label.toLowerCase(), this.state.query.toLowerCase()) ||
        (item.group &&
          _.includes(item.group.toLowerCase(), this.state.query.toLowerCase()))
    );

    if (e.keyCode == 13) {
      e.stopPropagation();
      e.preventDefault();

      !_.isEmpty(query) &&
        !_.isEmpty(results) &&
        !_.first(results).disabled &&
        this._toggleValue(_.first(results).value, true);
    }
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    this.refs.search.focus();
  }

  @autobind
  clearFilter(name) {
    this.setState((prevState) => ({
      selectedFilterValues: _.omit(this.state.selectedFilterValues, name),
    }));
  }

  @autobind
  _toggleValue(value, highlight_query = false) {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );

    multiedit_enabled &&
      registerMultieditField(this.props.meta.form, this.props.input.name);

    this.setState(
      {
        selected: _.xor(this.state.selected, [value]),
      },
      () => {
        highlight_query && this.refs.search && this.refs.search.select();
      }
    );
  }

  render() {
    const {
      noSort,
      searchable,
      disabled,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
      filters,
    } = this.props;

    const { query, selected, selectedFilterValues } = this.state;

    const selected_options = _.filter(this.props.options, ({ value }) =>
      _.includes(selected, value)
    );
    const available_options = _.filter(
      this.props.options,
      ({ value }) => !_.includes(selected, value)
    );

    let results = _.filter(available_options, (item) => {
      return (
        _.isEmpty(this.state.query) ||
        _.includes(item.label.toLowerCase(), this.state.query.toLowerCase()) ||
        (item.group &&
          _.includes(item.group.toLowerCase(), this.state.query.toLowerCase()))
      );
    });

    results = _.filter(results, (result) => {
      return _.every(Object.keys(selectedFilterValues), (key) => {
        return isArray(result[key])
          ? _.some(result[key], (data) =>
              _.includes(selectedFilterValues[key], data)
            )
          : _.includes(selectedFilterValues[key], result[key]);
      });
    });

    let selected_filtered_results = noSort
      ? selected_options
      : _.sortBy(selected_options, (item) => item.label.toLowerCase());
    let available_filtered_results = noSort
      ? results
      : _.sortBy(results, (item) => item.label.toLowerCase());

    return (
      <div
        className={classNames(
          styles.wrapper,
          _.isEmpty(this.props.input.value) && styles.placeholder,
          this.props.meta.active && styles.focus,
          this.props.center && styles.center,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          this.props.disabled && styles.disabled
        )}
      >
        <div className={styles.label}>
          <label onClick={this._handleOpen}>
            <Tooltip text={this.props.labelTooltip || this.props.label}>
              {this.props.label}
            </Tooltip>
          </label>
        </div>
        <div
          className={styles.selectGroup}
          ref={elementRef}
          onClick={this._handleOpen}
        >
          <div className={styles.select}>
            {!_.isEmpty(this.props.input.value)
              ? _.map(
                  _.filter(this.props.options, (item) =>
                    _.includes(_.get(this.props.input, 'value', []), item.value)
                  ),
                  'label'
                ).join(', ')
              : ''}
          </div>
          {!this.props.center && !this.props.disabled && (
            <ArrowDownMiddleIcon />
          )}
        </div>
        {this.props.meta.active && (
          <OutsideClickWrapper closable={false}>
            <KeyboardEventHandler
              handleKeys={['esc']}
              onKeyEvent={this._handleClose}
            />
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              {!_.isEmpty(selected_filtered_results) && (
                <div className={styles.selected}>
                  <div className={styles.title}>
                    <span className={styles.currentlySelected}>
                      {__(
                        'project-list.all-projects.create-new-project.currently-selected'
                      )}
                      ({_.size(selected_filtered_results)})
                    </span>
                  </div>
                  <div className={styles.list}>
                    {_.map(selected_filtered_results, (item, i) => (
                      <div
                        key={i}
                        className={classNames(
                          styles.row,
                          item.disabled && styles.itemDisabled
                        )}
                        onClick={() =>
                          !item.disabled && this._toggleValue(item.value)
                        }
                      >
                        <span>{item.label}</span>
                        {!disabled && !item.disabled && (
                          <CloseSmallIcon className={styles.close} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {!_.isEmpty(available_options) && (
                <div className={styles.available}>
                  <div className={styles.title}>
                    <span className={styles.availableOptions}>
                      {__(
                        'project-list.all-projects.create-new-project.available-options'
                      )}
                      ({_.size(results)})
                    </span>

                    <div className={styles.filter}>
                      {_.map(filters, (filter) => {
                        return (
                          <SearchableDropdown
                            multiselect
                            options={filter.options}
                            dropdownWrapperClassName={styles.dropdownWrapper}
                            name={filter.name}
                            selectedValues={selectedFilterValues[filter.name]}
                            onSelect={(item) => {
                              const keyValues =
                                this.state.selectedFilterValues[filter.name];

                              let newValues = _.get(item, 'groupHeading')
                                ? [...item.value]
                                : [item.value];

                              if (!_.isUndefined(keyValues)) {
                                newValues = [...keyValues, ...newValues];
                              }

                              this.setState((prevState, props) => ({
                                selectedFilterValues: {
                                  ...prevState.selectedFilterValues,
                                  [filter.name]: newValues,
                                },
                              }));
                            }}
                            onRemove={(item) => {
                              this.setState(
                                (prevState) => ({
                                  selectedFilterValues: {
                                    ...prevState.selectedFilterValues,

                                    [filter.name]: _.filter(
                                      prevState.selectedFilterValues[
                                        filter.name
                                      ],
                                      (value) =>
                                        !_.includes(
                                          _.get(item, 'groupHeading')
                                            ? item.value
                                            : [item.value],
                                          value
                                        )
                                    ),
                                  },
                                }),
                                () => {
                                  if (
                                    _.size(
                                      this.state.selectedFilterValues[
                                        filter.name
                                      ]
                                    ) == 0
                                  ) {
                                    this.setState({
                                      selectedFilterValues: _.omit(
                                        this.state.selectedFilterValues,
                                        filter.name
                                      ),
                                    });
                                  }
                                }
                              );
                            }}
                            header={
                              <div className={styles.dropdownHeader}>
                                <Tooltip
                                  text={__(
                                    'note-chooser.filters.' + filter.name
                                  )}
                                  className={styles.filterHeaderName}
                                >
                                  {__('note-chooser.filters.' + filter.name)}
                                </Tooltip>

                                <span
                                  className={styles.filterHeaderClearAll}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.clearFilter(filter.name);
                                  }}
                                >
                                  {__('note-chooser.filters.clear')}
                                </span>
                              </div>
                            }
                          >
                            <div
                              className={classNames(
                                styles.bubble,
                                _.size(selectedFilterValues[filter.name]) ===
                                  0 && styles.bubbleEmpty
                              )}
                            >
                              <span>
                                {__('note-chooser.filters.' + filter.name)}
                              </span>
                              {_.size(selectedFilterValues[filter.name]) >
                                0 && (
                                <strong className={styles.filterCount}>
                                  ({_.size(selectedFilterValues[filter.name])})
                                </strong>
                              )}
                              <ArrowDownSmallIcon />
                            </div>
                          </SearchableDropdown>
                        );
                      })}
                    </div>
                  </div>

                  {searchable && (
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        ref='search'
                        type='text'
                        value={query}
                        autoFocus={true}
                        placeholder={__('multiselect.title.search')}
                        onChange={this._handleSearch}
                        onKeyDown={this._handleKeyDown}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                  )}
                  <div className={styles.list}>
                    {_.map(
                      _.groupBy(
                        _.filter(available_filtered_results, 'group'),
                        'group'
                      ),
                      (items, group) => (
                        <div className={styles.group} key={group}>
                          <div className={styles.heading}>{group}</div>
                          {_.map(items, (item, i) => (
                            <div
                              key={i}
                              className={classNames(
                                styles.row,
                                item.disabled && styles.itemDisabled
                              )}
                              onClick={() =>
                                !item.disabled &&
                                this._toggleValue(item.value, true)
                              }
                            >
                              <div
                                className={classNames(
                                  styles.icon,
                                  item.is_company_note
                                    ? styles.company
                                    : styles.system
                                )}
                              >
                                {item.is_company_note ? (
                                  'MY'
                                ) : (
                                  <Tooltip text={'System'}>
                                    <LogoIcon />
                                  </Tooltip>
                                )}
                              </div>

                              <span>{item.label}</span>

                              <PlusMiddleIcon className={styles.plus} />
                            </div>
                          ))}
                        </div>
                      )
                    )}

                    {_.isEmpty(available_filtered_results) && (
                      <div className={styles.noResults}>No results found.</div>
                    )}
                  </div>
                </div>
              )}

              {/* FOOTER */}
              <div className={styles.footer}>
                <ButtonGroup right>
                  <Button
                    lightGray
                    medium
                    middleText={__('button.cancel')}
                    onClick={this._handleClose}
                  />
                  <Button
                    lightBlue
                    medium
                    middleText={__('button.done')}
                    onClick={this._handleSave}
                  />
                </ButtonGroup>
              </div>
            </div>
          </OutsideClickWrapper>
        )}
        <div className={styles.bar} />
      </div>
    );
  }
}

export default NoteChooser;
