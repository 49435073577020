import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../styles/forms/add_specification_wizard/AddSpecificationForm.scss";
import Button from "../../components/Button";
import { __, getLocalized, mapStateToProps, setTableParams, getActiveProject, getActiveStage } from "../../core/utils";
import Input from "../../components/Input";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import Select from "../../components/Select";
import { checkSpecificationCode, getNextSpecificationCode, listPossibleSpecificationStages, listPossibleSpecificationTeams } from "../../redux/actions/table/working_set_specifications";
import ButtonGroup from "../../components/ButtonGroup";
import autobind from "autobind-decorator";
import * as _ from "lodash";
import RadioGroup from "../../components/RadioGroup";

@reduxForm({
	form: "copy_specification_wizard.copy_specification"
})
@mapStateToProps((state) => ({
	values: getFormValues("copy_specification_wizard.copy_specification")(state),
	application_language_id: state.auth.language_id,
	create_action: state.table.working_set_specifications.create_action,
	active_modules: state.general.active_modules
}))
@subscribe(Localization, "localization")
class CopySpecificationForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_teams: []
			//possible_stages: []
		};
	}

	componentDidMount() {
		if (this.props.values.stage_id) {
			this.props.change("team_id", null);

			listPossibleSpecificationStages(_.get(this.props, "values.project_id"))
				.then(({ response }) => {
					this.setState({ possible_stages: response.data });

					if (_.size(response.data) == 1) {
						this.props.change("stage_id", _.get(response.data, [0, "id"], null));
					}
				})
				.then(() =>
					listPossibleSpecificationTeams(this.props.values.stage_id).then(({ response }) => {
						this.setState({ possible_teams: response.data });

						if (_.size(response.data) == 1) {
							this.props.change("team_id", _.get(response.data, [0, "id"], null));
						}
					})
				)
				.then(() => {
					this.props.change("specification_subgroup_id", _.get(this.props, "specification_subgroup_id"));

					getNextSpecificationCode(this.props.values.stage_id, {
						specification_subgroup_id: _.get(this.props, "values.specification_subgroup_id")
					}).then(({ response }) => {
						this.props.change("code", response.data.code);
					});
				});
		}
	}

	componentDidUpdate(prevProps) {
		if (_.get(prevProps, "values.project_id") && _.get(prevProps, "values.project_id") != _.get(this.props, "values.project_id") && _.get(this.props, "values.project_id")) {
			listPossibleSpecificationStages(_.get(this.props, "values.project_id")).then(({ response }) => {
				this.setState({ possible_stages: response.data });

				if (_.size(response.data) !== 0) {
					this.props.change("stage_id", _.get(response.data, [0, "id"], null));
				}
			});

			this.props.change("stage_id", null);
			this.props.change("team_id", null);
		}

		if (prevProps.values.stage_id != this.props.values.stage_id && this.props.values.stage_id) {
			this.props.change("team_id", null);

			listPossibleSpecificationTeams(this.props.values.stage_id)
				.then(({ response }) => {
					this.setState({ possible_teams: response.data });

					if (_.size(response.data) == 1) {
						this.props.change("team_id", _.get(response.data, [0, "id"], null));
					}
				})
				.then(() => {
					if (_.get(this.props, "values.specification_subgroup_id"))
						getNextSpecificationCode(this.props.values.stage_id, {
							specification_subgroup_id: _.get(this.props, "values.specification_subgroup_id")
						}).then(({ response }) => {
							this.props.change("code", response.data.code);
						});
				});
		}

		if (_.get(prevProps, "values.specification_subgroup_id") != _.get(this.props, "values.specification_subgroup_id") && _.get(this.props, "values.specification_subgroup_id")) {
			getNextSpecificationCode(this.props.values.stage_id, {
				specification_subgroup_id: _.get(this.props, "values.specification_subgroup_id")
			}).then(({ response }) => {
				this.props.change("code", response.data.code);
			});
		}

		if (prevProps.values.specification_subgroup_id != this.props.values.specification_subgroup_id || prevProps.values.code != this.props.values.code || prevProps.values.team_id != this.props.values.team_id) {
			setTableParams("working_set_specifications", {
				create_action: false
			});

			if (this.props.values.specification_subgroup_id && this.props.values.code && this.props.values.team_id && _.size(this.props.values.code) == 2) {
				checkSpecificationCode(this.props.values.team_id, {
					code: this.props.values.code,
					specification_subgroup_id: this.props.values.specification_subgroup_id
				})
					.then(() => {
						setTableParams("working_set_specifications", {
							create_action: false
						});
					})
					.catch(({ response }) => {
						if (_.has(response, "code") && response.code === 1) {
							setTableParams("working_set_specifications", {
								create_action: true
							});
						} else {
							setTableParams("working_set_specifications", {
								create_action: false
							});
						}
					});
			}
		}
	}

	@autobind
	_handleSubmit(edit) {
		this.props.change("edit", edit);

		setTimeout(() => this.props.submit(), 10);
	}

	render() {
		const { values, projectLanguage, application_language_id, create_action } = this.props;
		const { specification_groups, specification_subgroups } = this.props.localization;

		const { possible_teams, possible_stages } = this.state;

		const possible_projects = this.props.active_modules.items;

		const project = _.find(this.props.active_modules.items, ["id", getActiveProject()]);
		const stage = project ? _.find(project.stages, ["id", getActiveStage()]) : null;

		return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				{stage && (
					<div className={styles.location}>
						<span>
							<strong>
								{project.name} / {stage.name} / {values.specification_code}
							</strong>
						</span>
					</div>
				)}

				<Field
					name="project_id"
					searchable={true}
					component={Select}
					label={__("specifications.working-set.copy-specification.project")}
					options={_.map(possible_projects, (project) => ({
						value: project.id,
						label: project.name
					}))}
				/>
				<Field
					name="stage_id"
					searchable={true}
					disabled={!values.project_id}
					component={Select}
					label={__("specifications.working-set.copy-specification.stage")}
					options={_.map(possible_stages, (stage) => ({
						value: stage.id,
						label: stage.name
					}))}
				/>
				<Field
					name="team_id"
					searchable={true}
					disabled={!values.stage_id}
					component={Select}
					label={__("specifications.working-set.copy-specification.discipline-team")}
					options={_.map(possible_teams, (team) => ({
						value: team.id,
						label: team.company.name + " [" + [team.stage_discipline.code, team.stage_discipline.discipline_id == null ? team.stage_discipline.description + "*" : _.get(this.props.localization, ["disciplines", team.stage_discipline.discipline_id, "name"])].join(": ") + "]"
					}))}
				/>
				<div className={styles.flex}>
					<div className={styles.f2}>
						<Field
							name="specification_subgroup_id"
							searchable={true}
							component={Select}
							label={__("specifications.working-set.copy-specification.category")}
							options={_.map(specification_subgroups, (specification_subgroup) => {
								const specification_group = _.get(specification_groups, _.get(specification_subgroup, "specification_group_id"), {});
								const label = getLocalized(specification_group.code, projectLanguage) + [_.padEnd(specification_subgroup.code, 3, "0"), getLocalized(specification_subgroup.name, application_language_id)].join(": ");
								const group = [getLocalized(specification_group.code, projectLanguage), getLocalized(specification_group.name, application_language_id)].join(": ");

								return {
									value: specification_subgroup.id,
									label,
									group
								};
							})}
						/>
					</div>
					{values.specification_subgroup_id && (
						<div className={styles.code}>
							<div className={styles.code}>{getLocalized(_.get(specification_groups, [_.get(specification_subgroups, [values.specification_subgroup_id, "specification_group_id"]), "code"]), projectLanguage) + _.get(specification_subgroups, [values.specification_subgroup_id, "code"])}</div>
						</div>
					)}
					<div className={styles.right}>
						<Field name="code" component={Input} label={__("specifications.working-set.copy-specification.code")} numeric maxLength={2} />
					</div>
				</div>
				{create_action && values.specification_subgroup_id && _.size(values.code) == 2 && (
					<>
						<div className={styles.exists}>
							{__("specifications.working-set.flyout.copy-specification.code")} <strong>{getLocalized(_.get(specification_groups, [_.get(specification_subgroups, [values.specification_subgroup_id, "specification_group_id"]), "code"]), projectLanguage) + _.get(specification_subgroups, [values.specification_subgroup_id, "code"]) + _.padStart(values.code, 2, "0")}</strong> already exists. Please
							choose an action:
						</div>
						<Field
							name="action"
							component={RadioGroup}
							options={[
								{
									label: __("copy-specification-form.add-as-new-option.main"),
									value: "option_main"
								},
								{
									label: __("copy-specification-form.add-as-new-option.alternative"),
									value: "option_alternative"
								}
							]}
						/>
					</>
				)}
				<div className={styles.footer}>
					<Button
						lightGray
						medium
						left
						middleText={__("button.cancel")}
						onClick={() =>
							setTableParams("working_set_specifications", {
								copy_wizard_active: undefined
							})
						}
					/>
					<ButtonGroup>
						<Button lightBlue medium submit middleText={__("button.copy")} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
						<Button lightBlue medium submit middleText={__("button.copy-edit")} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

export default CopySpecificationForm;
