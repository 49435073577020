const initialState = {
    shown: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.dropdown/SHOW_DROPDOWN":
            return {
                ...state,
                shown: action.name
            };
        case "general.dropdown/HIDE_DROPDOWN":
            return {
                ...state,
                shown: null
            };
        default:
            return state;
    }
}