import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/products/Images.scss';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import {
  listProducts,
  readProduct,
  reorderProductImages,
} from '../../redux/actions/table/products';
import { __, mapStateToProps, setTableParams } from '../../core/utils';
import { showLightbox } from '../../redux/actions/general/lightbox';
import { ReactSortable } from 'react-sortablejs';
import EditIcon from '../../assets/images/edit-16x16.svg';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';

@mapStateToProps((state) => ({
  flyout: state.table.products.flyout,
}))
class Images extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  _removeImage(attachment_id) {
    this.props.handleDeleteProductFile(attachment_id);
  }

  @autobind
  _changeOrder(category, images) {
    setTableParams('products', {
      flyout: {
        ...this.props.flyout,
        images: _.map(this.props.flyout.images, (image, i) => {
          return image.category == category
            ? {
                ...image,
                order: _.findIndex(images, ['id', image.id]),
              }
            : image;
        }),
      },
    });
  }

  render() {
    const { flyout, category, data } = this.props;

    const images = _.sortBy(data, 'order');

    const lightbox_images = _.map(data, (image) => ({
      id: image.id,
      stream_url: image.stream_url,
      title: [image.name, image.ext].join('.'),
    }));

    return (
      <ReactSortable
        list={images.concat([{}])}
        setList={(list) => this._changeOrder(category, list)}
        animation={200}
        delayOnTouchStart={true}
        handle={'.' + styles.thumbnail}
        onEnd={() => {
          reorderProductImages(flyout.id, category, _.map(images, 'id')).then(
            () => {
              setNotification(
                __('product.flyout.images.product-images-order-saved')
              );

              if (category.value == 'thumbnail') {
                readProduct(flyout.id).then(({ response }) => {
                  setTableParams('products', {
                    flyout: {
                      ...flyout,
                      thumb_url: response.data.thumb_url,
                    },
                  });
                });

                listProducts();
              }
            }
          );
        }}
        onMove={(event) => !event.related.classList.contains(styles.add)}
        className={styles.content}
      >
        {_.map(images, (image, i) => (
          <div
            key={i}
            data-id={image.id}
            className={styles.thumbnail}
            onClick={() => showLightbox(lightbox_images, image.id)}
            style={{
              backgroundImage: "url('" + image.thumb_url + "')",
            }}
          >
            <div className={styles.grabber}>
              <GrabberIcon />
            </div>
            <div className={styles.editIcon}>
              <EditIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setTableParams('products', {
                    edit_product_file_category: category,
                    attachment_id: image.id,
                    file_ext: image.ext,
                  });
                }}
              />
            </div>
            <div className={styles.removeIcon}>
              <CloseSmallIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this._removeImage(image.id);
                }}
              />
            </div>
          </div>
        ))}
      </ReactSortable>
    );
  }
}

export default Images;
