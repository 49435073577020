import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/ConfirmAccount.scss";
import {__, redirect} from "../../core/utils";
import classNames from "classnames";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import AuthLayout from "../../layouts/AuthLayout";
import {approveAccount} from "../../redux/actions/companies";

class ApproveAccount extends Component {
	componentDidMount() {
        approveAccount(this.props.match.params.token).then(({ response }) => {
            this.setState({ status: !_.has(response, "data.error") });
        }).catch(() => this.setState({ status: false }));
	}

	render() {
        const status = _.get(this.state, "status");

		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
                    <div className={styles.title}>
                        {status === false && __('Oops ..')}
                        {status === true && __('Thank You!')}
                    </div>
				</div>
				<div className={styles.details}>
					<div className={styles.content}>
                        {status === false && (
							<>
								<p className={styles.strong}>{__("We're sorry, but the verification link you have clicked is invalid.")}</p>
								<p>{__("Please double check the link you have clicked, and try again.")}</p>
							</>
						)}
                        {status === true && <p className={classNames(styles.strong, styles.blue)}>{__("You have successfully approved a new member registration to your company.")}</p>}
					</div>
                    {status === true && (
						<div className={styles.footer}>
							<Button onClick={() => redirect("/")} center leftText={__('Go to All Projects')} rightIcon={ArrowRightMiddleIcon} />
						</div>
					)}
				</div>
			</>
		);
	}
}

export default ApproveAccount;