import {setActiveProject, setActiveStage} from "../../../core/utils";

const initialState = {
    project_onClick: undefined,
    stage_onClick: undefined,
    items: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.active_modules/LIST_ACTIVE_MODULES_SUCCESS":
            const projects = action.response.data;
            const project = _.find(projects, ['active', true]) || _.first(projects);

            setActiveProject(project.id);

            const stages = project.stages;
            const stage = _.find(stages, ['active', true]) || _.first(stages);

            setActiveStage(stage.id);

            return {
                ...state,
                items: action.response.data
            };
        case "general.active_modules/CLEAR_ACTIVE_MODULES":
            return {
                items: null,
                project_onClick: undefined,
                stage_onClick: undefined,
            };
        case "general.active_modules/ENABLE_PROJECTS_DROPDOWN":
            return {
                ...state,
                project_onClick: action.onClick,
            };
        case "general.active_modules/ENABLE_STAGES_DROPDOWN":
            return {
                ...state,
                stage_onClick: action.onClick,
            };
        default:
            return state;
    }
}