import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import Textarea from "../../components/Textarea";
import {__, transformToListPairs} from "../../core/utils";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/storeys/EditStoreyForm.scss";
import Input from "../../components/Input";
import {withRouter} from "react-router";
import Select from "../../components/Select";
import {listPossibleBuildings} from "../../redux/actions/table/storeys";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";

@reduxForm({
    form: "storeys.edit_storey",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
class EditStoreyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_buildings: [],
        }
    }

    componentDidMount() {
        listPossibleBuildings(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_buildings: response.data});
        });
    }

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {data, handleSubmit} = this.props;
        const {possible_buildings} = this.state;
        const {project_languages} = data;

        return (
            <Form onSubmit={handleSubmit}>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="building_id" component={Select} label={__('storeys.field.building')} searchable options={transformToListPairs(possible_buildings, 'title')} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="number" component={Input} label={__('storeys.field.number')} maxLength={3} />
                    </div>
                    <div className={styles.f3}>
                        <Field name="name" component={Input} localized={project_languages} label={__('storeys.field.name')} maxLength={50} />
                    </div>
                </div>
                <Field name="description" component={Textarea} localized={project_languages} label={__('sites.field.description')} maxLength={2000} rows={1} maxRows={10} />
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="relative_altitude" component={Input} label={__('storeys.field.relative-altitude')} numeric decimal maxLength={8} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="height" component={Input} label={__('storeys.field.height')} numeric decimal maxLength={8} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="volume" component={Input} label={__('storeys.field.volume')} numeric decimal maxLength={10} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="area" component={Input} label={__('storeys.field.area')} numeric decimal maxLength={10} />
                    </div>
                </div>
            </Form>
        );
    }
}

export default EditStoreyForm;
