import store from '../../../core/store';

export const importSpreadsheet = (stage_id, formData) => {
  return store.dispatch({
    action: 'table.all_meetings/IMPORT_SPREADSHEET',
    method: 'PUT',
    endpoint: '/spreadsheet/' + stage_id + '/import',
    data: formData,
  });
};
