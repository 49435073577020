import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddProductWizard.scss";
import classNames from "classnames";
import {__, mapStateToProps, resetForm, setTableParams, validateForm} from "../core/utils";
import {getFormValues, SubmissionError} from "redux-form";
import Localization from "../helpers/Localization";
import {subscribe} from "react-contextual";
import autobind from "autobind-decorator";
import ProductBasicAttributesForm from "../forms/add_product_wizard/ProductBasicAttributesForm";
import {createProduct, listProducts, validateProductBasicAttributes} from "../redux/actions/table/products";
import ProductCategoriesForm from "../forms/add_product_wizard/ProductCategoriesForm";
import {setNotification} from "../redux/actions/general/notification";
import * as _ from "lodash";

@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    basic_attributes: getFormValues("add_product_wizard.basic_attributes")(state),
    initial_type: _.get(state.table, 'products.type', 'product')
}))
class AddProductWizard extends Component {
    steps = [
        {
            id: "01",
            title: __("wizard.add-product.title.product-basic-attributes")
        },
        {
            id: "02",
            title: __("wizard.add-product.title.product-categories")
        }
    ];

    componentWillUnmount() {
        resetForm('add_product_wizard');
    }

    @autobind
    _setActiveStep(id) {
        this.setState({ active: _.find(this.steps, item => item.id === id) });
    }

    @autobind
    _handleBasicAttributesSubmit(formData) {
        return validateForm(validateProductBasicAttributes(formData).then(() => {
            this._setActiveStep("02");
        }));
    }

    @autobind
    _handleCategories(formData) {
        const data = {
            ...this.props.basic_attributes,
            subgroups: _.map(formData.rows, 'subgroup'),
        };

        return validateForm(createProduct(data).then(({response}) => {
            this.props.onClose();

            setNotification(__("wizard.add-product.message.success"));

            listProducts();

            setTableParams('products', {
                clicked_row: response.data.id,
            });
        })).catch(({errors}) => {
            if(_.has(errors, 'subgroups')) {
                throw new SubmissionError(_.transform(errors.subgroups, (errors, error, field_id) => {
                    _.set(errors, ['rows', field_id, 'subgroup'], error);

                    return errors;
                }, {}));
            }
        });
    }

    render() {
        const active = _.get(this.state, "active", _.first(this.steps));

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.titleBar}>
                        <div className={styles.left}>
                            <span>{__('wizard.add-product.title.create-new-product')}</span>
                            <span>{active.title}</span>
                        </div>
                        <div className={styles.right}>
                            <span>{active.id}</span>
                            <span>{_.last(this.steps).id}</span>
                        </div>
                    </div>
                    <div className={styles.steps}>
                        {_.map(this.steps, (step, i) => (
                            <div key={i} className={classNames(styles.step, active.id == step.id && styles.active, step.id < active.id && styles.completed)}>
                                <span>{step.id}</span>
                                <span>{step.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.body}>
                    {active.id === "01" && (
                        <ProductBasicAttributesForm
                            onSubmit={this._handleBasicAttributesSubmit}
                            initialValues={{
                                type: this.props.initial_type,
                                languages: [1]
                            }}
                        />
                    )}
                    {active.id === "02" && (
                        <ProductCategoriesForm
                            onBack={() => this._setActiveStep("01")}
                            onSubmit={this._handleCategories}
                            initialValues={{
                                rows: [{}]
                            }}
                        />
                    )}
                </div>
            </>
        );
    }
}

export default AddProductWizard;
