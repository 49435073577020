import store from '../../../core/store';
import { __ } from '../../../core/utils';

export const toggleSidebar = () => {
  return store.dispatch({
    type: 'general.sidebar/TOGGLE_SIDEBAR',
  });
};

export const toggleSidebarCollapse = (key) => {
  return store.dispatch({
    type: 'general.sidebar/TOGGLE_SIDEBAR_COLLAPSE',
    key,
  });
};

export const hideSidebar = () => {
  return store.dispatch({
    type: 'general.sidebar/HIDE_SIDEBAR',
  });
};

export const createSidebar = (options) => {
  return store.dispatch({
    type: 'general.sidebar/CREATE_SIDEBAR',
    options,
  });
};

export const updateSidebar = (key, value) => {
  return store.dispatch({
    type: 'general.sidebar/UPDATE_SIDEBAR',
    key,
    value,
  });
};

export const createSuperadminSidebar = () => {
  const state = store.getState(); //get current redux state

  const superadmin = _.get(state, 'auth.superadmin'); //get all superadmin privileges
  const pathname = state.router.location.pathname; //get current pathname from 'router' reducer

  let items = [];

  superadmin.list_companies &&
    items.push({
      title: __('modules.submodules.title.companies'),
      link: '/superadmin/companies',
    });

  superadmin.list_users &&
    items.push({
      title: __('modules.submodules.title.users'),
      link: '/superadmin/users',
    });

  superadmin.list_raw_materials &&
    items.push({
      title: __('modules.submodules.title.filter-materials'),
      link: '/superadmin/raw_materials',
    });

  superadmin.list_materials &&
    items.push({
      title: __('modules.submodules.title.materials'),
      link: '/superadmin/materials',
    });

  superadmin.list_languages &&
    items.push({
      title: __('modules.submodules.title.languages'),
      link: '/superadmin/languages',
    });

  superadmin.list_translations &&
    items.push({
      title: __('modules.submodules.title.translations'),
      link: '/superadmin/translations',
    });

  superadmin.list_note_categories &&
    items.push({
      title: __('modules.submodules.title.instruction-categories'),
      link: '/superadmin/note_categories',
    });

  superadmin.list_notes &&
    items.push({
      title: __('modules.submodules.title.instructions'),
      link: '/superadmin/notes',
    });

  superadmin.list_products &&
    items.push({
      title: __('modules.submodules.title.products'),
      link: '/superadmin/products',
    });

  superadmin.list_raw_colors &&
    items.push({
      title: __('modules.submodules.title.raw-colors'),
      link: '/superadmin/raw_colors',
    });

  superadmin.list_colors &&
    items.push({
      title: __('modules.submodules.title.colors'),
      link: '/superadmin/colors',
    });

  superadmin.list_paper_sizes &&
    items.push({
      title: __('modules.submodules.title.paper-sizes'),
      link: '/superadmin/paper_sizes',
    });

  superadmin.list_product_attribute_template_groups &&
    items.push({
      title: __('modules.submodules.title.product-attribute-template-groups'),
      link: '/superadmin/product_attribute_template_groups',
    });

  superadmin.list_product_attribute_template_fields &&
    items.push({
      title: __('modules.submodules.title.product-attribute-template-fields'),
      link: '/superadmin/product_attribute_template_fields',
    });

  superadmin.list_regions &&
    items.push({
      title: __('modules.submodules.title.regions'),
      link: '/superadmin/regions',
    });

  superadmin.list_countries &&
    items.push({
      title: __('modules.submodules.title.countries'),
      link: '/superadmin/countries',
    });

  superadmin.list_unit_groups &&
    items.push({
      title: __('modules.submodules.title.unit-groups'),
      link: '/superadmin/unit_groups',
    });

  superadmin.list_units &&
    items.push({
      title: __('modules.submodules.title.units'),
      link: '/superadmin/units',
    });

  superadmin.list_building_types &&
    items.push({
      title: __('modules.submodules.title.building-types'),
      link: '/superadmin/building_types',
    });

  superadmin.list_functional_spaces &&
    items.push({
      title: __('modules.submodules.title.functional-spaces'),
      link: '/superadmin/functional_spaces',
    });

  superadmin.list_disciplines &&
    items.push({
      title: __('modules.submodules.title.disciplines'),
      link: '/superadmin/disciplines',
    });

  superadmin.list_product_categories &&
    items.push({
      title: __('modules.submodules.title.product-categories'),
      link: '/superadmin/product_categories',
    });

  superadmin.list_product_groups &&
    items.push({
      title: __('modules.submodules.title.product-groups'),
      link: '/superadmin/product_groups',
    });

  superadmin.list_product_subgroups &&
    items.push({
      title: __('modules.submodules.title.product-subgroups'),
      link: '/superadmin/product_subgroups',
    });

  superadmin.list_specification_groups &&
    items.push({
      title: __('modules.submodules.title.specification-groups'),
      link: '/superadmin/specification_groups',
    });

  superadmin.list_specification_subgroups &&
    items.push({
      title: __('modules.submodules.title.specification-subgroups'),
      link: '/superadmin/specification_subgroups',
    });

  superadmin.list_boq_template_groups &&
    items.push({
      title: __('modules.submodules.title.boq-template-groups'),
      link: '/superadmin/boq_template_groups',
    });

  superadmin.list_boq_template_subgroups &&
    items.push({
      title: __('modules.submodules.title.boq-template-subgroups'),
      link: '/superadmin/boq_template_subgroups',
    });

  superadmin.list_boq_template_calculations &&
    items.push({
      title: __('modules.submodules.title.boq-template-calculations'),
      link: '/superadmin/boq_template_calculations',
    });

  superadmin.list_boq_supply_types &&
    items.push({
      title: __('modules.submodules.title.boq-supply-types'),
      link: '/superadmin/boq_supply_types',
    });

  superadmin.list_boq_supply_type_groups &&
    items.push({
      title: __('modules.submodules.title.boq-supply-type-groups'),
      link: '/superadmin/boq_supply_type_groups',
    });

  superadmin.list_filters &&
    items.push({
      title: __('modules.submodules.title.filters'),
      link: '/superadmin/filters',
    });

  return createSidebar({
    title: __('modules.title.superadmin'),
    items: _.map(items, (item) => ({
      ...item,
      active: item.link == pathname,
      link: () => item.link,
    })),
  });
};
