import store from '../../../core/store';

export const listAllNotifications = (filters) => {
  const notification_table_data = store.getState().table.notifications.data;

  return store.dispatch({
    action: 'table.notifications/LIST_ALL_NOTIFICATIONS',
    method: 'GET',
    endpoint: '/notifications',
    filter_request: notification_table_data,
    main: true,
  });
};

export const readNotification = (notification_id) => {
  return store.dispatch({
    action: 'table.notifications/READ_NOTIFICATION',
    method: 'GET',
    endpoint: '/notifications/' + notification_id,
  });
};

export const deleteNotifications = (notification_ids) => {
  return store.dispatch({
    action: 'table.notifications/DELETE_NOTIFICATIONS',
    method: 'DELETE',
    endpoint: '/notifications',
    data: {
      notifications: notification_ids,
    },
  });
};

export const markNotificationsAsUnread = (notification_ids) => {
  return store.dispatch({
    action: 'table.notifications/MARK_NOTIFICATIONS_AS_UNREAD',
    method: 'PUT',
    endpoint: '/notifications/unread',
    data: {
      notifications: notification_ids,
    },
  });
};

export const markAllNotificationsAsUnread = () => {
  return store.dispatch({
    action: 'table.notifications/MARK_ALL_NOTIFICATIONS_AS_UNREAD',
    method: 'PUT',
    endpoint: '/notifications/unread_all',
  });
};

export const markNotificationsAsRead = (notification_ids) => {
  return store.dispatch({
    action: 'table.notifications/MARK_NOTIFICATIONS_AS_READ',
    method: 'PUT',
    endpoint: '/notifications/read',
    data: {
      notifications: notification_ids,
    },
  });
};

export const markAllNotificationsAsRead = () => {
  return store.dispatch({
    action: 'table.notifications/MARK_ALL_NOTIFICATIONS_AS_READ',
    method: 'PUT',
    endpoint: '/notifications/read_all',
  });
};

export const readNotificationState = (notification_id, read = true) => {
  return store.dispatch({
    type: 'table.notifications/READ_NOTIFICATION_STATE',
    notification_id,
    read,
  });
};
