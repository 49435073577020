import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/ResetPassword.scss";
import AuthLayout from "../../layouts/AuthLayout";
import {__, getURLParam, hasURLParam, redirect, validateForm} from "../../core/utils";
import ResetPasswordForm from "../../forms/auth/ResetPasswordForm";
import {resetPassword} from "../../redux/actions/auth";
import autobind from "autobind-decorator";
import {setNotification} from "../../redux/actions/general/notification";

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		if(!hasURLParam('token')) {
			redirect('/', true);
		}
	}

	@autobind
	_handleSubmit(formData) {
		return validateForm(
			resetPassword(getURLParam('token'), formData).then(() => {
				redirect("/login#1");
			})
		).catch(({ errors }) => {
			if (_.has(errors, "code")) {
				if (errors.code === 1) {
					setNotification(__("A password reset token has expired. Please retry the password reset process."));
				}
			}
		});
	}

	render() {
		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
					<div className={styles.title}>
                        {__('auth.reset-your-password')}
					</div>
				</div>
				<div className={styles.details}>
					<ResetPasswordForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default ResetPassword;