const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.preloaders/ADD_PRELOADER":
            return [
                ...state,
                action.id
            ];
        case "general.preloaders/REMOVE_PRELOADER":
            return [
                ..._.reject(state, (id) => id == action.id)
            ];
        default:
            return state;
    }
}
