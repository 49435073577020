import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/ChangeProductVariantAttributesWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import EditSpecificationCodeIcon from "../assets/images/edit-specification-code-64x64.svg";
import {withRouter} from "react-router";
import ChangeProductVariantAttributesForm
    from "../forms/change_product_variant_attributes_wizard/ChangeProductVariantAttributesForm";
import {changeProductVariantAttributes, readProduct} from "../redux/actions/table/products";
import {setNotification} from "../redux/actions/general/notification";
import * as _ from "lodash";

@mapStateToProps(state => ({
    store: _.get(state.table, 'products')
}))
@withRouter
class ChangeProductVariantAttributesWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {flyout} = this.props.store;

        return validateForm(
            changeProductVariantAttributes(flyout.id, formData).then(() => readProduct(flyout.id).then(({ response }) => {
                setTableParams('products', {
                    flyout: response.data
                });

                setNotification(__("products.flyout.variants.wizard.notification.successfully-saved"));

                this.props.onClose();
            }))
        )
    }

    render() {
        const {flyout} = this.props.store;

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('products.flyout.variants.change-product-variant-attributes')}</span>
                        <span>{__('products.flyout.variants.change-product-variant-attributes')}</span>
                    </div>
                    <div className={styles.right}>
                        <EditSpecificationCodeIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <ChangeProductVariantAttributesForm onSubmit={this._handleSubmit} initialValues={{
                        attribute_field_ids: flyout.variant_attributes
                    }} />
                </div>
            </>
        );
    }
}

export default ChangeProductVariantAttributesWizard;
