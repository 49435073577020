import store from "../../../../core/store";

export const listRawColors = () => {
	return store.dispatch({
		action: "table.superadmin_raw_colors/LIST_RAW_COLORS",
		method: "GET",
		endpoint: "/superadmin/raw_colors",
		main: true
	});
};

export const createRawColor = (formData) => {
	return store.dispatch({
		action: "table.superadmin_raw_colors/CREATE_RAW_COLOR",
		method: "POST",
		endpoint: "/superadmin/raw_colors",
		data: formData
	});
};

export const deleteRawColors = (raw_color_ids) => {
	return store.dispatch({
		action: "table.superadmin_raw_colors/DELETE_RAW_COLORS",
		method: "DELETE",
		endpoint: "/superadmin/raw_colors",
		data: {
			raw_colors: raw_color_ids
		}
	});
};

export const readRawColor = (raw_color_id) => {
	return store.dispatch({
		action: "table.superadmin_raw_colors/READ_RAW_COLOR",
		method: "GET",
		endpoint: "/superadmin/raw_colors/" + raw_color_id,
		has404: true
	});
};

export const updateRawColor = (raw_color_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_raw_colors/UPDATE_RAW_COLOR",
		method: "PUT",
		endpoint: "/superadmin/raw_colors/" + raw_color_id,
		data: formData
	});
};