import store from "../../../core/store";

export const listBrands = () => {
    return store.dispatch({
        action: "table.brands/LIST_BRANDS",
        method: "GET",
        endpoint: "/company/brands",
        main: true,
    });
};

export const createBrand = (formData) => {
    return store.dispatch({
        action: "table.brands/CREATE_BRAND",
        method: "POST",
        endpoint: "/company/brands",
        data: formData
    });
};

export const readBrand = (brand_id) => {
    return store.dispatch({
        action: "table.brands/READ_BRAND",
        method: "GET",
        endpoint: "/company/brands/" + brand_id,
        has404: true
    });
};

export const updateBrand = (brand_id, formData) => {
    return store.dispatch({
        action: "table.brands/UPDATE_BRAND",
        method: "POST",
        endpoint: "/company/brands/" + brand_id,
        data: formData
    });
};