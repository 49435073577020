import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/PartnerMemberApproval.scss";
import {__, clearURLFragment, redirect} from "../../core/utils";
import classNames from "classnames";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import AuthLayout from "../../layouts/AuthLayout";

class PartnerMemberApproval extends Component {
	componentDidMount() {
		if(this.props.location.hash == "#success") {
			clearURLFragment();
		} else {
			redirect('/', true);
		}
	}

	render() {
		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
                    <div className={styles.title}>
                        {__('Thank You!')}
                    </div>
				</div>
				<div className={styles.details}>
					<div className={styles.content}>
						<p className={classNames(styles.strong, styles.blue)}>{__("You have successfully approved a partner member request.")}</p>
					</div>
					<div className={styles.footer}>
						<Button onClick={() => redirect("/")} center leftText={__('Go to All Projects')} rightIcon={ArrowRightMiddleIcon} />
					</div>
				</div>
			</>
		);
	}
}

export default PartnerMemberApproval;