import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/storeys/StoreysFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import {__, setTableParams} from "../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import {setNotification} from "../../redux/actions/general/notification";
import withRouter from "react-router/es/withRouter";
import Tooltip from "../../components/Tooltip";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import classNames from "classnames";
import Properties from "./Properties";
import {deleteStoreys, listStoreys} from "../../redux/actions/table/storeys";

@subscribe(Localization, "localization")
@withRouter
class StoreysFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    render() {
        const {data, readAction} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>
                            {_.get(data.name, _.findKey(_.get(data, 'project_languages'), primary => primary == 1))}
                        </span>
                        <CloseBigIcon onClick={() => setTimeout(() => {
                            setTableParams('storeys', {
                            clicked_row: undefined
                        })}, 100)}/>
                    </div>
                    <div className={styles.actions}>
                        <div className={styles.actionWrapper}>
                            <Tooltip text={__('storeys.tooltip.delete-storey')} key={_.uniqueId()}>
                                <DeleteIcon
                                    onClick={e => {
                                        e.stopPropagation();

                                        showAlertbox({
                                            color: "red",
                                            title: __('general.alert.are-you-sure'),
                                            description: __('storeys.alert.delete-confirmation'),
                                            buttons: [
                                                {
                                                    color: 'lightGray',
                                                    text: __('general.alert.no-close'),
                                                    onClick: 'close'
                                                },
                                                {
                                                    color: 'gray',
                                                    text: __('general.alert.yes-delete'),
                                                    onClick: () => {
                                                        deleteStoreys([data.id]).then(() => {
                                                            hideAlertbox();

                                                            setNotification(__('storeys.alert.deleted'));
                                                            listStoreys(this.props.match.params.stage);

                                                            setTableParams('storeys', {
                                                                clicked_row: undefined
                                                            });
                                                        });
                                                    },
                                                }
                                            ]
                                        })
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>{__('storeys.flyout.properties')}</span>
                </div>
                {!_.isEmpty(data) && this.state.active == 'properties' && <Properties data={data} readAction={readAction} />}
            </div>
        );
    }
}

export default StoreysFlyout;
