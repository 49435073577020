import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddTeamMembersWizard.scss";
import autobind from "autobind-decorator";
import {__, setFormErrors, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import {addTeamMembers, listAllTeams} from "../redux/actions/table/teams";
import UserNewIcon from "../assets/images/user-new-60x60.svg";
import AddTeamMembersForm from "../forms/add_team_members_wizard/AddTeamMembersForm";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        table: state.table["teams"],
		auth: state.auth
    };
};

@withRouter
@connect(mapStateToProps, null)
class AddTeamMembersWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const team_id = formData.team_id;

		const member_invites = _.map(_.filter(formData.invites, invite => _.isUndefined(invite.invite)), invite => ({
			id: invite.id,
			role: invite.role
		}));

		const email_invites = _.map(_.filter(formData.invites, invite => invite.invite === true), invite => ({
			email: invite.name,
			role: invite.role
		}));

		formData = {
			team_id,
			member_invites,
			email_invites,
		};

		const validation = validateForm(
            addTeamMembers(formData).then(() => {
                listAllTeams(this.props.match.params.stage);

				setNotification(__("Team invites have been successfully sent."));

				this.props.onClose();
			})
		);

		validation.catch(({errors}) => {
			if(_.has(errors, 'email_invites')) {
				const email_errors = _.map(errors.email_invites, (row) => {
					return row.email;
				});

				setFormErrors('add_team_members_wizard.add_team', {invites: email_errors.join('\n')});
			}
		});

		return validation;
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.add-team-member.add-new')}</span>
						<span>{__('wizard.add-team-member.add-new-subtitle')}</span>
					</div>
					<div className={styles.right}>
                        <UserNewIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddTeamMembersForm onSubmit={this._handleSubmit} initialValues={{
						team_id: this.props.table.team_id
					}} />
				</div>
			</>
		);
	}
}

export default AddTeamMembersWizard;
