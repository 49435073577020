import store from "../../../core/store";

export const listRooms = (stage_id) => {
    return store.dispatch({
        action: "table.rooms/LIST_ROOMS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/rooms",
        main: true,
    });
};

export const createRoom = (stage_id, formData) => {
    return store.dispatch({
        action: "table.rooms/CREATE_ROOM",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/rooms",
        data: formData
    });
};

export const readRoom = (room_id) => {
    return store.dispatch({
        action: "table.rooms/READ_ROOM",
        method: "GET",
        endpoint: "/rooms/" + room_id,
        has404: true
    });
};

export const updateRoom = (room_id, formData) => {
    return store.dispatch({
        action: "table.rooms/UPDATE_ROOM",
        method: "PUT",
        endpoint: "/rooms/" + room_id,
        data: formData
    });
};

export const updateRooms = (rooms, fields, reset) => {
    return store.dispatch({
        action: "table.rooms/UPDATE_ROOMS",
        method: "PUT",
        endpoint: "/rooms",
        data: {
            ...fields,
            reset,
            rooms
        }
    });
};

export const deleteRooms = (room_ids) => {
    return store.dispatch({
        action: "table.rooms/DELETE_ROOMS",
        method: "DELETE",
        endpoint: "/rooms",
        data: {
            rooms: room_ids
        }
    });
};

export const listPossibleStoreys = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_STOREYS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_storeys",
    });
};

export const listPossibleExpansionJoints = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_EXPANSION_JOINTS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_expansion_joints",
    });
};

export const listPossibleEntrances = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_ENTRANCES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_entrances",
    });
};

export const listPossibleZones = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_ZONES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_zones",
    });
};

export const listPossibleRoomGroups = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_ROOM_GROUPS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_room_groups",
    });
};

export const listPossibleRoomTypes = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_ROOM_TYPES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_room_types",
    });
};

export const duplicateRoom = (room_id, copies) => {
    return store.dispatch({
        action: "table.storeys/DUPLICATE_ROOM",
        method: "POST",
        endpoint: "/rooms/" + room_id + "/duplicate",
        data: {
            copies
        }
    });
};