import * as _ from 'lodash';

const initialState = {};

const _transformData = (data) => {
  const processDeep = (rows, parentId = undefined, level = 0, code = '') => {
    return _.map(rows, (row, i) => {
      const id = parentId ? [parentId, row.id].join('-') : _.toString(row.id);
      const new_code = code.length == 0 ? i + 1 : `${code}.${i + 1}`;

      return {
        ...row,
        code: level < 4 ? new_code : undefined,
        level,
        id,
        parentId,
        ownId: row.id,
        children: row.children
          ? processDeep(
              _.sortBy(row.children, 'order'),
              id,
              level + 1,
              new_code
            )
          : undefined,
      };
    });
  };

  const transformed_data = processDeep(_.sortBy(data.groups, 'order'));

  let last_index = data.groups.length;

  !_.isEmpty(data.unallocated) &&
    transformed_data.push({
      id: 'UN',
      level: 0,
      order: last_index + 1,
    });

  _.each(data.unallocated, (calculation) =>
    transformed_data.push({
      ...calculation,
      id: ['UN', calculation.id].join('-'),
      level: 3,
      order: calculation.order + 1,
    })
  );

  return transformed_data;
};

const _filterData = (data, filter_params, language_id) => {
  const unallocatedData = _.filter(data, (row) => _.includes(row.id, 'UN'));

  data = _.cloneDeep(_.filter(data, (row) => !_.includes(row.id, 'UN')));

  if (_.get(filter_params, 'query')) {
    const query = _.lowerCase(_.get(filter_params, 'query'));

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (item) => {
                item.children = _.map(
                  _.filter(item.children, (calculation) => {
                    calculation.children = _.filter(
                      calculation.children,
                      (position) => {
                        return (
                          _.includes(
                            _.lowerCase(_.get(position.name, language_id)),
                            query
                          ) ||
                          _.includes(
                            _.lowerCase(
                              _.get(position.description, language_id)
                            ),
                            query
                          )
                        );
                      }
                    );
                    return (
                      !_.isEmpty(calculation.children) ||
                      _.includes(
                        _.lowerCase(_.get(calculation.name, language_id)),
                        query
                      ) ||
                      _.includes(
                        _.lowerCase(
                          _.get(calculation.description, language_id)
                        ),
                        query
                      )
                    );
                  }),
                  (calculation) => {
                    return {
                      ...calculation,
                      subtotal: _.sumBy(calculation.children, 'subtotal'),
                    };
                  }
                );
                return (
                  !_.isEmpty(item.children) ||
                  _.includes(
                    _.lowerCase(_.get(item.name, language_id)),
                    query
                  ) ||
                  _.includes(
                    _.lowerCase(_.get(item.description, language_id)),
                    query
                  )
                );
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return (
              !_.isEmpty(subgroup.children) ||
              _.includes(
                _.lowerCase(_.get(subgroup.name, language_id)),
                query
              ) ||
              _.includes(
                _.lowerCase(_.get(subgroup.description, language_id)),
                query
              )
            );
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return (
          !_.isEmpty(group.children) ||
          _.includes(_.lowerCase(_.get(group.name, language_id)), query) ||
          _.includes(_.lowerCase(_.get(group.description, language_id)), query)
        );
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.boq_group')) {
    const filterIds = _.split(_.get(filter_params, 'filter.boq_group'), '|');

    data = _.filter(data, (obj) => _.includes(filterIds, obj.id));
  }

  if (_.get(filter_params, 'filter.boq_subgroup')) {
    const filterIds = _.split(_.get(filter_params, 'filter.boq_subgroup'), '|');

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.filter(group.children, (subgroup) =>
          _.includes(filterIds, String(subgroup.ownId))
        );

        return !_.isEmpty(group.children);
      }),
      (group) => {
        return {
          ...group,
          subtotal: _.sumBy(group.children, 'subtotal'),
        };
      }
    );
  }

  if (_.get(filter_params, 'filter.specification_group')) {
    const filterIds = _.split(
      _.get(filter_params, 'filter.specification_group'),
      '|'
    );
    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(
                    filterIds,
                    _.toString(calculation.specification_group)
                  )
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.unit')) {
    const filterIds = _.split(_.get(filter_params, 'filter.unit'), '|');

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(filterIds, _.toString(calculation.unit_id))
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.price')) {
    const filterPrice = _.get(filter_params, 'filter.price');

    const [start, end] = filterPrice.split('|');

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const isStartValid =
                      !start ||
                      start.length === 0 ||
                      calculation.price >= parseFloat(start);
                    const isEndValid =
                      !end ||
                      end.length === 0 ||
                      calculation.price <= parseFloat(end);

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  if (_.get(filter_params, 'filter.budget_type')) {
    const filterIds = _.split(_.get(filter_params, 'filter.budget_type'), '|');

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(filterIds, _.toString(calculation.budget_type))
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.installation_type')) {
    const filterIds = _.split(
      _.get(filter_params, 'filter.installation_type'),
      '|'
    );

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(
                    filterIds,
                    _.toString(calculation.installation_type)
                  )
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.supply_type_group')) {
    const filterIds = _.split(
      _.get(filter_params, 'filter.supply_type_group'),
      '|'
    );

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(
                    filterIds,
                    _.toString(calculation.boq_supply_type_group_id)
                  )
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.quantity')) {
    const filterPrice = _.get(filter_params, 'filter.quantity');

    const [start, end] = filterPrice.split('|');

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const isStartValid =
                      !start ||
                      start.length === 0 ||
                      calculation.quantity >= parseFloat(start);
                    const isEndValid =
                      !end ||
                      end.length === 0 ||
                      calculation.quantity <= parseFloat(end);

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  if (_.get(filter_params, 'filter.total')) {
    const filterPrice = _.get(filter_params, 'filter.total');

    const [start, end] = filterPrice.split('|');

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const isStartValid =
                      !start ||
                      start.length === 0 ||
                      calculation.subtotal >= parseFloat(start);
                    const isEndValid =
                      !end ||
                      end.length === 0 ||
                      calculation.subtotal <= parseFloat(end);

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  if (_.get(filter_params, 'filter.total_percentage')) {
    const filterPrice = _.get(filter_params, 'filter.total_percentage');

    const [start, end] = filterPrice.split('|');

    const total = _.sumBy(data, (group) => group.subtotal);

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const isStartValid =
                      !start ||
                      start.length === 0 ||
                      _.round((calculation.subtotal / total) * 100, 2) >=
                        parseFloat(start);
                    const isEndValid =
                      !end ||
                      end.length === 0 ||
                      _.round((calculation.subtotal / total) * 100, 2) <=
                        parseFloat(end);

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  if (
    _.get(filter_params, 'filter.building') ||
    _.get(filter_params, 'filter.room') ||
    _.get(filter_params, 'filter.room_type') ||
    _.get(filter_params, 'filter.storey') ||
    _.get(filter_params, 'filter.site')
  ) {
    const filterBuildingIds = _.split(
      _.get(filter_params, 'filter.building'),
      '|'
    );
    const filterRoomIds = _.split(_.get(filter_params, 'filter.room'), '|');
    const filterRoomTypeIds = _.split(
      _.get(filter_params, 'filter.room_type'),
      '|'
    );
    const filterStoreyIds = _.split(_.get(filter_params, 'filter.storey'), '|');
    const filterSiteIds = _.split(_.get(filter_params, 'filter.site'), '|');

    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.map(
                  _.filter(items.children, (calculations) => {
                    calculations.children = _.filter(
                      calculations.children,
                      (position) => {
                        if (
                          _.get(filter_params, 'filter.building') &&
                          position.area_type === 'building'
                        ) {
                          return _.includes(
                            filterBuildingIds,
                            String(position.area_id)
                          );
                        }
                        if (
                          _.get(filter_params, 'filter.site') &&
                          position.area_type === 'site'
                        ) {
                          return _.includes(
                            filterSiteIds,
                            String(position.area_id)
                          );
                        }
                        if (
                          _.get(filter_params, 'filter.storey') &&
                          position.area_type === 'storey'
                        ) {
                          return _.includes(
                            filterStoreyIds,
                            String(position.area_id)
                          );
                        }
                        if (
                          _.get(filter_params, 'filter.room_type') &&
                          position.area_type === 'room_type'
                        ) {
                          return _.includes(
                            filterRoomTypeIds,
                            String(position.area_id)
                          );
                        }
                        if (
                          _.get(filter_params, 'filter.room') &&
                          position.area_type === 'room'
                        ) {
                          return _.includes(
                            filterRoomIds,
                            String(position.area_id)
                          );
                        }
                      }
                    );
                    return !_.isEmpty(calculations.children);
                  }),
                  (calculation) => {
                    return {
                      ...calculation,
                      subtotal: _.sumBy(calculation.children, 'subtotal'),
                    };
                  }
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.tag')) {
    const filterIds = _.split(_.get(filter_params, 'filter.tag'), '|');
    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(filterIds, _.toString(calculation.tags))
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.team')) {
    const filterIds = _.split(_.get(filter_params, 'filter.team'), '|');
    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(filterIds, _.toString(calculation.team_id))
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.responsible_user')) {
    const filterIds = _.split(
      _.get(filter_params, 'filter.responsible_user'),
      '|'
    );
    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(
                    filterIds,
                    _.toString(calculation.responsible_user_id)
                  )
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.creator')) {
    const filterIds = _.split(_.get(filter_params, 'filter.creator'), '|');
    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(filterIds, _.toString(calculation.created_by))
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.updater')) {
    const filterIds = _.split(_.get(filter_params, 'filter.updater'), '|');
    data = _.map(
      _.filter(data, (group) => {
        group.children = _.map(
          _.filter(group.children, (subgroup) => {
            subgroup.children = _.map(
              _.filter(subgroup.children, (items) => {
                items.children = _.filter(items.children, (calculation) =>
                  _.includes(filterIds, _.toString(calculation.updated_by))
                );
                return !_.isEmpty(items.children);
              }),
              (item) => {
                return {
                  ...item,
                  subtotal: _.sumBy(item.children, 'subtotal'),
                };
              }
            );
            return !_.isEmpty(subgroup.children);
          }),
          (subgroup) => {
            return {
              ...subgroup,
              subtotal: _.sumBy(subgroup.children, 'subtotal'),
            };
          }
        );
        return !_.isEmpty(group.children);
      }),
      (group) => {
        return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
      }
    );
  }

  if (_.get(filter_params, 'filter.created_at')) {
    const filterPrice = _.get(filter_params, 'filter.created_at');

    const [start, end] = filterPrice.split('|').map((date) => new Date(date));

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const calculationDate = new Date(calculation.created_at);

                    const isStartValid = !start || calculationDate >= start;

                    const isEndValid = !end || calculationDate <= end;

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  if (_.get(filter_params, 'filter.created_at')) {
    const filterPrice = _.get(filter_params, 'filter.created_at');

    const [start, end] = filterPrice.split('|').map((date) => new Date(date));

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const calculationDate = new Date(calculation.created_at);

                    const isStartValid = !start || calculationDate >= start;

                    const isEndValid = !end || calculationDate <= end;

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  if (_.get(filter_params, 'filter.updated_at')) {
    const filterPrice = _.get(filter_params, 'filter.updated_at');

    const [start, end] = filterPrice.split('|').map((date) => new Date(date));

    if (start || end)
      data = _.map(
        _.filter(data, (group) => {
          group.children = _.map(
            _.filter(group.children, (subgroup) => {
              subgroup.children = _.map(
                _.filter(subgroup.children, (items) => {
                  items.children = _.filter(items.children, (calculation) => {
                    const calculationDate = new Date(calculation.updated_at);

                    const isStartValid = !start || calculationDate >= start;

                    const isEndValid = !end || calculationDate <= end;

                    return isStartValid && isEndValid;
                  });
                  return !_.isEmpty(items.children);
                }),
                (item) => {
                  return {
                    ...item,
                    subtotal: _.sumBy(item.children, 'subtotal'),
                  };
                }
              );
              return !_.isEmpty(subgroup.children);
            }),
            (subgroup) => {
              return {
                ...subgroup,
                subtotal: _.sumBy(subgroup.children, 'subtotal'),
              };
            }
          );
          return !_.isEmpty(group.children);
        }),
        (group) => {
          return { ...group, subtotal: _.sumBy(group.children, 'subtotal') };
        }
      );
  }

  return _.some(filter_params, (param, group) => {
    return param;
  })
    ? data
    : [...data, ...unallocatedData];
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.working_set_boq/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };

    case 'table.working_set_boq/READ_WORKING_SET_BOQ_SUCCESS':
      return {
        ...state,
        data: _filterData(
          _transformData(action.response.data),
          _.pickBy(state, (value, key) => {
            return _.startsWith(key, 'filter.') || _.includes(['query'], key);
          }),
          state?.language_id
        ),

        initialData: _transformData(action.response.data), // Populate unfilteredData
      };

    case 'table.working_set_boq/READ_WORKING_SET_BOQ_FILTERS_SUCCESS':
      return {
        ...state,
        filters: action.response.filters,
      };

    case 'table.working_set_boq/REORDER_BOQ_WORKING_SET_DATA':
      let data = [...state.data];

      const mapped_rows = _.invert(action.rows);

      if (action.level > 0) {
        const target = _.reduce(
          action.parent_id.split('-'),
          (target, id) => {
            target.push(
              _.findIndex(
                _.isEmpty(target) ? state.data : _.get(state.data, target),
                (row) => _.last(row.id.split('-')) == id
              )
            );
            target.push('children');

            return target;
          },
          []
        );

        const sorted_children = _.sortBy(
          _.map(_.get(state.data, target), (row) => ({
            ...row,
            order: _.toInteger(mapped_rows[_.last(row.id.split('-'))]),
          })),
          'order'
        );

        _.set(data, target, sorted_children);
      } else {
        data = _.sortBy(
          _.map(state.data, (row) => {
            let order;

            if (row.id == 'UN') {
              order = state.data.length;
            } else if (_.includes(row.id, 'UN')) {
              order = state.data.length + 1;
            } else {
              order = _.toInteger(mapped_rows[row.id]);
            }

            return {
              ...row,
              order,
            };
          }),
          'order'
        );
      }

      const regenerated_codes_data = _.map(data, (group, i1) => ({
        ...group,
        code: i1 + 1,
        children: _.map(group.children, (subgroup, i2) => ({
          ...subgroup,
          code: `${i1 + 1}.${i2 + 1}`,
          children: _.map(subgroup.children, (item, i3) => ({
            ...item,
            code: `${i1 + 1}.${i2 + 1}.${i3 + 1}`,
          })),
        })),
      }));

      return {
        ...state,
        data: regenerated_codes_data,
      };

    case 'table.working_set_boq/FILTER_WORKING_SET_BOQ':
      return {
        ...state,
        data: _filterData(
          state.initialData,
          _.pickBy(state, (value, key) => {
            return _.startsWith(key, 'filter.') || _.includes(['query'], key);
          }),
          state?.language_id
        ),
        initialData: state.initialData,
      };
    default:
      return state;
  }
}
