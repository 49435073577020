import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import styles from "../../styles/forms/add_product_attribute_group_as_template_wizard/AddProductAttributeGroupAsTemplateForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { __, getLocalized, mapStateToProps, setTableParams } from "../../core/utils";
import { withRouter } from "react-router";
import Checkbox from "../../components/Checkbox";
import * as _ from "lodash";

@withRouter
@reduxForm({
	form: "add_product_attribute_group_as_template_wizard.add_product_attribute_group_as_template",
	enableReinitialize: true
})
class AddProductAttributeGroupAsTemplateForm extends Component {
	selectAllHandler(status) {
		_.each(this.props.fields, (field) => this.props.change("fields." + field.id, status));
	}

	render() {
		return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				<div className={styles.form}>
					<Field name="name" component={Input} label={__("products.product-attribute-flyout.add-product-attribute-group.template-name")} maxLength={50} />
					{!_.isEmpty(this.props.fields) && <div className={styles.label}>{__("products.flyout.add-product-attribute-group.attributes-list")}</div>}
					{!_.isEmpty(this.props.fields) && (
						<div className={styles.selector}>
							<button type="button" onClick={() => this.selectAllHandler(0)}>
								{__("products.flyout.attribute-group.unselect-all")}
							</button>
							<button type="button" onClick={() => this.selectAllHandler(1)}>
								{__("products.flyout.attribute-group.select-all")}
							</button>
						</div>
					)}
					{_.map(this.props.fields, (field, i) => (
						<Field name={"fields." + field.id} key={i} className={styles.attribute} component={Checkbox} label={field.name} />
					))}
				</div>
				<div className={styles.footer}>
					<Button
						lightGray
						medium
						left
						middleText={__("button.cancel")}
						onClick={() =>
							setTableParams("products", {
								save_product_attribute_group_as_template_wizard: undefined
							})
						}
					/>
					<Button lightBlue medium right submit middleText={__("button.save")} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default AddProductAttributeGroupAsTemplateForm;
