import * as React from "react";
import {Component} from "react";
import styles from "../styles/containers/Wizard.scss";
import classNames from "classnames";
import autobind from "autobind-decorator";
import KeyboardEventHandler from "react-keyboard-event-handler";

class Wizard extends Component {
	componentWillMount() {
		document.getElementById("root").classList.add("blur");
	}

	componentWillUnmount() {
		document.getElementById("root").classList.remove("blur");
	}

	render() {
		return (
			<div className={styles.wrapper}>
				<div className={classNames(styles.box, this.props.medium && styles.medium, this.props.small && styles.small, this.props.extraSmall && styles.extraSmall)}>
                    {React.cloneElement(this.props.children, { onClose: this.props.onClose })}
				</div>
				{this.props.onClose && <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this.props.onClose}/>}
			</div>
		);
	}
}

export default Wizard;
