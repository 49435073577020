import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddBOQSupplyTypesWizard.scss";
import autobind from "autobind-decorator";
import AddBOQTemplateGroupIcon from "../../assets/images/add-boq-template-group-60x60.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createBoqSupplyType, listBoqSupplyTypes } from "../../redux/actions/table/superadmin/boq_supply_types";
import AddBOQSupplyTypesForm from "../../forms/superadmin/add_boq_supply_types_wizard/AddBOQSupplyTypesForm";
import * as _ from "lodash";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_boq_supply_types"],
}))
class AddBOQSupplyTypesWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createBoqSupplyType(formData).then(({ response }) => {
				listBoqSupplyTypes().then(() => {
					if (edit) {
						setTableParams('superadmin_boq_supply_types', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_boq_supply_types', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new BOQ template group.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add BOQ Supply type</span>
						<span>Add BOQ Supply type</span>
					</div>
					<div className={styles.right}>
						<AddBOQTemplateGroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddBOQSupplyTypesForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
						boq_supply_type_group: {}
					}} />
				</div>
			</>
		);
	}
}

export default AddBOQSupplyTypesWizard;