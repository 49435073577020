import * as React from "react";
import { Component } from "react";
import classNames from "classnames";
import { Field, FieldArray, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../styles/forms/add_product_wizard/ProductCategoriesForm.scss";
import Button from "../../components/Button";
import { __, mapStateToProps, setTableParams, transformToListPairs } from "../../core/utils";
import ButtonGroup from "../../components/ButtonGroup";
import CloseMediumIcon from "../../assets/images/close-middle-15x15.svg";
import Select from "../../components/Select";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import * as _ from "lodash";

@reduxForm({
    form: "add_product_wizard.categories",
})
@mapStateToProps(state => ({
    values: getFormValues("add_product_wizard.categories")(state)
}))
@subscribe(Localization, "localization")
class ProductCategoriesForm extends Component {
    render_rows = ({ fields }) => {
        const { product_groups, product_subgroups } = this.props.localization;
        const { values } = this.props;
        const selected_subgroups = _.map(values, 'rows.subgroup');

        const product_categories = _.sortBy(_.values(this.props.localization.product_categories), 'priority');

        return (
            <>
                {fields.map((field, i) => (
                    <div className={classNames(styles.flex, styles.row)} key={i}>
                        <div className={styles.index}>{i + 1}.</div>
                        <div className={styles.f1}>
                            <Field onChange={(e, value) => {
                                if (value != _.get(values, field + ".category")) {
                                    this.props.change(field + ".group", null);
                                    this.props.change(field + ".subgroup", null);
                                }
                            }} name={field + ".category"} component={Select} searchable label={__('products.column.primary-level')} noSort options={transformToListPairs(product_categories)} />
                        </div>
                        <div className={styles.f1}>
                            <Field onChange={(e, value) => {
                                if (value != _.get(values, field + ".group")) {
                                    this.props.change(field + ".subgroup", null);
                                }
                            }} name={field + ".group"} component={Select} searchable label={__('products.column.secondary-level')} options={transformToListPairs(_.filter(product_groups, group => {
                                return group.product_category_id == _.get(values, field + ".category")
                            }))} disabled={!_.get(values, field + ".category")} />
                        </div>
                        <div className={styles.f1}>
                            <Field name={field + ".subgroup"} component={Select} searchable label={__('products.column.tertiary-level')} options={transformToListPairs(_.filter(product_subgroups, subgroup => {
                                return subgroup.product_group_id == _.get(values, field + ".group") && (!_.includes(selected_subgroups, subgroup.id) || _.get(values, field + ".subgroup") == subgroup.id);
                            }))} disabled={!_.get(values, field + ".group")} />
                        </div>
                        <div className={styles.remove}>
                            <CloseMediumIcon className={classNames(_.size(fields) == 1 && styles.disabled)} onClick={() => _.size(fields) > 1 && fields.remove(i)} />
                        </div>
                    </div>
                ))}
                <div className={styles.addRow}>
                    <button type="button" onClick={() => fields.push({})}>{__('products.create-new-product-wizard.add-new-category')}</button>
                </div>
            </>
        )
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.form}>
                <span className={styles.desc}>{__('products.product-categories.choose-products-category-path.edit-attributes-later.fine-tune-before-publishing')}.</span>
                <FieldArray name="rows" component={this.render_rows} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('products', {
                        wizard_active: undefined
                    })} />
                    <ButtonGroup right>
                        <Button medium lightGray onClick={this.props.onBack} middleText={__('button.back')} />
                        <Button lightBlue medium submit right middleText={__('button.done')} spinning={this.props.submitting} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default ProductCategoriesForm;