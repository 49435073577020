import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/WorkingSetSpecificationsFlyout.scss';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  redirect,
  setTableParams,
  toBoolean,
  validateForm,
} from '../../core/utils';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import GlobeIcon from '../../assets/images/globe-16x16.svg';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Select from '../../components/Select';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import autobind from 'autobind-decorator';
import BasicDataForm from '../../forms/working_set_specifications/BasicDataForm';
import { setNotification } from '../../redux/actions/general/notification';
import {
  copySpecification,
  deleteWSSpecification,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  setMainSpecification,
  updateSpecificationBasicData,
  updateSpecificationQuantity,
  updateSpecificationPrice,
  updateSpecificationTags,
  updateSpecificationToolbar,
} from '../../redux/actions/table/working_set_specifications';
import TagsForm from '../../forms/working_set_specifications/TagsForm';
import Files from './Files';
import Images from './Images';
import Attributes from './Attributes';
import ReadOnlyAttributes from './read_only/Attributes';
import Notes from './Notes';
import { SubmissionError } from 'redux-form';
import DeliveryIcon from '../../assets/images/delivery-15x15.svg';
import OriginProductIcon from '../../assets/images/product-origin-16x16.svg';
import Revisions from './Revisions';
import Drawings from './Drawings';
import { withRouter } from 'react-router';
import Options from './Options';
import LinkedSpecifications from './LinkedSpecifications';
import MakeMainIcon from '../../assets/images/make-main-16x16.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import History from './History';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-24x24.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-24x24.svg';
import PrintIcon from '../../assets/images/print-16x16.svg';
import DuplicateIcon from '../../assets/images/duplicate-16x16.svg';
import Quantities from './Quantities';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';
import EditWorkingSetSpecificationToolbarForm from '../../forms/working_set_specifications/EditWorkingSetSpecificationToolbarForm';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  auth: state.auth,
  tab_opened: _.get(state.table, ['working_set_specifications', 'tab']),
}))
@withRouter
@subscribe(Localization, 'localization')
class WorkingSetSpecificationsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language_switcher: false,
      collapsed_tabs: ['properties'], //fix for scrollSpy not working on init
      highlighted_tab: null,
    };

    setTableParams('working_set_specifications', {
      language_id: _.findKey(
        props.store.flyout.languages,
        (primary) => primary == true
      ),
    });

    this.highlight_timeout = null;

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();

    this.setState({
      collapsed_tabs: [],
    });

    if (this.props.tab_opened) {
      setTimeout(() => {
        this.tab_refs[this.props.tab_opened] &&
          this.tab_refs[this.props.tab_opened].click();

        setTableParams('working_set_specifications', {
          tab: undefined,
        });
      }, 500);
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    let all_tabs = [
      'properties',
      'attributes',
      'quantity',
      'revisions',
      'linked-specifications',
      'drawings',
      'images',
      'clauses',
      'files',
      'tags',
      'history',
    ];

    if (_.size(this.props.store.flyout.options) > 1) {
      all_tabs.push('options');
    }

    if (_.size(this.state.collapsed_tabs) == _.size(all_tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: all_tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  @autobind
  _refetchData() {
    readWorkingSetSpecification(this.props.store.flyout.id).then(
      ({ response }) => {
        setTableParams('working_set_specifications', {
          flyout: response.data,
        });
      }
    );

    listWorkingSetSpecifications(this.props.match.params.stage);
  }

  @autobind
  _handleBasicDataSubmit(formData) {
    formData = {
      ...formData,
      description: Object.assign({}, formData.description),
      title: Object.assign({}, formData.title),
    };

    return validateForm(
      updateSpecificationBasicData(this.props.store.flyout.id, formData).then(
        () => {
          this._refetchData();

          setNotification(
            __(
              'working-set-specifications-flyout.specification-properties-updated'
            )
          );
        }
      )
    ).catch(({ errors }) => {
      const { language_id } = this.props.store;

      if (_.has(errors, 'description')) {
        throw new SubmissionError({
          description: {
            [language_id]: errors.description,
          },
        });
      }
    });
  }

  @autobind
  _handlePriceSubmit(formData) {
    formData = {
      ...formData,
      price_note: Object.assign({}, formData.price_note),
    };

    return validateForm(
      updateSpecificationPrice(this.props.store.flyout.id, formData).then(
        () => {
          setNotification(
            __(
              'working-set-specifications-flyout.specification-details-updated'
            )
          );
        }
      )
    );
  }

  @autobind
  _handleTagsSubmit(formData) {
    updateSpecificationTags(this.props.store.flyout.id, formData).then(() => {
      setNotification(
        __('working-set-specifications-flyout.specification-tags-saved')
      );
    });
  }

  @autobind
  _handleChangeStatus(status) {
    const { flyout } = this.props.store;

    updateSpecificationToolbar(flyout.id, { status: status }).then(() => {
      hideAlertbox();

      this._refetchData();
      setNotification(__('specifications.properties.saved'));
    });
  }

  render() {
    const { auth } = this.props;
    const { language_id, flyout } = this.props.store;
    const { readOnly } = this.props;
    const { language_switcher, collapsed_tabs, highlighted_tab } = this.state;

    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const languages = _.map(_.keys(flyout.languages), (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      code: _.get(this.props.localization.languages, [language_id, 'code']),
      value: language_id,
    }));

    const specification_subgroup =
      _.get(specification_subgroups, [flyout.specification_subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};

    const specification_code =
      getLocalized(specification_group.code, flyout.project_language, 1) +
      specification_subgroup.code +
      _.padStart(flyout.code, 2, '0');

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    const specification_title = _.filter(
      [_.get(flyout.description, language_id), flyout.model, flyout.brand],
      _.identity
    ).join(' / ');

    let total_tabs = 11;

    if (_.size(flyout.options) > 1) {
      total_tabs += 1;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              {flyout.product_id ? (
                <Tooltip
                  text={__(
                    'specifications.working-set-specifications.specification-created-from-product'
                  )}
                >
                  <SpecificationFromProductIcon className={styles.type} />
                </Tooltip>
              ) : (
                <Tooltip
                  text={__(
                    'specifications.working-set-specifications.specification'
                  )}
                >
                  <SpecificationByArchitectIcon className={styles.type} />
                </Tooltip>
              )}
              {!flyout.main ? '↳ ' : ''}
              <strong>
                {specification_code}
                {!_.isEmpty(specification_title) ? ':' : ''}
              </strong>
              <span>{specification_title}</span>
            </div>
            <Tooltip
              text={__(
                'specifications.working-set.flyout.close-specification-flyout'
              )}
            >
              <CloseBigIcon
                className={styles.close}
                onClick={() =>
                  setTimeout(() => {
                    setTableParams('working_set_specifications', {
                      clicked_row: undefined,
                    });
                  }, 100)
                }
              />
            </Tooltip>
          </div>
          <div className={styles.actions}>
            {!flyout.main && !readOnly && (
              <Tooltip
                text={__('specifications.working-set.delete-specification')}
                key={_.uniqueId()}
              >
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    showAlertbox({
                      color: 'red',
                      title: __('general.alert.are-you-sure'),
                      description: __(
                        'specifications.working-set.delete-specification.alert.are-you-sure'
                      ),
                      buttons: [
                        {
                          color: 'lightGray',
                          text: __('general.alert.no-close'),
                          onClick: 'close',
                        },
                        {
                          color: 'gray',
                          text: __('general.alert.yes-delete'),
                          onClick: () => {
                            deleteWSSpecification(flyout.id).then(() => {
                              hideAlertbox();

                              listWorkingSetSpecifications(
                                this.props.match.params.stage
                              );

                              setNotification(
                                __(
                                  'specifications.working-set.delete-specification.notification.spec-deleted'
                                )
                              );

                              setTableParams('working_set_specifications', {
                                clicked_row: undefined,
                              });
                            });
                          },
                        },
                      ],
                    });
                  }}
                />
              </Tooltip>
            )}
            <Tooltip
              text={__(
                'specifications.working-set-specifications.flyout.print-specification'
              )}
            >
              <PrintIcon
                onClick={(e) => {
                  e.stopPropagation();

                  setTableParams('working_set_specifications', {
                    print_specifications_wizard: 'print_selected',
                    specification_ids: [flyout.id],
                  });
                }}
              />
            </Tooltip>
            ,
            {!flyout.product_id && !readOnly && (
              <Tooltip
                text={__(
                  'specifications.working-set-specifications.flyout.copy-specification'
                )}
              >
                <DuplicateIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setTableParams('working_set_specifications', {
                      copy_wizard_active: flyout.id,
                      specification_code,
                    });
                  }}
                />
              </Tooltip>
            )}
            {flyout.product_id && !readOnly && (
              <Tooltip
                text={__(
                  'specifications.working-set-specifications.edit-specification-in-catalog'
                )}
              >
                <EditIcon
                  className={styles.iconDisabled}
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('working_set_specifications', {
                      edit_specification_in_product: flyout.attribute_groups,
                    });

                    redirect('/products?id=' + flyout.product_id);
                  }}
                />
              </Tooltip>
            )}
            {flyout.product_id && !readOnly && (
              <Tooltip
                text={__(
                  'working-set-specifications-flyout.view-product-in-catalog'
                )}
              >
                <OriginProductIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('working_set_specifications', {
                      edit_specification_in_product: [],
                    });

                    redirect('/products?id=' + flyout.product_id);
                  }}
                />
              </Tooltip>
            )}
            {flyout.can_deliver && flyout.main && !readOnly ? (
              <Tooltip
                text={__(
                  'working-set-specifications-flyout.deliver-specification'
                )}
              >
                <DeliveryIcon
                  key={_.uniqueId()}
                  onClick={(e) => {
                    e.stopPropagation();

                    flyout.ready_for_delivery
                      ? setTableParams('working_set_specifications', {
                          delivery_wizard_active: true,
                          selected_specifications: [
                            {
                              ..._.find(
                                this.props.store.data,
                                (specification) =>
                                  specification.id === flyout.id
                              ),
                              ready_for_delivery: flyout.ready_for_delivery,
                            },
                          ],
                        })
                      : setNotification({
                          text: __(
                            'specifications.working-set.flyout.deliver-specification.specification-not-ready-for-delivery'
                          ),
                          type: 'warning',
                        });
                  }}
                />
              </Tooltip>
            ) : (
              ''
            )}
            {!flyout.main && (
              <Tooltip
                text={__(
                  'working-set-specifications-flyout.make-primary-option'
                )}
              >
                <MakeMainIcon
                  className={styles.primaryIcon}
                  key={_.uniqueId()}
                  onClick={(e) => {
                    e.stopPropagation();

                    showAlertbox({
                      color: 'red',
                      title: __('general.alert.are-you-sure'),
                      description: __(
                        'working-set-specifications-flyout.are-you-sure-make-specification-main'
                      ),
                      buttons: [
                        {
                          color: 'lightGray',
                          text: __('general.alert.no'),
                          onClick: 'close',
                        },
                        {
                          color: 'gray',
                          text: __('general.alert.yes'),
                          onClick: () => {
                            setMainSpecification(flyout.id).then(() => {
                              hideAlertbox();

                              readWorkingSetSpecification(flyout.id).then(
                                ({ response }) => {
                                  setTableParams('working_set_specifications', {
                                    flyout: response.data,
                                  });
                                }
                              );

                              listWorkingSetSpecifications(
                                this.props.match.params.stage
                              );

                              setNotification(
                                __(
                                  'working-set-specifications-flyout.specification-set-main-option'
                                )
                              );
                            });
                          },
                        },
                      ],
                    });
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.toolbar}>
          <Tooltip
            placement='left'
            text={__(
              'working-set-specifications-flyout.specification-in-working-set'
            )}
          >
            <div className={classNames(styles.baloon, styles.set)}>
              {__('specifications.working-set.flyout.working-set')}
            </div>
          </Tooltip>
          {flyout.product_id && (
            <Tooltip
              placement='left'
              text={__(
                'working-set-specifications-flyout.specification-created-catalog product'
              )}
            >
              <div className={classNames(styles.baloon, styles.catalog)}>
                {__('specifications.working-set.flyout.source-catalog')}
              </div>
            </Tooltip>
          )}
          {!flyout.product_id &&
            auth.company.id == flyout.created_by.company_id && (
              <Tooltip
                placement='left'
                text={__(
                  'working-set-specifications-flyout.specification-created-your-company'
                )}
              >
                <div className={classNames(styles.baloon, styles.myCompany)}>
                  {__('specifications.working-set.flyout.source-my-company')}
                </div>
              </Tooltip>
            )}
          {!flyout.product_id &&
            auth.company.id != flyout.created_by.company_id && (
              <Tooltip
                placement='left'
                text={__(
                  'working-set-specifications-flyout.specification-created-your-partners-company'
                )}
              >
                <div
                  className={classNames(styles.baloon, styles.partnerCompany)}
                >
                  {__(
                    'specifications.working-set.flyout.source-partner-company'
                  )}
                </div>
              </Tooltip>
            )}
          {!!flyout.discontinued && (
            <Tooltip
              text={__(
                'working-set-specifications-flyout.product-discontinued'
              )}
            >
              <div className={styles.discontinued}>
                {__('specifications.working-set.flyout.discontinued')}
              </div>
            </Tooltip>
          )}

          {!flyout.main && (
            <Tooltip
              text={__(
                'working-set-specifications-flyout.alternative-specification'
              )}
            >
              <div className={classNames(styles.baloon, styles.set)}>
                {__('specifications.working-set.flyout.alternative')}
              </div>
            </Tooltip>
          )}

          <EditWorkingSetSpecificationToolbarForm
            changeStatus={this._handleChangeStatus}
            initialValues={{
              ..._.pick(flyout, ['status', 'id']),
            }}
            readOnly={readOnly}
          />

          {language_id && (
            <div className={styles.language}>
              <Select
                input={{
                  value: language_id,
                  onChange: (language_id) =>
                    setTableParams('working_set_specifications', {
                      language_id,
                    }),
                  onBlur: () => this.setState({ language_switcher: false }),
                }}
                meta={{
                  active: language_switcher,
                }}
                label={__(
                  'working-set-specifications-flyout.specification-language'
                )}
                options={languages}
                left={-75}
                top={40}
                noSort
                dropdown
                icon={<GlobeIcon className={styles.globeIcon} />}
                wrapper={(handleOpen, activeItem) => (
                  <Tooltip
                    text={__(
                      'working-set-specifications-flyout.change-specification-language'
                    )}
                  >
                    <div
                      className={styles.languageSwitcher}
                      onClick={() => this.setState({ language_switcher: true })}
                    >
                      <GlobeIcon className={styles.icon} />
                      {_.get(activeItem, 'code')}
                      <ArrowDownSmallIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                )}
              />
            </div>
          )}
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == total_tabs && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>
              {__('working-set-specifications.flyout.tabs.properties')}
            </Tab>
            <Tab target='revisions'>
              {__('working-set-specifications.flyout.tabs.revisions')}
            </Tab>
            <Tab target='attributes'>
              {__('working-set-specifications.flyout.tabs.attributes')}
            </Tab>
            <Tab target='clauses'>
              {__('working-set-specifications.flyout.tabs.instructions')}
            </Tab>
            <Tab target='quantity'>
              {__('working-set-specifications.flyout.tabs.quantity')}
            </Tab>
            {_.size(flyout.options) > 1 && (
              <Tab target='options'>
                {__('working-set-specifications.flyout.tabs.options')}
              </Tab>
            )}
            <Tab target='linked-specifications'>
              {__(
                'working-set-specifications.flyout.tabs.linked-specifications'
              )}{' '}
              {!_.isEmpty(flyout.linked) && ' (' + _.size(flyout.linked) + ')'}
            </Tab>
            <Tab target='drawings'>
              {__('working-set-specifications.flyout.tabs.drawings')}{' '}
              {!_.isEmpty(flyout.drawings) &&
                ' (' + _.size(flyout.drawings) + ')'}
            </Tab>
            {/* <Tab target='images'>
              {__('working-set-specifications.flyout.tabs.images')}{' '}
              {!_.isEmpty(flyout.images) && ' (' + _.size(flyout.images) + ')'}
            </Tab> */}
            <Tab target='files'>
              {__('working-set-specifications.flyout.tabs.files')}
              {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) &&
                ' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
            </Tab>
            <Tab target='tags'>
              {__('working-set-specifications.flyout.tabs.tags')}
            </Tab>
            <Tab target='history'>
              {__('working-set-specifications.flyout.tabs.history')}
            </Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.properties')}
            </div>
            <BasicDataForm
              readOnly={readOnly}
              collapsed={_.includes(collapsed_tabs, 'properties')}
              onSubmit={this._handleBasicDataSubmit}
              initialValues={{
                ..._.pick(flyout, [
                  'model',
                  'description',
                  'supplier_company_id',
                  'supplier_contact_id',
                  'responsible_user_id',
                  'team_id',
                ]),
                brand: flyout.product_id ? flyout.brand : undefined,
                created_by: [flyout.created_by],
                updated_by: [flyout.updated_by],
                manufacturer_company_id: !flyout.product_id
                  ? flyout.manufacturer_company_id
                  : undefined,
                code:
                  getLocalized(
                    _.get(specification_groups, [
                      _.get(specification_subgroups, [
                        flyout.specification_subgroup_id,
                        'specification_group_id',
                      ]),
                      'code',
                    ]),
                    flyout.project_language
                  ) +
                  _.get(specification_subgroups, [
                    flyout.specification_subgroup_id,
                    'code',
                  ]) +
                  _.padStart(flyout.code, 2, '0'),
              }}
            />
          </Element>
          <Element
            name='revisions'
            className={classNames(
              styles.section,
              highlighted_tab == 'revisions' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('revisions')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'revisions') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.revisions')}
            </div>
            <Revisions
              readOnly={readOnly}
              key={flyout.id}
              collapsed={_.includes(collapsed_tabs, 'revisions')}
            />
          </Element>
          <Element
            name='attributes'
            className={classNames(
              styles.section,
              highlighted_tab == 'attributes' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('attributes')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'attributes') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.attributes')}
            </div>
            {flyout.product_id ||
            _.includes(['canceled'], flyout.status) ||
            readOnly ? (
              <ReadOnlyAttributes
                collapsed={_.includes(collapsed_tabs, 'attributes')}
                initialValues={_.sortBy(flyout.attribute_groups, 'priority')}
              />
            ) : (
              <Attributes
                collapsed={_.includes(collapsed_tabs, 'attributes')}
              />
            )}
          </Element>
          <Element
            name='clauses'
            className={classNames(
              styles.section,
              highlighted_tab == 'clauses' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('clauses')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'clauses') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.instructions')}
            </div>
            <Notes
              collapsed={_.includes(collapsed_tabs, 'clauses')}
              readOnly={readOnly}
            />
          </Element>
          <Element
            name='quantity'
            className={classNames(
              styles.section,
              highlighted_tab == 'quantity' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('quantity')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'quantity') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.quantity')}
            </div>
            <Quantities
              readOnly={readOnly}
              collapsed={_.includes(collapsed_tabs, 'quantity')}
              readWorkingSetBOQ={this.props.readAction}
            />
          </Element>
          {_.size(flyout.options) > 1 && (
            <Element
              name='options'
              className={classNames(
                styles.section,
                highlighted_tab == 'options' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('options')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'options') && styles.collapsed
                  )}
                />
                {__('working-set-specifications.flyout.tabs.options')}
              </div>
              <Options
                collapsed={_.includes(collapsed_tabs, 'options')}
                readOnly={readOnly}
              />
            </Element>
          )}
          <Element
            name='linked-specifications'
            className={classNames(
              styles.section,
              highlighted_tab == 'linked-specifications' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('linked-specifications')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'linked-specifications') &&
                    styles.collapsed
                )}
              />
              {__(
                'working-set-specifications.flyout.tabs.linked-specifications'
              )}{' '}
              {!_.isEmpty(flyout.linked) && ' (' + _.size(flyout.linked) + ')'}
            </div>
            <LinkedSpecifications
              collapsed={_.includes(collapsed_tabs, 'linked-specifications')}
              readOnly={readOnly}
            />
          </Element>
          <Element
            name='drawings'
            className={classNames(
              styles.section,
              highlighted_tab == 'drawings' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('drawings')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'drawings') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.drawings')}
            </div>
            <Drawings
              collapsed={_.includes(collapsed_tabs, 'drawings')}
              readOnly={readOnly}
            />
          </Element>
          {/* <Element
            name='images'
            className={classNames(
              styles.section,
              highlighted_tab == 'images' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('images')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'images') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.images')}
            </div>
            <Images collapsed={_.includes(collapsed_tabs, 'images')} />
          </Element> */}
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.files-and-images')}
              {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) &&
                ' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
            </div>
            <Files
              collapsed={_.includes(collapsed_tabs, 'files')}
              readOnly={readOnly}
            />
          </Element>
          <Element
            name='tags'
            className={classNames(
              styles.section,
              highlighted_tab == 'tags' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('tags')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'tags') && styles.collapsed
                )}
              />
              {__('working-set-specifications.flyout.tabs.tags')}
            </div>
            <TagsForm
              collapsed={_.includes(collapsed_tabs, 'tags')}
              onSubmit={this._handleTagsSubmit}
              initialValues={_.pick(flyout, ['tags'])}
              readOnly={readOnly}
            />
          </Element>
          <Element
            name='history'
            className={classNames(
              styles.section,
              highlighted_tab == 'history' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('history')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'history') && styles.collapsed
                )}
              />
              {__('task.flyout.history')}
            </div>
            <History
              collapsed={_.includes(collapsed_tabs, 'history')}
              data={flyout}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default WorkingSetSpecificationsFlyout;
