import _ from 'lodash';
import actions from 'redux-form/lib/actions';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.working_set_specifications/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };
    case 'table.working_set_specifications/LIST_WORKING_SET_SPECIFICATIONS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        filters: action.response.filters,
        additional_params: action.response.additional_params,
        ...action.response.pagination,
      };

    case 'table.working_set_specifications/LIST_POSSIBLE_LINKED_SPECIFICATIONS_SUCCESS':
      return {
        ...state,
        selected_specifications: [
          ...state.selected_specifications,
          ..._.filter(action.response.data, (response) => {
            return !_.find(
              state.selected_specifications,
              (state) => state.id === response.id
            );
          }),
        ],
      };

    default:
      return state;
  }
}
