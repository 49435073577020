import store from "../../../core/store";

export const listAddressBookContacts = () => {
	return store.dispatch({
		action: "table.address_book_contacts/LIST_ADDRESS_BOOK_CONTACTS",
		method: "GET",
		endpoint: "/address_book_contacts",
		main: true
	});
};

export const createAddressBookContact = (formData) => {
	return store.dispatch({
		action: "table.address_book_contacts/CREATE_ADDRESS_BOOK_CONTACT",
		method: "POST",
		endpoint: "/address_book_contacts",
		data: formData
	});
};

export const readAddressBookContact = (address_book_contact_id) => {
	return store.dispatch({
		action: "table.address_book_contacts/READ_ADDRESS_BOOK_CONTACT",
		method: "GET",
		endpoint: "/address_book_contacts/" + address_book_contact_id,
		has404: true
	});
};

export const listPossibleAddressBookCompanies = () => {
	return store.dispatch({
		action: "table.address_book_contacts/LIST_POSSIBLE_ADDRESS_BOOK_COMPANIES",
		method: "GET",
		endpoint: "/address_book_contacts/possible_address_book_companies",
	});
};

export const updateAddressBookContact = (address_book_contact_id, formData) => {
	return store.dispatch({
		action: "table.address_book_contacts/UPDATE_ADDRESS_BOOK_CONTACT",
		method: "PUT",
		endpoint: "/address_book_contacts/" + address_book_contact_id,
		data: formData
	});
};