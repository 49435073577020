import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, dateFrom, dateTimeFrom, getURLParam, mapStateToProps, setTableParams } from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import TranslationsFlyout from "../../flyouts/superadmin/translations/TranslationsFlyout";
import {
	deleteTranslations,
	listTranslations,
	readTranslation
} from "../../redux/actions/table/superadmin/translations";
import TableNA from "../../components/TableNA";
import { listLocalization } from "../../redux/actions/localization";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_translations"],
	auth: state.auth,
}))
class Translations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_translation && setPlusActions([{
			title: __('button.add-translation'),
			onClick: () => setTableParams('superadmin_translations', {
				add_translation_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readTranslation(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin } = this.props;

		let columns = [
			{
				key: "keyword",
				name: __('superadmin.translations.topbar.title.keyword'),
				width: 400,
				required: true,
				default: true,
				value: keyword => (
					<Tooltip text={keyword}>{keyword}</Tooltip>
				)
			}, {
				key: "tag",
				name: __('superadmin.translations.topbar.title.tag'),
				width: 100,
				value: tag => (
					<Tooltip text={tag}>{tag || <TableNA />}</Tooltip>
				)
			}, {
				key: "updated_at",
				name: __('superadmin.translations.topbar.title.updated-at'),
				value: value => value ? dateTimeFrom(value) : <TableNA />,
				width: 110,
			}
		];

		_.each(_.filter(this.props.localization.languages, language => language.application_language == true), language => columns.push({
			key: "language" + language.id,
			name: language.name,
			value: (x, row) => {
				const translation = _.get(row.translations, language.id);

				return <Tooltip text={translation}>{translation || <TableNA />}</Tooltip>
			}
		}));

		const singleActions = (translation_id) => {
			return [
				<Tooltip text={__('superadmin.select-transations.delete-selected-translation')} key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: __('superadmin.select-transations.delete-selected-translation.notification.want-delete-selected-translation.process-cannot-undone'),
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteTranslations([translation_id]).then(() => {
												hideAlertbox();

												setNotification(__('superadmin.select-transations.delete-selected-translation.notification.selected-translation-been-deleted'));
												listTranslations();

												listLocalization();

												setTableParams('superadmin_translations', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (translations_ids) => [
			<Tooltip text={__('superadmin.select-transations.delete-selected-translations')} key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: __('superadmin.select-transations.delete-translations.want-delete-these-translations.process-cannot-undone'),
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteTranslations(translations_ids).then(() => {
											hideAlertbox();

											setNotification(__('superadmin.select-transations.delete-translations.notification.selected-translations-been-deleted'));
											listTranslations();

											listLocalization();

											setTableParams('superadmin_translations', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<TranslationsFlyout
				data={this.state.preview}
				readAction={translation_id => {
					return new Promise(resolve => {
						readTranslation(getURLParam('id') || translation_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listTranslations}
				name="superadmin_translations"
				groupActions={superadmin.delete_translation && groupActions}
				singleActions={superadmin.delete_translation && singleActions}
				title={__('modules.submodules.title.translations')}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default Translations;
