import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {
    __,
    dateFrom,
    dateTimeFrom,
    getActiveStage,
    getURLParam,
    mapStateToProps,
    redirect,
    setTableParams
} from "../../core/utils";
import Table from "../../components/Table";
import {setPlusActions} from "../../redux/actions/general/plus";
import {
    enableProjectsDropdown,
    enableStagesDropdown,
    listActiveModules
} from "../../redux/actions/general/active_modules";
import * as _ from "lodash";
import Tooltip from "../../components/Tooltip";
import {getTeamsPermissions} from "../../redux/actions/table/teams";
import {setNotification} from "../../redux/actions/general/notification";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import InfoPositiveIcon from "../../assets/images/info-positive-16x16.svg";
import {deleteZones, listZones, readZone} from "../../redux/actions/table/zones";
import ZonesFlyout from "../../flyouts/zones/ZonesFlyout";
import classNames from "classnames";
import styles from "../../styles/views/space_allocation/Zones.scss";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import TableNA from "../../components/TableNA";
import Avatar from "../../components/Avatar";

@mapStateToProps(state => ({
    table: state.table["zones"],
}))
class Zones extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preview: null,
        };
    }

    componentDidMount() {
        getTeamsPermissions(this.props.match.params.stage).then(({response}) => {
            this.setState({team_roles: _.get(response.data, 'teams', {})});

            !_.isEmpty(this.state.team_roles.editor) && setPlusActions([{
                title: __('space-allocation.zones.table.button.create'),
                onClick: () => setTableParams('zones', {
                    add_zone_wizard: true
                })
            }]);
        });

        enableProjectsDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/space_allocation/zones'));
        enableStagesDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/space_allocation/zones'));

        createSidebar({
            title: __('submenu.title.space-allocation'),
            items: [
                {
                    title: __("submenu.title.space-allocation.rooms"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/rooms",
                }, {
                    title: __("submenu.title.space-allocation.room-types"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/room_types",
                }, {
                    title: __("submenu.title.space-allocation.room-groups"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/room_groups",
                }, {
                    title: __("submenu.title.space-allocation.zones"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/zones",
                    active: true
                }, {
                    title: __("submenu.title.space-allocation.entrances"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/entrances",
                }, {
                    title: __("submenu.title.space-allocation.expansion-joints"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/expansion_joints",
                }, {
                    title: __("submenu.title.space-allocation.storeys"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/storeys",
                }, {
                    title: __("submenu.title.space-allocation.buildings"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/buildings",
                }, {
                    title: __("submenu.title.space-allocation.sites"),
                    link: () => "/stages/" + getActiveStage() + "/space_allocation/sites",
                }
            ]
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
            if (!prevProps.table.clicked_row) {
                this.setState({ preview: undefined });
            }

            readZone(this.props.table.clicked_row).then(({ response }) => {
                this.setState({ preview: response.data });
            });
        }
    }

    render() {
        const columns = [
            {
                key: "title",
                name: __("zones.field.title"),
                required: true,
                sortable: false
            }, {
                key: "functional_spaces",
                name: __("zones.field.functional-space"),
                width: 150,
                value: functional_spaces => !_.isEmpty(functional_spaces) ? (
                    <Tooltip text={_.join(functional_spaces, ', ')}>
                        {_.join(functional_spaces, ', ')}
                    </Tooltip>
                ) : <TableNA />,
                sortable: false
            }, {
                key: "volume",
                name: __("zones.field.volume"),
                width: 60,
                value: volume => _.toNumber(volume),
                required: true,
            }, {
                key: "area",
                name: __("zones.field.area"),
                width: 60,
                value: area => _.toNumber(area),
                required: true,
            }, {
                key: "rooms",
                name: __("zones.field.rooms"),
                value: (rooms, row) => (
                    <div className={classNames(styles.rooms)} onClick={() => {
                        setTableParams('rooms', _.transform(['filter.site', 'filter.building', 'filter.storey', 'filter.expansion_joint', 'filter.entrance', 'filter.zone', 'filter.functional_space', 'filter.foh', 'filter.outdoor', 'filter.room_group', 'filter.room_type', 'filter.security', 'filter.date', 'filter.creator', 'query'], (filters, filter) => {
                            filters[filter] = '';

                            return filters;
                        }, {}));

                        redirect('/stages/' + this.props.match.params.stage + '/space_allocation/rooms?filter.zone=' + row.id)
                    }}>
                        {rooms}
                        <ArrowDoubleRightSmallIcon />
                    </div>
                ),
                width: 60,
                sortable: false
            }, {
                key: "created_at",
                name: __("zones.field.created-at"),
                value: created_at => <Tooltip text={dateTimeFrom(created_at)}>{dateFrom(created_at)}</Tooltip>,
                width: 80
            }, {
                key: "created_by",
                name: __("zones.field.created-by"),
                value: user => (
                    <Avatar avatar_url={user.avatar_url} name={user.fullname} active={user.active} />
                ),
                width: 50
            }
        ];

        const singleActions = (zone_id) => {
            return [
                <Tooltip text={__('zones.tooltip.zone-properties')} key={_.uniqueId()}><InfoPositiveIcon
                    className=''
                    onClick={e => {
                        e.stopPropagation();

                        setTableParams('zones', {
                            clicked_row: zone_id,
                            actioned_row: undefined,
                        });
                    }}
                /></Tooltip>,
                <Tooltip text={__('zones.tooltip.delete-zone')} key={_.uniqueId()}>
                    <DeleteIcon
                        onClick={e => {
                            e.stopPropagation();

                            showAlertbox({
                                color: "red",
                                title: __('general.alert.are-you-sure'),
                                description: __('zones.alert.delete-confirmation'),
                                buttons: [
                                    {
                                        color: 'lightGray',
                                        text: __('general.alert.no-close'),
                                        onClick: 'close'
                                    },
                                    {
                                        color: 'gray',
                                        text: __('general.alert.yes-delete'),
                                        onClick: () => {
                                            deleteZones([zone_id]).then(() => {
                                                hideAlertbox();

                                                setNotification(__('zones.alert.deleted'));
                                                listZones(this.props.match.params.stage);

                                                setTableParams('zones', {
                                                    actioned_row: undefined
                                                });
                                            });
                                        },
                                    }
                                ]
                            })
                        }}
                    />
                </Tooltip>
            ];
        };

        const groupActions = (zones_ids) => [
            <Tooltip text={__('zones.tooltip.delete-selected-zones')} key={_.uniqueId()}>
                <DeleteIcon
                    onClick={e => {
                        e.stopPropagation();

                        showAlertbox({
                            color: "red",
                            title: __('general.alert.are-you-sure'),
                            description: __('zones.alert.multiple-delete-confirmation'),
                            buttons: [
                                {
                                    color: 'lightGray',
                                    text: __('general.alert.no-close'),
                                    onClick: 'close'
                                },
                                {
                                    color: 'gray',
                                    text: __('general.alert.yes-delete'),
                                    onClick: () => {
                                        deleteZones(zones_ids).then(() => {
                                            hideAlertbox();

                                            setNotification(__('zones.alert.multiple-deleted'));
                                            listZones(this.props.match.params.stage);

                                            setTableParams('zones', {
                                                selected_rows: []
                                            });
                                        });
                                    },
                                }
                            ]
                        })
                    }}
                />
            </Tooltip>
        ];

        const preview = (
            <ZonesFlyout
                data={this.state.preview}
                readAction={zone_id => {
                    return new Promise(resolve => {
                        readZone(getURLParam('id') || zone_id).then(({response}) => {
                            this.setState({preview: response.data});

                            resolve(response);
                        });
                    });
                }}
            />
        );

        return (
            <Table
                listAction={() => listZones(this.props.match.params.stage)}
                name="zones"
                title={__('submenu.title.space-allocation.zones')}
                singleActions={singleActions}
                groupActions={groupActions}
                columns={columns}
                preview={this.state.preview ? preview : []}
                onLoad={() => listActiveModules()}
            />
        );
    }
}

export default Zones;