import store from '../../../core/store';
import { backendURL } from '../../../core/utils';
import { downloadFile } from '../general/downloader';

export const listWorkingSetPlans = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/LIST_WORKING_SET_PLANS',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/plans/working',
    main: true,
  });
};

export const readPlanVersion = (plan_version_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/READ_PLAN_VERSION',
    method: 'GET',
    endpoint: '/plans/versions/' + plan_version_id,
    has404: true,
  });
};

export const readWorkingSetPlan = (plan_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/READ_WORKING_SET_PLAN',
    method: 'GET',
    endpoint: '/plans/working/' + plan_id,
    has404: true,
  });
};

export const updateWorkingSetPlan = (plan_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_plans/UPDATE_WORKING_SET_PLAN',
    method: 'PUT',
    endpoint: '/plans/working/' + plan_id,
    data: formData,
  });
};

export const deleteWorkingSetPlan = (plan_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/DELETE_WORKING_SET_PLAN',
    method: 'DELETE',
    endpoint: '/plans/working/' + plan_id,
  });
};

export const downloadPlanVersion = (plan_version_id) => {
  return downloadFile(
    backendURL + '/plans/versions/' + plan_version_id + '/download',
    [],
    'POST'
  );
};

export const uploadNewPlanVersion = (plan_id, file) => {
  return store.dispatch({
    action: 'table.working_set_plans/UPLOAD_NEW_PLAN_VERSION',
    method: 'POST',
    preloaded: false,
    endpoint: '/plans/' + plan_id + '/versions',
    progress: true,
    id: plan_id,
    data: {
      file,
    },
  });
};

export const removeNewPlanVersionUpload = (plan_id) => {
  return store.dispatch({
    type: 'table.working_set_plans/REMOVE_NEW_PLAN_VERSION_UPLOAD',
    plan_id,
  });
};

export const createDelivery = (team_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_plans/CREATE_DELIVERY',
    method: 'post',
    endpoint: '/teams/' + team_id + '/deliveries',
    data: formData,
  });
};

export const addToExistingDelivery = (delivery_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_plans/ADD_TO_EXISTING_DELIVERY',
    method: 'put',
    endpoint: '/deliveries/' + delivery_id + '/plans',
    data: formData,
  });
};

export const listExistingDeliveries = (team_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/LIST_EXISTING_DELIVERIES',
    method: 'GET',
    endpoint: '/teams/' + team_id + '/existing_deliveries',
  });
};

export const listDeliveryApprovers = (team_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/LIST_DELIVERY_APPROVERS',
    method: 'GET',
    endpoint: '/teams/' + team_id + '/delivery_approvers',
  });
};

export const uploadPlanFiles = (plan_id, files) => {
  return store.dispatch({
    action: 'table.working_set_plans/UPLOAD_PLAN_FILE',
    method: 'POST',
    preloaded: false,
    endpoint: '/plans/' + plan_id + '/files',
    data: {
      files,
    },
  });
};

export const deletePlanFile = (plan_id, attachment_id) => {
  return store.dispatch({
    action: 'table.working_set_plans/DELETE_PLAN_FILE',
    method: 'DELETE',
    endpoint: '/plans/' + plan_id + '/files',
    data: {
      attachment_id,
    },
  });
};
