import * as React from "react";
import {Component} from "react";
import LoginForm from "../../forms/auth/LoginForm";
import {
    __,
    clearURLFragment,
    getURLFragment,
    getURLParam,
    redirect,
    setFormErrors,
    validateForm
} from "../../core/utils";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/Login.scss";
import AuthLayout from "../../layouts/AuthLayout";
import {setNotification} from "../../redux/actions/general/notification";
import autobind from "autobind-decorator";
import {getUser, login, resendConfirmationEmail} from "../../redux/actions/auth";

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			two_factor: false
		}
	}

	componentDidMount() {
		if(getURLFragment() == '1') {
			setNotification("You have successfully reset your password. You may now login.");
		}

		if(getURLFragment() == '2') {
			setNotification("Your email has been successfully confirmed. You may now login.");
		}

		clearURLFragment();
	}

	@autobind
	_handleSubmit(formData) {
		const validation = validateForm(
			login(formData).then(() => {
				const return_url = getURLParam("return_url") || '';

				getUser().then(() => {
                    if(return_url.length > 1) {
                    	setTimeout(() => redirect(return_url, true), 100);
					}
                });
			})
		);

		validation.catch(({ errors }) => {
			if (_.has(errors, "code")) {
				if (errors.code === 1) {
					setNotification({
						type: "warning",
						text: __("auth.login.incorrect-login")
					});
				}

				if (errors.code === 2) {
					setFormErrors('auth.login', {email: [<>Email is not confirmed. <a onClick={() => resendConfirmationEmail(formData.email).then(() => {
						setNotification(__("auth.login.confirmation-resent"));

						setFormErrors('auth.login', {email: ['Email is not confirmed.']});
					})}>Resend confirmation email ?</a></>]});
				}

				if (errors.code === 3) {
					this.setState({two_factor: true});
				}

				if (errors.code === 4) {
					setNotification({
						type: "warning",
						text: __("auth.login.incorrect-two-factor-code")
					});
				}

				if (errors.code === 5) {
					setNotification({
						type: "warning",
						text: "There have been too many login failures. Please wait and try again later."
					});
				}
			}
		});

		return validation;
	}

	render() {
		const {two_factor} = this.state;

		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
					<span>{__('login-page.welcome-to-volume')}</span>
				</div>
				<div className={styles.form}>
					<LoginForm onSubmit={this._handleSubmit} initialValues={{ remember: "1" }} twoFactor={two_factor} />
				</div>
			</>
		);
	}
}

export default Login;