import store from "../../../../core/store";

export const listDisciplines = () => {
	return store.dispatch({
		action: "table.superadmin_disciplines/LIST_DISCIPLINES",
		method: "GET",
		endpoint: "/superadmin/disciplines",
		main: true
	});
};

export const createDiscipline = (formData) => {
	return store.dispatch({
		action: "table.superadmin_disciplines/CREATE_DISCIPLINE",
		method: "POST",
		endpoint: "/superadmin/disciplines",
		data: formData
	});
};

export const deleteDisciplines = (discipline_ids) => {
	return store.dispatch({
		action: "table.superadmin_disciplines/DELETE_DISCIPLINES",
		method: "DELETE",
		endpoint: "/superadmin/disciplines",
		data: {
			disciplines: discipline_ids
		}
	});
};

export const readDiscipline = (discipline_id) => {
	return store.dispatch({
		action: "table.superadmin_disciplines/READ_DISCIPLINE",
		method: "GET",
		endpoint: "/superadmin/disciplines/" + discipline_id,
		has404: true
	});
};

export const updateDiscipline = (discipline_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_disciplines/UPDATE_DISCIPLINE",
		method: "PUT",
		endpoint: "/superadmin/disciplines/" + discipline_id,
		data: formData
	});
};