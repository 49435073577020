import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/boq/Quantity.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  formatMoney,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setTableParams,
  unformatMoney,
  validateForm,
  submitForm,
} from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import Tooltip from '../../components/Tooltip';
import QuantityForm from '../../forms/boq/QuantityForm';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';

import RoomChooser from '../../components/RoomChooser';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import Dropdown from '../../components/Dropdown';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

import {
  addBOQPosition,
  addMultipleBOQPositions,
  deleteBOQPosition,
  deleteMultipleBOQPositions,
  getBOQArea,
  updateBOQCalculationQuantityBasicData,
  updateBOQCalculationQuantity,
  updateBOQPositionArea,
  updateBOQPositionName,
  updateBOQPositionQuantity,
} from '../../redux/actions/table/working_set_boq';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import NumericInput from '../../views/boq/helpers/NumericInput';

@subscribe(Localization, 'localization')
@mapStateToProps((state, props) => ({
  store: state.table.working_set_boq,
  clicked_row: _.get(state.table, ['working_set_boq', 'clicked_row']),
}))
class Quantities extends Component {
  static defaultProps = {
    editable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      space_allocation: {},
      collapsed_items: false,
      quantity_area_expanded_all: false,
    };
  }

  componentDidMount() {
    const stage_id = getActiveStage();

    getBOQArea(stage_id).then(({ response }) => {
      this.setState({ space_allocation: response.data });
    });
  }

  @autobind
  _toggleMasterCollapse() {
    this.setState((prevState) => {
      return {
        collapsed_items: !prevState.collapsed_items,
      };
    });
  }

  @autobind
  _handleBOQQuantitySubmit(formData) {
    return validateForm(
      updateBOQCalculationQuantityBasicData(this.props.store.flyout.id, {
        ...formData,
      }).then(() => {
        this.props.readAction(this.props.clicked_row);
        setNotification(__('boq-flyout.specification-details-updated'));
      })
    );
  }

  @autobind
  _handleAreaChange(id, values, position) {
    const { type: area_type, id: area_id } = values || {};

    const area_count = area_id
      ? _.size(
          _.filter(
            this.state.space_allocation.rooms,
            (room) => room.room_type_id == area_id
          )
        ) || 1
      : 1;

    updateBOQPositionArea(id, area_type, area_id, area_count).then(() => {
      setNotification(__('boq.details-updated'));

      this.props.readAction(this.props.clicked_row);
    });

    hideDropdown();
  }

  @autobind
  _handleAddMultiplePositions(areas) {
    const flyout = this.props.store.flyout;

    const formData = _.map(
      _.filter(areas, (area) => {
        return !_.find(
          flyout.positions,
          (position) =>
            area.id == position.area_id && area.type == position.area_type
        );
      }),
      (position) => {
        return {
          area_id: position.id,
          area_type: position.type,
        };
      }
    );

    const unit_id = flyout.unit_id;

    this.setState({ collapsed_items: true });

    if (!unit_id) {
      submitForm('working_set_boq.boq');
    } else {
      const deletedSpaceAllocationOrderIds = _.map(
        _.filter(
          _.filter(flyout.positions, (position) => position.area_id),
          (position) => {
            return !_.find(
              areas,
              (area) =>
                area.id == position.area_id && area.type == position.area_type
            );
          }
        ),
        (position) => position.order
      );

      if (_.size(deletedSpaceAllocationOrderIds) > 0) {
        showAlertbox({
          color: 'red',
          title: __('general.alert.are-you-sure'),
          description: __('quantity.alert.delete-confirmation'),
          buttons: [
            {
              color: 'lightGray',
              text: __('general.alert.no-close'),
              onClick: 'close',
            },
            {
              color: 'gray',
              text: __('general.alert.yes-delete'),
              onClick: () => {
                deleteMultipleBOQPositions(
                  this.props.clicked_row,
                  deletedSpaceAllocationOrderIds
                ).then(() =>
                  addMultipleBOQPositions(this.props.clicked_row, {
                    data: formData,
                  }).then(() => {
                    this.props.readAction(this.props.clicked_row);

                    hideDropdown();
                  })
                );

                hideAlertbox();
              },
            },
          ],
        });
      } else {
        addMultipleBOQPositions(this.props.clicked_row, {
          data: formData,
        }).then(() => {
          hideDropdown();

          this.props.readAction(this.props.clicked_row);
        });

        hideAlertbox();
      }
    }
  }

  @autobind
  _handlePositionNameChange(id, value, position) {
    const { language_id } = this.props.store;

    updateBOQPositionName(id, value, language_id, position).then(() => {
      setNotification(__('boq-flyout.specification-details-updated'));

      this.props.readAction(this.props.clicked_row);
    });

    hideDropdown();
  }

  @autobind
  _handlePositionQuantityChange(id, quantity, key) {
    updateBOQPositionQuantity(id, unformatMoney(quantity)).then(() => {
      setNotification(__('boq-flyout.specification-details-updated'));

      this.props.readAction(this.props.clicked_row);
    });

    hideDropdown();
  }

  @autobind
  _handlePositionAreaCountChange(id, area_id, area_type, area_count, key) {
    const { flyout } = this.props.store;

    updateBOQPositionArea(
      id,
      area_type,
      area_id,
      unformatMoney(area_count)
    ).then(() => {
      setNotification(__('boq-flyout.details-updated'));

      this.props.readAction(this.props.clicked_row);
    });

    hideDropdown();
  }

  @autobind
  _handleBOQCalculationQuantityChange(id, value) {
    updateBOQCalculationQuantity(id, value).then(() => {
      this.props.readAction(this.props.clicked_row);
    });
  }

  @autobind
  _handleDelete(id, position, item) {
    let handleDelete;

    handleDelete = () => deleteBOQPosition(id);

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('quantity.alert.delete-confirmation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () => {
            handleDelete().then(() => {
              setNotification(__('quantity.alert.deleted'));
            });

            hideAlertbox();

            this.props.readAction(this.props.clicked_row);
          },
        },
      ],
    });
  }

  @autobind
  _handleAddPosition() {
    const flyout = this.props.store.flyout;

    const unit_id = flyout.unit_id;

    this.setState({ collapsed_items: true });

    if (!unit_id) {
      submitForm('working_set_boq.boq');
    } else {
      addBOQPosition(this.props.clicked_row).then(() => {
        this.props.readAction(this.props.clicked_row);
      });
    }
  }

  render() {
    const { collapsed, store, localization } = this.props;
    const { flyout, language_id } = store;
    const { units, currencies } = localization;

    const { collapsed_items, quantity_area_expanded_all } = this.state;

    let space_allocation = [];

    _.each(this.state.space_allocation.sites, (site) =>
      space_allocation.push({
        id: site.id,
        type: 'site',
        display_name: site.code + ': ' + getLocalized(site.title, language_id),
        parent_type: null,
        parent_id: null,
      })
    );

    _.each(this.state.space_allocation.buildings, (building) =>
      space_allocation.push({
        id: building.id,
        type: 'building',
        display_name:
          building.code + ': ' + getLocalized(building.title, language_id),
        parent_type: building.site_id ? 'site' : null,
        parent_id: building.site_id ? building.site_id : null,
        children: _.filter(
          this.state.space_allocation.storeys,
          (storey) => storey.building_id === building.id
        ),
      })
    );

    _.each(this.state.space_allocation.storeys, (storey) => {
      space_allocation.push({
        id: storey.id,
        type: 'storey',
        display_name:
          storey.number + ': ' + getLocalized(storey.name, language_id),
        parent_type: storey.building_id ? 'building' : null,
        parent_id: storey.building_id ? storey.building_id : null,
      });
    });

    const room_types_quantity = _.map(
      _.filter(
        _.get(flyout, 'positions'),
        (position) => position.area_type == 'room_type'
      ),
      (position) => position.area_id
    );

    _.each(this.state.space_allocation.rooms, (room) => {
      const find_room_type = _.find(this.state.space_allocation.room_types, [
        'id',
        room.room_type_id,
      ]);

      space_allocation.push({
        id: room.id,
        type: 'room',
        display_name: (
          <Tooltip
            text={
              find_room_type
                ? `${getLocalized(
                    find_room_type?.title,
                    language_id
                  )} (${_.size(
                    _.filter(this.state.space_allocation.rooms, [
                      'room_type_id',
                      find_room_type?.id,
                    ])
                  )})`
                : room.code + ': ' + getLocalized(room.name, language_id)
            }
          >
            {room.code + ': ' + getLocalized(room.name, language_id)}
          </Tooltip>
        ),

        parent_type: room.storey_id ? 'storey' : null,
        parent_id: room.storey_id ? room.storey_id : null,
        disabled: _.includes(room_types_quantity, room.room_type_id),
      });
    });

    const excluded_room_types = _.map(
      _.filter(this.state.space_allocation.rooms, (room) =>
        _.some(
          _.get(flyout, 'positions'),
          (position) =>
            position.area_type == 'room' && position.area_id == room.id
        )
      ),
      (data) => data.room_type_id
    );

    _.each(this.state.space_allocation.room_types, (room_type) => {
      space_allocation.push({
        id: room_type.id,
        type: 'room_type',
        display_name: `${getLocalized(room_type.title, language_id)} (${_.size(
          _.filter(this.state.space_allocation.rooms, [
            'room_type_id',
            room_type?.id,
          ])
        )})`,
        disabled: _.includes(excluded_room_types, room_type.id),
      });
    });

    const unit = _.get(units, [_.get(flyout, 'unit_id'), 'symbol']);

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <QuantityForm
          onSubmit={this._handleBOQQuantitySubmit}
          initialValues={{
            ..._.pick(flyout, [
              'price',
              'unit_id',
              'budget_type',
              'installation_type',
              'boq_supply_type_group_id',
            ]),
          }}
          language_id={language_id}
        />
        {/* --- HEADER --- */}
        <div className={classNames(styles.row, styles.header)}>
          <span className={styles.expand} />
          <span className={styles.nameAndArea}>
            {__('boq-flyout.name&area')}
          </span>

          <span className={styles.areaCount}>
            {__('boq-flyout.area-count')}
          </span>
          <span className={styles.quantity}>{__('boq-flyout.quantity')}</span>
          <span className={styles.subQuantity}>
            {__('boq-flyout.sub_quantity')}
          </span>
          <span className={styles.unit}>{__('boq-flyout.unit')}</span>
          <span className={styles.price}>{__('boq-flyout.price')}</span>
          <span className={styles.subtotal}>{__('boq.quantity.subtotal')}</span>
          <span className={styles.delete} />
        </div>

        {/* --- FIRST ROW --- */}
        <div className={classNames(styles.row, styles.calculation)}>
          <span className={styles.expand}>
            <ArrowDownMiddleIcon
              className={classNames(collapsed_items && styles.active)}
              onClick={this._toggleMasterCollapse}
            />
          </span>
          <span className={styles.name}>{__('boq-flyout.total')}</span>

          {_.isEmpty(_.get(flyout, 'positions')) && (
            <span className={styles.quantity}>
              <NumericInput
                readOnly={!_.isEmpty(_.get(flyout, 'positions'))}
                key={_.uniqueId()}
                onBlur={(value) =>
                  this._handleBOQCalculationQuantityChange(
                    _.get(flyout, 'id'),
                    value
                  )
                }
                defaultValue={_.toNumber(_.get(flyout, 'quantity'))}
              />
            </span>
          )}

          <span className={styles.subQuantity}>
            <NumericInput
              readOnly={true}
              key={_.uniqueId()}
              defaultValue={_.toNumber(_.get(flyout, 'quantity'))}
            />
          </span>
          <span className={styles.unit}>{unit}</span>

          <span className={styles.price}>
            {formatMoney(_.get(flyout, 'price'))}
          </span>

          <span className={styles.subtotal}>
            {formatMoney(
              _.toNumber(_.get(flyout, 'quantity')) * _.get(flyout, 'price')
            )}
          </span>
          <span className={styles.delete} />
        </div>

        {/* --- QUANTITY POSITIONS --- */}
        {collapsed_items &&
          _.map(flyout?.positions, (position, p) => {
            let area;

            if (position.area_id) {
              area =
                _.find(space_allocation, {
                  type: position.area_type,
                  id: position.area_id,
                }) || {};
            }

            return (
              <div className={classNames(styles.row, styles.position)} key={p}>
                <span className={styles.expand}>{p + 1}</span>
                <span className={styles.nameAndArea}>
                  <span className={styles.area}>
                    <Dropdown
                      top={30}
                      left={-20}
                      leftStyled
                      wrapperClassName={styles.dropdownWrapper}
                      name={'area.' + position.id}
                      header={
                        <div className={styles.dropdownHeader}>
                          {__('table.area-tab.area')}

                          <div className={styles.dropdownHeaderActions}>
                            <span
                              className={styles.dropdownHeaderExpandAll}
                              onClick={() => {
                                this.setState({
                                  quantity_area_expanded_all:
                                    !this.state.quantity_area_expanded_all,
                                });
                              }}
                            >
                              {__('boq-flyout.area.expand-all')}
                            </span>
                            <span
                              className={styles.dropdownHeaderClear}
                              onClick={() =>
                                this._handleAreaChange(
                                  position.id,
                                  {
                                    type: '',
                                    id: null,
                                  },
                                  p + 1
                                )
                              }
                            >
                              {__('boq-flyout.area.clear')}
                            </span>
                          </div>
                        </div>
                      }
                      content={
                        <div
                          className={classNames(
                            styles.dropdownContent,
                            styles.space
                          )}
                        >
                          <RoomChooser
                            onCancel={hideDropdown}
                            options={space_allocation}
                            onDone={(values) =>
                              this._handleAreaChange(position.id, values, p + 1)
                            }
                            initialValues={_.isEmpty(area) ? {} : [area]}
                            expandedAllNodes={quantity_area_expanded_all}
                          />
                        </div>
                      }
                    >
                      <div
                        className={classNames(
                          styles.dropdown,
                          styles.areaDropdown
                        )}
                      >
                        {area ? (
                          <Tooltip text={area.display_name}>
                            <span>{area.display_name}</span>
                          </Tooltip>
                        ) : (
                          <span className={styles.none}>
                            {__('boq-flyout.select-area')}
                          </span>
                        )}{' '}
                        <ArrowDownSmallIcon />
                      </div>
                    </Dropdown>
                  </span>

                  <span className={styles.name}>
                    <input
                      className={styles.input}
                      type='text'
                      placeholder={__('boq-flyout.add-name')}
                      onKeyDown={(e) =>
                        e.key === 'Enter' && e.currentTarget.blur()
                      }
                      onBlur={({ currentTarget }) =>
                        this._handlePositionNameChange(
                          position.id,
                          currentTarget.value,
                          p + 1
                        )
                      }
                      key={
                        position.id + getLocalized(position.name, language_id)
                      }
                      defaultValue={getLocalized(position.name, language_id)}
                    />
                  </span>
                </span>

                <span className={styles.areaCount}>
                  <NumericInput
                    readOnly={position.area_id}
                    key={position.id + position.area_id}
                    onBlur={(value) =>
                      this._handlePositionAreaCountChange(
                        position.id,
                        position.area_id,
                        position.area_type,
                        value,
                        p + 1
                      )
                    }
                    defaultValue={position.area_count}
                  />
                </span>

                <span className={styles.quantity}>
                  <NumericInput
                    key={position.id + position.quantity}
                    onBlur={(value) =>
                      this._handlePositionQuantityChange(
                        position.id,
                        value,
                        p + 1
                      )
                    }
                    defaultValue={position.quantity}
                  />
                </span>

                <span className={styles.subQuantity}>
                  {formatMoney(position.sub_quantity)}
                </span>

                <span className={styles.unit}>
                  <Dropdown
                    leftStyled
                    top={25}
                    right={-15}
                    readOnly
                    wrapperClassName={styles.dropdownWrapper}
                    name={'unit.' + position.id}
                    header={
                      <div className={styles.dropdownHeader}>
                        {__('boq-flyout.unit')}
                      </div>
                    }
                  >
                    <div className={styles.dropdown}>
                      {unit && <span>{unit}</span>}
                      {!unit && <span className={styles.none}>--</span>}
                    </div>
                  </Dropdown>
                </span>
                <span className={styles.price}>
                  <NumericInput
                    key={position.id + flyout.price}
                    readOnly={true}
                    tooltip='This field is auto-calculated'
                    defaultValue={flyout?.price}
                  />
                </span>
                <span className={styles.subtotal}>
                  {formatMoney(position.subtotal_price)}
                </span>

                <span
                  className={styles.delete}
                  onClick={() => this._handleDelete(position.id, p + 1)}
                >
                  <Tooltip text={__(__('boq-flyout.delete-element'))}>
                    <DeleteIcon />
                  </Tooltip>
                </span>
              </div>
            );
          })}

        <div className={styles.addElementRow}>
          <button className={styles.button} onClick={this._handleAddPosition}>
            {__('boq-flyout.add-element')}
          </button>

          <Dropdown
            top={25}
            leftStyled
            wrapperClassName={styles.dropdownWrapper}
            name={'boq-flyout.add-multiple-rooms'}
            header={
              <div className={styles.dropdownHeader}>
                {__('table.area-tab.area')}

                <div className={styles.dropdownHeaderActions}>
                  <span
                    className={styles.dropdownHeaderExpandAll}
                    onClick={() => {
                      this.setState({
                        quantity_area_expanded_all:
                          !this.state.quantity_area_expanded_all,
                      });
                    }}
                  >
                    expand-all
                  </span>
                </div>
              </div>
            }
            content={
              <div className={classNames(styles.dropdownContent, styles.space)}>
                <RoomChooser
                  multiselect={true}
                  onCancel={hideDropdown}
                  options={_.map(space_allocation, (area) => {
                    return { ...area, disabled: false };
                  })}
                  //options={space_allocation}
                  onDone={(values) => {
                    this._handleAddMultiplePositions(values);
                  }}
                  selectAllRooms
                  initialValues={_.map(
                    _.filter(
                      flyout?.positions,
                      (position) => position?.area_id
                    ),
                    (position) => {
                      return _.find(space_allocation, {
                        type: position.area_type,
                        id: position.area_id,
                      });
                    }
                  )}
                  expandedAllNodes={quantity_area_expanded_all}
                />
              </div>
            }
          >
            <button className={styles.button}>
              {__('boq-flyout.add-multiple-rooms')}
            </button>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default Quantities;
