import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/CreateMeetingFromTasksWizard.scss";
import autobind from "autobind-decorator";
import {__, mapStateToProps, redirect, setTableParams} from "../core/utils";
import AddToMeetingIcon from "../assets/images/add-to-meeting-60x60.svg";
import {withRouter} from "react-router";
import {setNotification} from "../redux/actions/general/notification";
import {addTasksToMeeting} from "../redux/actions/table/all_meetings";
import CreateMeetingFromTasksForm from "../forms/create_meeting_from_tasks_wizard/CreateMeetingFromTasksForm";
import {SubmissionError} from "redux-form";
import {readTask} from "../redux/actions/table/all_tasks";

@withRouter
@mapStateToProps(state => ({
    tasks: _.get(state.table, 'all_meetings.meeting_from_tasks'), //tasks checkboxed at AllTasks.js
    opened_task_id: _.get(state.table, 'all_tasks.flyout.id'), //task opened in flyout (if clicked from meetings tab)
}))
class CreateMeetingFromTasksWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {new_meeting, meeting_id, edit} = formData;
        const {tasks, opened_task_id} = this.props;

        if(new_meeting) {
            setTableParams('all_meetings', {
                meeting_from_tasks: undefined, //close 'CreateMeetingFromTasksWizard'
                wizard_active: true, //open 'CreateMeetingWizard'
                tasks
            });
        } else {
            if(meeting_id) {
                addTasksToMeeting(meeting_id, {tasks}).then(() => {
                    this.props.onClose();

                    if(edit) {
                        redirect('/meetings/' + meeting_id);
                    } else if(opened_task_id) {
                        readTask(opened_task_id);

                        if(this.props.match.path == "/meetings/:meeting") {
                            setTableParams('all_tasks', {
                                clicked_row: opened_task_id,
                            });
                        }
                    }

                    setTimeout(() => setNotification(__('meetings.all-meetings.add-new.notification.selected-tasks-have-been-added-to-the-meeting')), 25);
                });
            } else {
                throw new SubmissionError({ meeting_id: 'Field required' });
            }
        }
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.meeting.title.create-meeting-from-tasks')}</span>
                        <span>{__('wizard.meeting.subtitle.create-meeting-from-tasks')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddToMeetingIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <CreateMeetingFromTasksForm
                        initialValues={{
                            new_meeting: false
                        }}
                        onSubmit={this._handleSubmit}
                    />
                </div>
            </>
        );
    }
}

export default CreateMeetingFromTasksWizard;