import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Options.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  dateTimeFrom,
  getLocalized,
  mapStateToProps,
  setTableParams,
  formatMoney,
  formatPercentageMoney,
} from '../../core/utils';
import TableNA from '../../components/TableNA';
import Tooltip from '../../components/Tooltip';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import MakeMainIcon from '../../assets/images/make-main-16x16.svg';
import autobind from 'autobind-decorator';
import {
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  setMainSpecification,
} from '../../redux/actions/table/working_set_specifications';
import { setNotification } from '../../redux/actions/general/notification';
import { withRouter } from 'react-router';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';

@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
@withRouter
@subscribe(Localization, 'localization')
class Options extends Component {
  @autobind
  _setMainOption(e, specification_id) {
    e.stopPropagation();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'working-set-specifications.options.are-you-sure-make-specification-main'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            setMainSpecification(specification_id).then(() => {
              hideAlertbox();

              readWorkingSetSpecification(this.props.store.flyout.id).then(
                ({ response }) => {
                  setTableParams('working_set_specifications', {
                    flyout: response.data,
                  });
                }
              );

              listWorkingSetSpecifications(this.props.match.params.stage);

              setNotification(
                __(
                  'working-set-specifications.options.selected-specification-main-option'
                )
              );
            });
          },
        },
      ],
    });
  }

  render() {
    const { collapsed, store, readOnly } = this.props;
    const { flyout } = store;
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const options = _.sortBy(flyout.options, (option) => !option.main);
    const main_total = _.first(options).total;

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.header}>
          <span className={styles.code}>
            {__('specifications.working-set.table-title.code')}
          </span>
          <span className={styles.title}>
            {__('specifications.working-set.table-title.title')}
          </span>
          <span className={styles.total}>
            {__('specifications.working-set.table-title.total')}
          </span>
          <span className={styles.price}>&Delta;</span>
          <span className={styles.price}>&#37;</span>
          {/* <span className={styles.date}>
            {__('specifications.working-set.table-title.created-at')}
          </span> */}
          <span className={styles.primary} />
        </div>
        {_.map(options, (option, i) => {
          const specification_subgroup =
            _.get(specification_subgroups, [
              flyout.specification_subgroup_id,
            ]) || {};
          const specification_group =
            _.get(
              specification_groups,
              _.get(specification_subgroup, 'specification_group_id')
            ) || {};
          const code =
            getLocalized(specification_group.code, flyout.project_language) +
            specification_subgroup.code +
            _.padStart(flyout.code, 2, '0');

          const title = _.filter(
            [
              _.get(option.description, store.language_id),
              option.model,
              option.brand,
            ],
            _.identity
          ).join(' / ');

          const deltaPrice = option.total - main_total;

          const percentagePrice = formatPercentageMoney(
            main_total ? deltaPrice / main_total : 0
          );

          const total = formatMoney(option.total);

          return (
            <div className={styles.row} key={i}>
              {!option.main && <span className={styles.indent}>&#8627;</span>}
              <div className={styles.code}>{code}</div>
              <Tooltip text={title}>
                <div
                  className={classNames(
                    styles.title,
                    flyout.id != option.id && styles.clickable
                  )}
                  onClick={() => {
                    flyout.id != option.id &&
                      setTableParams('working_set_specifications', {
                        clicked_row: option.id,
                      });
                  }}
                >
                  <span>{title || <TableNA />}</span>
                  {flyout.id != option.id && <ArrowDoubleRightSmallIcon />}
                </div>
              </Tooltip>
              <div className={styles.total}>{total}</div>
              <div className={styles.price}>
                {option.main
                  ? '-'
                  : deltaPrice > 0
                  ? '+' + formatMoney(deltaPrice)
                  : formatMoney(deltaPrice)}
              </div>
              <div className={styles.price}>
                {option.main ? '-' : percentagePrice}
              </div>
              {/* <div className={styles.date}>
                {dateTimeFrom(option.created_at)}
              </div> */}
              <div className={styles.primary}>
                {!option.main && !readOnly && (
                  <Tooltip
                    text={__(
                      'working-set-specifications.options.make-primary-option'
                    )}
                  >
                    <MakeMainIcon
                      onClick={(e) => this._setMainOption(e, option.id)}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          );

          return flyout.id != option.id ? (
            <Tooltip
              text={__('working-set-specifications.options.go-to-option')}
              placement='left'
            >
              {row}
            </Tooltip>
          ) : (
            row
          );
        })}
      </div>
    );
  }
}

export default Options;
