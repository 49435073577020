import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_team_wizard/AddTeamForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {__, transformToListPairs} from "../../core/utils";
import Select from "../../components/Select";
import classNames from "classnames";
import * as _ from "lodash";
import {listPossibleStageDisciplines, listPossibleTeamCompanies} from "../../redux/actions/table/teams";
import {withRouter} from "react-router";
import Input from "../../components/Input";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";

const mapStateToProps = state => ({
    values: getFormValues("add_team_wizard.add_team")(state),
});

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideAddTeamWizard: () => dispatch({
                type: "table.teams/SET_TABLE_PARAMS",
                params: {
                    add_team_wizard_active: undefined
                }
            })
        }
    };
};

@reduxForm({
    form: "add_team_wizard.add_team",
})
@subscribe(Localization, "localization")
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AddTeamForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_companies: [],
            stage_disciplines: [],
        }
    }

    componentDidMount() {
        listPossibleStageDisciplines(this.props.match.params.stage).then(({response}) => {
            this.setState({stage_disciplines: response.data});
        });

        listPossibleTeamCompanies().then(({response}) => {
            this.setState({possible_companies: response.data});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.values.discipline != this.props.values.discipline) || (prevProps.values.custom_discipline && !this.props.values.custom_discipline)) {
            if(_.startsWith(this.props.values.discipline, 'DISCIPLINE-')) {
                this.props.change('code', _.get(this.props.localization.disciplines, [_.trimStart(this.props.values.discipline, 'DISCIPLINE-'), 'code']));
            } else {
                this.props.change('code', null);
            }
        }

        if(!prevProps.values.custom_discipline && this.props.values.custom_discipline) {
            this.props.change('code', null);
            this.props.change('description', null);
        }
    }

    render_owners = ({fields}) => (<>
        {fields.map((field, i) => (
            <div className={classNames(styles.flex, styles.ownerRow)} key={i}>
                <div className={styles.f5}>
                    <Field name={field + '.email'} component={Input} label={__('project-settings.teams.add-new.team.new-company.company-owners-email')} />
                </div>
                <div className={styles.f2}>
                    <Field name={field + '.team_role'} component={Select} noSort label={__('project-settings.teams.add-new.team.new-company.team-role')} options={[
                        {
                            label: __('project-settings.teams.add-new.team.new-company.team-role.viewer'),
                            value: 'viewer',
                        }, {
                            label: __('project-settings.teams.add-new.team.new-company.team-role.reviewer'),
                            value: 'reviewer',
                        }, {
                            label: __('project-settings.teams.add-new.team.new-company.team-role.editor'),
                            value: 'editor',
                        }
                    ]} />
                </div>
                <CloseMiddleIcon className={classNames(styles.deleteOwner, _.size(fields) == 1 && styles.disabled)} onClick={() => _.size(fields) > 1 && fields.remove(i)} />
            </div>
        ))}
        <Button lightBlue rightIcon={PlusMiddleIcon} leftText={__('button.invite-more')} onClick={() => fields.push({team_role: 'editor'})} />
    </>);

    render() {
        const {stage_disciplines} = this.state;
        const {custom_discipline, discipline, new_company} = this.props.values;

        let disciplines = [];

        _.each(stage_disciplines, stage_discipline => {
            if(stage_discipline.discipline_id == null) {
                const label = [stage_discipline.code, stage_discipline.description].join(': ') + '*';

                disciplines.push({
                    value: 'STAGEDISCIPLINE-' + stage_discipline.id,
                    label
                });
            }
        });

        _.each(this.props.localization.disciplines, discipline => {
            let value;
            let label;
            const matching_stage_discipline = _.find(stage_disciplines, ['discipline_id', discipline.id]);

            if(matching_stage_discipline) {
                value = 'STAGEDISCIPLINE-' + matching_stage_discipline.id;
                label = [matching_stage_discipline.code, discipline.name].join(': ');
            } else {
                value = 'DISCIPLINE-' + discipline.id;
                label = [discipline.code, discipline.name].join(': ');
            }

            disciplines.push({value, label});
        });

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.chooser}>
                    <div className={classNames(styles.option, !custom_discipline && styles.selected)} onClick={() => this.props.change('custom_discipline', false)}><span />{__('project-settings.teams.add-new.team.choose-existing-discipine')}</div>
                    <div className={classNames(styles.option, custom_discipline && styles.selected)} onClick={() => this.props.change('custom_discipline', true)}><span />{__('project-settings.teams.add-new.team.custom-discipline')}</div>
                </div>
                {!custom_discipline && <Field name="discipline" component={Select} searchable label={__('project-settings.teams.add-new.team.discipline')} noSort options={disciplines} />}
                {(custom_discipline || _.startsWith(discipline, 'DISCIPLINE-')) && <Field name="code" component={Input} label={__('project-settings.team.add-new.team.choose-existing-discipline.code')} maxLength={4} />}
                {custom_discipline && <Field name="description" component={Input} label={__('project-settings.teams.add-new.team.custom-discipline')} />}
                <div className={styles.chooser}>
                    <div className={classNames(styles.option, !new_company && styles.selected)} onClick={() => this.props.change('new_company', false)}><span />{__('project-settings.teams.add-new.team.choose-existing-company')}</div>
                    <div className={classNames(styles.option, new_company && styles.selected)} onClick={() => this.props.change('new_company', true)}><span />{__('project-settings.teams.add-new.team.new-company')}</div>
                </div>
                {!new_company && <Field name="company_id" searchable={true} component={Select} label={__('project-settings.teams.add-new.team.company')} options={transformToListPairs(this.state.possible_companies)} />}
                {new_company && <Field name="company_name" component={Input} label={__('project-settings.teams.add-new.team.company-name')} />}
                {new_company && <FieldArray name="owner" component={this.render_owners} />}
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideAddTeamWizard} />
                    <Button gray medium submit right leftText={__('button.create')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default AddTeamForm;
