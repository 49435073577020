import React, { useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_tender_boq_from_boq_wizard/CreateTenderBOQFromBOQForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __ } from '../../core/utils';
import Select from '../../components/Select';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import classNames from 'classnames';
import * as _ from 'lodash';
import { listPossibleTenders } from '../../redux/actions/table/tenders';
import { withRouter } from 'react-router';

const CreateTenderBOQFromBOQForm = (props) => {
  const [list_possible_tenders, set_list_possible_tenders] = useState([]);

  useEffect(() => {
    listPossibleTenders(props.match.params.stage).then(({ response }) => {
      set_list_possible_tenders(
        _.map(response.data, (item) => {
          const { id, title, identifier } = item;

          return {
            label: 'B-' + _.padStart(identifier, 4, '0') + ': ' + title,
            value: id,
          };
        })
      );
    });
  }, []);

  const _handleSubmit = (edit) => {
    props.change('edit', edit);

    setTimeout(() => props.submit(), 10);
  };

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.chooser}>
          <div
            className={classNames(
              styles.option,
              !props.values.new_tender_boq && styles.selected
            )}
            onClick={() => props.change('new_tender_boq', false)}
          >
            <span />
            {__('boq.create-tender-boq-from-boq.add-to-existing-tender')}
          </div>
        </div>
        <div className={styles.selector}>
          {!props.values.new_tender_boq && (
            <Field
              name='tender_id'
              component={Select}
              noSort
              searchable
              label={__('boq.add-to-existing-tenders.existing-tender')}
              options={list_possible_tenders}
            />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={props.actions.hideCreateTenderBOQFromBOQWizard}
        />
        <ButtonGroup>
          {!props.values.new_tender_boq && (
            <>
              <Button
                lightBlue
                medium
                middleText={__('button.add')}
                spinning={props.submitting}
                onClick={() => _handleSubmit(false)}
              />
              <Button
                lightBlue
                medium
                middleText={__('button.add-go-to-tender')}
                spinning={props.submitting}
                onClick={() => _handleSubmit(true)}
              />
            </>
          )}
        </ButtonGroup>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    values: getFormValues('create_tender_boq_form.create_tender_boq')(state),
    stage_id: _.get(state.table, 'working_set_boq.stage_id'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideCreateTenderBOQFromBOQWizard: () =>
        dispatch({
          type: 'table.working_set_boq/SET_TABLE_PARAMS',
          params: {
            create_tender_boq_from_boq: undefined,
          },
        }),
    },
  };
};

export default withRouter(
  reduxForm({
    form: 'create_tender_boq_form.create_tender_boq',
  })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(subscribe(Localization, 'localization')(CreateTenderBOQFromBOQForm))
  )
);
