import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import {__, backendURL, dateTimeFrom, mapStateToProps, setTableParams} from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import styles from "../../styles/views/superadmin/Users.scss";
import Avatar from "../../components/Avatar";
import TableNA from "../../components/TableNA";
import classNames from "classnames";
import { listUsers, manualConfirmUserEmail } from "../../redux/actions/table/superadmin/users";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import { default as _ } from "lodash";
import Tooltip from "../../components/Tooltip";
import StatusIcon from "../../assets/images/status-15x15.svg";
import { setNotification } from "../../redux/actions/general/notification";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import {deleteRegions, listRegions} from "../../redux/actions/table/superadmin/regions";
import {listLocalization} from "../../redux/actions/localization";
import ExportIcon from "../../assets/images/export-15x15.svg";
import {downloadFile} from "../../redux/actions/general/downloader";

@mapStateToProps(state => ({
	table: state.table["superadmin_users"],
	superadmin: state.auth.superadmin
}))
class Users extends Component {
	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;
	}

	_handleOpacity(value, row) {
		return row.active == true ? value : <div className={styles.faded}>{value}</div>;
	}

	render() {
		const columns = [
			{
				key: "id",
				name: __("superadmin.users.column.id"),
				width: 60,
				default: true,
				value: (id, row) => this._handleOpacity(id, row)
			},
			{
				key: "avatar_url",
				name: __("superadmin.users.column.avatar"),
				value: (avatar_url, row) => (
					this._handleOpacity(<Avatar avatar_url={avatar_url} name={[row.firstname, row.lastname].join(' ')} active={row.active} />, row)
				),
				sortable: false,
				width: 50,
			},
			{
				key: "firstname",
				name: __("superadmin.users.column.first-name"),
				required: true,
				width: 100,
				value: (firstname, row) => this._handleOpacity(<Tooltip text={firstname}>{firstname}</Tooltip>, row)
			},
			{
				key: "lastname",
				name: __("superadmin.users.column.last-name"),
				width: 130,
				value: (lastname, row) => this._handleOpacity(<Tooltip text={lastname}>{lastname}</Tooltip>, row)
			},
			{
				key: "email",
				name: __("superadmin.users.column.email"),
				width: 150,
				value: (email, row) => this._handleOpacity(<Tooltip text={email}>{email}</Tooltip>, row)
			},
			{
				key: "email_confirmed",
				name: __("superadmin.users.column.email-confirmed"),
				value: (is_confirmed, row) => (
					this._handleOpacity(<div className={classNames(styles.boolean, is_confirmed && styles.yes)}>{is_confirmed ? __('general.yes') : __('general.no')}</div>, row)
				),
				width: 50,
				fullWidth: true,
			},
			{
				key: "two_factor",
				name: __("superadmin.users.column.two-factor-enabled"),
				value: (is_two_factor_enabled, row) => (
					this._handleOpacity(<div className={classNames(styles.boolean, is_two_factor_enabled && styles.yes)}>{is_two_factor_enabled ? __('general.yes') : __('general.no')}</div>, row)
				),
				width: 50,
				fullWidth: true,
			},
			{
				key: "company",
				name: __("superadmin.users.column.company"),
				value: (company, row) => this._handleOpacity(company ? <Tooltip text={company}>{company}</Tooltip> : <TableNA />, row)
			},
			{
				key: "role",
				name: __("superadmin.users.column.company-role"),
				value: (role, row) => this._handleOpacity(role ? _.upperFirst(role) : <TableNA />, row),
				width: 80
			},
			{
				key: "active",
				name: __("superadmin.users.column.active"),
				value: (is_active, row) => (
					this._handleOpacity(<div className={classNames(styles.boolean, is_active && styles.yes)}>{is_active ? __('general.yes') : __('general.no')}</div>, row)
				),
				width: 50,
				fullWidth: true,
			},
			{
				key: "language",
				name: __("superadmin.users.column.language"),
				width: 70,
				value: (language, row) => this._handleOpacity(language, row)
			},
			{
				key: "timezone",
				name: __("superadmin.users.column.timezone"),
				width: 100,
				value: (timezone, row) => this._handleOpacity(timezone, row)
			},
			{
				key: "created_at",
				name: __("superadmin.users.column.registered-at"),
				value: (value, row) => this._handleOpacity(value ? dateTimeFrom(value) : <TableNA />, row),
				width: 120,
			}, {
				key: "last_login_at",
				name: __("superadmin.users.column.last-login-at"),
				value: (value, row) => this._handleOpacity(value ? dateTimeFrom(value) : <TableNA />, row),
				width: 120,
				sortable: false
			}
		];

		const singleActions = (user_id) => {
			const email_confirmed = _.get(_.find(this.props.table.data, { id: user_id }), 'email_confirmed');

			let actions = [];

			if (!email_confirmed && this.props.superadmin.confirm_user_email) {
				actions.push(
					<Tooltip text={__('Manually confirm user\'s email')} key={_.uniqueId()}><StatusIcon
						onClick={e => {
							e.stopPropagation();

							manualConfirmUserEmail([user_id]).then(() => {
								setNotification(__("The selected user has been confirmed"));

								listUsers();

								this.props.actions.setTableParams({
									actioned_row: undefined
								});
							});
						}}
					/></Tooltip>
				)
			}

			return actions;
		};

		const groupActions = (user_ids) => [
			<Tooltip text={__('users.tooltip.icon.export-users')} key={_.uniqueId()}>
				<ExportIcon
					onClick={e => {
						e.stopPropagation();

						setNotification("users.notification.users-export");

						downloadFile(backendURL + "/superadmin/users/export", {
							users: user_ids
						});
					}}
				/>
			</Tooltip>,
		];

		return (
			<Table
				listAction={listUsers}
				name="superadmin_users"
				singleActions={singleActions}
				groupActions={groupActions}
				title={__("modules.submodules.title.users")}
				columns={columns}
			/>
		);
	}
}

export default Users;
