import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/meetings/MeetingsFlyout.scss";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import classNames from 'classnames';
import * as _ from "lodash";
import autobind from "autobind-decorator";
import {__, setTableParams} from "../../core/utils";
import EditMeetingToolbarForm from "../../forms/meetings/EditMeetingToolbarForm";
import Properties from "./Properties";
import {listAllMeetings, updateMeetingToolbar} from "../../redux/actions/table/all_meetings";
import {setNotification} from "../../redux/actions/general/notification";
import Tooltip from "../../components/Tooltip";
import SendIcon from "../../assets/images/send-16x16.svg";
import PrintIcon from "../../assets/images/print-16x16.svg";
import Archive from "./Archive";
import MeetingFollowUpIcon from "../../assets/images/meeting-follow-up-16x16.svg";

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.all_meetings/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(state => ({
    forceMeetingUpdate: _.get(state.table, 'all_meetings.forceMeetingUpdate'),
    user_id: state.auth.id,
}), mapDispatchToProps)
@withRouter
class MeetingsFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    @autobind
    _handleSubmit(formData) {
        updateMeetingToolbar(formData.id, {
            ..._.omit(formData, ['id']),
        }).then(() => {
            this.props.match.params.stage && listAllMeetings(this.props.match.params.stage, this.props.match.params.status);

            this.props.readAction(formData.id);

            setNotification(__('tasks.properties.saved'));
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.forceMeetingUpdate && this.props.forceMeetingUpdate) {
            setTableParams('all_meetings', {
                forceMeetingUpdate: undefined
            });

            this.props.readAction(this.props.data.id);
        }
    }

    render() {
        const {data, readAction, user_id} = this.props;
        const is_administrator = _.isObject(_.find(data.administrators, ['id', user_id]));

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>
                            <strong>{'M-' + _.padStart(data.identifier, 4, "0")}:</strong>{_.get(data.title, _.findKey(_.get(data, 'project_languages'), primary => primary == 1))}
                        </span>
                        <CloseBigIcon onClick={() => setTimeout(() => {
                            this.props.actions.setTableParams({
                            clicked_row: undefined
                        })}, 100)}/>
                    </div>
                    <div className={styles.actions}>
                        {data.is_editor && <div className={styles.actionWrapper}>
                            <Tooltip text={__('meetings.tooltip.icon.create-follow-up')}><MeetingFollowUpIcon
                                onClick={e => {
                                    e.stopPropagation();

                                    setTableParams('all_meetings', {
                                        create_followup_meeting_wizard: data.id
                                    });
                                }}
                            /></Tooltip>
                        </div>}
                        {is_administrator && <div className={styles.actionWrapper}>
                            <Tooltip text={__('meetings.tooltip.icon.send-meeting-report')}><SendIcon
                                onClick={e => {
                                    e.stopPropagation();

                                    setTableParams('all_meetings', {
                                        send_meeting_report_wizard: true,
                                        meeting_id: data.id,
                                        send: true,
                                    });
                                }}
                            /></Tooltip>
                        </div>}
                        <div className={styles.actionWrapper}>
                            <Tooltip text={__('meetings.tooltip.icon.print-meeting-report')}><PrintIcon
                                onClick={e => {
                                    e.stopPropagation();

                                    setTableParams('all_meetings', {
                                        print_meeting_report_wizard: true,
                                        meeting_id: data.id,
                                        meeting_status: data.status,
                                        send: false,
                                    });
                                }}
                            /></Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.toolbar}>
                    <EditMeetingToolbarForm
                        data={data}
                        onSubmit={this._handleSubmit}
                        initialValues={{
                            ..._.pick(data, ['id', 'date', 'status', 'visibility']),
                        }}
                    />
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>{__('allmeetings.flyout.properties')}</span>
                    <span className={classNames(this.state.active == 'archive' && styles.active)} onClick={() => this.setState({active: 'archive'})}>{__('allmeetings.flyout.archive')} {(!_.isEmpty(data.agenda_reports) || !_.isEmpty(data.closed_reports)) && (' (' + (_.size(data.agenda_reports) + _.size(data.closed_reports)) + ')')}</span>
                </div>
                {!_.isEmpty(data) && this.state.active == 'properties' && <Properties data={data} readAction={readAction} />}
                {!_.isEmpty(data) && this.state.active == 'archive' && <Archive data={data} />}
            </div>
        );
    }
}

export default MeetingsFlyout;