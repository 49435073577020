import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/partner_companies/PartnerCompaniesFlyout.scss";
import * as _ from "lodash";
import {get, groupBy, map} from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import {connect} from "react-redux";
import CompanySizeIcon from "../../assets/images/company-size-15x15.svg";
import {__, getLocalizedListValue, redirect} from "../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import withRouter from "react-router/es/withRouter";
import UserMaleIcon from "../../assets/images/user-male-15x15.svg";
import LocationPinIcon from "../../assets/images/location-pin-15x15.svg";
import Tooltip from "../../components/Tooltip";
import {setNotification} from "../../redux/actions/general/notification";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import PhoneIcon from "../../assets/images/phone-15x15.svg";
import MessageBigIcon from "../../assets/images/message-big-15x15.svg";
import InfoPositiveIcon from "../../assets/images/info-positive-16x16.svg";
import Image from "../../components/Image";
import DeactivateIcon from "../../assets/images/deactivate-15x15.svg";
import {listPartnerCompanies, removePartnerCompanies} from "../../redux/actions/table/partner_companies";
import DefaultImage from "../../assets/images/default.png";
import UsersIcon from "../../assets/images/users-16x16.svg";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.partner_companies/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(null, mapDispatchToProps)
@subscribe(Localization, "localization")
@withRouter
class PartnerCompaniesFlyout extends Component {
    render() {
        const {data} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        {get(data, "name")}
                        <CloseBigIcon
                            onClick={() => setTimeout(() => {
                                this.props.actions.setTableParams({
                                    clicked_row: undefined
                            })}, 100)}/>
                    </div>
                    <div className={styles.actions}>
                        <div className={styles.actionWrapper}>
                            <Tooltip text={__('companies-and-users.partner-companies.remove-from-partner-companies')} key={_.uniqueId()}>
                                <DeactivateIcon
                                    onClick={e => {
                                        e.stopPropagation();

                                        showAlertbox({
                                            color: "red",
                                            title: __('general.alert.are-you-sure'),
                                            description: __('companies-and-users.partner-companies.want-remove-selected-company-partner list.process-cannot-undone'),
                                            buttons: [
                                                {
                                                    color: 'lightGray',
                                                    text: __('general.alert.no-close'),
                                                    onClick: 'close'
                                                },
                                                {
                                                    color: 'gray',
                                                    text: __('general.alert.yes-remove'),
                                                    onClick: () => {
                                                        removePartnerCompanies([data.id]).then(() => {
                                                            hideAlertbox();

                                                            setNotification(__('companies-and-users.partner-companies.selected-company-removed-partner-companies'));
                                                            listPartnerCompanies();

                                                            this.props.actions.setTableParams({
                                                                clicked_row: undefined
                                                            });
                                                        });
                                                    },
                                                }
                                            ]
                                        })
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.scroller}>
                    <div className={styles.details}>
                        <div className={styles.info}>
                            <div>
								<span>
									<CompanySizeIcon /> {__('companies-and-users.partner-companies.flyout.size')}
								</span>
                                <span>
									{_.upperFirst(getLocalizedListValue(this.props.localization.company_sizes, get(data, "size_id")))}:{" "}
                                    {getLocalizedListValue(this.props.localization.company_sizes, get(data, "size_id"), "size")} {__('companies-and-users.partner-companies.flyout.employees')}
								</span>
                            </div>
                            <div>
								<span>
									<LocationPinIcon /> {__('companies-and-users.partner-companies.flyout.location')}
								</span>
                                <span>
									{get(data, "office.address")}
                                    <br />
                                    {get(data, "office.state") && get(data, "office.state") + ", "}
                                    {get(data, "office.zip")}, {get(data, "office.city")}
                                    <br />
                                    {getLocalizedListValue(this.props.localization.countries, get(data, "office.country_id"))}
								</span>
                            </div>
                            <div>
								<span>
									<PhoneIcon /> {__('companies-and-users.partner-companies.flyout.phone')}
								</span>
                                <span>{get(data, "office.phones.0")}</span>
                            </div>
                            <div>
								<span>
									<MessageBigIcon /> {__('companies-and-users.partner-companies.flyout.email')}
								</span>
                                <span>{get(data, "office.emails.0")}</span>
                            </div>
                            <div>
								<span>
									<InfoPositiveIcon className='' /> {__('companies-and-users.partner-companies.flyout.vat-nr')}
								</span>
                                <span>{get(data, "vat")}</span>
                            </div>
                            <div>
								<span>
									<UserMaleIcon /> {__('companies.account.owner')}
								</span>
                                <span>{get(data, "owner")}</span>
                            </div>
                            <div>
                                <span><UsersIcon /> {__('companies.account.members')}</span>
                                <div className={styles.target} onClick={() => redirect('/companies/partner_members?filter.company=' + data.id)}>
                                    <div>{data.members}</div>
                                    <ArrowDoubleRightSmallIcon />
                                </div>
                            </div>
                        </div>
                        <div className={styles.logo}>
                            <Image src={_.get(data, 'logo_url')} default={DefaultImage} />
                        </div>
                    </div>
                    {(get(data, "description_long") || get(data, "description")) && (
                        <div className={styles.content}>
                            <div className={styles.title}>{__('companies-and-users.partner-companies.flyout.description')}</div>
                            <p>{get(data, "description_long") || get(data, "description")}</p>
                        </div>
                    )}
                    <div className={styles.content}>
                        <div className={styles.title}>{__('companies-and-users.partner-companies.flyout.disciplines')}</div>
                        <p>{map(get(data, "disciplines"), discipline => getLocalizedListValue(this.props.localization.disciplines, discipline)).join(", ")}</p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.title}>{__('companies-and-users.partner-companies.flyout.market')}</div>
                        {map(groupBy(get(data, "market"), country_id => getLocalizedListValue(this.props.localization.countries, country_id, "region_id")), (countries, region_id) => (
                            <p key={region_id}>
                                <strong>{getLocalizedListValue(this.props.localization.regions, region_id)}:</strong>{" "}
                                {map(countries, country_id => getLocalizedListValue(this.props.localization.countries, country_id)).join(", ")}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default PartnerCompaniesFlyout;
