import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/company_info_wizard/LogoForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {withRouter} from "react-router";
import Dropzone from "../../components/Dropzone";
import {__} from "../../core/utils";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import {hideCreateCompanyWizard} from "../../redux/actions/profile";
import ButtonGroup from "../../components/ButtonGroup";

class LogoForm extends Component {
	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.content}>
					<Field name="logo" component={Dropzone} item="Logo" accept="image/png,image/jpeg" />
				</div>
				<div className={styles.footer}>
					<Button lightGray medium left middleText={__('button.cancel')} onClick={hideCreateCompanyWizard} />
					<ButtonGroup right>
						<Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
						<Button submit medium lightBlue leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

export default withRouter(
	reduxForm({
		form: "company_info_wizard.logo",
		destroyOnUnmount: false
	})(LogoForm)
);
