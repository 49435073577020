import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {__} from "../../core/utils";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {listPossibleStageDisciplineApprovers} from "../../redux/actions/table/stage_disciplines";
import {enableAutosave} from "../../redux/actions/general/autosave";
import {withRouter} from "react-router";
import PeopleChooser from "../../components/PeopleChooser";

const mapStateToProps = state => {
    return {
        values: getFormValues("stage_disciplines.details")(state),
    };
};

@reduxForm({
    form: "stage_disciplines.details",
    enableReinitialize: true,
})
@subscribe(Localization, "localization")
@connect(mapStateToProps)
@withRouter
class EditStageDisciplineForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_approvers: []
        }
    }

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    componentDidMount() {
        listPossibleStageDisciplineApprovers(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_approvers: response.data});
        });
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit}>
                <Field name="approvers" component={PeopleChooser} disabled={!this.props.isProjectAdministrator} label={__('Approvers')} searchable options={this.state.possible_approvers} />
            </Form>
        );
    }
}

export default EditStageDisciplineForm;
