import * as React from "react";
import {Component} from "react";
import styles from "../styles/containers/FloatingProgress.scss";
import * as _ from "lodash";
import {connect} from "react-redux";
import autobind from "autobind-decorator";

const mapStateToProps = state => {
    return {
        plan_uploads: state.table["plan_uploads"],
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.plan_uploads/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class FloatingProgress extends Component {
    @autobind
    _showPlanUploadingProgress() {
        this.props.actions.setTableParams({
            wizard_active: true,
        })
    }

    render() {
        const files_uploading = _.filter(this.props.plan_uploads.files, file => !_.has(file, 'success'));
        const uploading = !_.isEmpty(this.props.plan_uploads.files) && !_.isEmpty(files_uploading);
        const average_progress = _.round(parseFloat(_.sumBy(files_uploading, 'progress')) / _.size(files_uploading));
        
        return (
            <div>
                {this.props.plan_uploads.minimize && uploading && (
                    <div className={styles.floatingBar} onClick={this._showPlanUploadingProgress}>
                        <div className={styles.bar} style={{width: average_progress + "%"}}/>
                        <div className={styles.info}>
                            {average_progress}%
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default FloatingProgress;