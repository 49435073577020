import store from "../../../../core/store";

export const listBOQTemplateSubgroups = () => {
	return store.dispatch({
		action: "table.superadmin_boq_template_subgroups/LIST_BOQ_TEMPLATE_SUBGROUPS",
		method: "GET",
		endpoint: "/superadmin/boq_template_subgroups",
		main: true
	});
};

export const createBOQTemplateSubgroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_subgroups/CREATE_BOQ_TEMPLATE_SUBGROUP",
		method: "POST",
		endpoint: "/superadmin/boq_template_subgroups",
		data: formData
	});
};

export const deleteBOQTemplateSubgroups = (boq_template_subgroup_ids) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_subgroups/DELETE_BOQ_TEMPLATE_SUBGROUPS",
		method: "DELETE",
		endpoint: "/superadmin/boq_template_subgroups",
		data: {
			boq_template_subgroups: boq_template_subgroup_ids
		}
	});
};

export const readBOQTemplateSubgroup = (boq_template_subgroup_id) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_subgroups/READ_BOQ_TEMPLATE_SUBGROUP",
		method: "GET",
		endpoint: "/superadmin/boq_template_subgroups/" + boq_template_subgroup_id,
		has404: true
	});
};

export const updateBOQTemplateSubgroup = (boq_template_subgroup_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_template_subgroups/UPDATE_BOQ_TEMPLATE_SUBGROUP",
		method: "PUT",
		endpoint: "/superadmin/boq_template_subgroups/" + boq_template_subgroup_id,
		data: formData
	});
};