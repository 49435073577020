import React, {Component} from "react";
import styles from "../styles/components/Checkbox.scss";
import CheckedIcon from "../assets/images/checked-15x15.svg";
import UncheckedIcon from "../assets/images/unchecked-15x15.svg";
import classNames from "classnames";
import autobind from "autobind-decorator";

class Checkbox extends Component {
    @autobind
	_toggleChecked() {
    	if(!this.props.disabled) {
			this.props.input.onFocus();
			this.props.input.onChange(this.props.input.value ? 0 : 1);
			setTimeout(() => this.props.input.onBlur(), 100); //fix for unBlur listener... without timeout, it's too fast to catch the listener :)
		}
	}

	render() {
		const classes = classNames([
			styles.wrapper,
			this.props.center && styles.center,
			this.props.blue && styles.blue,
			this.props.darkGray && styles.darkGray,
			this.props.white && styles.white,
			this.props.lightGray && styles.lightGray,
			this.props.disabled && styles.disabled,
			this.props.className
		]);

		return (
			<div className={classes} onClick={this._toggleChecked}>
				{this.props.input.value == 1 && <CheckedIcon className={styles.checked} />}
				{this.props.input.value == 0 && <UncheckedIcon className={styles.unchecked} />}
				{this.props.label}
			</div>
		);
	}
}

export default Checkbox;
