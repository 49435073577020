import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {mapStateToProps, transformToListPairs} from "../../../core/utils";
import {enableAutosave} from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import {withRouter} from "react-router";
import Localization from "../../../helpers/Localization";
import {subscribe} from "react-contextual";

@subscribe(Localization, "localization")
@reduxForm({
    form: "superadmin_countries.edit_country",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
class EditCountryForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {handleSubmit, superadmin, localization} = this.props;
       
        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_country} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_country} name="region_id" component={Select} searchable options={transformToListPairs(localization.regions)} label="Region" />
            </Form>
        );
    }
}

export default EditCountryForm;