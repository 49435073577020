import * as React from 'react';
import styles from '../../styles/flyouts/tenders/Bidders.scss';
import { __, setTableParams } from '../../core/utils';

import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import Dropdown from '../../components/Dropdown';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import { hideAlertbox } from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import { updateBidderDecisionStatus } from '../../redux/actions/table/tenders';
import { hideDropdown } from '../../redux/actions/general/dropdown';

const Bidders = (props) => {
  const {
    data: { bidders, status, administrators, is_bidder, id },
    collapsed,
    user_id,
    readAction,
  } = props;

  const is_administrator = _.isObject(_.find(administrators, ['id', user_id]));

  const disabled =
    !is_administrator || _.includes(['closed', 'canceled'], status);

  const statuses = [
    {
      value: 'received',
      label: __('tenders.bidder.status.received'),
    },
    {
      value: 'in-progress',
      label: __('tenders.bidder.status.in-progress'),
    },
    {
      value: 'submitted',
      label: __('tenders.bidder.status.submitted'),
    },
    {
      value: 'withdrawn',
      label: __('tenders.bidder.status.withdrawn'),
    },
    {
      value: 'expired',
      label: __('tenders.bidder.status.expired'),
    },
  ];

  const decision_statuses = [
    {
      value: 'not_selected',
      label: __('tenders.bidder.decision_status.not_selected'),
      color: '#CCCDD1',
    },
    {
      value: 'awarded',
      label: __('tenders.bidder.decision_status.awarded'),
      color: '#5777FC',
    },
    {
      value: 'shortlisted',
      label: __('tenders.bidder.decision_status.shortlisted'),
      color: '#75FCFD',
    },
    {
      value: 'disqualified',
      label: __('tenders.bidder.decision_status.disqualified'),
      color: '#E62A10',
    },
    {
      value: 'withdrawn',
      label: __('tenders.bidder.decision_status.withdrawn'),
      color: '#F19E38',
      disabled: true,
    },
    {
      value: 'expired',
      label: __('tenders.bidder.decision_status.expired'),
      color: '#FEFE54',
      disabled: true,
    },
  ];

  const _handleDecisionStatusChange = (bidder_id, decision_status) => {
    updateBidderDecisionStatus(id, bidder_id, { decision_status }).then(() => {
      if (decision_status === 'awarded') {
        const bidderWithAwardedStatus = _.find(
          bidders,
          (bidder) => bidder.decision_status === 'awarded'
        );

        if (bidderWithAwardedStatus) {
          return updateBidderDecisionStatus(id, bidderWithAwardedStatus.id, {
            decision_status: 'not_selected',
          }).then(() => {
            readAction();
          });
        }
      }

      return readAction();
    });

    hideDropdown();

    setNotification(__('tenders.properties.saved'));
  };

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      {_.isEmpty(bidders) ? (
        <div className={styles.empty}>{__('tenders.flyout.no-bidders')}</div>
      ) : (
        ''
      )}
      {!_.isEmpty(bidders) && (
        <>
          <div className={styles.header}>
            <span className={styles.name}>
              {__('tenders.flyout.bidder-name')}
            </span>
            <span className={styles.company}>
              {__('tenders.flyout.bidder-company')}
            </span>
            <span className={styles.email}>
              {__('tenders.flyout.bidder-email')}
            </span>
            {!is_bidder && (
              <span className={styles.fileCount}>
                {__('tenders.flyout.bidder-file-count')}
              </span>
            )}
            <span className={styles.status}>
              {is_bidder
                ? __('tenders.flyout.bidder-status')
                : __('tenders.flyout.bidder-status&decision')}
            </span>
          </div>
          {_.map(bidders, (bidder, i) => (
            <div className={styles.row} key={i}>
              <span className={styles.name}>{bidder.name}</span>
              <span className={styles.company}>{bidder.company}</span>
              <span className={styles.email}>{bidder.email}</span>
              {!is_bidder && (
                <span className={styles.fileCount}>{bidder?.file_count}</span>
              )}

              {is_bidder ? (
                <span className={styles.status}>
                  {
                    _.find(statuses, (status) => status.value == bidder.status)
                      ?.label
                  }
                </span>
              ) : (
                <div className={styles.status}>
                  {
                    _.find(statuses, (status) => status.value == bidder.status)
                      ?.label
                  }

                  <Dropdown
                    top={30}
                    left={-35}
                    readOnly={_.includes(
                      ['expired', 'withdrawn'],
                      bidder.decision_status
                    )}
                    wrapperClassName={styles.dropdownWrapper}
                    name={'decision_status.' + i}
                    header={
                      <div className={styles.dropdownHeader}>
                        <MeetingStatusIcon className={styles.icon} />
                        {__('tender-boq.tooltip.decision_status')}
                      </div>
                    }
                    content={
                      <div className={styles.dropdownContent}>
                        {_.map(
                          _.filter(
                            decision_statuses,
                            (status) =>
                              !_.includes(
                                ['expired', 'withdrawn'],
                                status.value
                              )
                          ),
                          (status, i) => (
                            <div
                              key={i}
                              className={classNames(styles.option)}
                              onClick={(e) => {
                                e.stopPropagation();

                                _handleDecisionStatusChange(
                                  bidder.id,
                                  status.value
                                );
                              }}
                            >
                              <span>{status.value}</span>

                              <span
                                className={styles.dot}
                                style={{
                                  background: status.color,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    }
                  >
                    <div
                      className={classNames(
                        styles.dropdownStatus,
                        _.includes(
                          ['expired', 'withdrawn'],
                          bidder.decision_status
                        ) && styles.disabled
                      )}
                      style={{
                        background: _.find(
                          decision_statuses,
                          (status) => status.value === bidder.decision_status
                        ).color,
                      }}
                    >
                      <div className={styles.button}>
                        <span>{bidder.decision_status}</span>

                        <ArrowDownSmallIcon />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>
          ))}
        </>
      )}

      {!disabled && (
        <div className={styles.addRow}>
          <button
            onClick={() =>
              setTableParams('tenders', {
                add_bidder_wizard_active: true,
              })
            }
          >
            {__('tenders.bidders.add-bidder')}
          </button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user_id: state.auth.id,
  };
};

export default connect(mapStateToProps, null)(Bidders);
