import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/meetings/Archive.scss";
import {withRouter} from "react-router";
import {__, dateTimeFrom, scrollToBottom} from "../../core/utils";
import DocumentExtIcon from "../../assets/images/document-ext-30x30.svg";
import DownloadIcon from "../../assets/images/download-15x15.svg";
import * as _ from "lodash";
import autobind from "autobind-decorator";
import {setNotification} from "../../redux/actions/general/notification";

@withRouter
class Archive extends Component {
    componentDidMount() {
        scrollToBottom(this.refs.scroll);
    }

    @autobind
    _downloadFile(download_url) {
        setNotification(__('meetings.reports.download.continue'));

        window.open(download_url, '_blank');
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.wrapper}>
                <div className={styles.scroller} ref="scroll">
                    {_.map(data.agenda_reports, row => (
                        <div className={styles.row} key={row.id}>
                            <div className={styles.left}>
                                <div className={styles.icon} onClick={() => this._downloadFile(row.download_url)}>
                                    <DocumentExtIcon className={styles['color' + _.sumBy(_.toLower(row.ext).split(''), item => item.charCodeAt(0)) % 5]}/>
                                    <span>{row.ext.substring(0, 4)}</span>
                                </div>
                                <strong onClick={() => this._downloadFile(row.download_url)}>{row.name}</strong> · {row.ext.toUpperCase()}
                            </div>
                            <div className={styles.right}>
                                {row.created_by} · {dateTimeFrom(row.created_at)} <DownloadIcon onClick={() => this._downloadFile(row.download_url)} />
                            </div>
                        </div>
                    ))}
                    {_.map(data.closed_reports, row => (
                        <div className={styles.row} key={row.id}>
                            <div className={styles.left}>
                                <div className={styles.icon} onClick={() => this._downloadFile(row.download_url)}>
                                    <DocumentExtIcon className={styles['color' + _.sumBy(_.toLower(row.ext).split(''), item => item.charCodeAt(0)) % 5]}/>
                                    <span>{row.ext.substring(0, 4)}</span>
                                </div>
                                <strong onClick={() => this._downloadFile(row.download_url)}>{row.name}</strong> · {row.ext.toUpperCase()}
                            </div>
                            <div className={styles.right}>
                                {row.created_by} · {dateTimeFrom(row.created_at)} <DownloadIcon onClick={() => this._downloadFile(row.download_url)} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Archive;
