import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_zone_wizard/AddZoneForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams, transformToListPairs} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";

@withRouter
@reduxForm({form: "add_zone_wizard.add_zone"})
@subscribe(Localization, "localization")
class AddZoneForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const {localization} = this.props;
        const {functional_spaces} = localization;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="title" component={Input} localized={this.props.projectLanguages} label={__('zones.field.title')} maxLength={50} />
                    <Field name="description" component={Textarea} localized={this.props.projectLanguages} label={__('zones.field.description')} maxLength={2000} rows={1} maxRows={10} />
                    <Field name="functional_spaces" component={MultiSelect} label={__('zones.field.functional-space')} searchable options={transformToListPairs(functional_spaces)} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('zones', {
                        add_zone_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddZoneForm;
