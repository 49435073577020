import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_plans/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import EditCurrentSetPlanForm from '../../forms/plans/EditCurrentSetPlanForm';
import {
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { getBOQArea } from '../../redux/actions/table/working_set_boq';
import classNames from "classnames";

@mapStateToProps((state) => ({
  application_language_id: state.auth.language_id,
  possible_space_allocation:
    state.table.current_set_plans.possible_space_allocation,
}))
@withRouter
class Properties extends Component {
  componentDidMount() {
    const { data } = this.props;

    const stage_id = data.stage_id;

    getBOQArea(stage_id).then(({ response }) => {
      setTableParams('current_set_plans', {
        possible_space_allocation: response.data,
      });
    });
  }

  render() {
    const { data, collapsed, application_language_id } = this.props;

    const possible_space_allocation = _.get(
      this.props,
      'possible_space_allocation',
      {}
    );

    const space_allocation = [];

    _.each(possible_space_allocation.sites, (site) =>
      space_allocation.push({
        id: site.id,
        type: 'site',
        display_name:
          site.code + ': ' + getLocalized(site.title, application_language_id),
        parent_type: null,
        parent_id: null,
      })
    );

    _.each(possible_space_allocation.buildings, (building) =>
      space_allocation.push({
        id: building.id,
        type: 'building',
        display_name:
          building.code +
          ': ' +
          getLocalized(building.title, application_language_id),
        parent_type: building.site_id ? 'site' : null,
        parent_id: building.site_id ? building.site_id : null,
      })
    );

    _.each(possible_space_allocation.storeys, (storey) =>
      space_allocation.push({
        id: storey.id,
        type: 'storey',
        display_name:
          storey.number +
          ': ' +
          getLocalized(storey.name, application_language_id),
        parent_type: storey.building_id ? 'building' : null,
        parent_id: storey.building_id ? storey.building_id : null,
      })
    );

    _.each(possible_space_allocation.rooms, (room) =>
      space_allocation.push({
        id: room.id,
        type: 'room',
        display_name:
          room.code + ': ' + getLocalized(room.name, application_language_id),
        parent_type: room.storey_id ? 'storey' : null,
        parent_id: room.storey_id ? room.storey_id : null,
      })
    );

    _.each(possible_space_allocation.expansion_joints, (expansion_joint) =>
      space_allocation.push({
        id: expansion_joint.id,
        type: 'expansion_joint',
        display_name: getLocalized(
          expansion_joint.title,
          application_language_id
        ),
      })
    );

    _.each(possible_space_allocation.room_types, (room_type) =>
      space_allocation.push({
        id: room_type.id,
        type: 'room_type',
        display_name:
          room_type?.code +
          ': ' +
          getLocalized(room_type.title, application_language_id),
      })
    );

    _.each(possible_space_allocation.entrances, (entrance) =>
      space_allocation.push({
        id: entrance.id,
        type: 'entrance',
        display_name: getLocalized(entrance.title, application_language_id),
      })
    );

    _.each(possible_space_allocation.zones, (zone) =>
      space_allocation.push({
        id: zone.id,
        type: 'zone',
        display_name: getLocalized(zone.title, application_language_id),
      })
    );

    _.each(possible_space_allocation.room_groups, (room_group) =>
      space_allocation.push({
        id: room_group.id,
        type: 'room_group',
        display_name:
          room_group?.code +
          ': ' +
          getLocalized(room_group.title, application_language_id),
      })
    );

    return (
      <div className={classNames(styles.scroller, collapsed && styles.collapsed)}>
        <EditCurrentSetPlanForm
          previewUrl={data.preview_url}
          initialValues={{
            ..._.pick(data, [
              'id',
              'code',
              'title',
              'description',
              'tags',
              'status',
            ]),
            scale: _.join(_.map(_.split(data.scale, ':'), _.toNumber), ':'),
            space_allocation: _.map(
              _.filter(space_allocation, (object) => {
                if (
                  object.type == 'site' &&
                  _.includes(data.sites, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'building' &&
                  _.includes(data.buildings, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'storey' &&
                  _.includes(data.storeys, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'expansion_joint' &&
                  _.includes(data.expansion_joints, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'entrance' &&
                  _.includes(data.entrances, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'zone' &&
                  _.includes(data.zones, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'room_group' &&
                  _.includes(data.room_groups, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'room_type' &&
                  _.includes(data.room_types, object.id)
                ) {
                  return true;
                } else if (
                  object.type == 'room' &&
                  _.includes(data.rooms, object.id)
                ) {
                  return true;
                }
              }),
              (space_allocation) => space_allocation.display_name
            ),
          }}
        />
      </div>
    );
  }
}

export default Properties;
