import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/rooms/Specifications.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import {
  __,
  mapStateToProps,
  getLocalized,
  formatMoney,
  redirect,
  getActiveStage,
} from '../../core/utils';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Localization from '../../helpers/Localization';
import { subscribe } from 'react-contextual';
import classNames from 'classnames';
import NumericInput from '../../views/boq/helpers/NumericInput';
import Dropdown from '../../components/Dropdown';

@subscribe(Localization, 'localization')
@withRouter
@mapStateToProps((state) => ({
  language_id: state.auth.language_id,
  store: state.table.rooms,
}))
class Specifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      security_tags: [],
    };
  }

  render() {
    const { store, language_id } = this.props;
    const { flyout } = store;

    const { specification_groups, specification_subgroups, units } =
      this.props.localization;

    return (
      <div className={classNames(styles.wrapper)}>
        <div className={classNames(styles.row, styles.header)}>
          <span className={styles.expand} />
          <span className={styles.code}>
            {__('rooms.properties.specifications.specification-code')}
          </span>
          <span className={styles.name}>
            {__('rooms.properties.specifications.specification-name')}
          </span>
          <span className={styles.quantity}>
            {__('rooms.properties.specifications.quantity')}
          </span>
          <span className={styles.unit}>
            {__('rooms.properties.specifications.unit')}
          </span>
        </div>
        <div className={classNames(styles.row, styles.specification)}>
          <span className={styles.expand}>
            <ArrowDownMiddleIcon className={classNames(styles.active)} />
          </span>
          <span className={styles.code}>Total</span>
          <span className={styles.quantity}>
            {formatMoney(
              _.sumBy(flyout.specifications, (specification) =>
                parseFloat(specification.quantity)
              )
            )}
          </span>
          <span className={styles.unit} />
        </div>
        {_.map(
          _.sortBy(flyout.specifications, (specification) => specification.id),
          (specification, i) => {
            const specification_subgroup =
              _.get(specification_subgroups, [
                specification.specification_subgroup_id,
              ]) || {};

            const specification_group =
              _.get(
                specification_groups,
                _.get(specification_subgroup, 'specification_group_id')
              ) || {};

            const specification_code =
              getLocalized(
                specification_group.code,
                specification.project_language,
                1
              ) +
              specification_subgroup.code +
              _.padStart(specification.code, 2, '0');

            const specification_title = _.filter(
              [
                _.get(specification.description, language_id),
                specification.model,
                specification.brand,
              ],
              _.identity
            ).join(' / ');

            return (
              <div
                className={styles.row}
                key={i}
                onClick={(e) => {
                  redirect(
                    '/stages/' +
                      getActiveStage() +
                      '/specifications/current?id=' +
                      specification.id
                  );
                }}
              >
                <span className={styles.expand}>{i + 1}</span>
                <span className={styles.code}>{specification_code}</span>
                <span className={styles.name}>{specification_title}</span>
                <span className={styles.quantity}>
                  {formatMoney(specification.quantity)}
                </span>
                <span className={styles.unit}>
                  <Dropdown
                    leftStyled
                    top={25}
                    right={-15}
                    readOnly
                    wrapperClassName={styles.dropdownWrapper}
                    name={'unit.' + specification.id}
                    header={<div className={styles.dropdownHeader}>Unit</div>}
                  >
                    <div className={styles.dropdown}>
                      {specification.unit_id && (
                        <span>
                          {_.get(units, [specification.unit_id, 'symbol'])}
                        </span>
                      )}
                      {!specification.unit_id && (
                        <span className={styles.none}>--</span>
                      )}
                    </div>
                  </Dropdown>
                </span>
              </div>
            );
          }
        )}

        <div className={styles.assist}>
          {__('rooms.properties.specifications.assist')}
        </div>
      </div>
    );
  }
}

export default Specifications;
