import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddLanguageWizard.scss";
import autobind from "autobind-decorator";
import AddLanguageIcon from "../../assets/images/add-material-60x60.svg";
import {mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import {createLanguage, listLanguages} from "../../redux/actions/table/superadmin/languages";
import AddLanguageForm from "../../forms/superadmin/add_languages_wizard/AddLanguageForm";
import * as _ from "lodash";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_languages"],
}))
class AddLanguageWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;
        
        return validateForm(
            createLanguage(formData).then(({response}) => {
                listLanguages().then(() => {
                    if(edit) {
                        setTableParams('superadmin_languages', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_languages', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                this.props.onClose();

                setNotification("You have successfully added a new language.");
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>Add language</span>
                        <span>Add language to the application</span>
                    </div>
                    <div className={styles.right}>
                        <AddLanguageIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddLanguageForm onSubmit={this._handleSubmit} initialValues={{
                        application_language: 0
                    }} />
                </div>
            </>
        );
    }
}

export default AddLanguageWizard;