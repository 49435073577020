import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {mapStateToProps} from "../../../core/utils";
import {enableAutosave} from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";

@reduxForm({
    form: "superadmin_raw_materials.edit_raw_material",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
class EditRawMaterialForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {handleSubmit, superadmin} = this.props;

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_raw_material} localized={application_languages} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_raw_material} numeric name="priority" component={Input} label="Priority" />
            </Form>
        );
    }
}

export default EditRawMaterialForm;