import store from '../../core/store';

export const approveAccount = (token) => {
  return store.dispatch({
    action: 'companies/APPROVE_ACCOUNT',
    method: 'POST',
    endpoint: '/users/approve_account',
    data: {
      token,
    },
  });
};

export const validateCompanyDetails = (formData) => {
  return store.dispatch({
    action: 'companies/VALIDATE_COMPANY_DETAILS',
    method: 'POST',
    endpoint: '/companies/my/details',
    data: formData,
  });
};

export const validateCompanyOffices = (formData) => {
  return store.dispatch({
    action: 'companies/VALIDATE_COMPANY_OFFICES',
    method: 'POST',
    endpoint: '/companies/my/offices',
    data: formData,
  });
};

export const createCompany = (formData) => {
  return store.dispatch({
    action: 'companies/CREATE_COMPANY',
    method: 'POST',
    endpoint: '/companies/my',
    data: formData,
  });
};

export const listMyCompanyTags = (context) => {
  return store.dispatch({
    action: 'companies/LIST_MY_COMPANY_TAGS',
    method: 'POST',
    endpoint: '/companies/my/tags',
    data: {
      context,
    },
  });
};

export const listMyCompanyColors = (all = 1) => {
  return store.dispatch({
    action: 'companies/LIST_MY_COMPANY_COLORS',
    method: 'GET',
    endpoint: `/companies/my/colors?all=${all}`,
  });
};

export const listMyCompanyMaterials = (all = 1) => {
  return store.dispatch({
    action: 'companies/LIST_MY_COMPANY_MATERIALS',
    method: 'GET',
    endpoint: `/companies/my/materials?all=${all}`,
  });
};

export const listMyCompanyBrands = () => {
  return store.dispatch({
    action: 'companies/LIST_MY_COMPANY_BRANDS',
    method: 'GET',
    endpoint: '/companies/my/brands',
  });
};

export const listPublicCompanies = () => {
  return store.dispatch({
    action: 'companies/LIST_PUBLIC_COMPANIES',
    method: 'GET',
    endpoint: '/companies/public',
  });
};

export const createCompanyMaterial = (formData) => {
  return store.dispatch({
    action: 'companies/CREATE_COMPANY_MATERIAL',
    method: 'POST',
    endpoint: '/companies/my/materials',
    data: formData,
  });
};

export const createCompanyColor = (formData) => {
  return store.dispatch({
    action: 'companies/CREATE_COMPANY_COLOR',
    method: 'POST',
    endpoint: '/companies/my/colors',
    data: formData,
  });
};
