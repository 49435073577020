import moment from 'moment';

export const generateDigestHours = () => {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    const hour = moment(i + ':00', 'h:00').format('HH:mm');
    hours.push({ value: hour, label: hour });
  }

  return hours;
};
