import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddProductGroupWizard.scss";
import autobind from "autobind-decorator";
import AddProductGroupIcon from "../../assets/images/add-functional-space-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createProductGroup, listProductGroups } from "../../redux/actions/table/superadmin/product_groups";
import AddProductGroupForm from "../../forms/superadmin/add_product_groups_wizard/AddProductGroupForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_product_groups"],
}))
class AddProductGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createProductGroup(formData).then(({ response }) => {
				listProductGroups().then(() => {
					if (edit) {
						setTableParams('superadmin_product_groups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_product_groups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new product group.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add product group</span>
						<span>Add product group to system product groups</span>
					</div>
					<div className={styles.right}>
						<AddProductGroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddProductGroupForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddProductGroupWizard;