import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/SpecificationApprovalWizard.scss";
import autobind from "autobind-decorator";
import {getActiveProject, getLocalized, mapStateToProps, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import * as _ from "lodash";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../helpers/Localization";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import ThumbUpIcon from "../assets/images/thumb-up-60x60.svg";
import ThumbDownIcon from "../assets/images/thumb-down-60x60.svg";
import {withRouter} from "react-router";
import SpecificationApprovalForm from "../forms/specification_approval_wizard/SpecificationApprovalForm";
import {readProjectGeneral} from "../redux/actions/projects";
import {
    approveSpecifications,
    listSpecificationApprovals,
    rejectSpecifications
} from "../redux/actions/table/specification_approvals";

@mapStateToProps(state => ({
    table: state.table["specification_approvals"],
}))
@withRouter
@subscribe(Localization, "localization")
class SpecificationApprovalWizard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            project_language: null
        }
    }

    componentDidMount() {
        readProjectGeneral(getActiveProject()).then(({response}) => {
            this.setState({project_language: response.data.main_language});
        });
    }

    @autobind
    _handleSubmit(formData) {
        const {approve} = this.props.table;

        return validateForm(
            approve ? approveSpecifications({
                specifications: this.props.table.selected_specifications,
                message: _.get(formData, 'message'),
            }).then(() => {
                listSpecificationApprovals(this.props.match.params.stage, this.props.match.params.status);

                setNotification("The specifications you have just approved will appear as a new revision in your Current Specifications Set.\nAll project members will be notified about the updated specifications.");

                this.props.onClose();
            }) : rejectSpecifications({
                specifications: this.props.table.selected_specifications,
                message: _.get(formData, 'message'),
            }).then(() => {
                listSpecificationApprovals(this.props.match.params.stage, this.props.match.params.status);

                setNotification("The specifications you have just rejected won't leave the working specifications set. Instead, they will be granted new 'Rejected' status.\nAll users involved will be notified about the rejected specifications.");

                this.props.onClose();
            })
        );
    }

    @autobind
    _removeSpecification(id) {
        this.props.actions.setTableParams({
            selected_specifications: _.reject(this.props.table.selected_specifications, specification => specification == id)
        });
    }

    render() {
        const {approve} = this.props.table;
        const {project_language} = this.state;
        const {specification_groups, specification_subgroups} = this.props.localization;

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{approve ? "Approve Specifications" : "Reject Specifications"}</span>
                        {approve ? (
                            <span>You have selected specifications to approve.<br />Do you really want to approve those specifications?</span>
                        ) : (
                            <span>You have selected specifications to reject.<br />Do you really want to reject those specifications?</span>
                        )}
                    </div>
                    <div className={styles.right}>
                        {approve ? <ThumbUpIcon /> : <ThumbDownIcon />}
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.scroller}>
                        {_.map(_.filter(this.props.table.data, specification => _.includes(this.props.table.selected_specifications, specification.id)), (specification, i) => {
                            const specification_subgroup = _.get(specification_subgroups, [specification.specification_subgroup_id]) || {};
                            const specification_group = _.get(specification_groups, _.get(specification_subgroup, 'specification_group_id')) || {};

                            const specification_code = getLocalized(specification_group.code, project_language) + specification_subgroup.code + _.padStart(specification.code, 2, "0");
                            const specification_title = _.filter([_.get(specification.description, project_language), specification.model, specification.brand], _.identity).join(' / ');

                            return (
                                <div className={styles.specification} key={i}>
                                    <div>
                                        <strong>{specification_code}:</strong> {specification_title}
                                    </div>
                                    <div>
                                        {_.size(this.props.table.selected_specifications) > 1 && <CloseSmallIcon onClick={() => this._removeSpecification(specification.id)} />}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <SpecificationApprovalForm onSubmit={this._handleSubmit} />
                </div>
            </>
        );
    }
}

export default SpecificationApprovalWizard;
