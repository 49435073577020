import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/create_meeting_from_tasks_wizard/CreateMeetingFromTasksForm.scss";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {__} from "../../core/utils";
import Select from "../../components/Select";
import {withRouter} from "react-router";
import ButtonGroup from "../../components/ButtonGroup";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import {listExistingMeetings} from "../../redux/actions/table/all_meetings";
import classNames from 'classnames';
import * as _ from "lodash";
import autobind from "autobind-decorator";

const mapStateToProps = state => {
    return {
        values: getFormValues("create_meeting_from_tasks_form.create_meeting_from_tasks")(state),
        stage_id: _.get(state.table, "all_meetings.stage_id"),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideCreateMeetingFromTasksWizard: () =>
                dispatch({
                    type: "table.all_meetings/SET_TABLE_PARAMS",
                    params: {
                        meeting_from_tasks: undefined
                    }
                })
        }
    };
};

@withRouter
@subscribe(Localization, "localization")
@reduxForm({form: "create_meeting_from_tasks_form.create_meeting_from_tasks"})
@connect(mapStateToProps, mapDispatchToProps)
class CreateMeetingFromTasksForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            existing_meetings: [],
        }
    }

    componentDidMount() {
        listExistingMeetings(this.props.match.params.stage || this.props.stage_id).then(({response}) => {
            this.setState({existing_meetings: response.data});
        });
    }

    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const existing_meetings = _.map(this.state.existing_meetings, meeting => ({
            label: 'M-' + _.padStart(meeting.identifier, 4, "0") + ': ' + meeting.title,
            value: meeting.id
        }));

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <div className={styles.chooser}>
                        <div className={classNames(styles.option, this.props.values.new_meeting && styles.selected)} onClick={() => this.props.change('new_meeting', true)}><span />{__('meetings.all-meetings.create-meeting-from-tasks.new-meeting')}</div>
                        <div className={classNames(styles.option, !this.props.values.new_meeting && styles.selected)} onClick={() => this.props.change('new_meeting', false)}><span />{__('meetings.all-meetings.create-meeting-from-tasks.add-to-existing-meeting')}</div>
                    </div>
                    <div className={styles.selector}>
                        {!this.props.values.new_meeting && (
                            <Field name="meeting_id" component={Select} noSort searchable label={__('tasks.all-tasks.flyout.add-to-existing-meeting.existing-meeting')} options={existing_meetings} />
                        )}
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideCreateMeetingFromTasksWizard} />
                    <ButtonGroup>
                        {this.props.values.new_meeting && <Button lightBlue medium submit middleText={__('button.next')} spinning={this.props.submitting} />}
                        {!this.props.values.new_meeting && (
                            <>
                                <Button lightBlue medium middleText={__('button.add')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                                <Button lightBlue medium middleText={__('button.add-go-to-meeting')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                            </>
                        )}
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default CreateMeetingFromTasksForm;
