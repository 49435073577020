import store from "../../../core/store";

export const showOverlay = () => {
    return store.dispatch({
        type: "general.overlay/SHOW_OVERLAY",
    });
};

export const hideOverlay = () => {
    return store.dispatch({
        type: "general.overlay/HIDE_OVERLAY",
    });
};