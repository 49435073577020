import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import * as _ from "lodash";
import {createSidebar} from "../../redux/actions/general/sidebar";
import styles from "../../styles/views/specifications/MySpecificationDeliveries.scss";
import {
    enableProjectsDropdown,
    enableStagesDropdown,
    listActiveModules
} from "../../redux/actions/general/active_modules";
import {__, dateTimeFrom, getActiveStage, mapStateToProps, redirect, setTableParams} from "../../core/utils";
import Table from "../../components/Table";
import {setNotification} from "../../redux/actions/general/notification";
import SendIcon from "../../assets/images/send-16x16.svg";
import classNames from "classnames";
import {listMyDeliveries, sendDeliveries} from "../../redux/actions/table/my_deliveries";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import Tooltip from "../../components/Tooltip";
import {
    listMySpecificationDeliveries,
    sendSpecificationDeliveries
} from "../../redux/actions/table/my_specification_deliveries";

@mapStateToProps(state => ({
    table: state.table["my_specification_deliveries"],
}))
class MySpecificationDeliveries extends Component {
    componentDidMount() {
        enableProjectsDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/specification_deliveries/my'));
        enableStagesDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/specification_deliveries/my'));

        createSidebar({
            title: __('submenu.title.specifications'),
            items: [
                {
                    title: __("submenu.title.current-set-specifications"),
                    link: () => "/stages/" + getActiveStage() + '/specifications/current',
                }, {
                    title: __("submenu.title.working-set-specifications"),
                    link: () => "/stages/" + getActiveStage() + '/specifications/working',
                }, {
                    title: __("submenu.title.deliveries"),
                    link: () => "/stages/" + getActiveStage() + "/specification_deliveries",
                    active: true,
                    items: [
                        {
                            title: __("submenu.title.deliveries.public"),
                            link: () => "/stages/" + getActiveStage() + "/specification_deliveries",
                        },
                        {
                            title: __("submenu.title.deliveries.my-team"),
                            link: () => "/stages/" + getActiveStage() + "/specification_deliveries/my",
                            active: true,
                        }
                    ]
                }, {
                    title: __("submenu.title.approvals"),
                    link: () => "/stages/" + getActiveStage() + "/specification_approvals",
                }
            ]
        });
    }

    render() {
        const columns = [
            {
                key: "title",
                name: __('specifications.deliveries.table-title.title'),
                required: true,
            }, {
                key: "specifications",
                name: __('specifications.deliveries.table-title.specifications'),
                sortable: false,
                value: (specifications, row) => (
                    <div className={styles.specifications} onClick={() => redirect('/stages/' + this.props.match.params.stage + '/specification_deliveries/my/' + row.id)}>
                        {specifications} <Tooltip text={__('specifications.specification-delivery.view-specifications-in-this-delivery')}><ArrowDoubleRightSmallIcon /></Tooltip>
                    </div>
                ),
                width: 60
            }, {
                key: "discipline",
                name: __('specifications.deliveries.table-title.discipline'),
                value: (discipline, row) => {
                    const prefixed_discipline = [row.discipline.code, row.discipline.id == null ? (row.discipline.description + '*') : _.get(this.props.localization.disciplines, [row.discipline.id, 'name'])].join(': ');

                    return <Tooltip text={prefixed_discipline}>{prefixed_discipline}</Tooltip>
                },
                width: 70
            }, {
                key: "team",
                name: __('specifications.deliveries.table-title.team'),
                value: (team) => team.company
            }, {
                key: "created_at",
                name: __('specifications.deliveries.table-title.added'),
                default: true,
                desc: true,
                value: (value) => dateTimeFrom(value),
                width: 105
            }, {
                key: "created_by",
                name: __('specifications.deliveries.table-title.added-by'),
                width: 110
            }, {
                key: "delivered_at",
                name: __('specifications.deliveries.table-title.delivered-at'),
                value: (value) => value && dateTimeFrom(value),
                width: 105
            }, {
                key: "sent",
                name: __('specifications.deliveries.table-title.my-team.sent'),
                value: (sent, row) => row.delivered_at ? (
                    <span className={classNames(styles.sent, styles.yes)}>{__('specifications.specification-delivery.yes')}</span>
                ) : (
                    <span className={styles.sent}>{__('specifications.specification-delivery.no')}</span>
                ),
                width: 60
            }
        ];

        const singleActions = (delivery_id) => {
            const delivered = _.get(_.find(this.props.table.data, {id: delivery_id}), 'delivered_at');
            const specifications = _.get(_.find(this.props.table.data, {id: delivery_id}), 'specifications');

            const actions = delivered ? [] : [
                <Tooltip text={__('specifications.deliveries.send-delivery')} key={_.uniqueId()}><SendIcon
                    onClick={e => {
                        e.stopPropagation();

                        if(specifications == 0) {
                            setNotification({
                                text: __('specifications.deliveries.you-cannot-send-empty-delivery'),
                                type: "warning"
                            });
                        } else {
                            sendSpecificationDeliveries([delivery_id]).then(() => {
                                setNotification(__('specifications.specification-deliveries.selected-delivery-successfully-sent'));
                                listMySpecificationDeliveries(this.props.match.params.stage);

                                setTableParams('my_specification_deliveries', {
                                    actioned_row: undefined
                                });
                            });
                        }
                    }}
                /></Tooltip>
            ];

            // !delivered && actions.push(
            //     <Tooltip text={__('Delete delivery')} key={_.uniqueId()}>
            //         <DeleteIcon
            //             onClick={e => {
            //                 e.stopPropagation();
            //
            //                 showAlertbox({
            //                     color: "red",
            //                     title: __('general.alert.are-you-sure'),
            //                     description: "Do you really want to delete the selected delivery?\nThis process cannot be undone.",
            //                     buttons: [
            //                         {
            //                             color: 'lightGray',
            //                             text: __('general.alert.no-close'),
            //                             onClick: 'close'
            //                         },
            //                         {
            //                             color: 'gray',
            //                             text: __('general.alert.yes-delete'),
            //                             onClick: () => {
            //                                 deleteMyDeliveries([delivery_id]).then(() => {
            //                                     hideAlertbox();
            //
            //                                     setNotification(__("The selected delivery has been deleted."));
            //                                     listMyDeliveries(this.props.match.params.stage);
            //
            //                                     this.props.actions.setTableParams({
            //                                         actioned_row: undefined
            //                                     });
            //                                 });
            //                             },
            //                         }
            //                     ]
            //                 })
            //             }}
            //         />
            //     </Tooltip>
            // );

            return actions;
        };

        const groupActions = (specification_delivery_ids) => [
            <Tooltip text={__('specifications.specification-deliveries.send-deliveries')} key={_.uniqueId()}>
                <SendIcon
                    onClick={e => {
                        e.stopPropagation();

                        if(_.size(_.filter(this.props.table.data, delivery => _.includes(specification_delivery_ids, delivery.id) && !_.isEmpty(delivery.delivered_at))) >= 1) {
                            setNotification({
                                text: __('specifications.specification-delivery.you-may-send-only-unsent-deliveries'),
                                type: "warning"
                            });
                        } else if(_.size(_.filter(this.props.table.data, delivery => _.includes(specification_delivery_ids, delivery.id) && delivery.specifications == 0)) >= 1) {
                            setNotification({
                                text: __('specifications.specification-delivery.you-cannot-send-empty-deliveries'),
                                type: "warning"
                            });
                        } else {
                            sendSpecificationDeliveries(specification_delivery_ids).then(() => {
                                setNotification(__('specifications.specification-delivery.selected-deliveries-successfully-sent'));
                                listMySpecificationDeliveries(this.props.match.params.stage);

                                setTableParams('my_specification_deliveries', {
                                    selected_rows: []
                                });
                            });
                        }
                    }}
                />
            </Tooltip>,
            // <Tooltip text={__('Delete delivery')} key={_.uniqueId()}>
            //     <DeleteIcon
            //         onClick={e => {
            //             e.stopPropagation();
            //
            //             if(_.size(_.filter(this.props.table.data, delivery => _.includes(delivery_ids, delivery.id) && !_.isEmpty(delivery.delivered_at))) >= 1) {
            //                 setNotification({
            //                     text: __("You may delete only unsent deliveries."),
            //                     type: "warning"
            //                 });
            //             } else {
            //                 showAlertbox({
            //                     color: "red",
            //                     title: __('general.alert.are-you-sure'),
            //                     description: "Do you really want to delete the selected deliveries?\nThis process cannot be undone.",
            //                     buttons: [
            //                         {
            //                             color: 'lightGray',
            //                             text: __('general.alert.no-close'),
            //                             onClick: 'close'
            //                         },
            //                         {
            //                             color: 'gray',
            //                             text: __('general.alert.yes-delete'),
            //                             onClick: () => {
            //                                 deleteMyDeliveries(delivery_ids).then(() => {
            //                                     hideAlertbox();
            //
            //                                     setNotification(__("The selected deliveries have been deleted."));
            //                                     listMySpecificationDeliveries(this.props.match.params.stage);
            //
            //                                     this.props.actions.setTableParams({
            //                                         selected_rows: undefined
            //                                     });
            //                                 });
            //                             },
            //                         }
            //                     ]
            //                 });
            //             }
            //         }}
            //     />
            // </Tooltip>
        ];

        return (
            <Table
                listAction={() => listMySpecificationDeliveries(this.props.match.params.stage)}
                name="my_specification_deliveries"
                title={__('submenu.title.deliveries.my-team')}
                columns={columns}
                singleActions={singleActions}
                groupActions={groupActions}
                onLoad={() => listActiveModules()}
            />
        );
    }
}

export default MySpecificationDeliveries;