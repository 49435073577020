import store from "../../../../core/store";

export const listBoqSupplyTypeGroups = () => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_type_groups/LIST_BOQ_SUPPLY_TYPE_GROUPS",
		method: "GET",
		endpoint: "/superadmin/boq_supply_type_groups",
		main: true
	});
};

export const createBoqSupplyTypeGroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_type_groups/CREATE_BOQ_SUPPLY_TYPE_GROUP",
		method: "POST",
		endpoint: "/superadmin/boq_supply_type_groups",
		data: formData
	});
};

export const deleteBoqSupplyTypeGroups = (boq_supply_type_groups_ids) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_type_groups/DELETE_BOQ_SUPPLY_TYPE_GROUPS",
		method: "DELETE",
		endpoint: "/superadmin/boq_supply_type_groups",
		data: {
			boq_supply_type_groups: boq_supply_type_groups_ids
		}
	});
};

export const readBoqSupplyTypeGroup = (boq_supply_type_groups_id) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_type_groups/READ_BOQ_SUPPLY_TYPE_GROUP",
		method: "GET",
		endpoint: "/superadmin/boq_supply_type_groups/" + boq_supply_type_groups_id,
		has404: true
	});
};

export const updateBoqSupplyTypeGroup = (boq_supply_type_groups_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_boq_supply_type_groups/UPDATE_BOQ_SUPPLY_TYPE_GROUP",
		method: "PUT",
		endpoint: "/superadmin/boq_supply_type_groups/" + boq_supply_type_groups_id,
		data: formData
	});
};