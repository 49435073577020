import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/plan_uploads/PlanUploadsFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import {setNotification} from "../../redux/actions/general/notification";
import {__, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import Tooltip from "../../components/Tooltip";
import {deletePlanUploads, listPlanUploads, publishPlanUploads} from "../../redux/actions/table/plan_uploads";
import SendIcon from "../../assets/images/send-16x16.svg";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import classNames from "classnames";
import Properties from "./Properties";
import Files from "./Files";
import {connect} from "react-redux";
import OCRIcon from "../../assets/images/ocr-16x16.svg";

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.plan_uploads/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(null, mapDispatchToProps)
@withRouter
class PlanUploadsFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    render() {
        const {data, readAction} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>{data.code && <strong>{data.code}: </strong>}{data.title}</span>
                        <CloseBigIcon onClick={() => setTimeout(() => {
                            setTableParams('plan_uploads', {
                            clicked_row: undefined
                        })}, 100)}/>
                    </div>
                    <div className={styles.actions}>
                        {_.get(data, 'status') == '1' && !_.get(data, 'ocr_processing') && <div className={styles.actionWrapper}>
                            <Tooltip text={__('plans.tooltip.icon.plan-publish')}>
                                <SendIcon
                                    onClick={() => {
                                        if(_.isEmpty(data.code)) {
                                            setNotification({
                                                text: __('plans.uploads.notification.missing-number'),
                                                type: "warning"
                                            });
                                        } else if(data.existing == true) {
                                            setNotification({
                                                text: __('plans.uploads.notification.code-existing-disallow-publish'),
                                                type: "warning"
                                            });
                                        } else {
                                            publishPlanUploads([data.id]).then(() => {
                                                setNotification(__('plans.uploads.notification.published'));

                                                listPlanUploads(this.props.match.params.stage);

                                                setTableParams('plan_uploads', {
                                                    clicked_row: undefined
                                                })
                                            });
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>}
                        {_.get(data, 'status') == '1' && !_.get(data, 'ocr_processing') && <div className={styles.actionWrapper}>
                            <Tooltip text={__('plans.tooltip.icon.ocr-plan')} key={_.uniqueId()}><OCRIcon
                                onClick={e => {
                                    e.stopPropagation();

                                    this.props.actions.setTableParams({
                                        ocr_plans_wizard: [data.id]
                                    });
                                }}
                            /></Tooltip>
                        </div>}
                        <div className={styles.actionWrapper}>
                            <Tooltip text={__('plans.plan-upload-list.flyout.delete-plan-upload')} key={_.uniqueId()}>
                                <DeleteIcon
                                    onClick={e => {
                                        e.stopPropagation();

                                        showAlertbox({
                                            color: "red",
                                            title: __('general.alert.are-you-sure'),
                                            description: __('plans.plan-upload-list.want-delete-selected-plan.this-process-cannot-undone'),
                                            buttons: [
                                                {
                                                    color: 'lightGray',
                                                    text: __('general.alert.no-close'),
                                                    onClick: 'close'
                                                },
                                                {
                                                    color: 'gray',
                                                    text: __('general.alert.yes-delete'),
                                                    onClick: () => {
                                                        deletePlanUploads([data.id]).then(() => {
                                                            hideAlertbox();

                                                            setNotification(__('plans.plan-upload-list.selected-plan-upload-deleted'));
                                                            listPlanUploads(this.props.match.params.stage);

                                                            setTableParams('plan_uploads', {
                                                                clicked_row: undefined
                                                            });
                                                        });
                                                    },
                                                }
                                            ]
                                        })
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>{__('plans.plan-uploads.tab.properties')}</span>
                    <span className={classNames(this.state.active == 'files' && styles.active)} onClick={() => this.setState({active: 'files'})}>{__('plans.plan-uploads.tab.files')} {!_.isEmpty(data.files) && (' (' + _.size(data.files) + ')')}</span>
                </div>
                {this.state.active == 'properties' && <Properties data={data} readAction={readAction} />}
                {this.state.active == 'files' && <Files data={data} readAction={readAction} />}
            </div>
        );
    }
}

export default PlanUploadsFlyout;
