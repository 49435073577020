const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "table.current_set_specifications/SET_TABLE_PARAMS":
            return {
                ...state,
                ...action.params
            };
        case "table.current_set_specifications/LIST_CURRENT_SET_SPECIFICATIONS_SUCCESS":
            return {
                ...state,
                data: action.response.data,
                filters: action.response.filters,
                ...action.response.pagination
            };
        default:
            return state;
    }
}
