import store from "../../../core/store";

export const listStoreys = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_STOREYS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/storeys",
        main: true,
    });
};

export const createStorey = (stage_id, formData) => {
    return store.dispatch({
        action: "table.storeys/CREATE_STOREY",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/storeys",
        data: formData
    });
};

export const readStorey = (storey_id) => {
    return store.dispatch({
        action: "table.storeys/READ_STOREY",
        method: "GET",
        endpoint: "/storeys/" + storey_id,
        has404: true
    });
};

export const updateStorey = (storey_id, formData) => {
    return store.dispatch({
        action: "table.storeys/UPDATE_STOREY",
        method: "PUT",
        endpoint: "/storeys/" + storey_id,
        data: formData
    });
};

export const deleteStoreys = (storey_ids) => {
    return store.dispatch({
        action: "table.storeys/DELETE_STOREYS",
        method: "DELETE",
        endpoint: "/storeys",
        data: {
            storeys: storey_ids
        }
    });
};

export const listPossibleBuildings = (stage_id) => {
    return store.dispatch({
        action: "table.storeys/LIST_POSSIBLE_BUILDINGS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/possible_buildings",
    });
};