import React, { Component } from 'react';
import styles from '../styles/components/MultiSelect.scss';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import ClickOutside from 'react-click-outside';
import autobind from 'autobind-decorator';
import CheckedIcon from '../assets/images/checked-15x15.svg';
import UncheckedIcon from '../assets/images/unchecked-15x15.svg';
import SearchIcon from '../assets/images/search-15x15.svg';
import InfoIcon from '../assets/images/info-positive-16x16.svg';
import { __, mapStateToProps } from '../core/utils';
import * as _ from 'lodash';
import {
  registerMultieditField,
  toggleMultieditClearAll,
  unregisterMultieditField,
} from '../redux/actions/general/multiedit';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import PlusMiddleIcon from '../assets/images/plus-middle-15x15.svg';
import OutsideClickWrapper from './OutsideClickWrapper';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import FieldComponent from './FieldComponent';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { isMobileOnly } from 'react-device-detect';
import Tooltip from './Tooltip';

@FieldComponent
@mapStateToProps((state) => ({
  multiedit: state.general.multiedit,
}))
class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.meta.active != this.props.meta.active) {
      this.setState(
        {
          query: '',
          selected: this.props.input.value || [],
        },
        () => {
          this.props.recalculateOffset();
        }
      );
    }

    if (
      prevState.selected != this.state.selected ||
      prevState.query != this.state.query
    ) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.props.input.onFocus();
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _handleSave() {
    this.props.input.onChange(this.state.selected);

    this._handleClose();
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _handleKeyDown(e) {
    if (e.keyCode == 27) {
      e.stopPropagation();
      e.preventDefault();

      this._handleClose();
    }

    const { query, selected } = this.state;

    const available_options = _.filter(
      this.props.options,
      ({ value }) => !_.includes(selected, value)
    );

    const results = _.filter(
      available_options,
      (item) =>
        _.isEmpty(this.state.query) ||
        _.includes(item.label.toLowerCase(), this.state.query.toLowerCase()) ||
        (item.group &&
          _.includes(item.group.toLowerCase(), this.state.query.toLowerCase()))
    );

    if (e.keyCode == 13) {
      e.stopPropagation();
      e.preventDefault();

      !_.isEmpty(query) &&
        !_.isEmpty(results) &&
        !_.first(results).disabled &&
        this._toggleValue(_.first(results).value, true);
    }
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    this.refs.search.focus();
  }

  @autobind
  _toggleValue(value, highlight_query = false) {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );

    multiedit_enabled &&
      registerMultieditField(this.props.meta.form, this.props.input.name);

    this.setState(
      {
        selected: _.xor(this.state.selected, [value]),
      },
      () => {
        highlight_query && this.refs.search && this.refs.search.select();
      }
    );
  }

  render() {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );
    const multiedited =
      multiedit_enabled &&
      _.includes(
        _.get(this.props.multiedit, [this.props.meta.form, 'fields']),
        this.props.input.name
      );
    const multiedit_clear_existing =
      multiedit_enabled &&
      _.includes(
        _.get(this.props.multiedit, [this.props.meta.form, 'reset']),
        this.props.input.name
      );

    const {
      noSort,
      searchable,
      disabled,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
    } = this.props;

    const { query, selected } = this.state;

    const selected_options = _.filter(this.props.options, ({ value }) =>
      _.includes(selected, value)
    );
    const available_options = _.filter(
      this.props.options,
      ({ value }) => !_.includes(selected, value)
    );

    const results = _.filter(
      available_options,
      (item) =>
        _.isEmpty(this.state.query) ||
        _.includes(item.label.toLowerCase(), this.state.query.toLowerCase()) ||
        (item.group &&
          _.includes(item.group.toLowerCase(), this.state.query.toLowerCase()))
    );
    let selected_filtered_results = noSort
      ? selected_options
      : _.sortBy(selected_options, (item) => item.label.toLowerCase());
    let available_filtered_results = noSort
      ? results
      : _.sortBy(results, (item) => item.label.toLowerCase());

    return (
      <div
        className={classNames(
          styles.wrapper,
          _.isEmpty(this.props.input.value) && styles.placeholder,
          this.props.meta.active && styles.focus,
          this.props.center && styles.center,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          this.props.disabled && styles.disabled,
          multiedit_enabled &&
            !multiedited &&
            !multiedit_clear_existing &&
            styles.noChange
        )}
      >
        <div className={styles.label}>
          <label onClick={this._handleOpen}>
            <Tooltip text={this.props.labelTooltip || this.props.label}>
              {this.props.label}
            </Tooltip>
          </label>
          {multiedit_enabled && (multiedited || multiedit_clear_existing) && (
            <span
              className={styles.labelRevertChanges}
              onClick={() =>
                unregisterMultieditField(
                  this.props.meta.form,
                  this.props.input.name
                )
              }
            >
              {__('button.revert-changes')}
            </span>
          )}
        </div>
        <div
          className={styles.selectGroup}
          ref={elementRef}
          onClick={this._handleOpen}
        >
          <div className={styles.select}>
            {_.size(this.props.input.value) == 0 && this.props.placeholder && (
              <span className={styles.placeholder}>
                {this.props.placeholder}
              </span>
            )}

            {!_.isEmpty(this.props.input.value)
              ? _.map(
                  _.filter(this.props.options, (item) =>
                    _.includes(_.get(this.props.input, 'value', []), item.value)
                  ),
                  'label'
                ).join(', ')
              : multiedit_enabled
              ? multiedited || multiedit_clear_existing
                ? ''
                : '-- no change --'
              : ''}
          </div>
          {!this.props.center && !this.props.disabled && (
            <ArrowDownMiddleIcon />
          )}
        </div>
        {this.props.meta.active && (
          <OutsideClickWrapper closable={false}>
            <KeyboardEventHandler
              handleKeys={['esc']}
              onKeyEvent={this._handleClose}
            />
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              {!_.isEmpty(selected_filtered_results) && (
                <div className={styles.selected}>
                  <div className={styles.title}>
                    {__(
                      'project-list.all-projects.create-new-project.currently-selected'
                    )}{' '}
                    ({_.size(selected_filtered_results)})
                  </div>
                  <div className={styles.list}>
                    {_.map(selected_filtered_results, (item, i) => (
                      <Tooltip placement='left' text={item.label} key={i}>
                        <div
                          className={classNames(
                            styles.row,
                            item.disabled && styles.itemDisabled
                          )}
                          onClick={() =>
                            !item.disabled && this._toggleValue(item.value)
                          }
                        >
                          <span>{item.label}</span>
                          {!disabled && !item.disabled && (
                            <CloseSmallIcon className={styles.close} />
                          )}
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
              {!_.isEmpty(available_options) && (
                <div className={styles.available}>
                  <div className={styles.title}>
                    {__(
                      'project-list.all-projects.create-new-project.available-options'
                    )}{' '}
                    ({_.size(available_options)})
                  </div>
                  {searchable && (
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        ref='search'
                        type='text'
                        value={query}
                        autoFocus={true}
                        placeholder={__('multiselect.title.search')}
                        onChange={this._handleSearch}
                        onKeyDown={this._handleKeyDown}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                  )}
                  <div className={styles.list}>
                    {_.map(
                      _.groupBy(
                        _.filter(available_filtered_results, 'group'),
                        'group'
                      ),
                      (items, group) => (
                        <div className={styles.group} key={group}>
                          <div className={styles.heading}>{group}</div>
                          {_.map(items, (item, i) => (
                            <Tooltip
                              placement='left'
                              text={item.tooltip || item.label}
                              key={i}
                            >
                              <div
                                className={classNames(
                                  styles.row,
                                  item.disabled && styles.itemDisabled
                                )}
                                onClick={() =>
                                  !item.disabled &&
                                  this._toggleValue(item.value, true)
                                }
                              >
                                <span>{item.label}</span>
                                <PlusMiddleIcon className={styles.plus} />
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      )
                    )}
                    {_.map(
                      _.reject(available_filtered_results, 'group'),
                      (item, i) => (
                        <Tooltip
                          placement='left'
                          text={item.tooltip || item.label}
                          key={i}
                        >
                          <div
                            className={classNames(
                              styles.row,
                              item.disabled && styles.itemDisabled
                            )}
                            onClick={() =>
                              !item.disabled &&
                              this._toggleValue(item.value, true)
                            }
                          >
                            <span>{item.label}</span>
                            <PlusMiddleIcon className={styles.plus} />
                          </div>
                        </Tooltip>
                      )
                    )}
                    {_.isEmpty(available_filtered_results) && (
                      <div className={styles.noResults}>No results found.</div>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.footer}>
                <ButtonGroup right>
                  <Button
                    lightGray
                    medium
                    middleText={__('button.cancel')}
                    onClick={this._handleClose}
                  />
                  <Button
                    lightBlue
                    medium
                    middleText={__('button.done')}
                    onClick={this._handleSave}
                  />
                </ButtonGroup>
              </div>
            </div>
          </OutsideClickWrapper>
        )}
        <div className={styles.bar} />
        {multiedit_enabled && (
          <div
            className={styles.clearExisting}
            onClick={() =>
              toggleMultieditClearAll(
                this.props.meta.form,
                this.props.input.name
              )
            }
          >
            {!multiedit_clear_existing && (
              <UncheckedIcon className={styles.unchecked} />
            )}
            {multiedit_clear_existing && (
              <CheckedIcon className={styles.checked} />
            )}
            {__('component.multiselect.clear-all-existing-options')}
          </div>
        )}
        {this.props.center && (
          <div className={styles.arrow}>
            <ArrowDownMiddleIcon />
          </div>
        )}
        {!this.props.center && (
          <div
            className={classNames(
              styles.assist,
              ((_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched')) ||
                this.props.hint) &&
                styles.hint
            )}
          >
            {((_.get(this.props, 'meta.error') &&
              _.get(this.props, 'meta.touched')) ||
              this.props.hint) && (
              <span>
                {_.get(this.props, 'meta.error.0') || this.props.hint}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default MultiSelect;
