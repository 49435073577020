import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/PlanApprovalWizard.scss";
import autobind from "autobind-decorator";
import {__, validateForm} from "../core/utils";
import {connect} from "react-redux";
import {setNotification} from "../redux/actions/general/notification";
import * as _ from "lodash";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../helpers/Localization";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import ThumbUpIcon from "../assets/images/thumb-up-60x60.svg";
import ThumbDownIcon from "../assets/images/thumb-down-60x60.svg";
import PlanApprovalForm from "../forms/plan_approval_wizard/PlanApprovalForm";
import {approvePlanVersions, listPlanApprovals, rejectPlanVersions} from "../redux/actions/table/plan_approvals";
import {withRouter} from "react-router";

const mapStateToProps = state => {
    return {
        table: state.table["plan_approvals"],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.plan_approvals/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
@subscribe(Localization, "localization")
class PlanApprovalWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {approve} = this.props.table;

        return validateForm(
            approve ? approvePlanVersions({
                plan_versions: this.props.table.selected_plan_versions,
                message: _.get(formData, 'message'),
            }).then(() => {
                listPlanApprovals(this.props.match.params.stage, this.props.match.params.status);

                setNotification(__("wizard.plan-approval.notification.message1"));

                this.props.onClose();
            }) : rejectPlanVersions({
                plan_versions: this.props.table.selected_plan_versions,
                message: _.get(formData, 'message'),
            }).then(() => {
                listPlanApprovals(this.props.match.params.stage, this.props.match.params.status);

                setNotification(__("wizard.plan-approval.notification.message2"));

                this.props.onClose();
            })
        );
    }

    @autobind
    _removePlan(id) {
        this.props.actions.setTableParams({
            selected_plan_versions: _.reject(this.props.table.selected_plan_versions, plan => plan == id)
        });
    }

    render() {
        const {approve} = this.props.table;

        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{approve ? __('wizard.plan-approval.title.approve-plans') : __('wizard.plan-approval.title.reject-plans')}</span>
                        {approve ? (
                            <span>{__('wizard.plan-approval.message.approve-plans')}</span>
                        ) : (
                            <span>{__('wizard.plan-approval.message.reject-plans')}</span>
                        )}
                    </div>
                    <div className={styles.right}>
                        {approve ? <ThumbUpIcon /> : <ThumbDownIcon />}
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.scroller}>
                        {_.map(_.filter(this.props.table.data, plan => _.includes(this.props.table.selected_plan_versions, plan.id)), (plan, i) => (
                            <div className={styles.plan} key={i}>
                                <div>
                                    <strong>{plan.code}:</strong> {plan.title}
                                </div>
                                <div>
                                    {plan.discipline.id == null ? (plan.discipline.description + '*') : _.get(this.props.localization.disciplines, [plan.discipline.id, 'name'])} &middot; {plan.team.company} {_.size(this.props.table.selected_plan_versions) > 1 && <CloseSmallIcon onClick={() => this._removePlan(plan.id)} />}
                                </div>
                            </div>
                        ))}
                    </div>
                    <PlanApprovalForm onSubmit={this._handleSubmit} />
                </div>
            </>
        );
    }
}

export default PlanApprovalWizard;
