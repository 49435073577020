import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CreateSpecificationFromWSBOQWizard.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import autobind from 'autobind-decorator';
import { setNotification } from '../redux/actions/general/notification';
import { readProjectGeneral } from '../redux/actions/projects';
import {
  createSpecification,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  updateSpecificationProjectNote,
} from '../redux/actions/table/working_set_specifications';
import AddSpecificationIcon from '../assets/images/add-specification-60x60.svg';
import CreateSpecificationFromWSBOQForm from '../forms/create_specification_from_ws_boq_wizard/CreateSpecificationFromWSBOQForm';
import {
  addSpecificationCodeToCalculation,
  readWorkingSetBOQ,
} from '../redux/actions/table/working_set_boq';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
  calculation_id: _.get(state.table, 'working_set_boq.calculation_id'),
  description: _.get(state.table, 'working_set_boq.description'),
  unit_id: _.get(state.table, 'working_set_boq.unit_id'),
  project_note: _.get(state.table, 'working_set_boq.project_note'),
}))
class CreateSpecificationFromWSBOQWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_language: null,
    };
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      this.setState({ project_language: response.data.main_language });
    });
  }

  @autobind
  _handleCreateSpecification(formData) {
    const { edit } = formData;
    const { project_note, store, calculation_id, onClose } = this.props;

    if (store.create_action) {
      formData = _.omit(formData, ['edit']);
    } else {
      formData = _.omit(formData, ['action', 'edit']);
    }

    if (store.linked_specification_code_id) {
      formData.linked_specification_code_id =
        store.linked_specification_code_id;
    }

    formData.calculation_id = calculation_id;

    return validateForm(
      createSpecification(getActiveStage(), formData).then(({ response }) => {
        setNotification(
          __('specifications.add-new.successfully-added-new-specification')
        );

        if (edit) {
          setTableParams('working_set_specifications', {
            clicked_row: response.data.id,
          });
        } else if (store.linked_specification_code_id) {
          readWorkingSetSpecification(store.flyout.id).then(({ response }) => {
            setTableParams('working_set_specifications', {
              flyout: response.data,
            });
          });
        }

        updateSpecificationProjectNote(response.data.id, {
          project_note,
        }).then(() =>
          addSpecificationCodeToCalculation(calculation_id, {
            specification_code_id: response.data.specification_code_id,
          }).then(() =>
            readWorkingSetBOQ(getActiveStage()).then(() => {
              setNotification(
                'A specification has been successfully added to the selected calculation.'
              );

              onClose();
            })
          )
        );
      })
    );
  }

  render() {
    const { project_language } = this.state;
    const { unit_id, description } = this.props;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'specifications.working-set.flyout.add-new.convert-to-specification'
              )}
            </span>
            <span>
              {__(
                'specifications.working-set.flyout.add-new.create-new-specification-in-your-workspace'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddSpecificationIcon />
          </div>
        </div>
        <div className={styles.body}>
          <CreateSpecificationFromWSBOQForm
            onSubmit={this._handleCreateSpecification}
            projectLanguage={project_language}
            initialValues={{
              custom: '0',
              action: 'option_main',
              description,
              unit_id,
            }}
          />
        </div>
      </>
    );
  }
}

export default CreateSpecificationFromWSBOQWizard;
