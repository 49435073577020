import * as React from "react";
import {Component} from "react";
import {Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/company_info_wizard/DisciplinesForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import SearchIcon from "../../assets/images/search-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import classNames from "classnames";
import {__} from "../../core/utils";
import autobind from "autobind-decorator";
import {hideCreateCompanyWizard} from "../../redux/actions/profile";
import ButtonGroup from "../../components/ButtonGroup";

@subscribe(Localization, "localization")
class DisciplinesForm extends Component {
    @autobind
	_addDiscipline(id) {
		const disciplines = [..._.get(this.props, "values.disciplines", []), id];

		this.props.change("disciplines", _.uniqBy(disciplines));
	}

    @autobind
	_removeDiscipline(id) {
		const disciplines = _.filter(_.get(this.props, "values.disciplines", []), item => item != id);

		this.props.change("disciplines", _.uniqBy(disciplines));
	}

    @autobind
	_handleSearch(e) {
		this.setState({ query: e.target.value });
	}

	render() {
		const available_disciplines = _.sortBy(this.props.localization.disciplines, 'priority');

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.content}>
					<div className={styles.left}>
						<div className={styles.title}>{__('Disciplines')}</div>
						<div className={styles.search}>
							<input type="text" placeholder={__('topbar.tooltip.disciplines.search')} onChange={this._handleSearch} />
							<SearchIcon />
						</div>
						<div className={styles.disciplines}>
							{_.map(
								_.filter(
                                    available_disciplines,
									item =>
										!_.includes(_.get(this.props, "values.disciplines", []), item.id) &&
										(!_.get(this.state, "query") || _.includes(item.name.toLowerCase(), _.get(this.state, "query", "").toLowerCase()))
								),
								item => (
									<span key={item.id} onClick={() => this._addDiscipline(item.id)}>
										{item.name}
										<ArrowRightMiddleIcon />
									</span>
								)
							)}
						</div>
					</div>
					<div className={classNames(styles.right, this.props.submitFailed && _.isEmpty(_.get(this.props, "values.disciplines")) && styles.error)}>
						<div className={styles.title}>{this.props.submitFailed && _.isEmpty(_.get(this.props, "values.disciplines")) ? __("Company Disciplines Required") : __("My Company Disciplines")}</div>
						<div className={styles.disciplines}>
							{this.props.submitFailed && _.isEmpty(_.get(this.props, "values.disciplines")) && <div className={styles.message}>{__('To continue, please choose at lease one discipline')}</div>}
							{_.map(_.get(this.props, "values.disciplines", []), id => (
								<span key={id} onClick={() => this._removeDiscipline(id)}>
									<ArrowLeftMiddleIcon />
									{_.find(available_disciplines, item => item.id == id).name}
								</span>
							))}
						</div>
					</div>
				</div>
				<div className={styles.footer}>
					<Button lightGray medium left middleText={__('button.cancel')} onClick={hideCreateCompanyWizard} />
					<ButtonGroup right>
						<Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
						<Button submit medium lightBlue leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

function mapStateToProps(state) {
	return {
		values: getFormValues("company_info_wizard.disciplines")(state)
	};
}

export default reduxForm({
	form: "company_info_wizard.disciplines",
	destroyOnUnmount: false
})(
	connect(
		mapStateToProps,
		null
	)(DisciplinesForm)
);
