import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import * as _ from "lodash";
import {get} from "lodash";
import Table from "../../components/Table";
import {__, dateTimeFrom, getLocalizedListValue} from "../../core/utils";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {readPartnerCompany} from "../../redux/reducers/companies";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {listPartnerCompanies, removePartnerCompanies} from "../../redux/actions/table/partner_companies";
import Tooltip from "../../components/Tooltip";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import {setNotification} from "../../redux/actions/general/notification";
import DeactivateIcon from "../../assets/images/deactivate-15x15.svg";
import PartnerCompaniesFlyout from "../../flyouts/partner_companies/PartnerCompaniesFlyout";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import TableNA from "../../components/TableNA";

const mapStateToProps = state => {
	return {
		table: state.table["partner_companies"],
		auth: state.auth
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: {
			...bindActionCreators(
				{
					readPartnerCompany,
				},
				dispatch
			),
			setTableParams: params =>
				dispatch({
					type: "table.partner_companies/SET_TABLE_PARAMS",
					params
				})
		}
	};
};
@connect(
	mapStateToProps,
	mapDispatchToProps
)
class PartnerCompanies extends Component {
	componentDidMount() {
		listActiveModules();

        createSidebar({
            title: __('companies-and-users.users.partner-companies.companies-and-users'),
			items: [
				{
					title: __('companies-and-users.users.partner-companies.companies'),
					active: true,
					items: [
						{
							title: __('companies-and-users.users.partner-companies.all-companies'),
							link: () => "/companies",
						},
						{
							title: __('companies-and-users.users.partner-companies.partner-companies'),
							link: () => "/companies/partner",
							active: true,
						},
					]
				}, {
					title: __('companies-and-users.users.partner-companies.users'),
					link: () => "/companies/my/members",
					items: [
						{
							title: __('companies-and-users.users.partner-companies.partner-members'),
							link: () => "/companies/partner_members"
						},
						{
							title: __('companies-and-users.partner-companies.my-company-members'),
							link: () => "/companies/my/members",
						}
					]
				}, {
					title: __('modules.address-book'),
					link: () => "/address_book/companies",
					items: [
						{
							title: __('modules.address-book.companies'),
							link: () => "/address_book/companies",
						},
						{
							title: __('modules.address-book.contacts'),
							link: () => "/address_book/contacts",
						},
					]
				}
			]
        });

		// _.includes(["administrator", "owner"], this.props.auth.role) && setPlusActions([{
        //     title: 'Add Partner Companies',
        //     onClick: () => this.props.actions.setTableParams({
        //         wizard_active: true
        //     })
        // }]);
		//TODO:URGENT - implement this
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			this.props.actions.readPartnerCompany(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const columns = [
			{
				key: "name",
				name: __('partner-companies.column.name'),
				required: true,
			},
			{
				key: "disciplines",
				name: __('partner-companies.column.disciplines'),
				value: disciplines => _.map(disciplines, discipline => getLocalizedListValue(this.props.localization.disciplines, discipline)).join(", "),
				sortable: false
			}, {
				key: "created_at",
				name: __('partner-companies.column.created-at'),
				value: value => value ? dateTimeFrom(value) : <TableNA />,
				width: 120,
				required: true,
				default: true,
				desc: true,
			}
		];

		const preview = (
			<PartnerCompaniesFlyout
				data={_.get(this.state, 'preview', {})}
			/>
		);

		const singleActions = (company_id) => [
			<Tooltip text={__('companies-and-users.users.partner-companies.remove-from-partner-companies')} key={_.uniqueId()}>
				<DeactivateIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: __('companies-and-users.users.partner-companies.remove-selected-company-partner-list.process-cannot-undone'),
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: 'Yes, Remove',
									onClick: () => {
										removePartnerCompanies([company_id]).then(() => {
											hideAlertbox();

											setNotification(__('companies-and-users.users.partner-companies.selected-company-removed-partner-companies'));
											listPartnerCompanies();

											this.props.actions.setTableParams({
												actioned_row: undefined
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const groupActions = (company_ids) => [
			<Tooltip text={__('companies-and-users.users.partner-companies.remove-from-partner-companies')} key={_.uniqueId()}>
				<DeactivateIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: __('companies-and-users.users.partner-companies.remove-selected-companies-partner-list.process-cannot-undone'),
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: 'Yes, Remove',
									onClick: () => {
										removePartnerCompanies(company_ids).then(() => {
											hideAlertbox();

											setNotification(__('companies-and-users.users.partner-companies.selected-companies-removed-partner-companies'));
											listPartnerCompanies();

											this.props.actions.setTableParams({
												selected_rows: undefined
											});
										});
									},
								}
							]
						});
					}}
				/>
			</Tooltip>
		];

        const gridOptions = {
            section: __('companies-and-users.partner-companies.companies'),
            image: (id, row) => row.logo_url,
            title: (row) => row.name,
            subTitle: (row) => [], //todo
            content: (row) => [] //TODO
        };

        return (
            <Table
				listAction={listPartnerCompanies}
				name="partner_companies"
				title={__('companies-and-users.users.partner-companies.partner-companies')}
				columns={columns}
				singleActions={singleActions}
				groupActions={groupActions}
				preview={get(this.state, "preview") ? preview : []}
                gridOptions={gridOptions}
                defaultView="grid"
			/>
        );
	}
}

export default PartnerCompanies;
