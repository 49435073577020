import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AttachTaskToPlanWizard.scss';
import autobind from 'autobind-decorator';
import AddTaskIcon from '../assets/images/add-task-60x60.svg';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import AddTaskForm from '../forms/add_task_wizard/AddTaskForm';
import {
  attachTaskToPlanRevision,
  attachTaskToPlanVersion,
  createPlanRevisionTask,
  createPlanVersionTask,
  listPossiblePlanRevisionTasks,
  listPossiblePlanVersionTasks,
  listPossibleTaskAssignee,
  listPossibleTaskFollowers,
} from '../redux/actions/table/all_tasks';
import { withRouter } from 'react-router';
import { getStagesLanguages } from '../redux/actions/table/stages';
import * as _ from 'lodash';
import classNames from 'classnames';
import Button from '../components/Button';
import ButtonGroup from '../components/ButtonGroup';
import AddExistingTaskForm from '../forms/add_existing_task_form/AddExistingTaskForm';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';

@withRouter
@mapStateToProps((state) => ({
  table: state.table['all_tasks'],
}))
@subscribe(Localization, 'localization')
class AttachTaskToPlanWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_task: true,
      mode: false,
      possible_task_assignee: [],
      possible_task_followers: [],
    };
  }

  componentDidMount() {
    const { disciplines } = this.props.localization;

    listPossibleTaskAssignee(this.props.table.stage_id).then(({ response }) => {
      this.setState({
        possible_task_assignee: _.map(response.data, (data) => {
          return data.type === 'discipline'
            ? {
                ...data,
                name: `${[
                  data.company_name,
                  [
                    data.stage_discipline.code,
                    data.stage_discipline.discipline_id == null
                      ? data.stage_discipline.description + '*'
                      : _.get(disciplines, [
                          data.stage_discipline.discipline_id,
                          'name',
                        ]),
                  ].join(': '),
                ].join(' | ')}`,
              }
            : data;
        }),
      });
    });

    listPossibleTaskFollowers(this.props.table.stage_id).then(
      ({ response }) => {
        this.setState({
          possible_task_followers: _.map(response.data, (data) => {
            return data.type === 'discipline'
              ? {
                  ...data,
                  name: `${[
                    data.company_name,
                    [
                      data.stage_discipline.code,
                      data.stage_discipline.discipline_id == null
                        ? data.stage_discipline.description + '*'
                        : _.get(disciplines, [
                            data.stage_discipline.discipline_id,
                            'name',
                          ]),
                    ].join(': '),
                  ].join(' | ')}`,
                }
              : data;
          }),
        });
      }
    );

    getStagesLanguages(this.props.table.stage_id).then(({ response }) => {
      this.setState({ project_languages: response.data });
    });

    if (this.props.match.path == '/plans/versions/:plan_version') {
      listPossiblePlanVersionTasks(this.props.match.params.plan_version).then(
        ({ response }) => {
          this.setState({ possible_tasks: response.data });
        }
      );
    }

    if (this.props.match.path == '/plans/revisions/:plan_revision') {
      listPossiblePlanRevisionTasks(this.props.match.params.plan_revision).then(
        ({ response }) => {
          this.setState({ possible_tasks: response.data });
        }
      );
    }
  }

  @autobind
  _handleSubmit(formData) {
    if (this.state.new_task) {
      formData = {
        ..._.omit(formData, ['edit']),
        assignee: _.map(formData.assignee, 'id'),
        followers: _.map(formData.followers, 'id'),
      };

      if (this.props.match.path == '/plans/revisions/:plan_revision') {
        return validateForm(
          createPlanRevisionTask(this.props.match.params.plan_revision, {
            ...formData,
            x: this.props.table.x,
            y: this.props.table.y,
          }).then(({ response }) => {
            setNotification(__('wizard.add-task.confirm'));

            setTableParams('current_set_plans', {
              task_id: response.data.id,
              x: this.props.table.x,
              y: this.props.table.y,
            });

            this.props.onClose();
          })
        );
      }

      if (this.props.match.path == '/plans/versions/:plan_version') {
        return validateForm(
          createPlanVersionTask(this.props.match.params.plan_version, {
            ...formData,
            x: this.props.table.x,
            y: this.props.table.y,
          }).then(({ response }) => {
            setNotification(__('wizard.add-task.confirm'));

            setTableParams('working_set_plans', {
              task_id: response.data.id,
              x: this.props.table.x,
              y: this.props.table.y,
            });

            this.props.onClose();
          })
        );
      }
    } else {
      if (this.props.match.path == '/plans/revisions/:plan_revision') {
        return validateForm(
          attachTaskToPlanRevision(this.props.match.params.plan_revision, {
            ...formData,
            x: this.props.table.x,
            y: this.props.table.y,
          }).then(({ response }) => {
            setNotification(__('wizard.attach-existing-task.confirm'));

            setTableParams('current_set_plans', {
              task_id: response.data.id,
              x: this.props.table.x,
              y: this.props.table.y,
            });

            this.props.onClose();
          })
        );
      }

      if (this.props.match.path == '/plans/versions/:plan_version') {
        return validateForm(
          attachTaskToPlanVersion(this.props.match.params.plan_version, {
            ...formData,
            x: this.props.table.x,
            y: this.props.table.y,
          }).then(({ response }) => {
            setNotification(__('wizard.attach-existing-task.confirm'));

            setTableParams('working_set_plans', {
              task_id: response.data.id,
              x: this.props.table.x,
              y: this.props.table.y,
            });

            this.props.onClose();
          })
        );
      }
    }
  }

  render() {
    const { new_task, next } = this.state;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('wizard.add-task.title.attach-task')}</span>
            <span>{__('wizard.add-task.subtitle.attach-task')}</span>
          </div>
          <div className={styles.right}>
            <AddTaskIcon />
          </div>
        </div>
        <div className={styles.body}>
          {!next && (
            <div className={styles.form}>
              <div className={styles.chooser}>
                <div
                  className={classNames(
                    styles.option,
                    new_task === true && styles.selected
                  )}
                  onClick={() => this.setState({ new_task: true })}
                >
                  <span />
                  Create New Task
                </div>
                <div
                  className={classNames(
                    styles.option,
                    new_task === false && styles.selected
                  )}
                  onClick={() => this.setState({ new_task: false })}
                >
                  <span />
                  Choose Existing Task
                </div>
              </div>
              <div className={styles.footer}>
                <Button
                  lightGray
                  medium
                  left
                  middleText={__('button.cancel')}
                  onClick={() =>
                    setTableParams('all_tasks', {
                      attach_task_wizard: undefined,
                    })
                  }
                />
                <ButtonGroup>
                  <Button
                    lightBlue
                    medium
                    middleText={__('button.next')}
                    onClick={() => this.setState({ next: true })}
                  />
                </ButtonGroup>
              </div>
            </div>
          )}
          {next && new_task && this.state.project_languages && (
            <AddTaskForm
              possibleTaskAssignee={this.state.possible_task_assignee}
              possibleTaskFollowers={this.state.possible_task_followers}
              projectLanguages={this.state.project_languages}
              onSubmit={this._handleSubmit}
              initialValues={{
                status: 'not-started',
                priority: 'normal',
                visibility: '1', //TODO - fetch from project preferences !!!
                type: 'todo',
                description: {},
              }}
            />
          )}
          {next && !new_task && this.state.possible_tasks && (
            <AddExistingTaskForm
              onSubmit={this._handleSubmit}
              possibleTasks={this.state.possible_tasks}
            />
          )}
        </div>
      </>
    );
  }
}

export default AttachTaskToPlanWizard;
