import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/working_set_plans/Versions.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ReactDropzone from "react-dropzone";
import DownloadIcon from "../../assets/images/download-15x15.svg";
import UploadIcon from "../../assets/images/upload-40x40.svg";
import { __, dateTimeFrom, validateForm } from "../../core/utils";
import {
  downloadPlanVersion,
  removeNewPlanVersionUpload,
  uploadNewPlanVersion
} from "../../redux/actions/table/working_set_plans";
import autobind from "autobind-decorator";
import { setNotification } from "../../redux/actions/general/notification";
import { connect } from "react-redux";
import Tooltip from "../../components/Tooltip";

const mapStateToProps = (state, props) => {
  return {
    upload: _.get(state.table["working_set_plans"], ['uploads', props.data.id]),
    auth: state.auth
  };
};

@connect(mapStateToProps)
class Versions extends Component {
  @autobind
  _handleDrop(acceptedFiles, rejectedFiles) {
    this.setState({ drag: false });

    if (!_.isEmpty(acceptedFiles)) {
      if ((_.size(acceptedFiles) + _.size(rejectedFiles)) > 1) {
        setNotification({
          text: __('plans.working-set.notification.only-one-pdf'),
          type: "warning"
        });
      } else if (_.first(acceptedFiles).size >= 104857600) {
        setNotification({
          text: __('plans.working-set.notification.upload-limit'),
          type: "warning"
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('plans.working-set.notification.upload-filetype-error'),
          type: "warning"
        });
      } else {
        setNotification(__('plans.working-set.notification.uploading'));

        validateForm(uploadNewPlanVersion(this.props.data.id, _.first(acceptedFiles)).then(() => {
          this.props.readAction(this.props.data.id);
          removeNewPlanVersionUpload(this.props.data.id)
        })).catch(({ errors }) => {
          if (_.has(errors, "code")) {
            if (errors.code === 1) {
              setNotification({
                type: "warning",
                text: __('plans.working-set.notification.upload-one-page-error')
              });
            }

            if (errors.code === 2) {
              setNotification({
                type: "warning",
                text: __('plans.working-set.notification.upload-page-dimensions-error')
              });
            }
          }

          removeNewPlanVersionUpload(this.props.data.id);
        });
      }
    }
  }

  render() {
    const { data, upload, auth, collapsed } = this.props;

    return (
      <>
        <div className={classNames(styles.table, collapsed && styles.collapsed)}>
          <div className={styles.header}>
            <div className={classNames(styles.version, (!_.isEmpty(_.filter(data.versions, { processed: 0 })) || upload) && styles.withStatus)}>Ver</div>
            <div>{__('plans.current-set.tab.revisions.uploaded-by')}</div>
            <div>{__('plans.current-set.tab.revisions.uploaded-at')}</div>
            <div className={styles.download} />
          </div>
          <div className={styles.rows}>
            {_.map(data.versions, (version, i) => (
              <div className={styles.row} key={i}>
                <div className={classNames(styles.version, (!_.isEmpty(_.filter(data.versions, { processed: 0 })) || upload) && styles.withStatus)}>
                  {'V' + version.number}
                  {!version.processed && <div className={styles.status}>
                    <span className={styles.statusBg} style={{ width: "100%" }} />
                    <span className={styles.statusText}>{__('plans.status.processing-plan')}</span>
                  </div>}
                </div>
                <div>{version.created_by}</div>
                <div>{dateTimeFrom(version.created_at)}</div>
                <Tooltip text={__('plans.tooltip.icon.working-set.download-version')}><div className={styles.download} onClick={() => downloadPlanVersion(version.id)}><DownloadIcon /></div></Tooltip>
              </div>
            ))}
            {upload && (
              <div className={styles.row}>
                <div className={classNames(styles.version, upload && styles.withStatus)}>
                  {'V' + (_.last(data.versions).number + 1)}
                  <div className={styles.status}>
                    <span className={styles.statusBg} style={{ width: upload + "%" }} />
                    <span className={styles.statusText}>{__('plans.working-set.notification.uploading')}</span>
                  </div>
                </div>
                <div>{auth.firstname + ' ' + auth.lastname}</div>
                <div />
                <div className={styles.download} />
              </div>
            )}
          </div>
        </div>
        {!collapsed && _.get(data, 'is_editor') && _.get(data, 'status') == '1' && <Tooltip text={__('plans.tooltip.icon.working-set.upload-version')}><ReactDropzone
          accept="application/pdf"
          className={classNames(styles.dropzone, _.get(this.state, "drag") && styles.drag, upload && styles.hidden)}
          onDrop={this._handleDrop}
          onDragOver={() => this.setState({ drag: true })}
          onDragLeave={() => this.setState({ drag: false })}
          multiple={false}
        >
          <UploadIcon />
          <span className={styles.dropTitle}>{__('dropzone.title.drop-your-file-here')}</span>
          <span className={styles.dropSubtitle}>{__('dropzone.title.or-select')}</span>
        </ReactDropzone></Tooltip>}
      </>
    );
  }
}

export default Versions;
