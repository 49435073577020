import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/components/TableListView.scss';
import { connect } from 'react-redux';
import ViewListIcon from '../assets/images/view-list-15x15.svg';
import FilterIcon from '../assets/images/filter-15x15.svg';
import UnpinBigIcon from '../assets/images/unpin-big-16x16.svg';
import PinBigIcon from '../assets/images/pin-big-16x16.svg';
import PinSmallIcon from '../assets/images/pin-small-16x16.svg';
import ArrowRightMiddleIcon from '../assets/images/arrow-right-middle-15x15.svg';
import ArrowLeftMiddleIcon from '../assets/images/arrow-left-middle-15x15.svg';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import ArrowDoubleRightMiddleIcon from '../assets/images/arrow-double-right-middle-15x15.svg';
import ItemProductIcon from '../assets/images/item-product-40x40.svg';
import ArrowDoubleLeftMiddleIcon from '../assets/images/arrow-double-left-middle-15x15.svg';
import UncheckedIcon from '../assets/images/unchecked-15x15.svg';
import { FixedSizeList as List } from 'react-window';
import CheckedIcon from '../assets/images/checked-15x15.svg';
import ColumnSelectorIcon from '../assets/images/column-selector-16x16.svg';
import MenuHIcon from '../assets/images/menu-h-15x15.svg';
import { isFirefox } from 'react-device-detect';
import MenuVIcon from '../assets/images/menu-v-15x15.svg';
import SortArrowMedium from '../assets/images/sort-arrow-medium-15x15.svg';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import classNames from 'classnames';
import Dropdown from './Dropdown';
import NeutralIcon from '../assets/images/neutral-15x15.svg';
import { hideDropdown, showDropdown } from '../redux/actions/general/dropdown';
import SortAscIcon from '../assets/images/sort-asc-16x16.svg';
import SortDescIcon from '../assets/images/sort-desc-16x16.svg';
import SortAscendingIcon from '../assets/images/sort-ascending-15x15.svg';
import SortDescendingIcon from '../assets/images/sort-descending-15x15.svg';
import ClickOutside from 'react-click-outside';
import Cleave from 'cleave.js/react';
import CloseBigIcon from '../assets/images/close-big-15x15.svg';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import { __, getURLParam, redirect, setURLParam } from '../core/utils';
import ViewThumbnailsIcon from '../assets/images/view-thumbnails-15x15.svg';
import Tooltip from '../components/Tooltip';
import DateTimePicker from './DateTimePicker';
import DatetimeIcon from '../assets/images/datetime-15x15.svg';
import SearchIcon from '../assets/images/search-15x15.svg';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import TableNA from './TableNA';
import ReactHoverObserver from 'react-hover-observer';
import AutoSizer from 'react-virtualized-auto-sizer';
import pluralize from 'pluralize';
import SearchableDropdown from './SearchableDropdown';
import { filter } from 'lodash';
import { updatePreferences } from '../redux/actions/profile';
import Input from './Input';
import Range from './Table/Range';
import ToggleButton from './ToggleButton';

const mapStateToProps = (state, props) => {
  return {
    ...state.table[props.name],
    dropdownName: state.general.dropdown.shown,
    lightbox_active: state.general.lightbox.active,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.' + props.name + '/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class TableListView extends Component {
  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        _.omit(queryString.parse(prevProps.location.search), 'id'),
        _.omit(queryString.parse(this.props.location.search), 'id')
      )
    ) {
      this._mapUrlParamsToProps();
    }

    if (
      this.props.dropdownName == 'column_selector' &&
      this.props.dropdownName != prevProps.dropdownName
    ) {
      this.setState({ disabled_columns: this.props.disabled_columns });
    }

    if (_.isUndefined(prevProps.filters) && this.props.filters) {
      const params = {};

      _.each(this.props.filters, (filter, key) => {
        _.each(filter?.defaultItems, (item, value) => {
          params['filter.' + key] = value;
        });
      });

      this.props.setURLParams(params);
    }
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  _mapUrlParamsToProps() {
    const params = queryString.parse(this.props.location.search);

    this.props.actions.setTableParams(
      _.pick(params, ['page', 'limit', 'sort_by', 'order', 'query'])
    );

    _.each(params, (value, key) => {
      //TODO - try to merge this with the upper keys using pickBy()
      if (_.startsWith(key, 'filter.')) {
        const params = {};
        params[key] = value;

        if (this.props[key] != value) {
          params['page'] = 1;
        }

        this.props.actions.setTableParams(params);
      }
    });

    this.props.listAction();
  }

  _columnValue(row, column) {
    const value = _.get(row, [column.key]);

    return column.value ? column.value(value, row) : value;
  }

  /**
   * Table actions
   */
  @autobind
  _toggleColumn(column) {
    this.setState({
      disabled_columns: _.xor(this.state.disabled_columns, [column]),
    });
  }

  @autobind
  _toggleAllColumns() {
    if (_.isEmpty(this.state.disabled_columns)) {
      this.setState({
        disabled_columns: _.map(
          _.filter(this.props.columns, (column) => !column.required),
          'key'
        ),
      });
    } else {
      this.setState({
        disabled_columns: [],
      });
    }
  }

  @autobind
  _resetColumnsToDefault() {
    this.setState({
      disabled_columns: _.map(
        _.filter(this.props.columns, (column) => column.active === false),
        'key'
      ),
    });
  }

  @autobind
  _toggleRow(row, e) {
    const { selected_rows, data } = this.props;

    e.stopPropagation();

    const { shift_pressed, checkbox_row_hovered } = this.state;

    if (
      !_.includes(selected_rows, row) &&
      !_.isEmpty(selected_rows) &&
      shift_pressed
    ) {
      let shift_hover_range = [];

      if (shift_pressed) {
        shift_hover_range = _.map(
          _.slice(
            data,
            _.findIndex(data, ['id', _.first(selected_rows)]),
            _.findIndex(data, ['id', checkbox_row_hovered]) + 1
          ),
          'id'
        );

        this.props.actions.setTableParams({
          selected_rows: shift_hover_range,
        });
      }
    } else {
      this.props.actions.setTableParams({
        selected_rows: _.xor(selected_rows, [row]),
      });
    }
  }

  @autobind
  _toggleAllRows() {
    if (_.size(this.props.selected_rows) === _.size(this.props.data)) {
      this.props.actions.setTableParams({
        selected_rows: [],
      });
    } else {
      this.props.actions.setTableParams({
        selected_rows: _.map(this.props.data, 'id'),
      });
    }
  }

  @autobind
  _setSort(column, order) {
    if (
      !_.isEmpty(
        _.filter(
          this.props.columns,
          (item) => item.sortable !== false && item.key == column
        )
      )
    ) {
      this.props.setURLParams({
        sort_by: column,
        order:
          order ||
          (this.props.sort_by == column && this.props.order != 'desc'
            ? 'desc'
            : 'asc'),
        page: 1,
        selected_rows: [],
        actioned_row: undefined,
        clicked_row: undefined,
      });
    }
  }

  @autobind
  _setPage(page) {
    if (_.inRange(page, 1, this.props.pages + 1)) {
      this.props.setURLParams({
        page,
        selected_rows: [],
        actioned_row: undefined,
        clicked_row: undefined,
      });

      this.refs.table.scrollTop = 0;
    }
  }

  @autobind
  _setLimit(limit) {
    if (limit > 0) {
      this.props.setURLParams({
        limit: _.min([limit, 2000]),
        page: 1,
        selected_rows: [],
        actioned_row: undefined,
        clicked_row: undefined,
      });
    }
  }

  @autobind
  _handleQuery(e) {
    const query = e.target.value;

    if (this.queryTimeout) clearTimeout(this.queryTimeout);

    this.queryTimeout = setTimeout(() => {
      this.props.setURLParams({
        query,
        page: 1,
        selected_rows: [],
        actioned_row: undefined,
        clicked_row: undefined,
      });
    }, 600);
  }

  @autobind
  _setSearchFocus(focus) {
    this.props.actions.setTableParams({
      search_focus: focus,
    });
  }

  @autobind
  _toggleSearch() {
    this.refs.search.focus();

    this.props.actions.setTableParams({
      search_active: !this.props.search_active,
    });
  }

  @autobind
  _handleCurrentPageOutsideClick() {
    this.props.dropdownName == 'current_page' && hideDropdown();
  }

  @autobind
  _handleCurrentPageKeyDown(e) {
    if (_.includes([13, 27], e.keyCode)) {
      this._handleCurrentPageOutsideClick();
      this._setPage(e.currentTarget.value);
    }
  }

  @autobind
  _handleLimitOutsideClick() {
    this.props.dropdownName == 'limit' && hideDropdown();
  }

  @autobind
  _handleLimitKeyDown(e) {
    if (_.includes([13, 27], e.keyCode)) {
      this._handleLimitOutsideClick();
      this._setLimit(e.currentTarget.value);
    }
  }

  @autobind
  _showRowActions(id, e) {
    this.props.actions.setTableParams({
      actioned_row: this.props.actioned_row != id ? id : undefined,
    });

    e.stopPropagation();
  }

  @autobind
  _clickRow(id, row) {
    if (this.props.onRowClick) {
      this.props.onRowClick(id, row);
    } else if (this.props.preview) {
      this.props.actions.setTableParams({
        clicked_row: this.props.clicked_row != id ? id : undefined,
        showFilters: undefined,
      });
    }
  }

  @autobind
  _toggleFilterFlyout() {
    this.props.actions.setTableParams({
      showFilters: !this.props.showFilters,
    });
  }

  @autobind
  _toggleFilterSection(section) {
    this.props.actions.setTableParams({
      filterExpandedSections: _.xor(this.props.filterExpandedSections, [
        section,
      ]),
    });
  }

  @autobind
  _toggleFilterPin(section, pinned) {
    const pinned_columns = {
      ...this.props.pinned_columns,
      [section]: pinned,
    };
    this.props.actions.setTableParams({ pinned_columns });
    updatePreferences(`table.${this.props.name}`, { pinned_columns });
  }

  @autobind
  _toggleExpandFilterSections() {
    const all_filters = _.reject(
      _.map(this.props.filters, (filter, key) => ({
        key: key,
        type: filter.type,
        items: filter.items,
      })),
      (filter) =>
        filter.type == 'sidebar' ||
        (filter.type == 'select' && _.isEmpty(filter.items))
    );

    this.props.actions.setTableParams({
      filterExpandedSections: _.isEmpty(this.props.filterExpandedSections)
        ? _.map(all_filters, 'key')
        : [],
    });
  }

  @autobind
  _clearFilters() {
    const params = {
      query: '',
    };

    this.props.actions.setTableParams({
      search_active: false,
      search_focus: false,
    });

    this.refs.search.value = '';

    _.each(
      _.pickBy(this.props, (value, key) => _.startsWith(key, 'filter.')),
      (value, key) => {
        params[key] = '';
      }
    );

    _.each(this.props.filters, (filter, key) => {
      _.each(filter?.defaultItems, (item, value) => {
        params['filter.' + key] = value;
      });
    });

    this.props.setURLParams(params);
  }

  @autobind
  _clearSearch() {
    this.props.setURLParams({
      query: '',
    });

    this.props.actions.setTableParams({
      search_active: true,
      search_focus: true,
    });

    this.refs.search.value = '';
    this.refs.search.select();
  }

  @autobind
  _clearFilter(filter) {
    const params = {};

    params['filter.' + filter.key] = _.some(
      _.split(getURLParam('filter.' + filter.key), '|'),
      (item) => _.includes(_.map(filter?.defaultItems, 'value'), item)
    )
      ? [..._.map(filter?.defaultItems, 'value')].join('|')
      : '';

    this.props.setURLParams(params);
  }

  @autobind
  _setView(view) {
    this.props.actions.setTableParams({ view });

    this.props.listAction();
  }

  @autobind
  _handleShortcut(key, e) {
    if (this.props.clicked_row && !this.props.lightbox_active) {
      e.preventDefault();

      const index = _.findIndex(this.props.data, [
        'id',
        this.props.clicked_row,
      ]);

      if (key == 'up' || key == 'left') {
        if (index > 0) {
          this.props.actions.setTableParams({
            clicked_row: _.get(this.props.data, [index - 1, 'id']),
            showFilters: undefined,
          });
        }
      } else if (key == 'down' || key == 'right') {
        if (index < _.size(this.props.data) - 1) {
          this.props.actions.setTableParams({
            clicked_row: _.get(this.props.data, [index + 1, 'id']),
            showFilters: undefined,
          });
        }
      }
    }
  }

  render() {
    let start_page, end_page;

    const total_pages = parseInt(this.props.pages);
    const current_page = parseInt(this.props.page);

    if (total_pages <= 10) {
      start_page = 1;
      end_page = total_pages;
    } else {
      if (current_page <= 6) {
        start_page = 1;
        end_page = 10;
      } else if (current_page + 4 >= total_pages) {
        start_page = total_pages - 9;
        end_page = total_pages;
      } else {
        start_page = current_page - 5;
        end_page = current_page + 4;
      }
    }

    const filters = _.reject(
      _.map(this.props.filters, (filter, key) => {
        return {
          key: key,
          name: filter.name,
          type: filter.type,
          nullable: filter.nullable,
          pinned: _.get(this.props.pinned_columns, key),
          items: _.map(filter.items, (value, key) => {
            return {
              value: key,
              label: value,
            };
          }),
          defaultItems: _.map(filter.defaultItems, (value, key) => {
            return {
              value: key,
              label: value,
            };
          }),
        };
      }),
      (filter) =>
        filter.type == 'sidebar' ||
        (filter.type == 'select' && _.isEmpty(filter.items))
    );

    const applied_filters_num = _.size(
      _.filter(
        filters,
        (filter) =>
          getURLParam('filter.' + filter.key) != '' &&
          !_.every(_.split(getURLParam('filter.' + filter.key), '|'), (item) =>
            _.includes(_.map(filter?.defaultItems, 'value'), item)
          )
      )
    );

    const { footer, selected_rows, data, viewActions, hideSearch } = this.props;
    const { shift_pressed, checkbox_row_hovered } = this.state;

    let shift_hover_range = [];

    if (!_.isEmpty(selected_rows) && shift_pressed) {
      shift_hover_range = _.map(
        _.slice(
          data,
          _.findIndex(data, ['id', _.first(selected_rows)]),
          _.findIndex(data, ['id', checkbox_row_hovered]) + 1
        ),
        'id'
      );
    }

    const Row = ({ index, style }) => {
      const row = this.props.data[index];

      return (
        <div
          key={`table-row-${index}`}
          className={classNames(
            styles.row,
            _.includes(this.props.selected_rows, row.id) && styles.selected,
            this.props.clicked_row == row.id &&
              this.props.preview &&
              styles.clicked,
            (this.props.preview || this.props.onRowClick) && styles.clickable,
            index % 2 && styles.odd
          )}
          style={style}
          onClick={() => this._clickRow(row.id, row)}
        >
          {this.props.groupActions && (
            // <ReactHoverObserver key={index} onMouseOver={() => this.setState({checkbox_row_hovered: row.id})} onMouseLeave={() => this.setState({checkbox_row_hovered: null})} className={classNames(styles.checkbox, _.includes(shift_hover_range, row.id) && styles.shiftHovered)} onClick={e => this._toggleRow(row.id, e)}>
            // 	{(_.includes(this.props.selected_rows, row.id) || _.includes(shift_hover_range, row.id)) ? <CheckedIcon className={classNames(_.includes(this.props.selected_rows, row.id) && styles.checked)} /> : <UncheckedIcon className={styles.unchecked} />}
            // </ReactHoverObserver>
            <div
              key={`group-actions-${index}`}
              className={styles.checkbox}
              onClick={(e) => this._toggleRow(row.id, e)}
            >
              {_.includes(this.props.selected_rows, row.id) ||
              _.includes(shift_hover_range, row.id) ? (
                <CheckedIcon
                  className={classNames(
                    _.includes(this.props.selected_rows, row.id) &&
                      styles.checked
                  )}
                />
              ) : (
                <UncheckedIcon className={styles.unchecked} />
              )}
            </div>
          )}
          {_.map(
            _.filter(
              this.props.columns,
              (column) => !_.includes(this.props.disabled_columns, column.key)
            ),
            (column, i1) => (
              <div
                key={`column-${i1}`}
                className={classNames(column.overflow && styles.overflow)}
                style={column.width && { flex: '0 0 ' + column.width + 'px' }}
              >
                <span
                  className={classNames(column.fullWidth && styles.fullWidth)}
                >
                  {this._columnValue(row, column) || <TableNA />}
                </span>
              </div>
            )
          )}
          {this.props.singleActions &&
            !_.isEmpty(
              _.reject(this.props.singleActions(row.id), _.isEmpty)
            ) && (
              <>
                <Tooltip text={__('content.tooltip.table.rowactions')}>
                  <div
                    className={styles.action}
                    onClick={(e) => this._showRowActions(row.id, e)}
                  >
                    <MenuHIcon
                      className={classNames(
                        this.props.actioned_row == row.id && styles.active
                      )}
                    />
                  </div>
                </Tooltip>
                <div
                  className={classNames(
                    styles.actions,
                    this.props.actioned_row == row.id && styles.active
                  )}
                >
                  {_.map(
                    _.reject(this.props.singleActions(row.id), _.isEmpty),
                    (action, i) => (
                      <div
                        className={styles.actionWrapper}
                        key={`single-action-${index}-${i}`}
                      >
                        {action}
                      </div>
                    )
                  )}
                </div>
              </>
            )}
          {(!this.props.singleActions ||
            _.isEmpty(
              _.reject(this.props.singleActions(row.id), _.isEmpty)
            )) && <div className={styles.emptyPlaceholder} />}
        </div>
      );
    };

    const hasSelectedValuesInFilters =
      _.filter(
        _.keys(this.props),
        (item) =>
          _.startsWith(item, 'filter.') &&
          _.includes(_.map(filters, 'key'), item.substring(7)) &&
          !_.isEmpty(_.get(this.props, item))
      ).length > 0;
    const hasPinnedFilters = _.some(
      _.values(_.get(this.props, 'pinned_columns')),
      (pinned_column) => pinned_column
    );
    const hasQueryFilter = !_.isEmpty(this.props.query);

    return (
      <div className={styles.wrapper}>
        <KeyboardEventHandler
          handleKeys={['up', 'down', 'left', 'right']}
          onKeyEvent={this._handleShortcut}
        />
        <KeyboardEventHandler
          handleKeys={['shift']}
          onKeyEvent={() => this.setState({ shift_pressed: false })}
          handleEventType='keyup'
        />
        <KeyboardEventHandler
          handleKeys={['shift']}
          onKeyEvent={() => this.setState({ shift_pressed: true })}
          handleEventType='keydown'
        />
        <div className={styles.toolbar}>
          <div className={styles.title}>
            {this.props.title}
            <div
              className={classNames(
                styles.preloader,
                this.props.loading && styles.active
              )}
            >
              <div className={styles.spinner}>
                <div></div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              styles.search,
              this.props.search_focus && styles.focus,
              this.props.search_active && styles.active,
              hideSearch && styles.hideSearch
            )}
          >
            <div className={styles.bar} />
            <input
              ref='search'
              type='text'
              defaultValue={this.props.query}
              placeholder={__('table.search.enter-search-criteria')}
              spellCheck='false'
              onChange={this._handleQuery}
              onClick={() => this.refs.search.select()}
              onBlur={() => this._setSearchFocus(false)}
              onFocus={() => this._setSearchFocus(true)}
            />
            {!_.isEmpty(this.props.query) && (
              <CloseSmallIcon
                className={styles.close}
                onClick={this._clearSearch}
              />
            )}
            {_.isEmpty(this.props.query) && (
              <Tooltip text={__('topbar.tooltip.search')}>
                <SearchIcon onClick={this._toggleSearch} />
              </Tooltip>
            )}
          </div>
          {!_.isEmpty(filters) && (
            <>
              <div className={styles.separator} />
              <Tooltip text={__('content.tooltip.table.filters')}>
                <FilterIcon
                  className={styles.filter}
                  onClick={this._toggleFilterFlyout}
                />
              </Tooltip>
            </>
          )}
          <div className={styles.separator} />
          {viewActions && !_.isEmpty(viewActions) && (
            <>
              <div className={styles.viewActions}>{viewActions}</div>
              <div className={styles.separator} />
            </>
          )}
          {!_.isEmpty(
            _.filter(this.props.columns, (column) => column.sortable !== false)
          ) && (
            <>
              {this.props.order == 'asc' && (
                <SortAscIcon
                  className={styles.sortAsc}
                  onClick={() => this._setSort(this.props.sort_by, 'desc')}
                />
              )}
              {this.props.order == 'desc' && (
                <SortDescIcon
                  className={styles.sortDesc}
                  onClick={() => this._setSort(this.props.sort_by, 'asc')}
                />
              )}
              <Tooltip text={__('content.tooltip.table.sort')}>
                <div className={styles.sort}>
                  <Dropdown
                    top={30}
                    right={-43}
                    name='sort'
                    header={
                      <div className={styles.sortHeader}>
                        {__('table.sort-by')}
                      </div>
                    }
                    content={
                      <div className={styles.sortContent}>
                        {_.map(
                          _.filter(
                            this.props.columns,
                            (column) => column.sortable !== false
                          ),
                          (column, i) => (
                            <div
                              onClick={() =>
                                this._setSort(
                                  column.key,
                                  this.props.sort_by == column.key
                                    ? this.props.order == 'asc'
                                      ? 'desc'
                                      : 'asc'
                                    : 'asc'
                                )
                              }
                              key={i}
                              className={classNames(
                                styles.value,
                                this.props.sort_by == column.key &&
                                  styles.active
                              )}
                            >
                              {column.name}
                              {this.props.order == 'asc' &&
                                this.props.sort_by == column.key && (
                                  <SortAscendingIcon />
                                )}
                              {this.props.order == 'desc' &&
                                this.props.sort_by == column.key && (
                                  <SortDescendingIcon />
                                )}
                            </div>
                          )
                        )}
                      </div>
                    }
                  >
                    <span>
                      {_.get(
                        _.find(this.props.columns, { key: this.props.sort_by }),
                        'name'
                      )}
                    </span>
                  </Dropdown>
                </div>
              </Tooltip>
              <div className={styles.separator} />
            </>
          )}
          <div className={styles.views}>
            <Tooltip text={__('content.tooltip.table.listview')}>
              <ViewListIcon
                className={styles.active}
                onClick={() => this._setView('list')}
              />
            </Tooltip>
            {/*<ViewThumbnailListIcon />*/}
            {this.props.gridOptions ? (
              <Tooltip text={__('content.tooltip.table.gridview')}>
                <ViewThumbnailsIcon onClick={() => this._setView('grid')} />
              </Tooltip>
            ) : (
              <Tooltip
                text={__(
                  'profile-settings.notifications.topbar.tooltip.grid-view-not-available-section'
                )}
                placement='left'
              >
                <ViewThumbnailsIcon className={styles.notAvailable} />
              </Tooltip>
            )}
          </div>
          <div className={styles.separator} />
          {!_.isEmpty(
            _.filter(this.props.columns, (column) => !column.required)
          ) ? (
            <div
              className={classNames(
                styles.columnSelector,
                this.props.dropdownName == 'column_selector' && styles.active
              )}
            >
              <Dropdown
                closable={true}
                top={30}
                right={-18}
                name='column_selector'
                header={
                  <div className={styles.columnSelectorHeader}>
                    <span>{__('table.columns.select-columns')}</span>
                    {!_.isEmpty(this.state.disabled_columns) &&
                      _.size(this.state.disabled_columns) !==
                        _.size(
                          _.filter(
                            this.props.columns,
                            (column) => !column.required
                          )
                        ) && (
                        <NeutralIcon
                          onClick={this._toggleAllColumns}
                          className={styles.neutral}
                        />
                      )}
                    {_.size(this.state.disabled_columns) ===
                      _.size(
                        _.filter(
                          this.props.columns,
                          (column) => !column.required
                        )
                      ) && (
                      <UncheckedIcon
                        onClick={this._toggleAllColumns}
                        className={styles.unchecked}
                      />
                    )}
                    {_.isEmpty(this.state.disabled_columns) && (
                      <CheckedIcon
                        onClick={this._toggleAllColumns}
                        className={styles.checked}
                      />
                    )}
                  </div>
                }
                content={
                  <div className={styles.columnSelectorContent}>
                    {_.map(
                      _.filter(
                        this.props.columns,
                        (column) => !column.required
                      ),
                      (column, i) => (
                        <div
                          onClick={() => this._toggleColumn(column.key)}
                          key={i}
                          className={styles.column}
                        >
                          {column.name}
                          {_.includes(
                            this.state.disabled_columns,
                            column.key
                          ) && <UncheckedIcon className={styles.unchecked} />}
                          {!_.includes(
                            this.state.disabled_columns,
                            column.key
                          ) && <CheckedIcon className={styles.checked} />}
                        </div>
                      )
                    )}
                    <div
                      onClick={this._resetColumnsToDefault}
                      className={styles.column}
                    >
                      {__('table.columns.reset-to-default')}
                    </div>
                    <div className={styles.saveFooter}>
                      <ButtonGroup right>
                        <Button
                          lightGray
                          medium
                          middleText={__('button.cancel')}
                          onClick={() => {
                            hideDropdown();
                          }}
                        />
                        <Button
                          lightBlue
                          medium
                          middleText={__('button.done')}
                          onClick={() => {
                            this.props.actions.setTableParams({
                              disabled_columns: this.state.disabled_columns,
                            });

                            this.props.listAction();

                            hideDropdown();
                          }}
                        />
                      </ButtonGroup>
                    </div>
                  </div>
                }
              >
                <Tooltip text={__('content.tooltip.table.columns')}>
                  <ColumnSelectorIcon />
                </Tooltip>
              </Dropdown>
            </div>
          ) : (
            <Tooltip text='Column selector is currently disabled in this section'>
              <ColumnSelectorIcon className={styles.notAvailable} />
            </Tooltip>
          )}
        </div>
        {(hasQueryFilter || hasSelectedValuesInFilters || hasPinnedFilters) && (
          <div className={styles.filterList}>
            {_.map(
              _.filter(
                filters,
                (filter) =>
                  (_.isEmpty(filter.defaultItems)
                    ? !_.isEmpty(_.get(this.props, 'filter.' + filter.key))
                    : false) || filter.pinned
              ),
              (filter, i) => {
                const filterURLParam = getURLParam('filter.' + filter.key);
                const active_filter_items = _.filter(
                  filterURLParam == 'n/a' ? [] : _.split(filterURLParam, '|'),
                  _.identity
                );

                const clearFilter = (e) => {
                  e.stopPropagation();
                  this._clearFilter(filter);
                };

                const filterHeader = (
                  <div className={styles.filterHeaderWithPin}>
                    <Tooltip
                      text={
                        filter.pinned
                          ? `${__('table.filters.unpin')} ${filter.name}`
                          : `${__('table.filters.pin')} ${filter.name}`
                      }
                    >
                      <div
                        className={styles.filterHeaderPinButton}
                        onClick={() =>
                          this._toggleFilterPin(filter.key, !filter.pinned)
                        }
                      >
                        {filter.pinned ? <UnpinBigIcon /> : <PinBigIcon />}
                      </div>
                    </Tooltip>

                    <Tooltip
                      text={filter.name}
                      className={styles.filterHeaderName}
                    >
                      {filter.name}
                    </Tooltip>
                    <div className={styles.filterHeaderActions}>
                      <span
                        className={styles.filterHeaderClearAll}
                        onClick={clearFilter}
                      >
                        {__('table.filters.clear')}
                      </span>
                    </div>
                  </div>
                );

                if (filter.type == 'date_range') {
                  let start, end;
                  const values = filterURLParam.split('|');

                  if (values.length == 2) {
                    start = values[0];
                    end = values[1];
                  }

                  return (
                    <Dropdown
                      key={filter.key}
                      wrapperClassName={styles.filterItem}
                      name={filter.key}
                      header={filterHeader}
                      leftStyled
                      content={
                        <div className={styles.daterange}>
                          {(filterURLParam != '0' || !filter.nullable) && (
                            <div className={styles.datepickers}>
                              <DateTimePicker
                                icon={DatetimeIcon}
                                placeholder={__(
                                  'table.filters.title.select-date-from'
                                )}
                                footer
                                meta={{
                                  active: _.get(
                                    this.state,
                                    'pinned_filter_active_from_' + filter.key,
                                    false
                                  ),
                                }}
                                input={{
                                  value: start,
                                  onFocus: () =>
                                    this.setState({
                                      ['pinned_filter_active_from_' +
                                      filter.key]: true,
                                      ['pinned_filter_active_to_' +
                                      filter.key]: false,
                                    }),
                                  onBlur: () =>
                                    this.setState({
                                      ['pinned_filter_active_from_' +
                                      filter.key]: false,
                                    }),
                                  onChange: (start) =>
                                    setURLParam(
                                      'filter.' + filter.key,
                                      [start, end].join('|')
                                    ),
                                }}
                              />
                              <DateTimePicker
                                icon={DatetimeIcon}
                                placeholder={__(
                                  'table.filters.title.select-date-to'
                                )}
                                footer
                                meta={{
                                  active: _.get(
                                    this.state,
                                    'pinned_filter_active_to_' + filter.key,
                                    false
                                  ),
                                }}
                                input={{
                                  value: end,
                                  onFocus: () =>
                                    this.setState({
                                      ['pinned_filter_active_to_' +
                                      filter.key]: true,
                                      ['pinned_filter_active_from_' +
                                      filter.key]: false,
                                    }),
                                  onBlur: () =>
                                    this.setState({
                                      ['pinned_filter_active_to_' +
                                      filter.key]: false,
                                    }),
                                  onChange: (end) =>
                                    setURLParam(
                                      'filter.' + filter.key,
                                      [start, end].join('|')
                                    ),
                                }}
                              />
                            </div>
                          )}
                          {filter.nullable && (
                            <div className={styles.nullable}>
                              {filterURLParam == '0' ? (
                                <CheckedIcon
                                  className={styles.checked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '')
                                  }
                                />
                              ) : (
                                <UncheckedIcon
                                  className={styles.unchecked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '0')
                                  }
                                />
                              )}
                              <span
                                onClick={() =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    filterURLParam == '0' ? '' : '0'
                                  )
                                }
                              >
                                {filter.nullable}
                              </span>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div
                        className={classNames(
                          styles.bubble,
                          _.trim(values?.[0]) == '' &&
                            _.trim(values?.[1]) == '' &&
                            styles.bubbleEmpty
                        )}
                      >
                        {filter.pinned && (
                          <PinSmallIcon className={styles.filterPinIcon} />
                        )}
                        {filter.name}
                        <ArrowDownSmallIcon />
                      </div>
                    </Dropdown>
                  );
                }

                if (filter.type == 'range') {
                  const values = filterURLParam.split('|');

                  const start = values[0];
                  const end = values[1];

                  return (
                    <Dropdown
                      key={filter.key}
                      wrapperClassName={styles.filterItem}
                      name={filter.key}
                      header={filterHeader}
                      leftStyled
                      content={
                        <>
                          {(filterURLParam != '0' || !filter.nullable) && (
                            <Range
                              nullable={false}
                              value={filterURLParam}
                              onChange={(value) =>
                                setURLParam('filter.' + filter.key, value)
                              }
                            />
                          )}
                          {filter.nullable && (
                            <div className={styles.nullable}>
                              {filterURLParam == '0' ? (
                                <CheckedIcon
                                  className={styles.checked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '')
                                  }
                                />
                              ) : (
                                <UncheckedIcon
                                  className={styles.unchecked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '0')
                                  }
                                />
                              )}
                              <span
                                onClick={() =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    filterURLParam == '0' ? '' : '0'
                                  )
                                }
                              >
                                {filter.nullable}
                              </span>
                            </div>
                          )}
                        </>
                      }
                    >
                      <div
                        className={classNames(
                          styles.bubble,
                          _.trim(start) == '' &&
                            _.trim(end) == '' &&
                            styles.bubbleEmpty
                        )}
                      >
                        {filter.pinned && (
                          <PinSmallIcon className={styles.filterPinIcon} />
                        )}
                        {filter.name}
                        <ArrowDownSmallIcon />
                      </div>
                    </Dropdown>
                  );
                }

                const selectedValues = _.filter(
                  _.split(_.get(this.props, 'filter.' + filter.key), '|'),
                  (filterItem) => {
                    return (
                      !!filterItem &&
                      !!_.find(
                        filter.items,
                        (item) => _.get(item, 'value') == filterItem
                      )
                    );
                  }
                );

                let filterItems = this.props.filtersSort
                  ? _.sortBy(filter.items, (item) => {
                      const sorting_number = this.props.filtersSort(
                        filter.key,
                        item.value,
                        item.label
                      );

                      return _.isUndefined(sorting_number) ? 0 : sorting_number;
                    })
                  : filter.items;

                const defaultItemsFooter = _.map(
                  filter.defaultItems,
                  (defaultItem) => (
                    <div className={styles.defaultItems}>
                      <span
                        onClick={() =>
                          setURLParam('filter.' + filter.key, defaultItem.value)
                        }
                      >
                        <Tooltip placement='left' text={defaultItem.label}>
                          {defaultItem.label}
                        </Tooltip>
                      </span>

                      <ToggleButton
                        onToggle={() => {
                          const checked = _.includes(
                            active_filter_items,
                            _.toString(defaultItem.value)
                          );

                          if (checked) {
                            setURLParam(
                              'filter.' + filter.key,
                              _.reject(
                                active_filter_items,
                                (row) => row == defaultItem.value
                              ).join('|')
                            );
                          } else {
                            setURLParam(
                              'filter.' + filter.key,
                              [...active_filter_items, defaultItem.value].join(
                                '|'
                              )
                            );
                          }
                        }}
                        value={_.includes(
                          active_filter_items,
                          _.toString(defaultItem.value)
                        )}
                      />
                    </div>
                  )
                );

                return (
                  <SearchableDropdown
                    key={filter.key}
                    dropdownWrapperClassName={styles.filterItem}
                    name={filter.key}
                    header={filterHeader}
                    footer={defaultItemsFooter}
                    options={filterItems}
                    onSelect={(item) => {
                      setURLParam(
                        'filter.' + filter.key,
                        [...active_filter_items, item.value].join('|')
                      );
                    }}
                    onRemove={(item) => {
                      setURLParam(
                        'filter.' + filter.key,
                        _.reject(
                          active_filter_items,
                          (row) => row == item.value
                        ).join('|')
                      );
                    }}
                    selectedValues={selectedValues}
                    multiselect
                    leftStyled
                  >
                    <div
                      className={classNames(
                        styles.bubble,
                        _.size(selectedValues) === 0 && styles.bubbleEmpty
                      )}
                    >
                      {filter.pinned && (
                        <PinSmallIcon className={styles.filterPinIcon} />
                      )}
                      {filter.name}
                      {_.size(selectedValues) > 0 && (
                        <strong className={styles.filterCount}>
                          ({_.size(selectedValues)})
                        </strong>
                      )}
                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                );
              }
            )}
            {hasQueryFilter && (
              <div
                onClick={this._clearSearch}
                className={classNames(styles.bubble)}
              >
                {__('table.filters.search')}: {this.props.query}{' '}
                <CloseSmallIcon />
              </div>
            )}

            {(hasSelectedValuesInFilters || hasQueryFilter) && (
              <div
                onClick={this._clearFilters}
                className={classNames(styles.bubble, styles.gray)}
              >
                {__('table.filters.clear-all')}
              </div>
            )}
          </div>
        )}
        <div
          className={classNames(
            styles.header,
            _.isEmpty(this.props.data) && styles.disabled
          )}
          style={{ paddingRight: isFirefox ? '50px' : undefined }}
        >
          {this.props.groupActions && (
            <Tooltip text={__('content.tooltip.table.selectall')}>
              <div className={styles.checkbox} onClick={this._toggleAllRows}>
                {_.isEmpty(this.props.selected_rows) && (
                  <UncheckedIcon className={styles.unchecked} />
                )}
                {_.size(this.props.selected_rows) === _.size(this.props.data) &&
                  !_.isEmpty(this.props.data) && (
                    <CheckedIcon className={styles.checked} />
                  )}
                {!_.isEmpty(this.props.selected_rows) &&
                  _.size(this.props.selected_rows) !==
                    _.size(this.props.data) && (
                    <NeutralIcon className={styles.neutral} />
                  )}
              </div>
            </Tooltip>
          )}
          {_.map(
            _.filter(
              this.props.columns,
              (column) => !_.includes(this.props.disabled_columns, column.key)
            ),
            (column, i) => (
              <div
                key={i}
                className={classNames(
                  column.sortable !== false && styles.sortable,
                  !_.isEmpty(this.props.selected_rows) && styles.hidden
                )}
                style={column.width && { flex: '0 0 ' + column.width + 'px' }}
                onClick={() => this._setSort(column.key)}
              >
                <Tooltip text={column.name}>
                  <span>{column.name}</span>
                </Tooltip>{' '}
                {this.props.sort_by == column.key && (
                  <SortArrowMedium
                    className={
                      this.props.order == 'asc' ? styles.asc : styles.desc
                    }
                  />
                )}
              </div>
            )
          )}
          {!_.isEmpty(this.props.selected_rows) && (
            <div className={styles.groupActions}>
              <div className={styles.actions}>
                {this.props.groupActions(this.props.selected_rows)}
              </div>
              <MenuVIcon className={styles.dots} />
            </div>
          )}
        </div>
        {_.isEmpty(this.props.data) && !_.isUndefined(this.props.data) && (
          <div className={styles.noItems}>
            <ItemProductIcon />
            <div className={styles.text}>{__('table-grid-view.no-items')}</div>
            <span>
              {__(
                'table-grid-view.not-created-items-this-section-or-too-many-filters-applied-no-items'
              )}
            </span>
          </div>
        )}
        {!_.isEmpty(this.props.data) && (
          <div
            className={classNames(
              styles.content,
              shift_pressed && styles.disableUserSelect
            )}
            ref='table'
          >
            <AutoSizer>
              {({ height, width }) => (
                <List
                  className={styles.scroller}
                  height={height}
                  itemCount={_.size(this.props.data)}
                  itemSize={34}
                  width={width}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </div>
        )}
        {footer && <div className={styles.footer}>{footer}</div>}
        {!_.isEmpty(this.props.data) && !_.isUndefined(this.props.pages) && (
          <div className={styles.footer}>
            <div className={styles.left}>
              <div className={styles.totalPages}>
                <span>{__('table.pager.pages')}:</span>
                <span>{this.props.pages}</span>
              </div>
              <div
                className={styles.currentPage}
                onClick={() => showDropdown('current_page')}
              >
                <ClickOutside
                  onClickOutside={this._handleCurrentPageOutsideClick}
                  className={classNames(
                    styles.dropout,
                    this.props.dropdownName == 'current_page' && styles.active
                  )}
                >
                  {this.props.dropdownName == 'current_page' && (
                    <Cleave
                      onBlur={(e) => this._setPage(e.currentTarget.value)}
                      options={{ numericOnly: true }}
                      onKeyDown={this._handleCurrentPageKeyDown}
                      maxLength={4}
                      autoFocus={true}
                    />
                  )}
                </ClickOutside>
                <span>{__('table.pager.current-page')}:</span>
                <span>
                  {this.props.page}
                  <ArrowDownSmallIcon />
                </span>
              </div>
            </div>
            <div className={styles.pagination}>
              <Tooltip text={__('content.tooltip.table.first-page')}>
                <ArrowDoubleLeftMiddleIcon onClick={() => this._setPage(1)} />
              </Tooltip>
              <Tooltip text={__('content.tooltip.table.prev-page')}>
                <ArrowLeftMiddleIcon
                  onClick={() => this._setPage(_.max([1, current_page - 1]))}
                />
              </Tooltip>
              {_.map(_.range(start_page, end_page + 1), (page) => (
                <span
                  onClick={() => this._setPage(page)}
                  key={page}
                  className={classNames(
                    styles.page,
                    this.props.page == page && styles.active
                  )}
                >
                  {_.padStart(page, 2, '0')}
                </span>
              ))}
              <Tooltip text={__('content.tooltip.table.next-page')}>
                <ArrowRightMiddleIcon
                  onClick={() =>
                    this._setPage(_.min([total_pages, current_page + 1]))
                  }
                />
              </Tooltip>
              <Tooltip text={__('content.tooltip.table.last-page')}>
                <ArrowDoubleRightMiddleIcon
                  onClick={() => this._setPage(total_pages)}
                />
              </Tooltip>
            </div>
            <div className={styles.right}>
              {!_.isUndefined(this.props.total) && (
                <div className={styles.totalResults}>
                  <span>{__('table.pager.total-items')}:</span>
                  <span>{this.props.total}</span>
                </div>
              )}
              <div
                className={styles.limit}
                onClick={() => showDropdown('limit')}
              >
                <ClickOutside
                  onClickOutside={this._handleLimitOutsideClick}
                  className={classNames(
                    styles.dropout,
                    this.props.dropdownName == 'limit' && styles.active
                  )}
                >
                  {this.props.dropdownName == 'limit' && (
                    <Cleave
                      onBlur={(e) => this._setLimit(e.currentTarget.value)}
                      options={{ numericOnly: true }}
                      onKeyDown={this._handleLimitKeyDown}
                      maxLength={4}
                      autoFocus={true}
                    />
                  )}
                </ClickOutside>
                <span>{__('table.pager.items-per-page')}:</span>
                <span>
                  {this.props.limit}
                  <ArrowDownSmallIcon />
                </span>
              </div>
            </div>
          </div>
        )}
        {filters && (
          <div
            className={classNames(
              styles.filtersflyout,
              this.props.showFilters && styles.active
            )}
          >
            <div className={styles.heading}>
              {__('table.filters.title.filters')}
              <CloseBigIcon
                onClick={() => {
                  this.props.actions.setTableParams({
                    showFilters: undefined,
                  });

                  this.setState({
                    filtersQuery: {},
                  });
                }}
              />
            </div>
            <div className={styles.filters}>
              <div
                className={classNames(
                  styles.expandAll,
                  !_.isEmpty(this.props.filterExpandedSections) &&
                    styles.expanded
                )}
              >
                <div onClick={this._toggleExpandFilterSections}>
                  <ArrowDownMiddleIcon className={styles.arrowDownIcon} />
                  {applied_filters_num == 0
                    ? __(
                        'space-allocation-beta.table-list-view.no-filters-applied'
                      )
                    : pluralize('filter', applied_filters_num, true) +
                      ' applied'}
                  {_.filter(
                    _.keys(this.props),
                    (item, key) =>
                      _.startsWith(item, 'filter.') &&
                      _.includes(_.map(filters, 'key'), item.substring(7)) &&
                      !_.isEmpty(_.get(this.props, item)) &&
                      _.isEmpty(
                        _.find(
                          filters,
                          (filter) => filter.key == item.substring(7)
                        )?.defaultItems
                      )
                  ).length > 0 && (
                    <div
                      onClick={this._clearFilters}
                      className={classNames(styles.bubble, styles.gray)}
                    >
                      {__('table.filters.clear-all')}
                    </div>
                  )}
                </div>
              </div>
              {_.map(filters, (filter, i) => {
                const filterHeader = (
                  <div className={styles.filterHeader}>
                    <span onClick={() => this._toggleFilterSection(filter.key)}>
                      <ArrowDownMiddleIcon className={styles.arrowDownIcon} />
                      {filter.name}
                    </span>
                    <span
                      onClick={() =>
                        this._toggleFilterPin(filter.key, !filter.pinned)
                      }
                    >
                      <Tooltip
                        text={
                          filter.pinned
                            ? `${__('table.filters.unpin')} ${filter.name}`
                            : `${__('table.filters.pin')} ${filter.name}`
                        }
                      >
                        {filter.pinned ? <UnpinBigIcon /> : <PinBigIcon />}
                      </Tooltip>
                    </span>
                  </div>
                );

                if (filter.type == 'date_range') {
                  let value = getURLParam('filter.' + filter.key);
                  let start, end;

                  if (value.split('|').length == 2) {
                    start = value.split('|')[0];
                    end = value.split('|')[1];
                  }

                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.section,
                        _.includes(
                          this.props.filterExpandedSections,
                          filter.key
                        ) && styles.expanded,
                        getURLParam('filter.' + filter.key) != '' &&
                          styles.active
                      )}
                    >
                      {filterHeader}
                      <div className={styles.daterange}>
                        {(value != '0' || !filter.nullable) && (
                          <div className={styles.datepickers}>
                            <DateTimePicker
                              icon={DatetimeIcon}
                              placeholder={__(
                                'table.filters.title.select-date-from'
                              )}
                              footer
                              meta={{
                                active: _.get(
                                  this.state,
                                  'filter_active_from_' + filter.key,
                                  false
                                ),
                              }}
                              input={{
                                value: start,
                                onFocus: () =>
                                  this.setState({
                                    ['filter_active_from_' + filter.key]: true,
                                    ['filter_active_to_' + filter.key]: false,
                                  }),
                                onBlur: () =>
                                  this.setState({
                                    ['filter_active_from_' + filter.key]: false,
                                  }),
                                onChange: (start) =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    [start, end].join('|')
                                  ),
                              }}
                            />
                            <DateTimePicker
                              icon={DatetimeIcon}
                              placeholder={__(
                                'table.filters.title.select-date-to'
                              )}
                              footer
                              meta={{
                                active: _.get(
                                  this.state,
                                  'filter_active_to_' + filter.key,
                                  false
                                ),
                              }}
                              input={{
                                value: end,
                                onFocus: () =>
                                  this.setState({
                                    ['filter_active_to_' + filter.key]: true,
                                    ['filter_active_from_' + filter.key]: false,
                                  }),
                                onBlur: () =>
                                  this.setState({
                                    ['filter_active_to_' + filter.key]: false,
                                  }),
                                onChange: (end) =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    [start, end].join('|')
                                  ),
                              }}
                            />
                          </div>
                        )}
                        {filter.nullable && (
                          <div className={styles.nullable}>
                            {value == '0' ? (
                              <CheckedIcon
                                className={styles.checked}
                                onClick={() =>
                                  setURLParam('filter.' + filter.key, '')
                                }
                              />
                            ) : (
                              <UncheckedIcon
                                className={styles.unchecked}
                                onClick={() =>
                                  setURLParam('filter.' + filter.key, '0')
                                }
                              />
                            )}
                            <span
                              onClick={() =>
                                setURLParam(
                                  'filter.' + filter.key,
                                  value == '0' ? '' : '0'
                                )
                              }
                            >
                              {filter.nullable}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (filter.type == 'select') {
                  const { filtersQuery } = this.state;

                  let active_filter_items = _.filter(
                    getURLParam('filter.' + filter.key) == 'n/a'
                      ? []
                      : _.split(getURLParam('filter.' + filter.key), '|'),
                    _.identity
                  );

                  const non_filtered_items = _.sortBy(
                    _.sortBy(filter.items, 'label'),
                    (row) => (row.value == '0' ? 0 : 1)
                  );

                  let filter_items = non_filtered_items;

                  if (_.size(_.get(filtersQuery, filter.key)) > 0) {
                    filter_items = _.filter(filter_items, (item) =>
                      _.includes(
                        _.toLower(item.label),
                        _.toLower(_.get(filtersQuery, filter.key))
                      )
                    );
                  }

                  active_filter_items = _.map(active_filter_items, (item) =>
                    encodeURIComponent(item)
                  );
                  filter_items = _.map(filter_items, (item) => ({
                    ...item,
                    value: encodeURIComponent(item.value),
                  }));

                  if (this.props.filtersSort) {
                    filter_items = _.sortBy(filter_items, (item) => {
                      const sorting_number = this.props.filtersSort(
                        filter.key,
                        item.value,
                        item.label
                      );

                      return _.isUndefined(sorting_number) ? 0 : sorting_number;
                    });
                  }

                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.section,
                        _.includes(
                          this.props.filterExpandedSections,
                          filter.key
                        ) && styles.expanded,
                        getURLParam('filter.' + filter.key) != '' &&
                          !_.every(
                            _.split(getURLParam('filter.' + filter.key), '|'),
                            (item) =>
                              _.includes(
                                _.map(filter?.defaultItems, 'value'),
                                item
                              )
                          ) &&
                          styles.active
                      )}
                    >
                      {filterHeader}
                      <div>
                        {_.size(non_filtered_items) >= 10 && (
                          <div className={styles.filterSearch}>
                            <SearchIcon className={styles.magnifier} />
                            <input
                              type='text'
                              ref={'filter_search_' + filter.key}
                              value={_.get(filtersQuery, filter.key, '')}
                              placeholder={__('table.filters.search')}
                              onChange={(e) =>
                                this.setState({
                                  filtersQuery: {
                                    ...filtersQuery,
                                    [filter.key]: e.target.value,
                                  },
                                })
                              }
                            />
                            {_.size(_.get(filtersQuery, filter.key, '')) >
                              0 && (
                              <CloseSmallIcon
                                className={styles.close}
                                onClick={() => {
                                  this.setState({
                                    filtersQuery: {
                                      ...filtersQuery,
                                      [filter.key]: '',
                                    },
                                  });

                                  this.refs[
                                    'filter_search_' + filter.key
                                  ].focus();
                                }}
                              />
                            )}
                          </div>
                        )}
                        <div className={styles.items}>
                          {_.map(filter_items, (item, i1) => {
                            const checked = _.includes(
                              active_filter_items,
                              _.toString(item.value)
                            );

                            return (
                              <div key={i1} className={styles.item}>
                                {!checked && (
                                  <UncheckedIcon
                                    className={styles.unchecked}
                                    onClick={() => {
                                      setURLParam(
                                        'filter.' + filter.key,
                                        [
                                          ...active_filter_items,
                                          item.value,
                                        ].join('|')
                                      );
                                    }}
                                  />
                                )}
                                {checked === true && (
                                  <CheckedIcon
                                    className={styles.checked}
                                    onClick={() => {
                                      setURLParam(
                                        'filter.' + filter.key,
                                        _.reject(
                                          active_filter_items,
                                          (row) => row == item.value
                                        ).join('|')
                                      );
                                    }}
                                  />
                                )}
                                <span
                                  onClick={() =>
                                    setURLParam(
                                      'filter.' + filter.key,
                                      _.some(
                                        _.split(
                                          getURLParam('filter.' + filter.key),
                                          '|'
                                        ),
                                        (item) =>
                                          _.includes(
                                            _.map(
                                              filter?.defaultItems,
                                              'value'
                                            ),
                                            item
                                          )
                                      )
                                        ? [
                                            ..._.map(
                                              filter?.defaultItems,
                                              'value'
                                            ),
                                            item.value,
                                          ].join('|')
                                        : item.value
                                    )
                                  }
                                >
                                  <Tooltip placement='left' text={item.label}>
                                    {item.label}
                                  </Tooltip>
                                </span>
                              </div>
                            );
                          })}

                          {_.size(_.get(filtersQuery, filter.key)) > 0 &&
                            _.isEmpty(filter_items) && (
                              <div className={styles.noResults}>
                                No results found.
                              </div>
                            )}
                        </div>
                        {_.map(filter.defaultItems, (defaultItem) => (
                          <div className={styles.defaultItems}>
                            <span
                              onClick={() =>
                                setURLParam(
                                  'filter.' + filter.key,
                                  defaultItem.value
                                )
                              }
                            >
                              <Tooltip
                                placement='left'
                                text={defaultItem.label}
                              >
                                {defaultItem.label}
                              </Tooltip>
                            </span>

                            <ToggleButton
                              onToggle={() => {
                                const checked = _.includes(
                                  active_filter_items,
                                  _.toString(defaultItem.value)
                                );

                                if (checked) {
                                  setURLParam(
                                    'filter.' + filter.key,
                                    _.reject(
                                      active_filter_items,
                                      (row) => row == defaultItem.value
                                    ).join('|')
                                  );
                                } else {
                                  setURLParam(
                                    'filter.' + filter.key,
                                    [
                                      ...active_filter_items,
                                      defaultItem.value,
                                    ].join('|')
                                  );
                                }
                              }}
                              value={_.includes(
                                active_filter_items,
                                _.toString(defaultItem.value)
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }

                if (filter.type == 'range') {
                  let value = getURLParam('filter.' + filter.key);

                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.section,
                        _.includes(
                          this.props.filterExpandedSections,
                          filter.key
                        ) && styles.expanded,
                        getURLParam('filter.' + filter.key) != '' &&
                          styles.active
                      )}
                    >
                      {filterHeader}
                      <Range
                        nullable={filter.nullable}
                        value={value}
                        onChange={(value) =>
                          setURLParam('filter.' + filter.key, value)
                        }
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
        {this.props.preview && (
          <div
            className={classNames(
              styles.flyout,
              !_.isEmpty(this.props.preview) &&
                this.props.clicked_row &&
                styles.active
            )}
          >
            {this.props.preview}
          </div>
        )}
      </div>
    );
  }
}

export default TableListView;
