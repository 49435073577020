import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/components/DateTimePicker.scss';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import ArrowLeftMiddleIcon from '../assets/images/arrow-left-middle-15x15.svg';
import * as _ from 'lodash';
import ArrowRightMiddleIcon from '../assets/images/arrow-right-middle-15x15.svg';
import moment from 'moment';
import MomentIterator from 'moment-iterator';
import DateIcon from '../assets/images/date-15x15.svg';
import DatetimeIcon from '../assets/images/datetime-15x15.svg';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import '../helpers/moment-round';
import Cleave from 'cleave.js/dist/cleave-react-node';
import { __, dateFrom, dateTimeFrom } from '../core/utils';
import UncheckedIcon from '../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../assets/images/checked-15x15.svg';
import FieldComponent from './FieldComponent';
import OutsideClickWrapper from './OutsideClickWrapper';
import { isMobileOnly } from 'react-device-detect';
import TableNA from './TableNA';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import Select from './Select';
import { generateHours, generateMinutes } from '../lists/date_time_picker';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import Tooltip from './Tooltip';

/**
 * Component Props:
 * - range
 * - time
 * - footer
 * - presets
 * - icon
 */
@FieldComponent
class DateTimePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours_1: false,
      minutes_1: false,
      hours_2: false,
      minutes_2: false,
      clear_date: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState != this.state || prevProps.meta != this.props.meta) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      const date1 = this.props.range
        ? _.get(this.props.input, 'value', {}).start
        : this.props.input.value;
      const date2 = _.get(this.props.input, 'value', {}).end;
      const allDay = _.get(this.props.input, 'value', {}).allDay;
      const isUndefinedAllDay = _.isUndefined(
        _.get(this.props.input, 'value', {}).allDay
      );

      const selectedDate1 = date1
        ? allDay
          ? moment(date1)
          : moment.utc(date1).local()
        : moment().startOf('day');

      const selectedDate2 = date2
        ? allDay
          ? moment(date2)
          : moment.utc(date2).local()
        : moment().endOf('day').startOf('minute');

      this.setState(
        {
          selectedDate1: selectedDate1,
          highlightedDate1: selectedDate1,
          selectedDate2: selectedDate2,
          highlightedDate2: selectedDate2,
          allDay: this.props.range
            ? isUndefinedAllDay
              ? true
              : _.isNull(allDay)
              ? true
              : allDay
            : false,

          //allDay: isUndefinedAllDay ? false : allDay && this.props.range,
          //&& allDay,
          // selectedDate1.isSame(selectedDate1.clone().startOf('day')) &&
          // selectedDate2.isSame(selectedDate2.clone().startOf('day')),
        },
        () => {
          this.props.input.onFocus();
        }
      );
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _formatSubmitValues(date) {
    return this.state.allDay || !this.props.time
      ? date.local().format('YYYY-MM-DD')
      : date.format('YYYY-MM-DD HH:mm:ss');
  }

  @autobind
  _handleSave() {
    const { time, range } = this.props;

    let value;

    if (this.props.range) {
      value = {
        start: this._formatSubmitValues(
          this.state.selectedDate1
            .clone()
            .startOf(this.props.time ? 'minute' : 'day')
            .utc()
        ),

        end: this._formatSubmitValues(
          this.state.selectedDate2
            .clone()
            .startOf(this.props.time ? 'minute' : 'day')
            .utc()
        ),
      };
    } else {
      value = this._formatSubmitValues(
        this.state.selectedDate1
          .clone()
          .startOf(this.props.time ? 'minute' : 'day')
          .utc()
      );
    }

    if (time && range) {
      value.allDay = this.state.allDay ? 1 : 0;
    }

    this.props.input.onChange(value);

    this._handleClose();
  }

  @autobind
  _handleSelectDate1(date) {
    this.setState(
      {
        selectedDate1: date
          .clone()
          .set('minutes', this.state.selectedDate1.minutes())
          .set('hours', this.state.selectedDate1.hours()),
        highlightedDate1: date
          .clone()
          .set('minutes', this.state.selectedDate1.minutes())
          .set('hours', this.state.selectedDate1.hours()),
      },
      () => {
        this._checkToDate();
      }
    );
  }

  @autobind
  _handleSelectDate2(date) {
    this.setState(
      {
        selectedDate2: date
          .clone()
          .set('minutes', this.state.selectedDate2.minutes())
          .set('hours', this.state.selectedDate2.hours()),
        highlightedDate2: date
          .clone()
          .set('minutes', this.state.selectedDate2.minutes())
          .set('hours', this.state.selectedDate2.hours()),
      },
      () => {
        this._checkFromDate();
      }
    );
  }

  @autobind
  _checkFromDate() {
    if (this.state.selectedDate2.isBefore(this.state.selectedDate1)) {
      this.setState({
        selectedDate1: this.state.selectedDate2
          .clone()
          .set('minutes', this.state.selectedDate2.minutes())
          .set('hours', this.state.selectedDate2.hours()),
        highlightedDate1: this.state.selectedDate2
          .clone()
          .set('minutes', this.state.selectedDate2.minutes())
          .set('hours', this.state.selectedDate2.hours()),
      });
    }
  }

  @autobind
  _checkToDate() {
    if (this.state.selectedDate1.isAfter(this.state.selectedDate2)) {
      this.setState({
        selectedDate2: this.state.selectedDate1
          .clone()
          .set('minutes', this.state.selectedDate1.minutes())
          .set('hours', this.state.selectedDate1.hours()),
        highlightedDate2: this.state.selectedDate1
          .clone()
          .set('minutes', this.state.selectedDate1.minutes())
          .set('hours', this.state.selectedDate1.hours()),
      });
    }
  }

  @autobind
  _handlePrevMonth(start) {
    if (start) {
      this.setState({
        highlightedDate1: this.state.highlightedDate1
          .clone()
          .subtract(1, 'month'),
      });
    } else {
      this.setState({
        highlightedDate2: this.state.highlightedDate2
          .clone()
          .subtract(1, 'month'),
      });
    }
  }

  @autobind
  _handleNextMonth(start) {
    if (start) {
      this.setState({
        highlightedDate1: this.state.highlightedDate1.clone().add(1, 'month'),
      });
    } else {
      this.setState({
        highlightedDate2: this.state.highlightedDate2.clone().add(1, 'month'),
      });
    }
  }

  @autobind
  _handlePreset(preset) {
    switch (preset) {
      case 'today':
        //from next hour to the end of the day
        this.setState({
          selectedDate1: moment().local().ceil(1, 'hour'),
          highlightedDate1: moment().local().ceil(1, 'hour'),
          selectedDate2: moment().local().endOf('day'),
          highlightedDate2: moment().local().endOf('day'),
        });
        break;
      case 'tomorrow':
        //from tomorrow morning to tomorrow night
        this.setState({
          selectedDate1: moment().local().add(1, 'day').startOf('day'),
          highlightedDate1: moment().local().add(1, 'day').startOf('day'),
          selectedDate2: moment().local().add(1, 'day').endOf('day'),
          highlightedDate2: moment().local().add(1, 'day').endOf('day'),
        });
        break;
      case 'next_week':
        //from the beginning of next week to the end of next week
        this.setState({
          selectedDate1: moment().local().add(1, 'week').startOf('isoWeek'),
          highlightedDate1: moment().local().add(1, 'week').startOf('isoWeek'),
          selectedDate2: moment().local().add(1, 'week').endOf('isoWeek'),
          highlightedDate2: moment().local().add(1, 'week').endOf('isoWeek'),
        });
        break;
      case 'next_month':
        //from the beginning of next month to the end of next month
        this.setState({
          selectedDate1: moment().local().add(1, 'month').startOf('month'),
          highlightedDate1: moment().local().add(1, 'month').startOf('month'),
          selectedDate2: moment().local().add(1, 'month').endOf('month'),
          highlightedDate2: moment().local().add(1, 'month').endOf('month'),
        });
        break;
    }
  }

  @autobind
  _handlePrevHour(start) {
    if (start) {
      this.setState(
        {
          selectedDate1: this.state.selectedDate1.clone().subtract('1', 'hour'),
        },
        () => this._checkToDate()
      );
    } else {
      this.setState(
        {
          selectedDate2: this.state.selectedDate2.clone().subtract('1', 'hour'),
        },
        () => this._checkFromDate()
      );
    }
  }

  @autobind
  _handleNextHour(start) {
    if (start) {
      this.setState(
        {
          selectedDate1: this.state.selectedDate1.clone().add('1', 'hour'),
        },
        () => this._checkToDate()
      );
    } else {
      this.setState(
        {
          selectedDate2: this.state.selectedDate2.clone().add('1', 'hour'),
        },
        () => this._checkFromDate()
      );
    }
  }

  @autobind
  _handlePrevMinute(start) {
    if (start) {
      this.setState(
        {
          selectedDate1: this.state.selectedDate1
            .clone()
            .subtract(15, 'minutes')
            .ceil(15, 'minutes'),
        },
        () => this._checkToDate()
      );
    } else {
      this.setState(
        {
          selectedDate2: this.state.selectedDate2
            .clone()
            .subtract(15, 'minutes')
            .ceil(15, 'minutes'),
        },
        () => this._checkFromDate()
      );
    }
  }

  @autobind
  _handleNextMinute(start) {
    if (start) {
      this.setState(
        {
          selectedDate1: this.state.selectedDate1
            .clone()
            .add(15, 'minutes')
            .floor(15, 'minutes'),
        },
        () => this._checkToDate(this.state.selectedDate2)
      );
    } else {
      this.setState(
        {
          selectedDate2: this.state.selectedDate2
            .clone()
            .add(15, 'minutes')
            .floor(15, 'minutes'),
        },
        () => this._checkFromDate(this.state.selectedDate1)
      );
    }
  }

  @autobind
  _handleChangeHour(hours, start) {
    if (start) {
      this.setState(
        {
          selectedDate1: this.state.selectedDate1.clone().set('hours', hours),
        },
        () => this._checkToDate(this.state.selectedDate2)
      );
    } else {
      this.setState(
        {
          selectedDate2: this.state.selectedDate2.clone().set('hours', hours),
        },
        () => this._checkFromDate(this.state.selectedDate1)
      );
    }
  }

  @autobind
  _handleChangeMinute(minutes, start) {
    if (start) {
      this.setState(
        {
          selectedDate1: this.state.selectedDate1
            .clone()
            .set('minutes', minutes),
        },
        () => this._checkToDate(this.state.selectedDate2)
      );
    } else {
      this.setState(
        {
          selectedDate2: this.state.selectedDate2
            .clone()
            .set('minutes', minutes),
        },
        () => this._checkFromDate(this.state.selectedDate1)
      );
    }
  }

  @autobind
  _handleAllDay() {
    this.setState({
      allDay: true,
      selectedDate1: this.state.selectedDate1.clone().startOf('day'),
      selectedDate2: this.state.selectedDate2.clone().endOf('day'),
    });
  }

  @autobind
  _handleClearDate() {
    this.props.input.onChange('');

    this.props.input.onBlur();
  }

  render() {
    const { selectedDate1, highlightedDate1, selectedDate2, highlightedDate2 } =
      this.state;
    const {
      range,
      time,
      presets,
      footer,
      icon,
      dropdown,
      wrapper,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
    } = this.props;

    let calendar1_range, calendar1, calendar2_range, calendar2;

    if (this.props.meta.active) {
      calendar1_range = MomentIterator(
        highlightedDate1.clone().startOf('month').startOf('isoWeek'),
        highlightedDate1.clone().endOf('month').endOf('isoWeek')
      ).range('day');

      if (_.size(calendar1_range) == 35) {
        calendar1_range = MomentIterator(
          highlightedDate1.clone().startOf('month').startOf('isoWeek'),
          highlightedDate1
            .clone()
            .endOf('month')
            .endOf('isoWeek')
            .add(7, 'days')
        ).range('day');
      }

      calendar1 = _.map(calendar1_range, (day) => {
        return {
          day,
          current_month: day.month() == highlightedDate1.month(),
          weekend: _.includes([6, 0], day.day()),
          today: day
            .clone()
            .startOf('day')
            .isSame(moment().local().startOf('day')),
          active:
            selectedDate1 &&
            day
              .clone()
              .startOf('day')
              .isSame(selectedDate1.clone().startOf('day')),
          inRange:
            range &&
            day.isBetween(
              selectedDate1.clone().startOf('day'),
              selectedDate2.clone().endOf('day'),
              null,
              '[]'
            ),
        };
      });

      calendar2_range = MomentIterator(
        highlightedDate2.clone().startOf('month').startOf('isoWeek'),
        highlightedDate2.clone().endOf('month').endOf('isoWeek')
      ).range('day');

      if (_.size(calendar2_range) == 35) {
        calendar2_range = MomentIterator(
          highlightedDate2.clone().startOf('month').startOf('isoWeek'),
          highlightedDate2
            .clone()
            .endOf('month')
            .endOf('isoWeek')
            .add(7, 'days')
        ).range('day');
      }

      calendar2 = _.map(calendar2_range, (day) => {
        return {
          day,
          current_month: day.month() == highlightedDate2.month(),
          weekend: _.includes([6, 0], day.day()),
          today: day
            .clone()
            .startOf('day')
            .isSame(moment().local().startOf('day')),
          active:
            selectedDate2 &&
            day
              .clone()
              .startOf('day')
              .isSame(selectedDate2.clone().startOf('day')),
          inRange:
            range &&
            day.isBetween(
              selectedDate1.clone().startOf('day'),
              selectedDate2.clone().endOf('day'),
              null,
              '[]'
            ),
        };
      });
    }

    let valueText = '';

    if (this.props.range) {
      if (this.props.time) {
        valueText = (
          <>
            {this.state.allDay ? (
              <>
                {dateFrom(_.get(this.props.input.value, 'start'))} -{' '}
                {dateFrom(_.get(this.props.input.value, 'end'))}
              </>
            ) : (
              <>
                {dateTimeFrom(_.get(this.props.input.value, 'start'))} -{' '}
                {dateTimeFrom(_.get(this.props.input.value, 'end'))}
              </>
            )}
          </>
        );
      } else {
        valueText = (
          <>
            {dateFrom(_.get(this.props.input.value, 'start'))} -{' '}
            {dateFrom(_.get(this.props.input.value, 'end'))}
          </>
        );
      }
    } else {
      if (this.props.time) {
        valueText = dateTimeFrom(this.props.input.value);
      } else {
        valueText = dateFrom(this.props.input.value);
      }
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          wrapper && styles.hasWrapper,
          dropdown && styles.dropdownStyle,
          _.isEmpty(this.props.input.value) && styles.placeholder,
          this.props.meta.active && styles.focus,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          this.props.disabled && styles.disabled
        )}
      >
        <KeyboardEventHandler
          handleKeys={['esc']}
          onKeyEvent={() => this.props.meta.active && this._handleClose()}
        />
        {!wrapper && (
          <>
            <div className={styles.label}>
              <label onClick={this._handleOpen}>{this.props.label}</label>
            </div>
            <div
              className={styles.inputGroup}
              ref={elementRef}
              onClick={this._handleOpen}
            >
              <div className={styles.input}>
                {icon && (time ? <DatetimeIcon /> : <DateIcon />)}{' '}
                {!_.isEmpty(this.props.input.value) ? valueText : <TableNA />}
              </div>
              <ArrowDownMiddleIcon className={styles.arrow} />
            </div>
          </>
        )}
        {wrapper && (
          <div ref={elementRef}>
            {wrapper(this._handleOpen, this.props.input.value)}
          </div>
        )}
        {this.props.meta.active && (
          <OutsideClickWrapper closable={false}>
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop:
                  (this.props.top || this.props.dropdown ? 40 : 26) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              {dropdown && (
                <div className={styles.header}>
                  <DateIcon /> {__('datetimepicker.title.pick-a-date')}
                  {range && presets && (
                    <div className={styles.presets}>
                      <span onClick={() => this._handlePreset('today')}>
                        {__('datetimepicker.preset.today')}
                      </span>
                      <span onClick={() => this._handlePreset('tomorrow')}>
                        {__('datetimepicker.preset.tomorrow')}
                      </span>
                      <span onClick={() => this._handlePreset('next_week')}>
                        {__('datetimepicker.preset.next-week')}
                      </span>
                      <span onClick={() => this._handlePreset('next_month')}>
                        {__('datetimepicker.preset.next-month')}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {range && presets && !dropdown && (
                <div className={styles.presets}>
                  <span onClick={() => this._handlePreset('today')}>
                    {__('datetimepicker.preset.today')}
                  </span>
                  <span onClick={() => this._handlePreset('tomorrow')}>
                    {__('datetimepicker.preset.tomorrow')}
                  </span>
                  <span onClick={() => this._handlePreset('next_week')}>
                    {__('datetimepicker.preset.next-week')}
                  </span>
                  <span onClick={() => this._handlePreset('next_month')}>
                    {__('datetimepicker.preset.next-month')}
                  </span>
                </div>
              )}
              <div className={styles.calendars}>
                <div className={styles.calendar}>
                  {range && (
                    <div className={styles.title}>
                      {__('datetimepicker.title.start')}
                    </div>
                  )}
                  <div className={styles.month}>
                    <span>
                      {__(
                        'datetimepicker.month-' +
                          this.state.highlightedDate1.clone().format('M')
                      )}{' '}
                      {this.state.highlightedDate1.clone().format('YYYY')}
                    </span>
                    <ArrowLeftMiddleIcon
                      onClick={() => this._handlePrevMonth(true)}
                    />
                    <ArrowRightMiddleIcon
                      onClick={() => this._handleNextMonth(true)}
                    />
                  </div>
                  <div className={styles.daynames}>
                    {_.range(0, 7).map((day, i) => (
                      <span key={i}>
                        {__(
                          'datetimepicker.day-' +
                            moment()
                              .day(day + 1)
                              .clone()
                              .format('d')
                        )}
                      </span>
                    ))}
                  </div>
                  <div className={styles.days}>
                    {_.map(_.chunk(calendar1, 7), (week, i) => (
                      <div className={styles.week} key={i}>
                        {week.map((date, j) => (
                          <span
                            key={j}
                            onClick={() => this._handleSelectDate1(date.day)}
                            className={classNames(
                              !date.current_month && styles.faded,
                              date.weekend && styles.weekend,
                              date.today && styles.today,
                              date.active && styles.active,
                              date.inRange && styles.inRange
                            )}
                          >
                            {date.day.clone().format('DD')}
                          </span>
                        ))}
                      </div>
                    ))}
                  </div>

                  {time && !this.state.allDay && (
                    <div className={styles.time}>
                      <div className={styles.day}>
                        {selectedDate1.clone().format('ddd, D MMM, YYYY')}
                      </div>
                      <div className={styles.hours}>
                        <Select
                          input={{
                            value: _.find(generateHours(), [
                              'value',
                              selectedDate1.clone().format('HH'),
                            ]).value,
                            onChange: (value) => {
                              this._handleChangeHour(value, true);
                            },
                            onBlur: () => this.setState({ hours_1: false }),
                            onFocus: () => this.setState({ hours_1: true }),
                          }}
                          meta={{
                            active: this.state.hours_1,
                          }}
                          options={generateHours()}
                          left={-135}
                          top={160}
                          noSort
                          wrapper={(handleOpen, activeItem, elementRef) => (
                            <div className={styles.timeSwitcher}>
                              <Cleave
                                options={{
                                  time: true,
                                  timePattern: ['h'],
                                }}
                                value={selectedDate1.clone().format('HH')}
                                onKeyDown={(e) =>
                                  e.keyCode == 13 && e.currentTarget.blur()
                                }
                                onBlur={(e) =>
                                  this._handleChangeHour(
                                    e.currentTarget.value,
                                    true
                                  )
                                }
                              />
                              <div
                                onClick={() => this.setState({ hours_1: true })}
                              >
                                <ArrowDownSmallIcon className={styles.arrow} />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                      <span className={styles.separator}>:</span>
                      <div className={styles.minutes}>
                        <Select
                          input={{
                            value:
                              _.find(generateMinutes(), [
                                'value',
                                selectedDate1.clone().format('mm'),
                              ])?.value || 0,
                            onChange: (value) => {
                              this._handleChangeMinute(value, true);
                            },
                            onBlur: () => this.setState({ minutes_1: false }),
                            onFocus: () => this.setState({ minutes_1: true }),
                          }}
                          meta={{
                            active: this.state.minutes_1,
                          }}
                          options={generateMinutes()}
                          left={-100}
                          top={160}
                          noSort
                          wrapper={(handleOpen, activeItem, elementRef) => (
                            <div className={styles.timeSwitcher}>
                              <Cleave
                                options={{
                                  time: true,
                                  timePattern: ['m'],
                                }}
                                value={selectedDate1.clone().format('mm')}
                                onKeyDown={(e) =>
                                  e.keyCode == 13 && e.currentTarget.blur()
                                }
                                onBlur={(e) =>
                                  this._handleChangeMinute(
                                    e.currentTarget.value,
                                    true
                                  )
                                }
                              />
                              <div
                                onClick={() =>
                                  this.setState({ minutes_1: true })
                                }
                              >
                                <ArrowDownSmallIcon className={styles.arrow} />
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {range && (
                  <div className={styles.calendar}>
                    <div className={styles.title}>
                      {__('datetimepicker.title.end')}
                    </div>
                    <div className={styles.month}>
                      <span>
                        {__(
                          'datetimepicker.month-' +
                            this.state.highlightedDate2.clone().format('M')
                        )}{' '}
                        {this.state.highlightedDate2.clone().format('YYYY')}
                      </span>
                      <ArrowLeftMiddleIcon
                        onClick={() => this._handlePrevMonth(false)}
                      />
                      <ArrowRightMiddleIcon
                        onClick={() => this._handleNextMonth(false)}
                      />
                    </div>
                    <div className={styles.daynames}>
                      {_.range(0, 7).map((day, i) => (
                        <span key={i}>
                          {__(
                            'datetimepicker.day-' +
                              moment()
                                .day(day + 1)
                                .clone()
                                .format('d')
                          )}
                        </span>
                      ))}
                    </div>
                    <div className={styles.days}>
                      {_.map(_.chunk(calendar2, 7), (week, i) => (
                        <div className={styles.week} key={i}>
                          {week.map((date, j) => (
                            <span
                              key={j}
                              onClick={() => this._handleSelectDate2(date.day)}
                              className={classNames(
                                !date.current_month && styles.faded,
                                date.weekend && styles.weekend,
                                date.today && styles.today,
                                date.active && styles.active,
                                date.inRange && styles.inRange
                              )}
                            >
                              {date.day.clone().format('DD')}
                            </span>
                          ))}
                        </div>
                      ))}
                    </div>
                    {time && !this.state.allDay && (
                      <div className={styles.time}>
                        <div className={styles.day}>
                          {selectedDate2.clone().format('ddd, D MMM, YYYY')}
                        </div>
                        <div className={styles.hours}>
                          <Select
                            input={{
                              value: _.find(generateHours(), [
                                'value',
                                selectedDate2.clone().format('HH'),
                              ]).value,
                              onChange: (value) => {
                                this._handleChangeHour(value, false);
                              },
                              onBlur: () => this.setState({ hours_2: false }),
                              onFocus: () => this.setState({ hours_2: true }),
                            }}
                            meta={{
                              active: this.state.hours_2,
                            }}
                            options={generateHours()}
                            left={-135}
                            top={160}
                            noSort
                            wrapper={(handleOpen, activeItem, elementRef) => (
                              <div className={styles.timeSwitcher}>
                                <Cleave
                                  options={{
                                    time: true,
                                    timePattern: ['h'],
                                  }}
                                  value={selectedDate2.clone().format('HH')}
                                  onKeyDown={(e) =>
                                    e.keyCode == 13 && e.currentTarget.blur()
                                  }
                                  onBlur={(e) =>
                                    this._handleChangeHour(
                                      e.currentTarget.value,
                                      false
                                    )
                                  }
                                />
                                <div
                                  onClick={() =>
                                    this.setState({ hours_2: true })
                                  }
                                >
                                  <ArrowDownSmallIcon
                                    className={styles.arrow}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </div>
                        <span className={styles.separator}>:</span>
                        <div className={styles.minutes}>
                          <Select
                            input={{
                              value:
                                _.find(generateMinutes(), [
                                  'value',
                                  selectedDate2.clone().format('mm'),
                                ])?.value || 0,
                              onChange: (value) => {
                                this._handleChangeMinute(value, false);
                              },
                              onBlur: () => this.setState({ minutes_2: false }),
                              onFocus: () => this.setState({ minutes_2: true }),
                            }}
                            meta={{
                              active: this.state.minutes_2,
                            }}
                            options={generateMinutes()}
                            left={-80}
                            top={160}
                            noSort
                            wrapper={(handleOpen, activeItem, elementRef) => (
                              <div className={styles.timeSwitcher}>
                                <Cleave
                                  options={{
                                    time: true,
                                    timePattern: ['m'],
                                  }}
                                  value={selectedDate2.clone().format('mm')}
                                  onKeyDown={(e) =>
                                    e.keyCode == 13 && e.currentTarget.blur()
                                  }
                                  onBlur={(e) =>
                                    this._handleChangeMinute(
                                      e.currentTarget.value,
                                      false
                                    )
                                  }
                                />
                                <div
                                  onClick={() =>
                                    this.setState({ minutes_2: true })
                                  }
                                >
                                  <ArrowDownSmallIcon
                                    className={styles.arrow}
                                  />
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {(footer || isMobileOnly) && (
                <div className={styles.footer}>
                  <div className={styles.leftContainer}>
                    {time && range && (
                      <div className={styles.allDay}>
                        {!this.state.allDay && (
                          <UncheckedIcon
                            className={styles.unchecked}
                            onClick={this._handleAllDay}
                          />
                        )}
                        {!!this.state.allDay && (
                          <CheckedIcon
                            className={styles.checked}
                            onClick={() => this.setState({ allDay: false })}
                          />
                        )}
                        {__('datetimepicker.all-day')}
                      </div>
                    )}

                    {this.props.clearDate && (
                      <div className={styles.clearDate}>
                        <Tooltip text={__('datetimepicker.clear-date')}>
                          <button
                            className={styles.button}
                            onClick={() => this._handleClearDate()}
                          >
                            {__('datetimepicker.clear-date')}
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <ButtonGroup right>
                    <Button
                      lightGray
                      medium
                      middleText={__('button.cancel')}
                      onClick={this._handleClose}
                    />
                    {footer && (
                      <Button
                        lightBlue
                        medium
                        middleText={__('button.done')}
                        onClick={this._handleSave}
                      />
                    )}
                  </ButtonGroup>
                </div>
              )}
            </div>
          </OutsideClickWrapper>
        )}
        {!wrapper && (
          <>
            <div className={styles.bar} />
            <div
              className={classNames(
                styles.assist,
                ((_.get(this.props, 'meta.error') &&
                  _.get(this.props, 'meta.touched')) ||
                  this.props.hint) &&
                  styles.hint
              )}
            >
              {((_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched')) ||
                this.props.hint) && (
                <span>
                  {(_.isObject(_.get(this.props, 'meta.error'))
                    ? _.first(_.values(_.get(this.props, 'meta.error')))
                    : _.get(this.props, 'meta.error')) || this.props.hint}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default DateTimePicker;
