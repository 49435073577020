import * as React from "react";
import {Component} from "react";
import LogoIcon from "../../assets/images/logo-54x60.svg";
import styles from "../../styles/views/auth/RegisterUser.scss";
import {__, getURLFragment, getURLParam, redirect, setFormErrors, setFormValue, validateForm} from "../../core/utils";
import RegisterUserForm from "../../forms/auth/RegisterUserForm";
import AuthLayout from "../../layouts/AuthLayout";
import autobind from "autobind-decorator";
import {getRegistrationInvitation, register, resendConfirmationEmail} from "../../redux/actions/auth";
import classNames from "classnames";
import {clearNotifications, setNotification} from "../../redux/actions/general/notification";

class Register extends Component {
	constructor(props) {
		super(props);

		this.state = {
			registration_done: false //this will turn to true after a successful registration, and will display a message
		};

		this.valid_invitation_types = ['virtual_company_invitation', 'company_join_invitation', 'company_join_suggestion'];

		//if there is an invitation token in the URL, put it in the sessionStorage
		if(this.props.match.params.token && getURLFragment()) {
			const invitation_type = getURLFragment();

			//put 'invitation_type' and 'token' in the sessionStorage
			if(_.includes(this.valid_invitation_types, invitation_type)) {
				sessionStorage.setItem('invitation_type', invitation_type);
				sessionStorage.setItem('token', this.props.match.params.token);
			}
		}

		//clean all URL parts after '/register'
		redirect('/register', true);
	}

	componentDidMount() {
		//get 'invitation_type' and 'token' from the sessionStorage
		const invitation_type = sessionStorage.getItem('invitation_type');
		const token = sessionStorage.getItem('token');

		//if the invitation_type exists and is valid, predefine 'email' field from the database
		if(_.includes(this.valid_invitation_types, invitation_type)) {
			getRegistrationInvitation(invitation_type, token).then(({response}) => {
				setFormValue('auth.register_user', 'email', response.data.email);
			});
		}
	}

	@autobind
	_resendConfirmationEmail(email) {
		//resend confirmation email, display notification, and hide 'Resend confirmation email ?' action button
		resendConfirmationEmail(email).then(() => {
			setNotification(__("auth.login.confirmation-resent"));

			setFormErrors('auth.register_user', {email: ['Email already exists, but is unconfirmed.']});
		});
	}

	@autobind
	_handleSubmit(formData) {
		//clear existing notifications
		clearNotifications();

		//get 'invitation_type' and 'token' from the sessionStorage
		const invitation_type = sessionStorage.getItem('invitation_type');
		const token = sessionStorage.getItem('token');

		//pass the 'invitation_type' and 'token' with other data (whether it exists or not, backend security will take care of it)
		formData = {
			...formData,
			invitation_type,
			token,
		};

		//validate form, register, and set 'registration_done' to true (this will display a success message'
		const validation = validateForm(
			register(formData).then(() => {
				this.setState({
					registration_done: true
				});
			})
		);

		//if backend returns error code '1', display email confirmation resend option as a field validation error
		validation.catch(({errors}) => {
			if(_.has(errors, "code") && errors.code === 1) {
				setFormErrors('auth.register_user', {
					email: [<>Email already exists, but is unconfirmed. <a onClick={() => this._resendConfirmationEmail(formData.email)}>Resend confirmation email ?</a></>]
				});
			}
		});

		return validation;
	}

	render() {
		return (
			<>
				<div className={styles.heading}>
					<LogoIcon onClick={() => redirect('/')} />
					<div
						className={styles.title}>{this.state.registration_done ? __("auth.register.congratulations") : __("auth.register.enter-user-data")}</div>
				</div>
				{this.state.registration_done ? (
					<div className={styles.details}>
						<p className={styles.strong}>{__("auth.register.message.message1")}.</p>
						<p className={classNames(styles.blue, styles.strong)}>
							{__("auth.register.message.message3")}.<br/>{__("auth.register.message.message4")}.
						</p>
						<p>{__("auth.register.message.message5")}.</p>
						<p className={styles.strong}>{__("auth.register.message.message6")}.</p>
					</div>
				) : (
					<div className={styles.form}>
						<RegisterUserForm onSubmit={this._handleSubmit} initialValues={{email: getURLParam('email')}}/>}
					</div>
				)}
			</>
		);
	}
}

export default Register;