import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/products/AttributesForm.scss';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import EditIcon from '../../assets/images/edit-16x16.svg';
import VariateIcon from '../../assets/images/variate-16x16.svg';
import Input from '../../components/Input';
import Select from '../../components/Select';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  toBoolean,
} from '../../core/utils';
import ColorPicker from '../../components/ColorPicker';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import InfoIcon from '../../assets/images/info-positive-16x16.svg';
import classNames from 'classnames';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import {
  deleteProductAttributeField,
  readProduct,
  reorderProductAttributeFields,
  reorderProductNotes,
} from '../../redux/actions/table/products';
import { setNotification } from '../../redux/actions/general/notification';
import MaterialPicker from '../../components/MaterialPicker';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import { ReactSortable } from 'react-sortablejs';
import autobind from 'autobind-decorator';
import MultiSelect from '../../components/MultiSelect';
import Tooltip from '../../components/Tooltip';
import TexturePicker from '../../components/TexturePicker';

@reduxForm({
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.products,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
class AttributesForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _changeOrder(attributes) {
    setTableParams('products', {
      flyout: {
        ...this.props.store.flyout,
        attribute_groups: _.map(
          this.props.store.flyout.attribute_groups,
          (attribute_group) => {
            if (attribute_group.id == this.props.groupId) {
              return {
                ...attribute_group,
                attributes: _.map(attributes, (attribute, i) => ({
                  ...attribute,
                  priority: i,
                })),
              };
            } else {
              return attribute_group;
            }
          }
        ),
      },
    });
  }

  render() {
    const boolean_options = [
      {
        label: __('products.attributes-form.boolean.yes'),
        value: '1',
      },
      {
        label: __('products.attributes-form.boolean.no'),
        value: '0',
      },
    ];

    const {
      handleSubmit,
      expanded,
      store,
      groupId,
      application_language_id,
      is_configurator,
      mandatory_part_id,
    } = this.props;
    const { language_id, flyout } = store;
    const { units, product_attribute_fields } = this.props.localization;

    const attributes = _.sortBy(
      _.find(flyout.attribute_groups, ['id', groupId]).attributes,
      'priority'
    );

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, expanded && styles.expanded)}
      >
        {!_.isEmpty(attributes) && (
          <ReactSortable
            className={styles.attributes}
            list={attributes}
            setList={this._changeOrder}
            animation={200}
            delayOnTouchStart={true}
            onEnd={() => {
              reorderProductAttributeFields(
                this.props.groupId,
                _.map(attributes, 'id')
              ).then(() => {
                setNotification(
                  __('product-attributes-order.saved.notification')
                );
              });
            }}
            handle={'.' + styles.grabber}
          >
            {_.map(attributes, (attribute) => {
              const currentAttribute = attribute.template_field_id
                ? _.get(
                    product_attribute_fields,
                    attribute.template_field_id,
                    {}
                  )
                : attribute;
              const type = currentAttribute.type;
              let label = getLocalized(
                currentAttribute.label,
                application_language_id
              );
              label +=
                (attribute.unit_id || currentAttribute.unit_id) &&
                _.includes(['numeric', 'range', 'range_selectable'], type)
                  ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')'
                  : '';
              label += !attribute.template_field_id ? '*' : '';

              let fieldName = '';

              const labelTooltip = currentAttribute.description
                ? getLocalized(
                    currentAttribute.description,
                    application_language_id
                  )
                : undefined;

              const values = _.get(attribute, 'values', []);

              const custom_variations = _.size(
                _.reject(
                  values,
                  (value) => _.size(value.value) === 0 || !value.in_use
                )
              );
              const part_variations = _.size(
                _.reject(
                  _.flatten(_.map(attribute.parts, 'values')),
                  (value) =>
                    _.size(value.value) === 0 ||
                    !value.in_use ||
                    !value.inherit_in_use
                )
              );
              const total_variations = custom_variations + part_variations;

              const total_variations_with_non_used =
                _.size(_.reject(values, (value) => _.size(value.value) === 0)) +
                _.size(
                  _.reject(
                    _.flatten(_.map(attribute.parts, 'values')),
                    (value) => _.size(value.value) === 0
                  )
                );

              if (
                _.size(values) === 1 &&
                _.size(_.flatten(_.map(attribute.parts, 'values'))) === 0
              ) {
                fieldName = `value-${attribute.values[0].id}`;
              }

              return (
                <div
                  className={styles.attribute}
                  key={[attribute.id, attribute.template_field_id].join('-')}
                >
                  <div className={styles.grabber}>
                    <GrabberIcon />
                  </div>
                  {custom_variations > 1 || part_variations > 0 ? (
                    <div className={styles.multipleVariations}>
                      <Tooltip text={labelTooltip}>
                        <div className={styles.label}>{label}</div>
                      </Tooltip>
                      <span>
                        {total_variations}
                        {total_variations != total_variations_with_non_used
                          ? ` (${total_variations_with_non_used})`
                          : ''}{' '}
                        variations
                      </span>
                    </div>
                  ) : (
                    <>
                      {type == 'boolean' && (
                        <Field
                          name={fieldName}
                          component={Select}
                          label={label}
                          labelTooltip={labelTooltip}
                          options={boolean_options}
                        />
                      )}
                      {type == 'dropdown_string' && (
                        <Field
                          searchable
                          name={fieldName}
                          noSort
                          component={Select}
                          label={label}
                          labelTooltip={labelTooltip}
                          options={_.map(
                            _.find(
                              this.props.localization.product_attribute_fields,
                              ['id', attribute.template_field_id]
                            ).options,
                            (option) => ({
                              value: option.id,
                              label: getLocalized(option.value, language_id),
                              disabled: toBoolean(option.discontinued),
                            })
                          )}
                        />
                      )}
                      {type == 'dropdown_string_multiple' && (
                        <Field
                          searchable
                          name={fieldName}
                          noSort
                          component={MultiSelect}
                          label={label}
                          labelTooltip={labelTooltip}
                          options={_.map(
                            _.find(
                              this.props.localization.product_attribute_fields,
                              ['id', attribute.template_field_id]
                            ).options,
                            (option) => ({
                              value: option.id,
                              label: getLocalized(option.value, language_id),
                              disabled: toBoolean(option.discontinued),
                            })
                          )}
                        />
                      )}
                      {type == 'dropdown_numeric' && (
                        <Field
                          searchable
                          name={fieldName}
                          noSort
                          component={Select}
                          label={label}
                          labelTooltip={labelTooltip}
                          options={_.map(
                            _.find(
                              this.props.localization.product_attribute_fields,
                              ['id', attribute.template_field_id]
                            ).options,
                            (option) => {
                              let unit = _.get(units, [
                                attribute.unit_id,
                                'name',
                              ]);

                              return {
                                value: option.id,
                                label:
                                  _.toString(
                                    getLocalized(option.value, language_id)
                                  ) + (unit ? ' (' + unit + ')' : ''),
                                disabled: toBoolean(option.discontinued),
                              };
                            }
                          )}
                        />
                      )}
                      {type == 'dropdown_numeric_multiple' && (
                        <Field
                          searchable
                          name={fieldName}
                          noSort
                          component={MultiSelect}
                          label={label}
                          labelTooltip={labelTooltip}
                          options={_.map(
                            _.find(
                              this.props.localization.product_attribute_fields,
                              ['id', attribute.template_field_id]
                            ).options,
                            (option) => {
                              let unit = _.get(units, [
                                attribute.unit_id,
                                'name',
                              ]);

                              return {
                                value: option.id,
                                label:
                                  _.toString(
                                    getLocalized(option.value, language_id)
                                  ) + (unit ? ' (' + unit + ')' : ''),
                                disabled: toBoolean(option.discontinued),
                              };
                            }
                          )}
                        />
                      )}
                      {type == 'string' && (
                        <Field
                          name={fieldName + '.' + language_id}
                          component={Input}
                          label={label}
                          labelTooltip={labelTooltip}
                        />
                      )}
                      {type == 'numeric' && (
                        <Field
                          numeric
                          decimal
                          name={fieldName}
                          component={Input}
                          label={label}
                          labelTooltip={labelTooltip}
                        />
                      )}
                      {type == 'color' && (
                        <Field
                          name={fieldName}
                          language={store.language_id}
                          component={ColorPicker}
                          label={label}
                          labelTooltip={labelTooltip}
                          myCompanyColors
                        />
                      )}
                      {type == 'material' && (
                        <Field
                          name={fieldName}
                          component={MaterialPicker}
                          language={language_id}
                          label={label}
                          labelTooltip={labelTooltip}
                          myCompanyMaterials
                        />
                      )}
                      {type == 'texture' && (
                        <Field
                          name={fieldName}
                          component={TexturePicker}
                          language={language_id}
                          label={label}
                          labelTooltip={labelTooltip}
                        />
                      )}
                      {type == 'range' && (
                        <div className={styles.range}>
                          <Tooltip text={labelTooltip}>
                            <div className={styles.label}>{label}</div>
                          </Tooltip>
                          <div className={styles.flex}>
                            <div className={styles.f1}>
                              <Field
                                numeric
                                decimal
                                name={fieldName + '.0'}
                                component={Input}
                              />
                            </div>
                            <span className={styles.minus}>-</span>
                            <div className={styles.f1}>
                              <Field
                                numeric
                                decimal
                                name={fieldName + '.1'}
                                component={Input}
                                align='right'
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {type == 'range_selectable' && (
                        <div className={styles.range}>
                          <Tooltip text={labelTooltip}>
                            <div className={styles.label}>{label}</div>
                          </Tooltip>
                          <div className={styles.flex}>
                            <div className={styles.f1}>
                              <Field
                                numeric
                                decimal
                                name={fieldName + '.0'}
                                component={Input}
                              />
                            </div>
                            <span className={styles.minus}>-</span>
                            <div className={styles.f1}>
                              <Field
                                numeric
                                decimal
                                name={fieldName + '.1'}
                                component={Input}
                                align='right'
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {is_configurator && (
                    <div className={styles.variate}>
                      <VariateIcon
                        onClick={(e) => {
                          e.stopPropagation();

                          setTableParams('products', {
                            edit_product_attribute_group_field_values_wizard:
                              attribute.id,
                          });
                        }}
                      />
                    </div>
                  )}
                  {_.isNull(mandatory_part_id) && (
                    <>
                      <div className={styles.edit}>
                        <EditIcon
                          onClick={(e) => {
                            e.stopPropagation();

                            setTableParams('products', {
                              edit_product_attribute_group_field_wizard:
                                attribute.id,
                              template_field_id: attribute.template_field_id,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.remove}>
                        <CloseMediumIcon
                          onClick={(e) => {
                            e.stopPropagation();

                            showAlertbox({
                              color: 'red',
                              title: __('general.alert.are-you-sure'),
                              description: __(
                                'products.attributes.are-you-sure.remove'
                              ),
                              buttons: [
                                {
                                  color: 'lightGray',
                                  text: __('general.alert.no-close'),
                                  onClick: 'close',
                                },
                                {
                                  color: 'gray',
                                  text: __('general.alert.yes-delete'),
                                  onClick: () =>
                                    deleteProductAttributeField(
                                      attribute.id
                                    ).then(() => {
                                      hideAlertbox();

                                      readProduct(flyout.id).then(
                                        ({ response }) => {
                                          setTableParams('products', {
                                            flyout: response.data,
                                          });
                                        }
                                      );

                                      setNotification(
                                        __(
                                          'products.attributes.attribute-has-been-deleted'
                                        )
                                      );
                                    }),
                                },
                              ],
                            });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </ReactSortable>
        )}
      </Form>
    );
  }
}

export default AttributesForm;
