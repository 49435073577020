import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { get } from 'lodash';
import Table from '../../components/Table';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import styles from '../../styles/views/stage_disciplines/StageDisciplines.scss';
import {
  getStageDisciplinesPermissions,
  listStageDisciplines,
  readStageDiscipline,
  updateStageDiscipline,
} from '../../redux/actions/table/stage_disciplines';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import EditStageDisciplineForm from '../../forms/stage_disciplines/EditStageDisciplineForm';
import {
  __,
  getActiveProject,
  getActiveStage,
  getURLParam,
  redirect,
  validateForm,
} from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import autobind from 'autobind-decorator';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';

const mapStateToProps = (state) => {
  return {
    table: state.table['stage_disciplines'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.stage_disciplines/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class StageDisciplines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_project_administrator: false,
    };
  }

  componentDidMount() {
    getStageDisciplinesPermissions(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({
          is_project_administrator: _.get(response.data, 'administrator'),
        });

        enableProjectsDropdown(({ stage_id }) =>
          redirect('/stages/' + stage_id + '/approvers')
        );
        enableStagesDropdown(({ stage_id }) =>
          redirect('/stages/' + stage_id + '/approvers')
        );

        createSidebar({
          title: __('submenu.title.project'),
          items: [
            {
              title: __('submenu.title.stages'),
              link: () => '/projects/' + getActiveProject() + '/stages',
            },
            {
              title: __('submenu.title.teams'),
              link: () => '/stages/' + getActiveStage() + '/teams',
            },
            {
              title: __('submenu.title.approvers'),
              link: () => '/stages/' + getActiveStage() + '/approvers',
              active: true,
            },
            {
              title: __('submenu.title.project-properties'),
              link: () => '/projects/' + getActiveProject() + '/general',
            },
            {
              title: __('submenu.title.import_spreadsheet'),
              link: () => '/import/' + getActiveStage(),
            },
          ],
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readStageDiscipline(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  @autobind
  _handleSubmit(formData) {
    formData = {
      ...formData,
      approvers: _.map(formData.approvers, 'id'),
    };

    return validateForm(
      updateStageDiscipline(formData.id, _.omit(formData, ['id'])).then(() => {
        listStageDisciplines(this.props.match.params.stage);

        readStageDiscipline(getURLParam('id')).then(({ response }) => {
          this.setState({ preview: response.data });
        });

        setNotification(__('project.discipline.notification.success-saved'));
      })
    );
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('project.discipline.table.column.code'),
        width: 80,
      },
      {
        key: 'discipline',
        name: __('project.discipline.table.column.discipline'),
        required: true,
        default: true,
        sortable: false,
        value: (x, row) =>
          row.discipline_id == null
            ? row.description + '*'
            : _.get(this.props.localization, [
                'disciplines',
                row.discipline_id,
                'name',
              ]),
      },
      {
        key: 'approvers',
        name: __('project.discipline.table.column.approvers'),
        value: (approvers) => _.join(approvers, ', ') || 'n/a',
        sortable: false,
      },
    ];

    const preview = (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              <strong>{get(this.state, 'preview.code')}:</strong>{' '}
              {get(this.state, 'preview.discipline_id') == null
                ? get(this.state, 'preview.description') + '*'
                : _.get(this.props.localization, [
                    'disciplines',
                    get(this.state, 'preview.discipline_id'),
                    'name',
                  ])}
            </span>
            <CloseBigIcon
              onClick={() =>
                this.props.actions.setTableParams({
                  clicked_row: undefined,
                })
              }
            />
          </div>
        </div>
        <div className={styles.scroller}>
          <div className={styles.form}>
            <EditStageDisciplineForm
              isProjectAdministrator={this.state.is_project_administrator}
              onSubmit={this._handleSubmit}
              initialValues={_.pick(get(this.state, 'preview'), [
                'id',
                'approvers',
              ])}
            />
          </div>
        </div>
      </div>
    );

    return (
      <Table
        listAction={() => listStageDisciplines(this.props.match.params.stage)}
        name='stage_disciplines'
        title={__('Approvers')}
        columns={columns}
        preview={get(this.state, 'preview') ? preview : []}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default StageDisciplines;
