import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/RelatedTasks.scss';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import Tooltip from '../../components/Tooltip';
import {
  __,
  dateFrom,
  redirect,
  timeFrom,
  mapStateToProps,
  setTableParams,
  getActiveStage,
} from '../../core/utils';
import autobind from 'autobind-decorator';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import CloseMiddleIcon from '../../assets/images/close-middle-15x15.svg';
import {
  deleteRelatedTask,
  readTask,
} from '../../redux/actions/table/all_tasks';
import * as _ from 'lodash';
import { setNotification } from '../../redux/actions/general/notification';
import Avatar from '../../components/Avatar';
import TaskRfiIcon from '../../assets/images/task-rfi-15x15.svg';
import TaskFyiIcon from '../../assets/images/task-fyi-15x15.svg';
import TaskDecisionIcon from '../../assets/images/task-decision-15x15.svg';
import TaskTodoIcon from '../../assets/images/task-todo-15x15.svg';
import TaskMilestoneIcon from '../../assets/images/task-milestone-15x15.svg';
import TaskIssueIcon from '../../assets/images/task-issue-15x15.svg';

@mapStateToProps((state) => ({
  store: state.table.all_tasks,
  application_language_id: state.auth.language_id,
}))
@withRouter
class RelatedTasks extends Component {
  @autobind
  _deleteTask(e, related_task_id) {
    e.stopPropagation();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'tasks.all-tasks.flyout.related-tasks.are-you-sure-delete-related-task'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () =>
            deleteRelatedTask(this.props.data.id, {
              related_task_id,
            }).then(() =>
              readTask(this.props.data.id).then(() => {
                if (this.props.match.path == '/meetings/:meeting') {
                  setTableParams('all_tasks', {
                    clicked_row: this.props.data.id,
                  });
                }

                hideAlertbox();

                setNotification(
                  __(
                    'tasks.all-tasks.flyout.related-tasks.related-task-deleted'
                  )
                );
              })
            ),
        },
      ],
    });
  }

  render() {
    const { data, collapsed } = this.props;

    const taskStatus = (status) => {
      switch (status) {
        case 'not-started':
          return (
            <Tooltip
              text={__('tasks.all-tasks.flyout.related-tasks.not-started')}
            >
              <span className={classNames(styles.balloon, styles.notStarted)}>
                {__('table.columns.task-status-notstarted-short')}
              </span>
            </Tooltip>
          );
        case 'started':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.started')}>
              <span className={classNames(styles.balloon, styles.started)}>
                {__('table.columns.task-status-started-short')}
              </span>
            </Tooltip>
          );
        case 'finished':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.finished')}>
              <span className={classNames(styles.balloon, styles.finished)}>
                {__('table.columns.task-status-finished-short')}
              </span>
            </Tooltip>
          );
        case 'closed':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.closed')}>
              <span className={classNames(styles.balloon, styles.closed)}>
                {__('table.columns.task-status-closed-short')}
              </span>
            </Tooltip>
          );
        case 'reopened':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.reopened')}>
              <span className={classNames(styles.balloon, styles.reopened)}>
                {__('table.columns.task-status-reopened-short')}
              </span>
            </Tooltip>
          );
        case 'canceled':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.canceled')}>
              <span className={classNames(styles.balloon, styles.canceled)}>
                {__('table.columns.task-status-canceled-short')}
              </span>
            </Tooltip>
          );
      }
    };
    const taskPriority = (priority) => {
      switch (priority) {
        case 'low':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.low')}>
              <span className={classNames(styles.balloon, styles.low)}>
                {__('table.columns.task-priority-low-short')}
              </span>
            </Tooltip>
          );
        case 'normal':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.normal')}>
              <span className={classNames(styles.balloon, styles.normal)}>
                {__('table.columns.task-priority-normal-short')}
              </span>
            </Tooltip>
          );
        case 'high':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.high')}>
              <span className={classNames(styles.balloon, styles.high)}>
                {__('table.columns.task-priority-high-short')}
              </span>
            </Tooltip>
          );
        case 'urgent':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.urgent')}>
              <span className={classNames(styles.balloon, styles.urgent)}>
                {__('table.columns.task-priority-urgent-short')}
              </span>
            </Tooltip>
          );
      }
    };

    const taskType = (type) => {
      switch (type) {
        case 'rfi':
          return (
            <Tooltip
              text={__(
                'tasks.all-tasks.flyout.related-tasks.request-for-information'
              )}
            >
              <TaskRfiIcon className={styles.rfiIcon} />
            </Tooltip>
          );
        case 'fyi':
          return (
            <Tooltip
              text={__(
                'tasks.all-tasks.flyout.related-tasks.find-your-information'
              )}
            >
              <TaskFyiIcon className={styles.fyiIcon} />
            </Tooltip>
          );
        case 'decision':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.decision')}>
              <TaskDecisionIcon className={styles.decisionIcon} />
            </Tooltip>
          );
        case 'todo':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.todo')}>
              <TaskTodoIcon className={styles.todoIcon} />
            </Tooltip>
          );
        case 'milestone':
          return (
            <Tooltip
              text={__('tasks.all-tasks.flyout.related-tasks.milestone')}
            >
              <TaskMilestoneIcon className={styles.milestoneIcon} />
            </Tooltip>
          );
        case 'issue':
          return (
            <Tooltip text={__('tasks.all-tasks.flyout.related-tasks.issue')}>
              <TaskIssueIcon className={styles.issueIcon} />
            </Tooltip>
          );
      }
    };

    return (
      <div
        className={classNames(styles.scroller, collapsed && styles.collapsed)}
      >
        {/* {_.isEmpty(data.related_tasks) ? <div className={styles.empty}>{__('tasks.all-tasks.flyout.related-tasks.there-are-no-attached-related-tasks')}</div> : ''} */}
        {_.map(data.related_tasks, (related_task, i) => (
          <div className={styles.row} key={i}>
            {taskType(related_task.type)}
            <div
              onClick={() => {
                if (related_task.can_view) {
                  if (this.props.match.path === '/stages/:stage/tasks') {
                    setTableParams('all_tasks', {
                      clicked_row: related_task.id,
                    });
                  } else {
                    readTask(related_task.id);
                  }
                }
              }}
              className={classNames(
                styles.link,
                related_task.can_view ? styles.clickable : styles.disabled
              )}
            >
              {!related_task.can_view && (
                <Tooltip
                  text={__(
                    'tasks.all-tasks.flyout.related-tasks.tooltip.private-related-task.no-access'
                  )}
                >
                  <span>
                    {'T-' + related_task.identifier} &middot;{' '}
                    {related_task.title}
                  </span>
                </Tooltip>
              )}
              {related_task.can_view && (
                <span>
                  {'T-' + related_task.identifier} &middot; {related_task.title}
                </span>
              )}{' '}
              {related_task.can_view && <ArrowDoubleRightSmallIcon />}
            </div>
            {!_.isEmpty(related_task.assignee) && (
              <>
                <div className={styles.assignee}>
                  {_.size(related_task.assignee) == 1 &&
                    _.map(_.take(related_task.assignee, 1), (user, i) => (
                      <Avatar
                        key={i}
                        avatar_url={user.avatar_url}
                        name={user.fullname}
                        active={user.active}
                      />
                    ))}
                  {_.size(related_task.assignee) > 1 && (
                    <Tooltip
                      text={_.map(related_task.assignee, 'fullname').join('\n')}
                    >
                      <div className={styles.more}>
                        <span>{_.size(related_task.assignee)}</span>
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className={styles.separator} />
              </>
            )}
            {taskStatus(related_task.status)}
            <div className={styles.separator} />
            {taskPriority(related_task.priority)}
            <div className={styles.separator} />
            <div
              className={classNames(
                styles.balloon,
                styles.public,
                styles.private
              )}
            >
              <Tooltip text={related_task.visibility ? 'Public' : 'Private'}>
                {related_task.visibility ? (
                  <span className={classNames(styles.balloon, styles.public)}>
                    pu
                  </span>
                ) : (
                  <span className={classNames(styles.balloon, styles.private)}>
                    pr
                  </span>
                )}
              </Tooltip>
            </div>
            <div className={styles.actions}>
              <Tooltip
                text={__(
                  'tasks.all-tasks.flyout.related-tasks.delete-related-task'
                )}
              >
                <CloseMiddleIcon
                  onClick={(e) => this._deleteTask(e, related_task.id)}
                />
              </Tooltip>
            </div>
          </div>
        ))}
        <div className={styles.addRow}>
          <button
            onClick={() =>
              setTableParams('all_tasks', {
                add_related_task_wizard: data.id,
              })
            }
          >
            {__('button.add-related-task')}
          </button>
        </div>
      </div>
    );
  }
}

export default RelatedTasks;
