import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import {__, getURLParam, linkifyText, mapStateToProps, setTableParams} from "../../core/utils";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import {setPlusActions} from "../../redux/actions/general/plus";
import TableNA from "../../components/TableNA";
import classNames from "classnames";
import styles from "../../styles/views/companies_and_users/AddressBookCompanies.scss";
import {listAddressBookCompanies, readAddressBookCompany} from "../../redux/actions/table/address_book_companies";
import AddressBookCompaniesFlyout from "../../flyouts/address_book_companies/AddressBookCompaniesFlyout";

@mapStateToProps(state => ({
	table: state.table["address_book_companies"],
	auth: state.auth
}))
class AddressBookCompanies extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();

		createSidebar({
			title: __('companies-and-users.address-book-companies.companies-users'),
			items: [
				{
					title: __('companies-and-users.address-book-companies.companies'),
					link: () => "/companies",
					items: [
						{
							title: __('companies-and-users.address-book-companies.all-companies'),
							link: () => "/companies",
						},
						{
							title: __('companies-and-users.address-book-companies.partner-companies'),
							link: () => "/companies/partner",
						},
					]
				}, {
					title: __('companies-and-users.address-book-companies.users'),
					link: () => "/companies/my/members",
					items: [
						{
							title: __('companies-and-users.address-book-companies.partner-members'),
							link: () => "/companies/partner_members"
						},
						{
							title: __('companies-and-users.address-book-companies.my-company-members'),
							link: () => "/companies/my/members",
						}
					]
				}, {
					title: __('modules.address-book'),
					link: () => "/address_book/companies",
					active: true,
					items: [
						{
							title: __('modules.address-book.companies'),
							link: () => "/address_book/companies",
							active: true,
						},
						{
							title: __('modules.address-book.contacts'),
							link: () => "/address_book/contacts",
						},
					]
				}
			]
		});

		setPlusActions([{
			title: __('button.add-new-company'),
			onClick: () => setTableParams('address_book_companies', {
				wizard_active: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readAddressBookCompany(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const columns = [
			{
				key: "name",
				name: __('companies-and-users.address-book.companies.add-new-company.name'),
				required: true,
				value: name => <div className={styles.ellipsis}><Tooltip text={name}>{name || <TableNA />}</Tooltip></div>,
			}, {
				key: "address",
				name: __('companies-and-users.address-book.companies.add-new-company.address'),
				value: address => <div className={styles.ellipsis}><Tooltip text={address}>{address || <TableNA />}</Tooltip></div>,
				width: 140,
			}, {
				key: "city",
				name: __('companies-and-users.address-book.companies.add-new-company.city'),
				active: false,
				value: city => <div className={styles.ellipsis}><Tooltip text={city}>{city || <TableNA />}</Tooltip></div>,
				width: 80,
			}, {
				key: "zip",
				name: __('companies-and-users.address-book.companies.add-new-company.zip-code'),
				active: false,
				value: zip => <div className={styles.ellipsis}><Tooltip text={zip}>{zip || <TableNA />}</Tooltip></div>,
				width: 60,
			}, {
				key: "state",
				name: __('companies-and-users.address-book.companies.add-new-company.state'),
				active: false,
				value: state => <div className={styles.ellipsis}><Tooltip text={state}>{state || <TableNA />}</Tooltip></div>,
				width: 70,
			}, {
				key: "country_id",
				name: __('companies-and-users.address-book.companies.add-new-company.country'),
				value: country_id => {
					const country = _.get(this.props.localization.countries, [country_id, 'name']);

					return (
						<div className={styles.ellipsis}><Tooltip text={country}>{country || <TableNA />}</Tooltip></div>
					);
				},
				width: 120,
			}, {
				key: "vat",
				name: __('companies-and-users.address-book.companies.add-new-company.vat'),
				active: false,
				value: vat => <div className={styles.ellipsis}><Tooltip text={vat}>{vat || <TableNA />}</Tooltip></div>,
				width: 120,
			}, {
				key: "emails",
				name: __('companies-and-users.address-book.companies.add-new-company.emails'),
				value: emails => _.isEmpty(emails) ? <TableNA /> : <div className={styles.ellipsis}><Tooltip text={emails.join("\n")}>{emails.join(', ')}</Tooltip></div>,
				width: 180,
				sortable: false,
			}, {
				key: "phones",
				name: __('companies-and-users.address-book.companies.add-new-company.phones'),
				value: phones => _.isEmpty(phones) ? <TableNA /> : <div className={styles.ellipsis}><Tooltip text={phones.join("\n")}>{phones.join(', ')}</Tooltip></div>,
				width: 150,
				sortable: false,
			}, {
				key: "web",
				name: __('companies-and-users.address-book.companies.add-new-company.web'),
				value: web => <Tooltip text={web}><div className={styles.ellipsis}>{web ? linkifyText(web) : <TableNA />}</div></Tooltip>,
				width: 200,
			}, {
				key: "note",
				name: __('companies-and-users.address-book.companies.add-new-company.instruction'),
				active: false,
				width: 200,
				value: note => <div className={styles.ellipsis}><Tooltip text={note}>{note || <TableNA />}</Tooltip></div>,
			}, {
				key: "is_manufacturer",
				name: __('companies-and-users.address-book.companies.add-new-company.manufacturer'),
				value: is_manufacturer => <div className={classNames(styles.chip, is_manufacturer && styles.yes)}>{is_manufacturer ? __('companies-and-users.address-book.companies.add-new-company.manufacturer.yes') : __('companies-and-users.address-book.companies.add-new-company.manufacturer.no')}</div>,
				width: 50,
			}, {
				key: "is_supplier",
				name: __('companies-and-users.address-book.companies.add-new-company.representative'),
				value: is_supplier => <div className={classNames(styles.chip, is_supplier && styles.yes)}>{is_supplier ? __('companies-and-users.address-book.companies.add-new-company.representative.yes') : __('companies-and-users.address-book.companies.add-new-company.representative.no')}</div>,
				width: 50,
			}, {
				key: "is_designer",
				name: __('companies-and-users.address-book.companies.add-new-company.designer'),
				value: is_designer => <div className={classNames(styles.chip, is_designer && styles.yes)}>{is_designer ? __('companies-and-users.address-book.companies.add-new-company.designer.yes') : __('companies-and-users.address-book.companies.add-new-company.designer.no')}</div>,
				width: 50,
			}, {
				key: "is_client",
				name: __('companies-and-users.address-book.companies.add-new-company.client'),
				value: is_client => <div className={classNames(styles.chip, is_client && styles.yes)}>{is_client ? __('companies-and-users.address-book.companies.add-new-company.client.yes') : __('companies-and-users.address-book.companies.add-new-company.client.no')}</div>,
				width: 50,
			}
		];

		const preview = (
			<AddressBookCompaniesFlyout
				data={this.state.preview}
				readAction={address_book_company_id => {
					return new Promise(resolve => {
						readAddressBookCompany(getURLParam('id') || address_book_company_id).then(({response}) => {
							this.setState({preview: response.data});

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listAddressBookCompanies}
				name="address_book_companies"
				title={__('modules.address-book.companies')}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default AddressBookCompanies;
