import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddRawColorWizard.scss";
import autobind from "autobind-decorator";
import AddRawColorIcon from "../../assets/images/add-raw-color-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createRawColor, listRawColors } from "../../redux/actions/table/superadmin/raw_colors";
import AddRawColorForm from "../../forms/superadmin/add_raw_color_wizard/AddRawColorForm";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_raw_colors"],
}))
class AddRawColorWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const { edit } = formData;

        return validateForm(
            createRawColor(formData).then(({ response }) => {
                listRawColors().then(() => {
                    if (edit) {
                        setTableParams('superadmin_raw_colors', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_raw_colors', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                this.props.onClose();

                setNotification("You have successfully added a new filter color.");
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>Add filter color</span>
                        <span>Add filter color to the application</span>
                    </div>
                    <div className={styles.right}>
                        <AddRawColorIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddRawColorForm onSubmit={this._handleSubmit} initialValues={{
                        name: {}
                    }}/>
                </div>
            </>
        );
    }
}

export default AddRawColorWizard;