import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_room_wizard/AddRoomForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";
import {listPossibleStoreys} from "../../redux/actions/table/rooms";
import Select from "../../components/Select";

@withRouter
@reduxForm({form: "add_room_wizard.add_room"})
class AddRoomForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_storeys: [],
        }
    }

    componentDidMount() {
        listPossibleStoreys(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_storeys: response.data});
        });
    }

    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const {possible_storeys} = this.state;

        const indoor_outdoor = [
            {
                label: __('rooms.value.outdoor'),
                value: "1",
            },
            {
                label: __('rooms.value.indoor'),
                value: "0",
            }
        ];

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="code" component={Input} label={__('rooms.field.code')} maxLength={12} />
                        </div>
                        <div className={styles.f4}>
                            <Field name="title" component={Input} localized={this.props.projectLanguages} label={__('rooms.field.title')} maxLength={50} />
                        </div>
                    </div>
                    <Field name="storey_id" component={Select} label={__('rooms.field.storey')} searchable options={_.map(possible_storeys, storey => ({
                        label: storey.name,
                        value: storey.id,
                        group: storey.building
                    }))} />
                    <Field name="description" component={Textarea} localized={this.props.projectLanguages} label={__('rooms.field.description')} maxLength={2000} rows={1} maxRows={10} />
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="outdoor" component={Select} label={__('rooms.field.indoor-outdoor')} options={indoor_outdoor} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="area" component={Input} label={__('rooms.field.area')} numeric decimal maxLength={10} />
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field name="volume" component={Input} label={__('rooms.field.volume')} numeric decimal maxLength={10} />
                        </div>
                        <div className={styles.f1}>
                            <Field name="occupancy" component={Input} label={__('rooms.field.occupancy')} numeric maxLength={10} />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('rooms', {
                        add_room_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddRoomForm;
