import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import Table from '../../components/Table';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import {
  deleteSites,
  listSites,
  readSite,
} from '../../redux/actions/table/sites';
import SitesFlyout from '../../flyouts/sites/SitesFlyout';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import Avatar from '../../components/Avatar';

@mapStateToProps((state) => ({
  table: state.table['sites'],
}))
class Sites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('space-allocation.sites.table.button.create'),
            onClick: () =>
              setTableParams('sites', {
                add_site_wizard: true,
              }),
          },
        ]);
    });

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/sites')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/sites')
    );

    createSidebar({
      title: __('submenu.title.space-allocation'),
      items: [
        {
          title: __('submenu.title.space-allocation.rooms'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/rooms',
        },
        {
          title: __('submenu.title.space-allocation.room-types'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_types',
        },
        {
          title: __('submenu.title.space-allocation.room-groups'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_groups',
        },
        {
          title: __('submenu.title.space-allocation.zones'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/zones',
        },
        {
          title: __('submenu.title.space-allocation.entrances'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/entrances',
        },
        {
          title: __('submenu.title.space-allocation.expansion-joints'),
          link: () =>
            '/stages/' +
            getActiveStage() +
            '/space_allocation/expansion_joints',
        },
        {
          title: __('submenu.title.space-allocation.storeys'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/storeys',
        },
        {
          title: __('submenu.title.space-allocation.buildings'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/buildings',
        },
        {
          title: __('submenu.title.space-allocation.sites'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/sites',
          active: true,
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readSite(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('sites.field.code'),
        default: true,
        width: 100,
      },
      {
        key: 'title',
        name: __('sites.field.title'),
        required: true,
      },
      {
        key: 'created_at',
        name: __('sites.field.created-at'),
        value: (created_at) => (
          <Tooltip text={dateTimeFrom(created_at)}>
            {dateFrom(created_at)}
          </Tooltip>
        ),
        width: 80,
      },
      {
        key: 'created_by',
        name: __('sites.field.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
    ];

    const singleActions = (site_id) => {
      return [
        <Tooltip text={__('sites.tooltip.site-properties')} key={_.uniqueId()}>
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('sites', {
                clicked_row: site_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>,
        <Tooltip text={__('sites.tooltip.delete-site')} key={_.uniqueId()}>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description: __('sites.alert.delete-confirmation'),
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteSites([site_id]).then(() => {
                        hideAlertbox();

                        setNotification(__('sites.alert.deleted'));
                        listSites(this.props.match.params.stage);

                        setTableParams('sites', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (site_ids) => [
      <Tooltip
        text={__('sites.tooltip.delete-selected-sites')}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __('sites.alert.multiple-delete-confirmation'),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteSites(site_ids).then(() => {
                      hideAlertbox();

                      setNotification(__('site.alert.multiple-deleted'));
                      listSites(this.props.match.params.stage);

                      setTableParams('sites', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <SitesFlyout
        data={this.state.preview}
        readAction={(site_id) => {
          return new Promise((resolve) => {
            readSite(getURLParam('id') || site_id).then(({ response }) => {
              this.setState({ preview: response.data });

              resolve(response);
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listSites(this.props.match.params.stage)}
        name='sites'
        title={__('submenu.title.space-allocation.sites')}
        singleActions={singleActions}
        groupActions={groupActions}
        columns={columns}
        preview={this.state.preview ? preview : []}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default Sites;
