const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "table.products/SET_TABLE_PARAMS":
            return {
                ...state,
                ...action.params
            };
        case "table.products/LIST_PRODUCTS_SUCCESS":
            return {
                ...state,
                data: action.response.data,
                ...action.response.pagination
            };
        case "table.products/LIST_PRODUCTS_FILTERS_SUCCESS":
            return {
                ...state,
                filters: action.response.filters,
            };
        default:
            return state;
    }
}
