import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/current_set_specifications/BasicDataForm.scss';
import Input from '../../components/Input';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  transformToListPairs,
} from '../../core/utils';
import classNames from 'classnames';
import Image from '../../components/Image';
import DefaultImage from '../../assets/images/default.png';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import { showLightbox } from '../../redux/actions/general/lightbox';
import Select from '../../components/Select';
import EditIcon from '../../assets/images/edit-16x16.svg';
import {
  listPossibleAddressBookManufacturers,
  listPossibleAddressBookSuppliers,
} from '../../redux/actions/table/address_book_companies';
import { listPossibleSpecificationResponsibleUsers } from '../../redux/actions/table/working_set_specifications';
import PeopleChooser from '../../components/PeopleChooser';

@reduxForm({
  form: 'current_set_specifications.basic_data',
  enableReinitialize: true,
})
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
  application_language_id: state.auth.language_id,
}))
class BasicDataForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_address_book_manufacturers: [],
      possible_address_book_suppliers: [],
      possible_specification_responsible_users: [],
    };
  }

  componentDidMount() {
    const { flyout } = this.props.store;

    if (!flyout.product_id) {
      listPossibleAddressBookManufacturers(flyout.manufacturer_company_id).then(
        ({ response }) => {
          this.setState({ possible_address_book_manufacturers: response.data });
        }
      );
    }

    listPossibleAddressBookSuppliers(flyout.supplier_company_id).then(
      ({ response }) => {
        this.setState({ possible_address_book_suppliers: response.data });
      }
    );

    listPossibleSpecificationResponsibleUsers(
      flyout.stage_id,
      flyout.responsible_user_id
    ).then(({ response }) => {
      this.setState({
        possible_specification_responsible_users: response.data,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { flyout } = this.props.store;

    if (flyout.id != prevProps.store.flyout.id) {
      if (!flyout.product_id) {
        listPossibleAddressBookManufacturers(
          flyout.manufacturer_company_id
        ).then(({ response }) => {
          this.setState({ possible_address_book_manufacturers: response.data });
        });
      }

      listPossibleAddressBookSuppliers(flyout.supplier_company_id).then(
        ({ response }) => {
          this.setState({ possible_address_book_suppliers: response.data });
        }
      );
    }
  }

  render() {
    const { handleSubmit, collapsed } = this.props;
    const { language_id, flyout } = this.props.store;
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const specification_subgroup =
      _.get(specification_subgroups, [flyout.specification_subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};

    const specification_name = getLocalized(
      specification_group.name,
      this.props.application_language_id
    );
    const {
      possible_address_book_manufacturers,
      possible_address_book_suppliers,
      possible_specification_responsible_users,
    } = this.state;

    const thumbnail_images = _.sortBy(
      _.filter(flyout.images, ['category', 'thumbnail']),
      'order'
    );

    const lightbox_images = _.map(thumbnail_images, (image) => ({
      id: image.id,
      stream_url: image.stream_url,
      title: [image.name, image.ext].join('.'),
    }));

    const selected_address_book_supplier_company =
      _.find(possible_address_book_suppliers, [
        'id',
        flyout.supplier_company_id,
      ]) || {};

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.fields}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='code'
                component={Input}
                disabled={true}
                label={__('current-set-specifications.basic-data-form.code')}
              />
            </div>
            <div className={styles.f1} />
            <div className={classNames(styles.f2, styles.specGroup)}>
              <span>
                {__('specifications.current-set.flyout.spec-group-name')}
              </span>
              <div style={{ marginBottom: '9px' }} />
              <span style={{ fontSize: '16px', color: '#666664' }}>
                {specification_name}
              </span>
            </div>
          </div>
          <Field
            name={'description.' + language_id}
            component={Input}
            label={__('current-set-specifications.basic-data-form.description')}
            maxLength={70}
            disabled={true}
          />
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='model'
                component={Input}
                label={__('current-set-specifications.basic-data-form.model')}
                disabled={true}
              />
            </div>
            <div className={styles.f1}>
              {flyout.product_id ? (
                <Field
                  name='brand'
                  component={Input}
                  label={__(
                    'current-set-specifications.basic-data-form.manufacturer'
                  )}
                  disabled={true}
                />
              ) : (
                <Field
                  name='manufacturer_company_id'
                  disabled={true}
                  component={Select}
                  searchable
                  label={__(
                    'current-set-specifications.basic-data-form.manufacturer'
                  )}
                  options={transformToListPairs(
                    possible_address_book_manufacturers
                  )}
                />
              )}
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='supplier_company_id'
                component={Select}
                disabled={true}
                searchable
                label={__(
                  'current-set-specifications.basic-data-form.representative'
                )}
                options={transformToListPairs(possible_address_book_suppliers)}
              />
            </div>
            {flyout.supplier_contact_id && (
              <div className={styles.f1}>
                <Field
                  name='supplier_contact_id'
                  component={Select}
                  disabled={true}
                  searchable
                  label={__(
                    'current-set-specifications.basic-data-form.representative-contact'
                  )}
                  options={transformToListPairs(
                    selected_address_book_supplier_company.contacts
                  )}
                />
              </div>
            )}
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='created_by'
                disabled={true}
                component={PeopleChooser}
                label={__(
                  'current-set-specifications.basic-data-form.created-by'
                )}
                options={[flyout.created_by]}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='updated_by'
                disabled={true}
                component={PeopleChooser}
                label={__(
                  'current-set-specifications.basic-data-form.last-modified-by'
                )}
                options={[flyout.updated_by]}
              />
            </div>
            <div className={styles.f4}>
              <Field
                name='responsible_user_id'
                disabled={true}
                component={Select}
                searchable
                label={__(
                  'current-set-specifications.basic-data-form.responsible'
                )}
                options={transformToListPairs(
                  possible_specification_responsible_users
                )}
              />
            </div>
          </div>
        </div>
        <div className={classNames(styles.f1, styles.image)}>
          <Image default={DefaultImage} src={flyout.thumb_url} />
          <div
            className={classNames(
              styles.actions,
              !_.isEmpty(thumbnail_images) && styles.clickable
            )}
            onClick={() =>
              !_.isEmpty(thumbnail_images)
                ? showLightbox(lightbox_images, _.first(thumbnail_images).id)
                : undefined
            }
          />
        </div>
      </Form>
    );
  }
}

export default BasicDataForm;
