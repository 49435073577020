import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/boq/EditBOQForm.scss';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import {
  __,
  getActiveStage,
  mapStateToProps,
  transformToListPairs,
} from '../../core/utils';
import * as _ from 'lodash';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Select from '../../components/Select';
import {
  listPossibleBOQResponsibleUsers,
  listPossibleResponsibleBOQTeams,
} from '../../redux/actions/table/working_set_boq';

@reduxForm({
  form: 'boq.edit_boq',
  enableReinitialize: true,
})
@withRouter
@mapStateToProps((state) => ({
  table: state.table['working_set_boq'],
  user_id: state.auth.id,
}))
@subscribe(Localization, 'localization')
class EditBOQForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_boq_responsible_users: [],
      possible_boq_responsible_teams: [],
    };
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  componentDidMount() {
    const { flyout } = this.props.table;

    listPossibleBOQResponsibleUsers(
      this.props.match.params.stage,
      flyout?.responsible_user_id
    ).then(({ response }) => {
      this.setState({
        possible_boq_responsible_users: response.data,
      });
    });

    listPossibleResponsibleBOQTeams(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({
          possible_boq_responsible_teams: _.map(response.data, (team) => ({
            value: team.id,
            label:
              team.company.name +
              ' [' +
              [
                team.stage_discipline.code,
                team.stage_discipline.discipline_id == null
                  ? team.stage_discipline.description + '*'
                  : _.get(this.props.localization, [
                      'disciplines',
                      team.stage_discipline.discipline_id,
                      'name',
                    ]),
              ].join(': ') +
              ']',
          })),
        });
      }
    );
  }

  render() {
    const { project_languages } = this.props;
    const { possible_boq_responsible_teams, possible_boq_responsible_users } =
      this.state;

    return (
      <Form onSubmit={this.props.handleSubmit}>
        <div className={styles.form}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='name'
                component={Input}
                localized={project_languages}
                label={__('table.columns.boq-name')}
                maxLength={70}
              />
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='description'
                component={Textarea}
                localized={project_languages}
                label={__('table.columns.boq-description')}
                maxLength={3000}
                rows={1}
                maxRows={10}
              />
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='responsible_user_id'
                component={Select}
                searchable
                label={__('table.columns.boq-responsible_user')}
                options={transformToListPairs(possible_boq_responsible_users)}
              />
            </div>
            <div className={styles.f1}>
              <Field
                name='team_id'
                searchable={true}
                component={Select}
                label={__('table.columns.team_id')}
                options={possible_boq_responsible_teams}
              />
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default EditBOQForm;
