import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddTeamWizard.scss";
import autobind from "autobind-decorator";
import AddTeamIcon from "../assets/images/add-team-60x60.svg";
import {__, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import AddTeamForm from "../forms/add_team_wizard/AddTeamForm";
import {createTeam, listAllTeams} from "../redux/actions/table/teams";

@withRouter
class AddTeamWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		formData = {
			...formData,
			custom_discipline: _.get(formData, 'custom_discipline', false) ? 1 : 0,
			new_company: _.get(formData, 'new_company', false) ? 1 : 0,
		};

		const validation = validateForm(
            createTeam(this.props.match.params.stage, formData).then(() => {
                listAllTeams(this.props.match.params.stage);

				setNotification(__("wizard.add-team.notification.success"));

				this.props.onClose();
			})
		);

		validation.catch(({ errors }) => {
			if (_.has(errors, "code")) {
				if (errors.code === 1) {
					setNotification({
						type: "warning",
						text: __("The team you have selected already exists in the specified stage discipline.")
					});
				}
			}
		});

		return validation;
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.add-team.title.add-new')}</span>
						<span>{__('wizard.add-team.subtitle.add-new')}</span>
					</div>
					<div className={styles.right}>
						<AddTeamIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddTeamForm onSubmit={this._handleSubmit} initialValues={{
						custom_discipline: false,
						new_company: false,
						owner: [
							{
								team_role: 'editor'
							}
						]
					}} />
				</div>
			</>
		);
	}
}

export default AddTeamWizard;
