import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import styles from '../../styles/views/profile/NotificationsSettings.scss';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { __, getLocalizedListValue, mapStateToProps } from '../../core/utils';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import {
  listNotificationActions,
  readProfile,
  updateNotificationsSettings,
} from '../../redux/actions/profile';
import NotificationsSettingsForm from '../../forms/profile/NotificationsSettingsForm';
import autobind from 'autobind-decorator';
import moment from 'moment';

@mapStateToProps((state) => ({
  profile: state.profile,
}))
class NotificationsSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    listActiveModules();

    createSidebar({
      title: __('submenu.title.profile-settings'),
      items: [
        {
          title: __('submenu.title.personal-details'),
          link: () => '/profile/details',
        },
        {
          title: __('submenu.title.security'),
          link: () => '/profile/security',
        },
        {
          title: __('submenu.title.notifications'),
          link: () => '/profile/notifications',
        },
        {
          title: __('submenu.title.notifications.settings'),
          link: () => '/profile/notifications/settings',
          active: true,
        },
      ],
    });

    listNotificationActions().then(({ response }) => {
      this.setState({ actions: response.data });
    });

    readProfile();
  }

  @autobind
  _handleSubmit(formData) {
    const { digest_time, ...filteredFormData } = formData;

    return updateNotificationsSettings({
      ...filteredFormData,
      digest_time: moment(`${digest_time}`, ['HH:mm']).format('HH:mm:ss'),
    });
  }

  render() {
    const { profile } = this.props;
    const { actions } = this.state;

    let alert_notifications = {};
    let email_notifications = {};
    let digest_notifications = {};

    _.each(actions, (action) => {
      alert_notifications[action.name] = action.alert.disabled
        ? action.alert.default
        : _.get(profile.alert_notifications, action.name, action.alert.default);
      email_notifications[action.name] = action.email.disabled
        ? action.email.default
        : _.get(profile.email_notifications, action.name, action.email.default);
      digest_notifications[action.name] = action.digest.disabled
        ? action.digest.default
        : _.get(
            profile.digest_notifications,
            action.name,
            action.digest.default
          );
    });

    return (
      <>
        <div className={styles.header}>
          <div className={styles.title}>
            {/* {profile.title && _.upperFirst(profile.title) + ' '} */}
            {__('submenu.title.notifications.settings')}
          </div>
          <div className={styles.subtitle}>
            {!_.isEmpty(profile.disciplines) &&
              _.map(profile.disciplines, (discipline) =>
                getLocalizedListValue(
                  this.props.localization.disciplines,
                  discipline,
                  'profession'
                )
              ).join(', ') + ' · '}
            {profile.company} · {profile.email}
          </div>
        </div>
        <NotificationsSettingsForm
          actions={actions}
          onSubmit={this._handleSubmit}
          initialValues={{
            digest_time: profile.digest_time,
            alert_notifications,
            email_notifications,
            digest_notifications,
          }}
        />
      </>
    );
  }
}

export default NotificationsSettings;
