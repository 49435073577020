import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/wizards/superadmin/AddBOQTemplateSubgroupWizard.scss';
import autobind from 'autobind-decorator';
import AddBOQTemplateSubgroupIcon from '../../assets/images/add-boq-template-subgroup-60x60.svg';
import {
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../../core/utils';
import { withRouter } from 'react-router';
import { setNotification } from '../../redux/actions/general/notification';
import {
  createBOQTemplateCalculation,
  listBOQTemplateCalculations,
} from '../../redux/actions/table/superadmin/boq_template_calculations';
import AddBOQTemplateCalculationForm from '../../forms/superadmin/add_boq_template_calculations_wizard/AddBOQTemplateCalculationForm';

@withRouter
@mapStateToProps((state) => ({
  table: state.table['superadmin_boq_template_calculations'],
}))
class AddBOQTemplateCalculationWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { edit } = formData;

    return validateForm(
      createBOQTemplateCalculation(formData).then(({ response }) => {
        listBOQTemplateCalculations().then(() => {
          if (edit) {
            setTableParams('superadmin_boq_template_calculations', {
              clicked_row: response.data.id,
            });
          }
        });

        setTableParams('superadmin_boq_template_calculations', {
          actioned_row: undefined,
          selected_rows: [],
        });

        this.props.onClose();

        setNotification(
          'You have successfully added a new BOQ template calculation.'
        );
      })
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>Add BOQ template calculation</span>
            <span>
              Add BOQ template calculation to system BOQ template calculations
            </span>
          </div>
          <div className={styles.right}>
            <AddBOQTemplateSubgroupIcon />
          </div>
        </div>
        <div className={styles.body}>
          <AddBOQTemplateCalculationForm
            onSubmit={this._handleSubmit}
            initialValues={{
              name: {},
              boq_template_subgroup_id: null,
            }}
          />
        </div>
      </>
    );
  }
}

export default AddBOQTemplateCalculationWizard;
