import * as React from "react";
import {Component} from "react";
import styles from "../styles/layouts/AuthLayout.scss";

class AuthLayout extends Component {
	render() {
		return (
			<div className={styles.wrapper}>
				<div className={styles.box}>{this.props.children}</div>
			</div>
		);
	}
}

export default AuthLayout;
