import React, {Component} from "react";
import * as Sentry from '@sentry/browser';

class ExceptionHandler extends Component {
	componentDidCatch(error, errorInfo) {
		process.env.SENTRY_DSN && Sentry.withScope(scope => {
			Object.keys(errorInfo).forEach(key => {
				scope.setExtra(key, errorInfo[key]);
			});
			Sentry.captureException(error);
		});
	}

	render() {
		return this.props.children;
	}
}

export default ExceptionHandler;
