import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/products/Attributes.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../../assets/images/arrow-down-middle-15x15.svg";
import autobind from "autobind-decorator";
import EditIcon from "../../assets/images/edit-16x16.svg";
import { SubmissionError } from "redux-form";
import { __, getLocalized, mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import AttributesForm from "../../forms/products/AttributesForm";
import { deleteProductAttributesGroup, readProduct, reorderProductAttributeGroups, updateProductAttributesGroupFields } from "../../redux/actions/table/products";
import { setNotification } from "../../redux/actions/general/notification";
import CloseMediumIcon from "../../assets/images/close-middle-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import ProductAttributeGroupSaveIcon from "../../assets/images/product-attribute-group-save-16x16.svg";
import Tooltip from "../../components/Tooltip";
import { ReactSortable } from "react-sortablejs";
import GrabberIcon from "../../assets/images/grabber-16x16.svg";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";

@mapStateToProps((state) => ({
	store: state.table.products,
	application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
class Attributes extends Component {
	last_request_id = null;

	constructor(props) {
		super(props);

		this.state = {
			collapsed_groups: [],
			attribute_groups: []
		};
	}

	@autobind
	_toggleCollapseGroup(group) {
		this.setState({
			collapsed_groups: _.xor(this.state.collapsed_groups, [group])
		});
	}

	@autobind
	_handleSubmit(product_attribute_group_id, formData) {
		const request_id = _.uniqueId("request");

		this.last_request_id = request_id;

		const request = updateProductAttributesGroupFields(product_attribute_group_id, formData);

		const validation = request.then(() => {
			if (request_id === this.last_request_id) {
				setNotification(__("product-attribute-group-updated.notification.flyout"));

				readProduct(this.props.store.flyout.id).then(({ response }) => {
					setTableParams("products", {
						flyout: response.data
					});
				});
			}
		});

		return validation.catch(({ response, status }) => {
			if (request_id === this.last_request_id) {
				if (status === 422 && "errors" in response) {
					setNotification({
						text: "Product attributes have not been saved! Please revise the marked fields.",
						type: "warning"
					});

					throw new SubmissionError(
						_.transform(
							response.errors,
							(errors, error, field) => {
								_.set(errors, field, error);
							},
							{}
						)
					);
				}
			}
		});
	}

	@autobind
	_changeOrder(attribute_groups) {
		setTableParams("products", {
			flyout: {
				...this.props.store.flyout,
				attribute_groups: _.map(attribute_groups, (attribute_group, i) => ({
					...attribute_group,
					priority: i
				}))
			}
		});
	}

	render() {
		const { collapsed, store, application_language_id, attribute_groups, is_configurator } = this.props;
		const { flyout } = store;
		const { collapsed_groups } = this.state;

		// let attribute_groups = _.sortBy(
		// 	_.filter(flyout.attribute_groups, (item) => !item.is_configurator),
		// 	"priority"
		// );

		return (
			<div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
				<ReactSortable
					className={styles.attributes}
					list={flyout.attribute_groups}
					setList={this._changeOrder}
					animation={200}
					delayOnTouchStart={true}
					onEnd={() => {
						reorderProductAttributeGroups(flyout.id, _.map(flyout.attribute_groups, "id")).then(() => {
							setNotification(__("product-attributes-group-order.saved.notification"));
						});
					}}
					handle={"." + styles.grabber}
				>
					{_.map(attribute_groups, (group) => {
						const attributes = {};

						_.each(group.attributes, (attribute) => {
							if (_.size(attribute.values) === 1 && _.size(_.flatten(_.map(attribute.parts, "values"))) === 0) {
								const type = attribute.template_field_id ? _.get(this.props.localization.product_attribute_fields, [attribute.template_field_id, "type"]) : attribute.type;

								const value_model = _.get(attribute, "values.0", {});

								// for some reason, when redux receives a id for initial value key
								// it registers as many fields as big key is, i.e. if initial value
								// key was 2704, there would be 2704 registered fields for that form
								// with 1 - 2703 being just empty value.
								const fieldName = `value-${value_model.id}`;

								if (type == "string") {
									attributes[fieldName] = _.size(value_model.value) > 0 ? value_model.value : {};
								} else {
									attributes[fieldName] = value_model.value;
								}
							}
						});

						return (
							<div className={styles.group} key={group.id}>
								<div className={styles.title} onClick={() => this._toggleCollapseGroup(group.id)}>
									<Tooltip text={__("product-attributes-group-reorder.tooltip")}>
										<div className={styles.grabber}>
											<GrabberIcon />
										</div>
									</Tooltip>
									<ArrowDownMiddleIcon className={classNames(styles.collapse, !_.includes(collapsed_groups, group.id) && styles.collapsed)} />
									{getLocalized(group.name, application_language_id)}

									{_.isNull(group.mandatory_part_id) && (
										<>
											<Tooltip text={__("products.attributes.add-attribute-to-group.tooltip")}>
												<PlusMiddleIcon
													className={styles.add}
													onClick={(e) => {
														e.stopPropagation();

														setTableParams("products", {
															add_product_attribute_group_field_wizard: group.id
														});
													}}
												/>
											</Tooltip>
											<Tooltip text={__("products.flyout.attributes.edit-group-name")}>
												<EditIcon
													className={styles.edit}
													onClick={(e) => {
														e.stopPropagation();

														setTableParams("products", {
															edit_product_attribute_group_wizard: group.id
														});
													}}
												/>
											</Tooltip>
											<Tooltip text={__("products.flyout.attributes.save-group-as-template.tooltip")}>
												<ProductAttributeGroupSaveIcon
													className={styles.saveTemplate}
													onClick={(e) => {
														e.stopPropagation();

														setTableParams("products", {
															save_product_attribute_group_as_template_wizard: group.id
														});
													}}
												/>
											</Tooltip>
											<Tooltip text={__("products.flyout.attributes.delete-group.tooltip")}>
												<CloseMediumIcon
													className={styles.delete}
													onClick={(e) => {
														e.stopPropagation();

														showAlertbox({
															color: "red",
															title: __("general.alert.are-you-sure"),
															description: __("flyouts.products.attributes.are-you-sure.delete-attribute-group-will-delete-all-attributes-cannot-undone.alert"),
															buttons: [
																{
																	color: "lightGray",
																	text: __("general.alert.no-close"),
																	onClick: "close"
																},
																{
																	color: "gray",
																	text: __("general.alert.yes-delete"),
																	onClick: () =>
																		deleteProductAttributesGroup(group.id).then(() => {
																			hideAlertbox();

																			readProduct(flyout.id).then(({ response }) => {
																				setTableParams("products", {
																					flyout: response.data
																				});
																			});

																			setNotification(__("products.flyout.attributes.attribute-group-has-been-deleted.notification"));
																		})
																}
															]
														});
													}}
												/>
											</Tooltip>
										</>
									)}
								</div>
								<AttributesForm expanded={!_.includes(collapsed_groups, group.id)} onSubmit={(formData) => this._handleSubmit(group.id, formData)} form={"products.attributes.group" + group.id} groupId={group.id} initialValues={attributes} is_configurator={is_configurator} mandatory_part_id={group.mandatory_part_id} />
							</div>
						);
					})}
				</ReactSortable>

				<div className={styles.addRow}>
					<button
						onClick={() =>
							setTableParams("products", {
								add_product_attribute_group_wizard: flyout.id,
								is_configurator: is_configurator ? 1 : 0
							})
						}
					>
						{__("products.product-attribute-flyout.add-product-group")}
					</button>
				</div>
			</div>
		);
	}
}

export default Attributes;
