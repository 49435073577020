import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_product_note_wizard/EditProductNoteForm.scss';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Textarea from '../../components/Textarea';
import Select from '../../components/Select';
import ButtonGroup from '../../components/ButtonGroup';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

@reduxForm({
  form: 'edit_product_note_wizard.edit_product_note',
  enableReinitialize: true,
})
@mapStateToProps((state, props) => ({
  store: state.table.products,
}))
@subscribe(Localization, 'localization')
@withRouter
class EditProductNoteForm extends Component {
  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  render() {
    const { flyout, language_id } = this.props.store;

    let note_categories = [];

    const languages = _.transform(
      flyout.languages,
      (languages, language) => {
        languages[language] = language == language_id ? 1 : 0;
      },
      {}
    );

    _.each(this.props.localization.note_categories, (note) => {
      note_categories.push({
        value: note.id,
        label: getLocalized(note.name, language_id),
      });
    });

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                name='note_category_id'
                component={Select}
                label={__(
                  'products.edit-product-instruction-form.note_category'
                )}
                options={note_categories}
              />
            </div>
            <div className={styles.f1} />
          </div>

          <Field
            name='note'
            component={Textarea}
            localized={languages}
            label={__('products.edit-product-instruction-form.text')}
            rows={4}
            maxLength={1000}
          />
        </div>

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('products', {
                edit_product_note_wizard: undefined,
              })
            }
          />
          <ButtonGroup right>
            <Button
              lightBlue
              medium
              left
              leftText={__('button.create-new-instruction')}
              rightIcon={ArrowRightMiddleIcon}
              spinning={this.props.submitting}
              onClick={(e) => {
                e.stopPropagation();

                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: __(
                    'products.edit-product-instruction-form.create-new-instruction'
                  ),
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-create'),
                      onClick: () => {
                        this._handleSubmit(false);
                        hideAlertbox();
                      },
                    },
                  ],
                });
              }}
            />
            <Button
              lightBlue
              medium
              right
              leftText={__('button.edit')}
              rightIcon={ArrowRightMiddleIcon}
              spinning={this.props.submitting}
              onClick={(e) => {
                e.stopPropagation();

                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: __(
                    'products.edit-product-instruction-form.edit-instruction'
                  ),
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-edit'),
                      onClick: () => {
                        this._handleSubmit(true);
                        hideAlertbox();
                      },
                    },
                  ],
                });
              }}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default EditProductNoteForm;
