import React, { useState } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/ParentLinkedSpecifications.scss';
import * as _ from 'lodash';
import {
  __,
  formatMoney,
  getLocalized,
  setTableParams,
} from '../../core/utils';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import classNames from 'classnames';

const ParentLinkedSpecifications = ({ localization, flyout, language_id }) => {
  const [collapse, setCollapse] = useState(false);

  const { specification_groups, specification_subgroups, units } = localization;

  const parent_linked_specs = _.sortBy(
    _.map(flyout.parent_linked, (spec) => {
      const specification_subgroup =
        _.get(specification_subgroups, [spec.specification_subgroup_id]) || {};
      const specification_group =
        _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id')
        ) || {};

      const code =
        getLocalized(specification_group.code, flyout.project_language) +
        specification_subgroup.code +
        _.padStart(spec.code, 2, '0');

      return {
        ...spec,
        code,
      };
    }),
    'code'
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.linkedText}>
        {__(
          'specifications.working-set.flyout.specification-linked-following-master-specifications'
        )}
      </div>

      <div className={classNames(styles.table, styles.header)}>
        <span className={styles.expand} />
        <span className={styles.name}>
          {__(
            'working-set-specifications-flyout.parent-linked-specification.specification'
          )}
        </span>
        <span className={styles.quantity}>
          {__(
            'working-set-specifications-flyout.parent-linked-specification.quantity'
          )}
        </span>
        <span className={styles.unit}>
          {__(
            'working-set-specifications-flyout.parent-linked-specification.unit'
          )}
        </span>
        <span className={styles.count}>
          {__(
            'working-set-specifications-flyout.parent-linked-specification.count'
          )}
        </span>
        <span className={styles.total}>
          {__('working-set-specifications-flyout.quantity.total')}
        </span>
      </div>
      <div className={classNames(styles.table, styles.body)}>
        <span className={styles.expand}>
          <ArrowDownMiddleIcon
            className={classNames(collapse && styles.active)}
            onClick={() => setCollapse(!collapse)}
          />
        </span>
        <span className={styles.name}>
          {__(
            'working-set-specifications-flyout.quantity.total-linked-specifications'
          )}
        </span>
        <span className={styles.quantity} />
        <span className={styles.unit}>
          {_.get(units, [_.first(parent_linked_specs)?.unit_id, 'symbol'])}
        </span>
        <span className={styles.count} />
        <span className={styles.total}>
          {formatMoney(
            _.sumBy(
              parent_linked_specs,
              (specification) =>
                specification.included_in_price == 0 &&
                specification.quantity * specification.count
            )
          )}
        </span>
      </div>
      {collapse &&
        _.map(parent_linked_specs, (specification, i) => {
          const specification_title = _.filter(
            [
              _.get(specification.description, language_id),
              specification.model,
              specification.brand,
            ],
            _.identity
          ).join(' / ');

          return (
            <div className={classNames(styles.table)} key={i}>
              <span className={styles.expand}>{i + 1}</span>
              <span
                className={styles.name}
                onClick={() =>
                  setTableParams('working_set_specifications', {
                    clicked_row: specification.id,
                  })
                }
              >
                {`${specification.code}${
                  !_.isEmpty(specification_title) ? ':' : ''
                }${specification_title}`}
              </span>
              {!specification.included_in_price == 0 ? (
                <span className={styles.includedInPrice}>
                  {__(
                    'working-set-specifications-flyout.quantity.included_in_price'
                  )}
                </span>
              ) : (
                <>
                  <span className={styles.quantity}>
                    {specification.included_in_price == 0
                      ? formatMoney(specification.quantity)
                      : 'n/a'}
                  </span>
                  <span className={styles.unit}>
                    {specification.included_in_price == 0 ? (
                      <>
                        {specification.unit_id && (
                          <span>
                            {_.get(units, [specification.unit_id, 'symbol'])}
                          </span>
                        )}
                        {!specification.unit_id && 'n/a'}
                      </>
                    ) : (
                      'n/a'
                    )}
                  </span>
                  <span className={styles.count}>
                    {specification.included_in_price == 0
                      ? specification.count
                      : 'n/a'}
                  </span>
                  <span className={styles.total}>
                    {specification.included_in_price == 0
                      ? formatMoney(
                          specification.quantity * specification.count
                        )
                      : 'n/a'}
                  </span>
                </>
              )}
            </div>
          );
        })}

      {/* <ul className={styles.linkedList}>
        {_.map(parent_linked_specs, (specification, i) => (
          <>
            {i > 0 && <div className={styles.separator}>,</div>}
            <span
              key={i}
              onClick={() =>
                setTableParams('working_set_specifications', {
                  clicked_row: specification.id,
                })
              }
            >
              {`${specification.code} ${
                specification.included_in_price == 0
                  ? '(' + specification.quantity + ')'
                  : ''
              }`}
            </span>
          </>
        ))}
      </ul> */}
    </div>
  );
};

export default ParentLinkedSpecifications;
