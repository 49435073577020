const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.downloader/START_DOWNLOAD":
            return {
                url: action.url,
                fields: action.fields,
                method: action.method,
            };
        case "general.downloader/RESET_DOWNLOAD":
            return {};
        default:
            return state;
    }
}