import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddSpecificationSubgroupWizard.scss";
import autobind from "autobind-decorator";
import AddSpecificationSubgroupIcon from "../../assets/images/add-functional-space-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createSpecificationSubgroup, listSpecificationSubgroups } from "../../redux/actions/table/superadmin/specification_subgroups";
import AddSpecificationSubgroupForm from "../../forms/superadmin/add_specification_subgroups_wizard/AddSpecificationSubgroupForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_specification_subgroups"],
}))
class AddSpecificationSubgroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createSpecificationSubgroup(formData).then(({ response }) => {
				listSpecificationSubgroups().then(() => {
					if (edit) {
						setTableParams('superadmin_specification_subgroups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_specification_subgroups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new specification subgroup.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add specification subgroup</span>
						<span>Add specification subgroup to system specification subgroups</span>
					</div>
					<div className={styles.right}>
						<AddSpecificationSubgroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddSpecificationSubgroupForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
						code: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddSpecificationSubgroupWizard;