import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../../styles/forms/superadmin/add_country_wizard/AddCountryForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import {__, mapStateToProps, setTableParams, transformToListPairs} from "../../../core/utils";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import Localization from "../../../helpers/Localization";
import {subscribe} from "react-contextual";

@subscribe(Localization, "localization")
@withRouter
@reduxForm({form: "superadmin.add_country_wizard.add_country"})
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_country_wizard.add_country")(state),
    auth: state.auth,
}))
class AddCountryForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const {regions} = this.props.localization;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" component={Input} label="Name" />
                    <Field name="region_id" component={Select} searchable options={transformToListPairs(regions)} label="Region" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_countries', {
                        add_country_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddCountryForm;
