import store from "../../../../core/store";

export const listCountries = () => {
	return store.dispatch({
		action: "table.superadmin_countries/LIST_COUNTRIES",
		method: "GET",
		endpoint: "/superadmin/countries",
		main: true
	});
};

export const createCountry = (formData) => {
	return store.dispatch({
		action: "table.superadmin_countries/CREATE_COUNTRY",
		method: "POST",
		endpoint: "/superadmin/countries",
		data: formData
	});
};

export const deleteCountries = (country_ids) => {
	return store.dispatch({
		action: "table.superadmin_countries/DELETE_COUNTRIES",
		method: "DELETE",
		endpoint: "/superadmin/countries",
		data: {
			countries: country_ids
		}
	});
};

export const readCountry = (country_id) => {
	return store.dispatch({
		action: "table.superadmin_countries/READ_COUNTRY",
		method: "GET",
		endpoint: "/superadmin/countries/" + country_id,
		has404: true
	});
};

export const updateCountry = (country_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_countries/UPDATE_COUNTRY",
		method: "PUT",
		endpoint: "/superadmin/countries/" + country_id,
		data: formData
	});
};