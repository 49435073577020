import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddMaterialWizard.scss";
import autobind from "autobind-decorator";
import AddMaterialIcon from "../../assets/images/add-material-60x60.svg";
import {mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import AddMaterialForm from "../../forms/superadmin/add_materials_wizard/AddMaterialForm";
import {createMaterial, listMaterials} from "../../redux/actions/table/superadmin/materials";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_materials"],
}))
class AddMaterialWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const {edit} = formData;

		return validateForm(
			createMaterial(formData).then(({response}) => {
				listMaterials().then(() => {
					if(edit) {
						setTableParams('superadmin_materials', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_materials', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new material.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add material</span>
						<span>Add material to system materials</span>
					</div>
					<div className={styles.right}>
						<AddMaterialIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddMaterialForm onSubmit={this._handleSubmit} initialValues={{ 
						name: {} 
					}} />
				</div>
			</>
		);
	}
}

export default AddMaterialWizard;