import store from "../../../core/store";

export const showDropdown = (name) => {
    return store.dispatch({
        type: "general.dropdown/SHOW_DROPDOWN",
        name
    });
};

export const hideDropdown = () => {
    return store.dispatch({
        type: "general.dropdown/HIDE_DROPDOWN"
    });
};