const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.import_spreadsheet/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };

    default:
      return state;
  }
}
