import store from "../../../core/store";

export const listDocuments = (stage_id) => {
    return store.dispatch({
        action: "table.documents/LIST_DOCUMENTS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/documents",
        main: true
    });
};

export const uploadDocument = (stage_id, file) => {
    const id = _.uniqueId('file');

    store.dispatch({
        type: "table.documents/UPLOAD_DOCUMENT",
        file: {
            ..._.pick(file, ['name', 'size']),
            id,
            progress: 0
        },
    });

    return store.dispatch({
        action: "table.documents/UPLOAD_DOCUMENT",
        method: "POST",
        preloaded: false,
        endpoint: "/stages/" + stage_id + "/documents",
        progress: true,
        id,
        data: {
            file
        }
    });
};

export const hideDocumentUploadFile = (document) => {
    store.dispatch({
        type: "table.documents/HIDE_DOCUMENT_UPLOAD_FILE",
        document
    });
};

export const minimizeDocumentUploads = () => {
    store.dispatch({
        type: "table.documents/MINIMIZE_DOCUMENT_UPLOADS",
    });
};