import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import { __, getActiveProject, getActiveStage } from '../../core/utils';
import { setPlusActions } from '../../redux/actions/general/plus';
import Localization from '../../helpers/Localization';
import { subscribe } from 'react-contextual';
import ImportSpreadsheetTable from '../../components/ImportSpreadsheetTable';
import _ from 'lodash';
import { setNotification } from '../../redux/actions/general/notification';
import { importSpreadsheet } from '../../redux/actions/table/import_spreadsheets';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

const ImportSpreadsheet = ({
  actions,
  table: { data, columns_count, module, data_structure, rows, import_type },
}) => {
  let options = {
    area: [
      {
        value: null,
        label: __('import_spreadsheet.field.none'),
        required: false,
        isVisibleInHierarchyStructure: false,
      },
      {
        value: 'roomCode',
        label: __('import_spreadsheet.field.room_code*'),
        required: true,
        isVisibleInHierarchyStructure: false,
      },
      {
        value: 'roomTitle',
        label: __('import_spreadsheet.field.room_title*'),
        required: true,
        isVisibleInHierarchyStructure: false,
      },
      {
        value: 'storeyNumber',
        label: __('import_spreadsheet.field.storey_number*'),
        required: true,
        isVisibleInHierarchyStructure: false,
      },
      {
        value: 'storeyName',
        label: __('import_spreadsheet.field.storey_name*'),
        required: true,
        isVisibleInHierarchyStructure: false,
      },
      {
        value: 'area',
        label: __('import_spreadsheet.field.area'),
      },
      {
        value: 'fohBoh',
        label: __('import_spreadsheet.field.foh_boh'),
      },
      {
        value: 'building',
        label: __('import_spreadsheet.field.building*'),
        required: true,
        isVisibleInHierarchyStructure: false,
      },
      {
        value: 'indoorOutdoor',
        label: __('import_spreadsheet.field.indoor_outdoor*'),
        required: true,
      },
      {
        value: 'volume',
        label: __('import_spreadsheet.field.volume'),
      },
      {
        value: 'zone',
        label: __('import_spreadsheet.field.zone'),
      },
      {
        value: 'expansionJoint',
        label: __('import_spreadsheet.field.expansion_joint'),
      },
      {
        value: 'type',
        label: __('import_spreadsheet.field.room_type'),
      },
      {
        value: 'entrance',
        label: __('import_spreadsheet.field.entrance'),
      },
    ],
    task: [],
    // bill_of_quantities: [
    //   {
    //     value: 'quantity',
    //     label: __('import_spreadsheet.bill_of_quantities.field.quantity'),
    //     required: true,
    //   },
    //   {
    //     value: 'unit',
    //     label: __('import_spreadsheet.bill_of_quantities.field.unit'),
    //     required: true,
    //   },
    //   {
    //     value: 'price',
    //     label: __('import_spreadsheet.bill_of_quantities.field.price'),
    //     required: true,
    //   },
    // ],
    address_book: [],
  };

  useEffect(() => {
    setPlusActions([
      {
        title: __('import_spreadsheet.table.button.create'),
        onClick: () =>
          actions.setTableParams({
            wizard_active: true,
            is_boq_page: false,
          }),
      },
    ]);

    createSidebar({
      title: __('submenu.title.project'),
      items: [
        {
          title: __('submenu.title.stages'),
          link: () => '/projects/' + getActiveProject() + '/stages',
        },
        {
          title: __('submenu.title.teams'),
          link: () => '/stages/' + getActiveStage() + '/teams',
        },
        {
          title: __('submenu.title.approvers'),
          link: () => '/stages/' + getActiveStage() + '/approvers',
        },
        {
          title: __('submenu.title.project-properties'),
          link: () => '/projects/' + getActiveProject() + '/general',
        },
        {
          title: __('submenu.title.import_spreadsheet'),
          link: () => '/import/' + getActiveStage(),
          active: true,
        },
      ],
    });

    actions.setTableParams({
      module: undefined,
      data_structure: undefined,
      rows: [],
    });
  }, []);

  const isHierarchyStructure = data_structure === 'hierarchy';

  const _handleSubmit = (data) => {
    const headerRow = _.first(data)?.headerRow;

    let rowsData = [];

    !isHierarchyStructure
      ? _.each(
          _.filter(_.slice(data, 1), (row) => row.checked),
          (row) => {
            let rowObject = {};

            _.each(headerRow, (header, i) => {
              rowObject = { ...rowObject, [header.value]: row[i - 1] || '' };
            });

            rowsData.push(rowObject);
          }
        )
      : _.each(_.slice(data, 1), (row) => {
          let rowObject = {};

          _.each(headerRow, (header, i) => {
            rowObject = { ...rowObject, [header.value]: row[i] || '' };
          });

          rowsData.push(rowObject);
        });

    showAlertbox({
      color: 'blue',
      title: __('import-spreadsheet.alert.title'),
      description: __('import-spreadsheet.alert.import-confirmation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-import'),
          onClick: () => {
            setNotification(__('import_spreadsheet.importing'));

            const formData = {
              data: rowsData,
              module,
              data_structure,
              import_type,
            };

            importSpreadsheet(getActiveStage(), formData).then(() => {
              actions.setTableParams({
                module: undefined,
                data_structure: undefined,
                import_type: '',
                rows: [],
              });
            });

            hideAlertbox();
          },
        },
      ],
    });
  };

  options = _.filter(
    options[module],
    (option) =>
      _.isUndefined(option.isVisibleInHierarchyStructure) ||
      (isHierarchyStructure
        ? option.isVisibleInHierarchyStructure
        : !option.isVisibleInHierarchyStructure)
  );

  return (
    <ImportSpreadsheetTable
      name='import_spreadsheet'
      data={rows}
      isHierarchyStructure={isHierarchyStructure}
      title={__('import_spreadsheet')}
      columns_count={columns_count}
      options={options}
      _handleSubmit={(data) => _handleSubmit(data)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['import_spreadsheet'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.import_spreadsheet/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(subscribe(Localization, 'localization')(ImportSpreadsheet));
