import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/current_set_specifications/Drawings.scss";
import * as _ from "lodash";
import classNames from "classnames";
import {__, mapStateToProps} from "../../core/utils";
import PDFIcon from "../../assets/images/pdf-16x16.svg";

@mapStateToProps(state => ({
    flyout: state.table.current_set_specifications.flyout
}))
class Drawings extends Component {
    render() {
        const {collapsed, flyout} = this.props;

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                <div className={styles.header}>
                    <span className={styles.letter}>{__('specifications.current-set.flyout.drawings.letter')}</span>
                    <span className={styles.file}>{__('specifications.current-set.flyout.drawings.file')}</span>
                </div>
                {_.map(flyout.drawings, (file, i) => (
                    <div className={styles.row} key={i}>
                        <div className={styles.letter}>
                            {file.letter == '-' ? '--' : _.toUpper(file.letter)}
                        </div>
                        <div className={styles.file} onClick={() => window.open(file.stream_url, '_blank')}><PDFIcon /> {[file.name, file.ext].join('.')}</div>
                    </div>
                ))}
            </div>
        );
    }
}

export default Drawings;