import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/CurrentSetSpecificationsFlyout.scss';
import {
  __,
  getLocalized,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import Tooltip from '../../components/Tooltip';
import classNames from 'classnames';
import GlobeIcon from '../../assets/images/globe-16x16.svg';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import OriginProductIcon from '../../assets/images/product-origin-16x16.svg';
import Select from '../../components/Select';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import autobind from 'autobind-decorator';
import BasicDataForm from '../../forms/current_set_specifications/BasicDataForm';
import TagsForm from '../../forms/current_set_specifications/TagsForm';
import Files from './Files';
import Images from './Images';
import Attributes from './Attributes';
import Notes from './Notes';
import PrintIcon from '../../assets/images/print-16x16.svg';
import Revisions from './Revisions';
import Drawings from './Drawings';
import LinkedSpecifications from '../current_set_specifications/LinkedSpecifications';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-24x24.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-24x24.svg';
import CurrentSetSpecificationToolbarForm from '../../forms/current_set_specifications/CurrentSetSpecificationToolbarForm';
import Quantities from './Quantities';

@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
  auth: state.auth,
  tab_opened: _.get(state.table, ['current_set_specifications', 'tab']),
}))
@subscribe(Localization, 'localization')
class CurrentSetSpecificationsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language_switcher: false,
      collapsed_tabs: ['properties'], //fix for scrollSpy not working on init
      highlighted_tab: null,
    };

    setTableParams('current_set_specifications', {
      language_id: _.findKey(
        props.store.flyout.languages,
        (primary) => primary == true
      ),
    });

    this.highlight_timeout = null;

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();

    this.setState({
      collapsed_tabs: [],
    });

    if (this.props.tab_opened) {
      setTimeout(() => {
        this.tab_refs[this.props.tab_opened] &&
          this.tab_refs[this.props.tab_opened].click();

        setTableParams('current_set_specifications', {
          tab: undefined,
        });
      }, 500);
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    const all_tabs = [
      'properties',
      'attributes',
      'revisions',
      'drawings',
      'tags',
    ];

    if (!_.isEmpty(this.props.store.flyout.images)) {
      all_tabs.push('images');
    }

    if (!_.isEmpty(this.props.store.flyout.files)) {
      all_tabs.push('files');
    }

    if (!_.isEmpty(this.props.store.flyout.quantity)) {
      all_tabs.push('quantity');
    }

    if (!_.isEmpty(this.props.store.flyout.notes)) {
      all_tabs.push('clauses');
    }

    if (
      !_.isEmpty(this.props.store.flyout.linked) ||
      !_.isEmpty(this.props.store.flyout.parent_linked)
    ) {
      all_tabs.push('linked-specifications');
    }

    if (_.size(this.state.collapsed_tabs) == _.size(all_tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: all_tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  render() {
    const { auth } = this.props;
    const { language_id, flyout } = this.props.store;

    const { language_switcher, collapsed_tabs, highlighted_tab } = this.state;

    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const languages = _.map(_.keys(flyout.languages), (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      code: _.get(this.props.localization.languages, [language_id, 'code']),
      value: language_id,
    }));

    const specification_subgroup =
      _.get(specification_subgroups, [flyout.specification_subgroup_id]) || {};
    const specification_group =
      _.get(
        specification_groups,
        _.get(specification_subgroup, 'specification_group_id')
      ) || {};

    const specification_code =
      getLocalized(specification_group.code, flyout.project_language) +
      specification_subgroup.code +
      _.padStart(flyout.code, 2, '0');

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    const specification_title = _.filter(
      [_.get(flyout.description, language_id), flyout.model, flyout.brand],
      _.identity
    ).join(' / ');

    const has_linked_specifications =
      !_.isEmpty(flyout.linked) || !_.isEmpty(flyout.parent_linked);

    const has_quantity = !_.isEmpty(flyout.quantity);

    const has_notes =
      !_.isEmpty(flyout.notes) || !_.isEmpty(flyout.project_note);

    let total_tabs;

    total_tabs = 7;

    !_.isEmpty(flyout.images) && total_tabs++;
    !_.isEmpty(flyout.files) && total_tabs++;
    has_linked_specifications && total_tabs++;
    has_quantity && total_tabs++;
    has_notes && total_tabs++;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              {flyout.product_id ? (
                <Tooltip
                  text={__(
                    'specifications.current-set-specifications.specification-created-from-product'
                  )}
                >
                  <SpecificationFromProductIcon className={styles.type} />
                </Tooltip>
              ) : (
                <Tooltip
                  text={__(
                    'specifications.current-set-specifications.specification'
                  )}
                >
                  <SpecificationByArchitectIcon className={styles.type} />
                </Tooltip>
              )}
              <strong>
                {specification_code}
                {!_.isEmpty(specification_title) ? ':' : ''}
              </strong>
              <span>{specification_title}</span>
            </div>
            <Tooltip
              text={__(
                'specifications.current-set.flyout.close-specification-flyout'
              )}
            >
              <CloseBigIcon
                className={styles.close}
                onClick={() =>
                  setTimeout(() => {
                    setTableParams('current_set_specifications', {
                      clicked_row: undefined,
                    });
                  }, 100)
                }
              />
            </Tooltip>
          </div>
          <div className={styles.actions}>
            {flyout.product_id && (
              <Tooltip
                text={__(
                  'current-set-specifications-flyout.view-product-in-catalog'
                )}
              >
                <OriginProductIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    redirect('/products?id=' + flyout.product_id);
                  }}
                />
              </Tooltip>
            )}
            <Tooltip
              text={__('current-set-specifications-flyout.print-specification')}
            >
              <PrintIcon
                onClick={(e) => {
                  e.stopPropagation();

                  setTableParams('current_set_specifications', {
                    print_specifications_wizard: 'print_selected',
                    specification_ids: [flyout.id],
                  });
                }}
              />
            </Tooltip>
          </div>
        </div>
        <div className={styles.toolbar}>
          <Tooltip
            placement='left'
            text={__(
              'current-set-specifications-flyout.this-specification-is-in-current-set'
            )}
          >
            <div className={classNames(styles.baloon, styles.set)}>
              {__('specifications.current-set.title.current-set')}
            </div>
          </Tooltip>
          {flyout.product_id && (
            <Tooltip
              placement='left'
              text={__(
                'current-set-specifications-flyout.specification-created-from-catalog-product'
              )}
            >
              <div className={classNames(styles.baloon, styles.catalog)}>
                {__('specifications.current-set.flyout.source-catalog')}
              </div>
            </Tooltip>
          )}
          {!flyout.product_id &&
            auth.company.id == flyout.created_by.company_id && (
              <Tooltip
                placement='left'
                text={__(
                  'current-set-specifications-flyout.specification-created-your company'
                )}
              >
                <div className={classNames(styles.baloon, styles.myCompany)}>
                  {__(
                    'specifications.current-set.flyout.source-source-my-company'
                  )}
                </div>
              </Tooltip>
            )}
          {!flyout.product_id &&
            auth.company.id != flyout.created_by.company_id && (
              <Tooltip
                placement='left'
                text={__(
                  'current-set-specifications-flyout.specification-created-your-partners-company'
                )}
              >
                <div
                  className={classNames(styles.baloon, styles.partnerCompany)}
                >
                  {__(
                    'specifications.current-set.flyout.source-partner-company'
                  )}
                </div>
              </Tooltip>
            )}
          {!!flyout.discontinued && (
            <Tooltip
              text={__(
                'current-set-specifications-flyout.product-discontinued'
              )}
            >
              <div className={styles.discontinued}>
                {__('specifications.current-set.flyout.discontinued')}
              </div>
            </Tooltip>
          )}

          <CurrentSetSpecificationToolbarForm
            onSubmit={this._handleSubmit}
            initialValues={{
              ..._.pick(flyout, ['status', 'id']),
            }}
          />

          {language_id && (
            <div className={styles.language}>
              <Select
                input={{
                  value: language_id,
                  onChange: (language_id) =>
                    setTableParams('current_set_specifications', {
                      language_id,
                    }),
                  onBlur: () => this.setState({ language_switcher: false }),
                }}
                meta={{
                  active: language_switcher,
                }}
                label={__(
                  'current-set-specifications-flyout.specification-language'
                )}
                options={languages}
                left={-75}
                top={40}
                noSort
                dropdown
                icon={<GlobeIcon className={styles.globeIcon} />}
                wrapper={(handleOpen, activeItem) => (
                  <Tooltip
                    text={__(
                      'current-set-specifications-flyout.change-specification-language'
                    )}
                  >
                    <div
                      className={styles.languageSwitcher}
                      onClick={() => this.setState({ language_switcher: true })}
                    >
                      <GlobeIcon className={styles.icon} />
                      {_.get(activeItem, 'code')}
                      <ArrowDownSmallIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                )}
              />
            </div>
          )}
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == total_tabs && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>
              {__('current-set-specifications.flyout.tabs.properties')}
            </Tab>
            <Tab target='attributes'>
              {__('current-set-specifications.flyout.tabs.attributes')}
            </Tab>
            {has_notes && (
              <Tab target='clauses'>
                {__('current-set-specifications.flyout.tabs.instructions')}
              </Tab>
            )}
            {has_quantity && (
              <Tab target='quantity'>
                {__('current-set-specifications.flyout.tabs.quantity')}
              </Tab>
            )}
            {has_linked_specifications && (
              <Tab target='linked-specifications'>
                {__(
                  'current-set-specifications.flyout.tabs.linked-specifications'
                )}
              </Tab>
            )}
            <Tab target='drawings'>
              {__('current-set-specifications.flyout.tabs.drawings')}{' '}
              {!_.isEmpty(flyout.drawings) &&
                ' (' + _.size(flyout.drawings) + ')'}
            </Tab>
            <Tab target='revisions'>
              {__('current-set-specifications.flyout.tabs.revisions')}
            </Tab>

            {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) && (
              <Tab target='files'>
                {__('current-set-specifications.flyout.tabs.files')}
                {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) &&
                  ' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
              </Tab>
            )}
            <Tab target='tags'>
              {__('current-set-specifications.flyout.tabs.tags')}
            </Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('current-set-specifications.flyout.tabs.properties')}
            </div>
            <BasicDataForm
              collapsed={_.includes(collapsed_tabs, 'properties')}
              initialValues={{
                ..._.pick(flyout, [
                  'model',
                  'description',
                  'brand',
                  'manufacturer_company_id',
                  'supplier_company_id',
                  'supplier_contact_id',
                  'responsible_user_id',
                ]),
                created_by: [flyout.created_by],
                updated_by: [flyout.updated_by],
                code:
                  getLocalized(
                    _.get(specification_groups, [
                      _.get(specification_subgroups, [
                        flyout.specification_subgroup_id,
                        'specification_group_id',
                      ]),
                      'code',
                    ]),
                    flyout.project_language
                  ) +
                  _.get(specification_subgroups, [
                    flyout.specification_subgroup_id,
                    'code',
                  ]) +
                  _.padStart(flyout.code, 2, '0'),
              }}
            />
          </Element>
          <Element
            name='attributes'
            className={classNames(
              styles.section,
              highlighted_tab == 'attributes' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('attributes')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'attributes') && styles.collapsed
                )}
              />
              {__('current-set-specifications.flyout.tabs.attributes')}
            </div>
            <Attributes collapsed={_.includes(collapsed_tabs, 'attributes')} />
          </Element>
          {has_notes && (
            <Element
              name='clauses'
              className={classNames(
                styles.section,
                highlighted_tab == 'clauses' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('clauses')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'clauses') && styles.collapsed
                  )}
                />
                {__('current-set-specifications.flyout.tabs.instructions')}
              </div>
              <Notes collapsed={_.includes(collapsed_tabs, 'clauses')} />
            </Element>
          )}
          {has_quantity && (
            <Element
              name='quantity'
              className={classNames(
                styles.section,
                highlighted_tab == 'quantity' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('quantity')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'quantity') && styles.collapsed
                  )}
                />
                {__('current-set-specifications.flyout.tabs.quantity')}
              </div>
              <Quantities collapsed={_.includes(collapsed_tabs, 'quantity')} />
            </Element>
          )}
          {has_linked_specifications && (
            <Element
              name='linked-specifications'
              className={classNames(
                styles.section,
                highlighted_tab == 'linked-specifications' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('linked-specifications')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'linked-specifications') &&
                      styles.collapsed
                  )}
                />
                {__(
                  'current-set-specifications.flyout.tabs.linked-specifications'
                )}
              </div>
              <LinkedSpecifications
                collapsed={_.includes(collapsed_tabs, 'linked-specifications')}
              />
            </Element>
          )}
          <Element
            name='drawings'
            className={classNames(
              styles.section,
              highlighted_tab == 'drawings' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('drawings')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'drawings') && styles.collapsed
                )}
              />
              {__('current-set-specifications.flyout.tabs.drawings')}
            </div>
            <Drawings collapsed={_.includes(collapsed_tabs, 'drawings')} />
          </Element>
          <Element
            name='revisions'
            className={classNames(
              styles.section,
              highlighted_tab == 'revisions' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('revisions')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'revisions') && styles.collapsed
                )}
              />
              {__('current-set-specifications.flyout.tabs.revisions')}
            </div>
            <Revisions collapsed={_.includes(collapsed_tabs, 'revisions')} />
          </Element>
          {/* {!_.isEmpty(flyout.images) && (
            <Element
              name='images'
              className={classNames(
                styles.section,
                highlighted_tab == 'images' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('images')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'images') && styles.collapsed
                  )}
                />
                {__('current-set-specifications.flyout.tabs.images')}
              </div>
              <Images collapsed={_.includes(collapsed_tabs, 'images')} />
            </Element>
          )} */}
          {(!_.isEmpty(flyout.files) || !_.isEmpty(flyout.images)) && (
            <Element
              name='files'
              className={classNames(
                styles.section,
                highlighted_tab == 'files' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('files')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'files') && styles.collapsed
                  )}
                />
                {__('current-set-specifications.flyout.tabs.files')}
              </div>
              <Files collapsed={_.includes(collapsed_tabs, 'files')} />
            </Element>
          )}
          <Element
            name='tags'
            className={classNames(
              styles.section,
              highlighted_tab == 'tags' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('tags')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'tags') && styles.collapsed
                )}
              />
              {__('current-set-specifications.flyout.tabs.tags')}
            </div>
            <TagsForm
              key={flyout.id}
              collapsed={_.includes(collapsed_tabs, 'tags')}
              initialValues={_.pick(flyout, ['tags'])}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default CurrentSetSpecificationsFlyout;
