import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../../styles/forms/superadmin/add_unit_wizard/AddUnitForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { __, mapStateToProps, setTableParams, getLocalized } from "../../../core/utils";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";
import Localization from "../../../helpers/Localization";
import { subscribe } from "react-contextual";

@subscribe(Localization, "localization")
@withRouter
@reduxForm({ form: "superadmin.add_unit_wizard.add_unit" })
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_unit_wizard.add_unit")(state),
    auth: state.auth,
    application_language_id: state.auth.language_id
}))
class AddUnitForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == this.props.auth.language_id;

            return languages;
        }, {});

        const unit_groups = _.sortBy(_.map(this.props.localization.unit_groups, item => ({
            value: item.id,
            priority: item.priority,
            label: getLocalized(item.name, this.props.application_language_id),
        })), 'priority');

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" localized={application_languages} component={Input} label="Name" />
                    <Field name="symbol" localized={application_languages} component={Input} label="Symbol" />
                    <Field name="description" localized={application_languages} component={Input} label="Description" />
                    <Field name="coefficient" numeric decimal component={Input} decimals={9} label="Coefficient" />
                    <Field name="unit_group_id" component={Select} searchable noSort options={unit_groups} label="Unit Group" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_units', {
                        add_unit_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddUnitForm;
