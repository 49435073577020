import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditLinkedSpecificationWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { withRouter } from 'react-router';
import {
  readProduct,
  updateProductFileCategory,
} from '../redux/actions/table/products';
import { setNotification } from '../redux/actions/general/notification';
import * as _ from 'lodash';
import AddDocumentIcon from '../assets/images/add-document-60x60.svg';
import EditSpecificationFileCategoryForm from '../forms/edit_specification_file_category_wizard/EditSpecificationFileCategoryForm';
import EditProductFileCategoryForm from '../forms/edit_product_file_category_wizard/EditProductFileCategoryForm';

@mapStateToProps((state) => ({
  store: _.get(state.table, 'products'),
}))
@withRouter
class EditProductFileCategoryWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const product_id = this.props.store.flyout.id;
    const attachment_id = this.props.store.attachment_id;

    return validateForm(
      updateProductFileCategory(product_id, {
        category: formData.category,
        attachment_id: attachment_id,
      }).then(() =>
        readProduct(this.props.store.flyout.id).then(({ response }) => {
          setNotification('A product file category has been updated.');

          setTableParams('products', {
            flyout: response.data,
          });

          this.props.onClose();
        })
      )
    );
  }

  render() {
    const { edit_product_file_category } = this.props.store;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('wizard.products.title.edit-specification-file-category')}
            </span>
            <span>
              {__('wizard.products.subtitle.edit-specification-file-category')}
            </span>
          </div>
          <div className={styles.right}>
            <AddDocumentIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditProductFileCategoryForm
            onSubmit={this._handleSubmit}
            initialValues={{ category: edit_product_file_category }}
          />
        </div>
      </>
    );
  }
}

export default EditProductFileCategoryWizard;
