import * as React from 'react';
import { Component, Fragment } from 'react';
import MainLayout from '../../layouts/MainLayout';
import styles from '../../styles/views/teams/Teams.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  getURLParam,
  hasURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
  setURLParam,
} from '../../core/utils';
import EditIcon from '../../assets/images/edit-16x16.svg';
import ResendIcon from '../../assets/images/resend-15x15.svg';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import DeactivateIcon from '../../assets/images/deactivate-15x15.svg';
import ActivateIcon from '../../assets/images/activate-15x15.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  deactivateTeamMember,
  deleteCompanyJoinInvitation,
  deleteCompanyJoinRequest,
  deleteCompanyJoinSuggestion,
  deleteTeam,
  deleteTeamMemberInvitation,
  deleteVirtualCompanyInvitation,
  getTeamsPermissions,
  listAllTeams,
  reactivateTeamMember,
  resendCompanyJoinInvitation,
  resendCompanyJoinRequest,
  resendCompanyJoinSuggestion,
  resendTeamMemberInvitation,
  resendVirtualCompanyInvitation,
  updateCompanyJoinInvitationRole,
  updateCompanyJoinRequestRole,
  updateCompanyJoinSuggestionRole,
  updateTeamMemberInvitationRole,
  updateTeamMemberRole,
  updateVirtualCompanyInvitationRole,
} from '../../redux/actions/table/teams';
import UserAddIcon from '../../assets/images/user-add-16x16.svg';
import {
  removePlusActions,
  setPlusActions,
} from '../../redux/actions/general/plus';
import {
  createSidebar,
  toggleSidebarCollapse,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import classNames from 'classnames';
import TeamIcon from '../../assets/images/team-15x15.svg';
import UserMaleIcon from '../../assets/images/user-male-15x15.svg';
import Dropdown from '../../components/Dropdown';
import Tooltip from '../../components/Tooltip';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import * as _ from 'lodash';
import Avatar from '../../components/Avatar';

@mapStateToProps((state) => ({
  teams: state.table['teams'],
}))
class Teams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded_teams: [],
    };
  }

  componentDidMount() {
    removePlusActions();

    setTableParams('teams', {
      filters: undefined,
      'filter.discipline': hasURLParam('filter.discipline')
        ? getURLParam('filter.discipline')
        : undefined,
    });

    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({
        is_project_administrator: _.get(response.data, 'administrator'),
      });

      enableProjectsDropdown(({ stage_id }) =>
        redirect('/stages/' + stage_id + '/teams')
      );
      enableStagesDropdown(({ stage_id }) =>
        redirect('/stages/' + stage_id + '/teams')
      );

      createSidebar({
        title: __('submenu.title.project'),
        items: [
          {
            title: __('submenu.title.stages'),
            link: () => '/projects/' + getActiveProject() + '/stages',
          },
          {
            title: __('submenu.title.teams'),
            link: () =>
              '/stages/' + getActiveStage() + '/teams?filter.discipline=',
            active: true,
            expanded: true,
            onArrowClick: () => toggleSidebarCollapse('items.1'),
          },
          {
            title: __('submenu.title.approvers'),
            link: () => '/stages/' + getActiveStage() + '/approvers',
          },
          {
            title: __('submenu.title.project-properties'),
            link: () => '/projects/' + getActiveProject() + '/general',
          },
          {
            title: __('submenu.title.import_spreadsheet'),
            link: () => '/import/' + getActiveStage(),
          },
        ],
      });

      if (!hasURLParam('filter.discipline')) {
        setURLParam('filter.discipline', '');
      }

      listAllTeams(this.props.match.params.stage).then(() => {
        listActiveModules();

        this.setState({ expanded_teams: _.map(this.props.teams.data, 'id') });
      });

      this.state.is_project_administrator &&
        setPlusActions([
          {
            name: __('project-settings.teams.add-new.team'),
            icon: <TeamIcon />,
            onClick: () =>
              setTableParams('teams', {
                add_team_wizard_active: true,
              }),
          },
          {
            name: __('project-settings.teams.add-new.team-members'),
            icon: <UserMaleIcon />,
            onClick: () =>
              setTableParams('teams', {
                add_team_members_wizard_active: true,
              }),
          },
        ]);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search != this.props.location.search) {
      hasURLParam('filter.discipline') &&
        setTableParams('teams', {
          'filter.discipline': getURLParam('filter.discipline'),
        });

      listAllTeams(this.props.match.params.stage);
    }

    //if there are new filter options available, update sidebar
    if (this.props.teams.filters) {
      const items = _.get(this.props.teams.filters, 'discipline.items');

      const all_ids = _.keys(items);

      updateSidebar('items.1.items', [
        {
          title: __('submenu.title.all-disciplines'),
          onClick: () => setURLParam('filter.discipline', ''),
          active: () => {
            const active_disciplines = _.split(
              getURLParam('filter.discipline'),
              '|'
            );

            const intersected = _.intersection(
              active_disciplines,
              _.keys(items)
            );

            return (
              _.isEmpty(getURLParam('filter.discipline')) ||
              _.size(intersected) == _.size(items)
            );
          },
          checkbox: {
            status: () => {
              const active_disciplines = _.split(
                getURLParam('filter.discipline'),
                '|'
              );

              const intersected = _.intersection(
                active_disciplines,
                _.keys(items)
              );

              if (_.isEmpty(getURLParam('filter.discipline'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(items)) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_disciplines = _.split(
                getURLParam('filter.discipline'),
                '|'
              );

              const intersected = _.intersection(
                active_disciplines,
                _.keys(items)
              );

              if (
                _.size(intersected) == _.size(items) ||
                _.isEmpty(getURLParam('filter.discipline'))
              ) {
                setURLParam('filter.discipline', 'n/a');
              } else {
                setURLParam('filter.discipline', '');
              }
            },
          },
        },
        ..._.map(items, (discipline, discipline_id) => ({
          title: discipline,
          onClick: () => setURLParam('filter.discipline', discipline_id),
          active: () => {
            const active_disciplines = _.split(
              getURLParam('filter.discipline'),
              '|'
            );

            return (
              _.isEmpty(getURLParam('filter.discipline')) ||
              _.includes(active_disciplines, discipline_id)
            );
          },
          checkbox: {
            status: () => {
              const active_disciplines = _.split(
                getURLParam('filter.discipline'),
                '|'
              );

              if (_.isEmpty(getURLParam('filter.discipline'))) {
                return 'checked';
              }

              return _.includes(active_disciplines, discipline_id)
                ? 'checked'
                : 'unchecked';
            },
            onClick: () => {
              const setDisciplines = (disciplines) => {
                disciplines = _.trim(
                  _.join(
                    _.reject(disciplines, (discipline) => discipline == 'n/a'),
                    '|'
                  ),
                  '|'
                );

                setURLParam(
                  'filter.discipline',
                  _.isEmpty(disciplines) ? 'n/a' : disciplines
                );
              };

              const active_disciplines =
                getURLParam('filter.discipline') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.discipline'), '|');

              if (_.includes(active_disciplines, discipline_id)) {
                setDisciplines(
                  _.reject(active_disciplines, (row) => row == discipline_id)
                );
              } else {
                active_disciplines.push(discipline_id);

                setDisciplines(active_disciplines);
              }
            },
          },
        })),
      ]);
    }
  }

  @autobind
  _deleteTeam(team_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('project-settings.teams.alert.want-delete-this team'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-remove'),
          onClick: () => {
            deleteTeam(team_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification(
                __(
                  'project-settings.teams.notification.team-successfully-deleted'
                )
              );
            });
          },
        },
      ],
    });
  }

  @autobind
  _deactivateMember(team_id, user_id) {
    showAlertbox({
      color: 'blue',
      title: __('general.alert.are-you-sure'),
      description: __(
        'project-settings.teams.alert.want-deactivate-this-member'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-deactivate'),
          onClick: () => {
            deactivateTeamMember(team_id, user_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification(
                __(
                  'project-settings.teams.notification.team-member-deactivated'
                )
              );
            });
          },
        },
      ],
    });
  }

  @autobind
  _reactivateMember(team_id, user_id) {
    showAlertbox({
      color: 'blue',
      title: __('general.alert.are-you-sure'),
      description: __(
        'project-settings.teams.alert.want-reactivate-this-member'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-reactivate'),
          onClick: () => {
            reactivateTeamMember(team_id, user_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification(
                __(
                  'project-settings.teams.notification.team-member-reactivated'
                )
              );
            });
          },
        },
      ],
    });
  }

  @autobind
  _deleteMemberInvitation(team_member_invitation_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __(
        'project-settings.teams.alert.want-delete-member-invitation'
      ),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-remove'),
          onClick: () => {
            deleteTeamMemberInvitation(team_member_invitation_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification(
                __(
                  'project-settings.teams.notification.team-member-invitation-successfully-deleted'
                )
              );
            });
          },
        },
      ],
    });
  }

  @autobind
  _deleteCompanyJoinInvitation(company_join_invitation_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('general.alert.delete-company-invitation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-remove'),
          onClick: () => {
            deleteCompanyJoinInvitation(company_join_invitation_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification('The invitation has been successfully deleted.');
            });
          },
        },
      ],
    });
  }

  @autobind
  _deleteCompanyJoinSuggestion(company_join_suggestion_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('general.alert.delete-company-invitation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-remove'),
          onClick: () => {
            deleteCompanyJoinSuggestion(company_join_suggestion_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification('The invitation has been successfully deleted.');
            });
          },
        },
      ],
    });
  }

  @autobind
  _deleteCompanyJoinRequest(company_join_request_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('general.alert.delete-company-invitation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-remove'),
          onClick: () => {
            deleteCompanyJoinRequest(company_join_request_id).then(() => {
              listAllTeams(this.props.match.params.stage);

              hideAlertbox();

              setNotification('The invitation has been successfully deleted.');
            });
          },
        },
      ],
    });
  }

  @autobind
  _deleteVirtualCompanyInvitation(virtual_company_invitation_id) {
    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('general.alert.delete-company-invitation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-remove'),
          onClick: () => {
            deleteVirtualCompanyInvitation(virtual_company_invitation_id).then(
              () => {
                listAllTeams(this.props.match.params.stage);

                hideAlertbox();

                setNotification(
                  'The invitation has been successfully deleted.'
                );
              }
            );
          },
        },
      ],
    });
  }

  @autobind
  _toggleExpandTeam(team_id) {
    this.setState({
      expanded_teams: _.xor(this.state.expanded_teams, [team_id]),
    });
  }

  @autobind
  _toggleExpandAllTeams() {
    const { teams } = this.props;

    if (teams.data.length == this.state.expanded_teams.length) {
      this.setState({ expanded_teams: [] });
    } else {
      this.setState({ expanded_teams: _.map(teams.data, 'id') });
    }
  }

  render() {
    const { teams } = this.props;
    const { disciplines } = this.props.localization;

    return (
      <div className={styles.wrapper}>
        <div className={styles.toolbar}>
          <div className={styles.title}>
            {__('project-settings.teams.title.teams')}
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.collapse} onClick={this._toggleExpandAllTeams}>
            <Tooltip text={__('teams.expand-collapse-teams')}>
              <ArrowDownMiddleIcon
                className={classNames(
                  _.get(teams, 'data', []).length ==
                    this.state.expanded_teams.length && styles.active
                )}
                onClick={this._toggleExpandAllItems}
              />
            </Tooltip>
          </div>
          <span>{__('project-settings.teams.title.status')}</span>
          <span>{__('project-settings.teams.title.role')}</span>
        </div>
        <div className={styles.teams}>
          {_.map(teams.data, (team, i) => (
            <Fragment key={i}>
              <div className={styles.team}>
                <div
                  className={styles.collapse}
                  onClick={() => this._toggleExpandTeam(team.id)}
                >
                  <Tooltip text={__('teams.expand-collapse-team')}>
                    <ArrowDownMiddleIcon
                      className={classNames(
                        _.includes(this.state.expanded_teams, team.id) &&
                          styles.active
                      )}
                    />
                  </Tooltip>
                </div>
                <div className={styles.teamName}>
                  {[
                    team.company.name,
                    [
                      team.stage_discipline.code,
                      team.stage_discipline.discipline_id == null
                        ? team.stage_discipline.description + '*'
                        : _.get(disciplines, [
                            team.stage_discipline.discipline_id,
                            'name',
                          ]),
                    ].join(': '),
                  ].join(' | ')}
                  {' (' +
                    (_.size(team.members) +
                      _.size(team.member_invitations) +
                      _.size(team.company_join_invitations) +
                      _.size(team.company_join_suggestions) +
                      _.size(team.company_join_requests) +
                      _.size(team.virtual_company_invitations)) +
                    ')'}
                  {team.pending && (
                    <span className={styles.pending}>(pending)</span>
                  )}
                </div>
                {this.state.is_project_administrator && (
                  <Tooltip
                    text={__('content.tooltip.team.invite-user-to-team')}
                  >
                    <UserAddIcon
                      onClick={() =>
                        setTableParams('teams', {
                          add_team_members_wizard_active: true,
                          team_id: team.id,
                        })
                      }
                    />
                  </Tooltip>
                )}
                {this.state.is_project_administrator && (
                  <Tooltip text={__('content.tooltip.team.edit-team')}>
                    <EditIcon
                      onClick={() =>
                        setTableParams('teams', {
                          edit_team: team.id,
                          stage_discipline_id: team.stage_discipline.id,
                          company_id: team.company.id,
                        })
                      }
                    />
                  </Tooltip>
                )}
                {this.state.is_project_administrator && team.deletable && (
                  <Tooltip text={__('content.tooltip.team.delete-team')}>
                    <DeleteIcon onClick={() => this._deleteTeam(team.id)} />
                  </Tooltip>
                )}
              </div>
              {_.includes(this.state.expanded_teams, team.id) && (
                <div className={styles.members}>
                  {_.map(team.members, (member, i) => (
                    <div
                      className={classNames(
                        styles.member,
                        (member.disabled || member.company_disabled) &&
                          styles.disabled
                      )}
                      key={i}
                    >
                      <Avatar
                        avatar_url={member.avatar_url}
                        name={member.name}
                        active={!(member.disabled || member.company_disabled)}
                      />
                      <span className={styles.name}>{member.name}</span>
                      <div className={styles.email}>
                        <span>{member.email}</span>
                        {this.state.is_project_administrator &&
                          member.disabled == false && (
                            <Tooltip
                              text={__(
                                'content.tooltip.team.deactivate-team-member'
                              )}
                            >
                              <DeactivateIcon
                                onClick={() =>
                                  this._deactivateMember(team.id, member.id)
                                }
                              />
                            </Tooltip>
                          )}
                        {this.state.is_project_administrator &&
                          member.disabled == true &&
                          !member.company_disabled && (
                            <Tooltip
                              text={__(
                                'content.tooltip.team.reactivate-team-member'
                              )}
                            >
                              <ActivateIcon
                                onClick={() =>
                                  this._reactivateMember(team.id, member.id)
                                }
                              />
                            </Tooltip>
                          )}
                      </div>
                      <div className={styles.status}>
                        {member.disabled || member.company_disabled ? (
                          <span className={styles.deactivated}>
                            <Tooltip
                              text={__('project.team.title.deactivated')}
                            >
                              {__('project.team.title.deactivated')}
                            </Tooltip>
                          </span>
                        ) : (
                          <span className={styles.active}>
                            <Tooltip text={__('project.team.title.active')}>
                              {__('project.team.title.active')}
                            </Tooltip>
                          </span>
                        )}
                      </div>
                      <div className={styles.role}>
                        {this.state.is_project_administrator ? (
                          <Dropdown
                            top={23}
                            right={0}
                            name={'member_role.' + team.id + '.' + member.id}
                            header={
                              <div className={styles.roleDropdownHeader}>
                                <UserMaleIcon />
                                {__('project.team.title.team-role')}
                              </div>
                            }
                            content={
                              <div className={styles.roleDropdownContent}>
                                <div
                                  className={classNames(
                                    styles.item,
                                    member.role === 'viewer' && styles.active
                                  )}
                                  onClick={() => {
                                    updateTeamMemberRole(
                                      team.id,
                                      member.id,
                                      'viewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.viewer'
                                    )}
                                  </span>
                                  {member.role === 'viewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    member.role === 'reviewer' && styles.active
                                  )}
                                  onClick={() => {
                                    updateTeamMemberRole(
                                      team.id,
                                      member.id,
                                      'reviewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.reviewer'
                                    )}
                                  </span>
                                  {member.role === 'reviewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    member.role === 'editor' && styles.active
                                  )}
                                  onClick={() => {
                                    updateTeamMemberRole(
                                      team.id,
                                      member.id,
                                      'editor'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.editor'
                                    )}
                                  </span>
                                  {member.role === 'editor' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <div className={styles.roleButton}>
                              {member.role}
                              <ArrowDownSmallIcon />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className={styles.roleButton}>{member.role}</div>
                        )}
                      </div>
                    </div>
                  ))}
                  {_.map(team.member_invitations, (invitation, i) => (
                    <div
                      className={classNames(
                        styles.member,
                        invitation.company_disabled && styles.disabled
                      )}
                      key={i}
                    >
                      <Avatar
                        avatar_url={invitation.avatar_url}
                        name={invitation.name}
                        active={!invitation.company_disabled}
                      />
                      <span className={styles.name}>{invitation.name}</span>
                      <div className={styles.email}>
                        <span>{invitation.email}</span>
                        {this.state.is_project_administrator &&
                          !invitation.company_disabled && (
                            <Tooltip
                              text={__(
                                'content.tooltip.team.resend-invitation'
                              )}
                            >
                              <ResendIcon
                                onClick={() => {
                                  resendTeamMemberInvitation(
                                    invitation.id
                                  ).then(() =>
                                    setNotification(
                                      'The team member invitation has been successfully resent.'
                                    )
                                  );
                                }}
                              />
                            </Tooltip>
                          )}
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.delete-invitation')}
                          >
                            <CloseMediumIcon
                              onClick={() =>
                                this._deleteMemberInvitation(invitation.id)
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.status}>
                        {invitation.company_disabled ? (
                          <span className={styles.deactivated}>
                            <Tooltip
                              text={__('project.team.title.deactivated')}
                            >
                              {__('project.team.title.deactivated')}
                            </Tooltip>
                          </span>
                        ) : (
                          <span className={styles.pending}>
                            <Tooltip text={__('project.team.title.pending')}>
                              {__('project.team.title.pending')}
                            </Tooltip>
                          </span>
                        )}
                      </div>
                      <div className={styles.role}>
                        {this.state.is_project_administrator ? (
                          <Dropdown
                            top={23}
                            right={0}
                            name={'member_invitation_role.' + invitation.id}
                            header={
                              <div className={styles.roleDropdownHeader}>
                                <UserMaleIcon />
                                {__('project.team.title.team-role')}
                              </div>
                            }
                            content={
                              <div className={styles.roleDropdownContent}>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'viewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateTeamMemberInvitationRole(
                                      invitation.id,
                                      'viewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.viewer'
                                    )}
                                  </span>
                                  {invitation.role === 'viewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'reviewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateTeamMemberInvitationRole(
                                      invitation.id,
                                      'reviewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.reviewer'
                                    )}
                                  </span>
                                  {invitation.role === 'reviewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'editor' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateTeamMemberInvitationRole(
                                      invitation.id,
                                      'editor'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.editor'
                                    )}
                                  </span>
                                  {invitation.role === 'editor' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <div className={styles.roleButton}>
                              {invitation.role}
                              <ArrowDownSmallIcon />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className={styles.roleButton}>
                            {invitation.role}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {_.map(team.company_join_invitations, (invitation, i) => (
                    <div className={styles.member} key={i}>
                      <Avatar />
                      <span className={classNames(styles.name, styles.na)}>
                        n/a
                      </span>
                      <div className={styles.email}>
                        <span>{invitation.email}</span>
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.resend-invitation')}
                          >
                            <ResendIcon
                              onClick={() => {
                                resendCompanyJoinInvitation(invitation.id).then(
                                  () =>
                                    setNotification(
                                      'The invitation has been successfully resent.'
                                    )
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.delete-invitation')}
                          >
                            <CloseMediumIcon
                              onClick={() =>
                                this._deleteCompanyJoinInvitation(invitation.id)
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.status}>
                        <span className={styles.pending}>
                          <Tooltip text={__('project.team.title.pending')}>
                            {__('project.team.title.pending')}
                          </Tooltip>
                        </span>
                      </div>
                      <div className={styles.role}>
                        {this.state.is_project_administrator ? (
                          <Dropdown
                            top={23}
                            right={0}
                            name={
                              'company_join_invitation_role.' + invitation.id
                            }
                            header={
                              <div className={styles.roleDropdownHeader}>
                                <UserMaleIcon />
                                {__('project.team.title.team-role')}
                              </div>
                            }
                            content={
                              <div className={styles.roleDropdownContent}>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'viewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinInvitationRole(
                                      invitation.id,
                                      'viewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.viewer'
                                    )}
                                  </span>
                                  {invitation.role === 'viewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'reviewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinInvitationRole(
                                      invitation.id,
                                      'reviewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.reviewer'
                                    )}
                                  </span>
                                  {invitation.role === 'reviewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'editor' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinInvitationRole(
                                      invitation.id,
                                      'editor'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.editor'
                                    )}
                                  </span>
                                  {invitation.role === 'editor' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <div className={styles.roleButton}>
                              {invitation.role}
                              <ArrowDownSmallIcon />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className={styles.roleButton}>
                            {invitation.role}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {_.map(team.company_join_suggestions, (invitation, i) => (
                    <div className={styles.member} key={i}>
                      <Avatar />
                      <span className={classNames(styles.name, styles.na)}>
                        n/a
                      </span>
                      <div className={styles.email}>
                        <span>{invitation.email}</span>
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.resend-invitation')}
                          >
                            <ResendIcon
                              onClick={() => {
                                resendCompanyJoinSuggestion(invitation.id).then(
                                  () =>
                                    setNotification(
                                      'The invitation has been successfully resent.'
                                    )
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.delete-invitation')}
                          >
                            <CloseMediumIcon
                              onClick={() =>
                                this._deleteCompanyJoinSuggestion(invitation.id)
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.status}>
                        <span className={styles.pending}>
                          <Tooltip text={__('project.team.title.pending')}>
                            {__('project.team.title.pending')}
                          </Tooltip>
                        </span>
                      </div>
                      <div className={styles.role}>
                        {this.state.is_project_administrator ? (
                          <Dropdown
                            top={23}
                            right={0}
                            name={
                              'company_join_suggestion_role.' + invitation.id
                            }
                            header={
                              <div className={styles.roleDropdownHeader}>
                                <UserMaleIcon />
                                {__('project.team.title.team-role')}
                              </div>
                            }
                            content={
                              <div className={styles.roleDropdownContent}>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'viewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinSuggestionRole(
                                      invitation.id,
                                      'viewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.viewer'
                                    )}
                                  </span>
                                  {invitation.role === 'viewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'reviewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinSuggestionRole(
                                      invitation.id,
                                      'reviewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.reviewer'
                                    )}
                                  </span>
                                  {invitation.role === 'reviewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'editor' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinSuggestionRole(
                                      invitation.id,
                                      'editor'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.editor'
                                    )}
                                  </span>
                                  {invitation.role === 'editor' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <div className={styles.roleButton}>
                              {invitation.role}
                              <ArrowDownSmallIcon />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className={styles.roleButton}>
                            {invitation.role}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {_.map(team.company_join_requests, (invitation, i) => (
                    <div className={styles.member} key={i}>
                      <Avatar />
                      <span className={classNames(styles.name, styles.na)}>
                        n/a
                      </span>
                      <div className={styles.email}>
                        <span>{invitation.email}</span>
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.resend-invitation')}
                          >
                            <ResendIcon
                              onClick={() => {
                                resendCompanyJoinRequest(invitation.id).then(
                                  () =>
                                    setNotification(
                                      'The invitation has been successfully resent.'
                                    )
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.delete-invitation')}
                          >
                            <CloseMediumIcon
                              onClick={() =>
                                this._deleteCompanyJoinRequest(invitation.id)
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.status}>
                        <span className={styles.pending}>
                          <Tooltip text={__('project.team.title.pending')}>
                            {__('project.team.title.pending')}
                          </Tooltip>
                        </span>
                      </div>
                      <div className={styles.role}>
                        {this.state.is_project_administrator ? (
                          <Dropdown
                            top={23}
                            right={0}
                            name={'company_join_request_role.' + invitation.id}
                            header={
                              <div className={styles.roleDropdownHeader}>
                                <UserMaleIcon />
                                {__('project.team.title.team-role')}
                              </div>
                            }
                            content={
                              <div className={styles.roleDropdownContent}>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'viewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinRequestRole(
                                      invitation.id,
                                      'viewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.viewer'
                                    )}
                                  </span>
                                  {invitation.role === 'viewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'reviewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinRequestRole(
                                      invitation.id,
                                      'reviewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.reviewer'
                                    )}
                                  </span>
                                  {invitation.role === 'reviewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'editor' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateCompanyJoinRequestRole(
                                      invitation.id,
                                      'editor'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.editor'
                                    )}
                                  </span>
                                  {invitation.role === 'editor' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <div className={styles.roleButton}>
                              {invitation.role}
                              <ArrowDownSmallIcon />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className={styles.roleButton}>
                            {invitation.role}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {_.map(team.virtual_company_invitations, (invitation, i) => (
                    <div className={styles.member} key={i}>
                      <Avatar />
                      <span className={classNames(styles.name, styles.na)}>
                        n/a
                      </span>
                      <div className={styles.email}>
                        <span>{invitation.email}</span>
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.resend-invitation')}
                          >
                            <ResendIcon
                              onClick={() => {
                                resendVirtualCompanyInvitation(
                                  invitation.id
                                ).then(() =>
                                  setNotification(
                                    'The invitation has been successfully resent.'
                                  )
                                );
                              }}
                            />
                          </Tooltip>
                        )}
                        {this.state.is_project_administrator && (
                          <Tooltip
                            text={__('content.tooltip.team.delete-invitation')}
                          >
                            <CloseMediumIcon
                              onClick={() =>
                                this._deleteVirtualCompanyInvitation(
                                  invitation.id
                                )
                              }
                            />
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.status}>
                        <span className={styles.pending}>
                          <Tooltip text={__('project.team.title.pending')}>
                            {__('project.team.title.pending')}
                          </Tooltip>
                        </span>
                      </div>
                      <div className={styles.role}>
                        {this.state.is_project_administrator ? (
                          <Dropdown
                            top={23}
                            right={0}
                            name={
                              'virtual_company_invitation_role.' + invitation.id
                            }
                            header={
                              <div className={styles.roleDropdownHeader}>
                                <UserMaleIcon />
                                {__('project.team.title.team-role')}
                              </div>
                            }
                            content={
                              <div className={styles.roleDropdownContent}>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'viewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateVirtualCompanyInvitationRole(
                                      invitation.id,
                                      'viewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.viewer'
                                    )}
                                  </span>
                                  {invitation.role === 'viewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'reviewer' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateVirtualCompanyInvitationRole(
                                      invitation.id,
                                      'reviewer'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.reviewer'
                                    )}
                                  </span>
                                  {invitation.role === 'reviewer' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.item,
                                    invitation.role === 'editor' &&
                                      styles.active
                                  )}
                                  onClick={() => {
                                    updateVirtualCompanyInvitationRole(
                                      invitation.id,
                                      'editor'
                                    ).then(() => {
                                      listAllTeams(
                                        this.props.match.params.stage
                                      );

                                      hideDropdown();

                                      setNotification(
                                        __(
                                          'A team member role on invitation has been successfully updated.'
                                        )
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {__(
                                      'project-settings.teams.team-role.editor'
                                    )}
                                  </span>
                                  {invitation.role === 'editor' && (
                                    <span className={styles.dot} />
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <div className={styles.roleButton}>
                              {invitation.role}
                              <ArrowDownSmallIcon />
                            </div>
                          </Dropdown>
                        ) : (
                          <div className={styles.roleButton}>
                            {invitation.role}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
}

export default Teams;
