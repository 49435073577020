const initialState = {};

export function updateProfile(formData) {
	return {
		action: "profile/UPDATE_PROFILE",
		method: "PUT",
		endpoint: "/profile",
		data: formData
	};
}

export function uploadAvatar(avatar) {
	return {
		action: "profile/UPLOAD_AVATAR",
		method: "POST",
		endpoint: "/profile/avatar",
		data: {
			avatar
		}
	};
}

export function deleteAvatar() {
	return {
		action: "profile/DELETE_AVATAR",
		method: "DELETE",
		endpoint: "/profile/avatar"
	};
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "profile/READ_PROFILE_SUCCESS":
			return {
				...action.response.data
			};
		case "profile/DELETE_AVATAR_SUCCESS":
			return {
				...state,
				avatar: false
			};
		case "profile/ENABLE_TWO_FACTOR_AUTHENTICATION_SUCCESS":
			return {
				...state,
				two_factor: true
			};
		case "profile/SHOW_CREATE_COMPANY_WIZARD":
			return {
				...state,
				create_company_wizard: true,
				predefined_company_name: action.predefined_company_name,
			};
		case "profile/HIDE_CREATE_COMPANY_WIZARD":
			return {
				...state,
				create_company_wizard: false
			};
		default:
			return state;
	}
}
