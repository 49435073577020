import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams, getLocalized } from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import { deleteMaterials, listMaterials, readMaterial } from "../../redux/actions/table/superadmin/materials";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import MaterialsFlyout from "../../flyouts/superadmin/materials/MaterialsFlyout";
import TableNA from "../../components/TableNA";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_materials"],
	auth: state.auth,
	application_language_id: state.auth.language_id,
}))
class Materials extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_material && setPlusActions([{
			title: "Add material",
			onClick: () => setTableParams('superadmin_materials', {
				add_material_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readMaterial(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, auth, application_language_id } = this.props;

		const columns = [
			{
				key: "id",
				name: "ID",
				width: 60,
				sortable: false
			},
			{
				key: "raw_materials",
				name: "Filter materials",
				width: 150,
				value: raw_materials => {
					const joined_raw_materials = _.join(_.map(raw_materials, raw_material => getLocalized(raw_material, application_language_id)), ', ');

					return _.isEmpty(raw_materials) ? <TableNA /> : <Tooltip text={joined_raw_materials}>{joined_raw_materials}</Tooltip>;
				},
				sortable: false
			},
			{
				key: "name",
				name: "Name",
				value: name => {
					const localized_name = getLocalized(name, application_language_id);
					return <Tooltip text={localized_name}>{localized_name}</Tooltip>
				}
			},
		];

		const singleActions = (material_id) => {
			return [
				<Tooltip text="Delete selected material" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected material?\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteMaterials([material_id]).then(() => {
												hideAlertbox();

												setNotification("The selected material has been deleted.");
												listMaterials();

												setTableParams('superadmin_materials', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (materials_ids) => [
			<Tooltip text="Delete selected materials" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these materials?\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteMaterials(materials_ids).then(() => {
											hideAlertbox();

											setNotification("The selected materials have been deleted.");
											listMaterials();

											setTableParams('superadmin_materials', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<MaterialsFlyout
				data={this.state.preview}
				readAction={material_id => {
					return new Promise(resolve => {
						readMaterial(getURLParam('id') || material_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		const gridOptions = {
			section: __("modules.submodules.title.materials"),
			image: (id, row) => row.thumbnail_url,
			title: (row) => getLocalized(row.name, application_language_id),
			subTitle: (row) => {
				const raw_materials = _.join(_.map(row.raw_materials, raw_material => _.get(raw_material, _.get(auth, 'language_id', 1))), ', ');

				return <Tooltip text={raw_materials}>{raw_materials}</Tooltip>
			},
			content: (row) => []
		};

		return (
			<Table
				listAction={listMaterials}
				name="superadmin_materials"
				gridOptions={gridOptions}
				defaultView="grid"
				groupActions={superadmin.delete_material && groupActions}
				singleActions={superadmin.delete_material && singleActions}
				title={__("modules.submodules.title.materials")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default Materials;
