import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/products/read_only/ReadOnlyProductsFlyout.scss';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  toBoolean,
} from '../../../core/utils';
import CloseBigIcon from '../../../assets/images/close-big-15x15.svg';
import Tooltip from '../../../components/Tooltip';
import classNames from 'classnames';
import GlobeIcon from '../../../assets/images/globe-16x16.svg';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import Select from '../../../components/Select';
import { subscribe } from 'react-contextual';
import EditIcon from '../../../assets/images/edit-16x16.svg';
import Localization from '../../../helpers/Localization';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import autobind from 'autobind-decorator';
import Overview from './Overview';
import Images from './Images';
import Files from './Files';
import About from './About';
import Parts from './Parts';
import LinkedProducts from './LinkedProducts';
import Price from './Price';
import PlusMiddleIcon from '../../../assets/images/plus-middle-15x15.svg';
import {
  copyProduct,
  deleteProduct,
  listProducts,
} from '../../../redux/actions/table/products';
import { setNotification } from '../../../redux/actions/general/notification';
import DuplicateIcon from '../../../assets/images/duplicate-16x16.svg';
import AttributesForm from '../../../forms/products/read_only/AttributesForm';
import ProductPartIcon from '../../../assets/images/product-part-24x24.svg';
import ProductIcon from '../../../assets/images/product-24x24.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../../redux/actions/general/alertbox';
import DeleteIcon from '../../../assets/images/delete-15x15.svg';
import MessageBigIcon from '../../../assets/images/message-big-15x15.svg';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.products,
  auth: state.auth,
  edit_specification_in_product: _.get(
    state.table.working_set_specifications,
    'edit_specification_in_product'
  ),
}))
class ReadOnlyProductsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language_switcher: false,
      collapsed_tabs: ['overview'], //fix for scrollSpy not working on init
      highlighted_tab: null,
    };

    setTableParams('products', {
      language_id: _.first(props.store.flyout.languages),
    });

    this.highlight_timeout = null;
  }

  componentDidMount() {
    scrollSpy.update();

    this.setState({
      collapsed_tabs: [],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'store.clicked_row') !=
        _.get(this.props, 'store.clicked_row') &&
      _.get(this.props, 'edit_specification_in_product')
    ) {
      setTableParams('working_set_specifications', {
        edit_specification_in_product: {},
      });
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    const { flyout, language_id } = this.props.store;

    const all_tabs = ['overview', 'attributes', 'about'];

    // if (_.size(flyout.images) > 0) {
    //   all_tabs.push('images');
    // }

    if (_.size(flyout.files) > 0 || _.size(flyout.images) > 0) {
      all_tabs.push('files');
    }

    if (
      flyout.price_from ||
      flyout.price_to ||
      flyout.price_date ||
      _.size(getLocalized(flyout.price_note, language_id)) > 0 ||
      flyout.discontinued
    ) {
      all_tabs.push('price');
    }

    if (_.size(this.state.collapsed_tabs) == _.size(all_tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: all_tabs,
      });
    }

    // if (_.size(flyout.files) > 0) {
    // 	all_tabs.push("linked_products");
    // }

    this.refs.scroll.scrollTop = 0;
  }

  _deleteDraftProduct = () => {
    const productId = this.props.store.flyout.id;

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('products.alert.delete-confirmation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () => {
            deleteProduct(productId).then(() => {
              hideAlertbox();

              setNotification(__('products.alert.deleted'));
              listProducts();

              setTableParams('products', {
                clicked_row: undefined,
              });
            });
          },
        },
      ],
    });
  };

  _duplicateProduct = () => {
    const productId = this.props.store.flyout.id;

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('products.alert.duplicate-product-confirmation'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-copy'),
          onClick: () => {
            copyProduct(productId).then(({ response }) => {
              hideAlertbox();

              setNotification(__('products.alert.copied-product'));
              listProducts();

              setTableParams('products', {
                clicked_row: response.data.id,
                actioned_row: undefined,
                selected_rows: [],
              });
            });
          },
        },
      ],
    });
  };

  render() {
    const { auth } = this.props;
    const { language_id, flyout } = this.props.store;

    const { language_switcher, collapsed_tabs, highlighted_tab } = this.state;

    const languages = _.map(flyout.languages, (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      code: _.get(this.props.localization.languages, [language_id, 'code']),
      value: language_id,
    }));

    const TabLink = ScrollLink((props) => (
      <span
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    let total_tabs = 3;

    // if (_.size(flyout.images) > 0) {
    //   total_tabs++;
    // }

    if (_.size(flyout.files) > 0 || _.size(flyout.images) > 0) {
      total_tabs++;
    }

    if (
      this.props.store.flyout.type == 'product' &&
      _.size(_.filter(flyout.parts, ['category', 'optional'])) > 0
    ) {
      total_tabs++;
    }

    if (
      flyout.price_from ||
      flyout.price_to ||
      flyout.price_date ||
      _.size(getLocalized(flyout.price_note, language_id)) > 0 ||
      flyout.discontinued
    ) {
      total_tabs++;
    }

    if (
      this.props.store.flyout.type == 'product' &&
      _.size(flyout.linked_products) > 0
    ) {
      total_tabs++;
    }

    let initialAttributes = {};

    _.each(flyout.attribute_groups, (group) => {
      let attributes = _.map(group.attributes, (attribute) => {
        let custom_variations = _.map(
          _.reject(
            attribute.values,
            (value) => _.size(value.value) === 0 || !value.in_use
          ),
          (value) => ({
            id: value.id,
            value: value.value,
            unit_id: attribute.unit_id,
          })
        );

        const part_variations = _.transform(
          attribute.parts,
          (variations, part) => {
            _.each(
              part.values,
              (value) =>
                _.size(value.value) > 0 &&
                value.in_use &&
                value.inherit_in_use &&
                variations.push({
                  id: value.id,
                  value: value.value,
                  unit_id: part.unit_id,
                })
            );
          },
          []
        );

        return {
          ...attribute,
          values: [...custom_variations, ...part_variations],
        };
      });

      let specification_attribute_groups =
        this.props.edit_specification_in_product;

      attributes = _.reject(attributes, (attribute) => _.isEmpty(attribute));
      _.each(attributes, (attribute) => {
        const value = _.get(attribute.values, '0.id');

        const specification_attribute_group = _.find(
          specification_attribute_groups,
          (specification_attribute_group) =>
            _.some(
              specification_attribute_group.attributes,
              (specification_product_attribute) =>
                specification_product_attribute?.product_attribute_group_field_id ===
                attribute.id
            )
        );

        if (specification_attribute_group) {
          initialAttributes[`field-${attribute.id}`] =
            _.find(attribute.values, (value) =>
              _.some(
                specification_attribute_group.attributes,
                (specification_product_attribute) =>
                  specification_product_attribute?.product_attribute_group_field_value_id ==
                  value.id
              )
            )?.id ?? value;
        } else if (value) {
          initialAttributes[`field-${attribute.id}`] = value;
        }
      });

      if (
        !_.isEmpty(
          _.reject(flyout.variants, (variant) => _.isEmpty(variant.values))
        ) &&
        !specification_attribute_groups
      ) {
        _.each(flyout.variants[0].values, (value, key) => {
          if (value) {
            initialAttributes[`field-${key}`] = value;
          }
        });
      }
    });

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              {flyout.type == 'product' && (
                <Tooltip text={__('products.table-titles.type.product')}>
                  <ProductIcon />
                </Tooltip>
              )}
              {flyout.type == 'part' && (
                <Tooltip text={__('products.table-titles.type.part')}>
                  <ProductPartIcon />
                </Tooltip>
              )}
              <strong>{'P-' + _.padStart(flyout.id, 4, '0')}:</strong>
              <span>
                {flyout.model} by {flyout.brand}
              </span>
            </div>
            <Tooltip
              text={__('products.flyout.read-only.close-product-flyout')}
            >
              <CloseBigIcon
                className={styles.close}
                onClick={() =>
                  setTimeout(() => {
                    setTableParams('products', {
                      clicked_row: undefined,
                    });
                  }, 100)
                }
              />
            </Tooltip>
          </div>
          <div className={styles.actions}>
            {flyout.company_email && (
              <Tooltip
                text={__('products.tooltip.contact-seller')}
                key={_.uniqueId()}
              >
                <a href={`mailto:${flyout.company_email}`}>
                  <MessageBigIcon />
                </a>

                {/* <a href='mailto:support@volum3.com'>
                <MessageSendIcon />
              </a> */}
              </Tooltip>
            )}

            {!flyout.public && (
              <Tooltip
                text={__('products.tooltip.delete-product')}
                key={_.uniqueId()}
              >
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    this._deleteDraftProduct();
                  }}
                />
              </Tooltip>
            )}
            {flyout.company_id === auth.company.id && (
              <Tooltip text={__('products.flyout.read-only.enter-edit-mode')}>
                <EditIcon
                  onClick={() =>
                    setTableParams('products', {
                      edit_mode: true,
                    })
                  }
                />
              </Tooltip>
            )}
            {flyout.company_id === auth.company.id && (
              <Tooltip text='Duplicate product/part'>
                <DuplicateIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    this._duplicateProduct();
                  }}
                />
              </Tooltip>
            )}
            {flyout.type === 'product' && !flyout.discontinued && (
              <>
                <span className={styles.separator} />
                <Tooltip text={__('products.flyout.read-only.add-to-project')}>
                  <div
                    className={styles.addToProject}
                    onClick={() =>
                      setTableParams('products', {
                        create_specification_from_product_wizard: flyout.id,
                      })
                    }
                  >
                    <PlusMiddleIcon className={styles.plus} />
                    <span>
                      {__('products.flyout.read-only.add-to-project')}
                    </span>
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className={styles.toolbar}>
          {!flyout.public ? (
            <Tooltip text='Draft'>
              <div className={styles.draft}>Draft</div>
            </Tooltip>
          ) : (
            <Tooltip text='Public'>
              <div className={styles.public}>Public</div>
            </Tooltip>
          )}
          {flyout.discontinued ? (
            <Tooltip text={__('product.discontinued.products.flyout.tooltip')}>
              <div className={styles.discontinued}>
                {__('products.flyout.discontinued')}
              </div>
            </Tooltip>
          ) : (
            ''
          )}
          {language_id && (
            <div className={styles.language}>
              <Select
                input={{
                  value: language_id,
                  onChange: (language_id) =>
                    setTableParams('products', {
                      language_id,
                    }),
                  onBlur: () => this.setState({ language_switcher: false }),
                }}
                meta={{
                  active: language_switcher,
                }}
                label={__('products.flyout.read-only.tooltip.product-language')}
                options={languages}
                left={-75}
                top={40}
                noSort
                dropdown
                icon={<GlobeIcon className={styles.globeIcon} />}
                wrapper={(handleOpen, activeItem) => (
                  <Tooltip
                    text={__(
                      'products.flyout.read-only.change-product-language'
                    )}
                  >
                    <div
                      className={styles.languageSwitcher}
                      onClick={() => this.setState({ language_switcher: true })}
                    >
                      <GlobeIcon className={styles.icon} />
                      {_.get(activeItem, 'code')}
                      <ArrowDownSmallIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                )}
              />
            </div>
          )}
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) === total_tabs && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='overview'>{__('products.flyout.tabs.overview')}</Tab>
            <Tab target='configurator'>
              {__('products.flyout.tabs.configurator')}
            </Tab>
            <Tab target='attributes'>
              {__('products.flyout.tabs.attributes')}
            </Tab>
            {this.props.store.flyout.type == 'product' &&
              _.size(_.filter(flyout.parts, ['category', 'optional'])) > 0 && (
                <Tab target='optional_parts'>
                  {__('products.flyout.tabs.optional_tabs')}
                </Tab>
              )}
            {toBoolean(
              flyout.price_from ||
                flyout.price_to ||
                flyout.price_date ||
                _.size(getLocalized(flyout.price_note, language_id)) > 0 ||
                flyout.discontinued
            ) && <Tab target='price'>{__('products.flyout.tabs.price')}</Tab>}
            <Tab target='about'>{__('products.flyout.tabs.about')}</Tab>
            {/* {_.size(flyout.images) > 0 && (
              <Tab target='images'>{__('products.flyout.tabs.images')}</Tab>
            )} */}
            {(_.size(flyout.files) > 0 || _.size(flyout.images) > 0) && (
              <Tab target='files'>
                {__('products.flyout.tabs.files')}
                {' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
              </Tab>
            )}
            {this.props.store.flyout.type == 'product' &&
              _.size(flyout.linked_products) > 0 && (
                <Tab target='linked_products'>
                  {__('products.flyout.tabs.linked_products')}
                </Tab>
              )}
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='overview'
            className={classNames(
              styles.section,
              highlighted_tab === 'overview' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('overview')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'overview') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.overview')}
            </div>
            <Overview collapsed={_.includes(collapsed_tabs, 'overview')} />
          </Element>
          <Element
            name='configurator'
            className={classNames(
              styles.section,
              highlighted_tab === 'configurator' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('configurator')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'configurator') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.configurator')}
            </div>

            <AttributesForm
              collapsed={_.includes(collapsed_tabs, 'configurator')}
              attribute_groups={_.sortBy(
                _.filter(
                  flyout.attribute_groups,
                  (item) => item.is_configurator
                ),
                'priority'
              )}
              initialValues={initialAttributes}
            />
          </Element>
          <Element
            name='attributes'
            className={classNames(
              styles.section,
              highlighted_tab === 'attributes' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('attributes')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'attributes') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.attributes')}
            </div>
            <AttributesForm
              collapsed={_.includes(collapsed_tabs, 'attributes')}
              initialValues={_.pickBy(initialAttributes, (v, k) => {
                return !_.includes(k, 'is_configurator-');
              })}
              attribute_groups={_.sortBy(
                _.filter(
                  flyout.attribute_groups,
                  (item) => !item.is_configurator
                ),
                'priority'
              )}
            />
          </Element>

          {this.props.store.flyout.type == 'product' &&
            _.size(_.filter(flyout.parts, ['category', 'optional'])) > 0 && (
              <Element
                name='optional_parts'
                className={classNames(
                  styles.section,
                  highlighted_tab == 'optional_parts' && styles.highlighted
                )}
              >
                <div
                  className={styles.title}
                  onClick={() => this._toggleCollapseTab('optional_parts')}
                >
                  <ArrowDownMiddleIcon
                    className={classNames(
                      styles.collapse,
                      _.includes(collapsed_tabs, 'optional_parts') &&
                        styles.collapsed
                    )}
                  />
                  {__('products.flyout.tabs.optional_parts')}
                </div>
                <Parts
                  collapsed={_.includes(collapsed_tabs, 'optional_parts')}
                  sorted_list={_.sortBy(
                    _.filter(flyout.parts, ['category', 'optional']),
                    'order'
                  )}
                />
              </Element>
            )}

          {toBoolean(
            flyout.price_from ||
              flyout.price_to ||
              flyout.price_date ||
              _.size(getLocalized(flyout.price_note, language_id)) > 0 ||
              flyout.discontinued
          ) && (
            <Element
              name='price'
              className={classNames(
                styles.section,
                highlighted_tab === 'price' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('price')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'price') && styles.collapsed
                  )}
                />
                {__('products.flyout.tabs.price')}
              </div>
              <Price collapsed={_.includes(collapsed_tabs, 'price')} />
            </Element>
          )}
          <Element
            name='about'
            className={classNames(
              styles.section,
              highlighted_tab === 'about' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('about')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'about') && styles.collapsed
                )}
              />
              {__('products.flyout.tabs.about')}
            </div>
            <About collapsed={_.includes(collapsed_tabs, 'about')} />
          </Element>
          {/* {_.size(flyout.images) > 0 && (
            <Element
              name='images'
              className={classNames(
                styles.section,
                highlighted_tab === 'images' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('images')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'images') && styles.collapsed
                  )}
                />
                {__('products.flyout.tabs.images')}
              </div>
              <Images collapsed={_.includes(collapsed_tabs, 'images')} />
            </Element>
          )} */}
          {(_.size(flyout.files) > 0 || _.size(flyout.images) > 0) && (
            <Element
              name='files'
              className={classNames(
                styles.section,
                highlighted_tab === 'files' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('files')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'files') && styles.collapsed
                  )}
                />
                {__('products.flyout.tabs.files')}
                {' (' + (_.size(flyout.files) + _.size(flyout.images)) + ')'}
              </div>
              <Files collapsed={_.includes(collapsed_tabs, 'files')} />
            </Element>
          )}

          {this.props.store.flyout.type == 'product' &&
            _.size(flyout.linked_products) > 0 && (
              <Element
                name='linked_products'
                className={classNames(
                  styles.section,
                  highlighted_tab == 'linked_products' && styles.highlighted
                )}
              >
                <div
                  className={styles.title}
                  onClick={() => this._toggleCollapseTab('linked_products')}
                >
                  <ArrowDownMiddleIcon
                    className={classNames(
                      styles.collapse,
                      _.includes(collapsed_tabs, 'linked_products') &&
                        styles.collapsed
                    )}
                  />
                  {__('products.flyout.tabs.linked_products')}
                </div>
                <LinkedProducts
                  collapsed={_.includes(collapsed_tabs, 'linked_products')}
                />
              </Element>
            )}
        </div>
      </div>
    );
  }
}

export default ReadOnlyProductsFlyout;
