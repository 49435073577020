import * as React from "react";
import {Component} from "react";
import ClickOutside from "react-click-outside";
import styles from "../styles/components/OutsideClickWrapper.scss";
import classNames from "classnames";
import {hideOverlay, showOverlay} from "../redux/actions/general/overlay";
import {mapStateToProps} from "../core/utils";

@mapStateToProps(state => ({
    alertbox_active: state.general.alertbox.title
}))
class OutsideClickWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shake: false,
        }
    }

    componentDidMount() {
        if(this.props.closable === false) {
            showOverlay();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.closable != this.props.closable) {
            this.props.closable ? hideOverlay() : showOverlay();
        }
    }

    componentWillUnmount() {
        hideOverlay();
    }

    render() {
        const {shake} = this.state;
        const {closable, alertbox_active} = this.props;

        return (
            <>
                <div className={classNames(styles.wrapper, shake && styles.shaked, closable === false && styles.nonClosable)}>
                    <ClickOutside onClickOutside={e => {
                        if(this.props.onClickOutside) {
                            this.props.onClickOutside(e);
                        }

                        if(this.props.closable === false && !alertbox_active) { // added a check for 'alertbox_active' because of the bug (clicking on PeopleChooser on inactive users)
                            e.stopPropagation();

                            this.setState({shake: true}, () => {
                                setTimeout(() => this.setState({shake: false}), 1000);
                            });
                        }
                    }}>
                        {this.props.children}
                    </ClickOutside>
                </div>
            </>
        );
    }
}

export default OutsideClickWrapper;