import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/current_set_plans/CurrentSetPlansFlyout.scss";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import classNames from "classnames";
import Properties from "../current_set_plans/Properties";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { downloadLastRevision } from "../../redux/actions/table/current_set_plans";
import DownloadIcon from "../../assets/images/download-15x15.svg";
import Revisions from "./Revisions";
import { __ } from "../../core/utils";
import Tooltip from "../../components/Tooltip";
import Files from "./Files";
import * as _ from "lodash";
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      setTableParams: params =>
        dispatch({
          type: "table.current_set_plans/SET_TABLE_PARAMS",
          params
        })
    }
  };
};

@connect(null, mapDispatchToProps)
@withRouter
class CurrentSetPlansFlyout extends Component {
  constructor (props) {
    super(props);

    this.state = {
      active: 'properties',
      collapsed_tabs: [], //fix for scrollSpy not working on init
      highlighted_tab: null,
    }

    this.highlight_timeout = null;

    this.tabs = [
      'properties',
      'revisions',
      'files',
    ];

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  render() {
    const { data, readAction } = this.props;

    const { collapsed_tabs, highlighted_tab } = this.state;

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          props.target == 'history' && this._lazyLoadTaskHistory();

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>{data.code && <strong>{data.code}: </strong>}{data.title}</span>
            <CloseBigIcon onClick={() => setTimeout(() => {
              this.props.actions.setTableParams({
                clicked_row: undefined
              })
            }, 100)} />
          </div>
          <div className={styles.actions}>
            <div className={styles.actionWrapper}>
              <Tooltip text={__('plans.tooltip.icon.current-set.download-revision')}><div className={styles.actionWrapper}>
                <DownloadIcon onClick={() => downloadLastRevision(data.id)} />
              </div></Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>{__('plans.current-set.tab.properties')}</Tab>
            <Tab target='revisions'>{__('plans.current-set.tab.revisions')}</Tab>
            <Tab target='files'>{__('plans.current-set.tab.files')}</Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('plans.current-set.tab.properties')}
            </div>
            <Properties
              collapsed={_.includes(collapsed_tabs, 'properties')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='revisions'
            className={classNames(
              styles.section,
              highlighted_tab == 'revisions' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('revisions')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'revisions') && styles.collapsed
                )}
              />
              {__('plans.current-set.tab.revisions')}
            </div>
            <Revisions
              collapsed={_.includes(collapsed_tabs, 'revisions')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {__('plans.current-set.tab.files')}
            </div>
            <Files
              collapsed={_.includes(collapsed_tabs, 'files')}
              data={data}
              readAction={readAction}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default CurrentSetPlansFlyout;
