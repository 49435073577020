import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MoveMeetingItemWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setFormErrors,
  validateForm,
} from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import {
  readWorkingSetBOQ,
  reorderBOQCalculations,
  reorderBOQGroups,
  reorderBOQItems,
  reorderBOQPositions,
  reorderBOQSubgroups,
} from '../redux/actions/table/working_set_boq';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';
import MoveBOQMeetingForm from '../forms/move_meeting_item_wizard/MoveMeetingItemForm';
import { reorderMeetingItems } from '../redux/actions/table/all_meetings';

@mapStateToProps((state) => ({
  id: _.get(state.table, 'all_meetings.move_meeting_item_wizard.id'),
  name: _.get(state.table, 'all_meetings.move_meeting_item_wizard.name'),
  sorted_items: _.get(
    state.table,
    'all_meetings.move_meeting_item_wizard.sorted_items'
  ),
  store: state.table['all_meetings'],
}))
@subscribe(Localization, 'localization')
@withRouter
class MoveMeetingItemWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
    };
  }

  @autobind
  _handleSubmit(formData) {
    const { id, sorted_items, match } = this.props;
    const meeting_id = match.params.meeting;

    let options = _.map(sorted_items, 'id');

    const currentIndex = _.indexOf(options, id);

    const targetIndex = _.indexOf(options, formData.selected_item_id);

    // Calculate the insertion index based on move_action
    const insertionIndex =
      targetIndex +
      (formData.move_action === 'before' ? 0 : 1) -
      (currentIndex < targetIndex ? 1 : 0);

    options.splice(insertionIndex, 0, ...options.splice(currentIndex, 1));

    reorderMeetingItems(meeting_id, options).then(() => {
      this.props.onClose();
    });
  }

  render() {
    const { name, sorted_items } = this.props;

    const options = _.map(sorted_items, (item) => {
      return {
        value: item.id,
        label:
          item.type == 'task'
            ? '—\u00A0' + item.task_title
            : item.type == 'topic'
            ? '—\u00A0' + item.title
            : item.title,
        blackMarkedValue: item.type === 'group',
      };
    });

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{__('meeting.items.move.title')}</span>
            <span>{__('meeting.items.move.description')}</span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MoveBOQMeetingForm
            onSubmit={this._handleSubmit}
            initialValues={{ name, move_action: 'after' }}
            options={options}
          />
        </div>
      </>
    );
  }
}

export default MoveMeetingItemWizard;
