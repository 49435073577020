import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/views/boq/WorkingSetBOQ.scss';
import { connect } from 'react-redux';
import UnpinBigIcon from '../../../assets/images/unpin-big-16x16.svg';
import PinBigIcon from '../../../assets/images/pin-big-16x16.svg';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';

import UncheckedIcon from '../../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../../assets/images/checked-15x15.svg';

import { withRouter } from 'react-router';
import queryString from 'query-string';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import classNames from 'classnames';

import { hideDropdown } from '../../../redux/actions/general/dropdown';

import CloseBigIcon from '../../../assets/images/close-big-15x15.svg';
import CloseSmallIcon from '../../../assets/images/close-small-15x15.svg';
import { __, getURLParam, setURLParam } from '../../../core/utils';
import Tooltip from '../../../components/Tooltip';
import DatetimeIcon from '../../../assets/images/datetime-15x15.svg';
import SearchIcon from '../../../assets/images/search-15x15.svg';
import PinSmallIcon from '../../../assets/images/pin-small-16x16.svg';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';

import pluralize from 'pluralize';

import { updatePreferences } from '../../../redux/actions/profile';
import Range from '../../../components/Table/Range';
import SearchableDropdown from '../../../components/SearchableDropdown';
import Dropdown from '../../../components/Dropdown';
import DateTimePicker from '../../../components/DateTimePicker';

const mapStateToProps = (state, props) => {
  return {
    ...state.table[props.name],
    dropdownName: state.general.dropdown.shown,
    lightbox_active: state.general.lightbox.active,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.' + props.name + '/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class Filter extends Component {
  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        _.omit(queryString.parse(prevProps.location.search), 'id'),
        _.omit(queryString.parse(this.props.location.search), 'id')
      )
    ) {
      this._mapUrlParamsToProps();
    }

    if (_.isUndefined(prevProps.filters) && this.props.filters) {
      const params = {};

      _.each(this.props.filters, (filter, key) => {
        _.each(filter?.defaultItems, (item, value) => {
          params['filter.' + key] = value;
        });
      });

      this.props.setURLParams(params);
    }
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  _mapUrlParamsToProps() {
    const params = queryString.parse(this.props.location.search);

    this.props.actions.setTableParams(
      _.pick(params, ['page', 'limit', 'sort_by', 'order', 'query'])
    );

    _.each(params, (value, key) => {
      //TODO - try to merge this with the upper keys using pickBy()
      if (_.startsWith(key, 'filter.')) {
        const params = {};
        params[key] = value;

        if (this.props[key] != value) {
          params['page'] = 1;
        }

        this.props.actions.setTableParams(params);
      }
    });

    this.props.filterData();
  }

  @autobind
  _toggleFilterFlyout() {
    this.props.actions.setTableParams({
      showFilters: !this.props.showFilters,
    });
  }

  @autobind
  _toggleFilterSection(section) {
    this.props.actions.setTableParams({
      filterExpandedSections: _.xor(this.props.filterExpandedSections, [
        section,
      ]),
    });
  }

  @autobind
  _toggleFilterPin(section, pinned) {
    const pinned_columns = {
      ...this.props.pinned_columns,
      [section]: pinned,
    };
    this.props.actions.setTableParams({ pinned_columns });
    updatePreferences(`table.${this.props.name}`, { pinned_columns });
  }

  @autobind
  _toggleExpandFilterSections() {
    const all_filters = _.reject(
      _.map(this.props.filters, (filter, key) => ({
        key: key,
        type: filter.type,
        items: filter.items,
      })),
      (filter) =>
        filter.type == 'sidebar' ||
        (filter.type == 'select' && _.isEmpty(filter.items))
    );

    this.props.actions.setTableParams({
      filterExpandedSections: _.isEmpty(this.props.filterExpandedSections)
        ? _.map(all_filters, 'key')
        : [],
    });
  }

  @autobind
  _clearFilters() {
    const params = {
      query: '',
    };

    _.each(
      _.pickBy(this.props, (value, key) => _.startsWith(key, 'filter.')),
      (value, key) => {
        params[key] = '';
      }
    );

    _.each(this.props.filters, (filter, key) => {
      _.each(filter?.defaultItems, (item, value) => {
        params['filter.' + key] = value;
      });
    });

    this.props.setURLParams(params);
  }

  @autobind
  _clearFilter(filter) {
    const params = {};

    params['filter.' + filter.key] = _.some(
      _.split(getURLParam('filter.' + filter.key), '|'),
      (item) => _.includes(_.map(filter?.defaultItems, 'value'), item)
    )
      ? [..._.map(filter?.defaultItems, 'value')].join('|')
      : '';

    this.props.setURLParams(params);
  }

  render() {
    let start_page, end_page;

    const total_pages = parseInt(this.props.pages);
    const current_page = parseInt(this.props.page);

    if (total_pages <= 10) {
      start_page = 1;
      end_page = total_pages;
    } else {
      if (current_page <= 6) {
        start_page = 1;
        end_page = 10;
      } else if (current_page + 4 >= total_pages) {
        start_page = total_pages - 9;
        end_page = total_pages;
      } else {
        start_page = current_page - 5;
        end_page = current_page + 4;
      }
    }

    const filters = _.reject(
      _.map(this.props.filters, (filter, key) => {
        return {
          key: key,
          name: filter.name,
          type: filter.type,
          nullable: filter.nullable,
          pinned: _.get(this.props.pinned_columns, key),
          items: _.map(filter.items, (value, key) => {
            return {
              value: key,
              label: value,
            };
          }),
          defaultItems: _.map(filter.defaultItems, (value, key) => {
            return {
              value: key,
              label: value,
            };
          }),
        };
      }),
      (filter) =>
        filter.type == 'sidebar' ||
        (filter.type == 'select' && _.isEmpty(filter.items))
    );

    const applied_filters_num = _.size(
      _.filter(
        filters,
        (filter) =>
          getURLParam('filter.' + filter.key) != '' &&
          !_.every(_.split(getURLParam('filter.' + filter.key), '|'), (item) =>
            _.includes(_.map(filter?.defaultItems, 'value'), item)
          )
      )
    );

    const { footer, selected_rows, data, viewActions, hideSearch } = this.props;
    const { shift_pressed, checkbox_row_hovered } = this.state;

    let shift_hover_range = [];

    if (!_.isEmpty(selected_rows) && shift_pressed) {
      shift_hover_range = _.map(
        _.slice(
          data,
          _.findIndex(data, ['id', _.first(selected_rows)]),
          _.findIndex(data, ['id', checkbox_row_hovered]) + 1
        ),
        'id'
      );
    }

    const hasSelectedValuesInFilters =
      _.filter(
        _.keys(this.props),
        (item) =>
          _.startsWith(item, 'filter.') &&
          _.includes(_.map(filters, 'key'), item.substring(7)) &&
          !_.isEmpty(_.get(this.props, item))
      ).length > 0;
    const hasPinnedFilters = _.some(
      _.values(_.get(this.props, 'pinned_columns')),
      (pinned_column) => pinned_column
    );
    const hasQueryFilter = !_.isEmpty(this.props.query);

    return (
      <>
        {(hasQueryFilter || hasSelectedValuesInFilters || hasPinnedFilters) && (
          <div className={styles.filterList}>
            {_.map(
              _.filter(
                filters,
                (filter) =>
                  (_.isEmpty(filter.defaultItems)
                    ? !_.isEmpty(_.get(this.props, 'filter.' + filter.key))
                    : false) || filter.pinned
              ),
              (filter, i) => {
                const filterURLParam = getURLParam('filter.' + filter.key);
                const active_filter_items = _.filter(
                  filterURLParam == 'n/a' ? [] : _.split(filterURLParam, '|'),
                  _.identity
                );

                const clearFilter = (e) => {
                  e.stopPropagation();
                  this._clearFilter(filter);
                };

                const filterHeader = (
                  <div className={styles.filterHeaderWithPin}>
                    <Tooltip
                      text={
                        filter.pinned
                          ? `${__('table.filters.unpin')} ${filter.name}`
                          : `${__('table.filters.pin')} ${filter.name}`
                      }
                    >
                      <div
                        className={styles.filterHeaderPinButton}
                        onClick={() =>
                          this._toggleFilterPin(filter.key, !filter.pinned)
                        }
                      >
                        {filter.pinned ? <UnpinBigIcon /> : <PinBigIcon />}
                      </div>
                    </Tooltip>

                    <Tooltip
                      text={filter.name}
                      className={styles.filterHeaderName}
                    >
                      {filter.name}
                    </Tooltip>
                    <div className={styles.filterHeaderActions}>
                      <span
                        className={styles.filterHeaderClearAll}
                        onClick={clearFilter}
                      >
                        {__('table.filters.clear')}
                      </span>
                    </div>
                  </div>
                );

                if (filter.type == 'date_range') {
                  let start, end;
                  const values = filterURLParam.split('|');

                  if (values.length == 2) {
                    start = values[0];
                    end = values[1];
                  }

                  return (
                    <Dropdown
                      key={filter.key}
                      wrapperClassName={styles.filterItem}
                      name={filter.key}
                      header={filterHeader}
                      leftStyled
                      content={
                        <div className={styles.daterange}>
                          {(filterURLParam != '0' || !filter.nullable) && (
                            <div className={styles.datepickers}>
                              <DateTimePicker
                                icon={DatetimeIcon}
                                placeholder={__(
                                  'table.filters.title.select-date-from'
                                )}
                                footer
                                meta={{
                                  active: _.get(
                                    this.state,
                                    'pinned_filter_active_from_' + filter.key,
                                    false
                                  ),
                                }}
                                input={{
                                  value: start,
                                  onFocus: () =>
                                    this.setState({
                                      ['pinned_filter_active_from_' +
                                      filter.key]: true,
                                      ['pinned_filter_active_to_' +
                                      filter.key]: false,
                                    }),
                                  onBlur: () =>
                                    this.setState({
                                      ['pinned_filter_active_from_' +
                                      filter.key]: false,
                                    }),
                                  onChange: (start) =>
                                    setURLParam(
                                      'filter.' + filter.key,
                                      [start, end].join('|')
                                    ),
                                }}
                              />
                              <DateTimePicker
                                icon={DatetimeIcon}
                                placeholder={__(
                                  'table.filters.title.select-date-to'
                                )}
                                footer
                                meta={{
                                  active: _.get(
                                    this.state,
                                    'pinned_filter_active_to_' + filter.key,
                                    false
                                  ),
                                }}
                                input={{
                                  value: end,
                                  onFocus: () =>
                                    this.setState({
                                      ['pinned_filter_active_to_' +
                                      filter.key]: true,
                                      ['pinned_filter_active_from_' +
                                      filter.key]: false,
                                    }),
                                  onBlur: () =>
                                    this.setState({
                                      ['pinned_filter_active_to_' +
                                      filter.key]: false,
                                    }),
                                  onChange: (end) =>
                                    setURLParam(
                                      'filter.' + filter.key,
                                      [start, end].join('|')
                                    ),
                                }}
                              />
                            </div>
                          )}
                          {filter.nullable && (
                            <div className={styles.nullable}>
                              {filterURLParam == '0' ? (
                                <CheckedIcon
                                  className={styles.checked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '')
                                  }
                                />
                              ) : (
                                <UncheckedIcon
                                  className={styles.unchecked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '0')
                                  }
                                />
                              )}
                              <span
                                onClick={() =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    filterURLParam == '0' ? '' : '0'
                                  )
                                }
                              >
                                {filter.nullable}
                              </span>
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div
                        className={classNames(
                          styles.bubble,
                          _.trim(values?.[0]) == '' &&
                            _.trim(values?.[1]) == '' &&
                            styles.bubbleEmpty
                        )}
                      >
                        {filter.pinned && (
                          <PinSmallIcon className={styles.filterPinIcon} />
                        )}
                        {filter.name}
                        <ArrowDownSmallIcon />
                      </div>
                    </Dropdown>
                  );
                }

                if (filter.type == 'range') {
                  const values = filterURLParam.split('|');

                  const start = values[0];
                  const end = values[1];

                  return (
                    <Dropdown
                      key={filter.key}
                      wrapperClassName={styles.filterItem}
                      name={filter.key}
                      header={filterHeader}
                      leftStyled
                      content={
                        <>
                          {(filterURLParam != '0' || !filter.nullable) && (
                            <Range
                              nullable={false}
                              value={filterURLParam}
                              onChange={(value) =>
                                setURLParam('filter.' + filter.key, value)
                              }
                            />
                          )}
                          {filter.nullable && (
                            <div className={styles.nullable}>
                              {filterURLParam == '0' ? (
                                <CheckedIcon
                                  className={styles.checked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '')
                                  }
                                />
                              ) : (
                                <UncheckedIcon
                                  className={styles.unchecked}
                                  onClick={() =>
                                    setURLParam('filter.' + filter.key, '0')
                                  }
                                />
                              )}
                              <span
                                onClick={() =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    filterURLParam == '0' ? '' : '0'
                                  )
                                }
                              >
                                {filter.nullable}
                              </span>
                            </div>
                          )}
                        </>
                      }
                    >
                      <div
                        className={classNames(
                          styles.bubble,
                          _.trim(start) == '' &&
                            _.trim(end) == '' &&
                            styles.bubbleEmpty
                        )}
                      >
                        {filter.pinned && (
                          <PinSmallIcon className={styles.filterPinIcon} />
                        )}
                        {filter.name}
                        <ArrowDownSmallIcon />
                      </div>
                    </Dropdown>
                  );
                }

                const selectedValues = _.filter(
                  _.split(_.get(this.props, 'filter.' + filter.key), '|'),
                  (filterItem) => {
                    return (
                      !!filterItem &&
                      !!_.find(
                        filter.items,
                        (item) => _.get(item, 'value') == filterItem
                      )
                    );
                  }
                );

                let filterItems = this.props.filtersSort
                  ? _.sortBy(filter.items, (item) => {
                      const sorting_number = this.props.filtersSort(
                        filter.key,
                        item.value,
                        item.label
                      );

                      return _.isUndefined(sorting_number) ? 0 : sorting_number;
                    })
                  : filter.items;

                const defaultItemsFooter = _.map(
                  filter.defaultItems,
                  (defaultItem) => (
                    <div className={styles.defaultItems}>
                      <span
                        onClick={() =>
                          setURLParam('filter.' + filter.key, defaultItem.value)
                        }
                      >
                        <Tooltip placement='left' text={defaultItem.label}>
                          {defaultItem.label}
                        </Tooltip>
                      </span>

                      <ToggleButton
                        onToggle={() => {
                          const checked = _.includes(
                            active_filter_items,
                            _.toString(defaultItem.value)
                          );

                          if (checked) {
                            setURLParam(
                              'filter.' + filter.key,
                              _.reject(
                                active_filter_items,
                                (row) => row == defaultItem.value
                              ).join('|')
                            );
                          } else {
                            setURLParam(
                              'filter.' + filter.key,
                              [...active_filter_items, defaultItem.value].join(
                                '|'
                              )
                            );
                          }
                        }}
                        value={_.includes(
                          active_filter_items,
                          _.toString(defaultItem.value)
                        )}
                      />
                    </div>
                  )
                );

                return (
                  <SearchableDropdown
                    key={filter.key}
                    dropdownWrapperClassName={styles.filterItem}
                    name={filter.key}
                    header={filterHeader}
                    footer={defaultItemsFooter}
                    options={filterItems}
                    onSelect={(item) => {
                      setURLParam(
                        'filter.' + filter.key,
                        [
                          ...active_filter_items,
                          encodeURIComponent(item.value),
                        ].join('|')
                      );
                    }}
                    onRemove={(item) => {
                      setURLParam(
                        'filter.' + filter.key,
                        _.reject(
                          active_filter_items,
                          (row) => row == item.value
                        ).join('|')
                      );
                    }}
                    selectedValues={selectedValues}
                    multiselect
                    leftStyled
                  >
                    <div
                      className={classNames(
                        styles.bubble,
                        _.size(selectedValues) === 0 && styles.bubbleEmpty
                      )}
                    >
                      {filter.pinned && (
                        <PinSmallIcon className={styles.filterPinIcon} />
                      )}
                      {filter.name}
                      {_.size(selectedValues) > 0 && (
                        <strong className={styles.filterCount}>
                          ({_.size(selectedValues)})
                        </strong>
                      )}
                      <ArrowDownSmallIcon />
                    </div>
                  </SearchableDropdown>
                );
              }
            )}
            {hasQueryFilter && (
              <div
                onClick={this._clearSearch}
                className={classNames(styles.bubble)}
              >
                {__('table.filters.search')}: {this.props.query}{' '}
                <CloseSmallIcon />
              </div>
            )}

            {(hasSelectedValuesInFilters || hasQueryFilter) && (
              <div
                onClick={this._clearFilters}
                className={classNames(styles.bubble, styles.gray)}
              >
                {__('table.filters.clear-all')}
              </div>
            )}
          </div>
        )}

        {filters && (
          <div
            className={classNames(
              styles.filtersflyout,
              this.props.showFilters && styles.active
            )}
          >
            <div className={styles.heading}>
              {__('table.filters.title.filters')}
              <CloseBigIcon
                onClick={() => {
                  this.props.actions.setTableParams({
                    showFilters: undefined,
                  });

                  this.setState({
                    filtersQuery: {},
                  });
                }}
              />
            </div>
            <div className={styles.filters}>
              <div
                className={classNames(
                  styles.expandAll,
                  !_.isEmpty(this.props.filterExpandedSections) &&
                    styles.expanded
                )}
              >
                <div onClick={this._toggleExpandFilterSections}>
                  <ArrowDownMiddleIcon className={styles.arrowDownIcon} />
                  {applied_filters_num == 0
                    ? __(
                        'space-allocation-beta.table-list-view.no-filters-applied'
                      )
                    : pluralize('filter', applied_filters_num, true) +
                      ' applied'}
                  {_.filter(
                    _.keys(this.props),
                    (item, key) =>
                      _.startsWith(item, 'filter.') &&
                      _.includes(_.map(filters, 'key'), item.substring(7)) &&
                      !_.isEmpty(_.get(this.props, item)) &&
                      _.isEmpty(
                        _.find(
                          filters,
                          (filter) => filter.key == item.substring(7)
                        )?.defaultItems
                      )
                  ).length > 0 && (
                    <div
                      onClick={this._clearFilters}
                      className={classNames(styles.bubble, styles.gray)}
                    >
                      {__('table.filters.clear-all')}
                    </div>
                  )}
                </div>
              </div>
              {_.map(filters, (filter, i) => {
                const filterHeader = (
                  <div className={styles.filterHeader}>
                    <span onClick={() => this._toggleFilterSection(filter.key)}>
                      <ArrowDownMiddleIcon className={styles.arrowDownIcon} />
                      {filter.name}
                    </span>
                    <span
                      onClick={() =>
                        this._toggleFilterPin(filter.key, !filter.pinned)
                      }
                    >
                      <Tooltip
                        text={
                          filter.pinned
                            ? `${__('table.filters.unpin')} ${filter.name}`
                            : `${__('table.filters.pin')} ${filter.name}`
                        }
                      >
                        {filter.pinned ? <UnpinBigIcon /> : <PinBigIcon />}
                      </Tooltip>
                    </span>
                  </div>
                );

                if (filter.type == 'date_range') {
                  let value = getURLParam('filter.' + filter.key);
                  let start, end;

                  if (value.split('|').length == 2) {
                    start = value.split('|')[0];
                    end = value.split('|')[1];
                  }

                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.section,
                        _.includes(
                          this.props.filterExpandedSections,
                          filter.key
                        ) && styles.expanded,
                        getURLParam('filter.' + filter.key) != '' &&
                          styles.active
                      )}
                    >
                      {filterHeader}
                      <div className={styles.daterange}>
                        {(value != '0' || !filter.nullable) && (
                          <div className={styles.datepickers}>
                            <DateTimePicker
                              icon={DatetimeIcon}
                              placeholder={__(
                                'table.filters.title.select-date-from'
                              )}
                              footer
                              meta={{
                                active: _.get(
                                  this.state,
                                  'filter_active_from_' + filter.key,
                                  false
                                ),
                              }}
                              input={{
                                value: start,
                                onFocus: () =>
                                  this.setState({
                                    ['filter_active_from_' + filter.key]: true,
                                    ['filter_active_to_' + filter.key]: false,
                                  }),
                                onBlur: () =>
                                  this.setState({
                                    ['filter_active_from_' + filter.key]: false,
                                  }),
                                onChange: (start) =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    [start, end].join('|')
                                  ),
                              }}
                            />
                            <DateTimePicker
                              icon={DatetimeIcon}
                              placeholder={__(
                                'table.filters.title.select-date-to'
                              )}
                              footer
                              meta={{
                                active: _.get(
                                  this.state,
                                  'filter_active_to_' + filter.key,
                                  false
                                ),
                              }}
                              input={{
                                value: end,
                                onFocus: () =>
                                  this.setState({
                                    ['filter_active_to_' + filter.key]: true,
                                    ['filter_active_from_' + filter.key]: false,
                                  }),
                                onBlur: () =>
                                  this.setState({
                                    ['filter_active_to_' + filter.key]: false,
                                  }),
                                onChange: (end) =>
                                  setURLParam(
                                    'filter.' + filter.key,
                                    [start, end].join('|')
                                  ),
                              }}
                            />
                          </div>
                        )}
                        {filter.nullable && (
                          <div className={styles.nullable}>
                            {value == '0' ? (
                              <CheckedIcon
                                className={styles.checked}
                                onClick={() =>
                                  setURLParam('filter.' + filter.key, '')
                                }
                              />
                            ) : (
                              <UncheckedIcon
                                className={styles.unchecked}
                                onClick={() =>
                                  setURLParam('filter.' + filter.key, '0')
                                }
                              />
                            )}
                            <span
                              onClick={() =>
                                setURLParam(
                                  'filter.' + filter.key,
                                  value == '0' ? '' : '0'
                                )
                              }
                            >
                              {filter.nullable}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }

                if (filter.type == 'select') {
                  const { filtersQuery } = this.state;

                  let active_filter_items = _.filter(
                    getURLParam('filter.' + filter.key) == 'n/a'
                      ? []
                      : _.split(getURLParam('filter.' + filter.key), '|'),
                    _.identity
                  );

                  const non_filtered_items = _.sortBy(
                    _.sortBy(filter.items, 'label'),
                    (row) => (row.value == '0' ? 0 : 1)
                  );

                  let filter_items = non_filtered_items;

                  if (_.size(_.get(filtersQuery, filter.key)) > 0) {
                    filter_items = _.filter(filter_items, (item) =>
                      _.includes(
                        _.toLower(item.label),
                        _.toLower(_.get(filtersQuery, filter.key))
                      )
                    );
                  }

                  active_filter_items = _.map(active_filter_items, (item) =>
                    encodeURIComponent(item)
                  );
                  filter_items = _.map(filter_items, (item) => ({
                    ...item,
                    value: encodeURIComponent(item.value),
                  }));

                  if (this.props.filtersSort) {
                    filter_items = _.sortBy(filter_items, (item) => {
                      const sorting_number = this.props.filtersSort(
                        filter.key,
                        item.value,
                        item.label
                      );

                      return _.isUndefined(sorting_number) ? 0 : sorting_number;
                    });
                  }

                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.section,
                        _.includes(
                          this.props.filterExpandedSections,
                          filter.key
                        ) && styles.expanded,
                        getURLParam('filter.' + filter.key) != '' &&
                          !_.every(
                            _.split(getURLParam('filter.' + filter.key), '|'),
                            (item) =>
                              _.includes(
                                _.map(filter?.defaultItems, 'value'),
                                item
                              )
                          ) &&
                          styles.active
                      )}
                    >
                      {filterHeader}
                      <div>
                        {_.size(non_filtered_items) >= 10 && (
                          <div className={styles.filterSearch}>
                            <SearchIcon className={styles.magnifier} />
                            <input
                              type='text'
                              ref={'filter_search_' + filter.key}
                              value={_.get(filtersQuery, filter.key, '')}
                              placeholder={__('table.filters.search')}
                              onChange={(e) =>
                                this.setState({
                                  filtersQuery: {
                                    ...filtersQuery,
                                    [filter.key]: e.target.value,
                                  },
                                })
                              }
                            />
                            {_.size(_.get(filtersQuery, filter.key, '')) >
                              0 && (
                              <CloseSmallIcon
                                className={styles.close}
                                onClick={() => {
                                  this.setState({
                                    filtersQuery: {
                                      ...filtersQuery,
                                      [filter.key]: '',
                                    },
                                  });

                                  this.refs[
                                    'filter_search_' + filter.key
                                  ].focus();
                                }}
                              />
                            )}
                          </div>
                        )}
                        <div className={styles.items}>
                          {_.map(filter_items, (item, i1) => {
                            const checked = _.includes(
                              active_filter_items,
                              _.toString(item.value)
                            );

                            return (
                              <div key={i1} className={styles.item}>
                                {!checked && (
                                  <UncheckedIcon
                                    className={styles.unchecked}
                                    onClick={() => {
                                      setURLParam(
                                        'filter.' + filter.key,
                                        [
                                          ...active_filter_items,
                                          item.value,
                                        ].join('|')
                                      );
                                    }}
                                  />
                                )}
                                {checked === true && (
                                  <CheckedIcon
                                    className={styles.checked}
                                    onClick={() => {
                                      setURLParam(
                                        'filter.' + filter.key,
                                        _.reject(
                                          active_filter_items,
                                          (row) => row == item.value
                                        ).join('|')
                                      );
                                    }}
                                  />
                                )}
                                <span
                                  onClick={() =>
                                    setURLParam(
                                      'filter.' + filter.key,
                                      _.some(
                                        _.split(
                                          getURLParam('filter.' + filter.key),
                                          '|'
                                        ),
                                        (item) =>
                                          _.includes(
                                            _.map(
                                              filter?.defaultItems,
                                              'value'
                                            ),
                                            item
                                          )
                                      )
                                        ? [
                                            ..._.map(
                                              filter?.defaultItems,
                                              'value'
                                            ),
                                            item.value,
                                          ].join('|')
                                        : item.value
                                    )
                                  }
                                >
                                  <Tooltip placement='left' text={item.label}>
                                    {item.label}
                                  </Tooltip>
                                </span>
                              </div>
                            );
                          })}

                          {_.size(_.get(filtersQuery, filter.key)) > 0 &&
                            _.isEmpty(filter_items) && (
                              <div className={styles.noResults}>
                                No results found.
                              </div>
                            )}
                        </div>
                        {_.map(filter.defaultItems, (defaultItem) => (
                          <div className={styles.defaultItems}>
                            <span
                              onClick={() =>
                                setURLParam(
                                  'filter.' + filter.key,
                                  defaultItem.value
                                )
                              }
                            >
                              <Tooltip
                                placement='left'
                                text={defaultItem.label}
                              >
                                {defaultItem.label}
                              </Tooltip>
                            </span>

                            <ToggleButton
                              onToggle={() => {
                                const checked = _.includes(
                                  active_filter_items,
                                  _.toString(defaultItem.value)
                                );

                                if (checked) {
                                  setURLParam(
                                    'filter.' + filter.key,
                                    _.reject(
                                      active_filter_items,
                                      (row) => row == defaultItem.value
                                    ).join('|')
                                  );
                                } else {
                                  setURLParam(
                                    'filter.' + filter.key,
                                    [
                                      ...active_filter_items,
                                      defaultItem.value,
                                    ].join('|')
                                  );
                                }
                              }}
                              value={_.includes(
                                active_filter_items,
                                _.toString(defaultItem.value)
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }

                if (filter.type == 'range') {
                  let value = getURLParam('filter.' + filter.key);

                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.section,
                        _.includes(
                          this.props.filterExpandedSections,
                          filter.key
                        ) && styles.expanded,
                        getURLParam('filter.' + filter.key) != '' &&
                          styles.active
                      )}
                    >
                      {filterHeader}
                      <Range
                        nullable={filter.nullable}
                        value={value}
                        onChange={(value) =>
                          setURLParam('filter.' + filter.key, value)
                        }
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Filter;
