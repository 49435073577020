import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/invite_new_company_member_wizard/InviteNewCompanyMemberForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import Input from "../../components/Input";
import {__} from "../../core/utils";
import Select from "../../components/Select";
import classNames from "classnames";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";
import * as _ from "lodash";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideInviteNewCompanyMemberWizard: () => dispatch({
                type: "table.my_company_members/SET_TABLE_PARAMS",
                params: {
                    wizard_active: undefined
                }
            })
        }
    };
};

@reduxForm({
    form: "invite_new_company_member_wizard.invite_new_company_member",
})
@connect(mapStateToProps, mapDispatchToProps)
class InviteNewCompanyMemberForm extends Component {
    render_invites = ({fields}) => (<>
        {fields.map((field, i) => (
            <div className={classNames(styles.flex, styles.memberRow)} key={i}>
                <div className={styles.f5}>
                    <Field name={field + '.email'} component={Input} label={__('companies-and-users.my-company-members.invite-new-members.new-members-email')} />
                </div>
                <div className={styles.f2}>
                    <Field name={field + '.role'} component={Select} noSort label={__('companies-and-users.my-company-members.table-title.role')} options={[
                        ...(this.props.auth.role === "owner" ? [{
                            value: 'owner',
                            label: __('companies-and-users.my-company-members.table-title.role.owner')
                        }] : []),
                        {
                            value: 'administrator',
                            label: __('companies-and-users.my-company-members.table-title.role.administrator')
                        },
                        {
                            value: 'user',
                            label: __('companies-and-users.my-company-members.table-title.role.user')
                        },
                    ]} />
                </div>
                <CloseMiddleIcon className={classNames(styles.deleteMember, _.size(fields) == 1 && styles.disabled)} onClick={() => _.size(fields) > 1 && fields.remove(i)} />
            </div>
        ))}
        <Button lightBlue rightIcon={PlusMiddleIcon} leftText={__('button.invite-more')} onClick={() => fields.push({role: 'user'})} />
    </>);

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <FieldArray name="invites" component={this.render_invites} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideInviteNewCompanyMemberWizard} />
                    <Button gray medium submit right leftText={__('button.invite')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default InviteNewCompanyMemberForm;
