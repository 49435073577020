import store from "../../../../core/store";

export const listFilters = () => {
	return store.dispatch({
		action: "table.superadmin_filters/LIST_FILTERS",
		method: "GET",
		endpoint: "/superadmin/filters",
		main: true
	});
};

export const createFilter = (formData) => {
	return store.dispatch({
		action: "table.superadmin_filters/CREATE_FILTER",
		method: "POST",
		endpoint: "/superadmin/filters",
		data: formData
	});
};

export const deleteFilters = (filters_id) => {
	return store.dispatch({
		action: "table.superadmin_filters/DELETE_FILTERS",
		method: "DELETE",
		endpoint: "/superadmin/filters",
		data: {
			filters: filters_id
		}
	});
};

export const readFilter = (filters_id) => {
	return store.dispatch({
		action: "table.superadmin_filters/READ_FILTER",
		method: "GET",
		endpoint: "/superadmin/filters/" + filters_id,
		has404: true
	});
};

export const updateFilter = (filters_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_filters/UPDATE_FILTER",
		method: "PUT",
		endpoint: "/superadmin/filters/" + filters_id,
		data: formData
	});
};