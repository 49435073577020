import store from "../../../../core/store";

export const listFunctionalSpaces = () => {
	return store.dispatch({
		action: "table.superadmin_functional_spaces/LIST_FUNCTIONAL_SPACES",
		method: "GET",
		endpoint: "/superadmin/functional_spaces",
		main: true
	});
};

export const createFunctionalSpace = (formData) => {
	return store.dispatch({
		action: "table.superadmin_functional_spaces/CREATE_FUNCTIONAL_SPACE",
		method: "POST",
		endpoint: "/superadmin/functional_spaces",
		data: formData
	});
};

export const deleteFunctionalSpaces = (functional_space_ids) => {
	return store.dispatch({
		action: "table.superadmin_functional_spaces/DELETE_FUNCTIONAL_SPACES",
		method: "DELETE",
		endpoint: "/superadmin/functional_spaces",
		data: {
			functional_spaces: functional_space_ids
		}
	});
};

export const readFunctionalSpace = (functional_space_id) => {
	return store.dispatch({
		action: "table.superadmin_functional_spaces/READ_FUNCTIONAL_SPACE",
		method: "GET",
		endpoint: "/superadmin/functional_spaces/" + functional_space_id,
		has404: true
	});
};

export const updateFunctionalSpace = (functional_space_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_functional_spaces/UPDATE_FUNCTIONAL_SPACE",
		method: "PUT",
		endpoint: "/superadmin/functional_spaces/" + functional_space_id,
		data: formData
	});
};