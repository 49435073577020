import store from "../../../core/store";

export const listPartnerCompanies = () => {
    return store.dispatch({
		action: "table.partner_companies/LIST_PARTNER_COMPANIES",
		method: "GET",
		endpoint: "/companies/partner",
		main: true
	});
};

export const removePartnerCompanies = (company_ids) => {
	return store.dispatch({
		action: "table.partner_companies/REMOVE_PARTNER_COMPANIES",
		method: "DELETE",
		endpoint: "/companies/partner",
		data: {
			companies: company_ids
		}
	});
};