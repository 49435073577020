import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/my_deliveries/MyDeliveriesFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import {connect} from "react-redux";
import CompanySizeIcon from "../../assets/images/company-size-15x15.svg";
import {__, dateTimeFrom, redirect, setTableParams, validateForm} from "../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import autobind from "autobind-decorator";
import {setNotification} from "../../redux/actions/general/notification";
import {
    deleteMyDeliveries,
    listMyDeliveries,
    sendDeliveries,
    updateMyDelivery
} from "../../redux/actions/table/my_deliveries";
import EditMyDeliveryForm from "../../forms/plans/EditMyDeliveryForm";
import withRouter from "react-router/es/withRouter";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import UserMaleIcon from "../../assets/images/user-male-15x15.svg";
import DatetimeIcon from "../../assets/images/datetime-15x15.svg";
import PlanSetIcon from "../../assets/images/plan-set-15x15.svg";
import DisciplineIcon from "../../assets/images/discipline-15x15.svg";
import TeamIcon from "../../assets/images/team-15x15.svg";
import Tooltip from "../../components/Tooltip";
import SendIcon from "../../assets/images/send-16x16.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import DeleteIcon from "../../assets/images/delete-15x15.svg";

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.my_deliveries/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(null, mapDispatchToProps)
@subscribe(Localization, "localization")
@withRouter
class MyDeliveriesFlyout extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateMyDelivery(formData.id, _.omit(formData, ['id'])).then(() => {
                listMyDeliveries(this.props.match.params.stage);

                this.props.readAction(formData.id);

                setNotification(__('plans.deliveries.notification.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>{data.title}</span>
                        <CloseBigIcon onClick={() => setTimeout(() => {
                            this.props.actions.setTableParams({
                            clicked_row: undefined
                        })}, 100)}/>
                    </div>
                    <div className={styles.actions}>
                        {!data.delivered_at && <div className={styles.actionWrapper}>
                            <Tooltip text={__('plans.deliveries.my-deliveries.send-delivery')} key={_.uniqueId()}><SendIcon
                                onClick={e => {
                                    e.stopPropagation();

                                    sendDeliveries([data.id]).then(() => {
                                        setNotification(__('plans.deliveries.my-deliveries.selected-delivery-successfully-sent'));

                                        this.props.readAction(data.id);
                                        listMyDeliveries(this.props.match.params.stage);
                                    });
                                }}
                            /></Tooltip>
                        </div>}
                        <div className={styles.actionWrapper}>
                            {!data.delivered_at && <Tooltip text={__('plans.deliveries.my-deliveries.delete-delivery')} key={_.uniqueId()}>
                                <DeleteIcon
                                    onClick={e => {
                                        e.stopPropagation();

                                        showAlertbox({
                                            color: "red",
                                            title: __('general.alert.are-you-sure'),
                                            description: __('plans.deliveries.my-deliveries.want-delete-selected-delivery.process-cannot-undone'),
                                            buttons: [
                                                {
                                                    color: 'lightGray',
                                                    text: __('general.alert.no-close'),
                                                    onClick: 'close'
                                                },
                                                {
                                                    color: 'gray',
                                                    text: __('general.alert.yes-delete'),
                                                    onClick: () => {
                                                        deleteMyDeliveries([data.id]).then(() => {
                                                            hideAlertbox();

                                                            setNotification(__('plans.deliveries.my-deliveries.selected-delivery-deleted'));
                                                            listMyDeliveries(this.props.match.params.stage);

                                                            setTableParams('my_deliveries', {
                                                                clicked_row: undefined
                                                            });
                                                        });
                                                    },
                                                }
                                            ]
                                        })
                                    }}
                                />
                            </Tooltip>}
                        </div>
                    </div>
                </div>
                <div className={styles.scroller}>
                    <div className={styles.details}>
                        <div>
                            <span>
                                <PlanSetIcon /> {__('plans.deliveries.columns.plans')}
                            </span>
                            <span className={styles.plans} onClick={() => redirect('/stages/' + this.props.match.params.stage + '/deliveries/public/' + data.id)}>
                                {data.plans} <Tooltip text={__('plans.tooltip.icon.deliveries.goto-plans')}><ArrowDoubleRightSmallIcon /></Tooltip>
                            </span>
                        </div>
                        <div>
                            <span>
                                <DisciplineIcon /> {__('plans.deliveries.columns.disciplines')}
                            </span>
                            <span>
                                {data.discipline.id == null ? (data.discipline.description + '*') : _.get(this.props.localization.disciplines, [data.discipline.id, 'name'])}
                            </span>
                        </div>
                        <div>
                            <span>
                                <TeamIcon /> {__('plans.deliveries.columns.team')}
                            </span>
                            <span>
                                {data.team.company}
                            </span>
                        </div>
                        <div>
                            <span>
                                <DatetimeIcon /> {__('plans.deliveries.columns.created-at')}
                            </span>
                            <span>
                                {dateTimeFrom(data.created_at)}
                            </span>
                        </div>
                        <div>
                            <span>
                                <UserMaleIcon /> {__('plans.deliveries.columns.created-by')}
                            </span>
                            <span>
                                {data.created_by}
                            </span>
                        </div>
                        <div>
                            <span>
                                <CompanySizeIcon /> {__('plans.deliveries.columns.sent')}
                            </span>
                            <span>
                                {data.delivered_at ? __('plans.deliveries.columns.yes') : __('plans.deliveries.columns.no')}
                            </span>
                        </div>
                        {data.delivered_at && (
                            <div>
                                <span>
                                    <DatetimeIcon /> {__('plans.deliveries.columns.delivered-at')}
                                </span>
                                <span>
                                    {dateTimeFrom(data.delivered_at)}
                                </span>
                            </div>
                        )}
                        {data.delivered_at && (
                            <div>
                                <span>
                                    <UserMaleIcon /> {__('plans.deliveries.columns.delivered-to')}
                                </span>
                                <span>
                                    {_.join(data.delivered_to, ', ')}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={styles.form}>
                        <EditMyDeliveryForm
                            onSubmit={this._handleSubmit}
                            initialValues={_.pick(data, ['id', 'title'])}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MyDeliveriesFlyout;
