import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/InviteNewCompanyMemberWizard.scss";
import autobind from "autobind-decorator";
import UserNewIcon from "../assets/images/user-new-60x60.svg";
import {__, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import {withRouter} from "react-router";
import InviteNewCompanyMemberForm from "../forms/invite_new_company_member_wizard/InviteNewCompanyMemberForm";
import {inviteNewCompanyMember, listMyCompanyMembers} from "../redux/actions/table/my_company_members";

@withRouter
class InviteNewCompanyMemberWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
            inviteNewCompanyMember(formData).then(({response}) => {
                listMyCompanyMembers();

				if(_.get(response, 'data.invite') === false) {
					//TODO:URGENT - implement this
					// setNotification("A user with the entered email already exists in the application.\n\nInstead, a user has been added to Partner Members.");
				} else {
					setNotification("You have successfully sent an invitation.");
				}

				this.props.onClose();
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.invite-new-company-member.title')}</span>
						<span>{__('wizard.invite-new-company-member.subtitle')}</span>
					</div>
					<div className={styles.right}>
						<UserNewIcon />
					</div>
				</div>
				<div className={styles.body}>
					<InviteNewCompanyMemberForm onSubmit={this._handleSubmit} initialValues={{
						invites: [
							{
								role: 'user'
							}
						]
					}} />
				</div>
			</>
		);
	}
}

export default InviteNewCompanyMemberWizard;
