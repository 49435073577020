import React, { useEffect, useState } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_boq_calculation_as_template_wizard/AddBOQCalculationAsTemplateForm.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import { __, getLocalized } from '../../core/utils';
import * as _ from 'lodash';
import Select from '../../components/Select';
import { connect } from 'react-redux';
import { listBOQTemplateSubgroups } from '../../redux/actions/table/superadmin/boq_template_subgroups';

const AddBOQCalculationAsTemplateForm = ({
  handleSubmit,
  onClose,
  language_id,
}) => {
  const [subgroups, setSubgroups] = useState([]);

  useEffect(() => {
    listBOQTemplateSubgroups().then(({ response }) => {
      setSubgroups(response.data);
    });
  }, []);

  const boq_template_subgroups = _.map(subgroups, (subgroup) => {
    return {
      value: subgroup.id,
      label: getLocalized(subgroup.name, language_id),
      group: getLocalized(subgroup.boq_template_group, language_id),
    };
  });

  return (
    <Form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.flex}>
        <div className={styles.f1}>
          <Field
            name='boq_template_subgroup_id'
            component={Select}
            label={__(
              'boq-calculation.flyout.create-boq-calculation-as-template.boq_template_subgroup_id'
            )}
            options={boq_template_subgroups}
            searchable
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          lightGray
          medium
          middleText={__('button.cancel')}
          onClick={onClose}
        />
        <Button
          lightBlue
          medium
          middleText={__('button.add')}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    language_id: state.table.working_set_boq.language_id,
  };
};

export default reduxForm({
  form: 'create_boq_template_calculation_wizard.create_boq_calculation_as_template',
})(
  connect(
    mapStateToProps,
    null
  )(subscribe(Localization, 'localization')(AddBOQCalculationAsTemplateForm))
);
