import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import Textarea from "../../components/Textarea";
import {__} from "../../core/utils";
import {enableAutosave} from "../../redux/actions/general/autosave";
import Input from "../../components/Input";
import {withRouter} from "react-router";

@reduxForm({
    form: "expansion_joints.edit_expansion_joint",
    enableReinitialize: true,
})
@withRouter
class EditExpansionJointForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {handleSubmit, data} = this.props;
        const {project_languages} = data;

        return (
            <Form onSubmit={handleSubmit}>
                <Field name="title" component={Input} localized={project_languages} label={__('expansion-joints.field.title')} maxLength={50} />
                <Field name="description" component={Textarea} localized={project_languages} label={__('expansion-joints.field.description')} maxLength={2000} rows={1} maxRows={10} />
                <Field name="area" component={Input} label={__('expansion-joints.field.area')} numeric decimal maxLength={10} />
            </Form>
        );
    }
}

export default EditExpansionJointForm;
