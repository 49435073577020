import React, { useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_tender_wizard/CreateTenderForm.scss';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import { __, getActiveStage, setFormValue } from '../../core/utils';
import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker';
import TenderStatusIcon from '../../assets/images/tender-status-15x15.svg';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import PeopleChooser from '../../components/PeopleChooser';
import MultiSelect from '../../components/MultiSelect';
import moment from 'moment';
import {
  listPossibleTenderAdministrators,
  listPossibleTenderFollowers,
} from '../../redux/actions/table/tenders';

const CreateTenderForm = (props) => {
  const [possible_tender_followers, set_possible_tender_followers] = useState(
    []
  );
  const [possible_tender_administrators, set_possible_tender_administrators] =
    useState([]);

  useEffect(() => {
    listPossibleTenderAdministrators(getActiveStage()).then(({ response }) => {
      set_possible_tender_administrators(response.data);
    });

    listPossibleTenderFollowers(getActiveStage()).then(({ response }) => {
      set_possible_tender_followers(response.data);
    });
  }, []);

  const _handleSubmit = (edit) => {
    props.change('edit', edit);
    setTimeout(() => props.submit(), 10);
  };

  const statuses = [
    {
      value: 'draft',
      label: __('table.columns.task-status-draft'),
      color: '#9C27B0',
    },
  ];

  const languages = _.map(props.projectLanguages, (primary, language_id) => ({
    label: _.get(props.localization.languages, [language_id, 'name']),
    value: language_id,
  }));

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <div className={styles.top}>
        <Field
          name='status'
          component={Select}
          label={__('table.columns.tender-status')}
          options={statuses}
          noSort
          dropdown
          icon={<TenderStatusIcon className={styles.icon} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('tender.tooltip.icon.status')}>
              <div
                className={styles.dropdown}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TenderStatusIcon className={styles.icon} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </div>
      <div className={styles.form}>
        <div className={styles.flex}>
          <div className={styles.f5}>
            <Field
              name='languages'
              component={MultiSelect}
              searchable
              label={__('Tender Languages')}
              noSort
              options={languages}
            />
          </div>
        </div>

        <div className={styles.flex}>
          <div className={styles.f5}>
            <Field
              name='date'
              component={DateTimePicker}
              label={__('table.columns.tender-date-time')}
              footer
              range
              time
              presets
              onChange={(e) => {
                setFormValue(
                  props.form,
                  'duration',
                  moment(e.end).diff(moment(e.start), 'days')
                );

                setFormValue(
                  props.form,
                  'remaining_days',
                  moment(e.end).diff(moment().startOf('day').utc(), 'days')
                );
              }}
            />
          </div>
          <div className={styles.f3}>
            <Field
              name='duration'
              component={Input}
              label={__('table.columns.tender-duration-estimate')}
              numeric
              hint={__('tender.hint.in-days')}
              disabled
            />
          </div>

          <div className={styles.f3}>
            <Field
              name='remaining_days'
              component={Input}
              label={__('table.columns.tender-remaining-estimate')}
              numeric
              hint={__('tender.hint.in-days')}
              disabled
            />
          </div>
        </div>
        <Field
          name='title'
          component={Input}
          localized={props.projectLanguages}
          label={__('table.columns.tender-title')}
          maxLength={50}
        />
        <Field
          name='description'
          component={Textarea}
          localized={props.projectLanguages}
          label={__('table.columns.tender-description')}
          maxLength={1000}
          rows={1}
          maxRows={10}
        />
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='administrators'
              component={PeopleChooser}
              label={__('table.columns.tender-administrators')}
              searchable
              options={possible_tender_administrators}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='followers'
              component={PeopleChooser}
              label={__('table.columns.tender-followers')}
              searchable
              options={possible_tender_followers}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={props.actions.hideCreateTenderWizard}
        />
        <ButtonGroup>
          <Button
            lightBlue
            medium
            middleText={__('Create')}
            spinning={props.submitting}
            onClick={() => _handleSubmit(false)}
          />
          <Button
            lightBlue
            medium
            middleText={__('Create & Edit')}
            spinning={props.submitting}
            onClick={() => _handleSubmit(true)}
          />
        </ButtonGroup>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    values: getFormValues('create_tender_form.create_tender')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideCreateTenderWizard: () =>
        dispatch({
          type: 'table.tenders/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
          },
        }),
    },
  };
};

export default reduxForm({
  form: 'create_tender_form.create_tender',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(CreateTenderForm))
);
