import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {withRouter} from "react-router";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import styles from "../../styles/forms/auth/ForgotPasswordForm.scss";
import Input from "../../components/Input";
import {__, redirect} from "../../core/utils";

@reduxForm({
	form: "auth.forgot_password",
})
@withRouter
class ForgotPasswordForm extends Component {
	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.form}>
					<Field name="email" component={Input} label={__('Enter Your email')} center />
					<div className={styles.info}>
						{__('Just remembered your forgotten password')}? <a onClick={() => redirect("/login")}>{__('welcome-to-volum3.forgot-password.recover.sign-in')}</a>
					</div>
				</div>
				<div className={styles.footer}>
					<Button submit center leftText={__('button.reset-password')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default ForgotPasswordForm;