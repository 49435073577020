import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../../styles/forms/superadmin/add_product_category_wizard/AddProductCategoryForm.scss';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { __, mapStateToProps, setTableParams } from '../../../core/utils';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import ButtonGroup from '../../../components/ButtonGroup';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';

@withRouter
@reduxForm({ form: 'superadmin.add_note_category_wizard.add_note_category' })
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  values: getFormValues(
    'superadmin.add_note_category_wizard.add_note_category'
  )(state),
  auth: state.auth,
}))
class AddNoteCategoryForm extends Component {
  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  render() {
    const application_languages = _.transform(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (languages, language) => {
        languages[language.id] = language.id == this.props.auth.language_id;

        return languages;
      },
      {}
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='name'
            localized={application_languages}
            component={Input}
            label='Name'
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('superadmin_note_categories', {
                add_note_category_wizard: undefined,
              })
            }
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.create')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(false)}
            />
            <Button
              lightBlue
              medium
              middleText={__('button.create-edit')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(true)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default AddNoteCategoryForm;
