const initialState = {};

const _transformData = (data) => {
  const processDeep = (rows, parentId = undefined, level = 0, code = '') => {
    return _.map(rows, (row, i) => {
      const id = parentId ? [parentId, row.id].join('-') : _.toString(row.id);
      const new_code = code.length == 0 ? i + 1 : `${code}.${i + 1}`;

      return {
        ...row,
        code: level < 3 ? new_code : undefined,
        level,
        id,
        parentId,
        children: row.children
          ? processDeep(
              _.sortBy(row.children, 'order'),
              id,
              level + 1,
              new_code
            )
          : undefined,
      };
    });
  };

  const transformed_data = processDeep(_.sortBy(data.groups, 'order'));

  let last_index = data.groups.length;

  !_.isEmpty(data.unallocated) &&
    transformed_data.push({
      id: 'UN',
      level: 0,
      order: last_index + 1,
    });

  _.each(data.unallocated, (calculation) =>
    transformed_data.push({
      ...calculation,
      id: ['UN', calculation.id].join('-'),
      level: 3,
      order: calculation.order + 1,
    })
  );

  return transformed_data;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.tenders/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };

    case 'table.tenders/LIST_ALL_TENDERS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        filters: action.response.filters,
        ...action.response.pagination,
      };

    case 'table.tenders/READ_TENDER_SUCCESS':
      return {
        ...state,
        flyout: action.response.data,
      };

    case 'table.tenders/READ_TENDER_BOQ_SUCCESS':
      return {
        ...state,
        tender_boq_data: _transformData(action.response.data),
      };

    default:
      return state;
  }
}
