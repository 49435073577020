import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/attach_task_to_plan_wizard/AddExistingTaskForm.scss";
import Button from "../../components/Button";
import {__, setTableParams, transformToListPairs} from "../../core/utils";
import Select from "../../components/Select";
import {withRouter} from "react-router";
import ButtonGroup from "../../components/ButtonGroup";

@withRouter
@reduxForm({form: "attach_task_to_plan_wizard.add_existing_task_form"})
class AddExistingTaskForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="task_id" searchable component={Select} label={__('table.columns.choose-existing-task')} options={transformToListPairs(this.props.possibleTasks, 'title')} />
                    <div className={styles.footer}>
                        <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_tasks', {
                            attach_task_wizard: undefined
                        })} />
                        <ButtonGroup>
                            <Button lightBlue medium submit middleText={__('button.attach')} spinning={this.props.submitting} />
                        </ButtonGroup>
                    </div>
                </div>
            </Form>
        );
    }
}

export default AddExistingTaskForm;
