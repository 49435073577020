import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import Table from '../../components/Table';
import {
  __,
  getLocalized,
  getURLParam,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import { createSuperadminSidebar } from '../../redux/actions/general/sidebar';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import * as _ from 'lodash';
import { setPlusActions } from '../../redux/actions/general/plus';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import {
  deleteNoteCategories,
  listNoteCategories,
  readNoteCategory,
} from '../../redux/actions/table/superadmin/note_categories';
import NoteCategoriesFlyout from '../../flyouts/superadmin/note_categories/NoteCategoriesFlyout';
import { listLocalization } from '../../redux/actions/localization';

@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  table: state.table['superadmin_note_categories'],
  application_language_id: state.auth.language_id,
}))
class NoteCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    listActiveModules();
    createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

    const { superadmin } = this.props;

    superadmin.add_note_category &&
      setPlusActions([
        {
          title: 'Add instruction category',
          onClick: () =>
            setTableParams('superadmin_note_categories', {
              add_note_category_wizard: true,
            }),
        },
      ]);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readNoteCategory(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  render() {
    const { superadmin, application_language_id } = this.props;

    const columns = [
      {
        key: 'id',
        name: 'ID',
        width: 60,
        sortable: false,
      },
      {
        key: 'name',
        name: 'Name',
        value: (name) => getLocalized(name, application_language_id),
      },
    ];

    const singleActions = (note_category_id) => {
      return [
        <Tooltip text='Delete selected note category' key={_.uniqueId()}>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description:
                  'Do you really want to delete the selected instruction categories?\n\nThis process cannot be undone.',
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteNoteCategories([note_category_id]).then(() => {
                        hideAlertbox();

                        setNotification(
                          'The selected note category has been deleted.'
                        );

                        listNoteCategories();
                        listLocalization();

                        setTableParams('superadmin_note_categories', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (note_categories_ids) => [
      <Tooltip text='Delete selected instruction categories' key={_.uniqueId()}>
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description:
                'Do you really want to delete these instruction categories?\n\nThis process cannot be undone.',
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteNoteCategories(note_categories_ids).then(() => {
                      hideAlertbox();

                      setNotification(
                        'The selected instruction categories have been deleted.'
                      );

                      listNoteCategories();
                      listLocalization();

                      setTableParams('superadmin_note_categories', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <NoteCategoriesFlyout
        data={this.state.preview}
        readAction={(note_category_id) => {
          return new Promise((resolve) => {
            readNoteCategory(getURLParam('id') || note_category_id).then(
              ({ response }) => {
                this.setState({ preview: response.data });

                resolve(response);
              }
            );
          });
        }}
      />
    );

    return (
      <Table
        listAction={listNoteCategories}
        name='superadmin_note_categories'
        groupActions={superadmin.delete_note_categories && groupActions}
        singleActions={superadmin.delete_note_categories && singleActions}
        title={__('modules.submodules.title.instruction-categories')}
        preview={this.state.preview ? preview : []}
        columns={columns}
      />
    );
  }
}

export default NoteCategories;
