import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/superadmin/notes/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm } from '../../../core/utils';
import { setNotification } from '../../../redux/actions/general/notification';
import {
  listNotes,
  updateNote,
} from '../../../redux/actions/table/superadmin/notes';
import EditNoteForm from '../../../forms/superadmin/notes/EditNoteForm';

@withRouter
class Properties extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateNote(formData.id, _.omit(formData, ['id'])).then(() => {
        listNotes();

        this.props.readAction(formData.id);

        setNotification(
          __('properties.notifications.properties-have-been-saved')
        );
      })
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.scroller}>
        <EditNoteForm
          onSubmit={this._handleSubmit}
          initialValues={{
            ..._.pick(data, [
              'id',
              'note',
              'priority',
              'note_category_id',
              'specification_subgroup_ids',
            ]),
          }}
        />
      </div>
    );
  }
}

export default Properties;

// 'product_category_ids',
// 'product_group_ids',
// 'product_subgroup_ids',
