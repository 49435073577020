import React, { useState, useEffect } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_bidder_wizard/AddBidderForm.scss';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import { __, getActiveStage } from '../../core/utils';
import PeopleChooser from '../../components/PeopleChooser';
import { listPossibleTenderBidders } from '../../redux/actions/table/tenders';

const AddBidderForm = (props) => {
  const [possible_tender_bidders, set_possible_tender_bidders] = useState([]);

  useEffect(() => {
    listPossibleTenderBidders(getActiveStage()).then(({ response }) => {
      set_possible_tender_bidders(response.data);
    });
  }, []);

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <Field
        name='bidders'
        component={PeopleChooser}
        label={__('table.columns.tender-bidders')}
        searchable
        options={possible_tender_bidders}
      />

      <div className={styles.footer}>
        <Button
          lightGray
          medium
          left
          middleText={__('button.cancel')}
          onClick={props.actions.hideAddBidderWizard}
        />
        <Button
          gray
          medium
          submit
          right
          leftText={__('button.add')}
          rightIcon={ArrowRightMiddleIcon}
          spinning={props.submitting}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['tenders'],
    values: getFormValues('add_bidder_wizard.add_bidder')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideAddBidderWizard: () =>
        dispatch({
          type: 'table.tenders/SET_TABLE_PARAMS',
          params: {
            add_bidder_wizard_active: undefined,
          },
        }),
    },
  };
};

export default reduxForm({
  form: 'add_bidder_wizard.add_bidder',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(AddBidderForm))
);
