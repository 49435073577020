import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/address_book_contacts/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import {listAddressBookContacts, updateAddressBookContact} from "../../redux/actions/table/address_book_contacts";
import EditAddressBookContactForm from "../../forms/address_book_contacts/EditAddressBookContactForm";

@withRouter
class Properties extends Component {
    @autobind
    _handleSubmit(formData) {
        return validateForm(
            updateAddressBookContact(formData.id, _.omit(formData, ['id'])).then(() => {
                listAddressBookContacts();

                this.props.readAction(formData.id);

                setNotification(__('address-book-contacts.flyout.notification.saved'));
            })
        );
    }

    render() {
        const {data} = this.props;

        return (
            <div className={styles.scroller}>
                <EditAddressBookContactForm
                    onSubmit={this._handleSubmit}
                    data={data}
                    initialValues={{
                        ..._.pick(data, ['id', 'address_book_company_id', 'title', 'firstname', 'lastname', 'disciplines', 'is_designer', 'is_client', 'tags', 'note']),
                        emails: _.isEmpty(data.emails) ? [''] : data.emails,
                        phones: _.isEmpty(data.phones) ? [''] : data.phones,
                    }}
                />
            </div>
        );
    }
}

export default Properties;
