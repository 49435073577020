import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams } from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import styles from "../../styles/views/superadmin/Languages.scss";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import classNames from "classnames";
import LanguagesFlyout from "../../flyouts/superadmin/languages/LanguagesFlyout";
import { deleteLanguages, listLanguages, readLanguage } from "../../redux/actions/table/superadmin/languages";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_languages"],
	auth: state.auth,
}))
class Languages extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_language && setPlusActions([{
			title: "Add language",
			onClick: () => setTableParams('superadmin_languages', {
				add_language_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readLanguage(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, auth } = this.props;

		const columns = [
			{
				key: "id",
				name: "ID",
				width: 60,
				sortable: false
			},
			{
				key: "code",
				name: "Code",
				width: 60,
				sortable: false
			},
			{
				key: "name",
				name: "Name",
				sortable: false
			},
			{
				key: "tesseract_name",
				name: "Tesseract (OCR) code",
				width: 80,
				sortable: false
			},
			{
				key: "application_language",
				name: "Application language",
				value: application_language => <div className={classNames(styles.chip, application_language && styles.yes)}>{application_language ? 'Yes' : 'No'}</div>,
				width: 100,
				sortable: false
			},
		];

		const singleActions = (language_id) => {
			return [
				<Tooltip text="Delete selected language" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected language?\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteLanguages([language_id]).then(() => {
												hideAlertbox();

												setNotification("The selected language has been deleted.");
												listLanguages();

												setTableParams('superadmin_languages', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (languages_ids) => [
			<Tooltip text="Delete selected languages" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these languages?\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteLanguages(languages_ids).then(() => {
											hideAlertbox();

											setNotification("The selected languages have been deleted.");
											listLanguages();

											setTableParams('superadmin_languages', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<LanguagesFlyout
				data={this.state.preview}
				readAction={language_id => {
					return new Promise(resolve => {
						readLanguage(getURLParam('id') || language_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listLanguages}
				name="superadmin_languages"
				groupActions={superadmin.delete_language && groupActions}
				singleActions={superadmin.delete_language && singleActions}
				title={__("modules.submodules.title.languages")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default Languages;
