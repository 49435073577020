import store from '../../../../core/store';

export const listBOQTemplateCalculations = () => {
  return store.dispatch({
    action:
      'table.superadmin_boq_template_calculations/LIST_BOQ_TEMPLATE_CALCULATIONS',
    method: 'GET',
    endpoint: '/superadmin/boq_template_calculations',
    main: true,
  });
};

export const createBOQTemplateCalculation = (formData) => {
  return store.dispatch({
    action:
      'table.superadmin_boq_template_calculations/CREATE_BOQ_TEMPLATE_CALCULATION',
    method: 'POST',
    endpoint: '/superadmin/boq_template_calculations',
    data: formData,
  });
};

export const deleteBOQTemplateCalculations = (boq_template_calculation_ids) => {
  return store.dispatch({
    action:
      'table.superadmin_boq_template_calculations/DELETE_BOQ_TEMPLATE_CALCULATIONS',
    method: 'DELETE',
    endpoint: '/superadmin/boq_template_calculations',
    data: {
      boq_template_calculations: boq_template_calculation_ids,
    },
  });
};

export const readBOQTemplateCalculation = (boq_template_calculation_id) => {
  return store.dispatch({
    action:
      'table.superadmin_boq_template_calculations/READ_BOQ_TEMPLATE_CALCULATION',
    method: 'GET',
    endpoint:
      '/superadmin/boq_template_calculations/' + boq_template_calculation_id,
    has404: true,
  });
};

export const updateBOQTemplateCalculation = (
  boq_template_calculation_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.superadmin_boq_template_calculations/UPDATE_BOQ_TEMPLATE_CALCULATION',
    method: 'PUT',
    endpoint:
      '/superadmin/boq_template_calculations/' + boq_template_calculation_id,
    data: formData,
  });
};
