import React, {Component} from "react";
import styles from "../styles/components/ButtonGroup.scss";
import classNames from "classnames";

class ButtonGroup extends Component {
	render() {
		const classes = classNames([styles.wrapper, this.props.left && styles.left, this.props.center && styles.center, this.props.right && styles.right]);

		return <div className={classes}>{this.props.children}</div>;
	}
}

export default ButtonGroup;
