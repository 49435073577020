import React, { Component } from 'react';
import styles from '../styles/components/Textarea.scss';
import classNames from 'classnames';
import { __, mapStateToProps } from '../core/utils';
import Tooltip from '../components/Tooltip';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../helpers/Localization';
import autobind from 'autobind-decorator';
import ExpandIcon from '../assets/images/expand-16x16.svg';
import CollapseIcon from '../assets/images/collapse-16x16.svg';
import TextareaAutosize from 'react-autosize-textarea';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import {
  registerMultieditField,
  unregisterMultieditField,
} from '../redux/actions/general/multiedit';

@mapStateToProps((state) => ({
  multiedit: state.general.multiedit,
}))
@subscribe(Localization, 'localization')
class Textarea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active_language: _.findKey(this.props.localized, (item) => item == true),
    };
  }

  @autobind
  _handleOnChange(e) {
    if (!this.props.disabled) {
      if (this.props.localized) {
        const value = _.cloneDeep(this.props.input.value);

        _.set(value, this.state.active_language, e.currentTarget.value);

        this.props.input.onChange(
          _.pickBy(value, (translation) => !_.isEmpty(translation))
        );
      } else {
        this.props.input.onChange(e.currentTarget.value);
      }
    }
  }

  @autobind
  _handleOnFocus() {
    if (!this.props.disabled) {
      this.props.input.onFocus();

      const multiedit_enabled = _.isObject(
        this.props.multiedit[this.props.meta.form]
      );

      multiedit_enabled &&
        registerMultieditField(this.props.meta.form, this.props.input.name);
    }
  }

  render() {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );
    const multiedited =
      multiedit_enabled &&
      _.includes(
        _.get(this.props.multiedit, [this.props.meta.form, 'fields']),
        this.props.input.name
      );
    const LeftIcon = this.props.leftIcon;
    const { expanded } = this.state;

    const value = this.props.localized
      ? _.get(this.props.input.value, this.state.active_language, '')
      : this.props.input.value;

    const languages = _.map(
      _.orderBy(
        _.map(this.props.localized, (primary, language_id) => ({
          primary,
          language_id,
        })),
        ['primary'],
        ['desc']
      ),
      'language_id'
    );

    let placeholder = this.props.placeholder || '';

    if (multiedit_enabled) {
      placeholder = multiedited ? '' : '-- no change --';
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          this.props.meta.active && styles.focus,
          this.props.center && styles.center,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          this.props.disabled && styles.disabled,
          multiedit_enabled && !multiedited && styles.noChange,
          this.props.className
        )}
      >
        {this.props.label && (
          <div className={styles.label}>
            <label htmlFor={this.props.input.name}>{this.props.label}</label>
            {multiedit_enabled && multiedited && (
              <span
                className={styles.labelRevertChanges}
                onClick={() =>
                  unregisterMultieditField(
                    this.props.meta.form,
                    this.props.input.name
                  )
                }
              >
                {__('button.revert-changes')}
              </span>
            )}
          </div>
        )}
        <div className={styles.inputGroup}>
          {this.props.leftIcon && !this.props.center && (
            <LeftIcon
              onClick={this.props.onLeftIconClick}
              className={this.props.onLeftIconClick && styles.hasAction}
            />
          )}
          <TextareaAutosize
            rows={expanded ? _.max([this.props.rows + 3, 8]) : this.props.rows}
            maxRows={expanded ? undefined : this.props.maxRows}
            id={this.props.input.name}
            disabled={this.props.disabled}
            placeholder={placeholder}
            maxLength={this.props.maxLength}
            onBlur={() => this.props.input.onBlur()}
            onFocus={this._handleOnFocus}
            value={value || ''}
            onChange={this._handleOnChange}
          />
          {(this.props.maxRows > 2 || _.isUndefined(this.props.maxRows)) && (
            <>
              {expanded ? (
                <Tooltip text={__('general-text-area.tooltip.collapse')}>
                  <CollapseIcon
                    className={classNames(styles.expand)}
                    onClick={() => this.setState({ expanded: false })}
                  />
                </Tooltip>
              ) : (
                <Tooltip text={__('general-text-area.tooltip.expand')}>
                  <ExpandIcon
                    className={styles.expand}
                    onClick={() => this.setState({ expanded: true })}
                  />
                </Tooltip>
              )}
            </>
          )}
        </div>
        <div className={styles.bar} />
        <div
          className={classNames(
            styles.assist,
            ((_.get(this.props, 'meta.error') &&
              !this.props.localized &&
              _.get(this.props, 'meta.touched')) ||
              this.props.hint) &&
              styles.hint,
            this.props.maxLength && styles.maxLength
          )}
        >
          {this.props.localized ? (
            <div className={styles.localization}>
              {_.map(languages, (language) => (
                <Tooltip
                  key={language}
                  text={
                    _.get(this.props.localization, [
                      'languages',
                      language,
                      'name',
                    ]) +
                    ': ' +
                    _.size(_.trim(_.get(this.props.input.value, language))) +
                    ' characters'
                  }
                >
                  <div
                    onClick={() => this.setState({ active_language: language })}
                    className={classNames(
                      styles.language,
                      _.size(_.trim(_.get(this.props.input.value, language))) ==
                        0 && styles.empty,
                      language == this.state.active_language && styles.active
                    )}
                  >
                    <span className={styles.code}>
                      {_.get(this.props.localization, [
                        'languages',
                        language,
                        'code',
                      ])}
                    </span>
                    <span className={styles.count}>
                      ({_.size(_.trim(_.get(this.props.input.value, language)))}
                      )
                    </span>
                  </div>
                </Tooltip>
              ))}
            </div>
          ) : (
            <>
              {((_.get(this.props, 'meta.error') &&
                _.get(this.props, 'meta.touched')) ||
                this.props.hint) && (
                <span>
                  {_.get(this.props, 'meta.error') || this.props.hint}
                </span>
              )}
            </>
          )}
          {this.props.maxLength && (
            <span>
              {_.size(value)} / {this.props.maxLength}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default Textarea;
