import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../styles/forms/add_product_wizard/ProductBasicAttributesForm.scss";
import Button from "../../components/Button";
import { __, setTableParams, mapStateToProps } from "../../core/utils";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Input from "../../components/Input";
import MultiSelect from "../../components/MultiSelect";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import Select from "../../components/Select";
import { listMyCompanyBrands } from "../../redux/actions/companies";
import * as _ from "lodash";
import { listPossibleProductBrands } from "../../redux/actions/table/products";

@reduxForm({
	form: "add_product_wizard.basic_attributes",
	destroyOnUnmount: false
})
@subscribe(Localization, "localization")
@mapStateToProps((state) => ({
	values: getFormValues("add_product_wizard.basic_attributes")(state)
}))
class ProductBasicAttributesForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_brands: []
		};
	}

	componentDidMount() {
		listPossibleProductBrands({ type: _.get(this.props, "initialValues.type") }).then(({ response }) => {
			this.setState({
				possible_brands: _.map(response.data, (brand) => {
					return {
						value: brand.id,
						label: _.filter([brand.name], _.identity).join(" · "),
						group: _.get({ 0: "My Brands", 1: "Address Book Companies" }, brand.type)
					};
				})
			});
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (_.get(prevProps, "values.type") != _.get(this.props, "values.type")) {
			listPossibleProductBrands({ type: _.get(this.props, "values.type") }).then(({ response }) => {
				this.setState({
					possible_brands: _.map(response.data, (brand) => {
						return {
							value: brand.id,
							label: _.filter([brand.name], _.identity).join(" · "),
							group: _.get({ 0: "My Brands", 1: "Address Book Companies" }, brand.type)
						};
					})
				});
			});
		}
	}

	render() {
		const languages = _.map(this.props.localization.languages, (language) => {
			let data = {
				value: language.id,
				label: language.name
			};

			if (language.id == 1) {
				data["disabled"] = true;
			}

			return data;
		});

		const type = [
			{
				label: __("products.create-new-product.type.product"),
				value: "product"
			},
			{
				label: __("products.create-new-product.type.part"),
				value: "part"
			}
		];

		const { possible_brands } = this.state;

		return (
			<Form onSubmit={this.props.handleSubmit} className={styles.form}>
				<div className={styles.desc1}>
					<span>{__("products.create-new-product.product-article-substance-manufactured-refined-sale")}</span>
					<span>{__("products.create-new-product.part-integral-product-combined-others-whole-product-not-public-catalog-part-product")}</span>
				</div>
				<span className={styles.desc}>{__("products.basic-attributes.enter-basic-product-details.define-product-language.edit-attributes-later.fine-tune-before-publishing")}.</span>
				<div className={styles.flex}>
					<div className={styles.f1}>
						<Field name="type" component={Select} label={__("products.create-new-product.type")} options={type} />
					</div>
					<div className={styles.f1}>
						<Field name="model" component={Input} label={__("products.column.model")} />
					</div>
					<div className={styles.f1}>
						<Field name="brand_id" searchable component={Select} options={possible_brands} label={__("products.create-new-product.brand")} />
					</div>
					<div className={styles.f1}>
						<Field name="languages" component={MultiSelect} searchable label={__("products.column.languages")} options={languages} />
					</div>
				</div>
				<div className={styles.footer}>
					<Button
						lightGray
						medium
						left
						middleText={__("button.cancel")}
						onClick={() =>
							setTableParams("products", {
								wizard_active: undefined
							})
						}
					/>
					<Button submit medium lightBlue leftText={__("button.next")} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default ProductBasicAttributesForm;
