import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_task_to_meeting_wizard/AddTasksToMeetingForm.scss";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {__} from "../../core/utils";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import {listPossibleExistingMeetingTasks} from "../../redux/actions/table/all_meetings";
import MeetingTasksChooser from "../../components/MeetingTasksChooser";
import WarningIcon from "../../assets/images/warning-30x30.svg";

const mapStateToProps = state => {
    return {
        values: getFormValues("add_task_to_meeting_wizard.add_task_to_meeting")(state),
        visibility: _.get(state.table, 'all_meetings.visibility')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideAddTasksToMeetingWizard: () =>
                dispatch({
                    type: "table.all_meetings/SET_TABLE_PARAMS",
                    params: {
                        wizard_add_tasks_to_meeting: undefined
                    }
                })
        }
    };
};

@withRouter
@subscribe(Localization, "localization")
@reduxForm({form: "add_task_to_meeting_wizard.add_task_to_meeting"})
@connect(mapStateToProps, mapDispatchToProps)
class AddTasksToMeetingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_meeting_tasks: [],
        }
    }

    componentDidMount() {
        listPossibleExistingMeetingTasks(this.props.match.params.meeting).then(({response}) => {
            this.setState({possible_meeting_tasks: response.data});
        });
    }

    render() {
        const {possible_meeting_tasks} = this.state;
        const {visibility} = this.props;
        const tasks = _.get(this.props.values, 'tasks', []);

        let warnings = [];

        const selected_tasks = _.filter(possible_meeting_tasks, task => _.includes(tasks, task.id));

        if(visibility == 1) {
            if(!_.isEmpty(_.filter(selected_tasks, ['visibility', 0]))) {
                warnings.push('Adding private task to a public meeting will result in converting a task visibility to public.');
            }
        } else {
            if(!_.isEmpty(_.filter(selected_tasks, ['visibility', 1]))) {
                warnings.push('Adding public task to a private meeting will result in updates done at task being publicly visible.');
            }

            if(!_.isEmpty(_.filter(selected_tasks, ['visibility', 0]))) {
                warnings.push('Adding private task to a private meeting will result in adding all meeting members as task followers.');
            }
        }

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    {_.map(warnings, (warning, i) => <div key={i} className={styles.warning}>
                        <WarningIcon />
                        <span>{warning}</span>
                    </div>)}
                    <Field name="tasks" component={MeetingTasksChooser} label={__('table.columns.meeting-tasks')} tasks={possible_meeting_tasks} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideAddTasksToMeetingWizard} />
                    <Button lightBlue medium right submit middleText={__('button.add')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default AddTasksToMeetingForm;
