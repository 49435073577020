import React, { useState, useEffect } from 'react';
import { Form, reduxForm, Field, getFormValues } from 'redux-form';
import styles from '../../styles/forms/import_spreadsheet_wizard/ImportSpreadsheetForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __, getActiveProject, setFormValue } from '../../core/utils';
import * as _ from 'lodash';
import { setNotification } from '../../redux/actions/general/notification';
import ButtonGroup from '../../components/ButtonGroup';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import RadioGroup from '../../components/RadioGroup';
import Select from '../../components/Select';
import { readProjectGeneral } from '../../redux/actions/projects';

const ImportSpreadsheetDetailsForm = (props) => {
  const project_languages = _.map(
    props.table.project_languages,
    (language_id, key) => {
      return {
        label: _.get(props.localization.languages, [key, 'name']),
        value: key,
      };
    }
  );

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.f1}>
          <div className={styles.title}>
            {__('import-spreadsheet.wizard.title')}
          </div>
        </div>

        {props.basic_attributes.module === 'bill_of_quantities' && (
          <div className={styles.f1}>
            <Field
              name='language_id'
              component={Select}
              noSort
              label={__('import-spreadsheet.wizard.language')}
              searchable
              options={project_languages}
            />
          </div>
        )}

        <div className={styles.f1}>
          <Field
            name='import_type'
            component={RadioGroup}
            options={[
              {
                label: __(
                  __('import-spreadsheet.wizard.import-type.singleline')
                ),
                value: 'singleline',
              },
              {
                label: __(
                  __('import-spreadsheet.wizard.import-type.multiline')
                ),
                value: 'multiline',
              },
            ]}
          />
        </div>

        <div className={styles.importDetailsInfo}>
          * {__('import-spreadsheet.wizard.info')}
        </div>

        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.back')}
            onClick={props.onBack}
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.import')}
              spinning={props.submitting}
              onClick={props.handleSubmit}
            />
          </ButtonGroup>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['import_spreadsheet'],
    state: state,
    basic_attributes: getFormValues(
      'import_spreadsheet_wizard.import_spreadsheet_basic_attributes'
    )(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideImportSpreadsheet: () =>
        dispatch({
          type: 'table.import_spreadsheet/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
          },
        }),
    },
  };
};

export default reduxForm({
  form: 'import_spreadsheet_wizard.import_spreadsheet_details',
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(subscribe(Localization, 'localization')(ImportSpreadsheetDetailsForm))
);
