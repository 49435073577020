import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../styles/forms/projects/ProjectGeneralForm.scss";
import { connect } from "react-redux";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import MultiSelect from "../../components/MultiSelect";
import { __, transformToListPairs } from "../../core/utils";
import { listPossibleProjectAdministrators, listPossibleProjectClients, listPossibleProjectDesigners } from "../../redux/actions/projects";
import { enableAutosave } from "../../redux/actions/general/autosave";
import { withRouter } from "react-router";
import Select from "../../components/Select";
import { subscribe } from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import * as _ from "lodash";
import PeopleChooser from "../../components/PeopleChooser";
import autobind from "autobind-decorator";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";

const mapStateToProps = (state) => {
	return {
		values: getFormValues("projects.general")(state)
	};
};

@reduxForm({
	form: "projects.general",
	enableReinitialize: true
})
@subscribe(Localization, "localization")
@withRouter
@connect(mapStateToProps)
class ProjectGeneralForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_administrators: [],
			possible_designers: [],
			possible_clients: []
		};
	}

	UNSAFE_componentWillReceiveProps() {
		enableAutosave(this.props.form);
	}

	componentDidMount() {
		listPossibleProjectAdministrators(this.props.project.id).then(({ response }) => {
			this.setState({ possible_administrators: response.data });
		});

		listPossibleProjectDesigners(this.props.project.id, this.props.project.designer_id).then(({ response }) => {
			this.setState({ possible_designers: response.data });
		});

		listPossibleProjectClients(this.props.project.id, _.pick(this.props.project, ["client_company_id", "client_user_id", "client_address_book_company_id", "client_address_book_contact_id"])).then(({ response }) => {
			this.setState({
				possible_clients: _.map(response.data, (client) => {
					return {
						value: client.id,
						label: _.filter([client.name, client.company], _.identity).join(" · "),
						group: _.get({ 0: "Companies", 1: "Users", 2: "Address Book Companies", 3: "Address Book Contacts" }, client.type)
					};
				})
			});
		});
	}

	@autobind
	_handleChangeCurrency(e, currency_id) {
		e.preventDefault();

		showAlertbox({
			color: "red",
			title: __("general.alert.are-you-sure"),
			description: "Do you really want to change the project language?\n\nThis will affect all the displayed currencies (and calculations) in the project current sets!",
			buttons: [
				{
					color: "lightGray",
					text: __("general.alert.no"),
					onClick: "close"
				},
				{
					color: "gray",
					text: __("general.alert.yes"),
					onClick: () => {
						hideAlertbox();

						this.props.touch("currency_id");
						this.props.change("currency_id", currency_id);
						this.props.blur("currency_id");
					}
				}
			]
		});
	}

	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.wrapper}>
					<div className={styles.title}>{__("General")}</div>
					<div className={styles.form}>
						<div className={styles.flex}>
							<div className={styles.f2}>
								<Field name="code" disabled={!this.props.isProjectAdministrator} component={Input} label={__("project-settings.general-form.code")} maxLength={7} />
							</div>
							<div className={styles.f6}>
								<Field name="name" disabled={!this.props.isProjectAdministrator} component={Input} label={__("project-settings.general-form.title")} maxLength={50} />
							</div>
						</div>
						<Field name="description" key={_.findKey(this.props.projectLanguages, (language) => language == 1)} disabled={!this.props.isProjectAdministrator} component={Textarea} label={__("table.columns.project-scope")} localized={this.props.projectLanguages} maxLength={500} rows={1} maxRows={10} />
						<div className={styles.flex}>
							<div className={styles.f1}>
								<Field name="administrators" disabled={!this.props.isProjectAdministrator} component={PeopleChooser} searchable label={__("project-settings.general-form.administrators")} options={this.state.possible_administrators} />
							</div>
							<div className={styles.f1}>
								<Field
									name="currency_id"
									onChange={this._handleChangeCurrency}
									disabled={!this.props.isProjectAdministrator}
									component={Select}
									searchable
									label={__("project-settings.general-form.currency")}
									options={_.map(this.props.localization.currencies, (currency) => ({
										label: [currency.code, currency.name].join(" - "),
										value: currency.id
									}))}
								/>
							</div>
						</div>
						<div className={styles.flex}>
							<div className={styles.f2}>
								<Field name="main_language" disabled={!this.props.isProjectAdministrator} component={Select} searchable label={__("project-settings.general-form.main-language")} noSort options={transformToListPairs(this.props.localization.languages, "name")} />
							</div>
							<div className={styles.f6}>
								<Field name="additional_languages" disabled={!this.props.isProjectAdministrator} component={MultiSelect} searchable label={__("project-settings.general-form.additional-languages")} noSort options={_.reject(transformToListPairs(this.props.localization.languages, "name"), ["value", this.props.values.main_language])} />
							</div>
						</div>
						<div className={styles.flex}>
							<div className={styles.f1}>
								<Field name="designer_id" disabled={!this.props.isProjectAdministrator} component={Select} searchable label={__("project-settings.general-form.designer")} noSort options={transformToListPairs(this.state.possible_designers)} noneValue />
							</div>
							<div className={styles.f1}>
								<Field name="client_id" disabled={!this.props.isProjectAdministrator} component={Select} searchable label={__("project-settings.general-form.client")} noSort options={this.state.possible_clients} noneValue />
							</div>
						</div>
						<div className={styles.flex}>
							<div className={styles.f2}>
								<Field name="country_id" disabled={!this.props.isProjectAdministrator} component={Select} searchable label={__("project-settings.general-form.country")} noSort options={transformToListPairs(this.props.localization.countries)} noneValue />
							</div>
							<div className={styles.f6}>
								<Field name="building_type_ids" disabled={!this.props.isProjectAdministrator} component={MultiSelect} searchable label={__("project-settings.general-form.building-types")} noSort options={transformToListPairs(this.props.localization.building_types)} />
							</div>
						</div>
						<div className={styles.flex}>
							<div className={styles.f2}>
								<Field name="area" disabled={!this.props.isProjectAdministrator} component={Input} numeric maxLength={7} label={__("project-settings.general-form.area")} />
							</div>
							<div className={styles.f6}>
								<Field name="address" disabled={!this.props.isProjectAdministrator} component={Input} label={__("project-settings.general-form.address")} />
							</div>
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default ProjectGeneralForm;
