import classNames from 'classnames';
import styles from '../../../styles/views/boq/WorkingSetBOQ.scss';
import GrabberIcon from '../../../assets/images/grabber-16x16.svg';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';
import * as _ from 'lodash';
import { ContextMenuTrigger } from 'react-contextmenu';

const DraggableElement = sortableElement(({ children }) => children);
const DraggableHandle = sortableHandle(({ children }) => children);

const Row = ({
  index,
  id,
  level,
  children,
  style,
  firstChild,
  className,
  unallocatedGroupCollapsed,
  parentId,
  name,
  code,
  setFocusedContextMenuRow,
  specification_code_id,
  specification,
  ...rest
}) => {
  if (children.length == 1) {
    const DraggableElementComponent = (
      <DraggableElement
        index={index}
        collection={_.includes(id, 'UN') ? 'UN' : parentId}
        disabled={_.includes(id, 'UN')}
      >
        <div
          {...rest}
          className={classNames(className, styles.leftFloatingColumn)}
          style={{
            ...style,
            width: style.width - 8,
          }}
        >
          <DraggableHandle>
            <div
              className={classNames(
                styles.grabber,
                _.includes(id, 'UN') && styles.hidden
              )}
            >
              <GrabberIcon />
            </div>
          </DraggableHandle>
          {children}
        </div>
      </DraggableElement>
    );

    // if (id == 'UN') {
    //   return DraggableElementComponent;
    // }

    return (
      <ContextMenuTrigger
        id='working_set_boq_context_menu'
        collect={(props) => {
          return setFocusedContextMenuRow({
            name,
            level,
            id,
            code,
            specification_code_id,
            firstChild,
            specification,
          });
        }}
      >
        {DraggableElementComponent}
      </ContextMenuTrigger>
    );
  }

  return (
    <ContextMenuTrigger
      id='working_set_boq_context_menu'
      collect={(props) => {
        return setFocusedContextMenuRow({
          name,
          level,
          id,
          code,
          specification_code_id,
          firstChild,
          specification,
        });
      }}
    >
      <div {...rest} style={style} className={className}>
        {children}
      </div>
    </ContextMenuTrigger>
  );
};

export default Row;
