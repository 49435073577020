import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/products/read_only/Files.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import { __, mapStateToProps } from '../../../core/utils';
import { setNotification } from '../../../redux/actions/general/notification';
import Images from './Images';
import FilePreview from '../../../components/FilePreview';

@mapStateToProps((state) => ({
  flyout: state.table.products.flyout,
}))
class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  @autobind
  _downloadFile({ download_url }) {
    setNotification(
      __('flyouts.products.files.products.files.download.continue')
    );

    window.open(download_url, '_blank');
  }

  componentDidMount() {
    const { files, images } = this.props.flyout;

    this.setState({
      expanded: _.map([...files, ...images], (file) => file.category),
    });
  }

  render() {
    const { collapsed, flyout } = this.props;
    const { expanded } = this.state;

    const categories = [
      {
        label: __('products.flyout.files.thumbnail'),
        value: 'thumbnail',
      },
      {
        label: __('products.flyout.files.product'),
        value: 'product',
      },
      {
        label: __('products.flyout.files.drawing'),
        value: 'drawing',
      },
      {
        label: __('products.flyout.files.ambient'),
        value: 'ambient',
      },
      {
        label: __('products.flyout.files.technical'),
        value: 'technical',
      },
      {
        label: __('products.flyout.files.tileable-texture-for-3d'),
        value: 'tileable',
      },
      {
        label: __('products.flyout.files.manuals'),
        value: 'manuals',
      },
      {
        label: __('products.flyout.files.guarantee'),
        value: 'guarantee',
      },
      {
        label: __('products.flyout.files.certificates'),
        value: 'certificates',
      },
      {
        label: __('products.flyout.files.catalogs'),
        value: 'catalogs',
      },
      {
        label: __('products.flyout.files.installation'),
        value: 'installation',
      },
      {
        label: __('products.flyout.files.bim'),
        value: 'bim',
      },
      {
        label: __('products.flyout.files.3d'),
        value: '3d',
      },
      {
        label: __('products.flyout.files.cad'),
        value: 'cad',
      },
      {
        label: __('products.flyout.files.2d'),
        value: '2d',
      },
      {
        label: __('products.flyout.files.photometrical-distribution'),
        value: 'light_data',
      },
      {
        label: __('products.flyout.files.video'),
        value: 'video',
      },
      {
        label: __('products.flyout.files.audio'),
        value: 'audio',
      },
      {
        label: __('products.flyout.files.other'),
        value: 'other',
      },
    ];

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.map(categories, (category) => {
          const category_files = _.sortBy(
            _.filter(
              [...flyout.files, ...flyout.images],
              ['category', category.value]
            ),
            ['order']
          );

          const lightbox_images = _.map(
            _.filter(category_files, (file) =>
              _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
            ),
            (file) => ({
              id: file.id,
              stream_url: file.stream_url,
              title: [file.name, file.ext].join('.'),
            })
          );

          return _.isEmpty(category_files) ? (
            ''
          ) : (
            <div className={styles.category} key={category.value}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseCategory(category.value)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    !_.includes(expanded, category.value) && styles.collapsed
                  )}
                />
                {category.label}{' '}
                {!_.isEmpty(category_files) &&
                  '(' + _.size(category_files) + ')'}
              </div>
              {_.includes(expanded, category.value) && (
                <>
                  {_.includes(expanded, category.value) &&
                    (_.includes(
                      [
                        'thumbnail',
                        'product',
                        'drawing',
                        'ambient',
                        'technical',
                        'tileable',
                      ],
                      category.value
                    ) ? (
                      // Change this component
                      <Images data={category_files} category={category.value} />
                    ) : (
                      !_.isEmpty(category_files) && (
                        <div className={styles.files}>
                          {_.map(category_files, (file, i) => (
                            <div className={styles.file} key={i}>
                              <FilePreview
                                onDownloadFile={this._downloadFile}
                                row={file}
                                lightbox_images={lightbox_images}
                              />
                            </div>
                          ))}
                        </div>
                      )
                    ))}
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Files;
