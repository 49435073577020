import store from "../../core/store";

export const listLocalization = () => {
    return store.dispatch({
        action: "localization/LIST_LOCALIZATION",
        method: "GET",
        endpoint: "/localization",
        preloaded: false,
    });
};

export const listTextures = () => {
    return store.dispatch({
        action: "localization/LIST_TEXTURES",
        method: "GET",
        endpoint: "/textures"
    });
};