import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddBOQTemplateGroupWizard.scss";
import autobind from "autobind-decorator";
import AddBOQTemplateGroupIcon from "../../assets/images/add-boq-template-group-60x60.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createBOQTemplateGroup, listBOQTemplateGroups } from "../../redux/actions/table/superadmin/boq_template_groups";
import AddBOQTemplateGroupForm from "../../forms/superadmin/add_boq_template_groups_wizard/AddBOQTemplateGroupForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_boq_template_groups"],
}))
class AddBOQTemplateGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createBOQTemplateGroup(formData).then(({ response }) => {
				listBOQTemplateGroups().then(() => {
					if (edit) {
						setTableParams('superadmin_boq_template_groups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_boq_template_groups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new BOQ template group.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add BOQ template group</span>
						<span>Add BOQ template group to system BOQ template groups</span>
					</div>
					<div className={styles.right}>
						<AddBOQTemplateGroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddBOQTemplateGroupForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddBOQTemplateGroupWizard;