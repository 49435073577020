import store from "../../../../core/store";

export const listSpecificationSubgroups = () => {
	return store.dispatch({
		action: "table.superadmin_specification_subgroups/LIST_SPECIFICATION_SUBGROUPS",
		method: "GET",
		endpoint: "/superadmin/specification_subgroups",
		main: true
	});
};

export const createSpecificationSubgroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_specification_subgroups/CREATE_SPECIFICATION_SUBGROUP",
		method: "POST",
		endpoint: "/superadmin/specification_subgroups",
		data: formData
	});
};

export const deleteSpecificationSubgroups = (specification_subgroup_ids) => {
	return store.dispatch({
		action: "table.superadmin_specification_subgroups/DELETE_SPECIFICATION_SUBGROUPS",
		method: "DELETE",
		endpoint: "/superadmin/specification_subgroups",
		data: {
			specification_subgroups: specification_subgroup_ids
		}
	});
};

export const readSpecificationSubgroup = (specification_subgroup_id) => {
	return store.dispatch({
		action: "table.superadmin_specification_subgroups/READ_SPECIFICATION_SUBGROUP",
		method: "GET",
		endpoint: "/superadmin/specification_subgroups/" + specification_subgroup_id,
		has404: true
	});
};

export const updateSpecificationSubgroup = (specification_subgroup_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_specification_subgroups/UPDATE_SPECIFICATION_SUBGROUP",
		method: "PUT",
		endpoint: "/superadmin/specification_subgroups/" + specification_subgroup_id,
		data: formData
	});
};