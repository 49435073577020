import React, {Component} from "react";
import styles from "../styles/components/RadioGroup.scss";
import classNames from "classnames";
import * as _ from "lodash";
import autobind from "autobind-decorator";

class RadioGroup extends Component {
	@autobind
    _chooseOption(value) {
        this.props.input.onFocus();
        this.props.input.onChange(value);
        this.props.input.onBlur();
    }

    render() {
        return (
            <div className={classNames(styles.wrapper, this.props.className)}>
                <div className={styles.options}>
					{_.map(this.props.options, ({label, value}) => (
						<div
							className={classNames(styles.option, this.props.input.value == value && styles.selected)}
							key={value}
							onClick={() => this._chooseOption(value)}
						>
							<span />
							{label}
						</div>
					))}
				</div>
            </div>
        );
    }
}

export default RadioGroup;