import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import Textarea from "../../components/Textarea";
import {__, transformToListPairs} from "../../core/utils";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/buildings/EditBuildingForm.scss";
import Input from "../../components/Input";
import {withRouter} from "react-router";
import Select from "../../components/Select";
import {listPossibleSites} from "../../redux/actions/table/buildings";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";
import classNames from "classnames";

@reduxForm({
    form: "buildings.edit_building",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
class EditBuildingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_sites: [],
        }
    }

    componentDidMount() {
        listPossibleSites(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_sites: response.data});
        });
    }

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {data, handleSubmit, localization} = this.props;
        const {possible_sites} = this.state;
        const {project_languages, calculated_indoor_area, calculated_outdoor_area, calculated_volume} = data;
        const {building_types} = localization;

        return (
            <Form onSubmit={handleSubmit}>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="code" component={Input} label={__('buildings.field.code')} maxLength={6} />
                    </div>
                    <div className={styles.f3}>
                        <Field name="title" component={Input} localized={project_languages} label={__('buildings.field.title')} maxLength={50} />
                    </div>
                    <div className={styles.f3}>
                        <Field name="site_id" component={Select} label={__('buildings.field.site')} searchable options={transformToListPairs(possible_sites, 'title')} />
                    </div>
                </div>
                <Field name="description" component={Textarea} localized={project_languages} label={__('sites.field.description')} maxLength={2000} rows={1} maxRows={10} />
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="indoor_area" component={Input} label={__('buildings.field.indoor-area')} numeric decimal maxLength={10} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="outdoor_area" component={Input} label={__('buildings.field.outdoor-area')} numeric decimal maxLength={10} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="volume" component={Input} label={__('buildings.field.volume')} numeric decimal maxLength={10} />
                    </div>
                </div>
                <div className={classNames(styles.flex, styles.calculated)}>
                    <div className={styles.f1}>
                        <span>{__('buildings.flyout.calculated-indoor-area')}</span>
                        <span>{calculated_indoor_area}</span>
                    </div>
                    <div className={styles.f1}>
                        <span>{__('buildings.flyout.calculated-outdoor-area')}</span>
                        <span>{calculated_outdoor_area}</span>
                    </div>
                    <div className={styles.f1}>
                        <span>{__('buildings.flyout.calculated-volume')}</span>
                        <span>{calculated_volume}</span>
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="footprint" component={Input} label={__('buildings.field.footprint')} numeric decimal maxLength={10} />
                    </div>
                    <div className={styles.f2}>
                        <Field name="building_types" component={MultiSelect} label={__('buildings.field.building-type')} searchable options={transformToListPairs(building_types)} />
                    </div>
                </div>
            </Form>
        );
    }
}

export default EditBuildingForm;
