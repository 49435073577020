import * as _ from 'lodash';
import {
  updateBOQCalculationName,
  updateBOQCalculationPrice,
  updateBOQCalculationQuantity,
  updateBOQCalculationInstallationType,
  updateBOQCalculationUnit,
  updateBOQGroupName,
  updateBOQItemName,
  updateBOQSubgroupName,
  updateBOQCalculationSupplyTypeGroup,
  updateBOQCalculationBudgetType,
  deleteBOQCalculation,
  deleteBOQItem,
  deleteBOQSubgroup,
  deleteBOQGroup,
  unlinkSpecificationCodeFromBOQCalculation,
  getBOQItem,
  getBOQCalculation,
  updateBOQPositionName,
  deleteBOQPosition,
  updateBOQPositionArea,
  updateBOQPositionQuantity,
  reorderBOQGroups,
  reorderBOQSubgroups,
  reorderBOQItems,
  reorderBOQCalculations,
  reorderBOQPositions,
  reorderBOQWorkingSetData,
  addBOQTemplateGroup,
  addBOQTemplateSubgroup,
} from '../../../redux/actions/table/working_set_boq';
import { hideDropdown } from '../../../redux/actions/general/dropdown';
import CloseMiddleIcon from '../../../assets/images/close-middle-15x15.svg';
import styles from '../../../styles/views/boq/WorkingSetBOQ.scss';
import {
  unformatMoney,
  formatMoney,
  getLocalized,
  __,
  setTableParams,
  setURLParam,
  getActiveStage,
  redirect,
  hasURLParam,
  getURLParam,
  backendURL,
} from '../../../core/utils';
import ArrowDoubleRightSmallIcon from '../../../assets/images/arrow-double-right-small-15x15.svg';
import MoveIcon from '../../../assets/images/move-16x16.svg';
import CopyIcon from '../../../assets/images/copy-16x16.svg';
import UnlinkIcon from '../../../assets/images/unlink-16x16.svg';
import LinkIcon from '../../../assets/images/link-16x16.svg';
import Dropdown from '../../../components/Dropdown';
import classNames from 'classnames';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import BaseTable from 'react-base-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ExpandIcon from './ExpandIcon';
import NumericInput from './NumericInput';
import RoomChooser from '../../../components/RoomChooser';
import Tooltip from '../../../components/Tooltip';
import {
  hideAlertbox,
  showAlertbox,
} from '../../../redux/actions/general/alertbox';
import { setNotification } from '../../../redux/actions/general/notification';
import { readWorkingSetSpecification } from '../../../redux/actions/table/working_set_specifications';
import UncheckedIcon from '../../../assets/images/unchecked-15x15.svg';
import NeutralIcon from '../../../assets/images/neutral-15x15.svg';
import CheckedIcon from '../../../assets/images/checked-15x15.svg';
import ConvertSpecificationIcon from '../../../assets/images/convert-to-specification-16x16.svg';
import ButtonGroup from '../../../components/ButtonGroup';
import Button from '../../../components/Button';
import InfoPositiveIcon from '../../../assets/images/info-positive-16x16.svg';
import SearchIcon from '../../../assets/images/search-15x15.svg';
import CloseSmallIcon from '../../../assets/images/close-small-15x15.svg';
import ColumnSelectorIcon from '../../../assets/images/column-selector-16x16.svg';
import { updatePreferences } from '../../../redux/actions/profile';
import { withRouter } from 'react-router';
import FilterIcon from '../../../assets/images/filter-15x15.svg';
import Filter from './Filter';
import queryString from 'query-string';
import Select from '../../../components/Select';
import GlobeIcon from '../../../assets/images/globe-16x16.svg';
import LoadFromTemplateIcon from '../../../assets/images/boq-load-from-template-16x16.svg';
import Row from './Row';
import { sortableContainer } from 'react-sortable-hoc';
import {
  ContextMenu,
  MenuItem,
  ContextMenuTrigger,
  showMenu,
  SubMenu,
} from 'react-contextmenu';
import MenuVIcon from '../../../assets/images/menu-v-15x15.svg';
import BOQCompactView from '../../../assets/images/boq-compact-view-16x16.svg';
import BOQDetailedView from '../../../assets/images/boq-detailed-view-16x16.svg';
import ExportIcon from '../../../assets/images/export-15x15.svg';
import { downloadFile } from '../../../redux/actions/general/downloader';
import SaveAsTemplateIcon from '../../../assets/images/boq-save-as-template-16x16.svg';
import ArrowRightSmallIcon from '../../../assets/images/arrow-right-small-40x40.svg';
import SearchableDropdown from '../../../components/SearchableDropdown';

const DraggableContainer = sortableContainer(({ children }) => children);

const TableCompactView = ({
  focusedId,
  setFocusedId,
  data,
  refreshData,
  filterData,
  setRef,
  setNameRef,
  projectCurrency,
  localization,
  tableRef,
  spaceAllocationData,
  setExpandedRows,
  expandedRows,
  groupActions,
  name,
  languageId,
  project_languages,
  languageSwitcher,
  setLanguageSwitcher,
  addRowButtons,
  dropdown,
  title,
  sidebar,
  nameRefs,
  handleCreateTemplateByLevel,
  ...props
}) => {
  const getContainer = () => {
    return tableRef
      .getDOMNode()
      .querySelector('.BaseTable__table-frozen-left .BaseTable__body');
  };

  const getHelperContainer = () => {
    return tableRef.getDOMNode().querySelector('.BaseTable__table-frozen-left');
  };

  const handleSortEnd = ({ oldIndex, newIndex, collection }) => {
    let all_rows = [...tableRef._data];

    const [removed] = all_rows.splice(oldIndex, 1);
    all_rows.splice(newIndex, 0, removed);

    all_rows = _.map(
      _.filter(
        all_rows,
        (row) =>
          row.parentId == (collection == 0 ? undefined : collection) &&
          !_.includes(row.id, 'UN')
      ),
      (row) => _.last(row.id.split('-'))
    );

    const level = collection == 0 ? 0 : _.size(collection.split('-'));

    const reorder = {
      0: reorderBOQGroups,
      1: reorderBOQSubgroups,
      2: reorderBOQItems,
      3: reorderBOQCalculations,
      4: reorderBOQPositions,
    };

    reorder[level](
      collection == 0 ? getActiveStage() : _.last(collection.split('-')),
      all_rows
    );

    reorderBOQWorkingSetData(level, collection, all_rows);
  };

  const {
    specification_groups,
    specification_subgroups,
    units,
    unit_groups,
    boq_supply_type_groups,
  } = localization;

  const [isDragging, setIsDragging] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isUnallocatedCollapsed, setUnallocatedCollapsed] = useState(true);
  const [areaExpandedAll, setAreaExpandAll] = useState(false);
  const [focusedContextMenuRow, setFocusedContextMenuRow] = useState({});

  // Column selector
  const [tempDisabledColumns, setTempDisabledColumns] = useState(false);

  const installation_types = ['OFCI', 'OFOI', 'CFCI', 'CFOI'];
  const budget_types = ['FF&E', 'OS&E', 'CAPEX'];

  const search = useRef();

  const getDeepIds = useCallback((rows) => {
    const ids = [];

    const process = (rows) =>
      _.each(rows, (row) => {
        row.id != 'UN' && _.isEmpty(row.children) && ids.push(row.id);

        !_.isEmpty(row.children) && process(row.children);
      });

    process(rows);

    return ids;
  }, []);

  const getSelectedRows = useCallback((rows) => {
    let result = _.cloneDeep(rows);

    function filterCheckedNotFalse(item) {
      if (item.children && Array.isArray(item.children)) {
        item.children = _.map(
          _.filter(
            item.children,
            (child) => !('checked' in child) || child.checked !== false
          ),
          (row) => filterCheckedNotFalse(row)
        );
      }

      let data = { name: item?.name };

      if (item?.children) {
        data = { ...data, children: item?.children };
      }

      if (item?.quantity) {
        data = { ...data, quantity: item?.quantity };
      }

      if (item?.unit_id) {
        data = {
          ...data,
          unit_id: item?.unit_id,
          specification_code_id: item?.specification_code_id,
        };
      }

      if (item?.area_id) {
        data = {
          ...data,
          area_id: item?.area_id,
          area_type: item?.area_type,
          area_count: item?.area_count,
        };
      }

      if (item?.level >= 2) {
        data = { ...data, description: item?.description };
      }

      return data;
    }

    return _.map(
      _.filter(result, (item) => item.checked != false),
      (item) => filterCheckedNotFalse(item)
    );
  }, []);

  const final_data = useMemo(() => {
    const rows = isUnallocatedCollapsed
      ? _.reject(data, (row) => _.includes(row.id, 'UN-'))
      : data;

    const process = (rows) =>
      _.map(rows, (row) => {
        let is_checked = false;

        if (_.isEmpty(row.children)) {
          if (_.includes(checked, row.id)) {
            is_checked = true;
          }
        } else {
          const child_ids = getDeepIds(row.children);
          const checked_child_ids = _.intersection(checked, child_ids);

          if (checked_child_ids.length != 0) {
            if (child_ids.length == checked_child_ids.length) {
              is_checked = true;
            } else {
              is_checked = 'neutral';
            }
          }
        }

        return {
          ...row,
          checked: is_checked,
          children: _.isEmpty(row.children) ? undefined : process(row.children),
        };
      });

    return process(rows);
  }, [isUnallocatedCollapsed, data, checked]);

  const data_ids = useMemo(() => getDeepIds(final_data), [final_data]);

  const selected_rows = useMemo(
    () => getSelectedRows(final_data),
    [final_data]
  );

  const _handleNameChange = useCallback(
    (id, value) => {
      if (_.includes(id, 'UN')) {
        const calculation_id = _.get(_.split(id, '-', 2), '1');

        updateBOQCalculationName(calculation_id, value, languageId).then(() =>
          refreshData()
        );
      } else {
        const [group_id, subgroup_id, item_id, calculation_id, position_id] =
          _.split(id, '-', 5);

        if (position_id) {
          updateBOQPositionName(position_id, value, languageId);
        } else if (calculation_id) {
          updateBOQCalculationName(calculation_id, value, languageId).then(() =>
            refreshData()
          );
        } else if (item_id) {
          updateBOQItemName(item_id, value, languageId);
        } else if (subgroup_id) {
          updateBOQSubgroupName(subgroup_id, value, languageId);
        } else if (group_id) {
          updateBOQGroupName(group_id, value, languageId);
        }
      }

      _fetchBOQDataByURLParam();

      hideDropdown();
    },
    [languageId]
  );

  const _fetchBOQDataByURLParam = useCallback(() => {
    if (hasURLParam('boq_item_id')) {
      getBOQItem(getURLParam('boq_item_id')).then(({ response }) => {
        setTableParams('working_set_boq', {
          flyout: response.data,
        });
      });
    }

    if (hasURLParam('boq_calculation_id')) {
      getBOQCalculation(getURLParam('boq_calculation_id')).then(
        ({ response }) => {
          setTableParams('working_set_boq', {
            flyout: response.data,
          });
        }
      );
    }
  });

  const _handleUnitChange = useCallback((id, unit_id) => {
    const calculation_id = _.includes(id, 'UN')
      ? _.get(_.split(id, '-', 2), 1)
      : _.get(_.split(id, '-', 5), 3);

    updateBOQCalculationUnit(calculation_id, unit_id).then(() => refreshData());

    hideDropdown();
  }, []);

  const _handleInstallationTypeChange = useCallback(
    (e, id, installation_type) => {
      e.stopPropagation();

      const calculation_id = _.includes(id, 'UN')
        ? _.get(_.split(id, '-', 2), 1)
        : _.get(_.split(id, '-', 5), 3);

      updateBOQCalculationInstallationType(
        calculation_id,
        installation_type
      ).then(() => refreshData());

      hideDropdown();
    },
    []
  );

  const _handleBudgetTypeChange = useCallback((e, id, budget_type) => {
    e.stopPropagation();

    const calculation_id = _.includes(id, 'UN')
      ? _.get(_.split(id, '-', 2), 1)
      : _.get(_.split(id, '-', 5), 3);

    updateBOQCalculationBudgetType(calculation_id, budget_type).then(() =>
      refreshData()
    );

    hideDropdown();
  }, []);

  const _handleQuantityChange = useCallback((id, quantity) => {
    if (_.includes(id, 'UN')) {
      const calculation_id = _.get(_.split(id, '-', 2), '1');

      updateBOQCalculationQuantity(
        calculation_id,
        unformatMoney(quantity)
      ).then(() => refreshData());
    } else {
      const [group_id, subgroup_id, item_id, calculation_id, position_id] =
        _.split(id, '-', 5);

      if (position_id) {
        updateBOQPositionQuantity(position_id, unformatMoney(quantity)).then(
          () => refreshData()
        );
      } else if (calculation_id) {
        updateBOQCalculationQuantity(
          calculation_id,
          unformatMoney(quantity)
        ).then(() => refreshData());
      }
    }
  }, []);

  const _handlePriceChange = useCallback((id, price) => {
    const calculation_id = _.includes(id, 'UN')
      ? _.get(_.split(id, '-', 2), 1)
      : _.get(_.split(id, '-', 5), 3);

    updateBOQCalculationPrice(calculation_id, unformatMoney(price)).then(() =>
      refreshData()
    );
  }, []);

  const _handleSupplyTypeGroupChange = useCallback(
    (e, id, boq_supply_type_group_id) => {
      e.stopPropagation();

      const calculation_id = _.includes(id, 'UN')
        ? _.get(_.split(id, '-', 2), 1)
        : _.get(_.split(id, '-', 5), 3);

      updateBOQCalculationSupplyTypeGroup(
        calculation_id,
        boq_supply_type_group_id
      ).then(() => refreshData());

      hideDropdown();
    },
    []
  );

  const _handleDelete = useCallback((id) => {
    let warningText, deletedText, handleDelete;

    if (_.includes(id, 'UN')) {
      const calculation_id = _.get(_.split(id, '-', 2), '1');

      warningText = 'Are you sure you want to delete this cost?';
      deletedText = 'The cost has been successfully deleted.';

      handleDelete = () => deleteBOQCalculation(calculation_id);
    } else {
      const [group_id, subgroup_id, item_id, calculation_id, position_id] =
        _.split(id, '-', 5);

      if (position_id) {
        warningText = 'Are you sure you want to delete this element?';
        deletedText = 'The element has been successfully deleted.';
        handleDelete = () => deleteBOQPosition(position_id);
      } else if (calculation_id) {
        warningText = 'Are you sure you want to delete this cost?';
        deletedText = 'The cost has been successfully deleted.';
        handleDelete = () => deleteBOQCalculation(calculation_id);
      } else if (item_id) {
        warningText = 'Are you sure you want to delete this item?';
        deletedText = 'The item has been successfully deleted.';
        handleDelete = () => deleteBOQItem(item_id);
      } else if (subgroup_id) {
        warningText = 'Are you sure you want to delete this type?';
        deletedText = 'The type has been successfully deleted.';
        handleDelete = () => deleteBOQSubgroup(subgroup_id);
      } else if (group_id) {
        warningText = 'Are you sure you want to delete this group?';
        deletedText = 'The group has been successfully deleted.';
        handleDelete = () => deleteBOQGroup(group_id);
      }
    }

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: warningText,
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () => {
            handleDelete()
              .then(() => {
                refreshData();

                setNotification(deletedText);
              })
              .catch(({ response }) => {
                refreshData();

                if (response.code === 1) {
                  setNotification({
                    text: 'You can not delete a last cost attached to this specification code.',
                    type: 'warning',
                  });
                }
              });

            hideAlertbox();
          },
        },
      ],
    });
  }, []);

  const _handleUnlinkSpecification = useCallback((id) => {
    const calculation_id = _.get(_.split(id, '-', 5), 3);

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description:
        'Are you sure you want to unlink the specification from the selected cost?',
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-unlink'),
          onClick: () => {
            unlinkSpecificationCodeFromBOQCalculation(calculation_id)
              .then(() => {
                refreshData();

                setNotification(
                  'The specification has been successfully unlinked from the selected cost.'
                );
              })
              .catch(({ response }) => {
                refreshData();

                if (response.code === 1) {
                  setNotification({
                    text: 'You can not unlink specification as there would be no costs with this specification code anymore.',
                    type: 'warning',
                  });
                }
              });

            hideAlertbox();
          },
        },
      ],
    });
  }, []);

  const _handleAreaChange = useCallback((id, values) => {
    const { type: area_type, id: area_id, count: area_count } = values || {};

    const position_id = _.get(_.split(id, '-', 5), '4');

    updateBOQPositionArea(position_id, area_type, area_id, area_count).then(
      () => refreshData()
    );

    hideDropdown();
  }, []);

  const _handleMasterExpand = (expanded_rows, unallocated_collapsed) => {
    setExpandedRows(expanded_rows); //set expanded rows in local state
    setUnallocatedCollapsed(unallocated_collapsed); //collapse 'Unallocated' group
    tableRef.setExpandedRowKeys(expanded_rows); //set expanded rows in dependency state
  };

  const total = _.sumBy(
    data,
    (group) => !_.includes(group.id, 'UN') && group.subtotal
  );

  let space_allocation = useMemo(() => {
    const space_allocation = [];

    _.each(spaceAllocationData.sites, (site) =>
      space_allocation.push({
        id: site.id,
        type: 'site',
        display_name: getLocalized(site.title, languageId),
        parent_type: null,
        parent_id: null,
      })
    );

    _.each(spaceAllocationData.buildings, (building) =>
      space_allocation.push({
        id: building.id,
        type: 'building',
        display_name: getLocalized(building.title, languageId),
        parent_type: building.site_id ? 'site' : null,
        parent_id: building.site_id ? building.site_id : null,
      })
    );

    _.each(spaceAllocationData.storeys, (storey) =>
      space_allocation.push({
        id: storey.id,
        type: 'storey',
        display_name: getLocalized(storey.name, languageId),
        parent_type: storey.building_id ? 'building' : null,
        parent_id: storey.building_id ? storey.building_id : null,
      })
    );

    _.each(spaceAllocationData.rooms, (room) => {
      const find_room_type = _.find(spaceAllocationData.room_types, [
        'id',
        room.room_type_id,
      ]);

      space_allocation.push({
        id: room.id,
        type: 'room',

        display_name: (
          <Tooltip
            text={
              find_room_type
                ? `${getLocalized(find_room_type?.title, languageId)} (${_.size(
                    _.filter(spaceAllocationData, [
                      'room_type_id',
                      find_room_type?.id,
                    ])
                  )})`
                : room.code + ': ' + getLocalized(room.name, languageId)
            }
          >
            {room.code + ': ' + getLocalized(room.name, languageId)}
          </Tooltip>
        ),
        parent_type: room.storey_id ? 'storey' : null,
        parent_id: room.storey_id ? room.storey_id : null,
        room_type_id: room.room_type_id,
      });
    });

    _.each(spaceAllocationData.room_types, (room_type) => {
      space_allocation.push({
        id: room_type.id,
        type: 'room_type',
        display_name: `${getLocalized(room_type.title, languageId)} (${_.size(
          _.filter(spaceAllocationData.rooms, ['room_type_id', room_type?.id])
        )})`,
        room_count: _.size(
          _.filter(spaceAllocationData.rooms, ['room_type_id', room_type?.id])
        ),
      });
    });

    return space_allocation;
  }, [languageId, spaceAllocationData]);

  const move_texts = useMemo(
    () => ({
      0: __('working-set-boq.table.row.move-group'),
      1: __('working-set-boq.table.row.move-type'),
      2: __('working-set-boq.table.row.move-item'),
      3: __('working-set-boq.table.row.move-cost'),
      4: __('working-set-boq.table.row.move-element'),
    }),
    []
  );

  const copy_texts = useMemo(
    () => ({
      2: __('working-set-boq.table.row.copy-item'),
      3: __('working-set-boq.table.row.copy-cost'),
    }),
    []
  );

  const _handleCheckbox = useCallback(
    (id) => {
      const target_ids = _.filter(data_ids, (child_id) =>
        _.startsWith(child_id, id)
      );

      const intersected = _.intersection(target_ids, checked).length;

      if (intersected == 0 || intersected != target_ids.length) {
        setChecked(_.union(checked, target_ids)); //select all
      } else {
        setChecked(_.difference(checked, target_ids)); //select none
      }
    },
    [data_ids, checked]
  );

  const _handleMasterCheckbox = useCallback(() => {
    if (data_ids.length == checked.length && checked.length !== 0) {
      setChecked([]);
    } else {
      setChecked(data_ids);
    }
  }, [data_ids, checked]);

  const columns = [
    {
      key: 'name',
      required: true,
      title: 'Title',
      width: 600,
      minWidth: 500,
      resizable: true,
      sortable: false,
      frozen: 'left',
      cellRenderer: ({ rowData }) =>
        rowData.id === 'UN' ? (
          <span>{__('working-set-boq.table.row.unallocated')}</span>
        ) : (
          <>
            {rowData.checked === true && (
              <CheckedIcon
                className={styles.checked}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}
            {rowData.checked === false && (
              <UncheckedIcon
                className={classNames(
                  styles.unchecked,
                  checked.length > 0 && styles.visible
                )}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}
            {rowData.checked === 'neutral' && (
              <NeutralIcon
                className={styles.neutral}
                onClick={() => _handleCheckbox(rowData.id)}
              />
            )}

            {!_.includes(rowData.id, 'UN') && (
              <>
                {rowData.level < 4 ? (
                  <span className={styles.code}>{rowData.code}</span>
                ) : (
                  <span className={styles.codePlaceholder} />
                )}
              </>
            )}

            <div
              className={classNames(
                styles.addRowActions,
                dropdown && rowData.id == focusedId && styles.fixedOpacity
              )}
            >
              <Dropdown
                top={5}
                left={5}
                name={'working-set-boq-add-row-actions-' + rowData.id}
                wrapperClassName={styles.dropdownWrapper}
                content={
                  <div className={styles.actionsContent}>
                    {_.map(addRowButtons, (button, i) => {
                      return (
                        !button.disabled && (
                          <Tooltip text={button.label} key={i}>
                            <div
                              onClick={button.onClick}
                              className={styles.action}
                            >
                              <span>{button.label}</span>
                            </div>
                          </Tooltip>
                        )
                      );
                    })}
                  </div>
                }
              >
                <span onClick={() => setFocusedId(rowData.id)}>
                  <Tooltip text={__('working-set-boq.table.row.add-boq-item')}>
                    +
                  </Tooltip>
                </span>
              </Dropdown>
            </div>

            <div
              className={classNames(
                styles.nameContainer,
                !_.isEmpty(rowData.description) && styles.hasLongDescription
              )}
            >
              <div className={styles.descriptionRow}>
                <div className={styles.shortDescriptionRow}>
                  {rowData.level === 4 && <span>&#x2212;&nbsp;&nbsp;</span>}

                  {_.includes([0, 1], rowData.level) ? (
                    <SearchableDropdown
                      dropdownWrapperClassName={styles.dropdownWrapper}
                      top={1}
                      leftStyled
                      multiselect={false}
                      name={`${
                        rowData.level === 0
                          ? 'add-boq-category'
                          : 'add-boq-type'
                      }.row-id-${rowData.id}`}
                      options={
                        rowData.level === 0
                          ? props.possible_boq_groups
                          : props.possible_boq_subgroups
                      }
                      onSelect={(row) => {
                        const value = row.label;

                        _handleNameChange(rowData.id, value);

                        nameRefs[rowData.id].value = value;
                      }}
                      onButtonClick={(value) => {
                        _handleNameChange(rowData.id, value);

                        nameRefs[rowData.id].value = value;
                      }}
                      handleAddNewOptionButton={(value) => {
                        _handleNameChange(rowData.id, value);

                        nameRefs[rowData.id].value = value;

                        handleCreateTemplateByLevel(rowData.level, value);
                      }}
                      defaultValue={_.get(rowData.name, languageId)}
                      showIcon
                      initialQuery
                    >
                      <input
                        className={classNames(styles.input, styles.bold)}
                        type='text'
                        onKeyDown={(e) =>
                          e.key === 'Enter' && e.currentTarget.blur()
                        }
                        ref={(ref) => setNameRef(rowData.id, ref)}
                        onClick={() => {
                          setFocusedId(rowData.id);
                        }}
                        key={rowData.id + _.get(rowData.name, languageId)}
                        defaultValue={_.get(rowData.name, languageId)}
                        maxLength={70}
                      />
                    </SearchableDropdown>
                  ) : (
                    <input
                      className={classNames(
                        styles.input,
                        (rowData.level === 2 || rowData.level === 3) &&
                          styles.longDescription,
                        rowData.level != 4 && styles.bold
                      )}
                      type='text'
                      onKeyDown={(e) =>
                        e.key === 'Enter' && e.currentTarget.blur()
                      }
                      ref={(ref) => setNameRef(rowData.id, ref)}
                      onBlur={({ currentTarget }) =>
                        _handleNameChange(rowData.id, currentTarget.value)
                      }
                      key={rowData.id + _.get(rowData.name, languageId)}
                      defaultValue={_.get(rowData.name, languageId)}
                      maxLength={70}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        ),
    },
    {
      key: 'spec',
      title: 'Spec',
      align: 'right',
      width: 100,
      active: true,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 2) {
          const id = _.last(_.split(rowData.id, '-'));

          return (
            <div
              className={classNames(
                styles.flex,
                styles.f1,
                styles.itemProperties
              )}
            >
              <div
                className={styles.icon}
                onClick={() => {
                  getBOQItem(id).then(({ response }) => {
                    setTableParams('working_set_boq', {
                      clicked_row: id,
                      flyout: response.data,
                    });
                  });

                  _setURLParam({ boq_item_id: id });
                }}
              >
                <Tooltip text={__('working-set-boq.table.row.properties')}>
                  <ArrowDoubleRightSmallIcon />
                </Tooltip>
              </div>
            </div>
          );
        }

        if (rowData.level === 3) {
          if (rowData.specification_code_id) {
            const specification_subgroup =
              _.get(specification_subgroups, [
                rowData.specification.subgroup_id,
              ]) || {};
            const specification_group =
              _.get(
                specification_groups,
                _.get(specification_subgroup, 'specification_group_id')
              ) || {};

            const specification_code =
              getLocalized(specification_group.code, languageId, 1) +
              specification_subgroup.code +
              _.padStart(rowData.specification.code, 2, '0');

            return (
              <Tooltip
                text={__('working-set-boq.table.row.specification-properties')}
              >
                <div
                  className={styles.spec}
                  onClick={() => {
                    readWorkingSetSpecification(rowData.specification.id).then(
                      ({ response }) => {
                        setTableParams('working_set_specifications', {
                          flyout: response.data,
                          clicked_row: response.data.id,
                        });

                        _setURLParam({
                          specification_id: rowData.specification.id,
                        });
                      }
                    );
                  }}
                >
                  {specification_code}
                  <ArrowDoubleRightSmallIcon />
                </div>
              </Tooltip>
            );
          } else {
            const id = _.last(_.split(rowData.id, '-'));

            return (
              <div
                className={classNames(styles.flex, styles.f1, styles.noSpec)}
              >
                <div
                  className={styles.addSpecificationToCalculation}
                  onClick={() => {
                    const calculation_id = _.includes(rowData.id, 'UN')
                      ? _.get(_.split(rowData.id, '-', 2), 1)
                      : _.get(_.split(rowData.id, '-', 5), 3);

                    setTableParams('working_set_boq', {
                      add_specification_to_calculation_wizard: calculation_id,
                      calculation_id: calculation_id,
                      unit_id: rowData.unit_id,
                      description: rowData.name,
                      project_note: rowData?.description,
                    });
                  }}
                >
                  <Tooltip
                    text={__(
                      'working-set-boq.table.row.convert-to-specification'
                    )}
                  >
                    <ConvertSpecificationIcon />
                  </Tooltip>
                </div>

                <div
                  className={styles.icon}
                  onClick={() => {
                    getBOQCalculation(id).then(({ response }) => {
                      setTableParams('working_set_boq', {
                        clicked_row: id,
                        flyout: response.data,
                      });
                    });

                    _setURLParams({ boq_calculation_id: id });
                  }}
                >
                  <Tooltip text={__('working-set-boq.table.row.properties')}>
                    <ArrowDoubleRightSmallIcon />
                  </Tooltip>
                </div>
              </div>
            );
          }
        }
      },
    },
    {
      key: 'area_count',
      title: 'Area count',
      align: 'right',
      width: 120,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.area_count}
              onBlur={(value) =>
                _handleAreaChange(rowData.id, {
                  type: rowData.area_type,
                  id: rowData.area_id,
                  count: value,
                })
              }
              readOnly={rowData.level === 3 || rowData.area_id}
              defaultValue={rowData.area_count}
              customWrapperStyles={styles.numericInputCompactView}
            />
          );
        }
      },
    },

    {
      key: 'quantity',
      title: 'Quantity',
      required: true,
      width: 120,
      resizable: true,
      align: 'right',
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 || rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.quantity}
              onBlur={(value) => _handleQuantityChange(rowData.id, value)}
              readOnly={rowData.level === 3 && !_.isEmpty(rowData.children)}
              customWrapperStyles={styles.numericInputCompactView}
              tooltip={
                rowData.level === 3 && !_.isEmpty(rowData.children)
                  ? 'This field is auto-calculated'
                  : ''
              }
              defaultValue={rowData.quantity}
            />
          );
        }
      },
    },

    {
      key: 'sub_quantity',
      title: 'Sub quantity',
      align: 'right',
      width: 120,
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 || rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.sub_quantity}
              readOnly={true}
              defaultValue={rowData.sub_quantity}
              customWrapperStyles={styles.numericInputCompactView}
            />
          );
        }
      },
    },

    {
      key: 'unit',
      title: 'Unit',
      required: true,
      align: 'right',
      width: 70,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        // Specification groups
        const unit_options = _.sortBy(
          _.map(units, (unit, i) => {
            const unit_group = _.get(unit_groups, unit.unit_group_id, {});

            return {
              value: unit.id,
              label: unit.symbol,
              group: getLocalized(unit_group.name, languageId),
              priority: unit_group.priority,
            };
          }),
          'priority'
        );

        return rowData.level === 3 ? (
          <SearchableDropdown
            top={25}
            right={-15}
            multiselect={false}
            dropdownWrapperClassName={styles.dropdownWrapper}
            name={'unit.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Unit</div>}
            groupReadOnly
            options={unit_options}
            onSelect={({ value }) => {
              _handleUnitChange(rowData.id, value);
            }}
          >
            <div className={styles.dropdown}>
              {rowData.unit_id && (
                <span>{_.get(units, [rowData.unit_id, 'symbol'])}</span>
              )}
              {!rowData.unit_id && <span className={styles.none}>--</span>}
              <ArrowDownSmallIcon />
            </div>
          </SearchableDropdown>
        ) : (
          ''
        );
      },
    },
    {
      key: 'price',
      required: true,
      title: `Price (${projectCurrency})`,
      width: 100,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 || rowData.level === 4) {
          return (
            <NumericInput
              key={rowData.id + rowData.price}
              onBlur={(value) => _handlePriceChange(rowData.id, value)}
              readOnly={rowData.level === 4}
              tooltip={
                rowData.level === 4 ? 'This field is auto-calculated' : ''
              }
              defaultValue={rowData.price}
              customWrapperStyles={styles.numericInputCompactView}
            />
          );
        }
      },
    },
    {
      key: 'subtotal',
      title: 'Total',
      width: 110,
      required: true,
      align: 'right',
      resizable: true,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.id !== 'UN') {
          return formatMoney(rowData.subtotal);
        } else {
          return formatMoney(
            _.sumBy(
              _.filter(data, (row) => _.includes(row.id, 'UN')),
              (row) => row.subtotal
            )
          );
        }
      },
    },
    {
      key: 'percentage',
      title: '%',
      width: 70,
      active: true,
      align: 'right',
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (!_.includes(rowData.id, 'UN')) {
          return formatMoney(_.round((rowData.subtotal / total) * 100, 2));
        } else {
          const un_total = _.sumBy(
            _.filter(data, (row) => _.includes(row.id, 'UN')),
            (row) => row.subtotal
          );

          if (rowData.id == 'UN') {
            return formatMoney(_.round((un_total / un_total) * 100, 2));
          }

          return formatMoney(_.round((rowData.subtotal / un_total) * 100, 2));
        }
      },
    },
    {
      key: 'boq_supply_type_group_id',
      title: 'Type',
      width: 130,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        rowData.level === 3 ? (
          <Dropdown
            top={25}
            right={-20}
            wrapperClassName={styles.dropdownWrapper}
            name={'supply_type_group.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Type</div>}
            content={
              <div className={styles.dropdownContent}>
                <div
                  className={classNames(
                    styles.option,
                    !rowData.boq_supply_type_group_id && styles.active
                  )}
                  onClick={(e) =>
                    _handleSupplyTypeGroupChange(e, rowData.id, null)
                  }
                >
                  <span className={styles.title}>--</span>
                  {!rowData.boq_supply_type_group_id && (
                    <span className={styles.dot} />
                  )}
                </div>
                {_.map(boq_supply_type_groups, (group, i) => {
                  return (
                    <div
                      key={i}
                      className={classNames(
                        styles.option,
                        rowData.boq_supply_type_group_id === group.id &&
                          styles.active
                      )}
                      onClick={(e) =>
                        _handleSupplyTypeGroupChange(e, rowData.id, group.id)
                      }
                    >
                      <span className={styles.title}>
                        {getLocalized(group.name, languageId)}
                      </span>
                      {rowData.boq_supply_type_group_id === group.id && (
                        <span className={styles.dot} />
                      )}
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className={styles.dropdown}>
              {rowData.boq_supply_type_group_id && (
                <Tooltip
                  text={getLocalized(
                    _.get(boq_supply_type_groups, [
                      rowData.boq_supply_type_group_id,
                      'name',
                    ]),
                    languageId
                  )}
                >
                  <span>
                    {getLocalized(
                      _.get(boq_supply_type_groups, [
                        rowData.boq_supply_type_group_id,
                        'name',
                      ]),
                      languageId
                    )}
                  </span>
                </Tooltip>
              )}
              {!rowData.boq_supply_type_group_id && (
                <span className={styles.none}>--</span>
              )}
              <ArrowDownSmallIcon />
            </div>
          </Dropdown>
        ) : (
          ''
        ),
    },
    {
      key: 'area',
      title: 'Area',
      width: 150,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) => {
        if (rowData.level === 3 && rowData.children) {
          const selected_room_type_ids = _.map(
            _.filter(
              _.get(rowData, 'children'),
              (position) => position.area_type == 'room_type'
            ),
            (position) => position.area_id
          );

          const excluded_room_type_ids = _.map(
            _.filter(spaceAllocationData.rooms, (room) =>
              _.some(
                _.get(rowData, 'children'),
                (position) =>
                  position.area_type == 'room' && position.area_id == room.id
              )
            ),
            (data) => data.room_type_id
          );

          space_allocation = _.map(space_allocation, (area) => {
            return _.find(
              rowData.children,
              (position) =>
                area.type == position.area_type && area.id == position.area_id
            ) ||
              (area.type == 'room_type' &&
                _.includes(excluded_room_type_ids, area.id)) ||
              (area.type == 'room' &&
                _.includes(selected_room_type_ids, area.room_type_id))
              ? { ...area, disabled: true }
              : { ...area, disabled: false };
          });
        }

        if (rowData.level === 4) {
          let area;

          if (rowData.area_id) {
            area =
              _.find(space_allocation, {
                type: rowData.area_type,
                id: rowData.area_id,
              }) || {};
          }

          return (
            <Dropdown
              top={25}
              right={-20}
              wrapperClassName={styles.dropdownWrapper}
              name={'area.' + rowData.id}
              header={
                <div className={styles.dropdownHeader}>
                  {__('table.area-tab.area')}

                  <div className={styles.dropdownHeaderActions}>
                    <span
                      className={styles.dropdownHeaderExpandAll}
                      onClick={() => {
                        setAreaExpandAll(!areaExpandedAll);
                      }}
                    >
                      {__(
                        'working-set-specifications-flyout.quantity.area.expand-all'
                      )}
                    </span>
                    <span
                      className={styles.dropdownHeaderClear}
                      onClick={() =>
                        _handleAreaChange(rowData.id, {
                          type: '',
                          id: null,
                          count: 1,
                        })
                      }
                    >
                      {__(
                        'working-set-specifications-flyout.quantity.area.clear'
                      )}
                    </span>
                  </div>
                </div>
              }
              content={
                <div
                  className={classNames(styles.dropdownContent, styles.space)}
                >
                  <RoomChooser
                    onCancel={hideDropdown}
                    options={space_allocation}
                    onDone={(values) => {
                      _handleAreaChange(rowData.id, {
                        ...values,
                        count:
                          values.type === 'room_type'
                            ? values.room_count
                            : rowData.area_count,
                      });
                    }}
                    initialValues={[]}
                    expandedAllNodes={areaExpandedAll}
                  />
                </div>
              }
            >
              <div className={classNames(styles.dropdown, styles.areaDropdown)}>
                {area ? (
                  <Tooltip text={area.display_name}>
                    <span>{area.display_name}</span>
                  </Tooltip>
                ) : (
                  <span className={styles.none}>--</span>
                )}{' '}
                <ArrowDownSmallIcon />
              </div>
            </Dropdown>
          );
        }
      },
    },
    {
      key: 'installation_type',
      title: 'F & I',
      width: 68,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        rowData.level === 3 ? (
          <Dropdown
            top={25}
            right={-20}
            wrapperClassName={styles.dropdownWrapper}
            name={'installation_type.' + rowData.id}
            header={
              <div className={styles.dropdownHeader}>Furnish & Install</div>
            }
            content={
              <div className={styles.dropdownContent}>
                <div
                  className={classNames(
                    styles.option,
                    !rowData.installation_type && styles.active
                  )}
                  onClick={(e) =>
                    _handleInstallationTypeChange(e, rowData.id, null)
                  }
                >
                  <span className={styles.title}>--</span>
                  {!rowData.installation_type && (
                    <span className={styles.dot} />
                  )}
                </div>
                {_.map(installation_types, (installation_type, i) => (
                  <div
                    key={i}
                    className={classNames(
                      styles.option,
                      rowData.installation_type === installation_type &&
                        styles.active
                    )}
                    onClick={(e) =>
                      _handleInstallationTypeChange(
                        e,
                        rowData.id,
                        installation_type
                      )
                    }
                  >
                    <span className={styles.title}>{installation_type}</span>
                    {rowData.installation_type === installation_type && (
                      <span className={styles.dot} />
                    )}
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.dropdown}>
              {rowData.installation_type && (
                <span>{rowData.installation_type}</span>
              )}
              {!rowData.installation_type && (
                <span className={styles.none}>--</span>
              )}
              <ArrowDownSmallIcon />
            </div>
          </Dropdown>
        ) : (
          ''
        ),
    },
    {
      key: 'budget_type',
      title: 'Budget',
      width: 70,
      resizable: false,
      sortable: false,
      cellRenderer: ({ rowData }) =>
        rowData.level === 3 ? (
          <Dropdown
            top={25}
            right={0}
            wrapperClassName={styles.dropdownWrapper}
            name={'budget_type.' + rowData.id}
            header={<div className={styles.dropdownHeader}>Budget</div>}
            content={
              <div className={styles.dropdownContent}>
                <div
                  className={classNames(
                    styles.option,
                    !rowData.budget_type && styles.active
                  )}
                  onClick={(e) => _handleBudgetTypeChange(e, rowData.id, null)}
                >
                  <span className={styles.title}>--</span>
                  {!rowData.budget_type && <span className={styles.dot} />}
                </div>
                {_.map(budget_types, (budget_type, i) => (
                  <div
                    key={i}
                    className={classNames(
                      styles.option,
                      rowData.budget_type === budget_type && styles.active
                    )}
                    onClick={(e) =>
                      _handleBudgetTypeChange(e, rowData.id, budget_type)
                    }
                  >
                    <span className={styles.title}>{budget_type}</span>
                    {rowData.budget_type === budget_type && (
                      <span className={styles.dot} />
                    )}
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.dropdown}>
              {rowData.budget_type && <span>{rowData.budget_type}</span>}
              {!rowData.budget_type && <span className={styles.none}>--</span>}
              <ArrowDownSmallIcon />
            </div>
          </Dropdown>
        ) : (
          ''
        ),
    },
    {
      key: 'actions',
      title: '',
      width: 35,
      resizable: false,
      sortable: false,
      frozen: 'right',
      required: true,
      cellRenderer: ({ rowData }) => {
        if (rowData.id == 'UN') return;

        return (
          <div
            className={classNames(styles.actions)}
            onClick={(e) => {
              const x = e.clientX;
              const y = e.clientY;

              const {
                name,
                level,
                id,
                code,
                specification_code_id,
                children,
                specification,
              } = rowData;

              setFocusedContextMenuRow({
                name,
                level,
                id,
                code,
                specification_code_id,
                firstChild: _.first(children),
                specification,
              });

              showMenu({
                position: { x, y },
                id: 'working_set_boq_context_menu',
              });
            }}
          >
            <MenuVIcon
              className={classNames(
                rowData.id === focusedContextMenuRow.id && styles.active
              )}
            />
          </div>
        );
      },
    },
    {
      key: 'placeholder',
      title: '',
      width: 0,
      resizable: false,
      sortable: false,
      frozen: 'right',
      required: true,
    },
  ];

  const headerRenderer = ({ headerIndex, cells, columns }) => {
    if (headerIndex === 0) {
      return _.map(columns, (column, i) => {
        if (column.key === 'name') {
          const levels = {
            2: [],
            3: [],
            4: [],
            5: [],
          };

          _.each(data, (group) => {
            !_.isEmpty(group.children) && levels[2].push(group.id);

            _.each(group.children, (subgroup) => {
              !_.isEmpty(subgroup.children) && levels[3].push(subgroup.id);

              _.each(subgroup.children, (item) => {
                !_.isEmpty(item.children) && levels[4].push(item.id);

                _.each(item.children, (calculation) => {
                  !_.isEmpty(calculation.children) &&
                    levels[5].push(calculation.id);
                });
              });
            });
          });

          let active = {};

          for (let i = 2; i <= 5; i++) {
            active[i] = _.every(levels[i], (id) =>
              _.includes(expandedRows, id)
            );
          }

          return React.cloneElement(
            cells[i],
            null,
            <>
              <div className={styles.placeholder} />
              <div className={styles.masterActions}>
                <Tooltip text='Show group-level'>
                  <span
                    className={classNames(styles.active)}
                    onClick={() => _handleMasterExpand([], true)}
                  >
                    1
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[2]) ? '' : 'Show type-level'}>
                  <span
                    className={classNames(
                      active[2] && styles.active,
                      _.isEmpty(levels[2]) && styles.disabled
                    )}
                    onClick={() => _handleMasterExpand(levels[2], true)}
                  >
                    2
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[3]) ? '' : 'Show item-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && styles.active,
                      _.isEmpty(levels[3]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand([...levels[2], ...levels[3]], true)
                    }
                  >
                    3
                  </span>
                </Tooltip>
                <Tooltip text={_.isEmpty(levels[4]) ? '' : 'Show cost-level'}>
                  <span
                    className={classNames(
                      active[2] && active[3] && active[4] && styles.active,
                      _.isEmpty(levels[4]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand(
                        [...levels[2], ...levels[3], ...levels[4]],
                        false
                      )
                    }
                  >
                    4
                  </span>
                </Tooltip>
                <Tooltip
                  text={_.isEmpty(levels[5]) ? '' : 'Show element-level'}
                >
                  <span
                    className={classNames(
                      active[2] &&
                        active[3] &&
                        active[4] &&
                        active[5] &&
                        styles.active,
                      _.isEmpty(levels[5]) && styles.disabled
                    )}
                    onClick={() =>
                      _handleMasterExpand(
                        [
                          ...levels[2],
                          ...levels[3],
                          ...levels[4],
                          ...levels[5],
                        ],
                        false
                      )
                    }
                  >
                    5
                  </span>
                </Tooltip>
              </div>
              <div className='BaseTable__header-cell-text'>Title</div>
            </>
          );
        } else {
          return React.cloneElement(cells[i], null);
        }
      });
    } else if (
      headerIndex === 1 &&
      _.size(cells) > 1 &&
      groupActions &&
      checked.length !== 0
    ) {
      return (
        <div className={styles.groupActions}>
          <div className={styles.actions}>{groupActions(selected_rows)}</div>
        </div>
      );
    } else {
      return _.map(columns, (column, i) => {
        let children = _.filter(cells[i].props.children, _.identity);

        let value = '';

        if (column.key === 'name') {
          value = (
            <div className='BaseTable__header-cell-text'>
              {checked.length === data_ids.length && checked.length !== 0 && (
                <CheckedIcon
                  className={classNames(styles.checked, styles.master)}
                  onClick={_handleMasterCheckbox}
                />
              )}
              {checked.length === 0 && (
                <UncheckedIcon
                  className={classNames(
                    styles.unchecked,
                    styles.master,
                    checked.length > 0 && styles.visible
                  )}
                  onClick={_handleMasterCheckbox}
                />
              )}
              {checked.length !== data_ids.length && checked.length !== 0 && (
                <NeutralIcon
                  className={classNames(styles.neutral, styles.master)}
                  onClick={_handleMasterCheckbox}
                />
              )}
            </div>
          );
        } else if (column.key === 'subtotal') {
          value = (
            <div className='BaseTable__header-cell-text'>
              {formatMoney(total)}
            </div>
          );
        } else if (column.key === 'percentage') {
          value = (
            <div className='BaseTable__header-cell-text'>
              {formatMoney(total === 0 ? 0 : 100)}
            </div>
          );
        } else if (column.key === 'correction') {
          value = <div className='BaseTable__header-cell-text'>TEST</div>;
        } else if (column.key === 'correction_total') {
          value = <div className='BaseTable__header-cell-text'>TEST</div>;
        }

        if (column.key == 'actions') {
          children[0] = value;
        } else {
          children[column.frozen ? 1 : 0] = value;
        }

        return React.cloneElement(cells[i], null, children);
      });
    }
  };

  // Column selector
  const _resetColumnsToDefault = () => {
    setTempDisabledColumns(
      _.map(
        _.filter(columns, ({ active }) => !active),
        'key'
      )
    );
  };

  const _toggleColumn = (column) => {
    setTempDisabledColumns(_.xor(tempDisabledColumns, [column]));
  };

  useEffect(() => {
    const disabled_columns = _.get(props.store, 'disabled_columns', []);

    setTempDisabledColumns(disabled_columns);
  }, []);

  const _toggleFilterFlyout = () => {
    setTableParams('working_set_boq', {
      showFilters: !props.showFilters,
    });
  };

  const filters = _.reject(
    _.map(props.filters, (filter, key) => {
      return {
        key: key,
        name: filter.name,
        type: filter.type,
        nullable: filter.nullable,
        pinned: _.get(props.pinned_columns, key),
        items: _.map(filter.items, (value, key) => {
          return {
            value: key,
            label: value,
          };
        }),
        defaultItems: _.map(filter.defaultItems, (value, key) => {
          return {
            value: key,
            label: value,
          };
        }),
      };
    }),
    (filter) =>
      filter.type == 'sidebar' ||
      (filter.type == 'select' && _.isEmpty(filter.items))
  );

  const _setURLParams = (params) => {
    const search = queryString.stringify({
      ...queryString.parse(props.history.location.search),
      ...params,
    });
    redirect({ search }, true);
  };

  const _setURLParam = (param) => {
    const search = queryString.stringify(param);
    redirect({ search }, true);
  };

  // Memoize the debounced function to avoid recreating it on each render
  const debouncedSetURLParams = useCallback(
    _.debounce((query) => {
      _setURLParams({
        query,
        actioned_row: undefined,
        clicked_row: undefined,
      });
    }, 600),
    []
  );

  const _handleQuery = (e) => {
    const query = e.target.value;

    debouncedSetURLParams(query);
  };

  const _setSearchFocus = (focus) => {
    setTableParams('working_set_boq', {
      search_focus: focus,
    });
  };

  const _toggleSearch = () => {
    search.current.focus();

    setTableParams('working_set_boq', {
      search_active: !props.store.search_active,
    });
  };

  const _clearSearch = () => {
    _setURLParams({
      query: '',
    });

    setTableParams('working_set_boq', {
      search_active: true,
      search_focus: true,
    });

    search.current.value = '';
    search.current.select();
  };

  const _setView = (view) => {
    setTableParams('working_set_boq', {
      view,
    });

    refreshData();

    updatePreferences(`table.working_set_boq`, { view });
  };

  return (
    <>
      {/* HEADER */}
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Dropdown
            top={25}
            name={title}
            readOnly={!sidebar.hidden}
            wrapperClassName={styles.titleMenuWrapper}
            header={
              <div className={styles.titleMenuHeader}>
                {__('titlemenu.header')}
              </div>
            }
            content={
              <div className={styles.titleMenuContent}>
                {_.map(sidebar.items, (item, i) => (
                  <div
                    className={classNames(
                      styles.item,
                      item.active && styles.activeItem
                    )}
                    key={i}
                    onClick={() => {
                      redirect(item.link());

                      hideDropdown();
                    }}
                  >
                    <Tooltip text={item.title}>
                      <span>{item.title}</span>
                    </Tooltip>

                    <div className={styles.dot} />
                  </div>
                ))}
              </div>
            }
          >
            <div className={styles.title}>
              {title}

              {sidebar.hidden && <ArrowDownSmallIcon />}
            </div>
          </Dropdown>

          <div
            className={classNames(
              styles.preloader,
              props.loading && styles.active
            )}
          >
            <div className={styles.spinner}>
              <div></div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            styles.search,
            props.store.search_focus && styles.focus,
            props.store.search_active && styles.active
          )}
        >
          <div className={styles.bar} />
          <input
            ref={search}
            type='text'
            defaultValue={props.store.query}
            placeholder={__('table.search.enter-search-criteria')}
            spellCheck='false'
            onChange={_handleQuery}
            onClick={() => search.current.select()}
            onBlur={() => _setSearchFocus(false)}
            onFocus={() => _setSearchFocus(true)}
          />
          {!_.isEmpty(props.store.query) && (
            <CloseSmallIcon className={styles.close} onClick={_clearSearch} />
          )}
          {_.isEmpty(props.store.query) && (
            <Tooltip text={__('topbar.tooltip.search')}>
              <SearchIcon onClick={_toggleSearch} />
            </Tooltip>
          )}
        </div>

        <div className={styles.separator} />

        <Tooltip text={__('table.header.button.tooltip.add-from-templates')}>
          <LoadFromTemplateIcon
            className={styles.loadFromTemplate}
            onClick={() => {
              setTableParams('working_set_boq', {
                selected_boq_rows: selected_rows,
                add_boq_items_from_template: true,
              });
            }}
          />
        </Tooltip>

        <div className={styles.separator} />

        <Tooltip
          text={__('working-set-boq.tooltip.icon.export-working-set-boq')}
          key={_.uniqueId()}
        >
          <ExportIcon
            className={styles.export}
            onClick={(e) => {
              e.stopPropagation();

              setNotification(
                'working-set-boq.notification.working-set-boq-export'
              );

              downloadFile(backendURL + '/boq/working/export', {
                stage_id: getActiveStage(),
              });
            }}
          />
        </Tooltip>

        <div className={styles.separator} />

        <Tooltip text={__('content.tooltip.table.boq-properties')}>
          <InfoPositiveIcon
            className={styles.info}
            onClick={() => {
              setTableParams('working_set_boq', {
                clicked_row: props.match.params.stage,
              });

              _setURLParam({ boq_flyout_id: props.match.params.stage });
            }}
          />
        </Tooltip>

        <div className={styles.separator} />

        <div className={styles.language}>
          <Dropdown
            closable={true}
            top={29}
            right={-10}
            name='language_switcher'
            header={
              <div className={styles.languageHeader}>
                {__('table.header.button.label.boq-language')}
              </div>
            }
            content={
              <div className={styles.languageContent}>
                {_.map(project_languages, (item, key) => {
                  return (
                    <div
                      key={key}
                      className={classNames(
                        styles.option,
                        key == languageId && styles.active
                      )}
                      onClick={(e) => {
                        e.stopPropagation();

                        setTableParams('working_set_boq', {
                          language_id: key,
                        });

                        hideDropdown();

                        refreshData();
                      }}
                    >
                      <span>
                        {_.get(localization.languages, [key, 'name'])}
                      </span>

                      <span className={styles.dot} />
                    </div>
                  );
                })}
              </div>
            }
          >
            <Tooltip text={__('table.header.button.label.change-boq-language')}>
              <div
                className={styles.languageSwitcher}
                onClick={() => setLanguageSwitcher(true)}
              >
                <GlobeIcon className={styles.icon} />
                {_.get(localization.languages, [languageId, 'code'])}
                <ArrowDownSmallIcon className={styles.arrow} />
              </div>
            </Tooltip>
          </Dropdown>
        </div>

        <div className={styles.separator} />

        {!_.isEmpty(filters) && (
          <>
            <Tooltip text={__('content.tooltip.table.filters')}>
              <FilterIcon
                className={styles.filter}
                onClick={_toggleFilterFlyout}
              />
            </Tooltip>
          </>
        )}

        <div className={styles.separator} />

        <div className={styles.views}>
          <Tooltip text={__('content.tooltip.table.compactview')}>
            <BOQCompactView
              className={styles.active}
              onClick={() => _setView('compact')}
            />
          </Tooltip>
          <Tooltip text={__('content.tooltip.table.detailedview')}>
            <BOQDetailedView onClick={() => _setView('detailed')} />
          </Tooltip>
        </div>

        <div className={styles.separator} />

        <div className={styles.columnSelector}>
          <Dropdown
            closable={false}
            top={29}
            right={-19}
            name='column_selector'
            header={
              <div className={styles.columnSelectorHeader}>
                {__('table.columns.select-columns')}
              </div>
            }
            content={
              <div className={styles.columnSelectorContent}>
                {_.map(
                  _.filter(
                    columns,
                    ({ required, key }) => !required && key != 'placeholder'
                  ),
                  ({ title, key }) => (
                    <div
                      onClick={() => _toggleColumn(key)}
                      key={key}
                      className={styles.column}
                    >
                      {title}

                      {_.includes(tempDisabledColumns, key) && (
                        <UncheckedIcon className={styles.unchecked} />
                      )}
                      {!_.includes(tempDisabledColumns, key) && (
                        <CheckedIcon className={styles.checked} />
                      )}
                    </div>
                  )
                )}
                <div onClick={_resetColumnsToDefault} className={styles.column}>
                  {__('table.columns.reset-to-default')}
                </div>
                <div className={styles.saveFooter}>
                  <ButtonGroup right>
                    <Button
                      lightGray
                      medium
                      middleText={__('button.cancel')}
                      onClick={() => {
                        hideDropdown();
                      }}
                    />
                    <Button
                      lightBlue
                      medium
                      middleText={__('button.done')}
                      onClick={() => {
                        setTableParams('working_set_boq', {
                          disabled_columns:
                            _.size(tempDisabledColumns) > 0
                              ? tempDisabledColumns
                              : null,
                        });

                        refreshData();

                        hideDropdown();
                      }}
                    />
                  </ButtonGroup>
                </div>
              </div>
            }
          >
            <Tooltip text={__('content.tooltip.table.columns')}>
              <ColumnSelectorIcon />
            </Tooltip>
          </Dropdown>
        </div>
      </div>

      {/* --- --- FILTER --- --- */}
      <Filter
        name={name}
        listAction={refreshData}
        setURLParams={(params) => _setURLParams(params)}
        filterData={filterData}
      />

      {/* --- --- TABLE --- --- */}
      <div className={styles.f1}>
        <AutoSizer>
          {({ height, width }) => (
            <DraggableContainer
              useDragHandle
              getContainer={getContainer}
              lockAxis='y'
              onSortStart={() => setIsDragging(true)}
              helperContainer={getHelperContainer}
              onSortEnd={(event) => {
                handleSortEnd(event);

                setIsDragging(false);
              }}
            >
              <BaseTable
                ref={(ref) => {
                  setRef(ref);

                  tableRef = ref;
                }}
                className={classNames(isDragging && styles.isDragging)}
                rowClassName={({ rowData }) =>
                  classNames(
                    rowData.level === 0 && styles.darkGray,
                    rowData.level === 1 && styles.lightGray,
                    styles.row,
                    rowData.id === focusedId && styles.focused,
                    rowData.id === 'UN' && styles.unallocatedGroup,
                    styles.compactBaseTableRow
                  )
                }
                fixed
                rowProps={({ rowData, rowIndex }) => ({
                  tagName: Row,
                  index: rowIndex,
                  id: rowData.id,
                  unallocatedGroupCollapsed: isUnallocatedCollapsed,
                  onClick: () => {
                    if (!_.includes(rowData.id, 'UN')) {
                      setFocusedId(rowData.id);
                      setTableParams('working_set_boq', {
                        rowIndex,
                        rowData: rowData,
                      });
                    }
                  },
                  onContextMenu: (e) => {
                    if (!_.includes(rowData.id, 'UN')) {
                      setFocusedId(rowData.id);

                      setTableParams('working_set_boq', {
                        rowIndex,
                        rowData: rowData,
                      });
                    }
                  },
                  level: rowData.level,
                  parentId: rowData.parentId,
                  name: rowData.name,
                  code: rowData.code,
                  firstChild: _.first(rowData.children),
                  specification_code_id: rowData.specification_code_id,
                  specification: rowData.specification,
                  setFocusedContextMenuRow: (data) =>
                    setFocusedContextMenuRow(data),
                })}
                components={{
                  ExpandIcon,
                }}
                expandIconProps={({ rowData }) => ({
                  depth: rowData.level,
                  unallocated_group_collapsed: isUnallocatedCollapsed,
                  id: rowData.id,
                  unallocated_group_click_handler: () =>
                    setUnallocatedCollapsed(!isUnallocatedCollapsed),
                })}
                expandColumnKey='name'
                columns={_.filter(
                  columns,
                  ({ key, required }) =>
                    required || !_.includes(props.store.disabled_columns, key)
                )}
                data={final_data}
                width={width}
                height={height}
                onExpandedRowsChange={setExpandedRows}
                ignoreFunctionInColumnCompare={false}
                headerHeight={[32, 32]}
                headerRenderer={headerRenderer}
                headerClassName={({ headerIndex }) =>
                  classNames(
                    headerIndex === 0 && styles.firstHeaderRow,
                    headerIndex === 1 && styles.secondHeaderRow,
                    headerIndex === 1 &&
                      groupActions &&
                      _.size(checked) > 0 &&
                      styles.groupActionsHeaderRow
                  )
                }
                rowHeight={32}
              />
            </DraggableContainer>
          )}
        </AutoSizer>

        <ContextMenu
          id='working_set_boq_context_menu'
          className={styles.contextMenu}
          onHide={() => {
            //setFocusedId(undefined);
            setFocusedContextMenuRow({});
          }}
        >
          {/* --- MOVE BOQ ITEM --- */}
          {_.get(focusedContextMenuRow, 'level') != 4 && (
            <MenuItem
              onClick={() =>
                setTableParams('working_set_boq', {
                  move_boq_item_wizard: {
                    id: _.get(focusedContextMenuRow, 'id'),
                    level: _.get(focusedContextMenuRow, 'level'),
                    name:
                      _.get(focusedContextMenuRow, 'code') +
                      ' ' +
                      _.get(
                        _.get(focusedContextMenuRow, 'name'),
                        languageId,
                        ''
                      ),
                  },
                })
              }
            >
              <Tooltip text={move_texts[_.get(focusedContextMenuRow, 'level')]}>
                <div className={styles.item}>
                  <span className={styles.icon}>
                    <MoveIcon />
                  </span>
                  <span>
                    {move_texts[_.get(focusedContextMenuRow, 'level')]}
                  </span>
                </div>
              </Tooltip>
            </MenuItem>
          )}

          {/* --- COPY BOQ ITEM --- */}
          {(_.get(focusedContextMenuRow, 'level') == 2 ||
            _.get(focusedContextMenuRow, 'level') == 3) && (
            <MenuItem
              onClick={() =>
                setTableParams('working_set_boq', {
                  copy_boq_item_wizard: {
                    id: _.get(focusedContextMenuRow, 'id'),
                    level: _.get(focusedContextMenuRow, 'level'),
                    name:
                      _.get(focusedContextMenuRow, 'code') +
                      ' ' +
                      _.get(focusedContextMenuRow.name, languageId, ''),
                  },
                })
              }
            >
              <Tooltip text={copy_texts[_.get(focusedContextMenuRow, 'level')]}>
                <div className={styles.item}>
                  <span className={styles.icon}>
                    <CopyIcon />
                  </span>
                  <span>
                    {copy_texts[_.get(focusedContextMenuRow, 'level')]}
                  </span>
                </div>
              </Tooltip>
            </MenuItem>
          )}

          {/* --- MERGE BOQ ITEM --- */}
          {_.includes(_.get(focusedContextMenuRow, 'id'), 'UN') && (
            <MenuItem
              onClick={() =>
                setTableParams('working_set_boq', {
                  merge_boq_item_wizard: {
                    id: _.get(focusedContextMenuRow, 'id'),
                    name:
                      _.get(focusedContextMenuRow, 'code') +
                      ' ' +
                      _.get(focusedContextMenuRow.name, languageId, ''),
                    specification: _.get(
                      focusedContextMenuRow,
                      'specification'
                    ),
                  },
                })
              }
            >
              <Tooltip text={__('working-set-boq.table.row.merge-cost')}>
                <div className={styles.item}>
                  <span className={styles.icon}>
                    <LinkIcon />
                  </span>
                  <span>{__('working-set-boq.table.row.merge-cost')}</span>
                </div>
              </Tooltip>
            </MenuItem>
          )}

          {/* --- DELETE BOQ ITEM --- */}
          {!_.get(focusedContextMenuRow, 'firstChild') &&
            !_.includes(_.get(focusedContextMenuRow, 'id'), 'UN') && (
              <MenuItem
                onClick={() =>
                  _handleDelete(_.get(focusedContextMenuRow, 'id'))
                }
              >
                <Tooltip text={__('working-set-boq.table.row.delete')}>
                  <div className={styles.item}>
                    <span className={styles.icon}>
                      <CloseMiddleIcon />
                    </span>
                    <span>{__('working-set-boq.table.row.delete')}</span>
                  </div>
                </Tooltip>
              </MenuItem>
            )}

          <MenuItem className={styles.itemDivider} divider />

          <SubMenu
            title={
              <div className={classNames(styles.item)}>
                <span>{__('working-set-boq.table.row.insert-below')}</span>

                <span className={styles.icon}>
                  <ArrowRightSmallIcon />
                </span>
              </div>
            }
          >
            {!_.includes(_.get(focusedContextMenuRow, 'id'), 'UN') &&
              _.map(addRowButtons, (button, i) => {
                return (
                  !button.disabled && (
                    <MenuItem key={i} onClick={button.onClick}>
                      <Tooltip text={button.label}>
                        <div className={styles.item}>
                          <span>{button.label}</span>
                        </div>
                      </Tooltip>
                    </MenuItem>
                  )
                );
              })}
          </SubMenu>

          {/* --- LOAD BOQ ITEM FROM TEMPLATE / SAVE AS TEMPLATE BOQ ITEM --- */}
          {_.get(focusedContextMenuRow, 'level') === 3 &&
            !_.get(focusedContextMenuRow, 'specification_code_id') &&
            !_.includes(_.get(focusedContextMenuRow, 'id'), 'UN') && (
              <>
                <MenuItem className={styles.itemDivider} divider />

                <MenuItem
                  onClick={() =>
                    setTableParams('working_set_boq', {
                      add_boq_calculation_from_template_wizard: _.get(
                        focusedContextMenuRow,
                        'id'
                      ),
                    })
                  }
                >
                  <Tooltip
                    text={__(
                      'boq-calculation.flyout.add-boq-calculation-from-template'
                    )}
                  >
                    <div className={styles.item}>
                      <span className={styles.icon}>
                        <LoadFromTemplateIcon />
                      </span>
                      <span>
                        {__(
                          'boq-calculation.flyout.add-boq-calculation-from-template'
                        )}
                      </span>
                    </div>
                  </Tooltip>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setTableParams('working_set_boq', {
                      add_boq_template_calculation_wizard: _.last(
                        _.split(_.get(focusedContextMenuRow, 'id'), '-')
                      ),
                    });
                  }}
                >
                  <Tooltip
                    text={__(
                      'boq-calculation.flyout.save-boq-calculation-as-template'
                    )}
                  >
                    <div className={styles.item}>
                      <span className={styles.icon}>
                        <SaveAsTemplateIcon />
                      </span>
                      <span>
                        {__(
                          'boq-calculation.flyout.save-boq-calculation-as-template'
                        )}
                      </span>
                    </div>
                  </Tooltip>
                </MenuItem>
              </>
            )}

          {/* --- UNLINK BOQ ITEM --- */}
          {_.get(focusedContextMenuRow, 'level') === 3 &&
            _.get(focusedContextMenuRow, 'specification_code_id') &&
            !_.includes(_.get(focusedContextMenuRow, 'id'), 'UN') && (
              <>
                <MenuItem className={styles.itemDivider} divider />
                <MenuItem
                  onClick={() =>
                    _handleUnlinkSpecification(
                      _.get(focusedContextMenuRow, 'id')
                    )
                  }
                >
                  <Tooltip
                    text={__('working-set-boq.table.row.unlink-specification')}
                  >
                    <div className={styles.item}>
                      <span className={styles.icon}>
                        <UnlinkIcon />
                      </span>
                      <span>
                        {__('working-set-boq.table.row.unlink-specification')}
                      </span>
                    </div>
                  </Tooltip>
                </MenuItem>
              </>
            )}

          {/* --- PROPERTIES --- */}
          {_.includes([2, 3], _.get(focusedContextMenuRow, 'level')) &&
            !_.includes(_.get(focusedContextMenuRow, 'id'), 'UN') && (
              <>
                <MenuItem className={styles.itemDivider} divider />
                <MenuItem
                  onClick={() => {
                    const level = _.get(focusedContextMenuRow, 'level');
                    let id = _.get(focusedContextMenuRow, 'id');
                    const specification_code_id = _.get(
                      focusedContextMenuRow,
                      'specification_code_id'
                    );
                    const specification_id = _.get(
                      focusedContextMenuRow,
                      'specification.id'
                    );

                    if (level === 2) {
                      id = _.get(_.split(id, '-', 5), 2);

                      getBOQItem(id).then(({ response }) => {
                        setTableParams('working_set_boq', {
                          clicked_row: id,
                          flyout: response.data,
                        });
                      });

                      _setURLParam({ boq_item_id: id });
                    }

                    if (level === 3) {
                      id = _.get(_.split(id, '-', 5), 3);

                      if (specification_code_id) {
                        readWorkingSetSpecification(specification_id).then(
                          ({ response }) => {
                            setTableParams('working_set_specifications', {
                              flyout: response.data,
                              clicked_row: response.data.id,
                            });

                            _setURLParam({
                              specification_id,
                            });
                          }
                        );
                      } else {
                        getBOQCalculation(id).then(({ response }) => {
                          setTableParams('working_set_boq', {
                            clicked_row: id,
                            flyout: response.data,
                          });
                        });

                        _setURLParams({ boq_calculation_id: id });
                      }
                    }
                  }}
                >
                  <Tooltip text={__('working-set-boq.table.row.properties')}>
                    <div className={styles.item}>
                      <span>{__('working-set-boq.table.row.properties')}</span>
                    </div>
                  </Tooltip>
                </MenuItem>
              </>
            )}
        </ContextMenu>
      </div>
    </>
  );
};

export default withRouter(TableCompactView);
