import * as React from "react";
import {Component} from "react";
import Table from "../../components/Table";
import {__, getLocalized, getURLParam, mapStateToProps, setTableParams} from "../../core/utils";
import {createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import {setPlusActions} from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";
import {setNotification} from "../../redux/actions/general/notification";
import FiltersFlyout from "../../flyouts/superadmin/filters/FiltersFlyout";
import {listLocalization} from "../../redux/actions/localization";
import TableNA from "../../components/TableNA";
import {deleteFilters, listFilters, readFilter} from "../../redux/actions/table/superadmin/filters";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_filters"],
	auth: state.auth,
	application_language_id: state.auth.language_id,
}))
class Filters extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_filter && setPlusActions([{
			title: "Add Filter",
			onClick: () => setTableParams('superadmin_filters', {
				add_filter_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readFilter(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, application_language_id, localization } = this.props;

		let columns = [
			{
				key: "name",
				name: "Name",
				value: name => getLocalized(name, application_language_id)
			},
			{
				key: "product_attribute_template_field_ids",
				name: "Product Attributes",
				sortable: false,
				width: 250,
				value: product_attribute_template_field_ids => {
					const product_attribute_fields = _.map(product_attribute_template_field_ids, product_attribute_template_field_id => getLocalized(_.get(localization.product_attribute_fields, [product_attribute_template_field_id, 'label'], {}), application_language_id));

					return !_.isEmpty(product_attribute_fields) ? (
						<Tooltip text={_.join(product_attribute_fields, "\n")}>{_.join(product_attribute_fields, ", ")}</Tooltip>
					) : <TableNA />;
				}
			}
		];

		const singleActions = (filter_id) => {
			return [
				<Tooltip text="Delete selected filter" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected filter?\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteFilters([filter_id]).then(() => {
												hideAlertbox();

												setNotification("The selected filter has been deleted.");
												listFilters();

												listLocalization();

												setTableParams('superadmin_filters', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (filter_ids) => [
			<Tooltip text="Delete filters" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these filters?\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteFilters(filter_ids).then(() => {
											hideAlertbox();

											setNotification("The selected filters have been deleted.");
											listFilters();

											listLocalization();

											setTableParams('superadmin_filters', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<FiltersFlyout
				data={this.state.preview}
				readAction={filter_id => {
					return new Promise(resolve => {
						readFilter(getURLParam('id') || filter_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listFilters}
				name="superadmin_filters"
				groupActions={superadmin.delete_filter && groupActions}
				singleActions={superadmin.delete_filter && singleActions}
				title={__("modules.submodules.title.filters")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default Filters;
