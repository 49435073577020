import React, { useEffect, useState } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_boq_calculation_from_template_wizard/AddBOQCalculationFromTemplateForm.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import { __ } from '../../core/utils';
import * as _ from 'lodash';
import Select from '../../components/Select';
import { connect } from 'react-redux';
import { listPossibleBOQCalculations } from '../../redux/actions/table/working_set_boq';

const AddBOQCalculationFromTemplateForm = ({ handleSubmit, onClose }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    listPossibleBOQCalculations().then(({ response }) => {
      setOptions(response.data);
    });
  }, []);

  return (
    <Form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.flex}>
        <div className={styles.f1}>
          <Field
            name='boq_template_calculation_id'
            component={Select}
            label={__(
              'boq-calculation.flyout.add-boq-calculation-from-template.boq_template_subgroup_id'
            )}
            options={options}
            searchable
            nestedList
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          lightGray
          medium
          middleText={__('button.cancel')}
          onClick={onClose}
        />
        <Button
          lightBlue
          medium
          middleText={__('button.add')}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    language_id: state.table.working_set_boq.language_id,
  };
};

export default reduxForm({
  form: 'add_boq_calculation_from_template_wizard.add_boq_calculation_from_template',
})(
  connect(
    mapStateToProps,
    null
  )(subscribe(Localization, 'localization')(AddBOQCalculationFromTemplateForm))
);
