export default [
	{
		value: "mr",
		label: "Mr"
	},
	{
		value: "mrs",
		label: "Mrs"
	},
	{
		value: "ms",
		label: "Ms"
	},
	{
		value: "miss",
		label: "Miss"
	}
];
