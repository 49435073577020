import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddOCRTemplateWizard.scss";
import autobind from "autobind-decorator";
import OCRTemplateIcon from "../assets/images/ocr-template-64x64.svg";
import {__, mapStateToProps, validateForm} from "../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../redux/actions/general/notification";
import {createOCRTemplate, listOCRTemplates} from "../redux/actions/table/ocr_templates";
import AddOCRTemplateForm from "../forms/add_ocr_template_wizard/AddOCRTemplateForm";
import * as _ from "lodash";
import {SubmissionError} from "redux-form";

@withRouter
@mapStateToProps(state => ({
	table: state.table["ocr_templates"],
}))
class AddOCRTemplateWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(createOCRTemplate(this.props.match.params.stage, formData).then(() => {
			listOCRTemplates(this.props.match.params.stage);

			this.props.onClose();

			setNotification(__("wizard.add-ocr-template.message.success"));
		})).catch(({ errors }) => {
			if (_.has(errors, "code") && errors.code === 1) {
				setNotification({
					type: "warning",
					text: __("wizard.add-ocr-template.notification.upload-one-page-error")
				});
			} else {
				throw new SubmissionError(errors);
			}
		});
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.add-ocr-template.title.add-new-ocr-template')}</span>
						<span>{__('wizard.add-ocr-template.subtitle.add-new-ocr-template')}</span>
					</div>
					<div className={styles.right}>
						<OCRTemplateIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddOCRTemplateForm onSubmit={this._handleSubmit} />
				</div>
			</>
		);
	}
}

export default AddOCRTemplateWizard;