import store from "../../../../core/store";

export const listProducts = () => {
    return store.dispatch({
        action: "table.superadmin_products/LIST_PRODUCTS",
        method: "GET",
        endpoint: "/superadmin/products",
        main: true
    });
};

export const readProduct = (product_id) => {
    return store.dispatch({
        action: "table.superadmin_products/READ_PRODUCT",
        method: "GET",
        endpoint: "/superadmin/products/" + product_id,
        has404: true
    });
};

export const publishProducts = (products) => {
    return store.dispatch({
        action: "table.superadmin_products/PUBLISH_PRODUCTS",
        method: "PUT",
        endpoint: "/superadmin/products/publish",
        data: {
            products
        }
    });
};

export const unpublishProducts = (products) => {
    return store.dispatch({
        action: "table.superadmin_products/UNPUBLISH_PRODUCTS",
        method: "PUT",
        endpoint: "/superadmin/products/unpublish",
        data: {
            products
        }
    });
};