import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import * as _ from "lodash";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {
    enableProjectsDropdown,
    enableStagesDropdown,
    listActiveModules
} from "../../redux/actions/general/active_modules";
import {__, backendURL, getActiveStage, getURLParam, hasURLParam, redirect, setTableParams} from "../../core/utils";
import styles from "../../styles/views/plans/PlanVersionPreview.scss";
import {readPlanVersion, readWorkingSetPlan} from "../../redux/actions/table/working_set_plans";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import ArrowRightSmallIcon from "../../assets/images/arrow-right-small-15x15.svg";
import queryString from "query-string";
import {connect} from "react-redux";
import classNames from "classnames";
import WorkingSetPlansFlyout from "../../flyouts/working_set_plans/WorkingSetPlansFlyout";
import InfoPositiveIcon from "../../assets/images/info-positive-16x16.svg";
import CompareIcon from "../../assets/images/compare-15x15.svg";
import autobind from "autobind-decorator";
import {leaveChannel, listenPlanProcessed} from "../../core/sockets";
import {isSafari} from "react-device-detect";
import Tooltip from "../../components/Tooltip";
import IframeComm from "react-iframe-comm";
import {readTask} from "../../redux/actions/table/all_tasks";
import TasksFlyout from "../../flyouts/tasks/TasksFlyout";
import ArrowDoubleLeftMiddleIcon from "../../assets/images/arrow-double-left-middle-15x15.svg";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ArrowDoubleRightMiddleIcon from "../../assets/images/arrow-double-right-middle-15x15.svg";
import KeyboardEventHandler from "react-keyboard-event-handler";

const mapStateToProps = state => {
    return {
        user_id: state.auth.id,
        table: state.table["working_set_plans"],
        task_flyout_active: _.get(state.table["all_tasks"], 'clicked_row'),
        working_set_plans: _.get(state.table["working_set_plans"], 'data')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.working_set_plans/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class PlanVersionPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            plan_version: null,
            preview: null,
            task_preview: null,
            tiles_loaded: false
        };
    }

    componentWillUnmount() {
        leaveChannel('working_set_plans.' + this.props.match.params.stage);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.table.task_id != nextProps.table.task_id && nextProps.table.task_id) {
            this.refs.openseadragon.sendMessage({
                action: 'task_created',
                task_id: nextProps.table.task_id,
                x: nextProps.table.x,
                y: nextProps.table.y,
            });

            setTableParams("working_set_plans", {
                task_id: undefined,
                x: undefined,
                y: undefined,
            });
        }
    }

    @autobind
    _handleReceivePostMessage({data}) {
        data.action == 'close_compare_tool' && redirect({
            search: queryString.stringify({
                ...queryString.parse(this.props.history.location.search),
                compare: undefined
            })
        }, true);

        data.action == 'attach_task' && setTableParams('all_tasks', {
            x: data.x,
            y: data.y,
            attach_task_wizard: true,
            stage_id: this.state.plan_version.plan.stage_id
        });

        data.action == 'read_task' && readTask(data.task_id).then(({response}) => {
            this.setState({ task_preview: response.data }, () => {
                setTableParams('all_tasks', {
                    clicked_row: data.task_id
                });
            });
        })
    }

    componentDidMount() {
        readPlanVersion(this.props.match.params.plan_version).then(({response}) => {
            this.setState({plan_version: response.data});

            listActiveModules();

            listenPlanProcessed(this.state.plan_version.plan.stage_id, (plan_id) => {
                plan_id == this.state.plan_version.plan.id && readWorkingSetPlan(this.state.plan_version.plan.id).then(({ response }) => {
                    this.setState({ preview: response.data });
                });
            });

            readWorkingSetPlan(this.state.plan_version.plan.id).then(({response}) => {
                this.setState({ preview: response.data });
            });

            enableProjectsDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/plans/working'));
            enableStagesDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/plans/working'));

            const approval = _.get(queryString.parse(this.props.location.search), "approval", undefined);

            if(hasURLParam('task_id')) {
                readTask(getURLParam('task_id')).then(({response}) => {
                    this.setState({ task_preview: response.data }, () => {
                        setTableParams('all_tasks', {
                            clicked_row: response.data.id
                        });
                    });
                })
            }

            createSidebar({
                title: __('submenu.title.plans'),
                items: [
                    {
                        title: __("submenu.title.current-set"),
                        link: () => "/stages/" + getActiveStage() + "/plans/current",
                    },
                    {
                        title: __("submenu.title.working-set"),
                        link: () => "/stages/" + getActiveStage() + "/plans/working",
                        active: _.isUndefined(approval)
                    },
                    {
                        title: __("submenu.title.uploads"),
                        link: () => "/stages/" + getActiveStage() + "/plans/uploads",
                    },
                    {
                        title: __("submenu.title.deliveries"),
                        link: () => "/stages/" + getActiveStage() + "/deliveries",
                    },
                    {
                        title: __("submenu.title.approvals"),
                        link: () => "/stages/" + getActiveStage() + "/approvals",
                        active: !_.isUndefined(approval),
                        items: !_.isUndefined(approval) && [
                            {
                                title: __("submenu.title.approvals.pending"),
                                link: () => "/stages/" + getActiveStage() + "/approvals/pending",
                                active: approval == 'pending'
                            },
                            {
                                title: __("submenu.title.approvals.approved"),
                                link: () => "/stages/" + getActiveStage() + "/approvals/approved",
                                active: approval == 'approved'
                            },
                            {
                                title: __("submenu.title.approvals.rejected"),
                                link: () => "/stages/" + getActiveStage() + "/approvals/rejected",
                                active: approval == 'rejected'
                            },
                        ]
                    }
                ]
            });
        });
    }

    @autobind
    _showRowActions(id, e) {
        this.props.actions.setTableParams({
            actioned_row: this.props.table.actioned_row != id ? id : undefined
        });

        e.stopPropagation();
    }

    @autobind
    _goBack() {
        window.previousLocation ? window.history.back() : redirect("/stages/" + getActiveStage() + "/plans/working")
    }

    @autobind
    _toggleCompareTool() {
        const compare = _.get(queryString.parse(this.props.location.search), "compare", undefined);

        redirect({
            search: queryString.stringify({
                ...queryString.parse(this.props.history.location.search),
                compare: compare ? undefined : '1'
            })
        }, true);
    }

    @autobind
    _handleShortcut(key, e) {
        e.preventDefault();

        let first_plan_id, last_plan_id, prev_plan_id, next_plan_id;

        if(!_.isEmpty(this.props.working_set_plans) && this.state.plan_version) {
            first_plan_id = _.first(this.props.working_set_plans).last_version.id;
            last_plan_id = _.last(this.props.working_set_plans).last_version.id;

            prev_plan_id = _.get(this.props.working_set_plans, [_.findIndex(this.props.working_set_plans, ['id', this.state.plan_version.plan.id]) - 1, 'last_version', 'id']) || first_plan_id;
            next_plan_id = _.get(this.props.working_set_plans, [_.findIndex(this.props.working_set_plans, ['id', this.state.plan_version.plan.id]) + 1, 'last_version', 'id']) || last_plan_id;

            if(key == "up" || key == "left") {
                redirect('/plans/versions/' + prev_plan_id, true);
            } else if(key == "down" || key == "right") {
                redirect('/plans/versions/' + next_plan_id, true);
            }
        }
    }

    render() {
        const approval = _.get(queryString.parse(this.props.location.search), "approval", undefined);

        const compare = _.has(queryString.parse(this.props.location.search), "compare") && _.get(this.state, 'plan_version.versions', []) > 1 && !isSafari;

        let plan_preview_data, toolUrl;

        if(this.state.plan_version) {
            plan_preview_data = queryString.stringify(compare ? {
                backend: backendURL,
                current_set: false,
                id: this.state.plan_version.id,
                width: this.state.plan_version.plan.width,
                height: this.state.plan_version.plan.height,
            } : {
                backend: backendURL,
                id: this.state.plan_version.id,
                width: this.state.plan_version.plan.width,
                height: this.state.plan_version.plan.height,
                annotations: _.isUndefined(approval),
                edit: _.isUndefined(approval) && this.state.plan_version.edit,
                scale1: this.state.plan_version.plan.scale1,
                scale2: this.state.plan_version.plan.scale2,
                canceled: this.state.plan_version.plan.status == '0',
                user_id: this.props.user_id,
                current_set: false,
                task_id: getURLParam('task_id') || undefined,
                can_create_task: this.state.plan_version.can_create_task
            });

            toolUrl = "/plan_preview/" + (compare ? 'compare.html' : 'index.html') + "?" + plan_preview_data;
        }

        let first_plan_id, last_plan_id, prev_plan_id, next_plan_id;

        if(!_.isEmpty(this.props.working_set_plans) && plan_preview_data) {
            first_plan_id = _.first(this.props.working_set_plans).last_version.id;
            last_plan_id = _.last(this.props.working_set_plans).last_version.id;

            prev_plan_id = _.get(this.props.working_set_plans, [_.findIndex(this.props.working_set_plans, ['id', this.state.plan_version.plan.id]) - 1, 'last_version', 'id']) || first_plan_id;
            next_plan_id = _.get(this.props.working_set_plans, [_.findIndex(this.props.working_set_plans, ['id', this.state.plan_version.plan.id]) + 1, 'last_version', 'id']) || last_plan_id;
        }

        return (
            <div className={styles.wrapper}>
                <KeyboardEventHandler handleKeys={['up', 'down', 'left', 'right']} onKeyEvent={this._handleShortcut} />
                <div className={styles.toolbar}>
                    <div className={styles.title}>
                        {this.state.plan_version && (
                            <>
                                {_.isUndefined(approval) ? (
                                    <>
                                        Working Set
                                        <ArrowRightSmallIcon />
                                        {this.state.plan_version.plan.discipline.id == null ? (this.state.plan_version.plan.discipline.description + '*') : _.get(this.props.localization.disciplines, [this.state.plan_version.plan.discipline.id, 'name'])}
                                    </>
                                ) : 'Approvals'}
                                <ArrowRightSmallIcon />
                                {_.filter([this.state.plan_version.plan.code, this.state.plan_version.plan.title], _.identity).join(': ')}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.header}>
                    {this.state.plan_version && <>
                        <div className={styles.back} onClick={this._goBack}>
                            <Tooltip text={__('plans.tooltip.icon.back-to-list')}><ArrowLeftMiddleIcon /></Tooltip> Back
                        </div>
                        <div className={classNames(styles.actions)}>
                            {this.state.plan_version.versions > 1 && isSafari && <Tooltip text="Compare tool is not available in Safari browser">
                                <CompareIcon className={classNames(compare && styles.active, styles.disabled)} />
                            </Tooltip>}
                            {this.state.plan_version.versions > 1 && !isSafari && <Tooltip text={__('plans.tooltip.icon.plan-version-compare')}><CompareIcon
                                className={classNames(compare && styles.active)}
                                onClick={this._toggleCompareTool}
                            /></Tooltip>}
                            <Tooltip text={__('plans.tooltip.icon.plan-properties')}><InfoPositiveIcon
                                className=''
                                onClick={() => {
                                    this.props.actions.setTableParams({
                                        clicked_row: this.state.plan_version.id,
                                    });
                                }}
                            /></Tooltip>
                        </div>
                    </>}
                </div>
                <div className={styles.content}>
                    {plan_preview_data && <IframeComm
                        attributes={{
                            src: toolUrl
                        }}
                        ref="openseadragon"
                        handleReady={() => this.refs.openseadragon.sendMessage({
                            action: 'handle_tiles',
                            tiles: this.state.plan_version.plan.tiles
                        })}
                        handleReceiveMessage={this._handleReceivePostMessage}
                    />}
                </div>
                {!_.isEmpty(this.props.working_set_plans) && plan_preview_data && <div className={styles.footer}>
                    <div className={styles.pagination}>
                        <Tooltip text={__('content.tooltip.table.first-page')}><ArrowDoubleLeftMiddleIcon onClick={() => redirect('/plans/versions/' + first_plan_id, true)} /></Tooltip>
                        <Tooltip text={__('content.tooltip.table.prev-page')}><ArrowLeftMiddleIcon onClick={() => redirect('/plans/versions/' + prev_plan_id, true)} /></Tooltip>
                        <span className={classNames(styles.page, styles.active)}>
                            {this.state.plan_version.plan.code}
                        </span>
                        <Tooltip text={__('content.tooltip.table.next-page')}><ArrowRightMiddleIcon onClick={() => redirect('/plans/versions/' + next_plan_id, true)} /></Tooltip>
                        <Tooltip text={__('content.tooltip.table.last-page')}><ArrowDoubleRightMiddleIcon onClick={() => redirect('/plans/versions/' + last_plan_id, true)} /></Tooltip>
                    </div>
                </div>}
                <div className={classNames(styles.flyout, (this.props.table.clicked_row || this.props.task_flyout_active) && styles.active)}>
                    {this.props.table.clicked_row && this.state.preview && <WorkingSetPlansFlyout
                        data={this.state.preview}
                        readAction={(id) => {
                            return new Promise(resolve => {
                                readWorkingSetPlan(this.state.plan_version.plan.id).then(({response}) => {
                                    if(this.state.preview.status == '1' && response.data.status == '0') {
                                        readPlanVersion(this.props.match.params.plan_version).then(({response}) => {
                                            this.setState({plan_version: response.data});

                                            resolve(response);
                                        });
                                    }

                                    this.setState({preview: response.data});
                                });
                            });
                        }}
                    />}
                    {this.props.task_flyout_active && this.state.task_preview && <TasksFlyout
                        data={this.state.task_preview}
                        readAction={task_id => {
                            return new Promise(resolve => {
                                readTask(task_id).then(({response}) => {
                                    this.setState({task_preview: response.data});

                                    resolve(response);
                                });
                            });
                        }}
                    />}
                </div>
            </div>
        );
    }
}

export default PlanVersionPreview;