import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import Textarea from '../../components/Textarea';
import autobind from 'autobind-decorator';
import { __, mapStateToProps, redirect } from '../../core/utils';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/tasks/EditTaskForm.scss';
import Input from '../../components/Input';
import TaskRfiIcon from '../../assets/images/task-rfi-15x15.svg';
import classNames from 'classnames';
import TaskFyiIcon from '../../assets/images/task-fyi-15x15.svg';
import TaskDecisionIcon from '../../assets/images/task-decision-15x15.svg';
import TaskTodoIcon from '../../assets/images/task-todo-15x15.svg';
import TaskMilestoneIcon from '../../assets/images/task-milestone-15x15.svg';
import TaskIssueIcon from '../../assets/images/task-issue-15x15.svg';
import Select from '../../components/Select';
import PeopleChooser from '../../components/PeopleChooser';
import Tags from '../../components/Tags';
import {
  listPossibleTaskAssignee,
  listPossibleTaskFollowers,
  updateTaskSpaceAllocation,
  listAllTasks,
} from '../../redux/actions/table/all_tasks';
import { withRouter } from 'react-router';
import * as _ from 'lodash';
import MultiSelect from '../../components/MultiSelect';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import { listPossibleStageDisciplines } from '../../redux/actions/table/teams';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import TargetIcon from '../../assets/images/target-15x15.svg';
import Tooltip from '../../components/Tooltip';
import Dropdown from '../../components/Dropdown';
import { listPossibleTags } from '../../redux/actions/general';
import RoomChooser from '../../components/RoomChooser';
import { hideDropdown } from '../../redux/actions/general/dropdown';
import { setNotification } from '../../redux/actions/general/notification';

@reduxForm({
  form: 'tasks.edit_task',
  enableReinitialize: true,
})
@withRouter
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  values: getFormValues('tasks.edit_task')(state),
}))
class EditTaskForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_task_assignee: [],
      possible_task_followers: [],
      stage_tags: [],
      possible_stage_disciplines: [],
    };
  }

  componentDidMount() {
    const {
      data,
      localization: { disciplines },
    } = this.props;
    const stage_id = data.stage.id;
    const project_id = data.project.id;

    listPossibleTaskAssignee(stage_id).then(({ response }) => {
      this.setState({
        possible_task_assignee: _.map(response.data, (data) => {
          return data.type === 'discipline'
            ? {
                ...data,
                name: `${[
                  data.company_name,
                  [
                    data.stage_discipline.code,
                    data.stage_discipline.discipline_id == null
                      ? data.stage_discipline.description + '*'
                      : _.get(disciplines, [
                          data.stage_discipline.discipline_id,
                          'name',
                        ]),
                  ].join(': '),
                ].join(' | ')}`,
              }
            : data;
        }),
      });
    });

    listPossibleTaskFollowers(stage_id).then(({ response }) => {
      this.setState({
        possible_task_followers: _.map(response.data, (data) => {
          return data.type === 'discipline'
            ? {
                ...data,
                name: `${[
                  data.company_name,
                  [
                    data.stage_discipline.code,
                    data.stage_discipline.discipline_id == null
                      ? data.stage_discipline.description + '*'
                      : _.get(disciplines, [
                          data.stage_discipline.discipline_id,
                          'name',
                        ]),
                  ].join(': '),
                ].join(' | ')}`,
              }
            : data;
        }),
      });
    });

    listPossibleTags('project', project_id).then(({ response }) => {
      this.setState({ stage_tags: response.data });
    });

    listPossibleStageDisciplines(stage_id).then(({ response }) => {
      this.setState({ possible_stage_disciplines: response.data });
    });
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _handleSpaceAllocation(values) {
    const { id } = this.props.data;

    this.props.change('space_allocation', values);

    updateTaskSpaceAllocation(id, {
      space_allocation: _.reduce(
        _.keys(values),
        (space_allocation, value) => {
          const [space_allocation_type, space_allocation_id] = _.split(
            value,
            ':'
          );

          if (!_.isArray(space_allocation[space_allocation_type])) {
            space_allocation[space_allocation_type] = [];
          }

          space_allocation[space_allocation_type].push(space_allocation_id);

          return space_allocation;
        },
        {}
      ),
    })
      .then(() => {
        this.props.match.params.stage &&
          listAllTasks(this.props.match.params.stage);
      })
      .then(() => {
        setNotification(__('tasks.properties.saved'));
      });

    hideDropdown();
  }

  render() {
    const { data, handleSubmit, possibleSpaceAllocation } = this.props;
    const { creator, is_editor, status } = data;
    const project_languages = data.project.languages;
    const {
      possible_stage_disciplines,
      stage_tags,
      possible_task_assignee,
      possible_task_followers,
    } = this.state;
    const { disciplines } = this.props.localization;
    const disabled = !is_editor || _.includes(['closed', 'canceled'], status);

    const types = [
      {
        value: 'rfi',
        label: __('tasks.type.rfi'),
        icon: (hover, active, selected) => (
          <TaskRfiIcon
            className={classNames(
              styles.rfiIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'fyi',
        label: __('tasks.type.fyi'),
        icon: (hover, active, selected) => (
          <TaskFyiIcon
            className={classNames(
              styles.fyiIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'decision',
        label: __('tasks.type.decision'),
        icon: (hover, active, selected) => (
          <TaskDecisionIcon
            className={classNames(
              styles.decisionIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'todo',
        label: __('tasks.type.todo'),
        icon: (hover, active, selected) => (
          <TaskTodoIcon
            className={classNames(
              styles.todoIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'milestone',
        label: __('tasks.type.milestone'),
        icon: (hover, active, selected) => (
          <TaskMilestoneIcon
            className={classNames(
              styles.milestoneIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
      {
        value: 'issue',
        label: __('tasks.type.issue'),
        icon: (hover, active, selected) => (
          <TaskIssueIcon
            className={classNames(
              styles.issueIcon,
              active && styles.active,
              selected && styles.selected
            )}
          />
        ),
      },
    ];

    const initialSpaceAllocation = _.get(
      this.props.values,
      'space_allocation',
      []
    );

    return (
      <Form onSubmit={handleSubmit}>
        <div className={styles.section}>
          <div className={styles.flex}>
            <div className={styles.f3}>
              <Field
                disabled={disabled}
                name='type'
                component={Select}
                noSort
                label={__('tasks.flyout.type')}
                options={types}
              />
            </div>
            <div className={styles.f7}>
              <Field
                disabled={disabled}
                name='title'
                component={Input}
                label={__('tasks.flyout.title')}
                maxLength={60}
              />
            </div>
          </div>
          <Field
            disabled={disabled}
            name='description'
            localized={project_languages}
            component={Textarea}
            label={__('tasks.flyout.description')}
            maxLength={2000}
            rows={1}
            maxRows={10}
            placeholder={__('tasks.flyout.add-description')}
          />
        </div>
        <div className={styles.section}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <div className={styles.flex}>
                <div className={styles.f1}>
                  <Field
                    disabled={true}
                    name='creator'
                    component={PeopleChooser}
                    label={__('tasks.flyout.owner')}
                    options={[creator]}
                  />
                </div>
                <div className={styles.f4}>
                  <Field
                    disabled={disabled}
                    limit={7}
                    name='assignee'
                    component={PeopleChooser}
                    label={__('tasks.flyout.assignee')}
                    searchable
                    options={possible_task_assignee}
                    placeholder={__('tasks.flyout.select-assignee')}
                  />
                </div>
              </div>
            </div>
            <div className={styles.f1}>
              <Field
                disabled={disabled}
                limit={10}
                name='followers'
                component={PeopleChooser}
                label={__('tasks.flyout.followers')}
                searchable
                options={possible_task_followers}
                placeholder={__('tasks.flyout.select-followers')}
              />
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.flex}>
            <div className={styles.f1}>
              <Field
                disabled={disabled}
                name='stage_disciplines'
                searchable={true}
                component={MultiSelect}
                label={__('tasks.flyout.disciplines')}
                options={_.map(
                  possible_stage_disciplines,
                  (stage_discipline) => ({
                    value: stage_discipline.id,
                    label: [
                      stage_discipline.code,
                      stage_discipline.discipline_id == null
                        ? stage_discipline.description + '*'
                        : _.get(disciplines, [
                            stage_discipline.discipline_id,
                            'name',
                          ]),
                    ].join(': '),
                  })
                )}
                placeholder={__('tasks.flyout.select-stage_disciplines')}
              />
            </div>
            <div className={styles.f1}>
              <Field
                disabled={disabled}
                name='tags'
                component={Tags}
                label={__('tasks.flyout.tags')}
                options={stage_tags}
              />
            </div>
          </div>
          {(!_.isEmpty(data.plan_versions) ||
            !_.isEmpty(data.plan_revisions)) && (
            <div className={styles.goto}>
              <Dropdown
                leftStyled
                top={30}
                left={0}
                name={'tasks_flyout.' + data.id}
                header={
                  <div className={styles.targetDropdownHeader}>
                    {__('table.columns.goto')}
                    <TargetIcon />
                  </div>
                }
                content={
                  <div className={styles.targetDropdownContent}>
                    {_.map(data.plan_versions, (version, i) => (
                      <div
                        className={styles.item}
                        key={i}
                        onClick={(e) => {
                          e.preventDefault();
                          redirect(
                            '/plans/versions/' +
                              version.id +
                              '?task_id=' +
                              data.id
                          );
                        }}
                      >
                        <Tooltip
                          text={version.name + (' (V' + version.number + ')')}
                        >
                          <span>
                            {version.name + (' (V' + version.number + ')')}
                          </span>
                        </Tooltip>
                        <div className={styles.dot} />
                      </div>
                    ))}
                    {_.map(data.plan_revisions, (revision, i) => (
                      <div
                        className={styles.item}
                        key={i}
                        onClick={(e) => {
                          e.preventDefault();
                          redirect(
                            '/plans/revisions/' +
                              revision.id +
                              '?task_id=' +
                              data.id
                          );
                        }}
                      >
                        <Tooltip
                          text={revision.name + (' (V' + revision.number + ')')}
                        >
                          <span>
                            {revision.name + (' (V' + revision.number + ')')}
                          </span>
                        </Tooltip>
                        <div className={styles.dot} />
                      </div>
                    ))}
                  </div>
                }
              >
                <button type='button'>
                  <TargetIcon /> {__('table.columns.goto')}{' '}
                  <ArrowDownMiddleIcon />
                </button>
              </Dropdown>
            </div>
          )}
          <Dropdown
            top={25}
            right={-20}
            wrapperClassName={styles.dropdownWrapper}
            name={'tasks_flyout_area.' + data.id}
            header={
              <div className={styles.dropdownHeader}>
                {__('table.area-tab.area')}
              </div>
            }
            content={
              <div className={classNames(styles.dropdownContent, styles.space)}>
                <RoomChooser
                  multiselect={true}
                  onCancel={hideDropdown}
                  options={possibleSpaceAllocation}
                  onDone={this._handleSpaceAllocation}
                  initialValues={initialSpaceAllocation}
                />
              </div>
            }
          >
            <label className={styles.spaceAllocationLabel}>
              {__('general.label.space-allocation')}
            </label>
            <div className={styles.tagsGroup} onClick={this._handleOpen}>
              {_.size(initialSpaceAllocation) == 0 ? (
                <span className={styles.placeholder}>
                  {__('tasks.flyout.select-area')}
                </span>
              ) : (
                <div className={styles.tags}>
                  {_.map(_.sortBy(initialSpaceAllocation), (tag, i) => (
                    <span key={i}>{tag.display_name}</span>
                  ))}
                </div>
              )}
              {!this.props.center && !this.props.disabled && (
                <ArrowDownMiddleIcon />
              )}
            </div>
          </Dropdown>
        </div>
      </Form>
    );
  }
}

export default EditTaskForm;
