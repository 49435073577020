import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/products/ProductsFlyout.scss";
import {__, getLocalized, mapStateToProps, setTableParams, toBoolean} from "../../../core/utils";
import CloseBigIcon from "../../../assets/images/close-big-15x15.svg";
import Tooltip from "../../../components/Tooltip";
import classNames from "classnames";
import GlobeIcon from "../../../assets/images/globe-16x16.svg";
import * as _ from "lodash";
import ArrowDownSmallIcon from "../../../assets/images/arrow-down-small-15x15.svg";
import Select from "../../../components/Select";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";
import {Element, ScrollLink, scrollSpy} from 'react-scroll';
import autobind from "autobind-decorator";
import Overview from "./Overview";
import Images from "./Images";
import Files from "./Files";
import About from "./About";
import Parts from "./Parts";
import Price from "./Price";
import {setNotification} from "../../../redux/actions/general/notification";
import LockOpenedIcon from "../../../assets/images/lock-opened-16x16.svg";
import LockClosedIcon from "../../../assets/images/lock-closed-16x16.svg";
import AttributesForm from "../../../forms/superadmin/products/AttributesForm";
import {
    listProducts,
    publishProducts,
    readProduct,
    unpublishProducts
} from "../../../redux/actions/table/superadmin/products";

@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    store: state.table.superadmin_products,
    auth: state.auth,
    superadmin: state.auth.superadmin,
}))
class ProductsFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            language_switcher: false,
            collapsed_tabs: ['overview'], //fix for scrollSpy not working on init
            highlighted_tab: null,
        };

        setTableParams('superadmin_products', {
            language_id: _.first(props.store.flyout.languages)
        });

        this.highlight_timeout = null;
    }

    componentDidMount() {
        scrollSpy.update();

        this.setState({
            collapsed_tabs: []
        })
    }

    @autobind
    _toggleCollapseTab(tab) {
        this.setState({
            collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab])
        })
    }

    @autobind
    _highlightSection(section) {
        this.setState({highlighted_tab: section}, () => {
            if(this.highlight_timeout) clearTimeout(this.highlight_timeout);

            this.highlight_timeout = setTimeout(() => {
                this.setState({highlighted_tab: null});
            }, 200);
        });
    }

    @autobind
    _toggleCollapseTabs() {
        const {flyout, language_id} = this.props.store;

        const all_tabs = [
            'overview',
            'attributes',
            'about',
        ];

        if(_.size(flyout.images) > 0) {
            all_tabs.push('images');
        }

        if(_.size(flyout.files) > 0) {
            all_tabs.push('files');
        }

        if(flyout.price_from || flyout.price_to || flyout.price_date || _.size(getLocalized(flyout.price_note, language_id)) > 0 || flyout.discontinued) {
            all_tabs.push('price');
        }

        if(_.size(this.state.collapsed_tabs) == _.size(all_tabs)) {
            this.setState({
                collapsed_tabs: []
            });
        } else {
            this.setState({
                collapsed_tabs: all_tabs
            });
        }

        this.refs.scroll.scrollTop = 0;
    }

    render() {
        const {superadmin} = this.props;
        const {language_id, flyout} = this.props.store;

        const {language_switcher, collapsed_tabs, highlighted_tab} = this.state;

        const languages = _.map(flyout.languages, language_id => ({
            label: _.get(this.props.localization.languages, [language_id, 'name']),
            code: _.get(this.props.localization.languages, [language_id, 'code']),
            value: language_id,
        }));

        const TabLink = ScrollLink(props => (
            <span onClick={e => {
                this.setState({collapsed_tabs: _.reject(collapsed_tabs, section => section == props.target)});

                this._highlightSection(props.target);

                props.onClick(e);
            }} className={props.className}>
                {props.children}
            </span>
        ));

        const Tab = props => (
            <TabLink target={props.target} activeClass={styles.active} to={props.target} spy={true} smooth={true} duration={500} container={this.refs.scroll}>{props.children}</TabLink>
        );

        let total_tabs = 3;

        if(_.size(flyout.images) > 0) {
            total_tabs++;
        }

        if(_.size(flyout.files) > 0) {
            total_tabs++;
        }
        
        if(this.props.store.flyout.type == "product" && _.size(flyout.parts) > 0) {
            total_tabs++
        }

        if(flyout.price_from || flyout.price_to || flyout.price_date || _.size(getLocalized(flyout.price_note, language_id)) > 0 || flyout.discontinued) {
            total_tabs++;
        }

        const initialAttributes = {};

        _.each(flyout.attribute_groups, group => {
            const attributes = _.reject(group.attributes, attribute => _.size(_.reject(attribute.values, value => _.size(value.value) === 0 || !value.in_use)) === 0);

            _.each(attributes, attribute => {
                const values = _.reject(attribute.values, value => _.size(value.value) === 0 || !value.in_use);

                initialAttributes[`field-${attribute.id}`] = _.get(values, '0.id');
            });
        });

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <div>
                            <strong>{'P-' + _.padStart(flyout.id, 4, "0")}:</strong>
                            <span>{flyout.model} by {flyout.brand}</span>
                        </div>
                        <Tooltip text={__('products.flyout.read-only.close-product-flyout')}>
                            <CloseBigIcon className={styles.close} onClick={() => setTimeout(() => {
                                setTableParams('superadmin_products', {
                                clicked_row: undefined
                            })}, 100)}/>
                        </Tooltip>
                    </div>
                    <div className={styles.actions}>
                        {toBoolean(flyout.public) && superadmin.publish_products && <Tooltip text={__('products.flyout.tooltip.unpublish-product-part')} key={_.uniqueId()}>
                            <LockOpenedIcon onClick={(e) => {
                                e.stopPropagation();

                                unpublishProducts([flyout.id]).then(() => {
                                    readProduct(flyout.id).then(({response}) => {
                                        setTableParams('superadmin_products', {
                                            flyout: response.data,
                                            actioned_row: undefined
                                        });
                                    });

                                    listProducts();

                                    setNotification(__('products.flyout.notifications.selected-product-part-unpublished'));
                                });
                            }} />
                        </Tooltip>}
                        {toBoolean(!flyout.public) && superadmin.publish_products && <Tooltip text={__('products.flyout.tooltip.publish-product-part')} key={_.uniqueId()}>
                            <LockClosedIcon onClick={(e) => {
                                e.stopPropagation();

                                publishProducts([flyout.id]).then(() => {
                                    readProduct(flyout.id).then(({response}) => {
                                        setTableParams('superadmin_products', {
                                            flyout: response.data,
                                            actioned_row: undefined
                                        });
                                    });

                                    listProducts();

                                    setNotification(__('products.flyout.notifications.selected-product-part-published'));
                                });
                            }} />
                        </Tooltip>}
                    </div>
                </div>
                <div className={styles.toolbar}>
                    {!flyout.public ? <Tooltip text="Draft"><div className={styles.draft}>Draft</div></Tooltip> : <Tooltip text="Public"><div className={styles.public}>Public</div></Tooltip>}
                    {flyout.discontinued ? <Tooltip text={__('product.discontinued.products.flyout.tooltip')}><div className={styles.discontinued}>discontinued</div></Tooltip> : ''}
                    {language_id && (
                        <div className={styles.language}>
                            <Select input={{
                                value: language_id,
                                onChange: language_id => setTableParams('superadmin_products', {
                                    language_id
                                }),
                                onBlur: () => this.setState({language_switcher: false})
                            }} meta={{
                                active: language_switcher
                            }} label={__('products.flyout.read-only.tooltip.product-language')} options={languages} left={-75} top={40} noSort dropdown icon={<GlobeIcon className={styles.globeIcon} />} wrapper={(handleOpen, activeItem) => (
                                <Tooltip text={__('products.flyout.read-only.change-product-language')}>
                                    <div className={styles.languageSwitcher} onClick={() => this.setState({language_switcher: true})}>
                                        <GlobeIcon className={styles.icon} />
                                        {_.get(activeItem, 'code')}
                                        <ArrowDownSmallIcon className={styles.arrow} />
                                    </div>
                                </Tooltip>
                            )} />
                        </div>
                    )}
                </div>
                <div className={styles.tabsWrapper}>
                    <ArrowDownMiddleIcon className={classNames(styles.collapseAll, _.size(collapsed_tabs) === total_tabs && styles.collapsed)} onClick={this._toggleCollapseTabs} />
                    <div className={styles.tabs}>
                        <Tab target="overview">{__('products.flyout.tabs.overview')}</Tab>
                        <Tab target="attributes">{__('products.flyout.tabs.attributes')}</Tab>
                        {toBoolean(flyout.price_from || flyout.price_to || flyout.price_date || _.size(getLocalized(flyout.price_note, language_id)) > 0 || flyout.discontinued) && <Tab target="price">{__('products.flyout.tabs.price')}</Tab>}
                        <Tab target="about">{__('products.flyout.tabs.about')}</Tab>
                        {_.size(flyout.images) > 0 && <Tab target="images">{__('products.flyout.tabs.images')}</Tab>}
                        {_.size(flyout.files) > 0 && <Tab target="files">{__('products.flyout.tabs.files')}</Tab>}
                        {this.props.store.flyout.type == "product" && _.size(flyout.parts) > 0 && <Tab target="parts">{__('products.flyout.tabs.parts')}</Tab>}
                    </div>
                </div>
                <div className={styles.content} ref="scroll">
                    <Element name="overview" className={classNames(styles.section, highlighted_tab === 'overview' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('overview')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'overview') && styles.collapsed)} />
                            {__('products.flyout.tabs.overview')}
                        </div>
                        <Overview collapsed={_.includes(collapsed_tabs, 'overview')} />
                    </Element>
                    <Element name="attributes" className={classNames(styles.section, highlighted_tab === 'attributes' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('attributes')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'attributes') && styles.collapsed)} />
                            {__('products.flyout.tabs.attributes')}
                        </div>
                        <AttributesForm collapsed={_.includes(collapsed_tabs, 'attributes')} initialValues={initialAttributes} />
                    </Element>
                    {toBoolean(flyout.price_from || flyout.price_to || flyout.price_date || _.size(getLocalized(flyout.price_note, language_id)) > 0 || flyout.discontinued) && <Element name="price" className={classNames(styles.section, highlighted_tab === 'price' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('price')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'price') && styles.collapsed)} />
                            {__('products.flyout.tabs.price')}
                        </div>
                        <Price collapsed={_.includes(collapsed_tabs, 'price')} />
                    </Element>}
                    <Element name="about" className={classNames(styles.section, highlighted_tab === 'about' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('about')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'about') && styles.collapsed)} />
                            {__('products.flyout.tabs.about')}
                        </div>
                        <About collapsed={_.includes(collapsed_tabs, 'about')} />
                    </Element>
                    {_.size(flyout.images) > 0 && <Element name="images" className={classNames(styles.section, highlighted_tab === 'images' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('images')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'images') && styles.collapsed)} />
                            {__('products.flyout.tabs.images')}
                        </div>
                        <Images collapsed={_.includes(collapsed_tabs, 'images')} />
                    </Element>}
                    {_.size(flyout.files) > 0 && <Element name="files" className={classNames(styles.section, highlighted_tab === 'files' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('files')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'files') && styles.collapsed)} />
                            {__('products.flyout.tabs.files')}
                        </div>
                        <Files collapsed={_.includes(collapsed_tabs, 'files')} />
                    </Element>}
                    {this.props.store.flyout.type == "product" && _.size(flyout.parts) > 0 && <Element name="parts" className={classNames(styles.section, highlighted_tab == 'parts' && styles.highlighted)}>
                        <div className={styles.title} onClick={() => this._toggleCollapseTab('parts')}>
                            <ArrowDownMiddleIcon className={classNames(styles.collapse, _.includes(collapsed_tabs, 'parts') && styles.collapsed)} />
                            {__('products.flyout.tabs.parts')}
                        </div>
                        <Parts collapsed={_.includes(collapsed_tabs, 'parts')} />
                    </Element>}
                </div>
            </div>
        );
    }
}

export default ProductsFlyout;
