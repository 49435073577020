import React, { Component, useCallback } from 'react';
import styles from '../../../styles/components/RoomChooser.scss';
import CloseSmallIcon from '../../../assets/images/close-small-15x15.svg';
import { __ } from '../../../core/utils';
import _ from 'lodash';
import Label from './Label';

class SelectedAreas extends Component {
  render() {
    const { onRemove, selectedNodes } = this.props;

    const nodes = _.values(_.pickBy(selectedNodes, _.identity));
    const count = _.size(nodes);
    return (
      <div className={styles.selectedAreas}>
        <Label text='Selected Areas' count={count} />

        {_.map(nodes, (node, i) => (
          <Room key={`${node.id}-${i}`} node={node} onClick={onRemove} />
        ))}

        {count === 0 && (
          <div className={styles.emptySelection}>No selected areas</div>
        )}
      </div>
    );
  }
}

const Room = ({ onClick, node }) => {
  const handleClick = useCallback(() => {
    onClick(node);
  }, [onClick]);

  return (
    <div className={styles.room} onClick={handleClick}>
      <span className={styles.title}>{node.display_name}</span>
      <CloseSmallIcon className={styles.close} />
    </div>
  );
};

export default SelectedAreas;
