import store from "../../../core/store";

export const listAddressBookCompanies = () => {
	return store.dispatch({
		action: "table.address_book_companies/LIST_ADDRESS_BOOK_COMPANIES",
		method: "GET",
		endpoint: "/address_book_companies",
		main: true
	});
};

export const createAddressBookCompany = (formData) => {
	return store.dispatch({
		action: "table.address_book_companies/CREATE_ADDRESS_BOOK_COMPANY",
		method: "POST",
		endpoint: "/address_book_companies",
		data: formData
	});
};

export const readAddressBookCompany = (address_book_company_id) => {
	return store.dispatch({
		action: "table.address_book_companies/READ_ADDRESS_BOOK_COMPANY",
		method: "GET",
		endpoint: "/address_book_companies/" + address_book_company_id,
		has404: true
	});
};

export const updateAddressBookCompany = (address_book_company_id, formData) => {
	return store.dispatch({
		action: "table.address_book_companies/UPDATE_ADDRESS_BOOK_COMPANY",
		method: "PUT",
		endpoint: "/address_book_companies/" + address_book_company_id,
		data: formData
	});
};

export const listPossibleAddressBookManufacturers = (manufacturer_company_id = null) => {
	return store.dispatch({
		action: "table.address_book_contacts/LIST_POSSIBLE_ADDRESS_BOOK_MANUFACTURERS",
		method: "POST",
		endpoint: "/address_book_contacts/possible_address_book_manufacturers",
		data: {
			manufacturer_company_id
		}
	});
};

export const listPossibleAddressBookSuppliers = (supplier_company_id = null) => {
	return store.dispatch({
		action: "table.address_book_contacts/LIST_POSSIBLE_ADDRESS_BOOK_SUPPLIERS",
		method: "POST",
		endpoint: "/address_book_contacts/possible_address_book_suppliers",
		data: {
			supplier_company_id
		}
	});
};