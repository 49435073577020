import * as React from 'react';
import { Component } from 'react';
import Table from '../../components/Table';
import {
  createSidebar,
  toggleSidebarCollapse,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  getActiveProject,
  getActiveStage,
  getLocalized,
  getURLParam,
  redirect,
  setTableParams,
  setURLParam,
} from '../../core/utils';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import PrintIcon from '../../assets/images/print-16x16.svg';
import * as _ from 'lodash';
import { readProjectGeneral } from '../../redux/actions/projects';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import {
  listCurrentSetSpecifications,
  readCurrentSetSpecification,
} from '../../redux/actions/table/current_set_specifications';
import Tooltip from '../../components/Tooltip';
import styles from '../../styles/views/specifications/CurrentSetSpecifications.scss';
import CurrentSetSpecificationsFlyout from '../../flyouts/current_set_specifications/CurrentSetSpecificationsFlyout';
import OriginProductIcon from '../../assets/images/product-origin-16x16.svg';
import Image from '../../components/Image';
import classNames from 'classnames';
import SpecificationFromProductIcon from '../../assets/images/specification-from-product-16x16.svg';
import SpecificationByArchitectIcon from '../../assets/images/specification-by-architect-16x16.svg';
import GoToDeliveryIcon from '../../assets/images/go-to-delivery-16x16.svg';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    table: state.table['current_set_specifications'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.current_set_specifications/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class CurrentSetSpecifications extends Component {
  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' + stage_id + '/specifications/current?filter.subgroup='
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' + stage_id + '/specifications/current?filter.subgroup='
      )
    );

    createSidebar({
      title: __('submenu.title.specifications'),
      items: [
        {
          title: __('submenu.title.current-set-specifications'),
          link: () =>
            '/stages/' +
            getActiveStage() +
            '/specifications/current?filter.subgroup=',
          active: true,
          expanded: true,
          onArrowClick: () => toggleSidebarCollapse('items.0'),
        },
        {
          title: __('submenu.title.working-set-specifications'),
          link: () => '/stages/' + getActiveStage() + '/specifications/working',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_deliveries',
        },
        {
          title: __('submenu.title.approvals'),
          link: () =>
            '/stages/' + getActiveStage() + '/specification_approvals',
        },
      ],
    });

    setURLParam('filter.status', 'active');
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const setSubgroups = (subgroups) => {
        subgroups = _.trim(
          _.join(
            _.reject(subgroups, (subgroup_id) => subgroup_id == 'n/a'),
            '|'
          ),
          '|'
        );

        setURLParam(
          'filter.subgroup',
          _.isEmpty(subgroups) ? 'n/a' : subgroups
        );
      };

      const groups = _.get(this.props.table.filters, 'subgroup.items');

      const all_ids = _.flattenDeep(
        _.map(groups, (group) => _.keys(group.items))
      );

      const items = _.map(
        _.map(_.sortBy(groups, 'title')),
        (group, group_index) => ({
          ...group,
          onClick: () => setSubgroups(_.keys(group.items)),
          onArrowClick: () =>
            toggleSidebarCollapse('items.0.items.' + group_index),
          active: () => {
            const active_subgroups = _.split(
              getURLParam('filter.subgroup'),
              '|'
            );

            return (
              _.isEmpty(getURLParam('filter.subgroup')) ||
              _.some(_.intersection(active_subgroups, _.keys(group.items)))
            );
          },
          checkbox: {
            status: () => {
              const active_subgroups = _.split(
                getURLParam('filter.subgroup'),
                '|'
              );

              const intersected = _.intersection(
                active_subgroups,
                _.keys(group.items)
              );

              if (_.isEmpty(getURLParam('filter.subgroup'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(_.keys(group.items))) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_subgroups =
                getURLParam('filter.subgroup') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.subgroup'), '|');

              const intersected = _.intersection(
                active_subgroups,
                _.keys(group.items)
              );

              if (_.size(intersected) == _.size(_.keys(group.items))) {
                setSubgroups(
                  _.reject(active_subgroups, (id) =>
                    _.includes(_.keys(group.items), id)
                  )
                );
              } else {
                _.each(
                  _.keys(group.items),
                  (id) =>
                    !_.includes(active_subgroups, id) &&
                    active_subgroups.push(id)
                );

                setSubgroups(active_subgroups);
              }
            },
          },
          items: _.sortBy(
            _.map(group.items, (subgroup, subgroup_id) => ({
              ...subgroup,
              onClick: () => setURLParam('filter.subgroup', subgroup_id),
              active: () => {
                const active_subgroups =
                  getURLParam('filter.subgroup') == ''
                    ? all_ids
                    : _.split(getURLParam('filter.subgroup'), '|');

                return (
                  _.isEmpty(getURLParam('filter.subgroup')) ||
                  _.includes(active_subgroups, subgroup_id)
                );
              },
              checkbox: {
                status: () => {
                  const active_subgroups = _.split(
                    getURLParam('filter.subgroup'),
                    '|'
                  );

                  if (_.isEmpty(getURLParam('filter.subgroup'))) {
                    return 'checked';
                  }

                  return _.includes(active_subgroups, subgroup_id)
                    ? 'checked'
                    : 'unchecked';
                },
                onClick: () => {
                  const active_subgroups = _.split(
                    getURLParam('filter.subgroup'),
                    '|'
                  );

                  if (_.includes(active_subgroups, subgroup_id)) {
                    setSubgroups(
                      _.reject(active_subgroups, (id) => id == subgroup_id)
                    );
                  } else {
                    active_subgroups.push(subgroup_id);

                    setSubgroups(active_subgroups);
                  }
                },
              },
            })),
            'title'
          ),
        })
      );

      updateSidebar('items.0.items', items);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        setTableParams('current_set_specifications', {
          flyout: undefined,
        });
      }

      readCurrentSetSpecification(this.props.table.clicked_row).then(
        ({ response }) => {
          setTableParams('current_set_specifications', {
            flyout: response.data,
          });
        }
      );
    }
  }

  _handleOpacity(value, row) {
    return (row.product && row.product.discontinued) ||
      _.includes(['canceled'], row.status) ? (
      <div className={styles.faded}>{value}</div>
    ) : (
      value
    );
  }

  _openDrawingsTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'drawings',
    });
  }

  _openImagesTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'images',
    });
  }

  _openFilesTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'files',
    });
  }

  _openLinkedSpecificationsTab(id) {
    this.props.actions.setTableParams({
      clicked_row: id,
      showFilters: undefined,
      tab: 'linked-specifications',
    });
  }

  render() {
    const { auth } = this.props;
    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const { project_language } = this.props.table;

    const columns = [
      {
        key: 'code',
        name: __('specifications.current-set.table-title.code'),
        value: (code, row) => {
          const specification_subgroup = _.get(specification_subgroups, [
            row.specification_subgroup_id,
          ]);
          const specification_group = _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          );

          return this._handleOpacity(
            getLocalized(_.get(specification_group, 'code'), project_language) +
              _.get(specification_subgroup, 'code') +
              _.padStart(code, 2, '0'),
            row
          );
        },
        required: true,
        width: 80,
      },
      {
        key: 'custom',
        name: __('specifications.current-set.table-title.type'),
        value: (custom, row) =>
          this._handleOpacity(
            row.product ? (
              <Tooltip
                text={__(
                  'specifications.current-set.specification-created-from-product'
                )}
              >
                <SpecificationFromProductIcon className={styles.type} />
              </Tooltip>
            ) : (
              <Tooltip
                text={__('specifications.current-set.flyout.specification')}
              >
                <SpecificationByArchitectIcon className={styles.type} />
              </Tooltip>
            ),
            row
          ),
        sortable: false,
        width: 50,
      },
      {
        key: 'thumb',
        name: __('specifications.current-set.table-title.thumbnail'),
        value: (created_by, row) =>
          this._handleOpacity(
            row.thumbnail_url ? (
              <Tooltip
                placement='right'
                text={
                  <Image
                    className={styles.thumbPreview}
                    src={row.thumbnail_url}
                  />
                }
                html={true}
                dark={true}
              >
                <Image className={styles.thumb} src={row.thumbnail_url} />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'description',
        name: __('specifications.current-set.table-title.description'),
        required: true,
        value: (value, row) => {
          const description = getLocalized(value, project_language);
          return this._handleOpacity(
            description ? (
              <Tooltip text={description}>{description}</Tooltip>
            ) : (
              <TableNA />
            ),
            row
          );
        },
      },
      {
        key: 'model',
        name: __('specifications.current-set.table-title.model'),
        default: true,
        value: (value, row) => this._handleOpacity(value, row),
        width: 160,
      },
      {
        key: 'brand',
        name: __('specifications.current-set.table-title.manufacturer'),
        value: (brand, row) =>
          this._handleOpacity(
            row.product ? brand : row.manufacturer_company,
            row
          ),
        width: 160,
      },
      {
        key: 'number',
        name: __('specifications.current-set.table-title.revision'),
        value: (number, row) => this._handleOpacity('R' + number, row),
        sortable: false,
        width: 60,
      },
      {
        key: 'product',
        name: __('specifications.current-set.table-title.product-in-catalog'),
        value: (product, row) =>
          this._handleOpacity(
            product ? (
              <Tooltip
                text={__(
                  'specifications.current-set.flyout.view-product-in-catalog'
                )}
              >
                <OriginProductIcon
                  className={styles.product}
                  onClick={(e) => {
                    e.stopPropagation();

                    redirect('/products?id=' + product.id);
                  }}
                />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        active: false,
        width: 50,
      },
      {
        key: 'created_at',
        name: __('specifications.current-set.table-title.added'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        active: false,
        width: 80,
      },
      {
        key: 'delivery',
        name: __('specifications.current-set.table-title.delivery'),
        value: (value, row) =>
          !_.isEmpty(row.delivery) ? (
            this._handleOpacity(
              <Tooltip
                text={
                  __('specifications.current-set.flyout.go-to-delivery') +
                  ' "' +
                  row.delivery.title +
                  '"'
                }
              >
                <div
                  className={styles.delivery}
                  onClick={(e) => {
                    e.stopPropagation();

                    redirect(
                      '/stages/' +
                        this.props.match.params.stage +
                        '/specification_deliveries/public/' +
                        row.delivery.id
                    );
                  }}
                >
                  <GoToDeliveryIcon />
                </div>
              </Tooltip>,
              row
            )
          ) : (
            <TableNA />
          ),
        active: false,
        width: 50,
      },
      {
        key: 'source',
        name: __('specifications.current-set.table-title.source'),
        sortable: false,
        value: (x, row) => {
          if (row.product) {
            return this._handleOpacity(
              <Tooltip
                text={__(
                  'specifications.current-set-specifications.created-from-catalog-product'
                )}
              >
                <span className={classNames(styles.balloon, styles.catalog)}>
                  CA
                </span>
              </Tooltip>,
              row
            );
          } else if (row.created_by.company_id == auth.company.id) {
            return this._handleOpacity(
              <Tooltip
                text={__(
                  'specifications.current-set-specifications.created-by-your-company'
                )}
              >
                <span className={classNames(styles.balloon, styles.myCompany)}>
                  MY
                </span>
              </Tooltip>,
              row
            );
          } else {
            return this._handleOpacity(
              <Tooltip
                text={__(
                  'specifications.current-set-specifications.created-by-your-partners-company'
                )}
              >
                <span
                  className={classNames(styles.balloon, styles.partnerCompany)}
                >
                  PA
                </span>
              </Tooltip>,
              row
            );
          }
        },
        active: false,
        width: 50,
      },
      {
        key: 'created_by',
        name: __('specifications.current-set.table-title.added-by'),
        value: (created_by, row) =>
          this._handleOpacity(
            <Avatar
              avatar_url={created_by.avatar_url}
              name={created_by.fullname}
              active={created_by.active}
            />,
            row
          ),
        width: 50,
      },
      {
        key: 'drawings',
        name: __('table.columns.drawings'),
        width: 30,
        active: false,
        value: (drawings, row) =>
          drawings == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openDrawingsTab(row.id)}
            >
              {drawings}
              <Tooltip
                text={__('views.current-set-specifications.goto-drawings')}
              >
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'images',
        name: __('table.columns.images'),
        width: 30,
        active: false,
        value: (images, row) =>
          images == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openImagesTab(row.id)}
            >
              {images}
              <Tooltip
                text={__('views.current-set-specifications.goto-images')}
              >
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'files',
        name: __('table.columns.files'),
        width: 30,
        active: false,
        value: (files, row) =>
          files == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openFilesTab(row.id)}
            >
              {files}
              <Tooltip text={__('views.current-set-specifications.goto-files')}>
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
      {
        key: 'linked_specifications_count',
        name: __('table.columns.linked_specifications'),
        width: 30,
        active: false,
        value: (linked_specifications_count, row) =>
          linked_specifications_count == 0 ? (
            0
          ) : (
            <div
              className={styles.goto}
              onClick={() => this._openLinkedSpecificationsTab(row.id)}
            >
              {linked_specifications_count}
              <Tooltip
                text={__(
                  'views.current-set-specifications.goto-linked_specifications'
                )}
              >
                <ArrowDoubleRightSmallIcon />
              </Tooltip>
            </div>
          ),
      },
    ];

    const groupActions = (specification_ids) => [
      // <Tooltip text={__("specifications.current-set-specifications.print-selected-specifications")}>
      // 	<PrintIcon
      // 		key={_.uniqueId()}
      // 		onClick={(e) => {
      // 			e.stopPropagation();
      // 			setTableParams("current_set_specifications", {
      // 				print_specifications_wizard: specification_ids
      // 			});
      // 		}}
      // 	/>
      // </Tooltip>
    ];

    const singleActions = (specification_id) => [
      <Tooltip
        text={__(
          'specifications.current-set-specifications.print-specification'
        )}
      >
        <PrintIcon
          key={_.uniqueId()}
          onClick={(e) => {
            e.stopPropagation();

            setTableParams('current_set_specifications', {
              print_specifications_wizard: 'print_selected',
              specification_ids: [specification_id],
            });
          }}
        />
      </Tooltip>,
      !_.isEmpty(
        _.get(
          _.find(this.props.table.data, { id: specification_id }),
          'product'
        )
      ) && (
        <Tooltip
          text={__(
            'specifications.current-set-specifications.view-product-in-catalog'
          )}
        >
          <OriginProductIcon
            key={_.uniqueId()}
            onClick={(e) => {
              e.stopPropagation();

              redirect(
                '/products?id=' +
                  _.get(
                    _.find(this.props.table.data, { id: specification_id }),
                    'product.id'
                  )
              );
            }}
          />
        </Tooltip>
      ),
    ];

    const viewActions = [
      // <Tooltip text="Settings"><SettingsIcon
      //     key={_.uniqueId()}
      //     onClick={e => {
      //         e.stopPropagation();
      //
      //         alert('In construction :)');
      //     }}
      // /></Tooltip>,
      <Tooltip
        key={_.uniqueId()}
        text={__(
          'specifications.current-set-specifications.print-all-specifications'
        )}
      >
        <PrintIcon
          onClick={(e) => {
            e.stopPropagation();

            const { selected_rows } = this.props.table;

            setTableParams('current_set_specifications', {
              specification_ids: selected_rows,
              print_specifications_wizard:
                selected_rows.length > 0 ? 'print_selected' : 'print_all',
            });
          }}
        />
      </Tooltip>,
    ];

    const gridOptions = {
      section: __('submenu.title.current-set-specifications'),
      image: (id, row) => row.thumbnail_url,
      title: (row) => {
        const specification_subgroup = _.get(specification_subgroups, [
          row.specification_subgroup_id,
        ]);
        const specification_group = _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id')
        );

        return (
          _.get(
            specification_group,
            ['code', project_language],
            _.get(specification_group, ['code', 1])
          ) +
          _.get(specification_subgroup, 'code') +
          _.padStart(row.code, 2, '0')
        );
      },
      rightTitle: (row) => 'R' + row.number,
      subTitle: (row) => {
        return _.filter(
          [_.get(row.description, project_language), row.model, row.brand],
          _.identity
        ).join(' / ');
      },
      content: (row) => [],
    };

    const preview = (
      <CurrentSetSpecificationsFlyout
        readAction={(specification_id) => {
          return new Promise((resolve) => {
            readCurrentSetSpecification(
              getURLParam('id') || specification_id
            ).then(({ response }) => {
              setTableParams('current_set_specifications', {
                flyout: response.data,
              });

              resolve(response);
            });
          });
        }}
      />
    );

    const filtersSort = (filter, value, label) => {
      switch (filter) {
        case 'creator':
        case 'drawings':
        case 'updater':
        case 'tag':
          return value;

        case 'delivery':
          return label === __('specifications.filters.delivery.unassigned')
            ? -1
            : label.toLowerCase();
      }
    };

    return (
      <Table
        filtersSort={filtersSort}
        listAction={() =>
          listCurrentSetSpecifications(this.props.match.params.stage)
        }
        name='current_set_specifications'
        title={__('submenu.title.current-set-specifications')}
        columns={columns}
        preview={this.props.table.flyout ? preview : []}
        gridOptions={gridOptions}
        viewActions={viewActions}
        groupActions={groupActions}
        singleActions={singleActions}
        onLoad={() => {
          listActiveModules().then(() => {
            readProjectGeneral(getActiveProject()).then(({ response }) => {
              setTableParams('current_set_specifications', {
                project_language: response.data.main_language,
              });
            });
          });
        }}
      />
    );
  }
}

export default CurrentSetSpecifications;
