import store from "../../../core/store";
import { backendURL } from "../../../core/utils";
import { downloadFile } from "../general/downloader";

export const listCurrentSetPlans = (stage_id) => {
	return store.dispatch({
		action: "table.current_set_plans/LIST_CURRENT_SET_PLANS",
		method: "GET",
		endpoint: "/stages/" + stage_id + "/plans/current",
		main: true
	});
};

export const readPlanRevision = (plan_revision_id) => {
	return store.dispatch({
		action: "table.current_set_plans/READ_PLAN_REVISION",
		method: "GET",
		endpoint: "/plans/revisions/" + plan_revision_id,
		has404: true
	});
};

export const readCurrentSetPlan = (plan_id) => {
	return store.dispatch({
		action: "table.current_set_plans/READ_CURRENT_SET_PLAN",
		method: "GET",
		endpoint: "/plans/current/" + plan_id,
		has404: true
	});
};

export const downloadLastRevision = (plan_id) => {
	return downloadFile(backendURL + "/plans/" + plan_id + "/download_last_revision", [], "POST");
};

export const downloadPlanRevision = (plan_revision_id) => {
	return downloadFile(backendURL + "/plans/revisions/" + plan_revision_id + "/download", [], "POST");
};

export const downloadPlanFile = (plan_id, attachment_id) => {
	return downloadFile(backendURL + "/plans/" + plan_id + "/files/" + attachment_id, [], "POST");
};
