import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/Attributes.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import { getLocalized, mapStateToProps } from '../../core/utils';
import AttributesForm from '../../forms/current_set_specifications/AttributesForm';
import Tooltip from '../../components/Tooltip';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';

@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
}))
@subscribe(Localization, 'localization')
class Attributes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed_groups: [],
    };
  }

  @autobind
  _toggleCollapseGroup(group) {
    this.setState({
      collapsed_groups: _.xor(this.state.collapsed_groups, [group]),
    });
  }

  render() {
    const { collapsed, store } = this.props;
    const { flyout, language_id } = store;
    const { collapsed_groups } = this.state;

    const attribute_groups = _.reject(
      _.sortBy(flyout.attribute_groups, 'priority'),
      (group) => {
        return _.isEmpty(
          _.filter(group.attributes, (attribute) => {
            return (
              attribute.value !== '' &&
              attribute.value !== null &&
              !_.isEmpty(getLocalized(attribute.value, language_id))
            );
          })
        );
      }
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.attributes}>
          {_.map(attribute_groups, (group) => (
            <div className={styles.group} key={group.id}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseGroup(group.id)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    !_.includes(collapsed_groups, group.id) && styles.collapsed
                  )}
                />
                {getLocalized(group.name, language_id)}
              </div>
              <AttributesForm
                expanded={!_.includes(collapsed_groups, group.id)}
                form={'specifications.attributes.group' + group.id}
                groupId={group.id}
                initialValues={_.transform(
                  group.attributes,
                  (attributes, attribute) => {
                    const type = attribute.template_field_id
                      ? _.get(
                          this.props.localization.product_attribute_fields,
                          [attribute.template_field_id, 'type']
                        )
                      : attribute.type;

                    // for some reason, when redux receives a id for initial value key
                    // it registers as many fields as big key is, i.e. if initial value
                    // key was 2704, there would be 2704 registered fields for that form
                    // with 1 - 2703 being just empty value.
                    const fieldName = `attr-${attribute.id}`;
                    if (type == 'string') {
                      attributes[fieldName] =
                        _.size(attribute.value) > 0 ? attribute.value : {};
                    } else {
                      attributes[fieldName] = attribute.value;
                    }

                    return attributes;
                  },
                  {}
                )}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Attributes;
