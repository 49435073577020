import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/containers/TopBar.scss';
import HamburgerIcon from '../assets/images/hamburger-15x15.svg';
import classNames from 'classnames';
import { connect } from 'react-redux';
import NotificationsOffIcon from '../assets/images/notifications-off-15x15.svg';
import Image from '../components/Image';
import { withRouter } from 'react-router';
import Dropdown from '../components/Dropdown';
import UserMaleIcon from '../assets/images/user-male-15x15.svg';
import HomeIcon from '../assets/images/home-15x15.svg';
import LogoutIcon from '../assets/images/logout-15x15.svg';
import AvatarImage from '../assets/images/avatar.png';
import {
  __,
  ___,
  getLocalizedListValue,
  redirect,
  setActiveProject,
  setActiveStage,
} from '../core/utils';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import { toggleSidebar } from '../redux/actions/general/sidebar';
import { hideDropdown } from '../redux/actions/general/dropdown';
import SoftArrowDownIcon from '../assets/images/soft-arrow-down-13x15.svg';
import * as _ from 'lodash';
import ProjectIcon from '../assets/images/project-15x15.svg';
import StageIcon from '../assets/images/stage-15x15.svg';
import PlusMiddleIcon from '../assets/images/plus-middle-15x15.svg';
import Tooltip from '../components/Tooltip';
import { logout } from '../redux/actions/auth';
import { setNotification } from '../redux/actions/general/notification';
import SearchIcon from '../assets/images/search-15x15.svg';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';

const mapStateToProps = (state) => {
  return {
    sidebar: state.general.sidebar,
    search: state.general.search,
    plus: state.general.plus,
    active_modules: state.general.active_modules,
    auth: state.auth,
  };
};

@withRouter
@subscribe(Localization, 'localization')
@connect(mapStateToProps)
class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_query: '',
      stage_query: '',
    };
  }

  render() {
    const { active_modules } = this.props;
    const enable_hamburger = this.props.match.path != '/';

    const { project_query, stage_query } = this.state;

    const { items, project_onClick, stage_onClick } = active_modules;

    const filtered_projects = _.orderBy(
      _.isEmpty(project_query)
        ? _.reject(items, ['active', true])
        : _.filter(items, (project) => {
            return (
              _.includes(
                [project.code, project.name].join(': ').toLowerCase(),
                project_query.toLowerCase()
              ) && project.active == false
            );
          }),
      ['used_at'],
      ['desc']
    );

    const stages = _.get(_.find(items, ['active', true]), 'stages');

    const filtered_stages = _.orderBy(
      _.isEmpty(stage_query)
        ? _.reject(stages, ['active', true])
        : _.filter(stages, (stage) => {
            return (
              _.includes(
                [_.padStart(stage.order, 2, '0'), stage.name]
                  .join(': ')
                  .toLowerCase(),
                stage_query.toLowerCase()
              ) && stage.active == false
            );
          }),
      ['order'],
      ['asc']
    );

    return (
      <div className={styles.wrapper}>
        <div>
          {enable_hamburger && (
            <Tooltip text={__('topbar.tooltip.hamburger')}>
              <div
                className={classNames(
                  styles.hamburger,
                  this.props.sidebar.hidden && styles.collapsed
                )}
                onClick={toggleSidebar}
              >
                <HamburgerIcon />
              </div>
            </Tooltip>
          )}
          {(project_onClick || stage_onClick) && (
            <div className={styles.sections}>
              {!_.isEmpty(items) && project_onClick && (
                <Dropdown
                  leftStyled
                  top={34}
                  left={0}
                  name='active_project'
                  header={
                    <div className={styles.activeProjectHeader}>
                      <ProjectIcon />
                      {__('topbar.active-project.project-list')}
                    </div>
                  }
                  content={
                    <div className={styles.activeProjectContent}>
                      <div className={styles.section}>
                        <div className={styles.title}>
                          {__('topbar.project-chooser.active-project')}
                        </div>
                        <Tooltip
                          placement='right'
                          text={[
                            _.get(_.find(items, ['active', true]), 'code'),
                            _.get(_.find(items, ['active', true]), 'name'),
                          ].join(': ')}
                        >
                          <div className={styles.activeItem}>
                            <span>
                              {[
                                _.get(_.find(items, ['active', true]), 'code'),
                                _.get(_.find(items, ['active', true]), 'name'),
                              ].join(': ')}
                            </span>
                            <div className={styles.dot} />
                          </div>
                        </Tooltip>
                        <div className={styles.title}>
                          {__('topbar.project-chooser.all-projects')}
                        </div>
                        <div className={styles.search}>
                          <SearchIcon className={styles.searchIcon} />
                          <input
                            ref='project_search'
                            type='text'
                            value={project_query}
                            autoFocus={true}
                            placeholder={__('topbar.active-project.search')}
                            onChange={(e) =>
                              this.setState({ project_query: e.target.value })
                            }
                          />
                          {!_.isEmpty(project_query) && (
                            <CloseSmallIcon
                              className={styles.close}
                              onClick={() => {
                                this.setState({ project_query: '' });

                                this.refs.project_search.focus();
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.items}>
                          {_.map(filtered_projects, (project) => (
                            <Tooltip
                              key={project.id}
                              placement='right'
                              text={[project.code, project.name].join(': ')}
                            >
                              <div
                                onClick={() => {
                                  project_onClick({
                                    project_id: project.id,
                                    stage_id: _.get(
                                      _.find(project.stages, ['active', true]),
                                      'id'
                                    ),
                                  });

                                  setActiveProject(project.id);
                                  setActiveStage(
                                    _.get(
                                      _.find(project.stages, ['active', true]),
                                      'id'
                                    )
                                  );

                                  hideDropdown();
                                }}
                                className={styles.value}
                              >
                                <span>
                                  {[project.code, project.name].join(': ')}
                                </span>
                                <div className={styles.dot} />
                              </div>
                            </Tooltip>
                          ))}
                          {_.isEmpty(filtered_projects) && (
                            <div className={styles.noResults}>
                              No projects found.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                >
                  <Tooltip text={__('topbar.tooltip.active-project')}>
                    <div
                      className={styles.project}
                      onClick={() => this.setState({ project_query: '' })}
                    >
                      <ProjectIcon className={styles.icon} />{' '}
                      <span>
                        <strong>
                          {_.get(_.find(items, ['active', true]), 'code')}:
                        </strong>{' '}
                        {_.get(_.find(items, ['active', true]), 'name')}
                      </span>
                      <SoftArrowDownIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                </Dropdown>
              )}
              {!_.isEmpty(stages) && stage_onClick && (
                <Dropdown
                  leftStyled
                  top={34}
                  left={0}
                  name='active_stage'
                  header={
                    <div className={styles.activeStageHeader}>
                      <StageIcon />
                      {__('topbar.active-stage.stage-list')}
                    </div>
                  }
                  content={
                    <div className={styles.activeStageContent}>
                      <div className={styles.section}>
                        <div className={styles.title}>
                          {__('topbar.project-chooser.active-stage')}
                        </div>
                        <Tooltip
                          placement='right'
                          text={[
                            _.padStart(
                              _.get(_.find(stages, ['active', true]), 'order'),
                              2,
                              '0'
                            ),
                            _.get(_.find(stages, ['active', true]), 'name'),
                          ].join(': ')}
                        >
                          <div className={styles.activeItem}>
                            <span>
                              {[
                                _.padStart(
                                  _.get(
                                    _.find(stages, ['active', true]),
                                    'order'
                                  ),
                                  2,
                                  '0'
                                ),
                                _.get(_.find(stages, ['active', true]), 'name'),
                              ].join(': ')}
                            </span>
                            <div className={styles.dot} />
                          </div>
                        </Tooltip>
                        <div className={styles.title}>
                          {__('topbar.project-chooser.all-stages')}
                        </div>
                        <div className={styles.search}>
                          <SearchIcon className={styles.searchIcon} />
                          <input
                            ref='stage_search'
                            type='text'
                            value={stage_query}
                            autoFocus={true}
                            placeholder={__('topbar.active-stage.search')}
                            onChange={(e) =>
                              this.setState({ stage_query: e.target.value })
                            }
                          />
                          {!_.isEmpty(stage_query) && (
                            <CloseSmallIcon
                              className={styles.close}
                              onClick={() => {
                                this.setState({ stage_query: '' });

                                this.refs.stage_search.focus();
                              }}
                            />
                          )}
                        </div>
                        <div className={styles.items}>
                          {_.map(filtered_stages, (stage) => (
                            <Tooltip
                              placement='right'
                              key={stage.id}
                              text={[
                                _.padStart(stage.order, 2, '0'),
                                stage.name,
                              ].join(': ')}
                            >
                              <div
                                onClick={() => {
                                  stage_onClick({
                                    project_id: _.find(items, (project) =>
                                      _.find(project.stages, ['id', stage.id])
                                    ).id,
                                    stage_id: stage.id,
                                  });

                                  setActiveProject(
                                    _.find(items, (project) =>
                                      _.find(project.stages, ['id', stage.id])
                                    ).id
                                  );
                                  setActiveStage(stage.id);

                                  hideDropdown();
                                }}
                                className={styles.value}
                              >
                                <span>
                                  {[
                                    _.padStart(stage.order, 2, '0'),
                                    stage.name,
                                  ].join(': ')}
                                </span>
                                <div className={styles.dot} />
                              </div>
                            </Tooltip>
                          ))}
                          {_.isEmpty(filtered_stages) && (
                            <div className={styles.noResults}>
                              {__(
                                'project-settings.topbar.stages.no-stages-found'
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                >
                  <Tooltip text={__('topbar.tooltip.active-stage')}>
                    <div
                      className={styles.stage}
                      onClick={() => this.setState({ stage_query: '' })}
                    >
                      <StageIcon className={styles.icon} />{' '}
                      <span>
                        <strong>
                          {_.padStart(
                            _.find(stages, ['active', true]).order,
                            2,
                            '0'
                          )}
                          :
                        </strong>{' '}
                        {_.find(stages, ['active', true]).name}
                      </span>
                      <SoftArrowDownIcon className={styles.arrow} />
                    </div>
                  </Tooltip>
                </Dropdown>
              )}
            </div>
          )}
        </div>
        <div>
          {!_.isEmpty(this.props.plus.actions) &&
            (_.size(this.props.plus.actions) <= 1 ? (
              <div
                className={styles.plus}
                onClick={_.get(this.props, 'plus.actions.0.onClick')}
              >
                <strong>+</strong>
                <span>{_.get(this.props, 'plus.actions.0.title')}</span>
              </div>
            ) : (
              <Dropdown
                top={40}
                right={0}
                name='plus_actions'
                header={
                  <div className={styles.plusActionsHeader}>
                    {__('topbar.button.add-new')}
                  </div>
                }
                content={
                  <div className={styles.plusActionsContent}>
                    {_.map(this.props.plus.actions, (action, i) => (
                      <div
                        className={classNames(
                          styles.item,
                          action?.disabled && styles.disabled
                        )}
                        key={i}
                        onClick={() => {
                          !action?.disabled && action.onClick();
                          hideDropdown();
                        }}
                      >
                        {action.icon}
                        <span>{action.name}</span>
                      </div>
                    ))}
                  </div>
                }
              >
                <div className={styles.plus}>
                  <strong>+</strong>
                  <span>{__('topbar.button.add-new')}</span>
                </div>
              </Dropdown>
            ))}
          {!_.isEmpty(this.props.plus.actions) && (
            <div className={styles.separator} />
          )}
          <Tooltip placement='left' text={__('topbar.tooltip.notifications')}>
            <div
              onClick={() => redirect('/profile/notifications')}
              className={classNames(
                styles.notifications,
                this.props.auth.unread_notifications > 0 &&
                  styles.hasNotifications
              )}
            >
              <NotificationsOffIcon />
              {this.props.auth.unread_notifications > 0 && (
                <span>{this.props.auth.unread_notifications}</span>
              )}
            </div>
          </Tooltip>
          <Dropdown
            top={40}
            right={5}
            name='profile'
            header={
              <div className={styles.avatarDropdownHeader}>
                <Image src={this.props.auth.avatar_url} default={AvatarImage} />
                <div className={styles.details}>
                  <div className={styles.name}>
                    {this.props.auth.title &&
                      _.upperFirst(this.props.auth.title) + ' '}{' '}
                    {this.props.auth.firstname} {this.props.auth.lastname}
                  </div>
                  <div className={styles.info}>
                    {!_.isEmpty(this.props.auth.disciplines) &&
                      _.map(this.props.auth.disciplines, (discipline) =>
                        getLocalizedListValue(
                          this.props.localization.disciplines,
                          discipline,
                          'profession'
                        )
                      ).join(', ') + ' · '}
                    {_.get(this.props.auth.company, 'name')}
                  </div>
                </div>
              </div>
            }
            content={
              <div className={styles.avatarDropdownContent}>
                <div
                  className={styles.item}
                  onClick={() => hideDropdown() && redirect('/profile/details')}
                >
                  <UserMaleIcon />
                  <span>{__('topbar.account.profile-settings')}</span>
                </div>
                {_.includes(
                  ['administrator', 'owner'],
                  this.props.auth.role
                ) && (
                  <div
                    className={styles.item}
                    onClick={() =>
                      hideDropdown() && redirect('/company/details')
                    }
                  >
                    <HomeIcon />
                    <span>{__('topbar.account.company-settings')}</span>
                  </div>
                )}
                <div
                  className={styles.item}
                  onClick={() => {
                    hideDropdown();
                    logout();

                    setNotification(
                      'Please wait while we are logging you out.'
                    );
                  }}
                >
                  <LogoutIcon />
                  <span>{__('topbar.account.sign-out')}</span>
                </div>
              </div>
            }
          >
            <div className={styles.avatar}>
              <Tooltip
                placement='left'
                text={__('topbar.tooltip.company-settings')}
              >
                <Image src={this.props.auth.avatar_url} default={AvatarImage} />
              </Tooltip>
            </div>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default TopBar;
