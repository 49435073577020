import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  createSidebar,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  guessPaperSize,
  hasURLParam,
  ptToMm,
  redirect,
  setTableParams,
  setURLParam,
} from '../../core/utils';
import Table from '../../components/Table';
import {
  listWorkingSetPlans,
  readWorkingSetPlan,
  deleteWorkingSetPlan,
} from '../../redux/actions/table/working_set_plans';
import DeliveryIcon from '../../assets/images/delivery-15x15.svg';
import { setNotification } from '../../redux/actions/general/notification';
import styles from '../../styles/views/plans/WorkingSetPlans.scss';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import WorkingSetPlansFlyout from '../../flyouts/working_set_plans/WorkingSetPlansFlyout';
import { leaveChannel, listenPlanProcessed } from '../../core/sockets';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import Tooltip from '../../components/Tooltip';
import ScaleIcon from '../../assets/images/scale-16x16.svg';
import TagIcon from '../../assets/images/tag-16x16.svg';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import Image from '../../components/Image';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';

const mapStateToProps = (state) => {
  return {
    table: state.table['working_set_plans'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.working_set_plans/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class WorkingSetPlans extends Component {
  componentWillUnmount() {
    leaveChannel('working_set_plans.' + this.props.match.params.stage);
  }

  componentDidMount() {
    listenPlanProcessed(this.props.match.params.stage, (plan_id) => {
      plan_id == this.props.table.clicked_row &&
        listWorkingSetPlans(this.props.match.params.stage) &&
        readWorkingSetPlan(plan_id).then(({ response }) => {
          this.setState({ preview: response.data });
        });
    });

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/plans/working')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/plans/working')
    );

    createSidebar({
      title: __('submenu.title.plans'),
      items: [
        {
          title: __('submenu.title.current-set'),
          link: () => '/stages/' + getActiveStage() + '/plans/current',
        },
        {
          title: __('submenu.title.working-set'),
          link: () =>
            '/stages/' + getActiveStage() + '/plans/working?filter.team=',
          active: true,
        },
        {
          title: __('submenu.title.uploads'),
          link: () => '/stages/' + getActiveStage() + '/plans/uploads',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () => '/stages/' + getActiveStage() + '/deliveries',
        },
        {
          title: __('submenu.title.approvals'),
          link: () => '/stages/' + getActiveStage() + '/approvals',
        },
      ],
    });

    if (!hasURLParam('filter.team')) {
      setURLParam('filter.team', '');
    }

    setURLParam('filter.status', 1);
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const items = _.get(this.props.table.filters, 'team.items');

      const all_ids = _.keys(items);

      updateSidebar('items.1.items', [
        {
          title: __('plans.working-set.all-teams'),
          onClick: () => setURLParam('filter.team', ''),
          active: () => {
            const active_teams = _.split(getURLParam('filter.team'), '|');

            const intersected = _.intersection(active_teams, _.keys(items));

            return (
              _.isEmpty(getURLParam('filter.team')) ||
              _.size(intersected) == _.size(items)
            );
          },
          checkbox: {
            status: () => {
              const active_teams = _.split(getURLParam('filter.team'), '|');

              const intersected = _.intersection(active_teams, _.keys(items));

              if (_.isEmpty(getURLParam('filter.team'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(items)) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_teams = _.split(getURLParam('filter.team'), '|');

              const intersected = _.intersection(active_teams, _.keys(items));

              if (
                _.size(intersected) == _.size(items) ||
                _.isEmpty(getURLParam('filter.team'))
              ) {
                setURLParam('filter.team', 'n/a');
              } else {
                setURLParam('filter.team', '');
              }
            },
          },
        },
        ..._.map(items, (team, team_id) => ({
          title: team,
          onClick: () => setURLParam('filter.team', team_id),
          active: () => {
            const active_teams = _.split(getURLParam('filter.team'), '|');

            return (
              _.isEmpty(getURLParam('filter.team')) ||
              _.includes(active_teams, team_id)
            );
          },
          checkbox: {
            status: () => {
              const active_teams = _.split(getURLParam('filter.team'), '|');

              if (_.isEmpty(getURLParam('filter.team'))) {
                return 'checked';
              }

              return _.includes(active_teams, team_id)
                ? 'checked'
                : 'unchecked';
            },
            onClick: () => {
              const setTeams = (teams) => {
                teams = _.trim(
                  _.join(
                    _.reject(teams, (team) => team == 'n/a'),
                    '|'
                  ),
                  '|'
                );

                setURLParam('filter.team', _.isEmpty(teams) ? 'n/a' : teams);
              };

              const active_teams =
                getURLParam('filter.team') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.team'), '|');

              if (_.includes(active_teams, team_id)) {
                setTeams(_.reject(active_teams, (row) => row == team_id));
              } else {
                active_teams.push(team_id);

                setTeams(active_teams);
              }
            },
          },
        })),
      ]);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readWorkingSetPlan(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  _handleOpacity(value, row) {
    return row.status == '1' ? (
      value
    ) : (
      <div className={styles.hidden}>{value}</div>
    );
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('plans.table.title.code'),
        default: true,
        width: 200,
        value: (value, row) => (
          <Tooltip text={value}>{this._handleOpacity(value, row)}</Tooltip>
        ),
      },
      {
        key: 'thumb',
        name: __('plans.table.thumbnail'),
        value: (value, row) =>
          this._handleOpacity(
            row.thumbnail_url ? (
              <Tooltip
                placement='right'
                text={
                  <Image
                    className={styles.thumbPreview}
                    src={row.thumbnail_url}
                  />
                }
                html={true}
                dark={true}
              >
                <Image className={styles.thumb} src={row.thumbnail_url} />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'title',
        name: __('plans.table.title.title'),
        required: true,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'delivery',
        name: __('plans.table.title.delivery'),
        value: (value, row) =>
          !_.isEmpty(row.last_version.delivery) &&
          this._handleOpacity(
            <div
              className={styles.delivery}
              onClick={(e) => {
                e.stopPropagation();

                redirect(
                  '/stages/' +
                    this.props.match.params.stage +
                    '/deliveries/my/' +
                    row.last_version.delivery.id
                );
              }}
            >
              {row.last_version.delivery.title} <ArrowDoubleRightSmallIcon />
            </div>,
            row
          ),
        width: 200,
      },
      {
        key: 'discipline',
        name: __('plans.deliveries.columns.disciplines'),
        value: (discipline, row) => (
          <Tooltip
            text={[
              row.discipline.code,
              row.discipline.id == null
                ? row.discipline.description + '*'
                : _.get(this.props.localization.disciplines, [
                    row.discipline.id,
                    'name',
                  ]),
            ].join(': ')}
          >
            {this._handleOpacity(row.discipline.code, row)}
          </Tooltip>
        ),
        width: 60,
      },
      {
        key: 'team',
        name: __('plans.deliveries.columns.team'),
        value: (team, row) => (
          <Tooltip text={team.company}>
            {this._handleOpacity(team.company, row)}
          </Tooltip>
        ),
        width: 120,
      },
      {
        key: 'size',
        name: __('plans.table.title.size'),
        value: (size, row) =>
          row.width == 0 && row.height == 0 ? (
            <TableNA />
          ) : (
            <Tooltip
              text={
                guessPaperSize(ptToMm(row.width), ptToMm(row.height))
                  .description
              }
            >
              {this._handleOpacity(
                guessPaperSize(ptToMm(row.width), ptToMm(row.height)).size,
                row
              )}
            </Tooltip>
          ),
        width: 50,
      },
      {
        key: 'scale',
        name: __('plans.table.title.scale'),
        width: 70,
        fullWidth: true,
        value: (scale, row) =>
          this._handleOpacity(
            _.join([_.toNumber(row.scale1), _.toNumber(row.scale2)], ':'),
            row
          ),
      },
      {
        key: 'last_version',
        name: __('plans.table.title.version'),
        value: (value, row) => this._handleOpacity('V' + value.number, row),
        width: 50,
      },
      {
        key: 'created_at',
        name: __('plans.table.title.added'),
        default: true,
        desc: true,
        value: (value, row) => (
          <Tooltip
            text={
              row.last_version.number > 0
                ? dateTimeFrom(row.last_version.created_at)
                : dateTimeFrom(row.published_at)
            }
          >
            {this._handleOpacity(
              row.last_version.number > 0
                ? dateFrom(row.last_version.created_at)
                : dateFrom(row.published_at),
              row
            )}
          </Tooltip>
        ),
        width: 80,
      },
      {
        key: 'created_by',
        name: __('plans.table.title.added-by'),
        value: (x, row) =>
          this._handleOpacity(
            <Avatar
              avatar_url={row.last_version.created_by.avatar_url}
              name={row.last_version.created_by.fullname}
              active={row.last_version.created_by.active}
            />,
            row
          ),
        width: 50,
      },
    ];

    const singleActions = (plan_id) => [
      <Tooltip
        text={__('plans.tooltip.icon.plan-properties')}
        key={_.uniqueId()}
      >
        <InfoPositiveIcon
          className=''
          onClick={(e) => {
            e.stopPropagation();

            this.props.actions.setTableParams({
              clicked_row: plan_id,
              actioned_row: undefined,
            });
          }}
        />
      </Tooltip>,
      _.get(_.find(this.props.table.data, { id: plan_id }), 'can_deliver') &&
        _.get(_.find(this.props.table.data, { id: plan_id }), 'status') ==
          '1' && (
          <Tooltip
            text={__('plans.tooltip.icon.working-set.plan-deliver')}
            key={_.uniqueId()}
          >
            <DeliveryIcon
              key={_.uniqueId()}
              onClick={(e) => {
                e.stopPropagation();

                if (
                  !_.isEmpty(
                    _.get(
                      _.find(this.props.table.data, { id: plan_id }),
                      'last_version.delivery'
                    )
                  )
                ) {
                  setNotification({
                    text: __(
                      'plans.working-set.notification.already-delivered'
                    ),
                    type: 'warning',
                  });
                } else {
                  this.props.actions.setTableParams({
                    actioned_row: undefined,
                    wizard_active: true,
                    selected_plans: [plan_id],
                  });
                }
              }}
            />
          </Tooltip>
        ),

      _.get(_.find(this.props.table.data, { id: plan_id }), 'can_deliver') &&
        _.get(_.find(this.props.table.data, { id: plan_id }), 'status') ==
          '1' &&
        _.isEmpty(
          _.get(
            _.find(this.props.table.data, { id: plan_id }),
            'last_version.delivery'
          )
        ) && (
          <Tooltip
            text={__('plans.tooltip.icon.working-set.plan-delete')}
            key={_.uniqueId()}
          >
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();

                showAlertbox({
                  color: 'red',
                  title: __('general.alert.are-you-sure'),
                  description: __(
                    'plans.working-set.delete-plan.alert.are-you-sure'
                  ),
                  buttons: [
                    {
                      color: 'lightGray',
                      text: __('general.alert.no-close'),
                      onClick: 'close',
                    },
                    {
                      color: 'gray',
                      text: __('general.alert.yes-delete'),
                      onClick: () => {
                        deleteWorkingSetPlan(plan_id).then(() => {
                          hideAlertbox();

                          listWorkingSetPlans(this.props.match.params.stage);

                          setNotification(
                            __(
                              'plans.working-set.delete-plan.notification.plan-deleted'
                            )
                          );
                        });
                      },
                    },
                  ],
                });
              }}
            />
          </Tooltip>
        ),
    ];

    const groupActions = (plan_ids) => [
      <Tooltip text={__('Multiple edit plan scale')} key={_.uniqueId()}>
        <ScaleIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (plan) => _.includes(plan_ids, plan.id) && !plan.can_deliver
                )
              ) >= 1
            ) {
              //TODO - change 'can_deliver' to 'is_editor'
              setNotification({
                text: __(
                  "You don't have permission over all plans to edit their scale."
                ),
                type: 'warning',
              });
            } else {
              setTableParams('plan_uploads', {
                wizard_multiple_plan_scale_edit: plan_ids,
              });
            }
          }}
        />
      </Tooltip>,
      <Tooltip text={__('Multiple edit plan tags')} key={_.uniqueId()}>
        <TagIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (plan) => _.includes(plan_ids, plan.id) && !plan.can_deliver
                )
              ) >= 1
            ) {
              //TODO - change 'can_deliver' to 'is_editor'
              setNotification({
                text: __(
                  "You don't have permission over all plans to edit their tags."
                ),
                type: 'warning',
              });
            } else {
              setTableParams('plan_uploads', {
                wizard_multiple_plan_tags_edit: plan_ids,
              });
            }
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__('plans.tooltip.icon.working-set.group-deliver')}
        key={_.uniqueId()}
      >
        <DeliveryIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.groupBy(
                  _.filter(this.props.table.data, (plan) =>
                    _.includes(plan_ids, plan.id)
                  ),
                  'team.id'
                )
              ) > 1
            ) {
              setNotification({
                text: __(
                  'plans.working-set.notification.cannot-create-within-teams'
                ),
                type: 'warning',
              });
            } else if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (plan) => _.includes(plan_ids, plan.id) && !plan.can_deliver
                )
              ) >= 1
            ) {
              setNotification({
                text: __('plans.working-set.notification.dont-have-permission'),
                type: 'warning',
              });
            } else if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (plan) => _.includes(plan_ids, plan.id) && plan.status == '0'
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  'You can not create a delivery as one or more plans have been canceled.'
                ),
                type: 'warning',
              });
            } else {
              this.props.actions.setTableParams({
                selected_rows: [],
                wizard_active: true,
                selected_plans: plan_ids,
              });
            }
          }}
        />
      </Tooltip>,
    ];

    const gridOptions = {
      section: 'Plans',
      image: (id, row) => row.thumbnail_url,
      title: (row) =>
        this._handleOpacity(
          row.code + (row.status == '0' ? ' (canceled)' : ''),
          row
        ),
      rightTitle: (row) => 'V' + row.last_version.number,
      subTitle: (row) => row.title,
      content: (row) => [
        <>
          {dateFrom(row.last_version.created_at)} by{' '}
          {row.last_version.created_by.fullname}
        </>,
        (row.width == 0 && row.height == 0
          ? ''
          : guessPaperSize(ptToMm(row.width), ptToMm(row.height)).size +
            ' (' +
            guessPaperSize(ptToMm(row.width), ptToMm(row.height)).description +
            ') | ') +
          _.join([_.toNumber(row.scale1), _.toNumber(row.scale2)], ':'),
      ],
    };

    const preview = (
      <WorkingSetPlansFlyout
        data={_.get(this.state, 'preview', {})}
        readAction={(id) => {
          return new Promise((resolve) => {
            readWorkingSetPlan(getURLParam('id') || id).then(({ response }) => {
              this.setState({ preview: response.data });

              resolve(response);
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listWorkingSetPlans(this.props.match.params.stage)}
        onRowClick={(id, row) =>
          redirect('/plans/versions/' + row.last_version.id)
        }
        name='working_set_plans'
        title={__('submenu.title.working-set')}
        columns={columns}
        preview={get(this.state, 'preview') ? preview : []}
        singleActions={singleActions}
        groupActions={groupActions}
        gridOptions={gridOptions}
        defaultView='grid'
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default WorkingSetPlans;
