import store from "../../../../core/store";

export const listProductSubgroups = () => {
	return store.dispatch({
		action: "table.superadmin_product_subgroups/LIST_PRODUCT_SUBGROUPS",
		method: "GET",
		endpoint: "/superadmin/product_subgroups",
		main: true
	});
};

export const createProductSubgroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_product_subgroups/CREATE_PRODUCT_SUBGROUP",
		method: "POST",
		endpoint: "/superadmin/product_subgroups",
		data: formData
	});
};

export const deleteProductSubgroups = (product_subgroup_ids) => {
	return store.dispatch({
		action: "table.superadmin_product_subgroups/DELETE_PRODUCT_SUBGROUPS",
		method: "DELETE",
		endpoint: "/superadmin/product_subgroups",
		data: {
			product_subgroups: product_subgroup_ids
		}
	});
};

export const readProductSubgroup = (product_subgroup_id) => {
	return store.dispatch({
		action: "table.superadmin_product_subgroups/READ_PRODUCT_SUBGROUP",
		method: "GET",
		endpoint: "/superadmin/product_subgroups/" + product_subgroup_id,
		has404: true
	});
};

export const updateProductSubgroup = (product_subgroup_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_product_subgroups/UPDATE_PRODUCT_SUBGROUP",
		method: "PUT",
		endpoint: "/superadmin/product_subgroups/" + product_subgroup_id,
		data: formData
	});
};