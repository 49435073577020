import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/print_tasks_report_wizard/PrintTasksReportForm.scss";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {__} from "../../core/utils";
import * as _ from "lodash";
import {withRouter} from "react-router";
import Checkbox from "../../components/Checkbox";

const mapStateToProps = state => {
    return {
        values: getFormValues("print_tasks_report_wizard.print_tasks_report")(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hidePrintTasksReportWizard: () => dispatch({
                type: "table.all_tasks/SET_TABLE_PARAMS",
                params: {
                    print_tasks_report_wizard: undefined
                }
            })
        }
    };
};

@reduxForm({
    form: "print_tasks_report_wizard.print_tasks_report",
})
@subscribe(Localization, "localization")
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class PrintTasksReportForm extends Component {
    render() {
        const {values} = this.props;

        const active_columns = _.size(_.filter(_.pick(values, ["column_type", "column_id", "column_title", "column_assignee", "column_created_by", "column_status", "column_priority", "column_start", "column_end"]), column => column == true));

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <div className={styles.title}>{__('tasks.print.title.select-elements')}</div>
                        <div className={styles.options}>
                            <Field name="header" component={Checkbox} label={__('tasks.print.header')} />
                            <Field name="footer" component={Checkbox} label={__('tasks.print.footer')} />
                            <Field name="legend" component={Checkbox} label={__('tasks.print.legend')} />
                            <Field name="index" component={Checkbox} label={__('tasks.print.index')} />
                            <Field name="details" component={Checkbox} label={__('tasks.print.details')} />
                            {values.details == true && <div className={styles.suboptions}>
                                <Field name="description" component={Checkbox} label={__('tasks.print.description')} />
                                <Field name="qr_code" component={Checkbox} label={__('tasks.print.qr-code')} />
                                <Field name="images" component={Checkbox} label={__('tasks.print.image-attachments')} />
                                <Field name="comments" component={Checkbox} label={__('tasks.print.comments')} />
                                <Field name="history" component={Checkbox} label={__('tasks.print.history')} />
                            </div>}
                        </div>
                    </div>
                    <div className={styles.f1}>
                        <div className={styles.title}>{__('tasks.print.title.select-columns')}</div>
                        <div className={styles.options}>
                            <Field disabled={active_columns == 1 && values.column_type} name="column_type" component={Checkbox} label={__('table.columns.task-type')} />
                            <Field disabled={active_columns == 1 && values.column_id} name="column_id" component={Checkbox} label={__('table.columns.task-identifier')} />
                            <Field disabled={active_columns == 1 && values.column_title} name="column_title" component={Checkbox} label={__('table.columns.task-title')} />
                            <Field disabled={active_columns == 1 && values.column_assignee} name="column_assignee" component={Checkbox} label={__('table.columns.assignee')} />
                            <Field disabled={active_columns == 1 && values.column_created_by} name="column_created_by" component={Checkbox} label={__('plans.deliveries.columns.created-by')} />
                            <Field disabled={active_columns == 1 && values.column_status} name="column_status" component={Checkbox} label={__('table.columns.task-status')} />
                            <Field disabled={active_columns == 1 && values.column_priority} name="column_priority" component={Checkbox} label={__('table.columns.task-priority')} />
                            <Field disabled={active_columns == 1 && values.column_start} name="column_start" component={Checkbox} label={__('table.columns.task-start')} />
                            <Field disabled={active_columns == 1 && values.column_end} name="column_end" component={Checkbox} label={__('table.columns.task-end')} />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('tasks.print.button.cancel')} onClick={this.props.actions.hidePrintTasksReportWizard} />
                    <Button lightBlue medium submit right middleText={__('tasks.print.button.print')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default PrintTasksReportForm;
