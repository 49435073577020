import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/products/read_only/Parts.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../../core/utils';
import Tooltip from '../../../components/Tooltip';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../../redux/actions/general/alertbox';
import { setNotification } from '../../../redux/actions/general/notification';
import {
  addOptionalProductPart,
  deleteProductPart,
  readProduct,
} from '../../../redux/actions/table/products';
import TableNA from '../../../components/TableNA';
import { enableAutosave } from '../../../redux/actions/general/autosave';
import { Form, reduxForm } from 'redux-form';
import CheckedIcon from '../../../assets/images/checked-15x15.svg';
import UncheckedIcon from '../../../assets/images/unchecked-15x15.svg';
import Image from '../../../components/Image';
import DefaultImage from '../../../assets/images/default.png';

@reduxForm({
  form: 'products.read_only.parts',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.products,
  application_language_id: state.auth.language_id,
  auth: state.auth,
}))
@subscribe(Localization, 'localization')
class Parts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optional_products_used: this.props.sorted_list
        .filter((item) => item.optional_product_used)
        .map((item) => item.id),
    };
  }

  @autobind
  _deletePart(e, part_id) {
    e.stopPropagation();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('products.parts.are-you-sure-delete-part'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () =>
            deleteProductPart(this.props.store.flyout.id, {
              part_id,
            }).then(() =>
              readProduct(this.props.store.flyout.id).then(({ response }) => {
                hideAlertbox();

                setNotification(__('products.parts.part-deleted'));

                setTableParams('products', {
                  flyout: response.data,
                });
              })
            ),
        },
      ],
    });
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _handlePartsSubmit(part_id) {
    this.setState({
      optional_products_used: this.state.optional_products_used.includes(
        part_id
      )
        ? this.state.optional_products_used.filter((item) => item !== part_id)
        : [...this.state.optional_products_used, part_id],
    });

    addOptionalProductPart(this.props.store.flyout.id, {
      part_id,
    }).then(() =>
      readProduct(this.props.store.flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });
      })
    );
  }

  render() {
    const { collapsed, store, auth, sorted_list } = this.props;
    const { flyout } = store;
    const { optional_products_used } = this.state;

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.isEmpty(sorted_list) ? (
          <div className={styles.empty}>{__('products.parts.no-parts')}</div>
        ) : (
          <>
            <div className={styles.header}>
              <span className={styles.description}>
                {__('products.parts.description')}
              </span>
            </div>
            <Form onSubmit={this._handlePartsSubmit}>
              {_.map(sorted_list, (part, i) => {
                return (
                  <div className={styles.row} key={i}>
                    {optional_products_used.includes(part.id) ? (
                      <CheckedIcon
                        className={styles.checked}
                        onClick={(e) => {
                          e.stopPropagation();

                          this._handlePartsSubmit(part.id);
                        }}
                      />
                    ) : (
                      <UncheckedIcon
                        className={styles.unchecked}
                        onClick={(e) => {
                          e.stopPropagation();

                          this._handlePartsSubmit(part.id);
                        }}
                      />
                    )}

                    <div className={styles.thumbnail}>
                      <Tooltip
                        placement='left'
                        text={
                          <Image
                            default={DefaultImage}
                            className={styles.thumbPreview}
                            src={part.thumb_url}
                          />
                        }
                        html={true}
                        dark={true}
                      >
                        <Image
                          default={DefaultImage}
                          src={part.thumb_url}
                          className={styles.icon}
                        />
                      </Tooltip>
                    </div>

                    <Tooltip
                      placement='left'
                      text={getLocalized(
                        part.description,
                        this.props.application_language_id
                      )}
                    >
                      <div
                        className={classNames(
                          styles.description,
                          auth.company.id == flyout.company_id &&
                            styles.clickable
                        )}
                        onClick={() =>
                          auth.company.id == flyout.company_id &&
                          readProduct(part.id).then(({ response }) => {
                            setTableParams('products', {
                              flyout: response.data,
                            });
                          })
                        }
                      >
                        {_.filter(
                          [
                            part.model,
                            getLocalized(
                              part.description,
                              this.props.application_language_id
                            ),
                          ],
                          _.identity
                        ).join(' / ') || <TableNA />}
                      </div>
                    </Tooltip>
                  </div>
                );
              })}
            </Form>
          </>
        )}
      </div>
    );
  }
}

export default Parts;
