import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/Revisions.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import { __, dateFrom, mapStateToProps } from '../../core/utils';
import TableNA from '../../components/TableNA';
import Tooltip from '../../components/Tooltip';
import WarningIcon from '../../assets/images/warning-16x16.svg';

@mapStateToProps((state) => ({
  flyout: state.table.current_set_specifications.flyout,
}))
class Revisions extends Component {
  render() {
    const { collapsed, flyout } = this.props;

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <div className={styles.header}>
          <span className={styles.no}>
            {__('specifications.current-set.flyout.revisions.code')}
          </span>
          <span className={styles.date}>
            {__('specifications.current-set.flyout.revisions.date')}
          </span>
          <span className={styles.description}>
            {__('specifications.current-set.flyout.revisions.description')}
          </span>
        </div>
        {_.map(flyout.revisions, (revision, i) => {
          const row = (
            <div
              className={classNames(
                styles.row,
                revision.specification_id != flyout.specification_id &&
                  styles.faded
              )}
              key={i}
            >
              <div className={styles.no}>
                R{revision.number}
                {revision.status == 'canceled' && ' (canceled)'}
                {/*{revision.specification_id != flyout.specification_id && <WarningIcon />}*/}
              </div>
              <div className={styles.date}>{dateFrom(revision.created_at)}</div>
              <div className={styles.description}>
                {revision.description || (
                  <span className={styles.faded}>n/a</span>
                )}
              </div>
            </div>
          );

          return revision.specification_id == flyout.specification_id ? (
            row
          ) : (
            <Tooltip
              placement='left'
              text={__(
                'current-set-specifications.revisions.revision-created-another-variant-same-code-specification'
              )}
            >
              {row}
            </Tooltip>
          );
        })}
      </div>
    );
  }
}

export default Revisions;
