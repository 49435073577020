import * as React from "react";
import {Component} from "react";
import {Field, FieldArray, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_stage_wizard/InitialTeamForm.scss";
import Button from "../../components/Button";
import {__, mapStateToProps, setTableParams, transformToListPairs} from "../../core/utils";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import ButtonGroup from "../../components/ButtonGroup";
import Select from "../../components/Select";
import * as _ from "lodash";
import {listPossibleTeamCompanies} from "../../redux/actions/table/teams";
import classNames from "classnames";
import Input from "../../components/Input";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import CloseMiddleIcon from "../../assets/images/close-middle-15x15.svg";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";

@reduxForm({
    form: "add_stage_wizard.initial_team",
    destroyOnUnmount: false
})
@mapStateToProps(state => ({
    user_id: state.auth.id,
    values: getFormValues("add_stage_wizard.initial_team")(state)
}))
@subscribe(Localization, "localization")
class InitialTeamForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_companies: [],
        }
    }

    componentDidMount() {
        listPossibleTeamCompanies().then(({response}) => {
            this.setState({possible_companies: response.data});
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.values.discipline != this.props.values.discipline) || (prevProps.values.custom_discipline && !this.props.values.custom_discipline)) {
            this.props.change('code', _.get(this.props.localization.disciplines, [this.props.values.discipline, 'code']));
        }

        if(!prevProps.values.custom_discipline && this.props.values.custom_discipline) {
            this.props.change('code', null);
            this.props.change('description', null);
        }
    }

    render_owners = ({fields}) => (<>
        {fields.map((field, i) => (
            <div className={classNames(styles.flex, styles.ownerRow)} key={i}>
                <div className={styles.f5}>
                    <Field name={field + '.email'} component={Input} label={__('project-settings-stages.create-new-stage.inital-team.company-owners-email')} />
                </div>
                <div className={styles.f2}>
                    <Field name={field + '.team_role'} component={Select} noSort label={__('project-settings-stages.create-new-stage.inital-team.team-role')} options={[
                        {
                            label: __('project-settings-stages.create-new-stage.inital-team.viewer'),
                            value: 'viewer',
                        }, {
                            label: __('project-settings-stages.create-new-stage.inital-team.reviewer'),
                            value: 'reviewer',
                        }, {
                            label: __('project-settings-stages.create-new-stage.inital-team.editor'),
                            value: 'editor',
                        }
                    ]} />
                </div>
                <CloseMiddleIcon className={classNames(styles.deleteOwner, _.size(fields) == 1 && styles.disabled)} onClick={() => _.size(fields) > 1 && fields.remove(i)} />
            </div>
        ))}
        <div className={styles.button}>
            <Button lightBlue rightIcon={PlusMiddleIcon} leftText={__('button.invite-more')} onClick={() => fields.push({team_role: 'editor'})} />
        </div>
    </>);

    render() {
        const {custom_discipline, new_company} = this.props.values;

        const disciplines = _.map(this.props.localization.disciplines, discipline => ({
            value: discipline.id,
            label: [discipline.code, discipline.name].join(': '),
        }));

        return (
            <Form onSubmit={this.props.handleSubmit}>
                <div className={styles.chooser}>
                    <div className={classNames(styles.option, !custom_discipline && styles.selected)} onClick={() => this.props.change('custom_discipline', false)}><span />{__('project-list.all-projects.create-new-project.team-form.choose-existing-discipline')}</div>
                    <div className={classNames(styles.option, custom_discipline && styles.selected)} onClick={() => this.props.change('custom_discipline', true)}><span />{__('project-list.all-projects.create-new-project.team-form.custom-discipline')}</div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="code" component={Input} label={__('project-list.all-projects.create-new-project.team-form.code')} maxLength={4} />
                    </div>
                    {!custom_discipline && (
                        <div className={styles.f2}>
                            <Field name="discipline" component={Select} searchable label={__('project-list.all-projects.create-new-project.team-form.discipline')} noSort options={disciplines} />
                        </div>
                    )}
                    {custom_discipline && (
                        <div className={styles.f2}>
                            <Field name="discipline_description" component={Input} label={__('project-list.all-projects.create-new-project.team-form.discipline.custom-discipline')} />
                        </div>
                    )}
                </div>
                <div className={styles.chooser}>
                    <div className={classNames(styles.option, !new_company && styles.selected)} onClick={() => this.props.change('new_company', false)}><span />{__('project-list.all-projects.create-new-project.team-form.choose-existing-company')}</div>
                    <div className={classNames(styles.option, new_company && styles.selected)} onClick={() => this.props.change('new_company', true)}><span />{__('project-list.all-projects.create-new-project.team-form.new-company')}</div>
                </div>
                {!new_company && <Field name="company_id" searchable={true} component={Select} label={__('project-list.all-projects.create-new-project.team-form.company')} options={transformToListPairs(this.state.possible_companies)} />}
                {new_company && <Field name="company_name" component={Input} label={__('project-list.all-projects.create-new-project.team-form.company-name')} />}
                {new_company && <FieldArray name="owner" component={this.render_owners} />}
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('stages', {
                        wizard_active: undefined
                    })} />
                    <ButtonGroup right>
                        <Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
                        <Button submit medium gray leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default InitialTeamForm;