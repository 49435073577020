import React, {Component} from "react";
import styles from "../styles/components/Button.scss";
import classNames from "classnames";
import SpinnerIcon from "../assets/images/spinner-16x16.svg";

class Button extends Component {
	render() {
		const LeftIcon = this.props.leftIcon;
		const MiddleIcon = this.props.middleIcon;
		const RightIcon = this.props.rightIcon;

		const classes = classNames([
			styles.wrapper,
			this.props.leftText && styles.leftText,
			this.props.middleText && styles.middleText,
			this.props.rightText && styles.rightText,
			this.props.leftIcon && styles.leftIcon,
			this.props.middleIcon && styles.middleIcon,
			this.props.rightIcon && styles.rightIcon,
			this.props.fullWidth && styles.fullWidth,
			this.props.left && styles.left,
			this.props.center && styles.center,
			this.props.right && styles.right,
			this.props.small && styles.small,
			this.props.medium && styles.medium,
			this.props.large && styles.large,
			this.props.spinning && styles.spinning,
			this.props.disabled && styles.disabled,
			this.props.gray && styles.gray,
			this.props.lightGray && styles.lightGray,
			this.props.lightBlue && styles.lightBlue,
			this.props.red && styles.red,
			this.props.green && styles.green,
			this.props.className
		]);

		return (
			<button type={this.props.submit ? "submit" : "button"} onClick={this.props.onClick} className={classes} disabled={this.props.disabled}>
				{this.props.leftText}
				{this.props.middleText}
				{this.props.rightText}
				{(this.props.leftIcon || this.props.middleIcon || this.props.rightIcon) && (
					<span className={styles.icons}>
						{this.props.leftIcon && <LeftIcon />}
						{this.props.middleIcon && <MiddleIcon />}
						{this.props.rightIcon && <RightIcon />}
					</span>
				)}
				{this.props.spinning && (
					<span className={styles.spinner}>
						<SpinnerIcon />
					</span>
				)}
			</button>
		);
	}
}

export default Button;
