import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddFunctionalSpaceWizard.scss";
import autobind from "autobind-decorator";
import AddFunctionalSpaceIcon from "../../assets/images/add-functional-space-64x64.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createFunctionalSpace, listFunctionalSpaces } from "../../redux/actions/table/superadmin/functional_spaces";
import AddFunctionalSpaceForm from "../../forms/superadmin/add_functional_spaces_wizard/AddFunctionalSpaceForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_functional_spaces"],
}))
class AddFunctionalSpaceWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createFunctionalSpace(formData).then(({ response }) => {
				listFunctionalSpaces().then(() => {
					if (edit) {
						setTableParams('superadmin_functional_spaces', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_functional_spaces', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new functional space.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add functional space</span>
						<span>Add functional space to system functional spaces</span>
					</div>
					<div className={styles.right}>
						<AddFunctionalSpaceIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddFunctionalSpaceForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddFunctionalSpaceWizard;