import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/products/Notes.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import CloseMediumIcon from '../../assets/images/close-middle-15x15.svg';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import {
  deleteProductNote,
  reorderProductNotes,
} from '../../redux/actions/table/products';
import { setNotification } from '../../redux/actions/general/notification';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import Tooltip from '../../components/Tooltip';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';

@mapStateToProps((state) => ({
  store: state.table.products,
}))
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }
  @autobind
  _changeOrder(category, notes) {
    const { flyout } = this.props.store;

    setTableParams('products', {
      flyout: {
        ...flyout,
        notes: _.map(flyout.notes, (note, i) => {
          return note.category_id == category
            ? {
                ...note,
                order: _.findIndex(notes, ['id', note.id]),
              }
            : note;
        }),
      },
    });
  }

  @autobind
  _changeNoteCategoryOrder(categories) {
    const { flyout } = this.props.store;

    setTableParams('products', {
      flyout: {
        ...flyout,
        notes: _.map(
          _.transform(
            categories,
            (result, category) => {
              _.each(_.sortBy(flyout.notes, 'order'), (note) => {
                if (note.category_id == category.value) {
                  result.push(note);
                }
              });
            },
            []
          ),
          (note, i) => ({
            ...note,
            order: i,
          })
        ),
      },
    });
  }

  componentDidMount() {
    const { notes } = this.props.store.flyout;

    this.setState({
      expanded: _.map(_.groupBy(notes, 'category_id'), (data, category_id) => {
        return Number(category_id);
      }),
    });
  }

  componentDidUpdate(prevProps) {
    const { notes } = this.props.store.flyout;
    if (
      !_.isEqual(
        _.map(_.get(prevProps, 'store.flyout.notes'), 'id'),
        _.map(_.get(this.props, 'store.flyout.notes'), 'id')
      )
    ) {
      this.setState({
        expanded: _.map(
          _.groupBy(notes, 'category_id'),
          (data, category_id) => {
            return Number(category_id);
          }
        ),
      });
    }
  }

  render() {
    const { collapsed, store } = this.props;
    const { flyout, language_id } = store;
    const { expanded } = this.state;

    const notes = _.sortBy(_.map(flyout.notes), 'order');

    let note_categories = _.sortBy(
      _.transform(
        notes,
        (result, note) => {
          if (!result.find((data) => data.value == note.category_id)) {
            result.push({
              label: getLocalized(note.category_name, language_id),
              value: note.category_id,
              order: note.order,
            });
          }
        },
        []
      ),
      'order'
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
        key={language_id}
      >
        <ReactSortable
          list={note_categories}
          setList={this._changeNoteCategoryOrder}
          animation={200}
          delayOnTouchStart={true}
          onEnd={() => {
            reorderProductNotes(flyout.id, _.map(notes, 'id')).then(() => {
              setNotification(
                __(
                  'products.flyout.instructions.product-instructions-order-saved'
                )
              );
            });
          }}
          handle={'.' + styles.grabber}
        >
          {_.map(note_categories, (category) => {
            const filtered_notes = _.sortBy(
              _.filter(notes, ['category_id', category.value]),
              'order'
            );

            return (
              <div className={styles.category} key={category.value}>
                <div
                  className={styles.title}
                  onClick={() => this._toggleCollapseCategory(category.value)}
                >
                  <div className={styles.grabber}>
                    <GrabberIcon />
                  </div>
                  <ArrowDownMiddleIcon
                    className={classNames(
                      styles.collapse,
                      !_.includes(expanded, category.value) && styles.collapsed
                    )}
                  />
                  {category.label}
                </div>
                <ReactSortable
                  list={filtered_notes}
                  setList={(list) => this._changeOrder(category.value, list)}
                  animation={200}
                  delayOnTouchStart={true}
                  onEnd={() => {
                    reorderProductNotes(flyout.id, [
                      ..._.map(filtered_notes, 'id'),
                      ..._.sortBy(
                        _.filter(notes, ['category_id', !category.value]),
                        'order'
                      ),
                    ]).then(() => {
                      setNotification(
                        __(
                          'products.flyout.instructions.product-instructions-order-saved'
                        )
                      );
                    });
                  }}
                  handle={'.' + styles.grabber}
                  className={styles.content}
                >
                  {_.map(filtered_notes, (note) => {
                    return (
                      <div
                        className={classNames(styles.flex, styles.row)}
                        key={note.id}
                      >
                        <div className={styles.grabber}>
                          <GrabberIcon />
                        </div>
                        <div className={styles.note}>
                          <span>&mdash;</span>

                          <Tooltip
                            text={getLocalized(note.text, language_id)}
                            placement='top'
                          >
                            <span>{getLocalized(note.text, language_id)}</span>
                          </Tooltip>
                        </div>

                        <div className={styles.edit}>
                          <EditIcon
                            onClick={() =>
                              setTableParams('products', {
                                edit_product_note_wizard: true,
                                edit_product_note_data: note,
                              })
                            }
                          />
                        </div>

                        <div className={styles.remove}>
                          <CloseMediumIcon
                            onClick={() =>
                              showAlertbox({
                                color: 'red',
                                title: __('general.alert.are-you-sure'),
                                description: __(
                                  'products.flyout.instructions.are-you-sure.remove-instruction.products.alert'
                                ),
                                buttons: [
                                  {
                                    color: 'lightGray',
                                    text: __('general.alert.no-close'),
                                    onClick: 'close',
                                  },
                                  {
                                    color: 'gray',
                                    text: __('general.alert.yes-delete'),
                                    onClick: () =>
                                      deleteProductNote(
                                        flyout.id,
                                        note.id
                                      ).then(() => {
                                        hideAlertbox();

                                        setNotification(
                                          __(
                                            'products.flyout.instructions.instruction-has-been-deleted'
                                          )
                                        );

                                        setTableParams('products', {
                                          flyout: {
                                            ...flyout,
                                            notes: _.reject(notes, [
                                              'id',
                                              note.id,
                                            ]),
                                          },
                                        });
                                      }),
                                  },
                                ],
                              })
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </ReactSortable>
              </div>
            );
          })}
        </ReactSortable>
        <div
          className={classNames(
            styles.addRow,
            _.size(notes) == 0 && styles.left
          )}
        >
          <button
            type='button'
            onClick={() =>
              setTableParams('products', {
                add_product_note_wizard: true,
              })
            }
          >
            {__('button.add-instruction')}
          </button>
        </div>
      </div>
    );
  }
}

export default Notes;
