import store from "../../../../core/store";

export const listSpecificationGroups = () => {
	return store.dispatch({
		action: "table.superadmin_specification_groups/LIST_SPECIFICATION_GROUPS",
		method: "GET",
		endpoint: "/superadmin/specification_groups",
		main: true
	});
};

export const createSpecificationGroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_specification_groups/CREATE_SPECIFICATION_GROUP",
		method: "POST",
		endpoint: "/superadmin/specification_groups",
		data: formData
	});
};

export const deleteSpecificationGroups = (specification_group_ids) => {
	return store.dispatch({
		action: "table.superadmin_specification_groups/DELETE_SPECIFICATION_GROUPS",
		method: "DELETE",
		endpoint: "/superadmin/specification_groups",
		data: {
			specification_groups: specification_group_ids
		}
	});
};

export const readSpecificationGroup = (specification_group_id) => {
	return store.dispatch({
		action: "table.superadmin_specification_groups/READ_SPECIFICATION_GROUP",
		method: "GET",
		endpoint: "/superadmin/specification_groups/" + specification_group_id,
		has404: true
	});
};

export const updateSpecificationGroup = (specification_group_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_specification_groups/UPDATE_SPECIFICATION_GROUP",
		method: "PUT",
		endpoint: "/superadmin/specification_groups/" + specification_group_id,
		data: formData
	});
};