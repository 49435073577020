import * as React from 'react';
import styles from '../styles/wizards/AddBidderWizard.scss';
import { __, validateForm } from '../core/utils';
import AddTeamIcon from '../assets/images/add-team-60x60.svg';
import { setNotification } from '../redux/actions/general/notification';
import AddBidderForm from '../forms/add_bidder_wizard/AddBidderForm';
import {
  createBidder,
  listAllTenders,
  readTender,
} from '../redux/actions/table/tenders';
import { connect } from 'react-redux';

const AddBidderWizard = (props) => {
  const _handleSubmit = (formData) => {
    const tender_id = props.table.flyout.id;
    const { edit } = formData;

    formData = {
      ..._.omit(formData, ['edit']),
      bidders: _.map(formData.bidders, 'id'),
    };

    return validateForm(
      createBidder(tender_id, formData).then(({ response }) => {
        readTender(tender_id);

        props.onClose();

        setNotification(__('wizard.add-tender.message.success'));
      })
    );
  };

  const { bidders } = props.table.flyout;

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.tenders.title.add-bidder')}</span>
          <span>{__('wizard.tenders.subtitle.add-bidder')}</span>
        </div>
        <div className={styles.right}>
          <AddTeamIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddBidderForm
          onSubmit={_handleSubmit}
          initialValues={{
            bidders,
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    table: state.table['tenders'],
  };
};

export default connect(mapStateToProps, null)(AddBidderWizard);
