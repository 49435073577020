import * as React from 'react';
import { Component } from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import {
  createSidebar,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  getActiveStage,
  getURLParam,
  redirect,
  setTableParams,
  setURLParam,
} from '../../core/utils';
import Table from '../../components/Table';
import { setPlusActions } from '../../redux/actions/general/plus';
import styles from '../../styles/views/tenders/Tenders.scss';
import classNames from 'classnames';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import Tooltip from '../../components/Tooltip';
import moment from 'moment';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';
import {
  listAllTenders,
  readTender,
  updateBidderStatus,
} from '../../redux/actions/table/tenders';
import TendersFlyout from '../../flyouts/tenders/TendersFlyout';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';

const mapStateToProps = (state) => {
  return {
    table: state.table['tenders'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.tenders/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class Tenders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team_roles: [],
    };
  }

  componentDidMount() {
    //this.setState({ team_roles: _.get(response.data, 'teams', {}) });

    // !_.isEmpty(this.state.team_roles.editor) &&
    //   setPlusActions([
    //     {
    //       title: __('tenders.table.button.create'),
    //       onClick: () =>
    //         this.props.actions.setTableParams({
    //           wizard_active: true,
    //           x: undefined,
    //           y: undefined,
    //           stage_id: undefined,
    //         }),
    //     },
    //   ]);

    setPlusActions([
      {
        title: __('tenders.table.button.create'),
        onClick: () =>
          this.props.actions.setTableParams({
            wizard_active: true,
            x: undefined,
            y: undefined,
            stage_id: undefined,
          }),
      },
    ]);

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/tenders')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/tenders')
    );

    createSidebar({
      title: __('submenu.title.tenders'),
      items: [
        {
          title: __('submenu.title.tender-list'),
          link: () => '/stages/' + getActiveStage() + '/tenders?filter.type=',
          active: true,
          expand: true,
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table?.filters, prevProps.table?.filters)) {
      const items = _.keys(_.get(this.props.table.filters, 'type.items'));

      const all_ids = items;

      updateSidebar('items.0.items', [
        {
          title: __('submenu.title.tenders'),
          onClick: () => setURLParam('filter.type', ''),
          active: () => {
            const active_types = _.split(getURLParam('filter.type'), '|');

            const intersected = _.intersection(active_types, items);

            return (
              _.isEmpty(getURLParam('filter.type')) ||
              _.size(intersected) == _.size(items)
            );
          },
          checkbox: {
            status: () => {
              const active_types = _.split(getURLParam('filter.type'), '|');

              const intersected = _.intersection(active_types, items);

              if (_.isEmpty(getURLParam('filter.type'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(items)) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_types = _.split(getURLParam('filter.type'), '|');

              const intersected = _.intersection(active_types, items);

              if (
                _.size(intersected) == _.size(items) ||
                _.isEmpty(getURLParam('filter.type'))
              ) {
                setURLParam('filter.type', 'n/a');
              } else {
                setURLParam('filter.type', '');
              }
            },
          },
        },
        ..._.map(items, (type) => ({
          title: __('tenders.tooltip.icon.' + type),
          onClick: () => setURLParam('filter.type', type),
          active: () => {
            const active_types = _.split(getURLParam('filter.type'), '|');

            return (
              _.isEmpty(getURLParam('filter.type')) ||
              _.includes(active_types, type)
            );
          },
          checkbox: {
            status: () => {
              const active_types = _.split(getURLParam('filter.type'), '|');

              if (_.isEmpty(getURLParam('filter.type'))) {
                return 'checked';
              }

              return _.includes(active_types, type) ? 'checked' : 'unchecked';
            },
            onClick: () => {
              const setTypes = (types) => {
                types = _.trim(
                  _.join(
                    _.reject(types, (type) => type == 'n/a'),
                    '|'
                  ),
                  '|'
                );

                setURLParam('filter.type', _.isEmpty(types) ? 'n/a' : types);
              };

              const active_types =
                getURLParam('filter.type') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.type'), '|');

              if (_.includes(active_types, type)) {
                setTypes(_.reject(active_types, (row) => row == type));
              } else {
                active_types.push(type);

                setTypes(active_types);
              }
            },
          },
        })),
      ]);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        setTableParams('tenders', {
          flyout: undefined,
        });
      }

      readTender(this.props.table.clicked_row).then(({ response }) => {
        const { is_bidder, bidders } = response.data;
        if (is_bidder && _.first(bidders).status == 'received') {
          updateBidderStatus(
            getURLParam('id') || tender_id,
            _.first(bidders).id,
            { status: 'in-progress' }
          ).then(() => readTender(this.props.table.clicked_row));
        }
      });
    }
  }

  render() {
    const columns = [
      {
        key: 'identifier',
        name: __('table.columns.tender-code'),
        value: (value) => 'B-' + value,
        width: 70,
        default: true,
        desc: true,
        required: true,
      },
      {
        key: 'title',
        name: __('table.columns.tender.title'),
        required: true,
        mobileVisible: true,
      },
      {
        key: 'bidders',
        name: __('table.columns.tender.number_of_bidders'),
        required: true,
        value: (bidders, value) => {
          if (value.is_bidder) {
            return <TableNA />;
          } else {
            return (
              <div className={styles.bidders}>
                {_.size(bidders) == 1 &&
                  _.map(_.take(bidders, 1), (user, i) => (
                    <Avatar
                      key={i}
                      avatar_url={user.avatar_url}
                      name={`${user.fullname} (${user.company})`}
                      active={user.active}
                      tooltip
                    />
                  ))}
                {_.size(bidders) > 1 && (
                  <Tooltip
                    text={_.map(
                      bidders,
                      (bidder) => `${bidder.fullname} (${bidder.company})`
                    ).join('\n')}
                  >
                    <div className={styles.more}>
                      <span>{_.size(bidders)}</span>
                    </div>
                  </Tooltip>
                )}
              </div>
            );
          }
        },

        mobileVisible: true,
        width: 60,
      },
      {
        key: 'selected_bidder',
        name: __('table.columns.tender.selected_bidder'),
        required: true,
        mobileVisible: true,
        width: 200,
      },

      {
        key: 'status',
        name: __('table.columns.tender.status'),
        value: (status) => {
          switch (status) {
            case 'draft':
              return (
                <Tooltip text={__('table.columns.tender-status-draft')}>
                  <span className={classNames(styles.balloon, styles.draft)}>
                    {__('table.columns.tender-status-draft-short')}
                  </span>
                </Tooltip>
              );
            case 'started':
              return (
                <Tooltip text={__('table.columns.tender-status-started')}>
                  <span className={classNames(styles.balloon, styles.started)}>
                    {__('table.columns.tender-status-started-short')}
                  </span>
                </Tooltip>
              );
            case 'finished':
              return (
                <Tooltip text={__('table.columns.tender-status-finished')}>
                  <span className={classNames(styles.balloon, styles.finished)}>
                    {__('table.columns.tender-status-finished-short')}
                  </span>
                </Tooltip>
              );
            case 'closed':
              return (
                <Tooltip text={__('table.columns.tender-status-closed')}>
                  <span className={classNames(styles.balloon, styles.closed)}>
                    {__('table.columns.tender-status-closed-short')}
                  </span>
                </Tooltip>
              );
            case 'canceled':
              return (
                <Tooltip text={__('table.columns.tender-status-canceled')}>
                  <span className={classNames(styles.balloon, styles.canceled)}>
                    {__('table.columns.tender-status-canceled-short')}
                  </span>
                </Tooltip>
              );
          }
        },
        width: 50,
        default: true,
        fullWidth: true,
      },

      {
        key: 'start_date',
        name: __('table.columns.tender-start'),
        value: (value) => (value ? dateFrom(value) : <TableNA />),
        width: 80,
        active: false,
      },
      {
        key: 'end_date',
        name: __('table.columns.tender-end'),
        value: (value, row) => {
          if (value) {
            return moment.utc().local().isAfter(moment.utc(value).local()) &&
              _.includes(['not-started', 'started'], row.status) ? (
              <span className={styles.overdue}>{dateFrom(value)}</span>
            ) : (
              dateFrom(value)
            );
          } else {
            return <TableNA />;
          }
        },
        width: 80,
        active: false,
      },

      {
        key: 'duration',
        name: __('table.columns.tender-duration'),
        value: (value) => value,
        width: 80,
        sortable: false,
      },

      {
        key: 'remaining_days',
        name: __('table.columns.tender-remaining_days'),
        value: (value) => value,
        width: 80,
        sortable: false,
      },

      {
        key: 'administrators',
        name: __('table.columns.tender.administrators'),
        value: (administrator) => (
          <div className={styles.administrators}>
            {_.size(administrator) == 1 &&
              _.map(_.take(administrator, 1), (user, i) => (
                <Avatar
                  key={i}
                  avatar_url={user.avatar_url}
                  name={user.fullname}
                  active={user.active}
                />
              ))}
            {_.size(administrator) > 1 && (
              <Tooltip text={_.map(administrator, 'fullname').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(administrator)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 60,
        active: false,
      },

      {
        key: 'followers',
        name: __('table.columns.tender.followers'),
        value: (follower) => (
          <div className={styles.administrators}>
            {_.size(follower) == 1 &&
              _.map(_.take(follower, 1), (user, i) => (
                <Avatar
                  key={i}
                  avatar_url={user.avatar_url}
                  name={user.fullname}
                  active={user.active}
                />
              ))}
            {_.size(follower) > 1 && (
              <Tooltip text={_.map(follower, 'fullname').join('\n')}>
                <div className={styles.more}>
                  <span>{_.size(follower)}</span>
                </div>
              </Tooltip>
            )}
          </div>
        ),
        sortable: false,
        width: 60,
        active: false,
      },
    ];

    const groupActions = (tender_id) => {};

    const filtersSort = (filter, value, label) => {};

    const singleActions = (tender_id) => {
      const actions = [];

      actions.push(
        <Tooltip
          text={__('tenders.tooltip.icon.tender-properties')}
          key={_.uniqueId()}
        >
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('tenders', {
                clicked_row: tender_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>
      );

      return actions;
    };

    const preview = (
      <TendersFlyout
        data={this.props.table.flyout}
        readAction={(tender_id) => {
          return new Promise(() => {
            readTender(getURLParam('id') || tender_id).then(({ response }) => {
              const { is_bidder, bidders } = response.data;
              if (is_bidder && _.first(bidders).status == 'received') {
                updateBidderStatus(
                  getURLParam('id') || tender_id,
                  _.first(bidders).id,
                  { status: 'in-progress' }
                ).then(() => readTender(this.props.table.clicked_row));
              }
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listAllTenders(this.props.match.params.stage)}
        name='tenders'
        title={__('Tenders')}
        columns={columns}
        singleActions={singleActions}
        groupActions={groupActions}
        filtersSort={filtersSort}
        preview={this.props.table.flyout ? preview : []}
        onLoad={() => listActiveModules()}
        onRowClick={(id) => {
          // const { is_bidder } = _.find(
          //   this.props.table.data,
          //   (row) => row.id === id
          // );

          redirect(
            '/stages/' +
              this.props.match.params.stage +
              '/tenders/' +
              id +
              '/boq'
          );
        }}
      />
    );
  }
}

export default Tenders;
