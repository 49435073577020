import * as React from "react";
import {Component} from "react";
import {Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/company/CompanyDisciplinesForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import SearchIcon from "../../assets/images/search-15x15.svg";
import {connect} from "react-redux";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import autobind from "autobind-decorator";
import {setNotification} from "../../redux/actions/general/notification";
import {enableAutosave} from "../../redux/actions/general/autosave";
import {__} from "../../core/utils";

const mapStateToProps = state => {
	return {
		values: getFormValues("company.disciplines")(state)
	};
};
@subscribe(Localization, "localization")
@reduxForm({
	form: "company.disciplines",
    enableReinitialize: true,
})
@autobind
@connect(
	mapStateToProps,
	null
)
class CompanyDisciplinesForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    @autobind
	_addDiscipline(id) {
		const disciplines = [..._.get(this.props, "values.disciplines", []), id];

		this.props.touch("disciplines");
		this.props.change("disciplines", _.uniqBy(disciplines));
        this.props.blur("disciplines");
	}

    @autobind
	_removeDiscipline(id) {
		const disciplines = _.filter(_.get(this.props, "values.disciplines", []), item => item != id);

		if (disciplines.length > 0) {
            this.props.touch("disciplines");
			this.props.change("disciplines", _.uniqBy(disciplines));
            this.props.blur("disciplines");
		} else {
			setNotification({
				text: __('company-settings.disciplines.at-least-one-discipline-is-required'),
				type: "warning"
			});
		}
	}

    @autobind
	_handleSearch(e) {
		this.setState({ query: e.target.value });
	}

	render() {
		const available_disciplines = _.sortBy(this.props.localization.disciplines, 'priority');

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.wrapper}>
					<div className={styles.left}>
						<div className={styles.title}>{__('company-settings.disciplines.disciplines')}</div>
						<div className={styles.search}>
							<input type="text" placeholder={__('company-settings.disciplines.disciplines.search')} onChange={this._handleSearch} />
							<SearchIcon />
						</div>
						<div className={styles.disciplines}>
							{_.map(_.filter(available_disciplines, item => !_.includes(_.get(this.props, "values.disciplines", []), item.id) && (!_.get(this.state, "query") || _.includes(item.name.toLowerCase(), _.get(this.state, "query", "").toLowerCase()))), item => (
								<span key={item.id} onClick={() => this._addDiscipline(item.id)}>
									{item.name}
									<ArrowRightMiddleIcon />
								</span>
							))}
						</div>
					</div>
					<div className={styles.right}>
						<div className={styles.title}>{__('company-settings.disciplines.my-company-disciplines')}</div>
						<div className={styles.disciplines}>
							{_.map(_.get(this.props, "values.disciplines", []), id => (
								<span key={id} onClick={() => this._removeDiscipline(id)}>
									<ArrowLeftMiddleIcon />
									{_.find(available_disciplines, item => item.id == id).name}
								</span>
							))}
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default CompanyDisciplinesForm;
