import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { getLocalized, mapStateToProps } from '../../../core/utils';
import { enableAutosave } from '../../../redux/actions/general/autosave';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import { listLocalization } from '../../../redux/actions/localization';

@reduxForm({
  form: 'superadmin_boq_template_calculations.edit_boq_template_calculation',
  enableReinitialize: true,
})
@withRouter
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  application_language_id: state.auth.language_id,
}))
class EditBOQTemplateCalculationForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  componentDidMount() {
    listLocalization();
  }

  render() {
    const { handleSubmit, superadmin } = this.props;

    const application_languages = _.transform(
      _.filter(
        this.props.localization.languages,
        (language) => language.application_language == true
      ),
      (languages, language) => {
        languages[language.id] = language.id == 1;

        return languages;
      },
      {}
    );

    const boq_template_groups = _.map(
      this.props.localization.boq_template_subgroups,
      (item) => ({
        value: item.id,
        label: getLocalized(item.name, this.props.application_language_id),
      })
    );

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          disabled={!superadmin.edit_boq_template_calculation}
          localized={application_languages}
          name='name'
          component={Input}
          label='Name'
        />
        <Field
          disabled={!superadmin.edit_boq_template_calculation}
          name='boq_template_subgroup_id'
          component={Select}
          searchable
          options={boq_template_groups}
          label='BOQ Template Subgroup'
        />
      </Form>
    );
  }
}

export default EditBOQTemplateCalculationForm;
