import * as React from "react";
import {Component} from "react";
import {Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/company_info_wizard/SizeForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import Button from "../../components/Button";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import classNames from "classnames";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import {__} from "../../core/utils";
import autobind from "autobind-decorator";
import {hideCreateCompanyWizard} from "../../redux/actions/profile";
import ButtonGroup from "../../components/ButtonGroup";

@subscribe(Localization, "localization")

class SizeForm extends Component {
    @autobind
	_selectSize(id) {
		this.props.change("size", id);
	}

	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.content}>
					<div className={styles.title}>{__('Size by Number of Employees')}</div>
					<div className={styles.sizes}>
						{_.map(this.props.localization.company_sizes, ({ size, name, id }, i) => (
							<div key={i} className={classNames(styles.size, id == _.get(this.props, "values.size") && styles.selected)} onClick={() => this._selectSize(id)}>
								<span>{size}</span>
								<span>{name}</span>
							</div>
						))}
					</div>
				</div>
				<div className={styles.footer}>
					<Button lightGray medium left middleText={__('button.cancel')} onClick={hideCreateCompanyWizard} />
					<ButtonGroup right>
						<Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
						<Button submit medium lightBlue leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

function mapStateToProps(state) {
	return {
		values: getFormValues("company_info_wizard.size")(state)
	};
}

export default withRouter(
	reduxForm({
		form: "company_info_wizard.size",
		destroyOnUnmount: false
	})(
		connect(
			mapStateToProps,
			null
		)(SizeForm)
	)
);
