import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_project_wizard/InitialStageForm.scss";
import Button from "../../components/Button";
import {__, setTableParams} from "../../core/utils";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";
import ButtonGroup from "../../components/ButtonGroup";
import DateTimePicker from "../../components/DateTimePicker";

@reduxForm({
    form: "add_project_wizard.initial_stage",
    destroyOnUnmount: false
})
class InitialStageForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit}>
				<Field name="name" component={Input} label={__('project.stages.column.title')} maxLength={50} />
				<Field name="description" component={Textarea} label={__('project.stages.column.description')} maxLength={100} rows={1} maxRows={10} />
				<Field name="date" component={DateTimePicker} label={__('project.stages.column.date-range')} footer range />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_projects', {
                        wizard_active: undefined
                    })} />
                    <ButtonGroup right>
                        <Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
                        <Button submit medium gray leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default InitialStageForm;