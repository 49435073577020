import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_specification_delivery_wizard/CreateSpecificationDeliveryForm.scss';
import Button from '../../components/Button';
import {
  __,
  getActiveProject,
  getLocalized,
  mapStateToProps,
  setTableParams,
  transformToListPairs,
} from '../../core/utils';
import ButtonGroup from '../../components/ButtonGroup';
import Input from '../../components/Input';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import classNames from 'classnames';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import PeopleChooser from '../../components/PeopleChooser';
import { readProjectGeneral } from '../../redux/actions/projects';
import WarningIcon from '../../assets/images/warning-15x15.svg';
import {
  listExistingSpecificationDeliveries,
  listPossibleLinkedSpecifications,
  listSpecificationDeliveryApprovers,
} from '../../redux/actions/table/working_set_specifications';
import Tooltip from '../../components/Tooltip';

@subscribe(Localization, 'localization')
@reduxForm({
  form: 'create_specification_delivery_wizard.create_specification_delivery',
})
@mapStateToProps((state) => ({
  table: state.table['working_set_specifications'],
  values: getFormValues(
    'create_specification_delivery_wizard.create_specification_delivery'
  )(state),
}))
class CreateSpecificationDeliveryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existing_deliveries: [],
      delivery_approvers: [],
      isPossibleLinkedSpecificationsLoading: false,
    };
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      this.setState({ project_language: response.data.main_language });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.values?.team_id != this.props.values?.team_id) {
      const team_id = this.props.values.team_id;

      listExistingSpecificationDeliveries(team_id).then(({ response }) => {
        this.setState({ existing_deliveries: response.data });
      });

      listSpecificationDeliveryApprovers(team_id).then(({ response }) => {
        this.setState({ delivery_approvers: response.data });

        this.props.change('approvers', response.data);
      });
    }
  }

  @autobind
  _handleSubmit(sent) {
    this.props.change('sent', sent);

    setTimeout(() => this.props.submit(), 10);
  }

  @autobind
  _removeSpecification(id) {
    setTableParams('working_set_specifications', {
      selected_specifications: _.reject(
        this.props.table.selected_specifications,
        (specification) => specification.id == id
      ),
    });
  }

  @autobind
  _renderTooltipWarningText({ ready_for_delivery, main, can_deliver }) {
    if (!main) {
      return __(
        'specifications.working-set.flyout.deliver-specification.deliver-only-main-option-specification'
      );
    }

    if (!can_deliver) {
      return __(
        'specifications.working-set.flyout.deliver-specification.specification-belong-delivery'
      );
    }

    if (!ready_for_delivery) {
      return __(
        'specifications.working-set.flyout.deliver-specification.specification-is-not-marked-as-ready-for-delivery'
      );
    }
  }

  @autobind
  addLinkedSpecifications() {
    this.setState({ isPossibleLinkedSpecificationsLoading: true });

    listPossibleLinkedSpecifications(
      _.map(
        this.props.table.selected_specifications,
        (specification) => specification.specification_code_id
      )
    ).then((response) => {
      this.setState({ isPossibleLinkedSpecificationsLoading: false });
    });
  }

  render() {
    // const disabled = _.size(_.filter(this.props.table.data, specification => _.includes(this.props.table.selected_specifications, specification.id) && !_.isEmpty(specification.last_version.delivery))) == _.size(this.props.table.selected_specifications) || _.isEmpty(this.props.values.title) || _.isEmpty(_.filter(this.props.table.data, specification => _.includes(this.props.table.selected_specifications, specification.id)));

    const { specification_groups, specification_subgroups } =
      this.props.localization;
    const { project_language } = this.state;
    const { new_delivery, team_id } = this.props.values;

    const possible_teams = _.map(
      _.groupBy(this.props.table.selected_specifications, 'team.id'),
      (items, group) => {
        const { team, description, discipline } = _.first(items);
        return {
          value: team.id,
          label:
            team.company +
            ' [' +
            [
              discipline.code,
              discipline.id == null
                ? description + '*'
                : _.get(this.props.localization, [
                    'disciplines',
                    discipline.id,
                    'name',
                  ]),
            ].join(': ') +
            ']',
        };
      }
    );

    const disabled =
      _.isUndefined(team_id) ||
      _.isEmpty(
        _.filter(
          this.props.table.selected_specifications,
          (specification) => specification.id
        )
      ) ||
      _.includes(
        _.map(
          _.filter(
            this.props.table.selected_specifications,
            (specification) =>
              specification.id && specification.team.id === team_id
          ),
          (filtered_specification) => {
            return filtered_specification.main &&
              filtered_specification.can_deliver &&
              filtered_specification.ready_for_delivery
              ? 1
              : 0;
          }
        ),
        0
      );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.team}>
          <Field
            name='team_id'
            searchable={true}
            component={Select}
            label={__('specifications.working-set.specification-delivery-team')}
            options={possible_teams}
          />
        </div>
        <div className={styles.delivery}>
          <div className={styles.chooser}>
            <div
              className={classNames(
                styles.option,
                new_delivery && styles.selected
              )}
              onClick={() => this.props.change('new_delivery', true)}
            >
              <span />
              {__(
                'specifications.working-set.flyout.deliver-specification.new-delivery'
              )}
            </div>
            <div
              className={classNames(
                styles.option,
                !new_delivery && styles.selected
              )}
              onClick={() => this.props.change('new_delivery', false)}
            >
              <span />
              {__(
                'specifications.working-set.flyout.deliver-specification.add-to-delivery'
              )}
            </div>
          </div>
          <div className={styles.selector}>
            {!new_delivery && (
              <Field
                name='delivery_id'
                component={Select}
                searchable
                label={__(
                  'specifications.working-set.flyout.deliver-specification.add-to-delivery.existing-delivery'
                )}
                options={transformToListPairs(
                  this.state.existing_deliveries,
                  'title'
                )}
              />
            )}
          </div>
        </div>

        <div className={styles.addLinkedSpecifications}>
          <Button
            medium
            lightBlue={this.props.values?.team_id}
            gray={!this.props.values?.team_id}
            disabled={!this.props.values?.team_id}
            onClick={this.addLinkedSpecifications}
            middleText={__(
              'specifications.working-set.flyout.deliver-specification.add-linked-specifications'
            )}
            spinning={this.state.isPossibleLinkedSpecificationsLoading}
          />
        </div>

        {new_delivery && (
          <div className={styles.title}>
            <Field
              name='title'
              component={Input}
              label={__(
                'specifications.working-set.flyout.deliver-specification.new-delivery.delivery-title'
              )}
            />
          </div>
        )}

        <div className={styles.scroller}>
          {_.map(
            _.filter(
              this.props.table.selected_specifications,
              (specification) =>
                specification.id && specification.team.id === team_id
            ),
            (specification, i) => {
              const specification_subgroup =
                _.get(specification_subgroups, [
                  specification.specification_subgroup_id,
                ]) || {};
              const specification_group =
                _.get(
                  specification_groups,
                  _.get(specification_subgroup, 'specification_group_id')
                ) || {};

              const code =
                getLocalized(specification_group.code, project_language) +
                specification_subgroup.code +
                _.padStart(specification.code, 2, '0');
              const title = _.filter(
                [
                  _.get(specification.description, project_language),
                  specification.model,
                  specification.brand,
                ],
                _.identity
              ).join(' / ');

              return (
                <div
                  className={classNames(
                    styles.specification,
                    (!specification.ready_for_delivery ||
                      !specification.main ||
                      !specification.can_deliver) &&
                      styles.hasError
                  )}
                  key={i}
                >
                  <div>
                    {(!specification.ready_for_delivery ||
                      !specification.main ||
                      !specification.can_deliver) && (
                      <Tooltip
                        text={this._renderTooltipWarningText(specification)}
                      >
                        <WarningIcon className={styles.warning} />
                      </Tooltip>
                    )}
                    <div>
                      <strong>
                        {code}
                        {!_.isEmpty(title) ? ':' : ''}
                      </strong>{' '}
                      {title}
                    </div>
                  </div>
                  <div>
                    {specification.discipline.id == null
                      ? specification.discipline.description + '*'
                      : _.get(this.props.localization.disciplines, [
                          specification.discipline.id,
                          'name',
                        ])}{' '}
                    &middot; {specification.team.company}{' '}
                    {_.size(this.props.table.selected_specifications) > 1 && (
                      <CloseSmallIcon
                        className={styles.remove}
                        onClick={() =>
                          this._removeSpecification(specification.id)
                        }
                      />
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
        {!_.isEmpty(this.state.delivery_approvers) && (
          // (
          //   <div className={styles.warningInfo}>
          //     {__(
          //       'specifications.working-set-specifications.flyout.deliver-specification.currently-no assigned-approvers-delivered-specifications-directly-current set',
          //       {
          //         NO_ASSIGNED_APPROVERS: (
          //           <strong>
          //             {__(
          //               'specifications.working-set-specifications.flyout.deliver-specification.no-assigned-approvers'
          //             )}
          //           </strong>
          //         ),
          //       }
          //     )}
          //   </div>
          // ) :

          <div className={styles.approvers}>
            <Field
              disabled={true}
              name='approvers'
              component={PeopleChooser}
              label={__(
                'specifications.working-set.flyout.deliver-specification.assigned-approvers'
              )}
              options={this.state.delivery_approvers}
              hint={__(
                'no-approvers-assigned.delivered-specification-bypass-approval-process.directly-to-current-set'
              )}
            />
          </div>
        )}
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                delivery_wizard_active: undefined,
              })
            }
          />
          <ButtonGroup right>
            <Button
              lightBlue={!disabled}
              gray={disabled}
              medium
              disabled={disabled}
              onClick={() => this._handleSubmit(false)}
              middleText={new_delivery ? __('button.create') : __('button.add')}
              spinning={this.props.submitting}
            />
            <Button
              lightBlue={!disabled}
              gray={disabled}
              medium
              disabled={disabled}
              onClick={() => this._handleSubmit(true)}
              middleText={
                new_delivery ? __('button.create-send') : __('button.add-send')
              }
              spinning={this.props.submitting}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default CreateSpecificationDeliveryForm;
