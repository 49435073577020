import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/superadmin/building_types/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm } from '../../../core/utils';
import { setNotification } from '../../../redux/actions/general/notification';
import {
  listNoteCategories,
  updateNoteCategory,
} from '../../../redux/actions/table/superadmin/note_categories';
import EditNoteCategoryForm from '../../../forms/superadmin/note_categories/EditNoteCategoryForm';

@withRouter
class Properties extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateNoteCategory(formData.id, _.omit(formData, ['id'])).then(() => {
        listNoteCategories();

        this.props.readAction(formData.id);

        setNotification(
          __('properties.notifications.properties-have-been-saved')
        );
      })
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.scroller}>
        <EditNoteCategoryForm
          onSubmit={this._handleSubmit}
          initialValues={{
            ..._.pick(data, ['id', 'name']),
          }}
        />
      </div>
    );
  }
}

export default Properties;
