import * as React from "react";
import { Component } from "react";
import styles from "../../styles/wizards/superadmin/AddBOQSupplyTypeGroupsWizard.scss";
import autobind from "autobind-decorator";
import AddBOQTemplateGroupIcon from "../../assets/images/add-boq-template-group-60x60.svg";
import { mapStateToProps, setTableParams, validateForm } from "../../core/utils";
import { withRouter } from "react-router";
import { setNotification } from "../../redux/actions/general/notification";
import { createBoqSupplyTypeGroup, listBoqSupplyTypeGroups } from "../../redux/actions/table/superadmin/boq_supply_type_groups";
import AddBOQSupplyTypeGroupsForm from "../../forms/superadmin/add_boq_supply_type_groups_wizard/AddBOQSupplyTypeGroupsForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["superadmin_boq_supply_type_groups"],
}))
class AddBOQSupplyTypeGroupsWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		const { edit } = formData;

		return validateForm(
			createBoqSupplyTypeGroup(formData).then(({ response }) => {
				listBoqSupplyTypeGroups().then(() => {
					if (edit) {
						setTableParams('superadmin_boq_supply_type_groups', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('superadmin_boq_supply_type_groups', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification("You have successfully added a new BOQ template group.");
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>Add BOQ Supply type group</span>
						<span>Add BOQ Supply type group</span>
					</div>
					<div className={styles.right}>
						<AddBOQTemplateGroupIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddBOQSupplyTypeGroupsForm onSubmit={this._handleSubmit} initialValues={{
						name: {},
					}} />
				</div>
			</>
		);
	}
}

export default AddBOQSupplyTypeGroupsWizard;