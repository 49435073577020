import store from "../../../../core/store";

export const listUnits = () => {
	return store.dispatch({
		action: "table.superadmin_units/LIST_UNITS",
		method: "GET",
		endpoint: "/superadmin/units",
		main: true
	});
};

export const createUnit = (formData) => {
	return store.dispatch({
		action: "table.superadmin_units/CREATE_UNIT",
		method: "POST",
		endpoint: "/superadmin/units",
		data: formData
	});
};

export const deleteUnits = (unit_ids) => {
	return store.dispatch({
		action: "table.superadmin_units/DELETE_UNITS",
		method: "DELETE",
		endpoint: "/superadmin/units",
		data: {
			units: unit_ids
		}
	});
};

export const readUnit = (unit_id) => {
	return store.dispatch({
		action: "table.superadmin_units/READ_UNIT",
		method: "GET",
		endpoint: "/superadmin/units/" + unit_id,
		has404: true
	});
};

export const updateUnit = (unit_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_units/UPDATE_UNIT",
		method: "PUT",
		endpoint: "/superadmin/units/" + unit_id,
		data: formData
	});
};