import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../../styles/forms/superadmin/add_note_wizard/AddNoteForm.scss';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import {
  __,
  mapStateToProps,
  setTableParams,
  getLocalized,
  setFormValue,
} from '../../../core/utils';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import ButtonGroup from '../../../components/ButtonGroup';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import Textarea from '../../../components/Textarea';
import Select from '../../../components/Select';
import SearchableDropdown from '../../../components/SearchableDropdown';
import Tooltip from '../../../components/Tooltip';
import _ from 'lodash';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';

@withRouter
@reduxForm({ form: 'superadmin.add_note_wizard.add_note' })
@mapStateToProps((state) => ({
  values: getFormValues('superadmin.add_note_wizard.add_note')(state),
  auth: state.auth,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
class AddNoteForm extends Component {
  @autobind
  _handleSubmit(edit) {
    this.props.change('edit', edit);

    setTimeout(() => this.props.submit(), 10);
  }

  @autobind
  clearFilter() {
    setFormValue(
      'superadmin.add_note_wizard.add_note',
      'specification_subgroup_ids',
      []
    );
  }

  render() {
    const {
      application_language_id,
      localization: { specification_groups, specification_subgroups },
      values,
    } = this.props;

    const languages = _.transform(
      _.filter(this.props.localization.languages, ['application_language', 1]),
      (languages, language) => {
        languages[language.id] = language.id == 1;
      },
      {}
    );

    const note_categories = _.map(
      this.props.localization.note_categories,
      (note_category) => {
        return {
          label: getLocalized(note_category.name, this.props.language),
          value: note_category.id,
        };
      }
    );

    // Specification groups
    const specification_subgroup_options = _.sortBy(
      _.map(specification_subgroups, (specification_subgroup) => {
        const specification_group = _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id'),
          {}
        );
        const label =
          getLocalized(specification_group.code, languages) +
          [
            _.padEnd(specification_subgroup.code, 3, '0'),
            getLocalized(specification_subgroup.name, application_language_id),
          ].join(': ');
        const group = [
          getLocalized(specification_group.code, languages),
          getLocalized(specification_group.name, application_language_id),
        ].join(': ');

        return {
          value: specification_subgroup.id,
          label,
          group,
        };
      }),
      'group'
    );

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='note'
            component={Textarea}
            className={styles.note}
            localized={languages}
            label='Instruction'
          />
          <Field name='priority' component={Input} numeric label='Priority' />
          {/* --- Specification supgroup ids --- */}
          <SearchableDropdown
            leftStyled
            multiselect
            options={specification_subgroup_options}
            dropdownWrapperClassName={styles.dropdownWrapper}
            dropdownContentClassName={styles.dropdownContent}
            name='specification_subgroup_ids'
            selectedValues={values.specification_subgroup_ids}
            onSelect={(item) => {
              const itemValue = _.get(item, 'groupHeading')
                ? [...item.value]
                : [item.value];

              const formValue = _.isEmpty(values.specification_subgroup_ids)
                ? itemValue
                : [...values.specification_subgroup_ids, ...itemValue];

              setFormValue(
                'superadmin.add_note_wizard.add_note',
                'specification_subgroup_ids',
                formValue
              );
            }}
            onRemove={(item) => {
              const formValue = _.filter(
                values.specification_subgroup_ids,
                (specification_subgroup_id) =>
                  item?.groupHeading
                    ? !_.includes(item.value, specification_subgroup_id)
                    : specification_subgroup_id != item.value
              );

              setFormValue(
                'superadmin.add_note_wizard.add_note',
                'specification_subgroup_ids',
                formValue
              );
            }}
            header={
              <div className={styles.dropdownHeader}>
                <Tooltip
                  text={__('note.specification_subgroup.header')}
                  className={styles.name}
                >
                  {__('note.specification_subgroup.header')}
                </Tooltip>

                <span
                  className={styles.clear}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.clearFilter();
                  }}
                >
                  {__('note.specification_subgroup.clear')}
                </span>
              </div>
            }
          >
            <div className={styles.label}>
              <label htmlFor={'specification_subgroup_ids'}>
                <Tooltip text={__('note.specification_subgroup.label')}>
                  {__('note.specification_subgroup.label')}
                </Tooltip>
              </label>
            </div>

            <div className={styles.selectGroup}>
              <div className={styles.select}>
                {_.join(
                  _.map(
                    _.filter(specification_subgroup_options, (subgroup) =>
                      _.includes(
                        values?.specification_subgroup_ids,
                        subgroup.value
                      )
                    ),
                    'label'
                  ),
                  ', '
                )}
              </div>

              <ArrowDownMiddleIcon />
            </div>
            <div className={styles.bar} />
          </SearchableDropdown>
          {/* --- --- */}
          <Field
            name='note_category_id'
            searchable
            component={Select}
            label='Instruction Category'
            options={note_categories}
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('superadmin_notes', {
                add_note_wizard: undefined,
              })
            }
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              middleText={__('button.create')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(false)}
            />
            <Button
              lightBlue
              medium
              middleText={__('button.create-edit')}
              spinning={this.props.submitting}
              onClick={() => this._handleSubmit(true)}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default AddNoteForm;

// const product_categories = _.sortBy(
//   _.values(this.props.localization.product_categories),
//   'priority'
// );

// const product_groups = _.sortBy(
//   _.map(this.props.localization.product_groups, (product_group) => ({
//     label: product_group.name,
//     value: product_group.id,
//     groupPriority: _.get(this.props.localization.product_categories, [
//       product_group.product_category_id,
//       'priority',
//     ]),
//     group: _.get(this.props.localization.product_categories, [
//       product_group.product_category_id,
//       'name',
//     ]),
//   })),
//   'groupPriority'
// );

// const product_subgroups = _.map(
//   this.props.localization.product_subgroups,
//   (product_subgroup) => {
//     const product_group = _.get(
//       this.props.localization.product_groups,
//       product_subgroup.product_group_id,
//       {}
//     );

//     return {
//       label: product_subgroup.name,
//       value: product_subgroup.id,
//       group: [
//         _.get(this.props.localization.product_categories, [
//           product_group.product_category_id,
//           'name',
//         ]),
//         product_group.name,
//       ].join(' / '),
//     };
//   }
// );

{
  /* <Field
            name='product_category_ids'
            searchable
            component={MultiSelect}
            label='Assigned Product Categories'
            noSort
            options={transformToListPairs(product_categories)}
          />
          <Field
            name='product_group_ids'
            searchable
            component={MultiSelect}
            label='Assigned Product Groups'
            noSort
            options={product_groups}
          />
          <Field
            name='product_subgroup_ids'
            searchable
            component={MultiSelect}
            label='Assigned Product Subgroups'
            options={product_subgroups}
          /> */
}
