import * as React from "react";
import { Component } from "react";
import styles from "../../styles/flyouts/working_set_plans/WorkingSetPlansFlyout.scss";
import * as _ from "lodash";
import { get } from "lodash";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import classNames from "classnames";
import Properties from "./Properties";
import { connect } from "react-redux";
import History from "./History";
import Versions from "./Versions";
import { setNotification } from "../../redux/actions/general/notification";
import { __ } from "../../core/utils";
import DeliveryIcon from "../../assets/images/delivery-15x15.svg";
import { withRouter } from "react-router";
import Tooltip from "../../components/Tooltip";
import Files from "./Files";
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      setTableParams: params =>
        dispatch({
          type: "table.working_set_plans/SET_TABLE_PARAMS",
          params
        })
    }
  };
};

@connect(null, mapDispatchToProps)
@withRouter
class WorkingSetPlansFlyout extends Component {
  constructor (props) {
    super(props);

    this.state = {
      active: 'properties',
      collapsed_tabs: [], //fix for scrollSpy not working on init
      highlighted_tab: null,
    }

    this.highlight_timeout = null;

    this.tabs = [
      'properties',
      'versions',
      'files',
      'history'
    ];

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  render() {
    const { data, readAction } = this.props;

    const { collapsed_tabs, highlighted_tab } = this.state;

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          props.target == 'history' && this._lazyLoadTaskHistory();

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>{data.code && <strong>{data.code}: </strong>}{data.title}</span>
            <CloseBigIcon onClick={() => setTimeout(() => {
              this.props.actions.setTableParams({
                clicked_row: undefined
              })
            }, 100)} />
          </div>
          <div className={styles.actions}>
            <div className={styles.actionWrapper}>
              {this.props.match.params.stage && _.get(data, 'is_editor') && _.get(data, 'status') == '1' && <Tooltip text={__('plans.tooltip.icon.working-set.plan-deliver')}><DeliveryIcon
                onClick={() => {
                  if (get(data, 'delivery')) {
                    setNotification({
                      text: __("plans.working-set.notification.already-delivered"),
                      type: "warning"
                    });
                  } else {
                    this.props.actions.setTableParams({
                      actioned_row: undefined,
                      wizard_active: true,
                      selected_plans: [get(data, 'id')]
                    });
                  }
                }}
              /></Tooltip>}
            </div>
          </div>
        </div>
        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>{__('plans.current-set.tab.properties')}</Tab>
            <Tab target='versions'>{__('plans.working-set.tab.versions')}</Tab>
            <Tab target='files'>{__('plans.working-set.tab.files')}</Tab>
            <Tab target='history'>{__('plans.working-set.tab.history')}</Tab>
          </div>
        </div>
        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('plans.current-set.tab.properties')}
            </div>
            <Properties
              collapsed={_.includes(collapsed_tabs, 'properties')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='versions'
            className={classNames(
              styles.section,
              highlighted_tab == 'versions' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('versions')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'versions') && styles.collapsed
                )}
              />
              {__('plans.working-set.tab.versions')}
            </div>
            <Versions
              collapsed={_.includes(collapsed_tabs, 'versions')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {__('plans.working-set.tab.files')}
            </div>
            <Files
              collapsed={_.includes(collapsed_tabs, 'files')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='history'
            className={classNames(
              styles.section,
              highlighted_tab == 'history' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('history')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'history') && styles.collapsed
                )}
              />
              {__('plans.working-set.tab.history')}
            </div>
            <History
              collapsed={_.includes(collapsed_tabs, 'history')}
              data={data}
              readAction={readAction}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default WorkingSetPlansFlyout;
