const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "general.lightbox/SHOW_LIGHTBOX":
            return {
                images: action.images,
                active: action.active
            };
        case "general.lightbox/HIDE_LIGHTBOX":
            return {};
        default:
            return state;
    }
}