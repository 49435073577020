import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_plans/Files.scss';
import { withRouter } from 'react-router';
import { __, dateTimeFrom, scrollToBottom } from '../../core/utils';
import DownloadIcon from '../../assets/images/download-15x15.svg';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { setNotification } from '../../redux/actions/general/notification';
import FilePreview from '../../components/FilePreview';
import classNames from "classnames";

@withRouter
class Files extends Component {
  componentDidMount() {
    scrollToBottom(this.refs.scroll);
  }

  @autobind
  _downloadFile(row, download = false) {
    if (row.ext == 'pdf' && download == false) {
      window.open(row.stream_url, '_blank');
    } else {
      setNotification(__('current_set_plans.files.download.continue'));

      window.open(row.download_url, '_blank');
    }
  }

  render() {
    const { data, collapsed } = this.props;

    const lightbox_images = _.map(
      _.filter(data.files, (file) =>
        _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
      ),
      (file) => ({
        id: file.id,
        stream_url: file.stream_url,
        title: [file.name, file.ext].join('.'),
      })
    );

    return (
      <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
        <div className={styles.scroller} ref='scroll'>
          {_.map(data.files, (row) => (
            <div className={styles.row} key={row.id}>
              <div className={styles.left}>
                <FilePreview
                  onDownloadFile={this._downloadFile}
                  row={row}
                  lightbox_images={lightbox_images}
                />
              </div>
              <div className={styles.right}>
                {row.created_by} · {dateTimeFrom(row.created_at)}
                <DownloadIcon onClick={() => this._downloadFile(row, true)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Files;
