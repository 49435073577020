import React, { useState, useEffect } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/Quantity.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  formatMoney,
  getActiveStage,
  getLocalized,
  redirect,
} from '../../core/utils';
import Tooltip from '../../components/Tooltip';
import { getBOQArea } from '../../redux/actions/table/working_set_boq';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import { connect } from 'react-redux';
import QuantityForm from '../../forms/current_set_specifications/QuantityForm';

const Quantities = (props) => {
  const [space_allocation, set_space_allocation] = useState([]);
  const [collapsed_items, set_collapsed_items] = useState([]);

  useEffect(() => {
    const {
      flyout: { stage_id },
    } = props.store;

    getBOQArea(stage_id).then(({ response }) => {
      set_space_allocation(response.data);
    });
  }, []);

  // TOOGLE //
  const _toggleMasterCollapse = () => {
    set_collapsed_items(!collapsed_items);

    const collapsable_boq_calculation = _.filter(
      props.store.flyout.quantity.boq_calculations,
      (calculation) => !_.isEmpty(calculation.positions)
    );

    if (_.size(collapsable_boq_calculation) == _.size(collapsed_items)) {
      set_collapsed_items([]);
    } else {
      set_collapsed_items(_.map(collapsable_boq_calculation, 'id'));
    }
  };

  const _toggleCollapse = (calculation_id) => {
    set_collapsed_items(_.xor(collapsed_items, [calculation_id]));
  };

  const { collapsed, store, localization } = props;
  const { flyout, language_id } = store;
  const { units } = localization;

  const space_allocation_data = [];

  _.each(space_allocation.sites, (site) =>
    space_allocation_data.push({
      id: site.id,
      type: 'site',
      display_name: site.code + ': ' + getLocalized(site.title, language_id),
      parent_type: null,
      parent_id: null,
    })
  );

  _.each(space_allocation.buildings, (building) =>
    space_allocation_data.push({
      id: building.id,
      type: 'building',
      display_name:
        building.code + ': ' + getLocalized(building.title, language_id),
      parent_type: building.site_id ? 'site' : null,
      parent_id: building.site_id ? building.site_id : null,
    })
  );

  _.each(space_allocation.storeys, (storey) =>
    space_allocation_data.push({
      id: storey.id,
      type: 'storey',
      display_name:
        storey.number + ': ' + getLocalized(storey.name, language_id),
      parent_type: storey.building_id ? 'building' : null,
      parent_id: storey.building_id ? storey.building_id : null,
    })
  );

  _.each(space_allocation.rooms, (room) =>
    space_allocation_data.push({
      id: room.id,
      type: 'room',
      display_name: room.code + ': ' + getLocalized(room.name, language_id),
      parent_type: room.storey_id ? 'storey' : null,
      parent_id: room.storey_id ? room.storey_id : null,
    })
  );

  _.each(space_allocation.expansion_joints, (expansion_joint) =>
    space_allocation_data.push({
      id: expansion_joint.id,
      type: 'expansion_joint',
      display_name: getLocalized(expansion_joint.title, language_id),
    })
  );

  _.each(space_allocation.room_types, (room_type) =>
    space_allocation_data.push({
      id: room_type.id,
      type: 'room_type',
      display_name: getLocalized(room_type.title, language_id),
    })
  );

  _.each(space_allocation.entrances, (entrance) =>
    space_allocation_data.push({
      id: entrance.id,
      type: 'entrance',
      display_name: getLocalized(entrance.title, language_id),
    })
  );

  _.each(space_allocation.zones, (zone) =>
    space_allocation_data.push({
      id: zone.id,
      type: 'zone',
      display_name: getLocalized(zone.title, language_id),
    })
  );

  _.each(space_allocation.room_groups, (room_group) =>
    space_allocation_data.push({
      id: room_group.id,
      type: 'room_group',
      display_name: getLocalized(room_group.title, language_id),
    })
  );

  const collapsable_boq_calculation = _.filter(
    flyout.quantity.boq_calculations,
    (calculation) => !_.isEmpty(calculation.positions)
  );

  return (
    <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
      <QuantityForm
        language_id={language_id}
        initialValues={{
          ..._.pick(flyout.quantity, [
            'unit_id',
            'budget_type',
            'installation_type',
            'boq_supply_type_group_id',
          ]),
        }}
      />

      {/* --- HEADER --- */}
      <div className={classNames(styles.row, styles.header)}>
        <span className={styles.expand} />
        <span className={styles.descriptionAndArea}>
          {__(
            'current-set-specifications-flyout.specification-quantity.descriptionAndArea'
          )}
        </span>
        <span className={styles.areaCount}>
          {__(
            'current-set-specifications-flyout.specification-quantity.area-count'
          )}
        </span>
        <span className={styles.quantity}>
          {__(
            'current-set-specifications-flyout.specification-quantity.quantity'
          )}
        </span>
        <span className={styles.subQuantity}>
          {__(
            'current-set-specifications-flyout.specification-quantity.sub_quantity'
          )}
        </span>
        <span className={styles.unit}>
          {__('current-set-specifications-flyout.specification-quantity.unit')}
        </span>
      </div>
      {/* --- FIRST BODY ROW - TOTAL --- */}

      <div className={classNames(styles.row, styles.calculation)}>
        <span className={styles.expand}>
          <ArrowDownMiddleIcon
            className={classNames(
              _.size(collapsable_boq_calculation) != _.size(collapsed_items) &&
                styles.active
            )}
            onClick={_toggleMasterCollapse}
          />
        </span>
        <span className={styles.name}>
          {__('current-set-specifications-flyout.specification-quantity.total')}
        </span>
        <span className={styles.areaCount} />

        <span className={styles.quantity} />

        <span className={styles.subQuantity}>
          {formatMoney(
            _.sumBy(
              flyout?.quantity?.boq_calculations,
              (calculation) => calculation.quantity
            )
          )}
        </span>

        <span className={styles.unit}>
          {_.get(units, [flyout.quantity.unit_id, 'symbol'])}
        </span>
      </div>

      {_.map(
        _.sortBy(flyout.quantity.boq_calculations, (calculation) =>
          calculation?.item?.id ? calculation?.id : Infinity
        ),
        (calculation, i) => {
          return (
            <>
              <div className={styles.row} key={i}>
                <span className={styles.expand}>
                  <ArrowDownMiddleIcon
                    className={classNames(
                      !_.includes(collapsed_items, calculation.id) &&
                        styles.active,
                      _.isEmpty(calculation.positions) && styles.faded
                    )}
                    onClick={() =>
                      !_.isEmpty(calculation.positions) &&
                      _toggleCollapse(calculation.id)
                    }
                  />
                </span>
                <span className={styles.name}>
                  {getLocalized(calculation?.boq_item_name, language_id)}
                </span>
                <span className={styles.areaCount} />

                <span className={styles.quantity} />

                <span className={styles.subQuantity}>
                  {formatMoney(calculation.quantity)}
                </span>

                <span className={styles.unit}>
                  {calculation.unit_id && (
                    <span>{_.get(units, [calculation.unit_id, 'symbol'])}</span>
                  )}
                  {!calculation.unit_id && (
                    <span className={styles.none}>--</span>
                  )}
                </span>
              </div>
              {!_.includes(collapsed_items, calculation.id) &&
                _.map(calculation.positions, (position, p) => {
                  let area;

                  if (position.area_id) {
                    area =
                      _.find(space_allocation_data, {
                        type: position.area_type,
                        id: position.area_id,
                      }) || {};
                  }

                  return (
                    <div
                      className={classNames(styles.row, styles.position)}
                      key={p}
                    >
                      <span className={styles.expand}>{p + 1}</span>

                      <span className={styles.descriptionAndArea}>
                        <span className={styles.area}>
                          <div
                            className={classNames(
                              styles.dropdown,
                              styles.areaDropdown
                            )}
                          >
                            {area ? (
                              <>
                                <Tooltip text={area.display_name}>
                                  <span>{area.display_name}</span>
                                </Tooltip>
                                <div
                                  onClick={(e) => {
                                    e.preventDefault();
                                    redirect(
                                      `/stages/${getActiveStage()}/space_allocation/${
                                        area.type
                                      }s?id=${area.id}`
                                    );
                                  }}
                                >
                                  <ArrowDoubleRightSmallIcon />
                                </div>
                              </>
                            ) : (
                              <span className={styles.none}>--</span>
                            )}
                          </div>
                        </span>
                        <span className={styles.description}>
                          {getLocalized(position.description, language_id)}
                        </span>
                      </span>
                      <span className={styles.areaCount}>
                        {position.area_count}
                      </span>
                      <span className={styles.quantity}>
                        {formatMoney(position.quantity)}
                      </span>
                      <span className={styles.subQuantity}>
                        {formatMoney(position.quantity * position.area_count)}
                      </span>
                      <span className={styles.unit}>
                        {flyout.quantity.unit_id && (
                          <span>
                            {_.get(units, [flyout.quantity.unit_id, 'symbol'])}
                          </span>
                        )}
                        {!flyout.quantity.unit_id && (
                          <span className={styles.none}>--</span>
                        )}
                      </span>
                    </div>
                  );
                })}
            </>
          );
        }
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    store: state.table.current_set_specifications,
  };
}

export default connect(mapStateToProps)(
  subscribe(Localization, 'localization')(Quantities)
);
