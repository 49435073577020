import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { enableAutosave } from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/products/PriceForm.scss";
import classNames from "classnames";
import Input from "../../components/Input";
import { __, mapStateToProps } from "../../core/utils";
import Textarea from "../../components/Textarea";
import MultiSelect from "../../components/MultiSelect";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/Select";
import DateTimePicker from "../../components/DateTimePicker";

@reduxForm({
    form: "products.price",
    enableReinitialize: true,
})
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    store: state.table.products
}))
class PriceForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, collapsed } = this.props;
        const { language_id } = this.props.store;

        const units = _.sortBy(_.map(this.props.localization.units, unit => ({
            label: unit.symbol,
            value: unit.id,
            tooltip: unit.description,
            group: _.get(this.props.localization.unit_groups, [unit.unit_group_id, 'name', 1]),
            groupPriority: _.get(this.props.localization.unit_groups, [unit.unit_group_id, 'priority']),
        })), 'groupPriority');

        return (
            <Form onSubmit={handleSubmit} className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="price_from" component={Input} numeric decimal maxLength={8} label={__('products.column.price-from')} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="price_to" component={Input} numeric decimal maxLength={8} label={__('products.column.price-to')} />
                    </div>
                    <div className={styles.f2}>
                        <Field name="currency_id" component={Select} searchable label={__('products.column.currency')} options={_.map(this.props.localization.currencies, currency => ({
                            label: [currency.code, currency.name].join(' - '),
                            value: currency.id
                        }))} />
                    </div>
                    <div className={styles.f2}>
                        <Field name="price_date" component={DateTimePicker} footer searchable label={__('products.column.price-date')} />
                    </div>
                </div>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="units" component={MultiSelect} searchable label={__('products.column.units-of-measure')} noSort options={units} />
                    </div>
                    <div className={styles.f2}>
                        <Field name={"price_note." + language_id} component={Textarea} label={__('products.column.price-instruction')} maxLength={500} rows={1} maxRows={10} />
                    </div>
                </div>
                <div className={styles.discontinued}>
                    <Field name="discontinued" component={Checkbox} label={__('products.column.discontinued')} />
                </div>
            </Form>
        );
    }
}

export default PriceForm;
