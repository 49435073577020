import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/boq/BOQFlyout.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { __, getURLParam, setTableParams } from '../../core/utils';
import Properties from './Properties';
import Quantities from './Quantities';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Tooltip from '../../components/Tooltip';
import CopyIcon from '../../assets/images/copy-16x16.svg';
import { updateBOQCalculationTags } from '../../redux/actions/table/working_set_boq';
import { setNotification } from '../../redux/actions/general/notification';
import TagsForm from '../../forms/boq/TagsForm';
import History from './History';
import SaveAsTemplateIcon from '../../assets/images/boq-save-as-template-16x16.svg';
import LoadFromTemplateIcon from '../../assets/images/boq-load-from-template-16x16.svg';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.working_set_boq/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(
  (state) => ({
    user_id: state.auth.id,
    flyout: state.table.working_set_boq.flyout,
    auth: state.auth,
  }),
  mapDispatchToProps
)
@withRouter
class BOQCalculationFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed_tabs: [],
      highlighted_tab: null,
    };

    this.highlight_timeout = null;

    this.tabs = ['properties', 'quantity', 'tags'];

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  @autobind
  _handleTagsSubmit(formData) {
    updateBOQCalculationTags(this.props.flyout.id, formData).then(() => {
      setNotification(__('boq-flyout.boq-calculation-tags-saved'));

      this.props.readAction(this.props.flyout.id);
    });
  }

  render() {
    const { collapsed_tabs, highlighted_tab } = this.state;

    const { flyout, readAction, project_languages, auth } = this.props;

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              {_.get(
                flyout?.name,
                _.findKey(project_languages, (primary) => primary == 1)
              )}
            </span>
            <CloseBigIcon
              onClick={() =>
                setTimeout(() => {
                  this.props.actions.setTableParams({
                    clicked_row: undefined,
                  });
                }, 100)
              }
            />
          </div>

          <div className={styles.actions}>
            {!_.isEmpty(flyout?.name) && (
              <Tooltip
                text={__(
                  'boq-calculation.flyout.save-boq-calculation-as-template'
                )}
                key={_.uniqueId()}
              >
                <SaveAsTemplateIcon
                  className={styles.iconDisabled}
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('working_set_boq', {
                      add_boq_template_calculation_wizard: flyout.id,
                    });
                  }}
                />
              </Tooltip>
            )}
            {!_.isEmpty(flyout?.name) && (
              <Tooltip
                text={__(
                  'boq-calculation.flyout.add-boq-calculation-from-template'
                )}
                key={_.uniqueId()}
              >
                <LoadFromTemplateIcon
                  className={styles.iconDisabled}
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('working_set_boq', {
                      add_boq_calculation_from_template_wizard: flyout.id,
                    });
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>

        <div className={styles.toolbar}>
          {auth.company.id == flyout?.created_by?.company_id && (
            <Tooltip
              placement='left'
              text={__(
                'boq-calculation.flyout.boq-calculation-created-company'
              )}
            >
              <div className={classNames(styles.baloon, styles.myCompany)}>
                {__('boq-calculation.working-set.flyout.source-company')}
              </div>
            </Tooltip>
          )}

          {auth.company.id != flyout?.created_by?.company_id && (
            <Tooltip
              placement='left'
              text={__(
                'boq-calculation.flyout.boq-calculation-created-your-partners-company'
              )}
            >
              <div className={classNames(styles.baloon, styles.partnerCompany)}>
                {__('boq-calculation.flyout.source-partner-company')}
              </div>
            </Tooltip>
          )}
        </div>

        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>{__('boq.flyout.properties')}</Tab>

            <Tab target='quantity'>{__('boq.flyout.quantity')}</Tab>

            <Tab target='tags'>{__('boq.flyout.tags')}</Tab>

            <Tab target='history'>{__('boq.flyout.history')}</Tab>
          </div>
        </div>

        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('boq.flyout.properties')}
            </div>
            <Properties
              data={flyout}
              readAction={readAction}
              collapsed={_.includes(collapsed_tabs, 'properties')}
            />
          </Element>
          <Element
            name='quantity'
            className={classNames(
              styles.section,
              highlighted_tab == 'quantity' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('quantity')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'quantity') && styles.collapsed
                )}
              />
              {__('boq.flyout.quantity')}
            </div>

            <Quantities
              collapsed={_.includes(collapsed_tabs, 'quantity')}
              readAction={readAction}
              flyout={flyout}
            />
          </Element>
          <Element
            name='tags'
            className={classNames(
              styles.section,
              highlighted_tab == 'tags' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('tags')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'tags') && styles.collapsed
                )}
              />
              {__('boq.flyout.tags')}
            </div>
            <TagsForm
              collapsed={_.includes(collapsed_tabs, 'tags')}
              onSubmit={this._handleTagsSubmit}
              initialValues={_.pick(flyout, ['tags'])}
            />
          </Element>
          <Element
            name='history'
            className={classNames(
              styles.section,
              highlighted_tab == 'history' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('history')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'history') && styles.collapsed
                )}
              />
              {__('task.flyout.history')}
            </div>
            <History
              collapsed={_.includes(collapsed_tabs, 'history')}
              data={flyout}
            />
          </Element>
        </div>
      </div>
    );
  }
}

export default BOQCalculationFlyout;
