import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { mapStateToProps } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import { withRouter } from "react-router";
import Localization from "../../../helpers/Localization";
import { subscribe } from "react-contextual";

@subscribe(Localization, "localization")
@reduxForm({
	form: "superadmin_boq_supply_type_groups.edit_boq_supply_type_group",
	enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
}))
class EditBOQSupplyTypeGroupForm extends Component {
	UNSAFE_componentWillReceiveProps() {
		enableAutosave(this.props.form);
	}

	render() {
        const { handleSubmit, superadmin, localization } = this.props;
        
		const application_languages = _.transform(_.filter(localization.languages, language => language.application_language == true), (languages, language) => {
			languages[language.id] = language.id == 1;

			return languages;
		}, {});

		return (
			<Form onSubmit={handleSubmit}>
				<Field disabled={!superadmin.edit_boq_supply_type_group} localized={application_languages} name="name" component={Input} label="Name" />
			</Form>
		);
	}
}

export default EditBOQSupplyTypeGroupForm;