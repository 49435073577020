import store from "../../core/store";

export const readProjectGeneral = (project_id) => {
    return store.dispatch({
        action: "projects/READ_PROJECT_GENERAL",
        method: "GET",
        endpoint: "/projects/" + project_id + "/general",
    });
};

export const uploadProjectThumbnail = (project_id, thumbnail) => {
    return store.dispatch({
        action: "companies/UPLOAD_LOGO",
        method: "POST",
        endpoint: "/projects/" + project_id + "/thumbnail",
        data: {
            thumbnail
        }
    });
};

export const updateProjectGeneral = (project_id, formData) => {
    return store.dispatch({
        action: "projects/UPDATE_PROJECT_GENERAL",
        method: "PUT",
        endpoint: "/projects/" + project_id + "/general",
        data: formData
    });
};

export const listPossibleProjectAdministrators = (project_id) => {
    return store.dispatch({
        action: "projects/LIST_POSSIBLE_PROJECT_ADMINISTRATORS",
        method: "GET",
        endpoint: "/projects/" + project_id + "/possible_administrators",
    });
};

export const getProjectPermissions = (project_id) => {
    return store.dispatch({
        action: "projects/GET_PROJECT_PERMISSIONS",
        method: "GET",
        endpoint: "/projects/" + project_id + "/permissions"
    });
};

export const listPossibleProjectDesigners = (project_id, designer_id = null) => {
    return store.dispatch({
        action: "projects/LIST_POSSIBLE_PROJECT_DESIGNERS",
        method: "POST",
        endpoint: "/projects/" + project_id + "/possible_designers",
        data: {
            designer_id
        }
    });
};

export const listPossibleProjectClients = (project_id, data) => {
    return store.dispatch({
        action: "projects/LIST_POSSIBLE_PROJECT_CLIENTS",
        method: "POST",
        endpoint: "/projects/" + project_id + "/possible_clients",
        data
    });
};