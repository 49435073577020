import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddTranslationWizard.scss";
import autobind from "autobind-decorator";
import AddTranslationIcon from "../../assets/images/add-translation-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import {createTranslation, listTranslations} from "../../redux/actions/table/superadmin/translations";
import AddTranslationForm from "../../forms/superadmin/add_translations_wizard/AddTranslationForm";
import {listLocalization} from "../../redux/actions/localization";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_translations"],
}))
class AddTranslationWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;
        
        return validateForm(
            createTranslation(formData).then(({response}) => {
                listTranslations().then(() => {
                    if(edit) {
                        setTableParams('superadmin_translations', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_translations', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                listLocalization();

                this.props.onClose();

                setNotification(__('superadmin.add-translation.notification.you-have-successfully-added-new-translation'));
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('button.add-translation')}</span>
                        <span>{__('superadmin.translations.add-translation.add-translation-application')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddTranslationIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddTranslationForm onSubmit={this._handleSubmit} initialValues={{
                        translations: {}
                    }} />
                </div>
            </>
        );
    }
}

export default AddTranslationWizard;