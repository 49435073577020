import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/products/DetailsForm.scss";
import classNames from "classnames";
import Input from "../../components/Input";
import {__, mapStateToProps} from "../../core/utils";
import Textarea from "../../components/Textarea";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import MultiSelect from "../../components/MultiSelect";
import * as _ from "lodash";
import Tags from "../../components/Tags";
import {listPossibleTags} from "../../redux/actions/general";
import autobind from "autobind-decorator";

@reduxForm({
    form: "products.details",
    enableReinitialize: true,
})
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    store: state.table.products,
    auth: state.auth
}))
class DetailsForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    constructor(props) {
        super(props);

        this.state = {
            families: [],
            designers: [],
            collections: []
        }
    }

    @autobind
    _loadTags() {
        listPossibleTags('product_family').then(({response}) => {
            this.setState({families: response.data});
        });

        listPossibleTags('product_collection').then(({response}) => {
            this.setState({collections: response.data});
        });

        listPossibleTags('product_designer').then(({response}) => {
            this.setState({designers: response.data});
        });
    }

    componentDidMount() {
        this._loadTags();
    }

    render() {
        const {families, designers, collections} = this.state
        const {handleSubmit, collapsed, auth} = this.props;
        const {language_id} = this.props.store;

        return (
            <Form onSubmit={handleSubmit} className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                <Field name={"description_long." + language_id} component={Textarea} label={__('products.column.long-description')} maxLength={2000} rows={1} maxRows={10} />
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="family" availableText={'Available Families'} component={Tags} label={__('company-settings.submenu.brands.flyout.family')} options={families} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="designer" availableText={'Available Designers'} component={Tags} label={__('company-settings.submenu.brands.flyout.designer')} options={designers} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="collection" availableText={'Available Collections'} component={Tags} label={__('company-settings.submenu.brands.flyout.collection')} options={collections} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                    </div>
                </div>
            </Form>
        );
    }
}

export default DetailsForm;
