import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {mapStateToProps} from "../../../core/utils";
import {enableAutosave} from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import {withRouter} from "react-router";

@reduxForm({
    form: "superadmin_paper_sizes.edit_paper_size",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
class EditPaperSizeForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {handleSubmit, superadmin} = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_paper_size} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_paper_size} name="width" component={Input} numeric decimal label="Width" />
                <Field disabled={!superadmin.edit_paper_size} name="height" component={Input} numeric decimal label="height" />
            </Form>
        );
    }
}

export default EditPaperSizeForm;