import * as React from "react";
import {Component} from "react";
import styles
    from "../../../styles/flyouts/superadmin/product_attribute_template_fields/ProductAttributeTemplateFieldsFlyout.scss";
import * as _ from "lodash";
import CloseBigIcon from "../../../assets/images/close-big-15x15.svg";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../../core/utils";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../../helpers/Localization";
import {setNotification} from "../../../redux/actions/general/notification";
import withRouter from "react-router/es/withRouter";
import Tooltip from "../../../components/Tooltip";
import DeleteIcon from "../../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../../redux/actions/general/alertbox";
import classNames from "classnames";
import Properties from "./Properties";
import {
    deleteProductAttributeTemplateFields,
    listProductAttributeTemplateFields
} from "../../../redux/actions/table/superadmin/product_attribute_template_fields";

@subscribe(Localization, "localization")
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    auth: state.auth,
}))
class ProductAttributeTemplateFieldsFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: 'properties'
        }
    }

    render() {
        const {data, readAction, superadmin, auth} = this.props;

        return (
            <div className={styles.preview}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <span>
                            {getLocalized(data.label, auth.language_id)}
                        </span>
                        <CloseBigIcon onClick={() => setTableParams('superadmin_product_attribute_template_fields', {
                            clicked_row: undefined
                        })}/>
                    </div>
                    <div className={styles.actions}>
                        {superadmin.delete_product_attribute_template_field && <div className={styles.actionWrapper}>
                            <Tooltip text={__('products.flyout.attribute-template.delete-product-attribute-template-field')} key={_.uniqueId()}>
                                <DeleteIcon
                                    onClick={e => {
                                        e.stopPropagation();

                                        showAlertbox({
                                            color: "red",
                                            title: __('general.alert.are-you-sure'),
                                            description: __('products.flyout.attribute-template.want-delete-selected-product-attribute-template-field.process-cannot-undone'),
                                            buttons: [
                                                {
                                                    color: 'lightGray',
                                                    text: __('general.alert.no-close'),
                                                    onClick: 'close'
                                                },
                                                {
                                                    color: 'gray',
                                                    text: __('general.alert.yes-delete'),
                                                    onClick: () => {
                                                        deleteProductAttributeTemplateFields([data.id]).then(() => {
                                                            hideAlertbox();

                                                            setNotification(__('products.flyout.attribute-template.selected-product-attribute-template-field-deleted'));
                                                            listProductAttributeTemplateFields();

                                                            setTableParams('superadmin_product_attribute_template_fields', {
                                                                clicked_row: undefined
                                                            });
                                                        });
                                                    },
                                                }
                                            ]
                                        })
                                    }}
                                />
                            </Tooltip>
                        </div>}
                    </div>
                </div>
                <div className={styles.tabs}>
                    <span className={classNames(this.state.active == 'properties' && styles.active)} onClick={() => this.setState({active: 'properties'})}>{__('products.flyout.attribute-template.properties')}</span>
                </div>
                {!_.isEmpty(data) && this.state.active == 'properties' && <Properties data={data} readAction={readAction} />}
            </div>
        );
    }
}

export default ProductAttributeTemplateFieldsFlyout;
