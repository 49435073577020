import store from "../../core/store";

export const getUniqueURL = (token) => {
    return store.dispatch({
        action: "general/GET_UNIQUE_URL",
        method: "GET",
        endpoint: "/url/" + token
    });
};

export const listPossibleTags = (context, context_id) => {
    return store.dispatch({
        action: "general/LIST_POSSIBLE_TAGS",
        method: "POST",
        endpoint: "/possible_tags/",
        data: {
            context,
            context_id
        }
    });
};

export const createTexture = (formData) => {
    return store.dispatch({
        action: "general/CREATE_TEXTURE",
        method: "POST",
        endpoint: "/textures",
        data: formData
    });
};