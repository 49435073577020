import React, { useEffect, useState } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/add_boq_calculation_from_template_wizard/AddBOQCalculationFromTemplateForm.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import { __, getLocalized } from '../../core/utils';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import BOQFilterChooser from '../../components/BOQFilterChooser';

const AttachTemplatesToBOQItemForm = ({
  handleSubmit,
  onClose,
  field_name,
  options,
  localization,
  language_id,
}) => {
  const { boq_supply_type_groups } = localization;

  const filters = [
    {
      name: 'boq_supply_type_group_id',
      options: _.map(boq_supply_type_groups, (item, i) => {
        return {
          key: item.id,
          value: item.id,
          label: getLocalized(item.name, language_id),
        };
      }),
    },
    {
      name: 'source',
      options: [
        {
          key: 1,
          value: 'system',
          label: __('working-set-boq.filter-item-source.system'),
        },
        {
          key: 2,
          value: 'company',
          label: __('working-set-boq.filter-item-source.company'),
        },
      ],
    },
  ];

  return (
    <Form onSubmit={handleSubmit} className={styles.wrapper}>
      <div className={styles.flex}>
        <div className={styles.f1}>
          <Field
            name={field_name}
            component={BOQFilterChooser}
            label={__('working-set-boq.attach-templates-to-boq.boq-chooser')}
            options={options}
            searchable
            level={3}
            filters={filters}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          lightGray
          medium
          middleText={__('button.cancel')}
          onClick={onClose}
        />
        <Button
          lightBlue
          medium
          middleText={__('button.add')}
          onClick={handleSubmit}
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  return {
    language_id: state.table.working_set_boq.language_id,
  };
};

export default reduxForm({
  form: 'attach_templates_to_boq_item_wizard.attach_templates_to_boq_item',
})(
  connect(
    mapStateToProps,
    null
  )(subscribe(Localization, 'localization')(AttachTemplatesToBOQItemForm))
);
