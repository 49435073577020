import React, { useState, useEffect } from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/copy_specifications_wizard/CopySpecificationsForm.scss';
import Button from '../../components/Button';
import {
  __,
  getLocalized,
  setTableParams,
  getActiveProject,
  getActiveStage,
  usePreviousProps,
} from '../../core/utils';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import {
  listPossibleSpecificationStages,
  listPossibleSpecificationTeams,
} from '../../redux/actions/table/working_set_specifications';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import RadioGroup from '../../components/RadioGroup';
import ArrowRightMiddleIcon from '../../assets/images/arrow-right-middle-15x15.svg';
import { connect } from 'react-redux';
import ButtonGroup from '../../components/ButtonGroup';

const CopySpecificationsForm = (props) => {
  const [possible_teams, set_possible_teams] = useState([]);
  const [possible_stages, set_possible_stages] = useState([]);

  const prevProps = usePreviousProps(props);

  useEffect(() => {
    props.change('team_id', null);

    listPossibleSpecificationStages(_.get(props, 'values.project_id'))
      .then(({ response }) => {
        const stages = _.filter(
          response.data,
          (stage) => stage.id !== getActiveStage()
        );

        set_possible_stages(stages);

        if (_.size(stages) == 1) {
          props.change('stage_id', _.get(stages, [0, 'id'], null));
        }
      })
      .then(() =>
        listPossibleSpecificationTeams(_.get(props, 'values.stage_id')).then(
          ({ response }) => {
            set_possible_teams(response.data);

            if (_.size(response.data) == 1) {
              props.change('team_id', _.get(response.data, [0, 'id'], null));
            }
          }
        )
      );
  }, []);

  useEffect(() => {
    if (
      _.get(prevProps, 'values.project_id') &&
      _.get(prevProps, 'values.project_id') !=
        _.get(props, 'values.project_id') &&
      _.get(props, 'values.project_id')
    ) {
      listPossibleSpecificationStages(_.get(props, 'values.project_id')).then(
        ({ response }) => {
          const stages = _.filter(
            response.data,
            (stage) => stage.id !== getActiveStage()
          );

          set_possible_stages(stages);

          if (_.size(stages) == 1) {
            props.change('stage_id', _.get(stages, [0, 'id'], null));
          }
        }
      );

      props.change('stage_id', null);
      props.change('team_id', null);
    }

    if (
      _.get(prevProps, 'values.stage_id') != props.values.stage_id &&
      props.values.stage_id
    ) {
      props.change('team_id', null);

      listPossibleSpecificationTeams(props.values.stage_id).then(
        ({ response }) => {
          set_possible_teams(response.data);

          if (_.size(response.data) == 1) {
            props.change('team_id', _.get(response.data, [0, 'id'], null));
          }
        }
      );
    }
  }, [props]);

  const possible_projects = props.active_modules.items;

  const project = _.find(props.active_modules.items, [
    'id',
    getActiveProject(),
  ]);

  const stage = project
    ? _.find(project.stages, ['id', getActiveStage()])
    : null;

  return (
    <Form onSubmit={props.handleSubmit} className={styles.wrapper}>
      {stage && (
        <div className={styles.location}>
          <span>
            <strong>
              {project.name} / {stage.name}
            </strong>
          </span>
        </div>
      )}

      <Field
        name='project_id'
        searchable={true}
        component={Select}
        label={__('specifications.working-set.copy-specification.project')}
        options={_.map(possible_projects, (project) => ({
          value: project.id,
          label: project.name,
        }))}
      />
      <Field
        name='stage_id'
        searchable={true}
        disabled={!props.values.project_id}
        component={Select}
        label={__('specifications.working-set.copy-specification.stage')}
        options={_.map(possible_stages, (stage) => ({
          value: stage.id,
          label: stage.name,
        }))}
      />
      <Field
        name='team_id'
        searchable={true}
        disabled={!props.values.stage_id}
        component={Select}
        label={__(
          'specifications.working-set.copy-specification.discipline-team'
        )}
        options={_.map(possible_teams, (team) => ({
          value: team.id,
          label:
            team.company.name +
            ' [' +
            [
              team.stage_discipline.code,
              team.stage_discipline.discipline_id == null
                ? team.stage_discipline.description + '*'
                : _.get(props.localization, [
                    'disciplines',
                    team.stage_discipline.discipline_id,
                    'name',
                  ]),
            ].join(': ') +
            ']',
        }))}
      />

      <Field
        name='copy_specifications_option'
        component={RadioGroup}
        options={[
          {
            label: __(
              'specifications.working-set.copy-specification.skip-option'
            ),
            value: '0',
          },
          {
            label: __(
              'specifications.working-set.copy-specification.replace-option'
            ),
            value: '1',
          },
        ]}
      />

      <div className={styles.copyOptionInfo}>
        * {__('specifications.working-set.copy-specification.info')}
      </div>

      <div className={styles.footer}>
        <ButtonGroup right>
          <Button
            lightGray
            medium
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                copy_specifications_wizard_active: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            submit
            middleText={__('button.next')}
            rightIcon={ArrowRightMiddleIcon}
            spinning={props.submitting}
          />
        </ButtonGroup>
      </div>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    values: getFormValues(
      'copy_specifications_wizard.copy_specification_details'
    )(state),
    application_language_id: state.auth.language_id,
    active_modules: state.general.active_modules,
  };
}

export default reduxForm({
  form: 'copy_specifications_wizard.copy_specification_details',
  destroyOnUnmount: false,
})(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(CopySpecificationsForm)
  )
);
