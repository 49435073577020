import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/MoveBOQItemWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setFormErrors,
  validateForm,
} from '../core/utils';
import MoveIcon from '../assets/images/move-64x64.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import AddSpecificationToCalculationForm from '../forms/add_specification_to_calculation_wizard/AddSpecificationToCalculationForm';
import {
  addSpecificationCodeToCalculation,
  moveBOQCalculation,
  moveBOQItem,
  moveBOQPosition,
  moveBOQSubgroup,
  readWorkingSetBOQ,
  reorderBOQCalculations,
  reorderBOQGroups,
  reorderBOQItems,
  reorderBOQPositions,
  reorderBOQSubgroups,
} from '../redux/actions/table/working_set_boq';
import MoveBOQItemForm from '../forms/move_boq_item_wizard/MoveBOQItemForm';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import _ from 'lodash';

const select_field_name = {
  0: 'boq_group_id',
  1: 'boq_subgroup_id',
  2: 'boq_item_id',
  3: 'boq_calculation_id',
  4: 'boq_position_id',
};
@mapStateToProps((state) => ({
  id: _.get(state.table, 'working_set_boq.move_boq_item_wizard.id'),
  level: _.get(state.table, 'working_set_boq.move_boq_item_wizard.level'),
  name: _.get(state.table, 'working_set_boq.move_boq_item_wizard.name'),
  store: state.table['working_set_boq'],
}))
@subscribe(Localization, 'localization')
@withRouter
class MoveBOQItemWizard extends Component {
  constructor(props) {
    super(props);

    const filtered_data = _.reject(this.props.store.data, (group) =>
      _.includes(group.id, 'UN')
    );
    const level = this.props.level;

    let options = [];

    if (level == 0) {
      options = _.map(filtered_data, (group) => {
        return {
          value: group.id,
          label:
            group.code +
            ' ' +
            getLocalized(_.get(group.name, this.props.store.language_id, '')),
        };
      });
    } else if (level == 1) {
      options = _.map(filtered_data, (group) => {
        return {
          value: group.id,
          label:
            group.code +
            ' ' +
            getLocalized(_.get(group.name, this.props.store.language_id, '')),
          children: _.map(group.children, (subgroup) => {
            return {
              value: subgroup.id,
              label:
                subgroup.code +
                ' ' +
                getLocalized(
                  _.get(subgroup.name, this.props.store.language_id, '')
                ),
              parentId: subgroup.parentId,
            };
          }),
        };
      });
    } else if (level == 2) {
      options = _.filter(
        _.map(filtered_data, (group) => {
          return {
            value: group.id,
            label:
              group.code +
              ' ' +
              getLocalized(_.get(group.name, this.props.store.language_id, '')),
            children: _.map(group.children, (subgroup) => {
              return {
                value: subgroup.id,
                label:
                  subgroup.code +
                  ' ' +
                  getLocalized(
                    _.get(subgroup.name, this.props.store.language_id, '')
                  ),
                parentId: subgroup.parentId,
                children: _.map(subgroup.children, (item) => {
                  return {
                    value: item.id,
                    label:
                      item.code +
                      ' ' +
                      getLocalized(
                        _.get(item.name, this.props.store.language_id, '')
                      ),
                    parentId: item.parentId,
                  };
                }),
              };
            }),
          };
        }),
        (data) => !_.isEmpty(data.children)
      );
    } else if (level == 3) {
      options = _.filter(
        _.map(filtered_data, (group) => {
          return {
            value: group.id,
            label:
              group.code +
              ' ' +
              getLocalized(_.get(group.name, this.props.store.language_id, '')),
            children: _.filter(
              _.map(group.children, (subgroup) => {
                return {
                  value: subgroup.id,
                  label:
                    subgroup.code +
                    ' ' +
                    getLocalized(
                      _.get(subgroup.name, this.props.store.language_id, '')
                    ),
                  parentId: subgroup.parentId,
                  children: _.map(subgroup.children, (item) => {
                    return {
                      value: item.id,
                      label:
                        item.code +
                        ' ' +
                        getLocalized(
                          _.get(item.name, this.props.store.language_id, '')
                        ),
                      parentId: item.parentId,
                      children: _.map(item.children, (calculation) => {
                        return {
                          value: calculation.id,
                          label:
                            calculation.code +
                            ' ' +
                            getLocalized(
                              _.get(
                                calculation.name,
                                this.props.store.language_id,
                                ''
                              )
                            ),
                          parentId: calculation.parentId,
                        };
                      }),
                    };
                  }),
                };
              }),
              (data) => !_.isEmpty(data.children)
            ),
          };
        }),
        (data) => !_.isEmpty(data.children)
      );
    }

    this.state = {
      options,
    };
  }

  @autobind
  _findNestedOptions(options, targetValue) {
    let foundOptions = null;

    _.each(options, (option) => {
      if (
        !foundOptions &&
        option.value?.toString() === targetValue?.toString()
      ) {
        foundOptions = options;
      }
      if (!foundOptions && option.children) {
        foundOptions = this._findNestedOptions(option.children, targetValue);
      }
    });

    return foundOptions;
  }

  @autobind
  _handleSubmit(formData) {
    const { level, id } = this.props;

    const reorder_actions = {
      0: reorderBOQGroups,
      1: reorderBOQSubgroups,
      2: reorderBOQItems,
      3: reorderBOQCalculations,
      4: reorderBOQPositions,
    };

    const move_actions = {
      1: moveBOQSubgroup,
      2: moveBOQItem,
      3: moveBOQCalculation,
      4: moveBOQPosition,
    };

    let destinationRows = _.cloneDeep(
      this._findNestedOptions(
        this.state.options,
        formData[select_field_name[level]]
      )
    );

    let sourceRows;
    let is_moved = true;

    if (!_.includes(id, 'UN')) {
      sourceRows = _.cloneDeep(this._findNestedOptions(this.state.options, id));

      is_moved =
        _.first(_.cloneDeep(sourceRows))?.parentId !=
        _.first(_.cloneDeep(destinationRows))?.parentId;
    }

    if (formData[select_field_name[level]]) {
      // Find the index where the element should be inserted in the destination array
      const indexToInsert = _.findIndex(
        destinationRows,
        (row) => row.value == formData[select_field_name[level]]
      );

      if (_.includes(id, 'UN')) {
        destinationRows.splice(
          indexToInsert + (formData.move_action == 'before' ? 0 : 1),
          0,
          ..._.map(
            _.filter(this.props.store.data, (item) => item.id == id),
            (item) => {
              return { ...item, value: item.id };
            }
          )
        );
      } else {
        // Find the index of the object in the source array
        const index = _.findIndex(sourceRows, (row) => row.value == id);

        if (!is_moved) {
          destinationRows.splice(index, 1);
        }

        destinationRows.splice(
          indexToInsert + (formData.move_action == 'before' ? 0 : 1),
          0,
          ...sourceRows.splice(index, 1)
        );
      }
    }

    const empty_parent_item = _.includes(id, 'UN')
      ? !(_.size(_.split(formData[select_field_name[level]], '-')) > 3)
      : _.size(_.split(formData[select_field_name[level]], '-')) !=
        _.size(_.split(id, '-'));

    const selected_item_id = empty_parent_item
      ? _.last(_.split(formData[select_field_name[level]], '-'))
      : _.nth(_.split(formData[select_field_name[level]], '-'), -2);

    const actions = (
      is_moved,
      level,
      {
        moveSourceId,
        moveDestinationItem,
        reorderSourceId,
        reorderDestionationItems,
      }
    ) => {
      if (is_moved) {
        return Promise.resolve(
          move_actions[level](moveSourceId, moveDestinationItem).then(() => {
            !empty_parent_item &&
              reorder_actions[level](reorderSourceId, reorderDestionationItems);
          })
        );
      } else {
        return Promise.resolve(
          reorder_actions[level](reorderSourceId, reorderDestionationItems)
        );
      }
    };

    const validation = validateForm(
      actions(is_moved, level, {
        moveSourceId: _.last(id.split('-')),
        moveDestinationItem: {
          [select_field_name[level - 1]]: selected_item_id,
        },
        reorderSourceId: level == 0 ? getActiveStage() : selected_item_id,
        reorderDestionationItems: _.map(destinationRows, (row) =>
          _.last(row.value.split('-'))
        ),
      }).then(() =>
        readWorkingSetBOQ(getActiveStage()).then(() => {
          const messages = {
            0: 'A group has been successfully reordered',
            1: 'A subgroup has been successfully moved to the selected group',
            2: 'An item has been successfully moved to the selected subgroup',
            3: 'A cost has been successfully moved to the selected item',
            4: 'An element has been successfully moved to the selected cost',
          };

          setNotification(messages[level]);

          this.props.onClose();
        })
      )
    );

    validation.catch(({ errors }) => {
      setFormErrors('move_boq_item_wizard.move_boq_item', {
        [select_field_name[level]]: ['Field required'],
      });
    });

    return validation;
  }

  render() {
    const { level, name } = this.props;

    const title = {
      0: __('working-set-boq.group.move'),
      1: __('working-set-boq.subgroup.move'),
      2: __('working-set-boq.item.move'),
      3: __('working-set-boq.cost.move'),
      4: __('working-set-boq.element.move'),
    };

    const description = {
      0: __('working-set-boq.group.move.description'),
      1: __('working-set-boq.subgroup.move.description'),
      2: __('working-set-boq.item.move.description'),
      3: __('working-set-boq.cost.move.description'),
      4: __('working-set-boq.element.move.description'),
    };

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{title[level]}</span>
            <span>{description[level]}</span>
          </div>
          <div className={styles.right}>
            <MoveIcon />
          </div>
        </div>
        <div className={styles.body}>
          <MoveBOQItemForm
            onSubmit={this._handleSubmit}
            initialValues={{ name, move_action: 'after' }}
            selectFieldName={select_field_name[level]}
            options={this.state.options}
          />
        </div>
      </>
    );
  }
}

export default MoveBOQItemWizard;
