import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/working_set_specifications/Images.scss';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import EditIcon from '../../assets/images/edit-16x16.svg';
import { setNotification } from '../../redux/actions/general/notification';
import {
  __,
  getActiveStage,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';

import { showLightbox } from '../../redux/actions/general/lightbox';
import { ReactSortable } from 'react-sortablejs';
import {
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
  reorderSpecificationImages,
} from '../../redux/actions/table/working_set_specifications';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';

@mapStateToProps((state) => ({
  flyout: state.table.working_set_specifications.flyout,
}))
class Images extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  @autobind
  _removeImage(attachment_id) {
    this.props.handleDeleteSpecificationFile(attachment_id);
  }

  @autobind
  _changeOrder(category, images) {
    setTableParams('working_set_specifications', {
      flyout: {
        ...this.props.flyout,
        images: _.map(this.props.flyout.images, (image, i) => {
          return image.category == category
            ? {
                ...image,
                order: _.findIndex(images, ['id', image.id]),
              }
            : image;
        }),
      },
    });
  }

  render() {
    const { flyout, category, data, readOnly } = this.props;

    const images = _.sortBy(data, 'order');

    const lightbox_images = _.map(data, (image) => ({
      id: image.id,
      stream_url: image.stream_url,
      title: [image.name, image.ext].join('.'),
    }));

    return (
      <ReactSortable
        disabled={
          !_.isNull(flyout.product_id) ||
          _.includes(['canceled'], flyout.status) ||
          readOnly
        }
        list={images.concat([{}])}
        setList={(list) => this._changeOrder(category, list)}
        animation={200}
        delayOnTouchStart={true}
        handle={'.' + styles.thumbnail}
        onEnd={() => {
          reorderSpecificationImages(
            flyout.id,
            category,
            _.map(images, 'id')
          ).then(() => {
            setNotification(
              __('working-set-specifications.specification-images-order-saved')
            );

            if (category == 'thumbnail') {
              readWorkingSetSpecification(flyout.id).then(({ response }) => {
                setTableParams('working_set_specifications', {
                  flyout: {
                    ...flyout,
                    thumb_url: response.data.thumb_url,
                  },
                });
              });

              listWorkingSetSpecifications(getActiveStage());
            }
          });
        }}
        onMove={(event) => !event.related.classList.contains(styles.add)}
        className={styles.content}
      >
        {_.map(images, (image, i) => (
          <div
            key={i}
            data-id={image.id}
            className={styles.thumbnail}
            onClick={() => showLightbox(lightbox_images, image.id)}
            style={{
              backgroundImage: "url('" + image.thumb_url + "')",
            }}
          >
            {!(
              !_.isNull(flyout.product_id) ||
              _.includes(['canceled'], flyout.status) ||
              readOnly
            ) && (
              <>
                <div className={styles.grabber}>
                  <GrabberIcon />
                </div>
                <div className={styles.editIcon}>
                  <EditIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      setTableParams('working_set_specifications', {
                        edit_specification_file_category: category,
                        attachment_id: image.id,
                        file_ext: image.ext,
                      });
                    }}
                  />
                </div>
                <div className={styles.removeIcon}>
                  <CloseSmallIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      this._removeImage(image.id);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ))}
      </ReactSortable>
    );
  }
}

export default Images;
