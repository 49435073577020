import * as React from "react";
import {Component} from "react";
import styles from "../styles/components/SnackBar.scss";
import {connect} from "react-redux";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import classNames from "classnames";
import {__} from "../core/utils";
import WarningIcon from "../assets/images/warning-30x30.svg";
import autobind from "autobind-decorator";
import {clearNotifications} from "../redux/actions/general/notification";

const mapStateToProps = state => {
	return {
		notification: state.general.notification,
		sidebar: state.general.sidebar
	};
};
@autobind
@connect(mapStateToProps, null)
class SnackBar extends Component {
	_handleEntered(id) {
		setTimeout(() => {
			if (_.get(this.props, 'notification.id') == id) {
				clearNotifications();
			}
		}, 12000);
	}

	render() {
		return (
			<TransitionGroup
				onClick={clearNotifications}
				className={classNames(styles.wrapper)}
			>
				{this.props.notification && (
					<CSSTransition
						onEntered={() => this._handleEntered(this.props.notification.id)}
						key={this.props.notification.id}
						timeout={300}
						classNames={{
							enter: styles.show,
							enterDone: styles.show,
							exit: styles.hide
						}}
						unmountOnExit
					>
						<>
							{this.props.notification.type === "warning" && (
								<div className={classNames(styles.snackBar, styles.warning)} onClick={() => clearNotifications()}>
									<div className={styles.icon}>
										<WarningIcon />
									</div>
									<div className={styles.text}>
										<span className={styles.title}>{__('snackbar.title.warning')}</span>
										<span>{this.props.notification.text}</span>
									</div>
									{this.props.notification.action && (
										<span className={styles.action} onClick={this.props.notification.action.onClick}>
											{this.props.notification.action.text}
										</span>
									)}
								</div>
							)}

							{this.props.notification.type !== "warning" && (
								<div className={styles.snackBar}>
									<div className={styles.text}>
										<span>{this.props.notification.text}</span>
									</div>
									{this.props.notification.action && (
										<span className={styles.action} onClick={this.props.notification.action.onClick}>
											{this.props.notification.action.text}
										</span>
									)}
								</div>
							)}
						</>
					</CSSTransition>
				)}
			</TransitionGroup>
		);
	}
}

export default SnackBar;
