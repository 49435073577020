import React, { useEffect } from 'react';
import styles from '../../../styles/components/RoomChooser.scss';
import UncheckedIcon from '../../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../../assets/images/checked-15x15.svg';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import classnames from 'classnames';
import _ from 'lodash';
import { __ } from '../../../core/utils';

const RootTree = ({
  initialCollapsedNodes = {},
  keyExtractor,
  multiselect,
  nodes = [],
  onSelect,
  selectedNodes = {},
  selectAllRooms,
}) => {
  const [expandedNodes, setExpandedNodes] = React.useState(
    initialCollapsedNodes
  );

  const onToggleExpand = React.useCallback((node, isExpanded) => {
    setExpandedNodes((prevExpandedNodes) => ({
      ...prevExpandedNodes,
      [keyExtractor(node)]: isExpanded,
    }));
  }, []);

  const onToggleSelect = React.useCallback(
    (node, isSelected) => {
      onSelect(node, isSelected);
    },
    [onSelect]
  );

  useEffect(() => {
    setExpandedNodes(initialCollapsedNodes);
  }, [_.size(initialCollapsedNodes)]);

  return (
    <Tree
      selectAllRooms={selectAllRooms}
      multiselect={multiselect}
      nodes={nodes}
      keyExtractor={keyExtractor}
      selectedNodes={selectedNodes}
      expandedNodes={expandedNodes}
      onToggleExpand={onToggleExpand}
      onToggleSelect={onToggleSelect}
    />
  );
};

const Tree = ({
  selectAllRooms,
  expandedNodes,
  keyExtractor,
  multiselect,
  nodes,
  onToggleExpand,
  onToggleSelect,
  selectedNodes,
}) => {
  return (
    <ul className={styles.tree}>
      {nodes.map((node, nodeIndex) => (
        <TreeNode
          key={`tree-node-${nodeIndex}-${keyExtractor(node)}`}
          multiselect={multiselect}
          node={node}
          keyExtractor={keyExtractor}
          selectedNodes={selectedNodes}
          expandedNodes={expandedNodes}
          onToggleExpand={onToggleExpand}
          onToggleSelect={onToggleSelect}
          selectAllRooms={selectAllRooms}
        />
      ))}
    </ul>
  );
};

const TreeNode = ({
  expandedNodes,
  keyExtractor,
  multiselect,
  node,
  onToggleExpand,
  onToggleSelect,
  selectedNodes,
  selectAllRooms,
}) => {
  const hasItems = node.items?.length > 0;

  const isCollapsed = !!_.get(expandedNodes, keyExtractor(node));
  const isSelected = !!_.get(selectedNodes, keyExtractor(node));

  return (
    <li
      className={classnames(
        styles.treeNode,
        isSelected && styles.treeNodeSelected
      )}
    >
      <span className={styles.treeNodeContent}>
        <button
          type='button'
          onClick={() => onToggleExpand(node, !isCollapsed)}
          className={styles.treeNodeExpandIcon}
        >
          <ArrowDownSmallIcon
            className={classnames(
              styles.arrowDownIcon,
              !isCollapsed && styles.expandedArrowDownIcon,
              !hasItems && styles.visibilityHidden
            )}
          />
        </button>
        <span
          className={classnames(
            styles.treeNodeLabel,
            isSelected && styles.treeNodeLabelSelected,
            node.disabled && styles.treeNodeLabelDisabled
          )}
          onClick={() => {
            if (!node.disabled)
              multiselect
                ? onToggleSelect(node, !isSelected)
                : onToggleSelect(node, true);
          }}
        >
          {node.display_name}
        </span>
        {multiselect ? (
          <span
            onClick={() => !node.disabled && onToggleSelect(node, !isSelected)}
            className={classnames(
              styles.treeNodeCheckbox,
              node.disabled && styles.treeNodeCheckboxDisabled
            )}
          >
            {isSelected ? (
              <CheckedIcon className={styles.checkedOption} />
            ) : (
              <div className={styles.flex}>
                {node.type == 'storey' && selectAllRooms && (
                  <span
                    className={styles.button}
                    onClick={(e) => {
                      e.stopPropagation();

                      onToggleExpand(node, false);

                      _.map(node.items, (item) => {
                        !item.disabled && onToggleSelect(item, true);
                      });
                    }}
                  >
                    {__('room-chooser.tree.storey.select-all')}
                  </span>
                )}
                <UncheckedIcon className={styles.uncheckedOption} />
              </div>
            )}
          </span>
        ) : (
          !node.disabled && (
            <span
              onClick={() => onToggleSelect(node, !isSelected)}
              className={classnames(
                styles.treeNodeDot,
                isSelected && styles.treeNodeDotSelected
              )}
            >
              <span className={styles.dot} />
            </span>
          )
        )}
      </span>
      {hasItems && !isCollapsed && (
        <Tree
          multiselect={multiselect}
          nodes={node.items}
          keyExtractor={keyExtractor}
          selectedNodes={selectedNodes}
          expandedNodes={expandedNodes}
          onToggleExpand={onToggleExpand}
          onToggleSelect={onToggleSelect}
          selectAllRooms={selectAllRooms}
        />
      )}
    </li>
  );
};

export default RootTree;
