import * as React from "react";
import {Component} from "react";
import styles from "../styles/components/AlertBox.scss";
import classNames from "classnames";
import WarningIcon from "../assets/images/warning-30x30.svg";
import {mapStateToProps} from "../core/utils";
import Button from "./Button";
import * as _ from "lodash";
import {hideAlertbox} from "../redux/actions/general/alertbox";

@mapStateToProps(state => ({
	...state.general.alertbox
}))
class AlertBox extends Component {
	componentDidUpdate(prevProps) {
		if(!prevProps.title && this.props.title) {
			document.getElementById("root").classList.add("blur");
		}

		if(prevProps.title && !this.props.title) {
			document.getElementById("root").classList.remove("blur");
		}
	}

	render() {
		const {color, title, description, buttons} = this.props;

		return !title ? '' : (
			<div className={classNames(styles.wrapper, color == 'red' && styles.red, color == 'blue' && styles.blue)}>
				<div className={styles.box}>
					<div className={styles.header}>
						<span>{title}</span>
						<WarningIcon />
					</div>
					<div className={styles.content}>
						{description}
					</div>
					{!_.isEmpty(buttons) && <div className={styles.footer}>
						{_.map(buttons, (button, i) => {
							const styles = [];
							styles[button.color] = true;

							return (
								<Button medium key={i} onClick={button.onClick == 'close' ? hideAlertbox : button.onClick} middleText={button.text} {...styles} />
							);
						})}
					</div>}
				</div>
			</div>
		);
	}
}

export default AlertBox;
