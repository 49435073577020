import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/company/CompanyDetailsForm.scss";
import {connect} from "react-redux";
import classNames from "classnames";
import Localization from "../../helpers/Localization";
import {subscribe} from "react-contextual";
import autobind from "autobind-decorator";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import Checkbox from "../../components/Checkbox";
import {enableAutosave} from "../../redux/actions/general/autosave";
import {__} from "../../core/utils";

const mapStateToProps = state => {
	return {
		values: getFormValues("company.details")(state),
		my_language_id: state.auth.language_id
	};
};

@subscribe(Localization, "localization")
@reduxForm({
	form: "company.details",
    enableReinitialize: true,
})
@autobind
@connect(mapStateToProps)
class CompanyDetailsForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

	_selectSize(id) {
		this.props.touch("size_id");
		this.props.change("size_id", id);
        this.props.blur("size_id");
    }

	render() {
    	const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
    		languages[language.id] = language.id == this.props.my_language_id;

    		return languages;
		}, {});

		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.wrapper}>
					<div className={styles.visibility}>
						<div className={styles.title}>{__('company-settings.company-details.public-visibility')}</div>
						<Field name="public" component={Checkbox} label={__('company-settings.company-details.checkbox.visibility-to-other-volum3-users-and-companies')} />
					</div>
					<div className={styles.title}>{__('company-settings.company-details.details')}</div>
					<div className={styles.form}>
						<div className={styles.companyTitle}>
							<div className={styles.flex}>
								<div className={styles.f2}>
									<Field name="name" component={Input} label={__('company-settings.company-details.details.company-title')} />
								</div>
								<div className={styles.f1}>
									<Field name="vat" component={Input} label={__('company-settings.company-details.details.vat-number')} />
								</div>
							</div>
						</div>
						<div className={styles.companyDescription}>
							<Field name="description" component={Input} label={__('company-settings.company-details.details.short-description')} localized={application_languages} maxLength={70} />
						</div>
						<Field name="description_long" component={Textarea} label={__('company-settings.company-details.details.long-description')} localized={application_languages} maxLength={2000} rows={1} maxRows={6} />
					</div>
					<div className={styles.title}>{__('company-settings.company-details.size-by-number.of.employees')}</div>
					<div className={styles.sizes}>
						{_.map(this.props.localization.company_sizes, ({ size, name, id }, i) => (
							<div key={i} className={classNames(styles.size, id == _.get(this.props, "values.size_id") && styles.selected)} onClick={() => this._selectSize(id)}>
								<span>{size}</span>
								<span>{name}</span>
							</div>
						))}
					</div>
				</div>
			</Form>
		);
	}
}

export default CompanyDetailsForm;
