import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/company_partners_wizard/AddCompanyPartnersForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {__, transformToListPairs} from "../../core/utils";
import MultiSelect from "../../components/MultiSelect";
import {bindActionCreators} from "redux";
import {listCompanies} from "../../redux/reducers/companies";

const mapStateToProps = state => {
	return {
		companies: state.companies
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: {
			...bindActionCreators(
				{
					listCompanies
				},
				dispatch
			),
			hideAddCompanyPartnersWizard: () =>
				dispatch({
					type: "table.partner_companies/SET_TABLE_PARAMS",
					params: {
						wizard_active: undefined
					}
				})
		}
	};
};
@reduxForm({
	form: "company_partners_wizard.add_company_partners"
})
@connect(
	mapStateToProps,
	mapDispatchToProps
)
class AddCompanyPartnersForm extends Component {
	componentDidMount() {
		this.props.actions.listCompanies();
	}

	render() {
		return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				<Field name="companies" placeholder={__('Select Companies')} component={MultiSelect} searchable options={transformToListPairs(this.props.companies, "name")} />
				<div className={styles.footer}>
					<Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideAddCompanyPartnersWizard} />
					<Button gray medium submit right leftText={__('button.add')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default AddCompanyPartnersForm;
