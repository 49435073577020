import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddSpecificationProductNoteWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import AddNoteIcon from '../assets/images/add-note-60x60.svg';
import {
  editSpecificationProductNote,
  readWorkingSetSpecification,
  addSpecificationProductNote,
} from '../redux/actions/table/working_set_specifications';
import EditSpecificationProductNoteForm from '../forms/edit_specification_product_note_wizard/EditSpecificationProductNoteForm';

@mapStateToProps((state) => ({
  flyout: state.table.working_set_specifications.flyout,
  data: _.get(
    state.table,
    'working_set_specifications.edit_specification_product_note_data'
  ),
}))
class EditSpecificationProductNoteWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { id } = this.props.flyout;

    const { edit } = formData;

    formData = _.omit(formData, ['edit']);

    return validateForm(
      (edit
        ? editSpecificationProductNote(id, formData)
        : addSpecificationProductNote(id, { ...formData, add_new: 1 })
      ).then(() =>
        readWorkingSetSpecification(this.props.flyout.id).then(
          ({ response }) => {
            setNotification('The specification note has been updated.');

            setTableParams('working_set_specifications', {
              flyout: response.data,
            });

            this.props.onClose();
          }
        )
      )
    );
  }

  render() {
    const { text, category_id, priority, is_my_company_note, id } =
      this.props.data;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'specifications.working-set.flyout.add-instruction.edit-specification-instruction'
              )}
            </span>
            <span>
              {__(
                'specifications.working-set.flyout.add-instruction.edit-note-to-your-specification'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddNoteIcon />
          </div>
        </div>
        <div className={styles.body}>
          <EditSpecificationProductNoteForm
            onSubmit={this._handleSubmit}
            initialValues={{
              note_category_id: category_id,
              note: text,
              priority: priority,
              is_my_company_note: is_my_company_note,
              id: id,
            }}
          />
        </div>
      </>
    );
  }
}

export default EditSpecificationProductNoteWizard;
