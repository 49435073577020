import * as React from "react";
import {Component} from "react";
import {redirect} from "../../core/utils";
import {getUniqueURL} from "../../redux/actions/general";
import {withRouter} from "react-router";
import EmptyLayout from "../../layouts/EmptyLayout";

@withRouter
class UniqueURL extends Component {
    componentDidMount() {
        const {token} = this.props.match.params;

        if(token) {
            getUniqueURL(token).then(({response}) => redirect(response.data.url, true)).catch(() => redirect('/', true));
        } else {
            redirect('/', true);
        }
    }

    render() {
        return null;
    }
}

export default UniqueURL;