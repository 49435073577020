import React, { useRef, useState } from 'react';
import styles from '../../styles/flyouts/tenders/Files.scss';
import { withRouter } from 'react-router';
import {
  __,
  bytesToSize,
  dateFrom,
  scrollToBottom,
  timeFrom,
} from '../../core/utils';
import DownloadIcon from '../../assets/images/download-15x15.svg';
import * as _ from 'lodash';
import ReactDropzone from 'react-dropzone';
import classNames from 'classnames';
import UploadIcon from '../../assets/images/upload-40x40.svg';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import FilePreview from '../../components/FilePreview';
import Avatar from '../../components/Avatar';
import {
  deleteTenderFile,
  listAllTenders,
  uploadTenderFiles,
} from '../../redux/actions/table/tenders';
import { connect } from 'react-redux';

const Files = (props) => {
  const [drag, setDrag] = useState();
  const scroll = useRef();

  const _handleDrop = (acceptedFiles, rejectedFiles) => {
    setDrag(false);
    const { data } = props;

    if (!_.isEmpty(acceptedFiles)) {
      if (_.size(rejectedFiles) > 1) {
        setNotification({
          text: __('tenders.files.upload.bulk-not-allowed'),
          type: 'warning',
        });
      } else if (!_.isEmpty(rejectedFiles)) {
        setNotification({
          text: __('tenders.files.upload.incorrect.format'),
          type: 'warning',
        });
      } else if (
        _.find(acceptedFiles, (file) => {
          return file.size >= 100000000;
        })
      ) {
        setNotification({
          text: __('tenders.files.upload.upload-limit'),
          type: 'warning',
        });
      } else {
        setNotification(__('tenders.files.upload.uploading'));

        uploadTenderFiles(data.id, acceptedFiles)
          .then(() => {
            setNotification(__('tenders.files.upload.uploaded'));

            props.match.params.stage &&
              listAllTenders(props.match.params.stage);

            props.readAction(data.id).then(() => {
              scrollToBottom(scroll);
            });
          })
          .catch(({ status }) => {
            setNotification({
              text: __('tenders.files.upload.upload-limit'),
              type: 'warning',
            });
          });
      }
    }
  };

  const _downloadFile = (row, download = false) => {
    if (row.ext == 'pdf' && download == false) {
      window.open(row.stream_url, '_blank');
    } else {
      setNotification(__('tenders.files.download.continue'));

      window.open(row.download_url, '_blank');
    }
  };

  const { data, collapsed, files, disabled } = props;

  const lightbox_images = _.map(
    _.filter(_.sortBy(files, ['created_at']), (file) =>
      _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
    ),
    (file) => ({
      id: file.id,
      stream_url: file.stream_url,
      title: [file.name, file.ext].join('.'),
    })
  );

  return (
    <div
      className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      ref={scroll}
    >
      <div className={styles.scroller}>
        {_.isEmpty(files) ? (
          <div className={styles.empty}>
            {__('tenders.flyout.files.there-are-no-files')}
          </div>
        ) : (
          ''
        )}

        {_.map(_.sortBy(files, ['created_at']), (row) => (
          <div className={styles.row} key={row.id}>
            <div className={styles.left}>
              <FilePreview
                onDownloadFile={_downloadFile}
                row={row}
                lightbox_images={lightbox_images}
              />
            </div>
            <div className={styles.right}>
              {bytesToSize(row.size)}

              <div className={styles.avatar}>
                <Avatar
                  size={24}
                  {...row.creator}
                  avatar_url={row.creator.avatar_url}
                  className={styles.avatar}
                />
              </div>

              <div className={styles.date_time}>
                {dateFrom(row.created_at)}
                <br />
                {timeFrom(row.created_at)}
              </div>
              <div className={styles.icons}>
                <DownloadIcon onClick={() => _downloadFile(row, true)} />
                {!disabled && (
                  <DeleteIcon
                    onClick={() => {
                      showAlertbox({
                        color: 'red',
                        title: __('general.alert.are-you-sure'),
                        description: __(
                          'files.general.alert.want-delete-file-attachment-tender.process-cannot-undone'
                        ),
                        buttons: [
                          {
                            color: 'lightGray',
                            text: __('general.alert.no-close'),
                            onClick: 'close',
                          },
                          {
                            color: 'gray',
                            text: __('general.alert.yes-delete'),
                            onClick: () => {
                              const deleteAction = deleteTenderFile(
                                data.id,
                                row.id
                              );

                              deleteAction.then(() => {
                                hideAlertbox();

                                setNotification(
                                  __(
                                    'files.notification.selected-file-attachment-deleted-tender'
                                  )
                                );

                                props.readAction(data.id).then(() => {
                                  scrollToBottom(this.refs.scroll);
                                });
                              });
                            },
                          },
                        ],
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {!disabled && (
        <ReactDropzone
          className={classNames(styles.dropzone, drag && styles.drag)}
          onDrop={_handleDrop}
          onDragOver={() => setDrag(true)}
          onDragLeave={() => setDrag(false)}
          multiple={true}
          disabled={disabled}
        >
          <UploadIcon />
          <span className={styles.dropTitle}>
            {__('dropzone.title.drop-your-file-here')}
          </span>
          <span className={styles.dropSubtitle}>
            {__('dropzone.title.or-select')}
          </span>
        </ReactDropzone>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.id,
    clicked_row: _.get(state.table, ['tenders', 'clicked_row']),
    wizard_active: _.get(state.table, ['tenders', 'wizard_active']),
  };
};

export default withRouter(connect(mapStateToProps, null)(Files));
