import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { mapStateToProps, transformToListPairs } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import Select from "../../../components/Select";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";

@reduxForm({
    form: "superadmin_product_groups.edit_product_group",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
class EditProductGroupForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        const product_categories = _.sortBy(_.values(this.props.localization.product_categories), 'priority');

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_product_group} localized={application_languages} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_product_group} name="priority" numeric component={Input} label="Priority" />
                <Field disabled={!superadmin.edit_product_group} name="product_category_id" component={Select} searchable noSort options={transformToListPairs(product_categories)} label="Product Category" />
            </Form>
        );
    }
}

export default EditProductGroupForm;