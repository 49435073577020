import React, {Component} from "react";
import styles from "../styles/components/MeetingTasksChooser.scss";
import classNames from "classnames";
import SearchIcon from "../assets/images/search-15x15.svg";
import * as _ from "lodash";
import PlusMiddleIcon from "../assets/images/plus-middle-15x15.svg";
import autobind from "autobind-decorator";
import {__} from "../core/utils";
import CloseSmallIcon from "../assets/images/close-small-15x15.svg";
import TaskRfiIcon from "../assets/images/task-rfi-15x15.svg";
import TaskTodoIcon from "../assets/images/task-todo-15x15.svg";
import TaskDecisionIcon from "../assets/images/task-decision-15x15.svg";
import TaskFyiIcon from "../assets/images/task-fyi-15x15.svg";
import TaskMilestoneIcon from "../assets/images/task-milestone-15x15.svg";
import TaskIssueIcon from "../assets/images/task-issue-15x15.svg";
import Tooltip from "./Tooltip";
import OutsideClickWrapper from "./OutsideClickWrapper";
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
import FieldComponent from "./FieldComponent";
import KeyboardEventHandler from "react-keyboard-event-handler";
import {isMobileOnly} from "react-device-detect";
import {FixedSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

@FieldComponent
class MeetingTasksChooser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.meta.active != this.props.meta.active) {
            this.setState({
                query: '',
                selected: this.props.input.value || [],
            }, () => {
                this.props.recalculateOffset();
            });
        }

        if(prevState.selected != this.state.selected || prevState.query != this.state.query) {
            this.props.recalculateOffset();
        }
    }

    @autobind
    _handleOpen() {
        if(!this.props.disabled) {
            this.props.input.onFocus();
        }
    }

    @autobind
    _handleClose() {
        this.props.input.onBlur();
    }

    @autobind
    _handleSave() {
        this.props.input.onChange(this.state.selected);

        this._handleClose();
    }

    @autobind
    _handleSearch(e) {
        this.setState({ query: e.target.value });
    }

    @autobind
    _toggleValue(id) {
        this.setState({
            selected: _.xor(this.state.selected, [id])
        }, () => {
            this.refs.search && this.refs.search.select();
        });
    }

    @autobind
    _handleKeyDown(e) {
        if(e.keyCode == 27) {
            e.stopPropagation();
            e.preventDefault();

            this._handleClose();
        }

        const {query, selected} = this.state;
        const {tasks} = this.props;

        const available_values = _.filter(tasks, task => !_.includes(selected, task.id) && (_.isEmpty(query) || _.includes(_.toLower(task.title), _.toLower(query)) || _.includes(_.toLower('t-' + task.identifier), _.toLower(query))));

        if(e.keyCode == 13) {
            e.stopPropagation();
            e.preventDefault();

            !_.isEmpty(query) && !_.isEmpty(available_values) && this._toggleValue(_.first(available_values).id, true);
        }
    }

    @autobind
    _handleClearSearch() {
        this.setState({
            query: ''
        });

        this.refs.search.focus();
    }

    @autobind
    _handleRemove(task_id) {
        this.props.input.onChange(_.reject(this.props.input.value, task => _.isEqual(task, task_id)));
    }

    statusToColor(status) {
        const statuses = {
            'not-started': '#9C27B0',
            'started': '#5777FC',
            'finished': '#ADD42B',
            'closed': '#009688',
            'reopened': '#303F9F',
            'canceled': '#CCCDD1',
        };

        return _.get(statuses, status);
    }
    
	render() {
        const {tasks, leftPosition, topPosition, elementRef, dropdownRef} = this.props;
        const {query, selected} = this.state;
        const {value} = this.props.input;

        let selected_values, available_values;

        if(this.props.meta.active) {
            selected_values = _.filter(tasks, task => _.includes(selected, task.id));
            available_values = _.filter(tasks, task => !_.includes(selected, task.id) && (_.isEmpty(query) || _.includes(_.toLower(task.title), _.toLower(query)) || _.includes(_.toLower('t-' + task.identifier), _.toLower(query))));
        }

        const Row = ({index, style}) => {
            const task = available_values[index];

            return (
                <Tooltip text={['T-' + task.identifier + ': ' + task.title, task.description].join('\n\n')} key={index} placement="left">
                    <div style={style} className={styles.row} onClick={() => this._toggleValue(task.id)}>
                        {task.type == 'rfi' && <div className={classNames(styles.icon, styles.rfi)} style={{background: this.statusToColor(task.status)}}>
                            <TaskRfiIcon />
                        </div>}
                        {task.type == 'fyi' && <div className={classNames(styles.icon, styles.fyi)} style={{background: this.statusToColor(task.status)}}>
                            <TaskFyiIcon />
                        </div>}
                        {task.type == 'decision' && <div className={classNames(styles.icon, styles.decision)} style={{background: this.statusToColor(task.status)}}>
                            <TaskDecisionIcon />
                        </div>}
                        {task.type == 'todo' && <div className={classNames(styles.icon, styles.todo)} style={{background: this.statusToColor(task.status)}}>
                            <TaskTodoIcon />
                        </div>}
                        {task.type == 'milestone' && <div className={classNames(styles.icon, styles.milestone)} style={{background: this.statusToColor(task.status)}}>
                            <TaskMilestoneIcon />
                        </div>}
                        {task.type == 'issue' && <div className={classNames(styles.icon, styles.issue)} style={{background: this.statusToColor(task.status)}}>
                            <TaskIssueIcon />
                        </div>}
                        <span>{'T-' + task.identifier} &middot; {task.title}</span>
                        <div className={classNames(styles.visibility, task.visibility && styles.public)}>
                            <Tooltip text={task.visibility ? __('tasks.flyout.related-tasks.tooltip.public') : __('tasks.flyout.related-tasks.tooltip.private')}>
                                {task.visibility ? __('tasks.flyout.related-tasks.pu') : __('tasks.flyout.related-tasks.pr')}
                            </Tooltip>
                        </div>
                        <PlusMiddleIcon className={styles.plus} />
                    </div>
                </Tooltip>
            );
        }

        return (
            <div className={classNames(
                styles.wrapper,
                _.get(this.props, "meta.error") && _.get(this.props, "meta.touched") && styles.error,
            )}>
                {_.isEmpty(value) ? <div className={styles.noTasks}>{__('meetings.meetings-list.tasks.no-selected-tasks')}</div> : (
                    <>
                        <div className={styles.selectedTasks}>{__('meetings.create-new-meeting.selected-tasks')} ({_.size(value)})</div>
                        <div className={styles.tasksList}>
                            {_.map(_.filter(tasks, task => _.includes(value, task.id)), task => (
                                <div className={styles.task} onClick={() => this._handleRemove(task.id)} key={task.id}>
                                    {task.type == 'rfi' && <div className={classNames(styles.icon, styles.rfi)} style={{background: this.statusToColor(task.status)}}>
                                        <TaskRfiIcon />
                                    </div>}
                                    {task.type == 'fyi' && <div className={classNames(styles.icon, styles.fyi)} style={{background: this.statusToColor(task.status)}}>
                                        <TaskFyiIcon />
                                    </div>}
                                    {task.type == 'decision' && <div className={classNames(styles.icon, styles.decision)} style={{background: this.statusToColor(task.status)}}>
                                        <TaskDecisionIcon />
                                    </div>}
                                    {task.type == 'todo' && <div className={classNames(styles.icon, styles.todo)} style={{background: this.statusToColor(task.status)}}>
                                        <TaskTodoIcon />
                                    </div>}
                                    {task.type == 'milestone' && <div className={classNames(styles.icon, styles.milestone)} style={{background: this.statusToColor(task.status)}}>
                                        <TaskMilestoneIcon />
                                    </div>}
                                    {task.type == 'issue' && <div className={classNames(styles.icon, styles.issue)} style={{background: this.statusToColor(task.status)}}>
                                        <TaskIssueIcon />
                                    </div>}
                                    <span>{'T-' + task.identifier} &middot; {task.title}</span>
                                    <div className={classNames(styles.visibility, task.visibility && styles.public)}>
                                        <Tooltip text={task.visibility ? __('tasks.flyout.related-tasks.tooltip.public') : __('tasks.flyout.related-tasks.tooltip.private')}>
                                            {task.visibility ? __('tasks.flyout.related-tasks.pu') : __('tasks.flyout.related-tasks.pr')}
                                        </Tooltip>
                                    </div>
                                    <CloseSmallIcon className={styles.delete} />
                                </div>
                            ))}
                        </div>
                    </>
                )}
                <div className={styles.selectTasks} ref={elementRef} onClick={this._handleOpen}>{__('meetings.meeting-list.tasks.select-tasks')}</div>
                {this.props.meta.active && (
                    <OutsideClickWrapper closable={false}>
                        <KeyboardEventHandler handleKeys={['esc']} onKeyEvent={this._handleClose} />
                        <div className={styles.dropdown} ref={dropdownRef} style={{top: topPosition + 'px', left: leftPosition + 'px', marginTop: (this.props.top || 27) + 'px', marginLeft: (this.props.left || 0) + 'px'}}>
                            {isMobileOnly && <div className={styles.mobileHeader}>{this.props.label}</div>}
                            {!_.isEmpty(selected_values) && <div className={styles.selected}>
                                <div className={styles.title}>{__('meetingtaskschooser.title.currently-selected')} ({_.size(selected_values)})</div>
                                <div className={styles.list}>
                                    {_.map(selected_values, task => (
                                        <Tooltip text={task.description} key={task.id} placement="left">
                                            <div className={styles.row} onClick={() => this._toggleValue(task.id)}>
                                                {task.type == 'rfi' && <div className={classNames(styles.icon, styles.rfi)} style={{background: this.statusToColor(task.status)}}>
                                                    <TaskRfiIcon />
                                                </div>}
                                                {task.type == 'fyi' && <div className={classNames(styles.icon, styles.fyi)} style={{background: this.statusToColor(task.status)}}>
                                                    <TaskFyiIcon />
                                                </div>}
                                                {task.type == 'decision' && <div className={classNames(styles.icon, styles.decision)} style={{background: this.statusToColor(task.status)}}>
                                                    <TaskDecisionIcon />
                                                </div>}
                                                {task.type == 'todo' && <div className={classNames(styles.icon, styles.todo)} style={{background: this.statusToColor(task.status)}}>
                                                    <TaskTodoIcon />
                                                </div>}
                                                {task.type == 'milestone' && <div className={classNames(styles.icon, styles.milestone)} style={{background: this.statusToColor(task.status)}}>
                                                    <TaskMilestoneIcon />
                                                </div>}
                                                {task.type == 'issue' && <div className={classNames(styles.icon, styles.issue)} style={{background: this.statusToColor(task.status)}}>
                                                    <TaskIssueIcon />
                                                </div>}
                                                {'T-' + task.identifier} &middot; {task.title}
                                                <div className={classNames(styles.visibility, task.visibility && styles.public)}>
                                                    <Tooltip text={task.visibility ? __('tasks.flyout.related-tasks.tooltip.public') : __('tasks.flyout.related-tasks.tooltip.private')}>
                                                        {task.visibility ? __('tasks.flyout.related-tasks.tooltip.pu') : __('tasks.flyout.related-tasks.tooltip.pr')}
                                                    </Tooltip>
                                                </div>
                                                <CloseSmallIcon className={styles.delete} />
                                            </div>
                                        </Tooltip>
                                    ))}
                                </div>
                            </div>}
                            {_.size(tasks) != _.size(selected) && <div className={styles.available}>
                                <div className={styles.title}>{__('meetingtaskschooser.title.available-tasks')} ({_.size(available_values)})</div>
                                <div className={styles.search}>
                                    <SearchIcon className={styles.searchIcon} />
                                    <input ref="search" type="text" value={query} autoFocus={true} placeholder={__('meetingtaskschooser.title.search')} onChange={this._handleSearch} onKeyDown={this._handleKeyDown} />
                                    {!_.isEmpty(query) && <CloseSmallIcon className={styles.close} onClick={this._handleClearSearch} />}
                                </div>
                                <div className={styles.list}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height}
                                                itemCount={_.size(available_values)}
                                                itemSize={32}
                                                width={width}
                                            >
                                                {Row}
                                            </List>
                                        )}
                                    </AutoSizer>
                                    {_.isEmpty(available_values) && <div className={styles.noResults}>No results found.</div>}
                                </div>
                            </div>}
                            {_.isEmpty(tasks) && <div className={styles.noAvailableTasks}>{__('tasks.flyout.related-tasks.no-available-tasks')}</div>}
                            <div className={styles.footer}>
                                <ButtonGroup right>
                                    <Button lightGray medium middleText={__('button.cancel')} onClick={this._handleClose} />
                                    <Button lightBlue medium middleText={__('button.done')} onClick={this._handleSave} />
                                </ButtonGroup>
                            </div>
                        </div>
                    </OutsideClickWrapper>
                )}
            </div>
		);
	}
}

export default MeetingTasksChooser;
