import * as React from "react";
import { Component } from "react";
import styles from "../styles/wizards/AddPartToProductWizard.scss";
import autobind from "autobind-decorator";
import { __, mapStateToProps, setTableParams, validateForm } from "../core/utils";
import AddLinkedSpecificationIcon from "../assets/images/add-linked-specification-64x64.svg";
import { withRouter } from "react-router";
import { addProductAccessories, addProductParts, readProduct } from "../redux/actions/table/products";
import { setNotification } from "../redux/actions/general/notification";
import AddAccessoryToProductForm from "../forms/add_accessory_to_product_wizard/AddAccessoryToProductForm";

@mapStateToProps((state) => ({
	store: _.get(state.table, "products")
}))
@withRouter
class AddAccessoryToProductWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
			addProductAccessories(this.props.store.flyout.id, this.props.store.category, formData.product_ids).then(() =>
				readProduct(this.props.store.flyout.id).then(({ response }) => {
					setNotification(__("products.flyout.accessories.accessories-have-been-added"));

					setTableParams("products", {
						flyout: response.data
					});

					this.props.onClose();
				})
			)
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__("products.flyout.accessory.add-accessory")}</span>
						<span>{__("products.flyout.accessory.add-accessory")}</span>
					</div>
					<div className={styles.right}>
						<AddLinkedSpecificationIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddAccessoryToProductForm
						onSubmit={this._handleSubmit}
						initialValues={{
							product_ids: []
						}}
					/>
				</div>
			</>
		);
	}
}

export default AddAccessoryToProductWizard;
