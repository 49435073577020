import store from '../../../core/store';
import { getActiveStage } from '../../../core/utils';

export const getBOQCurrency = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/GET_BOQ_CURRENCY',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/boq/currency',
  });
};

export const getBOQArea = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/GET_BOQ_AREA',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/boq/area',
  });
};

export const readWorkingSetBOQ = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/READ_WORKING_SET_BOQ',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/boq/working',
    main: true,
  });
};

export const filterWorkingSetBOQ = () => {
  return store.dispatch({
    type: 'table.working_set_boq/FILTER_WORKING_SET_BOQ',
  });
};

export const addBOQGroup = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_GROUP',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/boq/groups',
  });
};

export const addBOQSubgroup = (boq_group_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_SUBGROUP',
    method: 'POST',
    endpoint: '/boq_groups/' + boq_group_id + '/subgroups',
  });
};

export const addBOQItem = (boq_subgroup_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_ITEM',
    method: 'POST',
    endpoint: '/boq_subgroups/' + boq_subgroup_id + '/items',
  });
};

export const addBOQCalculation = ({ boq_item_id, stage_id, template_id }) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_CALCULATION',
    method: 'POST',
    endpoint: '/boq_items/' + boq_item_id + '/calculations',
    data: {
      stage_id,
      template_id,
    },
  });
};

export const addBOQCalculationsFromTemplates = (
  boq_item_id,
  boq_calculation_ids
) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_CALCULATIOS_FROM_TEMPLATES',
    method: 'POST',
    endpoint: '/boq_items/' + boq_item_id + '/calculation_templates',
    data: {
      boq_calculation_ids,
    },
  });
};

export const addBOQPosition = (boq_calculation_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_POSITION',
    method: 'POST',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/position',
  });
};

export const updateBOQGroupName = (boq_group_id, name, language) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_GROUP_NAME',
    method: 'PUT',
    endpoint: '/boq_groups/' + boq_group_id + '/name',
    data: {
      name,
      language,
    },
  });
};

export const updateBOQSubgroupName = (boq_subgroup_id, name, language) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_SUBGROUP_NAME',
    method: 'PUT',
    endpoint: '/boq_subgroups/' + boq_subgroup_id + '/name',
    data: {
      name,
      language,
    },
  });
};

export const updateBOQItemName = (boq_item_id, name, language) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_ITEM_NAME',
    method: 'PUT',
    endpoint: '/boq_items/' + boq_item_id + '/name',
    data: {
      name,
      language,
    },
  });
};

export const updateBOQItemDescription = (
  boq_item_id,
  description,
  language
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_ITEM_DESCRIPTION',
    method: 'PUT',
    endpoint: '/boq_items/' + boq_item_id + '/description',
    data: {
      description,
      language,
    },
  });
};

export const updateBOQPositionName = (
  boq_position_id,
  name,
  language,
  position,
  specification_id
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_POSITION_DESCRIPTION',
    method: 'PUT',
    endpoint: '/boq_positions/' + boq_position_id + '/name',
    data: {
      name,
      language,
      position,
      specification_id,
    },
  });
};

export const updateBOQCalculationName = (
  boq_calculation_id,
  name,
  language
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_NAME',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/name',
    data: {
      name,
      language,
    },
  });
};

export const updateBOQCalculationDescription = (
  boq_calculation_id,
  description,
  language
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_DESCRIPTION',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/description',
    data: {
      description,
      language,
    },
  });
};

export const updateBOQCalculationUnit = (boq_calculation_id, unit_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_UNIT',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/unit',
    data: {
      unit_id,
    },
  });
};

export const updateBOQCalculationInstallationType = (
  boq_calculation_id,
  installation_type
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_INSTALLATION_TYPE',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/installation_type',
    data: {
      installation_type,
    },
  });
};

export const updateBOQCalculationQuantity = (boq_calculation_id, quantity) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_QUANTITY',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/quantity',
    data: {
      quantity,
    },
  });
};

export const updateBOQPositionQuantity = (
  boq_position_id,
  quantity,
  position,
  specification_id
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_POSITION_QUANTITY',
    method: 'PUT',
    endpoint: '/boq_positions/' + boq_position_id + '/quantity',
    data: {
      quantity,
      position,
      specification_id,
    },
  });
};

export const updateBOQCalculationPrice = (boq_calculation_id, price) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_PRICE',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/price',
    data: {
      price,
    },
  });
};

export const updateBOQCalculationSupplyTypeGroup = (
  boq_calculation_id,
  boq_supply_type_group_id
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_SUPPLY_GROUP_TYPE',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/supply_type_group',
    data: {
      boq_supply_type_group_id,
    },
  });
};

export const updateBOQCalculationBudgetType = (
  boq_calculation_id,
  budget_type
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_BUDGET_TYPE',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/budget_type',
    data: {
      budget_type,
    },
  });
};

export const updateBOQCalculationFromTemplate = (
  boq_calculation_id,
  boq_template_calculation_id
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_GROUP_NAME',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/template',
    data: {
      boq_template_calculation_id,
    },
  });
};

export const deleteBOQGroup = (boq_group_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/DELETE_BOQ_GROUP',
    method: 'DELETE',
    endpoint: '/boq_groups/' + boq_group_id,
  });
};

export const deleteBOQSubgroup = (boq_subgroup_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/DELETE_BOQ_SUBGROUP',
    method: 'DELETE',
    endpoint: '/boq_subgroups/' + boq_subgroup_id,
  });
};

export const deleteBOQItem = (boq_item_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/DELETE_BOQ_ITEM',
    method: 'DELETE',
    endpoint: '/boq_items/' + boq_item_id,
  });
};

export const deleteBOQCalculation = (boq_calculation_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/DELETE_BOQ_CALCULATION',
    method: 'DELETE',
    endpoint: '/boq_calculations/' + boq_calculation_id,
  });
};

export const deleteBOQPosition = (
  boq_position_id,
  position,
  specification_id
) => {
  return store.dispatch({
    action: 'table.working_set_boq/DELETE_BOQ_POSITION',
    method: 'DELETE',
    endpoint: '/boq_positions/' + boq_position_id,
    data: {
      position,
      specification_id,
    },
  });
};

export const deleteMultipleBOQPositions = (boq_calculation_id, orders) => {
  return store.dispatch({
    action: 'table.working_set_boq/DELETE_BOQ_POSITIONS',
    method: 'DELETE',
    endpoint: `/boq_calculations/${boq_calculation_id}/positions`,
    data: {
      orders,
    },
  });
};

export const listPossibleSpecificationCodes = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_SPECIFICATION_CODES',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_specification_codes',
  });
};

export const addSpecificationCodeToCalculation = (
  boq_calculation_id,
  formData
) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_SPECIFICATION_CODE_TO_CALCULATION',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/specification_code',
    data: formData,
  });
};

export const unlinkSpecificationCodeFromBOQCalculation = (
  boq_calculation_id
) => {
  return store.dispatch({
    action:
      'table.working_set_boq/UNLINK_SPECIFICATION_CODE_FROM_BOQ_CALCULATION',
    method: 'DELETE',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/specification_code',
  });
};

export const reorderBOQWorkingSetData = (level, parent_id, rows) => {
  return store.dispatch({
    type: 'table.working_set_boq/REORDER_BOQ_WORKING_SET_DATA',
    level,
    parent_id,
    rows,
  });
};

export const reorderBOQGroups = (stage_id, boq_groups) => {
  return store.dispatch({
    action: 'table.working_set_boq/REORDER_BOQ_GROUPS',
    method: 'PUT',
    endpoint: '/stages/' + stage_id + '/boq/groups/order',
    data: {
      boq_groups,
    },
  });
};

export const updateBOQPositionArea = (
  boq_position_id,
  area_type,
  area_id,
  area_count
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_POSITION_AREA',
    method: 'PUT',
    endpoint: '/boq_positions/' + boq_position_id + '/area',
    data: {
      area_type,
      area_id,
      area_count,
    },
  });
};

export const reorderBOQSubgroups = (boq_group_id, boq_subgroups) => {
  return store.dispatch({
    action: 'table.working_set_boq/REORDER_BOQ_SUBGROUPS',
    method: 'PUT',
    endpoint: '/boq_groups/' + boq_group_id + '/subgroups/order',
    data: {
      boq_subgroups,
    },
  });
};

export const reorderBOQItems = (boq_subgroup_id, boq_items) => {
  return store.dispatch({
    action: 'table.working_set_boq/REORDER_BOQ_ITEMS',
    method: 'PUT',
    endpoint: '/boq_subgroups/' + boq_subgroup_id + '/items/order',
    data: {
      boq_items,
    },
  });
};

export const reorderBOQCalculations = (boq_item_id, boq_calculations) => {
  return store.dispatch({
    action: 'table.working_set_boq/REORDER_BOQ_CALCULATIONS',
    method: 'PUT',
    endpoint: '/boq_items/' + boq_item_id + '/calculations/order',
    data: {
      boq_calculations,
    },
  });
};

export const reorderBOQPositions = (boq_calculation_id, boq_positions) => {
  return store.dispatch({
    action: 'table.working_set_boq/REORDER_BOQ_POSITIONS',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/positions/order',
    data: {
      boq_positions,
    },
  });
};

export const moveBOQSubgroup = (boq_subgroup_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/MOVE_BOQ_SUBGROUP',
    method: 'PUT',
    endpoint: '/boq_subgroups/' + boq_subgroup_id + '/move',
    data: formData,
  });
};

export const moveBOQItem = (boq_item_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/MOVE_BOQ_ITEM',
    method: 'PUT',
    endpoint: '/boq_items/' + boq_item_id + '/move',
    data: formData,
  });
};

export const moveBOQCalculation = (boq_calculation_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/MOVE_BOQ_CALCULATION',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/move',
    data: formData,
  });
};

export const mergeUnallocatedBOQPositionsToSpecification = (
  boq_calculation_id,
  formData
) => {
  return store.dispatch({
    action:
      'table.working_set_boq/MERGE_UNALLOCATED_BOQ_CALCULATION_TO_SPECIFICATION',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/merge',
    data: formData,
  });
};

export const moveBOQPosition = (boq_position_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/MOVE_BOQ_POSITION',
    method: 'PUT',
    endpoint: '/boq_positions/' + boq_position_id + '/move',
    data: formData,
  });
};

export const copyBOQItem = (boq_item_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/COPY_BOQ_ITEM',
    method: 'PUT',
    endpoint: '/boq_items/' + boq_item_id + '/copy',
    data: formData,
  });
};

export const copyBOQCalculation = (boq_calculation_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/COPY_BOQ_CALCULATION',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/copy',
    data: formData,
  });
};

export const getBOQItem = (boq_item_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/GET_BOQ_ITEM',
    method: 'GET',
    endpoint: '/boq_items/' + boq_item_id,
  });
};

export const getBOQCalculation = (boq_calculation_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/GET_BOQ_CALCULATION',
    method: 'GET',
    endpoint: '/boq_calculations/' + boq_calculation_id,
  });
};

export const updateBOQItem = (boq_item_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_ITEM',
    method: 'PUT',
    endpoint: '/boq_items/' + boq_item_id,
    data: formData,
  });
};

export const updateBOQCalculationBasicData = (boq_calculation_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_BASIC_DATA',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/basic',
    data: formData,
  });
};

export const updateBOQCalculationQuantityBasicData = (
  boq_calculation_id,
  formData
) => {
  return store.dispatch({
    action: 'table.working_set_boq/UPDATE_BOQ_CALCULATION_QUANTITY_BASIC_DATA',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/quantity/basic',
    data: formData,
  });
};

export const addMultipleBOQPositions = (boq_calculation_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_MULTIPLE_BOQ_POSITIONS',
    method: 'POST',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/positions',
    data: formData,
  });
};

export const createBOQTemplateCalculations = (boq_calculation_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_boq/CREATE_BOQ_TEMPLATE_CALCULATION',
    method: 'POST',
    endpoint:
      '/boq_calculations/' + boq_calculation_id + '/boq_template_calculations',
    data: formData,
  });
};

export const listPossibleBOQCalculations = () => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_BOQ_TEMPLATE_CALCULATION',
    method: 'GET',
    endpoint: '/boq_calculations/possible_boq_calculations',
  });
};

export const listPossibleBOQGroups = () => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_BOQ_TEMPLATE_GROUP',
    method: 'GET',
    endpoint: '/boq_groups/possible_boq_groups',
  });
};

export const listPossibleBOQSubgroups = () => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_BOQ_TEMPLATE_SUBGROUP',
    method: 'GET',
    endpoint: '/boq_subgroups/possible_boq_subgroups',
  });
};

export const listPossibleBOQTemplates = () => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_BOQ_TEMPLATE',
    method: 'GET',
    endpoint: '/boq/templates',
  });
};

export const addBOQTemplate = (selected_rows, stage_id, { name }) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_TEMPLATE',
    method: 'PUT',
    endpoint: '/stages/' + stage_id + '/boq/templates',
    data: {
      selected_rows: selected_rows,
      name,
    },
  });
};

export const addBOQFromTemplate = ({ stage_id, boq_template_id }) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_FROM_TEMPLATE',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/boq/templates',
    data: {
      boq_template_id,
    },
  });
};

export const addBOQTemplateGroup = ({ name }) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_TEMPLATE_GROUP',
    method: 'POST',
    endpoint: '/boq_template_groups',
    data: {
      name,
    },
  });
};

export const addBOQTemplateSubgroup = ({ name }) => {
  return store.dispatch({
    action: 'table.working_set_boq/ADD_BOQ_TEMPLATE_SUBGROUP',
    method: 'POST',
    endpoint: '/boq_template_subgroups',
    data: {
      name,
    },
  });
};

export const listPossibleBOQResponsibleUsers = (stage_id, user_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_BOQ_RESPONSIBLE_USERS',
    method: 'POST',
    endpoint: '/stages/' + stage_id + '/possible_boq_responsible_users',
    data: {
      user_id,
    },
  });
};

export const listPossibleResponsibleBOQTeams = (stage_id) => {
  return store.dispatch({
    action: 'table.working_set_boq/LIST_POSSIBLE_RESPONSIBLE_BOQ_STAGES',
    method: 'GET',
    endpoint: '/stages/' + stage_id + '/possible_responsible_boq_teams',
  });
};

export const updateBOQCalculationTags = (boq_calculation_id, formData) => {
  return store.dispatch({
    action: 'table.working_set_specifications/UPDATE_SPECIFICATION_TAGS',
    method: 'PUT',
    endpoint: '/boq_calculations/' + boq_calculation_id + '/tags',
    data: formData,
  });
};
