import React from 'react';
import styles from '../../../styles/components/RoomChooser.scss';
import classnames from 'classnames';
import Search from './Search';
import Tree from './Tree';
import Tooltip from '../../Tooltip';
import { __ } from '../../../core/utils';
import * as _ from 'lodash';

const getLabelProps = (activeTab, data) => {
  switch (activeTab) {
    case 'rooms':
      return {
        label: __('room-chooser.available-areas'),
        count: _.size(data.rooms),
      };
    case 'room_types':
      return {
        label: __('room-chooser.available-room-types'),
        count: _.size(data.room_types),
      };
    case 'entrances':
      return {
        label: __('room-chooser.available-entrances'),
        count: _.size(data.entrances),
      };
    case 'zones':
      return {
        label: __('room-chooser.available-zones'),
        count: _.size(data.zones),
      };
    case 'expansion_joints':
      return {
        label: __('room-chooser.available-expanded-joints'),
        count: _.size(data.expansion_joints),
      };
  }
};

const Tabs = ({ data, onQueryChange, children }) => {
  const [activeTabId, setActiveTabId] = React.useState('rooms');

  const reactChildren = React.Children.toArray(children);
  const tabs = reactChildren.map((child) => ({ ...child.props }));

  const { label, count } = getLabelProps(activeTabId, data);

  return (
    <div className={styles.tabs}>
      <div className={styles.tabsHeader}>
        <div className={styles.tabsLabel}>
          {label} {count > 0 && `(${count})`}
        </div>
        <div className={styles.tabsNavigation}>
          {tabs.map((tab) => (
            <button
              type='button'
              onClick={() =>
                _.size(tab.children.props.nodes) > 0 && setActiveTabId(tab.id)
              }
              className={classnames(
                styles.tabsNavigationButton,
                _.size(tab.children.props.nodes) == 0 && styles.disabled,
                tab.id === activeTabId && styles.tabsNavigationButtonActive
              )}
              key={tab.id}
            >
              <Tooltip text={tab.tooltipText}>
                <tab.Icon />
              </Tooltip>
            </button>
          ))}
        </div>
      </div>

      {onQueryChange && <Search onQueryChange={onQueryChange} />}

      <div>
        {reactChildren.filter((child) => child.props.id === activeTabId)}
      </div>
    </div>
  );
};

const Tab = ({ children }) => {
  return children;
};

const Content = (props) => {
  return (
    <div className={styles.availableAreas}>
      <Tree {...props} />
    </div>
  );
};

Tabs.Tab = Tab;
Tabs.Content = Content;
export default Tabs;
