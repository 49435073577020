import store from "../../../core/store";

export const downloadFile = (url, fields = {}, method = "POST") => {
    return store.dispatch({
        type: "general.downloader/START_DOWNLOAD",
        url,
        fields,
        method
    });
};

export const resetDownload = () => {
    return store.dispatch({
        type: "general.downloader/RESET_DOWNLOAD",
    });
};