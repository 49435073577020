import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../styles/forms/current_set_specifications/AttributesForm.scss';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import * as _ from 'lodash';
import Input from '../../components/Input';
import Select from '../../components/Select';
import {
  __,
  getLocalized,
  mapStateToProps,
  toBoolean,
  formatUrlToOpenExternalLink,
} from '../../core/utils';
import ColorPicker from '../../components/ColorPicker';
import classNames from 'classnames';
import MaterialPicker from '../../components/MaterialPicker';
import MultiSelect from '../../components/MultiSelect';
import Tooltip from '../../components/Tooltip';
import TexturePicker from '../../components/TexturePicker';
import GlobeIcon from '../../assets/images/globe-16x16.svg';

@reduxForm({
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
}))
@subscribe(Localization, 'localization')
class AttributesForm extends Component {
  render() {
    const boolean_options = [
      {
        label: __('current-set-specifications.attributes-form.yes'),
        value: '1',
      },
      {
        label: __('current-set-specifications.attributes-form.no'),
        value: '0',
      },
    ];

    const { handleSubmit, expanded, store, groupId } = this.props;
    const { language_id, flyout } = store;
    const { units, product_attribute_fields } = this.props.localization;

    const attributes = _.sortBy(
      _.reject(
        _.find(flyout.attribute_groups, ['id', groupId]).attributes,
        (attribute) => {
          return attribute.value == '' || attribute.value == null;
        }
      ),
      'priority'
    );

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, expanded && styles.expanded)}
      >
        {!_.isEmpty(attributes) && (
          <div className={styles.attributes}>
            {_.map(attributes, (attribute) => {
              const currentAttribute = attribute.template_field_id
                ? _.get(
                    product_attribute_fields,
                    attribute.template_field_id,
                    {}
                  )
                : attribute;
              const type = currentAttribute.type;
              let label = getLocalized(currentAttribute.label, language_id);
              label +=
                (attribute.unit_id || currentAttribute.unit_id) &&
                _.includes(['numeric', 'range', 'range_selectable'], type)
                  ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')'
                  : '';
              label += !attribute.template_field_id ? '*' : '';

              const labelTooltip = currentAttribute.description
                ? getLocalized(currentAttribute.description, language_id)
                : undefined;

              // for some reason, when redux receives a id for initial value key
              // it registers as many fields as big key is, i.e. if initial value
              // key was 2704, there would be 2704 registered fields for that form
              // with 1 - 2703 being just empty value.
              // "attr-" prefix is striped in _handleSubmit method.
              const fieldName = `attr-${attribute.id}`;

              return (
                <div
                  className={styles.attribute}
                  key={[attribute.id, attribute.template_field_id].join('-')}
                >
                  {type == 'boolean' && (
                    <Field
                      disabled={true}
                      name={fieldName}
                      component={Select}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={boolean_options}
                    />
                  )}
                  {type == 'dropdown_string' && (
                    <Field
                      disabled={true}
                      searchable
                      name={fieldName}
                      noSort
                      component={Select}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => ({
                          value: option.id,
                          label: _.toString(
                            getLocalized(option.value, language_id)
                          ),
                          disabled: toBoolean(option.discontinued),
                        })
                      )}
                    />
                  )}
                  {type == 'dropdown_string_multiple' && (
                    <Field
                      disabled={true}
                      searchable
                      name={fieldName}
                      noSort
                      component={MultiSelect}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => ({
                          value: option.id,
                          label: _.toString(
                            getLocalized(option.value, language_id)
                          ),
                          disabled: toBoolean(option.discontinued),
                        })
                      )}
                    />
                  )}
                  {type == 'dropdown_numeric' && (
                    <Field
                      disabled={true}
                      searchable
                      name={fieldName}
                      noSort
                      component={Select}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => {
                          let unit = _.get(units, [
                            attribute.unit_id,
                            'symbol',
                          ]);

                          return {
                            value: option.id,
                            label:
                              _.toString(
                                getLocalized(option.value, language_id)
                              ) + (unit ? ' (' + unit + ')' : ''),
                            disabled: toBoolean(option.discontinued),
                          };
                        }
                      )}
                    />
                  )}
                  {type == 'dropdown_numeric_multiple' && (
                    <Field
                      disabled={true}
                      searchable
                      name={fieldName}
                      noSort
                      component={MultiSelect}
                      label={label}
                      labelTooltip={labelTooltip}
                      options={_.map(
                        _.find(
                          this.props.localization.product_attribute_fields,
                          ['id', attribute.template_field_id]
                        ).options,
                        (option) => {
                          let unit = _.get(units, [
                            attribute.unit_id,
                            'symbol',
                          ]);

                          return {
                            value: option.id,
                            label:
                              _.toString(
                                getLocalized(option.value, language_id)
                              ) + (unit ? ' (' + unit + ')' : ''),
                            disabled: toBoolean(option.discontinued),
                          };
                        }
                      )}
                    />
                  )}

                  {type == 'string' &&
                    getLocalized(
                      _.get(
                        this.props.localization.product_attribute_fields,
                        attribute.template_field_id,
                        {}
                      ).label,
                      1
                    ) !== 'Product URL' && (
                      <Field
                        disabled={true}
                        name={fieldName + '.' + language_id}
                        component={Input}
                        label={label}
                        labelTooltip={labelTooltip}
                      />
                    )}

                  {type == 'string' &&
                    getLocalized(attribute.value, language_id) ===
                      'Product URL' &&
                    !_.isEmpty(label) && (
                      <div className={styles.productUrlContainer}>
                        <div className={styles.label}>
                          <label htmlFor={fieldName + '.' + language_id}>
                            <Tooltip text={labelTooltip || label}>
                              {label}
                            </Tooltip>
                          </label>
                        </div>

                        <a
                          className={styles.productUrlLink}
                          href={formatUrlToOpenExternalLink(
                            getLocalized(attribute.value, language_id)
                          )}
                          target='_blank'
                        >
                          <div className={styles.button}>
                            <GlobeIcon className={styles.globeIcon} />
                            {__(
                              'current_set_specification.flyout.tabs.attributes.product_url'
                            )}
                          </div>
                        </a>
                      </div>
                    )}
                  {_.includes(['numeric', 'range_selectable'], type) && (
                    <Field
                      disabled={true}
                      numeric
                      decimal
                      name={fieldName}
                      component={Input}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'color' && (
                    <Field
                      disabled={true}
                      name={fieldName}
                      language={store.language_id}
                      component={ColorPicker}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'material' && (
                    <Field
                      disabled={true}
                      name={fieldName}
                      component={MaterialPicker}
                      language={language_id}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'texture' && (
                    <Field
                      disabled={true}
                      name={fieldName}
                      component={TexturePicker}
                      language={language_id}
                      label={label}
                      labelTooltip={labelTooltip}
                    />
                  )}
                  {type == 'range' && (
                    <div className={styles.range}>
                      <Tooltip text={labelTooltip}>
                        <div className={styles.label}>{label}</div>
                      </Tooltip>
                      <div className={styles.flex}>
                        <div className={styles.f1}>
                          <Field
                            numeric
                            disabled={true}
                            decimal
                            name={fieldName + '.0'}
                            component={Input}
                          />
                        </div>
                        <span className={styles.minus}>-</span>
                        <div className={styles.f1}>
                          <Field
                            numeric
                            disabled={true}
                            decimal
                            name={fieldName + '.1'}
                            component={Input}
                            align='right'
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Form>
    );
  }
}

export default AttributesForm;
