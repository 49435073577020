import React, { Component } from "react";
import styles from "../styles/components/ColorPreview.scss";
import Tooltip from "./Tooltip";

class ColorPreview extends Component {
    render() {
        const { hex, label } = this.props;

        const hex_string = hex ? `#${hex}` : '';

        return (
            <span className={styles.wrapper}>
                <div className={styles.box} style={{ backgroundColor: hex_string }} />
                <Tooltip text={label || hex_string}>
                    <span>{label || hex_string}</span>
                </Tooltip>
            </span>
        );
    }
}

export default ColorPreview;