import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import {__, getLocalizedListValue} from "../../core/utils";
import {connect} from "react-redux";
import styles from "../../styles/views/companies_and_users/PartnerMembers.scss";
import {createSidebar} from "../../redux/actions/general/sidebar";
import {listPartnerMembers} from "../../redux/actions/table/partner_members";
import {listActiveModules} from "../../redux/actions/general/active_modules";
import * as _ from "lodash";
import TableNA from "../../components/TableNA";

const mapStateToProps = state => {
	return {
		auth: state.auth,
		table: state.table["partner_members"],
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: {
			setTableParams: params =>
				dispatch({
					type: "table.partner_members/SET_TABLE_PARAMS",
					params
				})
		}
	};
};
@connect(
	mapStateToProps,
	mapDispatchToProps
)
class PartnerMembers extends Component {
    componentDidMount() {
		listActiveModules();

        createSidebar({
            title: __('companies-and-users.title.companies-&-users'),
			items: [
				{
					title: __('companies-and-users.sidebar.companies'),
					link: () => "/companies",
					items: [
						{
							title: __('companies-and-users.sidebar.all-companies'),
							link: () => "/companies",
						},
						{
							title: __('companies-and-users.sidebar.partner-companies'),
							link: () => "/companies/partner"
						},
					]
				}, {
					title: __('companies-and-users.sidebar.users'),
					active: true,
					items: [
						{
							title: __('companies-and-users.sidebar.partner-members'),
							link: () => "/companies/partner_members",
							active: true
						},
						{
							title: __('companies-and-users.submenu.my-company-members'),
							link: () => "/companies/my/members",
						}
					]
				}, {
					title: __("modules.address-book"),
					link: () => "/address_book/companies",
					items: [
						{
							title: __("modules.address-book.companies"),
							link: () => "/address_book/companies",
						},
						{
							title: __("modules.address-book.contacts"),
							link: () => "/address_book/contacts",
						},
					]
				}
			]
        });

		// _.includes(["administrator", "owner"], this.props.auth.role) && setPlusActions([{
		// 	title: 'Invite New User',
		// 	onClick: () => setTableParams('all_companies', {
		// 		wizard_active: true
		// 	})
		// }]);
		//TODO:URGENT - implement this
    }

	render() {
		const columns = [
			{
				key: "fullname",
				name: __("Name"),
				required: true,
				default: true,
			},
			{
				key: "email",
				name: __("Email"),
				fullWidth: true,
				value: (value, row) => (
					<div className={styles.email}>
						<span>{value}</span>
					</div>
				),
				width: 180
			},
			{
				key: "phone_number",
				name: __('companies-and-users.my-company-members.column-title.phone_number'),
				width: 150,
				value: (phone_number, row) => (row.type == 'phone_number' && row.active == false) ? <span className={styles.faded}>{row.phone_number}</span> : row.phone_number
			},
			{
				key: "company",
				name: __("Company"),
				width: 180
			},
			{
				key: "professions",
				name: __("Profession"),
				value: professions => !_.isEmpty(professions) ? _.map(professions, profession => getLocalizedListValue(this.props.localization.disciplines, profession, "profession")).join(", ") : <TableNA />,
				sortable: false,
				width: 200
			}
		];

        const gridOptions = {
            section: "Users",
            image: (id, row) => row.avatar_url,
            title: (row) => row.fullname,
            subTitle: (row) => row.company,
            content: (row) => [
            	row.email
			] //TODO
        };

		return (
			<Table
				listAction={listPartnerMembers}
				name="partner_members"
				title={__("Partner Members")}
				columns={columns}
                gridOptions={gridOptions}
			/>
		);
	}
}

export default PartnerMembers;
