import store from "../../../core/store";

export const listExpansionJoints = (stage_id) => {
    return store.dispatch({
        action: "table.expansion_joints/LIST_EXPANSION_JOINTS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/expansion_joints",
        main: true,
    });
};

export const createExpansionJoint = (stage_id, formData) => {
    return store.dispatch({
        action: "table.expansion_joints/CREATE_EXPANSION_JOINT",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/expansion_joints",
        data: formData
    });
};

export const readExpansionJoint = (expansion_joint_id) => {
    return store.dispatch({
        action: "table.expansion_joints/READ_EXPANSION_JOINT",
        method: "GET",
        endpoint: "/expansion_joints/" + expansion_joint_id,
        has404: true
    });
};

export const updateExpansionJoint = (expansion_joint_id, formData) => {
    return store.dispatch({
        action: "table.expansion_joints/UPDATE_EXPANSION_JOINT",
        method: "PUT",
        endpoint: "/expansion_joints/" + expansion_joint_id,
        data: formData
    });
};

export const deleteExpansionJoints = (expansion_joint_ids) => {
    return store.dispatch({
        action: "table.expansion_joints/DELETE_EXPANSION_JOINTS",
        method: "DELETE",
        endpoint: "/expansion_joints",
        data: {
            expansion_joints: expansion_joint_ids
        }
    });
};