import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams } from "../../core/utils";
import { createSuperadminSidebar } from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import {
	deleteBoqSupplyTypeGroups,
	listBoqSupplyTypeGroups,
	readBoqSupplyTypeGroup
} from "../../redux/actions/table/superadmin/boq_supply_type_groups";
import BOQSupplyTypeGroupsFlyout from "../../flyouts/superadmin/boq_supply_type_groups/BOQSupplyTypeGroupsFlyout";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table['superadmin_boq_supply_type_groups'],
}))
class BOQSupplyTypeGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js
		
		const { superadmin } = this.props;

		superadmin.add_boq_supply_type_group && setPlusActions([{
			title: "Add bill of quantities supply type group",
			onClick: () => setTableParams('superadmin_boq_supply_type_groups', {
				add_boq_supply_type_groups_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readBoqSupplyTypeGroup(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, auth } = this.props;

		const columns = [
			{
				key: "name",
				name: "Name",
				value: name => _.get(name, _.get(auth, 'language_id', 1)),
			},
		];

		const singleActions = (boq_supply_type_group_id) => {
			return [
				<Tooltip text="Delete selected bill of quantities supply type group" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected bill of quantities supply type group?\n\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteBoqSupplyTypeGroups([boq_supply_type_group_id]).then(() => {
												hideAlertbox();

												setNotification("The selected bill of quantities supply type group has been deleted.");
												listBoqSupplyTypeGroups();

												setTableParams('superadmin_boq_supply_type_groups', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (boq_supply_type_group_ids) => [
			<Tooltip text="Delete selected bill of quantities supply type groups" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these bill of quantities supply type groups?\n\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteBoqSupplyTypeGroups(boq_supply_type_group_ids).then(() => {
											hideAlertbox();

											setNotification("The selected bill of quantities supply type groups have been deleted.");
											listBoqSupplyTypeGroups();

											setTableParams('superadmin_boq_supply_type_groups', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<BOQSupplyTypeGroupsFlyout
				data={this.state.preview}
				readAction={boq_supply_type_group_id => {
					return new Promise(resolve => {
						readBoqSupplyTypeGroup(getURLParam('id') || boq_supply_type_group_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listBoqSupplyTypeGroups}
				name="superadmin_boq_supply_type_groups"
				groupActions={superadmin.delete_boq_supply_type_group && groupActions}
				singleActions={superadmin.delete_boq_supply_type_group && singleActions}
				title={__("modules.submodules.title.boq-supply-type-groups")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default BOQSupplyTypeGroups;
