import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import {withRouter} from "react-router";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import styles from "../../styles/forms/auth/ResetPasswordForm.scss";
import PasswordInput from "../../components/PasswordInput";
import {__, mapStateToProps, redirect} from "../../core/utils";

@withRouter
@reduxForm({
	form: "auth.reset_password",
})
@mapStateToProps(state => ({
	values: getFormValues("auth.reset_password")(state)
}))
class ResetPasswordForm extends Component {
	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.form}>
					<Field
						name="password"
						component={PasswordInput}
						success={this.props.values && this.props.values.password_confirmation && this.props.values.password == this.props.values.password_confirmation}
						label={__('welcome-to-volum3.recover.reset-password.gmail.reset-my-password.enter-new-password')}
						center
					/>
					<Field
						name="password_confirmation"
						component={PasswordInput}
						error={this.props.values && this.props.values.password_confirmation && this.props.values.password != this.props.values.password_confirmation}
						success={this.props.values && this.props.values.password_confirmation && this.props.values.password == this.props.values.password_confirmation}
						label={__('welcome-to-volum3.recover.reset-password.gmail.reset-my-password.confirm-new-password')}
						center
					/>
					<div className={styles.info}>
						{__('welcome-to-volum3.forgot-password.recover.just-remembered-your-forgotten-password')}? <a onClick={() => redirect("/login")}>{__('Sign in')}</a>
					</div>
				</div>
				<div className={styles.footer}>
					<Button submit center leftText={__('button.reset-password')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
				</div>
			</Form>
		);
	}
}

export default ResetPasswordForm;