import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {enableAutosave} from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/meetings/EditMeetingForm.scss";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import {__, mapStateToProps, setTableParams} from "../../core/utils";
import * as _ from "lodash";
import classNames from "classnames";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import PeopleChooser from "../../components/PeopleChooser";
import {
    listPossibleMeetingAdministrators,
    listPossibleMeetingFollowers,
    listPossibleMeetingParticipants
} from "../../redux/actions/table/all_meetings";
import Tags from "../../components/Tags";
import Select from "../../components/Select";
import MultiSelect from "../../components/MultiSelect";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import {listPossibleTags} from "../../redux/actions/general";

@reduxForm({
    form: "meetings.edit_meeting",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    table: state.table["all_meetings"],
    user_id: state.auth.id,
}))
@subscribe(Localization, "localization")
class EditMeetingForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_meeting_administrators: [],
            possible_meeting_participants: [],
            possible_meeting_followers: [],
            stage_tags: []
        }
    }

    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    componentDidMount() {
        const {data} = this.props;

        listPossibleMeetingAdministrators(this.props.match.params.stage || this.props.table.stage_id).then(({response}) => {
            this.setState({possible_meeting_administrators: response.data});
        });

        listPossibleMeetingParticipants(this.props.match.params.stage || this.props.table.stage_id).then(({response}) => {
            this.setState({possible_meeting_participants: response.data});
        });

        listPossibleMeetingFollowers(this.props.match.params.stage || this.props.table.stage_id).then(({response}) => {
            this.setState({possible_meeting_followers: response.data});
        });

        listPossibleTags('project', data.project_id).then(({response}) => {
            this.setState({stage_tags: response.data});
        });
    }

    render() {
        const {data, user_id} = this.props;
        const {project_languages, status} = data;
        const is_administrator = _.isObject(_.find(data.administrators, ['id', user_id]));

        const disabled = !is_administrator || _.includes(['closed', 'canceled'], status);

        const languages = _.map(project_languages, (primary, language_id) => ({
            label: _.get(this.props.localization.languages, [language_id, 'name']),
            value: language_id,
        }));

        return (
            <Form onSubmit={this.props.handleSubmit}>
                <div className={styles.form}>
                    <div className={styles.flex}>
                        <div className={styles.f2}>
                            <Field disabled={disabled} name="title" component={Input} localized={project_languages} label={__('table.columns.meeting-title')} maxLength={50} />
                        </div>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="languages" component={MultiSelect} searchable label={__('Meeting Languages')} noSort options={languages} />
                        </div>
                    </div>
                    <Field disabled={disabled} name="duration" component={Input} label={__('Duration Estimate')} numeric maxLength={4} hint={__('meetings.hint.in-minutes')} />
                    <Field disabled={disabled} name="description" component={Textarea} localized={project_languages} label={__('table.columns.meeting-description')} maxLength={1000} rows={1} maxRows={10} />
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="administrators" component={PeopleChooser} label={__('table.columns.meeting-administrators')} searchable options={this.state.possible_meeting_administrators} />
                        </div>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="participants" component={PeopleChooser} label={__('table.columns.meeting-participants')} searchable options={this.state.possible_meeting_participants} />
                        </div>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="followers" component={PeopleChooser} label={__('table.columns.meeting-followers')} searchable options={this.state.possible_meeting_followers} />
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <div className={classNames(styles.unregisteredMembers, disabled && styles.disabled)} onClick={() => !disabled && setTableParams('all_meetings', {
                                wizard_unregistered_meeting_member: true,
                                meeting_id: data.id,
                                type: 'participant',
                                edit_data: undefined
                            })}>
                                <div className={styles.label}>
                                    {__('meeting.field.unregistered-participants')}
                                </div>
                                <div className={styles.input}>
                                    <div className={styles.list}>
                                        {_.map(data.unregistered_participants, (unregistered_participant, i) => (
                                            <span key={i} onClick={(e) => {
                                                e.stopPropagation();

                                                setTableParams('all_meetings', {
                                                    wizard_unregistered_meeting_member: true,
                                                    meeting_id: data.id,
                                                    type: 'participant',
                                                    edit_data: unregistered_participant,
                                                    disabled
                                                });
                                            }}>{unregistered_participant.fullname}</span>
                                        ))}
                                    </div>
                                    {!disabled && <PlusMiddleIcon />}
                                </div>
                            </div>
                        </div>
                        <div className={styles.f1}>
                            <div className={classNames(styles.unregisteredMembers, disabled && styles.disabled)} onClick={() => !disabled && setTableParams('all_meetings', {
                                wizard_unregistered_meeting_member: true,
                                meeting_id: data.id,
                                type: 'follower',
                                edit_data: undefined
                            })}>
                                <div className={styles.label}>
                                    {__('meeting.field.unregistered-followers')}
                                </div>
                                <div className={styles.input}>
                                    <div className={styles.list}>
                                        {_.map(data.unregistered_followers, (unregistered_follower, i) => (
                                            <span key={i} onClick={(e) => {
                                                e.stopPropagation();

                                                setTableParams('all_meetings', {
                                                    wizard_unregistered_meeting_member: true,
                                                    meeting_id: data.id,
                                                    type: 'follower',
                                                    edit_data: unregistered_follower,
                                                    disabled
                                                });
                                            }}>{unregistered_follower.fullname}</span>
                                        ))}
                                    </div>
                                    {!disabled && <PlusMiddleIcon />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="location_name" component={Input} label={__('table.columns.meeting-location-name')} maxLength={50} />
                        </div>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="location_address" component={Input} label={__('table.columns.meeting-location-address')} maxLength={50} />
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="period" component={Select} label={__('table.columns.meeting-review-period')} options={_.map(_.range(1,8), days => ({
                                label: days + ' day (' + (days * 24) + 'h)',
                                value: days
                            }))} />
                        </div>
                        <div className={styles.f1}>
                            <Field disabled={disabled} name="tags" component={Tags} label={__('table.columns.meeting-tags')} options={this.state.stage_tags} />
                        </div>
                    </div>
                    <Field disabled={disabled} name="footnote" component={Textarea} localized={project_languages} label={__('table.columns.meeting-report-footnote')} maxLength={300} rows={1} maxRows={10} />
                </div>
            </Form>
        );
    }
}

export default EditMeetingForm;
