import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/invite_new_user_wizard/InviteNewUserForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import Input from "../../components/Input";
import {__} from "../../core/utils";

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideInviteNewUserWizard: () => dispatch({
                type: "table.all_companies/SET_TABLE_PARAMS",
                params: {
                    wizard_active: undefined
                }
            })
        }
    };
};

@reduxForm({
    form: "invite_new_user_wizard.invite_new_user",
})
@connect(null, mapDispatchToProps)
class InviteNewUserForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <Field name="email" component={Input} label={__('companies-and-users.my-company-members.invite-new-user.new-users-email')} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideInviteNewUserWizard} />
                    <Button gray medium submit right leftText={__('button.invite')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default InviteNewUserForm;
