import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CreateSpecificationFromProductWizard.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  mapStateToProps,
  redirect,
  setTableParams,
  validateForm,
} from '../core/utils';
import { getFormValues } from 'redux-form';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import autobind from 'autobind-decorator';
import { createSpecificationFromProduct } from '../redux/actions/table/products';
import { setNotification } from '../redux/actions/general/notification';
import * as _ from 'lodash';
import CreateSpecificationFromProductForm from '../forms/create_specification_from_product_wizard/CreateSpecificationFromProductForm';
import AddSpecificationFromProductIcon from '../assets/images/add-specification-from-product-60x60.svg';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  basic_attributes: getFormValues(
    'create_specification_from_product_wizard.product_basic_attributes'
  )(state),
  product_id: _.get(
    state.table,
    'products.create_specification_from_product_wizard'
  ),
  create_action: state.table.working_set_specifications.create_action,
  attributes: getFormValues('products.read_only.attributes')(state),
}))
class CreateSpecificationFromProductWizard extends Component {
  @autobind
  _handleCreateProductSpecification(formData) {
    const { product_id, attributes } = this.props;
    const { edit } = formData;

    formData = {
      project_id: formData.project_id,
      stage_id: formData.stage_id,
      team_id: formData.team_id,
      unit_id: formData.unit_id,
      specification_subgroup_id: formData.specification_subgroup_id,
      code: formData.code,
      action: this.props.create_action ? formData.action : undefined,
      attributes: {},
    };

    _.each(attributes, (value, id) => {
      formData['attributes'][_.replace(id, 'field-', '')] = value;
    });

    return validateForm(
      createSpecificationFromProduct(product_id, formData).then(
        ({ response }) => {
          this.props.onClose();

          setNotification(
            __(
              'products.all-products.item-actions.successfully-added-new-specification-from-product'
            )
          );

          edit &&
            redirect(
              '/stages/' +
                formData.stage_id +
                '/specifications/working?id=' +
                response.data.id
            );
        }
      )
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'products.all-products.item-actions.create-specification-from-product'
              )}
            </span>
            <span>
              {__(
                'products.all-products.item-actions.subtitle.create-specification-from-product'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddSpecificationFromProductIcon />
          </div>
        </div>
        <div className={styles.body}>
          <CreateSpecificationFromProductForm
            onSubmit={this._handleCreateProductSpecification}
            initialValues={{
              action: 'option_main',
            }}
          />
        </div>
      </>
    );
  }
}

export default CreateSpecificationFromProductWizard;
