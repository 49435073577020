import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/PrintCurrentSetSpecificationsWizard.scss';
import autobind from 'autobind-decorator';
import PrintIcon from '../assets/images/print-60x60.svg';
import {
  __,
  getActiveStage,
  mapStateToProps,
  validateForm,
} from '../core/utils';
import * as _ from 'lodash';
import PrintCurrentSetSpecificationsForm from '../forms/print_current_set_specifications_wizard/PrintCurrentSetSpecificationsForm';
import { setNotification } from '../redux/actions/general/notification';
import { printCurrentSetSpecifications } from '../redux/actions/table/current_set_specifications';
import moment from 'moment';

@mapStateToProps((state) => ({
  specifications: _.get(
    state.table,
    'current_set_specifications.print_specifications_wizard'
  ),
  project_language: _.get(
    state.table,
    'current_set_specifications.project_language'
  ),
  specification_ids: _.get(
    state.table,
    'current_set_specifications.specification_ids'
  ),
}))
class PrintCurrentSetSpecificationsWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    const { specification_ids } = this.props;

    if (formData.date) {
      formData = {
        ...formData,
        date: moment.utc(formData.date).clone().local().format('YYYY-MM-DD'),
      };
    }

    return validateForm(
      printCurrentSetSpecifications(
        getActiveStage(),
        formData.specifications == 'print_all' ? null : specification_ids,
        formData
      ).then(() => {
        setNotification(
          __(
            'specifications.current-set.flyout.print-specification.notification.current-set-specification-pdf-generation-start.receivenotification-download-link'
          )
        );

        this.props.onClose();
      })
    );
  }

  render() {
    const { specifications, specification_ids } = this.props;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__('specifications.current-set.flyout.print.pdf-settings')}
            </span>
            <span>
              {__(
                'specifications.current-set.flyout.print.choose-options-specifications-pdf'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <PrintIcon />
          </div>
        </div>
        <div className={styles.body}>
          <PrintCurrentSetSpecificationsForm
            onSubmit={this._handleSubmit}
            initialValues={{
              cover: _.size(specifications) > 1 ? 1 : 0,
              contents_simple: _.size(specifications) > 1 ? 1 : 0,
              contents_detailed: _.size(specifications) > 1 ? 1 : 0,
              header: 1,
              footer: 1,
              model: 1,
              manufacturer: 1,
              representative: 1,
              language_id: this.props.project_language,
              date: moment().startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
              specifications,
              specification_ids,
              print_linked_specifications: 1,
              print_specification_quantities: 1,
            }}
          />
        </div>
      </>
    );
  }
}

export default PrintCurrentSetSpecificationsWizard;
