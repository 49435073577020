import store from "../../../core/store";

export const listRoomGroups = (stage_id) => {
    return store.dispatch({
        action: "table.room_groups/LIST_ROOM_GROUPS",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/room_groups",
        main: true,
    });
};

export const createRoomGroup = (stage_id, formData) => {
    return store.dispatch({
        action: "table.room_groups/CREATE_ROOM_GROUP",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/room_groups",
        data: formData
    });
};

export const readRoomGroup = (room_group_id) => {
    return store.dispatch({
        action: "table.room_groups/READ_ROOM_GROUP",
        method: "GET",
        endpoint: "/room_groups/" + room_group_id,
        has404: true
    });
};

export const updateRoomGroup = (room_group_id, formData) => {
    return store.dispatch({
        action: "table.room_groups/UPDATE_ROOM_GROUP",
        method: "PUT",
        endpoint: "/room_groups/" + room_group_id,
        data: formData
    });
};

export const deleteRoomGroups = (room_group_ids) => {
    return store.dispatch({
        action: "table.room_groups/DELETE_ROOM_GROUPS",
        method: "DELETE",
        endpoint: "/room_groups",
        data: {
            room_groups: room_group_ids
        }
    });
};