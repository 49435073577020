import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/EditSpecificationProductAttributeGroupFieldWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeEditIcon from "../assets/images/product-attribute-edit-60x60.svg";
import {__, mapStateToProps, setTableParams, toBoolean, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import * as _ from "lodash";
import {
	readWorkingSetSpecification,
	updateSpecificationProductAttributeGroupField
} from "../redux/actions/table/working_set_specifications";
import EditSpecificationProductAttributeGroupFieldForm
	from "../forms/edit_specification_product_attribute_group_field_form/EditSpecificationProductAttributeGroupFieldForm";
import {subscribe} from "react-contextual";
import Localization from "../helpers/Localization";

@mapStateToProps(state => ({
	field_id: _.get(state, 'table.working_set_specifications.edit_specification_product_attribute_group_field_wizard'),
	flyout: state.table.working_set_specifications.flyout
}))
@subscribe(Localization, "localization")
class EditSpecificationProductAttributeGroupFieldWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		formData = {
			...formData,
			custom_attribute: _.get(formData, 'custom_attribute', false) ? 1 : 0,
		};

		return validateForm(
			updateSpecificationProductAttributeGroupField(this.props.field_id, formData).then(() => readWorkingSetSpecification(this.props.flyout.id).then(({response}) => {
				setNotification(__('notification.specification-attribute-updated'));

				setTableParams('working_set_specifications', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		const {flyout, field_id} = this.props;

		let attribute = _.find(_.flatten(_.map(flyout.attribute_groups, 'attributes')), ['id', field_id]);

		const type = attribute.template_field_id ? _.get(this.props.localization.product_attribute_fields, [attribute.template_field_id, 'type']) : attribute.type;

		let initialValues = {
			custom_attribute: !attribute.template_field_id,
			template_field_id: attribute.template_field_id,
			unit_id: (type == 'numeric' || type == 'dropdown_numeric' || type == 'dropdown_numeric_multiple' || type == 'range' || type == 'range_selectable') ? attribute.unit_id : undefined,
			type
		};

		if(!attribute.template_field_id) {
			initialValues['label'] = attribute.label;
		}

		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.edit-specification-attribute.field.title')}</span>
						<span>{__('wizard.edit-specification-attribute-group.field.subtitle')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeEditIcon />
					</div>
				</div>
				<div className={styles.body}>
					<EditSpecificationProductAttributeGroupFieldForm onSubmit={this._handleSubmit} initialValues={initialValues} />
				</div>
			</>
		);
	}
}

export default EditSpecificationProductAttributeGroupFieldWizard;