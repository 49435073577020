import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/CopyBOQItemWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  getActiveStage,
  getLocalized,
  mapStateToProps,
  setFormErrors,
  validateForm,
} from '../core/utils';
import CopyIcon from '../assets/images/copy-64x64.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import AddSpecificationToCalculationForm from '../forms/add_specification_to_calculation_wizard/AddSpecificationToCalculationForm';
import {
  addSpecificationCodeToCalculation,
  copyBOQCalculation,
  copyBOQItem,
  moveBOQCalculation,
  moveBOQItem,
  moveBOQPosition,
  moveBOQSubgroup,
  readWorkingSetBOQ,
  reorderBOQCalculations,
  reorderBOQItems,
  reorderBOQSubgroups,
} from '../redux/actions/table/working_set_boq';
import CopyBOQItemForm from '../forms/copy_boq_item_wizard/CopyBOQItemForm';

const select_field_name = {
  0: 'boq_group_id',
  1: 'boq_subgroup_id',
  2: 'boq_item_id',
  3: 'boq_calculation_id',
  4: 'boq_position_id',
};

@mapStateToProps((state) => ({
  id: _.get(state.table, 'working_set_boq.copy_boq_item_wizard.id'),
  level: _.get(state.table, 'working_set_boq.copy_boq_item_wizard.level'),
  name: _.get(state.table, 'working_set_boq.copy_boq_item_wizard.name'),
  store: state.table['working_set_boq'],
}))
@withRouter
class CopyBOQItemWizard extends Component {
  constructor(props) {
    super(props);

    const filtered_data = _.reject(this.props.store.data, (group) =>
      _.includes(group.id, 'UN')
    );
    const level = this.props.level;

    let options = [];

    if (level == 2) {
      options = _.filter(
        _.map(filtered_data, (group) => {
          return {
            value: group.id,
            label:
              group.code +
              ' ' +
              getLocalized(_.get(group.name, this.props.store.language_id, '')),
            children: _.map(group.children, (subgroup) => {
              return {
                value: subgroup.id,
                label:
                  subgroup.code +
                  ' ' +
                  getLocalized(
                    _.get(subgroup.name, this.props.store.language_id, '')
                  ),
                parentId: subgroup.parentId,
                children: _.map(subgroup.children, (item) => {
                  return {
                    value: item.id,
                    label:
                      item.code +
                      ' ' +
                      getLocalized(
                        _.get(item.name, this.props.store.language_id, '')
                      ),
                    parentId: item.parentId,
                  };
                }),
              };
            }),
          };
        }),
        (data) => !_.isEmpty(data.children)
      );
    } else if (level == 3) {
      options = _.filter(
        _.map(filtered_data, (group) => {
          return {
            value: group.id,
            label:
              group.code +
              ' ' +
              getLocalized(_.get(group.name, this.props.store.language_id, '')),
            children: _.filter(
              _.map(group.children, (subgroup) => {
                return {
                  value: subgroup.id,
                  label:
                    subgroup.code +
                    ' ' +
                    getLocalized(
                      _.get(subgroup.name, this.props.store.language_id, '')
                    ),
                  parentId: subgroup.parentId,
                  children: _.map(subgroup.children, (item) => {
                    return {
                      value: item.id,
                      label:
                        item.code +
                        ' ' +
                        getLocalized(
                          _.get(item.name, this.props.store.language_id, '')
                        ),
                      parentId: item.parentId,
                      children: _.map(item.children, (calculation) => {
                        return {
                          value: calculation.id,
                          label:
                            calculation.code +
                            ' ' +
                            getLocalized(
                              _.get(
                                calculation.name,
                                this.props.store.language_id,
                                ''
                              )
                            ),
                          parentId: calculation.parentId,
                        };
                      }),
                    };
                  }),
                };
              }),
              (data) => !_.isEmpty(data.children)
            ),
          };
        }),
        (data) => !_.isEmpty(data.children)
      );
    }

    this.state = {
      options,
    };
  }

  @autobind
  _findNestedOptions(options, targetValue) {
    let foundOptions = null;

    _.each(options, (option) => {
      if (
        !foundOptions &&
        option.value?.toString() === targetValue?.toString()
      ) {
        foundOptions = options;
      }
      if (!foundOptions && option.children) {
        foundOptions = this._findNestedOptions(option.children, targetValue);
      }
    });

    return foundOptions;
  }

  @autobind
  _handleSubmit(formData) {
    const { level, id } = this.props;

    const reorder_actions = {
      1: reorderBOQSubgroups,
      2: reorderBOQItems,
      3: reorderBOQCalculations,
    };

    const copy_actions = {
      2: copyBOQItem,
      3: copyBOQCalculation,
    };

    let destinationRows = _.cloneDeep(
      this._findNestedOptions(
        this.state.options,
        formData[select_field_name[level]]
      )
    );

    // Find the index where the element should be inserted in the destination array
    const indexToInsert = _.findIndex(
      destinationRows,
      (row) => row.value == formData[select_field_name[level]]
    );

    const empty_parent_item = _.includes(id, 'UN')
      ? !(_.size(_.split(formData[select_field_name[level]], '-')) > 3)
      : _.size(_.split(formData[select_field_name[level]], '-')) !=
        _.size(_.split(id, '-'));

    const selected_item_id = empty_parent_item
      ? _.last(_.split(formData[select_field_name[level]], '-'))
      : _.nth(_.split(formData[select_field_name[level]], '-'), -2);

    const actions = (
      level,
      {
        copySourceId,
        copyDestinationItem,
        reorderSourceId,
        reorderDestionationItems,
      }
    ) => {
      return Promise.resolve(
        copy_actions[level](copySourceId, copyDestinationItem).then(
          ({
            response: {
              data: { id },
            },
          }) => {
            reorderDestionationItems.splice(
              indexToInsert + (formData.move_action == 'before' ? 0 : 1),
              0,
              id
            );

            !empty_parent_item &&
              reorder_actions[level](reorderSourceId, reorderDestionationItems);
          }
        )
      );
    };

    const validation = validateForm(
      actions(level, {
        copySourceId: _.last(id.split('-')),
        copyDestinationItem: {
          [select_field_name[level - 1]]: selected_item_id,
        },
        reorderSourceId: selected_item_id,
        reorderDestionationItems: _.map(destinationRows, (row) =>
          _.last(row.value.split('-'))
        ),
      }).then(() =>
        readWorkingSetBOQ(getActiveStage()).then(() => {
          const messages = {
            2: 'An item has been successfully copied to the selected subgroup',
            3: 'A cost has been successfully copied to the selected item',
          };

          setNotification(messages[level]);

          this.props.onClose();
        })
      )
    );

    validation.catch(({ errors }) => {
      setFormErrors('copy_boq_item_wizard.copy_boq_item', {
        [select_field_name[level]]: ['Field required'],
      });
    });

    return validation;
  }

  render() {
    const { level, name } = this.props;

    const title = {
      2: __('working-set-boq.item.copy'),
      3: __('working-set-boq.cost.copy'),
    };

    const description = {
      2: __('working-set-boq.item.copy.description'),
      3: __('working-set-boq.cost.copy.description'),
    };

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>{title[level]}</span>
            <span>{description[level]}</span>
          </div>
          <div className={styles.right}>
            <CopyIcon />
          </div>
        </div>
        <div className={styles.body}>
          <CopyBOQItemForm
            onSubmit={this._handleSubmit}
            initialValues={{ name, move_action: 'after' }}
            selectFieldName={select_field_name[level]}
            options={this.state.options}
          />
        </div>
      </>
    );
  }
}

export default CopyBOQItemWizard;
