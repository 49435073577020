import store from "../../../../core/store";

export const listPaperSizes = () => {
	return store.dispatch({
		action: "table.superadmin_paper_sizes/LIST_PAPER_SIZES",
		method: "GET",
		endpoint: "/superadmin/paper_sizes",
		main: true
	});
};

export const createPaperSize = (formData) => {
	return store.dispatch({
		action: "table.superadmin_paper_sizes/CREATE_PAPER_SIZE",
		method: "POST",
		endpoint: "/superadmin/paper_sizes",
		data: formData
	});
};

export const deletePaperSizes = (paper_size_ids) => {
	return store.dispatch({
		action: "table.superadmin_paper_sizes/DELETE_PAPER_SIZES",
		method: "DELETE",
		endpoint: "/superadmin/paper_sizes",
		data: {
			paper_sizes: paper_size_ids
		}
	});
};

export const readPaperSize = (paper_size_id) => {
	return store.dispatch({
		action: "table.superadmin_paper_sizes/READ_PAPER_SIZE",
		method: "GET",
		endpoint: "/superadmin/paper_sizes/" + paper_size_id,
		has404: true
	});
};

export const updatePaperSize = (paper_size_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_paper_sizes/UPDATE_PAPER_SIZE",
		method: "PUT",
		endpoint: "/superadmin/paper_sizes/" + paper_size_id,
		data: formData
	});
};