import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import Textarea from '../../components/Textarea';
import { __, transformToListPairs } from '../../core/utils';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/zones/EditZoneForm.scss';
import Input from '../../components/Input';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import MultiSelect from '../../components/MultiSelect';

@reduxForm({
  form: 'zones.edit_zone',
  enableReinitialize: true,
})
@withRouter
@subscribe(Localization, 'localization')
class EditZoneForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  render() {
    const { localization, handleSubmit, data } = this.props;
    const { functional_spaces } = localization;
    const { project_languages } = data;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name='title'
          component={Input}
          localized={project_languages}
          label={__('zones.field.title')}
          maxLength={50}
        />
        <Field
          name='description'
          component={Textarea}
          localized={project_languages}
          label={__('zones.field.description')}
          maxLength={2000}
          rows={1}
          maxRows={10}
        />
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='functional_spaces'
              component={MultiSelect}
              label={__('zones.field.functional-space')}
              searchable
              options={transformToListPairs(functional_spaces)}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='volume'
              component={Input}
              label={__('zones.field.volume')}
              numeric
              decimal
              maxLength={10}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='area'
              component={Input}
              label={__('zones.field.area')}
              numeric
              decimal
              maxLength={10}
            />
          </div>
        </div>
        <Field
          name='requirement'
          component={Textarea}
          label={__('zone.field.requirement')}
          maxLength={2000}
          rows={3}
          maxRows={10}
          localized={project_languages}
        />
      </Form>
    );
  }
}

export default EditZoneForm;
