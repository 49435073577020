import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  dateTimeFrom,
  getActiveStage,
  getURLParam,
  mapStateToProps,
  redirect,
  setTableParams,
} from '../../core/utils';
import Table from '../../components/Table';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import { getTeamsPermissions } from '../../redux/actions/table/teams';
import { setNotification } from '../../redux/actions/general/notification';
import DeleteIcon from '../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import InfoPositiveIcon from '../../assets/images/info-positive-16x16.svg';
import {
  deleteStoreys,
  listStoreys,
  readStorey,
} from '../../redux/actions/table/storeys';
import StoreysFlyout from '../../flyouts/storeys/StoreysFlyout';
import classNames from 'classnames';
import styles from '../../styles/views/space_allocation/Storeys.scss';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';

@mapStateToProps((state) => ({
  table: state.table['storeys'],
}))
class Storeys extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
  }

  componentDidMount() {
    getTeamsPermissions(this.props.match.params.stage).then(({ response }) => {
      this.setState({ team_roles: _.get(response.data, 'teams', {}) });

      !_.isEmpty(this.state.team_roles.editor) &&
        setPlusActions([
          {
            title: __('space-allocation.storeys.table.button.create'),
            onClick: () =>
              setTableParams('storeys', {
                add_storey_wizard: true,
              }),
          },
        ]);
    });

    enableProjectsDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/storeys')
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect('/stages/' + stage_id + '/space_allocation/storeys')
    );

    createSidebar({
      title: __('submenu.title.space-allocation'),
      items: [
        {
          title: __('submenu.title.space-allocation.rooms'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/rooms',
        },
        {
          title: __('submenu.title.space-allocation.room-types'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_types',
        },
        {
          title: __('submenu.title.space-allocation.room-groups'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/room_groups',
        },
        {
          title: __('submenu.title.space-allocation.zones'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/zones',
        },
        {
          title: __('submenu.title.space-allocation.entrances'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/entrances',
        },
        {
          title: __('submenu.title.space-allocation.expansion-joints'),
          link: () =>
            '/stages/' +
            getActiveStage() +
            '/space_allocation/expansion_joints',
        },
        {
          title: __('submenu.title.space-allocation.storeys'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/storeys',
          active: true,
        },
        {
          title: __('submenu.title.space-allocation.buildings'),
          link: () =>
            '/stages/' + getActiveStage() + '/space_allocation/buildings',
        },
        {
          title: __('submenu.title.space-allocation.sites'),
          link: () => '/stages/' + getActiveStage() + '/space_allocation/sites',
        },
      ],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        this.setState({ preview: undefined });
      }

      readStorey(this.props.table.clicked_row).then(({ response }) => {
        this.setState({ preview: response.data });
      });
    }
  }

  render() {
    const columns = [
      {
        key: 'number',
        name: __('storeys.field.number'),
        width: 60,
      },
      {
        key: 'name',
        name: __('storeys.field.name'),
        required: true,
        sortable: false,
      },
      {
        key: 'site',
        name: __('storeys.field.site'),
        sortable: false,
        width: 150,
      },
      {
        key: 'building',
        name: __('storeys.field.building'),
        sortable: false,
        width: 150,
      },
      {
        key: 'rooms',
        name: __('storeys.field.rooms'),
        value: (rooms, row) => (
          <div
            className={classNames(styles.rooms)}
            onClick={() => {
              setTableParams(
                'rooms',
                _.transform(
                  [
                    'filter.site',
                    'filter.building',
                    'filter.storey',
                    'filter.expansion_joint',
                    'filter.entrance',
                    'filter.zone',
                    'filter.functional_space',
                    'filter.foh',
                    'filter.room_group',
                    'filter.room_type',
                    'filter.security',
                    'filter.date',
                    'filter.creator',
                    'query',
                  ],
                  (filters, filter) => {
                    filters[filter] = '';

                    return filters;
                  },
                  {}
                )
              );

              redirect(
                '/stages/' +
                  this.props.match.params.stage +
                  '/space_allocation/rooms?filter.storey=' +
                  row.id
              );
            }}
          >
            {rooms}
            <ArrowDoubleRightSmallIcon />
          </div>
        ),
        width: 60,
        sortable: false,
      },
      {
        key: 'relative_altitude',
        name: __('storeys.field.relative-altitude'),
        width: 75,
        value: (relative_altitude) =>
          relative_altitude ? (
            _.toNumber(relative_altitude).toFixed(2)
          ) : (
            <TableNA />
          ),
        sortable: false,
      },
      {
        key: 'height',
        name: __('storeys.field.height'),
        width: 60,
        value: (height) =>
          height ? _.toNumber(height).toFixed(2) : <TableNA />,
      },
      {
        key: 'volume',
        name: __('storeys.field.volume'),
        width: 60,
        value: (volume) =>
          volume ? _.toNumber(volume).toFixed(2) : <TableNA />,
        sortable: false,
      },
      {
        key: 'area',
        name: __('storeys.field.area'),
        width: 60,
        value: (area) => (area ? _.toNumber(area).toFixed(2) : <TableNA />),
        sortable: false,
      },
      {
        key: 'created_at',
        name: __('storeys.field.created-at'),
        value: (created_at) => (
          <Tooltip text={dateTimeFrom(created_at)}>
            {dateFrom(created_at)}
          </Tooltip>
        ),
        width: 80,
        sortable: false,
      },
      {
        key: 'created_by',
        name: __('storeys.field.created-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
        sortable: false,
      },
    ];

    const singleActions = (storey_id) => {
      return [
        <Tooltip
          text={__('storeys.tooltip.storey-properties')}
          key={_.uniqueId()}
        >
          <InfoPositiveIcon
            className=''
            onClick={(e) => {
              e.stopPropagation();

              setTableParams('storeys', {
                clicked_row: storey_id,
                actioned_row: undefined,
              });
            }}
          />
        </Tooltip>,
        <Tooltip text={__('storeys.tooltip.delete-storey')} key={_.uniqueId()}>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();

              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description: __('storeys.alert.delete-confirmation'),
                buttons: [
                  {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => {
                      deleteStoreys([storey_id]).then(() => {
                        hideAlertbox();

                        setNotification(__('storeys.alert.deleted'));
                        listStoreys(this.props.match.params.stage);

                        setTableParams('storeys', {
                          actioned_row: undefined,
                        });
                      });
                    },
                  },
                ],
              });
            }}
          />
        </Tooltip>,
      ];
    };

    const groupActions = (storeys_ids) => [
      <Tooltip
        text={__('storeys.tooltip.delete-selected-storeys')}
        key={_.uniqueId()}
      >
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation();

            showAlertbox({
              color: 'red',
              title: __('general.alert.are-you-sure'),
              description: __('storeys.alert.multiple-delete-confirmation'),
              buttons: [
                {
                  color: 'lightGray',
                  text: __('general.alert.no-close'),
                  onClick: 'close',
                },
                {
                  color: 'gray',
                  text: __('general.alert.yes-delete'),
                  onClick: () => {
                    deleteStoreys(storeys_ids).then(() => {
                      hideAlertbox();

                      setNotification(__('storeys.alert.multiple-deleted'));
                      listStoreys(this.props.match.params.stage);

                      setTableParams('storeys', {
                        selected_rows: [],
                      });
                    });
                  },
                },
              ],
            });
          }}
        />
      </Tooltip>,
    ];

    const preview = (
      <StoreysFlyout
        data={this.state.preview}
        readAction={(storey_id) => {
          return new Promise((resolve) => {
            readStorey(getURLParam('id') || storey_id).then(({ response }) => {
              this.setState({ preview: response.data });

              resolve(response);
            });
          });
        }}
      />
    );

    return (
      <Table
        listAction={() => listStoreys(this.props.match.params.stage)}
        name='storeys'
        title={__('submenu.title.space-allocation.storeys')}
        singleActions={singleActions}
        groupActions={groupActions}
        columns={columns}
        preview={this.state.preview ? preview : []}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default Storeys;
