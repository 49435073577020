import io from 'socket.io-client';
import Echo from 'laravel-echo';
import { frontendURL, getToken, play, socketURL } from './utils';
import Notify from 'notifyjs';
import NotificationSound from '../assets/sounds/notification.mp3';
import { setNotificationCount } from '../redux/actions/auth';
import store from './store';
import { listAllNotifications } from '../redux/actions/table/notifications';

export const initSocketIO = () => {
  window.io = io;

  window.Echo = new Echo({
    broadcaster: 'socket.io',
    auth: {
      headers: {
        Token: getToken(),
      },
    },
    host: socketURL,
  });
};

export const leaveChannel = (channel) => {
  window.Echo.leave(channel);
};

export const listenNotificationsUpdated = () => {
  window.Echo.private('notifications.' + store.getState().auth.id).listen(
    'NotificationsUpdated',
    ({ unread_notifications, alert, notification_id }) => {
      setNotificationCount(unread_notifications);

      if (!_.get(store.getState(), 'table.notifications.clicked_row')) {
        // listAllNotifications();
      }

      if (alert && notification_id) {
        const notification = new Notify('New notification', {
          body: 'You have a new notification !',
          notifyShow: () => play(NotificationSound),
          notifyClick: (e) => {
            e.preventDefault();
            window.open(
              frontendURL + '/profile/notifications?id=' + notification_id,
              '_blank'
            );
          },
        });

        if (!Notify.needsPermission) {
          notification.show();
        } else if (Notify.isSupported()) {
          Notify.requestPermission(
            (result) => result === 'granted' && notification.show()
          );
        }
      }
    }
  );
};

export const listenPlanProcessingStatusChanged = (stage_id, callback) => {
  setTimeout(() => {
    window.Echo.private('plan_uploads.' + stage_id).listen(
      'PlanProcessingStatusChanged',
      ({ plan_id, processed }) => {
        callback(plan_id, processed);
      }
    );
  }, 1000); //TODO - TEMP FIX - MAKE INTERVAL CHECKING WINDOW.ECHO, UNTIL CONNECTED
};

export const listenPlanProcessed = (stage_id, callback) => {
  setTimeout(() => {
    window.Echo.private('working_set_plans.' + stage_id).listen(
      'PlanProcessed',
      ({ plan_id }) => {
        callback(plan_id);
      }
    );
  }, 1000); //TODO - TEMP FIX - MAKE INTERVAL CHECKING WINDOW.ECHO, UNTIL CONNECTED
};
