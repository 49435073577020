import store from "../../../../core/store";

export const listMaterials = () => {
	return store.dispatch({
		action: "table.superadmin_materials/LIST_MATERIALS",
		method: "GET",
		endpoint: "/superadmin/materials",
		main: true
	});
};

export const createMaterial = (formData) => {
	return store.dispatch({
		action: "table.superadmin_materials/CREATE_MATERIAL",
		method: "POST",
		endpoint: "/superadmin/materials",
		data: formData
	});
};

export const deleteMaterials = (material_ids) => {
	return store.dispatch({
		action: "table.superadmin_materials/DELETE_MATERIALS",
		method: "DELETE",
		endpoint: "/superadmin/materials",
		data: {
			materials: material_ids
		}
	});
};

export const readMaterial = (material_id) => {
	return store.dispatch({
		action: "table.superadmin_materials/READ_MATERIAL",
		method: "GET",
		endpoint: "/superadmin/materials/" + material_id,
		has404: true
	});
};

export const updateMaterial = (material_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_materials/UPDATE_MATERIAL",
		method: "POST", //must be POST instead of PUT because of 'HTTP 1.1 RFC' => reference https://stackoverflow.com/a/107450
		endpoint: "/superadmin/materials/" + material_id,
		data: formData
	});
};