import store from "../../../core/store";

export const listEntrances = (stage_id) => {
    return store.dispatch({
        action: "table.entrances/LIST_ENTRANCES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/entrances",
        main: true,
    });
};

export const createEntrance = (stage_id, formData) => {
    return store.dispatch({
        action: "table.entrances/CREATE_ENTRANCE",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/entrances",
        data: formData
    });
};

export const readEntrance = (entrance_id) => {
    return store.dispatch({
        action: "table.entrances/READ_ENTRANCE",
        method: "GET",
        endpoint: "/entrances/" + entrance_id,
        has404: true
    });
};

export const updateEntrance = (entrance_id, formData) => {
    return store.dispatch({
        action: "table.entrances/UPDATE_ENTRANCE",
        method: "PUT",
        endpoint: "/entrances/" + entrance_id,
        data: formData
    });
};

export const deleteEntrances = (entrance_ids) => {
    return store.dispatch({
        action: "table.entrances/DELETE_ENTRANCES",
        method: "DELETE",
        endpoint: "/entrances",
        data: {
            entrances: entrance_ids
        }
    });
};