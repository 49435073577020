import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/CreateDeliveryWizard.scss";
import autobind from "autobind-decorator";
import {__, validateForm} from "../core/utils";
import {connect} from "react-redux";
import {setNotification} from "../redux/actions/general/notification";
import AddDeliveryIcon from "../assets/images/add-delivery-60x60.svg";
import * as _ from "lodash";
import CreateDeliveryForm from "../forms/create_delivery_wizard/CreateDeliveryForm";
import {addToExistingDelivery, createDelivery, listWorkingSetPlans} from "../redux/actions/table/working_set_plans";
import {withRouter} from "react-router";
import {SubmissionError} from "redux-form";

const mapStateToProps = state => {
    return {
        table: state.table["working_set_plans"],
    };
};

@connect(mapStateToProps)
@withRouter
class CreateDeliveryWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const team_id = _.find(this.props.table.data, {id: _.first(this.props.table.selected_plans)}).team.id;

        formData = {
            ...formData,
            approvers: _.map(formData.approvers, 'id'),
        }

        if(!formData.new_delivery && !formData.delivery_id) {
            throw new SubmissionError({ delivery_id: 'Field required' });
        } else {
            return validateForm(
                formData.new_delivery ? createDelivery(team_id, {
                    plans: this.props.table.selected_plans,
                    title: _.get(formData, 'title'),
                    sent: _.get(formData, 'sent') ? 1 : 0
                }).then(() => {
                    listWorkingSetPlans(this.props.match.params.stage);

                    if(_.get(formData, 'sent')) {
                        setNotification(__("wizard.delivery.notification.success1"));
                    } else {
                        setNotification(__("wizard.delivery.notification.success2"));
                    }

                    this.props.onClose();
                }) : addToExistingDelivery(_.get(formData, 'delivery_id'), {
                    plans: this.props.table.selected_plans,
                    sent: _.get(formData, 'sent') ? 1 : 0
                }).then(() => {
                    listWorkingSetPlans(this.props.match.params.stage);

                    if(_.get(formData, 'sent')) {
                        setNotification(__("wizard.delivery.notification.success1"));
                    } else {
                        setNotification(__("wizard.delivery.notification.success2"));
                    }

                    this.props.onClose();
                })
            );
        }
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.delivery.title.create-delivery')}</span>
                        <span>{__('wizard.delivery.subtitle.create-delivery')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddDeliveryIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <CreateDeliveryForm onSubmit={this._handleSubmit} initialValues={{
                        new_delivery: true
                    }}/>
                </div>
            </>
        );
    }
}

export default CreateDeliveryWizard;
