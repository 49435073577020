import * as React from 'react';
import { Component } from 'react';
import MainLayout from '../../layouts/MainLayout';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { createSidebar } from '../../redux/actions/general/sidebar';
import {
  enableProjectsDropdown,
  enableStagesDropdown,
  listActiveModules,
} from '../../redux/actions/general/active_modules';
import {
  __,
  dateTimeFrom,
  getActiveStage,
  guessPaperSize,
  ptToMm,
  redirect,
} from '../../core/utils';
import Table from '../../components/Table';
import styles from '../../styles/views/plans/PlanApprovals.scss';
import ThumbUpIcon from '../../assets/images/thumb-up-15x15.svg';
import ThumbDownIcon from '../../assets/images/thumb-down-15x15.svg';
import { listPlanApprovals } from '../../redux/actions/table/plan_approvals';
import Tooltip from '../../components/Tooltip';
import { setNotification } from '../../redux/actions/general/notification';
import TableNA from '../../components/TableNA';
import Avatar from '../../components/Avatar';

const mapStateToProps = (state) => {
  return {
    table: state.table['plan_approvals'],
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.plan_approvals/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class PlanApprovals extends Component {
  componentDidMount() {
    enableProjectsDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/approvals' +
          (this.props.match.params.status
            ? '/' + this.props.match.params.status
            : '')
      )
    );
    enableStagesDropdown(({ stage_id }) =>
      redirect(
        '/stages/' +
          stage_id +
          '/approvals' +
          (this.props.match.params.status
            ? '/' + this.props.match.params.status
            : '')
      )
    );

    createSidebar({
      title: __('submenu.title.plans'),
      items: [
        {
          title: __('submenu.title.current-set'),
          link: () => '/stages/' + getActiveStage() + '/plans/current',
        },
        {
          title: __('submenu.title.working-set'),
          link: () => '/stages/' + getActiveStage() + '/plans/working',
        },
        {
          title: __('submenu.title.uploads'),
          link: () => '/stages/' + getActiveStage() + '/plans/uploads',
        },
        {
          title: __('submenu.title.deliveries'),
          link: () => '/stages/' + getActiveStage() + '/deliveries',
        },
        {
          title: __('submenu.title.approvals'),
          link: () => '/stages/' + getActiveStage() + '/approvals',
          active: true,
          items: [
            {
              title: __('submenu.title.approvals.pending'),
              link: () => '/stages/' + getActiveStage() + '/approvals/pending',
              active: this.props.match.params.status == 'pending',
            },
            {
              title: __('submenu.title.approvals.approved'),
              link: () => '/stages/' + getActiveStage() + '/approvals/approved',
              active: this.props.match.params.status == 'approved',
            },
            {
              title: __('submenu.title.approvals.rejected'),
              link: () => '/stages/' + getActiveStage() + '/approvals/rejected',
              active: this.props.match.params.status == 'rejected',
            },
          ],
        },
      ],
    });
  }

  render() {
    const columns = [
      {
        key: 'code',
        name: __('plans.table.title.code'),
        width: 80,
      },
      {
        key: 'title',
        name: __('plans.table.title.title'),
        required: true,
      },
      {
        key: 'delivery',
        name: __('plans.table.title.delivery'),
      },
      {
        key: 'discipline',
        name: __('plans.deliveries.columns.disciplines'),
        value: (discipline, row) => (
          <Tooltip
            text={[
              row.discipline.code,
              row.discipline.id == null
                ? row.discipline.description + '*'
                : _.get(this.props.localization.disciplines, [
                    row.discipline.id,
                    'name',
                  ]),
            ].join(': ')}
          >
            {row.discipline.code}
          </Tooltip>
        ),
      },
      {
        key: 'team',
        name: __('plans.deliveries.columns.team'),
        value: (team) => team.company,
      },
      {
        key: 'size',
        name: __('plans.table.title.size'),
        value: (size, row) =>
          row.width == 0 && row.height == 0 ? (
            <TableNA />
          ) : (
            <Tooltip
              text={
                guessPaperSize(ptToMm(row.width), ptToMm(row.height))
                  .description
              }
            >
              {guessPaperSize(ptToMm(row.width), ptToMm(row.height)).size}
            </Tooltip>
          ),
        width: 130,
      },
      {
        key: 'scale',
        name: __('plans.table.title.scale'),
        width: 80,
        fullWidth: true,
        value: (scale, row) =>
          _.join([_.toNumber(row.scale1), _.toNumber(row.scale2)], ':'),
      },
      {
        key: 'version',
        name: __('plans.table.title.version'),
        value: (value) => 'V' + value,
        width: 60,
      },
      {
        key: 'created_at',
        name: __('plans.table.title.added'),
        default: true,
        desc: true,
        value: (value) => dateTimeFrom(value),
        width: 105,
      },
      {
        key: 'created_by',
        name: __('plans.table.title.added-by'),
        value: (user) => (
          <Avatar
            avatar_url={user.avatar_url}
            name={user.fullname}
            active={user.active}
          />
        ),
        width: 50,
      },
      {
        key: 'status',
        name: __('Status'),
        value: (status) => (
          <>
            {status === 0 && (
              <div className={styles.pending}>
                {__('submenu.title.approvals.pending')}
              </div>
            )}
            {status === 1 && (
              <div className={styles.approved}>
                {__('submenu.title.approvals.approved')}
              </div>
            )}
            {status === 2 && (
              <div className={styles.rejected}>
                {__('submenu.title.approvals.rejected')}
              </div>
            )}
          </>
        ),
        width: 120,
      },
    ];

    const singleActions = (plan_version_id) => {
      const pending =
        _.get(
          _.find(this.props.table.data, { id: plan_version_id }),
          'status'
        ) == '0';

      return pending
        ? [
            <Tooltip
              text={__('plans.tooltip.icon.approvals.approve')}
              key={_.uniqueId()}
            >
              <ThumbUpIcon
                onClick={(e) => {
                  e.stopPropagation();

                  this.props.actions.setTableParams({
                    actioned_row: undefined,
                    wizard_active: true,
                    approve: true,
                    selected_plan_versions: [plan_version_id],
                  });
                }}
              />
            </Tooltip>,
            <Tooltip
              text={__('plans.tooltip.icon.approvals.reject')}
              key={_.uniqueId()}
            >
              <ThumbDownIcon
                onClick={(e) => {
                  e.stopPropagation();

                  this.props.actions.setTableParams({
                    actioned_row: undefined,
                    wizard_active: true,
                    approve: false,
                    selected_plan_versions: [plan_version_id],
                  });
                }}
              />
            </Tooltip>,
          ]
        : undefined;
    };

    const groupActions = (plan_version_ids) => [
      <Tooltip
        text={__('plans.tooltip.icon.approvals.approve-group')}
        key={_.uniqueId()}
      >
        <ThumbUpIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (plan_version) =>
                    _.includes(plan_version_ids, plan_version.id) &&
                    plan_version.status != 0
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  'You may not perform this action as one or more plan versions have been approved/rejected.'
                ),
                type: 'warning',
              });
            } else {
              this.props.actions.setTableParams({
                selected_rows: [],
                wizard_active: true,
                approve: true,
                selected_plan_versions: plan_version_ids,
              });
            }
          }}
        />
      </Tooltip>,
      <Tooltip
        text={__('plans.tooltip.icon.approvals.reject-group')}
        key={_.uniqueId()}
      >
        <ThumbDownIcon
          onClick={(e) => {
            e.stopPropagation();

            if (
              _.size(
                _.filter(
                  this.props.table.data,
                  (plan_version) =>
                    _.includes(plan_version_ids, plan_version.id) &&
                    plan_version.status != 0
                )
              ) >= 1
            ) {
              setNotification({
                text: __(
                  'You may not perform this action as one or more plan versions have been approved/rejected.'
                ),
                type: 'warning',
              });
            } else {
              this.props.actions.setTableParams({
                selected_rows: [],
                wizard_active: true,
                approve: false,
                selected_plan_versions: plan_version_ids,
              });
            }
          }}
        />
      </Tooltip>,
    ];

    const gridOptions = {
      section: 'Plans',
      image: (id, row) => row.thumbnail_url,
      title: (row) => row.code,
      rightTitle: (row) => (
        <>
          {row.status === 0 && <div className={styles.circleP}>P</div>}
          {row.status === 1 && <div className={styles.circleA}>A</div>}
          {row.status === 2 && <div className={styles.circleR}>R</div>}
        </>
      ),
      subTitle: (row) => row.title,
      content: (row) => [
        <>
          {dateTimeFrom(row.created_at)} by {row.created_by.fullname}
        </>,
        (row.width == 0 && row.height == 0
          ? ''
          : guessPaperSize(ptToMm(row.width), ptToMm(row.height)).size +
            ' (' +
            guessPaperSize(ptToMm(row.width), ptToMm(row.height)).description +
            ') | ') +
          _.join([_.toNumber(row.scale1), _.toNumber(row.scale2)], ':'),
      ],
    };

    return (
      <Table
        onRowClick={(id, row) =>
          redirect(
            '/plans/versions/' +
              row.id +
              '?approval=' +
              _.get(this.props.match.params, 'status', '')
          )
        }
        listAction={() =>
          listPlanApprovals(
            this.props.match.params.stage,
            this.props.match.params.status
          )
        }
        name='plan_approvals'
        title={__('submenu.title.approvals')}
        columns={columns}
        singleActions={
          (!this.props.match.params.status ||
            this.props.match.params.status == 'pending') &&
          singleActions
        }
        groupActions={
          (!this.props.match.params.status ||
            this.props.match.params.status == 'pending') &&
          groupActions
        }
        gridOptions={gridOptions}
        onLoad={() => listActiveModules()}
      />
    );
  }
}

export default PlanApprovals;
