import store from "../../../core/store";

export const setPlusActions = (actions) => {
    return store.dispatch({
        type: "general.plus/SET_PLUS_ACTIONS",
        actions
    });
};

export const removePlusActions = () => {
    return store.dispatch({
        type: "general.plus/REMOVE_PLUS_ACTIONS",
    });
};