import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import {getLocalized, mapStateToProps} from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import MultiSelect from "../../../components/MultiSelect";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import * as _ from "lodash";

@reduxForm({
    form: "superadmin_filters.edit_filter",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    application_language_id: state.auth.language_id,
}))
class EditFilterForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin, localization, application_language_id } = this.props;

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == 1;

            return languages;
        }, {});

        const product_attribute_fields = _.sortBy(_.map(localization.product_attribute_fields, field => ({
            label: getLocalized(field.label, application_language_id),
            value: field.id,
            tooltip: getLocalized(field.description, application_language_id),
            groupPriority: _.get(localization.product_attribute_groups, [field.product_attribute_template_group_id, 'priority']),
            group: getLocalized(_.get(localization.product_attribute_groups, [field.product_attribute_template_group_id, 'name']), application_language_id)
        })), 'groupPriority');

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_filter} localized={application_languages} name="name" component={Input} label="Name" />
                <Field disabled={!superadmin.edit_filter} searchable name="product_attribute_template_field_ids"  component={MultiSelect} label="Product Attributes" noSort options={product_attribute_fields} />
            </Form>
        );
    }
}

export default EditFilterForm;