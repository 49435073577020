import store from "../../../../core/store";

export const listUsers = () => {
	return store.dispatch({
		action: "table.superadmin_users/LIST_USERS",
		method: "GET",
		endpoint: "/superadmin/users",
		main: true
	});
};

export const manualConfirmUserEmail = (user_id) => {
	return store.dispatch({
		action: "table.superadmin_users/MANUAL_CONFIRM_USER_EMAIL",
		method: "PUT",
		endpoint: "/superadmin/users/" + user_id + "/confirm_email",
	});
}