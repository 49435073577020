import React, { useState } from 'react';
import RC from './RoomChooser';

const generateId = () => Math.random().toString().replace('0.', '');

export const ccc = [
  { id: '1', type: 'site', display_name: `Site 1`, parent_type: null, parent_id: null },
  { id: '1.1', type: 'building', display_name: `Building 1.1`, parent_type: 'site', parent_id: '1' },
  { id: '1.1.1', type: 'storey', display_name: `Storey 1.1.1`, parent_type: 'building', parent_id: '1.1' },
  { id: '1.1.1.1', type: 'room', display_name: `Room 1.1.1.1`, parent_type: 'storey', parent_id: '1.1.1' },
  { id: '1.1.1.2', type: 'room', display_name: `Room 1.1.1.2`, parent_type: 'storey', parent_id: '1.1.1' },
  { id: '1.1.1.3', type: 'room', display_name: `Room 1.1.1.3`, parent_type: 'storey', parent_id: '1.1.1' },
  { id: '1.1.2', type: 'storey', display_name: `Storey 1.1.2`, parent_type: 'building', parent_id: '1.1' },
  { id: '1.1.2.1', type: 'room', display_name: `Room 1.1.2.1`, parent_type: 'storey', parent_id: '1.1.2' },
  { id: '1.1.2.2', type: 'room', display_name: `Room 1.1.2.2`, parent_type: 'storey', parent_id: '1.1.2' },
  { id: '1.1.2.3', type: 'room', display_name: `Room 1.1.2.3`, parent_type: 'storey', parent_id: '1.1.2' },
  { id: '1.2', type: 'building', display_name: `Building 1.2`, parent_type: 'site', parent_id: '1' },
  { id: '1.2.1', type: 'storey', display_name: `Storey 1.2.1`, parent_type: 'building', parent_id: '1.2' },
  { id: '1.2.1.1', type: 'room', display_name: `Room 1.2.1.1`, parent_type: 'storey', parent_id: '1.2.1' },
  { id: '1.2.1.2', type: 'room', display_name: `Room 1.2.1.2`, parent_type: 'storey', parent_id: '1.2.1' },
  { id: '1.2.1.3', type: 'room', display_name: `Room 1.2.1.3`, parent_type: 'storey', parent_id: '1.2.1' },
  { id: '1.2.2', type: 'storey', display_name: `Storey 1.2.2`, parent_type: 'building', parent_id: '1.2' },
  { id: '1.2.2.1', type: 'room', display_name: `Room 1.2.2.1`, parent_type: 'storey', parent_id: '1.2.2' },
  { id: '1.2.2.2', type: 'room', display_name: `Room 1.2.2.2`, parent_type: 'storey', parent_id: '1.2.2' },
  { id: '1.2.2.3', type: 'room', display_name: `Room 1.2.2.3`, parent_type: 'storey', parent_id: '1.2.2' },
  { id: generateId(), type: 'zone', display_name: 'Zone 1', title: 'Zone 1' },
  { id: generateId(), type: 'zone', display_name: 'Zone 2', title: 'Zone 2' },
  { id: generateId(), type: 'zone', display_name: 'Zone 4', title: 'Zone 4' },
  { id: generateId(), type: 'entrance', display_name: 'Entrance 1', title: 'Entrance 1' },
  { id: generateId(), type: 'entrance', display_name: 'Entrance 2', title: 'Entrance 2' },
  { id: generateId(), type: 'entrance', display_name: 'Entrance 4', title: 'Entrance 4' },
  { id: generateId(), type: 'expansion_joint', display_name: 'Expansion Joint 1', title: 'Expansion Joint 1' },
  { id: generateId(), type: 'expansion_joint', display_name: 'Expansion Joint 2', title: 'Expansion Joint 2' },
  { id: generateId(), type: 'expansion_joint', display_name: 'Expansion Joint 4', title: 'Expansion Joint 4' },
  { id: generateId(), type: 'room_type', display_name: 'Expansion Joint 1', title: 'Expansion Joint 1' },
  { id: generateId(), type: 'room_type', display_name: 'Expansion Joint 2', title: 'Expansion Joint 2' },
  { id: generateId(), type: 'room_type', display_name: 'Expansion Joint 4', title: 'Expansion Joint 4' },
]

export const MultiSelectRoomChooser = (props) => {
  const [visible, setVisible] = useState(true);
  return (
    <div style={{
      position: 'relative'
    }}>
      <span onClick={() => setVisible(true)}>
        RC
      </span>
      <div style={{ position: 'absolute', backgroundColor: 'white', padding: 16, minWidth: 400 }}>
        {visible && <RC multiselect options={data} onDone={(values) => console.log(values)} initialValues={[]} {...props} />}
      </div>
    </div>
  )
}

export default RC;