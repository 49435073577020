import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/print_meeting_report_wizard/PrintMeetingReportForm.scss";
import Button from "../../components/Button";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {__, mapStateToProps, resetForm, setTableParams} from "../../core/utils";
import * as _ from "lodash";
import {withRouter} from "react-router";
import Checkbox from "../../components/Checkbox";
import ButtonGroup from "../../components/ButtonGroup";

@reduxForm({
    form: "print_meeting_report_wizard.print_meeting_report",
    destroyOnUnmount: false
})
@subscribe(Localization, "localization")
@withRouter
@mapStateToProps(state => ({
    values: getFormValues("print_meeting_report_wizard.print_meeting_report")(state),
    send: _.get(state.table, 'all_meetings.send')
}))
class PrintMeetingReportForm extends Component {
    render() {
        const {values, send} = this.props;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <div className={styles.title}>{__('tasks.print.title.select-elements')}</div>
                        <div className={styles.options}>
                            <Field name="header" component={Checkbox} label={__('tasks.print.header')} />
                            <Field name="footer" component={Checkbox} label={__('tasks.print.footer')} />
                            <Field name="legend" component={Checkbox} label={__('tasks.print.legend')} />
                            <Field name="details" component={Checkbox} label={__('tasks.print.details')} />
                            {values.details == true && <div className={styles.suboptions}>
                                <Field name="description" component={Checkbox} label={__('tasks.print.description')} />
                                <Field name="attachments" component={Checkbox} label={__('meetings.tasks.print.attachments')} />
                                <Field name="qr_code" component={Checkbox} label={__('meetings.tasks.print.task-qr-code')} />
                                <Field name="comments" component={Checkbox} label={__('tasks.print.comments')} />
                                <Field name="footnote" component={Checkbox} label={__('meetings.tasks.print.footnote')} />
                            </div>}
                        </div>
                    </div>
                    <div className={styles.f1}>
                        <div className={styles.title}>{__('tasks.print.title.select-columns')}</div>
                        <div className={styles.options}>
                            <Field name="column_type" component={Checkbox} label={__('meetings.tasks.print.task-type')} />
                            <Field name="column_id" component={Checkbox} label={__('meetings.tasks.print.task-id')} />
                            <Field name="column_priority" component={Checkbox} label={__('meetings.tasks.print.task-priority')} />
                            <Field name="column_status" component={Checkbox} label={__('meetings.tasks.print.task-status')} />
                            <Field name="column_due_date" component={Checkbox} label={__('meetings.tasks.print.task-due-date')} />
                            <Field name="column_assignee" component={Checkbox} label={__('meetings.tasks.print.task-assignee')} />
                            <Field name="column_startend" component={Checkbox} label={__('meetings.tasks.print.task-start-end')} />
                            <Field name="column_duration" component={Checkbox} label={__('meetings.tasks.print.task-duration')} />
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('tasks.print.button.cancel')} onClick={() => {
                        setTableParams('all_meetings', {
                            print_meeting_report_wizard: undefined
                        });
                        this.props.reset();
                        resetForm('send_meeting_report_wizard');
                    }} />
                    <ButtonGroup>
                        {/*<Button lightBlue medium middleText={__('Preview')} spinning={this.props.submitting} />*/}
                        <Button lightBlue medium submit middleText={send ? __('button.save') : __('button.generate')} spinning={this.props.submitting} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default PrintMeetingReportForm;
