import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_ocr_template_wizard/AddOCRTemplateForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import {__, mapStateToProps, setTableParams} from "../../core/utils";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import ReactDropzone from "react-dropzone";
import classNames from "classnames";
import * as _ from "lodash";
import FileUploadIcon from "../../assets/images/file-upload-32x32.svg";
import FolderOpenedIcon from "../../assets/images/folder-opened-16x16.svg";
import autobind from "autobind-decorator";
import {setNotification} from "../../redux/actions/general/notification";
import DocumentExtIcon from "../../assets/images/document-ext-30x30.svg";
import CloseBigIcon from "../../assets/images/close-big-15x15.svg";
import Select from "../../components/Select";
import {getStagesLanguages} from "../../redux/actions/table/stages";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {withRouter} from "react-router";

@withRouter
@reduxForm({form: "add_ocr_template_wizard.add_ocr_template"})
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    values: getFormValues("add_ocr_template_wizard.add_ocr_template")(state) || {},
    errors: _.get(state.form, "add_ocr_template_wizard.add_ocr_template.submitErrors", {})
}))
class AddOCRTemplateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drag: false,
            project_languages: []
        }
    }

    @autobind
    _handleDrop(acceptedFiles, rejectedFiles) {
        this.setState({ drag: false });

        if(!_.isEmpty(acceptedFiles)) {
            if(_.size(rejectedFiles) > 1) {
                setNotification({
                    text: __("ocr-templates.upload.bulk-not-allowed"),
                    type: "warning"
                });
            } else if(!_.isEmpty(rejectedFiles)) {
                setNotification({
                    text: __("ocr-templates.upload.incorrect.format"),
                    type: "warning"
                });
            } else if(_.first(acceptedFiles).size >= 104857600) {
                setNotification({
                    text: __("ocr-templates.upload.upload-limit"),
                    type: "warning"
                });
            } else {
                this.props.change('file', _.first(acceptedFiles));
            }
        }
    }

    componentDidMount() {
        getStagesLanguages(this.props.match.params.stage).then(({response}) => {
            this.setState({project_languages: response.data});
        });
    }

    render() {
        const {project_languages} = this.state;

        const languages = _.map(project_languages, (primary, language_id) => ({
            label: _.get(this.props.localization.languages, [language_id, 'name']),
            value: language_id,
        }));

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" component={Input} label={__('ocr-templates.field.name')} maxLength={50} />
                    <Field name="language_id" component={Select} noSort label={__('ocr-templates.field.language')} searchable options={languages} />
                    {!this.props.values.file ? (
                        <ReactDropzone
                            accept="application/pdf"
                            className={classNames(styles.dropzone, _.get(this.state, "drag") && styles.drag, _.has(this.props.errors, 'file') && styles.error)}
                            onDrop={this._handleDrop}
                            onDragOver={() => this.setState({ drag: true })}
                            onDragLeave={() => this.setState({ drag: false })}
                            multiple={false}
                        >
                            <FileUploadIcon />
                            <span>{__('ocr-templates.wizard.drop-your-pdf-file-here')}</span>
                            <div className={styles.actions}>
                                <div>
                                    <FolderOpenedIcon />
                                </div>
                            </div>
                        </ReactDropzone>
                    ) : (
                        <div className={classNames(styles.dropzone, styles.deleteFile)}>
                            <div className={styles.icon}>
                                <DocumentExtIcon />
                                <div>PDF</div>
                            </div>
                            <div className={styles.filename}>{_.get(this.props.values, 'file.name')}</div>
                            <CloseBigIcon className={styles.delete} onClick={() => this.props.change('file', null)} />
                        </div>
                    )}
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('ocr_templates', {
                        add_ocr_template_wizard: undefined
                    })} />
                    <Button gray medium submit right leftText={__('button.create')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default AddOCRTemplateForm;
