import * as React from 'react';
import styles from '../styles/wizards/AddBOQItemsAsTemplateWizard.scss';
import { __, getActiveStage, validateForm } from '../core/utils';
import BOQIcon from '../assets/images/boq-24x24.svg';
import { withRouter } from 'react-router';
import { setNotification } from '../redux/actions/general/notification';
import { connect } from 'react-redux';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import AddBOQItemsAsTemplateForm from '../forms/add_boq_items_as_template_wizard/AddBOQItemsAsTemplateForm';
import { addBOQTemplate } from '../redux/actions/table/working_set_boq';

const AddBOQItemsAsTemplateWizard = ({ onClose, selected_boq_rows }) => {
  const _handleSubmit = (formData) => {
    return validateForm(
      addBOQTemplate(selected_boq_rows, getActiveStage(), formData).then(() => {
        onClose();

        setTimeout(
          () =>
            setNotification(
              __('boq.notification.selected-rows-have-been-added-as-template')
            ),
          25
        );
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>{__('wizard.save-boq-items-as-template.title')}</span>
          <span>{__('wizard.save-boq-items-as-template.subtitle')}</span>
        </div>
        <div className={styles.right}>
          <BOQIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AddBOQItemsAsTemplateForm onSubmit={_handleSubmit} onClose={onClose} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected_boq_rows: _.get(state.table, 'working_set_boq.selected_boq_rows'),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    subscribe(Localization, 'localization')(AddBOQItemsAsTemplateWizard)
  )
);
