import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/EditProductAttributeGroupFieldValuesWizard.scss';
import VariateIcon from '../assets/images/variate-64x64.svg';
import {
  __,
  mapStateToProps,
  resetForm,
  setTableParams,
  validateForm,
} from '../core/utils';
import EditProductAttributeGroupFieldValuesForm from '../forms/edit_product_attribute_group_field_values_form/EditProductAttributeGroupFieldValuesForm';
import _ from 'lodash';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import {
  addProductAttributesGroupFieldParts,
  readProduct,
  updateProductAttributesGroupFieldValues,
  useAsThumbnailProductAttributeGroupField,
} from '../redux/actions/table/products';
import { setNotification } from '../redux/actions/general/notification';
import EditProductAttributeGroupFieldPartsForm from '../forms/edit_product_attribute_group_field_parts_form/EditProductAttributeGroupFieldPartsForm';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';

@mapStateToProps((state) => ({
  flyout: _.get(state, 'table.products.flyout'),
  attribute_field_id:
    state.table.products.edit_product_attribute_group_field_values_wizard,
}))
@subscribe(Localization, 'localization')
class EditProductAttributeGroupFieldValuesWizard extends Component {
  state = {
    active_tab: 'custom',
  };

  @autobind
  _handleUpdateCustomValues(formData) {
    const { flyout, attribute_field_id } = this.props;

    const values = {};

    _.each(
      _.find(_.flatten(_.map(flyout.attribute_groups, 'attributes')), [
        'id',
        attribute_field_id,
      ]).values,
      ({ id, value }) => {
        values[`value-${id}`] = formData[`value-${id}`];
      }
    );

    const fetchProduct = () =>
      readProduct(flyout.id)
        .then(({ response }) => {
          setNotification(
            'Product attribute variations have been successfully saved.'
          );

          setTableParams('products', {
            flyout: response.data,
            edit_product_attribute_group_field_values_wizard: undefined,
          });
        })
        .catch(() =>
          setTableParams('products', {
            edit_product_attribute_group_field_values_wizard: undefined,
          })
        );

    return validateForm(
      updateProductAttributesGroupFieldValues(attribute_field_id, {
        ...values,
      }).then(() => {
        if (!_.isUndefined(formData.use_as_thumbnail)) {
          useAsThumbnailProductAttributeGroupField(attribute_field_id, {
            use_as_thumbnail: formData.use_as_thumbnail ? 1 : 0,
          }).then(() => fetchProduct());
        } else {
          fetchProduct();
        }
      })
      // .catch(({ response, status }) => {
      //   if (status === 422 && 'errors' in response) {
      //     setNotification({
      //       text: 'Product attribute variations have not been saved! Please revise the marked fields.',
      //       type: 'warning',
      //     });
      //   }
      // })
    );
  }

  @autobind
  _handleAddPart(formData) {
    const { flyout, attribute_field_id } = this.props;

    return validateForm(
      addProductAttributesGroupFieldParts(attribute_field_id, formData).then(
        () => {
          setNotification(
            'Product attribute variations have been successfully saved.'
          );

          resetForm(
            'edit_product_attribute_group_field_parts_form.edit_product_attribute_group_field_parts'
          );

          readProduct(flyout.id).then(({ response }) => {
            setTableParams('products', {
              flyout: response.data,
            });
          });
        }
      )
    );
  }

  render() {
    const { flyout, attribute_field_id } = this.props;
    const { active_tab } = this.state;

    const attribute = _.find(
      _.flatten(_.map(flyout.attribute_groups, 'attributes')),
      ['id', attribute_field_id]
    );

    const initialValues = {};

    _.each(attribute.values, ({ id, value }) => {
      initialValues[`value-${id}`] = value;
    });

    const { product_attribute_fields } = this.props.localization;

    const currentAttribute = attribute.template_field_id
      ? _.get(product_attribute_fields, attribute.template_field_id, {})
      : attribute;

    const type = currentAttribute.type;

    if (
      _.includes(['color', 'material', 'texture'], type) &&
      active_tab === 'custom'
    )
      initialValues[`use_as_thumbnail`] = attribute.use_as_thumbnail;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.titleBar}>
            <div className={styles.left}>
              <span>Edit Product Attribute Variations</span>
              <span>Edit Product Attribute Variations</span>
            </div>
            <div className={styles.right}>
              <VariateIcon />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.chooser}>
            <div
              className={classNames(
                styles.option,
                active_tab === 'custom' && styles.selected
              )}
              onClick={() => this.setState({ active_tab: 'custom' })}
            >
              <span />
              Custom
            </div>
            <div
              className={classNames(
                styles.option,
                active_tab === 'parts' && styles.selected
              )}
              onClick={() => this.setState({ active_tab: 'parts' })}
            >
              <span />
              From parts
            </div>
          </div>
          <div className={styles.separator} />
          <div className={styles.choices}>
            {active_tab === 'custom' && (
              <EditProductAttributeGroupFieldValuesForm
                attribute={attribute}
                type={type}
                initialValues={initialValues}
                onSubmit={this._handleUpdateCustomValues}
              />
            )}
            {active_tab === 'parts' && (
              <EditProductAttributeGroupFieldPartsForm
                attribute={attribute}
                type={type}
                onSubmit={this._handleAddPart}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default EditProductAttributeGroupFieldValuesWizard;
