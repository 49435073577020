import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/superadmin/products/Parts.scss";
import * as _ from "lodash";
import classNames from "classnames";
import {__, getLocalized, redirect, mapStateToProps, setTableParams} from "../../../core/utils";
import Tooltip from "../../../components/Tooltip";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import CloseMiddleIcon from "../../../assets/images/close-middle-15x15.svg";
import autobind from "autobind-decorator";
import {hideAlertbox, showAlertbox} from "../../../redux/actions/general/alertbox";
import {setNotification} from "../../../redux/actions/general/notification";
import {
    deleteProductPart,
    readProduct
} from "../../../redux/actions/table/products";
import TableNA from "../../../components/TableNA";
@mapStateToProps(state => ({
    store: state.table.superadmin_products,
    application_language_id: state.auth.language_id,
    auth: state.auth,
}))
@subscribe(Localization, "localization")
class Parts extends Component {
    @autobind
    _deletePart(e, part_id) {
        e.stopPropagation();

        showAlertbox({
            color: "red",
            title: __('general.alert.are-you-sure'),
            description: __('products.parts.are-you-sure-delete-part'),
            buttons: [
                {
                    color: 'lightGray',
                    text: __('general.alert.no-close'),
                    onClick: 'close'
                },
                {
                    color: 'gray',
                    text: __('general.alert.yes-delete'),
                    onClick: () => deleteProductPart(this.props.store.flyout.id, {
                        part_id
                    }).then(() => readProduct(this.props.store.flyout.id).then(({response}) => {
                        hideAlertbox();

                        setNotification(__('products.parts.part-deleted'));

                        setTableParams('superadmin_products', {
                            flyout: response.data
                        })
                    }))
                }
            ]
        })
    }

    render() {
        const {collapsed, store, auth} = this.props;
        const {flyout} = store;

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                {_.isEmpty(flyout.parts) ? <div className={styles.empty}>{__('products.parts.no-parts')}</div> : (
                    <>
                        <div className={styles.header}>
                            <span className={styles.description}>{__('products.parts.description')}</span>
                        </div>
                        {_.map(flyout.parts, (part, i) => {
                            return (
                                <div className={styles.row} key={i}>
                                    <Tooltip placement="left" text={getLocalized(part.description, this.props.application_language_id)}>
                                        <div className={classNames(styles.description, auth.company.id == flyout.company_id && styles.clickable)} onClick={() => auth.company.id == flyout.company_id && readProduct(part.id).then(({response}) => {
                                            setTableParams('superadmin_products', {
                                                flyout: response.data
                                            });
                                        })}>
                                            {_.filter([part.model, getLocalized(part.description, this.props.application_language_id)], _.identity).join(' / ') || <TableNA />}
                                        </div>
                                    </Tooltip>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        );
    }
}

export default Parts;