import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import styles from '../../../styles/forms/superadmin/products/AttributesForm.scss';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import * as _ from 'lodash';
import Select from '../../../components/Select';
import { __, getLocalized, mapStateToProps } from '../../../core/utils';
import classNames from 'classnames';
import autobind from 'autobind-decorator';
import Tooltip from '../../../components/Tooltip';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';
import {
  listMyCompanyColors,
  listMyCompanyMaterials,
} from '../../../redux/actions/companies';
import TaskVisibilityIcon from '../../../assets/images/task-visibility-15x15.svg';
import ArrowDownSmallIcon from '../../../assets/images/arrow-down-small-15x15.svg';
import TableNA from '../../../components/TableNA';

@reduxForm({
  form: 'superadmin_products.attributes',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.superadmin_products,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
class AttributesForm extends Component {
  state = {
    collapsed_groups: [],
    my_company_colors: [],
    my_company_materials: [],
  };

  componentDidMount() {
    listMyCompanyColors().then(({ response }) => {
      this.setState({ my_company_colors: response.data });
    });

    listMyCompanyMaterials().then(({ response }) => {
      this.setState({ my_company_materials: response.data });
    });
  }

  @autobind
  _toggleCollapseGroup(group) {
    this.setState({
      collapsed_groups: _.xor(this.state.collapsed_groups, [group]),
    });
  }

  _getColorNameAndHex = (id) => {
    const color =
      _.get(this.props.localization.colors, [id]) ||
      _.find(this.state.my_company_colors, (color) => color.id == id);

    return {
      label:
        (color?.name &&
          getLocalized(color?.name, this.props.store.language_id)) +
        (color?.code ? ` · ${color.code}` : ''),
      hex: color?.hex,
    };
  };

  _getMaterialNameAndThumbnailUrl = (id) => {
    const material =
      _.get(this.props.localization.materials, [id]) ||
      _.find(this.state.my_company_materials, (material) => material.id == id);

    return {
      label:
        material?.name &&
        getLocalized(material?.name, this.props.store.language_id),
      thumbnailUrl: material?.thumbnailUrl,
    };
  };

  _getTextureNameAndThumbnailUrl = (id) => {
    const texture = _.get(this.props.localization.textures, id) || {};

    return {
      label: [
        texture.code,
        getLocalized(texture.name, this.props.store.language_id),
      ].join(' '),
      thumbnailUrl: texture?.thumbnailUrl,
    };
  };

  render() {
    const { collapsed, store, application_language_id, handleSubmit } =
      this.props;
    const { flyout, language_id } = store;
    const { collapsed_groups } = this.state;

    const { units, product_attribute_fields } = this.props.localization;

    const attribute_groups = _.sortBy(flyout.attribute_groups, 'priority');

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.map(attribute_groups, (group) => {
          const attributes = _.reject(
            group.attributes,
            (attribute) =>
              _.size(
                _.reject(
                  attribute.values,
                  (value) => _.size(value.value) === 0 || !value.in_use
                )
              ) === 0
          );

          return _.isEmpty(attributes) ? (
            ''
          ) : (
            <div className={styles.group} key={group.id}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseGroup(group.id)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_groups, group.id) && styles.collapsed
                  )}
                />
                {getLocalized(group.name, application_language_id)}
              </div>
              <div
                className={classNames(
                  styles.attributes,
                  !_.includes(collapsed_groups, group.id) && styles.expanded
                )}
              >
                {_.map(attributes, (attribute, i) => {
                  let label = '',
                    type,
                    labelTooltip;

                  if (attribute.template_field_id) {
                    const original_attribute = _.get(
                      product_attribute_fields,
                      attribute.template_field_id,
                      {}
                    );

                    type = original_attribute.type;

                    if (original_attribute.description) {
                      labelTooltip = getLocalized(
                        original_attribute.description,
                        application_language_id
                      );
                    }

                    label += getLocalized(
                      original_attribute.label,
                      application_language_id
                    );
                  } else {
                    label += getLocalized(
                      attribute.label,
                      application_language_id
                    );

                    type = attribute.type;

                    if (attribute.description) {
                      labelTooltip = getLocalized(
                        attribute.description,
                        application_language_id
                      );
                    }
                  }

                  let custom_variations = _.map(
                    _.reject(
                      attribute.values,
                      (value) => _.size(value.value) === 0 || !value.in_use
                    ),
                    (value) => ({
                      id: value.id,
                      value: value.value,
                      unit_id: attribute.unit_id,
                    })
                  );

                  const part_variations = _.transform(
                    attribute.parts,
                    (variations, part) => {
                      _.each(
                        part.values,
                        (value) =>
                          (_.size(value.value) > 0) & value.in_use &&
                          value.inherit_in_use &&
                          variations.push({
                            id: value.id,
                            value: value.value,
                            unit_id: part.unit_id,
                          })
                      );
                    },
                    []
                  );

                  const values = [...custom_variations, ...part_variations];

                  const options = _.map(values, ({ id, value, unit_id }) => {
                    const option = {
                      value: id,
                    };

                    let unit = '';

                    if (
                      _.includes(
                        [
                          'numeric',
                          'dropdown_numeric',
                          'dropdown_numeric_multiple',
                          'range',
                          'range_selectable',
                        ],
                        type
                      ) &&
                      unit_id
                    ) {
                      unit = ' ' + _.get(units, [unit_id, 'symbol']);
                    }

                    switch (type) {
                      case 'boolean':
                        option.label = _.toInteger(value)
                          ? __('products.attributes-form.boolean.yes')
                          : __('products.attributes-form.boolean.no');
                        break;
                      case 'dropdown_string_multiple':
                        option.label = _.map(JSON.parse(value), (one_value) => {
                          return getLocalized(
                            _.get(
                              _.find(
                                _.flatten(
                                  _.map(
                                    this.props.localization
                                      .product_attribute_fields,
                                    'options'
                                  )
                                ),
                                ['id', _.parseInt(one_value)]
                              ),
                              'value'
                            ),
                            language_id
                          );
                        }).join(', ');
                        break;
                      case 'dropdown_numeric_multiple':
                        option.label = _.map(JSON.parse(value), (one_value) => {
                          return (
                            getLocalized(
                              _.get(
                                _.find(
                                  _.flatten(
                                    _.map(
                                      this.props.localization
                                        .product_attribute_fields,
                                      'options'
                                    )
                                  ),
                                  ['id', _.parseInt(one_value)]
                                ),
                                'value'
                              ),
                              language_id
                            ) + unit
                          );
                        }).join(', ');
                        break;
                      case 'dropdown_string':
                        option.label = getLocalized(
                          _.get(
                            _.find(
                              _.flatten(
                                _.map(
                                  this.props.localization
                                    .product_attribute_fields,
                                  'options'
                                )
                              ),
                              ['id', _.parseInt(value)]
                            ),
                            'value'
                          ),
                          language_id
                        );
                        break;
                      case 'dropdown_numeric':
                        option.label =
                          getLocalized(
                            _.get(
                              _.find(
                                _.flatten(
                                  _.map(
                                    this.props.localization
                                      .product_attribute_fields,
                                    'options'
                                  )
                                ),
                                ['id', _.parseInt(value)]
                              ),
                              'value'
                            ),
                            language_id
                          ) + unit;
                        break;
                      case 'string':
                        option.label = getLocalized(value, language_id);
                        break;
                      case 'numeric':
                        option.label = value + unit;
                        break;
                      case 'range':
                        option.label = _.join(value, ' ~ ') + unit;
                        break;
                      case 'range_selectable':
                        option.label = _.join(value, ' ~ ') + unit;
                        break;
                      case 'color':
                        option.label = this._getColorNameAndHex(value).label;
                        break;
                      case 'material':
                        option.label =
                          this._getMaterialNameAndThumbnailUrl(value).label;
                        break;
                      case 'texture':
                        option.label =
                          this._getTextureNameAndThumbnailUrl(value).label;
                        break;
                    }

                    return option;
                  });

                  return (
                    <div className={styles.attributeWrapper} key={i}>
                      <div className={styles.attribute}>
                        <Tooltip text={labelTooltip || label}>
                          <strong>{label}</strong>
                        </Tooltip>
                        <Field
                          name={`field-${attribute.id}`}
                          top={31}
                          wrapper={(handleOpen, activeItem) => (
                            <div
                              className={classNames(
                                styles.dropdown,
                                _.size(values) <= 1 && styles.disabled
                              )}
                              onClick={handleOpen}
                            >
                              {activeItem.label}
                              {_.size(values) > 1 && <ArrowDownSmallIcon />}
                            </div>
                          )}
                          component={Select}
                          searchable
                          disabled={_.size(values) <= 1}
                          noSort
                          options={options}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Form>
    );
  }
}

export default AttributesForm;
