import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  __,
  getLocalized,
  mapStateToProps,
  setFormValue,
  transformToListPairs,
} from '../../../core/utils';
import { enableAutosave } from '../../../redux/actions/general/autosave';
import Input from '../../../components/Input';
import { withRouter } from 'react-router';
import Checkbox from '../../../components/Checkbox';
import Textarea from '../../../components/Textarea';
import styles from '../../../styles/forms/superadmin/notes/EditNoteForm.scss';
import MultiSelect from '../../../components/MultiSelect';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import Select from '../../../components/Select';
import Tooltip from '../../../components/Tooltip';
import SearchableDropdown from '../../../components/SearchableDropdown';
import _ from 'lodash';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';

@reduxForm({
  form: 'superadmin_notes.edit_note',
  enableReinitialize: true,
})
@withRouter
@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  table: state.table['superadmin_notes'],
  values: getFormValues('superadmin_notes.edit_note')(state),
}))
@subscribe(Localization, 'localization')
class EditNoteForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  render() {
    const {
      application_language_id,
      localization: { specification_groups, specification_subgroups },
      values: { specification_subgroup_ids },
      handleSubmit,
      superadmin,
    } = this.props;

    const languages = _.transform(
      _.filter(this.props.localization.languages, ['application_language', 1]),
      (languages, language) => {
        languages[language.id] = language.id == 1;
      },
      {}
    );

    // Specification groups
    const specification_subgroup_options = _.sortBy(
      _.map(specification_subgroups, (specification_subgroup) => {
        const specification_group = _.get(
          specification_groups,
          _.get(specification_subgroup, 'specification_group_id'),
          {}
        );
        const label =
          getLocalized(specification_group.code, languages) +
          [
            _.padEnd(specification_subgroup.code, 3, '0'),
            getLocalized(specification_subgroup.name, application_language_id),
          ].join(': ');
        const group = [
          getLocalized(specification_group.code, languages),
          getLocalized(specification_group.name, application_language_id),
        ].join(': ');

        return {
          value: specification_subgroup.id,
          label,
          group,
        };
      }),
      'group'
    );

    const note_categories = _.map(
      this.props.localization.note_categories,
      (note_category) => {
        return {
          label: getLocalized(note_category.name, this.props.language),
          value: note_category.id,
        };
      }
    );

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name='note'
          disabled={!superadmin.edit_note}
          component={Textarea}
          className={styles.note}
          localized={languages}
          label='Instruction'
        />
        <Field
          name='priority'
          disabled={!superadmin.edit_note}
          numeric
          component={Input}
          label='Priority'
        />
        {/* --- Specification supgroup ids --- */}
        <SearchableDropdown
          leftStyled
          closable={false}
          multiselect
          options={specification_subgroup_options}
          dropdownWrapperClassName={styles.dropdownWrapper}
          dropdownContentClassName={styles.dropdownContent}
          name={'specification_subgroup_edit_ids'}
          selectedValues={specification_subgroup_ids}
          onButtonClick={handleSubmit}
          onSelect={(item) => {
            const itemValue = _.get(item, 'groupHeading')
              ? [...item.value]
              : [item.value];

            const formValue = _.isEmpty(specification_subgroup_ids)
              ? itemValue
              : [...specification_subgroup_ids, ...itemValue];

            setFormValue(
              'superadmin_notes.edit_note',
              'specification_subgroup_ids',
              formValue
            );

            this.setState({ specification_subgroup_ids: formValue });
          }}
          onRemove={(item) => {
            const formValue = _.filter(
              specification_subgroup_ids,
              (specification_subgroup_id) =>
                item?.groupHeading
                  ? !_.includes(item.value, specification_subgroup_id)
                  : specification_subgroup_id != item.value
            );

            setFormValue(
              'superadmin_notes.edit_note',
              'specification_subgroup_ids',
              formValue
            );

            this.setState({ specification_subgroup_ids: formValue });
          }}
          header={
            <div className={styles.dropdownHeader}>
              <Tooltip
                text={__('note.flyout.specification_subgroup.header')}
                className={styles.name}
              >
                {__('note.flyout.specification_subgroup.header')}
              </Tooltip>
            </div>
          }
        >
          <div className={styles.label}>
            <label htmlFor={'specification_subgroup_ids'}>
              <Tooltip text={__('note.flyout.specification_subgroup.label')}>
                {__('note.flyout.specification_subgroup.label')}
              </Tooltip>
            </label>
          </div>

          <div className={styles.selectGroup}>
            <div className={styles.select}>
              {_.join(
                _.map(
                  _.filter(specification_subgroup_options, (subgroup) =>
                    _.includes(specification_subgroup_ids, subgroup.value)
                  ),
                  'label'
                ),
                ', '
              )}
            </div>

            <ArrowDownMiddleIcon />
          </div>
          <div className={styles.bar} />
        </SearchableDropdown>
        {/* --- --- */}
        <Field
          name='note_category_id'
          disabled={!superadmin.edit_note}
          searchable
          component={Select}
          label='Instruction Category'
          options={note_categories}
        />
      </Form>
    );
  }
}

export default EditNoteForm;

// const product_groups = _.sortBy(
//   _.map(this.props.localization.product_groups, (product_group) => ({
//     label: product_group.name,
//     value: product_group.id,
//     groupPriority: _.get(this.props.localization.product_categories, [
//       product_group.product_category_id,
//       'priority',
//     ]),
//     group: _.get(this.props.localization.product_categories, [
//       product_group.product_category_id,
//       'name',
//     ]),
//   })),
//   'priority'
// );

// const product_subgroups = _.map(
//   this.props.localization.product_subgroups,
//   (product_subgroup) => {
//     const product_group = _.get(
//       this.props.localization.product_groups,
//       product_subgroup.product_group_id,
//       {}
//     );

//     return {
//       label: product_subgroup.name,
//       value: product_subgroup.id,
//       group: [
//         _.get(this.props.localization.product_categories, [
//           product_group.product_category_id,
//           'name',
//         ]),
//         product_group.name,
//       ].join(' / '),
//     };
//   }
// );

{
  /* <Field
name='product_category_ids'
disabled={!superadmin.edit_note}
searchable
component={MultiSelect}
label='Assigned Product Categories'
options={transformToListPairs(
  this.props.localization.product_categories
)}
/>
<Field
name='product_group_ids'
disabled={!superadmin.edit_note}
searchable
component={MultiSelect}
label='Assigned Product Groups'
noSort
options={product_groups}
/>
<Field
name='product_subgroup_ids'
disabled={!superadmin.edit_note}
searchable
component={MultiSelect}
label='Assigned Product Subgroups'
options={product_subgroups}
/> */
}
