import * as React from "react";
import { Component } from "react";
import { Field, Form, getFormValues, reduxForm } from "redux-form";
import styles from "../../../styles/forms/superadmin/add_region_wizard/AddRegionForm.scss";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { __, mapStateToProps, setTableParams } from "../../../core/utils";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../../components/ButtonGroup";

@withRouter
@reduxForm({ form: "superadmin.add_region_wizard.add_region" })
@mapStateToProps(state => ({
    values: getFormValues("superadmin.add_region_wizard.add_region")(state),
    auth: state.auth,
}))
class AddRegionForm extends Component {
    @autobind
    _handleSubmit(edit) {
        this.props.change('edit', edit);

        setTimeout(() => this.props.submit(), 10);
    }

    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="name" component={Input} label="Name" />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('superadmin_regions', {
                        add_region_wizard: undefined
                    })} />
                    <ButtonGroup>
                        <Button lightBlue medium middleText={__('button.create')} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
                        <Button lightBlue medium middleText={__('button.create-edit')} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
                    </ButtonGroup>
                </div>
            </Form>
        );
    }
}

export default AddRegionForm;
