import classNames from 'classnames';
import styles from '../../../styles/views/tenders/TenderBOQ.scss';
import GrabberIcon from '../../../assets/images/grabber-16x16.svg';
import * as _ from 'lodash';

const Row = ({
  index,
  id,
  level,
  children,
  style,
  firstChild,
  className,
  unallocatedGroupCollapsed,
  parentId,
  ...rest
}) => {
  if (children.length == 1) {
    return (
      <div
        index={index}
        collection={_.includes(id, 'UN') ? 'UN' : parentId}
        disabled={_.includes(id, 'UN')}
      >
        <div
          {...rest}
          className={classNames(className, styles.leftFloatingColumn)}
          style={{
            ...style,
            width: style.width - 8,
          }}
        >
          <div>
            <div
              className={classNames(
                styles.grabber,
                _.includes(id, 'UN') && styles.hidden
              )}
            >
              <GrabberIcon />
            </div>
          </div>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div {...rest} style={style} className={className}>
      {children}
    </div>
  );
};

export default Row;
