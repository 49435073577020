import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddAddressBookCompanyWizard.scss";
import autobind from "autobind-decorator";
import {__, setTableParams, validateForm} from "../core/utils";
import AddAddressBookCompanyIcon from "../assets/images/add-address-book-company-64x64.svg";
import {setNotification} from "../redux/actions/general/notification";
import AddAddressBookCompanyForm from "../forms/add_address_book_company_wizard/AddAddressBookCompanyForm";
import {createAddressBookCompany, listAddressBookCompanies} from "../redux/actions/table/address_book_companies";

class AddAddressBookCompanyWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;

        formData = _.omit(formData, ['edit']);

        return validateForm(
            createAddressBookCompany(formData).then(({response}) => {
                listAddressBookCompanies().then(() => {
                    if(edit) {
                        setTableParams('address_book_companies', {
                            clicked_row: response.data.id
                        });
                    }
                });

                this.props.onClose();

                setNotification(__('wizard.add-address-book-company.message.success'));
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>{__('wizard.address-book-companies.title.add-address-book-company')}</span>
                        <span>{__('wizard.address-book-companies.subtitle.add-address-book-company')}</span>
                    </div>
                    <div className={styles.right}>
                        <AddAddressBookCompanyIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddAddressBookCompanyForm onSubmit={this._handleSubmit} initialValues={{
                        emails: [''],
                        phones: [''],
                    }} />
                </div>
            </>
        );
    }
}

export default AddAddressBookCompanyWizard;
