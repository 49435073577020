import * as React from "react";
import {Component} from "react";
import MainLayout from "../../layouts/MainLayout";
import * as _ from "lodash";
import {get} from "lodash";
import {createSidebar} from "../../redux/actions/general/sidebar";
import styles from "../../styles/views/plans/MyDeliveries.scss";
import {
    enableProjectsDropdown,
    enableStagesDropdown,
    listActiveModules
} from "../../redux/actions/general/active_modules";
import {__, dateTimeFrom, getActiveStage, getURLParam, redirect} from "../../core/utils";
import Table from "../../components/Table";
import {setNotification} from "../../redux/actions/general/notification";
import SendIcon from "../../assets/images/send-16x16.svg";
import classNames from "classnames";
import {
    deleteMyDeliveries,
    listMyDeliveries,
    readMyDelivery,
    sendDeliveries
} from "../../redux/actions/table/my_deliveries";
import {connect} from "react-redux";
import MyDeliveriesFlyout from "../../flyouts/my_deliveries/MyDeliveriesFlyout";
import ArrowDoubleRightSmallIcon from "../../assets/images/arrow-double-right-small-15x15.svg";
import Tooltip from "../../components/Tooltip";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import {hideAlertbox, showAlertbox} from "../../redux/actions/general/alertbox";

const mapStateToProps = state => {
    return {
        table: state.table["my_deliveries"],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            setTableParams: params =>
                dispatch({
                    type: "table.my_deliveries/SET_TABLE_PARAMS",
                    params
                })
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class MyDeliveries extends Component {
    componentDidMount() {
        enableProjectsDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/deliveries/my'));
        enableStagesDropdown(({stage_id}) => redirect('/stages/' + stage_id + '/deliveries/my'));

        createSidebar({
            title: __('submenu.title.plans'),
            items: [
                {
                    title: __("submenu.title.current-set"),
                    link: () => "/stages/" + getActiveStage() + "/plans/current",
                },
                {
                    title: __("submenu.title.working-set"),
                    link: () => "/stages/" + getActiveStage() + "/plans/working",
                },
                {
                    title: __("submenu.title.uploads"),
                    link: () => "/stages/" + getActiveStage() + "/plans/uploads",
                },
                {
                    title: __("submenu.title.deliveries"),
                    link: () => "/stages/" + getActiveStage() + "/deliveries",
                    active: true,
                    items: [
                        {
                            title: __("submenu.title.deliveries.public"),
                            link: () => "/stages/" + getActiveStage() + "/deliveries",
                        },
                        {
                            title: __("submenu.title.deliveries.my-team"),
                            link: () => "/stages/" + getActiveStage() + "/deliveries/my",
                            active: true,
                        }
                    ]
                },
                {
                    title: __("submenu.title.approvals"),
                    link: () => "/stages/" + getActiveStage() + "/approvals",
                }
            ]
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
            if (!prevProps.table.clicked_row) {
                this.setState({ preview: undefined });
            }

            readMyDelivery(this.props.table.clicked_row).then(({ response }) => {
                this.setState({ preview: response.data });
            });
        }
    }

    render() {
        const columns = [
            {
                key: "title",
                name: __('plans.table.title.title'),
                required: true,
            }, {
                key: "plans",
                name: __("Plans"),
                sortable: false, //TODO - this must be sortable
                value: (plans, row) => (
                    <div className={styles.plans} onClick={() => redirect('/stages/' + this.props.match.params.stage + '/deliveries/my/' + row.id)}>
                        {plans} <Tooltip text={__('plans.tooltip.icon.deliveries.goto-plans')}><ArrowDoubleRightSmallIcon /></Tooltip>
                    </div>
                ),
                width: 60
            }, {
                key: "discipline",
                name: __('plans.deliveries.columns.disciplines'),
                value: (discipline, row) => {
                    const prefixed_discipline = [row.discipline.code, row.discipline.id == null ? (row.discipline.description + '*') : _.get(this.props.localization.disciplines, [row.discipline.id, 'name'])].join(': ');

                    return <Tooltip text={prefixed_discipline}>{prefixed_discipline}</Tooltip>
                },
            }, {
                key: "team",
                name: __('plans.deliveries.columns.team'),
                value: (team) => team.company
            }, {
                key: "created_at",
                name: __('plans.table.title.added'),
                default: true,
                desc: true,
                value: (value) => dateTimeFrom(value),
                width: 105
            }, {
                key: "created_by",
                name: __('plans.table.title.added-by'),
                width: 110
            }, {
                key: "delivered_at",
                name: __('plans.deliveries.columns.delivered-at'),
                value: (value) => value && dateTimeFrom(value),
                width: 105
            }, {
                key: "delivered_to",
                name: __('plans.deliveries.columns.delivered-to'),
                value: (approvers, row) => row.delivered_at && _.join(approvers, ', '),
                sortable: false,
            }, {
                key: "sent",
                name: __('plans.deliveries.columns.sent'),
                value: (sent, row) => row.delivered_at ? (
                    <span className={classNames(styles.sent, styles.yes)}>{__('Yes')}</span>
                ) : (
                    <span className={styles.sent}>{__('No')}</span>
                ),
                width: 60
            }
        ];

        const singleActions = (delivery_id) => {
            const delivered = _.get(_.find(this.props.table.data, {id: delivery_id}), 'delivered_at');
            const plans = _.get(_.find(this.props.table.data, {id: delivery_id}), 'plans');

            const actions = delivered ? [] : [
                <Tooltip text={__('plans.tooltip.icon.deliveries.send-delivery')} key={_.uniqueId()}><SendIcon
                    onClick={e => {
                        e.stopPropagation();

                        if(plans == 0) {
                            setNotification({
                                text: __("You can not send an empty delivery."),
                                type: "warning"
                            });
                        } else {
                            sendDeliveries([delivery_id]).then(() => {
                                setNotification(__('plans.deliveries.notification.sent'));
                                listMyDeliveries(this.props.match.params.stage);

                                this.props.actions.setTableParams({
                                    actioned_row: undefined
                                });
                            });
                        }
                    }}
                /></Tooltip>
            ];

            !delivered && actions.push(
                <Tooltip text={__('Delete delivery')} key={_.uniqueId()}>
                    <DeleteIcon
                        onClick={e => {
                            e.stopPropagation();

                            showAlertbox({
                                color: "red",
                                title: __('general.alert.are-you-sure'),
                                description: "Do you really want to delete the selected delivery?\nThis process cannot be undone.",
                                buttons: [
                                    {
                                        color: 'lightGray',
                                        text: __('general.alert.no-close'),
                                        onClick: 'close'
                                    },
                                    {
                                        color: 'gray',
                                        text: __('general.alert.yes-delete'),
                                        onClick: () => {
                                            deleteMyDeliveries([delivery_id]).then(() => {
                                                hideAlertbox();

                                                setNotification(__("The selected delivery has been deleted."));
                                                listMyDeliveries(this.props.match.params.stage);

                                                this.props.actions.setTableParams({
                                                    actioned_row: undefined
                                                });
                                            });
                                        },
                                    }
                                ]
                            })
                        }}
                    />
                </Tooltip>
            );

            return actions;
        };

        const groupActions = (delivery_ids) => [
            <Tooltip text={__('plans.tooltip.icon.deliveries.send-deliveries')} key={_.uniqueId()}>
                <SendIcon
                    onClick={e => {
                        e.stopPropagation();

                        if(_.size(_.filter(this.props.table.data, delivery => _.includes(delivery_ids, delivery.id) && !_.isEmpty(delivery.delivered_at))) >= 1) {
                            setNotification({
                                text: __("You may send only unsent deliveries."),
                                type: "warning"
                            });
                        } else if(_.size(_.filter(this.props.table.data, delivery => _.includes(delivery_ids, delivery.id) && delivery.plans == 0)) >= 1) {
                            setNotification({
                                text: __("You can not send empty deliveries."),
                                type: "warning"
                            });
                        } else {
                            sendDeliveries(delivery_ids).then(() => {
                                setNotification(__("plans.deliveries.notification.sent2"));
                                listMyDeliveries(this.props.match.params.stage);

                                this.props.actions.setTableParams({
                                    selected_rows: []
                                });
                            });
                        }
                    }}
                />
                </Tooltip>,
            <Tooltip text={__('Delete delivery')} key={_.uniqueId()}>
                <DeleteIcon
                    onClick={e => {
                        e.stopPropagation();

                        if(_.size(_.filter(this.props.table.data, delivery => _.includes(delivery_ids, delivery.id) && !_.isEmpty(delivery.delivered_at))) >= 1) {
                            setNotification({
                                text: __("You may delete only unsent deliveries."),
                                type: "warning"
                            });
                        } else {
                            showAlertbox({
                                color: "red",
                                title: __('general.alert.are-you-sure'),
                                description: "Do you really want to delete the selected deliveries?\nThis process cannot be undone.",
                                buttons: [
                                    {
                                        color: 'lightGray',
                                        text: __('general.alert.no-close'),
                                        onClick: 'close'
                                    },
                                    {
                                        color: 'gray',
                                        text: __('general.alert.yes-delete'),
                                        onClick: () => {
                                            deleteMyDeliveries(delivery_ids).then(() => {
                                                hideAlertbox();

                                                setNotification(__("The selected deliveries have been deleted."));
                                                listMyDeliveries(this.props.match.params.stage);

                                                this.props.actions.setTableParams({
                                                    selected_rows: undefined
                                                });
                                            });
                                        },
                                    }
                                ]
                            });
                        }
                    }}
                />
            </Tooltip>
        ];

        const preview = (
            <MyDeliveriesFlyout
                data={_.get(this.state, 'preview', {})}
                readAction={(id) => {
                    readMyDelivery(getURLParam('id') || id).then(({response}) => {
                        this.setState({preview: response.data});
                    });
                }}
            />
        );

        return (
            <Table
                listAction={() => listMyDeliveries(this.props.match.params.stage)}
                name="my_deliveries"
                title={__('submenu.title.deliveries.my-team')}
                columns={columns}
                preview={get(this.state, "preview") ? preview : []}
                singleActions={singleActions}
                groupActions={groupActions}
                onLoad={() => listActiveModules()}
            />
        );
    }
}

export default MyDeliveries;