import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/edit_linked_specification_wizard/EditLinkedSpecificationForm.scss';
import Button from '../../components/Button';
import { __, mapStateToProps, setTableParams } from '../../core/utils';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import * as _ from 'lodash';

@withRouter
@subscribe(Localization, 'localization')
@reduxForm({
  form: 'edit_specification_file_category_wizard.edit_specification_file_category',
})
@mapStateToProps((state) => ({
  store: _.get(state.table, 'working_set_specifications'),
}))
class EditSpecificationFileCategoryForm extends Component {
  render() {
    const categories = [
      {
        label: __('products.flyout.files.thumbnail'),
        value: 'thumbnail',
      },
      {
        label: __('products.flyout.files.product'),
        value: 'product',
      },
      {
        label: __('products.flyout.files.drawing'),
        value: 'drawing',
      },
      {
        label: __('products.flyout.files.ambient'),
        value: 'ambient',
      },
      {
        label: __('products.flyout.files.technical'),
        value: 'technical',
      },
      {
        label: __('products.flyout.files.tileable-texture-for-3d'),
        value: 'tileable',
      },
      {
        label: __('products.flyout.files.manuals'),
        value: 'manuals',
      },
      {
        label: __('products.flyout.files.guarantee'),
        value: 'guarantee',
      },
      {
        label: __('products.flyout.files.certificates'),
        value: 'certificates',
      },
      {
        label: __('products.flyout.files.catalogs'),
        value: 'catalogs',
      },
      {
        label: __('products.flyout.files.installation'),
        value: 'installation',
      },
      {
        label: __('products.flyout.files.bim'),
        value: 'bim',
      },
      {
        label: __('products.flyout.files.3d'),
        value: '3d',
      },
      {
        label: __('products.flyout.files.cad'),
        value: 'cad',
      },
      {
        label: __('products.flyout.files.2d'),
        value: '2d',
      },
      {
        label: __('products.flyout.files.photometrical-distribution'),
        value: 'light_data',
      },
      {
        label: __('products.flyout.files.video'),
        value: 'video',
      },
      {
        label: __('products.flyout.files.audio'),
        value: 'audio',
      },
      {
        label: __('products.flyout.files.other'),
        value: 'other',
      },
    ];

    const {
      store: { file_ext },
    } = this.props;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.form}>
          <Field
            name='category'
            component={Select}
            searchable
            label={__('products.flyout.edit-specification-file-category')}
            options={
              !_.includes(['jpg', 'jpeg', 'png'], _.toLower(file_ext))
                ? _.filter(
                    categories,
                    (category) =>
                      !_.includes(
                        [
                          'thumbnail',
                          'product',
                          'drawing',
                          'ambient',
                          'technical',
                          'tileable',
                        ],
                        category.value
                      )
                  )
                : categories
            }
          />
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                edit_specification_file_category: undefined,
              })
            }
          />
          <Button
            lightBlue
            medium
            right
            submit
            middleText={__('button.edit')}
            spinning={this.props.submitting}
          />
        </div>
      </Form>
    );
  }
}

export default EditSpecificationFileCategoryForm;
