import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddColorWizard.scss";
import autobind from "autobind-decorator";
import AddColorIcon from "../../assets/images/add-color-64x64.svg";
import {mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import {createColor, listColors} from "../../redux/actions/table/superadmin/colors";
import AddColorForm from "../../forms/superadmin/add_color_wizard/AddColorForm";
import * as _ from "lodash";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_colors"],
}))
class AddColorWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;
        
        return validateForm(
            createColor(formData).then(({response}) => {
                listColors().then(() => {
                    if(edit) {
                        setTableParams('superadmin_colors', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_colors', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                this.props.onClose();

                setNotification("You have successfully added a new color.");
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>Add color</span>
                        <span>Add color to the application</span>
                    </div>
                    <div className={styles.right}>
                        <AddColorIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddColorForm onSubmit={this._handleSubmit} initialValues={{
                        name: {}
                    }}/>
                </div>
            </>
        );
    }
}

export default AddColorWizard;