import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import { __, getLocalized, mapStateToProps } from '../../core/utils';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import styles from '../../styles/forms/current_set_specifications/BOQForm.scss';
import Input from '../../components/Input';

@reduxForm({
  form: 'current_set_specifications.boq',
  enableReinitialize: true,
})
@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
}))
class QuantityForm extends Component {
  static defaultProps = {
    editable: true,
  };

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  render() {
    const { handleSubmit, editable, localization, readOnly, language_id } =
      this.props;

    const { flyout } = this.props.store;

    const { currencies } = localization;

    const units = _.sortBy(
      _.map(this.props.localization.units, (unit) => ({
        label: unit.symbol,
        value: unit.id,
        tooltip: unit.description,
        group: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'name',
          1,
        ]),
        groupPriority: _.get(this.props.localization.unit_groups, [
          unit.unit_group_id,
          'priority',
        ]),
      })),
      'groupPriority'
    );

    const boq_supply_type_groups = _.sortBy(
      _.map(this.props.localization.boq_supply_type_groups, (unit) => ({
        label: getLocalized(unit.name, language_id),
        value: unit.id,
        tooltip: getLocalized(unit.name, language_id),
        priority: _.get(this.props.localization.boq_supply_type_groups, [
          unit.boq_supply_type_group_id,
          'priority',
        ]),
      })),
      'priority'
    );

    const installation_types = [
      { value: 'OFCI', label: 'OFCI' },
      { value: 'OFOI', label: 'OFOI' },
      { value: 'CFCI', label: 'CFCI' },
      { value: 'CFOI', label: 'CFOI' },
    ];

    const budget_types = [
      { value: 'FF&E', label: 'FF&E' },
      { value: 'OS&E', label: 'OS&E' },
      { value: 'CAPEX', label: 'CAPEX' },
    ];

    return (
      <Form onSubmit={handleSubmit}>
        <div className={styles.flex}>
          <div className={styles.f2} />
          <div className={styles.f1}>
            <Field
              disabled={true}
              name='unit_id'
              component={Select}
              label={__('working-set-specifications.boq-form.unit')}
              searchable
              options={units}
              noSort
            />
          </div>
        </div>

        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              disabled={true}
              name='budget_type'
              component={Select}
              label={__('boq.boq-form.budget_type')}
              searchable
              options={budget_types}
              noSort
            />
          </div>
          <div className={styles.f1}>
            <Field
              disabled={true}
              name='installation_type'
              component={Select}
              label={__('boq.boq-form.installation_type')}
              searchable
              options={installation_types}
              noSort
            />
          </div>

          <div className={styles.f1}>
            <Field
              disabled={true}
              name='boq_supply_type_group_id'
              component={Select}
              label={__('boq.boq-form.boq_supply_type_group')}
              searchable
              options={boq_supply_type_groups}
              noSort
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default QuantityForm;
