import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/create_delivery_wizard/CreateDeliveryForm.scss';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { __, transformToListPairs } from '../../core/utils';
import ButtonGroup from '../../components/ButtonGroup';
import Input from '../../components/Input';
import autobind from 'autobind-decorator';
import * as _ from 'lodash';
import classNames from 'classnames';
import WarningIcon from '../../assets/images/warning-15x15.svg';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import {
  listDeliveryApprovers,
  listExistingDeliveries,
} from '../../redux/actions/table/working_set_plans';
import PeopleChooser from '../../components/PeopleChooser';

const mapStateToProps = (state) => {
  return {
    table: state.table['working_set_plans'],
    values: getFormValues('create_delivery_wizard.create_delivery')(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      hideCreateDeliveryWizard: () =>
        dispatch({
          type: 'table.working_set_plans/SET_TABLE_PARAMS',
          params: {
            wizard_active: undefined,
          },
        }),
      setTableParams: (params) =>
        dispatch({
          type: 'table.working_set_plans/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@subscribe(Localization, 'localization')
@reduxForm({ form: 'create_delivery_wizard.create_delivery' })
@connect(mapStateToProps, mapDispatchToProps)
class CreateDeliveryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existing_deliveries: [],
      delivery_approvers: [],
    };
  }

  componentDidMount() {
    const team_id = _.find(this.props.table.data, {
      id: _.first(this.props.table.selected_plans),
    }).team.id;

    listExistingDeliveries(team_id).then(({ response }) => {
      this.setState({ existing_deliveries: response.data });
    });

    listDeliveryApprovers(team_id).then(({ response }) => {
      this.setState({ delivery_approvers: response.data });

      this.props.change('approvers', response.data);
    });
  }

  @autobind
  _handleSubmit(sent) {
    this.props.change('sent', sent);

    setTimeout(() => this.props.submit(), 10);
  }

  @autobind
  _removePlan(id) {
    this.props.actions.setTableParams({
      selected_plans: _.reject(
        this.props.table.selected_plans,
        (plan) => plan == id
      ),
    });
  }

  render() {
    const disabled =
      _.size(
        _.filter(
          this.props.table.data,
          (plan) =>
            _.includes(this.props.table.selected_plans, plan.id) &&
            !_.isEmpty(plan.last_version.delivery)
        )
      ) == _.size(this.props.table.selected_plans) ||
      _.isEmpty(
        _.filter(this.props.table.data, (plan) =>
          _.includes(this.props.table.selected_plans, plan.id)
        )
      );

    const { new_delivery } = this.props.values;

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.delivery}>
          <div className={styles.chooser}>
            <div
              className={classNames(
                styles.option,
                new_delivery && styles.selected
              )}
              onClick={() => this.props.change('new_delivery', true)}
            >
              <span />
              New delivery
            </div>
            <div
              className={classNames(
                styles.option,
                !new_delivery && styles.selected
              )}
              onClick={() => this.props.change('new_delivery', false)}
            >
              <span />
              Add to delivery
            </div>
          </div>
          <div className={styles.selector}>
            {!new_delivery && (
              <Field
                name='delivery_id'
                component={Select}
                searchable
                label={__('Existing Delivery')}
                options={transformToListPairs(
                  this.state.existing_deliveries,
                  'title'
                )}
              />
            )}
          </div>
        </div>
        <div className={styles.scroller}>
          {_.map(
            _.filter(this.props.table.data, (plan) =>
              _.includes(this.props.table.selected_plans, plan.id)
            ),
            (plan, i) => (
              <div
                className={classNames(
                  styles.plan,
                  !_.isEmpty(plan.last_version.delivery) && styles.hasError
                )}
                key={i}
              >
                <div>
                  {!_.isEmpty(plan.last_version.delivery) && (
                    <WarningIcon className={styles.warning} />
                  )}
                  <div>
                    <strong>{plan.code}:</strong> {plan.title}
                  </div>
                </div>
                <div>
                  {plan.discipline.id == null
                    ? plan.discipline.description + '*'
                    : _.get(this.props.localization.disciplines, [
                        plan.discipline.id,
                        'name',
                      ])}{' '}
                  &middot; {plan.team.company}{' '}
                  {_.size(this.props.table.selected_plans) > 1 && (
                    <CloseSmallIcon
                      className={styles.remove}
                      onClick={() => this._removePlan(plan.id)}
                    />
                  )}
                </div>
              </div>
            )
          )}
        </div>
        {new_delivery && (
          <div className={styles.title}>
            <Field
              name='title'
              component={Input}
              label={__('Delivery Title')}
            />
          </div>
        )}
        {!_.isEmpty(this.state.delivery_approvers) && (
          <div className={styles.approvers}>
            <Field
              disabled={true}
              name='approvers'
              component={PeopleChooser}
              label={__('Assigned Approvers')}
              options={this.state.delivery_approvers}
              hint='If no approvers are assigned, delivered plans will bypass the approval process and go directly to the current set.'
            />
          </div>
        )}
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={this.props.actions.hideCreateDeliveryWizard}
          />
          <ButtonGroup right>
            <Button
              lightBlue={!disabled}
              gray={disabled}
              medium
              disabled={disabled}
              onClick={() => this._handleSubmit(false)}
              middleText={new_delivery ? 'Create' : 'Add'}
              spinning={this.props.submitting}
            />
            <Button
              lightBlue={!disabled}
              gray={disabled}
              medium
              disabled={disabled}
              onClick={() => this._handleSubmit(true)}
              middleText={new_delivery ? 'Create & Send' : 'Add & Send'}
              spinning={this.props.submitting}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default CreateDeliveryForm;
