import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams } from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import CountriesFlyout from "../../flyouts/superadmin/countries/CountriesFlyout";
import {
	deleteCountries,
	listCountries,
	readCountry,
} from "../../redux/actions/table/superadmin/countries";
import { listLocalization } from "../../redux/actions/localization";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_countries"],
	auth: state.auth,
}))
class Countries extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_country && setPlusActions([{
			title: "Add country",
			onClick: () => setTableParams('superadmin_countries', {
				add_country_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readCountry(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin } = this.props;

		let columns = [
			{
				key: "name",
				name: "Name",
				required: true,
				value: name => (
					<Tooltip text={name}>{name}</Tooltip>
				)
			},
			{
				key: "region",
				name: "Region",
				width: 150,
				value: region => (
					<Tooltip text={region}>{region}</Tooltip>
				)
			}
		];

		const singleActions = (country_id) => {
			return [
				<Tooltip text="Delete selected country" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected country?\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteCountries([country_id]).then(() => {
												hideAlertbox();

												setNotification("The selected country has been deleted.");
												listCountries();

												listLocalization();

												setTableParams('superadmin_countries', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (countries_ids) => [
			<Tooltip text="Delete selected countries" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these countries?\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteCountries(countries_ids).then(() => {
											hideAlertbox();

											setNotification("The selected countries have been deleted.");
											listCountries();

											listLocalization();

											setTableParams('superadmin_countries', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<CountriesFlyout
				data={this.state.preview}
				readAction={country_id => {
					return new Promise(resolve => {
						readCountry(getURLParam('id') || country_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listCountries}
				name="superadmin_countries"
				groupActions={superadmin.delete_country && groupActions}
				singleActions={superadmin.delete_country && singleActions}
				title={__("modules.submodules.title.countries")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default Countries;
