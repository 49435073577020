import store from '../../../core/store';

export const listProducts = () => {
  return store.dispatch({
    action: 'table.products/LIST_PRODUCTS',
    method: 'GET',
    endpoint: '/products',
    main: true,
  });
};

export const validateProductBasicAttributes = (formData) => {
  return store.dispatch({
    action: 'table.products/VALIDATE_PRODUCT_BASIC_ATTRIBUTES',
    method: 'POST',
    endpoint: '/products/product_basic_attributes',
    data: formData,
  });
};

export const createProduct = (formData) => {
  return store.dispatch({
    action: 'table.products/CREATE_PRODUCT',
    method: 'POST',
    endpoint: '/products',
    data: formData,
  });
};

export const readProduct = (product_id) => {
  return store.dispatch({
    action: 'table.products/READ_PRODUCT',
    method: 'GET',
    endpoint: '/products/' + product_id,
    has404: true,
  });
};

export const linkedProducts = (product_id, value) => {
  return store.dispatch({
    action: 'table.products/READ_LINKED_PRODUCTS',
    method: 'GET',
    endpoint: '/products/' + product_id + '/linked_products/' + value,
  });
};

export const deleteProductAttributesGroup = (product_attribute_group_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_ATTRIBUTES_GROUP',
    method: 'DELETE',
    endpoint: '/products/attributes/groups/' + product_attribute_group_id,
  });
};

export const updateProductAttributesGroup = (
  product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_ATTRIBUTES_GROUP',
    method: 'PUT',
    endpoint: '/products/attributes/groups/' + product_attribute_group_id,
    data: formData,
  });
};

export const deleteProductNote = (product_id, note_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_NOTE',
    method: 'DELETE',
    endpoint: '/products/' + product_id + '/notes',
    data: {
      note_id,
    },
  });
};

export const reorderProductNotes = (product_id, notes) => {
  return store.dispatch({
    action: 'table.products/REORDER_PRODUCT_NOTES',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/notes/order',
    data: {
      notes,
    },
  });
};

export const addProductNote = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_NOTE',
    method: 'POST',
    endpoint: '/products/' + product_id + '/notes',
    data: formData,
  });
};

export const updateProductNote = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_NOTE',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/notes',
    data: formData,
  });
};

export const updateProductBasicData = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_BASIC_DATA',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/basic',
    data: formData,
  });
};

export const updateProductDetails = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_DETAILS',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/details',
    data: formData,
  });
};

export const updateProductPrice = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_PRICE',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/price',
    data: formData,
  });
};

export const uploadProductFiles = (product_id, files) => {
  return store.dispatch({
    action: 'table.products/UPLOAD_PRODUCT_FILES',
    method: 'POST',
    endpoint: '/products/' + product_id + '/files',
    data: {
      files,
    },
  });
};

export const updateProductFileCategory = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/UPLOAD_PRODUCT_FILES',
    method: 'POST',
    endpoint: '/products/' + product_id + '/file/category',
    data: formData,
  });
};

export const deleteProductFile = (product_id, category, attachment_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_FILE',
    method: 'DELETE',
    endpoint: '/products/' + product_id + '/file',
    data: {
      category,
      attachment_id,
    },
  });
};

export const uploadProductImages = (product_id, category, images) => {
  return store.dispatch({
    action: 'table.products/UPLOAD_PRODUCT_IMAGES',
    method: 'POST',
    endpoint: '/products/' + product_id + '/images',
    data: {
      images,
      category,
    },
  });
};

export const deleteProductImage = (product_id, category, attachment_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_IMAGE',
    method: 'DELETE',
    endpoint: '/products/' + product_id + '/image',
    data: {
      category,
      attachment_id,
    },
  });
};

export const updateProductCategories = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_CATEGORIES',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/categories',
    data: formData,
  });
};

export const reorderProductImages = (product_id, category, images) => {
  return store.dispatch({
    action: 'table.products/REORDER_PRODUCT_IMAGES',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/images/order',
    data: {
      category,
      images,
    },
  });
};

export const uploadProductThumbnails = (product_id, images) => {
  return store.dispatch({
    action: 'table.products/UPLOAD_PRODUCT_THUMBNAILS',
    method: 'POST',
    endpoint: '/products/' + product_id + '/thumbnails',
    data: {
      images,
    },
  });
};

export const deleteProductThumbnail = (product_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_THUMBNAIL',
    method: 'DELETE',
    endpoint: '/products/' + product_id + '/thumbnail',
  });
};

export const addProductAttributeGroup = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_ATTRIBUTE_GROUP',
    method: 'POST',
    endpoint: '/products/' + product_id + '/attribute_groups',
    data: formData,
  });
};

export const updateProductAttributesGroupFields = (
  product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_ATTRIBUTES_GROUP_FIELDS',
    method: 'PUT',
    endpoint:
      '/products/attributes/groups/' + product_attribute_group_id + '/fields',
    data: formData,
  });
};

export const deleteProductAttributeField = (product_attribute_field_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_ATTRIBUTE_FIELD',
    method: 'DELETE',
    endpoint: '/products/attributes/' + product_attribute_field_id,
  });
};

export const addProductAttributeGroupField = (
  product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_ATTRIBUTE_GROUP_FIELD',
    method: 'POST',
    endpoint:
      '/products/attributes/groups/' + product_attribute_group_id + '/fields',
    data: formData,
  });
};

export const updateProductAttributeGroupField = (
  product_attribute_field_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_ATTRIBUTE_GROUP_FIELD',
    method: 'PUT',
    endpoint: '/products/attributes/' + product_attribute_field_id,
    data: formData,
  });
};

export const createSpecificationFromProduct = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/CREATE_SPECIFICATION_FROM_PRODUCT',
    method: 'POST',
    endpoint: '/products/' + product_id + '/specifications',
    data: formData,
  });
};

export const saveProductAttributeGroupAsTemplate = (
  product_attribute_group_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/SAVE_PRODUCT_ATTRIBUTE_GROUP_AS_TEMPLATE',
    method: 'POST',
    endpoint:
      '/products/attributes/groups/' + product_attribute_group_id + '/template',
    data: formData,
  });
};

export const listProductAttributeGroupFields = (product_attribute_group_id) => {
  return store.dispatch({
    action: 'table.products/LIST_PRODUCT_ATTRIBUTE_GROUP_FIELDS',
    method: 'GET',
    endpoint:
      '/products/attributes/groups/' + product_attribute_group_id + '/fields',
  });
};

export const listMyCompanyProductAttributeTemplateGroups = () => {
  return store.dispatch({
    action: 'table.products/LIST_MY_COMPANY_PRODUCT_ATTRIBUTE_TEMPLATE_GROUPS',
    method: 'GET',
    endpoint: '/companies/my/product_attribute_template_groups',
  });
};

export const reorderProductAttributeFields = (
  product_attribute_group_id,
  fields
) => {
  return store.dispatch({
    action: 'table.products/REORDER_PRODUCT_ATTRIBUTE_FIELDS',
    method: 'PUT',
    endpoint:
      '/products/attributes/groups/' +
      product_attribute_group_id +
      '/fields/order',
    data: {
      fields,
    },
  });
};

export const reorderProductAttributeGroups = (product_id, groups) => {
  return store.dispatch({
    action: 'table.products/REORDER_PRODUCT_ATTRIBUTE_GROUPS',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/attribute_groups/order',
    data: {
      groups,
    },
  });
};

export const listPossibleProductNotes = (product_id) => {
  return store.dispatch({
    action: 'table.products/LIST_POSSIBLE_PRODUCT_NOTES',
    method: 'GET',
    endpoint: '/products/' + product_id + '/possible_product_notes',
  });
};

export const addProductParts = (product_id, category, product_ids) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_PARTS',
    method: 'POST',
    endpoint: '/products/' + product_id + '/parts',
    data: { category, product_ids },
  });
};

export const addProductAccessories = (product_id, category, product_ids) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_ACCESSORIES',
    method: 'POST',
    endpoint: '/products/' + product_id + '/accessories',
    data: { product_ids },
  });
};

export const addOptionalProductPart = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/ADD_OPTIONAL_PRODUCT_PART',
    method: 'POST',
    endpoint: '/products/' + product_id + '/optional_part',
    data: formData,
  });
};

export const deleteProductPart = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_PART',
    method: 'DELETE',
    endpoint: '/products/' + product_id + '/parts',
    data: formData,
  });
};

export const deleteProductAccessory = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_ACCESSORY',
    method: 'DELETE',
    endpoint: '/products/' + product_id + '/accessory',
    data: formData,
  });
};

export const listPossibleProductParts = (product_id) => {
  return store.dispatch({
    action: 'table.products/LIST_POSSIBLE_PRODUCT_PARTS',
    method: 'GET',
    endpoint: '/products/' + product_id + '/possible_parts',
  });
};

export const listPossibleProductAccessories = (product_id) => {
  return store.dispatch({
    action: 'table.products/LIST_POSSIBLE_PRODUCT_ACCESSORIES',
    method: 'GET',
    endpoint: '/products/' + product_id + '/possible_accessories',
  });
};

export const copyProduct = (product_id) => {
  return store.dispatch({
    action: 'table.products/COPY_PRODUCT',
    method: 'POST',
    endpoint: '/products/' + product_id + '/copy',
  });
};

export const addProductAttributeGroupFieldValue = (
  product_attribute_group_field_id
) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_ATTRIBUTE_GROUP_FIELD_VALUE',
    method: 'POST',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/values',
  });
};

export const useAsThumbnailProductAttributeGroupField = (
  product_attribute_group_field_id,
  data
) => {
  return store.dispatch({
    action: 'table.products/USE_AS_THUMBNAIL_PRODUCT_ATTRIBUTE_GROUP_FIELD',
    method: 'PUT',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/use_as_thumbnail',
    data: data,
  });
};

export const changeAttributeThumbnail = (
  product_attribute_group_field_id,
  data
) => {
  return store.dispatch({
    action: 'table.products/USE_AS_THUMBNAIL_PRODUCT_ATTRIBUTE_GROUP_FIELD',
    method: 'PUT',
    endpoint:
      '/products/' +
      product_attribute_group_field_id +
      '/change_attribute_thumbnail',
    data: data,
  });
};

export const updateProductAttributeGroupFieldValueInUse = (
  product_attribute_group_field_value_id,
  in_use
) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_ATTRIBUTE_GROUP_FIELD_VALUE_IN_USE',
    method: 'PUT',
    endpoint:
      '/product_attribute_group_field_values/' +
      product_attribute_group_field_value_id +
      '/in_use',
    data: {
      in_use,
    },
  });
};

export const deleteProductAttributeGroupFieldValue = (
  product_attribute_group_field_value_id
) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_ATTRIBUTE_GROUP_FIELD_VALUE',
    method: 'DELETE',
    endpoint:
      '/product_attribute_group_field_values/' +
      product_attribute_group_field_value_id,
  });
};

export const updateProductAttributesGroupFieldValues = (
  product_attribute_group_field_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_ATTRIBUTES_GROUP_FIELD_VALUES',
    method: 'PUT',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/values',
    data: formData,
  });
};

export const listPossibleProductAttributeGroupFieldParts = (
  product_attribute_group_field_id
) => {
  return store.dispatch({
    action: 'table.products/LIST_POSSIBLE_PRODUCT_ATTRIBUTE_GROUP_FIELD_PARTS',
    method: 'GET',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/possible_parts',
  });
};

export const addProductAttributesGroupFieldParts = (
  product_attribute_group_field_id,
  formData
) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_ATTRIBUTES_GROUP_FIELD_PARTS',
    method: 'POST',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/parts',
    data: formData,
  });
};

export const deleteProductAttributesGroupFieldPart = (
  product_attribute_group_field_id,
  related_product_attribute_group_field_id
) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_ATTRIBUTES_GROUP_FIELD_PART',
    method: 'DELETE',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/parts',
    data: {
      related_product_attribute_group_field_id,
    },
  });
};

export const toggleProductAttributesGroupFieldPartValue = (
  product_attribute_group_field_id,
  product_attribute_group_field_value_id
) => {
  return store.dispatch({
    action: 'table.products/TOGGLE_PRODUCT_ATTRIBUTES_GROUP_FIELD_PART_VALUE',
    method: 'PUT',
    endpoint:
      '/product_attribute_group_fields/' +
      product_attribute_group_field_id +
      '/part_value',
    data: {
      product_attribute_group_field_value_id,
    },
  });
};

export const changeProductVariantAttributes = (product_id, formData) => {
  return store.dispatch({
    action: 'table.products/CHANGE_PRODUCT_VARIANT_ATTRIBUTES',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/variant_attributes',
    data: formData,
  });
};

export const addProductVariant = (product_id) => {
  return store.dispatch({
    action: 'table.products/ADD_PRODUCT_VARIANT',
    method: 'POST',
    endpoint: '/products/' + product_id + '/variants',
  });
};

export const deleteProductVariant = (product_variant_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT_VARIANT',
    method: 'DELETE',
    endpoint: '/products_variants/' + product_variant_id,
  });
};

export const updateProductVariantValues = (product_variant_id, postData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_VARIANT_VALUES',
    method: 'PUT',
    endpoint: '/products_variants/' + product_variant_id,
    data: postData,
  });
};

export const updateProductType = (product_id, postData) => {
  return store.dispatch({
    action: 'table.products/UPDATE_PRODUCT_TYPE',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/type',
    data: postData,
  });
};

export const reorderProductPartItems = (product_id, items) => {
  return store.dispatch({
    action: 'table.products/REORDER_PRODUCT_PART_ITEMS',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/part_items/order',
    data: {
      items,
    },
  });
};

export const listPossibleProductBrands = (formData) => {
  return store.dispatch({
    action: 'table.products/LIST_POSSIBLE_PRODUCT_BRANDS',
    method: 'POST',
    endpoint: '/products/brands',
    data: formData,
  });
};

export const reorderProductAccessoryItems = (product_id, items) => {
  return store.dispatch({
    action: 'table.products/REORDER_PRODUCT_ACCESSORY_ITEMS',
    method: 'PUT',
    endpoint: '/products/' + product_id + '/accessory_items/order',
    data: {
      items,
    },
  });
};

export const deleteProduct = (product_id) => {
  return store.dispatch({
    action: 'table.products/DELETE_PRODUCT',
    method: 'DELETE',
    endpoint: '/products/' + product_id,
  });
};
