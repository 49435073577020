import React, { Component } from 'react';
import styles from '../styles/components/BOQFilterChooser.scss';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import SearchIcon from '../assets/images/search-15x15.svg';
import { __, mapStateToProps } from '../core/utils';
import * as _ from 'lodash';
import { registerMultieditField } from '../redux/actions/general/multiedit';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import PlusMiddleIcon from '../assets/images/plus-middle-15x15.svg';
import OutsideClickWrapper from './OutsideClickWrapper';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import FieldComponent from './FieldComponent';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { isMobileOnly } from 'react-device-detect';
import Tooltip from './Tooltip';
import LogoIcon from '../assets/images/logo-50x50.svg';
import SearchableDropdown from './SearchableDropdown';
import ArrowDownSmallIcon from '../assets/images/arrow-down-small-15x15.svg';
import { isArray } from 'lodash';
import ReactHoverObserver from 'react-hover-observer';

@FieldComponent
@mapStateToProps((state) => ({
  multiedit: state.general.multiedit,
}))
class BOQFilterChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      selectedFilterValues: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.meta.active != this.props.meta.active) {
      this.setState(
        {
          query: '',
          selected: this.props.input.value || [],
        },
        () => {
          this.props.recalculateOffset();
        }
      );
    }

    if (
      prevState.selected != this.state.selected ||
      prevState.query != this.state.query
    ) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.props.input.onFocus();
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _handleSave() {
    this.props.input.onChange(this.state.selected);

    this._handleClose();
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    this.refs.search.focus();
  }

  @autobind
  clearFilter(name) {
    this.setState((prevState) => ({
      selectedFilterValues: _.omit(this.state.selectedFilterValues, name),
    }));
  }

  @autobind
  _toggleValue(value, highlight_query = false) {
    const multiedit_enabled = _.isObject(
      this.props.multiedit[this.props.meta.form]
    );

    multiedit_enabled &&
      registerMultieditField(this.props.meta.form, this.props.input.name);

    this.setState(
      {
        selected: _.xor(this.state.selected, [value]),
      },
      () => {
        highlight_query && this.refs.search && this.refs.search.select();
      }
    );
  }

  @autobind
  _renderNestedList(sorted_results, depth = 0) {
    return _.map(
      sorted_results,
      ({
        label,
        tooltip,
        value,
        icon,
        color,
        markedValue,
        children,
        parentId,
        source,
      }) => {
        return (
          <>
            {!_.isEmpty(children) ? (
              <>
                <div
                  className={styles.group}
                  key={value}
                  style={{ paddingLeft: `${depth * 10}px` }}
                >
                  <div className={styles.heading}>{label}</div>
                </div>

                {this._renderNestedList(children, depth + 1)}
              </>
            ) : (
              <ReactHoverObserver key={value}>
                {({ isHovering }) => (
                  <Tooltip placement='right' text={tooltip || label}>
                    <div
                      onClick={() => this._toggleValue(value, true)}
                      className={classNames(
                        styles.row,
                        markedValue &&
                          this.props.input.value != value &&
                          styles.markedValue,
                        this.props.input.value == value && styles.active
                      )}
                      style={{ paddingLeft: `${depth * 10}px` }} // Add padding based on depth
                    >
                      <div
                        className={classNames(
                          styles.icon,
                          source === 'company' ? styles.company : styles.system
                        )}
                      >
                        {source === 'company' ? (
                          'MY'
                        ) : (
                          <Tooltip text={'System'}>
                            <LogoIcon />
                          </Tooltip>
                        )}
                      </div>

                      <span>{label}</span>

                      <PlusMiddleIcon className={styles.plus} />
                    </div>
                  </Tooltip>
                )}
              </ReactHoverObserver>
            )}
          </>
        );
      }
    );
  }

  render() {
    const {
      noSort,
      searchable,
      disabled,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
      filters,
      level,
    } = this.props;

    const { query, selected, selectedFilterValues } = this.state;

    const filterThroughNestedList = (options, query) => {
      const filterRecursively = (items) => {
        const filteredItems = [];

        _.each(items, (item) => {
          const newItem = _.cloneDeep(item);
          const children = _.get(newItem, 'children', []);

          if (
            _.includes(
              _.toLower(_.get(newItem, 'label', '')),
              _.toLower(query)
            ) &&
            _.every(Object.keys(selectedFilterValues), (key) =>
              _.includes(selectedFilterValues[key], newItem[key])
            )
          ) {
            // If the item itself matches the query, push it to filteredItems
            filteredItems.push(newItem);
          } else {
            // Recursively filter children
            const filteredChildren = filterRecursively(children);
            if (filteredChildren.length > 0) {
              // If any of its children match the query, attach them to the newItem
              newItem.children = filteredChildren;
              // Push newItem to filteredItems
              filteredItems.push(newItem);
            }
          }
        });

        return filteredItems;
      };

      return filterRecursively(options);
    };

    // const filterThroughNestedList = (
    //   options,
    //   query,
    //   filterType = 'labelContains'
    // ) => {
    //   const filterRecursively = (items) => {
    //     const filteredItems = [];

    //     _.each(items, (item) => {
    //       const newItem = _.cloneDeep(item);
    //       const children = _.get(newItem, 'children', []);

    //       const shouldInclude =
    //         filterType === 'labelContains'
    //           ? _.includes(
    //               _.toLower(_.get(newItem, 'label', '')),
    //               _.toLower(query)
    //             )
    //           : filterType === 'valueInArray' &&
    //             _.isEmpty(children) &&
    //             !_.includes(query, newItem.value);

    //       if (!query || shouldInclude) {
    //         // If query is empty or the item itself matches the query, push it to filteredItems
    //         filteredItems.push(newItem);
    //       } else {
    //         // Recursively filter children
    //         const filteredChildren = filterRecursively(children);
    //         if (filteredChildren.length > 0) {
    //           // If any of its children match the query, attach them to the newItem
    //           newItem.children = filteredChildren;
    //           // Push newItem to filteredItems
    //           filteredItems.push(newItem);
    //         }
    //       }
    //     });

    //     return filteredItems;
    //   };

    //   return filterRecursively(options);
    // };

    const findOptionTree = (options, targetValue) => {
      let foundOptions = [];

      options.forEach((option) => {
        if (!option.children && _.includes(targetValue, option.value)) {
          foundOptions.push({ value: option.value, label: option.label });
        }

        if (option.children) {
          foundOptions = [
            ...foundOptions,
            ...findOptionTree(option.children, targetValue),
          ];
        }
      });

      return foundOptions;
    };

    const getFilteredTree = (
      nodes,
      query,
      includes = true,
      labelKey = 'value'
    ) => {
      return _.reduce(
        nodes,
        (result, node) => {
          const newNode = { ...node };
          const children = _.get(newNode, 'children', []);

          newNode['children'] = getFilteredTree(children, query, includes);

          const sizeOfFilteredItems = _.size(newNode['children']) > 0;
          const isMatchingQuery = includes
            ? _.includes(query, _.get(newNode, labelKey, ''))
            : !_.includes(query, _.get(newNode, labelKey, '')) &&
              _.isEmpty(children);

          if (isMatchingQuery || sizeOfFilteredItems) {
            result.push(newNode);
          }

          return result;
        },
        []
      );
    };

    let selected_options = findOptionTree(this.props.options, selected, true);

    let available_options = getFilteredTree(
      this.props.options,
      selected,
      false
    );

    let results = _.filter(available_options, (item) => {
      return (
        _.isEmpty(this.state.query) ||
        _.includes(item.label.toLowerCase(), this.state.query.toLowerCase())
      );
    });

    // results = _.filter(results, (result) => {
    //   return _.every(Object.keys(selectedFilterValues), (key) => {
    //     return isArray(result[key])
    //       ? _.some(result[key], (data) =>
    //           _.includes(selectedFilterValues[key], data)
    //         )
    //       : _.includes(selectedFilterValues[key], result[key]);
    //   });
    // });

    results = filterThroughNestedList(available_options, this.state.query);

    let selected_filtered_results = noSort
      ? selected_options
      : _.sortBy(selected_options, (item) => item.label.toLowerCase());
    let available_filtered_results = noSort
      ? results
      : _.sortBy(results, (item) => item.label.toLowerCase());

    return (
      <div
        className={classNames(
          styles.wrapper,
          _.isEmpty(this.props.input.value) && styles.placeholder,
          this.props.meta.active && styles.focus,
          this.props.center && styles.center,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          this.props.disabled && styles.disabled
        )}
      >
        <div className={styles.label}>
          <label onClick={this._handleOpen}>
            <Tooltip text={this.props.labelTooltip || this.props.label}>
              {this.props.label}
            </Tooltip>
          </label>
        </div>
        <div
          className={styles.selectGroup}
          ref={elementRef}
          onClick={this._handleOpen}
        >
          <div className={styles.select}>
            {!_.isEmpty(this.props.input.value)
              ? _.map(selected_filtered_results, (result) => result.label).join(
                  ', '
                )
              : ''}
          </div>
          {!this.props.center && !this.props.disabled && (
            <ArrowDownMiddleIcon />
          )}
        </div>
        {this.props.meta.active && (
          <OutsideClickWrapper closable={false}>
            <KeyboardEventHandler
              handleKeys={['esc']}
              onKeyEvent={this._handleClose}
            />
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}
              {!_.isEmpty(selected_filtered_results) && (
                <div className={styles.selected}>
                  <div className={styles.title}>
                    <span className={styles.currentlySelected}>
                      {__(
                        'working-set-boq.add-template-item-to-boq.currently-selected'
                      )}
                      ({_.size(selected_filtered_results)})
                    </span>
                  </div>
                  <div className={styles.list}>
                    {_.map(selected_filtered_results, (item, i) => (
                      <div
                        key={i}
                        className={classNames(
                          styles.row,
                          item.disabled && styles.itemDisabled
                        )}
                        onClick={() =>
                          !item.disabled && this._toggleValue(item.value)
                        }
                      >
                        <span>{item.label}</span>
                        {!disabled && !item.disabled && (
                          <CloseSmallIcon className={styles.close} />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {!_.isEmpty(available_options) && (
                <div className={styles.available}>
                  <div className={styles.title}>
                    <span className={styles.availableOptions}>
                      {__(
                        'working-set-boq.add-template-item-to-boq.available-options'
                      )}
                    </span>

                    <div className={styles.filter}>
                      {_.map(filters, (filter) => {
                        return (
                          <SearchableDropdown
                            multiselect
                            options={filter.options}
                            dropdownWrapperClassName={styles.dropdownWrapper}
                            name={filter.name}
                            selectedValues={selectedFilterValues[filter.name]}
                            onSelect={(item) => {
                              const keyValues =
                                this.state.selectedFilterValues[filter.name];

                              let newValues = _.get(item, 'groupHeading')
                                ? [...item.value]
                                : [item.value];

                              if (!_.isUndefined(keyValues)) {
                                newValues = [...keyValues, ...newValues];
                              }

                              this.setState((prevState, props) => ({
                                selectedFilterValues: {
                                  ...prevState.selectedFilterValues,
                                  [filter.name]: newValues,
                                },
                              }));
                            }}
                            onRemove={(item) => {
                              this.setState(
                                (prevState) => ({
                                  selectedFilterValues: {
                                    ...prevState.selectedFilterValues,

                                    [filter.name]: _.filter(
                                      prevState.selectedFilterValues[
                                        filter.name
                                      ],
                                      (value) =>
                                        !_.includes(
                                          _.get(item, 'groupHeading')
                                            ? item.value
                                            : [item.value],
                                          value
                                        )
                                    ),
                                  },
                                }),
                                () => {
                                  if (
                                    _.size(
                                      this.state.selectedFilterValues[
                                        filter.name
                                      ]
                                    ) == 0
                                  ) {
                                    this.setState({
                                      selectedFilterValues: _.omit(
                                        this.state.selectedFilterValues,
                                        filter.name
                                      ),
                                    });
                                  }
                                }
                              );
                            }}
                            header={
                              <div className={styles.dropdownHeader}>
                                <Tooltip
                                  text={__(
                                    'working-set-boq.add-template-item-to-boq.filters.' +
                                      filter.name
                                  )}
                                  className={styles.filterHeaderName}
                                >
                                  {__(
                                    'working-set-boq.add-template-item-to-boq.filters.' +
                                      filter.name
                                  )}
                                </Tooltip>

                                <span
                                  className={styles.filterHeaderClearAll}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.clearFilter(filter.name);
                                  }}
                                >
                                  {__(
                                    'working-set-boq.add-template-item-to-boq.filters.clear'
                                  )}
                                </span>
                              </div>
                            }
                          >
                            <div
                              className={classNames(
                                styles.bubble,
                                _.size(selectedFilterValues[filter.name]) ===
                                  0 && styles.bubbleEmpty
                              )}
                            >
                              <span>
                                {__(
                                  'working-set-boq.add-template-item-to-boq.filters.' +
                                    filter.name
                                )}
                              </span>
                              {_.size(selectedFilterValues[filter.name]) >
                                0 && (
                                <strong className={styles.filterCount}>
                                  ({_.size(selectedFilterValues[filter.name])})
                                </strong>
                              )}
                              <ArrowDownSmallIcon />
                            </div>
                          </SearchableDropdown>
                        );
                      })}
                    </div>
                  </div>

                  {searchable && (
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        ref='search'
                        type='text'
                        value={query}
                        autoFocus={true}
                        placeholder={__('multiselect.title.search')}
                        onChange={this._handleSearch}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                  )}
                  <div className={styles.list}>
                    {this._renderNestedList(available_filtered_results)}
                    {/* {_.map(
                      _.groupBy(
                        _.filter(available_filtered_results, 'group'),
                        'group'
                      ),
                      (items, group) => (
                        <div className={styles.group} key={group}>
                          <div className={styles.heading}>{group}</div>
                          {_.map(items, (item, i) => (
                            <div
                              key={i}
                              className={classNames(
                                styles.row,
                                item.disabled && styles.itemDisabled
                              )}
                              onClick={() =>
                                !item.disabled &&
                                this._toggleValue(item.value, true)
                              }
                            >
                              <div
                                className={classNames(
                                  styles.icon,
                                  item.is_company_note
                                    ? styles.company
                                    : styles.system
                                )}
                              >
                                {item.is_company_note ? (
                                  'MY'
                                ) : (
                                  <Tooltip text={'System'}>
                                    <LogoIcon />
                                  </Tooltip>
                                )}
                              </div>

                              <span>{item.label}</span>

                              <PlusMiddleIcon className={styles.plus} />
                            </div>
                          ))}
                        </div>
                      )
                    )} */}

                    {_.isEmpty(available_filtered_results) && (
                      <div className={styles.noResults}>No results found.</div>
                    )}
                  </div>
                </div>
              )}

              {/* FOOTER */}
              <div className={styles.footer}>
                <ButtonGroup right>
                  <Button
                    lightGray
                    medium
                    middleText={__('button.cancel')}
                    onClick={this._handleClose}
                  />
                  <Button
                    lightBlue
                    medium
                    middleText={__('button.done')}
                    onClick={this._handleSave}
                  />
                </ButtonGroup>
              </div>
            </div>
          </OutsideClickWrapper>
        )}
        <div className={styles.bar} />
      </div>
    );
  }
}

export default BOQFilterChooser;
