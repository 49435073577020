import store from '../../core/store';

export const getPreferences = (name) => {
  return store.dispatch({
    action: 'profile/GET_PREFERENCES',
    method: 'GET',
    endpoint: '/profile/preferences?name=' + name,
  });
};

export const updatePreferences = (name, preferences) => {
  return store.dispatch({
    action: 'profile/UPDATE_PREFERENCES',
    method: 'PATCH',
    endpoint: '/profile/preferences?name=' + name,
    preloaded: false,
    data: {
      preferences,
    },
  });
};

export function readProfile() {
  return store.dispatch({
    action: 'profile/READ_PROFILE',
    method: 'GET',
    endpoint: '/profile',
  });
}

export function listNotificationActions() {
  return store.dispatch({
    action: 'profile/LIST_NOTIFICATION_ACTIONS',
    method: 'GET',
    endpoint: '/profile/notification_actions',
    main: true,
  });
}

export const updateNotificationsSettings = (formData) => {
  return store.dispatch({
    action: 'profile/UPDATE_NOTIFICATIONS_SETTINGS',
    method: 'PUT',
    endpoint: '/profile/notifications/settings',
    data: formData,
  });
};

export function updatePassword(formData) {
  return store.dispatch({
    action: 'profile/UPDATE_PASSWORD',
    method: 'PUT',
    endpoint: '/profile/password',
    data: formData,
  });
}

export function enableTwoFactorAuthentication(formData) {
  return store.dispatch({
    action: 'profile/ENABLE_TWO_FACTOR_AUTHENTICATION',
    method: 'POST',
    endpoint: '/profile/two_factor',
    data: formData,
  });
}

export function disableTwoFactorAuthentication(formData) {
  return store.dispatch({
    action: 'profile/DISABLE_TWO_FACTOR_AUTHENTICATION',
    method: 'DELETE',
    endpoint: '/profile/two_factor',
  });
}

export function showCreateCompanyWizard(predefined_company_name = '') {
  return store.dispatch({
    type: 'profile/SHOW_CREATE_COMPANY_WIZARD',
    predefined_company_name,
  });
}

export function hideCreateCompanyWizard() {
  return store.dispatch({
    type: 'profile/HIDE_CREATE_COMPANY_WIZARD',
  });
}

export const getInvitation = () => {
  return store.dispatch({
    action: 'profile/GET_INVITATIONS',
    method: 'GET',
    endpoint: '/profile/invitations',
  });
};

export const joinCompany = (company_join_invitation_id) => {
  return store.dispatch({
    action: 'profile/JOIN_COMPANY',
    method: 'POST',
    endpoint: '/profile/join_company',
    data: {
      company_join_invitation_id,
    },
  });
};

export const acceptCompanyJoinSuggestion = (company_join_suggestion_id) => {
  return store.dispatch({
    action: 'profile/ACCEPT_COMPANY_JOIN_SUGGESTION',
    method: 'POST',
    endpoint:
      '/profile/company_join_suggestions/' +
      company_join_suggestion_id +
      '/accept',
  });
};

export const sendJoinRequest = (company_id) => {
  return store.dispatch({
    action: 'profile/SEND_JOIN_REQUEST',
    method: 'POST',
    endpoint: '/profile/companies/' + company_id + '/join_request',
  });
};
