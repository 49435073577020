import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddProductNoteWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeAddIcon from "../assets/images/product-attribute-add-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import AddProductAttributeGroupFieldForm
	from "../forms/add_product_attribute_group_field_wizard/AddProductAttributeGroupFieldForm";
import {setNotification} from "../redux/actions/general/notification";
import {addProductAttributeGroupField, addProductNote, readProduct} from "../redux/actions/table/products";
import AddNoteIcon from "../assets/images/add-note-60x60.svg";
import AddProductNoteForm from "../forms/add_product_note_wizard/AddProductNoteForm";

@mapStateToProps(state => ({
	flyout: state.table.products.flyout
}))
class AddProductNoteWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		return validateForm(
			addProductNote(this.props.flyout.id, formData).then(() => readProduct(this.props.flyout.id).then(({response}) => {
				setNotification(__('products.all-products.flyout.instructions.product-instruction-has-been-added'));

				setTableParams('products', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('products.all-products.flyout.instructions.add-product-instruction')}</span>
						<span>{__('products.all-products.flyout.instructions.add-instruction-to-your-product')}</span>
					</div>
					<div className={styles.right}>
						<AddNoteIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddProductNoteForm onSubmit={this._handleSubmit} initialValues={{
						add_new: '0',
						note: {}
					}} />
				</div>
			</>
		);
	}
}

export default AddProductNoteWizard;
