import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tenders/TendersFlyout.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CloseBigIcon from '../../assets/images/close-big-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import autobind from 'autobind-decorator';
import { __, setTableParams } from '../../core/utils';
import Properties from './Properties';
import { setNotification } from '../../redux/actions/general/notification';
import EditTenderToolbarForm from '../../forms/tenders/EditTenderToolbarForm';
import {
  listAllTenders,
  readTender,
  updateBidderStatus,
  updateTenderToolbar,
} from '../../redux/actions/table/tenders';
import { Element, ScrollLink, scrollSpy } from 'react-scroll';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Files from './Files';
import Bidders from './Bidders';
import BidderFiles from './BidderFiles';
import { hideAlertbox } from '../../redux/actions/general/alertbox';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.tenders/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(
  (state) => ({
    user_id: state.auth.id,
  }),
  mapDispatchToProps
)
@withRouter
class TendersFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed_tabs: [],
      highlighted_tab: null,
    };

    this.highlight_timeout = null;

    this.tabs = ['properties', 'bidders', 'files', 'bidder_files'];

    this.tab_refs = [];
  }

  componentDidMount() {
    scrollSpy.update();

    if (this.props.tab_opened) {
      setTimeout(() => {
        this.tab_refs[this.props.tab_opened] &&
          this.tab_refs[this.props.tab_opened].click();

        setTableParams('tenders', {
          tab: undefined,
        });
      }, 500);
    }
  }

  @autobind
  _toggleCollapseTab(tab) {
    this.setState({
      collapsed_tabs: _.xor(this.state.collapsed_tabs, [tab]),
    });
  }

  @autobind
  _highlightSection(section) {
    this.setState({ highlighted_tab: section }, () => {
      if (this.highlight_timeout) clearTimeout(this.highlight_timeout);

      this.highlight_timeout = setTimeout(() => {
        this.setState({ highlighted_tab: null });
      }, 200);
    });
  }

  @autobind
  _toggleCollapseTabs() {
    if (_.size(this.state.collapsed_tabs) == _.size(this.tabs)) {
      this.setState({
        collapsed_tabs: [],
      });
    } else {
      this.setState({
        collapsed_tabs: this.tabs,
      });
    }

    this.refs.scroll.scrollTop = 0;
  }

  @autobind
  _handleSubmit(formData) {
    updateTenderToolbar(formData.id, {
      ..._.omit(formData, ['id']),
    }).then(() => {
      this.props.match.params.stage &&
        listAllTenders(this.props.match.params.stage);
      this.props.readAction(formData.id);
      setNotification(__('tenders.properties.saved'));
    });
  }

  @autobind
  _onChangeBidderStatus(status) {
    const {
      data: { id, bidders },
    } = this.props;

    updateBidderStatus(id, _.first(bidders).id, { status }).then(() => {
      hideAlertbox();

      setNotification(__('tenders.properties.saved'));

      readTender(id);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    const { collapsed_tabs, highlighted_tab } = this.state;

    const { data, readAction, user_id } = this.props;

    const is_administrator = _.isObject(
      _.find(data?.administrators, ['id', user_id])
    );
    const is_follower = _.isObject(_.find(data?.followers, ['id', user_id]));

    const files =
      is_administrator || is_follower
        ? _.filter(data?.files, (file) => file.is_admin)
        : data?.files;

    const files_size = _.size(files);

    // BIDDER VIEW
    const my_files_size = data?.is_bidder && _.size(data.my_files);

    // ADMIN VIEW
    const bidders_size = _.size(data?.bidders);

    const bidder_files_size = _.size(
      _.filter(data?.files, (file) => !file.is_admin)
    );

    const TabLink = ScrollLink((props) => (
      <span
        ref={(ref) => (this.tab_refs[props.target] = ref)}
        onClick={(e) => {
          this.setState({
            collapsed_tabs: _.reject(
              collapsed_tabs,
              (section) => section == props.target
            ),
          });

          this._highlightSection(props.target);

          props.onClick(e);
        }}
        className={props.className}
      >
        {props.children}
      </span>
    ));

    const Tab = (props) => (
      <TabLink
        target={props.target}
        activeClass={styles.active}
        to={props.target}
        spy={true}
        smooth={true}
        duration={500}
        container={this.refs.scroll}
      >
        {props.children}
      </TabLink>
    );

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>
              <strong>{'B-' + _.padStart(data?.identifier, 4, '0')}:</strong>
              {_.get(
                data?.title,
                _.findKey(
                  _.get(data, 'project_languages'),
                  (primary) => primary == 1
                )
              )}
            </span>
            <CloseBigIcon
              onClick={() =>
                setTimeout(() => {
                  this.props.actions.setTableParams({
                    clicked_row: undefined,
                  });
                }, 100)
              }
            />
          </div>
          {/* <div className={styles.actions}>
            {is_administrator && (
              <div className={styles.actionWrapper}>
                <Tooltip text={__('tenders.tooltip.icon.send-tender-report')}>
                  <SendIcon
                    onClick={(e) => {
                      e.stopPropagation();

                      setTableParams('tenders', {
                        send_tender_report_wizard: true,
                        tender_id: data.id,
                        send: true,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            )}
            <div className={styles.actionWrapper}>
              <Tooltip text={__('tenders.tooltip.icon.print-tender-report')}>
                <PrintIcon
                  onClick={(e) => {
                    e.stopPropagation();

                    setTableParams('tenders', {
                      print_tender_report_wizard: true,
                      tender_id: data.id,
                      tender_status: data.status,
                      send: false,
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div> */}
        </div>
        <div className={styles.toolbar}>
          <EditTenderToolbarForm
            data={data}
            onSubmit={this._handleSubmit}
            changeBidderStatus={this._onChangeBidderStatus}
            initialValues={{
              ..._.pick(data, ['id', 'status', 'decision_status']),
              date: {
                start: data?.start_date,
                end: data?.end_date,
                allDay: data?.all_day,
              },
            }}
          />
        </div>

        <div className={styles.tabsWrapper}>
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapseAll,
              _.size(collapsed_tabs) == _.size(this.tabs) && styles.collapsed
            )}
            onClick={this._toggleCollapseTabs}
          />
          <div className={styles.tabs}>
            <Tab target='properties'>{__('tender.flyout.properties')}</Tab>
            <Tab target='bidders'>
              {__('tender.flyout.bidders')}
              {!!bidders_size && ' (' + bidders_size + ')'}
            </Tab>
            <Tab target='files'>
              {__('tender.flyout.files')}
              {!!files_size && ' (' + files_size + ')'}
            </Tab>

            {data?.is_bidder && (
              <Tab target='my-files'>
                {__('tender.flyout.my-files')}
                {!!my_files_size && ' (' + my_files_size + ')'}
              </Tab>
            )}
            {!data?.is_bidder && (
              <Tab target='bidder_files'>
                {__('tender.flyout.files_bidders')}
                {!!bidder_files_size && ' (' + bidder_files_size + ')'}
              </Tab>
            )}
          </div>
        </div>

        <div className={styles.content} ref='scroll'>
          <Element
            name='properties'
            className={classNames(
              styles.section,
              highlighted_tab == 'properties' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('properties')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'properties') && styles.collapsed
                )}
              />
              {__('tender.flyout.properties')}
            </div>
            <Properties
              data={data}
              readAction={readAction}
              collapsed={_.includes(collapsed_tabs, 'properties')}
            />
          </Element>
          <Element
            name='bidders'
            className={classNames(
              styles.section,
              highlighted_tab == 'bidders' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('bidders')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'bidders') && styles.collapsed
                )}
              />
              {__('tender.flyout.bidders')}
              {!!bidders_size && ' (' + bidders_size + ')'}
            </div>
            <Bidders
              collapsed={_.includes(collapsed_tabs, 'bidders')}
              data={data}
              readAction={readAction}
            />
          </Element>
          <Element
            name='files'
            className={classNames(
              styles.section,
              highlighted_tab == 'files' && styles.highlighted
            )}
          >
            <div
              className={styles.title}
              onClick={() => this._toggleCollapseTab('files')}
            >
              <ArrowDownMiddleIcon
                className={classNames(
                  styles.collapse,
                  _.includes(collapsed_tabs, 'files') && styles.collapsed
                )}
              />
              {data?.is_bidder
                ? __('tender.flyout.tender_files')
                : __('tender.flyout.files')}
              {!!files_size && ' (' + files_size + ')'}
            </div>

            <Files
              collapsed={_.includes(collapsed_tabs, 'files')}
              data={data}
              files={files}
              disabled={
                !is_administrator ||
                _.includes(['closed', 'canceled', 'finished'], data.status)
              }
              readAction={readAction}
            />
          </Element>

          {data?.is_bidder && (
            <Element
              name='my-files'
              className={classNames(
                styles.section,
                highlighted_tab == 'my-files' && styles.highlighted
              )}
            >
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseTab('my-files')}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    _.includes(collapsed_tabs, 'my-files') && styles.collapsed
                  )}
                />
                {__('tender.flyout.my-files')}
                {!!my_files_size && ' (' + my_files_size + ')'}
              </div>

              <Files
                collapsed={_.includes(collapsed_tabs, 'my-files')}
                data={data}
                files={data.my_files}
                disabled={_.includes(
                  ['closed', 'canceled', 'finished'],
                  data.status
                )}
                readAction={readAction}
              />
            </Element>
          )}

          {!data?.is_bidder && (
            <>
              <Element
                name='bidder_files'
                className={classNames(
                  styles.section,
                  highlighted_tab == 'bidder_files' && styles.highlighted
                )}
              >
                <div
                  className={styles.title}
                  onClick={() => this._toggleCollapseTab('bidder_files')}
                >
                  <ArrowDownMiddleIcon
                    className={classNames(
                      styles.collapse,
                      _.includes(collapsed_tabs, 'bidder_files') &&
                        styles.collapsed
                    )}
                  />
                  {__('tender.flyout.bidder-files')}
                  {!!bidder_files_size && ' (' + bidder_files_size + ')'}
                </div>
                <BidderFiles
                  collapsed={_.includes(collapsed_tabs, 'bidder_files')}
                  data={data}
                  readAction={readAction}
                />
              </Element>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default TendersFlyout;

// Treba postaviti indekse na kreirane tabele tendetes tenders members tenders files

// Treba uvesti autorizaciju za biddere i followere ko sta moze vidjeti

// treba takodje napraviti strukturu za fajlove za biddere

// statusi

// Slanje notifikacija za followere i biddere, kada se sta Salje

// da li se moze dodati i admin i bidder i follower u jednom momentu, trenutno se to moze uraditi u aplikaciji npr na taskovima, meetingu...
