import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { __ } from '../../core/utils';
import styles from '../../styles/forms/current_set_specifications/EditSpecificationToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import TaskStatusIcon from '../../assets/images/task-status-15x15.svg';
import classNames from 'classnames';
import * as _ from 'lodash';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';

@reduxForm({
  form: 'current_set_specifications.toolbar',
  enableReinitialize: true,
})
class CurrentSetSpecificationToolbarForm extends Component {
  render() {
    const statuses = [
      {
        value: 'active',
        label: __('current_set_specifications.status.active'),
        color: '#5777FC',
      },

      {
        value: 'canceled',
        label: __('current_set_specifications.status.canceled'),
        color: '#CCCDD1',
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <Field
          name='status'
          component={Select}
          label={__('Status')}
          options={statuses}
          noSort
          disabled
          dropdown
          top={34}
          left={-40}
          icon={<TaskStatusIcon className={styles.taskStatus} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip
              text={__('current_set_specifications.tooltip.icon.status')}
            >
              <div
                className={classNames(styles.dropdown)}
                style={{ background: activeItem.color }}
                onClick={handleOpen}
              >
                <TaskStatusIcon className={styles.taskStatus} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </Form>
    );
  }
}

export default CurrentSetSpecificationToolbarForm;
