import * as React from 'react';
import { Component } from 'react';
import { Field, focus, Form, getFormValues, reduxForm } from 'redux-form';
import { __, dateFrom, timeFrom } from '../../core/utils';
import styles from '../../styles/forms/meetings/EditMeetingToolbarForm.scss';
import Select from '../../components/Select';
import Tooltip from '../../components/Tooltip';
import TaskVisibilityIcon from '../../assets/images/task-visibility-15x15.svg';
import classNames from 'classnames';
import DateTimePicker from '../../components/DateTimePicker';
import DateIcon from '../../assets/images/date-15x15.svg';
import connect from 'react-redux/es/connect/connect';
import { enableAutosave } from '../../redux/actions/general/autosave';
import * as _ from 'lodash';
import MeetingStatusIcon from '../../assets/images/meeting-status-15x15.svg';
import ArrowDownSmallIcon from '../../assets/images/arrow-down-small-15x15.svg';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { removePlusActions } from '../../redux/actions/general/plus';

@subscribe(Localization, 'localization')
@reduxForm({
  form: 'meetings.edit_meeting_toolbar',
  enableReinitialize: true,
})
@connect(
  (state) => ({
    user_id: state.auth.id,
    values: getFormValues('meetings.edit_meeting_toolbar')(state),
    dropdown: state.general.dropdown.shown,
  }),
  (dispatch) => ({
    focus: (field) => {
      dispatch(focus('meetings.edit_meeting_toolbar', field));
    },
  })
)
class EditMeetingToolbarForm extends Component {
  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  @autobind
  _warnOnStatusChange(e, status) {
    e.preventDefault();

    let status_message;

    switch (status) {
      case 'minutes':
        status_message = __(
          'meetings.all-meetings.flyout.meeting-status-minutes.process-cannot-undone'
        );
        break;
      case 'review':
        status_message = __(
          'meetings.all-meetings.flyout.meeting-status-review.creating-meeting-minutes-notification-participants.process-cannot-undone'
        );
        break;
      case 'closed':
        status_message = __(
          'meetings.all-meetings.close-meeting.trigger-creating-minutes-report-sending-meeting.editing-disabled.process-undone'
        );
        break;
      case 'canceled':
        status_message = __(
          'meetings.all-meetings.flyout.cancel-this-meeting.editing-be-disabled.process-cannot-undone'
        );
        break;
    }

    showAlertbox({
      color: 'blue',
      title: __('general.alert.are-you-sure'),
      description: status_message,
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes'),
          onClick: () => {
            hideAlertbox();

            this.props.touch('status');
            this.props.change('status', status);
            this.props.blur('status');

            if (_.includes(['closed', 'canceled'], status)) {
              removePlusActions();
            }
          },
        },
      ],
    });
  }

  render() {
    const { data, user_id, values } = this.props;
    const { status } = values;
    const is_administrator = _.isObject(
      _.find(data.administrators, ['id', user_id])
    );

    const statuses = [
      {
        value: 'agenda',
        label: __('table.columns.meeting-status-agenda'),
        color: '#9C27B0',
        disabled: true,
      },
      {
        value: 'minutes',
        label: __('table.columns.meeting-status-minutes'),
        color: '#5777FC',
        disabled: !_.includes(['agenda'], status),
      },
      {
        value: 'review',
        label: __('table.columns.meeting-status-review'),
        color: '#ADD42A',
        disabled: !_.includes(['minutes'], status),
      },
      {
        value: 'closed',
        label: __('table.columns.meeting-status-closed'),
        color: '#009688',
        disabled: !_.includes(['minutes', 'review'], status),
      },
      {
        value: 'canceled',
        label: __('table.columns.meeting-status-canceled'),
        color: '#CCCDD1',
        disabled: !_.includes(['agenda', 'minutes', 'review'], status),
      },
    ];

    const visibilities = [
      {
        value: '0',
        label: __('meeting.visibility.private'),
        color: '#CCCCCA',
      },
      {
        value: '1',
        label: __('meeting.visibility.public'),
        color: '#029DFC',
      },
    ];

    const disabled =
      !is_administrator || _.includes(['closed', 'canceled'], status);

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <Field
          disabled={disabled}
          name='date'
          component={DateTimePicker}
          label={__('meetings.all-meetings.flyout.date-time')}
          footer
          dropdown
          time
          wrapper={(handleOpen, value) => (
            <Tooltip text={__('table.columns.meeting-date-time')}>
              <div
                className={classNames(styles.date, disabled && styles.disabled)}
                onClick={handleOpen}
              >
                <DateIcon /> {dateFrom(value)} &middot; {timeFrom(value)}
              </div>
            </Tooltip>
          )}
        />
        <Field
          disabled={disabled}
          name='status'
          component={Select}
          label={__('table.columns.meeting-status')}
          onChange={this._warnOnStatusChange}
          options={statuses}
          noSort
          dropdown
          top={40}
          icon={<MeetingStatusIcon className={styles.icon} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('meeting.tooltip.icon.status')}>
              <div
                className={classNames(
                  styles.dropdown,
                  disabled && styles.disabled
                )}
                style={
                  activeItem ? { background: activeItem.color } : undefined
                }
                onClick={handleOpen}
              >
                <MeetingStatusIcon className={styles.icon} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
        <Field
          disabled={disabled}
          name='visibility'
          component={Select}
          label={__('table.columns.meeting-visibility')}
          options={visibilities}
          noSort
          dropdown
          top={40}
          icon={<TaskVisibilityIcon className={styles.icon} />}
          wrapper={(handleOpen, activeItem) => (
            <Tooltip text={__('meeting.tooltip.icon.visibility')}>
              <div
                className={classNames(
                  styles.dropdown,
                  disabled && styles.disabled
                )}
                style={
                  activeItem ? { background: activeItem.color } : undefined
                }
                onClick={handleOpen}
              >
                <TaskVisibilityIcon className={styles.icon} />
                <div className={styles.button}>
                  {activeItem.label}
                  <ArrowDownSmallIcon />
                </div>
              </div>
            </Tooltip>
          )}
        />
      </Form>
    );
  }
}

export default EditMeetingToolbarForm;
