import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import styles from '../../styles/forms/print_working_set_specifications_wizard/PrintWorkingSetSpecificationsForm.scss';
import Button from '../../components/Button';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import {
  __,
  getActiveProject,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import Checkbox from '../../components/Checkbox';
import ButtonGroup from '../../components/ButtonGroup';
import Select from '../../components/Select';
import { readProjectGeneral } from '../../redux/actions/projects';
import DateTimePicker from '../../components/DateTimePicker';
import classNames from 'classnames';
import { getCountOfSpecifications } from '../../redux/actions/table/working_set_specifications';

@reduxForm({
  form: 'print_working_set_specifications_wizard.print_working_set_specifications',
})
@subscribe(Localization, 'localization')
@withRouter
@mapStateToProps((state) => ({
  values: getFormValues(
    'print_working_set_specifications_wizard.print_working_set_specifications'
  )(state),
  specifications: _.get(
    state.table,
    'working_set_specifications.specifications'
  ),
}))
class PrintWorkingSetSpecificationsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languages: null,
      count_of_selected_specifications: false,
      count_of_total_table_items: 0,
    };
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject())
      .then(({ response }) => {
        this.setState({
          languages: [
            response.data.main_language,
            ...response.data.additional_languages,
          ],
        });
      })
      .then(() =>
        getCountOfSpecifications(this.props.match.params.stage, {
          type_of_specification: 'working',
        }).then(({ response }) =>
          this.setState({ count_of_total_table_items: response })
        )
      );
  }

  render() {
    const project_languages = _.map(this.state.languages, (language_id) => ({
      label: _.get(this.props.localization.languages, [language_id, 'name']),
      value: language_id,
    }));

    const { count_of_total_table_items } = this.state;
    const { specification_ids, specifications } = this.props.initialValues;

    const types = [
      {
        label: `${__(
          'specifications.working-set.print-specifications.print-selected'
        )} (${specification_ids.length})`,
        value: 'print_selected',
      },
      {
        label: `${__(
          'specifications.working-set.print-specifications.print-all'
        )} (${count_of_total_table_items})`,
        value: 'print_all',
      },
    ];

    return (
      <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='specifications'
              component={Select}
              label={__(
                'specifications.working-set.print-specifications.print-type'
              )}
              options={
                specifications === 'print_all'
                  ? types.filter((type) => {
                      return type.value !== 'print_selected';
                    })
                  : types
              }
            />
          </div>

          <div className={styles.f1} />
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <div className={styles.title}>
              {__(
                'specifications.working-set.print-specifications.select-elements'
              )}
            </div>
            <div className={styles.options}>
              <Field
                name='cover'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications.cover-page'
                )}
              />
              <Field
                name='contents_simple'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications.table-of-contents-simple'
                )}
              />
              <Field
                name='contents_detailed'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications.table-of-contents-detailed'
                )}
              />
              <Field
                name='header'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications-header'
                )}
              />
              <Field
                name='footer'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications-footer'
                )}
              />
              <Field
                name='model'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications-model'
                )}
              />
              <Field
                name='manufacturer'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications-manufacturer'
                )}
              />
              <Field
                name='representative'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications-representative'
                )}
              />
              {specifications !== 'print_all' && (
                <Field
                  name='print_linked_specifications'
                  component={Checkbox}
                  label={__(
                    'specifications.working-set.print-linked-specifications'
                  )}
                />
              )}
              <Field
                name='print_specification_quantities'
                component={Checkbox}
                label={__(
                  'specifications.working-set.print-specifications-quantities'
                )}
              />
            </div>
          </div>
          <div className={styles.f1}>
            <div className={classNames(styles.title, styles.settings)}>
              {__(
                'specifications.working-set.print-specifications.define-settings'
              )}
            </div>
            <Field
              name='language_id'
              component={Select}
              noSort
              label={__('print-working-set-specifications-form.language')}
              searchable
              options={project_languages}
            />
            <Field
              name='date'
              component={DateTimePicker}
              label={__('print-working-set-specifications-form.date')}
              footer
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            lightGray
            medium
            left
            middleText={__('button.cancel')}
            onClick={() =>
              setTableParams('working_set_specifications', {
                print_specifications_wizard: undefined,
              })
            }
          />
          <ButtonGroup>
            <Button
              lightBlue
              medium
              submit
              middleText={__('print-working-set-specifications-form.generate')}
              spinning={this.props.submitting}
            />
          </ButtonGroup>
        </div>
      </Form>
    );
  }
}

export default PrintWorkingSetSpecificationsForm;
