import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/Images.scss';
import * as _ from 'lodash';
import classNames from 'classnames';

import { __, mapStateToProps } from '../../core/utils';
import { showLightbox } from '../../redux/actions/general/lightbox';
import { ReactSortable } from 'react-sortablejs';

@mapStateToProps((state) => ({
  flyout: state.table.current_set_specifications.flyout,
}))
class Images extends Component {
  render() {
    const { data } = this.props;

    const images = _.sortBy(data, 'order');

    const lightbox_images = _.map(data, (image) => ({
      id: image.id,
      stream_url: image.stream_url,
      title: [image.name, image.ext].join('.'),
    }));

    return (
      <ReactSortable
        disabled={true}
        list={images.concat([{}])}
        setList={() => {}}
        animation={200}
        delayOnTouchStart={true}
        handle={'.' + styles.thumbnail}
        onMove={(event) => !event.related.classList.contains(styles.add)}
        className={styles.content}
      >
        {_.map(images, (image, i) => (
          <div
            key={i}
            data-id={image.id}
            className={styles.thumbnail}
            onClick={() => showLightbox(lightbox_images, image.id)}
            style={{ backgroundImage: "url('" + image.thumb_url + "')" }}
          />
        ))}
        <div className={classNames(styles.add, styles.disabled)} />
      </ReactSortable>
    );
  }
}

export default Images;
