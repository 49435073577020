import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {enableAutosave} from "../../redux/actions/general/autosave";
import Input from "../../components/Input";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import {__, mapStateToProps} from "../../core/utils";
import * as _ from "lodash";
import styles from "../../styles/forms/rooms/EditRoomForm.scss";
import Tags from "../../components/Tags";
import Dropzone from "../../components/Dropzone";

@reduxForm({
    form: "brands.edit_brand",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    auth: state.auth
}))
class EditBrandForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const {handleSubmit, auth, collectionTags, familyTags} = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Field name="name" disabled={!_.includes(["administrator", "owner"], auth.role)} component={Input} label={__('company-settings.submenu.brands.flyout.name')} />
                <div className={styles.flex}>
                    <div className={styles.f1}>
                        <Field name="collection" availableText={'Available Collections'} component={Tags} label={__('company-settings.submenu.brands.flyout.collection')} options={collectionTags} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                    </div>
                    <div className={styles.f1}>
                        <Field name="family" availableText={'Available Families'} component={Tags} label={__('company-settings.submenu.brands.flyout.family')} options={familyTags} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                    </div>
                </div>
                <Field name="phone" component={Input} label={__('company-settings.submenu.brands.flyout.phone')} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                <Field name="web" component={Input} label={__('company-settings.submenu.brands.flyout.web')} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                <Field name="address" component={Input} label={__('company-settings.submenu.brands.flyout.address')} disabled={!_.includes(["administrator", "owner"], auth.role)} />
                <Field name="logo" component={Dropzone} item={__('company-settings.submenu.brands.flyout.logo')} accept="image/png,image/jpeg" disabled={!_.includes(["administrator", "owner"], auth.role)} />
            </Form>
        );
    }
}

export default EditBrandForm;
