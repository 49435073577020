import store from "../../../core/store";

export const listMySpecificationDeliveries = (stage_id) => {
    return store.dispatch({
        action: "table.my_specification_deliveries/LIST_MY_SPECIFICATION_DELIVERIES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/specification_deliveries/my",
        main: true
    });
};

export const sendSpecificationDeliveries = (specification_delivery_ids) => {
    return store.dispatch({
        action: "table.my_specification_deliveries/SEND_SPECIFICATION_DELIVERIES",
        method: "POST",
        endpoint: "/specification_deliveries/my/send",
        data: {
            specification_deliveries: specification_delivery_ids
        }
    });
};