import * as React from "react";
import { Component } from "react";
import MainLayout from "../../layouts/MainLayout";
import Table from "../../components/Table";
import { __, getURLParam, mapStateToProps, setTableParams, getLocalized } from "../../core/utils";
import {createSidebar, createSuperadminSidebar} from "../../redux/actions/general/sidebar";
import { listActiveModules } from "../../redux/actions/general/active_modules";
import { listMaterials } from "../../redux/actions/table/superadmin/materials";
import Tooltip from "../../components/Tooltip";
import * as _ from "lodash";
import { setPlusActions } from "../../redux/actions/general/plus";
import DeleteIcon from "../../assets/images/delete-15x15.svg";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";
import {
	deleteRawMaterials,
	listRawMaterials,
	readRawMaterial
} from "../../redux/actions/table/superadmin/raw_materials";
import RawMaterialsFlyout from "../../flyouts/superadmin/raw_materials/RawMaterialsFlyout";
import TableNA from "../../components/TableNA";

@mapStateToProps(state => ({
	superadmin: state.auth.superadmin,
	table: state.table["superadmin_raw_materials"],
	application_language_id: state.auth.language_id
}))
class RawMaterials extends Component {
	constructor(props) {
		super(props);

		this.state = {
			preview: null,
		};
	}

	componentDidMount() {
		listActiveModules();
		createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

		const { superadmin } = this.props;

		superadmin.add_raw_material && setPlusActions([{
			title: "Add filter material",
			onClick: () => setTableParams('superadmin_raw_materials', {
				add_raw_material_wizard: true
			})
		}]);
	}

	componentDidUpdate(prevProps) {
		if (this.props.table.clicked_row && this.props.table.clicked_row !== prevProps.table.clicked_row) {
			if (!prevProps.table.clicked_row) {
				this.setState({ preview: undefined });
			}

			readRawMaterial(this.props.table.clicked_row).then(({ response }) => {
				this.setState({ preview: response.data });
			});
		}
	}

	render() {
		const { superadmin, auth, application_language_id } = this.props;

		const columns = [
			{
				key: "id",
				name: "ID",
				width: 60,
				sortable: false
			},
			{
				key: "name",
				name: "Name",
				value: name => {
					const localized_name = getLocalized(name, application_language_id);
					return <Tooltip text={localized_name}>{localized_name}</Tooltip>
				}
			},
			{
				key: "priority",
				name: "Priority",
				width: 60,
			},
			{
				key: "materials",
				name: "Attached materials",
				width: 200,
				value: materials => {
					const joined_materials = _.join(materials, ', ');

					return _.isEmpty(materials) ? <TableNA /> : <Tooltip text={joined_materials}>{joined_materials}</Tooltip>;
				},
				sortable: false
			},
		];

		const singleActions = (raw_material_id) => {
			return [
				<Tooltip text="Delete selected filter material" key={_.uniqueId()}>
					<DeleteIcon
						onClick={e => {
							e.stopPropagation();

							showAlertbox({
								color: "red",
								title: __('general.alert.are-you-sure'),
								description: "Do you really want to delete the selected filter material?\n\nThis action will detach all attached materials.\n\nThis process cannot be undone.",
								buttons: [
									{
										color: 'lightGray',
										text: __('general.alert.no-close'),
										onClick: 'close'
									},
									{
										color: 'gray',
										text: __('general.alert.yes-delete'),
										onClick: () => {
											deleteRawMaterials([raw_material_id]).then(() => {
												hideAlertbox();

												setNotification("The selected filter material has been deleted.");
												listRawMaterials();

												setTableParams('superadmin_raw_materials', {
													actioned_row: undefined
												});
											});
										},
									}
								]
							})
						}}
					/>
				</Tooltip>
			];
		};

		const groupActions = (raw_materials_ids) => [
			<Tooltip text="Delete selected filter materials" key={_.uniqueId()}>
				<DeleteIcon
					onClick={e => {
						e.stopPropagation();

						showAlertbox({
							color: "red",
							title: __('general.alert.are-you-sure'),
							description: "Do you really want to delete these filter materials?\n\nThis action will detach all attached materials.\n\nThis process cannot be undone.",
							buttons: [
								{
									color: 'lightGray',
									text: __('general.alert.no-close'),
									onClick: 'close'
								},
								{
									color: 'gray',
									text: __('general.alert.yes-delete'),
									onClick: () => {
										deleteRawMaterials(raw_materials_ids).then(() => {
											hideAlertbox();

											setNotification("The selected filter materials have been deleted.");
											listRawMaterials();

											setTableParams('superadmin_raw_materials', {
												selected_rows: []
											});
										});
									},
								}
							]
						})
					}}
				/>
			</Tooltip>
		];

		const preview = (
			<RawMaterialsFlyout
				data={this.state.preview}
				readAction={raw_material_id => {
					return new Promise(resolve => {
						readRawMaterial(getURLParam('id') || raw_material_id).then(({ response }) => {
							this.setState({ preview: response.data });

							resolve(response);
						});
					});
				}}
			/>
		);

		return (
			<Table
				listAction={listRawMaterials}
				name="superadmin_raw_materials"
				groupActions={superadmin.delete_raw_material && groupActions}
				singleActions={superadmin.delete_raw_material && singleActions}
				title={__("modules.submodules.title.filter-materials")}
				preview={this.state.preview ? preview : []}
				columns={columns}
			/>
		);
	}
}

export default RawMaterials;
