import React, { useState } from 'react';
import styles from '../styles/wizards/ImportSpreadsheetWizard.scss';
import {
  __,
  resetForm,
  setFormErrors,
  setTableParams,
  validateForm,
} from '../core/utils';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import ImportSpreadsheetBasicAttributesForm from '../forms/import_spreadsheet_wizard/ImportSpreadsheetBasicAttributesForm';
import _ from 'lodash';
import { read, utils, ws } from 'xlsx';
import { connect } from 'react-redux';
import ImportSpreadsheetDetailsForm from '../forms/import_spreadsheet_wizard/ImportSpreadsheetDetailsForm';
import { getFormValues } from 'redux-form';
import classNames from 'classnames';

const handleImport = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;

      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 });

        const range = utils.decode_range(wb.Sheets[sheets[0]]['!ref'] || 'A1');
        const columns_count = range.e.c + 1;

        return resolve({ rows, columns_count });
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

const validateImportSpreadsheet = function (formData) {
  return new Promise((resolve, reject) => {
    const errors = {};
    ['module', 'data_structure', 'file'].forEach((key) => {
      if (_.isEmpty(formData[key])) {
        errors[key] = true;
      }
    });

    if (!_.isEmpty(errors)) {
      return reject(errors);
    }

    return resolve();
  });
};

const importSpreadsheet = function (formData) {
  return new Promise((resolve, reject) => {
    handleImport(formData.file)
      .then((data) => {
        return resolve(data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

const ImportSpreadsheetWizard = ({
  actions,
  basic_attributes,
  detail_attributes,
  is_boq_page,
  table,
}) => {
  const data_structures = [
    {
      value: 'linear',
      label: __('wizard.import-spreadsheet.data_structures.linear'),
    },
    {
      value: 'hierarchy',
      label: __('wizard.import-spreadsheet.data_structures.hierarchy'),
    },
  ];

  const steps = [
    {
      id: '01',
      title: __(
        'wizard.import-spreadsheet.title.import_spreadsheet.basic_attributes'
      ),
    },
    {
      id: '02',
      title: __('wizard.import-spreadsheet.title.import_spreadsheet.details'),
    },
  ];

  const [active, set_active_step] = useState(_.first(steps));

  const _setActiveStep = (id) => {
    set_active_step(_.find(steps, (item) => item.id === id));
  };

  const _handleSubmitBasicAttributes = (formData) => {
    const validation = validateForm(
      validateImportSpreadsheet(formData)
        .then(() => {
          if (formData.module != 'bill_of_quantities') return _handleSubmit();

          _setActiveStep('02');
        })
        .catch((err) => {
          setFormErrors(
            'import_spreadsheet_wizard.import_spreadsheet_basic_attributes',
            err
          );
        })
    );

    return validation;
  };

  const _handleSubmit = () => {
    importSpreadsheet(basic_attributes).then(({ rows, columns_count }) => {
      setTableParams('import_spreadsheet', {
        module: basic_attributes.module,
        data_structure: basic_attributes.data_structure,
        import_type: detail_attributes?.import_type,
        language_id: detail_attributes?.language_id,
        wizard_active: undefined,
        columns_count,
        rows: rows,
      });

      resetForm(
        'import_spreadsheet_wizard.import_spreadsheet_basic_attributes'
      );
    });
  };

  const modules = is_boq_page
    ? [
        {
          value: 'bill_of_quantities',
          label: __('import-spreadsheet.module.bill_of_quantities'),
          hidden_data_structure: 'linear',
        },
      ]
    : [
        {
          value: 'area',
          label: __('import-spreadsheet.module.area'),
        },
        // {
        //   value: 'task',
        //   label: __('import-spreadsheet.module.task'),
        // },
        // {
        //   value: 'bill_of_quantities',
        //   label: __('import-spreadsheet.module.bill_of_quantities'),
        //   hidden_data_structure: 'linear',
        // },
        // {
        //   value: 'address_book',
        //   label: __('import-spreadsheet.module.address_book'),
        // },
      ];

  return (
    <>
      <div className={styles.header}>
        <div className={styles.titleBar}>
          <div className={styles.left}>
            <span>{__('wizard.import-spreadsheet.title')}</span>
            <span>{__('wizard.import-spreadsheet.subtitle')}</span>
          </div>
          <div className={styles.right}>
            <span>{active.id}</span>
            <span>{_.last(steps).id}</span>
          </div>
        </div>
        <div className={styles.steps}>
          {_.map(steps, (step, i) => (
            <div
              key={i}
              className={classNames(
                styles.step,
                active.id == step.id && styles.active,
                step.id < active.id && styles.completed
              )}
            >
              <span>{step.id}</span>
              <span>{step.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.body}>
        {active.id === '01' && (
          <ImportSpreadsheetBasicAttributesForm
            onSubmit={_handleSubmitBasicAttributes}
            modules={modules}
            data_structures={data_structures}
            initialValues={{
              module: is_boq_page ? 'bill_of_quantities' : 'area',
              data_structure: is_boq_page ? 'hierarchy' : undefined,
            }}
          />
        )}

        {active.id === '02' && (
          <ImportSpreadsheetDetailsForm
            onBack={() => _setActiveStep('01')}
            onSubmit={_handleSubmit}
            modules={modules}
            data_structures={data_structures}
            initialValues={{
              import_type: 'singleline',
              language_id: table.language_id,
            }}
          />
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    table: state.table['import_spreadsheet'],
    is_boq_page: state.table.import_spreadsheet.is_boq_page,
    basic_attributes: getFormValues(
      'import_spreadsheet_wizard.import_spreadsheet_basic_attributes'
    )(state),
    detail_attributes: getFormValues(
      'import_spreadsheet_wizard.import_spreadsheet_details'
    )(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(subscribe(Localization, 'localization')(ImportSpreadsheetWizard));
