import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/products/Variants.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import SettingsIcon from '../../assets/images/settings-15x15.svg';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../../core/utils';
import Tooltip from '../../components/Tooltip';
import { subscribe } from 'react-contextual';
import { SubmissionError } from 'redux-form';
import Localization from '../../helpers/Localization';
import autobind from 'autobind-decorator';
import {
  addProductVariant,
  readProduct,
  updateProductVariantValues,
} from '../../redux/actions/table/products';
import VariantRowForm from '../../forms/products/VariantRowForm';
import {
  listMyCompanyColors,
  listMyCompanyMaterials,
} from '../../redux/actions/companies';
import { setNotification } from '../../redux/actions/general/notification';
import {
  listPlanUploads,
  updatePlanUpload,
} from '../../redux/actions/table/plan_uploads';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import {
  deactivateTeamMember,
  listAllTeams,
} from '../../redux/actions/table/teams';

@mapStateToProps((state) => ({
  store: state.table.products,
  application_language_id: state.auth.language_id,
}))
@subscribe(Localization, 'localization')
class Variants extends Component {
  state = {
    my_company_colors: [],
    my_company_materials: [],
  };

  componentDidMount() {
    listMyCompanyColors().then(({ response }) => {
      this.setState({ my_company_colors: response.data });
    });

    listMyCompanyMaterials().then(({ response }) => {
      this.setState({ my_company_materials: response.data });
    });
  }

  @autobind
  _addProductVariant() {
    const { flyout } = this.props.store;

    addProductVariant(flyout.id).then(() =>
      readProduct(flyout.id).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
        });
      })
    );
  }

  _getColorNameAndHex = (id) => {
    const color =
      _.get(this.props.localization.colors, [id]) ||
      _.find(this.state.my_company_colors, (color) => color.id == id);

    return {
      label:
        (color?.name &&
          getLocalized(color?.name, this.props.store.language_id)) +
        (color?.code ? ` · ${color.code}` : ''),
      hex: color?.hex,
    };
  };

  _getMaterialNameAndThumbnailUrl = (id) => {
    const material =
      _.get(this.props.localization.materials, [id]) ||
      _.find(this.state.my_company_materials, (material) => material.id == id);

    return {
      label:
        material?.name &&
        getLocalized(material?.name, this.props.store.language_id),
      thumbnailUrl: material?.thumbnailUrl,
    };
  };

  _getTextureNameAndThumbnailUrl = (id) => {
    const texture = _.get(this.props.localization.textures, id) || {};

    return {
      label: [
        texture.code,
        getLocalized(texture.name, this.props.store.language_id),
      ].join(' '),
      thumbnailUrl: texture?.thumbnailUrl,
    };
  };

  @autobind
  _handleUpdateVariantRow(variant_id, formData) {
    const request_id = _.uniqueId('request');

    this.last_request_id = request_id;

    const data = _.reduce(
      formData,
      (postData, value_id, field) => {
        postData['field_values'][_.replace(field, 'attribute-', '')] = value_id;

        return postData;
      },
      {
        field_values: {},
      }
    );

    const request = updateProductVariantValues(variant_id, data);

    const validation = request.then(() => {
      if (request_id === this.last_request_id) {
        readProduct(this.props.store.flyout.id).then(({ response }) => {
          setNotification(__('products.variants.variant-values-updated'));

          setTableParams('products', {
            flyout: response.data,
          });
        });
      }
    });

    return validation.catch(({ response, status }) => {
      if (request_id === this.last_request_id) {
        if (status === 422 && 'errors' in response) {
          setNotification({
            text: __('products.variants.notifications.values-not-saved'),
            type: 'warning',
          });

          // throw new SubmissionError(
          //     _.transform(response.errors, (errors, error, field) => {
          //         _.set(errors, field, error);
          //     }, {})
          // );
        }
      }
    });
  }

  render() {
    const { collapsed, store, application_language_id } = this.props;
    const { flyout, language_id } = store;
    const { product_attribute_fields, units } = this.props.localization;

    const product_variant_attributes = _.sortBy(
      _.transform(
        flyout.attribute_groups,
        (attribute_fields, group) => {
          _.each(
            _.filter(group.attributes, (attribute) =>
              _.includes(flyout.variant_attributes, attribute.id)
            ),
            (attribute) => {
              const currentAttribute = attribute.template_field_id
                ? _.get(
                    product_attribute_fields,
                    attribute.template_field_id,
                    {}
                  )
                : attribute;
              const type = currentAttribute.type;
              let label = getLocalized(
                currentAttribute.label,
                application_language_id
              );
              label +=
                (attribute.unit_id || currentAttribute.unit_id) &&
                _.includes(['numeric', 'range', 'range_selectable'], type)
                  ? ' (' + _.get(units, [attribute.unit_id, 'symbol']) + ')'
                  : '';
              label += !attribute.template_field_id ? '*' : '';

              /**
               * Options
               */
              let custom_variations = _.map(
                _.reject(
                  attribute.values,
                  (value) => _.size(value.value) === 0 || !value.in_use
                ),
                (value) => ({
                  id: value.id,
                  value: value.value,
                  unit_id: attribute.unit_id,
                })
              );

              const part_variations = _.transform(
                attribute.parts,
                (variations, part) => {
                  _.each(
                    part.values,
                    (value) =>
                      _.size(value.value) > 0 &&
                      value.in_use &&
                      value.inherit_in_use &&
                      variations.push({
                        id: value.id,
                        value: value.value,
                        unit_id: part.unit_id,
                      })
                  );
                },
                []
              );

              const values = [...custom_variations, ...part_variations];

              const options = _.map(values, ({ id, value, unit_id }) => {
                const option = {
                  value: id,
                };

                let unit = '';

                if (
                  _.includes(
                    [
                      'numeric',
                      'dropdown_numeric',
                      'dropdown_numeric_multiple',
                      'range',
                      'range_selectable',
                    ],
                    type
                  ) &&
                  unit_id
                ) {
                  unit = ' ' + _.get(units, [unit_id, 'symbol']);
                }

                switch (type) {
                  case 'boolean':
                    option.label = _.toInteger(value)
                      ? __('products.attributes-form.boolean.yes')
                      : __('products.attributes-form.boolean.no');
                    break;
                  case 'dropdown_string_multiple':
                    option.label = _.map(JSON.parse(value), (one_value) => {
                      return getLocalized(
                        _.get(
                          _.find(
                            _.flatten(
                              _.map(
                                this.props.localization
                                  .product_attribute_fields,
                                'options'
                              )
                            ),
                            ['id', _.parseInt(one_value)]
                          ),
                          'value'
                        ),
                        language_id
                      );
                    }).join(', ');
                    break;
                  case 'dropdown_numeric_multiple':
                    option.label = _.map(JSON.parse(value), (one_value) => {
                      return (
                        getLocalized(
                          _.get(
                            _.find(
                              _.flatten(
                                _.map(
                                  this.props.localization
                                    .product_attribute_fields,
                                  'options'
                                )
                              ),
                              ['id', _.parseInt(one_value)]
                            ),
                            'value'
                          ),
                          language_id
                        ) + unit
                      );
                    }).join(', ');
                    break;
                  case 'dropdown_string':
                    option.label = getLocalized(
                      _.get(
                        _.find(
                          _.flatten(
                            _.map(
                              this.props.localization.product_attribute_fields,
                              'options'
                            )
                          ),
                          ['id', _.parseInt(value)]
                        ),
                        'value'
                      ),
                      language_id
                    );
                    break;
                  case 'dropdown_numeric':
                    option.label =
                      getLocalized(
                        _.get(
                          _.find(
                            _.flatten(
                              _.map(
                                this.props.localization
                                  .product_attribute_fields,
                                'options'
                              )
                            ),
                            ['id', _.parseInt(value)]
                          ),
                          'value'
                        ),
                        language_id
                      ) + unit;
                    break;
                  case 'string':
                    option.label = getLocalized(value, language_id);
                    break;
                  case 'numeric':
                    option.label = value + unit;
                    break;
                  case 'range':
                    option.label = _.join(value, ' ~ ') + unit;
                    break;
                  case 'range_selectable':
                    option.label = _.join(value, ' ~ ') + unit;
                    break;
                  case 'color':
                    option.label = this._getColorNameAndHex(value).label;
                    break;
                  case 'material':
                    option.label =
                      this._getMaterialNameAndThumbnailUrl(value).label;
                    break;
                  case 'texture':
                    option.label =
                      this._getTextureNameAndThumbnailUrl(value).label;
                    break;
                }

                return option;
              });

              attribute_fields.push({
                id: attribute.id,
                label,
                groupPriority: group.priority,
                group: getLocalized(group.name, application_language_id),
                options,
              });
            }
          );
        },
        []
      ),
      'groupPriority'
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.isEmpty(flyout.variant_attributes) ? (
          <div className={styles.empty}>
            {__('products.variants.no-product-variant-attributes-selected')}
          </div>
        ) : (
          <>
            <div className={styles.header}>
              {_.map(product_variant_attributes, (variant_attribute, i) => (
                <span key={i} className={styles.attribute}>
                  <Tooltip
                    text={_.filter(
                      [variant_attribute.label, variant_attribute.group],
                      _.identity
                    ).join(' / ')}
                  >
                    {variant_attribute.label}
                  </Tooltip>
                </span>
              ))}
              <span className={styles.actions} />
            </div>
            {_.map(flyout.variants, (variant) => (
              <VariantRowForm
                key={variant.id}
                onSubmit={(formData) =>
                  this._handleUpdateVariantRow(variant.id, formData)
                }
                form={'products.variants.variant' + variant.id}
                attributes={product_variant_attributes}
                variant={variant}
                initialValues={_.transform(
                  variant.values,
                  (
                    initialValues,
                    product_attribute_group_field_value_id,
                    product_attribute_group_field_id
                  ) => {
                    initialValues[
                      `attribute-${product_attribute_group_field_id}`
                    ] = product_attribute_group_field_value_id;
                  },
                  {}
                )}
              />
            ))}
          </>
        )}
        <div className={styles.addRow}>
          {!_.isEmpty(flyout.variant_attributes) && (
            <button onClick={this._addProductVariant}>
              {__('product.variants.button.add-new-variant')}
            </button>
          )}
          <button
            onClick={() =>
              showAlertbox({
                color: 'red',
                title: __('general.alert.are-you-sure'),
                description:
                  'Configuring variant attributes will result in a removal of all variants entered.',
                buttons: [
                  {
                    color: 'lightGray',
                    text: 'No',
                    onClick: 'close',
                  },
                  {
                    color: 'gray',
                    text: 'Yes',
                    onClick: () => {
                      hideAlertbox();

                      setTimeout(() => {
                        setTableParams('products', {
                          change_product_variant_attributes_wizard: true,
                        });
                      }, 100);
                    },
                  },
                ],
              })
            }
          >
            <SettingsIcon />
            {__('product.variants.button.configure-variant-attributes')}
          </button>
        </div>
      </div>
    );
  }
}

export default Variants;
