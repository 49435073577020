import store from "../../../../core/store";

export const listColors = () => {
	return store.dispatch({
		action: "table.superadmin_colors/LIST_COLORS",
		method: "GET",
		endpoint: "/superadmin/colors",
		main: true
	});
};

export const createColor = (formData) => {
	return store.dispatch({
		action: "table.superadmin_colors/CREATE_COLOR",
		method: "POST",
		endpoint: "/superadmin/colors",
		data: formData
	});
};

export const deleteColors = (color_ids) => {
	return store.dispatch({
		action: "table.superadmin_colors/DELETE_COLORS",
		method: "DELETE",
		endpoint: "/superadmin/colors",
		data: {
			colors: color_ids
		}
	});
};

export const readColor = (color_id) => {
	return store.dispatch({
		action: "table.superadmin_colors/READ_COLOR",
		method: "GET",
		endpoint: "/superadmin/colors/" + color_id,
		has404: true
	});
};

export const updateColor = (color_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_colors/UPDATE_COLOR",
		method: "PUT",
		endpoint: "/superadmin/colors/" + color_id,
		data: formData
	});
};