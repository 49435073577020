import {deleteToken, toBoolean} from "../../core/utils";

const initialState = {};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "auth/GET_USER_SUCCESS":
			let data = {
				...state,
				...action.response.data,
				loggedIn: true
			};

			//cast superadmin role actions to boolean - you can customize the cast in the _.transform()
			if(action.response.data.superadmin) {
				data["superadmin"] = _.transform(action.response.data.superadmin, (actions, value, action) => {
					actions[action] = toBoolean(value);
				}, {});
			}

			return data;
		case "auth/GET_USER_FAILURE":
			return {
				loggedIn: false
			};
		case "auth/LOGOUT_SUCCESS":
			deleteToken();

			sessionStorage.removeItem("invitation_type");
			sessionStorage.removeItem("token");

			location.reload();

			return state;
		case "auth/SET_NOTIFICATION_COUNT":
			return {
                ...state,
                unread_notifications: action.count
			};
		case "companies/CREATE_ADDITIONAL_INFO_SUCCESS":
			return {
				...state,
				company: {
					...state.company,
					additional_info: true
				}
			};
		default:
			return state;
	}
}
