import * as React from "react";
import { Component } from "react";
import { Field, FieldArray, Form, reduxForm } from "redux-form";
import styles from "../../styles/forms/add_address_book_contact_wizard/AddAddressBookContactForm.scss";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { __, mapStateToProps, setTableParams, transformToListPairs } from "../../core/utils";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import ButtonGroup from "../../components/ButtonGroup";
import Textarea from "../../components/Textarea";
import Checkbox from "../../components/Checkbox";
import Select from "../../components/Select";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import { listPossibleAddressBookCompanies } from "../../redux/actions/table/address_book_contacts";
import PlusMiddleIcon from "../../assets/images/plus-middle-15x15.svg";
import MinusMiddleIcon from "../../assets/images/minus-middle-15x15.svg";
import MultiSelect from "../../components/MultiSelect";
import * as _ from "lodash";
import people_addressing from "../../lists/people_addressing";
import Tags from "../../components/Tags";
import { listPossibleTags } from "../../redux/actions/general";
import { createAddressBookCompany } from "../../redux/actions/table/address_book_companies";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({ form: "add_address_book_contact_wizard.add_address_book_contact" })
@mapStateToProps((state) => ({
	auth: state.auth
}))
class AddAddressBookContactForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			possible_address_book_companies: [],
			possible_tags: []
		};
	}

	componentDidMount() {
		listPossibleAddressBookCompanies().then(({ response }) => {
			this.setState({ possible_address_book_companies: response.data });
		});

		listPossibleTags("address_book").then(({ response }) => {
			this.setState({ possible_tags: response.data });
		});
	}

	@autobind
	_handleSubmit(edit) {
		this.props.change("edit", edit);

		setTimeout(() => this.props.submit(), 10);
	}

	render_emails = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("companies-and-users.address-book.contacts.add-new-contact.email") + " " + (index + 1) : __("companies-and-users.address-book.contacts.add-new-contact.email")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	render_phones = ({ fields }) =>
		fields.map((field, index) => (
			<Field
				key={index}
				name={field}
				component={Input}
				label={fields.length > 1 ? __("companies-and-users.address-book.contacts.add-new-contact.phone") + " " + (index + 1) : __("companies-and-users.address-book.contacts.add-new-contact.phone")}
				rightIcon={index + 1 === fields.length ? PlusMiddleIcon : MinusMiddleIcon}
				onRightIconClick={() => (index + 1 === fields.length ? fields.push("") : fields.remove(index))}
			/>
		));

	@autobind
	_createNewCompany(value) {
		createAddressBookCompany({ name: value }).then(({ response }) => {
			listPossibleAddressBookCompanies()
				.then(({ response }) => {
					this.setState({ possible_address_book_companies: response.data });
				})
				.then(() => {
					this.props.change("address_book_company_id", _.get(response.data, "id"));
				});
		});
	}

	render() {
		const { possible_address_book_companies } = this.state;

		const available_disciplines = _.sortBy(this.props.localization.disciplines, "priority");

		return (
			<Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
				<div className={styles.form}>
					<div className={styles.flex}>
						<div className={styles.f1}>
							<Field name="title" component={Select} label={__("companies-and-users.address-book.contacts.add-new-contact.title")} options={people_addressing} />
						</div>
						<div className={styles.f4}>
							<Field name="firstname" component={Input} label={__("companies-and-users.address-book.contacts.add-new-contact.first-name")} />
						</div>
						<div className={styles.f4}>
							<Field name="lastname" component={Input} label={__("companies-and-users.address-book.contacts.add-new-contact.last-name")} />
						</div>
					</div>
					<div className={styles.flex}>
						<div className={styles.f1}>
							<Field
								name="address_book_company_id"
								component={Select}
								redirectTo={(id) => {
									redirect(`/address_book/companies?id=${id}`);
								}}
								callbackSetNewValue={(value) => {
									this._createNewCompany(value);
								}}
								searchable
								label={__("companies-and-users.address-book.contacts.add-new-contact.company")}
								options={transformToListPairs(possible_address_book_companies)}
							/>
						</div>
						<div className={styles.f1}>
							<Field name="disciplines" noSort component={MultiSelect} searchable label={__("companies-and-users.address-book.contacts.add-new-contact.profession")} options={transformToListPairs(available_disciplines, "profession")} />
						</div>
					</div>
					<div className={styles.flex}>
						<div className={styles.f1}>
							<FieldArray name="emails" component={this.render_emails} />
						</div>
						<div className={styles.f1}>
							<FieldArray name="phones" component={this.render_phones} />
						</div>
						<div className={styles.f1}>
							<div className={styles.roles}>
								<Field name="is_designer" component={Checkbox} label={__("companies-and-users.address-book.contacts.add-new-contact.designer")} />
								<Field name="is_client" component={Checkbox} label={__("companies-and-users.address-book.contacts.add-new-contact.client")} />
							</div>
						</div>
					</div>
					<Field name="tags" component={Tags} label={__("companies-and-users.address-book.contacts.add-new-contact.tags")} options={this.state.possible_tags} />
					<Field name="note" component={Textarea} label={__("companies-and-users.address-book.contacts.add-new-contact.note")} maxLength={2000} rows={1} maxRows={10} />
				</div>
				<div className={styles.footer}>
					<Button
						lightGray
						medium
						left
						middleText={__("button.cancel")}
						onClick={() =>
							setTableParams("address_book_contacts", {
								wizard_active: undefined
							})
						}
					/>
					<ButtonGroup>
						<Button lightBlue medium middleText={__("button.create")} spinning={this.props.submitting} onClick={() => this._handleSubmit(false)} />
						<Button lightBlue medium middleText={__("button.create-edit")} spinning={this.props.submitting} onClick={() => this._handleSubmit(true)} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

export default AddAddressBookContactForm;
