import * as React from "react";
import { Component } from "react";
import { Field, Form, FieldArray, reduxForm } from "redux-form";
import { mapStateToProps, redirect } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import styles from "../../../styles/forms/product_attribute_template_groups/EditProductAttributeTemplateGroupForm.scss";
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from "../../../assets/images/grabber-16x16.svg";
import classNames from "classnames";

@reduxForm({
    form: "superadmin_product_attribute_template_groups.edit_product_attribute_template_group",
    enableReinitialize: true,
})
@withRouter
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
}))
@subscribe(Localization, "localization")
class EditProductAttributeTemplateGroupForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render_fields = ({ fields }) => {
        return (
            <ReactSortable
                className={styles.option}
                list={fields.getAll() || []}
                setList={() => { }}
                onEnd={({ oldIndex, newIndex }) => {
                    fields.move(oldIndex, newIndex);
                    this.props.submit();
                }}
                animation={200}
                delayOnTouchStart={true}
                handle={'.' + styles.grabber}
            >
                {fields.map((field, i) => (
                    <div key={field} className={styles.optionGroup}>
                        <div className={styles.grabber}>
                            <GrabberIcon />
                        </div>
                        <div className={classNames(styles.flex, styles.row)}>
                            <Field name={field + '.label'} component={props =>
                                <span onClick={() => redirect('/superadmin/product_attribute_template_fields?id=' + fields.get(i)?.id)}>
                                    {props.input.value}
                                </span>
                            } />
                            <Field name={field + '.priority'} component="input" type="hidden" />
                        </div>
                    </div>
                ))}
            </ReactSortable>
        )
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        const languages = _.transform(_.filter(this.props.localization.languages, ['application_language', 1]), (languages, language) => {
            languages[language.id] = language.id == 1;
        }, {});

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_product_attribute_template_group} name="name" component={Input} label="Name" localized={languages} />
                <Field disabled={!superadmin.edit_product_attribute_template_group} name="priority" component={Input} label="Priority" numeric />
                {superadmin.list_product_attribute_template_groups && <>
                    <div className={styles.label}>Attributes list</div>
                    <div className={styles.attributes}>
                        <FieldArray name="fields" component={this.render_fields} />
                    </div>
                </>}
            </Form>
        );
    }
}

export default EditProductAttributeTemplateGroupForm;