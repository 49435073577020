import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/tasks/Comments.scss';
import {
  __,
  dateTimeFrom,
  linkifyText,
  scrollToBottom,
  setTableParams,
} from '../../core/utils';
import connect from 'react-redux/es/connect/connect';
import SendIcon from '../../assets/images/send-16x16.svg';
import { setNotification } from '../../redux/actions/general/notification';
import {
  addTaskComment,
  deleteCommentFile,
  deleteTaskFile,
  listAllTasks,
  updateTaskComment,
} from '../../redux/actions/table/all_tasks';
import autobind from 'autobind-decorator';
import classNames from 'classnames';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import TextareaAutosize from 'react-autosize-textarea';
import EditIcon from '../../assets/images/edit-16x16.svg';
import moment from 'moment';
import Tooltip from '../../components/Tooltip';
import Avatar from '../../components/Avatar';
import DocumentExtIcon from '../../assets/images/document-ext-30x30.svg';
import CloseSmallIcon from '../../assets/images/close-small-15x15.svg';
import { showLightbox } from '../../redux/actions/general/lightbox';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import AttachmentsIcon from '../../assets/images/attachments-16x16.svg';
import Image from '../../components/Image';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    table: _.get(state.table, 'all_tasks'),
    clicked_row: _.get(state.table, ['all_tasks', 'clicked_row']),
    wizard_active: _.get(state.table, ['all_tasks', 'wizard_active']),
  };
};

@withRouter
@connect(mapStateToProps, null)
class Comments extends Component {
  constructor(props) {
    super(props);

    this.message = React.createRef();

    this.refresher = null;

    this.state = {
      clipboardFiles: [],
      storagedFiles: [],
    };
  }

  @autobind
  _handleSubmit() {
    const message = this.message.current.value;
    const files = this.state.clipboardFiles;
    const { data, table } = this.props;

    if (_.isEmpty(message)) {
      setNotification({
        text: 'Please fill in the comment.',
        type: 'warning',
      });
    } else {
      if (table.update_comment) {
        updateTaskComment(table.update_comment, message, files)
          .then(() => {
            setNotification(__('tasks.notification.comment.updated'));

            this.props.match.params.stage &&
              listAllTasks(this.props.match.params.stage);

            this.props.readAction(data.id).then(() => {
              scrollToBottom(this.refs.scroll);
            });

            setTableParams('all_tasks', {
              update_comment: undefined,
              ['new_comment_' + data.id]: '',
            });
          })
          .catch(({ response }) => {
            if (_.has(response, 'code')) {
              if (response.code === 1) {
                setNotification({
                  text: 'You can only edit the latest comment. Your edit has been discarded.',
                  type: 'warning',
                });
              }

              if (response.code === 2) {
                setNotification({
                  text: 'You can edit your comment only within the first 15 minutes. Your edit has been discarded.',
                  type: 'warning',
                });
              }

              setTableParams('all_tasks', {
                update_comment: undefined,
              });

              this.props.match.params.stage &&
                listAllTasks(this.props.match.params.stage);

              this.props.readAction(data.id).then(() => {
                scrollToBottom(this.refs.scroll);
              });
            } else {
              setNotification({
                text: __('tasks.files.upload.upload-limit'),
                type: 'warning',
              });
            }
          });
      } else {
        addTaskComment(data.id, message, files)
          .then(() => {
            setNotification(__('tasks.notification.comment.created'));

            this.props.match.params.stage &&
              listAllTasks(this.props.match.params.stage);

            this.props.readAction(data.id).then(() => {
              scrollToBottom(this.refs.scroll);
            });

            setTableParams('all_tasks', {
              ['new_comment_' + data.id]: '',
            });
          })
          .catch(() => {
            setNotification({
              text: __('tasks.files.upload.upload-limit'),
              type: 'warning',
            });
          });
      }

      this.message.current.value = '';

      this.setState({ clipboardFiles: [], storagedFiles: [] });
    }
  }

  componentDidMount() {
    scrollToBottom(this.refs.scroll);

    if (this.message.current) {
      this.message.current.value = _.get(
        this.props,
        'table.new_comment_' + this.props.data.id,
        ''
      );
    }

    this.refresher && clearInterval(this.refresher);

    setInterval(
      () =>
        this.setState({
          uniqueId: _.uniqueId(),
        }),
      _.get(this.props, 'interval', 1000)
    );
  }

  componentWillUnmount() {
    this.refresher && clearInterval(this.refresher);
  }

  @autobind
  _handleOnPaste(event) {
    const { wizard_active, clicked_row } = this.props;

    if (clicked_row && !wizard_active) {
      let files = event.clipboardData.files;

      this.setState({
        clipboardFiles: [
          ...this.state.clipboardFiles,
          ..._.map(files, (file) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file),
            });
          }),
        ],
      });
    }
  }

  @autobind
  _downloadFile(row, download = false) {
    if (row.ext == 'pdf' && download == false) {
      window.open(row.stream_url, '_blank');
    } else {
      setNotification(__('tasks.files.download.continue'));

      window.open(row.download_url, '_blank');
    }
  }

  render() {
    const { data, auth, table, collapsed } = this.props;
    const { is_editor, is_reviewer } = data;
    const { clipboardFiles, storagedFiles } = this.state;

    const clipboard_lightbox_images = _.filter(
      _.map(
        clipboardFiles,
        (file, i) =>
          _.includes(
            ['image/jpg', 'image/jpeg', 'image/png'],
            _.toLower(file.type)
          ) && {
            id: ++i,
            stream_url: URL.createObjectURL(file),
            title: file.name,
          }
      )
    );

    const storaged_lightbox_images = _.map(
      _.filter([...storagedFiles], (file) =>
        _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
      ),
      (file, i) => ({
        id: file.id,
        stream_url: file.stream_url,
        title: [file.name, file.ext].join('.'),
      })
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {/* {_.isEmpty(data.comments) ? (
          <div className={styles.empty}>
            {__('tasks.all-tasks.flyout.comments.there-are-no-comments')}.
          </div>
        ) : (
          ''
        )} */}
        <div className={styles.scroller} ref='scroll'>
          {_.map(data.comments, (row, i) => {
            const lightbox_images = _.map(
              _.filter(row.files, (file) =>
                _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
              ),
              (file) => ({
                id: file.id,
                stream_url: file.stream_url,
                title: [file.name, file.ext].join('.'),
              })
            );

            return (
              <div
                className={classNames(
                  styles.row,
                  table.update_comment == row.id && styles.updating
                )}
                key={i}
              >
                <div className={styles.heading}>
                  <Avatar
                    size={30}
                    avatar_url={row.user.avatar_url}
                    name={row.user.fullname}
                    active={row.user.active}
                  />
                  <div className={styles.head}>
                    <span>{row.user.fullname}</span> ·{' '}
                    {dateTimeFrom(row.updated_at)}
                    {!table.update_comment &&
                      !_.includes(['closed', 'canceled'], data.status) &&
                      i == _.size(data.comments) - 1 &&
                      row.user.id == auth.id &&
                      moment.utc().diff(moment.utc(row.updated_at), 'minutes') <
                        15 && (
                        <Tooltip text='Edit comment (only within the first 15 minutes)'>
                          <EditIcon
                            onClick={() => {
                              setTableParams('all_tasks', {
                                update_comment: row.id,
                              });

                              if (this.message.current) {
                                this.message.current.value = row.message;
                                this.message.current.focus();
                              }

                              this.setState({
                                storagedFiles: row.files,
                              });
                            }}
                          />
                        </Tooltip>
                      )}
                    {!(
                      !table.update_comment &&
                      !_.includes(['closed', 'canceled'], data.status) &&
                      i == _.size(data.comments) - 1 &&
                      moment.utc().diff(moment.utc(row.updated_at), 'minutes') <
                        15
                    ) &&
                      row.user.id == auth.id && (
                        <Tooltip text='You can edit your comment only within the first 15 minutes'>
                          <EditIcon className={styles.disabled} />
                        </Tooltip>
                      )}
                  </div>
                </div>
                <div className={classNames(styles.f1, styles.content)}>
                  <span className={styles.message}>
                    {linkifyText(row.message)}
                  </span>

                  <div className={styles.files}>
                    {_.map(row.files, (file, i) =>
                      !_.includes(['jpg', 'jpeg', 'png'], file.ext) ? (
                        <Tooltip text={file.name} key={i}>
                          <div
                            className={styles.icon}
                            onClick={() => this._downloadFile(file)}
                          >
                            <DocumentExtIcon
                              className={
                                styles[
                                  'color' +
                                    (_.sumBy(
                                      _.toLower(file.ext).split(''),
                                      (item) => item.charCodeAt(0)
                                    ) %
                                      5)
                                ]
                              }
                            />
                            <span>{file.ext.substring(0, 4)}</span>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          placement='left'
                          text={
                            <Image
                              className={styles.thumbPreview}
                              src={file.stream_url}
                            />
                          }
                          html={true}
                          dark={true}
                        >
                          <div
                            key={i}
                            className={styles.thumbnail}
                            onClick={() =>
                              showLightbox(lightbox_images, file.id)
                            }
                            style={{
                              backgroundImage: "url('" + file.stream_url + "')",
                            }}
                          />
                        </Tooltip>
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {(is_editor || is_reviewer) &&
          !_.includes(['closed', 'canceled'], data.status) && (
            <div className={styles.addComment}>
              <Avatar size={30} avatar_url={auth.avatar_url} />
              <div className={classNames(styles.f1, styles.content)}>
                <TextareaAutosize
                  rows={1}
                  maxRows={10}
                  ref={this.message}
                  onBlur={(e) =>
                    !table.update_comment &&
                    setTableParams('all_tasks', {
                      ['new_comment_' + data.id]: e.target.value,
                    })
                  }
                  placeholder={__('tasks.button.comment.add-comment')}
                  maxLength={5000}
                  onPaste={this._handleOnPaste}
                />
                {(!_.isEmpty(clipboardFiles) ||
                  (table.update_comment && !_.isEmpty(storagedFiles))) && (
                  <div className={styles.files}>
                    {table.update_comment &&
                      !_.isEmpty(storagedFiles) &&
                      _.map(storagedFiles, (file, i) =>
                        !_.includes(['jpg', 'jpeg', 'png'], file.ext) ? (
                          <Tooltip text={file.name} key={i}>
                            <div
                              className={styles.icon}
                              onClick={() => this._downloadFile(file)}
                            >
                              <DocumentExtIcon
                                className={
                                  styles[
                                    'color' +
                                      (_.sumBy(
                                        _.toLower(file.ext).split(''),
                                        (item) => item.charCodeAt(0)
                                      ) %
                                        5)
                                  ]
                                }
                              />
                              <span>{file.ext.substring(0, 4)}</span>

                              <div className={styles.remove}>
                                <CloseSmallIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    showAlertbox({
                                      color: 'red',
                                      title: __('general.alert.are-you-sure'),
                                      description: __(
                                        'files.general.alert.want-delete-file-attachment-comment.process-cannot-undone'
                                      ),
                                      buttons: [
                                        {
                                          color: 'lightGray',
                                          text: __('general.alert.no-close'),
                                          onClick: 'close',
                                        },
                                        {
                                          color: 'gray',
                                          text: __('general.alert.yes-delete'),
                                          onClick: () => {
                                            deleteCommentFile(
                                              table.update_comment,
                                              file.id
                                            ).then(() => {
                                              hideAlertbox();

                                              setNotification(
                                                __(
                                                  'files.notification.selected-file-attachment-deleted-comment'
                                                )
                                              );

                                              this.props.readAction(data.id);

                                              this.setState({
                                                storagedFiles: _.filter(
                                                  this.state.storagedFiles,
                                                  (storagedFile) =>
                                                    storagedFile.id !== file.id
                                                ),
                                              });
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement='left'
                            text={
                              <Image
                                className={styles.thumbPreview}
                                src={file.stream_url}
                              />
                            }
                            html={true}
                            dark={true}
                            key={i}
                          >
                            <div
                              className={styles.thumbnail}
                              onClick={() =>
                                showLightbox(
                                  [
                                    ...storaged_lightbox_images,
                                    ...clipboard_lightbox_images,
                                  ],
                                  file.id
                                )
                              }
                              style={{
                                backgroundImage:
                                  "url('" + file.stream_url + "')",
                              }}
                            >
                              <CloseSmallIcon
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  showAlertbox({
                                    color: 'red',
                                    title: __('general.alert.are-you-sure'),
                                    description: __(
                                      'files.general.alert.want-delete-file-attachment-comment.process-cannot-undone'
                                    ),
                                    buttons: [
                                      {
                                        color: 'lightGray',
                                        text: __('general.alert.no-close'),
                                        onClick: 'close',
                                      },
                                      {
                                        color: 'gray',
                                        text: __('general.alert.yes-delete'),
                                        onClick: () => {
                                          deleteCommentFile(
                                            table.update_comment,
                                            file.id
                                          ).then(() => {
                                            hideAlertbox();

                                            setNotification(
                                              __(
                                                'files.notification.selected-file-attachment-deleted-comment'
                                              )
                                            );

                                            this.props.readAction(data.id);

                                            this.setState({
                                              storagedFiles: _.filter(
                                                this.state.storagedFiles,
                                                (storagedFile) =>
                                                  storagedFile.id !== file.id
                                              ),
                                            });
                                          });
                                        },
                                      },
                                    ],
                                  });
                                }}
                              />
                            </div>
                          </Tooltip>
                        )
                      )}
                    {!_.isEmpty(clipboardFiles) &&
                      _.map(clipboardFiles, (clipboardFile, i) => {
                        return !_.includes(
                          ['image/jpg', 'image/jpeg', 'image/png'],
                          clipboardFile.type
                        ) ? (
                          <Tooltip text={clipboardFile.name} key={i}>
                            <div className={styles.icon} onClick={() => {}}>
                              <DocumentExtIcon
                                className={
                                  styles[
                                    'color' +
                                      (_.sumBy(
                                        _.toLower(clipboardFile.type).split(
                                          '/'
                                        ),
                                        (item) => item.charCodeAt(0)
                                      ) %
                                        5)
                                  ]
                                }
                              />
                              <span>{clipboardFile.name.split('.').pop()}</span>

                              <div className={styles.remove}>
                                <CloseSmallIcon
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.setState({
                                      clipboardFiles: _.filter(
                                        Object.values(clipboardFiles),
                                        (file) => {
                                          return file != clipboardFile;
                                        }
                                      ),
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            placement='left'
                            text={
                              <Image
                                className={styles.thumbPreview}
                                src={clipboardFile.preview}
                              />
                            }
                            html={true}
                            dark={true}
                            key={i}
                          >
                            <div
                              className={styles.thumbnail}
                              onClick={() =>
                                showLightbox(
                                  [
                                    ...storaged_lightbox_images,
                                    ...clipboard_lightbox_images,
                                  ],
                                  ++i
                                )
                              }
                              style={{
                                backgroundImage:
                                  "url('" + clipboardFile.preview + "')",
                              }}
                            >
                              <CloseSmallIcon
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  this.setState({
                                    clipboardFiles: _.filter(
                                      Object.values(clipboardFiles),
                                      (file) => {
                                        return file != clipboardFile;
                                      }
                                    ),
                                  });
                                }}
                              />
                            </div>
                          </Tooltip>
                        );
                      })}
                  </div>
                )}
              </div>

              <div className={styles.addRow}>
                <Tooltip
                  text={
                    _.isEmpty(this.message?.current?.value)
                      ? __(
                          'tasks.all-tasks.flyout.comments.upload-files-from-computer'
                        )
                      : ''
                  }
                >
                  <div
                    className={styles.attachment}
                    onClick={() => this.refs['file_input'].click()}
                  >
                    <AttachmentsIcon />
                  </div>
                </Tooltip>

                <input
                  type='file'
                  ref={'file_input'}
                  multiple={true}
                  onChange={(e) =>
                    this.setState({
                      clipboardFiles: [
                        ...this.state.clipboardFiles,
                        ..._.map(e.target.files, (file) => {
                          return Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          });
                        }),
                      ],
                    })
                  }
                />
              </div>

              {!table.update_comment && (
                <Tooltip
                  text={
                    _.isEmpty(this.message?.current?.value)
                      ? __(
                          'tasks.all-tasks.flyout.comments.disabled-send-button'
                        )
                      : ''
                  }
                >
                  <div
                    className={classNames(
                      styles.button,
                      _.isEmpty(this.message?.current?.value) && [
                        styles.gray,
                        styles.disabled,
                      ]
                    )}
                    onClick={
                      !_.isEmpty(this.message?.current?.value) &&
                      this._handleSubmit
                    }
                  >
                    <SendIcon />
                  </div>
                </Tooltip>
              )}

              {table.update_comment && (
                <>
                  <div
                    className={classNames(styles.button, styles.gray)}
                    onClick={() => {
                      setTableParams('all_tasks', {
                        update_comment: undefined,
                      });

                      this.message.current.value = '';

                      this.setState({
                        storagedFiles: [],
                      });
                    }}
                  >
                    Cancel
                  </div>
                  <div className={styles.button} onClick={this._handleSubmit}>
                    Update
                  </div>
                </>
              )}
            </div>
          )}
      </div>
    );
  }
}

export default Comments;
