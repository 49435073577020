import * as React from 'react';
import { Component } from 'react';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/working_set_specifications/TagsForm.scss';
import {
  __,
  getActiveStage,
  mapStateToProps,
  resetForm,
} from '../../core/utils';
import classNames from 'classnames';
import Tags from '../../components/Tags';
import { readStage } from '../../redux/actions/table/stages';
import { listPossibleTags } from '../../redux/actions/general';

@reduxForm({
  form: 'working_set_specifications.tags',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
class TagsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specification_tags: [],
    };
  }

  UNSAFE_componentWillReceiveProps() {
    enableAutosave(this.props.form);
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, 'store.flyout.id') !=
      _.get(this.props, 'store.flyout.id')
    ) {
      resetForm('working_set_specifications.tags');
    }
  }

  componentDidMount() {
    const project_id = this.props.store.flyout.project_id;

    listPossibleTags('project', project_id).then(({ response }) => {
      this.setState({ specification_tags: response.data });
    });
  }

  render() {
    const { handleSubmit, collapsed, store, readOnly } = this.props;

    return (
      <Form
        onSubmit={handleSubmit}
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        <Field
          disabled={readOnly}
          key={store.flyout.id}
          name='tags'
          component={Tags}
          label={__('working-set-specifications.tags-form.tags')}
          options={this.state.specification_tags}
        />
      </Form>
    );
  }
}

export default TagsForm;
