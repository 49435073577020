import * as React from "react";
import {Component} from "react";
import styles from "../../../styles/flyouts/products/read_only/Price.scss";
import * as _ from "lodash";
import classNames from "classnames";
import ArrowDownMiddleIcon from "../../../assets/images/arrow-down-middle-15x15.svg";
import autobind from "autobind-decorator";
import EditIcon from "../../../assets/images/edit-16x16.svg";
import {
    __,
    dateFrom,
    getLocalized,
    mapStateToProps,
    setTableParams,
    toBoolean,
    validateForm
} from "../../../core/utils";
import {subscribe} from "react-contextual";
import Localization from "../../../helpers/Localization";
import Tooltip from "../../../components/Tooltip";

@mapStateToProps(state => ({
    store: state.table.products,
    application_language_id: state.auth.language_id
}))
@subscribe(Localization, "localization")
class Price extends Component {
    render() {
        const {collapsed, store} = this.props;
        const {flyout, language_id} = store;
        const {currencies, units} = this.props.localization;

        return (
            <div className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                <div className={styles.priceRow}>
                    {(flyout.price_from || flyout.price_to) && <div className={styles.price}>
                        <strong className={styles.priceFrom}>{__('products.all-products.flyout.price')}</strong>
                        {flyout.price_from && <span>{flyout.price_from}</span>}
                        {flyout.price_from && flyout.price_to && <strong className={styles.priceTo}>-</strong>}
                        {flyout.price_to && <span>{flyout.price_to}</span>}
                    </div>}
                    <Tooltip text={_.get(currencies, [flyout.currency_id, 'name'])}>
                        {(flyout.price_from || flyout.price_to) && flyout.currency_id && <div className={styles.price}>
                            <span className={styles.currency}>{_.get(currencies, [flyout.currency_id, 'code'])}</span>
                        </div>}
                    </Tooltip>
                </div>
                {_.size(flyout.units) > 0 && <div className={styles.row}>
                    <strong>{__('products.all-products.flyout.units')}</strong>
                    <span>{_.map(flyout.units, unit_id => units[unit_id].symbol).join(', ')}</span>
                </div>}
                {flyout.price_date && <div className={styles.row}>
                    <strong>{__('products.all-products.flyout.price-date')}</strong>
                    <span>{dateFrom(flyout.price_date, true)}</span>
                </div>}
                {_.size(getLocalized(flyout.price_note, language_id)) > 0 && <div className={styles.row}>
                    <strong>{__('products.all-products.flyout.price-instruction')}</strong>
                    <span className={styles.multiline}>{getLocalized(flyout.price_note, language_id)}</span>
                </div>}
                {toBoolean(flyout.discontinued) && <div className={styles.row}>
                    <strong>{__('products.all-products.flyout.discontinued')}</strong>
                    <span className={styles.redChip}>Yes</span>
                </div>}
            </div>
        );
    }
}

export default Price;
