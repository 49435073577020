import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/notifications/NotificationsFlyoutSkeleton.scss';
import * as _ from 'lodash';
import { connect } from 'react-redux';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import classNames from 'classnames';

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setTableParams: (params) =>
        dispatch({
          type: 'table.notifications/SET_TABLE_PARAMS',
          params,
        }),
    },
  };
};

@connect(null, mapDispatchToProps)
class NotificationsFlyoutSkeleton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => {
        return {
          count: prevState.count + 1,
        };
      });
    }, 600);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  _renderNotificationBody = (count) => {
    return _.map(Array(count).fill(0), (item, i) => {
      return (
        <div className={styles.row} key={i}>
          <Skeleton />

          <div className={styles.read} />

          <div className={styles.activityAndContent}>
            <Skeleton width={200} />
            <Skeleton width={30} />
          </div>

          <div className={styles.triggered_by}>
            <Skeleton width={24} height={24} />
          </div>

          <div className={styles.date_time}>
            <Skeleton count={2} width={60} />
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Skeleton baseColor='#202020' highlightColor='#fee' />
          </div>
          <div className={styles.actions}>
            <Skeleton width={150} baseColor='#202020' highlightColor='#fee' />
          </div>
        </div>
        <div className={styles.scroller}>
          <div className={styles.details}>
            <div className={classNames(styles.column, styles.f2)}>
              <div>
                <Skeleton />
              </div>
              <div>
                <Skeleton />
              </div>
              <div>
                <Skeleton />
              </div>
            </div>
          </div>
          <div className={styles.content}>
            {this._renderNotificationBody(this.state.count)}
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationsFlyoutSkeleton;
