import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/Files.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import { __, bytesToSize, mapStateToProps } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import FilePreview from '../../components/FilePreview';
import Images from './Images';

@mapStateToProps((state) => ({
  flyout: state.table.current_set_specifications.flyout,
}))
class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  @autobind
  _downloadFile({ download_url }) {
    setNotification(__('current-set-specifications.files.download.continue'));

    window.open(download_url, '_blank');
  }

  render() {
    const { collapsed, flyout } = this.props;
    const { expanded } = this.state;

    const categories = [
      {
        label: __('current-set-specifications.images.thumbnail'),
        value: 'thumbnail',
      },
      {
        label: __('current-set-specifications.images.product'),
        value: 'product',
      },
      {
        label: __('current-set-specifications.images.drawing'),
        value: 'drawing',
      },
      {
        label: __('current-set-specifications.images.ambient'),
        value: 'ambient',
      },
      {
        label: __('current-set-specifications.images.technical'),
        value: 'technical',
      },
      {
        label: __('current-set-specifications.images.tileable-texture-for-3d'),
        value: 'tileable',
      },
      {
        label: __('current-set-specifications.files.manuals'),
        value: 'manuals',
      },
      {
        label: __('current-set-specifications.files.guarantee'),
        value: 'guarantee',
      },
      {
        label: __('current-set-specifications.files.certificates'),
        value: 'certificates',
      },
      {
        label: __('current-set-specifications.files.catalogs'),
        value: 'catalogs',
      },
      {
        label: __('current-set-specifications.files.installation'),
        value: 'installation',
      },
      {
        label: __('current-set-specifications.files.bim'),
        value: 'bim',
      },
      {
        label: __('current-set-specifications.files.3d'),
        value: '3d',
      },
      {
        label: __('current-set-specifications.files.cad'),
        value: 'cad',
      },
      {
        label: __('current-set-specifications.files.2d'),
        value: '2d',
      },
      {
        label: __('current-set-specifications.files.light-data'),
        value: 'light_data',
      },
      {
        label: __('current-set-specifications.files.video'),
        value: 'video',
      },
      {
        label: __('current-set-specifications.files.audio'),
        value: 'audio',
      },
      {
        label: __('current-set-specifications.files.other'),
        value: 'other',
      },
    ];

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.map(categories, (category) => {
          const category_files = _.filter(
            [...flyout.images, ...flyout.files],
            ['category', category.value]
          );

          const lightbox_images = _.map(
            _.filter(category_files, (file) =>
              _.includes(['jpg', 'jpeg', 'png'], _.toLower(file.ext))
            ),
            (file) => ({
              id: file.id,
              stream_url: file.stream_url,
              title: [file.name, file.ext].join('.'),
            })
          );

          return _.isEmpty(category_files) ? (
            ''
          ) : (
            <div className={styles.category} key={category.value}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseCategory(category.value)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    !_.includes(expanded, category.value) && styles.collapsed
                  )}
                />
                {category.label}{' '}
                {!_.isEmpty(category_files) &&
                  '(' + _.size(category_files) + ')'}
              </div>
              {_.includes(expanded, category.value) &&
                (_.includes(
                  [
                    'thumbnail',
                    'product',
                    'drawing',
                    'ambient',
                    'technical',
                    'tileable',
                  ],
                  category.value
                ) ? (
                  // Change this component
                  <Images data={category_files} category={category.value} />
                ) : (
                  <>
                    {!_.isEmpty(category_files) && (
                      <div className={styles.files}>
                        {_.map(category_files, (file, i) => (
                          <div className={styles.file} key={i}>
                            <FilePreview
                              onDownloadFile={this._downloadFile}
                              row={file}
                              lightbox_images={lightbox_images}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ))}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Files;
