import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/rooms/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm, mapStateToProps } from '../../core/utils';
import { setNotification } from '../../redux/actions/general/notification';
import { listRooms, updateRoom } from '../../redux/actions/table/rooms';
import EditRoomForm from '../../forms/rooms/EditRoomForm';
import { listPossibleTags } from '../../redux/actions/general';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Localization from '../../helpers/Localization';
import { subscribe } from 'react-contextual';
import classNames from 'classnames';
import Specifications from './Specifications';
@subscribe(Localization, 'localization')
@withRouter
@mapStateToProps((state) => ({
  language_id: state.auth.language_id,
  store: state.table.rooms,
}))
class Properties extends Component {
  constructor(props) {
    super(props);

    this.state = {
      security_tags: [],
    };
  }

  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateRoom(formData.id, _.omit(formData, ['id'])).then(() => {
        this._loadTags();

        listRooms(this.props.match.params.stage);

        this.props.readAction(formData.id);

        setNotification(__('rooms.flyout.notification.saved'));
      })
    );
  }

  @autobind
  _loadTags() {
    listPossibleTags('room_security', this.props.store.flyout.project_id).then(
      ({ response }) => {
        this.setState({ security_tags: response.data });
      }
    );
  }

  componentDidMount() {
    this._loadTags();
  }

  render() {
    const { store } = this.props;
    const { security_tags } = this.state;
    const { flyout } = store;

    return (
      <div className={styles.scroller}>
        <EditRoomForm
          onSubmit={this._handleSubmit}
          data={flyout}
          securityTags={security_tags}
          initialValues={{
            ..._.pick(flyout, [
              'id',
              'code',
              'title',
              'description',
              'storey_id',
              'expansion_joints',
              'entrances',
              'zones',
              'area',
              'volume',
              'occupancy',
              'functional_spaces',
              'security',
              'room_group_id',
              'room_type_id',
              'requirement',
            ]),
            foh: _.toString(flyout.foh),
            outdoor: _.toString(flyout.outdoor),
          }}
        />

        {!_.isEmpty(flyout.specifications) && <Specifications />}
      </div>
    );
  }
}

export default Properties;
