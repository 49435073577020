import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/products/read_only/Images.scss';
import * as _ from 'lodash';
import { __, mapStateToProps } from '../../../core/utils';
import { showLightbox } from '../../../redux/actions/general/lightbox';

@mapStateToProps((state) => ({
  flyout: state.table.products.flyout,
}))
class Images extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  render() {
    const { data } = this.props;

    const lightbox_images = _.map(data, (image) => ({
      id: image.id,
      stream_url: image.stream_url,
      title: [image.name, image.ext].join('.'),
    }));

    return (
      <div className={styles.content}>
        {_.map(data, (image, i) => (
          <div
            key={i}
            className={styles.thumbnail}
            onClick={() => showLightbox(lightbox_images, image.id)}
            style={{ backgroundImage: "url('" + image.thumb_url + "')" }}
          />
        ))}
      </div>
    );
  }
}

export default Images;
