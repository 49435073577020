import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddEntranceWizard.scss";
import autobind from "autobind-decorator";
import AddEntranceIcon from "../assets/images/add-entrance-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {withRouter} from "react-router";
import {getStagesLanguages} from "../redux/actions/table/stages";
import {setNotification} from "../redux/actions/general/notification";
import {createEntrance, listEntrances} from "../redux/actions/table/entrances";
import AddEntranceForm from "../forms/add_entrance_wizard/AddEntranceForm";

@withRouter
@mapStateToProps(state => ({
	table: state.table["entrances"],
}))
class AddEntranceWizard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			project_languages: null
		}
	}

	componentDidMount() {
		getStagesLanguages(this.props.match.params.stage).then(({response}) => {
			this.setState({project_languages: response.data});
		});
	}

	@autobind
	_handleSubmit(formData) {
		const {edit} = formData;

		formData = _.omit(formData, ['edit']);

		return validateForm(
			createEntrance(this.props.match.params.stage, formData).then(({response}) => {
				listEntrances(this.props.match.params.stage).then(() => {
					if(edit) {
						setTableParams('entrances', {
							clicked_row: response.data.id
						});
					}
				});

				setTableParams('entrances', {
					actioned_row: undefined,
					selected_rows: []
				});

				this.props.onClose();

				setNotification(__("wizard.add-entrance.message.success"));
			})
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('wizard.add-entrance.title.add-new-entrance')}</span>
						<span>{__('wizard.add-entrance.subtitle.add-new-entrance')}</span>
					</div>
					<div className={styles.right}>
						<AddEntranceIcon />
					</div>
				</div>
				<div className={styles.body}>
					{this.state.project_languages && <AddEntranceForm
						projectLanguages={this.state.project_languages}
						onSubmit={this._handleSubmit}
						initialValues={{
							title: {},
							description: {},
						}}
					/>}
				</div>
			</>
		);
	}
}

export default AddEntranceWizard;