import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/products/Parts.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  getLocalized,
  redirect,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import Tooltip from '../../components/Tooltip';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import CloseMiddleIcon from '../../assets/images/close-middle-15x15.svg';
import autobind from 'autobind-decorator';
import {
  hideAlertbox,
  showAlertbox,
} from '../../redux/actions/general/alertbox';
import { setNotification } from '../../redux/actions/general/notification';
import {
  deleteProductPart,
  readProduct,
  reorderProductPartItems,
} from '../../redux/actions/table/products';
import TableNA from '../../components/TableNA';
import { ReactSortable } from 'react-sortablejs';
import GrabberIcon from '../../assets/images/grabber-16x16.svg';
import ArrowDownMiddleIcon from '../../assets/images/arrow-down-middle-15x15.svg';
import Image from '../../components/Image';
import DefaultImage from '../../assets/images/default.png';

@mapStateToProps((state) => ({
  application_language_id: state.auth.language_id,
  flyout: state.table.products.flyout,
}))
@subscribe(Localization, 'localization')
class Parts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
    };
  }

  @autobind
  _deletePart(e, part_id, category) {
    e.stopPropagation();

    showAlertbox({
      color: 'red',
      title: __('general.alert.are-you-sure'),
      description: __('products.parts.are-you-sure-delete-part'),
      buttons: [
        {
          color: 'lightGray',
          text: __('general.alert.no-close'),
          onClick: 'close',
        },
        {
          color: 'gray',
          text: __('general.alert.yes-delete'),
          onClick: () =>
            deleteProductPart(this.props.flyout.id, {
              part_id,
              category,
            }).then(() =>
              readProduct(this.props.flyout.id).then(({ response }) => {
                hideAlertbox();

                setNotification(__('products.parts.part-deleted'));

                setTableParams('products', {
                  flyout: response.data,
                });
              })
            ),
        },
      ],
    });
  }

  @autobind
  _changeOrder(category, items) {
    setTableParams('products', {
      flyout: {
        ...this.props.flyout,
        parts: _.map(this.props.flyout.parts, (item) => {
          return item.category == category
            ? {
                ...item,
                order: _.findIndex(items, ['id', item.id]),
              }
            : item;
        }),
      },
    });
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  render() {
    const { collapsed, flyout } = this.props;
    const { expanded } = this.state;

    const categories = [
      {
        label: __('products.parts.variator-parts'),
        value: 'variable',
      },
      {
        label: __('products.parts.mandatory-parts'),
        value: 'mandatory',
      },
      {
        label: __('products.parts.optional-parts'),
        value: 'optional',
      },
    ];

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.map(categories, (category) => {
          const sorted_list = _.sortBy(
            _.filter(flyout.parts, ['category', category.value]),
            'order'
          );

          return (
            <div className={styles.category} key={category.value}>
              <div
                className={styles.title}
                onClick={() => this._toggleCollapseCategory(category.value)}
              >
                <ArrowDownMiddleIcon
                  className={classNames(
                    styles.collapse,
                    !_.includes(expanded, category.value) && styles.collapsed
                  )}
                />
                {category.label}
              </div>
              {_.includes(expanded, category.value) && (
                <>
                  {_.isEmpty(sorted_list) ? (
                    <div className={styles.empty}>
                      {__('products.parts.no-parts')}
                    </div>
                  ) : (
                    <>
                      <div className={styles.header}>
                        <span className={styles.description}>
                          {__('products.parts.description')}
                        </span>
                        <span className={styles.actions} />
                      </div>
                      <ReactSortable
                        list={sorted_list}
                        setList={(list) =>
                          this._changeOrder(category.value, list)
                        }
                        onEnd={() =>
                          reorderProductPartItems(
                            flyout.id,
                            _.map(sorted_list, 'id')
                          ).then(() =>
                            readProduct(flyout.id).then(({ response }) => {
                              setTableParams('products', {
                                flyout: response.data,
                              });
                            })
                          )
                        }
                        animation={200}
                        delayOnTouchStart={true}
                        handle={'.' + styles.grabber}
                      >
                        {_.map(sorted_list, (part, i) => {
                          return (
                            <div className={styles.row} key={i}>
                              <div className={styles.grabber}>
                                <GrabberIcon />
                              </div>

                              <div className={styles.thumbnail}>
                                <Tooltip
                                  placement='left'
                                  text={
                                    <Image
                                      default={DefaultImage}
                                      className={styles.thumbPreview}
                                      src={part.thumb_url}
                                    />
                                  }
                                  html={true}
                                  dark={true}
                                >
                                  <Image
                                    default={DefaultImage}
                                    src={part.thumb_url}
                                    className={styles.icon}
                                  />
                                </Tooltip>
                              </div>

                              <Tooltip
                                placement='left'
                                text={getLocalized(
                                  part.description,
                                  this.props.application_language_id
                                )}
                              >
                                <div
                                  className={styles.description}
                                  onClick={() =>
                                    readProduct(part.id).then(
                                      ({ response }) => {
                                        setTableParams('products', {
                                          flyout: response.data,
                                        });
                                      }
                                    )
                                  }
                                >
                                  {_.filter(
                                    [
                                      part.model,
                                      getLocalized(
                                        part.description,
                                        this.props.application_language_id
                                      ),
                                    ],
                                    _.identity
                                  ).join(' / ') || <TableNA />}
                                </div>
                              </Tooltip>
                              <div className={styles.actions}>
                                <Tooltip
                                  text={__('products.parts.delete-part')}
                                >
                                  <CloseMiddleIcon
                                    onClick={(e) =>
                                      this._deletePart(
                                        e,
                                        part.id,
                                        category.value
                                      )
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          );
                        })}
                      </ReactSortable>
                    </>
                  )}
                  <div className={styles.addRow}>
                    <button
                      onClick={() =>
                        setTableParams('products', {
                          add_part_to_product_wizard: true,
                          category: category.value,
                        })
                      }
                    >
                      {__('button.add-part')}
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Parts;
