import * as React from "react";
import { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { getLocalized, mapStateToProps, transformToListPairs } from "../../../core/utils";
import { enableAutosave } from "../../../redux/actions/general/autosave";
import Input from "../../../components/Input";
import { withRouter } from "react-router";
import { subscribe } from "react-contextual";
import Localization from "../../../helpers/Localization";
import MultiSelect from "../../../components/MultiSelect";
import Dropzone from "../../../components/Dropzone";

@reduxForm({
    form: "superadmin_materials.edit_material",
    enableReinitialize: true,
})
@withRouter
@subscribe(Localization, "localization")
@mapStateToProps(state => ({
    superadmin: state.auth.superadmin,
    application_language_id: state.auth.language_id,
}))
class EditMaterialForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render() {
        const { handleSubmit, superadmin } = this.props;

        const raw_materials = _.sortBy(_.map(this.props.localization.raw_materials, raw_material => ({
            label: getLocalized(raw_material.name, this.props.application_language_id),
            priority: raw_material.priority,
            value: raw_material.id
        })), 'priority');

        const application_languages = _.transform(_.filter(this.props.localization.languages, language => language.application_language == true), (languages, language) => {
            languages[language.id] = language.id == this.props.application_language_id;

            return languages;
        }, {});

        return (
            <Form onSubmit={handleSubmit}>
                <Field disabled={!superadmin.edit_material} name="name" component={Input} localized={application_languages} label="Name" />
                <Field disabled={!superadmin.edit_material} name="raw_materials" component={MultiSelect} searchable noSort options={raw_materials} label="Filter materials" />
                <Field disabled={!superadmin.edit_material} name="file" component={Dropzone} item="Image" accept="image/png,image/jpeg" />
            </Form>
        );
    }
}

export default EditMaterialForm;