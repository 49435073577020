import * as React from 'react';
import { Component } from 'react';
import styles from '../../styles/flyouts/current_set_specifications/LinkedSpecifications.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import {
  __,
  dateFrom,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../core/utils';
import Tooltip from '../../components/Tooltip';
import { subscribe } from 'react-contextual';
import Localization from '../../helpers/Localization';
import ArrowDoubleRightSmallIcon from '../../assets/images/arrow-double-right-small-15x15.svg';
import TableNA from '../../components/TableNA';
import TickIcon from '../../assets/images/tick-15x15.svg';
import Image from '../../components/Image';
import DefaultImage from '../../assets/images/default.png';
import UncheckedIcon from '../../assets/images/unchecked-15x15.svg';
import CheckedIcon from '../../assets/images/checked-15x15.svg';

@mapStateToProps((state) => ({
  store: state.table.current_set_specifications,
}))
@subscribe(Localization, 'localization')
class LinkedSpecifications extends Component {
  render() {
    const { collapsed, store } = this.props;
    const { flyout } = store;
    const { specification_groups, specification_subgroups } =
      this.props.localization;

    const linked_specs = _.sortBy(
      _.map(flyout.linked, (spec) => {
        const specification_subgroup =
          _.get(specification_subgroups, [spec.specification_subgroup_id]) ||
          {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const code =
          getLocalized(specification_group.code, flyout.project_language) +
          specification_subgroup.code +
          _.padStart(spec.code, 2, '0');
        const title = _.filter(
          [_.get(spec.description, store.language_id), spec.model, spec.brand],
          _.identity
        ).join(' / ');

        return {
          ...spec,
          code,
          title,
        };
      }),
      'code'
    );

    const parent_linked_specs = _.sortBy(
      _.map(flyout.parent_linked, (spec) => {
        const specification_subgroup =
          _.get(specification_subgroups, [spec.specification_subgroup_id]) ||
          {};
        const specification_group =
          _.get(
            specification_groups,
            _.get(specification_subgroup, 'specification_group_id')
          ) || {};

        const code =
          getLocalized(specification_group.code, flyout.project_language) +
          specification_subgroup.code +
          _.padStart(spec.code, 2, '0');

        return {
          ...spec,
          code,
        };
      }),
      'code'
    );

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {_.isEmpty(flyout.linked) ? (
          <div className={styles.empty}>
            {__(
              'current-set-specifications.linked-specifications.no-linked-specifications'
            )}
          </div>
        ) : (
          ''
        )}
        {!_.isEmpty(flyout.linked) && (
          <>
            <div className={styles.header}>
              <span className={styles.code}>
                {__(
                  'specifications.current-set.flyout.linked-specifications.code'
                )}
              </span>
              <span className={styles.titleAndDescription}>
                {__(
                  'specifications.current-set.flyout.linked-specifications.title-and-description'
                )}
              </span>
              <span className={styles.included}>
                {__(
                  'specifications.working-set.linked-specification.included-in-price'
                )}
              </span>
              {/*<span className={styles.quantity}>{__('specifications.current-set.linked-specification.quantity')}</span>*/}
              {/*<span className={styles.included}>{__('specifications.current-set.linked-specification.included-in-price')}</span>*/}
              {/* <span className={styles.date}>
                {__(
                  'specifications.current-set.flyout.linked-specifications.created-at'
                )}
              </span> */}
            </div>
            {_.map(linked_specs, (specification, i) => {
              return (
                <div className={styles.row} key={i}>
                  <div
                    className={styles.code}
                    onClick={() => {
                      specification.id &&
                        setTableParams('current_set_specifications', {
                          clicked_row: specification.id,
                        });
                    }}
                  >
                    {specification.code}
                  </div>
                  {!specification.id && (
                    <div className={styles.tbd}>
                      <Tooltip
                        text={__(
                          'current-set-specifications.linked-specifications.specification-not-delivered-to-current-set'
                        )}
                      >
                        <span>
                          {__(
                            'specifications.current-set.flyout.linked-specifications.to-be-defined'
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {specification.id && (
                    <>
                      <div className={styles.thumbnail}>
                        <Tooltip
                          placement='left'
                          text={
                            <Image
                              default={DefaultImage}
                              className={styles.thumbPreview}
                              src={specification.thumb_url}
                            />
                          }
                          html={true}
                          dark={true}
                        >
                          <Image
                            default={DefaultImage}
                            src={specification.thumb_url}
                            className={styles.icon}
                          />
                        </Tooltip>
                      </div>
                      <div className={styles.titleAndDescription}>
                        <Tooltip text={specification.title}>
                          <div
                            className={styles.title}
                            onClick={() =>
                              setTableParams('current_set_specifications', {
                                clicked_row: specification.id,
                              })
                            }
                          >
                            {_.size(specification.title) > 0 ? (
                              <span>{specification.title}</span>
                            ) : (
                              <TableNA />
                            )}
                            <ArrowDoubleRightSmallIcon />
                          </div>
                        </Tooltip>

                        <Tooltip text={specification.linked_description}>
                          <div className={styles.description}>
                            {specification.linked_description || <TableNA />}
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  )}

                  {specification.id && (
                    <div className={styles.included}>
                      {specification.included_in_price ? (
                        <CheckedIcon className={styles.checked} />
                      ) : (
                        <UncheckedIcon className={styles.unchecked} />
                      )}
                    </div>
                  )}

                  {!specification.id && (
                    <div className={styles.included}>
                      <Tooltip
                        text={__(
                          'current-set-specifications.linked-specifications.specification-not-delivered-to-current-set'
                        )}
                      >
                        <span>
                          {__(
                            'specifications.current-set.flyout.linked-specifications.to-be-defined'
                          )}
                        </span>
                      </Tooltip>
                    </div>
                  )}

                  {/*<div className={styles.quantity}>1</div>*/}
                  {/*<div className={styles.included}>{_.random(0, 1) == 1 ? <TickIcon /> : <TableNA />}</div>*/}
                  {/* <div className={styles.date}>
                    {specification.id ? (
                      dateFrom(specification.created_at)
                    ) : (
                      <TableNA />
                    )}
                  </div> */}
                </div>
              );
            })}
          </>
        )}
        {!_.isEmpty(flyout.parent_linked) && (
          <div className={styles.parentLinked}>
            <div className={styles.linkedText}>
              {__(
                'specifications.current-set.flyout.linked-specifications.specification-linked-following-master-specifications'
              )}
            </div>
            <ul className={styles.linkedList}>
              {_.map(parent_linked_specs, (specification, i) => (
                <>
                  {i > 0 && <div className={styles.separator}>,</div>}
                  {specification.id ? (
                    <span
                      key={i}
                      onClick={() =>
                        setTableParams('current_set_specifications', {
                          clicked_row: specification.id,
                        })
                      }
                    >
                      {specification.code}
                    </span>
                  ) : (
                    <Tooltip
                      text={__(
                        'current-set-specifications.linked-specifications.specification-not-delivered-to-current-set'
                      )}
                    >
                      <span className={styles.tbd}>{specification.code}</span>
                    </Tooltip>
                  )}
                </>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default LinkedSpecifications;
