import * as React from "react";
import {Component} from "react";
import styles from "../../styles/wizards/superadmin/AddNoteWizard.scss";
import autobind from "autobind-decorator";
import AddNoteIcon from "../../assets/images/add-note-60x60.svg";
import {mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {withRouter} from "react-router";
import {setNotification} from "../../redux/actions/general/notification";
import {listNotes} from "../../redux/actions/table/superadmin/notes";
import {createNote} from "../../redux/actions/table/superadmin/notes";
import AddNoteForm from "../../forms/superadmin/add_note_wizard/AddNoteForm";

@withRouter
@mapStateToProps(state => ({
    table: state.table["superadmin_notes"],
}))
class AddNoteWizard extends Component {
    @autobind
    _handleSubmit(formData) {
        const {edit} = formData;
        
        return validateForm(
            createNote(formData).then(({response}) => {
                listNotes().then(() => {
                    if(edit) {
                        setTableParams('superadmin_notes', {
                            clicked_row: response.data.id
                        });
                    }
                });

                setTableParams('superadmin_notes', {
                    actioned_row: undefined,
                    selected_rows: []
                });

                this.props.onClose();

                setNotification("You have successfully added a new instruction.");
            })
        );
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.left}>
                        <span>Add note</span>
                        <span>Add note to the application</span>
                    </div>
                    <div className={styles.right}>
                        <AddNoteIcon />
                    </div>
                </div>
                <div className={styles.body}>
                    <AddNoteForm onSubmit={this._handleSubmit} initialValues={{
                        note: {}
                    }} />
                </div>
            </>
        );
    }
}

export default AddNoteWizard;