import * as React from "react";
import {Component} from "react";
import styles from "../../styles/flyouts/rooms_multi_edit/Properties.scss";
import * as _ from "lodash";
import {withRouter} from "react-router";
import autobind from "autobind-decorator";
import {__, mapStateToProps, setTableParams, validateForm} from "../../core/utils";
import {setNotification} from "../../redux/actions/general/notification";
import {listRooms, updateRooms} from "../../redux/actions/table/rooms";
import EditRoomForm from "../../forms/rooms/EditRoomForm";
import {getStagesLanguages, readStage} from "../../redux/actions/table/stages";
import Button from "../../components/Button";
import {listPossibleTags} from "../../redux/actions/general";

@withRouter
@mapStateToProps(state => ({
    table: state.table["rooms"],
    edit_values: _.get(state.form, 'rooms.edit_room.values', {}),
    edit_fields: _.get(state.general.multiedit, ['rooms.edit_room', 'fields']),
    reset_values: _.get(state.general.multiedit, ['rooms.edit_room', 'reset']),
}))
class Properties extends Component {
    constructor(props) {
        super(props);

        this.state = {
            project_languages: null,
            security_tags: [],
        };
    }

    @autobind
    _handleSubmit() {
        const room_ids = this.props.table.multi_edit;
        const {edit_values, edit_fields, reset_values} = this.props;

        const fields = _.transform(edit_fields, (fields, field) => {
            fields[field] = _.get(edit_values, field, '');

            return fields;
        }, {});

        return validateForm(
            updateRooms(room_ids, fields, reset_values).then(() => {
                this._loadTags();

                listRooms(this.props.match.params.stage);

                setTableParams('rooms', {
                    clicked_row: undefined,
                    multi_edit: undefined,
                    selected_rows: []
                });

                setNotification(__('rooms.flyout.multi-edit.notification.saved'));
            })
        );
    }

    @autobind
    _loadTags() {
        readStage(this.props.match.params.stage).then(({response}) => {
            listPossibleTags('room_security', response.data.project_id).then(({response}) => {
                this.setState({security_tags: response.data});
            });
        });
    }

    componentDidMount() {
        this._loadTags();

        getStagesLanguages(this.props.match.params.stage).then(({response}) => {
            this.setState({project_languages: response.data});
        });
    }

    render() {
        const {security_tags, project_languages} = this.state;

        return (
            <>
                <div className={styles.scroller}>
                    {this.state.project_languages && <EditRoomForm
                        multiedit={true}
                        onSubmit={this._handleSubmit}
                        data={{
                            project_languages
                        }}
                        securityTags={security_tags}
                        initialValues={{
                            title: {},
                            description: {},
                        }}
                    />}
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium middleText={__('button.cancel')} onClick={() => setTableParams('rooms', {
                        clicked_row: undefined,
                        multi_edit: undefined
                    })}/>
                    <Button lightBlue medium middleText={__('button.save-changes')} onClick={this._handleSubmit} />
                </div>
            </>
        );
    }
}

export default Properties;
