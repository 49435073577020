import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_ocr_template_wizard/AddOCRTemplateForm.scss";
import Button from "../../components/Button";
import {__, setTableParams, transformToListPairs} from "../../core/utils";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Select from "../../components/Select";
import {withRouter} from "react-router";
import {listPossibleOCRTemplates} from "../../redux/actions/table/ocr_templates";

@withRouter
@reduxForm({form: "ocr_plans_wizard.ocr_plans"})
class OCRPlansForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_ocr_templates: []
        }
    }

    componentDidMount() {
        listPossibleOCRTemplates(this.props.match.params.stage).then(({response}) => {
            this.setState({possible_ocr_templates: response.data});
        });
    }

    render() {
        const {possible_ocr_templates} = this.state;

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="ocr_template_id" component={Select} label={__('plan-upload.field.ocr-template')} options={transformToListPairs(possible_ocr_templates)} />
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('plan_uploads', {
                        ocr_plans_wizard: undefined
                    })} />
                    <Button gray medium submit right leftText={__('button.start')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default OCRPlansForm;
