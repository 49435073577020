import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/products/read_only/LinkedProducts.scss';
import * as _ from 'lodash';
import classNames from 'classnames';
import { __, mapStateToProps, setTableParams } from '../../../core/utils';
import { subscribe } from 'react-contextual';
import Localization from '../../../helpers/Localization';
import { reduxForm } from 'redux-form';
import ArrowDownMiddleIcon from '../../../assets/images/arrow-down-middle-15x15.svg';
import autobind from 'autobind-decorator';
import Image from '../../../components/Image';
import DefaultImage from '../../../assets/images/default.png';
import {
  linkedProducts,
  readProduct,
} from '../../../redux/actions/table/products';

@reduxForm({
  form: 'products.read_only.parts',
  enableReinitialize: true,
})
@mapStateToProps((state) => ({
  store: state.table.products,
  application_language_id: state.auth.language_id,
  auth: state.auth,
}))
@subscribe(Localization, 'localization')
class LinkedProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: [],
      expandedImages: [],
      linked_products: this.props.store.flyout.linked_products,
    };
  }

  @autobind
  _toggleCollapseCategory(category) {
    this.setState({
      expanded: _.xor(this.state.expanded, [category]),
    });
  }

  @autobind
  _toggleCollapseImages(category) {
    this.setState({
      expandedImages: _.xor(this.state.expandedImages, [category]),
    });
  }

  @autobind
  _getLinkedProducts(value, items) {
    const {
      flyout: { id },
    } = this.props.store;

    linkedProducts(id, value).then(({ response }) => {
      this.setState(
        {
          linked_products: _.unionBy(
            this.state.linked_products,
            response.data,
            'id'
          ),
        },
        () => this._toggleCollapseImages(value)
      );
    });
  }

  @autobind
  _renderItem(category, category_images) {
    const { expanded, expandedImages } = this.state;

    return _.isEmpty(category_images) ? (
      ''
    ) : (
      <div className={styles.category} key={category.value}>
        <div
          className={styles.title}
          onClick={() => this._toggleCollapseCategory(category.value)}
        >
          <ArrowDownMiddleIcon
            className={classNames(
              styles.collapse,
              !_.includes(expanded, category.value) && styles.collapsed
            )}
          />
          {category.label}
        </div>

        {_.includes(expanded, category.value) && (
          <div className={styles.content}>
            {_.map(
              !_.includes(expandedImages, category.value) &&
                category_images.length > 3
                ? category_images.slice(0, 3)
                : category_images,
              (product, i) => (
                <div className={styles.thumbnail}>
                  <Image
                    key={i}
                    onClick={() =>
                      readProduct(product.id).then(({ response }) => {
                        setTableParams('products', {
                          flyout: response.data,
                        });
                      })
                    }
                    default={DefaultImage}
                    src={product.thumb_url}
                  />
                </div>
              )
            )}
            {category_images.length > 3 ? (
              !_.includes(expandedImages, category.value) ? (
                <span
                  onClick={() => {
                    this._getLinkedProducts(category.value);
                  }}
                >
                  See More
                </span>
              ) : (
                <span
                  onClick={() => this._toggleCollapseImages(category.value)}
                >
                  See less
                </span>
              )
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { collapsed, store } = this.props;
    const { flyout } = store;
    const { linked_products } = this.state;

    const categories = [
      {
        label: __('products.linked_products.accessories'),
        value: 'accessories',
      },
      {
        label: __('products.linked_products.family_products'),
        value: 'family_products',
      },
      {
        label: __('products.linked_products.collection_products'),
        value: 'collection_products',
      },
      {
        label: __('products.linked_products.designer_products'),
        value: 'designer_products',
      },
    ];

    return (
      <div
        className={classNames(styles.wrapper, collapsed && styles.collapsed)}
      >
        {this._renderItem(categories[0], _.sortBy(flyout.accessories, 'order'))}

        {_.map(categories, (category) => {
          return this._renderItem(
            category,
            _.filter(linked_products, (product) =>
              product.category.includes(category.value)
            )
          );
        })}
      </div>
    );
  }
}

export default LinkedProducts;
