import React, { Component } from "react";
import styles from "../styles/components/ToggleButton.scss";

class ToggleButton extends Component {
	render() {
		return (
			<label className={styles.switch}>
				<input type="checkbox" disabled={this.props.disabled} checked={this.props.value} value={this.props.value} onChange={this.props.onToggle} />
				<span className={styles.slider}></span>
			</label>
		);
	}
}

export default ToggleButton;
