import React, { Component } from 'react';
import styles from '../styles/components/Dropdown.scss';
import { connect } from 'react-redux';
import { hideDropdown, showDropdown } from '../redux/actions/general/dropdown';
import classNames from 'classnames';
import ClickOutside from 'react-click-outside';
import autobind from 'autobind-decorator';

class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdown_offset_y: 0,
    };
  }

  @autobind
  _showDropdown(e) {
    e.stopPropagation();

    showDropdown(this.props.name);
  }

  @autobind
  _handleClickOutside() {
    if (this.props.closable !== false) {
      hideDropdown();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shown != this.props.shown &&
      this.props.shown == this.props.name
    ) {
      this._handleDropdownOffset();
    }
  }

  _handleDropdownOffset() {
    const { dropdown_offset_y } = this.state;

    const window_height = window.innerHeight;

    if (this.props.shown == this.props.name && this.refs.dropdown.container) {
      const dropdown = this.refs.dropdown.container.getBoundingClientRect();

      const dropdown_height = dropdown.height + 20;
      const dropdown_start_y = dropdown.top - dropdown_offset_y;
      const dropdown_end_y = dropdown_start_y + dropdown_height;

      if (dropdown_end_y > window_height) {
        this.setState({
          dropdown_offset_y: window_height - dropdown_end_y,
        });
      } else {
        this.setState({
          dropdown_offset_y: 0,
        });
      }
    }
  }

  render() {
    const { dropdown_offset_y } = this.state;
    const { readOnly, doubleClick } = this.props;

    return (
      <div
        className={classNames(
          styles.wrapper,
          this.props.leftStyled && styles.leftStyled,
          this.props.wrapperClassName
        )}
      >
        <div
          className={classNames(!readOnly && styles.clickable)}
          onClick={(e) => !readOnly && !doubleClick && this._showDropdown(e)}
          onDoubleClick={(e) =>
            !readOnly && doubleClick && this._showDropdown(e)
          }
        >
          {this.props.children}
        </div>
        {this.props.shown == this.props.name && (
          <ClickOutside
            style={{
              top: dropdown_offset_y + 'px',
              ..._.pick(this.props, ['left', 'right', 'bottom']),
              marginTop: (this.props.top || 40) + 'px',
            }}
            onClickOutside={this._handleClickOutside}
            className={styles.dropdown}
            ref='dropdown'
          >
            <div className={styles.header}>{this.props.header}</div>
            <div className={styles.content}>{this.props.content}</div>
          </ClickOutside>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.general.dropdown,
  };
}

export default connect(mapStateToProps, null)(Dropdown);
