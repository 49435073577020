const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'table.rooms/SET_TABLE_PARAMS':
      return {
        ...state,
        ...action.params,
      };
    case 'table.rooms/LIST_ROOMS_SUCCESS':
      return {
        ...state,
        data: action.response.data,
        filters: action.response.filters,
        additional_params: action.response.additional_params,
        ...action.response.pagination,
      };
    default:
      return state;
  }
}
