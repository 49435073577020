import React, { Component } from 'react';
import styles from '../styles/components/PeopleChooser.scss';
import classNames from 'classnames';
import SearchIcon from '../assets/images/search-15x15.svg';
import * as _ from 'lodash';
import ButtonGroup from './ButtonGroup';
import Button from './Button';
import PlusMiddleIcon from '../assets/images/plus-middle-15x15.svg';
import autobind from 'autobind-decorator';
import Tooltip from '../components/Tooltip';
import { __ } from '../core/utils';
import CloseSmallIcon from '../assets/images/close-small-15x15.svg';
import { hideAlertbox, showAlertbox } from '../redux/actions/general/alertbox';
import Avatar from './Avatar';
import FieldComponent from './FieldComponent';
import OutsideClickWrapper from './OutsideClickWrapper';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { isMobileOnly } from 'react-device-detect';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import slugify from 'slugify';
import UsersIcon from '../assets/images/users-16x16.svg';
import UserIcon from '../assets/images/user-16x16.svg';
import DisciplineIcon from '../assets/images/discipline-16x16.svg';
import CompanyIcon from '../assets/images/team-16x16.svg';

@FieldComponent
class PeopleChooser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      activeTab: {
        name: 'member',
        avatar_image: <UserIcon />,
        tooltipText: 'Member',
        label: __('peoplechooser.title.available-members'),
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.meta.active != this.props.meta.active) {
      this.setState(
        {
          query: '',
          selected: this.props.input.value || [],
        },
        () => {
          this.props.recalculateOffset();
        }
      );
    }

    if (
      prevState.selected != this.state.selected ||
      prevState.query != this.state.query
    ) {
      this.props.recalculateOffset();
    }
  }

  @autobind
  _handleOpen() {
    if (!this.props.disabled) {
      this.props.input.onFocus();
    }
  }

  @autobind
  _handleClose() {
    this.props.input.onBlur();
  }

  @autobind
  _handleSave() {
    this.props.input.onChange(this.state.selected);

    this._handleClose();
  }

  @autobind
  _handleSearch(e) {
    this.setState({ query: e.target.value });
  }

  @autobind
  _handleKeyDown(e) {
    if (e.keyCode == 27) {
      e.stopPropagation();
      e.preventDefault();

      this._handleClose();
    }

    const { query, selected } = this.state;
    const { options } = this.props;

    const available_values = _.filter(
      options,
      (item) =>
        !_.find(selected, ['id', item.id]) &&
        (_.isEmpty(query) ||
          _.includes(_.toLower(item.name), _.toLower(query)) ||
          _.includes(_.toLower(item.email), _.toLower(query)))
    );

    if (e.keyCode == 13) {
      e.stopPropagation();
      e.preventDefault();

      !_.isEmpty(query) &&
        !_.isEmpty(available_values) &&
        this._toggleValue(_.first(available_values), false, true);
    }
  }

  @autobind
  _handleClearSearch() {
    this.setState({
      query: '',
    });

    this.refs.search.focus();
  }

  @autobind
  _toggleValue(ids, isGroup = false, highlight_query = false) {
    this.setState(
      {
        selected: isGroup
          ? _.unionBy(this.state.selected, ids.items, 'id')
          : _.xor(this.state.selected, [ids]),
      },
      () => {
        highlight_query && this.refs.search && this.refs.search.select();
      }
    );
  }

  render() {
    const {
      searchable,
      options,
      disabled,
      leftPosition,
      topPosition,
      elementRef,
      dropdownRef,
    } = this.props;
    const { query, selected, activeTab } = this.state;

    let selected_values, available_values;

    const tabs = [
      {
        name: 'member',
        avatar_image: <UserIcon />,
        tooltipText: 'Member',
        label: __('peoplechooser.title.available-members'),
      },
      {
        name: 'team',
        avatar_image: <UsersIcon />,
        tooltipText: 'Team',
        label: __('peoplechooser.title.available-teams'),
      },
      {
        name: 'discipline',
        avatar_image: <DisciplineIcon />,
        tooltipText: 'Discipline',
        label: __('peoplechooser.title.available-disciplines'),
      },
      {
        name: 'company',
        avatar_image: <CompanyIcon />,
        tooltipText: 'Company',
        label: __('peoplechooser.title.available-companies'),
      },
    ];

    if (this.props.meta.active) {
      selected_values = _.sortBy(selected, (user) => _.toLower(user.name));
      available_values = _.sortBy(
        _.filter(options, (item) => {
          return (
            !_.find(selected, ['id', item.id]) &&
            (_.isEmpty(query) ||
              _.includes(
                slugify(_.toLower(item.name)),
                slugify(_.toLower(query))
              ) ||
              _.includes(_.toLower(item.email), _.toLower(query)))
          );
        }),
        (user) => _.toLower(user.name)
      );

      if (_.findKey(options, 'type')) {
        available_values = _.filter(available_values, (item) => {
          return item.type === activeTab.name;
        });

        if (activeTab.name != 'member')
          available_values = _.filter(available_values, (item) => {
            return !(_.differenceBy(item.items, selected, 'id').length === 0);
          });
      }
    }

    const limit = this.props.limit || 5;

    const Row = ({ index, style }) => {
      const item = available_values[index];

      return (
        <div
          style={style}
          className={styles.row}
          key={item.id}
          onClick={() =>
            this._toggleValue(
              item,
              _.findKey(options, 'type') && !(_.get(item, 'type') == 'member'),
              true
            )
          }
        >
          {_.findKey(options, 'type') && !(_.get(item, 'type') == 'member') ? (
            <span>{activeTab.avatar_image}</span>
          ) : (
            <Avatar {...item} size={22} />
          )}
          <span>{item.name}</span>
          <PlusMiddleIcon className={styles.plus} />
        </div>
      );
    };

    const TabContent = ({ tab }) => {
      return (
        <button
          type='button'
          onClick={() => this.setState({ activeTab: tab })}
          className={classNames(
            styles.tabsNavigationButton,
            tab.name === activeTab.name && styles.tabsNavigationButtonActive
          )}
          key={tab.name}
        >
          <Tooltip text={tab.tooltipText}>{tab.avatar_image}</Tooltip>
        </button>
      );
    };

    return (
      <div
        className={classNames(
          styles.wrapper,
          _.isEmpty(this.props.input.value) && styles.placeholder,
          this.props.meta.active && styles.focus,
          _.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') &&
            styles.error,
          disabled && styles.disabled
        )}
      >
        <div className={styles.label}>
          <label onClick={this._handleOpen}>
            {this.props.label}{' '}
            {!_.isEmpty(this.props.input.value) &&
              '(' + _.size(this.props.input.value) + ')'}
          </label>
        </div>
        <div
          className={styles.inputGroup}
          ref={elementRef}
          onClick={this._handleOpen}
        >
          <div className={styles.input}>
            {_.size(this.props.input.value) == 0 && this.props.placeholder && (
              <span className={styles.placeholder}>
                {this.props.placeholder}
              </span>
            )}
            {_.map(_.take(this.props.input.value, limit), (user) => (
              <Avatar
                className={styles.avatar}
                key={user.id}
                {...user}
                active={_.isObject(_.find(options, ['id', user.id]))}
              />
            ))}
            {_.size(this.props.input.value) > limit && (
              <Tooltip
                text={_.map(_.slice(this.props.input.value, limit), (user) =>
                  _.isObject(_.find(options, ['id', user.id]))
                    ? user.name
                    : user.name + ' (' + __('avatar.deactivated') + ')'
                ).join('\n')}
              >
                <div className={styles.more}>
                  <span>+ {_.size(this.props.input.value) - limit}</span>
                </div>
              </Tooltip>
            )}
          </div>
          {!disabled && <PlusMiddleIcon />}
        </div>

        {this.props.meta.active && (
          <OutsideClickWrapper closable={false}>
            <KeyboardEventHandler
              handleKeys={['esc']}
              onKeyEvent={this._handleClose}
            />
            <div
              className={styles.dropdown}
              ref={dropdownRef}
              style={{
                top: topPosition + 'px',
                left: leftPosition + 'px',
                marginTop: (this.props.top || 27) + 'px',
                marginLeft: (this.props.left || 0) + 'px',
              }}
            >
              {isMobileOnly && (
                <div className={styles.mobileHeader}>{this.props.label}</div>
              )}

              {/* ALWAYS IS SAME */}
              {!_.isEmpty(selected_values) && (
                <div
                  className={classNames(
                    styles.selected,
                    _.size(available_values) === 0 &&
                      !_.findKey(options, 'type') &&
                      styles.selectedAllAvailableValues
                  )}
                >
                  <div className={styles.title}>
                    {__('peoplechooser.title.currently-selected')} (
                    {_.size(selected_values)})
                  </div>
                  <div className={styles.list}>
                    {_.map(selected_values, (item) => (
                      <div
                        className={classNames(
                          styles.row,
                          !_.find(options, ['id', item.id]) &&
                            styles.deactivated
                        )}
                        key={item.id}
                        onClick={() =>
                          !_.find(options, ['id', item.id])
                            ? showAlertbox({
                                color: 'red',
                                title: __('general.alert.are-you-sure'),
                                description: __(
                                  'peoplechooser.alert.delete-confirmation'
                                ),
                                buttons: [
                                  {
                                    color: 'lightGray',
                                    text: __('general.alert.no-close'),
                                    onClick: 'close',
                                  },
                                  {
                                    color: 'gray',
                                    text: __('general.alert.yes-delete'),
                                    onClick: () => {
                                      this._toggleValue(item);

                                      hideAlertbox();
                                    },
                                  },
                                ],
                              })
                            : this._toggleValue(item)
                        }
                      >
                        <Avatar
                          {...item}
                          active={_.isObject(_.find(options, ['id', item.id]))}
                          size={22}
                        />
                        <span>{item.name}</span>

                        <CloseSmallIcon className={styles.close} />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* --- --- --- */}
              {(_.size(options) == 0 ||
                _.size(options) !=
                  _.size(
                    _.filter(selected, (item) =>
                      _.find(options, ['id', item.id])
                    )
                  )) && (
                <div
                  className={classNames(
                    styles.available,
                    _.size(selected) === 0 && styles.selectedValue
                  )}
                >
                  <div className={styles.title}>
                    <span>
                      {activeTab.label}
                      {!_.isEmpty(available_values)
                        ? ' (' + _.size(available_values) + ')'
                        : ''}
                    </span>
                    {_.findKey(options, 'type') && (
                      <div className={styles.tabsNavigation}>
                        {/* --- TABS --- */}
                        {tabs.map((tab) => {
                          return <TabContent tab={tab} />;
                        })}
                      </div>
                    )}
                  </div>

                  {searchable && (
                    <div className={styles.search}>
                      <SearchIcon className={styles.searchIcon} />
                      <input
                        ref='search'
                        type='text'
                        value={query}
                        autoFocus={true}
                        placeholder={__('peoplechooser.title.search')}
                        onChange={this._handleSearch}
                        onKeyDown={this._handleKeyDown}
                      />
                      {!_.isEmpty(query) && (
                        <CloseSmallIcon
                          className={styles.close}
                          onClick={this._handleClearSearch}
                        />
                      )}
                    </div>
                  )}
                  <div className={styles.list}>
                    <AutoSizer>
                      {({ height, width }) => (
                        <List
                          height={height}
                          itemCount={_.size(available_values)}
                          itemSize={36}
                          width={width}
                        >
                          {Row}
                        </List>
                      )}
                    </AutoSizer>
                    {_.isEmpty(available_values) && (
                      <div className={styles.noResults}>No results found.</div>
                    )}
                  </div>
                </div>
              )}

              <div className={styles.footer}>
                <ButtonGroup right>
                  <Button
                    lightGray
                    medium
                    middleText={__('button.cancel')}
                    onClick={this._handleClose}
                  />
                  <Button
                    lightBlue
                    medium
                    middleText={__('button.done')}
                    onClick={this._handleSave}
                  />
                </ButtonGroup>
              </div>
            </div>
          </OutsideClickWrapper>
        )}
        <div className={styles.bar} />
        <div
          className={classNames(
            styles.assist,
            _.get(this.props, 'meta.error') &&
              _.get(this.props, 'meta.touched') &&
              styles.hint
          )}
        >
          {_.get(this.props, 'meta.error') &&
            _.get(this.props, 'meta.touched') && (
              <span>{_.get(this.props, 'meta.error')}</span>
            )}
        </div>
      </div>
    );
  }
}

export default PeopleChooser;
