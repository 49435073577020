import store from "../../../../core/store";

export const listProductGroups = () => {
	return store.dispatch({
		action: "table.superadmin_product_groups/LIST_PRODUCT_GROUPS",
		method: "GET",
		endpoint: "/superadmin/product_groups",
		main: true
	});
};

export const createProductGroup = (formData) => {
	return store.dispatch({
		action: "table.superadmin_product_groups/CREATE_PRODUCT_GROUP",
		method: "POST",
		endpoint: "/superadmin/product_groups",
		data: formData
	});
};

export const deleteProductGroups = (product_group_ids) => {
	return store.dispatch({
		action: "table.superadmin_product_groups/DELETE_PRODUCT_GROUPS",
		method: "DELETE",
		endpoint: "/superadmin/product_groups",
		data: {
			product_groups: product_group_ids
		}
	});
};

export const readProductGroup = (product_group_id) => {
	return store.dispatch({
		action: "table.superadmin_product_groups/READ_PRODUCT_GROUP",
		method: "GET",
		endpoint: "/superadmin/product_groups/" + product_group_id,
		has404: true
	});
};

export const updateProductGroup = (product_group_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_product_groups/UPDATE_PRODUCT_GROUP",
		method: "PUT",
		endpoint: "/superadmin/product_groups/" + product_group_id,
		data: formData
	});
};