import store from "../../../core/store";

export const showLightbox = (images, active) => {
    return store.dispatch({
        type: "general.lightbox/SHOW_LIGHTBOX",
        images,
        active
    });
};

export const hideLightbox = () => {
    return store.dispatch({
        type: "general.lightbox/HIDE_LIGHTBOX",
    });
};