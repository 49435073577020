import idToKey from "id-to-key";

const initialState = {};

export function listCompanies() {
	return {
		action: "companies/LIST_COMPANIES",
		method: "GET",
		endpoint: "/companies"
	};
}

export function readCompany(company_id) {
	return {
		action: "companies/READ_COMPANY",
		method: "GET",
		endpoint: "/companies/" + company_id
	};
}

export function uploadLogo(company_id, logo) {
	return {
		action: "companies/UPLOAD_LOGO",
		method: "POST",
		endpoint: "/companies/" + company_id + "/logo",
		data: {
			logo
		}
	};
}

export function updateCompanySubscription(company_id, formData) {
	return {
		action: "companies/UPDATE_COMPANY_SUBSCRIPTION",
		method: "PUT",
		endpoint: "/companies/" + company_id + "/subscription",
		data: formData
	};
}

export function updateCompanyMarket(company_id, formData) {
	return {
		action: "companies/UPDATE_COMPANY_MARKET",
		method: "PUT",
		endpoint: "/companies/" + company_id + "/market",
		data: formData
	};
}

export function updateCompanyDisciplines(company_id, formData) {
	return {
		action: "companies/UPDATE_COMPANY_DISCIPLINES",
		method: "PUT",
		endpoint: "/companies/" + company_id + "/disciplines",
		data: formData
	};
}

export function updateCompanyOffices(company_id, formData) {
	return {
		action: "companies/UPDATE_COMPANY_OFFICES",
		method: "PUT",
		endpoint: "/companies/" + company_id + "/offices",
		data: formData
	};
}

export function updateCompanyDetails(company_id, formData) {
	return {
		action: "companies/UPDATE_COMPANY_DETAILS",
		method: "PUT",
		endpoint: "/companies/" + company_id + "/details",
		data: formData
	};
}

export function readPartnerCompany(company_id) {
	return {
		action: "companies/READ_PARTNER_COMPANY",
		method: "GET",
		endpoint: "/companies/partner/" + company_id
	};
}

export function readPublicCompany(company_id) {
	return {
		action: "companies/READ_PUBLIC_COMPANY",
		method: "GET",
		endpoint: "/companies/public/" + company_id
	};
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "companies/LIST_COMPANIES_SUCCESS":
			return {
				...action.response.data
			};
		case "companies/READ_COMPANY_SUCCESS":
			return {
				...state,
				...idToKey([action.response.data])
			};
		default:
			return state;
	}
}
