import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_team_members_wizard/AddTeamMembersForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import {connect} from "react-redux";
import {subscribe} from "react-contextual";
import Localization from "../../helpers/Localization";
import Select from "../../components/Select";
import * as _ from "lodash";
import {listPossibleTeamMembers} from "../../redux/actions/table/teams";
import {withRouter} from "react-router";
import {__} from "../../core/utils";
import TeamPeopleChooser from "../../components/TeamPeopleChooser";

const mapStateToProps = state => {
    return {
        table: state.table["teams"],
        auth: state.auth,
        values: getFormValues("add_team_members_wizard.add_team")(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideAddTeamMembersWizard: () => dispatch({
                type: "table.teams/SET_TABLE_PARAMS",
                params: {
                    add_team_members_wizard_active: undefined,
                    team_id: undefined
                }
            })
        }
    };
};

@reduxForm({
    form: "add_team_members_wizard.add_team",
})
@subscribe(Localization, "localization")
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AddTeamMembersForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_team_members: [],
        }
    }

    componentDidMount() {
        this.props.values.team_id && listPossibleTeamMembers(this.props.values.team_id).then(({response}) => {
            this.setState({possible_team_members: response.data});
        });
    }

    componentDidUpdate(prevProps) {
        (this.props.values.team_id != prevProps.values.team_id) && listPossibleTeamMembers(this.props.values.team_id).then(({response}) => {
            this.setState({possible_team_members: response.data});

            this.props.change('invites', []);
        });
    }

    render() {
        const {disciplines} = this.props.localization;

        const teams = _.map(this.props.table.data, team => {
            return {
                value: team.id,
                label: team.company.name + ' [' + [team.stage_discipline.code, team.stage_discipline.discipline_id == null ? (team.stage_discipline.description + '*') : _.get(disciplines, [team.stage_discipline.discipline_id, 'name'])].join(': ') + ']'
            };
        });

        const users = _.map(this.state.possible_team_members, user => {
            return {
                value: user.id,
                label: user.firstname + ' ' + user.lastname + (user.company ? ' · ' + user.company : ''),
                email: user.email,
                avatar_url: user.avatar_url
            };
        });

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                {!this.props.table.team_id && <Field name="team_id" component={Select} searchable label={__('project-settings.teams.add-new.team-members.team')} options={teams} />}
                {this.props.values.team_id && <Field name="invites" component={TeamPeopleChooser} label={__('project-settings.teams.invite-user-to-team.users')} options={users} />}
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideAddTeamMembersWizard} />
                    <Button gray medium submit right leftText={__('button.invite')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default AddTeamMembersForm;
