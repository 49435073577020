import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddSpecificationProductAttributeGroupFieldWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeAddIcon from "../assets/images/product-attribute-add-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import AddProductAttributeGroupFieldForm
	from "../forms/add_product_attribute_group_field_wizard/AddProductAttributeGroupFieldForm";
import {setNotification} from "../redux/actions/general/notification";
import {addProductAttributeGroupField, readProduct} from "../redux/actions/table/products";
import {
	addSpecificationProductAttributeGroupField,
	readWorkingSetSpecification
} from "../redux/actions/table/working_set_specifications";
import AddSpecificationProductAttributeGroupFieldForm
	from "../forms/add_specification_product_attribute_group_field_form/AddSpecificationProductAttributeGroupFieldForm";

@mapStateToProps(state => ({
	group_id: _.get(state, 'table.working_set_specifications.add_specification_product_attribute_group_field_wizard'),
	flyout: state.table.working_set_specifications.flyout
}))
class AddSpecificationProductAttributeGroupFieldWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		formData = {
			...formData,
			custom_attribute: _.get(formData, 'custom_attribute', false) ? 1 : 0,
		};

		return validateForm(
			addSpecificationProductAttributeGroupField(this.props.group_id, formData).then(() => readWorkingSetSpecification(this.props.flyout.id).then(({response}) => {
				setNotification(__('specifications.working-set.flyout.add-attributes.notification.specification-attributes-added'));

				setTableParams('working_set_specifications', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('specifications.working-set.flyout.add-attributes.add-specification-attributes')}</span>
						<span>{__('specifications.working-set.flyout.add-attributes.add-attributes-to-your-specification-attribute-group')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeAddIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddSpecificationProductAttributeGroupFieldForm onSubmit={this._handleSubmit} initialValues={{
						custom_attribute: false,
						label: {}
					}} />
				</div>
			</>
		);
	}
}

export default AddSpecificationProductAttributeGroupFieldWizard;
