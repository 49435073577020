import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddSpecificationProductNoteWizard.scss';
import autobind from 'autobind-decorator';
import {
  __,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import AddNoteIcon from '../assets/images/add-note-60x60.svg';
import {
  addSpecificationProductNote,
  readWorkingSetSpecification,
} from '../redux/actions/table/working_set_specifications';
import AddSpecificationProductNoteForm from '../forms/add_specification_product_note_wizard/AddSpecificationProductNoteForm';

@mapStateToProps((state) => ({
  flyout: state.table.working_set_specifications.flyout,
}))
class AddSpecificationProductNoteWizard extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      addSpecificationProductNote(this.props.flyout.id, formData).then(() =>
        readWorkingSetSpecification(this.props.flyout.id).then(
          ({ response }) => {
            setNotification('The specification note has been added.');

            setTableParams('working_set_specifications', {
              flyout: response.data,
            });

            this.props.onClose();
          }
        )
      )
    );
  }

  render() {
    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'specifications.working-set.flyout.add-instruction.add-specification-instruction'
              )}
            </span>
            <span>
              {__(
                'specifications.working-set.flyout.add-instruction.add-note-to-your-specification'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddNoteIcon />
          </div>
        </div>
        <div className={styles.body}>
          <AddSpecificationProductNoteForm
            onSubmit={this._handleSubmit}
            initialValues={{
              add_new: '0',
              note: {},
              is_my_company_note: 0,
            }}
          />
        </div>
      </>
    );
  }
}

export default AddSpecificationProductNoteWizard;
