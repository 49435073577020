import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/add_project_wizard/ProjectImageForm.scss";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import Button from "../../components/Button";
import Dropzone from "../../components/Dropzone";
import {__, setTableParams} from "../../core/utils";
import ButtonGroup from "../../components/ButtonGroup";
import ArrowLeftMiddleIcon from "../../assets/images/arrow-left-middle-15x15.svg";

@reduxForm({
    form: "add_project_wizard.project_image",
    destroyOnUnmount: false
})
class ProjectImageForm extends Component {
	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<Field name="logo" component={Dropzone} item={__('project-list.create-new-project.project-image.image')} accept="image/png,image/jpeg" />
				<div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('all_projects', {
                        wizard_active: undefined
                    })} />
					<ButtonGroup right>
                        <Button medium gray onClick={this.props.onBack} rightText={__('button.back')} leftIcon={ArrowLeftMiddleIcon} />
                        <Button submit medium gray leftText={__('button.next')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} />
					</ButtonGroup>
				</div>
			</Form>
		);
	}
}

export default ProjectImageForm;