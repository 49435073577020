import * as React from 'react';
import { Component } from 'react';
import styles from '../styles/wizards/AddSpecificationWizard.scss';
import {
  __,
  getActiveProject,
  getActiveStage,
  mapStateToProps,
  setTableParams,
  validateForm,
} from '../core/utils';
import { getFormValues } from 'redux-form';
import Localization from '../helpers/Localization';
import { subscribe } from 'react-contextual';
import autobind from 'autobind-decorator';
import { setNotification } from '../redux/actions/general/notification';
import AddSpecificationForm from '../forms/add_specification_wizard/AddSpecificationForm';
import { readProjectGeneral } from '../redux/actions/projects';
import {
  createSpecification,
  listWorkingSetSpecifications,
  readWorkingSetSpecification,
} from '../redux/actions/table/working_set_specifications';
import AddSpecificationIcon from '../assets/images/add-specification-60x60.svg';
import { hideAlertbox } from '../redux/actions/general/alertbox';

@subscribe(Localization, 'localization')
@mapStateToProps((state) => ({
  store: state.table.working_set_specifications,
}))
class AddSpecificationWizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project_language: null,
    };
  }

  componentDidMount() {
    readProjectGeneral(getActiveProject()).then(({ response }) => {
      this.setState({ project_language: response.data.main_language });
    });
  }

  @autobind
  _handleCreateSpecification(formData) {
    const { edit } = formData;

    if (this.props.store.create_action) {
      formData = _.omit(formData, ['edit']);
    } else {
      formData = _.omit(formData, ['action', 'edit']);
    }

    if (this.props.store.linked_specification_code_id) {
      formData.linked_specification_code_id =
        this.props.store.linked_specification_code_id;
    }

    return validateForm(
      createSpecification(getActiveStage(), formData).then(({ response }) => {
        this.props.onClose();

        setNotification(
          __('specifications.add-new.successfully-added-new-specification')
        );

        listWorkingSetSpecifications(getActiveStage());

        if (edit) {
          setTableParams('working_set_specifications', {
            clicked_row: response.data.id,
          });
        } else if (this.props.store.linked_specification_code_id) {
          readWorkingSetSpecification(this.props.store.flyout.id).then(
            ({ response }) => {
              setTableParams('working_set_specifications', {
                flyout: response.data,
              });
            }
          );
        }
      })
    );
  }

  render() {
    const { project_language } = this.state;

    return (
      <>
        <div className={styles.header}>
          <div className={styles.left}>
            <span>
              {__(
                'specifications.working-set.flyout.add-new.create-new-specification'
              )}
            </span>
            <span>
              {__(
                'specifications.working-set.flyout.add-new.create-new-specification-in-your-workspace'
              )}
            </span>
          </div>
          <div className={styles.right}>
            <AddSpecificationIcon />
          </div>
        </div>
        <div className={styles.body}>
          <AddSpecificationForm
            onSubmit={this._handleCreateSpecification}
            projectLanguage={project_language}
            initialValues={{
              custom: '0',
              action: 'option_main',
              quantity: '1.00',
            }}
          />
        </div>
      </>
    );
  }
}

export default AddSpecificationWizard;
