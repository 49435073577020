import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import styles from "../../styles/forms/edit_linked_specification_wizard/EditLinkedSpecificationForm.scss";
import Button from "../../components/Button";
import {__, getLocalized, mapStateToProps, setTableParams} from "../../core/utils";
import {withRouter} from "react-router";
import {subscribe} from "react-contextual/dist/react-contextual.es";
import Localization from "../../helpers/Localization";
import Select from "../../components/Select";
import {listPossibleLinkedSpecificationCodes} from "../../redux/actions/table/working_set_specifications";
import * as _ from "lodash";
import Input from "../../components/Input";
import RadioGroup from "../../components/RadioGroup";

@withRouter
@subscribe(Localization, "localization")
@reduxForm({form: "edit_linked_specification_wizard.edit_linked_specification"})
@mapStateToProps(state => ({
    store: _.get(state.table, 'working_set_specifications')
}))
class EditLinkedSpecificationForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            possible_linked_specifications: [],
        }
    }

    componentDidMount() {
        const edited_specification_code = _.find(this.props.store.flyout.linked, ['specification_code_id', this.props.store.edit_linked_specification_wizard]);

        listPossibleLinkedSpecificationCodes(this.props.store.flyout.specification_code_id).then(({response}) => {
            const possible_linked_specifications = response.data;

            possible_linked_specifications.push({
                id: edited_specification_code.specification_code_id,
                code: edited_specification_code.code,
                specification_subgroup_id: edited_specification_code.specification_subgroup_id,
                description: edited_specification_code.description,
                brand: edited_specification_code.brand,
                model: edited_specification_code.model
            });

            this.setState({possible_linked_specifications: response.data});
        });
    }

    render() {
        const {store} = this.props;
        const {specification_groups, specification_subgroups} = this.props.localization;
        const {flyout} = store;

        const possible_linked_specifications = _.map(this.state.possible_linked_specifications, specification => {
            const specification_subgroup = _.get(specification_subgroups, [specification.specification_subgroup_id]) || {};
            const specification_group = _.get(specification_groups, _.get(specification_subgroup, 'specification_group_id')) || {};

            const code = getLocalized(specification_group.code, flyout.project_language) + specification_subgroup.code + _.padStart(specification.code, 2, "0");
            const title = _.filter([_.get(specification.description, store.language_id), specification.model, specification.brand], _.identity).join(' / ');

            return {
                value: specification.id,
                label: [code, title].join(': ')
            }
        });

        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.form}>
                    <Field name="new_specification_code_id" component={Select} searchable label={__('specifications.working-set.add-linked-specification.specification')} options={possible_linked_specifications} />
                    <Field name="description" label={__('specifications.working-set.add-linked-specification.description')} component={Input} />
                    {/*<div className={styles.flex}>*/}
                    {/*    <div className={styles.f1}>*/}
                    {/*        <Field name="quantity" label={__('specifications.working-set.edit-linked-specification.quantity')} component={Input} numeric decimal />*/}
                    {/*    </div>*/}
                    {/*    <div className={styles.f1}>*/}
                    {/*        <div className={styles.label}>{__('specifications.working-set.edit-linked-specification.included-in-price')}</div>*/}
                    {/*        <Field name="included_in_price" component={RadioGroup} options={[*/}
                    {/*            {*/}
                    {/*                label: __('specifications.working-set.edit-linked-specification.included-in-price.no'),*/}
                    {/*                value: '0',*/}
                    {/*            }, {*/}
                    {/*                label: __('specifications.working-set.edit-linked-specification.included-in-price.yes'),*/}
                    {/*                value: '1',*/}
                    {/*            }*/}
                    {/*        ]} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={() => setTableParams('working_set_specifications', {
                        edit_linked_specification_wizard: undefined
                    })} />
                    <Button lightBlue medium right submit middleText={__('button.edit')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default EditLinkedSpecificationForm;
