import * as React from "react";
import {Component} from "react";
import {Field, Form, reduxForm} from "redux-form";
import {__} from "../../core/utils";
import Input from "../../components/Input";
import styles from "../../styles/forms/wizard_multiple_plan_scale_edit/MultiplePlanScaleEditForm.scss";
import Button from "../../components/Button";
import {connect} from "react-redux";

const mapDispatchToProps = dispatch => {
    return {
        actions: {
            hideMultiplePlanScaleEditWizard: () =>
                dispatch({
                    type: "table.plan_uploads/SET_TABLE_PARAMS",
                    params: {
                        wizard_multiple_plan_scale_edit: undefined,
                    }
                }),
        }
    };
};

@reduxForm({
    form: "wizard_multiple_plan_scale_edit.multiple_plan_scale_edit",
})
@connect(null, mapDispatchToProps)
class MultiplePlanScaleEditForm extends Component {
    render() {
        return (
            <Form onSubmit={this.props.handleSubmit} className={styles.wrapper}>
                <div className={styles.desc}>
                    <span>{__('plans.working-set.multiple-actions.scale-edit-wizard.scale-will-change-current-set')}</span>
                </div>
                <Field name="scale" component={Input} label={__('Scale')} />
                <div className={styles.footer}>
                    <Button lightGray medium left middleText={__('button.cancel')} onClick={this.props.actions.hideMultiplePlanScaleEditWizard} />
                    <Button lightBlue medium submit right middleText={__('Save')} spinning={this.props.submitting} />
                </div>
            </Form>
        );
    }
}

export default MultiplePlanScaleEditForm;