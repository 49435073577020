const initialState = [];

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "@@router/LOCATION_CHANGE":
            return [];
        case "general.autosave/ENABLE_AUTOSAVE":
            _.set(state, [action.form, 'autosave'], true);

            return state;
        case "general.autosave/HANDLE_BLUR":
            _.set(state, [action.form, 'values'], {});

            return state;
        case "@@redux-form/INITIALIZE":
            _.set(state, [action.meta.form, 'values'], {});

            return state;
        case "general.autosave/SET_VALUE":
            _.set(state, [action.form, 'values', action.field], action.value);

            return state;
        default:
            return state;
    }
}