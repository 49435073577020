import Image from '../../components/Image';
import * as React from 'react';
import { Component } from 'react';
import Table from '../../components/Table';
import {
  createSidebar,
  toggleSidebarCollapse,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import { setPlusActions } from '../../redux/actions/general/plus';
import {
  __,
  dateFrom,
  getLocalized,
  getURLParam,
  hasURLParam,
  mapStateToProps,
  setTableParams,
  setURLParam,
} from '../../core/utils';
import {
  copyProduct,
  listProducts,
  readProduct,
} from '../../redux/actions/table/products';
import * as _ from 'lodash';
import ProductsFlyout from '../../flyouts/products/ProductsFlyout';
import Tooltip from '../../components/Tooltip';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import styles from '../../styles/views/products/Products.scss';
import TableNA from '../../components/TableNA';
import ReadOnlyProductsFlyout from '../../flyouts/products/read_only/ReadOnlyProductsFlyout';
import { setNotification } from '../../redux/actions/general/notification';
import classNames from 'classnames';
import DuplicateIcon from '../../assets/images/duplicate-16x16.svg';
import ProductIcon from '../../assets/images/product-16x16.svg';
import ProductPartIcon from '../../assets/images/product-part-16x16.svg';

@mapStateToProps((state) => ({
  table: state.table['products'],
  auth: state.auth,
  application_language_id: state.auth.language_id,
}))
class Products extends Component {
  componentWillMount() {
    listActiveModules();
  }

  componentDidMount() {
    if (!hasURLParam('filter.subgroup')) {
      setURLParam('filter.subgroup', '');
    }

    createSidebar({
      title: __('submenu.title.products'),
      items: [
        {
          title: __('submenu.title.all-products'),
          link: () => '/products?filter.subgroup=',
          active: true,
          expanded: true,
          onArrowClick: () => toggleSidebarCollapse('items.0'),
        },
        {
          title: __('products.submenu.title.brands'),
          link: () => '/products/public_brands',
        },
      ],
    });

    setPlusActions([
      {
        name: __('products.add-new.add-product'),
        onClick: () =>
          setTableParams('products', {
            wizard_active: true,
            type: 'product',
          }),
      },
      {
        name: __('products.add-new.add-part'),
        onClick: () =>
          setTableParams('products', {
            wizard_active: true,
            type: 'part',
          }),
      },
      // {
      // 	name: __("products.add-new.add-accessory"),
      // 	onClick: () =>
      // 		setTableParams("products", {
      // 			wizard_active: true,
      // 			type: "accessory"
      // 		})
      // }
    ]);
  }

  componentDidUpdate(prevProps) {
    //if there are new filter options available, update sidebar
    if (!_.isEqual(this.props.table.filters, prevProps.table.filters)) {
      const setSubgroups = (subgroups) => {
        subgroups = _.trim(
          _.join(
            _.reject(subgroups, (subgroup_id) => subgroup_id == 'n/a'),
            '|'
          ),
          '|'
        );

        setURLParam(
          'filter.subgroup',
          _.isEmpty(subgroups) ? 'n/a' : subgroups
        );
      };

      const categories = _.get(this.props.table.filters, 'subgroup.items');

      const all_ids = _.flattenDeep(
        _.map(categories, (category) =>
          _.map(category.items, (group) => _.keys(group.items))
        )
      );

      const items = _.map(_.map(categories), (category, category_index) => ({
        ...category,
        onArrowClick: () =>
          toggleSidebarCollapse('items.0.items.' + category_index),
        onClick: () =>
          setSubgroups(
            _.transform(
              category.items,
              (keys, group) => {
                _.each(_.keys(group.items), (subgroup_id) =>
                  keys.push(subgroup_id)
                );

                return keys;
              },
              []
            )
          ),
        active: () => {
          const active_subgroups = _.split(getURLParam('filter.subgroup'), '|');

          return (
            _.isEmpty(getURLParam('filter.subgroup')) ||
            _.some(
              _.intersection(
                active_subgroups,
                _.transform(
                  category.items,
                  (keys, group) => {
                    _.each(_.keys(group.items), (subgroup_id) =>
                      keys.push(subgroup_id)
                    );

                    return keys;
                  },
                  []
                )
              )
            )
          );
        },
        checkbox: {
          status: () => {
            const active_subgroups = _.split(
              getURLParam('filter.subgroup'),
              '|'
            );

            const items = _.transform(
              category.items,
              (keys, group) => {
                _.each(_.keys(group.items), (subgroup_id) =>
                  keys.push(subgroup_id)
                );

                return keys;
              },
              []
            );

            const intersected = _.intersection(active_subgroups, items);

            if (_.isEmpty(getURLParam('filter.subgroup'))) {
              return 'checked';
            }

            if (_.isEmpty(intersected)) {
              return 'unchecked';
            } else if (_.size(intersected) == _.size(items)) {
              return 'checked';
            } else {
              return 'neutral';
            }
          },
          onClick: () => {
            const active_subgroups =
              getURLParam('filter.subgroup') == ''
                ? all_ids
                : _.split(getURLParam('filter.subgroup'), '|');

            const items = _.transform(
              category.items,
              (keys, group) => {
                _.each(_.keys(group.items), (subgroup_id) =>
                  keys.push(subgroup_id)
                );

                return keys;
              },
              []
            );

            const intersected = _.intersection(active_subgroups, items);

            if (_.size(intersected) == _.size(items)) {
              setSubgroups(
                _.reject(active_subgroups, (id) => _.includes(items, id))
              );
            } else {
              _.each(
                items,
                (id) =>
                  !_.includes(active_subgroups, id) && active_subgroups.push(id)
              );

              setSubgroups(active_subgroups);
            }
          },
        },
        items: _.map(_.map(category.items), (group, group_index) => ({
          ...group,
          onArrowClick: () =>
            toggleSidebarCollapse(
              'items.0.items.' + category_index + '.items.' + group_index
            ),
          onClick: () => setSubgroups(_.keys(group.items)),
          active: () => {
            const active_subgroups = _.split(
              getURLParam('filter.subgroup'),
              '|'
            );

            return (
              _.isEmpty(getURLParam('filter.subgroup')) ||
              _.some(_.intersection(active_subgroups, _.keys(group.items)))
            );
          },
          checkbox: {
            status: () => {
              const active_subgroups = _.split(
                getURLParam('filter.subgroup'),
                '|'
              );

              const intersected = _.intersection(
                active_subgroups,
                _.keys(group.items)
              );

              if (_.isEmpty(getURLParam('filter.subgroup'))) {
                return 'checked';
              }

              if (_.isEmpty(intersected)) {
                return 'unchecked';
              } else if (_.size(intersected) == _.size(_.keys(group.items))) {
                return 'checked';
              } else {
                return 'neutral';
              }
            },
            onClick: () => {
              const active_subgroups =
                getURLParam('filter.subgroup') == ''
                  ? all_ids
                  : _.split(getURLParam('filter.subgroup'), '|');

              const intersected = _.intersection(
                active_subgroups,
                _.keys(group.items)
              );

              if (_.size(intersected) == _.size(_.keys(group.items))) {
                setSubgroups(
                  _.reject(active_subgroups, (id) =>
                    _.includes(_.keys(group.items), id)
                  )
                );
              } else {
                _.each(
                  _.keys(group.items),
                  (id) =>
                    !_.includes(active_subgroups, id) &&
                    active_subgroups.push(id)
                );

                setSubgroups(active_subgroups);
              }
            },
          },
          items: _.map(group.items, (subgroup, subgroup_id) => ({
            ...subgroup,
            onClick: () => setURLParam('filter.subgroup', subgroup_id),
            active: () => {
              const active_subgroups = _.split(
                getURLParam('filter.subgroup'),
                '|'
              );

              return (
                _.isEmpty(getURLParam('filter.subgroup')) ||
                _.includes(active_subgroups, subgroup_id)
              );
            },
            checkbox: {
              status: () => {
                const active_subgroups = _.split(
                  getURLParam('filter.subgroup'),
                  '|'
                );

                if (_.isEmpty(getURLParam('filter.subgroup'))) {
                  return 'checked';
                }

                return _.includes(active_subgroups, subgroup_id)
                  ? 'checked'
                  : 'unchecked';
              },
              onClick: () => {
                const active_subgroups =
                  getURLParam('filter.subgroup') == ''
                    ? all_ids
                    : _.split(getURLParam('filter.subgroup'), '|');

                if (_.includes(active_subgroups, subgroup_id)) {
                  setSubgroups(
                    _.reject(active_subgroups, (id) => id == subgroup_id)
                  );
                } else {
                  active_subgroups.push(subgroup_id);

                  setSubgroups(active_subgroups);
                }
              },
            },
          })),
        })),
      }));

      updateSidebar('items.0.items', items);
    }

    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        setTableParams('products', {
          flyout: undefined,
        });
      }

      readProduct(this.props.table.clicked_row).then(({ response }) => {
        setTableParams('products', {
          flyout: response.data,
          edit_mode: false,
        });
      });
    }
  }

  _handleOpacity(value, row) {
    return row.discontinued ? (
      <div className={styles.faded}>{value}</div>
    ) : (
      value
    );
  }

  render() {
    const { application_language_id, auth } = this.props;

    const columns = [
      {
        key: 'type',
        name: __('products.table-titles.type'),
        value: (value, row) => {
          switch (value) {
            case 'product':
              return this._handleOpacity(
                <Tooltip text={__('products.table-titles.type.product')}>
                  <ProductIcon />
                </Tooltip>,
                row
              );
            case 'part':
              return this._handleOpacity(
                <Tooltip text={__('products.table-titles.type.part')}>
                  <ProductPartIcon />
                </Tooltip>,
                row
              );
          }
        },
        width: 50,
      },
      {
        key: 'thumb',
        name: __('products.table-titles.thumbnail'),
        value: (created_by, row) =>
          this._handleOpacity(
            row.image_url ? (
              <Tooltip
                placement='right'
                text={
                  <Image className={styles.thumbPreview} src={row.image_url} />
                }
                html={true}
                dark={true}
              >
                <Image className={styles.thumb} src={row.image_url} />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'description',
        name: __('products.table-titles.description'),
        value: (description, row) =>
          this._handleOpacity(
            getLocalized(description, application_language_id),
            row
          ),
        width: 200,
      },
      {
        key: 'model',
        name: __('products.table-titles.model'),
        required: true,
        default: true,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'company_id',
        name: __('products.table-titles.company'),
        value: (value, row) =>
          this._handleOpacity(
            <div className={styles.company}>
              {row.company.id === auth.company.id && (
                <span className={styles.my}>MY</span>
              )}
              {row.company.name}
            </div>,
            row
          ),
        width: 150,
        active: false,
      },
      {
        key: 'brand',
        name: __('products.table-titles.manufacturer'),
        value: (value, row) => this._handleOpacity(value, row),
        width: 150,
      },
      {
        key: 'created_at',
        name: __('products.table-titles.added-at'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        width: 80,
        active: false,
      },
      {
        key: 'price_from',
        name: __('products.table-titles.price-from'),
        value: (value, row) => this._handleOpacity(value || 'n/a', row),
        width: 80,
      },
      {
        key: 'price_to',
        name: __('products.table-titles.price-to'),
        value: (value, row) => this._handleOpacity(value || 'n/a', row),
        width: 80,
        active: false,
      },
      {
        key: 'price_date',
        name: __('products.table-titles.price-date'),
        value: (value, row) =>
          this._handleOpacity(value ? dateFrom(value) : 'n/a', row),
        width: 80,
        active: false,
      },
      {
        key: 'bim',
        name: __('products.table-titles.bim'),
        value: (value, row) =>
          this._handleOpacity(
            value
              ? __('products.table-titles.bim.yes')
              : __('products.table-titles.bim.no'),
            row
          ),
        width: 80,
        sortable: false,
      },
      {
        key: 'public',
        name: __('products.table-titles.public'),
        value: (sent, row) =>
          row.public ? (
            <span className={classNames(styles.public, styles.yes)}>
              {__('products.table-titles.public.yes')}
            </span>
          ) : (
            <span className={styles.public}>
              {__('products.table-titles.public.no')}
            </span>
          ),
        width: 60,
        active: false,
      },
    ];

    const singleActions = (product_id) => {
      const { auth } = this.props;
      const product = _.find(this.props.table.data, { id: product_id });

      let actions = [];

      if (product.company.id === auth.company.id) {
        actions.push(
          <Tooltip
            text={__('products.table.actions.duplicate')}
            key={_.uniqueId()}
          >
            <DuplicateIcon
              onClick={(e) => {
                e.stopPropagation();

                copyProduct(product_id).then(({ response }) => {
                  setNotification(
                    __('products.table.notifications.successfully-copied')
                  );

                  listProducts();

                  setTableParams('products', {
                    clicked_row: response.data.id,
                    actioned_row: undefined,
                    selected_rows: [],
                  });
                });
              }}
            />
          </Tooltip>
        );
      }

      return actions;
    };

    const gridOptions = {
      section: __('submenu.title.all-products'),
      image: (id, row) => row.image_url,
      title: (row) => row.model,
      subTitle: (row) => [], //
      content: (row) => [row.brand],
    };

    const FlyoutComponent = this.props.table.edit_mode
      ? ProductsFlyout
      : ReadOnlyProductsFlyout;

    const preview = (
      <FlyoutComponent
        readAction={(product_id) => {
          return new Promise((resolve) => {
            readProduct(getURLParam('id') || product_id).then(
              ({ response }) => {
                setTableParams('products', {
                  flyout: response.data,
                  edit_mode: false,
                });

                resolve(response);
              }
            );
          });
        }}
      />
    );

    const filtersSort = (filter, value, label) => {
      switch (filter) {
        case 'designer':
        case 'family':
        case 'language':
        case 'collection':
        case 'project':
        case 'company':
          return value;

        case 'brand':
          return label;
      }
    };

    return (
      <Table
        filtersSort={filtersSort}
        listAction={listProducts}
        name='products'
        title={__('products.submenu.title.all-products')}
        columns={columns}
        singleActions={singleActions}
        defaultView='grid'
        preview={this.props.table.flyout ? preview : []}
        gridOptions={gridOptions}
      />
    );
  }
}

export default Products;
