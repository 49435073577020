import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/superadmin/boq_template_calculations/BOQTemplateCalculationsFlyout.scss';
import * as _ from 'lodash';
import CloseBigIcon from '../../../assets/images/close-big-15x15.svg';
import {
  __,
  getLocalized,
  mapStateToProps,
  setTableParams,
} from '../../../core/utils';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../../helpers/Localization';
import { setNotification } from '../../../redux/actions/general/notification';
import withRouter from 'react-router/es/withRouter';
import Tooltip from '../../../components/Tooltip';
import DeleteIcon from '../../../assets/images/delete-15x15.svg';
import {
  hideAlertbox,
  showAlertbox,
} from '../../../redux/actions/general/alertbox';
import classNames from 'classnames';
import Properties from './Properties';
import {
  deleteBOQTemplateCalculations,
  listBOQTemplateCalculations,
} from '../../../redux/actions/table/superadmin/boq_template_calculations';

@subscribe(Localization, 'localization')
@withRouter
@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  auth: state.auth,
  application_language_id: state.auth.language_id,
}))
class BOQTemplateCalculationsFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 'properties',
    };
  }

  render() {
    const { data, readAction, superadmin, application_language_id } =
      this.props;

    return (
      <div className={styles.preview}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span>{getLocalized(data.name, application_language_id)}</span>
            <CloseBigIcon
              onClick={() =>
                setTableParams('superadmin_boq_template_calculations', {
                  clicked_row: undefined,
                })
              }
            />
          </div>
          <div className={styles.actions}>
            {superadmin.delete_boq_template_calculation && (
              <div className={styles.actionWrapper}>
                <Tooltip
                  text='Delete BOQ template calculation'
                  key={_.uniqueId()}
                >
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();

                      showAlertbox({
                        color: 'red',
                        title: __('general.alert.are-you-sure'),
                        description:
                          'Do you really want to delete the selected BOQ template calculation?\n\nThis process cannot be undone.',
                        buttons: [
                          {
                            color: 'lightGray',
                            text: __('general.alert.no-close'),
                            onClick: 'close',
                          },
                          {
                            color: 'gray',
                            text: __('general.alert.yes-delete'),
                            onClick: () => {
                              deleteBOQTemplateCalculations([data.id]).then(
                                () => {
                                  hideAlertbox();

                                  setNotification(
                                    'The selected BOQ template calculation has been deleted.'
                                  );
                                  listBOQTemplateCalculations();

                                  setTableParams(
                                    'superadmin_boq_template_calculations',
                                    {
                                      clicked_row: undefined,
                                    }
                                  );
                                }
                              );
                            },
                          },
                        ],
                      });
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className={styles.tabs}>
          <span
            className={classNames(
              this.state.active == 'properties' && styles.active
            )}
            onClick={() => this.setState({ active: 'properties' })}
          >
            Properties
          </span>
        </div>
        {!_.isEmpty(data) && this.state.active == 'properties' && (
          <Properties data={data} readAction={readAction} />
        )}
      </div>
    );
  }
}

export default BOQTemplateCalculationsFlyout;
