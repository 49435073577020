import React from 'react';
import { __ } from '../../core/utils';
import _ from 'lodash';
import SelectedAreas from './Shared/SelectedAreas';
import Button from '../Button';
import styles from '../../styles/components/RoomChooser.scss';
import EntranceIcon from '../../assets/images/entrance-16x16.svg';
import ExpansionJointIcon from '../../assets/images/expansion-joint-16x16.svg';
import RoomTypeIcon from '../../assets/images/room-type-16x16.svg';
import ViewTreeIcon from '../../assets/images/view-tree-16x16.svg';
import ZoneIcon from '../../assets/images/zone-16x16.svg';
import Tabs from './Shared/Tabs';
import { createTreeStructure, getFilteredData } from './helpers';
import classNames from 'classnames';

const keyExtractor = (o) => `${o.type}:${o.id}`;

const RoomChooser = ({
  multiselect,
  onDone,
  options,
  initialValues,
  onCancel,
  expandedAllNodes,
  selectAllRooms,
}) => {
  const [tree, setTree] = React.useState({});
  const [query, setQuery] = React.useState('');

  /**
   * {
   *    'room:1' : {...},
   *    'site:2' : {...},
   *  'storey:3' : {...},
   * }
   */
  const [selectedNodes, setSelectedNodes] = React.useState(() =>
    _.keyBy(initialValues, keyExtractor)
  );

  React.useEffect(() => {
    const treeArray = createTreeStructure(options);
    const tree = _.groupBy(treeArray, 'type');
    setTree(tree);
  }, [options]);

  const handleDone = React.useCallback(() => {
    onDone(_.pickBy(selectedNodes, _.identity));
  }, [selectedNodes]);

  const handleSelect = React.useCallback(
    (node, checked) => {
      if (multiselect) {
        setSelectedNodes((prevSelectedNodes) => {
          const selectedNodes = {
            ...prevSelectedNodes,
            [keyExtractor(node)]: checked ? node : undefined,
          };

          // if we deselected a room, we need to remove room type
          // as well from the selected nodes
          if (!checked && node.type === 'room' && node.room_type_id) {
            const roomType = options.find(
              (o) => o.type === 'room_type' && o.id === node.room_type_id
            );
            selectedNodes[keyExtractor(roomType)] = undefined;
          }

          // select/deselect all rooms if we clicked on room type
          if (node.type === 'room_type') {
            // find all rooms for room_type_id
            const rooms = options.reduce((prev, curr) => {
              if (curr.room_type_id === node.id) {
                Object.assign(prev, {
                  [keyExtractor(curr)]: checked ? curr : undefined,
                });
              }
              return prev;
            }, {});

            Object.assign(selectedNodes, rooms);
          }

          return selectedNodes;
        });
      } else {
        setSelectedNodes({
          [keyExtractor(node)]: node,
        });

        onDone(node);
      }
    },
    [setSelectedNodes, onDone, options]
  );

  const handleRemove = React.useCallback((node) => {
    handleSelect(node, false);
  }, []);

  const filteredData = getFilteredData(tree, query);

  const sharedTabProps = {
    keyExtractor,
    onSelect: handleSelect,
    multiselect,
    selectedNodes,
    selectAllRooms,
  };

  const roomNodes = [
    ...(filteredData?.site || []),
    ...(filteredData?.building || []),
    ...(filteredData?.storey || []),
    ...(filteredData?.room || []),
  ];
  const roomTypesNodes = filteredData?.room_type;
  const zonesNodes = filteredData?.zone;
  const expansionJointsNodes = filteredData?.expansion_joint;
  const entrancesNodes = filteredData?.entrance;

  return (
    <div className={classNames(!multiselect && styles.singleSelect)}>
      {multiselect && (
        <SelectedAreas selectedNodes={selectedNodes} onRemove={handleRemove} />
      )}

      <br />

      <Tabs data={filteredData} onQueryChange={setQuery}>
        <Tabs.Tab id='rooms' tooltipText='Rooms' Icon={ViewTreeIcon}>
          <Tabs.Content
            {...sharedTabProps}
            nodes={roomNodes}
            initialCollapsedNodes={_.reduce(
              _.flatMap(roomNodes, (sites) =>
                _.flatMap(sites?.items, (buildings) =>
                  _.flatMap(buildings?.items, (storeys) => {
                    if (expandedAllNodes)
                      return _.flatMap(storeys?.items, (room) => {
                        return { [sharedTabProps.keyExtractor(room)]: true };
                      });
                    return { [sharedTabProps.keyExtractor(storeys)]: true };
                  })
                )
              ),
              (obj, key) => {
                return { ...obj, ...key };
              },
              {}
            )}
          />
        </Tabs.Tab>
        <Tabs.Tab id='room_types' tooltipText='Room Types' Icon={RoomTypeIcon}>
          <Tabs.Content {...sharedTabProps} nodes={roomTypesNodes} />
        </Tabs.Tab>
        <Tabs.Tab id='zones' tooltipText='Zones' Icon={ZoneIcon}>
          <Tabs.Content {...sharedTabProps} nodes={zonesNodes} />
        </Tabs.Tab>
        <Tabs.Tab
          id='expansion_joints'
          tooltipText='Expansion Joints'
          Icon={ExpansionJointIcon}
        >
          <Tabs.Content {...sharedTabProps} nodes={expansionJointsNodes} />
        </Tabs.Tab>
        <Tabs.Tab id='entrances' tooltipText='Entrances' Icon={EntranceIcon}>
          <Tabs.Content {...sharedTabProps} nodes={entrancesNodes} />
        </Tabs.Tab>
      </Tabs>

      {multiselect && (
        <div className={styles.actions}>
          <Button middleText={'Cancel'} onClick={onCancel} lightGray />
          <Button middleText={'Done'} onClick={handleDone} />
        </div>
      )}
    </div>
  );
};

export default RoomChooser;
