import * as React from 'react';
import { Component } from 'react';
import styles from '../../../styles/flyouts/superadmin/boq_template_subgroups/Properties.scss';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { __, validateForm } from '../../../core/utils';
import { setNotification } from '../../../redux/actions/general/notification';
import {
  listBOQTemplateSubgroups,
  updateBOQTemplateSubgroup,
} from '../../../redux/actions/table/superadmin/boq_template_subgroups';
import EditBOQTemplateSubgroupForm from '../../../forms/superadmin/boq_template_subgroups/EditBOQTemplateSubgroupForm';

@withRouter
class Properties extends Component {
  @autobind
  _handleSubmit(formData) {
    return validateForm(
      updateBOQTemplateSubgroup(formData.id, _.omit(formData, ['id'])).then(
        () => {
          listBOQTemplateSubgroups();

          this.props.readAction(formData.id);

          setNotification(
            __('properties.notifications.properties-have-been-saved')
          );
        }
      )
    );
  }

  render() {
    const { data } = this.props;

    return (
      <div className={styles.scroller}>
        <EditBOQTemplateSubgroupForm
          onSubmit={this._handleSubmit}
          initialValues={{
            ..._.pick(data, ['id', 'name', 'boq_template_subgroup_id']),
          }}
        />
      </div>
    );
  }
}

export default Properties;
