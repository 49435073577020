import * as React from "react";
import {Component} from "react";
import styles from "../styles/wizards/AddSpecificationProductAttributeGroupWizard.scss";
import autobind from "autobind-decorator";
import ProductAttributeGroupAddIcon from "../assets/images/product-attribute-group-add-60x60.svg";
import {__, mapStateToProps, setTableParams, validateForm} from "../core/utils";
import {setNotification} from "../redux/actions/general/notification";
import * as _ from "lodash";
import {
	addSpecificationProductAttributeGroup,
	readWorkingSetSpecification
} from "../redux/actions/table/working_set_specifications";
import AddSpecificationProductAttributeGroupForm
	from "../forms/add_specification_product_attribute_group_form/AddSpecificationProductAttributeGroupForm";

@mapStateToProps(state => ({
	specification_id: _.get(state, 'table.working_set_specifications.add_specification_product_attribute_group_wizard')
}))
class AddSpecificationProductAttributeGroupWizard extends Component {
	@autobind
	_handleSubmit(formData) {
		let data = {
			name: formData.name,
			type: formData.type
		};

		if(formData.type == 'template' && formData.template) {
			if(_.startsWith(formData.template, 'system')) {
				data.source = 'system';
				data.template = formData.template.substring(7);
			}

			if(_.startsWith(formData.template, 'company')) {
				data.source = 'company';
				data.template = formData.template.substring(8);
			}

			data.fields = _.filter(_.keys(formData.fields), field_id => formData.fields[field_id] == true);
		}

		return validateForm(
			addSpecificationProductAttributeGroup(this.props.specification_id, data).then(() => readWorkingSetSpecification(this.props.specification_id).then(({response}) => {
				setNotification("A specification attribute group has been added.");

				setTableParams('working_set_specifications', {
					flyout: response.data
				});

				this.props.onClose();
			}))
		);
	}

	render() {
		return (
			<>
				<div className={styles.header}>
					<div className={styles.left}>
						<span>{__('specifications.working-set.flyout.add-group.add-specification-attribute-group')}</span>
						<span>{__('specifications.working-set.flyout.add-group.add-attribute-group-to-your-specification')}</span>
					</div>
					<div className={styles.right}>
						<ProductAttributeGroupAddIcon />
					</div>
				</div>
				<div className={styles.body}>
					<AddSpecificationProductAttributeGroupForm onSubmit={this._handleSubmit} initialValues={{
						type: 'template',
						name: {}
					}} />
				</div>
			</>
		);
	}
}

export default AddSpecificationProductAttributeGroupWizard;
