import React, { useEffect } from 'react';
import styles from '../styles/wizards/AttachTemplatesToBOQItemWizard.scss';
import { __, getActiveStage, validateForm } from '../core/utils';
import { setNotification } from '../redux/actions/general/notification';
import BOQIcon from '../assets/images/boq-24x24.svg';
import { connect } from 'react-redux';
import { subscribe } from 'react-contextual';
import Localization from '../helpers/Localization';
import AttachTemplatesToBOQItemForm from '../forms/attach_templates_to_boq_item_wizard/AttachTemplatesToBOQItemForm';
import {
  listPossibleBOQCalculations,
  addBOQCalculationsFromTemplates,
  readWorkingSetBOQ,
} from '../redux/actions/table/working_set_boq';

const field_name = {
  1: 'boq_subgroup_ids',
  2: 'boq_item_ids',
  3: 'boq_calculation_ids',
  4: 'boq_position_ids',
};

const AttachTemplatesToBOQItemWizard = ({ id, onClose, level }) => {
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    level === 2 &&
      listPossibleBOQCalculations().then(({ response }) => {
        setOptions(response.data);
      });
  }, []);

  const _handleSubmit = (formData) => {
    const add_actions = {
      // 1: ,
      2: addBOQCalculationsFromTemplates,
      // 3: ,
      // 4: ,
    };

    return validateForm(
      add_actions[level](id, formData[field_name[level + 1]]).then(() => {
        readWorkingSetBOQ(getActiveStage());

        setNotification('The boq calculations has been added.');

        onClose();
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.left}>
          <span>
            {__('boq-calculation.flyout.attach-templates-to-boq.title')}
          </span>
          <span>
            {__('boq-calculation.flyout.attach-templates-to-boq.subtitle')}
          </span>
        </div>
        <div className={styles.right}>
          <BOQIcon />
        </div>
      </div>
      <div className={styles.body}>
        <AttachTemplatesToBOQItemForm
          onSubmit={_handleSubmit}
          onClose={onClose}
          field_name={field_name[level + 1]}
          options={options}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.table.working_set_boq.attach_templates_to_boq_wizard,
    level: state.table.working_set_boq.level,
  };
};

export default connect(
  mapStateToProps,
  null
)(subscribe(Localization, 'localization')(AttachTemplatesToBOQItemWizard));
