import React from 'react';
import styles from '../../../styles/components/RoomChooser.scss';
import SearchIcon from '../../../assets/images/search-15x15.svg';
import CloseSmallIcon from '../../../assets/images/close-small-15x15.svg';
import { __ } from '../../../core/utils';
import * as _ from 'lodash';

const Search = ({ onQueryChange }) => {
  const [query, setQuery] = React.useState('');

  const handleQueryChange = (query) => {
    setQuery(query);
    onQueryChange(query);
  };

  return (
    <div className={styles.search}>
      <SearchIcon className={styles.searchIcon} />
      <input
        type='text'
        value={query}
        placeholder={__('select.title.search')}
        onChange={(e) => handleQueryChange(e.target.value)}
      />
      {!_.isEmpty(query) && (
        <CloseSmallIcon
          className={styles.close}
          onClick={(e) => handleQueryChange('')}
        />
      )}
    </div>
  );
};

export default Search;
