import store from "../../../../core/store";

export const listRawMaterials = () => {
	return store.dispatch({
		action: "table.superadmin_raw_materials/LIST_RAW_MATERIALS",
		method: "GET",
		endpoint: "/superadmin/raw_materials",
		main: true
	});
};

export const createRawMaterial = (formData) => {
	return store.dispatch({
		action: "table.superadmin_raw_materials/CREATE_RAW_MATERIAL",
		method: "POST",
		endpoint: "/superadmin/raw_materials",
		data: formData
	});
};

export const deleteRawMaterials = (raw_material_ids) => {
	return store.dispatch({
		action: "table.superadmin_raw_materials/DELETE_RAW_MATERIALS",
		method: "DELETE",
		endpoint: "/superadmin/raw_materials",
		data: {
			raw_materials: raw_material_ids
		}
	});
};

export const readRawMaterial = (raw_material_id) => {
	return store.dispatch({
		action: "table.superadmin_raw_materials/READ_RAW_MATERIAL",
		method: "GET",
		endpoint: "/superadmin/raw_materials/" + raw_material_id,
		has404: true
	});
};

export const updateRawMaterial = (raw_material_id, formData) => {
	return store.dispatch({
		action: "table.superadmin_raw_materials/UPDATE_RAW_MATERIAL",
		method: "PUT",
		endpoint: "/superadmin/raw_materials/" + raw_material_id,
		data: formData
	});
};