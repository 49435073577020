import * as React from "react";
import {Component} from "react";
import {Field, Form, getFormValues, reduxForm} from "redux-form";
import {withRouter} from "react-router";
import Button from "../../components/Button";
import ArrowRightMiddleIcon from "../../assets/images/arrow-right-middle-15x15.svg";
import styles from "../../styles/forms/auth/RegisterUserForm.scss";
import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import Select from "../../components/Select";
import people_addressing from "../../lists/people_addressing";
import {connect} from "react-redux";
import Checkbox from "../../components/Checkbox";
import {__} from "../../core/utils";
import classNames from "classnames";

class RegisterUserForm extends Component {
	render() {
		return (
			<Form onSubmit={this.props.handleSubmit}>
				<div className={styles.form}>
					<div className={styles.name}>
						<Field name="title" component={Select} label={__('welcome-to-volum3.register.title')} options={people_addressing} center />
						<Field name="firstname" component={Input} label={__('welcome-to-volum3.register.name')} center />
						<Field name="lastname" component={Input} label={__('welcome-to-volum3.register.surname')} center />
					</div>
					<Field name="email" component={Input} label={__('welcome-to-volum3.register.email')} center />
					<div className={styles.password}>
						<Field
							name="password"
							component={PasswordInput}
							success={this.props.values && this.props.values.password_confirmation && this.props.values.password == this.props.values.password_confirmation}
							label={__('welcome-to-volum3.register.choose-password')}
							center
						/>
						<Field
							name="password_confirmation"
							component={PasswordInput}
							error={this.props.values && this.props.values.password_confirmation && this.props.values.password != this.props.values.password_confirmation}
							success={this.props.values && this.props.values.password_confirmation && this.props.values.password == this.props.values.password_confirmation}
							label={__('welcome-to-volum3.register.confirm-password')}
							center
						/>
					</div>
				</div>
				<div className={styles.tos}>
					{__('Please click here to indicate that you agree to the use of your personal information as explained in')}
					<a href="https://www.volum3.com/privacy-and-terms/" target="_blank">{__('welcome-to-volum3.register.enter-user-data.privacy-policy')}</a>
					{__('and have read and agree to the')}
					<a href="https://www.volum3.com/privacy-and-terms/" target="_blank">{__('welcome-to-volum3.register.enter-user-data.terms-&-conditions')}</a>
					<div className={classNames(styles.agree, _.get(this.props, "values.agree") && styles.checked)}>
						<Field name="agree" component={Checkbox} center label="I agree" />
					</div>
				</div>
				<div className={styles.footer}>
					<Button submit center leftText={__('button.register')} rightIcon={ArrowRightMiddleIcon} spinning={this.props.submitting} disabled={!_.get(this.props, "values.agree")} />
				</div>
			</Form>
		);
	}
}

function mapStateToProps(state) {
	return {
		values: getFormValues("auth.register_user")(state),
		companies: state.companies
	};
}

export default withRouter(
	reduxForm({
		form: "auth.register_user",
		destroyOnUnmount: false,
        enableReinitialize: true,
	})(
		connect(
			mapStateToProps,
			null
		)(RegisterUserForm)
	)
);
