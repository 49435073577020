import * as React from "react";
import { Component } from "react";
import { Field, FieldArray, Form, getFormValues, reduxForm } from "redux-form";
import { enableAutosave } from "../../redux/actions/general/autosave";
import styles from "../../styles/forms/products/CategoriesForm.scss";
import { __, mapStateToProps, transformToListPairs } from "../../core/utils";
import classNames from "classnames";
import * as _ from "lodash";
import Select from "../../components/Select";
import CloseMediumIcon from "../../assets/images/close-middle-15x15.svg";
import { subscribe } from "react-contextual";
import Localization from "../../helpers/Localization";
import { hideAlertbox, showAlertbox } from "../../redux/actions/general/alertbox";
import { setNotification } from "../../redux/actions/general/notification";

@reduxForm({
    form: "products.categories",
    enableReinitialize: true,
})
@mapStateToProps(state => ({
    values: getFormValues("products.categories")(state),
    store: state.table.products,
}))
@subscribe(Localization, "localization")
class CategoriesForm extends Component {
    UNSAFE_componentWillReceiveProps() {
        enableAutosave(this.props.form);
    }

    render_rows = ({ fields }) => {
        const { product_groups, product_subgroups } = this.props.localization;
        const { values, store } = this.props;
        const selected_subgroups = _.map(values, 'rows.subgroup');

        const product_categories = _.sortBy(_.values(this.props.localization.product_categories), 'priority');

        return (
            <>
                {fields.map((field, i) => (
                    <div className={classNames(styles.flex, styles.row)} key={store.flyout.id + '-' + i}>
                        <div className={styles.index}>{i + 1}.</div>
                        <div className={styles.f1}>
                            <Field onChange={(e, value) => {
                                if (value != _.get(values, field + ".category")) {
                                    this.props.change(field + ".group", null);
                                    this.props.change(field + ".subgroup", null);
                                }
                            }} name={field + ".category"} component={Select} searchable label={__('products.column.primary-level')} noSort options={transformToListPairs(product_categories)} />
                        </div>
                        <div className={styles.f1}>
                            <Field onChange={(e, value) => {
                                if (value != _.get(values, field + ".group")) {
                                    this.props.change(field + ".subgroup", null);
                                }
                            }} name={field + ".group"} component={Select} searchable label={__('products.column.secondary-level')} options={transformToListPairs(_.filter(product_groups, group => {
                                return group.product_category_id == _.get(values, field + ".category")
                            }))} disabled={!_.get(values, field + ".category")} />
                        </div>
                        <div className={styles.f1}>
                            <Field name={field + ".subgroup"} component={Select} searchable label={__('products.column.tertiary-level')} options={transformToListPairs(_.filter(product_subgroups, subgroup => {
                                return subgroup.product_group_id == _.get(values, field + ".group") && (!_.includes(selected_subgroups, subgroup.id) || _.get(values, field + ".subgroup") == subgroup.id);
                            }))} disabled={!_.get(values, field + ".group")} />
                        </div>
                        <div className={styles.remove}>
                            <CloseMediumIcon className={classNames(_.size(fields) == 1 && styles.disabled)} onClick={() => {
                                if (_.size(fields) > 1) {
                                    showAlertbox({
                                        color: "red",
                                        title: __('general.alert.are-you-sure'),
                                        description: __('are-you-sure.detach-category.alert'),
                                        buttons: [
                                            {
                                                color: 'lightGray',
                                                text: __('general.alert.no-close'),
                                                onClick: 'close'
                                            },
                                            {
                                                color: 'gray',
                                                text: __('general.alert.yes-delete'),
                                                onClick: () => {
                                                    hideAlertbox();

                                                    setNotification(__('products.categories.forms.category-has-been-deleted.notification'));

                                                    fields.remove(i);

                                                    setTimeout(() => this.props.submit(), 50);
                                                }
                                            }
                                        ]
                                    });
                                }
                            }} />
                        </div>
                    </div>
                ))}
                <div className={classNames(styles.addRow, _.size(fields) == 0 && styles.left)}>
                    <button type="button" onClick={() => {
                        fields.push({});

                        setTimeout(() => this.props.submit(), 50);
                    }}>{__('button.add-category')}</button>
                </div>
            </>
        )
    }

    render() {
        const { handleSubmit, collapsed, store } = this.props;

        return (
            <Form onSubmit={handleSubmit} className={classNames(styles.wrapper, collapsed && styles.collapsed)}>
                <FieldArray name="rows" key={store.flyout.id} component={this.render_rows} />
            </Form>
        );
    }
}

export default CategoriesForm;
