import * as React from 'react';
import { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import Textarea from '../../components/Textarea';
import { __, transformToListPairs } from '../../core/utils';
import { enableAutosave } from '../../redux/actions/general/autosave';
import styles from '../../styles/forms/rooms/EditRoomForm.scss';
import Input from '../../components/Input';
import { withRouter } from 'react-router';
import { subscribe } from 'react-contextual/dist/react-contextual.es';
import Localization from '../../helpers/Localization';
import Select from '../../components/Select';
import {
  listPossibleEntrances,
  listPossibleExpansionJoints,
  listPossibleRoomGroups,
  listPossibleRoomTypes,
  listPossibleStoreys,
  listPossibleZones,
} from '../../redux/actions/table/rooms';
import MultiSelect from '../../components/MultiSelect';
import Tags from '../../components/Tags';
import { enableMultiedit } from '../../redux/actions/general/multiedit';

@reduxForm({
  form: 'rooms.edit_room',
  enableReinitialize: true,
})
@withRouter
@subscribe(Localization, 'localization')
class EditRoomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      possible_storeys: [],
      possible_expansion_joints: [],
      possible_entrances: [],
      possible_zones: [],
      possible_room_groups: [],
      possible_room_types: [],
    };
  }

  UNSAFE_componentWillReceiveProps() {
    !this.props.multiedit && enableAutosave(this.props.form);
  }

  componentDidMount() {
    this.props.multiedit && enableMultiedit(this.props.form);

    listPossibleStoreys(this.props.match.params.stage).then(({ response }) => {
      this.setState({ possible_storeys: response.data });
    });

    listPossibleExpansionJoints(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({ possible_expansion_joints: response.data });
      }
    );

    listPossibleEntrances(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({ possible_entrances: response.data });
      }
    );

    listPossibleZones(this.props.match.params.stage).then(({ response }) => {
      this.setState({ possible_zones: response.data });
    });

    listPossibleRoomGroups(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({ possible_room_groups: response.data });
      }
    );

    listPossibleRoomTypes(this.props.match.params.stage).then(
      ({ response }) => {
        this.setState({ possible_room_types: response.data });
      }
    );
  }

  render() {
    const { data, handleSubmit, localization, securityTags } = this.props;
    const { project_languages } = data;
    const { functional_spaces } = localization;
    const {
      possible_storeys,
      possible_expansion_joints,
      possible_entrances,
      possible_zones,
      possible_room_groups,
      possible_room_types,
    } = this.state;

    const indoor_outdoor = [
      {
        label: __('rooms.value.outdoor'),
        value: '1',
      },
      {
        label: __('rooms.value.indoor'),
        value: '0',
      },
    ];

    const foh_boh = [
      {
        label: __('rooms.value.foh'),
        value: '1',
      },
      {
        label: __('rooms.value.boh'),
        value: '0',
      },
    ];

    return (
      <Form onSubmit={handleSubmit}>
        <div className={styles.flex}>
          {!this.props.multiedit && (
            <div className={styles.f1}>
              <Field
                name='code'
                component={Input}
                label={__('rooms.field.code')}
                maxLength={12}
              />
            </div>
          )}
          <div className={styles.f3}>
            <Field
              name='title'
              component={Input}
              localized={project_languages}
              label={__('rooms.field.title')}
              maxLength={50}
            />
          </div>
          <div className={styles.f3}>
            <Field
              name='storey_id'
              component={Select}
              label={__('rooms.field.storey')}
              searchable
              options={_.map(possible_storeys, (storey) => ({
                label: storey.name,
                value: storey.id,
                group: storey.building,
              }))}
            />
          </div>
        </div>
        <Field
          name='description'
          component={Textarea}
          localized={project_languages}
          label={__('rooms.field.description')}
          maxLength={2000}
          rows={1}
          maxRows={10}
        />
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='functional_spaces'
              component={MultiSelect}
              label={__('rooms.field.functional-space')}
              searchable
              options={transformToListPairs(functional_spaces)}
            />
          </div>
          <div className={styles.f1}>
            <Field
              noneValue
              searchable
              name='zones'
              component={Select}
              label={__('rooms.field.zone')}
              options={transformToListPairs(possible_zones, 'title')}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='foh'
              component={Select}
              label={__('rooms.field.foh-boh')}
              options={foh_boh}
            />
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='outdoor'
              component={Select}
              label={__('rooms.field.indoor-outdoor')}
              options={indoor_outdoor}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='entrances'
              component={MultiSelect}
              label={__('rooms.field.entrance')}
              searchable
              options={transformToListPairs(possible_entrances, 'title')}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='expansion_joints'
              component={MultiSelect}
              label={__('rooms.field.expansion-joint')}
              searchable
              options={transformToListPairs(possible_expansion_joints, 'title')}
            />
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='area'
              component={Input}
              label={__('rooms.field.area')}
              numeric
              decimal
              maxLength={10}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='volume'
              component={Input}
              label={__('rooms.field.volume')}
              numeric
              decimal
              maxLength={10}
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='occupancy'
              component={Input}
              label={__('rooms.field.occupancy')}
              numeric
              maxLength={10}
            />
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='room_group_id'
              component={Select}
              searchable
              label={__('rooms.field.room-group')}
              options={transformToListPairs(possible_room_groups, 'title')}
              noneValue
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='room_type_id'
              component={Select}
              searchable
              label={__('rooms.field.room-type')}
              options={transformToListPairs(possible_room_types, 'title')}
              noneValue
            />
          </div>
          <div className={styles.f1}>
            <Field
              name='security'
              component={Tags}
              label={__('rooms.field.security')}
              options={securityTags}
            />
          </div>
        </div>
        <div className={styles.flex}>
          <div className={styles.f1}>
            <Field
              name='requirement'
              component={Textarea}
              label={__('rooms.field.requirement')}
              maxLength={2000}
              rows={3}
              maxRows={10}
              localized={project_languages}
            />
          </div>
        </div>
      </Form>
    );
  }
}

export default EditRoomForm;
