import store from "../../../core/store";

export const listSites = (stage_id) => {
    return store.dispatch({
        action: "table.sites/LIST_SITES",
        method: "GET",
        endpoint: "/stages/" + stage_id + "/sites",
        main: true,
    });
};

export const createSite = (stage_id, formData) => {
    return store.dispatch({
        action: "table.sites/CREATE_SITE",
        method: "POST",
        endpoint: "/stages/" + stage_id + "/sites",
        data: formData
    });
};

export const readSite = (site_id) => {
    return store.dispatch({
        action: "table.sites/READ_SITE",
        method: "GET",
        endpoint: "/sites/" + site_id,
        has404: true
    });
};

export const updateSite = (site_id, formData) => {
    return store.dispatch({
        action: "table.sites/UPDATE_SITE",
        method: "PUT",
        endpoint: "/sites/" + site_id,
        data: formData
    });
};

export const deleteSites = (site_ids) => {
    return store.dispatch({
        action: "table.sites/DELETE_SITES",
        method: "DELETE",
        endpoint: "/sites",
        data: {
            sites: site_ids
        }
    });
};