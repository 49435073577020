import * as React from "react";
import {Component} from "react";
import styles from "../styles/components/TableNA.scss";

class TableNA extends Component {
    render() {
        return (
            <span className={styles.wrapper}>n/a</span>
        );
    }
}

export default TableNA;
