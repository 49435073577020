import Image from '../../components/Image';
import * as React from 'react';
import { Component } from 'react';
import Table from '../../components/Table';
import {
  createSidebar,
  createSuperadminSidebar,
  toggleSidebarCollapse,
  updateSidebar,
} from '../../redux/actions/general/sidebar';
import {
  __,
  dateFrom,
  getLocalized,
  getURLParam,
  hasURLParam,
  mapStateToProps,
  setTableParams,
  setURLParam,
} from '../../core/utils';
import * as _ from 'lodash';
import Tooltip from '../../components/Tooltip';
import { listActiveModules } from '../../redux/actions/general/active_modules';
import styles from '../../styles/views/superadmin/Products.scss';
import LockClosedIcon from '../../assets/images/lock-closed-16x16.svg';
import LockOpenedIcon from '../../assets/images/lock-opened-16x16.svg';
import TableNA from '../../components/TableNA';
import classNames from 'classnames';
import ProductsFlyout from '../../flyouts/superadmin/products/ProductsFlyout';
import {
  listProducts,
  publishProducts,
  readProduct,
  unpublishProducts,
} from '../../redux/actions/table/superadmin/products';
import { setNotification } from '../../redux/actions/general/notification';
import { setPlusActions } from '../../redux/actions/general/plus';

@mapStateToProps((state) => ({
  superadmin: state.auth.superadmin,
  table: state.table['superadmin_products'],
  application_language_id: state.auth.language_id,
}))
class Products extends Component {
  componentDidMount() {
    listActiveModules();
    createSuperadminSidebar(); //don't forget to add new routes in sidebar.js

    const { superadmin } = this.props;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.table.clicked_row &&
      this.props.table.clicked_row !== prevProps.table.clicked_row
    ) {
      if (!prevProps.table.clicked_row) {
        setTableParams('superadmin_products', {
          flyout: undefined,
        });
      }

      readProduct(this.props.table.clicked_row).then(({ response }) => {
        setTableParams('superadmin_products', {
          flyout: response.data,
        });
      });
    }
  }

  _handleOpacity(value, row) {
    return row.discontinued ? (
      <div className={styles.faded}>{value}</div>
    ) : (
      value
    );
  }

  render() {
    const { superadmin, application_language_id } = this.props;

    const columns = [
      {
        key: 'type',
        name: __('products.table-titles.type'),
        value: (value, row) => {
          switch (value) {
            case 'product':
              return this._handleOpacity(
                __('products.table-titles.product'),
                row
              );
            case 'part':
              return this._handleOpacity(__('products.table-titles.part'), row);
          }
        },
        width: 120,
      },
      {
        key: 'description',
        name: __('products.table-titles.description'),
        value: (description, row) =>
          this._handleOpacity(
            getLocalized(description, application_language_id),
            row
          ),
        width: 200,
      },
      {
        key: 'model',
        name: __('products.table-titles.model'),
        required: true,
        default: true,
        value: (value, row) => this._handleOpacity(value, row),
      },
      {
        key: 'thumb',
        name: __('products.table-titles.thumbnail'),
        value: (created_by, row) =>
          this._handleOpacity(
            row.image_url ? (
              <Tooltip
                placement='right'
                text={
                  <Image className={styles.thumbPreview} src={row.image_url} />
                }
                html={true}
                dark={true}
              >
                <Image className={styles.thumb} src={row.image_url} />
              </Tooltip>
            ) : (
              <TableNA />
            ),
            row
          ),
        width: 50,
        sortable: false,
      },
      {
        key: 'company_id',
        name: __('products.table-titles.company'),
        value: (value, row) =>
          this._handleOpacity(
            <div className={styles.company}>{row.company.name}</div>,
            row
          ),
        width: 150,
      },
      {
        key: 'brand',
        name: __('products.table-titles.manufacturer'),
        value: (value, row) => this._handleOpacity(value, row),
        width: 150,
      },
      {
        key: 'created_at',
        name: __('products.table-titles.added-at'),
        value: (value, row) => this._handleOpacity(dateFrom(value), row),
        width: 80,
      },
      {
        key: 'price_from',
        name: __('products.table-titles.price-from'),
        value: (value, row) => this._handleOpacity(value || 'n/a', row),
        width: 80,
      },
      {
        key: 'price_to',
        name: __('products.table-titles.price-to'),
        value: (value, row) => this._handleOpacity(value || 'n/a', row),
        width: 80,
      },
      {
        key: 'price_date',
        name: __('products.table-titles.price-date'),
        value: (value, row) =>
          this._handleOpacity(value ? dateFrom(value) : 'n/a', row),
        width: 80,
      },
      {
        key: 'bim',
        name: __('products.table-titles.bim'),
        value: (value, row) =>
          this._handleOpacity(
            value
              ? __('products.table-titles.bim.yes')
              : __('products.table-titles.bim.no'),
            row
          ),
        width: 80,
        sortable: false,
      },
      {
        key: 'public',
        name: __('products.table-titles.public'),
        value: (sent, row) =>
          row.public ? (
            <span className={classNames(styles.public, styles.yes)}>
              {__('products.table-titles.public.yes')}
            </span>
          ) : (
            <span className={styles.public}>
              {__('products.table-titles.public.no')}
            </span>
          ),
        width: 60,
      },
    ];

    const singleActions = (product_id) => {
      const { auth } = this.props;
      const product = _.find(this.props.table.data, { id: product_id });

      let actions = [];

      actions.push(
        product.public ? (
          <Tooltip
            text={__('products.flyout.tooltip.unpublish-product-part')}
            key={_.uniqueId()}
          >
            <LockOpenedIcon
              onClick={(e) => {
                e.stopPropagation();

                unpublishProducts([product_id]).then(() => {
                  readProduct(product_id).then(({ response }) => {
                    setTableParams('products', {
                      flyout: response.data,
                      actioned_row: undefined,
                    });
                  });

                  listProducts();

                  setNotification(
                    __(
                      'products.flyout.notifications.selected-product-part-unpublished'
                    )
                  );
                });
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            text={__('products.all-products.tooltip.publish-product-part')}
            key={_.uniqueId()}
          >
            <LockClosedIcon
              onClick={(e) => {
                e.stopPropagation();

                publishProducts([product_id]).then(() => {
                  readProduct(product_id).then(({ response }) => {
                    setTableParams('products', {
                      flyout: response.data,
                      actioned_row: undefined,
                    });
                  });

                  listProducts();

                  setNotification(
                    __(
                      'products.flyout.notifications.selected-product-part-published'
                    )
                  );
                });
              }}
            />
          </Tooltip>
        )
      );

      return actions;
    };

    const gridOptions = {
      section: __('submenu.title.products'),
      image: (id, row) => row.image_url,
      title: (row) => row.model,
      subTitle: (row) => [], //
      content: (row) => [row.brand],
    };

    const preview = (
      <ProductsFlyout
        readAction={(product_id) => {
          return new Promise((resolve) => {
            readProduct(getURLParam('id') || product_id).then(
              ({ response }) => {
                setTableParams('superadmin_products', {
                  flyout: response.data,
                });

                resolve(response);
              }
            );
          });
        }}
      />
    );

    return (
      <Table
        listAction={listProducts}
        name='superadmin_products'
        title={__('products.submenu.title.products')}
        columns={columns}
        singleActions={superadmin.publish_products && singleActions}
        defaultView='grid'
        preview={this.props.table.flyout ? preview : []}
        gridOptions={gridOptions}
      />
    );
  }
}

export default Products;
