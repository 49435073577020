import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import Router from "./router";
import "../styles/core/app.scss";
import history from "./history";
import store from "./store";
import * as Sentry from '@sentry/browser';
import ExceptionHandler from "../components/ExceptionHandler";
import {hotjar} from 'react-hotjar';
import ReactGA from "react-ga";
import '../styles/core/table.scss'

/**
 * Sentry
 */
if(process.env.SENTRY_DSN) {
    Sentry.init({
        release: process.env.GIT_HASH,
        environment: process.env.APP_ENV,
        dsn: process.env.SENTRY_DSN,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        integrations: [
            new Sentry.Integrations.GlobalHandlers({
                onunhandledrejection: false
            })
        ],
    });
}

/**
 * Hotjar
 */
if(process.env.HOTJAR_ID && process.env.HOTJAR_SV) {
    hotjar.initialize(process.env.HOTJAR_ID, process.env.HOTJAR_SV);
}

/**
 * Google Analytics
 */
if(process.env.GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

    history.listen(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    });
}

ReactDOM.render(
	<ExceptionHandler>
        <Provider store={store}>
            <Router history={history} />
        </Provider>
    </ExceptionHandler>,
	document.getElementById("root")
);