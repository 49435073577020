import * as React from "react";
import {Component} from "react";
import {connect} from "react-redux";
import styles from '../styles/containers/Lightbox.scss';
import CloseSmallIcon from '../assets/images/close-small-40x40.svg';
import ArrowLeftSmallIcon from '../assets/images/arrow-left-small-40x40.svg';
import ArrowRightSmallIcon from '../assets/images/arrow-right-small-40x40.svg';
import {hideLightbox, showLightbox} from "../redux/actions/general/lightbox";
import KeyboardEventHandler from "react-keyboard-event-handler";
import autobind from "autobind-decorator";
import * as _ from "lodash";
import {isMobile} from "react-device-detect";
import classNames from 'classnames';
import Tooltip from "../components/Tooltip";

const mapStateToProps = state => ({
    ...state.general.lightbox
});

@connect(mapStateToProps)
class Lightbox extends Component {
    componentDidUpdate(prevProps) {
        if(!prevProps.active && this.props.active) {
            document.getElementById("root").classList.add("blur");
        }

        if(prevProps.active && !this.props.active) {
            document.getElementById("root").classList.remove("blur");
        }
    }

    @autobind
    _handleShortcut(key, e) {
        if(this.props.active) {
            e.preventDefault();

            const index = _.findIndex(this.props.images, ['id', this.props.active]);

            if(index != 0 && (key == "up" || key == "left")) {
                showLightbox(this.props.images, _.get(this.props, ['images', index - 1, 'id']));
            } else if(index != (_.size(this.props.images) - 1) && (key == "down" || key == "right")) {
                showLightbox(this.props.images, _.get(this.props, ['images', index + 1, 'id']));
            } else if(key == 'esc') {
                hideLightbox();
            }
        }
    }

    render() {
        const image = _.find(this.props.images, ['id', this.props.active]);

        const index = _.findIndex(this.props.images, ['id', this.props.active]);

        return this.props.active ? (
            <div className={classNames(styles.wrapper, isMobile && styles.mobile)}>
                <img src={image.stream_url} />
                <div className={styles.overlay}>
                    <div className={styles.top}>
                        <Tooltip text={image.title}>
                            <div className={styles.title}>
                                {image.title}
                            </div>
                        </Tooltip>
                        <CloseSmallIcon onClick={hideLightbox} />
                    </div>
                    <div className={styles.controls}>
                        <div className={classNames(styles.left, index > 0 && styles.clickable)} onClick={() => index > 0 && showLightbox(this.props.images, _.get(this.props.images[index - 1], 'id'))}>
                            {index != 0 && <ArrowLeftSmallIcon />}
                        </div>
                        <div className={styles.gap}/>
                        <div className={classNames(styles.right, index < (_.size(this.props.images) - 1) && styles.clickable)} onClick={() => index < (_.size(this.props.images) - 1) && showLightbox(this.props.images, _.get(this.props.images[index + 1], 'id'))}>
                            {index != (_.size(this.props.images) - 1) && <ArrowRightSmallIcon />}
                        </div>
                    </div>
                </div>
                <KeyboardEventHandler handleKeys={['up', 'down', 'left', 'right', 'esc']} onKeyEvent={this._handleShortcut} />
            </div>
        ) : '';
    }
}

export default Lightbox;